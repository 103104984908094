<template>

  <v-dialog :fullscreen="$vuetify.breakpoint.xsOnly" scrollable persistent 
    v-model="dialog" :width="680" v-if="dialog" @keydown.esc="cancel" @click:outside="cancel">
		<v-card class="card">
			<div class="d-flex justify-space-between mr-7 mt-5">
				<div>
					<q-date-picker
						v-model="startDate" 
						class="inline-block ml-6 data-picker-width" label="Start date"
						outlined inner dense
						hide-details
					/>
					<q-date-picker
						v-model="endDate"
						class="inline-block ml-4 data-picker-width" label="End date"
						outlined inner dense
						hide-details
					/>
				</div>
				<excel-btn @click="downloadExcel"/>
			</div>
			<v-card-title>
				<div class="cycle-time">
					<div>
						Lead Time:
						<span
							:style="{
              color: 
                goal.leadTimeGoal ?
                  ( toMinutes(goal.leadTimeGoal, goal.leadTimeGoalUnit, usingWorkingHours) < leadTime ? 'salmon' : 'lightgreen' )
                  : 'text'
            	}"
						>
							{{
								!selectedUnit ? 
									goal.leadTimeGoal ? 
                    formatTimeByUnit(leadTime, goal.leadTimeGoalUnit, usingWorkingHours) :
                    getIndicatorTime(leadTime, false, usingWorkingHours, usingWorkingHours)
									:
									formatTime(leadTime)
							}}
						</span>
						<span v-if="!!goal.leadTimeGoal">
							vs.
							<span>
								{{ 
									!selectedUnit ? 
										formatTimeByUnit(toMinutes(goal.leadTimeGoal, goal.leadTimeGoalUnit, usingWorkingHours), goal.leadTimeGoalUnit, usingWorkingHours) :
										formatTime(toMinutes(goal.leadTimeGoal, goal.leadTimeGoalUnit, usingWorkingHours))
								}}
							</span>
						</span>
					</div>
				</div>
				<div class="units-selector">
					<v-btn
						style="text-transform: none;"
						@click="selectedUnit++; selectedUnit%=units.length"
						:color="selectedUnit ? 'primary' : 'secondary'"
					>
						{{ 
							units[selectedUnit].name
						}}
					</v-btn>
					<v-btn
						v-if="activeSprint.id"
						class="ml-3"
						style="text-transform: none;"
						@click="showSprintLeadTime"
						color="primary"
					>
						Current Sprint
					</v-btn>
				</div>

				<v-tooltip top>
					<template v-slot:activator="{ on }">
						<v-btn text small v-on="on" @click="cancel" class="close">
							<v-icon>close</v-icon>
						</v-btn>
					</template>
					<span>Cancel</span>
				</v-tooltip>

			</v-card-title>
			<v-card-text class="ay">
				<v-data-table
					class="colored-header"
					style="border: 1px solid #888"
					:items="workItems"
					:headers="headersWorkItems"
					:sortable="false"
					:items-per-page="-1"
					hide-default-footer
					@click:row="clickWorkItem"
					:item-class="v => 'cursor-pointer'"
				>
					<template v-slot:[`item.code`]="{ item }">
						<div>
							{{item.code}}
						</div>
					</template>
					<template v-slot:[`item.name`]="{ item }">
						<div>
							{{item.name}}
						</div>
					</template>
					<template v-slot:[`item.leadTime`]="{ item }">
						<div>
							{{formatTime(item.leadTime)}}
						</div>
					</template>
				</v-data-table>
			</v-card-text>
		</v-card>
  </v-dialog>
</template>

<script>
import moment from "moment";
import ExcelBtn from '../components/ExcelBtn.vue';

export default {
	components: {
		ExcelBtn,
	},
	props: [
		'usingWorkingHours',
		'projectCreatedAt',
		'activeSprint',
	],
	data(){
		return {
			id: null,
			dialog: false,
			workItems: [],
			leadTime: 0,
			goal: {},
			units: [
				{ name: 'Minutes', },
				{ name: 'Minutes', shortname: 'mins', minutes: 1, workingMinutes: 1 },
				{ name: 'Hours',	 shortname: 'hrs', 	minutes: 60, workingMinutes: 60 },
				{ name: 'Days',  	 shortname: 'days',	minutes: 1440, workingMinutes: 540 },
			],
			headersWorkItems: [
				{
					text: "ID",
					value: "code", sort: (a, b) => {
						let indexA = a.indexOf('-')
						let indexB = a.indexOf('-')
						a = a.substr(indexA+1)
						b = b.substr(indexB+1)
						return parseInt(a) - parseInt(b)
					},
					width: 95,
				},
				{ text: "Summary", value: "name", },
				{ text: "Time", 	 value: "leadTime", align: 'right' },
			],
			selectedUnit: 0,
			startDate: moment().subtract(1, 'week').format('YYYY-MM-DD'),
			endDate: moment().add(1, 'day').format('YYYY-MM-DD'),
		}
	},
	watch: {
		startDate() {
			this.$nextTick(() => this.init())
		},
		endDate() {
			this.$nextTick(() => this.init())
		}
	},
	methods: {
		init() {
			this.$http.get(`api/Project/${this.id}/leadTime/${this.type}?includeWorkItems=true&startDate=${this.startDate}&endDate=${this.endDate}`).then((res) => {
				this.workItems = res.data.workItems
				this.leadTime = res.data.leadTime
				this.goal = res.data.leadTimeGoal || {}
			})
		},
		open(id, type){
			this.startDate = this.projectCreatedAt
			this.selectedUnit = 0
			this.id = id
			this.type = type
			this.dialog = true
			this.init()
		},
		clickWorkItem(v){ this.$emit('clickWorkItem', v.code, this.init) },
		cancel(){
			this.dialog = false
		},
		formatTime(time, withUnit = true){
			if(!this.selectedUnit)
				return this.getIndicatorTime(time, false, this.usingWorkingHours)

			if(time == null || isNaN(time) || time == -1)
				return '-'
			
			if(this.usingWorkingHours)
				time /= this.units[this.selectedUnit].workingMinutes
			else
				time /= this.units[this.selectedUnit].minutes
			let units = this.units[this.selectedUnit].shortname

			time = Math.round(time);
			if(time == 1)
				units = units.substring(0, units.length-1)
			return  new Intl.NumberFormat('en-US').format(time) + (withUnit ? ' ' + units : '')
		},
		showSprintLeadTime() {
			if(!this.activeSprint.startDate || !this.activeSprint.endDate)
				return
			this.startDate = this.activeSprint.startDate
			this.endDate = this.activeSprint.endDate
		},
		downloadExcel() {
			let props = {
        Title: 'Work Items Lead Time',
        Subject: 'Lead Time',
        Author: 'BlueSurf',
        CreatedDate: new Date()
      }

      // Fill sheet info
      let data = [], row = []

      row.push('ID')
      row.push('Summary')
      row.push('Time ' + (this.selectedUnit ? `(${this.units[this.selectedUnit].shortname})` : ''))
      data.push(row)

      for(let workItem of this.workItems) {
        row = []
        row.push(workItem.code)
				row.push(workItem.name)
				row.push(this.formatTime(workItem.leadTime, false))
        data.push(row)
      }

      let sheets = [{
        name: 'Lead Time',
        data
      }]

      this.createExcel(props, sheets);
		},
	}
}
</script>

<style scoped lang="scss">
.card{
	padding: 30px 0;
}
.ay::-webkit-scrollbar {
	width: 6px;
	height: 5px;
}
.ay::-webkit-scrollbar-thumb{
  background: #AED6F1;
}
.status-container{
	white-space:pre-wrap;
  word-break:break-word;
  box-shadow: 0 0 5px #888;
  display: inline-block;
  min-height: 30px;
  max-height: 70px;
	width: 100%;
  color: white;
  overflow: hidden;
  margin: 5px 0;
  border-radius: 5px;
  padding: 5px 10px;
	text-align: center;
}

.units-selector{
	text-transform: none;
	margin-left: auto;
}

.close{
	position: absolute;
	right: 10px;
	top: 10px;
}

.cycle-time{
	display: flex;
	align-items: center;

	div{
		background-color: var(--v-background-lighten2);
		padding: 10px;
		border-radius: 10px;
	}
}

.table{
	padding: 10px;
	border: 1px solid #888;
	color: #333;

	.header{
		background: #1976D2;
		color: white;
		.col{
			display: flex;
			align-items: center;
		}
	}

	.body{
		border: 1px solid #888;
		border-top: none;
	}
}
.data-picker-width {
	max-width: 200px;
}
</style>