<template>
  <div @click.stop="(disabled || readonly) ? null : datePickerMenu = true">
    <v-menu
      ref="datePickerMenu"
      :close-on-content-click="false"
      v-model="datePickerMenu"
      :nudge-right="40"
      transition="scale-transition"
      offset-y
      max-width="290px"
      min-width="290px"
      :zIndex="zIndex"
      :disabled="readonly"
    >
      <template v-slot:activator="{ on }">
        <v-text-field
          v-on="on"
          v-model="dateFormatted"
          :label="pickerLabel"
          :prepend-icon="inner ? null:icon"
          :prepend-inner-icon="!inner ? null:icon"
          @blur="$emit('blur'); date = parseDate(dateFormatted)"
          v-bind="$attrs"
          :disabled="disabled"
          :readonly="readonly"
        ></v-text-field>
      </template>
      <v-date-picker
        :key="key"
        v-model="date"
        :min="min" :max="max"
        v-on="listeners"
        no-title @input="input"
        :allowed-dates="allowedDates"
        :type="type"
      />
    </v-menu>
  </div>
</template>

<script>
  import moment from 'moment'
  import _ from "lodash"

  export default {
    props: {
      label: String,
      value: String,
      inner: Boolean,
      min: String,
      max: String,
      disabled: Boolean,
      allowedDates: Function,
      icon: { type:String, default:'event' },
      zIndex: { type:String|Number },
      readonly: Boolean,
      type: { type:String, default:'date' },
    },
    data: () => ({
      datePickerMenu: false,
      date: null,
      dateFormatted: null,
      key: 0,
    }),
    watch: {
      date: function (val) {
        this.dateFormatted = this.formatDate(this.date);
        this.$emit('input', val);
      },
      value: function (val) {
        this.date = val;
      },
      datePickerMenu: function (val) {
        this.$emit('update:pickerMenu', val);
      },
      type: function (val) {
        this.key++;
      },
    },
    computed: {
      pickerLabel() {
        if(this.label === '')
          return ''
        return this.label || "Date";
      },
      listeners() {
        const { input, ...listeners } = this.$listeners;
        return listeners;
      },
    },
    methods: {
      input($event) {
        if(this.type === 'month' && this.date.length === 7)
          this.date += '-01'
        this.datePickerMenu = false
        this.$emit('input', this.date)
      },
      //TODO: Send to q-datepicker
      formatDate (date) {
        if (!date) return null;
        if(this.type === 'month')
          return moment(String(date)).format('MMM YYYY');
        return moment(String(date)).format('MMM D, YYYY');
      },
      parseDate (date) {
        if (!date) return null;
        let compatibleFormats = ["YYYY-MM-DD", "MMM D, YYYY", "MMM/D/YYYY", "DD-MM-YYYY", "DD/MMM/YYYY", "DD/MM/YYYY", "MM-D-YYYY",];
        return moment(date, compatibleFormats)
          .format('YYYY-MM-DD');
      },
    },
    created() {
      if (this.value) {
        this.date = this.parseDate(this.value);
      }
    },
   }
</script>

<style scoped lang="scss">

</style>