<template>
	<blue-confirm-dialog ref="dialog">
		<template #text>
			<div class="body-2 mb-5">Please select the corresponding Invoices</div>

			<div class="bordered darken rounded overflow-hidden">
				<v-text-field
					v-model="search" label="Search"
					hide-details solo flat
					background-color="newDesignBackground"
					prepend-inner-icon="mdi-magnify"
					class="rounded-0" style="border-bottom: 1px solid var(--v-lightgray-base)"
				/>
				<invoices-table
					v-model="selectedItems" show-select
					:items="shownInvoices" :complete="false" relate-view
					class="new-back lighten2 cursor-pointer rounded-0"
					checkbox-color="primary"
				/>
			</div>
		</template>
	</blue-confirm-dialog>
</template>

<script>
import InvoicesTable from '../../components/InvoicesTable'
export default {
	components: { InvoicesTable },
	data: () => ({
		search: '',
		selectedItems: [],
		invoices: [],
		resolve: null,
	}),
	computed: {
		shownInvoices() {
			return this.applySearch(this.search, this.invoices, ['code'])
		},
	},
	methods: {
		open(invoices){
			this.invoices = invoices
			this.selectedItems = []
			this.$refs.dialog.open({
				title: 'Relate Invoices',
				confirmBtnText: 'Add Invoices',
				width: 1500,
			})
				.then(res => {
					if(!res) {
						this.resolve([])
						return
					}
					this.resolve(this.selectedItems)
				})
			return new Promise(res => this.resolve = res)
		},
	},
}
</script>