var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (!!_vm.item)?_c('html-to-pdf',{attrs:{"name":_vm.item.code,"prevent":!_vm.download},model:{value:(_vm.show),callback:function ($$v) {_vm.show=$$v},expression:"show"}},[_c('div',{staticClass:"d-flex justify-space-between"},[_c('div',[_c('label',[_vm._v("Billable")]),_c('div',{staticClass:"body-1 primary--text"},[_vm._v(_vm._s(_vm.item.billableCode))])]),_c('div',[_c('label',[_vm._v("Customer")]),_c('div',{staticClass:"body-1"},[_vm._v(_vm._s(_vm.item.customerName))])]),_c('div',[_c('label',[_vm._v("Currency")]),_c('div',{staticClass:"body-1"},[_c('v-avatar',{attrs:{"size":"18"}},[_c('v-img',{attrs:{"src":require(("../../assets/currencies/" + (_vm.item.billableCurrency.toLowerCase()) + ".png"))}})],1),_vm._v(" "+_vm._s(_vm.item.billableCurrency)+" ")],1)]),_c('div',[_c('label',[_vm._v("Start Date")]),_c('div',{staticClass:"body-1"},[_vm._v(_vm._s(_vm._f("formatDateCustom")(_vm.item.startDate,'DD/MM/YY')))])]),_c('div',[_c('label',[_vm._v("End Date")]),_c('div',{staticClass:"body-1"},[_vm._v(_vm._s(_vm._f("formatDateCustom")(_vm.item.endDate,'DD/MM/YY')))])]),_c('div',[_c('label',[_vm._v("Status")]),_c('div',[_c('v-btn',{staticStyle:{"border-radius":"20px"},style:({
						background: _vm.$vuetify.theme.isDark ? '' : _vm.item.statusLightColor
					}),attrs:{"color":_vm.item.statusColor,"outlined":"","small":""}},[_vm._v(" "+_vm._s(_vm.item.statusDisplayName)+" ")])],1)])]),_c('v-row',{staticClass:"mt-4"},[_c('v-col',[_c('div',{staticClass:"bordered darken rounded-lg pa-2"},[_c('span',[_vm._v("Total Billed")]),_c('div',{staticClass:"headline text-right"},[_vm._v("$"+_vm._s(_vm._f("money")(_vm.item.total)))])])]),_c('v-col',[_c('div',{staticClass:"bordered darken rounded-lg pa-2"},[_c('span',[_vm._v("Total Invoiced")]),_c('div',{staticClass:"headline text-right"},[_vm._v("$"+_vm._s(_vm._f("money")(_vm.item.invoiced)))])])]),_c('v-col',[_c('div',{staticClass:"bordered darken rounded-lg pa-2"},[_c('span',[_vm._v("Pending amount to invoice")]),_c('div',{staticClass:"headline text-right"},[_vm._v("$"+_vm._s(_vm._f("money")(_vm.item.total - _vm.item.invoiced)))])])])],1),_c('billable-item-lines-table',{staticClass:"mt-4 darken",attrs:{"item":_vm.item,"expanded-by-default":"","new-back":false,"userDisplayName":"FullName"}}),_c('div',{staticClass:"mt-5"},[_c('h3',[_vm._v("Related Invoices")]),_c('v-data-table',{staticClass:"bordered darken rounded-lg mt-1",attrs:{"items":_vm.item.invoices,"headers":_vm.invoiceHeaders,"hide-default-footer":"","disable-pagination":"","disable-sort":""},scopedSlots:_vm._u([{key:"item.invoiceDate",fn:function(ref){
					var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("formatDate")(item.invoiceIssueDate))+" ")]}},{key:"item.amount",fn:function(ref){
					var item = ref.item;
return [_c('span',{style:({ textDecoration: item.invoiceStatus == 3 ? 'line-through' : '' })},[_vm._v(" "+_vm._s(_vm.currencyFormat(item.amount))+" ")])]}},{key:"item.invoiceCurrency",fn:function(ref){
					var item = ref.item;
return [_c('div',{staticClass:"d-flex align-center"},[(item.invoiceCurrency)?_c('img',{staticClass:"mr-1",attrs:{"height":20,"contain":"","src":require(("../../assets/currencies/" + (item.invoiceCurrency.toLowerCase()) + ".png"))}}):_vm._e(),_c('b',[_vm._v(_vm._s(item.invoiceCurrency))])])]}},{key:"item.status",fn:function(ref){
					var item = ref.item;
return [_c('v-btn',{staticStyle:{"border-radius":"20px"},style:({
						background: _vm.$vuetify.theme.isDark ? '' : item.invoiceStatusLightColor
					}),attrs:{"color":item.invoiceStatusColor,"outlined":"","small":""}},[_vm._v(_vm._s(item.invoiceStatusName))])]}}],null,true)})],1)],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }