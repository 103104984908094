<template>
	<v-dialog v-model="show">
		<v-card>
			<v-card-title class="headline" primary-title>
				Create New Status List
			</v-card-title>
			<v-card-text>
				<v-row>
					<v-col>
						<v-select solo placeholder="For Type" :items="types" item-value="value" item-text="displayName" v-model="forType">
							<template v-slot:item="{ item }">
								<v-icon :color="item.color" class="mr-2">{{item.iconClass}}</v-icon>
								{{item.displayName}}
							</template>
							<template v-slot:selection="{ item }">
								<v-icon :color="item.color" class="mr-2">{{item.iconClass}}</v-icon>
								{{item.displayName}}
							</template>
						</v-select>
					</v-col>
				</v-row>
				<v-row>
					<v-col v-for="(statuses, ix) in defaultStatuses" :key="ix">
						<h4>{{ix}} Workflow</h4>
						<v-list>
							<v-list-item v-for="(item, ix) in statuses" :key="ix">
								<v-list-item-action>
									<v-checkbox v-model="item.selected"></v-checkbox>
								</v-list-item-action>
								<v-list-item-content>
									<span>
										<v-btn :min-width="130" dark small :color="item.color" class="text-capitalize">
											<v-icon v-if="item.iconClass" small left>{{ item.iconClass }}</v-icon>
											{{ item.name }}
										</v-btn>
									</span>
								</v-list-item-content>
							</v-list-item>
				</v-list>
					</v-col>
				</v-row>
			</v-card-text>
			<v-divider></v-divider>
			<v-card-actions>
				<v-spacer></v-spacer>
				<v-btn color="primary" text @click="close">
					Close
				</v-btn>
				<v-btn color="primary" @click="save">
					Create
				</v-btn>
			</v-card-actions>
		</v-card>
	</v-dialog>
</template>

<script>
export default {
	props: {
		types: Array,
	},
	data: () => ({
		show: false,
		forType: 0,
    defaultStatuses: {
      "Simple": [
        { "name":"Created","description":"Created", "active":true, "color":"#52B7EFFF","iconClass":"adjust","isDefault":true, "isFinal":false },
        { "name":"In Progress","description":"In Progress", "active":true, "color":"#F78F3CFF","iconClass":"autorenew","isDefault":false, "isFinal":false },
        { "name":"Done","description":"Done", "active":true, "color":"#30782AFF","iconClass":"check","isDefault":false, "isFinal":true },
        { "name":"Cancelled","description":"Cancelled", "active":true, "color":"#656565FF","iconClass":"block","isDefault":false, "isFinal":true },
      ],
      "Task": [
        { "name":"New","description":"Created", "active":true, "color":"#52B7EFFF","iconClass":"adjust","isDefault":true, "isFinal":false },
        { "name":"In Progress","description":"In Progress", "active":true, "color":"#F78F3CFF","iconClass":"autorenew","isDefault":false, "isFinal":false },
        { "name":"Blocked","description":"Blocked", "active":true, "color":"#FE5353FF","iconClass":"block","isDefault":false, "isFinal":false },
        { "name":"Done","description":"Done", "active":true, "color":"#30782AFF","iconClass":"check","isDefault":false, "isFinal":false },
        { "name":"Reviewed","description":"Closed", "active":true, "color":"#5259EFFF","iconClass":"done_all","isDefault":false, "isFinal":true },
        { "name":"Cancelled","description":"Cancelled", "active":true, "color":"#656565FF","iconClass":"block","isDefault":false, "isFinal":true },
      ],
      "Authorization": [
        { "name":"New","description":"New", "active":true, "color":"#52B7EFFF","iconClass":"adjust","isDefault":true, "isFinal":false },
        { "name":"In Review","description":"In Review", "active":true, "color":"#F78F3CFF","iconClass":"autorenew","isDefault":false, "isFinal":false },
        { "name":"Rejected","description":"Rejected", "active":true, "color":"#FE5353FF","iconClass":"clear","isDefault":false, "isFinal":true },
        { "name":"Authorized","description":"Authorized", "active":true, "color":"#30782AFF","iconClass":"done_all","isDefault":false, "isFinal":true },
        { "name":"Cancelled","description":"Cancelled", "active":true, "color":"#656565FF","iconClass":"block","isDefault":false, "isFinal":true },
      ],
      "Bug": [
        { "name":"Created","description":"Created", "active":true, "color":"#52B7EFFF","iconClass":"adjust","isDefault":true, "isFinal":false },
        { "name":"Reopened","description":"Reopened", "active":true, "color":"#FE5353FF","iconClass":"block","isDefault":false, "isFinal":false },
        { "name":"In Progress","description":"In Progress", "active":true, "color":"#F78F3CFF","iconClass":"autorenew","isDefault":false, "isFinal":false },
        { "name":"Done","description":"Done", "active":true, "color":"#30782AFF","iconClass":"check","isDefault":false, "isFinal":true },
        { "name":"Closed","description":"Closed", "active":true, "color":"#5259EFFF","iconClass":"done_all","isDefault":false, "isFinal":true },
        { "name":"Cancelled","description":"Cancelled", "active":true, "color":"#656565FF","iconClass":"block","isDefault":false, "isFinal":true },
      ],
      "Issue": [
        { "name":"New","description":"New", "active":true, "color":"#52B7EFFF","iconClass":"adjust","isDefault":true, "isFinal":false },
        { "name":"In Review","description":"In Review", "active":true, "color":"#FE5353FF","iconClass":"block","isDefault":false, "isFinal":false },
        { "name":"Accepted","description":"Accepted", "active":true, "color":"#FE5353FF","iconClass":"block","isDefault":false, "isFinal":false },
        { "name":"Fixing","description":"Fixing", "active":true, "color":"#F78F3CFF","iconClass":"autorenew","isDefault":false, "isFinal":false },
        { "name":"Done","description":"Done", "active":true, "color":"#30782AFF","iconClass":"check","isDefault":false, "isFinal":false },
        { "name":"Validated","description":"Validated", "active":true, "color":"#5259EFFF","iconClass":"done_all","isDefault":false, "isFinal":false },
        { "name":"Root Cause Analysis","description":"Root Cause Analysis", "active":true, "color":"#5259EFFF","iconClass":"done_all","isDefault":false, "isFinal":false },
        { "name":"Closed","description":"Closed", "active":true, "color":"#656565FF","iconClass":"block","isDefault":false, "isFinal":true },
        { "name":"Cancelled","description":"Cancelled", "active":true, "color":"#656565FF","iconClass":"block","isDefault":false, "isFinal":true },
      ],
      "User Story A": [
        {"name":"Backlog","deleted":false,"active":true,"color":"#040404FF","iconClass":"list","isDefault":true, "isFinal":false, },
        {"name":"Analysis (IN PROGRESS)","deleted":false,"active":true,"color":"#527CEFFF","iconClass":"library_books","isDefault":false, "isFinal":false, },
        {"name":"Analysis (DONE)","deleted":false,"active":true,"color":"#33337BFF","iconClass":"library_add_check","isDefault":false, "isFinal":false, },
        {"name":"Development (IN PROGRESS)","deleted":false,"active":true,"color":"#4D7D4CFF","iconClass":"headset","isDefault":false, "isFinal":false, },
        {"name":"Development (DONE)","deleted":false,"active":true,"color":"#2F4A2EFF","iconClass":"cloud_done","isDefault":false, "isFinal":false, },
        {"name":"Testing (IN PROGRESS)","deleted":false,"active":true,"color":"#D25757FF","iconClass":"bug_report","isDefault":false, "isFinal":false, },
        {"name":"Testing (DONE)","deleted":false,"active":true,"color":"#7D3434FF","iconClass":"verified_user","isDefault":false, "isFinal":false, },
        {"name":"Validation (IN PROGRESS)","deleted":false,"active":true,"color":"#794FBFFF","iconClass":"account_circle","isDefault":false, "isFinal":false, },
        {"name":"Validation (DONE)","deleted":false,"active":true,"color":"#553885FF","iconClass":"check_circle","isDefault":false, "isFinal":true, },
      ],
      "User Story B": [
        {"name":"Backlog","deleted":false,"active":true,"color":"#5259EFFF","iconClass":"assignment","isDefault":true, "isFinal":false, },
        {"name":"Design (IN PROGRESS)","deleted":false,"active":true,"color":"#FF4646FF","iconClass":"view_comfy","isDefault":false, "isFinal":false, },
        {"name":"Design (DONE)","deleted":false,"active":true,"color":"#FF4B4BFF","iconClass":"view_comfy","isDefault":false, "isFinal":false, },
        {"name":"Development (IN PROGRESS)","deleted":false,"active":true,"color":"#2F335DFF","iconClass":"build","isDefault":false, "isFinal":false, },
        {"name":"Development (DONE)","deleted":false,"active":true,"color":"#2F335DFF","iconClass":"build","isDefault":false, "isFinal":false, },
        {"name":"Testing (IN PROGRESS)","deleted":false,"active":true,"color":"#3D7938FF","iconClass":"bug_report","isDefault":false, "isFinal":false, },
        {"name":"Testing (DONE)","deleted":false,"active":true,"color":"#3D7938FF","iconClass":"bug_report","isDefault":false, "isFinal":false, },
        {"name":"Validation (IN PROGRESS)","deleted":false,"active":true,"color":"#5F3168FF","iconClass":"done","isDefault":false, "isFinal":false, },
        {"name":"Validation (DONE)","deleted":false,"active":true,"color":"#5F3168FF","iconClass":"done","isDefault":false, "isFinal":true, },
      ],
    

    },
		resolve: null,
	}),
	methods: {
		open(){
			if(this.types.length === 1){
				this.forType = this.types[0].value
			}
			this.show = true
			_.map(this.defaultStatuses, arr => arr)
				.flat()
				.forEach(s => s.selected = false)
			return new Promise(res => {
				this.resolve = res
			})
		},
		close(){
			this.show = false
			this.resolve(null)
		},
		save(){
			if((!this.forType && this.forType !== 0) || !this.types.some(x => x.value === this.forType)) {
				this.$root.error('Please select a work item type first')
				return
			}

			this.$openConfirmDialog({
				title: 'Warning',
				description: `Are you sure you want to create several new status?`,
			})
				.then(confirmed => {
					if(!confirmed) return
					this.show = false
					_.map(this.defaultStatuses, arr => arr)
						.flat()
						.forEach(s => s.workItemType = this.forType)
					this.resolve(structuredClone(this.defaultStatuses))
				})
		},
	},
}
</script>