<template>
  <div class="pa-5">
    <v-row>
      <v-col :sm="12">
        <v-data-table
          style="width: calc(100vw - 220px);"
          :headers="headers" :items="members"
          class="bordered rounded-lg new-back lighten2 overflow-hidden"
          disable-sort disable-pagination hide-default-footer
        >
          <template #[`item.blueTag`]="{ item }">
            <div class="d-flex align-center my-3" style="gap: 10px">
              <user-avatar :user-id="item.userId" :size="40"/>
              <div class="d-flex flex-column">
                <b>{{ item.userBlueTag }}</b>
                <span>{{ item.userFullName }}</span>
              </div>
            </div>
          </template>
          <template v-slot:[`item.active`]="{ item }">
            <div v-if="item.active">
              <v-icon color="grey">mdi-checkbox-marked</v-icon>
            </div>
            <div v-else>
              <v-icon color="grey">mdi-checkbox-blank-outline</v-icon>
            </div>
          </template>
          <template v-slot:[`item.canView`]="{ item }">
            <div v-if="item.canView">
              <v-icon color="grey">mdi-checkbox-marked</v-icon>
            </div>
            <div v-else>
              <v-icon color="grey">mdi-checkbox-blank-outline</v-icon>
            </div>
          </template>
          <template v-slot:[`item.canEdit`]="{ item }">
            <div v-if="item.canEdit">
              <v-icon color="grey">mdi-checkbox-marked</v-icon>
            </div>
            <div v-else>
              <v-icon color="grey">mdi-checkbox-blank-outline</v-icon>
            </div>
          </template>
          <template v-slot:[`item.canReport`]="{ item }">
            <div v-if="item.canReport">
              <v-icon color="grey">mdi-checkbox-marked</v-icon>
            </div>
            <div v-else>
              <v-icon color="grey">mdi-checkbox-blank-outline</v-icon>
            </div>
          </template>
          <template v-for="tab in projectTabs" #[getItemSlot(tab.tab)]="{ item }">
            <div :key="tab.tab">
              <div v-if="canView(item, tab)">
                <v-icon color="grey">mdi-checkbox-marked</v-icon>
              </div>
              <div v-else>
                <v-icon color="grey">mdi-checkbox-blank-outline</v-icon>
              </div>
            </div>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import _ from "lodash"

export default {
  props: ["id", 'projectName'],
  data: () => ({ 
    headers: [
      { text: "Bluetag", value: "blueTag", width: 300 },
      { text: "Active", value: "active", align: "center", width: 55 },
      { text: "Can View", value: "canView", align: "center", width: 65 },
      { text: "Can Edit", value: "canEdit", align: "center", width: 65 },
      { text: "Can Report", value: "canReport", align: "center", width: 65 },
    ],
    members: [],
    projectTabs: [],
  }),
  created() {
    this.$q.log(1, 17, 3, this.id, this.$parent.item.id)
    this.init()
  },
  methods: {
    init() {
      this.$http.get(`/api/project/${this.id}/permissions`)
        .then(res => {
          let members = res.data
          members.forEach(m => {
            m.canView = m.viewLevel >= 2
            m.canEdit = m.canEdit == 3
            m.canReport = m.canReport == 3
          })
          members = members.filter(m => m.canView || m.canEdit || m.canReport)
          this.members = members
        })
        .catch(err => {
          console.error(err)
          this.$root.error("Cannot obtain project members.")
        })
      this.$http
        .get(`/api/project/${this.id}`)
        .then(res => {
          const tabOrder = [2, 15, 17, 18, 19, 11, 3, 14, 20, 1, 4, 5, 6, 7, 8, 9, 10, 13, 12, 16]
          this.projectTabs = res.data.tabs
            .filter(x => (x.active || x.tab == 20) && x.tab != 0)
            .sort((a, b) => tabOrder.findIndex(x => x == a.tab) > tabOrder.findIndex(x => x == b.tab) ? 1 : -1)
          this.headers = this.headers.concat(
            this.projectTabs.map(x => ({
              text: x.tabName,
              value: 'tab-'+x.tab,
              align: 'center',
              width: 80
            }))
          )
        })
        .catch(err => {
          console.error(err)
          this.$root.error("Cannot get project.")
        })

    },
		getItemSlot(val){ return 'item.tab-' + val },
    canView(item, tab){
      return item.viewableTabs.some(x => x.tab == tab.tab && x.viewLevel == 3)
    },
  }
}
</script>

<style scoped lang="scss">
::v-deep {
  th, td{
    padding: 0 !important;
  }
  table > tbody > tr > td:nth-child(1),
  table > thead > tr > th:nth-child(1) {
    position: sticky !important;
    left: 0;
    z-index: 1;
    padding: 0 16px !important;
    background: var(--v-newDesignBackground-lighten2);
    border-right: 1px solid var(--v-lightgray-lighten);
  }
  table > thead > tr > th:nth-child(1) {
    z-index: 1;
    padding: 0 16px !important;
  }
}
</style> 









