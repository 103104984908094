<template>
  <v-container class="px-1">
    <div style="display: flex; justify-content: space-between" class="mb-2">
      <h3>
        <v-icon @click="$emit('toggleView')">chevron_left</v-icon>
        {{ project.name ? setMaxText(project.name, 23)  : 'Add a Task'}}
      </h3>
      <v-icon @click="$emit('close')">mdi-close</v-icon>
    </div>
      <v-row dense justify="center">
        <v-col class="col-login" :class="'mb-1'">
          <span>Type</span>
          <v-autocomplete
            class="mt-2"
            outlined
            dense
            v-model="task.type"
            ref="project" 
            hide-details
            :persistent-hint="true"
            :items="shownTypes" item-value="value" item-text="name"
            :rules="[v => (!!v || v === 0) || 'Required']"
          >
            <template v-slot:item="{ item }">
              <v-icon :color="item.color" class="mr-2">{{item.iconClass}}</v-icon>
              {{item.displayName}}
            </template>
            <template v-slot:selection="{ item }">
              <v-icon :color="item.color" class="mr-2">{{item.iconClass}}</v-icon>
              {{item.displayName}}
            </template>
          </v-autocomplete>
        </v-col>
      </v-row>
    <v-form v-model="form" ref="form">
      <v-row dense justify="center">
        <v-col class="col-login" :class="'mb-1'">
          <span>Status</span>
          <v-select 
            class="mt-2"
            outlined
            dense
            v-model="task.statusId"
            hide-details
            ref="project" 
            :persistent-hint="true"
            :items="statuses" item-value="id" item-text="name"
            :rules="[v => (!!v || v === 0) || 'Required']"
          >
            <template v-slot:item="{ item }">
              <v-chip class="cursor-pointer my-2" label :style="{ width: '100%' }" dark :color="item.color">
                <v-icon class="mr-2">{{item.iconClass}}</v-icon>
                {{item.name}}
              </v-chip>
            </template>
            <template v-slot:selection="{ item }">
              <v-icon :color="item.color" class="mr-2">{{item.iconClass}}</v-icon>
              {{item.name}}
            </template>
          </v-select>
        </v-col>
      </v-row>
      <v-row dense justify="center" v-if="task.type == 1">
        <v-col class="col-login" :class="'mb-1'">
          <span>Source</span>
          <v-select 
            class="mt-2"
            dense
            outlined
            hide-details
            v-model="task.defectOriginId"
            ref="project" 
            :persistent-hint="true"
            :items="$parent.config.defectsOrigins" item-value="id" item-text="name"
            :rules="[v => (!!v || v === 0) || 'Required']"
          >
          </v-select>
        </v-col>
      </v-row>
      <v-row dense justify="center">
        <v-col class="col-login" :class="'mb-1'">
          <span>Assigned To</span>
          <q-user-autocomplete
            :handleEsc="true"
            dense
            :handleSave="true"
            class="user-autocomplete"
            outlined
            v-model="task.assignedToId" :items="users" 
            item-value="id" item-text="blueTag" item-subtitle="fullName"
          >
          </q-user-autocomplete>
        </v-col>
      </v-row>
      <v-row dense justify="center">
        <v-col class="col-login" :class="'mb-1'">
          <span>Summary</span>
          <v-textarea
            outlined
            :rows="1"
            no-resize
            v-model="task.name"
            hide-details
            :rules="[v => !!v || 'Required']"
          ></v-textarea>
        </v-col>
      </v-row>
      <v-row dense justify="center">
        <v-col class="col-login" :class="'mb-1'">
          <span>Description</span>
          <v-textarea
            outlined
            :rows="2"
            no-resize
            v-model="task.description"
            hide-details
          ></v-textarea>
        </v-col>
      </v-row>
      <v-row dense justify="center" v-if="task.type == 1">
        <v-col class="col-login" :class="'mb-1'">
          <span>Steps to Reproduce</span>
          <v-textarea
            outlined
            :rows="2"
            no-resize
            v-model="task.stepsToReproduce"
            hide-details
          ></v-textarea>
        </v-col>
      </v-row>
      <v-row dense justify="center" v-if="task.type == 1">
        <v-col class="col-login" :class="'mb-1'">
          <span>Expected Result</span>
          <v-textarea
            outlined
            :rows="2"
            no-resize
            v-model="task.expectedResult"
            hide-details
          ></v-textarea>
        </v-col>
      </v-row>
      <v-row dense justify="center">
        <v-col class="col-login" :class="'mb-1'">
          <span>Priority</span>
          <v-select 
            class="mt-2"
            outlined
            dense
            hide-details
            v-model="task.priority"
            ref="project" 
            :persistent-hint="true"
            :items="priorities" item-value="value" item-text="displayName"
            :rules="[v => (!!v || v === 0) || 'Required']"
          >
            <template v-slot:item="{ item }">
              <v-img class="mr-2" style="flex: none" width="24px" height="24px" :src="require(`../assets/priority-icons/${item.value}.png`)" contain></v-img>
              {{item.displayName}}
            </template>
            <template v-slot:selection="{ item }">
              <v-img class="mr-1" style="flex: none" width="24px" height="24px" :src="require(`../assets/priority-icons/${item.value}.png`)" contain></v-img>
              {{ setMaxText(item.displayName, 4)}}
            </template>
          </v-select>
        </v-col>
        <v-col class="col-login" :class="'mb-1'">
          <span>Value</span>
          <v-select 
            class="mt-2"
            outlined
            dense
            hide-details
            v-model="task.value"
            ref="project" 
            :persistent-hint="true"
            :items="priorities" item-value="value" item-text="displayName"
            :rules="[v => (!!v || v === 0) || 'Required']"
          >
            <template v-slot:item="{ item }">
              <v-img class="mr-2" style="flex: none" width="24px" height="24px" :src="require(`../assets/priority-icons/${item.value}.png`)" contain></v-img>
              {{item.displayName}}
            </template>
            <template v-slot:selection="{ item }">
              <v-img class="mr-1" style="flex: none" width="24px" height="24px" :src="require(`../assets/priority-icons/${item.value}.png`)" contain></v-img>
              {{ setMaxText(item.displayName, 4)}}
            </template>
          </v-select>
        </v-col>
      </v-row>
      <v-row wrap class="files pa-2">
        <div v-for="(wiFile, ix) in files"  :key="ix">
          <v-tooltip  top >
            <template v-slot:activator="{ on }">
              <div class="relative-ctn2 img-container" >
                <v-img
                  v-on="on"
                  v-show="!isFile(wiFile)"
                  class="img ma-1 cursor-pointer small relative-ctn2"
                  :src="getImageUrl(wiFile)"
                >
                </v-img>
                <v-btn
                  color="red"
                  class="icon-cont"
                  @click.stop="removeFile('files', ix)" 
                  x-small
                  dark
                >
                  <v-icon small>delete</v-icon>
                </v-btn>
              </div>
              <div 
                v-on="on"
                class="relative-ctn2 file-container"
                v-show="isFile(wiFile)"
              >
                <v-btn
                  color="red"
                  class="icon-cont second-delete"
                  @click.stop="removeFile('files', ix)"
                  x-small
                  dark
                >
                  <v-icon small>delete</v-icon>
                </v-btn>
                <img
                  src="@/assets/pdf.png"
                  v-if="equalsFileType(wiFile.name, ['pdf'])"
                  class="img ma-1 pa-1 cursor-pointer small"
                >
                <img
                  src="@/assets/xls.png"
                  v-else-if="equalsFileType(wiFile.name, ['xls', 'xlsx'])"
                  class="img ma-1 pa-1 cursor-pointer small"
                >
                <img
                  src="@/assets/docx.png"
                  v-else-if="equalsFileType(wiFile.name, ['docx'])"
                  class="img ma-1 pa-1 cursor-pointer small"
                >
                <img
                  v-else
                  class="img ma-1 pa-1 cursor-pointer small"
                  src="@/assets/file.png"
                >
              </div>
            </template>
            <span>{{ wiFile.name }}</span>
          </v-tooltip>

        </div>
        <div @click="$refs.newImage.click()" class="img ma-1 add-file-button cursor-pointer" :class="files.length ? 'small' : 'small-full-width'">
          <v-icon color="primary" large>add</v-icon>
        </div>
      </v-row>
      <div class="cursor-pointer py-2 pl-1" :style="{color: 'var(--v-primary-base)'}" @click="showCompleteForm = !showCompleteForm">
        {{showCompleteForm ? 'Show Less' : 'Show More'}}
      </div>
      <div v-if="showCompleteForm">
        <v-row dense justify="center" >
          <v-col class="col-login" :class="'mb-1'" > 
            <span>Sprint</span>
            <v-select 
              outlined
              dense
              hide-details
              v-model="task.sprintId"
              ref="project" 
              :persistent-hint="true"
              :items="$parent.config.sprints" item-value="id" item-text="name"
            >
            </v-select>
          </v-col>
          <v-col class="col-login" :class="'mb-1'">
            <span>Color</span>
            <v-menu bottom offset-x :close-on-content-click="false">
              <template v-slot:activator="{ on }">
                <div class="color-filter cursor-pointer" v-on="on">
                  <div v-if="!task.boardColor">
                    <v-icon left>invert_colors</v-icon>
                    <span>Color</span>
                  </div>
                  <div class="colors-container" v-else>
                    <div
                      class="color cursor-pointer"
                      :style="{background: task.boardColor}"
                    >
                      <v-icon small :color="isBlackText(task.boardColor) ? 'white' : 'black'">close</v-icon>
                    </div>
                  </div>
                  <v-icon>chevron_right</v-icon>
                </div>
              </template>

              <color-picker
                v-model="task.boardColor"
                @update:color="colorChanged"
                :workItem="true"
              />
            </v-menu>
          </v-col>
        </v-row>
        <v-row dense justify="center" >
          <v-col class="col-login" :class="'mb-1'">
            <span>Tags</span>
            <v-autocomplete
              label="Tags"
              :items="tags"
              item-value="id"  item-text="name"
              v-model="currentTags" outlined multiple hide-details dense
              >
              <template v-slot:item="{ item, attrs }">
                <v-list-item-action>
                  <v-checkbox v-model="attrs.inputValue"></v-checkbox>
                </v-list-item-action>
                <v-list-item-content>
                  <v-list-item-title>
                    <v-chip class="cursor-pointer" dark small :color="item.id !== null ? item.color : 'white'"
                      :style="`color: ${isBlackText(item.color) ? 'white' : 'black'}`"
                    >
                      {{item.name}}
                    </v-chip>
                  </v-list-item-title>
                </v-list-item-content>
              </template>
              <template v-slot:selection="{ item, index, attrs, selected }">
                <v-chip 
                  small
                  v-bind="attrs"
                  :input-value="selected"
                  v-if="item === Object(item) && index === 0"
                  :color="item.id !== null ? item.color : ''"
                  :class="item.id !== null ? '' : 'transparent'"
                  :style="`color: ${item.id !== null ? isBlackText(item.color) ? 'white' : 'black' : ''}`"
                >
                  <span>{{ item.name }}</span>
                </v-chip>
                <span
                  v-if="index === 1"
                  class="grey--text text-caption"
                >
                  (+{{ currentTags.length - 1 }})
                </span>
              </template>
            </v-autocomplete>
          </v-col>
        </v-row>
        <div style="height: 38px"></div>
      </div>
      <input class="d-none" type="file" id="newImage" ref="newImage" v-on:change="addImage()"/>
      <v-row  dense justify="center" class="save-task-button">
        <v-col class="mt-1 py-2 " :style="{ background: 'var(--v-newDesignBackground-base)' }">
          <v-btn
            block
            large
            @click="save"
            color="primary"
          >
            <span style="color:white;">
              Create
            </span>
          </v-btn>
        </v-col>
      </v-row>
    </v-form>
    <work-item-details-dialog ref="workItemDetailsDialog" :taskShow="true" :isPersonal="false"></work-item-details-dialog>
  </v-container>
</template>

<script>
import workItemDetailsDialog from '../dialogs/WorkItemDetailsDialog.vue'
export default {
  components: {
    workItemDetailsDialog,
  },
  props: ['id', 'types', 'priorities', 'project'],
  data: function () {
   return {
    task: {
      projectId: null,
      type: 0,
      assignedToId: null, 
      priority: 4,
      value: 4,
    },
    form: false,
    currentUser: {},
    users: [],
    files: [],
    currentTags: [],
    showCompleteForm: false,
  }},
  computed: {
    shownTypes() {
      if (!this.$parent.config || !this.$parent.config?.workItemStatuses || !this.types) return []
      return this.types.filter(t =>
        this.$parent.config.workItemStatuses.some(s => s.workItemType == t.value)
      ).map(t => {
        let cur = this.$parent.config.boardConfigs.find(s => s.workItemType == t.value)
        t.displayName = (cur && cur.nickname) ? cur.nickname : t.displayName
        return t
      })
    },
    tags() {
      if (!this.$parent.config || !this.$parent.config.tags) return []
      let tags = _.orderBy(this.$parent.config.tags, ['sortIndex'])
      tags = tags.filter(t => t.types.includes(this.task.type))
      return tags
    },
    shownUsers() {
      let users = this.users || []
      this.$q.addLastItem(users, 'Unassigned', 'blueTag')
      return users
    },
    statuses() {
      if (!this.$parent.config || !this.$parent.config?.workItemStatuses) return []
      console.log(this.$parent.config.workItemStatuses, this.task.type)
      let statuses = this.$parent.config.workItemStatuses.filter(s => s.workItemType === this.task.type)
      return statuses
    },
  },
  mounted() {
    this.init()
  },
  methods: {
    resetTask() {
      this.$refs.form.reset()
      this.$nextTick(() => {
        this.task = {
          ...this.task,
          priority: 4, 
          value: 4, 
        }
        this.files = []
      })
    },
    openWorkItemDetailsDialog(id) {
      let defaults = {
        projectId: this.id,
        openCreateDialog: () => {}
      }

      this.$refs.workItemDetailsDialog.open(id, defaults)
      this.resetTask()
    },
    colorChanged(color){
      if (!this.task) {
        console.error("No context work item")
      }
      if (!color) {
        color = null
      } else {
        color = color.hexa || color
      }
      this.task.boardColor = color
    },
    addImage(file = this.$refs.newImage.files[0]){
      let isFile = this.isFile(file);
      if(!isFile)
          file.big = false;
      this.files.push(file)
    },
    isFile(file) {
      return !file.type.includes('image')
    },
    equalsFileType(filename, filetype){
      filetype = filetype.map(type => type.toLowerCase())
      if(!filename)
        return false;
      filename = filename.split('.');
      return filetype.indexOf(filename[filename.length - 1]) != -1
    },
    removeFile(from ,index){
      this[from] = this[from].filter((file, i) => i != index)
    },
    getFileNames: async function(files) {
      let promises = [];
      for(let file of files)
        promises.push(this.$http.get(`/api/workItem/${file.workItemId}/fileName/${file.id}`));

      let ress = await Promise.all(promises);
      for(let [i, file] of ress.entries())
        files[i].name = file.data;
      return files;
    },
    getImageUrl(image){
      if(!image.url)
        image.url = URL.createObjectURL(image)
      return image.url
    },
    init() {
      this.$blueSystem.getCurrentUser()
        .then(user => {
          this.currentUser = user
        })
      this.getProjectData()
    },
    getProjectData() {
      this.$http
        .get(`/api/project/${this.id}/members`)
        .then(res => {
          let members = res.data 
          members = members.map(member => {
            if(!member.user.blueTag)
              member.user.blueTag = member.user.fullName
            return member.user
          })
          this.users = members
          this.$q.addLastItem(this.users, 'Unassigned', 'blueTag')
        })
    },
    handleImageUpload(file = this.$refs.newImage.files[0]){
        let isFile = !file.type.includes('image')
        let formData = new FormData()
        formData.append('file', file)
        this.$http.post(`/api/workItem/${this.task.id}/${isFile ? 'file' : 'image'}`,
            formData,
            {
              headers: {
                'Content-Type': 'multipart/form-data'
              }
            }
        ).then(async () => {
          window.getApp.success(`${isFile ? 'file' : 'image'} uploaded.`)
        })
        .catch(() => {
          window.getApp.error(`Cannot upload ${isFile ? 'file' : 'image'}.`)
      })
    },
    save() {
      if(!this.form) {
        this.$refs.form.validate()
        return
      }
      let workItem = JSON.parse(JSON.stringify(this.task))
      workItem.reportedById = this.currentUser.id
      workItem.boardColor = workItem.boardColor ||'#FFFFFFFF'
      workItem.tags = []
      workItem.projectId = this.id
      if(workItem.type !== 1) {
        delete workItem.defectOriginId
        delete workItem.stepsToReproduce
        delete workItem.expectedResult
      }

      this.$q.save({
        api: "/api/workitem",
        data: workItem,
        successMsg: "Work item saved.",
        afterSuccessUrl: null,
        errorMsg: "Cannot save record.",
        afterErrorUrl: null,
      }).then(async (res) => {
        this.task = res.data
        for(let file of this.files){
          this.handleImageUpload(file)
        }
        await this.$q.save({
          api: `/api/workitem/${this.task.id}/tags`,
          data: this.currentTags,
          successMsg: null,
          afterSuccessUrl: null,
          errorMsg: "Cannot save tags.",
          afterErrorUrl: null,
        })
        this.openWorkItemDetailsDialog(this.task.id)
      })
    }
  },
}
</script>

<style lang="scss" scoped>
.col-login {
  padding-bottom: 0px !important;
  max-height: 120px !important;

  span {
    font-weight: 400;
    color:darkgrey;
    margin-bottom: 8px;
  }
}
.user-autocomplete {
  max-width: 280px;
}
.files .img {
    transition: all 200ms;
    width: 100%;
    max-height: 400px;
    min-height: 30px;
    border-radius: 3px;
    border: 1px solid #cccccc;
  }

  .files .img .delete-btn {
    display: none;
  }

  .files .img.big:hover .delete-btn {
    position: absolute;
    display: inline-block;
    top: 1px;
    right: 3px;
  }

  .files .img.small {
    max-width: 70px;
    max-height: 70px;
    height: 70px;
    border: 1px solid #cccccc;
    border-radius: 5px;
  }
  .files .img.small-full-width {
    max-height: 70px;
    height: 70px;
    border: 1px solid #cccccc;
    border-radius: 5px;
  }


  .relative-ctn2 {
  position: relative;
}
.add-file-button {
  margin: 4px;
  border-radius: 10px !important;
  border: none !important;
  background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='12' ry='12' stroke='%23167BD7FF' stroke-width='4' stroke-dasharray='8' stroke-dashoffset='0' stroke-linecap='square'/%3e%3c/svg%3e");
  display: flex;
  justify-content: center;
  box-sizing: border-box;
}
.first-delete {
  top: 15px;
}
.second-delete {
  top: 25px;
}
.file-container:hover img {
  opacity: 0.5 !important;
}
.icon-cont {
  position: absolute;
  z-index: 99;
  left: 20px;
  display: none;
}
.img-container:hover {
  & .icon-cont {
    display: block !important;
    top: 10px;
  }
}
.file-container:hover .icon-cont {
  display: block !important;
}
.full-width {
  width: 100% !important;
}
.color-filter{
  border: 1px solid #555;
  border-radius: 5px;
  padding: 7px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: var(--v-text-lighten2);

  .v-icon{
    color: var(--v-text-lighten2);
  }

  .colors-container{
    display: flex;
    flex-wrap: wrap;

    .color{
      display: inline-block;
      margin: 2px 4px 2px 0;
      border: 1px solid var(--v-text-lighten);
      width: 24px;
      height: 24px;
      border-radius: 50%;

      &:hover .v-icon{
        display: initial;
      }

      .v-icon{
        display: none;
        position: relative;
        left: 3px;
      }
    }
  }
}
.save-task-button {
  position: fixed;
  bottom: 5px;
  width: 281px
}
</style>