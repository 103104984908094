<template>
	<component
		v-bind="$attrs"
		v-on="$listeners"
		:item-text="itemText" :item-value="itemValue"
		:is="inputTag"
		:items="shownData"
		:value="value"
		:multiple="multiple"
		:class="{ 'hide-input': hideInput && hasValue}"
		v-clear-on-select="!inputSelect"
	>
		<template #item="props">
			<div :style="{color: props.item.filtered ? '' : 'grey'}">
				<template v-if="multiple">
					<v-icon v-if="value && value.includes(props.item[itemValue])" color="primary">mdi-checkbox-marked</v-icon>
					<v-icon v-else>mdi-checkbox-blank-outline</v-icon>
				</template>
				<slot name="item" v-bind="props">
					{{props.item[itemText]}}
				</slot>
			</div>
		</template>
		<template #selection="props">
			<slot name="selection" v-bind="props">
				{{props.item[itemText]}}
			</slot>
		</template>
	</component>
</template>

<script>
import { VSelect, VAutocomplete } from 'vuetify/lib'

export default {
	components: { VSelect, VAutocomplete },
	props: {
		value: {},
		items: { type: Array },
		itemText: { type: String, default: 'text' },
		itemValue: { type: String, default: 'value' },
		parentShownItems: { type: String|Array, default: 'shownData' },
		dataComparer: { type: Function, default: x => x.id },
		inputSelect: { type: Boolean, default: false },
		multiple: { type: Boolean, default: false },
		hideInput: { type: Boolean, default: false },
	},
	computed: {
		hasValue(){
			return this.value && (!this.multiple || this.value.length)
		},
		shownData(){
			let items = _.cloneDeep(this.items)

			items.forEach(item => {
				if(typeof this.parentShownItems == 'string')
					item.filtered = this.$parent[this.parentShownItems]
						.some(x => this.dataComparer(x, item[this.itemValue]))
				else if(Array.isArray(this.parentShownItems))
					item.filtered = this.parentShownItems
						.some(x => this.dataComparer(x, item[this.itemValue]))
				if(Array.isArray(this.value))
					item.filtered = item.filtered || this.value.includes(item[this.itemValue])
			})

			items = items.sort((a, b) => {
				if(b.filtered == a.filtered) {
					if(typeof a[this.itemText] == 'number')
						return a[this.itemText] < b[this.itemText] ? -1 : 1
					return a[this.itemText].localeCompare(b[this.itemText])
				}
				return b.filtered-a.filtered
			})

			return items
		},
		inputTag(){
			return this.inputSelect ? 'v-select' : 'v-autocomplete'
		},
	},
}
</script>

<style lang="scss" scoped>
.hide-input ::v-deep input{
	display: none;
}
</style>
