<template>
  <div>
    <v-layout>
      <v-navigation-drawer class="app-menu" dark
        permanent persistent
        :mini-variant="miniVariant"
        v-model="openDrawer"
        enable-resize-watcher
        fixed
        app
        :class="{ mini: miniVariant }"
        mini-variant-width="100"
        :style="{ 'border-right':  darkTheme ? '1px solid rgba(255, 255, 255, 0.12);' : '1px solid rgba(31, 31, 31, 0.12);' } "
      >
        <v-layout column fill-height :class="!$vuetify.theme.dark ? 'wrapper' : ''">
          <div
            class="top text-center cursor-pointer"
            :class="{ 'mb-8 mt-4': !isAnniversary, 'mb-4 mt-4': isAnniversary }"
            @click="$router.push('/profile/me')"
          >
            <img v-if="!isAnniversary" class="logo-surf" src="../../public/new-logo-surf.svg" />
            <div v-else class="d-flex aniversary mt-n3" @click="$router.push('/profile/me')">
              <img v-if="$vuetify.theme.isDark" class="logo-bp" contain src="../assets/aniversary.png"/>
              <img v-else class="logo-bp" contain src="../assets/aniversary_blue.png"/>
            </div>
            <span class="main-title ml-2" v-show="!miniVariant">Blue Surf</span>
          </div>
          <v-btn
            :style="{ backgroundColor: 'var(--v-coloredBack-base)' }"
            v-if="openDrawer" class="open-close-btn"
            :elevation="3" fab small
            @click.stop="miniVariant = !miniVariant"
          >
            <v-icon color="primary" v-html="miniVariant ? 'chevron_right' : 'chevron_left'"></v-icon>
          </v-btn>
          <v-list dense :key="listKey">
            <template v-for="(item, ix) in items">
              <v-menu :key="ix" :min-width="1" open-on-hover right offset-x :nudge-right="10" :nudge-bottom="2">
                <template v-slot:activator="{ on }">
                  <v-layout v-if="item.heading" :key="item.heading" row align-center>
                    <v-flex xs6>
                      <v-subheader v-if="item.heading">
                      {{ item.heading }} 
                      </v-subheader>
                    </v-flex>
                    <v-flex xs6 class="text-center">
                      <a href="#!" class="body-2 black--text">EDIT</a>
                    </v-flex>
                  </v-layout>
                  <v-list-group v-on="on"
                    v-else-if="item.children"
                    v-model="item.model"
                    :key="item.text" append-icon="" active-class="active-item">
                    <template v-slot:activator>
                      <v-list-item :class="!miniVariant ? 'pl-5' : ''">
                        <v-list-item-action v-if="item.icon" class="align-center">
                          <span class="menu-icon material-symbols-outlined" v-if="item.googleIcon" >{{item.icon}}</span>
                          <v-icon class="menu-icon" v-else>{{item.icon}}</v-icon>
                          <v-icon v-if="item.model && miniVariant" small class="mini-icon menu-icon">mdi-chevron-up</v-icon>
                          <v-icon v-else-if="miniVariant" small class="mini-icon menu-icon">mdi-chevron-down</v-icon>
                        </v-list-item-action>
                        <v-list-item-content>
                          <v-list-item-title>
                            {{ item.text }}
                            <v-icon class="menu-icon" v-if="item.model" small>mdi-chevron-up</v-icon>
                            <v-icon class="menu-icon" v-else small>mdi-chevron-down</v-icon>
                          </v-list-item-title>
                        </v-list-item-content>
                      </v-list-item>
                    </template>
                    <template v-for="(child, i) in item.children">
                      <v-menu :key="i" :min-width="1" open-on-hover right offset-x :nudge-right="10" :nudge-bottom="2">
                        <template v-slot:activator="{ on: onChild }">
                          <v-list-item  active-class="active-item" class="child-icon" :to="child.to" :href="child.url" v-on="onChild">
                            <v-list-item-action class="align-center">
                              <v-badge
                                :value="!!pending[child.pendingKey]"
                                class="ma-0 small"
                                dark right overlap
                                color="pink" :content="pending[child.pendingKey]"
                                offset-x="7" offset-y="25"
                              >
                                <span :style="{ 'fontSize': '18px' }" class="menu-icon material-symbols-outlined" v-if="child.googleIcon" >{{ child.icon}}</span>
                                <v-icon class="menu-icon" v-else small>{{ child.icon }}</v-icon>
                              </v-badge>
                              <span class="mini-title" v-if="miniVariant">{{child.text}}</span>
                            </v-list-item-action>
                            <v-list-item-content>
                            <v-list-item-title :style="{ 'fontSize': '15px' }">
                              {{ child.text }}
                            </v-list-item-title>
                            </v-list-item-content>
                          </v-list-item>
                        </template>
                        <v-btn small v-if="miniVariant" class="text-capitalize">{{child.text}}</v-btn>
                      </v-menu>
                    </template>
                  </v-list-group>
                  <v-list-item
                    :class="!miniVariant ? 'pl-10' : ''"
                    v-else v-on="on" :key="item.text"
                    active-class="active-item" :to="item.to"
                    :href="item.url"
                  >
                    <v-list-item-action class="align-center">
                      <div v-if="item.to == '/dailyReport'">
                        <v-badge class="ma-0" dark right :value="dueReports" color="pink" overlap content="!"
                          offset-x="12" 
                          offset-y="32">
                          <v-icon class="menu-icon" :large="false">{{ item.icon }}</v-icon>
                        </v-badge>
                      </div>
                      <div v-else-if="item.to == '/recess/requests'" style="height: 24px">
                        <v-badge
                          :value="!!pending.recessRequests"
                          :content="pending.recessRequests"
                          dark right overlap
                          class="ma-0" color="pink"
                          offset-x="12" offset-y="32"
                        >
                          <span class="menu-icon material-symbols-outlined">{{ item.icon }}</span>
                        </v-badge>
                      </div>
                      <span class="menu-icon material-symbols-outlined" v-else-if="item.googleIcon">{{ item.icon }}</span>
                      <v-icon class="menu-icon" v-else>{{ item.icon }}</v-icon>
                      <span class="mini-title" v-if="miniVariant">{{item.text}}</span>
                    </v-list-item-action>
                    <v-list-item-content>
                      <v-list-item-title :style="{ opacity: '0.6' }">
                        {{ item.text }}
                      </v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                </template>
                <v-btn v-if="miniVariant" class="text-capitalize">{{item.text}}</v-btn>
              </v-menu>
            </template>
          </v-list>
          <v-spacer></v-spacer>
          <div class="version-info text-center mb-2">
            <v-menu
              content-class="change-menu"
              offset-x
              open-on-hover
            >
              <template v-slot:activator="{ on, attrs }">
                <v-btn small text class="whats-new" v-on="on" v-bind="attrs" @click="openInfographic">
                  <v-badge
                    class="ma-0" dark right color="red" overlap dot
                    offset-x="0" offset-y="3" :value="showInfographic"
                  >
                    What's new
                  </v-badge>
                </v-btn>
                <br>
              </template>
          
              <div class="change-log-container elevation-5">
                <div class="change-log">
                  <h2 style="margin-top: -5px">What's new</h2>
                  <template v-for="(log, ix) in changeLog">
                    <b :key="ix+'b'" class="">v{{log.version}} ({{getMoment(log.date)}})</b>
                    <ul :key="ix+'ul'">
                      <li v-for="(change, jx) in log.changes" :key="jx">
                        <b class="primary--text">{{change.title}}</b>
                        <p style="text-align: justify;" v-html="change.description"></p>
                      </li>
                    </ul>
                  </template>
                </div>
              </div>
            </v-menu>
            <span>
              v{{version}}
            </span>
          </div>
        </v-layout>
        <Infographic ref="infographic" />
      </v-navigation-drawer>
      <v-app-bar
        class="nav-bar px-3"
        elevation="0"
        height="75"
        app
        hide-on-scroll
      >
        <div class="header-container">
          <div>
            <h2>
              <v-btn v-if="$store.state.showPreviousRedirect" @click="goBack" icon class="ml-n5">
                <v-icon large>mdi-chevron-left</v-icon>
              </v-btn>
              {{$store.state.mainTitle}}
            </h2>
          </div>
          <div class="d-flex align-center">
            <v-menu v-if="isExternal && !isInProjectList" z-index="200">
              <template #activator="{ on }">
                <v-btn v-on="on" class="mr-3" text>
                  {{ selectedProject.name }}
                  <v-icon right>mdi-chevron-down</v-icon>
                </v-btn>
              </template>
              <v-list dense>
                <v-list-item
                  v-for="project in projects.filter(x => x.smallCode != getContextProjectId())" :key="project.id"
                  @click="selectProject(project)"
                  class="actions-item"
                >
                  <v-list-item-content>{{ project.name }}</v-list-item-content>
                </v-list-item>
              </v-list>
            </v-menu>
            <notifications-list/>
            <v-tooltip left>
                <template v-slot:activator="{ on: onTooltip }">
                  <v-menu
                    bottom
                    offset-y
                    :close-on-content-click="false"
                    style="z-index: 201"
                  >
                    <template v-slot:activator="{ on: onMenu, attrs }">
                      <v-avatar
                        class="avatar cursor-pointer"
                        color="grey" 
                        v-bind="attrs"
                        v-on="{ ...onMenu, ...onTooltip }"
                        :size="40"
                      >
                        <v-img :src="profilePicSrc" @error="profilePicError = true"></v-img>
                      </v-avatar>
                    </template>
                    <v-list dense class="dropdown-menu">
                      <v-list-item  class="actions-item">
                        <v-list-item-action class="mr-3">
                          <v-avatar
                            class="avatar cursor-pointer child"
                            color="grey"
                            :size="40"
                          >
                            <v-img :src="profilePicSrc" @error="profilePicError = true"></v-img>
                          </v-avatar>
                        </v-list-item-action>
                        <v-list-item-content>
                          <v-list-item-title style="font-size: 1.0rem;">
                            {{ user.blueTag || user.fullName || ''}}
                          </v-list-item-title>
                          <v-list-item-subtitle>
                            {{ user.fullName || '' }}
                          </v-list-item-subtitle>
                        </v-list-item-content>
                      </v-list-item>
                      <v-list-item @click="openProfile" class="actions-item">
                        <v-list-item-action  class="mr-3 ml-n1">
                          <span class="material-symbols-outlined">
                            person
                          </span>
                        </v-list-item-action>
                        <v-list-item-content>
                          <v-list-item-title style="font-weight: 400; font-size: 0.9125rem;">
                            My profile
                          </v-list-item-title>
                        </v-list-item-content>
                      </v-list-item>
                      <v-list-item @click="logout" class="actions-item">
                        <v-list-item-action class="mr-3">
                          <span class="material-symbols-outlined">
                            logout
                          </span>
                        </v-list-item-action>
                        <v-list-item-content >
                          <v-list-item-title style="font-weight: 400; font-size: 0.9125rem;">
                            Logout
                          </v-list-item-title>
                        </v-list-item-content>
                      </v-list-item>
                      <div class="px-3 my-3">
                        <v-divider></v-divider>
                      </div>
                      <v-list-item class="actions-item">
                        <v-list-item-content>
                          <div style="display: flex; align-items: center; font-size: 0.9rem;" class="mr-3">
                            <span class="material-symbols-outlined mr-2">
                              {{ $vuetify.theme.dark ? 'dark_mode' : 'light_mode' }}
                            </span>
                            {{`Dark mode: ${darkTheme ? 'On' : 'Off'}`}}
                          </div>
                        </v-list-item-content>
                        <v-list-item-action>
                          <div>
                            <label class="switch">
                              <input :checked="darkTheme" type="checkbox" @click="$vuetify.theme.dark = !$vuetify.theme.dark">
                              <span class="slider round "></span>
                            </label>
                          </div>
                        </v-list-item-action>
                      </v-list-item>
                    </v-list>
                  </v-menu>
                </template>
                <span>Account Options</span>
              </v-tooltip>
          </div>
        </div>
      </v-app-bar>
      <aside-bar
        v-model="showTaskMenu"
        :id="user.id"
        :isExternal="isExternal"
      />
    </v-layout>
  </div>
</template>

<script>
import publicChangeLog from '../json/publicChangeLog.json'
import Infographic from '../dialogs/Infographic'
import moment from 'moment'
import Highcharts from "highcharts";
import AsideBar from "./AsideBar"
import NotificationsList from './NotificationsList';
import { mapState } from 'vuex';

export default {
  components: {
    AsideBar,
    Infographic,
    NotificationsList,
  },
  props: {
    drawer: { default: true },
    // newMessages: Number,
  },
  watch: {
    '$vuetify.theme.dark'(){
      this.darkTheme = this.$vuetify.theme.dark
      if(this.darkTheme){
        Highcharts.setOptions({
          chart: { backgroundColor: '#282828', },
          title: { style: { color: '#fff', } },
          subtitle: { style: { color: '#ccc', } },
          legend: {
            itemStyle: { color: 'white' },
            itemHoverStyle:{ color: 'gray' }
          },
          labels: { style: { color: '#fff' } },
          yAxis: {
            labels: { style: { color: '#fff', } },
            title: { style: { color: '#fff', } },
          },
          xAxis: {
            labels: { style: { color: '#fff', } },
            title: { style: { color: '#fff', } },
          },
        });
      }
      else{
        Highcharts.setOptions({
          chart: { backgroundColor: '#fff', },
          title: { style: { color: '#000', } },
          subtitle: { style: { color: '#666666', } },
          legend: {
            itemStyle: { color: 'black' },
            itemHoverStyle:{ color: 'gray' }
          },
          labels: { style: { color: '#000' } },
          yAxis: {
            labels: { style: { color: '#000', } },
            title: { style: { color: '#000', } },
          },
          xAxis: {
            labels: { style: { color: '#000', } },
            title: { style: { color: '#000', } },
          },
        });
      }
    },
    $route(current, prev){
      if(current.path != prev.path)
        this.fetchPendingData()
    },
    miniVariant(){
      const r = document.querySelector(':root')
      r.style.setProperty('--v-menu-width', this.miniVariant ? '92px' : '256px')
    },
  },
  mounted() {
    const r = document.querySelector(':root')
    r.style.setProperty('--v-menu-width', this.miniVariant ? '92px' : '256px')
    this.init()
    this.fetchPendingData()
  },
  data() {
    return {
      showTaskMenu: true,
      menuElementActive: null,
      darkTheme: false,
      listKey: 0,
      version: "0.0.0",
      openDrawer: this.drawer,
      miniVariant: true,
      items: [],
      changeLog: publicChangeLog.changeLog,
      dueReports: 0,
      showInfographic: false,
      lastScrollPosition: 0,
      profilePicError: false,
      profilePicFailover: 'https://cdn.vuetifyjs.com/images/parallax/material2.jpg',

      permissionGroups: {
        admin: {
          icon: "mdi-calculator",
          text: "Admin Surf",
          model: false,
          children: [
            { permission: 23, icon: "mdi-chart-timeline", text: "Board", to: "/admin/board" },
            { permission: 25, icon: "mdi-account-multiple-check", text: "Assignments", to: "/admin/assignment", pendingKey: 'assignmentSuggestions' },
            { permission: 24, icon: "mdi-file-chart-check-outline", text: "Billables", to: "/admin/billable", pendingKey: 'billables' },
            { permission: 26, icon: "mdi-format-list-text", text: "Billable Items", to: "/admin/billableItem", pendingKey: 'billableItems' },
            { permission: 27, icon: "mdi-account-tie", text: "Customers", to: "/admin/customer" },
            { permission: 28, icon: "mdi-script-text-outline", text: "Invoices", to: "/admin/invoice" },
            { permission: 29, icon: "mdi-cash-multiple", text: "Payments", to: "/admin/payment" },
            { permission: 39, icon: "inventory", text: "Suppliers", to: "/admin/supplier", googleIcon: true },
            { permission: 40, icon: "mdi-script-outline", text: "Supplier Invoices", to: "/admin/supplierInvoice" },
            { permission: 30, icon: "monetization_on", text: "Revenue", to: "/admin/revenue", googleIcon: true },
            { permission: 47, icon: "receipt_long", text: "Billing Report", to: "/admin/billing", googleIcon: true },
            { permission: 45, icon: "mdi-progress-check", text: "Validation Report", to: "/admin/validation" },
            { permission: 42, icon: "mdi-folder-wrench-outline", text: "Configuration", to: "/admin/config" },
          ] 
        },
        people: {
          icon: "groups",
          text: "People",
          model: false,
          googleIcon: true,
          children: [
            { permission: 4,  icon: "contacts", text: "Directory", to: "/profile/directory", googleIcon: true, },
            { permission: 19,  icon: "pin_drop", text: "People Map", to: "/map", googleIcon: true, },
          ],
        },
        report: {
          icon: "donut_large",
          text: "Reports",
          model: false,
          googleIcon: true,
          children: [
            { permission: 18, icon: "workspace_premium", text: "Badges", to: "/report/badges", googleIcon: true, },
            { permission: 5,  icon: "timer", text: "Daily Hours", to: "/report/dailyHours", googleIcon: true, },
            { permission: 6,  icon: "show_chart", text: "On-time Reporting", to: "/report/onTimeReporting" },
            { permission: 7,  icon: "pie_chart", text: "Activity Log", to: "/report/activityLog" },
            { permission: 31, icon: "mdi-script-text-outline", text: "Log Report", to: "/report/logReport" },
            { permission: 34, icon: "mdi-account-group-outline", text: "Team Members", to: "/report/teamMemberReport" },
            { permission: 35, icon: "travel_explore", text: "Vacations", to: "/report/recessDaysReport", googleIcon: true, },
            { permission: 41,  icon: "mdi-application-edit-outline", text: "One Pager", to: "/report/onePager" },
            { permission: 43,  icon: "mdi-shield-lock-open-outline", text: "User Permissions", to: "/report/permissions" },
            { permission: 44,  icon: "mdi-timetable", text: "Executed Hours", to: "/report/executedHours" },
            { permission: 46,  icon: "mdi-map-clock-outline", text: "RPEH Report", to: "/report/RPEHReport" },
          ]
        },
        settings: {
          icon: "settings",
          text: "Configuration",
          model: false,
          children: [
            { permission: 17, icon: "workspace_premium", text: "Badges", to: "/badges", googleIcon: true, },
            { permission: 13, icon: "view_module", text: "Categories", to: "/category" },
            { permission: 14, icon: "person", text: "People", to: "/user" },
            { permission: 15, icon: "groups", text: "User Groups", to: "/userGroup", googleIcon: true, },
            { permission: 32, icon: "flight_takeoff", text: "Recess", to: "/recess/config", googleIcon: true, },
            { permission: 38, icon: "interests", text: "Project Types", to: "/projectTypes", googleIcon: true, },
          ] 
        },
      },
      projects: [],
      user: {},
      permissions: [],
      pending: {
        recessRequests: 0,
        billableItems: 0,
      },
      newMessages: 0,
    };
  },
  computed: {
    ...mapState(['isAnniversary']),
    profilePicSrc() {
      if (this.profilePicError) {
        return this.profilePicFailover
      }
      let src = `api/user/current/profilePicture`;
      if(!this.$http.defaults.baseURL.endsWith('/'))
        src = '/' + src;
      return this.$http.defaults.baseURL + src;
    },
    isExternal(){
      return this.user.type === 1
    },
    selectedProject(){
      let contextProjectId = this.getContextProjectId()
      return this.projects
        .find(x => x.smallCode == contextProjectId) || {}
    },
    isInProjectList(){
      return this.$route.path == '/external/project'
    },
  },
  methods: {
    goBack(){
      let redirect = this.$route.query.redirect
      if(redirect)
        this.$router.push(redirect)
      else this.$router.go(-1)
    },
    init() {
      this.setUserPreferences("AppMenu", "miniVariant")
      this.setUserPreferences("AppMenu", "darkTheme")
      
      this.$vuetify.theme.dark = this.darkTheme

      window.getApp.$on("APP_DRAWER_TOGGLED", () => {
        this.openDrawer = !this.openDrawer;
      });
      this.$blueSystem.getVersion()
        .then(version => this.version = version);
        
      const permissionPr = this.$security.getInfo()
        .then(res => {
          this.permissions = res.permissions
        })

      this.$http.get('api/user/current/showInfographic', { headers: { hideLoading: true } })
        .then(res => {
          if(res.data.show){
            this.showInfographic = res.data.show
            this.openInfographic()
          }
        })

      const userPr = this.getUser()
        .then(() => {
          if(this.isExternal){
            this.$http
              .get("/api/user/current/project")
              .then((res) => {
                this.projects = res.data
                let contextProjectId = this.getContextProjectId()
                if(contextProjectId && !this.projects.some(x => x.smallCode == contextProjectId)){
                  this.$q.setCookie('contextProjectId', '')
                  this.$router.push('/external/project')
                }
              })
              .catch((res) => {
                window.getApp.error("Cannot get records.");
                console.error(res);
              })
          }
        })
      
        Promise.all([permissionPr, userPr])
          .then(this.createMenu)
    },
    getUser(){
      return this.$blueSystem.getCurrentUser()
        .then(user => {
          this.user = user
        })
    },
    openProfile() {
      this.$router.push('/profile/me');
    },
    getMoment: (date) => new moment(date).format('MMM Do, YYYY'),
    logout() {
      this.$vuetifyConfirmDialog.open("Logout", "Are you sure you want logout?", "Cancel", "Confirm")
        .then(confirmed => {
          if (!confirmed) return
          
          this.$http.get('/api/logout')
            .then((result) => {
              this.$vuetify.theme.dark = false
              this.$q.changeHeaderTitle('Blue Surf')
              this.$router.push('/login')
              this.$blueSystem.clearCurrentUser()
            })
            .catch((error) => {
              window.getApp.error('cannot log out')
            })
        })
    },
    openInfographic(){
      this.$refs.infographic.open(this.changeLog[0])
        .then(res => {
          this.showInfographic = res
        })
    },
    applyGroup(group, menu){
      const currentRoute = this.$router.currentRoute.path
      group.model = group.children.some(c => currentRoute.startsWith(c.to))
      group.children = group.children.filter( child => child.permission == -1 || this.permissions.find(p => p.type == child.permission)?.accessLevel >= 2 )
      if(group.children.length)
        menu.push(group)
    },
    async createMenu() {
      if(this.isExternal){
        let contextProjectId = this.getContextProjectId()
        if(!contextProjectId) {
          this.items = [{ permission: -1,  icon: "grid_view", text: "Projects", to: "/external/project", googleIcon: true },]
          return
        }
        const res = await this.$http.get(`api/project/${contextProjectId}/viewableTabs`)
        const viewableTabs = res.data
        const items = [
          { permission: -2,  icon: "mdi-scale-balance", text: "Account Balance", to: "/external/accountBalance" },
          { permission: -1,  icon: "grid_view", text: "Projects", to: "/external/project", googleIcon: true },

          { permission: 2,  icon: "checklist_rtl", text: "Work Items", to: "/external/workItems", googleIcon: true },
          { permission: 15,  icon: "mdi-chart-bar", text: "Dashboard", to: "/external/dashboard" },
          { permission: 18,  icon: "mdi-file-document-multiple-outline", text: "Account", to: "/external/account" },
          { permission: 19,  icon: "monitor_heart", text: "Healthchecks", to: "/external/healthchecks", googleIcon: true },
          { permission: 11,  icon: "mdi-clipboard-pulse-outline", text: "Risks", to: "/external/riskManagement" },
          { permission: 3,  icon: "mdi-link-variant", text: "Links", to: "/external/links" },
          { permission: 14,  icon: "mdi-account-tie", text: "Team Members", to: "/external/members" },
          { permission: 10,  icon: "mdi-file-chart-outline", text: "Activity Log", to: "/external/activityLog" },
          { permission: 16,  icon: "mdi-chart-timeline-variant-shimmer", text: "Overview", to: "/external/overview" },
        ]
        this.items = items.filter(x =>
          x.permission == -1 ||
          (x.permission === -2 && this.user.showAccountBalance) ||
          viewableTabs.some(tab => tab.tab == x.permission && tab.viewLevel == 3)
        )

        if(!this.items.some(x => x.to == this.$route.path)){
          this.$router.push('/external/project')
        }

        return
      }

      this.dueReports = window.getApp.dueReports || 0
      
      if(this.dueReports >= 3){
        this.items = [ { permission: 3,  icon: "calendar_today", text: "Daily Report", to: "/dailyReport" } ]
        this.listKey++
        return
      }

      let menu = [
        { permission: -1, icon: "view_kanban", text: "My Kanban", to: "/myKanban", googleIcon: true },
        { permission: 0,  icon: "checklist_rtl", text: "Work Items", to: "/workItem", googleIcon: true },
        { permission: 1,  icon: "grid_view", text: "Projects", to: "/project", googleIcon: true },
        { permission: 2,  icon: "monitor_heart", text: "Risks", to: "/risks", googleIcon: true },
        { permission: 3,  icon: "today", text: "Daily Report", to: "/dailyReport", googleIcon: true },
        { permission: 36, icon: "luggage", text: "Recess Requests", to: "/recess/requests", googleIcon: true },
      ];
      menu = menu.filter( item => item.permission == -1 || this.permissions.find(p => p.type == item.permission)?.accessLevel >= 2 )

      for(const group in this.permissionGroups){
        this.applyGroup(this.permissionGroups[group], menu)
      }
      
      this.items = menu
      this.$nextTick(() => this.listKey++)
    },
    selectProject(project){
			this.$q.setCookie("contextProjectId", project.smallCode)
      localStorage.setItem('currentProjectImage', this.getBackendUrl(`api/project/${project.smallCode}/image`))
      this.$root.updateAll()
    },
    fetchPendingData(){
      this.$http.get('/api/admin/pending', { headers: { hideLoading: true } })
        .then(res => {
          this.pending = res.data
        })
    },
    getContextProjectId() {
      return this.$q.getCookie('contextProjectId')
    },
  },
};
</script> 

<style scoped lang="scss">
.avatar:hover:not(.child) {
  opacity: 0.6;
}
.wrapper {
  background: rgba(22, 123, 215, 0.02);
}
.avatar:hover:not(.child) {
  opacity: 0.6;
}
.wrapper {
  background: rgba(22, 123, 215, 0.02);
}
.avatar:hover:not(.child) {
  opacity: 0.6;
}
.wrapper {
  background: rgba(22, 123, 215, 0.02);
}
.header-container {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.nav-bar {
  background: var(--v-navBar-base) !important;
}
.switch {
  position: relative;
  display: inline-block;
  width: 38px;
  height: 24px;
}

.switch input { 
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: .4s;
  transition: .4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 18px;
  width: 18px;
  left: 4px;
  bottom: 3px;
  background-color: white;
  -webkit-transition: .4s;
  transition: .4s;
}

input:checked + .slider {
  background-color: var(--v-primary-base);
}

input:focus + .slider {
  box-shadow: 0 0 1px var(--v-primary-base);
}

input:checked + .slider:before {
  -webkit-transform: translateX(13px);
  -ms-transform: translateX(13px);
  transform: translateX(13px);
}

.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}

  .v-list-item:not(.actions-item) {
    min-height: 32px;
    opacity: 0.6;
  }
  .menu-icon {
    color: var(--v-newDesignText-base);
    opacity: 0.6;
  }
  .open-close-btn {
    right: -19px;
    bottom: 50px;
    z-index: 1;
    position: absolute;
  }
  .open-close-icon {
    margin-left: 22px;
  }
  .open-close-icon .v-icon{
    margin: -17px;
  }
  .app-menu {
    z-index: 121;
    overflow: inherit;
    background: var(--v-newDesignBackground-base);
    border-right: 1px solid var(--v-borders-base);
  }
  .v-navigation-drawer .v-list {
    background: none;
  }
  .app-menu:not(.mini) ::v-deep .v-list-item {
    padding-left: 24px;
    padding-right: 24px;
  }
  .app-menu  ::v-deep .v-list-item__title {
    color: var(--v-newDesignText-base) !important;
    font-size: 17px;
    opacity: 0.6;
  }
  .app-menu:not(.mini)  ::v-deep .v-list-group__header .v-list-item {
    padding-left: 0;
    padding-right: 0;
  }
  .app-menu:not(.mini)  ::v-deep .v-list-group__items .v-list-item {
    padding-left: 60px;
  }
  .app-menu:not(.mini) ::v-deep {
    .v-list-item__icon,
    .v-list-item__action {
      margin-right: 12px !important;
    }
  }
  .v-list-item__icon {
      margin-right: 12px !important;
  }
  .v-list.v-list--dense .v-list-item__action {
    margin-top: 8px;
    margin-bottom: 8px;
  }
  .app-menu ::v-deep .v-list-item.active-item {
    opacity: 1 !important;
  }
  .app-menu ::v-deep .v-list-item.active-item .menu-icon {
    color: var(--v-newDesignPrimary-base);
    opacity: 1 !important;
  }
  .app-menu ::v-deep .v-list-item:not(.child-icon).active-item::before {
    content: "";
    position: absolute;
    
    background-color: var(--v-newDesignPrimary-base) !important;
    bottom: -50px;
    height: 100%;
    width: 10px;
    border-top-right-radius: 20px;
    border-bottom-right-radius: 20px;
    opacity: 1 !important;
  }
  .app-menu ::v-deep .v-list-item.active-item::before {
    opacity: 0 !important;
  }
  .app-menu ::v-deep .v-list-item.active-item .v-list-item__title {
    color: var(--v-text-base) !important;
  }

  ::v-deep .small .v-badge__badge {
    height: 16px !important;
    width: 16px !important;
    min-width: 16px !important;
    font-size: .7em;
    padding: 2px 5px;
  }

  .top {
    padding-top: 8px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .mini .top {
    padding-top: 0px;
  }
  .whats-new{
    text-transform: none;
    color: var(--v-text-base);
    width: 80px;
  }
  .logo-surf {
    cursor: pointer;
    transition: all 250ms;
  }
  .mini .logo-surf {
    margin-top: 10px;
  }

  .aniversary {
    display: inline-flex;
    align-items: center;
    overflow: hidden;
    .logo-bp{
      height: 85px;
      cursor: pointer;
    }
  }
  .mini{
    .aniversary{ height: 90px; }
    .logo-bp {
      margin-top: 10px;
      width: 65px;
      right: 0;
    }
    .blue-logo{
      top: -102px;
      left: 0;
    }
  }

  .main-title {

    font-size: 30px;
    line-height: 18px;
    font-weight: 500;
    transition: font-size 500s;
  }
  .version-info {
    margin-top: 20px;
    color: var(--v-text-base);
    font-size: 13px;
  }
  .change-menu{
    background: transparent;
    box-shadow: none;
    overflow: visible;
    padding: 10px;
    padding-left: 20px;
    padding-top: 0;
    .change-log-container{
      max-height: 80vh;
      max-width: 500px;
      overflow-y: scroll;
      border-radius: 10px;
      .change-log{
        margin: 0;
        background: var(--v-background-lighten);
        padding: 15px;
        ul{
          margin-bottom: 30px;
          list-style: none;
          padding: 0;
        }
        li{
          font-size: .9em;
          line-height: 1.05em;
        }
      }
    }
    .change-log-container::-webkit-scrollbar {
      width: 8px;
      height: 5px;
      background: #F9FCFF;
    }
    .change-log-container::-webkit-scrollbar-thumb{
      background: var(--v-background-lighten2)
    }
  }
  .mini-title {
    overflow: hidden;
    text-align: center;
    text-overflow: ellipsis;
    width: 65px;
    font-size: 10px;
    white-space: normal;
    height: 0;
    transition: height 200ms;
  }
  .mini-icon{
    position: absolute;
    right: 0; top: 12px;
  }
  .actions-item {
    opacity: 1 !important;
  }
  .task-menu {
    background: var(--v-newDesignBackground-base);
    height: 100vh;
    z-index: 200;
    .grid{
      display: grid;
      grid-template-columns: 60px auto;
      height: 100%;
    }
  }
  .dropdown-menu {
    background: var(--v-newDesignBackground-base);
  }
</style>