<template>
	<div class="fill-height new-back pa-5 pb-10">

		<filter-search-input v-model="filters.search" :applied-filters="appliedFilters" outlined dense>
			<q-user-autocomplete
				v-model="filters.user"
				@change="fetchMembers"
				label="BlueTag"
				:items="users"
				save-on-change
				hide-details dense outlined
				clearable :confirmable="false"
			/>
			<v-autocomplete
				v-model="filters.project"
				@change="fetchMembers"
				label="Project"
				:items="projects.filter(x => !filters.statuses.length || filters.statuses.includes(x.status))"
				hide-details dense outlined clearable
				item-text="name" item-value="id"
			/>
			<v-text-field
				v-model="filters.role"
				@change="fetchMembers"
				label="Role"
				hide-details dense outlined clearable
			/>
			<v-select
				v-model="filters.department"
				@change="fetchMembers"
				label="Department"
				:items="departments"
				hide-details dense outlined clearable
				item-text="name" item-value="id"
			/>
			<v-select
				v-model="filters.types" :items="types"
				label="Type" item-value="id" item-text="name"
				outlined hide-details clearable multiple dense
			>
				<template #selection="{ item }">
					<v-chip class="ma-1">
						{{ item.name }}
					</v-chip>
				</template>
			</v-select>
			<v-select
				v-model="filters.statuses" :items="statuses"
				label="Status" item-value="id" item-text="name"
				outlined hide-details clearable multiple dense
			/>
		</filter-search-input>

		<data-table
			:headers="headers"
			:items="shownMembers"
		>
			<template #[`item.blueTag`]="{ item }">
				<div class="d-flex align-center my-1" style="gap: 10px">
					<user-avatar
						:user-id="item.userId"
						:size="32" hide-loading
					/>
					<b>{{ item.blueTag }}</b>
				</div>
			</template>
		</data-table>
	</div>
</template>

<script>
export default {
	data: () => ({
		members: [],
		headers: [
			{ text: 'BlueTag', value: 'blueTag' },
			{ text: 'Name', value: 'fullName' },
			{ text: 'Project', value: 'projectName' },
			{ text: 'Role', value: 'role' },
		],

		users: [],
		projects: [],
		departments: [],
		types: [],
		statuses: [],
		filters: {
			user: '',
			project: '',
			role: '',
			department: null,
			types: [],
			statuses: [],
		},
	}),
	mounted(){
		this.setUserPreferences('teamMemberReport', 'filters')
    this.$q.log(7, 49, 3, 'NA')
		this.fetchMembers()
		this.$http.get('api/user')
			.then(res => {
				this.users = res.data
			})
		this.$http.get('api/project?all=true')
			.then(res => {
				this.projects = res.data
			})
		this.$http.get('api/enums/departments')
			.then(res => {
				this.departments = res.data
			})
		this.$http.get('api/project/types')
			.then(res => {
				this.types = res.data
			})
			.catch(res => {
				window.getApp.error("Cannot obtain project types.")
				console.error(res)
			})
		this.$http
			.get(`/api/Enums/ProjectStatus`)
			.then(res => {
				this.statuses = res.data;
			})
			.catch(res => {
				window.getApp.error("Cannot obtain project status.");
				console.log(res);
			});
	},
	computed: {
		shownMembers(){
			let members = this.members

			if(this.filters.types && this.filters.types.length)
				members = members.filter(x => this.filters.types.includes(x.projectTypeId))
			if(this.filters.statuses && this.filters.statuses.length)
				members = members.filter(x => this.filters.statuses.includes(x.projectStatus))

			members = this.applySearch(this.filters.role, members, ['role'])

			return members
		},
		appliedFilters() { return this.countActiveFilters(this.filters, ['search', 'selectedTypes', 'selectedStatuses']) },
	},
	methods: {
		fetchMembers(){
			this.$nextTick(() => {
				this.$http.get(`api/project/teamMemberReport?
					userId=${this.filters.user}&
					projectId=${this.filters.project}&
					department=${this.filters.department}&
				`)
					.then(res => {
						this.members = res.data
					})
			})
		},
	},
}
</script>

<style lang="scss" scoped>

</style>