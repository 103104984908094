<template>
	<html-to-pdf
		v-if="!!project"
		v-model="show"
		:name="project.name"
		display-flex landscape prevent
		:preExport="preExport"
		preExportAtClick
	>
	<div style="width: 100%; height: 710px">
		<div class="d-flex flex-column">
			<div>
				<div class="d-inline-flex">
					<v-img
						src="../assets/bluePeople.png"
						height="90" width="150" contain
					/>
					<div v-if="!imageError" class="ml-5 pl-5" style="border-left: 1px solid #ddd;">
						<v-img
							@error="imageError = true"
							:src="getBackendUrl(`api/Project/${project.id}/image`)"
							height="90" width="150" contain
						/>
					</div>
				</div>
			</div>

			<div class="d-flex">
				<div class="d-flex flex-column" style="width: 50%">
					<div class="d-flex mt-5" style="gap: 15px">
						<v-chip v-if="project.industry || project.industry === 0" class="px-5">
							<v-icon size="20" class="mr-2">{{ project.industryIconClass }}</v-icon>
							{{ project.industryName }}
						</v-chip>
						<v-chip v-if="duration" class="px-5">
							<v-icon size="22" class="mr-2">mdi-calendar</v-icon>
							{{ duration }} Months
						</v-chip>
						<v-chip class="px-5">
							<v-icon size="22" class="mr-2">mdi-account-group</v-icon>
							{{ project.membersQuantity }} Team members
						</v-chip>
					</div>

					<v-spacer></v-spacer>

					<div>
						<span class="text-h5">About the project</span>
						<div class="mt-2" style="font-size: 1.5em !important; font-weight: 300;">{{ project.description || '-' }}</div>
					</div>
		
					<v-spacer></v-spacer>
		
					<div>
						<span class="text-h5">Endpoints</span>
						<div class="mt-2" style="font-size: 1.5em !important; font-weight: 300;">
							<ul>
								<li v-for="endpoint in project.endpointList" :key="endpoint" class="mb-1">
									{{ endpoints.find(x => x.id == endpoint).name }}
								</li>
							</ul>
						</div>
						<span v-if="!project.endpointList.length">No endpoints</span>
					</div>
		
					<v-spacer></v-spacer>
					
					<div>
						<span class="text-h5">Tech stack</span>
						<div class="mt-2 d-flex flex-wrap" style="gap: 10px">
							<v-chip v-for="tech in project.technologies" :key="tech.id" outlined large class="px-4">
								{{ tech.name }}
							</v-chip>
						</div>
						<span v-if="!project.technologies.length">No technologies registered</span>
					</div>
		
					<v-spacer></v-spacer>
				</div>
				<div class="d-flex flex-column flex-column justify-center align-center mt-10" :style="{ width: '50%' }">
					<div class="carousel" @click="cancel">
						<v-carousel
							v-model="model"
							hide-delimiters
							show-arrows-on-hover
						>
							<v-carousel-item
								v-for="(img, i) in images"
								:key="i"
								style="height: 100%;"
								@click="cancel"
							>
								<div class="px-3 fill-height d-flex">
									<img width="100%" style="margin: auto" :src="getBackendUrl(`/api/project/${project.id}/slideshow/${img.name}`)" contain>
								</div>
							</v-carousel-item>
							<template v-slot:prev="{ on, attrs }">
								<div class="cont" v-bind="attrs" v-on="on">
									<v-btn fab color="secondary">
										<v-icon x-large>mdi-chevron-left</v-icon>
									</v-btn>
								</div>
							</template>
							<template v-slot:next="{ on, attrs }">
								<div class="cont" v-bind="attrs" v-on="on">
									<v-btn fab color="secondary">
										<v-icon x-large>mdi-chevron-right</v-icon>
									</v-btn>
								</div>
							</template>
						</v-carousel>
						<div class="delimiters">
							<div class="d-flex justify-center">
								<div
									class="mini-img-cont"
									v-for="(img, i) in images.slice(0, 5)"
									:key="i"
									:style="{borderColor: model == i ? 'var(--v-accent-base)' : 'transparent'}"
								>
									<v-img
										:src="getBackendUrl(`/api/project/${project.id}/slideshow/${img.name}`)"
										@click.stop="model = i"
										height="70px" width="70px"
										class="mini-img elevation-5"
										:class="{'cursor-pointer': model != i}"
									>
										<div class="action-btns" v-if="model == i">
											<v-icon v-if="onDelete" color="error"  @click="handleDelete(img)">delete</v-icon>
										</div>
									</v-img>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
	</html-to-pdf>
</template>

<script>
import moment from "moment"
import ImageCarousel from '../components/ImageCarousel';

export default {
	components: {
		ImageCarousel,
	}, 
	data: () => ({
		model: 0,
		show: false,
		project: null,
		imageError: false,
		onDelete: null,
		endpoints: [],
		images: [],
		images64: [],
	}),
	created() {
		this.$http
			.get('api/enums/ProjectEndpoints')
			.then(res => {
				this.endpoints = res.data
			})
	},
	computed: {
		duration(){
			if(!this.project.startDate || !this.project.agreedEndDate) return null

			return moment(this.project.agreedEndDate).diff(this.project.startDate, 'month')
		},
	},
	methods: {
		open(project){
			this.imageError = false
			this.project = project
			this.$http.get(`/api/project/${this.project.id}/slideshow`)
			.then(res => {
				this.images = res.data
			})
			.catch(err => {
				window.getApp.error("Cannot get slideshow.")
				console.error(err)
			})
			this.show = true
		},
		preExport(){
			return new Promise(res =>{
				this.isPreExport = !this.isPreExport;
				this.$q.log(7, 53, 3, 'Export: ' + this.project.name, this.project.id)
				res();
			})
		},
		keyPress(evt) {
			if(!this.dialog)
				return
			let sum = 0
			if(evt.key == 'ArrowRight' || evt.code == 'ArrowRight'){
				sum = 1
			}
			if(evt.key == 'ArrowLeft' || evt.code == 'ArrowLeft'){
				sum = -1
			}
			const mod = (n, m) => (n % m + m) % m;
			this.model = mod(this.model+sum, this.images.length)
		},

		cancel(){
			this.dialog = false
		},
		handleDelete(img){
			this.onDelete(img)
		},
	},
}
</script>

<style lang="scss" scoped>

.carousel{
	position: relative;
	width: 80%;
	max-height: 450px;
	border-radius: 5%;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	background: #dce7fa;
}
.delimiters{
	position: absolute;
	bottom: -80px;
	width: 100%;
	color: white;
	text-align: center;
	margin-top: 10px;
	margin-bottom: 20px;

	.mini-img-cont{
		display: flex;
		max-width: 70px;
		margin-right: 10px;
		padding-bottom: 5px;
		justify-content: center;
		.mini-img{
			border-radius: 5px;

			.action-btns{
				width: 100%;
				height: 100%;
				background: rgba(0, 0, 0, 0.6);
				display: flex;
				justify-content: space-evenly;
				align-items: center;
			}
		}
		border-bottom: 2px solid transparent;
	}
}
::v-deep .v-window__container, ::v-deep .v-image.v-carousel__item{
	height: 100% !important;
}
::v-deep .v-responsive__sizer{
	padding: 0 !important;
}
::v-deep .v-window__prev, ::v-deep .v-window__next{
	top: 0;
	height: 100% !important;
	background-color: transparent;
	.cont{
		height: 100% !important;
		display: flex;
		align-items: center;
		cursor: pointer;
		transition: all 0.5s;
	}
}
::v-deep .v-ripple__container{
	display: none;
}

</style>