<template>
	<blue-confirm-dialog ref="dialog" >
		<template #text>
			<div>
				<p>We have detected a mismatch between the total of this billable item and its related invoice(s).</p>

				<div class="d-flex">
					<div class="flex">
						Amount to adjust: <br>
						<b :class="adjustment.amount < 0 ? 'error--text' : 'success--text'" class="headline">
							{{ currencyFormat(adjustment.amount) }}
						</b>
					</div>
					<div class="flex">
						Justification:
						<v-textarea
							v-model="adjustment.reason"
							:rows="3"
							outlined hide-details required
						/>
					</div>
				</div>
			</div>
		</template>
		<template #actions="{ cancel, confirm }">
			<v-spacer/>
			<v-btn @click="cancel" text>Cancel</v-btn>
			<v-btn @click="_confirm(confirm)" color="primary">Save Adjustment</v-btn>
		</template>
	</blue-confirm-dialog>
</template>

<script>
export default {
	data: () => ({
		id: null,
		invoiceId: null,
		adjustment: {
			amount: 0,
			reason: '',
		},
	}),
	methods: {
		async open(id, invoiceId){
			this.id = id
			this.invoiceId = invoiceId
			try {
				const res = await this.$http.get(`api/billableItem/${id}/adjustment/generate?invoiceId=${invoiceId}`)
				this.adjustment = res.data
				return this.$refs.dialog.open({
					title: 'Adjust Billable Item',
					description: 'We have detected a mismatch between the total of this billable item and its related invoice(s).',
					width: 700,
				})
			} catch (err) {
				if(err.notified) return
				console.error(err)
				this.$root.error('Failed to generate adjustment')
			}
		},
		async _confirm(callback){
			if(!this.adjustment.reason) return this.$root.error('Please provide a reason for the adjustment')
			try {
				await this.$http.post(
					`api/billableItem/${this.id}/adjustment?invoiceId=${this.invoiceId}`,
					{ reason: this.adjustment.reason }
				)
				callback()
			} catch (err) {
				console.error(err)
				this.$root.error('Failed to save adjustment')
			}
		},
	},
}
</script>

<style lang="scss" scoped>

</style>