<template>
	<div>
		<div
			class="bar text-left" :class="{ stack }"
			:style="{ border: overflowError && primaryAmount > innerObjective ? '2px solid red' : '' }"
		>
			<div :style="{ width: primaryPercentage + '%', background: primaryColor }"></div>
			<div :style="{ width: secondaryPercentage + '%', background: secondaryColor }"></div>
		</div>
		<div class="d-flex align-center justify-space-between mt-1">
			<div>
				<b>
					<span :style="{ color: primaryTextColor }">{{ amountFormatter(primaryAmount) }}</span>
					<span v-show="secondaryAmount" :style="{ color: secondaryTextColor }">(+{{ amountFormatter(secondaryAmount) }})</span>
				</b> /
				<span v-if="infinity" style="font-size: 1.1rem;">∞</span>
				<span v-else-if="objective" :style="{ fontWeight: boldObjective ? 600 : 400 }">{{ amountFormatter(objective) }}</span>
				<span v-else>-</span>
			</div>
			<div v-if="infinity" class="float-right">-</div>
			<div
				v-else
				class="float-right"
				:style="{
					color: totalPercentage === 100 ?
						'var(--v-success-base)' : 'var(--v-warning-darken)'
				}"
			>
				{{ totalPercentage | limitDecimals(percentageDecimals) }}%
			</div>
		</div>
	</div>
</template>

<script>

export default {
	props: {
		primaryAmount: { type: Number, default: 0 },
		primaryColor: { type: String, default: 'var(--v-success-base)'},
		primaryTextColor: { type: String, default: 'var(--v-text-base)'},
		secondaryAmount: { type: Number, default: 0 },
		secondaryColor: { type: String, default: 'var(--v-error-base)'},
		secondaryTextColor: { type: String, default: 'var(--v-error-base)'},
		amountFormatter: {
			type: Function, default: (v) => {
				let formatter = new Intl.NumberFormat()
				return formatter.format(v)
			}
		},
		percentageDecimals: { type: Number, default: 2 },
		objective: { type: Number, default: 0 },
		boldObjective: { type: Boolean, default: false },
		overflowError: { type: Boolean, default: false },
		limitSecondaryPercentage: { type: Boolean, default: true },
		useTotalAmount: { type: Boolean, default: false },
		stack: { type: Boolean, default: false },
		infinity: { type: Boolean, default: false },
	},
	computed: {
		innerObjective() {
			return this.objective || this.primaryAmount + this.secondaryAmount
		},
		primaryPercentage() {
			if(this.secondaryAmount > this.primaryAmount)
				return 0
			return this.innerObjective ? this.primaryAmount / this.innerObjective * 100 : 0
		},
		secondaryPercentage() {
			if(this.secondaryAmount > this.primaryAmount && this.limitSecondaryPercentage)
				return this.primaryAmount / this.innerObjective * 100
			return this.innerObjective ? this.secondaryAmount / this.innerObjective * 100 : 0
		},
		total() {
			return this.primaryAmount + this.secondaryAmount
		},
		totalPercentage() {
			if(!this.objective)
				return 0
			let amount = this.primaryAmount
			if(this.useTotalAmount)
				amount = this.total
			return amount / this.objective * 100
		},
	},
}

</script>

<style lang="scss" scoped>
.bar{
	width: 100%;
	height: 10px;
	background: lightgray;
	border-radius: 5px;
	overflow: hidden;
	position: relative;

	div{
		position: absolute;
		left: 0;
		height: 10px;
		display: inline-block;
		border-radius: 0 5px 5px 0;
	}
	
	&.stack{
		div {
			position: relative;
			left: unset;
			border-radius: 0;
			height: 20px;
		}
		div:nth-child(2) {
			border-left: 1px solid white;
		}
	}
}
</style>