var render = function () {
var this$1 = this;
var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"pa-3 new-back full-height"},[_c('filter-search-input',{attrs:{"applied-filters":_vm.appliedFilters,"outlined":"","dense":""},scopedSlots:_vm._u([{key:"append",fn:function(){return [(_vm.permissions.add)?_c('v-btn',{attrs:{"to":"supplier/new","color":"primary","fab":"","depressed":"","small":""}},[_c('v-icon',[_vm._v("mdi-plus")])],1):_vm._e()]},proxy:true}]),model:{value:(_vm.filters.search),callback:function ($$v) {_vm.$set(_vm.filters, "search", $$v)},expression:"filters.search"}},[_c('q-filter-input',{attrs:{"items":_vm.suppliers,"label":"Supplier","item-value":"id","item-text":"name","dense":"","outlined":"","hide-details":"","clearable":"","input-select":"","multiple":"","hide-input":"","parent-shown-items":_vm.shownSuppliers,"data-comparer":function (item, value) { return item.id == value; }},model:{value:(_vm.filters.supplier),callback:function ($$v) {_vm.$set(_vm.filters, "supplier", $$v)},expression:"filters.supplier"}}),_c('v-select',{attrs:{"items":_vm.activeOptions,"label":"Active","dense":"","outlined":"","hide-details":"","clearable":""},model:{value:(_vm.filters.active),callback:function ($$v) {_vm.$set(_vm.filters, "active", $$v)},expression:"filters.active"}})],1),_c('data-table',{staticClass:"mt-2",attrs:{"item-class":function () { return _vm.permissions.detail ? 'cursor-pointer' : ''; },"headers":_vm.headers,"items":_vm.shownSuppliers},on:{"click:row":function (row) { return _vm.permissions.detail ? this$1.$router.push('supplier/' + row.id) : null; }},scopedSlots:_vm._u([{key:"item.active",fn:function(ref){
var item = ref.item;
return [_c('v-icon',{staticClass:"ml-n3"},[_vm._v(" mdi-"+_vm._s(item.active ? 'check' : 'close')+" ")])]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [(_vm.permissions.actions)?_c('v-menu',{attrs:{"left":"","offset-x":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"icon":""}},on),[_c('v-icon',[_vm._v("mdi-dots-vertical")])],1)]}}],null,true)},[_c('v-list',{staticClass:"py-1",attrs:{"dense":""}},[_c('v-list-item',{staticClass:"pa-1 py-0"},[_c('v-tooltip',{attrs:{"right":"","z-index":"200"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"color":"error","text":"","small":""},on:{"click":function($event){return _vm.deleteSupplier(item)}}},on),[_c('v-icon',[_vm._v("mdi-delete")])],1)]}}],null,true)},[_c('span',{staticClass:"caption"},[_vm._v("Delete")])])],1)],1)],1):_vm._e()]}}],null,true)})],1)}
var staticRenderFns = []

export { render, staticRenderFns }