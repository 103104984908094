import _ from "lodash"
import moment from 'moment'

export default function OnTimeReportingChart (
  title,
  startDate,
  endDate,
  data) {
        
  let start = moment(startDate);

  // Construct x categories
  let xCategories = [];
  for (let current = moment(start); current.isSameOrBefore(endDate, 'day'); current.add(1, 'day')) {
    xCategories.push(current.format("D-MMM"));
  }


  let chartData = data
    .map((reporter, ix) => reporter.hours.map(dayReport => {
      let delay = dayReport.lastClosed ? moment(dayReport.lastClosed).subtract(6, 'hours').diff(dayReport.date) / (24*60*60*1000) - 1 : null;
      return {
        y: ix,
        x: moment(dayReport.date).diff(start, 'days'),
        value: delay,
        dateFormatted: moment(dayReport.date).format("(ddd) MMM D, YYYY"),
        delayFormatted: delay > 0 ? `${Number(delay).toFixed(1)} days delayed` : 'No delay',
        executed: dayReport.executedHours,
      };
    }))
    .flat();

  let height = 120 + data.length * 20;
  // if (height < 300) {
  //   // height = 300;
  // }
  return {
    chart: {
      type: 'heatmap',
      marginTop: 40,
      marginBottom: 80,
      plotBorderWidth: 1,
      height: height,
    },


    title: {
      text: title,
    },

    xAxis: {
      // min: 0,
      // max: weeks - 1,
      categories: xCategories,
      // categories: ['Alexander', 'Marie', 'Maximilian', 'Sophia', 'Lukas', 'Maria', 'Leon', 'Anna', 'Tim', 'Laura']
    },

    yAxis: {
      // min: 0,
      // max: 5,
      categories: data.map(r => r.reporterFullName),
      title: null,
      reversed: true
    },

    colorAxis: {
      stops: [
          [0, '#34e813'],
          [0.01, '#efd51d'],
          [0.1, '#efd51d'],
          [0.3, '#ff0000'],
          [1, '#770000'],
      ],
      min: 0,
      max: 10,
      tickInterval: 1,
    },

    legend: {
      align: 'right',
      layout: 'vertical',
      margin: 0,
      verticalAlign: 'top',
      y: 25,
      symbolHeight: 280
    },

    tooltip: {
      formatter: function () {
        return `<b>${this.point.dateFormatted}</b><br>Reported ${this.point.executed} hrs<br>${this.point.delayFormatted}`;// + `<br>(${this.point.x},${this.point.y})`;
      }
    },

    series: [{
      name: '',
      borderWidth: 1,
      borderColor: '#FFFFFF',
      data: chartData,
      turboThreshold: 2000,
    }],

    responsive: {
      rules: [{
        condition: {
          maxWidth: 500
        },
        chartOptions: {
          yAxis: {
            labels: {
              formatter: function () {
                return this.value.charAt(0);
              }
            }
          }
        }
      }]
    }
  };
  
}
  