<template>
  <v-container
    grid-list-md
    fill-height
    fluid
    class="pt-0 new-back"
  >
    <v-layout column fill-height justify-start>
      <v-flex class="nav-container">
        <v-tabs
          fixed-tabs
          v-model="activeTab"
          show-arrows
          :key="navKey"
          hide-slider
          :active-class="`selected-tab ${$vuetify.theme.dark ? 'dark-tab' : 'light-tab'}`"
        >
          <v-tab 
            v-for="(tab, ix) in tabs"
            :style="{
              marginLeft: 0,
              fontSize: tab.permission == 7 ? '.72rem' : '.875rem',
              minWidth: tab.permission == 7 ? '136px' : ''
            }"
            :key="ix" :to="tab.to"
            v-show="tabPermissions.length && tab && tabPermissions[tab.permission] && tabPermissions[tab.permission].viewLevel == 3"
          >
            {{tab.title}}
          </v-tab>
        </v-tabs>
        <div class="pa-0">
          <router-view 
            v-if="havePermissions(activeTab)"
            :canEditProject="canEditProject"
            :isInternal="true"
            :projectName="item.name"
            :socket="socket"
            @addSpinner="() => $emit('addSpinner')"
            @reload="() => init()"
            @removeSpinner="() => $emit('removeSpinner')"
          ></router-view>
        </div>
      </v-flex> 
      <v-spacer></v-spacer>
      <div class="pt-12" v-if="activeTab.split('/').includes('details')">
        <v-btn rounded color="red" outlined dark class="px-6"  @click="deleteProject()" :disabled="!canEditProject">
          Delete Project
        </v-btn>
      </div>
    </v-layout>
  </v-container>
</template>

<script>
import _ from "lodash"

export default {
  props: ["id", 'socket'],
  data: function() { 
    return {
      componentName: "ProjectDetails",
      item: {},
      activeTab: '',
      tabs: [],
      current: '',
      canEditProject: false,
      tabPermissions: [],
      navKey: 0,
      newBackground: false,
    };
  },
  watch: {
    id() {
      this.init()
    },
    activeTab() {
      let tab = this.tabs.filter(t => t.to == this.activeTab)[0]
      document.title = `${this.id} - ${tab.title} - ${this.item.name || ''}`
    },
    '$route.name'(route) {
      let wiRouteName = 'projectworkitems' //temporary while we made the design migration
      this.newBackground = wiRouteName == route.toLowerCase()
    }
  },
  computed: {
  },
  created() {
    document.title = 'Project'
    localStorage.setItem('currentProjectImage', this.getBackendUrl(`api/project/${this.id}/image`))
    this.current = this.$router.history.current.path.split("/");
    this.current = this.current[this.current.length-1]
    if(this.current == 'board')
      this.$router.push('workItems')
    this.init();
  },
  methods: {
    setNewBackground(change) {
      this.newBackground = change
    },
    init() {
      this.tabs =  [
        { permission: 2,  name: 'workItems', title: 'Work Items', to: `/project/${this.id}/workItems` },
        { permission: 15, name: 'dashboard', title: 'Dashboard', to: `/project/${this.id}/dashboard` },
        { permission: 17, name: 'nucleiManagement', title: 'Nuclei Management', to: `/project/${this.id}/nucleiManagement` },
        { permission: 18, name: 'account', title: 'Account', to: `/project/${this.id}/account` },
        { permission: 19, name: 'healthchecks', title: 'Healthchecks', to: `/project/${this.id}/healthchecks` },
        { permission: 11, name: 'riskManagement', title: 'Risks', to: `/project/${this.id}/riskManagement` },
        { permission: 3,  name: 'links', title: 'Links', to: `/project/${this.id}/links` },
        { permission: 14, name: 'members', title: 'Team Members', to: `/project/${this.id}/members` },
        { permission: 1,  name: 'basic', title: 'General Details', to: `/project/${this.id}/details` },
        { permission: 10, name: 'activityLog', title: 'Activity Log', to: `/project/${this.id}/activityLog` },
        { permission: 13, name: 'users', title: 'Users Permissions', to: `/project/${this.id}/usersPermissions` },
        { permission: 12, name: 'config', title: 'Config', to: `/project/${this.id}/config` },
        { permission: 16, name: 'overview', title: 'Overview', to: `/project/${this.id}/overview` },
      ]

      if (this.id) {
        this.$http
          .get(`/api/project/${this.id}/header`)
          .then(response => {
            this.item = response.data;
            this.$q.changeHeaderTitle(response.data.name)
            let tab = this.tabs.find(t => t.to == this.activeTab)
            document.title = `${this.id} - ${tab.title} - ${this.item.name || ''}`
          })
          .catch(response => {
            window.getApp.error("Cannot get record.");
            console.log(response);
          });
      }
      this.$http.get(`api/Project/${this.id}/viewableTabs`).then(res => {
      this.tabPermissions = res.data
      this.$http
        .get(`api/Project/${this.id}/currentPermissions`)
        .then(res => {
          this.canEditProject = res.data.canEdit == 3;
        }).catch( res => {
          window.getApp.error("Cannot get permissions.");
          console.log(res);
        })
      if(!this.havePermissions()){
        let founded = false
        for(let tab of this.tabs){
          for(let permission of this.tabPermissions){
            if(tab.permission == permission.tab && permission.viewLevel == 3){
              this.activeTab = tab.to
              founded = true
              break
            }
          }
          if(founded){
            break
          }
        }

        if(founded){
          this.$router.push(this.activeTab)
        }
      }
      this.navKey++
    })
    },
    
    havePermissions(){
      let tab = this.tabs.find(t => t.to == this.activeTab)
      return this.tabPermissions.length && tab && this.tabPermissions[tab.permission] && this.tabPermissions[tab.permission].viewLevel == 3
    },

    deleteProject() {

      this.$q.confirmDelete({
        api: `/api/project/${this.item.id}`,
        messageBody: "Are you sure you want to delete this project?", 
        sucessMsg: "Project deleted",
        errorMsg: "The project was not deleted.",
      }).then(res => {
        this.$router.push("/project")
        // this.$q.removeById(this.categories, id);
        this.init(); // TODO: modify data instead, consider new default
      });
    },
  }
    
}
</script>

<style scoped lang="scss">
  .check-icon {
    margin: 0;
  }

  // HACK: Vuetify Tab height bug workaround https://github.com/vuetifyjs/vuetify/issues/6962
  ::v-deep .v-tabs .v-window__container { 
    height: auto !important;
  }

  ::v-deep .theme--dark.v-tabs > .v-tabs-bar{
    background: transparent;
  }

  .selected-tab{
    color: var(--v-primary-base) !important;
    border-bottom: 2px solid var(--v-primary-base) !important;
  }

  ::v-deep .v-tabs-bar{
    height: 38px;
    margin-bottom: 5px;
  }

  .nav-container ::v-deep .v-slide-group, .sticky{
    width: calc(100vw - 206px);
    position: sticky;
    left: 120px;
  }
</style> 