<template>
	<blue-confirm-dialog ref="dialog">
		<template #text>
			<v-select
				v-model="filters.status" :items="statuses"
				item-value="id" item-text="name"
				outlined dense multiple clearable
				class="inline-block" label="Status"
			>
				<template #selection="{ item }">
					<v-btn
						class="mt-2 mb-1 mr-2"
						:color="item.color"
						outlined small style="border-radius: 20px"
						:style="{ background: $vuetify.theme.isDark ? '' : item.lightColor }"
					>{{item.name}}</v-btn>
				</template>
				<template #item="{ item }">
					<v-btn
						:color="item.color"
						outlined small style="border-radius: 20px"
						:style="{ background: $vuetify.theme.isDark ? '' : item.lightColor }"
					>{{item.name}}</v-btn>
				</template>
			</v-select>

			<billable-items-table
				v-model="selectedItems" show-select
				:items="shownBillableItems"
				class="rounded-lg bordered new-back lighten2 cursor-pointer"
				:item-class="x => x.isClosed ? 'item-completed' : ''"
			/>
		</template>
	</blue-confirm-dialog>
</template>

<script>
import BillableItemsTable from '../../components/BillableItemsTable'
export default {
	components: { BillableItemsTable },
	data: () => ({
		billableItems: [],
		selectedItems: [],
		statuses: [],
		filters: {
			status: [3, 4],
		},
		resolve: null,
	}),
	created(){
		this.$http.get('api/enums/admin/billableItem/status')
			.then(res => {
				this.statuses = res.data
					.filter(x => [3, 4, 5].includes(x.id))
			})
	},
	computed: {
		shownBillableItems(){
			let billableItems = this.billableItems
			if(this.filters.status.length){
				billableItems = billableItems.filter(x => this.filters.status.includes(x.status))
			}
			return billableItems
		}
	},
	methods: {
		open(billableItems){
			this.billableItems = billableItems
			this.selectedItems = []
			this.$refs.dialog.open({
				title: 'Relate Billable Items',
				confirmBtnText: 'Add Billable Items',
				width: 1500,
			})
				.then(res => {
					if(!res) {
						this.resolve([])
						return
					}
					this.resolve(this.selectedItems.filter(x => !x.isClosed))
				})
			return new Promise(res => this.resolve = res)
		},
	},
}
</script>

<style lang="scss" scoped>
::v-deep .item-completed{
	&.v-data-table__selected{
		background: none !important;
	}
	.v-data-table__checkbox{
		display: none;
	}
}
</style>