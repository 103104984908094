<template>
	<div class="py-2 px-10 new-back full-height">
		<div
			v-if="item.isNew"
			class="d-flex align-center px-5 top-btn"
			:style="{background: $vuetify.theme.isDark ? 'var(--v-newDesignBackground-base)' : '#fafcfe'}"
		>
			<v-btn @click="save" color="primary">Save Credit Note</v-btn>
		</div>

		<v-row style="max-width: 1400px; margin-left: auto; margin-right: auto">
			<v-col :cols="12">
				<div class="bordered rounded-lg pa-5">
					<h4 class="mb-5">Company details</h4>
					<div class="d-flex" style="gap: 10px">
						<div>
							<label>Business Name</label>
							<v-text-field :value="item.invoiceBusinessDetailsBusinessName" v-bind="inputProps"/>
						</div>
						<div>
							<label>RFC/EIN</label>
							<v-text-field :value="item.invoiceBusinessDetailsRfc" v-bind="inputProps"/>
						</div>
						<div class="flex">
							<label>Business Addresss</label>
							<v-text-field :value="item.invoiceBusinessDetailsFullAddress" v-bind="inputProps"/>
						</div>
					</div>
				</div>
			</v-col>

			<v-col :cols="8">
				<div class="bordered rounded-lg pa-5">
					<h4 class="mb-5">General details</h4>
					<v-row>
						<v-col :cols="4">
							<label>Invoice No.</label>
							<v-text-field :value="item.invoiceCode" v-bind="inputProps"/>
						</v-col>
						<v-col :cols="4">
							<label>Customer</label>
							<v-text-field :value="item.invoiceCustomerName" v-bind="inputProps"/>
						</v-col>
						<v-col :cols="4" class="pb-0">
							<label>RFC</label>
							<v-text-field :value="item.invoiceRfcRFC" v-bind="inputProps"/>
							<div class="mt-1" style="color: var(--v-text-lighten)">
								{{ item.invoiceRfcName }}
								<span v-if="item.invoiceRfcName && !item.invoiceRfcName" class="error--text">
									Not name found, please insert a name
								</span>
							</div>
						</v-col>
						<v-col :cols="12">
							<label>Business Address</label>
							<v-text-field :value="item.invoiceBusinessAddress" v-bind="inputProps"/>
						</v-col>
						<v-col :cols="3">
							<label>Credit Note Date</label>
							<v-form ref="form">
								<q-date-picker
									v-model="item.dateTime"
									inner label=""
									v-bind="inputProps"
									:readonly="!item.isNew"
									:rules="[$rules.required]"
								/>
							</v-form>
						</v-col>
						<v-col :cols="3">
							<label>Invoice Date</label>
							<q-date-picker :value="item.invoiceIssueDate" inner label="" v-bind="inputProps"/>
						</v-col>
						<v-col :cols="3">
							<label>Delivery date</label>
							<q-date-picker :value="item.invoiceDeliveryDate" inner label="" v-bind="inputProps"/>
						</v-col>
						<v-col :cols="3">
							<label>Due Date</label>
							<q-date-picker :value="item.invoiceIssueDate" inner label="" v-bind="inputProps"/>
						</v-col>
						<v-col>
							<label>Payment type</label>
							<v-text-field :value="item.invoiceWayToPay" v-bind="inputProps"/>
						</v-col>
						<v-col>
							<label>Payment method</label>
							<v-text-field :value="item.invoicePaymentMethod" v-bind="inputProps"/>
						</v-col>
						<v-col>
							<label>Use</label>
							<v-text-field :value="item.invoiceUse" v-bind="inputProps"/>
						</v-col>
					</v-row>
				</div>
			</v-col>
			<v-col :cols="4">
				<div class="bordered rounded pa-5" style="position: relative">
					<h4>Invoice amount</h4>

					<v-btn text absolute right top>
						<v-avatar color="indigo" size="20" class="mr-1">
							<v-img :src="require(`../../assets/currencies/${item.invoiceCurrency.toLowerCase()}.png`)" />
						</v-avatar>
						<span>{{item.invoiceCurrency}}</span>
					</v-btn>

					<div class="mt-8">
						<label>Subtotal</label>
						<v-text-field
							:value="currencyFormat(item.invoiceNotRequireTax ? item.amount : item.amount/1.16)"
							v-bind="inputProps"
						/>
					</div>
					<div class="mt-4">
						<label>Tax</label>
						<v-text-field
							:value="currencyFormat(item.invoiceNotRequireTax ? 0 : item.amount/1.16*.16)"
							v-bind="inputProps"
						/>
					</div>
					<div class="mt-4">
						<label>Total amount</label>
						<v-text-field
							:value="currencyFormat(item.amount)"
							v-bind="inputProps"
						/>
					</div>
				</div>
			</v-col>

			<v-col :cols="12">
				<div class="bordered rounded-lg pa-5">
					<h4 class="mb-5">Credit note details</h4>
					<v-row>
						<v-col :cols="3">
							<label>Origin invoice</label>
							<v-text-field :value="item.invoiceCode" v-bind="inputProps"/>
						</v-col>
						<v-col :cols="3">
							<label>Applied invoice</label>
							<v-text-field :value="item.invoiceCode" v-bind="inputProps"/>
						</v-col>
					</v-row>
				</div>
			</v-col>

			<v-col :cols="12">
				<div class="bordered rounded-lg pa-5">
					<h4 class="mb-5">Invoice lines</h4>
					<div class="d-flex flex-column">
						<div
							class="d-flex" style="gap: 10px"
						>
							<div class="flex">
								<label>Description</label>
								<v-text-field
									:value="`Related to invoice ${item.invoiceCode}`"
									v-bind="inputProps"
								/>
							</div>
							<div class="flex">
								<label>Additional Details</label>
								<v-text-field
									:value="`Related to invoice ${item.invoiceCode}`"
									v-bind="inputProps"
								/>
							</div>
							<div style="width: 100px">
								<label>Quantity</label>
								<v-text-field
									:value="1"
									v-bind="inputProps"
									type="number"
								/>
							</div>
							<div style="width: 130px">
								<label>Unit Price</label>
								<input-mask
									:value="item.amount"
									v-bind="inputProps"
									:mask="currencyFormat"
									v-only-numbers
								/>
							</div>
							<div style="width: 130px">
								<label>Amount</label>
								<input-mask
									:value="item.amount"
									v-bind="inputProps"
									:mask="currencyFormat"
									v-only-numbers
									disabled
								/>
							</div>
						</div>
					</div>
				</div>
			</v-col>
		</v-row>
	</div>
</template>

<script>
export default {
	props: {
		invoiceId: {
			type: String,
			default: null,
		},
	},
	data: () => ({
		item: {},
		inputProps: {
			readonly: true,
			outlined: true,
			hideDetails: true,
			dense: true,
		},
	}),
	created() {
		this.$http.get(`api/invoice/${this.invoiceId}/creditNote`).then((res) => {
			this.item = res.data;
			document.title = `Credit Note - ${this.item.invoiceCode}`;
			this.$q.changeHeaderTitle(`Credit Note - ${this.item.invoiceCode}`);
		}).catch((err) => {
			if(err.notified) {
				setTimeout(() => this.$router.go(-1), 3000);
				return;
			}
			console.error(err);
			this.$root.error('Error loading credit note details. Please try again later.');
		});
	},
	methods: {
		async save() {
			if(!this.$refs.form.validate()) return;

			try {
				await this.$http.post(
					`api/invoice/${this.invoiceId}/creditNote`,
					{ dateTime: this.item.dateTime}
				)
				this.$root.success('Credit note saved successfully');
				this.$router.go(-1);
			} catch (error) {
				if(error.notified) return;
				console.error(error);
				this.$root.error('Error saving credit note. Please try again later.');
			}
		}
	},
}
</script>

<style lang="scss" scoped>
::v-deep .v-input--is-readonly{
	.v-input__slot{
		background: var(--v-coloredBack-base);
	}
	fieldset{
		border: none;
	}
}
label{
	color: var(--v-text-lighten) !important;
	font-size: .9em;
}

.top-btn{
	position: absolute;
	top: -75px; right: 0;
	z-index: 100;
	height: 75px;
	gap: 10px
}
</style>