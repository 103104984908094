<template>

  <v-dialog persistent @keydown.esc="cancel" @click:outside="cancel"
   :fullscreen="$vuetify.breakpoint.xs" scrollable v-model="dialog" v-if="dialog" content-class="choose-task-dialog">
    <v-card :class="{ 'parent-selectable': selectParentTasks }">
      <!-- <v-card-title class="text--text" primary-title>
        {{project.name}} Tasks
      </v-card-title> -->
      <v-card-text>
        <task-chooser
          @itemChosen="chooseItem"
          :project="project" :users="users"
          :selectParentTasks="selectParentTasks"
          :work-items="workItems"
        />
      </v-card-text>
      <v-divider/>
      <v-card-actions>
        <v-spacer/>
        <v-btn color="primary" text @click="cancel">
            Close
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>


<script>
import moment from 'moment'
import _ from "lodash"
import TaskListManager from './../helpers/task-list-manager'
  export default {
    components: {
    },
    props: {
      selectParentTasks: { default: false }
    },
    data: () => ({
      dialog: false,
      resolve: null,
      reject: null,

      users: [],
      project: {},
      workItems: [],
    }),
    computed: {
      sortedTasks() {
        if (!this.project || !this.project.tasks) return [];

        return _.orderBy(this.project.tasks, ["sortIndex"], ['asc']);
      },
    },
    methods: {
      open(projectId, users) {
        this.dialog = true
        this.search = null
        this.users = users
        this.project = {};

        this.$http
          .get(`/api/project/${projectId}`)
          .then(res => {
            this.project = res.data
          })
          .catch(res => {
            window.getApp.error("Cannot obtain project tasks.")
            console.error(res)
          })
        this.$http
          .get(`/api/project/${projectId}/workItem?simpleView=true`)
          .then(res => {
            this.workItems = res.data
          })
          .catch(res => {
            window.getApp.error("Cannot obtain project work items.")
            console.error(res)
          })

        return new Promise((resolve, reject) => {
          this.resolve = resolve
          this.reject = reject
        })
      },
      chooseItem(taskChosen) {
        this.resolve(taskChosen)
        this.dialog = false
      },
      cancel() {
        setTimeout(() => {

          this.reject(false)
          this.dialog = false
        })
      }
    }
  }
</script>

<style scoped lang="scss">
.headline {
  background: var(--v-background-lighten2);
}
.table {
  color: #333;
}
.xxxs.v-btn {
  margin: 2px;
  height: 13px;
  min-width: 13px;
  margin: 0;
  padding: 0;
}
.xxxs.v-btn ::v-deep i.v-icon {
  font-size: 11px;
}
.v-dialog__content {
  align-items: start;
}
.task-status {
    font-size: 85%;
    padding: 1px 6px;
    border-radius: 2px;
    white-space: nowrap;
}
.task-status.In.Progress {
    background: #e8a300;
    color: #fff;
}
.task-status.Done {
    background: #0090a9;
    color: #fff;
}
.task-status.Not.Started {
    background: #797979;
    color: #fff;
}

.parent-task { font-weight: 700; }
.parent-task.collapsed-task td { border-bottom-style: groove; }
.level1 { background: #f0f0f0; }
.level2 { background: #f5f5f5; }
.level3 { background: #fafafa; }
.level4 { background: #ffffff; }

table {
  border-spacing: 0;
  width: 100%;
}
.task-row:hover {
  color: #087aad;
}
.task-row.parent-task:hover {
  color: inherit;
}
.task-row.parent-task:hover .v-btn {
  color: #087aad;
}
.parent-selectable .task-row.parent-task:hover {
  color: #087aad;
}
.parent-selectable .task-row.parent-task:hover .v-btn {
  color: initial;
}

.task-row td {
    border-bottom: 1px solid #e0e0e0;
    border-spacing: 0px;
    padding: 4px 1px;
}
.selected-task {
  background: #7adaff;
}
</style>