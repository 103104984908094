
const BlueSecurity = {
	securityInfo: null,
	currentPermission: null,
	currentAccessLevel: 0,
	$http: null,
	permissionKeys: {
		'default': 0,
		'not_allowed': 1,
		'list': 2,
		'detail': 3,
		'add': 4,
		'edit': 5,
		'full_access': 6,
		'delete': 6,
	},
	permissionTypes: {},

	setup(axios){
		this.$http  = axios
		this.$http.get('api/enums/permissions')
			.then(res => {
				this.permissionTypes = res.data
			})
			.catch(err => {
				console.error(err)
			})
	},

	can(requiredLevel) {
		if(typeof requiredLevel == 'string'){
			requiredLevel = this.permissionKeys[requiredLevel.toLowerCase()]
		}

		return requiredLevel <= this.currentAccessLevel
	},

	async updateCurrentAccessLevel() {
		if(this.currentPermission === undefined)
			return

		const info = await this.getInfo()
		if(!info)
			throw new Error('Not logged')

		this.currentAccessLevel = this.securityInfo.permissions
			.find(x => x.type == this.currentPermission).accessLevel
	},

  async getInfo(){
		if(!this.securityInfo){
			try{
				let res = await this.$http.get(`/api/instance/currentUser/permissions`)
				this.securityInfo = res.data
			}
			catch(e){
				this.securityInfo = null
			}
		}
		return this.securityInfo
	},

	async navigateHandler(to, from){
		if(to.path == '/')
			return '/profile/me'
		if(from.path == to.path || to.meta.allowAnonymous) 
			return true
		
		const meta = to.matched[0]?.meta || to.meta
		this.currentPermission = meta.permission
		this.updateCurrentAccessLevel()
		try{
			return await this.hasRequiredPermission(meta.requiredLevel || 2, null, meta.allowExternal)
		}
		catch(e){
			return '/login'
		}
	},

	async hasRequiredPermission(requiredLevel, permission = null, allowExternal = false){
		if(!permission) permission = this.currentPermission

		if(typeof permission == 'string') {
			let permissionText = permission
			permission = this.permissionTypes.find(x => x.name == permission)?.id
			if(!permission)
				throw new Error(`Permission ${permissionText} not found`)
		}

		if(typeof requiredLevel == 'string'){
			requiredLevel = this.permissionKeys[requiredLevel.toLowerCase()]
		}

		const info = await this.getInfo()
		if(!info)
			throw new Error('Not logged')

		return permission === undefined ||
			(info.isExternal && allowExternal) ||
			this.securityInfo.permissions
				.some(x => x.type == permission && x.accessLevel >= requiredLevel)
	},
}

export default BlueSecurity