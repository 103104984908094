<template>
  <v-dialog
    :fullscreen="$vuetify.breakpoint.xsOnly"
    scrollable
    persistent
    v-model="dialog"
    :max-width="550"
    v-if="dialog"
  >
    <v-form ref="form">
      <v-card style="padding: 20px">
        <v-card-title class="pt-2 pb-4">
          {{ workItem.id ? "Edit Work Item" : "New Work Item" }}
        </v-card-title>

        <v-card-text class="pt-2">
          <v-autocomplete
						style="width: 100%"
						v-if="!projectId"
						:disabled="true"
						outlined dense
						v-model="workItem.projectId"
						label="Project *" ref="project" 
						:persistent-hint="true"
						:items="projects" item-value="id" item-text="name"
						:filter="(item, queryText, itemText) => (item.customerProduct + ': ' + item.name).toLocaleLowerCase().indexOf(queryText.toLocaleLowerCase()) > -1"
						@change="changeProject"
					>
						<template v-slot:selection="{ item }">
						<category-chip small :code="item.customerProduct" :text="item.name"></category-chip>
						</template>
						<template v-slot:item="{ item }">
						<v-list-item-content>
								<v-list-item-title>
								<span>{{item.name}}</span>
								</v-list-item-title>
								<v-list-item-subtitle v-text="item.customerProduct"></v-list-item-subtitle>
						</v-list-item-content>
						</template>
					</v-autocomplete>

					<v-row>
						<v-col>
							<v-autocomplete
								:items="newRiskCreation ? risks : risks.filter(r => r.projectId == workItem.projectId)"
								:item-value="item => item.id"
								v-model="workItem.projectRiskId"
								outlined
								dense
								label="Risk *"
								:disabled="disableRiskChange || readonly"
							>
								<template v-slot:selection="{ item }">
									<v-icon :color="item.color" class="mr-2">
										mdi-alert-circle
									</v-icon>
									{{item.code}}
								</template>
								
      					<template v-slot:item="{ item }" class="mr-2">
									<v-icon :color="item.color">
										mdi-alert-circle
									</v-icon>
									{{item.code}}
								</template>
							</v-autocomplete>
						</v-col>
						<v-col>
							<v-select dense outlined label="Status *" :disabled="!!workItem.id" v-model="workItem.statusId" item-value="id" item-text="name" :items="this.projectConfig.workItemStatuses">
                <template v-slot:item="{ item }">
                  <v-chip class="cursor-pointer mt-2 mb-2" label :style="{ width: '100%' }" dark :color="item.color">
                    <v-icon class="mr-2">{{item.iconClass}}</v-icon>
                    {{item.name}}
                  </v-chip>
                </template>
                <template v-slot:selection="{ item }">
                  <v-icon :color="item.color" class="mr-2">{{item.iconClass}}</v-icon>
                  {{setMaxText(item.name, 25)}}
                </template>
              </v-select>
						</v-col>
					</v-row>

					<v-text-field
						outlined
						v-model="workItem.name"
						label="Description *"
						:readonly="readonly"
					/>

					<v-row>
						<v-col>
							<v-autocomplete
								label="Assigned to" :items="users" 
								item-value="id" item-text="blueTag"
								:filter="(item, queryText, itemText) => normalizeIncludes(item.blueTag + ' ' + item.fullName, queryText)"
								v-model="workItem.assignedToId"
								outlined dense
								:readonly="readonly"
							>
								<template v-slot:item="{ item }">
									<v-list-item-avatar color="indigo" class="headline font-weight-light white--text">
										{{ (item.blueTag || '').charAt(0) }}
									</v-list-item-avatar>
									<v-list-item-content>
										<v-list-item-title v-text="item.blueTag"></v-list-item-title>
										<v-list-item-subtitle v-text="item.fullName"></v-list-item-subtitle>
									</v-list-item-content>
								</template>
								<template v-slot:selection="{ item }">
									<v-list-item-content>
										<v-list-item-title v-text="item.blueTag"></v-list-item-title>
									</v-list-item-content>
								</template>
							</v-autocomplete>
						</v-col>

						<v-col>
							<v-menu
								v-model="menu"
								:close-on-content-click="false"
								:nudge-right="40"
								transition="scale-transition"
								offset-y
								min-width="auto"
								:readonly="readonly"
							>
								<template v-slot:activator="{ on, attrs }">
									<v-text-field
										v-model="workItem.dueDate"
										label="Due Date"
										prepend-icon="mdi-calendar"
										readonly
										v-bind="attrs"
										v-on="on"
										outlined dense
										clearable
									></v-text-field>
								</template>
								<v-date-picker
									v-model="workItem.dueDate"
									@input="menu = false"
									no-title
									:readonly="readonly"
								></v-date-picker>
							</v-menu>
						</v-col>
					</v-row>

        </v-card-text>

        <v-card-actions class="px-6">
          <v-spacer></v-spacer>

					<div v-show="showWarning" class="status-warning">
						<div style="width: 25px">
							<v-icon color="#cc3300">warning</v-icon>
						</div>
						<div class="txt">
							It is necessary to have at least one status of type task
						</div>
					</div>

          <v-btn color="primary" text @click="cancel"> Cancel </v-btn>
          <v-btn color="primary" depressed @click="save" :disabled="showWarning || !projectId" >
            {{ workItem.id || newRiskCreation ? "Save" : "Create" }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-form>
  </v-dialog>
</template>
<script>
import moment from "moment"
export default {
  props: {
		risks: { type: Array, default: () => ([]) },
		newRiskCreation: { type: Boolean, default: false },
		projectId: { type: String, default: '' },
	},
  data() {
    return {
			projects: [],
      dialog: false,
      risk: {},
      riskStatus: [
        { name: "Active", value: 0 },
        { name: "Eliminated", value: 1 },
        { name: "Materialized", value: 2 },
      ],
			workItem: {
				projectRiskId: null
			},
			users: [],
			projectConfig: {},
			menu: false,
			project: {},
			showWarning: false,
			disableRiskChange: false,
			resolve: undefined,
			reject: undefined,
    };
  },
	computed: {
		readonly() {
			return !this.projectId
		}
	},
  methods: {
    save() {
			if(
				!this.workItem.projectRiskId && !this.newRiskCreation ||
				!this.workItem.statusId ||
				!this.workItem.name
			){
        window.getApp.error("Cannot save risk.")
				return;
			}

			if(!this.workItem.dueDate){
				this.workItem.dueDate = null
			}

			if(this.newRiskCreation) {
				let wi = JSON.parse(JSON.stringify(this.workItem))
				wi.status = this.projectConfig.workItemStatuses.find(st => st.id === wi.statusId)
				let assignedTo = this.users.find(user => user.id === wi.assignedToId)
				wi.assignedTo = assignedTo
				wi.assignedToBlueTag = assignedTo.blueTag
				this.resolve(wi)
				this.cancel()
				return
			}
			if(this.workItem.id){
				this.workItem.cardColumns = []
				this.$http
					.put(`api/WorkItem/${this.workItem.id}`, this.workItem)
					.then((res) => {
						if (!res.data.id) {
							window.getApp.error(res.data)
							return
						}
						window.getApp.success("Work Item updated successfully.")
						this.dialog = false
						this.resolve()
					})
					.catch((response) => {
						window.getApp.error("Cannot update work item.")
						console.log(response)
					})
			} else {
				this.$q.save({
					api: `/api/WorkItem`,
					data: this.workItem,
					successMsg: 'Work item created successfully',
					afterSuccessUrl: null,
					errorMsg: "Cannot create work item.",
					afterErrorUrl: null,
				}).then(() => {
					this.dialog = false
					this.resolve()
				})
			}
			this.workItem = {}
    },

		changeProject(){
			this.$http.get(`api/project/${this.workItem.projectId}/config?active=true`).then(res => {
				this.projectConfig = res.data
				this.projectConfig.workItemStatuses = this.projectConfig.workItemStatuses.filter(s => s.workItemType === 3)
				this.showWarning = !this.projectConfig.workItemStatuses.length
			})

			this.users = []
			const membersPr = this.$http
				.get(`/api/project/${this.workItem.projectId}/members`)
				.then(res => {
					let members = res.data
					this.users = this.users.concat(
						members.map(member => {
							if(!member.user.blueTag)
								member.user.blueTag = member.user.fullName
							return member.user
						})
					)
				})
				.catch(res => {
					console.log(res)
					window.getApp.error("Cannot obtain project members.")
				})
			const billableMembersPr = this.$http.get(`api/nucleiManagement/${this.workItem.projectId}/members`)
				.then((res) => {
					this.users = this.users.concat(
						res.data.map(x => ({
							id: x.assignment.userId,
							blueTag: x.assignment.userBlueTag,
							fullName: x.assignment.userFullName,
						}))
					)
				})
				.catch(response => {
					window.getApp.error("Cannot get nuclei members.");
				})
			Promise.all([membersPr, billableMembersPr]).then(() => this.$q.addLastItem(this.users, 'Unassigned', 'blueTag'))
		},

    async open(id, riskId = '', editingNoCreatedWI, noCreatedWi = {}) {
			const newRiskCreation = this.newRiskCreation
			if(newRiskCreation && this.risks[0]?.id !== null) {
				this.$q.addFirstItem(this.risks, 'NEW RISK', 'code')
			}
			if(typeof riskId != 'string'){
				riskId = ''
			}
			if(riskId || newRiskCreation){
				this.disableRiskChange = true
			}
			if(id){
				await this.$http.get(`api/workItem/${id}`).then((res) => {
					this.workItem = res.data
					this.workItem.dueDate = this.workItem.dueDate ? moment(this.workItem.dueDate).format( 'YYYY-MM-DD') : ''
					this.workItem.assignedToId = this.workItem.assignedToId ? this.workItem.assignedToId : null
				})
			}
			let projectId = this.projectId || this.workItem.projectId
			if(!this.projectId){
				await this.$http
					.get("/api/user/current/project")
					.then(res => {
							this.projects = res.data;
							if(!projectId)
								projectId = this.projects[0].id
					})
					.catch(res => {
							window.getApp.error("Cannot get records.");
					});
			}
      this.dialog = true
			this.showWarning = false
			let configPromise = this.$http.get(`api/project/${projectId}/config?active=true`).then(res => {
				this.projectConfig = res.data
				this.projectConfig.workItemStatuses = this.projectConfig.workItemStatuses.filter(s => s.workItemType === 3)
				this.showWarning = !this.projectConfig.workItemStatuses.length
			})
			let userPromise = this.$blueSystem.getCurrentUser().then(user => {
				this.currentUser = user
			})
			let projectPromise = this.$http.get(`api/project/${projectId}`).then(res => {
				this.project = res.data
			})
			this.users = []
			const membersPr = this.$http
				.get(`/api/project/${this.workItem.projectId}/members`)
				.then(res => {
					let members = res.data
					this.users = this.users.concat(
						members.map(member => {
							if(!member.user.blueTag)
								member.user.blueTag = member.user.fullName
							return member.user
						})
					)
				})
				.catch(res => {
					console.log(res)
					window.getApp.error("Cannot obtain project members.")
				})
			const billableMembersPr = this.$http.get(`api/nucleiManagement/${this.workItem.projectId}/members`)
				.then((res) => {
					this.users = this.users.concat(
						res.data.map(x => ({
							id: x.assignment.userId,
							blueTag: x.assignment.userBlueTag,
							fullName: x.assignment.userFullName,
						}))
					)
				})
				.catch(response => {
					window.getApp.error("Cannot get nuclei members.");
				})
			Promise.all([membersPr, billableMembersPr]).then(() => this.$q.addLastItem(this.users, 'Unassigned', 'blueTag'))

			Promise.all([configPromise, userPromise, projectPromise]).then(ress => {
				if (!id) {
					console.log({'id': riskId})
					let statuses = [];
					if (this.projectConfig && this.projectConfig.workItemStatuses)
						statuses = this.projectConfig.workItemStatuses
					let curStatus = statuses.filter(s => s.isDefault)
					curStatus = curStatus.length ? curStatus[0].id : null;
					const wi = noCreatedWi.temporalId ? noCreatedWi : {
						code: '',
						assignedToId: null,
						description: "",
						estimatedEffort: null,
						name: "",
						priority: 3,
						projectId: this.project.id,
						reportedById: this.currentUser.id,
						sprintId: null,
						statusId: curStatus,
						type: 3,
						createdAt: new Date(),
						tags: [],
						projectRiskId: newRiskCreation ? null : riskId,
					}
					this.workItem = wi
					console.log({wi, withis: this.workItem})
				}
			})
			return new Promise((resolve, reject) => {
				this.resolve = resolve;
				this.reject = reject;
			})
    },
    cancel() {
			this.reject()
			this.workItem = {}
      this.dialog = false
    },
  },
}
</script>
<style scoped lang="scss">
.color-black {
  color: black !important;
}
.severity {
  height: 40px;
  display: flex;
  align-items: center;
}
.status-warning{
	display: flex;
	align-items: center;
	div {
		display: inline-block;
	}
	.txt{
		margin-left: 12px;
		width: calc(100% - 45px);
		font-size: 14px;
	}
}
</style>