<template>
	<v-dialog scrollable v-if="dialog" 
    v-model="dialog" :max-width="700" @keydown.esc="cancel" persistent>
    <v-card>
			<v-card-title>
				Confirm Billable Items
				<v-btn @click="cancel" icon absolute style="right: 10px"><v-icon>mdi-close</v-icon></v-btn>
			</v-card-title>
			<v-card-text class="pt-2">
				<v-expand-transition v-for="(item, ix) in shownBillableItems" :key="ix">
					<v-expansion-panels
						v-show="!item.deleted"
						v-model="item.expanded" class="mb-2"
					>
						<v-expansion-panel
							class="expansion-panel"
						>
							<v-expansion-panel-header>
								<h3>
									{{ item.billableCode }}
									<v-icon v-if="item.saved" color="success">mdi-checkbox-marked-circle</v-icon>
								</h3>
							</v-expansion-panel-header>
							<v-expansion-panel-content class="pt-3">
								<div class="head">
									<div class="date">
										Start date <br>
										<q-date-picker
											class="inline-block mr-3" label="" style="width: 150px"
											inner outlined dense hide-details
											v-model="item.startDate"
											@change="loadBillableItem(item)"
										/>
									</div>
									<div class="date">
										End date <br>
										<q-date-picker
											class="inline-block" label="" style="width: 150px"
											inner outlined dense hide-details
											v-model="item.endDate" :min="item.startDate"
											@change="loadBillableItem(item)"
										/>
									</div>
									<div class="currency text-center">
										Currency <br>
										<div>
											<v-avatar color="indigo" size="32" class="mr-1">
												<v-img :src="require(`../../assets/currencies/${item.billableCurrency.toLowerCase()}.png`)" />
											</v-avatar>
											<span>{{item.billableCurrency}}</span>
										</div>
									</div>
								</div>

								<div v-if="item.billableType !== 0" class="mt-3">
									<v-btn @click="selectAll(item)" color="primary" text small>Select all</v-btn>
								</div>

								<div class="mx-2">
									<div class="line px-2" style="color: grey; font-size: .9em">
										<div>Seat</div>
										<div class="text-right">{{ item.billableType === 0 ? 'Unitary Rate' : 'Rate per hour' }}</div>
										<div class="text-right">{{ item.billableType === 0 ? 'Quantity' : 'Total Hours' }}</div>
										<div class="text-right">Total</div>
									</div>
									<div
										v-for="(line, ix) in item.lines" :key="ix"
										class="bordered darken rounded-lg overflow-hidden pt-2 mb-2"
										style="min-height: 44px"
									>
										<div
											@click="item.billableType === 0 ? null : toggleExpanded(line)"
											:style="{'border-bottom': line.expanded ? '1px solid var(--v-lightgray-base)':''}"
											class="line cursor-pointer px-2 pb-1" style="min-height: 30px"
										>
											<div class="d-flex align-center" style="gap: 10px">
												<v-icon
													@click="item.billableType !== 0 ? null : toggleExpanded(line)"
													v-if="line.members && line.members.length"
													:class="{'rotate-180': line.expanded}"
													class="bordered lighten rounded"
												>
													mdi-chevron-down
												</v-icon>
												<b>{{ line.billableLineRole }}</b>
											</div>
											<div class="text-right">{{ currencyFormat(line.rate) }}</div>
											<div v-if="item.billableType === 0" class="text-right">
												<v-text-field
													v-model="line.quantity"
													@change="fixedRateChanged(item, line, 'quantity')"
													class="mt-n1 d-inline-flex"
													dense hide-details
													type="number"
													style="font-size: .95em; width: 60px"
													:min="0" :max="line.maxQuantityToBill"
												/>
											</div>
											<div v-else class="text-right">{{ line.quantity || '-' }}</div>
											<div v-if="item.billableType === 0" class="text-right">
												<input-mask
													v-model="line.total"
													@change="fixedRateChanged(item, line, 'total')"
													class="mt-n1 d-inline-flex"
													dense hide-details
													:mask="currencyFormat"
													style="font-size: .95em; width: 90px"
												/>
											</div>
											<div v-else class="text-right">{{ currencyFormat(line.total) }}</div>
										</div>
										<div class="px-2" :class="{'py-1': line.expanded}" style="background: var(--v-navBar-base)">
											<div
												v-for="(member, ix) in line.members" :key="ix"
												class="my-1"
											>
												<v-expand-transition>
													<div v-show="line.expanded" class="grey-text" :class="{ line:  item.billableType !== 0 }">
														<div class="d-flex align-center">
															<v-checkbox
																v-if="item.billableType !== 0"
																v-model="member.selected" @change="computeUniqueHours(item)" dense hide-details
																class="inline-block mt-0 pt-0" color="primary"
															/>
															{{ member.userBlueTag }}
															<div v-if="item.billableType === 0" class="ml-1">
																<small>
																	[{{ member.startDate | formatDate }} - {{ member.endDate | formatDate }}]
																</small>
															</div>
														</div>
														<div v-if="item.billableType !== 0" class="text-right">
															{{ currencyFormat(line.rate) }}
														</div>
														<div v-if="item.billableType !== 0" class="text-right">
															{{ member.hours ? (round(member.hours, 2)) : '-' }}
														</div>
														<div v-if="item.billableType !== 0" class="text-right">
															{{ currencyFormat(member.total) }}
														</div>
													</div>
												</v-expand-transition>
											</div>
										</div>
									</div>
									<div class="line px-2 mt-1">
										<div :cols="4"><b>Totals</b></div>
										<div :cols="3"></div>
										<div :cols="2" class="text-right">{{ item.quantity | limitDecimals(2) }}</div>
										<div :cols="3" class="text-right"><b>{{currencyFormat(item.total)}}</b></div>
									</div>
								</div>
								<div class="footer mt-2 pr-2">
									<div v-if="!!item.datesError" class="error-dates text-center">
										<v-icon color="error">mdi-alert</v-icon>
										<span v-html="item.datesError"></span>
									</div>
									<div v-else></div>
									<div class="text-right">
										<div v-if="!item.saved" class="mt-5 d-flex justify-space-between">
											<v-btn @click="deleteBillableItem(item)" v-if="!item.billableItemId" text color="error">Cancel</v-btn>
											<div>
												<v-btn @click="save(item, true)" color="primary" :disabled="!item.startDate || !item.endDate || !item.total || !!item.datesError" outlined>Save as Draft</v-btn>
												<v-btn @click="save(item, false)" class="ml-2" color="primary" :disabled="!item.startDate || !item.endDate || !item.total || !!item.datesError">Submit</v-btn>
											</div>
										</div>
										<div v-else class="created-banner mt-5">
											<v-icon color="success">mdi-checkbox-marked-circle</v-icon> Billable Item Created
										</div>
									</div>
								</div>
							</v-expansion-panel-content>
						</v-expansion-panel>
					</v-expansion-panels>
				</v-expand-transition>
			</v-card-text>
		</v-card>
	</v-dialog>
</template>

<script>
import moment from "moment"
export default {
	data: () => ({
		dialog: false,
		resolve: () => {},
		assignments: [],
		assignmentsByBillable: {},
		billableItems: [],
		registeredDates: [],
		shownBillableItems: [],
	}),
	methods: {
		open(billables, startDate, endDate){
			this.billableItems = []
			this.shownBillableItems = []
			startDate = startDate || moment().format('YYYY-MM-DD')
			endDate = endDate || moment().format('YYYY-MM-DD')
			this.dialog = true
			this.billables = billables

			this.$http.post(
				'api/billableItem/build',
				{
					billableIds: billables.map(x => x.id),
					startDate,
					endDate
				}
			)
				.then(res => {
					this.billableItems = res.data
					this.billableItems.forEach(x => {
						x.lines.forEach(l => {
							l.expanded = !!l.members.length
						})
					})
					this.billableItems[0].expanded = 0
					this.computeHours()
				})

			return new Promise((resolve) => {
				this.resolve = resolve
			})
		},
		save(item, isDraft){
			const toSave = _.cloneDeep(item)
			toSave.isDraft = isDraft
			if(toSave.billableType !== 0){
				toSave.lines.forEach(x => x.members = x.members.filter(m => m.selected))
				toSave.lines = toSave.lines.filter(x => x.members.length)
			}
			else{
				toSave.lines = toSave.lines.filter(x => x.total > 0)
			}
			this.$http.post('api/BillableItem', toSave)
				.then(res => {
					item.id = res.data.id
					this.$set(item, 'saved', true)
					delete item.expanded
					this.openNext()
				})
				.catch(err => {
					console.error(err)
					this.$root.error('Error saving billable item')
				})
		},
		computeHours(){
			const billableItems = _.cloneDeep(this.billableItems)
			for(let item of billableItems){
				this.computeUniqueHours(item)
			}
			this.shownBillableItems = billableItems
		},
		computeUniqueHours(item){
			item.quantity = 0
			item.total = 0
			for(let line of item.lines){
				if(item.billableType !== 0){
					line.quantity = 0
					line.total = 0
					for(let member of line.members){
						this.$set(member, 'selected', !!member.selected || item.billableType === 0)
						if(member.selected){
							line.quantity += member.hours
							line.total += member.total
						}
					}
				}
				item.quantity += line.quantity
				item.total += line.total
			}
		},
		fixedRateChanged(item, line, change){
			line.quantity = Number(line.quantity)
			line.total = Number(line.total)
			if(change === 'quantity'){
				if(line.quantity < 0)
					line.quantity = 0
				if(line.quantity > line.maxQuantityToBill){
					line.quantity = line.maxQuantityToBill
					this.$root.error('You cannot bill more than ' + line.maxQuantityToBill + ' for this line.')
				}
				line.total = line.quantity * line.rate
			}
			else{
				line.quantity = line.total / line.rate
				if(line.quantity > line.maxQuantityToBill){
					this.fixedRateChanged(item, line, 'quantity')
					return
				}
			}
			this.computeUniqueHours(item)
		},
		loadBillableItem(item){
			if(moment(item.endDate).isBefore(item.startDate)){
				item.endDate = item.startDate
			}

			this.$http.post(
				'api/billableItem/build',
				{
					billableIds: [item.billableId],
					startDate: item.startDate,
					endDate: item.endDate
				}
			)
				.then(res => {
					let index = this.shownBillableItems.findIndex(x => x.billableId == item.billableId)
					this.$set(this.shownBillableItems, index, res.data[0])
					this.shownBillableItems[index].expanded = 0
					this.shownBillableItems[index].lines.forEach(l => {
							this.$set(l, 'expanded', !!l.members.length)
						})
					this.computeUniqueHours(this.shownBillableItems[index])
				})
		},
		selectAll(item){
			for(let line of item.lines){
				for(let member of line.members){
					this.$set(member, 'selected', true)
				}
			}
			this.computeUniqueHours(item)
		},
		openNext(){
			if(!this.shownBillableItems.some(i => !i.deleted)){
				this.cancel()
			}
			for(let item of this.shownBillableItems){
				if(!item.deleted && !item.saved){
					this.$set(item, 'expanded', 0)
					break
				}
			}
		},
		toggleExpanded(line){
			this.$set(line, 'expanded', !line.expanded)
		},
		deleteBillableItem(billableItem){
			billableItem.deleted = true
			this.openNext()
		},
		cancel(){
			this.dialog = false
			this.resolve()
		},
	},
}
</script>

<style lang="scss" scoped>
.v-expansion-panels{
	border: 1px solid lightgrey;
	margin-bottom: 15px !important;
}
.v-expansion-panel-header{
	border-bottom: 1px solid lightgrey;
	min-height: 48px;
}
.expansion-panel::before{
	box-shadow: none;
}

.head{
	.date{
		color: var(--v-text-lighten);
		display: inline-block;
	}
	.currency{
		float: right;
		color: var(--v-text-lighten);

		div{
			display: flex;
			align-items: center;
			span{
				color: var(--v-text-base);
				font-size: 20px;
			}
		}
	}
}
.line{
	display: grid;
	grid-template-columns: 2fr 1fr 1fr 1fr;
	align-items: center;
}
.grey-text{
	color: var(--v-text-lighten2);
}

.created-banner{
	color: var(--v-success-base);
	width: 100%;
	background: #e1f8dd;
	padding: 10px;
	text-align: center;
	border-radius: 5px;
	font-weight: 600;
	font-size: 1.1em;
}
</style>