<template>
	<v-dialog v-if="dialog" v-model="dialog" width="530" persistent>
		<v-card>
			<v-card-title> Project Settings </v-card-title>
			<v-card-text class="pt-5">
				<v-form @submit.prevent="addRequirement">
					<h3 class="mb-3">Requirement list</h3>
					<div class="d-flex align-center">
						<v-text-field
							v-model="requirement" outlined dense hide-details
							placeholder="Requirement (Ex. Credentials, VPN, etc.)"
						/>
						<v-btn type="submit" color="primary" class="ml-3" :disabled="!requirement">Add</v-btn>
					</div>
				</v-form>
				<div class="mt-2">
					<v-chip v-for="(req, ix) in requirements" :key="ix" outlined color="primary" class="mr-2 mb-2">
						{{req.name}}
						<v-icon @click="removeRequirement(ix)" class="cursor-pointer" right>mdi-close-circle</v-icon>
					</v-chip>
				</div>

				<div v-if="showHealthcheckReminder">
					<h3 class="mb-3 mt-5">Healthcheck reminder</h3>
					<div class="d-flex justify-space-between" style="gap: 10px">
						<div style="width: 48%">
							<label>Recurrence goal</label>
							<v-select v-model="healthcheckReminder" :items="healthcheckReminders" outlined dense/>
						</div>
						<div style="width: 48%">
							<label>Next healthcheck</label>
							<v-text-field v-model="nextHealthcheck" outlined disabled dense append-icon="event"/>
						</div>
					</div>
				</div>
			</v-card-text>
			<v-card-actions class="justify-end">
				<v-btn @click="close(false)" color="primary" outlined>Cancel</v-btn>
				<v-btn @click="save" color="primary">Confirm</v-btn>
			</v-card-actions>
		</v-card>
	</v-dialog>	
</template>

<script>
import moment from 'moment'

export default {
	props: {
		showHealthcheckReminder: { type: Boolean, default: true }
	},
	data: () => ({
		dialog: false,
		id: null,
		requirements: [],
		resolve: null,
		requirement: '',
		healthcheckReminders: [
			{ value: 0, text: 'Every two weeks' },
			{ value: 1, text: 'Every month' },
		],
		healthcheckReminder: 0,
		lastHealthcheckDate: '',
	}),
	computed: {
		nextHealthcheck(){
			const timeIncreasers = {
				0: date => moment(date).clone().add(2, 'week'),
				1: date => moment(date).clone().add(1, 'month'),
			}
			return timeIncreasers[this.healthcheckReminder](this.lastHealthcheckDate).format('MMM D, YYYY')
		},
	},
	methods: {
		open(id, healthcheckReminder, lastHealthcheckDate){
			this.healthcheckReminder = healthcheckReminder
			this.id = id
			this.dialog = true
			this.lastHealthcheckDate = lastHealthcheckDate

			this.$http.get(`api/nucleiManagement/${id}/requirements`)
				.then(res => {
					this.requirements = res.data
				})
				.catch(err => {
					console.error(err)
					this.$root.error('Cannot load data')
				})

			return new Promise(res => {
				this.resolve = res
			})
		},
		save(){
			this.$http
				.post(`api/nucleiManagement/${this.id}/config`, {
					requirements: this.requirements,
					healthcheckReminderRecurrence: this.healthcheckReminder,
				})
				.then(() => {
					this.close(true)
				})
				.catch(err => {
					console.error(err)
					this.$root.error('Cannot save changes')
				})
			this.$http.post(`api/nucleiManagement/${this.id}/requirements`, this.requirements)
				.then(() => {
					this.close(true)
				})
				.catch(err => {
					console.error(err)
					this.$root.error('Cannot save changes')
				})
		},
		addRequirement(){
			if(!this.requirement) return
			this.requirements.push({
				name: this.requirement
			})
			this.requirement = ''
		},
		removeRequirement(index){
			this.requirements = this.requirements.filter((r, ix) => ix != index)
		},
		close(val){
			this.requirement = ''
			this.dialog = false
			this.resolve(val)
		},
	},
}
</script>

<style lang="scss" scoped>

</style>