<template>
	<html-to-pdf v-if="!!item" v-model="show" :name="item.code" :prevent="!download">
		<div class="d-flex justify-space-between">
			<div>
				<label>Billable</label>
				<div class="body-1 primary--text">{{ item.billableCode }}</div>
			</div>
			<div>
				<label>Customer</label>
				<div class="body-1">{{ item.customerName }}</div>
			</div>
			<div>
				<label>Currency</label>
				<div class="body-1">
					<v-avatar size="18">
						<v-img :src="require(`../../assets/currencies/${item.billableCurrency.toLowerCase()}.png`)" />
					</v-avatar>
					{{ item.billableCurrency }}
				</div>
			</div>
			<div>
				<label>Start Date</label>
				<div class="body-1">{{ item.startDate | formatDateCustom('DD/MM/YY') }}</div>
			</div>
			<div>
				<label>End Date</label>
				<div class="body-1">{{ item.endDate | formatDateCustom('DD/MM/YY') }}</div>
			</div>
			<div>
				<label>Status</label>
				<div>
					<v-btn
						:color="item.statusColor"
						outlined small
						style="border-radius: 20px"
						:style="{
							background: $vuetify.theme.isDark ? '' : item.statusLightColor
						}"
					>
						{{item.statusDisplayName}}
					</v-btn>
				</div>
			</div>
		</div>
		<v-row class="mt-4">
			<v-col>
				<div class="bordered darken rounded-lg pa-2">
					<span>Total Billed</span>
					<div class="headline text-right">${{ item.total | money }}</div>
				</div>
			</v-col>
			<v-col>
				<div class="bordered darken rounded-lg pa-2">
					<span>Total Invoiced</span>
					<div class="headline text-right">${{ item.invoiced | money }}</div>
				</div>
			</v-col>
			<v-col>
				<div class="bordered darken rounded-lg pa-2">
					<span>Pending amount to invoice</span>
					<div class="headline text-right">${{ item.total - item.invoiced | money }}</div>
				</div>
			</v-col>
		</v-row>
		<billable-item-lines-table
			:item="item"
			class="mt-4 darken"
			expanded-by-default :new-back="false"
			userDisplayName="FullName"
		/>
		<div class="mt-5">
			<h3>Related Invoices</h3>
			<v-data-table
				:items="item.invoices"
				:headers="invoiceHeaders"
				class="bordered darken rounded-lg mt-1"
				hide-default-footer
				disable-pagination
				disable-sort
			>
				<template #[`item.invoiceDate`]="{ item }">
					{{ item.invoiceIssueDate | formatDate }}
				</template>
				<template #[`item.amount`]="{ item }">
					<span :style="{ textDecoration: item.invoiceStatus == 3 ? 'line-through' : '' }">
						{{ currencyFormat(item.amount)  }}
					</span>
				</template>
				<template #[`item.invoiceCurrency`]="{ item }">
					<div class="d-flex align-center">
						<img
							v-if="item.invoiceCurrency"
							:height="20" contain class="mr-1"
							:src="require(`../../assets/currencies/${item.invoiceCurrency.toLowerCase()}.png`)"
						/>
						<b>{{item.invoiceCurrency}}</b>
					</div>
				</template>
				<template #[`item.status`]="{ item }">
					<v-btn
						:color="item.invoiceStatusColor"
						outlined small style="border-radius: 20px"
						:style="{
							background: $vuetify.theme.isDark ? '' : item.invoiceStatusLightColor
						}"
					>{{item.invoiceStatusName}}</v-btn>
				</template>
			</v-data-table>
		</div>
	</html-to-pdf>
</template>

<script>
import BillableItemLinesTable from '../../components/admin-surf/billable-item/BillableItemLinesTable.vue'

export default {
	components: {
		BillableItemLinesTable,
	},
	data: () => ({
		show: false,
		item: null,
		download: false,
		
		invoiceHeaders: [
			{ text: 'Number', value: 'invoiceCode' },
			{ text: 'Business name', value: 'invoiceRfcName' },
			{ text: 'Invoice Date', value: 'invoiceDate' },
			{ text: 'Amount', value: 'amount' },
			{ text: 'Currency', value: 'invoiceCurrency' },
			{ text: 'Status', value: 'status' },
		],
	}),
	methods: {
		open(billableItem, download = false){
			this.download = download
			this.item = billableItem
			this.show = true
		},
	},
}
</script>

<style lang="scss" scoped>
label{
	color: var(--v-text-lighten2);
	font-size: .9em;
}
</style>