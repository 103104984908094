<template lang="">
  <v-navigation-drawer
    v-if="id && !isExternal"
    right fixed app permanent persistent
    :mini-variant="value"
    class="task-menu pb-0"
    width="350"
  >
    <div class="grid">
      <div class="text-center" style="height: 100%; border-right: 1px solid var(--v-lightgray-base); position: relative;">
        <v-list class="pt-10" style="position: sticky; top: 0;">
          <v-list-item v-for="(item,ix) in menuElements" :key="ix" class="mb-4 justify-center px-0" @click="toggleMenu(item.component)">
            <v-badge
              dark overlap
              :value="!!item.badgeContent"
              :content="item.badgeContent"
              color="pink"
            >
              <span class="material-symbols-outlined" :class="{ 'primary--text': menuElementActive == item.component }">
              {{item.icon}}
              </span>
            </v-badge>
          </v-list-item>
        </v-list>
      </div>
      <div v-show="!value">
        <component @close="$emit('input', true)" :is="menuElementActive"></component>
      </div>
    </div>
  </v-navigation-drawer>
</template>
<script>
import AsideBarWorkItems from "./AsideBarWorkItems"
import AsideBarNotes from "./AsideBarNotes"
import AsideBarSupportChat from "./AsideBarSupportChat"
import AsideBarRisks from "./AsideBarRisks"

export default {
  components: {
    AsideBarWorkItems,
    AsideBarNotes,
    AsideBarSupportChat,
    AsideBarRisks,
  },
  props: {
    id: { type: String, default: '' },
    value: { type: Boolean , default: false },
    isExternal: { type: Boolean , default: false },
  },
  data() {
    return {
      knownEvents: ['close'],
      menuElementActive: null,
      menuElements: [
        {
          value: 0,
          name: 'value',
          icon: 'add_task',
          badgeContent: null,
          component: 'aside-bar-work-items'
        },
        {
          value: 1,
          name: 'notesMenu',
          icon: 'sticky_note_2',
          badgeContent: null,
          component: 'aside-bar-notes'
        },
        // {
        //   value: 3,
        //   name: 'support',
        //   icon: 'chat_bubble',
        //   badgeContent: null,
        //   component: 'aside-bar-support-chat'
        // },
        {
          value: 2,
          name: 'risks',
          icon: 'warning',
          component: 'aside-bar-risks'
        },
      ],
    }
  },
  watch: {
    value(newVal) {
      if(newVal)
        this.menuElementActive = null
    }
  },
  methods: {
    toggleMenu(component) {
      this.$emit('input', false)
      let elementToShow = this.menuElements.find(elm => elm.component == component);
      if(!this.value && elementToShow.component == this.menuElementActive) {
        this.$emit('input', !this.value)
        return
      }
      this.menuElementActive = elementToShow.component
    },
    setPendingChats(v){
      let menuElement = this.menuElements.find(x => x.value == 3)
      menuElement.badgeContent = v
    },
  },
}
</script>

<style scoped lang="scss">
  .task-menu {
    background: var(--v-newDesignBackground-base);
    height: 100vh;
    z-index: 200;
    .grid {
      display: grid;
      grid-template-columns: 60px auto;
      height: 100%;
    }
}
</style>