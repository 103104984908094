<template>
  <div>
    <v-container class="px-1" v-show="!showCreateRisk">
      <div style="display: flex; justify-content: space-between" class="mb-2">
        <h3>Risks</h3>
        <v-icon @click="$emit('close')">mdi-close</v-icon>
      </div>
      <div
        class="col-login mx-0"
        >
        <span>Project</span>
        <v-autocomplete
          class="mt-2 mb-0"
          outlined
          dense
          hide-details
          v-model="projectId"
          ref="project" 
          :persistent-hint="true"
          :items="projects" item-value="id" item-text="name"
          :rules="[v => (!!v || v === 0) || 'Required']"
        >
          <template v-slot:item="{ item }">
            <v-list-item-content>
              <v-list-item-title>
                <span>{{item.name}}</span>
              </v-list-item-title>
              <v-list-item-subtitle v-text="item.customerProduct"></v-list-item-subtitle>
            </v-list-item-content>
          </template>
        </v-autocomplete>
      </div>
      <div>
        <h4 @click="showCreateRisk = !showCreateRisk" class="cursor-pointer py-4">
          <v-icon color="primary">add</v-icon>
          Add a Risk
        </h4>
      </div>
      <v-container class="px-0 py-1">
        <risk-card
          :ref="risk.id" v-for="(risk, ix) in risks"
          :key="ix"
          :risk="risk"
          :open="openCreateWorkItem"
          :edit="editFromTable"
          :selectedRisk="{}"
          :tempSelectedRisk="{}"
          :createdRisk="{}"
          width="285"
          :selectRiskAsTemporal="() => {}"
          :unselectRiskAsTemporal="() => {}"
          :cols="6" :md="4"
        />
      </v-container>
      <risk-work-item-dialog
        ref="riskWorkItemDialog"
        :projectId="projectId"
        :risks="risks"
        :canEditProject="canEditProject"
      />
      <project-risk-create-dialog
        :overviewView="false"
        ref="projectRiskCreateDialog"
        :projectId="projectId"
        :risks="risks"
        :canEditProject="canEditProject"
        :isEmployee="true"
      />
    </v-container>
    <aside-bar-create-risk
      v-show="showCreateRisk"
      @close="$emit('close')"
      @saved="closeCreateRisk"
      @toggleView="showCreateRisk = !showCreateRisk"
      :project="currentProject"
    />
  </div>
</template>

<script>
import moment from 'moment';
import RiskCard from './RiskCard.vue';
import RiskWorkItemDialog from "../dialogs/RiskWorkItemDialog";
import AsideBarCreateRisk from "./AsideBarCreateRisk";
import ProjectRiskCreateDialog from "../dialogs/ProjectRiskCreateDialog";

export default {
  components: {
    RiskCard,
    RiskWorkItemDialog,
    ProjectRiskCreateDialog,
    AsideBarCreateRisk,
  },
  props: ['close'],
  data: () => ({
    currentUser: {},
    projects: [],
    projectId: '',
    showCreateRisk: false,
    workItems: [],
    risks: [],
    canEditProject: true,
    workItemsToCreate: [],
  }),
  mounted() {
    this.init()
  },
  watch: {
    'projectId'(newId, oldId) {
      this.getRisks(newId)
    },
  },
  computed: {
    currentProject() {
      return this.projects.find(project => project.id == this.projectId) || {}
    },
  },
  methods: {
    init() {
      this.$blueSystem.getCurrentUser()
        .then(user => {
          this.currentUser = user
        })
      this.$http
        .get("/api/user/current/project")
        .then(res => {
          this.projects = res.data;
          this.projectId = res.data[0].id
        })
        .catch(res => {
          window.getApp.error("Cannot get records.");
          console.log(res);
        });

    },
    getRisks(id) {
      this.$http.get(`api/Project/Risks?id=${id}`).then((res) => {
        this.risks = res.data.sort((a, b) => {
          let difSeverity = b.impact * b.probability - a.impact * a.probability;
          if (!difSeverity) {
            return new Date(a.createdAt) - new Date(b.createdAt);
          }
          return difSeverity;
        });
        this.$forceUpdate();

        this.workItems = [];
        for (let risk of this.risks) {
          for (let wi of risk.workItems) {
            wi.assignedToBlueTag = wi.assignedTo
              ? wi.assignedTo.blueTag
              : "Unassinged";
            wi.statusName = wi.status.name;
            wi.dueDate = wi.dueDate
              ? moment(wi.dueDate).format("MMM DD, YYYY")
              : "";
            wi.riskCode = risk.code;
            this.workItems.push(wi);
          }
        }
        let users = {}
        this.risks.forEach(risk => {
          if(!users[risk.reporterId]) {
            users[risk.reporterId] = risk.reporter
          }
        })
      })
    },
    openCreateWorkItem(id) {
      if (!this.canEditProject || !this.projectId) return;
      this.$refs.riskWorkItemDialog.open(null, id);
    },
    closeCreateRisk() {
      this.showCreateRisk = false
      this.getRisks(this.projectId)
    },
    editFromTable(risk) {
      this.selectedRisk = risk;
      this.$refs.projectRiskCreateDialog.open(risk.id);
    },
  },
}
</script>

<style lang="scss" scoped>
.col-login {
  padding-bottom: 0px !important;
  max-height: 120px !important;

  span {
    font-weight: 400;
    color:darkgrey;
    margin-bottom: 8px;
  }
}
</style>