<template>

  <v-dialog :fullscreen="$vuetify.breakpoint.xsOnly" scrollable persistent 
    v-model="dialog" :max-width="1000" v-if="dialog">
    <v-form ref="form" v-model="validForm">
      <v-card>
        <v-card-title class="pt-2 pb-4">
          <v-btn v-if="workItemType" :min-width="130" dark x-small :color="workItemType.color" class="type-button text-capitalize mr-2">
            <v-icon x-small left>{{workItemType.iconClass}}</v-icon>
            <span>{{workItemType.displayName}}</span>
          </v-btn>
          <div class="pt-5">
            Register
            <span v-if="workItemType">
              {{workItemType.displayName}}
            </span> 
          </div>

          <v-spacer></v-spacer>
          
          <v-menu offset-y>
            <template v-slot:activator="menuActivator">
              <v-tooltip top>
                <template v-slot:activator="{ on }">
                  <span v-on="on">
                    <v-btn text small v-on="menuActivator.on" disabled>
                      <v-icon>visibility</v-icon>
                    </v-btn>
                  </span>
                </template>
                <span>Watch</span>
              </v-tooltip>
            </template>
          </v-menu>

          
          <v-menu offset-y>
            <template v-slot:activator="menuActivator">
              <v-tooltip top>
                <template v-slot:activator="{ on }">
                  <span v-on="on">
                    <v-btn text small v-on="menuActivator.on" disabled>
                      <v-icon>more_vert</v-icon>
                    </v-btn>
                  </span>
                </template>
                <span>Options</span>
              </v-tooltip>
            </template>
          </v-menu>


          <v-tooltip top>
            <template v-slot:activator="{ on }">
              <v-btn text small v-on="on" @click="cancel">
                <v-icon>close</v-icon>
              </v-btn>
            </template>
            <span>Cancel</span>
          </v-tooltip>

        </v-card-title>
        <v-card-text>
          <v-row>
            <v-col cols="12" :sm="8">

              <v-row>
                <v-col cols="6">
                  <h4>Project</h4>
                  <!-- TODO: Componentize -->
                  <v-autocomplete label="Project" single-line filled flat ref="project" 
                    v-model="workItem.projectId" :persistent-hint="true" 
                    @change="projectChanged()"
                    :disabled="disabledProject"
                    :items="projects" item-value="id" item-text="name"
                    :filter="(item, queryText, itemText) => (item.customerProduct + ': ' + item.name).toLocaleLowerCase().indexOf(queryText.toLocaleLowerCase()) > -1">
                    <template v-slot:selection="{ item }">
                      <category-chip small :code="item.customerProduct" :text="item.name"></category-chip>
                    </template>
                    <template v-slot:item="{ item }">
                      <v-list-item-content>
                        <v-list-item-title>
                          <span>{{item.name}}</span>
                        </v-list-item-title>
                        <v-list-item-subtitle v-text="item.customerProduct"></v-list-item-subtitle>
                      </v-list-item-content>
                    </template>
                  </v-autocomplete>
                </v-col>
                <v-col cols="6">
                  <h4>Type</h4>
                  <!-- TODO: componentize -->
                  <v-autocomplete label="Type" :items="types" item-value="value" item-text="displayName"
                    @change="typeChanged" v-model="workItem.type" single-line filled flat
                    :disabled="createItemForWorkItem"
                  >
                    <template v-slot:item="{ item }">
                      <v-icon :color="item.color" class="mr-2">{{item.iconClass}}</v-icon>
                      {{item.displayName}}
                    </template>
                    <template v-slot:selection="{ item }">
                      <v-icon :color="item.color" class="mr-2">{{item.iconClass}}</v-icon>
                      {{item.displayName}}
                    </template>
                  </v-autocomplete>
                </v-col>
              </v-row>

              <h4>Summary</h4>
              <v-text-field ref="summaryInput" label="Summary*" v-model="workItem.name" required :rules="[v => !!v || 'Summary is required']" single-line filled flat></v-text-field>

              <h4>Description</h4>
              <rich-editor v-model="workItem.description" :handlePaste="handlePaste" :user-suggestions="users"></rich-editor>
              <br>

              <div class="mb-3">
                <h4>Acceptance Requirements</h4>
                <div class="d-flex justify-space-between align-center my-1">
                  <span
                    v-if="workItem.acceptanceRequirements && workItem.acceptanceRequirements.length"
                    class="body-1"
                  >
                    {{ acceptancePercentage }}% Complete
                  </span>
                  <span v-else class="body-1">No acceptance requirements</span>
                  <v-btn
                    @click="openRequirementDialog()"
                    text color="primary"
                  >
                    <v-icon color="primary">mdi-plus</v-icon>
                    New Action
                  </v-btn>
                </div>
                <v-expansion-panels>
                  <draggable v-model="workItem.acceptanceRequirements" class="rounded-lg pb-1" style="width: 100%">
                    <v-expansion-panel
                      v-for="(requirement, ix) in workItem.acceptanceRequirements" :key="ix"
                      class="bordered rounded-lg mt-0" :class="{ 'remove-border-bottom': workItem.acceptanceRequirements.length-1 != ix }"
                    >
                      <v-expansion-panel-header class="py-0">
                        <div>
                          <v-checkbox
                            v-model="requirement.done"
                            @click.stop :label="requirement.name"
                            hide-details class="mt-0 inline-block"
                          />
                        </div>
                      </v-expansion-panel-header>
                      <v-expansion-panel-content>
                        <span v-html="requirement.description"></span>
                        <div class="text-right">
                          <v-btn @click="deleteRequirement(ix)" small outlined color="error">
                            <v-icon small>mdi-delete</v-icon>
                            Delete
                          </v-btn>
                          <v-btn @click="openRequirementDialog(requirement, ix)" small outlined class="ml-1" color="primary">
                            <v-icon small>mdi-pencil</v-icon>
                            Edit
                          </v-btn>
                        </div>
                      </v-expansion-panel-content>
                    </v-expansion-panel>
                  </draggable>
                </v-expansion-panels>
              </div>

              <template v-if="workItem.type == 1">
                <h4>Steps to Reproduce</h4>
                <rich-editor v-model="workItem.stepsToReproduce" :handlePaste="handlePaste"></rich-editor>
              <br>
                <h4>Expected Result</h4>
                <rich-editor v-model="workItem.expectedResult" :handlePaste="handlePaste"></rich-editor>
                <br>
              </template>

              <linked-work-items
                :type="1"
                :linked="linkedBugs"
                title="Bugs"
                :projectConfig="projectConfig"
                :projects="projects"
                :linkRelationships="linkRelationships"
                :save="saveLink"
                :open="openRelatedWorkItem"
              />
              <linked-work-items
                :linked="linkedWorkItems"
                title="Work items"
                class="mt-5"
                :projectConfig="projectConfig"
                :projects="projects"
                :linkRelationships="linkRelationships"
                :save="saveLink"
                :open="openRelatedWorkItem"
              />

              <!-- Files & Images Begin -->
              <h4 class="mt-5">Files & Images</h4>
              <p v-if="!files || !files.length">
                <i>No files or images</i>
              </p>
              <v-row wrap class="files pa-2">
              <div v-for="(wiFile, ix) in files"  :key="ix">
                <v-tooltip  top >
                  <template v-slot:activator="{ on }">
                    <div class="relative-ctn2 img-container" >
                      <v-img
                        v-on="on"
                        v-show="!isFile(wiFile)"
                        class="img ma-1 cursor-pointer small relative-ctn2"
                        @click="openImage(wiFile, $event)"
                        :src="getImageUrl(wiFile)"
                      >
                      </v-img>
                      <v-btn
                        color="red"
                        class="icon-cont"
                        @click.stop="removeFile('files', ix)" 
                        x-small
                        dark
                      >
                        <v-icon small>delete</v-icon>
                      </v-btn>
                    </div>
                    <div 
                      v-on="on"
                      class="relative-ctn2 file-container"
                      v-show="isFile(wiFile)"
                    >
                      <v-btn
                        color="red"
                        class="icon-cont second-delete"
                        @click.stop="removeFile('files', ix)"
                        x-small
                        dark
                      >
                        <v-icon small>delete</v-icon>
                      </v-btn>
                      <img
                        src="@/assets/pdf.png"
                        v-if="equalsFileType(wiFile.name, ['pdf'])"
                        class="img ma-1 pa-1 cursor-pointer small"
                      >
                      <img
                        src="@/assets/xls.png"
                        v-else-if="equalsFileType(wiFile.name, ['xls', 'xlsx'])"
                        class="img ma-1 pa-1 cursor-pointer small"
                      >
                      <img
                        src="@/assets/docx.png"
                        v-else-if="equalsFileType(wiFile.name, ['docx'])"
                        class="img ma-1 pa-1 cursor-pointer small"
                      >
                      <img
                        v-else
                        class="img ma-1 pa-1 cursor-pointer small"
                        src="@/assets/file.png"
                      >
                    </div>
                  </template>
                  <span>{{ wiFile.name }}</span>
                </v-tooltip>

              </div>
              <div @click="$refs.newImage.click()" class="small img ma-1 add-file-button cursor-pointer">
                <v-icon color="primary" large>add</v-icon>
              </div>
              </v-row>
              <input class="d-none" type="file" id="newImage" ref="newImage" v-on:change="addImage()"/>

              <h4 class="mt-5">Videos</h4>
              <div class="d-flex flex-column" style="gap: 10px">
                <div
                  v-for="(video, ix) in videos" :key="ix"
                  class="video d-flex align-stretch"
                >
                  <video width="500" controls="controls">
                    <source :src="video.url" type="video/mp4">
                  </video>
                  <div class="actions d-flex align-center">
                    <v-btn @click="downloadVideo(video.url)" color="primary" large icon>
                      <v-icon>mdi-download</v-icon>
                    </v-btn>
                    <v-btn @click="removeVideo(ix)" color="error" large icon>
                      <v-icon>mdi-delete</v-icon>
                    </v-btn>
                  </div>
                </div>
                <div>
                  <div v-if="!this.recording" @click="recordScreen" class="small img add-file-button ma-0 cursor-pointer" style="width: 70px; height: 70px;">
                    <v-icon color="primary" large>mdi-video-plus-outline</v-icon>
                  </div>
                  <div v-else class="d-flex align-center px-2" style="gap: 10px">
                    <div id="record"></div>
                    <div id="counter">
                      <h1>{{ formatTime(recordingTime/60) }}:{{ formatTime(recordingTime%60) }}</h1>
                    </div>
                  
                    <div @click="closePanel" class="d-flex flex-column align-center cursor-pointer ml-5">
                      <span
                        style="font-size: 2.5em; color: #167bd7"
                        class="material-symbols-outlined"
                      >
                        stop
                      </span>
                      Stop
                    </div>
                  </div>
                </div>
              </div>
              
              <h4 class="mt-5">Tags</h4>
              <p v-if="!currentTags || !currentTags.length">
                <i>No tags</i>
              </p>
              <div v-else>
                <v-chip 
                  v-for="(tag, ix) in currentTags" :key="ix"
                  class="showed-tag"
                  :style="{ color: isBlackText(tag.tagColor) ? 'white' : 'black' }"
                  small dark :color="tag.tagColor"
                >
                  <v-icon small left>check</v-icon>
                  {{tag.tagName}}
                </v-chip>
              </div>
              <!-- <v-chip
                  class="showed-tag"
                  :style="{ color: 'white' }"
                  small dark :color=""
                >
                  <v-icon small left>check</v-icon>
                  Add new Ta
                </v-chip>
              <br> -->

              <v-row>

                <v-col cols="3">
                  <v-menu open-on-hover bottom offset-x :close-on-content-click="false">
                    <template v-slot:activator="{ on }">
                      <v-list-item
                        v-on="on" class="pr-2 color-tags"
                        :style="{background: workItem.boardColor, color: isBlackText(workItem.boardColor) ? 'white' : 'black'}"
                      >
                        <v-icon left :color="isBlackText(workItem.boardColor) ? '#eee' : 'black'">invert_colors</v-icon>
                        <v-list-item-content>
                          <v-list-item-title>Color</v-list-item-title>
                        </v-list-item-content>
                        <v-list-item-action>
                          <v-icon :color="isBlackText(workItem.boardColor) ? '#eee' : 'black'">chevron_right</v-icon>
                        </v-list-item-action>
                      </v-list-item>
                    </template>
                    <color-picker
                      @update:color="colorChanged"
                      v-model="workItem.boardColor"
                      :workItem="true"
                    ></color-picker>
                  </v-menu>
                </v-col>

                <v-col cols="3">
                  <v-menu open-on-hover bottom offset-x :close-on-content-click="false">
                    <template v-slot:activator="{ on }">
                      <v-list-item v-on="on" class="pr-2 color-tags">
                        <v-icon left color="text">label</v-icon>
                        <v-list-item-content>
                          <v-list-item-title>Tags</v-list-item-title>
                        </v-list-item-content>
                        <v-list-item-action>
                          <v-icon color="">chevron_right</v-icon>
                        </v-list-item-action>
                      </v-list-item>
                    </template>

                    <v-list dense class="py-1 tags-container">
                      <v-list-item @click="openTagDialog">
                        <v-list-item-content>
                          <v-list-item-title>
                            <v-chip
                              :style="{ display: 'block', cursor: 'pointer', color: 'black' }" small dark color="white"
                            >
                              <v-icon small left>add</v-icon>
                              Create tag
                            </v-chip>
                          </v-list-item-title>
                        </v-list-item-content>
                      </v-list-item>
                      <v-list-item v-if="!tags || !tags.length">
                          <v-list-item-title>
                            <i>No tags available</i>
                          </v-list-item-title>
                      </v-list-item>
                      <v-tooltip right :disabled="!tag.description"  v-for="(tag, ix) in tags" :key="ix">
                        <template v-slot:activator="{ on }">
                          <v-list-item v-on="on"
                              @click="toggleTag(tag)">
                            <v-list-item-content>
                              <v-list-item-title>
                                <v-chip
                                  :style="{ display: 'block', cursor: 'pointer', color: isBlackText(tag.color) ? 'white' : 'black' }"
                                  small dark :color="tag.color"
                                >
                                  <v-icon small left v-if="currentTags && currentTags.some(t => t.tagId == tag.id)">check</v-icon>
                                  <v-icon small left v-else color="grey lighten-1">clear</v-icon>  
                                  {{tag.name}}
                                </v-chip>
                              </v-list-item-title>
                            </v-list-item-content>
                          </v-list-item>
                        </template>
                        <div style="white-space:pre-wrap; word-break:break-word; max-width: 280px;" v-html="tag.description ? JSON.parse(tag.description) : ''"></div>
                      </v-tooltip>
                    </v-list>
                  </v-menu>
                </v-col>

              </v-row>

            </v-col>
            <v-col :style="{ maxWidth: '300px' }">
              <h4>Status</h4>
              <v-select label="Status" v-model="workItem.statusId" item-value="id" item-text="name" :items="statuses"  single-line filled flat>
                <template v-slot:item="{ item }">
                  <v-chip class="cursor-pointer" label :style="{ width: '100%' }" dark :color="item.color">
                    <v-icon class="mr-2">{{item.iconClass}}</v-icon>
                    {{item.name}}
                  </v-chip>
                </template>
                <template v-slot:selection="{ item }">
                  <v-icon :color="item.color" class="mr-2">{{item.iconClass}}</v-icon>
                  {{item.name}}
                </template>
              </v-select>

              <h4>Assigned to</h4>
              <q-user-autocomplete
                class="radious-shadow mb-2"
                v-model="workItem.assignedToId" :items="users" :disabled="isPersonal" solo flat
                item-value="id" item-text="blueTag" item-subtitle="fullName"
                style="background: var(--v-background-lighten2)"
                handleEsc handleSave
              />

              <h4>Reported by</h4>
              <q-user-autocomplete
                handleEsc handleSave
                class="radious-shadow mb-2"
                v-model="workItem.reportedById" :items="users.filter(user => user.id !== null)" :disabled="isPersonal" solo flat
                item-value="id" item-text="blueTag" item-subtitle="fullName"
                style="background: var(--v-background-lighten2)"
              />

              <v-row>
                <v-col class="pr-1" cols="6">
                  <h4>Priority</h4>
                  <v-select label="Priority" class="priority" :items="priorities" item-value="value" item-text="displayName" v-model="workItem.priority" single-line filled flat>
                    <template #item="{ item }">
                      <v-img class="mr-2" style="flex: none" width="24px" height="24px" :src="require(`../assets/priority-icons/${item.value}.png`)" contain></v-img>
                      {{item.displayName}}
                    </template>
                    <template #selection="{ item }">
                      <v-img class="mx-2" style="flex: none" width="24px" height="24px" :src="require(`../assets/priority-icons/${item.value}.png`)" contain></v-img>
                      {{item.displayName}}
                    </template>
                  </v-select>
                </v-col>
                <v-col class="pl-1" cols="6">
                  <h4>Value</h4>
                  <v-select label="Value" class="priority" :items="priorities" item-value="value" item-text="displayName" v-model="workItem.value" single-line filled flat>
                    <template #item="{ item }">
                      <v-img class="mr-2" style="flex: none" width="24px" height="24px" :src="require(`../assets/priority-icons/${item.value}.png`)" contain></v-img>
                      {{item.displayName}}
                    </template>
                    <template #selection="{ item }">
                      <v-img class="mx-2" style="flex: none" width="24px" height="24px" :src="require(`../assets/priority-icons/${item.value}.png`)" contain></v-img>
                      {{item.displayName}}
                    </template>
                  </v-select>
                </v-col>
              </v-row>

              <v-row>
                <v-col 
                  class="py-0"
                  :cols="workItem.type == 1 ? 6 : 12"
                >
                <h4>Sprint</h4>
                <v-select
                  v-model="currentSprints"
                  label="Sprint" item-text="name" item-value="id"
                  :items="projectConfig.sprints"
                  single-line filled flat multiple
                />
              </v-col>
              <v-col 
                class="py-0"
                cols="6"
                v-if="workItem.type == 1"
              >
                <h4>Source*</h4>
                <v-select label="Source*" v-model="workItem.defectOriginId" single-line filled flat item-text="name" item-value="id" :items="projectConfig.defectsOrigins" :rules="[v => !!v || 'Required']"></v-select>
              </v-col>
              </v-row>

              <h4>Estimated dev effort</h4>
              <estimated-dev-effort @change="(value) => workItem.estimatedEffort = value">
                <template #[`menu.activator`]="{ on }">
                    <span v-on="on">
                      <v-text-field
                        type="number"
                        :value="workItem.estimatedEffort"
                        single-line
                        filled
                        readonly
                        flat
                      ></v-text-field>
                    </span>
                  </template>
              </estimated-dev-effort>

              <template v-if="workItem.type === 3">
                <h4>Due Date</h4>
                <q-date-picker
                  v-model="workItem.dueDate"
                  single-line filled flat inner
                />
              </template>
            </v-col>
          </v-row>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions class="px-6">
          <v-spacer></v-spacer>
          <v-checkbox
            :disabled="createItemForWorkItem"
            v-if="!isBased"
            class="mx-3"
            label="Create another"
            v-model="createAnother"
          >
          </v-checkbox>
          <v-btn color="primary" text @click="cancel">
              Cancel
          </v-btn>
          <v-btn color="primary" depressed @click="save" :disabled="!validForm">
              Create
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-form>
    <create-tag-dialog
      ref="createTagDialog"
      :callback="addNewTagToWorkItem"
    >
    </create-tag-dialog>
    <requirement-dialog ref="requirementDialog" :users="users"/>
    <image-carousel ref="imageCarousel"/>
  </v-dialog>
</template>


<script>
import moment from 'moment'
import _ from "lodash"
import RichEditor from "./../components/RichEditor";
import EstimatedDevEffort from '../components/EstimatedDevEffort.vue';
import CreateTagDialog from './CreateTagDialog'
import colors from '../json/colors.json'
import RequirementDialog from '../dialogs/RequirementDialog'
import draggable from 'vuedraggable'
import ImageCarousel from '../components/ImageCarousel';
import LinkedWorkItems from '../components/LinkedWorkItems'

  export default {
    components: {
      RichEditor,
      CreateTagDialog,
      RequirementDialog,
      draggable,
      EstimatedDevEffort,
      ImageCarousel,
      LinkedWorkItems,
    }, 
    props: [
      'isPersonal',
      'createItemForWorkItem'
    ],
    created() {
      window.addEventListener("keydown", this.keyPress)
    },
    destroyed() {
      window.removeEventListener("keydown", this.keyPress)
    },
    data: () => ({
      dialog: false,
      resolve: null,
      reject: null,
      types: [],
      priorities: [],
      projects: [],
      users: [],
      projectConfig: {},
      workItem: {
        assignedToId: null,
        acceptanceRequirements: [],
      },
      currentUser: null,
      createAnother: false,
      createdOne: false,
      validForm: false,
      disabledProject: false,
      defaults: null,
      addingLink: false,
      linkRelationships: [],
      projectWorkItems: [],
      linkDisplayNames: {
        isBlockedBy: { DisplayName: "is blocked by", ReverseName: "blocks" },
        blocks: { DisplayName: "blocks", ReverseName: "isBlockedBy" },
        isClonedBy: { DisplayName: "is cloned by", ReverseName: "cloned" },
        cloned: { DisplayName: "clones", ReverseName: "isClonedBy" },
        isDuplicatedBy: { DisplayName: "is duplicated by", ReverseName: "duplicates" },
        duplicates: { DisplayName: "duplictes", ReverseName: "isDuplicatedBy" },
        splitFrom: { DisplayName: "split from", ReverseName: "splitTo" },
        splitTo: { DisplayName: "split to", ReverseName: "splitFrom" },
        isCausedBy: { DisplayName: "is caused by", ReverseName: "causes" },
        causes: { DisplayName: "causes", ReverseName: "isCausedBy" },
        relatesTo: { DisplayName: "relates to", ReverseName: "relatesTo" },
      },
      files: [],
      videos: [],
      currentTags: [],
      currentSprints: [],
      isBased: false,
      recordPanel: null,
      recording: false,
      recordingTime: 0,
      recordingInterval: null,
    }),
    computed: {
      acceptancePercentage(){
        const requirements = this.workItem.acceptanceRequirements
        return Math.round(requirements.filter(x => x.done).length / requirements.length * 10000) / 100
      },
      linkedBugs() {
        return this.workItem.linkedWorkItems?.filter(wi => wi.relatedWorkItemType == 1) || []
      },
      linkedWorkItems() {
        return this.workItem.linkedWorkItems?.filter(wi => wi.relatedWorkItemType != 1) || []
      },
      workItemType() {
        let type = this.types.find(t => t.value === this.workItem.type)
        return type
      },
      statuses() {
        if (!this.projectConfig || !this.projectConfig.workItemStatuses) return []
        let statuses = this.projectConfig.workItemStatuses.filter(s => s.workItemType === this.workItem.type)
        return statuses
      },
      tags() {
        if (!this.projectConfig || !this.projectConfig.tags || !this.workItem) return []
        let tags = _.orderBy(this.projectConfig.tags, ['sortIndex'])
        return tags.filter(t => t.types.includes(this.workItem.type) && t.active)
      },
    },
    watch: {
      "workItem.type"() {
        // if current status does not corresponds to its type, select default status for type
        let currentStatus = this.statuses.find(s => s.id == this.workItem.statusId)
        if (!currentStatus || currentStatus.workItemType != this.workItemType.type) {
          this.workItem.statusId = this.statuses.filter(s => s.isDefault).map(s => s.id)[0]
        }
      },
    },
    methods: {
      openRequirementDialog(requirement, ix){
        if(!this.workItem.acceptanceRequirements) this.$set(this.workItem, 'acceptanceRequirements', [])
        this.$refs.requirementDialog
          .open(requirement)
          .then(updated => {
            if(!requirement) {
              this.workItem.acceptanceRequirements.push(updated)
            }
            else{
              this.workItem.acceptanceRequirements[ix] = updated
            }
            this.$forceUpdate()
          })
      },
      deleteRequirement(index){
        this.$openConfirmDialog({
          title: 'Delete confirmation',
          text: 'Are you sure you want to delete this requirement?',
        })
          .then(res => {
            if(!res) return
            this.workItem.acceptanceRequirements = this.workItem.acceptanceRequirements
              .filter((x, ix) => ix != index)
          })
      },

      defaultColorInNewColorTemplate(color) { //temporary while the color transition its done
        return colors.workItems.indexOf(color) != -1
      },
      equalsFileType(filename, filetype){
        filetype = filetype.map(type => type.toLowerCase())
        if(!filename)
          return false;
        filename = filename.split('.');
        return filetype.indexOf(filename[filename.length - 1]) != -1
      },
      addNewTagToWorkItem(tag) {
        this.toggleTag(tag, true)
      },
      openTagDialog() {
        this.$refs.createTagDialog.open(this.workItem.projectId, null, this.workItem.type)
      },
      openRelatedWorkItem(link){
        let defaults = {
          projectId: link.relatedProjectId,
          openCreateDialog: () => {}
        }
        this.$refs.relatedWorkItem.open(link.relatedWorkItemCode, defaults)
          .finally(() => {
            this.$http
              .get(`/api/workItem/${this.workItem.id}`)
              .then(res => {
                this.workItem = res.data;
              })
          })
      },
      saveLink(relationship, relatedWorkItemId, linked) {
        if(!linked) {
          this.workItem.linkedWorkItems = this.workItem.linkedWorkItems
            .filter(lwi => lwi.relationship !== relationship || lwi.relatedWorkItemId !== relatedWorkItemId)
          return
        }

        this.newLink = {
          relationship,
          relatedWorkItemId,
        }
        this.addLink()
      },
      keyPress(evt) {
        let ctrlDown = evt.ctrlKey || evt.metaKey
        let vKey = evt.key == 'v' || evt.code == 'KeyV' || evt.which == 86 || evt.keyCode === 86
        if(ctrlDown && vKey && this.dialog){
          navigator.permissions.query({ name: "clipboard-read" }).then(res => {
            if(res.state == 'granted' || res.state == 'prompt'){
              navigator.clipboard.read().then(data => {
                this.handlePaste({clipboardData: {items: data}})
              })
            }
          })
        }
      },
      toggleTag(tag, newTag){
        let hasTag = this.currentTags.some(t => t.tagId == tag.id)
        if (hasTag) {
          this.currentTags = this.currentTags.filter(t => t.tagId != tag.id)
        } else {
          // temporary tag
          this.currentTags.push({
            tagId: tag.id,
            tagName: tag.name,
            tagColor: tag.color,
            tagSortIndex: tag.sortIndex,
          })
          if(newTag) this.tags.unshift(tag)
        }
      },
      colorChanged(color){
        if (!this.workItem) {
          console.error("No context work item")
        }
        if (!color) {
          color = null
        } else {
          color = color.hexa || color
        }
        this.workItem.boardColor = color
      },
      deleteLink(link, index, $event) {
        this.workItem.linkedWorkItems = this.workItem.linkedWorkItems.filter((linkeds, i) => i != index)
      },
      getWorkItemLink(code) {
        let projectCode = code.substring(0, code.lastIndexOf("-"))
        let link = `/project/${projectCode}/board?wi=${code}`
        return link
      },
      addLink(baseWorkItem) {
        this.addingLink = false
        let relatedWorkItem = this.projectWorkItems.filter(wi => wi.id == this.newLink.relatedWorkItemId)[0]
        console.log(relatedWorkItem)
        if(baseWorkItem && baseWorkItem.code && baseWorkItem.statusName){
          relatedWorkItem = baseWorkItem
        }
        let toAdd = {
          relationship: this.newLink.relationship,
          relatedWorkItemId: this.newLink.relatedWorkItemId,
          relatedWorkItemCode: relatedWorkItem.code,
          relationshipDisplayName: this.linkDisplayNames[this.newLink.relationship].DisplayName,
          relatedWorkItemTypeColor: relatedWorkItem.typeColor,
          relatedWorkItemTypeIconClass: relatedWorkItem.typeIconClass,
          relatedWorkItemStatusIconClass: relatedWorkItem.statusIconClass,
          relatedWorkItemStatusName: relatedWorkItem.statusName,
          relatedWorkItemStatusColor: relatedWorkItem.statusColor,
          relatedWorkItemName: relatedWorkItem.name,
          relatedWorkItemType: relatedWorkItem.type,
        }
        this.workItem.linkedWorkItems.push(toAdd)
      },
      isFile(file) {
        return !file.type.includes('image')
      },
      addImage(file = this.$refs.newImage.files[0], isImage = false){
        let isFile = this.isFile(file) && !isImage;
        if(!isFile)
          file.big = false;
        this.files.push(file)
        this.$refs.newImage.value = null
      },
      openImage(selected){
        let allImages = this.files.filter(file => file.type.includes('image'))
        for(let img of allImages){
          img.url = this.getImageUrl(img)
        }
        if(!selected){
          if(!allImages.length){
            this.$refs.imageCarousel.cancel()
            return
          } else {
            selected = allImages[0]
          }
        }
        selected.url = this.getImageUrl(selected)
        this.$refs.imageCarousel.open(allImages, selected, this.deleteImage)
      },
      setBig(wiFile, $event){
        this.$set(wiFile, 'big', !wiFile.big)
        if($event.target.parentElement.className.includes('big'))
          $event.target.parentElement.className = 'v-image v-responsive img ma-1 cursor-pointer theme--light small'
        else
          $event.target.parentElement.className = 'v-image v-responsive img ma-1 mr-2 cursor-pointer theme--light big'
      },
      getImageUrl(image){
        if(!image.url)
          image.url = URL.createObjectURL(image)
        return image.url
      },
      removeFile(from ,index){
        this[from] = this[from].filter((file, i) => i != index)
      },
      handleImageUpload(file = this.$refs.newImage.files[0]){
        let isFile = !file.type.includes('image')
        let formData = new FormData()
        formData.append('file', file)
        this.$http.post(`/api/workItem/${this.workItem.id}/${isFile ? 'file' : 'image'}`,
            formData,
            {
              headers: {
                'Content-Type': 'multipart/form-data'
              }
            }
        ).then(async () => {
          window.getApp.success(`${isFile ? 'file' : 'image'} uploaded.`)
        })
        .catch(() => {
          window.getApp.error(`Cannot upload ${isFile ? 'file' : 'image'}.`)
      })
      },
      handlePaste(pasteEvent){
        var item = pasteEvent.clipboardData.items[0];
        if (item.types.some(type => type.includes("image")))
          item.getType("image/png").then((blob) => {
            blob.name = 'image.jpg'
            var file = new File([blob], "image.jpg", { type: "image/jpeg" })
            this.addImage(file, true)
          });
      },

      open(defaults, socket) {
        this.socket = socket;
        this.defaults = defaults;
        this.init();

        setTimeout(() => {
          this.$refs.summaryInput.focus()
        }, 500)

        // Open dialog and return promise
        this.dialog = true
        return new Promise((resolve, reject) => {
          this.resolve = resolve
          this.reject = reject
        })
      },
      typeChanged(type){
        if (this.projectConfig) {
          let curBoard = this.projectConfig.boardConfigs.find(b => b.workItemType == type)
          if(curBoard && curBoard.defaultColor) {
            if(this.defaultColorInNewColorTemplate(curBoard.defaultColor)) 
              this.workItem.boardColor = curBoard.defaultColor
            else
              this.workItem.boardColor = '#fff'
          } else {  
            this.workItem.boardColor = '#fff'
          }
        }
      },
      projectChanged() {

        this.projectConfig = {}

        this.$http
          .get(`/api/project/${this.workItem.projectId}/config?active=true`)
          .then(res => {
            this.projectConfig = res.data
            if (this.projectConfig && this.projectConfig.workItemStatuses && !this.workItem.statusId) {
              let defaultStatus = this.projectConfig.workItemStatuses.find(s => 
                s.workItemType === this.workItem.type
                && s.isDefault)
              if (defaultStatus) {
                this.workItem.statusId = defaultStatus.id
              }
              let curBoard = this.projectConfig.boardConfigs.find(b => b.workItemType == this.workItem.type)
              if(curBoard && curBoard.defaultColor) {
                if(this.defaultColorInNewColorTemplate(curBoard.defaultColor)) 
                  this.workItem.boardColor = curBoard.defaultColor
              }
            }
          })
          .catch(res => {
            window.getApp.error("Cannot get project sprints, tags and status.")
            console.error(res)
          })
      },
      async init() {
        this.currentTags = []
        this.addingLink = false
        this.workItem = {
          type: Number.isInteger(this.type) ? this.type : 1, // bug
          priority: 3, // medium
          value: 3, // medium
          description: '',

          // preserve previous data
          assignedToId: null,
          linkedWorkItems: [],
          boardColor: '#ffffff',
        }
        if(this.defaults.createFrom){
          this.isBased = true
          let baseId = this.defaults.createFrom
          try{
            let res = await this.$http.get(`/api/workItem/${baseId}`)
            let baseWorkItem = res.data;
            this.workItem = {
              projectId: baseWorkItem.projectId,
              name: baseWorkItem.name,
              type: Number.isInteger(baseWorkItem.type) ? baseWorkItem.type : 1, // bug
              priority: baseWorkItem.priority,
              description: baseWorkItem.description,
              sprintId: baseWorkItem.sprintId,
              estimatedEffort: baseWorkItem.estimatedEffort,
              assignedToId: baseWorkItem.assignedToId,
              linkedWorkItems: [],
              boardColor: baseWorkItem.boardColor,
            }
            this.currentTags = baseWorkItem.tags
            
            this.newLink = {
              relatedWorkItemId: baseWorkItem.id,
              relationship: "isClonedBy"
            }
            this.addLink(baseWorkItem)
          }catch(e){
            window.getApp.error("Cannot get base work item.")
            console.error(e.message)
          }
        }
        if (this.defaults) {
          if(this.defaults.sprintId) {
            let sprintId = this.defaults.sprintId
            delete this.defaults.sprintId
            this.currentSprints = [sprintId]
          }
          Object.assign(this.workItem, this.defaults)
        }

        this.disabledProject = this.defaults && !!this.defaults.projectId

        if (this.workItem.projectId) {
          this.projectChanged();
        }

        // Get current user
        this.$blueSystem.getCurrentUser()
          .then(user => {
            this.currentUser = user
            this.workItem.reportedById = this.currentUser.id
          })
        this.$http
          .get(`/api/project/${this.workItem.projectId}/workItem`)
          .then(async (res) => {
            let workItems = res.data
            workItems.forEach(wi => {
              let status = this.projectConfig.workItemStatuses.find(s => s.id === wi.statusId)
              if (!status) return

              wi.statusIconClass = status.iconClass
              wi.statusColor = status.color
              wi.statusName = status.name
              wi.statusIsFinal = status.isFinal
            })

            this.projectWorkItems = workItems
              .sort((a, b) => {
                if(a.type === b.type)
                  return moment(a.createdAt).isBefore(b.createdAt) ? 1 : -1
                if (a.type < b.type) return -1
                if (a.type > b.type) return 1
              })
          })
          .catch(res => {
            window.getApp.error("Cannot get work items.")
            console.error(res)
          })

        this.$http.get(`/api/Enums/WorkItemType`)
          .then(res => {
            this.types = res.data;
            if(this.isPersonal)
              this.types = this.types.filter(type => type.value == 3)
          })
          .catch(res => {
            window.getApp.error("Cannot obtain types.")
            console.error(res)
          })

        this.$http.get(`/api/Enums/WorkItemPriority`)
          .then(res => {
            this.priorities = res.data
          })
          .catch(res => {
            window.getApp.error("Cannot obtain priorities.")
            console.error(res)
          })

        this.$http
          .get("/api/Project")
          .then(res => {
            this.projects = res.data
            if(this.defaults.projectId){
              this.workItem.projectId = this.projects.find(p => p.smallCode == this.defaults.projectId)?.id
            }
          })
          .catch(res => {
            window.getApp.error("Cannot get projects.")
            console.error(res)
          })

        this.$http
          .get(`/api/workItem/options/linking`)
          .then(async (res) => {
            this.linkRelationships = res.data
          })
          .catch(res => {
            window.getApp.error("Cannot get linking options.")
            console.error(res)
          })

        if(!this.isPersonal){
          this.users = []
          const membersPr = this.$http
            .get(`/api/project/${this.workItem.projectId}/members`)
            .then(res => {
              let members = res.data
              this.users = this.users.concat(
                members.map(member => {
                  if(!member.user.blueTag)
                    member.user.blueTag = member.user.fullName
                  return member.user
                })
              )            
            })
            .catch(res => {
              console.log(res)
              window.getApp.error("Cannot obtain project members.")
            })
          const billableMembersPr = this.$http.get(`api/nucleiManagement/${this.workItem.projectId}/members`)
            .then((res) => {
              this.users = this.users.concat(
                res.data.map(x => ({
                  id: x.assignment.userId,
                  blueTag: x.assignment.userBlueTag,
                  fullName: x.assignment.userFullName,
                }))
              )
            })
            .catch(response => {
              window.getApp.error("Cannot get nuclei members.");
            })
          Promise.all([membersPr, billableMembersPr]).then(() => this.$q.addLastItem(this.users, 'Unassigned', 'blueTag'))
        } else {
          this.$blueSystem.getCurrentUser() 
            .then(user => {
              this.users = [user]
              this.workItem.assignedToId = user.id
            })
        }

      },
      notifyAndSubscribeMentions(mentions, origin){
        return this.$http.post(`api/WorkItem/${this.workItem.id}/mentions/${origin}`, mentions)
          .catch(err => {
            this.$root.error("Cannot save notify mentions.")
            console.error(err)
          })
      },
      closePanel(){
        if(this.recordPanel)
          this.recordPanel.close()
        this.recordPanel = null

        if(this.recorder.state == 'recording')
          this.recorder.stop()
        
        this.recording = false
      },

      formatTime(time){
        time = Math.floor(time)
        return time < 10 ? `0${time}` : time
      },
      
      async recordScreen(){
        const stream = await navigator.mediaDevices.getDisplayMedia({ video: true })
        const audio = await navigator.mediaDevices.getUserMedia({
          audio: {
            echoCancellation: true,
            noiseSuppression: true,
            sampleRate: 44100,
          }
        })

        stream.addEventListener('inactive', () => {
          this.closePanel()
        })
        audio.addEventListener('inactive', () => {
          this.closePanel()
        })

        let chunks = []
        const mixedStream = new MediaStream([...stream.getTracks(), ...audio.getTracks()])
        this.recorder = new MediaRecorder(mixedStream)
        this.recorder.ondataavailable = e => chunks.push(e.data)

        this.recorder.onstart = () => {
          this.recording = true
          this.recordingTime = 0
          clearInterval(this.recordingInterval)
          this.recordingInterval = setInterval(() => {
            if(!this.recording) return
            this.recordingTime++
          }, 1000)

          this.recordPanel = window.open(`${window.location.origin}/record-panel.html`, "", "popup=true, width=320, height=100, top=0, left=0")
          this.recordPanel.addEventListener('beforeunload', () => {
            this.closePanel()
          })
          this.recordPanel.addEventListener('stop', () => {
            this.closePanel()
          })
          this.recordPanel.addEventListener('pause', () => {
            this.recorder.pause()
          })
          this.recordPanel.addEventListener('resume', () => {
            this.recorder.resume()
          })
        }

        const workItemId = this.workItem.id
        const workItemCode = this.workItem.code
        this.recorder.onstop = e => {
          this.closePanel()
          const blob = new Blob(chunks, { 'type' : 'video/mp4' })
          chunks = []

          stream.getTracks().forEach(t => t.stop())
          audio.getTracks().forEach(t => t.stop())

          let file = new File([blob], moment().format('YYYY-MM-DD hh:mm') + '.mp4')
          this.videos.push({
            url: URL.createObjectURL(file),
            file: file,
          })
        }
        this.recorder.start(1000)
      },

      downloadVideo(url){
        this.$q.openNewBackendTab(url)
      },

      async removeVideo(index){
        const res = await this.$openConfirmDialog({
          title: 'Delete video',
          description: 'Are you sure you want to delete this video?',
        })
        if(!res) return
        this.videos.splice(index, 1)
      },
      cancel() {
        this.files = []
        if (this.createdOne) {
          this.resolve() 
        } else {
          this.reject()
        }
        this.dialog = false
      },
      saveVideos(){
        let errorsCount = 0
        let promises = []
        this.videos.forEach(v => {
          let formData = new FormData()
          formData.append('file', v.file)

          let promise = this.$http.post(`/api/workItem/${this.workItem.id}/video`, formData, {headers: {'Content-Type': 'multipart/form-data'}})
            .catch(err => {
              errorsCount++
            })
          promises.push(promise)
        })
        Promise.all(promises).then(() => {
          this.videos = []
          if(errorsCount)
            this.$root.error(`Error saving ${errorsCount} videos.`)
        })
      },
      save() {
        if(this.workItem.acceptanceRequirements)
          this.workItem.acceptanceRequirements.forEach((x, ix) => x.sortIndex = ix)
        let newWorkItem = JSON.parse(JSON.stringify(this.workItem))
        newWorkItem.tags = this.currentTags
          .map(x => ({
            tagId: x.tagId,
          }))
        newWorkItem.sprints = this.currentSprints
          .map(x => ({
            sprintId: x,
          }))

        const descriptionMentions = this.$q.getMentions(this.workItem.description)
        const requirementMentions = this.$q.getMentions(this.workItem.acceptanceRequirements?.flatMap(x => x.description)) || []

        this.$q.save({
          api: "/api/workitem?isPersonal=" + !!this.isPersonal,
          data: newWorkItem,
          successMsg: "Work item saved.",
          afterSuccessUrl: null,
          errorMsg: "Cannot save record.",
          afterErrorUrl: null,
        }).then(async res => {
          this.workItem = res.data
          this.createdOne = true

          if(descriptionMentions && descriptionMentions.length)
            this.notifyAndSubscribeMentions(descriptionMentions, 'Description')
          if(requirementMentions && requirementMentions.length)
            this.notifyAndSubscribeMentions(requirementMentions, 'Acceptance Requirement')

          for(let file of this.files){
            this.handleImageUpload(file)
          }
          this.files = []

          this.saveVideos()

          if(this.defaults.projectId)
            this.socket.invoke("ProjectUpdated", this.defaults.projectId, this.workItem.id)
          
          if (this.createAnother) {
            this.init()
          } else {
            this.resolve(this.workItem.id)
            this.dialog = false
          }
        })
      },
    }
  }
</script>

<style scoped lang="scss">
  ::v-deep .v-expansion-panel-header{
    min-height: 48px !important;
    &::before{
      opacity: 0 !important;
    }
  }
  ::v-deep .v-expansion-panel::before{
    box-shadow: none !important;
  }
  ::v-deep .v-expansion-panel::after{
    content: none !important;
  }
  .v-expansion-panels > div {
    width: 100%;
  }
  .remove-border-bottom{
    border-bottom: none !important;
  }

  #record{
    display: inline-block;
    width: 25px;
    height: 25px;
    border-radius: 50%;
    background-color: red;
    animation: pulse 1s infinite;
  }
  @keyframes pulse {
    0% {
      transform: scale(1);
    }
    50% {
      transform: scale(1.2);
    }
    100% {
      transform: scale(1);
    }
  }

  .tags-container{
    max-height: 400px;
    overflow-y: scroll;
  }
  
  .type-button {
    position: absolute;
    top: 0;
    left: 0;
    border-radius: 0 0 5px 0;

    ::v-deep .v-btn__content {
      justify-content: left;
    }
  }

  .user-tile {
    border-bottom: 1px solid #949494 !important;
  }

  .files .img {
    transition: all 200ms;
    width: 100%;
    max-height: 200px;
    min-height: 30px;
    border-radius: 3px;
    border: 1px solid #cccccc;
  }

  .files .img .delete-btn {
    display: none;
  }

  .files .img.big:hover .delete-btn {
    position: absolute;
    display: inline-block;
    top: 1px;
    right: 3px;
  }

  .files .img.small {
    max-width: 70px;
    max-height: 70px;
    height: 70px;
    border: 1px solid #cccccc;
  }


  .files .file-card {

    transition: all 200ms;
    width: 100%;
    max-height: 400px;
    min-height: 30px;
    height: 70px;
    border-radius: 3px;
    border: 1px solid #cccccc;

    &.big:hover .delete-btn {
      position: absolute;
      display: inline-block;
      top: 1px;
      right: 3px;
    }

    &.small {
      max-width: 70px;
      max-height: 70px;
    }

    .delete-btn {
      display: none;
    }
  }

  .files .file-card {
    display: flex;
    justify-content: center;

    i {
      font-size: 40px;
    }
  }

  .files-container {
    margin: 10px 0;
  }
  .files-container .file-card:nth-child(odd){
    margin-right: 3%;
  }
  .files-container .file-card{
    display: inline-flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
    height: 50px;
    width: 45%;

    & .file-info{
      display: inline-flex;
      align-items: center;
    }

    & img{
      height: 40px;
      margin-right: 10px;
    }

    & .delete-btn{
      display: none;
      position: relative;
      align-self: start;
    }
    &:hover .delete-btn{
      display: inline-block;
    }
  }
  
  .color-tags{
    min-height: 28px;
    height: 28px;
    background: var(--v-kanban-base);
    color: var(--v-text-base);
    border-radius: 3px;
  }
  
  .showed-tag{
    display: inline-block;
    border: 1px solid #888 !important;
    margin-right: 5px;
    margin-bottom: 5px;
  }

  .files .img {
    transition: all 200ms;
    width: 100%;
    max-height: 400px;
    min-height: 30px;
    border-radius: 3px;
    border: 1px solid #cccccc;
  }

  .files .img .delete-btn {
    display: none;
  }

  .files .img.big:hover .delete-btn {
    position: absolute;
    display: inline-block;
    top: 1px;
    right: 3px;
  }

  .files .img.small {
    max-width: 70px;
    max-height: 70px;
    height: 70px;
    border: 1px solid #cccccc;
    border-radius: 5px;
  }


  .files .file-card {

    transition: all 200ms;
    width: 100%;
    max-height: 400px;
    min-height: 30px;
    height: 70px;
    border-radius: 3px;
    border: 1px solid #cccccc;

    &.big:hover .delete-btn {
      position: absolute;
      display: inline-block;
      top: 1px;
      right: 3px;
    }

    &.small {
      max-width: 70px;
      max-height: 70px;
    }

    .delete-btn {
      display: none;
    }
  }

  .files .file-card {
    display: flex;
    justify-content: center;

    i {
      font-size: 40px;
    }
  }

  .files-container {
    margin: 10px 0;
  }
  .files-container .file-card:nth-child(odd){
    margin-right: 3%;
  }
  .files-container .file-card{
    display: inline-flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
    height: 50px;
    width: 45%;

    & .file-info{
      display: inline-flex;
      align-items: center;
    }

    & img{
      height: 40px;
      margin-right: 10px;
    }

    & .delete-btn{
      display: none;
      position: relative;
      align-self: start;
    }
    &:hover .delete-btn{
      display: inline-block;
    }
  }
  .relative-ctn2 {
  position: relative;
}
.add-file-button {
  margin: 4px;
  border-radius: 10px !important;
  border: none !important;
  background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='12' ry='12' stroke='%23167BD7FF' stroke-width='4' stroke-dasharray='8' stroke-dashoffset='0' stroke-linecap='square'/%3e%3c/svg%3e");
  display: flex;
  justify-content: center;
  box-sizing: border-box;
}
.first-delete {
  top: 15px;
}
.second-delete {
  top: 25px;
}
.file-container:hover img {
  opacity: 0.5 !important;
}
.icon-cont {
  position: absolute;
  z-index: 99;
  left: 20px;
  display: none;
}
.img-container:hover {
  & .icon-cont {
    display: block !important;
    top: 10px;
  }
}
.file-container:hover .icon-cont {
  display: block !important;
}
.priority ::v-deep .v-input__slot {
  padding: 0 4px !important;
}
</style>