<template>
	<div>
		<admin-change-list
			v-model="history.show"
			@new-comment="addComment"
			:changes="history.changes"
			:item="history.item"
			:variant="history.type"
			:urls="history.urls"
		/>

		<blue-confirm-dialog ref="validateBillableDialog">
			<template #actions="{ confirm }">
				<div class="flex text-right">
					<v-btn @click="lastResponse = false; confirm()" color="error" outlined small>Send to draft</v-btn>
					<v-btn @click="lastResponse = true, confirm()" color="primary" depressed small class="ml-2">Approve</v-btn>
				</div>
			</template>
		</blue-confirm-dialog>
		<billable-close-dialog ref="closeBillableDialog"/>
		<billable-delete-dialog ref="deleteDialog"/>

		<billable-item-pdf-dialog ref="billableItemPdfDialog"/>
		<blue-confirm-dialog ref="validateBillableItemDialog">
			<template #actions="{ confirm }">
				<div class="flex text-right">
					<v-btn @click="lastResponse = false; confirm()" color="error" outlined small>Send to Draft</v-btn>
					<v-btn @click="lastResponse = true, confirm()" color="primary" depressed small class="ml-2">Approve</v-btn>
				</div>
			</template>
		</blue-confirm-dialog>

		<blue-confirm-dialog ref="invoiceCancellationReason">
			<template #text>
				<v-form>
					<v-select
						v-model="cancellationReason"
						:items="cancellationReasons"
						outlined hide-details
					/>
				</v-form>
				
			</template>
			<template #actions="{ cancel, confirm }">
				<div class="d-flex mb-2 align-center" style="gap: 10px">
					<div class="warning-msg pa-2 rounded">
						<v-icon color="warning"> mdi-alert </v-icon>
						All the payments associated to this invoice will be unlinked.
					</div>
					<div class="d-flex">
						<v-btn @click="cancel" text>Back</v-btn>
						<v-btn @click="confirm" color="error" :disabled="!cancellationReason" text>Cancel Invoice</v-btn>
					</div>
				</div>
			</template>
		</blue-confirm-dialog>
		<invoice-pdf-dialog ref="invoicePdfDialog"/>
	</div>
</template>

<script>
import AdminChangeList from '../components/AdminChangeList'
import BillableDeleteDialog from '../dialogs/adminSurf/BillableDeleteDialog'
import BillableCloseDialog from '../dialogs/adminSurf/BillableCloseDialog'
import BillableItemPdfDialog from '../dialogs/adminSurf/BillableItemPdfDialog.vue'
import InvoicePdfDialog from '../dialogs/adminSurf/InvoicePdfDialog'

export default {
	components: {
		AdminChangeList,
		BillableDeleteDialog,
		BillableCloseDialog,
		BillableItemPdfDialog,
		InvoicePdfDialog,
	},
	data: () => ({
		history: {
			show: false,
			changes: [],
			item: null,
			type: null,
			urls: {},
		},
		cancellationReasons: [
			'Voucher issued in relation to a relation',
			'Voucher issued with unrelated errors',
			'The operation was not carried out',
		],
		cancellationReason: '',

		lastResponse: null,
	}),
	methods: {
		openHistory(item, type, changesProperty = 'changes'){
			const urls = {
				billable: {
					creation: `api/billable/${item.id}/comment`,
					deletion: id => `api/billable/comment/${id}`,
				},
				assignment: {
					creation: `api/assignment/${item.id}/comment`,
					deletion: id => `api/assignment/comment/${id}`,
				},
			}
			this.history = {
				show: true,
				changes: item[changesProperty],
				item,
				type,
				urls: urls[type],
			}
		},
		addComment(comment){
			if(!this.history.item.changes)
				this.history.item.changes = []
			this.history.item.changes.push(comment)
			this.history.changes = this.history.item.changes
		},

		cancelInvoice(id){
			this.cancellationReason = ''
			return new Promise(async (response, reject) => {
				let confirm = await this.$refs.invoiceCancellationReason
					.open({
						title: 'Invoice Cancellation',
						width: 600
					})
				if(!confirm){
					response(false)
					return
				}

				this.$http.delete(`api/invoice/${id}/${this.cancellationReason}`)
					.then(response)
					.catch(reject)
			})
		},
		invoiceToPdf(invoice){
			if(invoice.businessAddress === 'Missing'){
				this.$root.error('Business address is missing. Please add it in the customer settings.')
				return
			}
			return this.$refs.invoicePdfDialog.open(invoice)
		},

		validateBillable(billable){
			this.lastResponse = null
			return new Promise((response, reject) => {
				this.$refs.validateBillableDialog.open({
					title: 'Approve Billable',
					description: `Do you want to approve the billable <b>${billable.code}</b>?`,
					cancelBtnText: 'Reject',
					confirmBtnText: 'Approve',
					addClose: true,
				})
					.then((res) => {
						if(!res) return
						this.$http.post(`api/billable/${billable.id}/validate/${this.lastResponse ? 2 : 3}`)
							.then((res) => {
								this.$root.$refs.appMenu?.fetchPendingData()
								response(res)
							})
							.catch(reject)
					})
			})
		},
		cancelBillable(id){
			return new Promise(async (response, reject) => {
				let deletionResponse = await this.$refs.deleteDialog.open(id)
				if(!deletionResponse.confirmed){
					response(false)
					return
				}

				this.$http.delete(`api/Billable/${id}?deleteAssignments=${deletionResponse.deleteAssignments}`)
					.then(response)
					.catch(reject)
			})
		},
		closeBillable(item){
			return this.$refs.closeBillableDialog.open(item.id)
				.then(billable => {
					if(billable)
						this.$q.setComplete(item, billable)
				})
		},
		openBillable(item){
			return this.$refs.closeBillableDialog.open(item.id, true)
				.then(billable => {
					if(billable)
						this.$q.setComplete(item, billable)
				})
		},

		approveBillableItem(item) {
			this.lastResponse = null
			return new Promise((response, reject) => {
				this.$refs.validateBillableItemDialog.open({
					title: 'Approve Billable Item',
					description: `Do you want to approve the billable item <b>${item.code}</b>?`,
					cancelBtnText: 'Reject',
					confirmBtnText: 'Approve',
					addClose: true,
				})
					.then((res) => {
						if(!res) return
						this.$http.post(`api/billableItem/${item.id}/approve/${this.lastResponse ? 2 : 3}`)
							.then((res) => {
								this.$root.$refs.appMenu?.fetchPendingData()
								response(res)
							})
							.catch(reject)
					})
			})
		},
		billableItemToPdf(item, download = true){
			this.$refs.billableItemPdfDialog.open(item, download)
		},

		cancelSupplierInvoice(id){
			return new Promise(async (resolve, reject) => {
				let confirm = await this.$openConfirmDialog({
					title: 'Invoice Cancellation',
					description: 'Do you want to cancel this invoice?',
					cancelBtnText: 'Back',
					confirmBtnText: 'Cancel Invoice',
					addClose: true,
				})
				if(!confirm){
					resolve(false)
					return
				}

				this.$http.delete(`api/supplierInvoice/${id}`)
					.then(resolve)
					.catch(reject)
			})
		},
	},
}
</script>

<style lang="scss" scoped>
::v-deep .v-card__text{
	padding-bottom: 0;
}
.warning-msg{
	display: grid;
	grid-template-columns: 50px auto;
	align-items: center;
	background: var(--v-background-lighten);
}
</style>