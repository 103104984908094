<template>
  <div>
    <bubble-menu
      class="bubble-menu"
      :tippy-options="{ duration: 100 }"
      :editor="editor"
    >
      <button @click="editor.chain().focus().toggleBold().run()" :class="{ 'is-active': editor.isActive('bold') }">
        <v-icon color="white">format_bold</v-icon>
      </button>
      <button @click="editor.chain().focus().toggleItalic().run()" :class="{ 'is-active': editor.isActive('italic') }">
        <v-icon color="white">format_italic</v-icon>
      </button>
      <button @click="editor.chain().focus().toggleStrike().run()" :class="{ 'is-active': editor.isActive('strike') }">
        <v-icon color="white">format_strikethrough</v-icon>
      </button>
    </bubble-menu>
    
    <div class="new-editor">
        <v-btn tabindex="-1" v-if="!!imageHandler" @click="() => imageHandler(editor.commands)" small text class="new-menubar__button">
          <v-icon >mdi-camera-outline</v-icon>
        </v-btn>
        <v-btn tabindex="-1" small text class="menubar__button"	@click="editor.chain().focus().toggleTaskList().run()" :class="{ 'is-active': editor.isActive('taskList') }">
          <v-icon >mdi-format-list-checks</v-icon>
        </v-btn>
        <v-btn tabindex="-1" style="float: right;" small text class="menubar__button mr-1"	@click="$emit('close')">
          <v-icon >mdi-close</v-icon>
        </v-btn>
        <editor-content v-model="value" :editor="editor" class="editor__content rich-text-format" />
      </div>
    </div>
  </div>
</template>

<script>
import { Editor, EditorContent, BubbleMenu } from '@tiptap/vue-2'
import StarterKit from '@tiptap/starter-kit'
import Paragraph from '@tiptap/extension-paragraph'
import Document from '@tiptap/extension-document'
import TaskItem from '@tiptap/extension-task-item'
import TaskList from '@tiptap/extension-task-list'
import Text from '@tiptap/extension-text'
import Image from '@tiptap/extension-image'
import Link from '@tiptap/extension-link'
import Placeholder from '@tiptap/extension-placeholder'
export default {
  components: {
    BubbleMenu,
    EditorContent,
  },
  props: {
    value: { type: String, default: '' },
    handlePaste: { type: Function, default: () => {} },
    imageHandler: { type: Function, default: null },
    autofocus: { type: Boolean, default: true },
  },
  data() {
    return {
      editor: null,
      canSave: true,
    }
  },
  methods: {
    handleMouseEnter(){
      this.canSave = false
    },
    onPaste(e){
      this.handlePaste(e);
    },
    focus(){
      this.editor.commands.focus()
    },
  },
  watch: {
    value(val) {
      if (this.editor.getHTML() != val) {
        this.editor.commands.setContent(val, false)
      }
    },
  },
  mounted() {
    const CustomDocument = Document.extend({
      content: 'heading block*',
    })
    const extensions = [
      CustomDocument,
      Paragraph,
      StarterKit.configure({
        document: false,
      }),
      Text,
      Link.configure({
        linkOnPaste: false,
      }),
      TaskList,
      TaskItem.configure({
        nested: true,
      }),
      Image.configure({
        HTMLAttributes: {
          class: 'rich-image',
        },
      }),
      Placeholder.configure({
        placeholder:  ({ node }) => {
            if (node.type.name === 'heading') {
              return 'Title'
            }
            return 'Take a note...'
          }
      }),
    ]
    this.editor = new Editor({
      content: ``,
      extensions,
      onUpdate: () => {
        this.$emit('input', this.editor.getHTML())
      },
      onBlur: (e) => {
        if(!this.canSave) return
        this.$emit('change', this.editor.getHTML())
      },
      onFocus: () =>{
        this.$emit('focus')
      }
    })
    this.$nextTick(() => {
      this.editor.commands.setContent(this.value)
      if(this.autofocus)
        this.editor.commands.focus()
    })
  },

  beforeUnmount() {
    this.editor.destroy()
  },
}
</script>

<style lang="scss">
ul[data-type="taskList"] {
  list-style: none;
  padding: 0;
  p {
    margin: 0;
    word-break: break-word;
  }
  li {
    display: flex;
    align-items: center;
    > label {
      flex: 0 0 auto;
      margin-right: 0.5rem;
      user-select: none;
    }
    > div {
      flex: 1 1 auto;
    }
  }
}
.rich-image {
  max-width: 100%;
  min-width: 100%;
}
.editor {
  border: 1px solid var(--v-text-lighten);
  border-radius: 3px;
  padding: 0px;
}
.editor__content {
  & .ProseMirror {
    color: var(--v-text-base);
    min-height: 60px;
    padding: 4px;
    height: auto;
    word-break: break-word;
    &:focus {
      outline-width: 0;
      outline: none;
    }
  }
}

.menubar__button {
  padding-left: 0px !important;
  padding-right: 0px !important;
  min-width: 28px !important;
}
.new-editor {
  width: 100%;
  padding: 10px 0;
    border-radius: 7px 7px 0 0;
    border: 1px solid;
}
.t1 {
  & .ProseMirror {
    border: 1px solid;
  }
}
.new-border {
  border: 1px solid
}
.bubble-menu {
  display: flex;
  background-color: #0D0D0D;
  padding: 0.2rem;
  border-radius: 0.5rem;

  button {
    border: none;
    background: none;
    color: #FFF;
    font-size: 0.85rem;
    font-weight: 500;
    padding: 0 0.2rem;
    opacity: 0.6;

    &:hover,
    &.is-active {
      opacity: 1;
    }
  }
}
.ProseMirror .is-empty:nth-child(-n+2)::before {
  content: attr(data-placeholder);
  float: left;
  color: #ced4da;
  pointer-events: none;
  height: 0;
}
</style>