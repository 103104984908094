<template>
	<v-btn-toggle
		:value="value" mandatory @change="e => $emit('input', e)"
		class="toggle ml-2 rounded" :class="{ dense }"
	>
		<div v-for="(item, ix) in items" :key="ix">
			<v-tooltip top :disabled="value == ix">
				<template #activator="{ on }">
					<v-btn
						:color="value == ix ? 'var(--v-background-lighten)' : 'transparent'"
						class="mx-1 inline-block" depressed
						:style="{ height: '100%'}"
						v-on="on"
					>
						<v-icon
							:style="{
								filter: value == ix ? '' : $vuetify.theme.isDark ? 'brightness(0) invert(1)' : 'brightness(.5)'
							}"
							color="primary" :small="dense"
						>{{item.iconClass}}</v-icon>
						<div v-show="value == ix" :class="{'caption': dense}">
							<span class="ml-1" style="font-weight: 600;">{{ item.text }}</span>
						</div>
					</v-btn>
				</template>
				<span>{{item.text}}</span>
			</v-tooltip>
		</div>
	</v-btn-toggle>
</template>

<script>
export default {
	props: {
		value: { type: Number, default: 0 },
		items: { type: Array },
		dense: { type: Boolean, default: false },
	},
}
</script>

<style lang="scss" scoped>
.toggle{
	padding: 5px 1px;
	background: rgba(22, 123, 215, 0.08) !important;
	height: 40px;

	.v-btn:before{
		background: none !important;
	}
}
.dense{
	height: 33px;
	padding: 3px 0;
}
</style>