<template>
  <blue-confirm-dialog persistent ref="dialog">
    <template #text>
      <v-form ref="form">
        <div class="d-flex flex-column" style="gap: 10px">
          <div>
            <label>Approver*</label>
            <q-user-autocomplete
              v-model="approval.approverId"
              :items="members"
              :confirmable="false"
              :rules="[(val) => !!val || 'Required']"
              dense outlined hide-details
            />
          </div>
					<div>
						<label>Type</label>
						<v-select
							v-model="type" :items="types"
							item-value="value" item-text="displayName"
              dense outlined hide-details
						>
							<template v-slot:item="{ item }">
								<v-icon :color="item.color" class="mr-2">
									{{ item.iconClass }}
								</v-icon>
								{{ item.displayName }}
							</template>
							<template v-slot:selection="{ item }">
								<v-icon :color="item.color" class="mr-2">
									{{ item.iconClass }}
								</v-icon>
								{{ item.displayName }}
							</template>
						</v-select>
					</div>
          <div>
            <label>Status to Approve*</label>
            <v-autocomplete
              v-model="approval.statusId"
              :items="shownStatus"
              :rules="[(val) => !!val || 'Required']"
              dense outlined hide-details
              item-value="id"
            >
              <template #selection="{ item }">
                <v-chip label :color="item.color" small dark>
                  <v-icon left :color="item.textColor" small>
                    {{ item.iconClass }}
                  </v-icon>
                  {{ item.name }}
                </v-chip>
              </template>
              <template #item="{ item }">
                <v-chip label :color="item.color" small dark>
                  <v-icon left :color="item.textColor" small>
                    {{ item.iconClass }}
                  </v-icon>
                  {{ item.name }}
                </v-chip>
              </template>
            </v-autocomplete>
          </div>
          <div>
            <label>Requirements</label>
            <v-textarea
              v-model="approval.requirements"
              dense outlined hide-details
              rows="2"
            />
          </div>
        </div>
      </v-form>
    </template>
  </blue-confirm-dialog>
</template>

<script>
export default {
	props: {
		members: Array,
		status: Array,
		types: Array,
		projectId: String,
	},
	data: () => ({
		approval: {},
		resolve: null,
		type: '',
	}),
	computed: {
		shownStatus(){
			return this.status.filter(x => this.type == x.workItemType)
		},
	},
	methods: {
		open(approval){
			this.approval = structuredClone(approval) || {}

			if(this.approval.statusId && this.status.length){
				const status = this.status.find(x => x.id == this.approval.statusId)
				this.type = this.types.find(x => x.value == status.workItemType).value
			}
			else
				this.type = this.types[0].value || 0

			this.$refs.dialog.open({
				title: approval ? 'Edit Approval' : 'New Approval',
			})
				.then(confirmed => {
					if(!confirmed) return
					if(!this.$refs.form.validate()){
						this.open(approval)
						return
					}

					this.$http.post(`/api/project/${this.projectId}/approval`, this.approval)
						.then(res => {
							this.resolve(res.data)
							this.$refs.dialog.close()
						})
						.catch(err => {
							this.open(approval)
							console.error(err)
							this.$root.error('Failed to save approval')
						})
				})
			
			return new Promise(res => {
				this.resolve = res
			})
		},
	}
}
</script>