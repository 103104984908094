
<template>
  <v-card
    flat class="mx-auto rounded-lg"
    max-width="100%" fill-height :class="{ small: small }"
  >
    <div class="d-none">
      <input type="file" id="coverFile" accept="image/*" ref="coverFile" v-on:change="handleCoverFileUpload()"/>
      <input type="file" id="profileFile" accept="image/*" ref="profileFile" v-on:change="handleProfileFileUpload()"/>
      <img class="" :src="coverPicSrc" @error="coverPicError = true" />
    </div>
    
    <v-parallax v-if="!small" class="cover-image align-start rounded-lg" height="160" 
      :src="coverPicSrc">
      <v-btn v-if="editable" @click="$refs.coverFile.click()" class="edit-cover-image" fab absolute :style="{ top: 0, right: 0 }" icon dark>
        <v-icon>edit</v-icon>
      </v-btn>
    </v-parallax>
    <v-card-text class="text-center card-text">

      <v-row justify="space-around">
        <v-col :cols="small ? '' : 12" class="top-details">

          <div class="text-center" :class="small ? 'mt-2' : ''">
            <v-avatar @click="openProfilePicDialog" :class="{ 'cursor-pointer': editable, 'base-border': !small }" class="avatar" color="grey" 
              :size="small? 80 : 120">
              <v-img :src="profilePicSrc" @error="profilePicError = true"></v-img>
            </v-avatar>
          </div>
          <h2 :style="{ fontSize: small ? '19px' : '' }" class="pt-1">{{item.blueTag || item.fullName}}</h2>
          <div>{{!item.type ? item.fullName : ''}}</div>

          <div v-if="item.roles || item.experienceLevel !== undefined" :style="{ fontSize: small ? '16px' : '' }" class="px-2 pb-2">
            <div v-show="item.roles">
              <b>{{ item.roles }}</b>
            </div>
            <div v-show="item.experienceLevel !== undefined" class="mt-n1">
              {{ item.experienceLevelName }}
            </div>
          </div>

          <div class="badge-container badge-small pl-2 mr-0" v-if="small" >
            <div class="badge-container-small mr-1" v-for="(badge, ix) in item.badges" :key="ix">
                <v-img class="inline-block mr-1" :src="formatHttp(badge.badgeImgUrl)" @error="badge.badgeImgUrl = profilePicFailover" height="30" contain>
                </v-img>
            </div>
          </div>
        </v-col>
        <v-col :cols="!small ? contactInfoCols : 'auto'" xs="12" class="flex-grow-1 pt-0 contact-details" :class="small ? 'init-container' : ''">
          <v-card
            outlined class="text-left background--lighten"
            :class="{
              'mx-2 small-info-card': small,
              'small-padding pt-3 rounded-lg': !small,
              'no-border': directoryView,
            }"
          >
            <v-card-text>
              <hr v-show="directoryView" class="mb-8 mt-n8 hr">
              <h4 v-if="!small" :style="{ fontSize: small ? '17px' : '17px', fontWeight: 'bold' }">
                Contact Details
              </h4>
              <v-btn outlined v-if="!!onEdit" @click="onEdit" color="primary" class="float-right rounded-lg" :style="{ top: '-24px', right: 0 }">
                Edit
              </v-btn>
              <v-list :dense="small" class="background--lighten" :class="{'transparent': directoryView}">
                <v-list-item v-if="item.mobile">
                  <v-list-item-icon class="mr-3">
                    <v-icon color="primary">phone</v-icon>
                  </v-list-item-icon>
                  <v-list-item-content>
                    <v-list-item-subtitle v-if="!small">Mobile</v-list-item-subtitle>
                    <v-list-item-title :class="small ? '' : 'mt-1'">{{item.mobile}}</v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
                <v-list-item v-if="item.email">
                  <v-list-item-icon class="mr-3">
                    <v-icon color="primary">mail</v-icon>
                  </v-list-item-icon>
                  <v-list-item-content>
                    <v-list-item-subtitle v-if="!small">Email</v-list-item-subtitle>
                    <v-list-item-title :class="small ? '' : 'mt-1'">{{item.email}}</v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
                <v-list-item v-if="(item.state || item.city)">
                  <v-list-item-icon class="mr-3">
                    <v-icon color="primary">mdi-map-marker</v-icon>
                  </v-list-item-icon>
                  <v-list-item-content>
                    <v-list-item-subtitle v-if="!small">City</v-list-item-subtitle>
                    <v-list-item-title :class="small ? '' : 'mt-1'">{{item.city ? item.city + ', ' : ''}}{{item.state ? item.state : ''}}</v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
                <v-list-item v-if="item.birthdate">
                  <v-list-item-icon class="mr-3">
                    <v-icon color="primary">cake</v-icon>
                  </v-list-item-icon>
                  <v-list-item-content>
                    <v-list-item-subtitle v-if="!small">Birthday</v-list-item-subtitle>
                    <v-list-item-title 
                    :class="{
                      strikethrough: years >= 2,
                      'mt-1': !small
                    }"
                    >{{item.birthdate | formatDateCustom('MMM D')}}</v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
                <v-list-item v-if="item.blueniversary">
                  <v-list-item-icon class="mr-3">
                    <span class="display-1 primary--text ml-1">{{years}}</span>
                  </v-list-item-icon>
                  <v-list-item-content>
                    <v-list-item-subtitle v-if="!small">Bluenniversary</v-list-item-subtitle>
                    <v-list-item-title :class="small ? 'mt-2' : 'mt-1'">{{item.blueniversary  | formatDate }}</v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
                <v-list-item v-if="!small && !directoryView && !item.type">
                  <v-list-item-icon class="mr-3">
                    <span class="display-1 primary--text ml-1">{{item.totalRecessDays}}</span>
                  </v-list-item-icon>
                  <v-list-item-content>
                    <v-list-item-subtitle v-if="!small">Vacation Days Left</v-list-item-subtitle>
                    <v-list-item-title :class="small ? 'mt-2' : 'mt-1'">
                      <a @click="openVacationRequestDialog()" class="cursor-pointer opacity-on-hover" >
                        Request Vacations
                      </a>
                      <span class="mx-2">&#x2022;</span>
                      <a @click=" openMyRequests()" class="cursor-pointer opacity-on-hover" >
                        Requests
                      </a>
                    </v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
                <slot
                  v-if="!small && !item.type"
                  :name="'google-btn'"
                  v-bind="{ item }"
                >
                </slot>
              </v-list>

              <template v-if="directoryView">
                <hr class="mt-5 mb-8 hr">
                <h4 v-if="!small" :style="{ fontSize: small ? '17px' : '17px', fontWeight: 'bold' }">
                  Projects
                </h4>
                <div v-for="project in item.customerProjects" :key="project.id" class="project">
                  <div class="d-flex justify-space-between align-center">
                    <div>
                      <b class="title text--text"> {{ project.customerProjectName }} </b>
                      <v-btn
                        :color="project.isActive ? 'green' : 'error'"
                        :style="{
                          background: $vuetify.theme.isDark ? '' :
                          project.isActive ? '#e1f8dd' : '#f5d0d0'
                        }"
                        outlined x-small style="border: none"
                        class="mt-n1 ml-1"
                      >
                        {{project.isActive ? 'Active' : 'Inactive'}}
                      </v-btn>
                    </div>
                    <div class="text-right">
                      <span class="caption">Period</span>
                      <div class="subtitle-1 mt-n1 text--text">
                        {{ project.startDate | formatDate }}
                        -
                        <template v-if="project.isActive">Present</template>
                        <template v-else>{{ project.endDate | formatDate }}</template>
                      </div>
                    </div>
                  </div>
                  <div class="d-flex justify-space-between mt-0">
                    <div>
                      <span class="caption">Customer</span>
                      <div class="subtitle-1 mt-n1 text--text">{{ project.customerName }}</div>
                    </div>
                    <div class="text-right">
                      <span class="caption">Responsible DM</span>
                      <div class="subtitle-1 mt-n1 text--text">{{ project.deliveryManagerBlueTag || project.deliveryManagerFullName || '-' }}</div>
                    </div>
                  </div>
                </div>
              </template>

            </v-card-text>
          </v-card>

        </v-col>

        <v-col :cols="$vuetify.breakpoint.width <= 1088 && !small ? 12 : 7" class="pt-0" xs="12" v-if="userId == 'me'">
          <v-card
            v-if="userId == 'me' && !item.type"
            class="text-left background--lighten mb-5"
            :class="`${small ? 'mx-2 fill-height' : 'rounded-lg'}`"
            outlined
          >
            <v-card-text>
              <h4>Badges</h4>
              <div class="mt-5">
                <div class="badges">
                  <div class="badge-container" v-for="(badge, ix) in item.badges" :key="ix">
                    <v-tooltip top color="black">
                      <template v-slot:activator="{ on, attrs }">
                        <v-img 
                          class="inline-block"
                          :src="formatHttp(badge.badgeImgUrl)"
                          @error="badge.badgeImgUrl = profilePicFailover"
                          contain
                          v-on="on"
                          v-bind="attrs"
                        > 
                        </v-img>
                      </template>
                      <span >
                        <div class="text-center" :style="{ maxWidth: '100px', wordBreak: 'break-word' }">
                          {{ badge.badgeName }} <br>
                          {{ formatDate(badge.dateEarned) }}
                        </div>
                      </span>
                    </v-tooltip>
                  </div>
                  <div class="clearfix"></div>
                </div>
              </div>
            </v-card-text>
          </v-card>
          <v-card outlined class="text-left background--lighten" :class="small ? 'mx-2 fill-height' : 'rounded-lg'">
            <v-card-text :style="{ padding: '23px 16px 16px' }">
              <h4>Favorites</h4>
              <v-icon @click="openNewShortcut" color="primary" style="position: absolute; right: 16px; top: 22px; cursor: pointer;" >mdi-plus-circle-outline</v-icon>

              <div class="mt-5">
                <draggable-sort
                  v-model="item.links" @change="positionChanged"
                  col-class="link"
                  row-class="links-container"
                  no-row
                  spaceEvenly
                >
                  <template v-slot="link">
                    <v-icon class="icon-cont icon-close" @click="deleteShortcut(link)">mdi-close</v-icon>
                    <v-icon class="icon-cont icon-edit" @click="openEditShortcut(link)">mdi-pencil</v-icon>
                    <div class="icon-container rounded background--lighten3" @click="openUrl(link.url, $event)">
                      <v-icon>{{link.icon}}</v-icon>
                    </div>
                    <br>
                    <div class="name">
                      {{link.name}} 
                    </div>
                  </template>
                </draggable-sort>
              </div>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>

    </v-card-text>
    <UserShortcutDialog
      :dialog="shortcutDialog"
      :cancel="() => { linkEdit={}; shortcutDialog=false; }"
      :callback="init"
      :link="linkEdit"
    />
    <vacation-request-dialog
      ref="vacationRequestDialog"
    />
  </v-card>
</template>

<script>
import _ from "lodash"
import moment from 'moment'
import UserShortcutDialog from '../../dialogs/UserShortcutDialog'
import VacationRequestDialog from "../../dialogs/VacationRequestDialog.vue"
import DraggableSort from '../../components/DraggableSort'

export default {
  props: {
    userId: { type: String, default: 'me' },
    small: { type: Boolean, default: false },
    editable: { type: Boolean, default: false },
    hideLoading: { type: Boolean, default: false },
    onEdit: { type: Function, default: null },
    directoryView: { type: Boolean, default: false },
  },
  components: {
    UserShortcutDialog,
    DraggableSort,
    VacationRequestDialog,
  },
  data: () => ({
    item: {},
    coverPicError: false,
    profilePicError: false,
    coverPicFailover: require('@/assets/principles-bkg.jpg'),
    profilePicFailover: 'https://cdn.vuetifyjs.com/images/parallax/material2.jpg',

    linkEdit: {},
    shortcutDialog: false,
  }),
  watch: {
    userId() {
      this.init()
    },
  },
  created() {
    this.init()
  },
  computed: {
    contactInfoCols() {
      if(this.$vuetify.breakpoint.width <= 1088 && !this.small) {
        return 12
      } else if(this.userId !== 'me') {
        return 12
      }
      return 5
    },
    id() {
      if (this.userId == 'me') {
        return 'current'
      }
      this.coverPicError = false
      this.profilePicError = false
      return this.userId
    },
    years() {
      if (!this.item.blueniversary) return;
      return moment().diff(this.item.blueniversary, 'years', false)
    },
    coverPicSrc() {
      if (this.coverPicError) {
        return this.coverPicFailover
      }
      return this.$http.defaults.baseURL + `/api/user/${this.id}/coverPicture`
    },
    profilePicSrc() {
      if (this.profilePicError) {
        return this.profilePicFailover
      }
      let src = `api/user/${this.id}/profilePicture`;
      if(!this.$http.defaults.baseURL.endsWith('/'))
        src = '/' + src;
      return this.$http.defaults.baseURL + src;
    }
  },
  methods: {
    openVacationRequestDialog() {
      this.$refs.vacationRequestDialog.open()
    },
    openMyRequests() {
      this.$router.push('/recess/requests/current')
    },
    positionChanged(e){
      let link = e.moved.element
      this.$http.post(`/api/user/${this.id}/moveLink/${link.id}/${e.moved.newIndex}`)
        .then(res => {
          link = res.data
        })
    },
    submitFile(file, type){
      let formData = new FormData()
      formData.append('file', file)
      this.$http.put(`/api/user/${this.id}/${type}`,
          formData,
          {
            headers: {
              'Content-Type': 'multipart/form-data'
            }
          }
      ).then(res => {
        window.getApp.success("Picture uploaded.")
      })
      .catch(res => {
        console.error(res)
        window.getApp.error("Cannot upload picture.")
      })
    },
    handleCoverFileUpload(){
      let file = this.$refs.coverFile.files[0]
      this.submitFile(file, 'coverPicture')
    },
    handleProfileFileUpload(){
      let file = this.$refs.profileFile.files[0]
      this.submitFile(file, 'profilePicture')
    },
    openProfilePicDialog() {
      if (this.editable) {
        this.$refs.profileFile.click()
      }
    },
    init() {
      this.shortcutDialog = false

      this.$http
        .get(`/api/user/${this.id}?includeProjects=true`, { headers: {hideLoading: this.hideLoading} })
        .then(res => {
          this.item = res.data
        })
        .catch(res => {
          window.getApp.error("Cannot get record.")
          console.log(res)
        })
    },
    isInternalUrl(url){
      return url.includes(window.location.host)
    },
    getUrl(url){
      if (!/^https?:\/\//i.test(url))
        url = 'http://' + url;
      return url;
    },
    openUrl(url, event){
      let newTab = (event && event.ctrlKey) || !this.isInternalUrl(url)
      if(!newTab){
        let toPush = url.substr(url.indexOf(window.location.host) + window.location.host.length)
        this.$router.replace(toPush)
      } else {
        url = this.getUrl(url)
        let tagA = document.createElement('a')
        tagA.href = url
        tagA.target = '_blank'
        tagA.rel = 'noopener noreferrer'
        tagA.click()
      }
    },
		formatDate: (date) => moment(date).format('MMM D, YYYY'),
    openNewShortcut() {
      this.shortcutDialog = true
      this.linkEdit = {}  
    },
    openEditShortcut(link) {
      this.shortcutDialog = true
      this.linkEdit = Object.assign({}, link)
    },
    deleteShortcut(link) {
      this.$http.delete(`api/user/current/link/${link.id}`).then(res => {
        window.getApp.success("Deleted successfully.");
        this.init();
      }).catch(response => {
        window.getApp.error("Cannot delete.");
        console.log(response);
      });
    },
  }

}
</script>

<style scoped lang="scss">
  .cover-image {
    position: absolute;
    z-index: 0;
    width: 100%;
  }
  .top-details {
    position: relative;
    min-width: 250px;
  }
  .contact-details {
    min-width: 350px;
  }
  .small .top-details {
    max-width: 300px;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  .strikethrough {
    text-decoration: line-through;
  }
  .small .card-text {
    padding-top: 16px;

  }
  .card-text {
    padding-top: 80px;
  }
  .cover-image .edit-cover-image {
    display: none;
  }
  .cover-image:hover .edit-cover-image {
    display: block;
  }

  ::v-deep .links-container{
    margin: -12px;
    display: flex;
    flex-wrap: wrap;
  }
  ::v-deep .link{
    width: 100px;
    height: 100px;
    display: block;
    margin-right: 12px;
    margin-bottom: 30px;
    text-align: center;
  }
  .link {
    display: inline-block;
    margin-right: 15px;
    margin-bottom: 40px;
    text-align: center;

    .icon-cont{
      position: absolute;
      border-radius: 50%;
      font-size: 17px;
      border: none !important;
      padding: 3px;
      cursor: pointer;
      display: none;
    }

    .icon-close{
      margin-left: -10px;
      margin-top: -10px;
      background: #e04f5f;
      color: white;
    }
    .icon-close:hover{
      background: white;
      border: 1px solid #e04f5f;
      color: #e04f5f;
    }
    .icon-edit{
      margin-left: 55px;
      margin-top: -10px;
      background: white;
      border: 1px solid #3d9dff !important;
      color: #3d9dff;
    }
    .icon-edit:hover{
      background: #3d9dff;
      border: none;
      color: white;
    }

    .icon-container {
      cursor: pointer;
      background: transparent !important;
      transition: .3s all;
      display: inline-flex;
      width: 70px;
      height: 70px;
      align-items: center;
      justify-content: center;
      i{ font-size: 60px; }
    }
  }

  .link:hover {
    .icon-cont{
      display: inline-block;
    }
    .icon-container {
      i{ color: var(--v-primary-base) !important; }
    }
    .name{
      color: var(--v-primary-base);
    }
  }
  
  .badge-container {
    float: left;
    width: 80px;
    box-sizing: content-box;
    border-radius: 5px;
    margin-bottom: 22px;
    margin-right: 22px;
    position: relative;
    font-size: 16px;

    .img-container {
      height: 80px;
      overflow: hidden;
      border-top-left-radius: 5px;
      .date{
        position: absolute;
        right: 0; top: 0;
        border-bottom-left-radius: 5px;
        padding: 0 5px;
        font-size: .65em;
        background: var(--v-background-lighten3);
      }
    }

    .text {
      display: flex;
      border-radius: 0 0 5px 5px;
      align-items: center;
      justify-content: center;
      padding: 5px;
      background: var(--v-background-lighten3) !important;
      text-align: center;
      height: 40px;
      overflow: hidden;
      line-height: 22px;
      text-overflow: ellipsis;
      div{
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
      }
    }
  }
  .badge-container:hover {
    .card-btn{
      display: inline-block;
    }
  }
  .small-info-card {
    border-right: none;
    border-top: none;
    border-bottom: none;
  }
  .badge-container-small {
    display: flex;
    max-width: 50px;
  }
  .badge-small {
    width: 100% !important;
    box-sizing: border-box;
  }
  .badge-container {
    display: flex;
    max-width: 240px;
    gap: 10px 0px;
    flex-direction: row;
    justify-content: center;
    flex-wrap: wrap;
  }
  .init-container {
    display: flex;
    align-items: center;
  }
  .base-border {
    border: 3px solid var(--v-background-base) !important;
  }
  .small-padding {
    padding: '23px 16px 16px' !important; 
  }
  .no-border{
    border: none;
    background: transparent !important;
  }
  .hr{
    border: none;
    border-top: 1px solid var(--v-lightgray-base);
  }

  .project{
    border: 1px solid var(--v-lightgray-base);
    padding: 5px 20px;
    margin: 20px 0;
    border-radius: 15px;
  }
</style>