<template>
  <div class="mt-15" ref="risks">
    <div class="max-container">
      <div style="width: 100%">
        <h4>Risks</h4>
      </div>
      <div
        class="graph-container"
        :style="{ opacity: $vuetify.theme.isDark ? 0.8 : 1 }"
      >
        <div class="sidebar">Probability</div>
        <div class="matrix-container">
          <v-row
            v-for="row in probabilityRows"
            :key="row"
            class="probability-row"
          >
            <v-col
              v-for="col in impactCols"
              :key="col"
              class="impact-col"
              :style="{ background: getBackground(row, col) }"
            >
              <div
                v-if="
                  risksBySeverity[col + '-' + (6 - row)] &&
                  risksBySeverity[col + '-' + (6 - row)].length <= 4
                "
              >
                <div
                  v-for="(risk, ix) in risksBySeverity[col + '-' + (6 - row)]"
                  :key="ix"
                  class="risk"
                  :style="{
                    ...positions[ix],
                    background: risk.color,
                    color: 'white',
                  }"
                >
                  {{ risk.code.split("-")[1] }}
                  <br />
                  {{ risk.code.split("-")[2] }}
                </div>
              </div>

              <div v-else-if="risksBySeverity[col + '-' + (6 - row)]">
                <v-menu
                  bottom
                  origin="center center"
                  transition="scale-transition"
                >
                  <template v-slot:activator="{ on: menu, attrs }">
                    <v-tooltip top>
                      <template v-slot:activator="{ on: tooltip }">
                        <div
                          class="risk risk-center"
                          v-on="{ ...tooltip, ...menu }"
                          v-bind="attrs"
                        >
                          <div class="number">
                            {{ risksBySeverity[col + "-" + (6 - row)].length }}
                          </div>
                        </div>
                      </template>
                      <span>Show all</span>
                    </v-tooltip>
                  </template>
                  <v-list style="max-height: 320px; overflow-y: auto">
                    <v-list-item
                      v-for="(risk, ix) in risksBySeverity[
                        col + '-' + (6 - row)
                      ]"
                      :key="ix"
                      :style="{
                        cursor: 'pointer',
                      }"
                    >
                      <v-list-item-title>
                        <v-icon :color="risk.color" class="mr-2">
                          mdi-alert-circle
                        </v-icon>
                        {{ risk.code }}
                      </v-list-item-title>
                    </v-list-item>
                  </v-list>
                </v-menu>
              </div>
            </v-col>
          </v-row>
          <div class="underline">Impact</div>
        </div>
      </div>
    </div>
    <v-row v-for="n in length" :key="n" class="all-risks-container" id="all-risks-container">
      <v-col
        class="card-container"
        cols="3"
        v-for="risk in risks.slice((n-1)*4, n*4)"
        :key="risk.id"
        :ref="risk.id"
      >
        <v-card
          :class="{
            ['hover-' +
            getDarkColor(risk.probability * risk.impact).substr(1)]: true,
          }"
          :style="{
            borderColor: getDarkColor(risk.probability * risk.impact),
            background: risk.hover
              ? getDarkColor(risk.probability * risk.impact)
              : '',
          }"
        >
          <v-card-title>
            <v-avatar class="avatar" color="grey" :size="35">
              <v-img
                :lazy-src="risk.reporter.profilePicSrc"
                :src="risk.reporter.profilePicSrc"
                @error="risk.reporter.profilePicSrc = profilePicFailover"
              ></v-img>
            </v-avatar>
            <div class="reporter-name">
              <span class="fullname">
                {{ risk.reporter.blueTag }}
              </span>
              <span>
                {{ formatDate(risk.createdAt) }}
              </span>
            </div>
            <div class="icon-container">
              <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                  <div
                    v-bind="attrs"
                    v-on="on"
                    style="cursor: pointer"
                  >
                    <v-icon :style="{ fontSize: '22px' }"
                      >mdi-clipboard-check-outline</v-icon
                    >
                    <span class="workitems-quantity">{{
                      risk.workItems.length
                    }}</span>
                  </div>
                </template>
                <span>{{
                  "Work item" + (risk.workItems.length != 1 ? "s" : "")
                }}</span>
              </v-tooltip>
            </div>
          </v-card-title>
          <v-card-text>
            <v-row>
              <v-col>
                <h3>{{ risk.code }}</h3>
              </v-col>
            </v-row>
            <br />
            <div
              class="description"
              v-html="
                JSON.parse(risk.description.replaceAll('\\n', '<br/>'))
              "
            ></div>
            <div class="severity">
              <span>Probability: {{ risk.probability }}</span>
              <span>Impact: {{ risk.impact }}</span>
              <span>Severity: {{ risk.probability * risk.impact }}</span>
            </div>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import moment from "moment";

export default {
  props: ["id"],
  data() {
    return {
      probabilityRows: 5,
      impactCols: 5,
      severityByColor: {
        "#f5b5b5": [20, 25],
        "#ffd28e": [12, 15, 16],
        "#fff1c4": [5, 6, 8, 9, 10],
        "#b5f1b1": [1, 2, 3, 4],
      },
      severityByDarkColor: {
        "#ff6968": [20, 25],
        "#efab45": [12, 15, 16],
        "#e9cc75": [5, 6, 8, 9, 10],
        "#72bb6d": [1, 2, 3, 4],
      },
      risks: [],
      positions: [
        { left: "4px", top: "4px" },
        { left: "16px", top: "4px" },
        { left: "4px", top: "8px" },
        { left: "16px", top: "8px" },
      ],
      profilePicFailover:
        "https://cdn.vuetifyjs.com/images/parallax/material2.jpg",
      statuses: ["Active", "Eliminated", "Materialized"],
      risksBySeverity: {},
      length: 0,
    };
  },
  mounted() {
    this.$http.get(`api/Project/Risks?id=${this.id}`).then((res) => {
      this.risks = res.data.filter((r) => !r.status).sort((a, b) => {
        let difSeverity = b.impact * b.probability - a.impact * a.probability;
        if (!difSeverity) {
          return new Date(a.createdAt) - new Date(b.createdAt);
        }
        return difSeverity;
      });
      this.risks = this.risks
      this.$forceUpdate();
      this.length = parseInt(this.risks.length / 4)
      if(this.risks.length%4 > 0){
        this.length++
      }
      this.risksBySeverity = {};

      for (let risk of this.risks.filter((r) => r.status == 0)) {
        let severity = risk.impact + "-" + risk.probability;
        if (!this.risksBySeverity[severity]) {
          this.risksBySeverity[severity] = [];
        }
        this.risksBySeverity[severity].push(risk);
      }

      this.risks.forEach((r) => this.$q.profilePicSrc(r.reporter));
    });
  },
  methods: {
    getDarkColor(severity) {
      for (let color in this.severityByDarkColor) {
        if (this.severityByDarkColor[color].includes(severity)) {
          return color;
        }
      }
      return "white";
    },
    getBackground(row, col) {
      for (let color in this.severityByColor) {
        if (this.severityByColor[color].includes((6 - row) * col)) return color;
      }
      return "white";
    },
    formatDate(date) {
      return moment(date).format("h:mm A, MMM D YYYY");
    },
		getCanvases(){
			let lineElements = this.$refs.risks.children
			let elements = []
			for(let line of lineElements)
				elements.push(line)
			return elements
		},
  },
};
</script>

<style scoped lang="scss">
.max-container {
	display: flex;
	flex-direction: column;
	align-items: center;
}
.graph-container {
  display: flex;
  justify-content: flex-start;
  align-items: center;
	margin-bottom: 30px;
}
.sidebar {
  display: inline-block;
  transform: rotate(0.75turn);
  text-align: center;
  height: 170px;
  margin-right: -140px;
  text-transform: uppercase;
  font-size: 30px;
  font-weight: 600;
}
.underline {
  width: 600px;
  margin-top: 15px;
  text-transform: uppercase;
  font-weight: 600;
  font-size: 30px;
  text-align: center;
}
.matrix-container {
  display: inline-block;
  width: 750px;
}
.probability-row {
  width: 600px;
  height: 120px;
  margin: 0;
  border-right: 1px solid var(--v-background-base);
  border-left: 1px solid var(--v-background-base);
}
.impact-col {
  border: 1px solid var(--v-background-base);
  padding: 5px;
}
.risk {
  display: inline-block;
  position: relative;
  width: 43px;
  height: 43px;
  line-height: 19px;
  background: white;
  text-align: center;
  cursor: pointer;
  overflow: hidden;
  box-shadow: 2px 2px 7px #aaa;
  .v-icon {
    filter: brightness(0.9);
    font-size: 30px;
  }
}
.risk-center {
  top: 32px;
  left: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #d2b4de;
  .number {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 33px;
    height: 33px;
    border-radius: 50%;
    color: white;
    font-size: 22px;
    padding-top: 2px;
    font-weight: 700;
  }
}

.all-risks-container {
  overflow-y: auto;

  .card-container {
    padding-top: 0;
    min-width: 330px;
  }
}

.v-card {
  cursor: pointer;
  width: 100%;
  border: 1px solid #aaa;
  height: 100%;
  background: var(--v-background-lighten);

  .reporter-name {
    margin-left: 10px;

    .fullname {
      font-size: 0.8em;
      display: block;
      margin-bottom: -18px;
    }
    span {
      font-size: 0.6em;
      color: var(--v-text-lighten2);
    }
  }
  .icon-container {
    display: flex;
    align-items: center;
    position: absolute;
    right: 10px;
    top: 10px;

    .edit-icon {
      color: #1a73e8;
      cursor: pointer;
    }
    .workitems-quantity {
      color: var(--v-text-lighten2);
      font-size: 0.9em;
    }
  }
  .status {
    font-size: 1em;
  }
  .description {
    font-size: 1.2em;
    color: var(--v-text-lighten2);
    margin-bottom: 30px;
  }
  .severity {
    position: absolute;
    bottom: 0;
    width: calc(100% - 30px);
    margin-bottom: 10px;
    display: flex;
    justify-content: space-between;
    font-size: 0.9em;
  }
}

.risk-table {
  margin-top: 20px;
}
</style>