<template>
	<v-text-field
		v-if="!isInFocus"
		v-model="formattedValue"
		v-on="listeners"
		v-bind="$attrs"
		@focus="isInFocus = true"
	>
		<template #append><slot name="append"></slot></template>
	</v-text-field>
	<v-text-field
		v-else
		v-model="innerValue"
		v-on="listeners"
		v-bind="$attrs"
		@blur="isInFocus = false"
		:type="type"
		@input="emit"
		ref="input"
	>
		<template #append><slot name="append"></slot></template>
	</v-text-field>
</template>

<script>
export default {
	props: {
		value: { default: '' },
		mask: Function,
		number: Boolean,
		type: String,
	},
	data: () => ({
		innerValue: null,
		formattedValue: '',
		isInFocus: false,
	}),
	mounted() { 
		this.setValues()
	},
	watch: {
		value(){ this.setValues() },
		mask(){ this.setValues() },
		type(){ this.setValues() },
	},
	computed: {
		listeners(){
			let ls = _.cloneDeep(this.$listeners)
			delete ls.input
			return ls
		},
	},
	methods: {
		emit(){
			if(this.type == 'number') this.$emit('input', Number(this.innerValue))
			else this.$emit('input', this.innerValue)
		},
		setValues(){
			let value = this.value

			if(!value && value !== 0) {
				this.innerValue = ''
				this.formattedValue = ''
				return
			}

			if(this.type == 'number'){
				value = (value + '').replace(/[^0-9\.]/g, '')
				value = parseFloat(value)
			}

			this.innerValue = value
			this.formattedValue = this.mask(value)
		},
		focus(){
			this.isInFocus = true
			this.$nextTick(() => {
				this.$refs.input.focus()
			})
		},
	},
}
</script>

<style lang="scss" scoped>

</style>