var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-data-table',_vm._g(_vm._b({staticClass:"rounded-lg bordered new-back lighten2",attrs:{"checkbox-color":"primary","headers":_vm.shownHeaders,"items":_vm.billables,"items-per-page":_vm.pagination.itemsPerPage,"page":_vm.pagination.page,"sort-by":_vm.pagination.sortBy,"sort-desc":_vm.pagination.sortDesc,"server-items-length":_vm.pagination.totalItems},on:{"update:itemsPerPage":function($event){return _vm.$set(_vm.pagination, "itemsPerPage", $event)},"update:items-per-page":function($event){return _vm.$set(_vm.pagination, "itemsPerPage", $event)},"update:page":function($event){return _vm.$set(_vm.pagination, "page", $event)},"update:sortBy":function($event){return _vm.$set(_vm.pagination, "sortBy", $event)},"update:sort-by":[function($event){return _vm.$set(_vm.pagination, "sortBy", $event)},function($event){return _vm.$emit('pagination', $event)}],"update:sortDesc":function($event){return _vm.$set(_vm.pagination, "sortDesc", $event)},"update:sort-desc":[function($event){return _vm.$set(_vm.pagination, "sortDesc", $event)},function($event){return _vm.$emit('pagination', $event)}]},scopedSlots:_vm._u([{key:"item.code",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"text-left"},[_vm._v(" "+_vm._s(item.code)+" ")])]}},{key:"item.customerName",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"text-left"},[_vm._v(" "+_vm._s(item.customerName)+" ")])]}},{key:"item.projects",fn:function(ref){
var item = ref.item;
return _vm._l((item.customerProjects),function(project){return _c('div',{key:project.id,staticClass:"project",style:({background: _vm.projectTypes.find(function (t) { return t.value == project.type; }).lightColor})},[_c('div',{staticClass:"letter",style:({background: _vm.projectTypes.find(function (t) { return t.value == project.type; }).color})},[_vm._v(" "+_vm._s(_vm.projectTypes.find(function (t) { return t.value == project.type; }).small)+" ")]),_c('div',{staticClass:"name"},[_vm._v(_vm._s(project.name))])])})}},{key:"item.currency",fn:function(ref){
var item = ref.item;
return [_c('v-avatar',{staticClass:"mr-1",attrs:{"size":"24"}},[_c('v-img',{attrs:{"src":require(("../assets/currencies/" + (item.currency.toLowerCase()) + ".png"))}})],1),_c('b',[_vm._v(" "+_vm._s(item.currency)+" ")])]}},{key:"item.type",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.codeByType[item.type])+" ")]}},{key:"item.startDate",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("formatDate")(item.startDate))+" ")]}},{key:"item.endDate",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("formatDate")(item.endDate))+" ")]}},{key:"item.status",fn:function(ref){
var item = ref.item;
return [_c('v-menu',{attrs:{"offset-x":"","right":"","open-on-hover":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"rounded-xl",style:({
						background: _vm.$vuetify.theme.isDark ? '' : item.statusLightColor
					}),attrs:{"color":item.statusColor,"outlined":"","small":""}},on),[_vm._v(_vm._s(item.statusDisplayName))])]}}],null,true)},[_c('v-list',{attrs:{"dense":""}},_vm._l((item.approvers.filter(function (x) { return x.statusToApprove === 1; })),function(approver){return _c('v-list-item',{key:approver.id},[_c('div',{staticClass:"d-flex justify-space-between align-center",staticStyle:{"min-width":"20px","width":"100%"}},[_c('span',{staticClass:"mr-2 body-2"},[_c('user-avatar',{attrs:{"user-id":approver.approverId,"size":28,"show-card":false}}),_vm._v(" "+_vm._s(approver.approverBlueTag)+" ")],1),_c('span',{attrs:{"set":_vm.status = _vm.approverStatus.find(function (s) { return s.id == approver.status; })}},[_c('v-chip',{style:({ color: _vm.status.color }),attrs:{"color":_vm.status.lightColor,"small":""}},[_vm._v(" "+_vm._s(_vm.status.description)+" ")])],1)])])}),1)],1)]}},{key:"item.plannedHours",fn:function(ref){
					var item = ref.item;
return [(item.type === 0 || item.type === 2)?_c('div',[_vm._v("-")]):_c('billable-percentage-bar',{attrs:{"primaryAmount":item.plannedBillableHours,"secondaryAmount":item.plannedNonBillableHours,"objective":item.isOpen ? item.plannedBillableHours : item.contractedHours,"limit-secondary-percentage":item.type !== 3,"use-total-amount":item.type === 3,"infinity":item.isOpen}})]}},{key:"item.executedHours",fn:function(ref){
					var item = ref.item;
return [(item.type === 0 || item.type === 2)?_c('div',[_vm._v("-")]):_c('billable-percentage-bar',{attrs:{"primaryAmount":item.executedBillableHours,"secondaryAmount":item.executedNonBillableHours,"objective":item.plannedBillableHours,"limit-secondary-percentage":item.type !== 3,"use-total-amount":item.type === 3}})]}},{key:"item.billedHours",fn:function(ref){
					var item = ref.item;
return [(item.isOpen)?_c('div',[_vm._v("-")]):[_c('progress-bar',{attrs:{"items":[
					{ amount: item.billed },
					{ amount: -item.negativeAdjustment, skipForTotal: true },
					{ amount: item.positiveAdjustment, skipForTotal: true } ],"objective":item.subtotalWithDiscount,"amountFormatter":_vm.currencyFormat}})]]}},{key:"item.actions",fn:function(ref){
				var item = ref.item;
return [(_vm.$security.can('edit'))?_c('v-menu',{attrs:{"left":"","offset-x":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
				var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"icon":""}},on),[_c('v-icon',[_vm._v("mdi-dots-vertical")])],1)]}}],null,true)},[_c('v-list',{staticClass:"py-1",attrs:{"dense":""}},[(item.canApprove)?_c('v-list-item',{staticClass:"pa-1 py-0"},[_c('v-tooltip',{attrs:{"right":"","z-index":"200"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
				var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"color":"primary","text":"","small":""},on:{"click":function($event){return _vm.$emit('validate', item)}}},on),[_c('v-icon',[_vm._v("mdi-check")])],1)]}}],null,true)},[_c('span',{staticClass:"caption"},[_vm._v("Validate")])])],1):_vm._e(),_c('v-list-item',{staticClass:"pa-1 py-0"},[_c('v-tooltip',{attrs:{"right":"","z-index":"200"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
				var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"text":"","small":""},on:{"click":function($event){return _vm.$adminSurf('openHistory', item, 'billable')}}},on),[_c('v-icon',[_vm._v("mdi-history")])],1)]}}],null,true)},[_c('span',{staticClass:"caption"},[_vm._v("History")])])],1),_c('v-list-item',{staticClass:"pa-1 py-0"},[_c('v-tooltip',{attrs:{"right":"","z-index":"200"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
				var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"text":"","small":"","color":"grey"},on:{"click":function($event){return _vm.$emit('duplicate', item.id)}}},on),[_c('v-icon',[_vm._v("mdi-content-copy")])],1)]}}],null,true)},[_c('span',{staticClass:"caption"},[_vm._v("Duplicate")])])],1),(!item.isCancelled && item.status != 6)?_c('v-list-item',{staticClass:"pa-1 py-0"},[_c('v-tooltip',{attrs:{"right":"","z-index":"200"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
				var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"text":"","small":"","color":"secondary"},on:{"click":function($event){return _vm.$adminSurf('closeBillable', item)}}},on),[_c('v-icon',[_vm._v("mdi-door")])],1)]}}],null,true)},[_c('span',{staticClass:"caption"},[_vm._v("Close")])])],1):_vm._e(),(!item.isCancelled && item.status == 6 && _vm.$security.can('full_access'))?_c('v-list-item',{staticClass:"pa-1 py-0"},[_c('v-tooltip',{attrs:{"right":"","z-index":"200"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
				var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"text":"","small":"","color":"secondary"},on:{"click":function($event){return _vm.$adminSurf('openBillable', item)}}},on),[_c('v-icon',[_vm._v("mdi-door-open")])],1)]}}],null,true)},[_c('span',{staticClass:"caption"},[_vm._v("Reopen")])])],1):_vm._e(),(!item.isCancelled && item.status != 6)?_c('v-list-item',{staticClass:"pa-1 py-0"},[_c('v-tooltip',{attrs:{"right":"","z-index":"200"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
				var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"text":"","small":"","color":"error"},on:{"click":function($event){return _vm.$emit('cancel', item)}}},on),[_c('v-icon',[_vm._v("mdi-cancel")])],1)]}}],null,true)},[_c('span',{staticClass:"caption"},[_vm._v("Cancel")])])],1):_vm._e(),_c('v-list-item',{staticClass:"pa-1 py-0"},[_c('watchers-menu',{attrs:{"entity-id":item.id,"watchers":item.subscribers,"required-permission":"billables"},on:{"watcher-added":function($event){return item.subscribers.push($event)},"watcher-removed":function($event){item.subscribers.splice(item.subscribers.findIndex(function (x) { return x.userId == $event; }), 1)}}})],1)],1)],1):_vm._e()]}}],null,true)},'v-data-table',_vm.$attrs,false),_vm.$listeners))}
var staticRenderFns = []

export { render, staticRenderFns }