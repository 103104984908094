<template>
	<v-data-table
		:value="value"
		class="rounded-lg bordered new-back lighten2"
		:headers="headers" :items="workItems"
		:show-select="showSelect"
		ref="table"
		v-on="$listeners"
	>
		<template #body="props">
			<tbody v-if="$refs.table">
				<v-nodes
					v-for="(item) in props.items" :key="item.id"
					:vnodes="$refs.table.genItems([item], props)"
					@dragstart="$event.dataTransfer.setData('id', item.id)"
				/>
			</tbody>
		</template>

		<template #[`item.code`]="{ item }">
			<span class="text-no-wrap">
				{{item.code}}
			</span>
		</template>
		<template #[`item.type`]="{ item }">
			<v-tooltip top>
				<template v-slot:activator="{ on }">
					<v-btn v-on="on" dark :elevation="0" fab x-small :color="item.typeColor">
						<v-icon>{{ item.typeIconClass }}</v-icon>
					</v-btn>
				</template>
				<span v-text="item.typeDisplayName"></span>
			</v-tooltip>
		</template>
		<template #[`item.name`]="{ item }">
			<v-text-field
				v-if="item.isNew && !item.name" :value="item.name"
				label="Summary*" @change="handleFirstSummaryChange($event, item)"
				outlined dense hide-details single-line
				class="inline-block"
			/>
			<div v-else :class="{oneline: singleLine}" style="text-align:left;" :title="item.name">{{item.name}}</div>
		</template>
		<template #[`item.description`]="{ item }">
			<div :class="{oneline: singleLine}" v-if="!item.isNew" style="text-align:left;" v-html="item.description"></div>
		</template>
		<template #[`item.statusName`]="{ item }">
			<div
				:class="{'oneline-ellipsis': singleLine}"
				:style="`background:${item.statusColor};`" class="status-container"
			>
				<v-icon v-if="item.statusIconClass" color="white" small>{{item.statusIconClass}}</v-icon>
				{{setMaxText(item.statusName, 25)}}
			</div>
		</template>
		<template #[`item.priority`]="{ item }">
			<v-tooltip top>
				<template v-slot:activator="{ on }">
					<div class="fill-height d-flex align-center justify-center">
						<v-img v-on="on" style="flex: none" width="24px" height="24px" :src="require(`../../assets/priority-icons/${item.priority}.png`)" contain></v-img>
					</div>
				</template>
				<span class="text-capitalize" v-text="item.priorityDisplayName"></span>
			</v-tooltip>
		</template>
		<template #[`item.value`]="{ item }">
			<v-tooltip top>
				<template v-slot:activator="{ on }">
					<div class="fill-height d-flex align-center justify-center">
						<v-img v-on="on" style="flex: none" width="24px" height="24px" :src="require(`../../assets/priority-icons/${item.value}.png`)" contain></v-img>
					</div>
				</template>
				<span class="text-capitalize" v-text="item.valueDisplayName"></span>
			</v-tooltip>
		</template>
		<template #[`item.estimatedEffort`]="{ item }">
			{{item.estimatedEffort}}
		</template>
		<template #[`item.totalHours`]="{ item }">
			{{ roundedTotalHours(item.totalHours) }}
		</template>
		<template #[`item.currentSprintName`]="{ item }">
			<span>{{item.currentSprintName || ''}}</span>
		</template>
		<template #[`item.allTags`]="{ item }">
			<div :class="{oneline: singleLine}">
				<v-chip class="mr-1" x-small dark v-for="(tag, ix) in item.tags" :key="ix"
					:color="tag.tagColor"
					:style="`color: ${isBlackText(tag.tagColor) ? 'white' : 'black'}`"
				>
					{{tag.tagName}}
				</v-chip>
			</div>
		</template>
		<template #[`item.assignedToBlueTag`]="{ item }">
			<div>
				<span v-if="item.assignedToId">{{item.assignedToBlueTag || item.assignedToFullName}}</span>
				<i v-else>Unassigned</i>
			</div>
		</template>
		<template #[`item.reportedByBlueTag`]="{ item }">
			<span>{{ item.reportedByBlueTag || item.reportedByFullName }}</span>
		</template>
		<template #[`item.createdAt`]="{ item }">
			<small>{{item.createdAt | formatDate}}</small>
		</template>
	</v-data-table>
</template>

<script>
export default {
	props: {
		workItems: { type: Array, default: () => [] },
		value: { type: Array, default: () => [] },
		singleLine: { type: Boolean, default: false },
		headers: { type: Array, default: () => [] },
		showSelect: { type: Boolean, default: false },
	},
	components: {
		VNodes: {
      functional: true,
      render: (h, ctx) => ctx.props.vnodes
				.map(vnode => {
					vnode.data = vnode.data || {}
					vnode.data.attrs = vnode.data.attrs || {}
					vnode.data.attrs.draggable = true
					vnode.data.key = vnode.key
					vnode.data.on = vnode.data.on || {}
					vnode.data.on.dragstart = ctx.listeners.dragstart

					return vnode
				})
    }
	},
	data: () => ({}),
	methods: {
		roundedTotalHours(hours) {
      return hours ? this.round(hours) : '0'
    },
	},
}
</script>

<style lang="scss" scoped>
.status-container{
  box-shadow: 0 0 5px #888;
  display: inline-block;
  min-height: 30px;
  max-height: 60px;
  width: 145px;
  color: white;
  overflow: hidden;
  margin: 5px 0;
  border-radius: 5px;
  padding: 5px 7px;
}
.oneline{
  line-height: 48px;
  height: 48px;
  overflow: hidden;
  text-overflow: ellipsis;
}
</style>