<template>
	<blue-confirm-dialog ref="deleteDialog">
		<template #text>
			<v-stepper v-model="step" vertical flat class="pb-0">

				<v-stepper-step
					:rules="[() => !invoices.length]"
					@click="step > 1 ? step = 1 : null" :complete="step > 1" step="1"
					:class="{'cursor-pointer' : step > 1}"
				>
					Invoices
				</v-stepper-step>
				<v-stepper-content step="1">
					{{!invoices.length ? 'There is no invoice linked to this billable' : 'Unlink required billable items from the next invoices'}}
					<div v-for="(invoice, ix) in invoices" :key="ix">
						<span class="title"> {{invoice.code}} </span><br>
						<v-chip v-for="(bi, jx) in invoice.billableItems" :key="jx" class="mr-1 mb-1">
							{{bi.billableItem.code}}
						</v-chip>
					</div>
					<div class="mt-2">
						<v-btn :disabled="!!invoices.length" @click="step++" small color="primary">Next</v-btn>
					</div>
				</v-stepper-content>

				<v-stepper-step
					@click="step > 2 ? step = 2 : null" :complete="step > 2" step="2"
					:class="{'cursor-pointer' : step > 2}"
				>
					Billable Items
				</v-stepper-step>
				<v-stepper-content step="2">
					<template v-if="!billableItems.length">
						There is no billable items linked to this billable
						<div class="mt-2">
							<v-btn @click="step++" small color="primary">Next</v-btn>
							<v-btn @click="step--" small text>Previous</v-btn>
						</div>
					</template>
					<template v-else>
						The next billable items will be deleted:
						<div class="mt-1" style="display: grid; grid-template-columns: auto auto auto; gap: 15px; padding: 0 5px">
							<div
								v-for="(billableItem, ix) in billableItems" :key="ix"
								class="elevation-2 py-1 px-2 rounded background--lighten2"
							>
								<div>
									<span style="font-size: .9em">
										{{billableItem.startDate | formatDate}} - {{billableItem.endDate | formatDate}}
									</span>
								</div>
								<span style="font-size: 1.2em">
									{{billableItem.code}}
								</span>
							</div>
						</div>
						<div class="mt-5">
							<v-btn @click="step++" small color="primary">Confirm</v-btn>
							<v-btn @click="step--" small text>Previous</v-btn>
						</div>
					</template>
				</v-stepper-content>

				<v-stepper-step
					@click="step > 3 ? step = 3 : null" :complete="step > 3" step="3"
					:class="{'cursor-pointer' : step > 3}"
				>
					Assignments
				</v-stepper-step>
				<v-stepper-content step="3">
					<template v-if="!assignments.length">
						There is no assignments linked to this billable
						<div class="mt-2">
							<v-btn @click="step++" small color="primary">Next</v-btn>
							<v-btn @click="step--" small text>Previous</v-btn>
						</div>
					</template>
					<template v-else>
						Do you want to unlink or delete the current assignments?
						<div class="mt-1" style="display: grid; grid-template-columns: auto auto; gap: 15px; padding: 0 5px">
							<div
								v-for="(assignment, ix) in assignments" :key="ix"
								class="elevation-2 py-1 px-2 rounded background--lighten2"
							>
								<div class="flex-space-between">
									<b class="mr-2"> {{assignment.userBlueTag || assignment.userFullName}} </b>
									<span style="font-size: .9em">
										{{assignment.startDate | formatDate}} - {{assignment.endDate | formatDate}}
									</span>
								</div>
								<span style="font-size: 1.2em">
									{{assignment.billableLineRole}}
								</span>
							</div>
						</div>
						<div class="mt-5 mb-1 px-1">
							<v-btn @click="deleteAssignments = false; step++" small color="primary" class="mr-2">
								<v-icon left>mdi-link-variant-off</v-icon> Unlink
							</v-btn>
							<v-btn @click="deleteAssignments = true; step++" small color="error" class="mr-2">
								<v-icon left>mdi-delete</v-icon> Delete
							</v-btn>
						</div>
					</template>
				</v-stepper-content>

				<v-stepper-step step="4">
					Confirm cancellation
				</v-stepper-step>
				<v-stepper-content step="4">
					<div class="flex-all-center mt-2">
						<div class="text-center title">
							<div class="mb-1"> Are you sure you want to cancel this billable? </div>
							<v-btn class="mr-2" @click="$refs.deleteDialog.close(true)" :disabled="step < 3" color="primary">Confirm</v-btn>
							<v-btn @click="$refs.deleteDialog.close(false)" text>Cancel</v-btn>
						</div>
					</div>
				</v-stepper-content>
			</v-stepper>
		</template>
		<template #actions>
			<v-spacer/>
		</template>
	</blue-confirm-dialog>
</template>

<script>
export default {
	data: () => ({
		resolve: () => {},
		step: 1,
		assignments: [],
		billableItems: [],
		invoices: [],
		deleteAssignments: false,
	}),
	methods: {
		open(id){
			this.step = 1
			this.$http.get(`api/Billable/CanDelete/${id}`)
				.then((res) => {
					this.invoices = res.data.invoices
					if(!this.invoices.length) this.step = 2
					this.billableItems = res.data.billableItems
					if(!this.billableItems.length && this.step === 2) this.step = 3
					this.assignments = res.data.assignments
					if(!this.assignments.length && this.step === 3) this.step = 4
				})
				.catch(err => {
					console.log(err)
					this.$root.error('Cannot get billable info')
					return new Promise(resolve => resolve(false))
				})
			this.$refs.deleteDialog
				.open({
					title: 'Cancel Billable',
					description: 'Are you sure you want to cancel this billable?',
					width: 700, addClose: true,
				})
				.then(res => {
					this.resolve({
						confirmed: res,
						deleteAssignments: this.deleteAssignments
					})
				})
			
			return new Promise(resolve => this.resolve = resolve)
		},
	}
}
</script>

<style lang="scss" scoped>
::v-deep .v-stepper__content{
	padding-top: 0;
}
</style>