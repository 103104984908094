<template>
  <v-menu :disabled="disabled">
    <template #activator="{ on }">
      <v-btn v-on="on"
        :color="currentLevel.color"
        :dark="value > 3"
        width="150"
        small depressed
      >
        <v-icon size="18" class="mr-1">{{ currentLevel.icon }}</v-icon>
        {{ currentLevel.text }}
      </v-btn>
    </template>
    <v-list dense >
      <v-list-item
        v-for="level in shownLevels" :key="level.value"
        @click="$emit('input', level.value)"
      >
        <v-list-item-content class="py-1">
          <div class="d-flex align-center">
            <v-icon left>{{ level.icon }}</v-icon>
            <div>
              <span class="body-2">{{ level.text }}</span><br>
              <span class="caption">{{ level.description || defaultDescriptions[level.value] }}</span>
            </div>
          </div>
        </v-list-item-content>
      </v-list-item>
    </v-list>
  </v-menu>
</template>

<script>
import permissionsJSON from "../json/permissions.json"

export default {
  props: {
    value: { type: Number, default: 0 },
    type: { type: String, default: 'full' },
    disabled: { type: Boolean, default: false },
  },
  data: () => ({
    accessLevels: permissionsJSON.accessLevels,
    defaultDescriptions: permissionsJSON.defaultDescriptions,
  }),
  created() {
  },
  computed: {
    currentLevel(){
      return this.accessLevels[this.type]
        .find(x => x.value == this.value) || this.accessLevels[this.type][0]
    },
    shownLevels(){
      return this.accessLevels[this.type]
    },
  },
};
</script>
