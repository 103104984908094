<template>
	<div class="pa-3 full-height new-back">
		<div class="d-inline-flex py-1">
			<div>
				<v-tabs v-model="activeTab" background-color="newDesignBackground">
					<v-tab>Gantt</v-tab>
					<v-tab>Dashboard</v-tab>
				</v-tabs>
			</div>
		</div>
		<v-tabs-items v-model="activeTab" class="new-back">
			<v-tab-item :value="0" class="pt-2">
				<admin-board-gantt/>
			</v-tab-item>
			<v-tab-item :value="1" class="pa-2 pt-5">
				<admin-board-dashboard/>
			</v-tab-item>
		</v-tabs-items>
	</div>
</template>

<script>
import AdminBoardGantt from "../../components/admin-surf/board/AdminBoardGantt"
import AdminBoardDashboard from "../../components/admin-surf/board/AdminBoardDashboard"

export default {
	components: { AdminBoardGantt, AdminBoardDashboard },
	data: () => ({
		activeTab: 0,
	}),
	mounted() {
		document.title = 'Board'
		this.$q.log(4, 21, 3, 'NA')
	},
}
</script>

<style lang="scss" scoped>
::v-deep .v-window {
	overflow: visible !important;
}
</style>