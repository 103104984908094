<template>
	<div>
		<div class="top py-3 pl-5 pr-3 d-flex justify-space-between align-center">
			<v-menu bottom offset-y zIndex="300">
				<template #activator="{ on, attrs }">
					<v-btn v-on="on" v-bind="attrs" text class="ml-n5">
						<b>{{ onlyActive ? 'Open' : 'All' }} chats ({{shownChats.length}})</b>
						<v-icon>mdi-chevron-down</v-icon>
					</v-btn>
				</template>
				<v-list>
					<v-list-item @click="onlyActive = true">
						<v-list-item-content>
							Open chats ({{activeChats.length}})
						</v-list-item-content>
					</v-list-item>
					<v-list-item @click="(onlyActive = false)">
						<v-list-item-content>
							All chats ({{chats.length}})
						</v-list-item-content>
					</v-list-item>
				</v-list>
			</v-menu>
			<v-btn @click="$emit('close')" icon><v-icon>close</v-icon></v-btn>
		</div>
		<div v-if="!shownChats.length" class="support text-center">
			<img height="50px" src="../assets/avatar_blue.png" contain/>
			<h3>BluePeople Support</h3>
			<span>No {{onlyActive ? 'open ' : ''}}chats</span>
		</div>
		<div class="chats">
			<div
				v-for="chat in shownChats" :key="chat.id"
				@click="$emit('input', chat)"
				class="d-flex align-center cursor-pointer chat py-3 pl-3"
			>
				<user-avatar :user-id="chat.userId" :size="40" hide-loading/>
				<div>
					<b>{{(chat.user.blueTag || chat.user.fullName)}}</b>
					<div class="one-line body-2">
						{{ chat.messages[chat.messages.length-1].text }}
					</div>
				</div>
				<div
					v-if="chat.isActive && chat.unreadChatsMessages"
					class="dot caption d-flex align-center justify-center primary"
				>
					{{chat.unreadChatsMessages}}
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import moment from "moment"

export default {
	props: {
		chats: Array,
	},
	data: () => ({
		onlyActive: true,
	}),
	computed: {
		sortedChats(){
			return this.chats.sort((a, b) => {
				const lastMessageA = a.messages[a.messages.length-1]
				const lastMessageB = b.messages[b.messages.length-1]

				if(lastMessageA.type == lastMessageB.type)
					return moment(lastMessageA.date).isAfter(lastMessageB.date) ? -1 : 1
				return lastMessageA.type > lastMessageB.type ? 1 : -1
			})
		},
		activeChats(){
			return this.sortedChats.filter(x => x.isActive)
		},
		shownChats(){
			return this.onlyActive ? this.activeChats : this.sortedChats
		},
	},
}
</script>

<style lang="scss" scoped>
.top{
	border-bottom: 1px solid var(--v-lightgray-base);
	position: sticky; top: 0;
	z-index: 10;
	padding-top: 10px;
	background: var(--v-newDesignBackground-base);
}
.support{
	margin-top: 35vh;
	span{
		color: var(--v-text-lighten);
	}
}
.chat{
	border-bottom: 1px solid var(--v-lightgray-base);
	gap: 10px;
}
.dot{
	color: white;
	width: 25px;
	height: 25px;
	border-radius: 50%;
}
.one-line {
	width: 185px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
</style>