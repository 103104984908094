<template>
  <v-container fluid>
    <project-config-boards v-if="!isPersonal" class="mb-5"/>
    <project-config-approvals class="mb-5"/>
    <project-config-status @saved="init" class="mb-5"/>
    <project-config-tags @saved="init" class="mb-5"/>
  </v-container>  
  
</template>

<script>
import ProjectConfigBoards from '../../components/project/ProjectConfigBoards'
import ProjectConfigApprovals from '../../components/project/ProjectConfigApprovals'
import ProjectConfigStatus from "../../components/project/ProjectConfigStatus"
import ProjectConfigTags from "../../components/project/ProjectConfigTags"

export default {
  components: {
    ProjectConfigBoards,
    ProjectConfigApprovals,
    ProjectConfigStatus,
    ProjectConfigTags,
  },
  props: ["id", 'canEditProject', 'projectName', 'isPersonal'],
  data: () => ({
    config: {},
    boardConfigs: [],
    sprints: [],
    types: [],
    selectNewStatusListDialog: false,
    project: {},
    members: [],
    
    roles: [
      { name: 'Business Analyst', value: 0 },
      { name: 'Delivery Manager', value: 1 },
      { name: 'Developer ', value: 2 },
      { name: 'Key User', value: 3 },
      { name: 'Product Owner', value: 4 },
      { name: 'Program Manager', value: 5 },
      { name: 'Project Manager', value: 6 },
      { name: 'QA', value: 7 },
      { name: 'Solutions Architect', value: 8 },
      { name: 'Stakeholder', value: 9 },
      { name: 'UX/UI', value: 10 },
      { name: 'Other', value: 11 },
    ],
  }),
  created() {
    this.$q.log(1, 18, 3, this.id, this.$parent.item?.id || this.id)
    this.project = this.$q.getParentVariable('ProjectDetails', 'item')
    this.init()
  },
  methods: {
    init() {
      this.$http.get(`/api/project/${this.id}/config?includeLeadTime=true`)
        .then(res => {
          this.config = res.data
          this.sprints = res.data.sprints
          this.config.workItemStatuses = this.config.workItemStatuses.sort((a, b) => {
            if(a.workItemType == b.workItemType){
              return a.sortIndex - b.sortIndex
            }
            return a.workItemType - b.workItemType
          })
          this.config.boardConfigs = this.config.boardConfigs.sort((a, b) => {
            return a.workItemType - b.workItemType
          })
          this.boardConfigs = this.config.boardConfigs.map(config => {
            return{
              ...config,
              leadTimeGoalTemporal: config.leadTimeGoal,
              leadTimeGoalUnitTemporal: config.leadTimeGoalUnit,
              defaultColorTemporal: config.defaultColor || '#fff',
              nicknameTemporal: config.nickname || '',
              defaultColor: config.defaultColor || '#fff',
              editing: false,
            }
          })
          this.config.tags.forEach(tag => {
            tag.types = tag.types.filter(t => this.boardConfigs.some(c => c.workItemType == t))
          })
        })
        .catch(res => {
          window.getApp.error("Cannot obtain project configuration.")
        })
      
      this.$http.get(`/api/Enums/WorkItemType`)
        .then(res => {
          this.types = res.data
          if(this.isPersonal){
            this.types = this.types.filter(type => type.name == 'task')
            this.createStatusListForType = 3
          }
        })
        .catch(res => {
          window.getApp.error("Cannot obtain types.")
          console.error(res)
        })

      let members = []
      const membersPr = this.$http.get(`api/project/${this.id}/members`)
        .then(res => {
          members = members.concat(
            res.data.map(member => {
              if(!member.user.blueTag)
                member.user.blueTag = member.user.fullName
              member.user.role = this.roles[member.role].name
              return member.user
            })
          )
        })
        .catch(err => {
          console.error(err)
          window.getApp.error("Cannot get members.");
        })
      const nucleiPr = this.$http.get(`api/nucleiManagement/${this.id}/members`)
        .then((res) => {
          members = members.concat(
            res.data.map(x => ({
              id: x.assignment.userId,
              blueTag: x.assignment.userBlueTag,
              fullName: x.assignment.userFullName,
              role: x.assignment.billableLineRole || x.assignment.otherBillableLine
            }))
          )
        })
        .catch(err => {
          console.error(err)
          window.getApp.error("Cannot get nuclei members.");
        })
      
      Promise.all([membersPr, nucleiPr])
        .then(() => {
          this.members = this.$q.distinctByProperty(members, 'id')
        })

    }
  }
}
</script>

<style scoped lang="scss">
</style> 