<template>
  <v-dialog
    :fullscreen="$vuetify.breakpoint.xsOnly"
    scrollable
    persistent
    v-model="dialog"
    :max-width="550"
    v-if="dialog"
  >
    <v-form ref="form">
      <v-card style="padding: 20px">
        <v-card-title class="pt-2 pb-4">
          {{ workItem.id ? "Edit Action" : "New Action" }}
        </v-card-title>

        <v-card-text class="pt-2">
          <v-text-field
						outlined
						v-model="workItem.name"
						label="Summary *"
						:readonly="readonly"
					/>
					<v-row>
						<v-col>
							<v-autocomplete
								:items="shownCodes"
								:item-value="item => item.id"
								v-model="workItem.id"
								outlined
								dense
								label="Action *"
								disabled
							>
								<template v-slot:selection="{ item }">
									<v-icon :color="item.color" class="mr-2">
										mdi-label
									</v-icon>
									{{item.code}}
								</template>
								
      					<template v-slot:item="{ item }" class="mr-2">
									<v-icon :color="item.color">
										mdi-label
									</v-icon>
									{{item.code}}
								</template>
							</v-autocomplete>
						</v-col>
						<v-col>
							<v-select dense outlined label="Status *" v-model="workItem.statusId" item-value="id" item-text="name" :items="projectConfig.workItemStatuses">
                <template v-slot:item="{ item }">
                  <v-chip class="cursor-pointer mt-2 mb-2" label :style="{ width: '100%' }" dark :color="item.color">
                    <v-icon class="mr-2">{{item.iconClass}}</v-icon>
                    {{item.name}}
                  </v-chip>
                </template>
                <template v-slot:selection="{ item }">
                  <v-icon :color="item.color" class="mr-2">{{item.iconClass}}</v-icon>
                  {{setMaxText(item.name, 25)}}
                </template>
              </v-select>
						</v-col>
					</v-row>

					<v-text-field
						outlined
						v-model="workItem.description"
						label="Description *"
						:readonly="readonly"
					/>

					<v-row>
						<v-col>
							<v-autocomplete
								label="Assigned to" :items="users" 
								item-value="id" item-text="blueTag"
								:filter="(item, queryText, itemText) => normalizeIncludes(item.blueTag + ' ' + item.fullName, queryText)"
								v-model="workItem.reportedToId"
								outlined dense
								:readonly="readonly"
							>
								<template v-slot:item="{ item }">
									<v-list-item-avatar color="indigo" class="headline font-weight-light white--text">
										{{ (item.blueTag || '').charAt(0) }}
									</v-list-item-avatar>
									<v-list-item-content>
										<v-list-item-title v-text="item.blueTag"></v-list-item-title>
										<v-list-item-subtitle v-text="item.fullName"></v-list-item-subtitle>
									</v-list-item-content>
								</template>
								<template v-slot:selection="{ item }">
									<v-list-item-content>
										<v-list-item-title v-text="item.blueTag"></v-list-item-title>
									</v-list-item-content>
								</template>
							</v-autocomplete>
						</v-col>

						<v-col>
							<v-menu
								v-model="menu"
								:close-on-content-click="false"
								:nudge-right="40"
								transition="scale-transition"
								offset-y
								min-width="auto"
								:readonly="readonly"
							>
								<template v-slot:activator="{ on, attrs }">
									<v-text-field
										v-model="workItem.dueDate"
										label="Due Date"
										prepend-icon="mdi-calendar"
										readonly
										v-bind="attrs"
										v-on="on"
										outlined dense
										clearable
									></v-text-field>
								</template>
								<v-date-picker
									v-model="workItem.dueDate"
									@input="menu = false"
									no-title
									:readonly="readonly"
								></v-date-picker>
							</v-menu>
						</v-col>
					</v-row>

        </v-card-text>

        <v-card-actions class="px-6">
          <v-spacer></v-spacer>

					<div v-show="showWarning" class="status-warning">
						<div style="width: 25px">
							<v-icon color="#cc3300">warning</v-icon>
						</div>
						<div class="txt">
							It is necessary to have at least one status of type task
						</div>
					</div>

          <v-btn color="primary" text @click="cancel"> Cancel </v-btn>
          <v-btn color="primary" depressed @click="save" :disabled="showWarning || !projectId" >
            {{ workItem.id || newTaskCreation ? "Save" : "Create" }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-form>
  </v-dialog>
</template>
<script>
import moment from "moment"
import _ from 'lodash'
export default {
  props: ["projectId"],
  data() {
    return {
      dialog: false,
      workItem: {
        code: null
      },
			tasks: [{
				code: 'NEW ACTION',
				id: null
			}],
			workItem: {},
			users: [],
			projectConfig: {},
			menu: false,
			project: {},
			showWarning: false,
			newTaskCreation: false,
			reject: null,
			resolve: null,
    };
  },
	computed: {
		readonly() {
			return !this.projectId
		},
		shownCodes() {
			if(this.workItem.id) {
				let tasks = _.cloneDeep(this.tasks)
				tasks.push({
					id: this.workItem.id,
					code: this.workItem.code
				})
				return tasks
			}
			return this.tasks
		}
	},
  methods: {
		returnWorkItemPromise() {
			let wi = JSON.parse(JSON.stringify(this.workItem))
			let assignedTo = this.users.find(user => user.id === wi.assignedToId)
			let status = this.projectConfig.workItemStatuses.find(st => st.id === wi.statusId)
			wi.status = status
			wi.relatedWorkItemTypeIconClass = status.workItemTypeIconClass
			wi.relatedWorkItemTypeColor = status.workItemTypeColor
			wi.relatedWorkItemName = wi.name
			wi.relatedWorkItemStatusColor = status?.color
			wi.relatedWorkItemStatusIconClass = status?.workItemTypeIconClass
			wi.relatedWorkItemStatusName = status?.name
			wi.assignedTo = assignedTo
			wi.assignedToBlueTag = assignedTo?.blueTag
			this.resolve(wi)
			this.cancel()
		},
    save() {
			if(!this.workItem.statusId || !this.workItem.name) {
        window.getApp.error("Cannot save Action.")
				return;
			}
			if(this.newTaskCreation) {
				this.returnWorkItemPromise()
				return
			}
			else if(this.workItem.id){
				this.updateWorkItem()
				return
			} 
			this.createWorkItem()
			this.workItem = {}
    },
		updateWorkItem() {
			this.$http.put(`api/WorkItem/${this.workItem.id}`, this.workItem)
				.then((res) => {
					if (!res.data.id) {
						window.getApp.error(res.data)
						return
					}
					window.getApp.success("Work Item updated successfully.")
					this.resolve()
					this.workItem = {}
					this.dialog = false
				})
				.catch((response) => {
					window.getApp.error("Cannot update work item.")
					console.error(response)
				})
		},
		createWorkItem() {
			this.$http.post(`api/WorkItem`, this.workItem)
				.then((res) => {
					if (!res.data.id) {
						window.getApp.error(res.data)
						return
					}
					window.getApp.success("Work Item created successfully.")
					this.dialog = false
					this.resolve(res.data)
				})
				.catch((response) => {
					window.getApp.error("Cannot create work item.")
					console.log(response)
				})
		},
    async open(id, taskId = '', noCreatedWi) {
			this.newTaskCreation = !taskId
			let projectId = this.projectId || this.workItem.projectId
      this.dialog = true
			this.showWarning = false
			let configPromise = this.$http.get(`api/project/${projectId}/config?active=true`).then(res => {
				this.projectConfig = res.data
				this.projectConfig.workItemStatuses = this.projectConfig.workItemStatuses.filter(s => s.workItemType === 3)
				this.showWarning = !this.projectConfig.workItemStatuses.length
			})
			let userPromise = this.$blueSystem.getCurrentUser().then(user => {
				this.currentUser = user
			})
			let projectPromise = this.$http.get(`api/project/${projectId}`).then(res => {
				this.project = res.data
			})
			this.$http
				.get(`/api/project/${projectId}/members`)
				.then(res => {
					let members = res.data
					this.users = members.map(member => member.user)
					this.$q.addLastItem(this.users, 'Unassigned', 'blueTag')              
				})
				.catch(res => {
					console.log(res)
					window.getApp.error("Cannot obtain project members.")
				})
			if(id){
				this.$http.get(`api/workItem/${id}`).then((res) => {
					this.workItem = res.data
					this.workItem.dueDate = this.workItem.dueDate ? moment(this.workItem.dueDate).format( 'YYYY-MM-DD') : ''
					this.workItem.assignedToId = this.workItem.assignedToId ? this.workItem.assignedToId : null
				})
			}
			Promise.all([configPromise, userPromise, projectPromise]).then(ress => {
				if (!id) {
					let statuses = [];
					if (this.projectConfig && this.projectConfig.workItemStatuses)
						statuses = this.projectConfig.workItemStatuses
					let curStatus = statuses.filter(s => s.isDefault)
					curStatus = curStatus.length ? curStatus[0].id : null;
					const wi = noCreatedWi.temporalId ? noCreatedWi : {
						temporalId: Date.now(),
						id: null,
						description: "",
						estimatedEffort: null,
						name: "",
						priority: 3,
						projectId: this.project.id,
						reportedById: this.currentUser.id,
						reportedToId: null,
						sprintId: null,
						statusId: curStatus,
						type: 3,
						createdAt: new Date(),
						tags: [],
						// projectHealthcheckId: taskId ? taskId : null,
					}
					this.workItem = wi
				}
			})
      return new Promise((resolve, reject) => {
        this.resolve = resolve
        this.reject = reject
      })
    },
    cancel() {
			this.workItem = {}
      this.dialog = false
      this.reject()
    },
  },
}
</script>
<style scoped lang="scss">
.color-black {
  color: black !important;
}
.severity {
  height: 40px;
  display: flex;
  align-items: center;
}
.status-warning{
	display: flex;
	align-items: center;
	div {
		display: inline-block;
	}
	.txt{
		margin-left: 12px;
		width: calc(100% - 45px);
		font-size: 14px;
	}
}
</style>