<template>
    <v-btn-toggle v-model.number="value[attr]" mandatory @change="change" :dense="dense">
        <template v-for="option of keys">
            <v-tooltip top :key="levelOptions[option].key">
                <template v-slot:activator="{ on }">
                    <v-btn 
                        v-on="on"
                        :disabled="viewOnly && value[attr] != levelOptions[option].key"
                        :color="levelOptions[option].properties.color"
                        text
                        :value="Number(levelOptions[option].key)"
                    >
                        <v-icon :color="levelOptions[option].properties.color">{{levelOptions[option].properties.icon}}</v-icon>
                    </v-btn>
                </template>
                <span :key="option">{{levelOptions[option].properties.tooltip}}</span>
            </v-tooltip>
        </template>
    </v-btn-toggle>
</template>

<script>

import {
  ViewLevelOptions,
  PermissionsOptions
} from '../enums/permissions';

export default {
  props: {
    viewOnly: { type: Boolean, default: true},
    value: { type: Object, default: {viewLevel: 1} },
    attr: {type: String, default: "viewLevel"},
    type: {type: String, default: "ViewLevelOptions"},
    dense: {type: Boolean, default: false},
  },
  data:() => ({
        keys: [],
        levelOptions: ViewLevelOptions,
  }),
  created() {
    if(this.type === "PermissionsOptions") {
        this.levelOptions = PermissionsOptions;
        this.keys = ['CANNOT_BY_DEFAULT', 'CAN', 'CAN_NOT'];
    } else {
        this.levelOptions = ViewLevelOptions;
        this.keys = ['CANNOT_VIEW_BY_DEFAULT', 'CAN_VIEW', 'CAN_NOT_VIEW'];
        
    }
  },
  methods: {
      change(value) {
          if(!this.viewOnly) {
            this.$emit('input', {... this.value, [this.attr]: value});
          }
      }
  }
}

</script>
