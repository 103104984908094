<template>
	<div class="new-back fill-height pa-5">
		<AccountBalance v-if="customerId" :id="customerId" disabled/>
	</div>
</template>

<script>
import AccountBalance from '../../components/admin-surf/customer/AccountBalance'

export default {
	components: { AccountBalance },
	data: () => ({
		customerId: null
	}),
	created() {
		this.$blueSystem.getCurrentUser()
			.then(user => {
				this.customerId = user.customerId
			})
	}
}
</script>