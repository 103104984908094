<template>
  <div class="pa-3 new-back fill-height" :style="{ width: 'calc(100vw - 206px);' }">
    
    <div class="d-flex justify-space-between">
      <filter-search-input v-if="!overviewView" v-model.lazy="filters.search" :applied-filters="appliedFilters" outlined dense>
        <v-text-field
          v-model="filters.id"
          prefix="RISK -"
          label="ID"
          placeholder="ID"
          outlined
          dense hide-details clearable class="input"
          maxlength="10"
        />

        <v-select
          v-model="filters.status" :items="riskTypes"
          label="Status"
          item-value="value" item-text="name"
          outlined dense hide-details multiple clearable
        />
        
        <v-autocomplete
          label="Reported by" :items="users"
          item-value="id" item-text="blueTag"
          v-model="filters.reportedBy" outlined multiple hide-details dense clearable
        >
          <template v-slot:item="{ item, attrs }">
            <v-list-item-action>
              <v-checkbox v-model="attrs.inputValue"></v-checkbox>
            </v-list-item-action>
            <v-list-item-content class="pa-0">
              <v-list-item class="pa-0">
                <v-list-item-avatar color="indigo" class="headline font-weight-light white--text">
                  {{ (item.blueTag || '-').charAt(0) }}
                </v-list-item-avatar>
                <v-list-item-content>
                  <v-list-item-title>{{ item.blueTag }}</v-list-item-title>
                  <v-list-item-subtitle>{{ item.fullName }}</v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
            </v-list-item-content>
          </template>
          <template v-slot:selection="{ attrs, item, parent, selected }">
            <v-chip v-if="item === Object(item)"
              v-bind="attrs" class="accent--lighten" :input-value="selected" label small>
              <span class="pr-2">
                {{ item.blueTag }}
              </span>
              <v-icon small @click="parent.selectItem(item)">close</v-icon>
            </v-chip>
          </template>
        </v-autocomplete>
        
        <v-autocomplete
          v-model="filters.projects"
          label="Projects" :items="projects"
          item-value="id"  item-text="name" 
          outlined multiple hide-details dense clearable
        >
          <template v-slot:selection="{ attrs, item, parent, selected }">
            <v-chip v-if="item === Object(item)"
              v-bind="attrs" class="accent--lighten" :input-value="selected" label small>
              <span class="pr-2">
                {{ item.name }}
              </span>
              <v-icon small @click="parent.selectItem(item)">close</v-icon>
            </v-chip>
          </template>
        </v-autocomplete>
      </filter-search-input>
      <v-btn
        @click="editFromTable"
        color="primary"
        :disabled="!canEditProject && !!id"
        small fab
        v-show="!!id"
      >
        <v-icon dark>add</v-icon>
      </v-btn>
    </div>

    <div class="severity-container" v-if="!overviewView">
      <v-col cols="3" v-for="(severity, i) in severityValue" :key="severity.value[0]">
        <v-card @click="filterBySeverity(i)"  :style="{ borderColor: severity.active ? severity.color : '' }" class="severity-card">
          <v-card-title class="severity-title">
            <div class="severity-icon" :style="{ background: severity.backColor }">
              <v-icon :color="severity.color">mdi-alert-outline</v-icon>
            </div>
            <div>
              <h4>{{ getNumberOfRisksBySeverity(severity.value) }}</h4>
              <span class="risk-name">
                {{ severity.name }} Risk
              </span>
            </div>
          </v-card-title>
        </v-card>
      </v-col>
    </div>
    <br>
    <div class="max-container" :class="!overviewView ? 'mx-auto' : ''">
      <div :style="{ opacity: $vuetify.theme.isDark ? 0.8 : 1 }" class="matrix-container" v-if="!overviewView">
        <v-row
          v-for="row in probabilityRows"
          :key="row"
          class="probability-row"
        >
          <v-col
            v-for="col in impactCols"
            :key="col"
            class="impact-col"
            :style="{ background: getBackground(row, col) }"
          >
            <div
              v-if="
                risksBySeverity[col + '-' + (6 - row)] &&
                risksBySeverity[col + '-' + (6 - row)].length <= 3
              "
            >
              <div
                v-for="(risk, ix) in risksBySeverity[col + '-' + (6 - row)]"
                :key="ix"
                class="risk"
                :style="{
                  background: risk.color,
                  color: 'white',
                  border:
                    risk.id == selectedRisk.id ||
                    risk.id == tempSelectedRisk.id
                      ? '2px solid white'
                      : '',
                  fontSize:
                    risk.code.length < 12 ? '1em' :
                      risk.code.length < 16 ? '.75em' : '.6em'
                }"
                @click="selectedRisk = risk"
                @mouseover="
                  tempSelectedRisk = risk;
                  scrollTo(risk.id);
                "
                @mouseleave="tempSelectedRisk = {}"
              >
              <b>
                {{ risk.code.split("-")[1] }}
                {{ risk.code.split("-")[2] }}
              </b>
              </div>
            </div>

            <div v-else-if="risksBySeverity[col + '-' + (6 - row)]">
              <v-menu
                bottom
                origin="center center"
                transition="scale-transition"
              >
                <template v-slot:activator="{ on: menu, attrs }">
                  <v-tooltip top>
                    <template v-slot:activator="{ on: tooltip }">
                      <div
                        class="risk risk-center"
                        v-on="{ ...tooltip, ...menu }"
                        v-bind="attrs"
                        :style="{
                          border: risksBySeverity[col + '-' + (6 - row)].some(
                            (risk) =>
                              risk.id == selectedRisk.id ||
                              risk.id == tempSelectedRisk.id
                          )
                            ? '2px solid white'
                            : '',
                        }"
                      >
                        <div class="number">
                          {{ risksBySeverity[col + "-" + (6 - row)].length }}
                        </div>
                      </div>
                    </template>
                    <span>Show all</span>
                  </v-tooltip>
                </template>
                <v-list style="max-height: 320px; overflow-y: auto">
                  <v-list-item
                    v-for="(risk, ix) in risksBySeverity[
                      col + '-' + (6 - row)
                    ]"
                    :key="ix"
                    :style="{
                      cursor: 'pointer',
                      background:
                        risk.id == selectedRisk.id ||
                        risk.id == tempSelectedRisk.id
                          ? '#eee'
                          : '',
                    }"
                    @click="selectedRisk = risk"
                    @mouseover.native="
                      tempSelectedRisk = risk;
                      scrollTo(risk.id);
                    "
                    @mouseleave.native="tempSelectedRisk = {}"
                  >
                    <v-list-item-title>
                      <v-icon :color="risk.color" class="mr-2">
                        mdi-alert-circle
                      </v-icon>
                      {{ risk.code }}
                    </v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-menu>
            </div>
          </v-col>
        </v-row>
      </div>
      <div
        v-if="!overviewView"
        class="info-card-container"
        :style="{ opacity: $vuetify.theme.isDark ? 0.8 : 1 }"
      >
        <div class="all-risks-container" id="all-risks-container">
          <div
            class="card-container"
            v-for="risk in shownRisks"
            :key="risk.id"
            :ref="risk.id"
          >
            <v-card
              :class="{
                ['hover-' +
                getDarkColor(risk.probability * risk.impact).substr(1)]: true,
                selected:
                  risk.id == selectedRisk.id || risk.id == tempSelectedRisk.id,
                mainContainer: true
              }"
              :style="{
                borderColor: getDarkColor(risk.probability * risk.impact),
                background: risk.hover
                  ? getDarkColor(risk.probability * risk.impact)
                  : '',
              }"
              @click="editFromTable(risk)"
              @mouseover.native="tempSelectedRisk = risk"
              @mouseleave.native="tempSelectedRisk = {}"
            >
              <v-card-title>
                <v-avatar class="avatar" color="grey" :size="35">
                  <v-img
                    :src="risk.reporter.profilePicSrc"
                    @error="risk.reporter.profilePicSrc = profilePicFailover"
                  ></v-img>
                </v-avatar>
              </v-card-title>
              <v-card-text>
                <div
                  :style="{
                    color: getPropertyBySeverity(risk.probability, risk.impact, 'color'),
                  }"
                  class="reporter-name"
                >
                    {{ risk.code.replace("/-/", " ") }}
                </div>
                <div
                  class="description"
                  v-html="
                    JSON.parse(risk.description.replaceAll('\\n', '<br/>'))
                  "
                ></div>
                <div class="created-date">
                  {{ formatDate(risk.createdAt) }} by {{ risk.reporter.blueTag }}
                </div>
                <div
                  class="severity"
                  :style="{
                    backgroundColor: getPropertyBySeverity(risk.probability, risk.impact, 'backColor'),
                    color: getPropertyBySeverity(risk.probability, risk.impact, 'color') 
                  }"
                >
                      Severity: {{getSeverityName(risk.probability, risk.impact) || ""}}
                </div>
                <div class="icon-container">
                  <v-tooltip top>
                    <template v-slot:activator="{ on, attrs }">
                      <div
                        v-bind="attrs"
                        v-on="on"
                        style="cursor: pointer"
                        @click.stop="openCreateWorkItem(risk.id)"
                      >
                        <v-icon :style="{ fontSize: '26px' }"
                          >mdi-clipboard-check-outline</v-icon
                        >
                        <span class="workitems-quantity">
                          {{ workItemsDone(activeWorkItems(risk.workItems))}}/{{activeWorkItems(risk.workItems).length }}
                        </span>
                      </div>
                    </template>
                    <span>{{
                      "Work item" + (risk.workItems.length != 1 ? "s" : "")
                    }}</span>
                  </v-tooltip>
                </div>
              </v-card-text>
            </v-card>
          </div>
        </div>
      </div>
      <v-row class="mb-3 mx-n1" v-else>
        <risk-card
          :ref="risk.id" v-for="(risk, ix) in shownRisks.filter(risk => risk.internalViewOnly)"
          :key="ix"
          :risk="risk"
          :open="openCreateWorkItem"
          :edit="editFromTable"
          width="330"
          :selectedRisk="selectedRisk"
          :tempSelectedRisk="tempSelectedRisk"
          :createdRisk="createdRisk"
          :selectRiskAsTemporal="(risk) => tempSelectedRisk = risk"
          :unselectRiskAsTemporal="() => tempSelectedRisk = {}"
          :cols="6" :md="4"
        />
      </v-row>
    </div>
    <project-risk-create-dialog
      :overviewView="overviewView"
      ref="projectRiskCreateDialog"
      :projectId="id"
      :risks="risks"
      :canEditProject="canEditProject"
      :isEmployee="isEmployee"
    ></project-risk-create-dialog>
    <risk-work-item-dialog
      ref="riskWorkItemDialog"
      :projectId="id"
      :risks="risks"
      :canEditProject="canEditProject"
    ></risk-work-item-dialog>
  </div>
</template>

<script>
import ProjectRiskCreateDialog from "../../dialogs/ProjectRiskCreateDialog";
import RiskWorkItemDialog from "../../dialogs/RiskWorkItemDialog";
import RiskCard from "../../components/RiskCard";
import moment from "moment";

export default {
  props: {
    id: { type: String, default: null },
    canEditProject: { type: Boolean, default: false },
    overviewView: { type: Boolean, default: false },
    startDate: { type: String, default: '' },
    endDate: { type: String, default: '' },
  },
  components: {
    ProjectRiskCreateDialog,
    RiskWorkItemDialog,
    RiskCard,
  },
  data() {
    return {
      probabilityRows: 5,
      impactCols: 5,
      severityByColor: {
        "#f5b5b5": [20, 25],
        "#ffd28e": [12, 15, 16],
        "#fff1c4": [5, 6, 8, 9, 10],
        "#b5f1b1": [1, 2, 3, 4],
      },
      severityByDarkColor: {
        "#ff6968": [20, 25],
        "#efab45": [12, 15, 16],
        "#e9cc75": [5, 6, 8, 9, 10],
        "#72bb6d": [1, 2, 3, 4],
      },
      risks: [],
      positions: [
        { left: "4px", top: "4px" },
        { left: "16px", top: "4px" },
        { left: "4px", top: "8px" },
        { left: "16px", top: "8px" },
      ],
      selectedRisk: {},
      createdRisk: {},
      tempSelectedRisk: {},
      profilePicFailover:
        "https://cdn.vuetifyjs.com/images/parallax/material2.jpg",
      statuses: ["Active", "Eliminated", "Materialized"],
      headers: [
        { text: "Code", value: "code" },
        { text: "Description", value: "description" },
        { text: "Probability", value: "probability" },
        { text: "Impact", value: "impact" },
        { text: "Severity", value: "severity" },
        { text: "Reported By", value: "reportedBy" },
        { text: "Reported At", value: "createdAt" },
      ],
      headersWorkItems: [
        { text: "Risk", value: "riskCode" },
        { text: "Description", value: "name" },
        { text: "Status", value: "statusName" },
        { text: "Assigned to", value: "assignedToBlueTag" },
        { text: "Due Date", value: "dueDate" },
      ],
      workItems: [],
      risksBySeverity: {},
      users: [],
      filters: {
        status: [],
        reportedBy: [],
        projects: [],
        id: null,
        severity: [],
      },
      riskTypesToShow: [0, 1, 2],
      projects: [],
      searchTimeout: null,
      temporalSearch: '',
      search: '',
      isEmployee: false,
      showFilters: false,
      riskTypes: [
        { name: "Active Risks", value: 0 },
        { name: "Closed", value: 2 },
        { name: "Materialized", value: 1 },
      ],
      severityNameByNumber: {
				"Critical": [20, 25],
				"High": [12, 15, 16],
				"Medium": [5, 6, 8, 9, 10],
				"Low": [1, 2, 3, 4],
			},
      severityValue: [
				{ name: "Critical", value: [20, 25], color: "#ff6968", backColor: "#f5b5b560", active: false },
				{ name: "High", value: [12, 15, 16], color: "#efab45", backColor: "#ffd28e60", active: false },
				{ name: "Medium", value: [5, 6, 8, 9, 10], color: "#e9cc75", backColor: "#fff1c460", active: false },
				{ name: "Low", value: [1, 2, 3, 4], color: "#72bb6d", backColor: "#b5f1b160", active: false },
			],
    };
  },
  computed: {
    shownRisks() {
      let activeStatus = 0
      let risks = this.risks
      if(this.overviewView) {
        let startDate = JSON.stringify(this.startDate)
        let endDate = JSON.stringify(this.endDate)
        risks = risks.filter(risk => moment(risk.createdAt).isBetween(startDate, endDate,'day', '[]') || risk.status === activeStatus)
        return risks
      }
      if (this.filters.status.length) {
        risks =  risks.filter(risk => this.filters.status.includes(risk.status))
      }
      if (this.filters.reportedBy.length) {
        risks =  risks.filter(risk => this.filters.reportedBy.indexOf(risk.reporterId || null) != -1)
      }
      if (this.filters.projects.length) {
        risks =  risks.filter(risk => this.filters.projects.indexOf(risk.projectId || null) != -1)
      }
      if(this.filters.severity.length) {
        risks = risks.filter(risk => _.concat(...this.filters.severity).indexOf(risk.probability * risk.impact) != -1)
      }
      if(this.filters.id) {
        risks = risks.filter(risk => risk.code.toLowerCase().includes(this.filters.id.toLowerCase()))
      }
      risks = this.applySearch(this.filters.search, risks, ['code', 'description'])
      return risks
    },
    appliedFilters() {
			return this.countActiveFilters(this.filters, ['search', 'severity'])
		},
  },
  watch: {
    shownRisks: {
      handler(val) {
        let risksBySeverity = {}
        for (let risk of val.filter((r) => this.riskTypesToShow.includes(r.status))) {
          let severity = risk.impact + "-" + risk.probability;
          if (!risksBySeverity[severity]) {
            risksBySeverity[severity] = [];
          }
          risksBySeverity[severity].push(risk);
        }
        this.risksBySeverity = risksBySeverity
      },
    },
    temporalSearch() {
      clearTimeout(this.searchTimeout)
      this.searchTimeout = setTimeout(() => {
        if(!this.temporalSearch) this.temporalSearch = ''
        this.search = this.temporalSearch
      }, 500)
    },
  },
  mounted() {
    this.init();
    if (this.id) {
      this.$q.log(1, 6, 3, this.id, this.$parent.item.id)
      this.headers.push({ text: "", value: "actions", sortable: false });
      this.headersWorkItems.push({
        text: "",
        value: "actions",
        sortable: false,
      });
    } else {
      this.$q.log(2, 6, 3, 'NA')
      document.title = "Risks";
    }
  },
  methods: {
    filterBySeverity(i) {
      if(!this.severityValue[i].active) {
        this.severityValue[i].active = true
        this.filters.severity.push(this.severityValue[i].value)
        return 
      } 
      this.filters.severity.splice(this.filters.severity.indexOf(this.severityValue[i].value) || null, 1)
      this.severityValue[i].active = false
    },
    getPropertyBySeverity(probability, impact, property) {
				let value = ''
				this.severityValue.forEach(severity => {
					if(severity.value.includes(probability * impact)) {
						value = severity[property]
						return
					}
				})
				return value;
			},
    getNumberOfRisksBySeverity(severities) {
      let risks = this.risks.filter((r) => this.riskTypesToShow.includes(r.status))
      let numberOfRisks = risks.filter((r) => severities.indexOf(r.impact * r.probability) !== -1)
      return numberOfRisks.length
    },
    getSeverityName(probability, impact) {
			for (let name in this.severityNameByNumber) {
				if (this.severityNameByNumber[name].includes(probability * impact)) return name;
			}
			return "";
		},
    activeWorkItems(workItems) {
      if(workItems.length) {
        let activeWorkItems = workItems.filter(wi => {
          if(!wi.deleted && !wi.status.deleted) {
            return wi
          }
        })
        return activeWorkItems
      }
      return []
    },
    workItemsDone(workItems) {
      if(workItems.length) {
        return workItems.filter(wi => wi.status.isFinal).length
      }
      return 0
    },
    scrollTo(id) {
      if (!id) return;
      let container = this.$el.querySelector("#all-risks-container");
      let element = this.$refs[id][0];
      container.scrollTop = element.offsetTop - 400;
    },
    getDarkColor(severity) {
      for (let color in this.severityByDarkColor) {
        if (this.severityByDarkColor[color].includes(severity)) {
          return color;
        }
      }
      return "white";
    },
    async init(selectedId, created) {
      const user = await this.$blueSystem.getCurrentUser()
      if(!user.type) {
        this.isEmployee = true
      }
      this.currentUser = user
      this.selectedRisk = {};
      this.$http.get(`api/Project/Risks?id=${this.id}`).then((res) => {
        this.risks = res.data.sort((a, b) => {
          let difSeverity = b.impact * b.probability - a.impact * a.probability;
          if (!difSeverity) {
            return new Date(a.createdAt) - new Date(b.createdAt);
          }
          return difSeverity;
        });
        this.$forceUpdate();

        this.workItems = [];
        for (let risk of this.risks) {
          for (let wi of risk.workItems) {
            wi.assignedToBlueTag = wi.assignedTo
              ? wi.assignedTo.blueTag
              : "Unassinged";
            wi.statusName = wi.status.name;
            wi.dueDate = wi.dueDate
              ? moment(wi.dueDate).format("MMM DD, YYYY")
              : "";
            wi.riskCode = risk.code;
            this.workItems.push(wi);
          }
        }
        let users = {}
        this.risks.forEach(risk => {
          if(!users[risk.reporterId]) {
            users[risk.reporterId] = risk.reporter
          }
        })
        this.users = _.toArray(users)
        if (selectedId) {
          let risk = this.risks.filter(
            (r) => r.id == selectedId && this.riskTypesToShow.includes(r.status)
          );
          if(risk.length) {
            if(created && this.overviewView)
              this.flickerRisk(risk[0])
            else if(!created && !this.overviewView)
              this.selectedRisk = risk[0];
          }
        }
        
        this.risks.forEach((r) => this.$q.profilePicSrc(r.reporter));
      });
      if(!selectedId) {
        this.$http
          .get("/api/Project")
          .then(res => {
            this.projects = res.data
              .filter(x => x.status == 1)
          })
          .catch(res => {
            window.getApp.error("Cannot get projects.")
            console.error(res)
          })
      }
    },
    flickerRisk(risk) {
      let counter = 0
      const createInterval = () => new Promise((resolve) => {
        const interval = setInterval(() => {
          this.createdRisk = risk
          setTimeout(() => {
              this.createdRisk = {}
              counter++
              if(counter == 4) return resolve(interval)
            }, 250)
        }, 400)
      })
      createInterval().then((interval) => clearInterval(interval))
    },
    getBackground(row, col) {
      for (let color in this.severityByColor) {
        if (this.severityByColor[color].includes((6 - row) * col)) return color;
      }
      return "white";
    },
    editFromTable(risk = {}) {
      this.selectedRisk = risk;
      this.$refs.projectRiskCreateDialog.open(risk.id)
        .then(({id, risk}) => this.init(id, risk))
    },
    openCreateWorkItem(id) {
      if (!this.canEditProject || !this.id) return;
        this.$refs.riskWorkItemDialog.open(null, id)
          .then(() => this.init())
    },
    formatDate(date) {
      return `${moment(date).format("MMM D")} at ${moment(date).format("HH:mm")}`
    },
    setMaxText(text, max){
			if(!text)
				return ''
      if(text.length > max)
        text = text.substr(0, max) + '...'
      return text
    },
  },
};
</script>

<style scoped lang="scss">
.max-container {
  display: flex;
}
.graph-container {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.sidebar {
  display: inline-block;
  transform: rotate(0.75turn);
  text-align: center;
  height: 170px;
  margin-right: -140px;
  text-transform: uppercase;
  font-size: 30px;
  font-weight: 600;
}
.matrix-container {
  display: inline-block;
  width: auto;
  padding-top: 15px;
}
.probability-row {
  width: 525px;
  height: 105px;
  margin: 0;
  border-right: 1px solid var(--v-background-base);
  border-left: 1px solid var(--v-background-base);
}
.impact-col {
  border-radius: 10px;
  border: 6px solid var(--v-background-base);
  padding: 5px;
  div {
    margin-top: 2.5px;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    align-content: center;
  }
  display: flex;
  justify-content: center;
  align-items: flex-start;
  padding: 2px
}
.risk {
  margin-bottom: 2px;
  display: inline-block;
  position: relative;
  width: 79px;
  height: 24px;
  border-radius: 5px;
  line-height: 19px;
  background: white;
  text-align: center;
  cursor: pointer;
  overflow: hidden;
  box-shadow: 2px 2px 7px #aaa;
  .v-icon {
    filter: brightness(0.9);
    font-size: 30px;
  }
}
.risk-center {
  width: 43px;
  height: 43px;
  border-radius: 10px;
  margin: 0;
  top: 17px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #d2b4de;
  .number {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 33px;
    height: 33px;
    border-radius: 50%;
    color: white;
    font-size: 22px;
    padding-top: 2px;
    font-weight: 700;
  }
}

@media only screen and (min-width: 1400px) {
  .card-container {
    grid-template-columns: repeat(2, auto) !important; 
    width: 49%;
  }
}
@media only screen and (min-width: 2400px) {
  .card-container {
    min-width: 450px ! important;
  }
  .probability-row {
    width: 700px;
    height: 145px;
  }
}
.card-container {
  padding-top: 0;
  flex: 1 1 1;
  min-width: 330px;
  width: 32%;
  .v-card {
    cursor: pointer;
    width: 100%;
    border: 1px solid #aaa;
    height: 100%;
    background: var(--v-background-lighten);

    .reporter-name {
      margin-left: 10px;
      align-self: flex-start;
      font-size: 0.8em;

      .fullname {
        display: block;
        margin-bottom: -18px;
      }
    }
    .icon-container {
      display: flex;
      align-items: center;
      position: absolute;
      right: 10px;
      bottom: 10px;

      .edit-icon {
        color: #1a73e8;
        cursor: pointer;
      }
      .workitems-quantity {
        color: var(--v-text-lighten2);
        font-size: 1.2em;
        font-weight: bold;
      }
    }
    .status {
      font-size: 1em;
    }
    .description {
      font-size: 1.2em;
      color: var(--v-text-lighten2);
      margin-bottom: 10px;
      margin-left: 5px;
      word-break: break-word;
    }
    .created-date {
      font-size: 1.0em;
      color: var(--v-text-lighten2);
      margin-bottom: 19px;
      margin-left: 4px;
    }
    .severity {
      position: absolute;
      bottom: 0;
      margin: 0 0 11px 6px;
      font-size: 1em;
      font-weight: bold;
      padding: 2px 7px;
      border-radius: 5px;
    }
  }

  .v-card:hover,
  .v-card.selected {
    
    color: white;
    span {
      color: white;
    }
    .description {
      color: white;
    }
    h3 {
      color: white;
    }
    .v-icon {
      color: white;
    }
    .workitems-quantity {
      color: white;
    }
    .reporter-name, .severity {
      color: white !important;
    }
  }

  .v-card.hover-72bb6d:hover,
  .v-card.hover-72bb6d.selected {
    background: #72bb6d;
  }
  .v-card.hover-e9cc75:hover,
  .v-card.hover-e9cc75.selected {
    background: #e9cc75;
  }
  .v-card.hover-ff6968:hover,
  .v-card.hover-ff6968.selected {
    background: #ff6968;
  }
  .v-card.hover-efab45:hover,
  .v-card.hover-efab45.selected {
    background: #efab45;
  }
}
.info-card-container {
  padding-top: 10px;
  padding-left: 44px;
  .all-risks-container {
    max-height: 640px;
    overflow-y: auto;
    overflow-x: hidden;
    display: flex;
    flex-wrap: wrap;
    gap: 10px 20px;
  }
}

.risk-table {
  margin-top: 20px;
}
.table-action {
  cursor: pointer;
  margin: 0 5px;
}
.table-title-icon {
  margin-top: -5px;
  margin-right: 5px;
  font-size: 30px;
}

.v-list::-webkit-scrollbar,
.all-risks-container::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}
.v-list::-webkit-scrollbar-thumb,
.all-risks-container::-webkit-scrollbar-thumb {
  background: var(--v-scroll-lighten2)
}
.filter-container {
  padding: 0px 20px;
  display: flex;
  gap: 10px;
  margin-top: 10px;
}
.expansion-container {
  padding: 0 40px
}
.container-1 {
  display: flex;
  width: 100px;
  height: 80px;
}
.item-1 {
  width: 90px;
  height: 24px;
  background-color: red;
  border: 2px solid white
}
.mainContainer {
  display: flex;
  padding: 0 0 10px 0;
  .v-card__title {
    padding: 16px 2px 0 16px;
    align-self: flex-start;
  }
  .v-card__text {
    margin: 18px 0 0 0;
    padding-left: 7px;
     .reporter-name {
      margin-left: 0;
      align-self: flex-start;
      color: var(--v-text-lighten2);
      font-size: 1.1em;
      font-weight: bold;
    }
  }
}
.severity-container {
  padding: 0 40px;
}
.severity-title {
  display: flex;
}
.risk-name {
  font-size: .7em;
  font-weight: 400;
}
.severity-icon {
  margin: 0 10px;
  width: 40px;
  height: 40px;
  display: flex;
  box-sizing: border-box;
  justify-content: center;
  border-radius: 20px;
}
.filter-button {
  color: var(--v-primary-base);
  margin-right: 20px;
}
.severity-container {
  display: flex;
}
.severity-card {
  border: 1px solid rgb(0 0 0 / 14%);
  cursor: pointer;
}
.slider{
  display: inline-block;
  width: auto !important;
  border-radius: 6px;
  padding-left: 2px;
  padding-right: 2px;
}

.colored-back {
  background-color: rgba(22, 123, 215, 0.08) !important;
}
.slide-item-active {
  color: var(--v-primary-base)
}
.slide-item {
  color: rgba(22, 123, 215, 0.784);
}
.btn-slide {
  font-weight: 600;
}
.test2 {
  max-width: none;
}
</style>