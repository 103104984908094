<template>
  <v-container class="px-1" v-if="!showCreateTask">
    <div style="display: flex; justify-content: space-between" class="mb-2">
      <h3>Assigned Work Items</h3>
      <v-icon @click="$emit('close')">mdi-close</v-icon>
    </div>
    <div
      class="col-login mx-0"
      >
      <span>Project</span>
      <v-autocomplete
        class="mt-2 mb-0"
        outlined
        dense
        hide-details
        v-model="projectId"
        ref="project" 
        :persistent-hint="true"
        :items="projects" item-value="id" item-text="name"
        :rules="[v => (!!v || v === 0) || 'Required']"
      >
        <template v-slot:item="{ item }">
          <v-list-item-content>
            <v-list-item-title>
              <span>{{item.name}}</span>
            </v-list-item-title>
            <v-list-item-subtitle v-text="item.customerProduct"></v-list-item-subtitle>
          </v-list-item-content>
        </template>
      </v-autocomplete>
    </div>
    <div>
      <h4 @click="showCreateTask = !showCreateTask" class="cursor-pointer py-4">
        <v-icon color="primary">add</v-icon>
        Add a Task
      </h4>
    </div>
    <v-container class="px-0 py-1" :style="{background: 'var(--v-newDesignBackground-base)'}">
      <work-item
        v-for="(workItem, ix) in shownWorkItems"
        :key="ix" 
        :tags="workItemTags(workItem)"
        :sprints="config.sprints"
        :usingWorkingHours="true"
        :leadTimeGoal="config.leadTimeGoal"
        :workItem="workItem"
        :status="{ isFinal: false }"
        @click="openWorkItemDetailsDialog(workItem.id)"
      />
    </v-container>
    <work-item-details-dialog ref="workItemDetailsDialog" :taskShow="true" :isPersonal="false"></work-item-details-dialog>
  </v-container>
  <aside-bar-create-task
    v-else
    @close="$emit('close')"
    @toggleView="showCreateTask = !showCreateTask"
    :id="projectId"
    :types="types"
    :priorities="priorities"
    :project="selectedProject"
  />
</template>

<script>
import WorkItem from './WorkItem';
import workItemDetailsDialog from '../dialogs/WorkItemDetailsDialog.vue'
import AsideBarCreateTask from './AsideBarCreateTask';
export default {
  props: ['close'],
  components: {
    WorkItem,
    workItemDetailsDialog,
    AsideBarCreateTask,
  },
  data: () => ({
    projectId: null,
    canEditProject: false,
    projects: [],
    config: {},
    workItems: [],
    currentUser: {},
    showCreateTask: false,
    selectedStatuses: [0, 1],
  }),
  watch: {
    'projectId'(newId, oldId) {
      this.getProject(newId)
    },
  },
  computed: {
    shownWorkItems() {
      if(!this.currentUser.id)
        return this.workItems
      return this.workItems.filter(wi => wi.assignedToId === this.currentUser.id)
    },
    selectedProject() {
      if(!this.projectId)
        return {}
      return this.projects.find(p => p.id === this.projectId)
    },
  },
  mounted() {
    this.init();
  },
  methods: {
    workItemTags(workItem) {
      if (!workItem.tags) return []
      return _.orderBy(workItem.tags, ['tagSortIndex'])
    },
    openWorkItemDetailsDialog(id) {
      let defaults = {
        projectId: this.id,
        openCreateDialog: () => {}
      }

      this.$refs.workItemDetailsDialog.open(id, defaults, this.socket)
        .finally(result => {
          this.workItemUpdated(id)
        })
    },
    getProject(id) {
      this.$http
        .get(`api/Project/${id}/currentPermissions`) 
        .then(res => {
          this.canEditProject = res.data.canEdit == 3;
        }).catch( res => {
          window.getApp.error("Cannot get permissions.");
          console.log(res);
        })
      this.$http.get(`/api/workItem?projectId=${id}`)
        .then(res => {
          this.workItems = res.data
          this.workItems.forEach(this.setDarkenWorkItem);
        })
        .catch(res => {
          window.getApp.error("Cannot obtain work items.")
          console.log(res)
        })
      this.$http
        .get(`/api/project/${id}/config?active=true`)
        .then(res => {
          this.config = res.data
        })
        .catch(res => {
          window.getApp.error("Cannot get project sprints, tags and status.")
          console.log(res)
        })
    },
    workItemUpdated(workItemId){
      this.$http.get(`api/workItem/${workItemId}`, {headers: {hideLoading: true}})
        .then(res => {
          let newWorkItem = res.data
          if(!newWorkItem){
            this.workItems = this.workItems.filter(wi => wi.id != workItemId && wi.code != workItemId)
            return
          }
          this.setDarkenWorkItem(newWorkItem);
          let oldWorkItem = this.workItems.find(wi => wi.id == newWorkItem.id)
          oldWorkItem = Object.assign(oldWorkItem, newWorkItem)
          if(!oldWorkItem)
            this.workItems.push(newWorkItem);
        })
    },
    init() {
      this.$blueSystem.getCurrentUser()
        .then(user => {
          this.currentUser = user
        })
      this.$http
        .get("/api/user/current/project")
        .then(res => {
          this.projects = res.data;
          this.projectId = res.data[0].id
        })
        .catch(res => {
          window.getApp.error("Cannot get records.");
          console.log(res);
        });
      this.$http.get(`/api/Enums/WorkItemPriority`)
        .then(res => {
          this.priorities = res.data
        })
        .catch(res => {
          window.getApp.error("Cannot obtain priorities.")
          console.error(res)
        })
      this.$http.get(`/api/Enums/WorkItemType`)
          .then(res => {
            this.types = res.data
          })
          .catch(res => {
            window.getApp.error("Cannot obtain types.")
            console.log(res)
          })
    },
    setDarkenWorkItem(workItem) {
      let color = workItem.boardColor
      if (!color) {
        this.$set(workItem, 'dark', false)
        return
      }

      const hex   = color.replace(/#/, '')
      const r     = parseInt(hex.substr(0, 2), 16)
      const g     = parseInt(hex.substr(2, 2), 16)
      const b     = parseInt(hex.substr(4, 2), 16)

      let luma = [
          0.299 * r,
          0.587 * g,
          0.114 * b
      ].reduce((a, b) => a + b) / 255

      this.$set(workItem, 'dark', (luma < 0.65))
    },
  }
}
</script>

<style lang="scss" scoped>
  .col-login {
    padding-bottom: 0px !important;
    max-height: 120px !important;

    span {
      font-weight: 400;
      color:darkgrey;
      margin-bottom: 8px;
    }
  }
</style>
