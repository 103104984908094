<template>
  <v-card
    class="scalling-binnacles-card px-2 pt-3 pb-2 rounded-lg"
    v-bind="$attrs"
    @click="$emit('click', item)"
    :style="{
      'min-width': width + 'px' || '',
      'min-height': '160px !important',
    }"
  >
    <v-card-text class="py-0 pt-1 d-flex justify-space-around" style="height: 100%; flex-direction: column;">
      <div>
        <b class="text--text">{{ item.reportedByBlueTag }}</b>
        <span class="ml-1" style="font-weight: 300; font-size: 16px;">
          {{ getRelativeTime(item.createdAt) }}
        </span>
      </div>
      <div class="button">
        Relates to: {{ item.reportedToFullName || '-' }}
      </div>
      <div class="body-1">
        {{ item.name }}
      </div>
      <v-card-actions class="pa-0">
        <v-menu offset-y v-show="disabled">
          <template
            v-slot:activator="{ on }"
          >
            <v-btn 
              class="" v-on="on" depressed small
              :color="getColor(item.statusColor)"
              :style="{textTransform: 'none'}"
            >
              <span
                :style="{ color: item.statusColor || 'white' }"
              >
                Status: {{ item.statusName }}
              </span>
              <v-icon :color="item.statusColor" right>keyboard_arrow_down</v-icon>
            </v-btn>
          </template>
          <v-list dense>
            <v-list-item 
              v-for="(stat, ix1) in statuses.filter(s => s.id != item.statusId)"
              :key="ix1"
              :color="getColor(stat.color)"
              @click="saveStatus(stat, item.id)"
              >
              <v-list-item-title 
                :style="{ color: stat.color }" :color="stat.color"
              > 
                {{ stat.name }}
              </v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
      </v-card-actions>
    </v-card-text>
  </v-card>
</template>

<script>
import moment from 'moment';
import _ from 'lodash';
export default {
  props: {
    statuses: { type: Array, default: () => ([]) },
    item: { type: Object, default: () => ({}) },
    disabled: { type: Boolean, default: false },
    width: { type: String, default: "" }
  },
  data: () => ({

  }),
  methods: {
    getRelativeTime(date) {
      return moment(date).fromNow()
    },
    getColor(color) {
      return _.dropRight(color, 2).join("") + '1A'
    },
    saveStatus(status, id, index) {
      let property = "statusId"
      let value = status.id
      
      if(status.confetti){
        this.$confetti.start()
        setTimeout(() => { this.$confetti.stop() }, 2000)
      }

      this.$q.save({
        api: `/api/WorkItem/${id}/byProperty/${property}`,
        data: {
          value: value
        },
        successMsg: 'Status changed successfully',
        afterSuccessUrl: null,
        errorMsg: "Cannot save changes.",
        afterErrorUrl: null,
      }).then((res) => {
        this.$emit('statusChange')
      })
    },
  }
}
</script>

<style lang="scss" scoped>
</style>