export default function taskListManager() {

  var search = "";
  var userId = null;
  var status = ['Not Started' , 'In Progress', 'Done'];
  var project = null;
  let identifyParentTasks = true;

  function collapseAll() {
    var collapsed = !project.allTasksCollapsed;
    project.allTasksCollapsed = collapsed;
    _.each(project.tasks, function (task) {
        task.collapsed = collapsed;
    });
    hideUnhideTasks();
  }

  function collapseTask(task) {
    task.collapsed = !task.collapsed;
    hideUnhideTasks();
  }

  function hideUnhideTasks() {

    if (!project || !project.tasks) return;

    var sortedTasks = _.sortBy(project.tasks, function (t) { return t.sortIndex; });
    var reverseSortedTasks = _.sortBy(project.tasks, function (t) { return 0 - t.sortIndex; });

    // Filter out based on user filter
    var lastChildLevel = null
    _.each(reverseSortedTasks, function (t) {
        if (!userId) {
            t.filteredOutUser = false;
            return;
        }

        t.filteredOutUser = false;
        if (t.responsibleId === userId) {
            lastChildLevel = t.level;
        } else if (lastChildLevel && lastChildLevel > t.level) {
            lastChildLevel = t.level;
        } else {
            t.filteredOutUser = true;
        }
    });

    // Filter out based on status filter
    var lastChildLevel = null
    _.each(reverseSortedTasks, function (t) {
        if (status.length == 3 || status.length == 0) {
            t.filteredOutStatus = false;
            return;
        }

        t.filteredOutStatus = false;
        if (status.indexOf(t.statusName) != -1) {
            lastChildLevel = t.level;
        } else if (lastChildLevel && lastChildLevel > t.level) {
            lastChildLevel = t.level;
        } else {
            t.filteredOutStatus = true;
        }
    });

    // Filter out based on search filter
    let searchText = function(text, queryText) {
        
        if (!text) return true
        let allWords = text

        // text as string or array
        if (Array.isArray(text)) {
            allWords = allWords.join(' ')
        } else if (typeof text !== 'string') {
            return false
        }

        // Create array of words
        allWords = allWords
            .normalize('NFD').replace(/[\u0300-\u036f]/g, "") // remove accents
            .replace(/[.,\/#!$%\^&\*;:{}=\-_`~()]/g,' ') // remove punctuation
            .split(' ')
            .filter(t => !!t)
        
        let allQueryWords = queryText
            .normalize('NFD').replace(/[\u0300-\u036f]/g, "") // remove accents
            .replace(/[.,\/#!$%\^&\*;:{}=\-_`~()]/g,' ') // remove punctuation
            .split(' ')
            
        return allQueryWords.every(qw => allWords.some(cw => cw.toLocaleLowerCase().indexOf(qw.toLocaleLowerCase())> -1))
    };
    var lastChildLevel = null
    _.each(reverseSortedTasks, function (t) {
        if (!search) {
            t.filteredOutSearch = false;
            return;
        }

        t.filteredOutSearch = false;
        if (searchText([t.name, t.responsibleFullName, t.statusName], search)) {
            lastChildLevel = t.level;
        } else if (lastChildLevel && lastChildLevel > t.level) {
            lastChildLevel = t.level;
        } else {
            t.filteredOutSearch = true;
        }
    });

    _.each(sortedTasks, function (t) {
        t.filteredOut = t.filteredOutUser || t.filteredOutStatus || t.filteredOutSearch;
    });

    // Hide/unhide based on collapse status
    var collapsedParents = [];
    var pastLevel = 0;
    _.each(sortedTasks, function (t) {
        var number = [];
        // reset to 0s
        for (var i = pastLevel + 1; i <= t.level; i++) {
            collapsedParents[i] = false;
        }
        // add current level
        collapsedParents[t.level] = t.collapsed;
        // construct and join number array. i.e. [2, 1, 3]
        for (var i = 1; i < t.level; i++) {
            number[i - 1] = collapsedParents[i] || false;
        }
        // add collapsed statuses
        t.hidden = _.some(number, function (n) { return n; });
        //t.number = number.join(".");
        pastLevel = t.level;
    });

  }

  function updateTasks () {

    var sortedTasks = _.sortBy(project.tasks, function (t) { return t.sortIndex; });

    // Set task number
    var currentLevel = [];
    var pastLevel = 0;
    _.each(sortedTasks, function (t) {
        var number = [];
        // reset to 0s
        for (var i = pastLevel + 1; i <= t.level; i++) {
            currentLevel[i] = 0;
        }
        // add current level
        currentLevel[t.level] = (currentLevel[t.level] || 0) + 1;
        // construct and join number array. i.e. [2, 1, 3]
        for (var i = 1; i <= t.level; i++) {
            number[i - 1] = currentLevel[i] || 0;
        }
        // join number '2.1.3'
        t.number = number.join(".");
        pastLevel = t.level;
    });

    // Identify parent tasks
    if (identifyParentTasks) {
        var pastTask = null;
        _.each(sortedTasks, function (t) {
            t.isParent = false;
            if (pastTask && t.level > pastTask.level) {
                pastTask.isParent = true;
            }
            pastTask = t;
        });
    }

    // Add effort
    var totalEstimatedEffort = 0;
    _.each(sortedTasks, function (t) {
        totalEstimatedEffort += (t.estimatedEffort || 0);
    });
    project.totalEstimatedEffort = totalEstimatedEffort;

    hideUnhideTasks();
  };

  return {
    collapseTask,
    collapseAll,
    updateTasks: updateTasks,
    init: function (_project, _identifyParentTasks) {
      project = _project
      if (_identifyParentTasks === false) {
          identifyParentTasks = false
      }
      updateTasks()
    },
    setUserId: function(_userId) {
      userId = _userId
      hideUnhideTasks()
    },
    setSearch: function(_search) {
        if (_search && project.allTasksCollapsed) {
            collapseAll()
        }
      search = _search
      hideUnhideTasks()
    },
    setStatus: function(_status) {
      status = _status
      hideUnhideTasks()
    }
  }
}
