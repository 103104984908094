

const AdminSurf = {
  install (Vue, options) {
		Vue.mixin({
      created() {
        const that = this
				const controller = this.$root.$refs.adminSurfController
				this.$adminSurf = (action, ...arg) => {
					return controller[action].apply(null, arg)
				}
			}
		})
	}
}

export default AdminSurf