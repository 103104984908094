<template>
	<div>
		<div
			class="bar text-left" :class="{ stack }"
			:style="{ border: overflowError && primaryAmount > innerObjective ? '2px solid red' : '' }"
		>
			<div
				v-for="(item, index) in items"
				:key="index"
				:style="{
					width: getPercentage(item) + '%',
					background: item.color || colors[index % items.length]
				}"
			>
			</div>
		</div>
		<div v-if="items.length" class="d-flex align-center justify-space-between mt-1">
			<div>
				<b>
					<span :style="{ color: items[0].textColor }">{{ amountFormatter(items[0].amount) }}</span>
					<span
						v-for="(item, index) in items.slice(1).filter(x => !x.skipForTotal)"
						:key="index"
						:style="{ color: item.textColor || item.color || colors[index % items.length] }"
					>
						(+{{ amountFormatter(item.amount) }})
					</span>
				</b> /
				<span v-if="infinity" style="font-size: 1.1rem;">∞</span>
				<span v-else-if="objective" :style="{ fontWeight: boldObjective ? 600 : 400 }">{{ amountFormatter(objective) }}</span>
				<span v-else>-</span>
			</div>
			<div v-if="infinity" class="float-right">-</div>
			<div
				v-else
				class="float-right"
				:style="{
					color: totalPercentage === 100 ?
						'var(--v-success-base)' : 'var(--v-warning-darken)'
				}"
			>
				{{ totalPercentage | limitDecimals(2) }}%
			</div>
		</div>
	</div>
</template>

<script>
export default {
	props: {
		/*
		 * Array of items to be displayed in the bar
		 * @type {Array}
		 * @default []
		 * @of {Object}
		 * @property {Number}  amount - The amount to be displayed
		 * @property {String}  color - The color of the bar
		 * @property {Boolean} skipForTotal - If the amount should be summed in the total
		 */
		items: {
			type: Array,
			default: () => [],
			of: Object,
		},
		amountFormatter: {
			type: Function,
			default: (v) => {
				return new Intl.NumberFormat().format(v)
			}
		},
		stack: { type: Boolean, default: false },
		objective: { type: Number, default: 0 },
		overflowError: { type: Boolean, default: false },
		infinity: { type: Boolean, default: false },
		boldObjective: { type: Boolean, default: false },
	},
	data: () => ({
		colors: [
			'var(--v-success-base)',
			'var(--v-error-base)',
			'var(--v-primary-base)',
			'var(--v-secondary-base)',
			'var(--v-warning-darken)',
			'var(--v-info-base)',
		],
	}),
	computed: {
		innerObjective() {
			return this.objective ||
				this.items.reduce((acc, item) => acc + (item.skipForTotal ? 0 : item.amount), 0)
		},
		totalPercentage() {
			if(!this.objective)
				return 0
			let amount = this.items.reduce((acc, item) => acc + (item.skipForTotal ? 0 : item.amount), 0)
			return amount / this.innerObjective * 100
		},
	},
	methods: {
		getPercentage(item){
			return this.innerObjective ? item.amount / this.innerObjective * 100 : 0
		}
	},
}
</script>

<style lang="scss" scoped>
.bar{
	width: 100%;
	height: 10px;
	background: lightgray;
	border-radius: 5px;
	overflow: hidden;
	position: relative;

	div{
		position: absolute;
		left: 0;
		height: 10px;
		display: inline-block;
		border-radius: 0 5px 5px 0;
	}
	
	&.stack{
		div {
			position: relative;
			left: unset;
			border-radius: 0;
			height: 20px;
		}
		div:nth-child(2) {
			border-left: 1px solid white;
		}
	}
}
</style>