
<template>
  <div>
    <div v-if="reportDialog" class="hide-back"></div>
    <div id="dashboard" class="pl-1">
      <div style="position: relative; overflow: hidden; min-height: 150px;" v-show="reportDialog" ref="header">
        <h1>Status Report</h1>
        <h2 class="ma-0">{{generalDetails.smallCode}} - {{generalDetails.name}}</h2>
        <h4>{{getDate()}}</h4><br>

        <img class="logo" width="250" src="../../assets/bluePeople.png"/>
      </div>
      <GeneralDetails :id="id" ref="generalDetails" v-show="reportDialog"/>
      <TeamMembers :id="id" ref="members" v-show="reportDialog"/>

      <!-- Lead Time & Highest Cycle Time -->
      <v-row v-show="!reportDialog || selectedReport.includes('dashboard')" ref="leadHighestCont" class="mt-10 mb-5">
        <v-col cols="12" sm="7" md="6" lg="6">
          <h4 class="mb-5" v-show="reportDialog">Dashboard</h4>
          <div class="pl-3 pr-3 elevation-3 rounded background--lighten">
            <v-row style="border: 1px solid var(--v-background-lighten2); padding: 10px">
              <v-col>
                <h2 class="card-title">Lead Time</h2>
                <div
                  class="leadtime rounded" 
                  :style="{
                    border: '2px solid ' + getColorByPercentage(leadTimePercentage),
                    gridTemplateColumns: (leadTimeGoal.leadTimeGoal ? '73% 27%' : '100%')
                  }"
                >
                  <div class="leadtime-section" :style="{background: getColorByPercentage(leadTimePercentage), color: getTextColorByPercentage(leadTimePercentage)}">
                    {{
                      leadTimeGoal.leadTimeGoal ?
                        formatTimeByUnit(leadTime.value, leadTimeGoal.leadTimeGoalUnit, project.usingWorkingHours) :
                        getIndicatorTime(leadTime.value, false, project.usingWorkingHours)
                    }}
                    <div v-if="leadTimeGoal.leadTimeGoal" style="text-align: center;">
                      vs <br>
                      {{ formatTimeByUnit(toMinutes(leadTimeGoal.leadTimeGoal, leadTimeGoal.leadTimeGoalUnit), leadTimeGoal.leadTimeGoalUnit) }}
                    </div>
                  </div>
                  <div v-if="leadTimeGoal.leadTimeGoal" class="leadtime-section" :style="{color: getColorByPercentage(leadTimePercentage), fontSize: JSON.stringify(leadTimePercentage).length > 5 ? '0.9em' : '1.2em'}">
                    {{ leadTimePercentage == '-1' ? '-' : leadTimePercentage + '%' }}
                  </div>
                </div>
              </v-col>

              <v-col class="background--lighten">
                <div class="card-title" style="display: inline-block; font-size: 1.4em; font-weight: bold">
                  Bottleneck
                </div>
                <span class="card-title" style="margin-top: -5px"> (highest cycle time)</span>
                <div
                  class="leadtime rounded"
                  :style="{
                    border: '2px solid ' + getColorByPercentage(cycleTimePercentage),
                    gridTemplateColumns: (highestStatus.cycleTimeGoal ? '73% 27%' : '100%'),
                  }"
                >
                  <div class="leadtime-section" :style="{background: getColorByPercentage(cycleTimePercentage), color: getTextColorByPercentage(cycleTimePercentage), display: 'inline-block'}">
                    {{
                      highestStatus.cycleTimeGoal ?
                        formatTimeByUnit(highestStatus.cycleTime, highestStatus.cycleTimeGoalUnit, project.usingWorkingHours) :
                        getIndicatorTime(highestStatus.cycleTime, false, project.usingWorkingHours)
                    }}
                    <div v-if="highestStatus.cycleTimeGoal" style="text-align: center; display: inline-block">
                      vs
                      {{ formatTimeByUnit(toMinutes(highestStatus.cycleTimeGoal, highestStatus.cycleTimeGoalUnit), highestStatus.cycleTimeGoalUnit) }}
                    </div>
                  </div>
                  <div v-if="highestStatus.cycleTimeGoal" class="leadtime-section" :style="{color: getColorByPercentage(cycleTimePercentage), fontSize: '1.2em'}">
                    {{ cycleTimePercentage == '-1' ? '-' : cycleTimePercentage + '%' }}
                  </div>
                </div>
                <div class="highest-status" :style="{background: highestStatus.color}">
                  <v-icon color="white">{{highestStatus.iconClass}}</v-icon>
                  <v-spacer></v-spacer>
                  {{ highestStatus.name}}
                  <v-spacer></v-spacer>
                </div>
              </v-col>
            </v-row>
          </div>
        </v-col>
        <v-col v-show="!reportDialog" cols="12" sm="5" md="6" lg="6" style="text-align: right; position: relative;">
          <v-slide-group right show-arrows mandatory v-model="board" style="display: inline-block; width: auto;">
            <v-slide-item v-for="type in shownTypes" :key="type.value" v-slot:default="{ active, toggle }">
              <v-btn class="ma-1 text-capitalize" :input-value="active" active-class="primary" @click="toggle" >
                {{type.displayName}}
              </v-btn>
            </v-slide-item>
          </v-slide-group>
          <v-menu offset-y bottom :close-on-content-click="false" v-model="reportMenu">
            <template v-slot:activator="{ attrs, on }">
              <v-btn color="#cc4b4c" dark class="pdf-btn" v-bind="attrs" v-on="on">
                <v-img class="pdf-icon mr-1" src="../../assets/pdf_icon.png" width="24"></v-img>
                Status Report
              </v-btn>
            </template>
            <v-card style="width: 300px">
              <v-card-text>
                <h2>Status Report</h2>
                <hr class="mt-1">
                <v-row class="pt-4">
                  <v-col
                    v-for="section in reportSections" :key="section.value"
                    cols="12"
                    class="ma-0 py-0"
                  >
                    <v-checkbox
                      v-model="selectedReport"
                      hide-details
                      :label="section.label"
                      :value="section.value"
                      class="mt-0"
                    ></v-checkbox>
                  </v-col>
                </v-row>
                <br>
                <v-btn small color="primary" dark class="float-right ml-2" @click="downloadStatusReport">
                  Download
                </v-btn>
                <v-btn small color="primary" text class="float-right" @click="reportMenu = false">Cancel</v-btn>
                <div class="clearfix"></div>
              </v-card-text>
            </v-card>
          </v-menu>
        </v-col>
      </v-row>

      <!-- Cycle Time Gap -->
      <div v-show="!reportDialog || selectedReport.includes('dashboard')" ref="cycleTimeCont" class="elevation-3 pa-5 rounded mb-5 cycletime-gap background--lighten" style="border: 1px solid var(--v-background-lighten2); padding: 10px">
        <h2 class="card-title" style="margin: 0">Cycle Time Gap</h2>
        <span class="primary--text">Cycle Time Real vs. Objective</span>
        <div class="board-container" ref="cycleTimeGap">
          <!-- Stepper Begin -->
          <div
            v-if="countBeforeFirstInitial() != shownStatus.length && countAfterLastFinal() != shownStatus.length"
            :style="{
              width: (shownStatus.length*columnWidth-marginTimeLine-columnWidth/2+10-columnWidth*countAfterLastFinal()) + 'px',
              marginLeft: marginTimeLine + 'px',
              ...getScale('stepper'),
            }"
          >
            <v-stepper flat :height="24" :key="stepperKey + '-stepper'" style="background: transparent">
              <v-stepper-header style="margin-top: -24px">

                <template v-for="(status, ix) in shownStatus">
                  
                  <v-tooltip content-class="tooltip-timeline" :key="ix + 'tooltip'" top v-if="status.isInitial || status.isFinal" v-model="status.showTooltip">
                    <template v-slot:activator="{ on }">
                      <v-stepper-step
                        step=""
                        v-on="on"
                        @mouseover.native="toggleTooltip(status, true)"
                        @mouseleave.native="toggleTooltip(status, false)"
                        style="cursor: pointer;"
                        :key="ix + 'status'"
                        complete
                        :color="status.isInitial ? 'background' : 'primary'"
                        complete-icon="mdi-checkbox-blank-circle-outline"
                      />
                    </template>
                    <span style="padding-bottom: -30px;">{{status.isInitial ? 'Initial' : 'Final'}}</span>
                  </v-tooltip>
                  
                  <div v-else-if="isAfterFirstInitial(status) && isBeforeLastFinal(status)" :key="ix + 'div'" class="timeline-hidden">
                    <v-divider class="timeline-line" v-if="ix != shownStatus.length-1" :key="ix + 'divider'" />
                  </div>
                  <v-divider class="timeline-line" v-if="isAfterFirstInitial(status) && isBeforeLastFinal(status)" :key="ix + 'divider2'" />
                </template>

              </v-stepper-header>
            </v-stepper>
          </div>
          <!-- Stepper End -->

          <v-row :style="getScale()" style="white-space: nowrap;" align="stretch" class="board flex-nowrap" id="board">
            <v-col v-for="status in shownStatus" :key="status.id" :set="percentage = getStatusPercetage(status)">

              <div
                class="leadtime rounded"
                :style="{
                  border: '2px solid ' + getColorByPercentage(percentage),
                  gridTemplateColumns: (status.cycleTimeGoal ? '73% 27%' : '100%'),
                }"
              >
                <div class="leadtime-section" :style="{background: getColorByPercentage(percentage), color: getTextColorByPercentage(percentage), display: 'inline-block'}">
                  {{
                    status.cycleTimeGoal ?
                      formatTimeByUnit(status.cycleTime, status.cycleTimeGoalUnit, project.usingWorkingHours) :
                      getIndicatorTime(status.cycleTime, false, project.usingWorkingHours)
                  }}
                  <div v-if="status.cycleTimeGoal" style="text-align: center; display: inline-block">
                    vs
                    {{ formatTimeByUnit(toMinutes(status.cycleTimeGoal, status.cycleTimeGoalUnit), status.cycleTimeGoalUnit) }}
                  </div>
                </div>
                <div v-if="status.cycleTimeGoal" class="leadtime-section" :style="{color: getColorByPercentage(percentage), fontSize: '1em'}" style="overflow: hidden; text-overflow: ellipsis; white-space: nowrap">
                  {{ percentage == '-1' ? '-' : percentage + '%' }}
                </div>
              </div>

              <div class="highest-status" :style="{background: status.color}">
                <v-icon color="white">{{status.iconClass}}</v-icon>
                <v-spacer></v-spacer>
                <span>
                  {{ status.name}}
                </span>
                <v-spacer></v-spacer>
              </div>

            </v-col>
          </v-row>
        </div>
      </div>

      <!-- Throughput/Velocity -->
      <div v-show="!reportDialog || selectedReport.includes('dashboard')" ref="throughputCont" class="elevation-3 pt-5 rounded mb-5 background--lighten" style="border: 1px solid var(--v-background-lighten2); padding: 10px">
        <h2 class="card-title ml-7">Throughput/Velocity</h2>
        <div class="filters" v-show="!reportDialog">
          <v-btn class="primary ml-3 mr-3" @click="setDefaults('filters')">Reset Dates</v-btn>
          <v-row class="mr-1">
            <v-col cols="12" sm="6" md="2" lg="2">
              <v-menu
                v-model="menus[0]"
                :close-on-content-click="false"
                :nudge-right="40"
                transition="scale-transition"
                offset-y
                min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="filters.initialDate"
                    label="Initial Date"
                    prepend-icon="mdi-calendar"
                    readonly
                    v-bind="attrs"
                    v-on="on"
                    outlined dense
                  ></v-text-field>
                </template>
                <v-date-picker
                  @change="updateVelocity"
                  v-model="filters.initialDate"
                  @input="menus[0] = false"
                  :min="initialDate"
                  no-title
                ></v-date-picker>
              </v-menu>
            </v-col>
            <v-col cols="12" sm="6" md="2" lg="2">
              <v-menu
                v-model="menus[1]"
                :close-on-content-click="false"
                :nudge-right="40"
                transition="scale-transition"
                offset-y
                min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="filters.finalDate"
                    label="Final Date"
                    prepend-icon="mdi-calendar"
                    readonly
                    v-bind="attrs"
                    v-on="on"
                    outlined dense
                  ></v-text-field>
                </template>
                <v-date-picker
                  @change="updateVelocity"
                  v-model="filters.finalDate"
                  :max="finalDate"
                  @input="menus[1] = false"
                  no-title
                ></v-date-picker>
              </v-menu>
            </v-col>
            <v-col cols="12" sm="6" md="3" lg="3">
              <v-select
                @change="updateVelocity"
                prepend-icon="mdi-clock-time-three-outline"
                :items="timeUnits"
                v-model="filters.timeUnit"
                :item-value="tu => tu"
                dense outlined
              />
            </v-col>
            <v-col cols="12" sm="6" md="5" lg="4">
              <StatusSelect v-model="filters.finalStatus" @change="updateVelocity" label="In Progress Status" :items="shownStatus" />
            </v-col>
          </v-row>
          <div class="pt-2 dates-info">
            <span class="mr-3"><b>Work Items: </b>{{ totals.workItems || 0 }}</span>
            <span class="mr-5"><b>Story Points: </b>{{ totals.storyPoints || 0 }}</span>
          </div>
        </div>
        <highcharts ref="throughputVelocity" :options="velocityOptions" :key="velocityKey + '-velocity'"></highcharts>
      </div>

      <!-- Cumulative Flow Diagram -->
      <div v-show="!reportDialog || selectedReport.includes('dashboard')" ref="cumulativeCont" class="elevation-3 pa-5 rounded background--lighten" style="border: 1px solid var(--v-background-lighten2); padding: 10px">
        <h2 class="card-title">Cumulative Flow Diagram</h2>
        <div class="filters" style="grid-template-columns: 160px auto;" v-show="!reportDialog">
          <v-btn class="primary ml-3 mr-3" @click="setDefaults('cummulativeFilters')">Reset Dates</v-btn>
          <v-row class="mr-1">
            <v-col cols="12" sm="6" md="2" lg="2">
              <v-menu
                v-model="menus[2]"
                :close-on-content-click="false"
                :nudge-right="40"
                transition="scale-transition"
                offset-y
                min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="cummulativeFilters.initialDate"
                    label="Initial Date"
                    prepend-icon="mdi-calendar"
                    readonly
                    v-bind="attrs"
                    v-on="on"
                    outlined dense
                  ></v-text-field>
                </template>
                <v-date-picker
                  @change="updateCumulative"
                  v-model="cummulativeFilters.initialDate"
                  @input="menus[2] = false"
                  :min="initialDate"
                  no-title
                ></v-date-picker>
              </v-menu>
            </v-col>
            <v-col cols="12" sm="6" md="2" lg="2">
              <v-menu
                v-model="menus[3]"
                :close-on-content-click="false"
                :nudge-right="40"
                transition="scale-transition"
                offset-y
                min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="cummulativeFilters.finalDate"
                    label="Final Date"
                    prepend-icon="mdi-calendar"
                    readonly
                    v-bind="attrs"
                    v-on="on"
                    outlined dense
                  ></v-text-field>
                </template>
                <v-date-picker
                  @change="updateCumulative"
                  v-model="cummulativeFilters.finalDate"
                  :max="finalDate"
                  @input="menus[3] = false"
                  no-title
                ></v-date-picker>
              </v-menu>
            </v-col>
            <v-col cols="12" sm="6" md="3" lg="3">
              <v-select
                @change="updateCumulative"
                prepend-icon="mdi-clock-time-three-outline"
                :items="timeUnits"
                v-model="cummulativeFilters.timeUnit"
                :item-value="tu => tu"
                dense outlined
              />
            </v-col>
          </v-row>
        </div>
        <highcharts ref="cumulativeDiagram" :options="cumulativeOptions" :key="cumulativeKey + '-cumulative'"></highcharts>
      </div>

      <Risks :id="id" ref="risks" v-show="reportDialog && selectedReport.includes('risks')"/>
      <KanbanBoard :id="id" ref="kanbanBoard" v-show="reportDialog && selectedReport.includes('kanbanBoard')" :statuses="shownStatus"/>
    </div>
  </div>
</template>

<script>
import _ from "lodash"
import moment from 'moment'
import { jsPDF } from "jspdf"
import html2canvas from 'html2canvas'
import ThroughputVelocity from "../../charts/ThroughputVelocity"
import CumulativeFlowDiagram from "../../charts/CumulativeFlowDiagram"
import StatusSelect from '../../components/StatusSelect'
import GeneralDetails from './statusReport/GeneralDetails'
import TeamMembers from './statusReport/TeamMembers'
import Risks from './statusReport/Risks'
import KanbanBoard from './statusReport/KanbanBoard'

export default {
  props: ["id", 'projectName'],
  components: { StatusSelect, GeneralDetails, TeamMembers, Risks, KanbanBoard },
  data: () => ({
    project: {},
    filters: {
      type: 0,
      initialDate: '',
      finalDate: moment().format("YYYY-MM-DD"),
      timeUnit: 'Weeks',
      status: '',
    },
    cummulativeFilters: {
      initialDate: '',
      finalDate: moment().format("YYYY-MM-DD"),
      timeUnit: 'Weeks',
    },

    velocityOptions: {},
    velocityKey: 0,
    totals: {},

    cumulativeOptions: {},
    cumulativeKey: 0,

    stepperKey: 0,

    defaults: {},
    cardColumns: [],
    timeUnits: ['Days', 'Weeks', 'Months'],
    types: [],
    statuses: [],
    menus: [false, false, false, false],
    categories: [],
    cumulativeCategories: [],
    workItems: [],

    board: 0,
    columnWidth: 200,

    leadTime: {
      value: 0,
      type: 0,
    },
    leadTimeGoal: {
      leadTimeGoal: 0,
      leadTimeUnit: 0,
    },
    initialDate: moment(),
    finalDate: moment(),

    reportDialog: false,
    reportMenu: false,
    selectedReport: ['generalDetails', 'teamMembers', 'dashboard', 'risks', 'kanbanBoard'],
    reportSections: [
      {label: 'General Details', value: 'generalDetails'},
      {label: 'Team Members', value: 'teamMembers'},
      {label: 'Dashboard', value: 'dashboard'},
      {label: 'Risks', value: 'risks'},
      {label: 'Kanban Board', value: 'kanbanBoard'},
    ],
    generalDetails: {},

    heightBeforeTeamMembers: 0,
  }),
  watch: {
    board(){
      this.filters.type = this.shownTypes[this.board].value
      this.updateType()
      this.getLeadTime()
    },
    '$vuetify.theme.dark'(){
      this.$nextTick(() => this.updateCharts())
    },
  },
  computed: {
    marginTimeLine(){
      return this.columnWidth/2 + this.countBeforeFirstInitial()*this.columnWidth
    },
    shownTypes() {
      if (!this.statuses || !this.types) return []
      return this.types.filter(t => this.statuses.some(s => s.workItemType == t.value))
    },
    leadTimePercentage() {
      if(!this.leadTimeGoal || !this.leadTimeGoal.leadTimeGoal)
        return -1
      return Math.round(this.leadTime.value * 100 / this.toMinutes(this.leadTimeGoal.leadTimeGoal, this.leadTimeGoal.leadTimeGoalUnit, this.project.usingWorkingHours))
    },
    cycleTimePercentage() {
      if(!this.highestStatus || !this.highestStatus.cycleTimeGoal)
        return -1
      return Math.round(this.highestStatus.cycleTime * 100 / this.toMinutes(this.highestStatus.cycleTimeGoal, this.highestStatus.cycleTimeGoalUnit, this.project.usingWorkingHours))
    },
    highestStatus(){
      let max = this.statuses.filter(s => s.workItemType == this.filters.type && s.cycleTimeGoal)
      max.sort((a, b) => b.cycleTime - a.cycleTime)
      max = max[0]
      return max || {}
    },
    shownStatus(){
      let statuses = this.statuses.filter(s => s.workItemType == this.filters.type)
      this.$nextTick(() => { 
        if(this.$el.querySelector("#board").children.length){
          this.columnWidth = this.$el.querySelector("#board").children[0].offsetWidth + 10
        } else {
          this.columnWidth = 200
        }
        this.stepperKey++
      })
      return _.orderBy(statuses, ['sortIndex']) 
    },
  },
  mounted() {
    this.setUserPreferences('StatusReport', 'selectedReport')
    this.$q.log(1, 2, 3, this.id, this.$parent.item.id)
    this.$http
      .get(`/api/project/${this.id}`)
      .then(response => {
        this.project = response.data;
      })
      .catch(response => {
        console.log(response)
        window.getApp.error("Cannot get project.");
      });
    let workItemPromise = this.$http.get(`/api/Project/${this.id}/firstWorkItem`)
      .then(res => {
        this.initialDate = moment(res.data.createdAt).format('YYYY-MM-DD')
        this.finalDate = moment().format('YYYY-MM-DD')
        this.filters.initialDate = moment(res.data.createdAt).format('YYYY-MM-DD')
        this.cummulativeFilters.initialDate = moment(res.data.createdAt).format('YYYY-MM-DD')
      })
    let workItemsPromise = this.$http.get(`/api/workItem?projectId=${this.id}&skipDetails=true`)
      .then(res => {
        this.workItems = res.data
      })
      .catch(res => {
        window.getApp.error("Cannot work items.")
        console.log(res)
      })
    let cardColumnsPromise = this.$http.get(`api/Project/${this.id}/cardColumns`)
      .then(res => {
        this.cardColumns = res.data
      })
      .catch(res => {
        window.getApp.error("Cannot obtain data.")
        console.log(res)
      })
    let typePromise = this.$http.get(`/api/Enums/WorkItemType`)
      .then(res => {
        this.types = res.data
      })
      .catch(res => {
        window.getApp.error("Cannot obtain types.")
        console.log(res)
      })
    let statusPromise = this.$http
      .get(`/api/project/${this.id}/config?active=true&includeCycleTime=true`)
      .then(res => {
        this.statuses = res.data.workItemStatuses
      })
      .catch(res => {
        window.getApp.error("Cannot get project sprints, tags and status.")
        console.log(res)
      })
    this.$http
      .get(`/api/project/${this.id}`)
      .then(res => {
        this.generalDetails = res.data
      })

    this.getLeadTime()
    
    Promise.all([workItemPromise, workItemsPromise, cardColumnsPromise, typePromise, statusPromise]).then(() => {
      this.filters.type = this.shownTypes[0].value || 0
      let finalStatus = this.statuses.filter(s => s.workItemType === this.filters.type && s.isFinal)[0]
      if(!finalStatus)
        finalStatus = this.statuses.filter(s => s.workItemType === this.filters.type)[0]
      this.filters.finalStatus = finalStatus.id

      this.$forceUpdate()
      this.updateCharts()
    })

    window.addEventListener('resize', () => {
      this.$nextTick(() => { 
        if(this.$el.querySelector("#board").children.length){
          this.columnWidth = this.$el.querySelector("#board").children[0].offsetWidth
        } else {
          this.columnWidth = 200
        }
        this.stepperKey++
        this.velocityKey++
        this.cumulativeKey++
      })
    })
  },
  methods: {
    getScale(element){
      if(!this.reportDialog){
        this.$nextTick(() => {
          containerTag = this.$refs.cycleTimeGap
          containerTag.scrollLeft = 0
          containerTag.style.overflowX = 'scroll'
        })
        return {}
      }

      let containerTag = this.$refs.cycleTimeGap
      let child = containerTag.children.length-1
      let scale = containerTag.offsetWidth / containerTag.children[child].offsetWidth
      if(scale >= 1){
        this.$nextTick(() => {
          containerTag = this.$refs.cycleTimeGap
          containerTag.scrollLeft = 0
          containerTag.style.overflowX = 'scroll'
        })
        return {}
      }

      let css = {
        transform: `scale(${scale}) ` + (element == 'stepper' ? ' translateY(15px)' : ''),
      }

      this.$nextTick(() => {
        containerTag = this.$refs.cycleTimeGap
        containerTag.scrollLeft = (containerTag.children[child].offsetWidth-containerTag.offsetWidth)/2
        containerTag.style.overflowX = 'hidden'
      })

      return css
    },
		getDate(){ return moment().format('MMMM DD, YYYY') },
    updateCharts(){
      this.updateVelocity()
      this.updateCumulative()
    },
    getColorByPercentage(percentage){
      if(percentage == -1)
        return 'var(--v-background-lighten2)'
      return percentage <= 100 ? 'var(--v-success-base)' : 'var(--v-error-base)'
    },
    getTextColorByPercentage(percentage){
      if(percentage == -1)
        return 'var(--v-text-lighten2)'
      return 'white'
    },
    getStatusPercetage(status){
      if(!status || !status.cycleTimeGoal)
        return -1
      return Math.round(status.cycleTime * 100 / this.toMinutes(status.cycleTimeGoal, status.cycleTimeGoalUnit, this.project.usingWorkingHours))
    },
    getLeadTime(){
      this.$http.get(`api/Project/${this.id}/leadTime/${this.filters.type}`, {headers: {hideLoading: true}}).then(res => {
        this.leadTime = {
          value: res.data.leadTime,
          type: this.filters.type,
        }
        this.leadTimeGoal = res.data.leadTimeGoal || {}
      })
      return this.leadTime
    },
    updateVelocity() {
      this.velocityOptions = ThroughputVelocity(
        this.filters.initialDate,
        this.filters.finalDate,
        this.filters.finalStatus,
        this.filters.type,
        this.filters.timeUnit,
        this.cardColumns,
        this.handleZoom,
        this.setCategories,
        this.setTotals,
        this.$vuetify.theme.isDark
      )
      this.velocityKey++
    },
    updateCumulative() {
      this.cumulativeOptions = CumulativeFlowDiagram(
        this.cummulativeFilters.initialDate,
        this.cummulativeFilters.finalDate,
        this.filters.type,
        this.cummulativeFilters.timeUnit,
        this.workItems,
        this.isNullDate,
        this.setCategories,
        this.handleZoomCummulative,
        this.$vuetify.theme.isDark
      )
      this.cumulativeKey++
    },
    updateType(){
      let finalStatus = this.statuses.filter(s => s.workItemType === this.filters.type && s.isFinal)[0]
      if(!finalStatus)
        finalStatus = this.statuses.filter(s => s.workItemType === this.filters.type)[0]
      this.filters.finalStatus = finalStatus.id
      this.filters.doneStatus = finalStatus.id
      this.updateCharts()
      this.$forceUpdate()
    },
    handleZoom(e){
      this.filters.initialDate = this.categories[Math.round(e.min)].date
      this.filters.finalDate = this.categories[Math.round(e.max)].date
      this.updateVelocity()
    },
    handleZoomCummulative(e){
      this.cummulativeFilters.initialDate = this.cumulativeCategories[Math.round(e.min)].date
      this.cummulativeFilters.finalDate = this.cumulativeCategories[Math.round(e.max)].date
      this.updateCumulative()
    },
    setCategories(categories, cumulative = false){
      if(cumulative){
        this.cumulativeCategories = categories
      } else {
        this.categories = categories
      }
    },
    setDefaults(filters){
      this[filters].initialDate = this.initialDate
      this[filters].finalDate = moment().format("YYYY-MM-DD")
      if(filters == 'filters')
        this.updateVelocity()
      else
        this.updateCumulative()
    },
    setTotals(workItems, storyPoints){
      this.totals.workItems = 0
      for(let workItem of workItems)
        this.totals.workItems += workItem || 0

      this.totals.storyPoints = 0
      for(let storyPoint of storyPoints)
        this.totals.storyPoints += storyPoint || 0
    },

    countBeforeFirstInitial(){
      let count = 0
      for(let ss of this.shownStatus){
        if(ss.isInitial){
          return count
        } else {
          count++
        }
      }
      return count
    },

    countAfterLastFinal(){
      let count = 0
      let reversed = JSON.parse(JSON.stringify(this.shownStatus))
      reversed.reverse()
      for(let ss of reversed){
        if(ss.isFinal){
          return count
        } else {
          count++
        }
      }
      return count
    },

    isAfterFirstInitial(status){
      let firstInitial = false
      for(let ss of this.shownStatus){
        if(ss.isInitial){
          firstInitial = true
        }
        if(ss.id == status.id){
          return firstInitial
        }
      }
      return firstInitial
    },

    isBeforeLastFinal(status){
      let lastFinal = false
      let reversed = JSON.parse(JSON.stringify(this.shownStatus))
      reversed.reverse()
      for(let ss of reversed){
        if(ss.id == status.id){
          return lastFinal
        }
        if(ss.isFinal){
          lastFinal = true
        }
      }
      return lastFinal
    },
    
    toggleTooltip(status, value) {
      status.showTooltip = value
      this.$forceUpdate()
    },
    
    profilePicSrc(member) {
			if (member.profilePicError) {
				return this.profilePicFailover
      }
      let src = `api/user/${member.user.id}/profilePicture`;
      if(!this.$http.defaults.baseURL.endsWith('/'))
        src = '/' + src;
      this.$set(member, 'profilePicSrc', this.$http.defaults.baseURL + src)
    },

    downloadStatusReport(){
      this.$emit('addSpinner')
      this.reportDialog = true
      this.reportMenu = false
      this.saveDark = this.$vuetify.theme.dark
      this.$vuetify.theme.dark = false

      setTimeout(() => {
        let promises = []
        let opts = { useCORS: true, scale: .7, }

        promises.push( html2canvas( this.$refs.header, opts) )
        if(this.selectedReport.includes('generalDetails')){
          promises.push( html2canvas( this.$refs.generalDetails.$el, opts) )
        }
        if(this.selectedReport.includes('teamMembers')){
          promises = promises.concat( this.$refs.members.getCanvases().map(e => html2canvas(e, opts)) )
        }
        if(this.selectedReport.includes('dashboard')){
          promises.push( html2canvas( this.$refs.leadHighestCont, opts) )
          promises.push( html2canvas( this.$refs.cycleTimeCont, opts) )
          promises.push( html2canvas( this.$refs.throughputCont, opts) )
          promises.push( html2canvas( this.$refs.cumulativeCont, opts) )
        }
        if(this.selectedReport.includes('risks')){
          promises = promises.concat( this.$refs.risks.getCanvases().map(e => html2canvas(e, opts)) )
        }
        if(this.selectedReport.includes('kanbanBoard')){
          promises = promises.concat( this.$refs.kanbanBoard.getCanvases().map(e => html2canvas(e, opts)) )
        }

        Promise.all(promises)
          .then(canvases => {
            const doc = new jsPDF('p', 'mm')
            let margin = 10
            let imgWidth = 210 - margin*2
            let pageHeight = 295
            let position = 5

            for(let canvas of canvases){
              let imgData = canvas.toDataURL('image/png')
              let imgHeight = canvas.height * imgWidth / canvas.width

              if(position+imgHeight+5 > pageHeight){
                doc.addPage()
                position = 5
              }

              doc.addImage(imgData, 'PNG', margin, position, imgWidth, imgHeight)
              position += imgHeight+2
            }
            doc.save(`Status Report_${this.generalDetails.smallCode}.pdf`)
          })
          .finally(() => {
            this.$emit('removeSpinner')
            this.reportDialog = false
            this.$vuetify.theme.dark = this.saveDark
          })
      }, 500)
    },
  }
}
</script>

<style scoped lang="scss">
.filters {
  padding: 10px 10px;
  display: grid;
  grid-template-columns: 160px auto 290px;
}
.card-title{
  color: var(--v-text-lighten2);
  margin-bottom: 10px;
}
.dates-info{
  float: right;
  text-align: right;
}

.leadtime {
  display: grid;
  grid-template-columns: 73% 27%;
  width: 95%;
  font-size: 1.2em;
  line-height: 1em;

  .leadtime-section{
    padding: 8px 0;
    display: grid;
    align-items: center;
    justify-content: center;
    text-align: center;
  }
}
.highest-status {
  color: white;
  width: 95%;
  padding: 5px 10px;
  border-radius: 5px;
  display: flex;
  justify-content: space-between;
  margin-top: 5px;
}

.pdf-btn{
  position: absolute;
  bottom: 0;
  right: 15px;
}
.pdf-icon{
  filter: invert(99%) sepia(7%) saturate(35%) hue-rotate(255deg) brightness(110%) contrast(99%);
}

@media (max-width: 1262px) {
  .cycletime-gap{
    width: auto !important;
  }
}
.cycletime-gap{
  width: calc(100vw - 180px);

  .board{
    width: max-content;
    min-width: 100%;
    margin: 0;
  }
  .col {
    padding: 12px 3px;
    flex-grow: 1;
    flex-shrink: 1;
    flex-basis: 0;
    width: 210px;
    margin: 10px 10px 0 0;
  }

  .leadtime {
    display: grid;
    grid-template-columns: 73% 27%;
    width: 100%;
    font-size: .9em;
    margin-bottom: 20px;

    .leadtime-section{
      padding: 8px 0;
      display: grid;
      align-items: center;
      justify-content: center;
      text-align: center;
    }
  }
  .highest-status {
    color: white;
    width: 100%;
    padding: 5px 10px;
    border-radius: 5px;
    display: flex;
    justify-content: center;
    margin-top: 5px;
    font-size: .9em;
    width: 100%;

    overflow: hidden;
    white-space: normal;
    text-align: center;
    white-space: normal;
    word-break: normal;
    height: 38px;
    align-items: center;
    line-height: 1.25em;
    text-overflow: ellipsis;
    overflow: hidden;
  }
}
.board-container{
  overflow-x: scroll;
  overflow-y: visible;
}
.board-container::-webkit-scrollbar {
  width: 5px;
  height: 7px;
}
.board-container::-webkit-scrollbar-thumb{
  background: #AED6F1;
}

.tooltip-timeline{
  margin-top: 30px;
}
::v-deep .v-stepper__step__step .v-icon{
  color: #1976d2 !important;
  font-size: 27px !important;
}
::v-deep .v-stepper__step__step{
  margin-right: 2px !important;
}
::v-deep .v-stepper__step{
  padding-left: 0 !important;
  padding-right: 0 !important;
}
.timeline-line{
  color: #1976d2;
  border: 1px solid #1976d2 !important;
}
.timeline-hidden{
  width: 25px !important;
  padding-left: 32px;
  padding-top: 35px;
}

.logo{
	position: absolute;
	right: 0;
	top: 20px;
}

.hide-back{
  display: inline-block;
  height: 120vh;
  width: 100vw;
}
</style>