<template>
	<div class="view">
		<filter-search-input
			v-model="search" :applied-filters="appliedFilters"
			dense outlined
		>
			<q-date-picker
				v-model="filters.startDate"
				outlined hide-details inner dense
				label="Start Date"
				type="month"
			/>
			<q-date-picker
				v-model="filters.endDate"
				outlined hide-details inner dense
				label="End Date"
				type="month"
			/>
			<v-autocomplete
				v-model="filters.customers"
				outlined hide-details dense multiple clearable
				label="Customer" item-text="name" item-value="id"
				:items="customers"
			/>
		</filter-search-input>

		<v-data-table
			:headers="shownHeaders" :items="report"
			class="rounded-lg bordered new-back lighten2"
			style="width: var(--view-width)"
			@pagination="updateReport"
			:items-per-page.sync="pagination.pageSize"
			:page.sync="pagination.page"
			:server-items-length="pagination.totalItems"
			disable-sort
		>
		<template #[`item.customerName`]="{ item }">
			<div class="px-4" style="width: 150px">{{ item.customerName }}</div>
		</template>
			<template #[`item.billableCode`]="{ item }">
				<div class="px-4" style="width: 170px">{{ item.billableCode }}</div>
			</template>
			<template #[`item.project`]="{ item }">
				<div
					class="py-1" :set="type = projectTypes.find(t => t.value == item.projectType)"
					style="width: 160px"
				>
					<div class="admin-project dense" :style="{ background: type.lightColor }">
						<div class="letter" :style="{ background: type.color }">
							{{type.small}}
						</div>
						<div class="name">{{ item.projectName }}</div>
					</div>
				</div>
			</template>
			<template #[`item.billableStatus`]="{ item }">
				<div class="text-center" style="width: 110px">
					<v-btn
						:color="item.billableStatusColor"
						outlined x-small class="rounded-xl"
						:style="{
							background: $vuetify.theme.isDark ? '' : item.billableStatusLightColor,
						}"
					>{{ item.billableStatusDisplayName }}</v-btn>
				</div>
			</template>
			<template v-for="ix in timeColumns" #[getHeaderSlotName(ix-1)]="{ item }">
				<div :key="ix">
					<span :set="status = statuses.find(s => s.id == item.statuses[ix-1])">
						<v-chip :color="status.lightColor" :style="{ color: status.color }" small>
							{{ status.description }}
						</v-chip>
					</span>
				</div>
			</template>
		</v-data-table>
	</div>
</template>

<script>
import moment from 'moment'
import BillableJSON from '../../json/billable.json'

export default {
	data: () => ({
		report: [],
		headers: [
			{ text: 'Customer', value: 'customerName' },
			{ text: 'Billable', value: 'billableCode' },
			{ text: 'Project', value: 'project' },
			{ text: 'Billable Status', value: 'billableStatus' },
		],

		search: '',
		searchTimer: null,
		filters: {
			startDate: moment().startOf('year').format('YYYY-MM-DD'),
			endDate: moment().endOf('year').format('YYYY-MM-DD'),
		},
		pagination: {
			page: 1,
			pageSize: 10,
			totalItems: 0,
		},

		customers: [],
		statuses: [],
		timeColumns: 12,
		projectTypes: [],
	}),
	created() {
		document.title = 'Validation Report'
		this.projectTypes = BillableJSON.projectTypes
		this.init();
	},
	computed: {
		appliedFilters(){
			return this.countActiveFilters(this.filters, ['startDate', 'endDate'])
		},
		shownHeaders() {
			let timeHeaders = []
			for(let i = 0; i < this.timeColumns; i++) {
				timeHeaders.push({
					text: moment(this.filters.startDate).add(i, 'months').format('MMM, YYYY'),
					value: `statuses.${i}`,
					align: 'center',
				})
			}
			return [...this.headers, ...timeHeaders]
		},
	},
	methods: {
		init() {
			this.$http.get('api/Enums/ValidationStatus')
				.then(res => {
					this.statuses = res.data
				})
				.catch(err => {
					console.error(err)
					this.$root.error('Failed to load validation statuses')
				})
			this.$http.get('api/customer')
				.then(res => {
					this.customers = res.data
				})
				.catch(err => {
					console.error(err)
					this.$root.error('Failed to load customers')
				})
		},
		updateReport() {
			this.$http.post('api/admin/validation', {
				...this.filters,
				...this.pagination,
				pageSize: this.pagination.pageSize !== 'all' ? this.pagination.pageSize : -1,
				timeUnit: this.timeUnit,
				search: this.search,
			})
				.then(res => {
					this.report = res.data.items
					if(this.report.length)
						this.timeColumns = this.report[0].statuses.length
					else
						this.timeColumns = 0
					this.pagination.totalItems = res.data.totalItems
				})
				.catch(err => {
					console.error(err)
					this.$root.error('Failed to load report')
				})
		},
		getHeaderSlotName(ix) {
			return `item.statuses.${ix}`
		},
	},
	watch: {
		search() {
			clearTimeout(this.searchTimer)
			this.searchTimer = setTimeout(() => {
				this.pagination.page = 1
				this.updateReport()
			}, 500)
		},
		filters: {
			handler() {
				this.pagination.page = 1
				this.updateReport()
			},
			deep: true,
		},
	},
}
</script>

<style lang="scss" scoped>
::v-deep {
	table > tbody > tr > td:nth-child(1),
	table > tbody > tr > td:nth-child(2),
	table > tbody > tr > td:nth-child(3),
	table > tbody > tr > td:nth-child(4)
	{
		padding: 0;
	}
	.v-data-table{
		&.theme--light {
			tbody > tr, thead > tr {
				&:hover {
					td, th {
						background: #eeeeee !important;
					}
				}
			}
		}
		&.theme--dark {
			> tbody > tr, > thead > tr {
				&:hover {
					td, th {
						background: #616161 !important;
					}
				}
			}
		}
	}

	table > tbody > tr, table > thead > tr {
		td:nth-child(1),
		th:nth-child(1),
		td:nth-child(2),
		th:nth-child(2),
		td:nth-child(3),
		th:nth-child(3),
		td:nth-child(4),
		th:nth-child(4) {
			position: sticky !important;
			z-index: 1;
			background: var(--v-newDesignBackground-lighten2);

			> div {
				overflow: hidden;
			}
		}
	}
	
	table > tbody > tr > td:nth-child(1),
	table > thead > tr > th:nth-child(1) {
		left: 0;
		width: 150px;
	}
	
	table > tbody > tr > td:nth-child(2),
	table > thead > tr > th:nth-child(2) {
		left: 150px;
		width: 170px;
	}
	
	table > tbody > tr > td:nth-child(3),
	table > thead > tr > th:nth-child(3) {
		left: calc(150px + 170px);
		width: 160px;
	}
	
	table > tbody > tr > td:nth-child(4),
	table > thead > tr > th:nth-child(4) {
		left: calc(150px + 170px + 160px);
		width: 110px;
	}
}
</style>