
<template>
  <div class="pa-3 new-back fill-height">
    <filter-search-input v-model="search" :applied-filters="appliedFilters" outlined dense>
      <q-user-autocomplete v-model="filters.users" label="People" :items="items" :confirmable="false" outlined dense clearable hide-details multiple hide-input/>
      <v-select :items="reportTypes" label="Report Type" item-value="value" item-text="name" v-model="filters.reportType" outlined dense clearable hide-details hide-input/>
      <v-select :items="departments" multiple label="Department" item-value="value" item-text="name" v-model="filters.department" outlined dense clearable hide-details hide-input/>
      <v-select :items="contractTypes" label="Contract Type" item-value="value" item-text="name" v-model="filters.contractType" outlined dense clearable hide-details hide-input/>
      <v-switch inset label="Only Users" class="mt-1" v-model="filters.onlyUsers" hide-details/>
    </filter-search-input>

    <v-row wrap>
      <v-col :cols="verticalView ? 7 : 12" :order="verticalView ? 0 : 2">
        <v-card flat class="rounded-lg new-back lighten bordered darken overflow-hidden">
          <v-card-text class="pa-0">
            <data-table
              :headers="headers"
              :items="shownItems"
              :footer-props="{ itemsPerPageOptions: [10,25,50,{text:'All','value':-1}] }"
              class="new-back lighten"
              item-key="id"
              v-model="selected"
              @click:row="openCard"
            >
              <template #[`item.blueTag`]="{ item }">
                <div class="d-flex align-center my-2" style="gap: 10px">
                  <user-avatar :size="40" :user-id="item.id" hide-loading/>
                  <div>
                    <b>{{ item.blueTag }}</b>
                    <div class="">{{ item.fullName }}</div>
                  </div>
                </div>
              </template>
              <template v-slot:item.birthdate="{ item }">
                <span>
                  {{item.birthdate | formatDateCustom('MMM D')}}
                </span>
              </template>
              <template v-slot:item.department="{ item }">
                <span>
                  {{ departments.find(d => d.value == item.department).name }}
                </span>
              </template>
              <template v-slot:item.blueniversary="{ item }">
                {{item.blueniversary  | formatDate }}
              </template>
              <template v-slot:[`item.badges`]="{ item }">
                <div class="mini-image-container cursor-pointer" v-for="(badge, ix) in item.badges" :key="ix">
                  <v-img height="45" contain :src="formatHttp(imgUrls[badge.badgeId] || '')"></v-img>
                </div>
              </template>
              <template v-slot:item.actions="props">
                <div class="nobr">
                  <v-btn color="primary" fab x-small dark @click="openCard(props.item)">
                    <v-icon>details</v-icon>
                  </v-btn>
                </div>
              </template>
            </data-table>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col :cols="verticalView ? 5 : 12" v-if="showCard" :order="1">
        <v-btn @click="showCard = false" color="primary" fab style="position: absolute; top: 120px; margin-left: 5px; z-index: 200" small>
          <v-icon>mdi-arrow-right</v-icon>
        </v-btn>
        <profile-card
          :small="!verticalView" :userId="userId" directory-view
          class="elevation-1"
        />
      </v-col>
    </v-row>
  </div>
</template>

<script>
import ProfileCard from "./../../components/panels/ProfileCard"
import moment from 'moment'

export default {
  title: 'Directory',
  components: {
    ProfileCard,
  },
  mounted() {
    this.$q.log(5, 29, 3, 'NA')
    this.$http
      .get(`/api/user?active=true&onlyInterns=true`)
      .then(response => {
        this.items = response.data
        this.items.forEach(this.$q.profilePicSrc)
      })
      .catch(response => {
        window.getApp.error("Cannot get records.")
        console.error(response)
      })
    this.$http.get('api/badge')
			.then(res => {
				this.badges = res.data
				for(let badge of this.badges){
					this.imgUrls[badge.id] = badge.imgUrl
				}
        this.$forceUpdate()
			})
			.catch(err => {
				window.getApp.error("Cannot obtain badges")
			})
  },
  data() {
    return {
      search: "",
      selected: [],
      allHeaders: [
        { text: "Blue Tag", value: "blueTag" },
        { text: "Email", value: "email" },
        { text: "Department", value: "department" },
        { text: "Roles", value: "roles" },
        { text: "Birthday", value: "birthdate", sort: (a, b) =>
          moment(moment(a).format('MM-DD')).isBefore(moment(moment(b).format('MM-DD'))) ? 1 : -1
        },
        { text: "Blueniversary", value: "blueniversary" },
				{ text: "Badges", value: "badges", sort: (a, b) => b.length - a.length},
        { text: "", value: "actions", class: "nobr", width: 1 },
      ],
			imgUrls: {},
      badges: [],
      items: [],
      showCard: false,
      userId: null,
      departments: [
        { value: 1, name: 'Administration' },
        { value: 5, name: 'Capability Management' },
        { value: 2, name: 'Commercial' },
        { value: 0, name: 'Dev Center' },
        { value: 4, name: 'Marketing' },
        { value: 3, name: 'Talent Acquisition' },
      ],
      filters: {
        users: [],
        reportType: undefined,
        department: [],
        contractType: undefined,
        onlyUsers: false,
      },
      userTypes: [
        { value: 0, name: 'Employee' },
        { value: 1, name: 'Not Employee' },
      ],
      reportTypes: [
        { value: 0, name: 'Automatic + Manual' },
        { value: 1, name: 'Manual' },
      ],
      departments: [
        { value: 1, name: 'Administration' },
        { value: 5, name: 'Capability Management' },
        { value: 2, name: 'Commercial' },
        { value: 0, name: 'Dev Center' },
        { value: 4, name: 'Marketing' },
        { value: 3, name: 'Talent Acquisition' },
      ],
      contractTypes: [
        { value: 0, name: 'Full Time' },
        { value: 1, name: 'Part Time' },
      ],
    }
  },
  computed: {
    shownItems(){
      let items = this.items
      if(!this.items)
        return []
      if(this.filters.onlyUsers){
        items = items.filter(i => i.isUser)
      }
      if(this.filters.users != undefined && this.filters.users.length){
        items = items.filter(i => this.filters.users.includes(i.id))
      }
      if(this.filters.reportType != undefined){
        items = items.filter(i => i.reportType == this.filters.reportType)
      }
      if(this.filters.department != undefined && this.filters.department.length){
        items = items.filter(i => this.filters.department.includes(i.department))
      }
      if(this.filters.contractType != undefined){
        items = items.filter(i => i.contractType == this.filters.contractType)
      }
      items = this.applySearch(this.search, items, this.allHeaders.map(h => h.value).filter(h => h).concat(['fullName']))
      return items
    },
    verticalView() {
      return (this.showCard && this.$vuetify.breakpoint.lgAndUp)
    },
    headers() {
      if (this.showCard) {
        let only = ['blueTag', 'email', 'fullName']
        return this.allHeaders.filter(m => only.indexOf(m.value) != -1)
      }
      return this.allHeaders
    },
    appliedFilters(){
			return this.countActiveFilters(this.filters)
		},
  },
  methods: {
    removeUser(id){
      this.filters.users = this.filters.users.filter(u => u != id)
    },
    openCard(item) {
      this.showCard = true
      this.userId = item.id
    }
  }
}
</script>

<style scoped lang="scss">
  .strikethrough {
    text-decoration: line-through;
  }
  .v-data-table ::v-deep tbody tr {
    cursor: pointer;
  }
  .mini-image-container{
    float: left;
    padding-right: 5px;
    width: 45px;
  }
  ::v-deep .v-input__slot{
    background: inherit !important;
  }
</style>