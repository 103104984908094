<template>
	<div>
		<h3 class="mb-5">Invoices</h3>
		<admin-balance-panel
			:items="invoices"
			:pathToChild="x => x.payments"
			:mainHeaders="invoiceHeaders"
			:secondaryHeaders="paymentHeaders"
			mainTemplateColumns="repeat(3, 1fr) repeat(3, 1fr)"
			secondaryTemplateColumns="1fr 2fr repeat(3, 1fr)"
		>
			<template #[`item.code`]="item">
				<h4>{{item.code}}</h4>
			</template>
			<template #[`item.issueDate`]="item">
				{{item.issueDate | formatDate}}
			</template>
			<template #[`item.paymentDay`]="item">
				{{item.paymentDay | formatDate}}
			</template>
			<template #[`item.total`]="item">
				<v-btn fab width="23" height="23" class="mr-1" depressed color="#b9d7fe">
					<v-icon size="15" color="primary">mdi-script-text-outline</v-icon>
				</v-btn>
				{{item.currency}} {{ currencyFormat(item.total) }}
			</template>
			<template #[`item.paidAmount`]="item">
				<v-btn fab width="23" height="23" class="mr-1" depressed color="#bce3c9">
					<v-icon size="15" color="success">mdi-cash-multiple</v-icon>
				</v-btn>
				{{item.currency}} {{ currencyFormat(item.paidAmount) }}
			</template>
			<template #[`item.pendingToPay`]="item">
				<v-btn fab width="23" height="23" class="mr-1" depressed color="#f5c4c5">
					<v-icon size="15" color="error">mdi-clipboard-file-outline</v-icon>
				</v-btn>
				{{item.currency}} {{ currencyFormat(item.total - item.paidAmount) }}
			</template>
			
			<template #[`subitem.paymentReference`]="item">
				<b>{{item.payment.reference}}</b>
			</template>
			<template #[`subitem.paymentDate`]="item">
				{{ item.payment.date | formatDate }}
			</template>
			<template #[`subitem.paymentAmount`]="item">
				<v-btn fab width="23" height="23" class="mr-1" depressed color="#b9d7fe">
					<v-icon size="15" color="primary">mdi-cash-multiple</v-icon>
				</v-btn>
				{{item.payment.accountCurrency}} {{ currencyFormat(item.payment.amount) }}
			</template>
			<template #[`subitem.paymentAppliedAmount`]="item">
				<v-btn fab width="23" height="23" class="mr-1" depressed color="#bce3c9">
					<v-icon size="15" color="success">mdi-check-circle-outline</v-icon>
				</v-btn>
				
				<div style="display: grid; grid-template-columns: 1fr 1fr; gap: 10px; width: 100%">
					<span>{{ item.payment.accountCurrency }} {{ currencyFormat(item.amount) }}</span>
					<span v-if="item.exchangeRateAmount">
						{{ item.invoiceCurrency }} {{ currencyFormat(item.exchangeRateAmount) }}
					</span>
				</div>
			</template>
		</admin-balance-panel>
	</div>
</template>

<script>
import AdminBalancePanel from './AdminBalancePanel'
export default {
	props: {
		invoices: Array,
	},
	components: { AdminBalancePanel },
	data: () => ({
		invoiceHeaders: [
			{ text: 'Invoice No.', value: 'code' },
			{ text: 'Invoice Date', value: 'issueDate' },
			{ text: 'Due Date', value: 'paymentDay' },
			{ text: 'Invoice total', value: 'total' },
			{ text: 'Total paid', value: 'paidAmount' },
			{ text: 'Pending to pay', value: 'pendingToPay' },
		],
		paymentHeaders: [
			{ text: 'Reference', value: 'paymentReference' },
			{ text: 'Payment date', value: 'paymentDate' },
			{ text: 'Payment amount', value: 'paymentAmount' },
			{ text: 'Applied amount', value: 'paymentAppliedAmount' },
		],
	}),
}
</script>