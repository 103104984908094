<template>
    <v-dialog :fullscreen="$vuetify.breakpoint.xsOnly" scrollable persistent 
        v-model="dialog" :max-width="400" v-if="dialog">
        <v-form ref="form" v-model="validForm">
            <v-card style="padding: 20px;">
                <v-card-title class="pt-2 pb-4">
                    Delete link
                </v-card-title>
                <v-card-text>
                    <span style="font-size:1.2em">
                        Are you sure you want to delete this link?
                    </span>
                </v-card-text>
                <v-card-actions class="px-6">
                    <v-spacer></v-spacer>
                    <v-btn color="primary" text @click="cancel">
                        Cancel
                    </v-btn>
                    <v-btn color="error" depressed @click="confirm" :disabled="!validForm">
                        Delete
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-form>
    </v-dialog>
</template>
<script>
export default {
    props: ['dialog', 'cancel', 'projectId', 'callback', 'link'],
    data(){
        return{
            validForm: false,
        }
    },
    methods:{
        confirm(){
            this.$http.delete(`api/Project/link/${this.link.id}`).then(res => {
                if(res.data != 'Success')
                    window.getApp.error(res.data);
                else{
                    window.getApp.success("Link deleted successfully.");
                    this.callback();
                }
            }).catch(response => {
                window.getApp.error("Cannot delete link.");
                console.log(response);
            });
        }
    }
}
</script>
<style scoped>
.color-black{
    color: black !important;
}
</style>