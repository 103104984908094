<template>
	<v-dialog v-if="dialog" v-model="dialog" width="530" persistent>
		<v-card>
			<v-card-title class="mb-3 mt-5"> Healthcheck reminder </v-card-title>
			<v-card-text>
				<div>
					<div class="d-flex justify-space-between" style="gap: 10px">
						<div style="width: 48%">
							<label>Recurrence goal*</label>
							<v-select v-model="healthcheckReminder" :items="healthcheckReminders" outlined dense/>
						</div>
						<div style="width: 48%">
							<label>Next healthcheck</label>
							<v-text-field v-model="nextHealthcheck" outlined disabled dense append-icon="event"/>
						</div>
					</div>
				</div>
			</v-card-text>
			<v-card-actions class="justify-end">
				<v-btn @click="close(false)" color="primary" outlined>Cancel</v-btn>
				<v-btn @click="save" color="primary">Confirm</v-btn>
			</v-card-actions>
		</v-card>
	</v-dialog>	
</template>

<script>
import moment from 'moment'

export default {
	data: () => ({
		dialog: false,
		id: null,
		resolve: null,
		healthcheckReminders: [
			{ value: 0, text: 'Every two weeks' },
			{ value: 1, text: 'Every month' },
		],
		healthcheckReminder: 0,
		lastHealthcheckDate: '',
	}),
	computed: {
		nextHealthcheck(){
			const timeIncreasers = {
				0: date => moment(date).clone().add(2, 'week'),
				1: date => moment(date).clone().add(1, 'month'),
			}
			return timeIncreasers[this.healthcheckReminder](this.lastHealthcheckDate).format('MMM D, YYYY')
		},
	},
	methods: {
		open(id, healthcheckReminder, lastHealthcheckDate){
			this.healthcheckReminder = healthcheckReminder
			this.id = id
			this.dialog = true
			this.lastHealthcheckDate = lastHealthcheckDate
			return new Promise(res => {
				this.resolve = res
			})
		},
		save(){
			if(this.healthcheckReminder === null || this.healthcheckReminder === undefined)
				return this.$root.error('Please select a recurrence goal')
			this.$http
				.post(`api/nucleiManagement/${this.id}/healthcheckReminder/${this.healthcheckReminder}`)
				.then(() => {
          this.$root.success('Config saved')
					this.close(true)
				})
				.catch(err => {
					console.error(err)
					this.$root.error('Cannot save changes')
				})
		},
		close(val){
			this.dialog = false
			this.resolve(val)
		},
	},
}
</script>

<style lang="scss" scoped>

</style>