<template>
	<div>
		<div class="mb-1">
			<div class="float-left" style="display: flex; gap: 5px; align-items: center; height: 48px">
				<v-btn class="quick-add mr-2" depressed :disabled="!showCol.name || !$parent.canEditProject" @click="quickAdd">
					<v-icon dark>add</v-icon>
					Quick Add
				</v-btn>
			</div>
			<div class="float-right" style="display: flex; gap: 5px; align-items: center">
				<v-tooltip top>
					<template v-slot:activator="{ on }">
						<v-btn
							v-on="on" @click="$router.push('traceability')"
							fab small color="primary"
						>
							<v-icon>mdi-chart-sankey-variant</v-icon>
						</v-btn>
					</template>
					Traceability Report
				</v-tooltip>

        <v-tooltip top>
          <template v-slot:activator="{ on }">
            <div small class="excel-btn" text v-on="on" @click="downloadExcel">
              <img :src="excelIcon"/>
            </div>
          </template>
          <span>Download Excel</span>
        </v-tooltip>

				<v-tooltip top>
					<template v-slot:activator="{ on }">
						<v-btn v-on="on" @click="onelineTable = !onelineTable" color="primary" small fab>
							<v-icon v-if="onelineTable">mdi-wrap-disabled</v-icon>
							<v-icon v-else>mdi-wrap</v-icon>
						</v-btn>
					</template>
					{{ onelineTable ? 'Unwrap' : 'Wrap' }}
				</v-tooltip>

				<v-tooltip top>
					<template v-slot:activator="{ on: on1 }">
						<v-menu v-model="menu" :close-on-content-click="false" bottom offset-y>
							<template v-slot:activator="{ on: on2 }">
								<v-btn color="primary" fab small v-on="{...on1, ...on2}">
									<v-icon>mdi-table-eye</v-icon>
								</v-btn> 
							</template>

							<v-card :width="$vuetify.breakpoint.mdAndUp ? '17vw' : ''">
								<v-list dense>
									<v-subheader><span class="ml-2 text-body-2 font-weight-bold"> Favorites</span> <v-spacer></v-spacer>
										<v-btn v-if="!editHeaderTemplate" text small color="primary" class="mx-1 clickeable" @click="editHeaderTemplates">Edit</v-btn>
										<div v-else>
											<v-btn text small color="primary" class="clickeable"  @click="cancelEditHeaderTemplates">Cancel</v-btn>
											<v-btn text small color="primary" @click="saveNewHeaderTemplate">Save</v-btn>
										</div>
									</v-subheader>
									<v-list-item v-for="favorite in favoriteTemplates.filter(f => !f.deleted)" :key="favorite.id">
										<v-list-item-action class="mr-2 my-0">
											<v-checkbox
												@change="handleFavoriteChange(favorite.id)"
												v-model="favorite.selected"
												dense color="primary"
												:disabled="editHeaderTemplate"
												@click="repositionMenu"
											></v-checkbox>
										</v-list-item-action>
										<v-list-item-title style="font-size: .95em">
											{{favorite.name}}

											<v-icon
												v-if="editHeaderTemplate"
												@click="removeHeaderTemplate(favorite)"
												small color="error" class="ml-1"
											>mdi-minus-circle</v-icon>
										</v-list-item-title>
									</v-list-item>
									<v-list-item v-if="newFavoriteTemplate">
										<v-list-item-title>
											<v-text-field
												label="Name" class="mb-2 mt-1"
												style="width: 150px"
												dense hide-details outlined
												@change="newFavoriteTemplate.name = $event"
											/>
										</v-list-item-title>
									</v-list-item>
									<v-list-item v-if="editHeaderTemplate && !newFavoriteTemplate">
										<v-list-item-action>
											<v-btn
												@click="newFavoriteTemplate = {}"
												x-small fab
												color="primary" class="mb-1"
											><v-icon>mdi-plus</v-icon></v-btn>
										</v-list-item-action>
									</v-list-item>
									<v-divider class="mx-4 my-2"></v-divider>
									<v-row class="clear-select-container">
										<v-col >
											<div class="mt-2 mb-2 toggleSelect" >
												<a @click="toggleColsSelect(true)" class="cursor-pointer" >
													Select All
												</a>
												<a @click="toggleColsSelect(false)" class="cursor-pointer" >
													Clear All
												</a>
											</div>
										</v-col>
									</v-row>
									<v-list-item v-for="header in headers" :key="header.value" v-show="header.text">
										<v-list-item-action class="mr-2">
											<v-checkbox
												dense color="primary"
												:disabled="disabledNameIfQuickAddActive(header.value)"
												v-model="showCol[header.value]"
											/>
										</v-list-item-action>
										<v-list-item-title style="font-size: .95em">
											{{header.text}}
										</v-list-item-title>
									</v-list-item>
								</v-list>
								<v-card-actions>
									<v-spacer></v-spacer>
									<v-btn color="primary" small text @click="menu = false">Close</v-btn>
								</v-card-actions>
							</v-card>
						</v-menu>
					</template>
					View
				</v-tooltip>
				
				<div class="text-center background--lighten2 px-2 rounded">
					Total: <br>
					{{totalHours}} hrs.
				</div>
      </div>
			<div class="clearfix"></div>
		</div>
		<v-data-table
			:value="selectedWorkItems"
			@input="$emit('update:selectedWorkItems', $event)"
			class="rounded-lg bordered new-back lighten2"
			:headers="shownHeaders" :items="newWorkItems.concat(workItems)"
			@contextmenu:row="openContextMenu"
			@click:row="openWorkItemDetailsDialog"
			show-select
		>
			<template #[`item.code`]="{ item }">
				<span class="text-no-wrap">
					{{item.code}}
				</span>
			</template>
			<template #[`item.type`]="{ item }">
				<v-tooltip top>
					<template v-slot:activator="{ on }">
						<v-btn v-on="on" dark :elevation="0" fab x-small :color="item.typeColor">
							<v-icon>{{ item.typeIconClass }}</v-icon>
						</v-btn>
					</template>
					<span v-text="item.typeDisplayName"></span>
				</v-tooltip>
			</template>
			<template #[`item.name`]="{ item }">
				<v-text-field
					v-if="item.isNew && !item.name" :value="item.name"
					label="Summary*" @change="handleFirstSummaryChange($event, item)"
					outlined dense hide-details single-line
					class="inline-block"
				/>
				<div v-else :class="{oneline: onelineTable}" style="text-align:left;" :title="item.name">{{item.name}}</div>
			</template>
			<template #[`item.description`]="{ item }">
				<div :class="{oneline: onelineTable}" v-if="!item.isNew" style="text-align:left;" v-html="item.description"></div>
			</template>
			<template #[`item.statusName`]="{ item }">
				<div
					:class="{'oneline-ellipsis': onelineTable}"
					:style="`background:${item.statusColor};`" class="status-container"
				>
					<v-icon v-if="item.statusIconClass" color="white" small>{{item.statusIconClass}}</v-icon>
					{{setMaxText(item.statusName, 25)}}
				</div>
			</template>
			<template #[`item.priority`]="{ item }">
				<v-tooltip top>
					<template v-slot:activator="{ on }">
						<div class="text-center" style="height: 100%; display: flex; justify-content: center">
							<v-img v-on="on" style="flex: none" width="24px" height="24px" :src="require(`../../assets/priority-icons/${item.priority}.png`)" contain></v-img>
						</div>
					</template>
					<span class="text-capitalize" v-text="item.priorityDisplayName"></span>
				</v-tooltip>
			</template>
			<template #[`item.estimatedEffort`]="{ item }">
				{{item.estimatedEffort}}
			</template>
			<template #[`item.totalHours`]="{ item }">
				{{ roundedTotalHours(item.totalHours) }}
			</template>
			<template #[`item.currentSprintName`]="{ item }">
				<span>{{item.currentSprintName || ''}}</span>
			</template>
			<template #[`item.allTags`]="{ item }">
				<div :class="{oneline: onelineTable}">
					<v-chip class="mr-1" x-small dark v-for="(tag, ix) in item.tags" :key="ix"
						:color="tag.tagColor"
						:style="`color: ${isBlackText(tag.tagColor) ? 'white' : 'black'}`"
					>
						{{tag.tagName}}
					</v-chip>
				</div>
			</template>
			<template #[`item.assignedToBlueTag`]="{ item }">
				<div>
					<span v-if="item.assignedToId">{{item.assignedToBlueTag || item.assignedToFullName}}</span>
					<i v-else>Unassigned</i>
				</div>
			</template>
			<template #[`item.reportedByBlueTag`]="{ item }">
				<span>{{ item.reportedByBlueTag || item.reportedByFullName }}</span>
			</template>
			<template #[`item.createdAt`]="{ item }">
				<small>{{item.createdAt | formatDate}}</small>
			</template>
		</v-data-table>
	</div>
</template>

<script>
import moment from "moment"
import excelIcon from '../../assets/excel-icon.svg';

export default {
	props: {
		id: String,
		selectedWorkItems: Array,
	},
	watch: {
		showCol: {
			handler(value) {
				if(this.someFavoriteSelected) {
					this.handleFavoriteChange(null)
				}
			},
			deep:true
		},
		'$parent.filters': {
			handler() {
				if(this.someFavoriteSelected) {
					this.handleFavoriteChange(null)
				}
        this.fetchWorkItems(true, false, false)
			},
			deep:true
		},
    '$parent.search'(){
			this.fetchWorkItems(true, false, false)
    },
    '$parent.selectedTypeValues'(){
			this.fetchWorkItems(true, false, false)
    },
	},
	data: () => ({
		excelIcon,
		headers: [
      { text: "Code", value: "code", editable: false,
        sort: (a, b) => {
          let indexA = a.lastIndexOf('-')
          let indexB = a.lastIndexOf('-')
          a = a.substr(indexA+1)
          b = b.substr(indexB+1)
          return parseInt(a) - parseInt(b)
        },
      },
      { text: "Type", value: "type", align: 'center', oneClickEdit: false, },
      { text: "Summary", value: "name", oneClickEdit: false, },
      { text: "Description", value: "description", oneClickEdit: false, },
      { text: "Status", value: "statusName", align: 'center', oneClickEdit: false, },
      { text: "Priority", value: "priority", align: 'center', oneClickEdit: false, },
      { text: "Estimated Dev", value: "estimatedEffort", align: 'center', oneClickEdit: false, },
      { text: "Total Hours", value: "totalHours", align: 'center', oneClickEdit: false, },
      { text: "Sprint", value: "currentSprintName", align: 'center', oneClickEdit: false, },
      { text: "Tags", value: "allTags", oneClickEdit: false, },
      { text: "Assigned to", value: "assignedToBlueTag", oneClickEdit: false, },
      { text: "Reported by", value: "reportedByBlueTag", oneClickEdit: false, },
      { text: "Created on", value: "createdAt", editable: false, },
      { text: "Created by", value: "createdByBlueTag", editable: false, },
    ],
		workItems: [],
		newWorkItems: [],

    menu: false,
		onelineTable: false,
    editHeaderTemplate:false,
    newFavoriteTemplate: null,
    someFavoriteSelected: false,
		favoriteTemplates: [],
		showCol: {
      code: true,
      type: true,
      name: true,
      description: true,
      statusName: true,
      priority: true,
      estimatedEffort: true,
      currentSprintName: true,
      allTags: true,
      assignedToBlueTag: true,
      reportedByBlueTag: true,
      createdAt: true,
      createdByBlueTag: true,
    },
		workItemTimeout: null,
    pagination: {
			page: 1,
			itemsPerPage: 20,
			totalItems: 0,
		},
	}),
	mounted(){
		const excelValues = {
			code: wi => wi.code,
			type: wi => wi.typeDisplayName,
			name: wi => wi.name,
			description: wi => this.htmlToText(wi.description),
			statusName: wi => wi.statusName,
			priority: wi => wi.priorityName,
			estimatedEffort: wi => wi.estimatedEffort || 0,
			totalHours: wi => this.roundedTotalHours(wi.totalHours || 0),
			currentSprintName: wi => wi.currentSprintName || '',
			allTags: wi => {
				let tagsString = ''
				for(let tag of wi.tags){
					tagsString += tag.tagName + ', '
				}
				if(tagsString.endsWith(', '))
					tagsString = tagsString.substring(0, tagsString.length-2)
				return tagsString
			},
			assignedToBlueTag: wi => wi.assignedToBlueTag || 'Unassigned',
			reportedByBlueTag: wi => wi.reportedByBlueTag || wi.reportedByFullName,
			createdAt: wi => wi.createdAt.substr(0, 10),
			createdByBlueTag: wi => wi.createdByBlueTag,
		}
		this.headers.forEach(x => {
			this.$set(x, 'excelValue', wi => wi[x.value])
			if(excelValues[x.value])
				this.$set(x, 'excelValue', excelValues[x.value])
		})
    this.setUserPreferences("WorkItemsList", "showCol")
		this.$http
			.get(`/api/FavoriteWorkItemConfig/${this.$parent.id}`)
			.then(res => {
				this.favoriteTemplates= res.data
			})
			.catch(res => {
				console.log(res)
				window.getApp.error("Cannot obtain favorite headers.")
			})
		this.fetchWorkItems(true, false, false)
	},
	computed: {
		noOneSelected(){
			for(let item of this.$parent.shownWorkItems){
				if(item._selected)
					return false
			}
			return true
		},
		totalHours(){
			let workItems = this.workItems
			if(this.selectedWorkItems.length){
				workItems = this.selectedWorkItems
			}
			var total = workItems
				.concat(this.newWorkItems)
				.reduce((partial, a) => partial + (a.totalHours || 0), 0)
			return Math.round(total)
		},
		someNew(){ return !!this.$parent.newWorkItems.length },
		isNewValid(){
			return !this.$parent.newWorkItems.some(wi => !wi.name)
		},
    shownHeaders(){
      return this.headers.filter(h => this.showCol[h.value])
    },
	},
	methods: {
    fetchWorkItems(resetWorkItems, updateCurrent, hideLoading) {
			if(this.workItemTimeout) {
        clearTimeout(this.workItemTimeout)
      }
			if(resetWorkItems) {
				this.pagination.page = 1
			}
			if(updateCurrent) {
				this.pagination.page = 1
				this.pagination.itemsPerPage = this.workItems.length
			}

			return new Promise((response, reject) => {
				this.workItemTimeout = setTimeout(() => {
					this.$http.post(
						'api/workItem/list?projectId=' + this.id,
						{
							...this.$parent.filters,
							types: this.$parent.selectedTypeValues,
							page: 1,
							pageSize: -1,
							search: this.$parent.search,
						},
						{ headers: { hideLoading } }
					)
						.then(res => {
							let workItems = res.data.workItems
							this.pagination.totalItems = res.data.totalItems

							if(resetWorkItems) {
								this.workItems = workItems
								this.$emit('update:selectedWorkItems', [])
							}
							else
								this.workItems = this.workItems.concat(workItems)

							if(updateCurrent)
								this.pagination.itemsPerPage = 20

							if(this.workItems.length == 0 && this.pagination.totalItems){
								this.$nextTick(() => {
									this.pagination.page = 1
								})
							}

							response()
						})
						.catch(res => {
							window.getApp.error("Cannot obtain work items.")
							console.error(res)
							reject()
						})
				}, 200)
			})
    },
		openWorkItemDetailsDialog(wi){
			if(wi.isNew) return
			this.$parent.openWorkItemDetailsDialog(wi.code, () => this.fetchWorkItems(true, true, false))
		},
		roundedTotalHours(hours) {
      return hours ? this.round(hours) : ''
    },
		repositionMenu(){
			this.menu = false
			this.$nextTick(() => {
				this.menu = true
			})
		},
		toggleColsSelect(select) {
			const showColKeys = _.keys(this.showCol)
			showColKeys.forEach(col => this.showCol[col] = select)
		},
		handleFirstSummaryChange(value, item){
			item.name = value
			if(value){
				this.$q.save({
					api: "/api/workitem",
					data: item,
					successMsg: null,
					afterSuccessUrl: null,
					errorMsg: "Cannot save record.",
					afterErrorUrl: null,
				}).then(res => {
					this.newWorkItems = this.newWorkItems.filter(i => i.code != item.code)
					this.fetchWorkItems(true, false, false)
						.then(this.recomputeCodes)
				}).catch(err => {
					console.error(err)
					this.$root.error('Cannot save work item.')
				})
			}
		},
		saveNew(){
			let reversed = this.$parent.newWorkItems.reverse()
			this.$q.save({
        api: "/api/workitems",
        data: reversed,
        successMsg: "Work items saved.",
        afterSuccessUrl: null,
        errorMsg: "Cannot save record.",
        afterErrorUrl: null,
      }).then(res => {
        let newWorkItems = res.data.reverse()
				this.$parent.newWorkItems = []
        this.$parent.workItems = newWorkItems.concat(this.$parent.workItems)
      }).catch(res => {
        console.log(res);
      })
		},
		quickAdd(){
      let lastItem = { type: 0 }
			let lastCode = 1
      if(this.workItems.length){
        lastItem = this.newWorkItems.concat(this.workItems)[0]
				lastCode = parseInt(lastItem.code.substr(lastItem.code.lastIndexOf('-')+1))
			}
      let smallCode = this.$parent.project.smallCode

      let statuses = this.$parent.projectConfig.workItemStatuses
      let defaultStatus = statuses.find(s => s.workItemType === lastItem.type && s.isDefault)
			let curStatus = statuses.find(s => s.workItemType === lastItem.type)
			let priority = this.$parent.priorities.find(p => p.value == 3)

			if(defaultStatus)
      	curStatus = defaultStatus
			this.newWorkItems.unshift({
        code: smallCode + '-' + (lastCode+1),
        assignedToId: null,
        description: "",
        estimatedEffort: null,
        name: '',
        projectId: this.$parent.project.id,

        priority: priority.value,
        priorityColor: priority.color,
        priorityIconClass: priority.iconClass,
        priorityDisplayName: priority.displayName,
        priorityName: priority.name,

        reportedById: this.$parent.currentUser.id,
        reportedByBlueTag: this.$parent.currentUser.blueTag,
        reportedByFullName: this.$parent.currentUser.fullName,

        createdById: this.$parent.currentUser.id,
        createdByBlueTag: this.$parent.currentUser.blueTag,
        createdByFullName: this.$parent.currentUser.fullName,

        currentSprintId: null,

        statusId: curStatus.id,
				statusColor: curStatus.color,
				statusIconClass: curStatus.iconClass,
				statusName: curStatus.name,

        type: lastItem.type,
        typeIconClass: lastItem.typeIconClass,
        typeDisplayName: lastItem.typeDisplayName,
        typeColor: lastItem.typeColor,

        isNew: true,
        _preventClick: true,

        createdAt: moment().format('YYYY-MM-DD'),
        tags: [],
      })
    },
		recomputeCodes(){
      let lastItem = { type: 0 }
			let lastCode = 1
      let smallCode = this.$parent.project.smallCode
			if(this.workItems.length){
        lastItem = this.workItems[0]
				lastCode = parseInt(lastItem.code.substr(lastItem.code.lastIndexOf('-')+1))
			}
			for(let wi of this.newWorkItems.reverse()){
				this.$set(wi, 'code', smallCode + '-' + (++lastCode))
			}
			this.newWorkItems.reverse()
		},
		disabledNameIfQuickAddActive(value) {
      return value === 'name' && this.nameNeedsToBeDisabled
    },
		openContextMenu(evt, item){
			this.$parent.openWorkItemContextMenu(evt, item.item)
		},
    handleFavoriteChange(id){
      this.someFavoriteSelected = false
      for(let template of this.favoriteTemplates){
        if(template.id == id && template.selected){
					for(let col in this.showCol){
						this.showCol[col] = !!template.columns.find(c => c == col)
          }
          
          this.$parent.filters.statuses = template.statuses
          this.$parent.filters.tags = template.tags
          this.$parent.filters.sprints = template.sprints
          this.$parent.filters.priorities = template.priorities
          this.$parent.filters.assignedTo = template.assignedTo
          this.$parent.filters.reportedBy = template.reportedBy
					this.$nextTick(() => this.someFavoriteSelected = true)
        }
        else{
          template.selected = false
        }
      }
      this.$forceUpdate()
    },
    editHeaderTemplates(){
      this.editHeaderTemplate = true
      for(let template of this.favoriteTemplates){
        template.selected = false
      }
    },
    cancelEditHeaderTemplates(){
      this.editHeaderTemplate=false
      this.newFavoriteTemplate = null
      for(let template of this.favoriteTemplates){
        template.deleted = false
      }
    },
    removeHeaderTemplate(fav){
			this.$set(fav, 'deleted', true)
    },
    saveNewHeaderTemplate(){
      let favorites = this.favoriteTemplates.filter(f => !f.deleted)
      if(this.newFavoriteTemplate && !!this.newFavoriteTemplate.name){
        this.newFavoriteTemplate.columns = []
        for(let col in this.showCol){
          if(this.showCol[col]){
            this.newFavoriteTemplate.columns.push(col)
          }
        }
        this.newFavoriteTemplate.statuses = this.$parent.filters.statuses
        this.newFavoriteTemplate.tags = this.$parent.filters.tags
        this.newFavoriteTemplate.sprints = this.$parent.filters.sprints
        this.newFavoriteTemplate.priorities = this.$parent.filters.priorities
        this.newFavoriteTemplate.assignedTo = this.$parent.filters.assignedTo
        this.newFavoriteTemplate.reportedBy = this.$parent.filters.reportedBy
        favorites.push(this.newFavoriteTemplate)
      }


      this.$http.post(`/api/FavoriteWorkItemConfig/${this.$parent.id}`, favorites)
        .then(res => {
          this.favoriteTemplates= res.data
          this.newFavoriteTemplate = null
          this.editHeaderTemplate = false
        })
        .catch(res => {
          console.log(res)
          window.getApp.error("Cannot save favorite headers.")
        })
    },
		downloadExcel(){
      let props = {
        Title: `${this.$parent.project?.name} - WorkItems`,
        Subject: "Work Items",
        Author: 'BlueSurf',
        CreatedDate: new Date()
      };

      // Fill sheet info
      let data = [], row = [];

			this.shownHeaders.forEach(x => {
				row.push(x.text)
			})

      data.push(row);

			let workItems = this.workItems
			if(this.selectedWorkItems.length){
				workItems = this.selectedWorkItems
			}

      for(let workItem of workItems.sort((item1, item2) => new Date(item2.createdAt) - new Date(item1.createdAt))){
      	if(workItem._selected || this.noOneSelected) {
					row = []
					this.shownHeaders.forEach(x => {
						row.push(x.excelValue(workItem))
					})
					data.push(row)
				}
      }

      let sheets = [{
        name: 'Work Items',
        data
      }]

      let conditionLength = (i) => {
        return i == 0 ? 1 : 1.2;
      };

      this.createExcel(props, sheets, conditionLength);
    },
	},
}
</script>

<style lang="scss" scoped>
.quick-add{
  background: var(--v-background-lighten2) !important;
  text-transform: none;
  padding: 0 8px;
}
::v-deep .v-data-table tbody tr {
  cursor: pointer;
}
.status-container{
  box-shadow: 0 0 5px #888;
  display: inline-block;
  min-height: 30px;
  max-height: 60px;
  width: 145px;
  color: white;
  overflow: hidden;
  margin: 5px 0;
  border-radius: 5px;
  padding: 5px 7px;
}
.tags-container{
  max-height: 400px;
  overflow-y: scroll;
}
.oneline-ellipsis {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.oneline{
  line-height: 48px;
  height: 48px;
  overflow: hidden;
  text-overflow: ellipsis;
}
.excel-btn{
  background: #21a366;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  height: 39px;
  width:  39px;
  color: white;
  border-radius: 50%;
  cursor: pointer;
  box-shadow: 0 2px 5px #888;
  
  img{
    filter: invert(99%) sepia(7%) saturate(35%) hue-rotate(255deg) brightness(110%) contrast(99%);
    width: 28px;
  }
}
.clear-select-container {
	padding: 0 23px;
}
.toggleSelect {
  display: flex;
  gap: 10px;
  justify-content: flex-start;
  text-decoration: underline !important;
}
</style>