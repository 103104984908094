import moment from 'moment'

export default function CumulativeFlowDiagram(
		initialDate,
		finalDate,
		type,
		timeUnit,
		allWorkItems,
		isNullDate,
		setCategories,
		handleZoom,
	) {

	allWorkItems = allWorkItems
		.filter(wi => wi.type == type)
		.map(wi => {
			wi.initiatedAt = moment(wi.initiatedAt)
			wi.createdAt = moment(wi.createdAt)
			wi.finishedAt = moment(wi.finishedAt)
			return wi
		})
	
	const getWorkItemsData = conditions => {
		let categories = []
		let current = moment(initialDate)
		let dataWorkItems = []
	
		while(1){
			if(timeUnit == 'Days'){
	
				categories.push({ display: current.format('DD MMM'), date: current.format('YYYY-MM-DD') })
	
				let workItems = allWorkItems.filter( wi => conditions[0](wi, current) ).length
				dataWorkItems.push(workItems)
	
				if(current.add(1, 'day') > moment(finalDate))
					break
	
			} else if(timeUnit == 'Weeks'){
	
				categories.push({ display: current.isoWeek(), date: current.format('YYYY-MM-DD') })
	
				let workItems = allWorkItems.filter( wi =>  conditions[1](wi, current) ).length
				dataWorkItems.push(workItems)
	
				if(current.add(1, 'isoweek') >= moment(finalDate)){
					if(categories[categories.length-1].display != moment(finalDate).isoWeek()){
						current = moment(finalDate)
						categories.push({ display: current.isoWeek(), date: current.format('YYYY-MM-DD') })
						workItems = allWorkItems.filter( wi =>  conditions[1](wi, current) ).length
						dataWorkItems.push(workItems)
					}
					break
				}
	
			} else if(timeUnit == 'Months'){
	
				categories.push({ display: current.format('MMM'), date: current.format('YYYY-MM-DD') })
	
				let workItems = allWorkItems.filter( wi =>  conditions[2](wi, current) ).length
				dataWorkItems.push(workItems)
	
				if(current.add(1, 'month') >= moment(finalDate)){
					if(categories[categories.length-1].display != moment(finalDate).format('MMM')){
						categories.push({ display: moment(finalDate).format('MMM'), date: current.format('YYYY-MM-DD') })
						workItems = allWorkItems.filter( wi =>  conditions[2](wi, current) ).length
						dataWorkItems.push(workItems)
					}
					break
				}
	
			} else {
				return
			}
		}
	
		return { categories, dataWorkItems }
	}

	const conditions = {
		toDo: {
			day: (wi, current) =>
				wi.createdAt < current ||
				wi.createdAt.isSame(current, 'day'),
			week: (wi, current) =>
				wi.createdAt < current ||
				wi.createdAt.isSame(current, 'isoweek'),
			month: (wi, current) => 
				wi.createdAt < current ||
				wi.createdAt.isSame(current, 'month'),
		},
		inProgress: {
			day: (wi, current) =>
				!isNullDate(wi.initiatedAt) && (
					wi.initiatedAt < current ||
					wi.initiatedAt.isSame(current, 'day')
				),
			week: (wi, current) =>
				!isNullDate(wi.initiatedAt) && (
					wi.initiatedAt < current ||
					wi.initiatedAt.isSame(current, 'isoweek')
				),
			month: (wi, current) => 
				!isNullDate(wi.initiatedAt) && (
					wi.initiatedAt < current ||
					wi.initiatedAt.isSame(current, 'month')
				),
		},
		done: {
			day: (wi, current) => 
				!isNullDate(wi.finishedAt) && (
					wi.finishedAt < current ||
					wi.finishedAt.isSame(current, 'day')
				),
			week: (wi, current) =>
				!isNullDate(wi.finishedAt) && (
					wi.finishedAt < current ||
					wi.finishedAt.isSame(current, 'isoweek')
				),
			month: (wi, current) => 
				!isNullDate(wi.finishedAt) && (
					wi.finishedAt < current ||
					wi.finishedAt.isSame(current, 'month')
				)
		},
	}

	let res = getWorkItemsData([
		(wi, current) => conditions.toDo.day(wi, current),
		(wi, current) => conditions.toDo.week(wi, current),
		(wi, current) => conditions.toDo.month(wi, current),
	])
	let categories = res.categories
	setCategories(categories, true)
	let toDoWorkItems = res.dataWorkItems

	
	res = getWorkItemsData([
		(wi, current) => conditions.inProgress.day(wi, current) || conditions.done.month(wi, current),
		(wi, current) => conditions.inProgress.week(wi, current) || conditions.done.month(wi, current),
		(wi, current) => conditions.inProgress.month(wi, current) || conditions.done.month(wi, current),
	])
	let inProgressWorkItems = res.dataWorkItems

	res = getWorkItemsData([
		(wi, current) => conditions.done.day(wi, current),
		(wi, current) => conditions.done.week(wi, current),
		(wi, current) => conditions.done.month(wi, current),
	])
	let doneWorkItems = res.dataWorkItems

	let numberArray = []
	for(let i = 0; i < categories.length; i++){
		numberArray.push(i)
	}

	let options = {
		chart: {
			zoomType: 'x',
			resetZoomButton: {
				theme: {
					display: 'none'
				},
			}
		},
		title: {
			text: '',
		},
		xAxis: {
			categories: categories.map(c => c.display),
			events: {
				setExtremes: handleZoom,
			},
		},
		yAxis: [
			{
				title: {
					text: 'Work Items',
					style: {
						fontSize: '23px',
					}
				},
				min: 0
			},
		],
		plotOptions: {
			area: {
				fillOpacity: 0.6,
			}
		},
		series: [
			{
				type: 'area',
				name: 'To Do',
				data: toDoWorkItems,
				marker: {
					radius: 4
				},
				lineWidth: 3,
			},
			{
				type: 'area',
				name: 'In Progress',
				data: inProgressWorkItems,
				marker: {
					radius: 4
				},
				lineWidth: 3,
			},
			{
				type: 'area',
				name: 'Done',
				data: doneWorkItems,
				marker: {
					radius: 4
				},
				lineWidth: 3,
			},
		],
		colors: ['#fcc674', '#8180fb', '#f57b83', '#7ab06e', '#1aadce',
        '#492970', '#f28f43', '#77a1e5', '#c42525', '#a6c96a']
	}
	return options
}