<template>
	<div :class="{fullscreen: activeFullscreen}" class="ma-2">
		<v-btn @click="toggleFullscreen" color="primary" class="float-right" fab small><v-icon>mdi-fullscreen</v-icon></v-btn>
		<q-date-picker
		 	v-model="startDate" @change="getIndicators"
			class="inline-block" label="Start date"
			outlined inner dense :min="lowerLimit" :max="upperLimit"
		/>
		<q-date-picker
		 	v-model="endDate" @change="getIndicators"
			class="inline-block ml-4" label="End date"
			outlined inner dense :min="startDate" :max="upperLimit"
		/>

		<v-row>
			<v-col>
				<div class="indicator elevation-2">
					<v-icon
						:style="{background: $vuetify.theme.isDark ? '' : '#e8f2fb'}"
						color="#167bd7"
					> insert_comment </v-icon>
					<div> <h2>{{ createdUserStoriesQuantity }}</h2> <span>Created US</span> </div>
				</div>
			</v-col>
			<v-col>
				<div class="indicator elevation-2">
					<v-icon
						:style="{background: $vuetify.theme.isDark ? '' : '#ecf8ed'}"
						color="success"
					> mdi-play-circle-outline </v-icon>
					<div> <h2>{{ startedUserStoriesQuantity }}</h2> <span>Started US</span> </div>
				</div>
			</v-col>
			<v-col>
				<div class="indicator elevation-2">
					<v-icon
						:style="{background: $vuetify.theme.isDark ? '' : '#ecf8ed'}"
						color="success"
					> mdi-check-circle </v-icon>
					<div> <h2>{{ finishedUserStoriesQuantity }}</h2> <span>Finished US</span> </div>
				</div>
			</v-col>
			<v-col>
				<div class="indicator elevation-2 relative">
					<v-icon
						:style="{background: $vuetify.theme.isDark ? '' : '#fde8e8'}"
						color="error"
					> mdi-bug </v-icon>
					<div>
						<h2>{{ reportedBugsByDefect }}</h2>
						 <span>{{ currentDefectOriginName.name }} bugs</span>
					</div>
					<v-menu
					>
						<template v-slot:activator="{ on, attrs }">
							<v-icon
							 class="source-bug-select"
								v-bind="attrs"
								v-on="on"
							>
							mdi-chevron-down
							</v-icon>
						</template>

						<v-list>
							<v-list-item
								v-for="(item, index) in config.defectsOrigins"
								:key="index"
								@click="showDefectOrigin = item.id"
							>
								<v-icon
									color="primary"
									v-show="showDefectOrigin === item.id"
								>
								star
								</v-icon>
								<v-icon
									v-show="showDefectOrigin !== item.id"
								>
									mdi-star-outline
								</v-icon>
								<v-list-item-title> {{item.name}} : {{getDefectsFound(item.id)}}</v-list-item-title>
							</v-list-item>
						</v-list>
					</v-menu>
				</div>
			</v-col>
			<v-col>
				<div class="indicator elevation-2">
					<v-icon
						:style="{background: $vuetify.theme.isDark ? '' : '#fde8e8'}"
						color="error"
					> mdi-alert </v-icon>
					<div> <h2>{{ risksFoundQuantity }}</h2> <span>Active Risks</span> </div>
				</div>
			</v-col>
			<v-col>
				<div class="indicator elevation-2">
					<v-icon
						:style="{background: $vuetify.theme.isDark ? '' : '#fff7ee'}"
						color="#fcb866"
					> mdi-timer-outline </v-icon>
					<div> <h2>{{ getIndicatorTime(leadTime / finishedUserStoriesQuantity, false, project.usingWorkingHours) }}</h2> <span>Lead Time</span> </div>
				</div>
			</v-col>
		</v-row>

		<v-card class="mt-5">
			<v-card-title class="py-5" style="justify-content: space-between; border-bottom: 1px solid var(--v-lightgray-base)">
				<h3 class="inline-block">Kanban Board</h3>
				
				<div style="margin-top: -7px; display: flex; align-items: center;">
					<v-menu v-show="timelapseView" open-on-hover bottom :close-on-content-click="false" transition="slide-y-transition" :nudge-bottom="36">
						<template #activator="{ on: menu, attrs }">
							<v-tooltip top>
								<template #activator="{ on: tooltip }">
									<v-btn class="mr-2" icon v-bind="attrs" v-on="createListeners(menu, tooltip)">
										<v-icon>mdi-clock-fast</v-icon>
									</v-btn>
								</template>
								Velocity
							</v-tooltip>
						</template>
						<div class="background" style="overflow: hidden;">
							<v-slider v-model="timelapseVelocity" max="10" min="1" vertical/>
						</div>
					</v-menu>
					
					<v-tooltip top>
						<template v-slot:activator="{ on }">
							<div v-on="on" @click="switchView" class="board-switch rounded cursor-pointer">
								<div class="icon-container rounded" :class="{selected: !timelapseView}">
									<v-icon>mdi-dns</v-icon>
								</div>
								<div class="icon-container rounded" :class="{selected: timelapseView}">
									<v-icon>mdi-clock-time-four-outline</v-icon>
								</div>
							</div>
						</template>
						{{timelapseView ? 'Timelapse' : 'Manual'}}
					</v-tooltip>
				</div>
			</v-card-title>
			<v-card-text class="pt-2 pb-0">
				<div class="text-right mb-1">
					<div v-if="!timelapseView" class="float-left pt-2">
						<h3>
							Version:
							<span v-if="isToday">Latest</span>
							<span v-else>{{currentDate.format('MMM D, YYYY')}}</span>
						</h3>
					</div>
					<v-tooltip top>
						<template v-slot:activator="{ on }">
							<v-btn v-on="on" @click="sliderVal--" :disabled="sliderVal == 0" icon><v-icon>mdi-chevron-left</v-icon></v-btn>
						</template>
						Previous {{timelapseView ? '' : 'day'}}
					</v-tooltip>
					<v-tooltip top>
						<template v-slot:activator="{ on }">
							<v-btn v-on="on" v-show="timelapseView" @click="repeatTimelapse = !repeatTimelapse" icon :color="repeatTimelapse ? 'primary' : ''"><v-icon>mdi-sync</v-icon></v-btn>
						</template>
						Repeat
					</v-tooltip>
					<v-tooltip top>
						<template v-slot:activator="{ on }">
							<v-btn v-on="on" v-show="timelapseView && !timelapsePlaying" @click="toggleTimelapse(true)" icon><v-icon>mdi-play</v-icon></v-btn>
						</template>
						Play
					</v-tooltip>
					<v-tooltip top>
						<template v-slot:activator="{ on }">
							<v-btn v-on="on" v-show="timelapseView && timelapsePlaying" @click="toggleTimelapse(false)" icon><v-icon>mdi-pause</v-icon></v-btn>
						</template>
						Pause
					</v-tooltip>
					<v-tooltip top>
						<template v-slot:activator="{ on }">
							<v-btn v-on="on" v-show="timelapseView" @click="sliderVal = 0" icon><v-icon>mdi-restore</v-icon></v-btn>
						</template>
						Restart
					</v-tooltip>
					<v-tooltip top>
						<template v-slot:activator="{ on }">
							<v-btn v-on="on" @click="sliderVal++" :disabled="sliderVal == totalUnits" icon><v-icon>mdi-chevron-right</v-icon></v-btn>
						</template>
						Next {{timelapseView ? '' : 'day'}}
					</v-tooltip>
				</div>
				<div class="slider-container">
					Start
					<v-slider
						v-model="sliderVal" hide-details
						:max="totalUnits" :min="0"
						thumb-label
						class="slider"
					>
						<template v-slot:thumb-label>
							{{currentDate.format('MMM D, YY')}}
						</template>
					</v-slider>
					End
				</div>

				<div class="board-container" :style="{width: activeFullscreen ? 'calc(100vw - 50px)' : 'calc(100vw - 204px)'}">
					<v-row align="stretch" class="board flex-nowrap" id="board">
						<v-col v-for="(s, ix) in shownStatuses" :key="ix" class="column pa-1">
							<v-btn
								block dark
								:color="s.color" :style="{opacity: $vuetify.theme.isDark ? .9 : 1}"
								class="mr-2 text-capitalize status-button-title" style="padding: 0 5px 0 12px;"
							>
								<v-icon left>{{s.iconClass}}</v-icon>
								<span class="status-name">{{s.name}}</span>
							</v-btn>
							<v-tooltip right  v-for="(wi) in s.workItems" :key="wi.id" >
								<template #activator="{ on }">
									<div
										@click="getColorBySprint(wi.sprintId)"
										v-on="on"
										class="workitem rounded elevation-2 my-3 cursor-pointer"
										:style="{	
											background: getColorBySprint(wi.sprintId) ? getColorBySprint(wi.sprintId) : 'var(--v-background-lighten)',
											color: getColorBySprint(wi.sprintId) ? isBlackText(getColorBySprint(wi.sprintId)) ? 'white' : 'black' : ''
										}"
									>
										<h2>{{ wi.code }}</h2>
									</div>
								</template>
								<span>{{ wi.name }}</span>
							</v-tooltip>
						</v-col>
					</v-row>
				</div>

			</v-card-text>
		</v-card>

		<v-row class="mt-5">
			<v-col :cols="12" :md="8">
				<div class="transactions-title">
					<h2>Transactions</h2>
					<div :style="{ 'display': 'flex' }">
						<v-autocomplete
							label="Leave (status)" item-value="id" item-text="name"
							v-model="transactionsFilters.fromStatuses" :items="shownStatuses"
							outlined multiple hide-details dense
						>
							<template v-slot:item="{ item }">
								<v-chip class="my-2 cursor-pointer" label :style="{ width: '100%' }" dark :color="item.color">
									<v-icon class="mr-2">{{item.iconClass}}</v-icon>
									{{item.name}}
								</v-chip>
							</template>
							<template v-slot:selection="{ item, index }">
								<v-chip 
									v-if="index === 0"
									label dark
									class="mt-2 cursor-pointer"
									:color="item.color"
								>
									<v-icon class="mr-2" style="font-size: 1.3em">{{item.iconClass}}</v-icon>
									{{item.name[0]}}
								</v-chip>
								<span
									v-if="index === 1"
									class="grey--text text-caption mt-2 ml-1"
								>
									(+{{ transactionsFilters.fromStatuses.length - 1 }})
								</span>
							</template>
						</v-autocomplete>
						<v-autocomplete
							label="Arrive to" item-value="id" item-text="name" class="ml-2"
							v-model="transactionsFilters.toStatuses" :items="shownStatuses"
							outlined multiple hide-details dense
						>
							<template v-slot:item="{ item }">
								<v-chip class="my-2 cursor-pointer" label :style="{ width: '100%' }" dark :color="item.color">
									<v-icon class="mr-2">{{item.iconClass}}</v-icon>
									{{item.name}}
								</v-chip>
							</template>
							<template v-slot:selection="{ item, index }">
								<v-chip 
									v-if="index === 0"
									label dark
									class="mt-2 cursor-pointer"
									:color="item.color"
								>
									<v-icon class="mr-2" style="font-size: 1.3em">{{item.iconClass}}</v-icon>
									{{item.name[0]}}
								</v-chip>
								<span
									v-if="index === 1"
									class="grey--text text-caption mt-2 ml-1"
								>
									(+{{ transactionsFilters.fromStatuses.length - 1 }})
								</span>
							</template>
						</v-autocomplete>
					</div>
				</div>
				<div v-for="(transaction, ix) in shownTransactions.slice(0, shownTransactionsQuantity)" :key="ix" class="transaction elevation-2 rounded pa-5 my-3">
					<div class="workitem-info">
						<h3> {{transaction.workItemCode}} </h3>
						{{transaction.workItemName}}
					</div>
					<div class="statuses-info text-right">
						<v-btn
							:color="transaction.fromStatus.color" :style="{opacity: $vuetify.theme.isDark ? .9 : 1}"
							class="text-capitalize status-button-title" style="padding: 0 5px 0 12px;" dark depressed
						>
							<v-icon left>{{transaction.fromStatus.iconClass}}</v-icon>
							<span class="status-name">{{transaction.fromStatus.name}}</span>
						</v-btn>
						<span class="mx-2">to</span>
						<v-btn
							:color="transaction.toStatus.color" :style="{opacity: $vuetify.theme.isDark ? .9 : 1}"
							class="text-capitalize status-button-title" style="padding: 0 5px 0 12px;" dark depressed
						>
							<v-icon left>{{transaction.toStatus.iconClass}}</v-icon>
							<span class="status-name">{{transaction.toStatus.name}}</span>
						</v-btn>
						<div class="date mt-2">
							{{transaction.date.format('MMM DD [at] hh:mm A')}}
						</div>
					</div>
				</div>
				<v-btn v-if="shownTransactions.length > 4" @click="showMoreTransactions" block text color="primary">
					{{shownTransactionsQuantity >= shownTransactions.length ? 'Show less' : 'Show more'}}
				</v-btn>
				<v-btn v-if="shownTransactions.length > shownTransactionsQuantity" @click="showAllTransactions" block text color="primary">
					Show All
				</v-btn>
			</v-col>

			<v-col :cols="12" :md="4">
				<v-btn @click="addHighlight" icon class="float-right" color="primary">
					<v-icon>mdi-plus-circle-outline</v-icon>
				</v-btn>
				<h2>Highlights</h2>
				<div v-for="(highlight, ix) in shownHighlights" :key="ix" class="highlight elevation-2 pa-3 rounded my-3">
					<template v-if="!highlight.isNew && !highlight.editting">
						<v-btn
							@click="$set(highlight, 'editting', true)" right absolute icon
							color="primary" class="mr-9 mt-n3 hide"
						><v-icon>mdi-pencil</v-icon></v-btn>
						<v-btn
							@click="deleteHighlight(highlight)" right absolute icon
							color="error" class="mr-3 mt-n3 hide"
						><v-icon>mdi-delete</v-icon></v-btn>
						<div class="multiline"> {{highlight.description}} </div>
						<span>{{highlight.date | formatDate}} by {{highlight.createdByBlueTag}}</span>
					</template>
					<template v-else>
						<v-textarea
							v-model.trim="highlight.description" outlined hide-details single-line rows="2"
							label="Add new highlight"
						/>
						<v-row>
							<v-col>
								<q-date-picker
									v-model="highlight.date" class="mt-2 inline-block"
									outlined inner dense hide-details
									:rules="[v => !!v || 'Required']"
								/>
							</v-col>
							<v-col class="new-highlight-btn">
								<v-btn @click="saveHighlight(highlight)" color="primary" fab x-small :disabled="!highlight.description"><v-icon>mdi-check</v-icon></v-btn>
								<v-btn
									@click="highlight.isNew ? $set(highlight, 'deleted', true) : $set(highlight, 'editting', false)"
									color="primary" outlined fab x-small
								>
									<v-icon>mdi-close</v-icon>
								</v-btn>
							</v-col>
						</v-row>
					</template>
				</div>
			</v-col>

			<v-col :cols="12">
				<h2>
					Tasks
					<v-btn @click="openTaskDialog" icon color="primary" class="mt-n1 ml-n1">
						<v-icon>mdi-plus-circle-outline</v-icon>
					</v-btn>
				</h2>
				<v-row class="mb-3">
					<v-col v-for="(task, ix) in shownTasks" :key="ix" :cols="6" :md="4" class="pb-0">
						<div class="task elevation-2 pa-3 pb-0 rounded my-3">
							<user-avatar :userId="task.assignedToId"></user-avatar>
							<div>
								<v-btn
									@click="editTask(task)" right absolute icon
									color="primary" class="mr-n3 mt-n3 hide"
								><v-icon>mdi-pencil</v-icon></v-btn>
								<b>
									{{task.assignedToBlueTag || task.assignedToFullName || 'Unassigned'}}
								</b>
								<div class="multiline mb-2"> {{task.name}} </div>
								
								<span v-if="task.dueDate">
									<b> Due: {{task.dueDate | formatDate}} </b>
								</span> <br>
								<span>
									Created by {{task.createdByBlueTag || task.createdByFullName}}
									on {{task.createdAt | formatDate}}
								</span> <br>

								<v-chip class="mt-2" :color="task.statusColor" outlined>
									<v-icon class="mr-1">{{task.statusIconClass}}</v-icon>{{task.statusName}}
								</v-chip>
							</div>
						</div>
					</v-col>
				</v-row>
			</v-col>
		</v-row>
		<v-row class="mx-0 mt-4">
			<h2 >
				Risks
			</h2>
			<h2>
				<v-btn @click="openCreateRiskDialog" icon color="primary" class="mt-n1 ml-1">
					<v-icon>mdi-plus-circle-outline</v-icon>
				</v-btn>
			</h2>
		</v-row>
		<v-row>
			<project-risk-management 
				:canEditProject="true"
				:overviewView="true"
				:id="id"
				ref="projectRiskManagement"
				:startDate="startDate"
				:endDate="endDate"
			>
			</project-risk-management>
		</v-row>

		<v-dialog v-model="taskDialog" v-if="taskDialog" persistent width="450">
			<v-card class="pb-1">
				<v-card-title>
					New task
					<v-spacer></v-spacer>
					<div v-if="!taskStatuses || !taskStatuses.length">
						<v-tooltip top>
							<template #activator="{ on }">
								<v-icon v-on="on" class="mr-1" color="#cc3300">warning</v-icon>
							</template>
							<div class="text-center">
								It is necessary to have at least <br> one status of type task
							</div>
						</v-tooltip>
					</div>
					<v-select
						label="Status" v-model="newTask.statusId" item-value="id" item-text="name"
						:items="taskStatuses" single-line filled hide-details dense
						class="float-right inline-block" style="width: 180px; flex: none"
					>
						<template v-slot:item="{ item }">
							<v-chip class="cursor-pointer my-1" label :style="{ width: '100%' }" dark :color="item.color">
								<v-icon class="mr-2">{{item.iconClass}}</v-icon>
								{{item.name}}
							</v-chip>
						</template>
						<template v-slot:selection="{ item }">
							<v-icon :color="item.color" class="mr-2">{{item.iconClass}}</v-icon>
							{{item.name}}
						</template>
					</v-select>
				</v-card-title>
				<v-card-text>
					<q-user-autocomplete
						v-model="newTask.assignedToId" :rules="[v => !!v || 'Required']"
						:items="users" label="Responsible" :confirmable="false" class="mb-2" outlined
					/>
					<v-textarea
						v-model.trim="newTask.name" outlined hide-details single-line
						:rules="[v => !!v || 'Required']" label="Add new task"
					/>
					<q-date-picker
						v-model="newTask.dueDate" outlined dense inner single-line hide-details
						class="mt-2" label="Due Date"
					/>
				</v-card-text>
				<v-card-actions>
					<v-btn @click="taskDialog = false" width="50%" color="primary" outlined>cancel</v-btn>
					<v-btn
						@click="saveTask" :disabled="!newTask.name || !newTask.assignedToId || !newTask.statusId"
						width="50%" color="primary" depressed
					>save</v-btn>
				</v-card-actions>
			</v-card>
		</v-dialog>

    <work-item-details-dialog ref="workItemDetailsDialog" :socket="socket" :isPersonal="false"></work-item-details-dialog>
	</div>
</template>

<script>
import _ from 'lodash'
import moment from "moment"
import WorkItemDetailsDialog from "./../../dialogs/WorkItemDetailsDialog"
import ProjectRiskManagement from "./ProjectRiskManagement.vue"

export default {
	props: ["id", 'socket'],
	components: { WorkItemDetailsDialog, ProjectRiskManagement },
	data: () => ({
		project: {},
		users: [],
		config: { workItemStatuses: [], defectsOrigins: [],},
		workItems: [],
		risks: [],
		activeFullscreen: false,
		startDate: moment().subtract(1, 'week').format('YYYY-MM-DD'),
		endDate: moment().format('YYYY-MM-DD'),
		totalDays: 0,
		sliderVal: 0,
		lowerLimit: '',
		upperLimit: moment().format('YYYY-MM-DD'),
		createdUserStoriesQuantity: 0,
		startedUserStoriesQuantity: 0,
		finishedUserStoriesQuantity: 0,
		reportedBugsQuantity: 0,
		risksFoundQuantity: 0,
		leadTime: 0,

		timelapseView: false,
		timelapsePlaying: false,
		timelapseVelocity: 1,
		repeatTimelapse: false,
		totalChanges: 0,
		allChanges: [],

		shownTransactionsQuantity: 4,
		highlightDialog: false,
		highlights: [],
		newHighlight: {},
		taskDialog: false,
		tasks: [],
		newTask: {},
		taskStatuses: [],
		showDefectOrigin: null,
		transactionsFilters: { 
			fromStatuses: [],
			toStatuses: [],
		},
	}),
	mounted(){
    this.$q.log(1, 19, 3, this.id, this.$parent.item.id)
		this.setUserPreferences("ProjectOverview", "showDefectOrigin")
		this.init()
    this.signalRConnection()
		this.playTimelapse()
		this.activeFullscreen = document.fullScreen || document.mozFullScreen || document.webkitIsFullScreen
		document.documentElement.addEventListener('fullscreenchange', (e) => {
			this.activeFullscreen = document.fullScreen || document.mozFullScreen || document.webkitIsFullScreen
		})
	},
  beforeDestroy(){
    this.socket.invoke('LeaveGroup', this.id)
  },
	watch: {
    'socket.state'(){
      if(this.socket.state == 'Connected'){
        this.signalRConnection()
      }
    },
	},
	computed: {
		shownTasks(){
			let startDate = moment(this.startDate)
			let endDate = moment(this.endDate)
			return this.tasks
				.filter(x =>
					moment(x.createdAt).isBetween(startDate, endDate, 'day', '[]') ||
					(!x.statusIsFinal && endDate.isSameOrAfter(x.createdAt, 'day'))
				)
		},
		shownTransactions() {
			let transactions = this.transactions
			if(this.transactionsFilters.fromStatuses.length && this.transactionsFilters.toStatuses.length) {
				let transactionsFromStatuses = _.cloneDeep(transactions).filter(transaction => this.transactionsFilters.fromStatuses.includes(transaction.fromStatusId))
				let transactionsToStatuses = _.cloneDeep(transactions).filter(transaction => this.transactionsFilters.toStatuses.includes(transaction.toStatusId))
				transactions = _.unionBy(transactionsFromStatuses, transactionsToStatuses, 'workItemId')
			} 
			else {
				if(this.transactionsFilters.fromStatuses.length) {
					transactions = transactions.filter(transaction => this.transactionsFilters.fromStatuses.includes(transaction.fromStatusId))
				}
				if(this.transactionsFilters.toStatuses.length) {
					transactions = transactions.filter(transaction => this.transactionsFilters.toStatuses.includes(transaction.toStatusId))
				}
			}
			return transactions
		},
		reportedBugsByDefect() {
			if(this.showDefectOrigin) {
				return this.getDefectsFound(this.showDefectOrigin)
			}
			return this.reportedBugsQuantity
		},
		currentDefectOriginName() {
			if(this.config.defectsOrigins?.length) {
				return this.config.defectsOrigins.find(defect => defect.id == this.showDefectOrigin) || { name: "Reported" }
			}
			return { name: "Reported" }	
		},
		currentDate(){
			return moment(this.startDate).add(this.sliderVal, 'day')
		},
		isToday(){
			return this.currentDate.isSame(moment(), 'day')
		},
		totalUnits(){
			return this.timelapseView ? this.totalChanges : this.totalDays
		},
		shownHighlights(){
			let startDate = moment(this.startDate)
			let endDate = moment(this.endDate)
			return this.highlights
				.filter(h => !h.deleted &&
					(moment(h.date).isBetween(startDate, endDate, 'day', '[]') || h.isNew || h.editting)
				)
				.sort((a, b) => {
					if(a.isNew == b.isNew)
						return moment(a.date).isBefore(b.date, 'day') ? -1 : 1
					
					if(a.isNew) return -1
					else return 1
				})
		},
		shownStatuses(){
			let startDate = moment(this.startDate)
			let endDate = moment(this.endDate)
			let statuses = this.config.workItemStatuses.filter(s => s.workItemType == 0)
			let workItems = []

			if(this.timelapseView){
				for(let wi of this.workItems.filter(wi =>
					wi.type == 0 &&
					wi.cardColumns.some(cc => cc.startedAt.isBetween(startDate, endDate, 'day', '[]'))
				)){
					this.setCurrentStatus(wi, startDate)
					for(let change of this.allChanges.slice(0, this.sliderVal)){
						if(change.workItemId == wi.id){
							wi.curStatus = change.projectWorkItemStatusId
						}
					}
					workItems.push(wi)
				}
			}
			else{
				for(let wi of this.workItems.filter(wi =>
					wi.type == 0 &&
					wi.cardColumns.some(cc => cc.startedAt.isBetween(startDate, endDate, 'day', '[]'))
				)){
					this.setCurrentStatus(wi, this.currentDate)
					if(wi.curStatus){
						workItems.push(wi)
					}
				}
			}

			for(let status of statuses){
				status.workItems = workItems.filter(wi => wi.curStatus == status.id).reverse()
			}

			return statuses
		},
		transactions(){
			let transactions = {}
			let startDate = moment(this.startDate)
			for(let change of this.allChanges){
				let workItem = this.workItems.find(wi => wi.id == change.workItemId)
				this.setCurrentStatus(workItem, startDate)
				if(!transactions[change.workItemId]){
					let statusId = workItem.curStatus || change.projectWorkItemStatusId
					transactions[change.workItemId] = {
						workItemId: change.workItemId,
						workItemCode: change.workItemCode,
						workItemName: change.workItemName,
						fromStatusId: statusId,
						fromStatus: this.config.workItemStatuses.find(s => s.id == statusId) || {},
					}
				}
				transactions[change.workItemId] = {
					...transactions[change.workItemId],
					toStatusId: change.projectWorkItemStatusId,
					toStatus: this.config.workItemStatuses.find(s => s.id == change.projectWorkItemStatusId) || {},
					date: change.startedAt,
				}
			}

			return _.toArray(transactions)
				.filter(t => t.fromStatusId != t.toStatusId)
				.sort((a, b) => {
					return moment(a.date).isBefore(b.date) ? 1 : -1
				})
		},
	},
	methods: {
		getColorBySprint(id) {
			let sprint = this.config.sprints.find(s => s.id == id)
			if (sprint?.color)
        return sprint.color
			return null
		},
		getDefectsFound(id) {
			let startDate = moment(this.startDate)
			let endDate = moment(this.endDate)
			return this.workItems.filter(wi => 
				wi.type == 1 && 
				wi.defectOriginId && 
				wi.defectOriginId == id && 
				wi.createdAt.isSameOrAfter(startDate, 'day') && 
				wi.createdAt.isSameOrBefore(endDate, 'day')
			).length || 0
		},
		openCreateRiskDialog() {
      if (!this.id) return
      this.$refs.projectRiskManagement.editFromTable();
		},
		init(){
			let projectPr = this.$http.get(`/api/project/${this.id}`)
				.then(res => {
					this.project = res.data
				})

			let workItemsPr = this.$http.get(`/api/project/${this.id}/workItem?includeReporter=true`)
        .then(res => {
					let workItems = res.data.filter(wi => wi.type == 0 || wi.type == 1)
					for(let wi of workItems) {
						wi.createdAt = moment(wi.createdAt)
						wi.initiatedAt = moment(wi.initiatedAt)
						wi.finishedAt = moment(wi.finishedAt)

						for(let cc of wi.cardColumns)
							cc.startedAt = moment(cc.startedAt)
						wi.cardColumns = wi.cardColumns.sort((a, b) => a.startedAt.isSameOrBefore(b.startedAt) ? -1 : 1)
						if(!this.lowerLimit || wi.createdAt.isBefore(this.lowerLimit, 'day'))
							this.lowerLimit = wi.createdAt.format('YYYY-MM-DD')
					}
          this.workItems = workItems.sort((a, b) => {
            if(a.createdAt < b.createdAt)
              return -1
            return 1
          })
        })
        .catch(res => {
          window.getApp.error("Cannot obtain work items.")
          console.log(res)
        })
			
			let risksPr = this.$http.get(`api/project/risks?id=${this.id}`)
				.then(res => {
					let risks = res.data
					for(let r of risks){
						r.createdAt = moment(r.createdAt)
						if(r.finishedAt)
							r.finishedAt = moment(r.finishedAt)
					}
					this.risks = risks
				})
			
			this.$http.get(`/api/project/${this.id}/config?active=true`)
				.then(res => {
					this.config = res.data
					if(!this.showDefectOrigin) this.showDefectOrigin = this.config.defectsOrigins[0].id || null
					this.taskStatuses = this.config.workItemStatuses.filter(s => s.workItemType === 3)
				})

			this.$http
        .get(`/api/project/${this.id}/members`)
        .then(res => {
          let members = res.data
          this.users = members.map(member => {
            if(!member.user.blueTag)
              member.user.blueTag = member.user.fullName
            return member.user
          })
        })
        .catch(res => {
          console.log(res)
          window.getApp.error("Cannot obtain project members.")
        })

			this.$http.get(`/api/project/${this.id}/highlight`)
				.then(res => {
					this.highlights = res.data
				})

			this.$http.get(`/api/project/${this.id}/overviewTask`)
				.then(res => {
					this.tasks = res.data
				})

			Promise.all([projectPr, workItemsPr, risksPr])
				.then(this.getIndicators)
		},
		setCurrentStatus(wi, startDate){
			let piv1 = 0, piv2 = wi.cardColumns.length-1
			while(piv1 + 1 < piv2){
				let midP = Math.floor((piv1 + piv2) / 2)
				if(wi.cardColumns[midP].startedAt.isSameOrBefore(startDate, 'day')){
					piv1 = midP
				}
				else{
					piv2 = midP
				}
			}
			wi.curStatus = null
			if(wi.cardColumns[piv2].startedAt.isSameOrBefore(startDate, 'day')){
				wi.curStatus = wi.cardColumns[piv2].projectWorkItemStatusId
			}
			else if(wi.cardColumns[piv1].startedAt.isSameOrBefore(startDate, 'day')){
				wi.curStatus = wi.cardColumns[piv1].projectWorkItemStatusId
			}

		},
		getIndicators(){
			this.timelapsePlaying = false
			let startDate = moment(this.startDate)
			let endDate = moment(this.endDate)
			this.totalDays = endDate.diff(startDate, 'day')
			this.allChanges = []
			this.$nextTick(() => {
				if(this.timelapseView)
					this.sliderVal = 0
				else
					this.sliderVal = this.totalDays
			})

			if(endDate.isBefore(startDate)){
				this.endDate = this.startDate
			  endDate = moment(this.endDate)
			}

			let createdUserStoriesQuantity = 0
			let startedUserStoriesQuantity = 0
			let finishedUserStoriesQuantity = 0
			let reportedBugsQuantity = 0
			this.leadTime = 0
			for(let wi of this.workItems){
				if(wi.type == 0){
					if(wi.createdAt.isSameOrAfter(startDate, 'day') && wi.createdAt.isSameOrBefore(endDate, 'day')){
						createdUserStoriesQuantity++
					}
					if(wi.initiatedAt.isSameOrAfter(startDate, 'day') && wi.initiatedAt.isSameOrBefore(endDate, 'day')){
						startedUserStoriesQuantity++
					}
					if(wi.finishedAt.isSameOrAfter(startDate, 'day') && wi.finishedAt.isSameOrBefore(endDate, 'day')){
						finishedUserStoriesQuantity++
						this.leadTime += wi.leadTime
					}
					let currentCardColumns = wi.cardColumns
						.filter(cc => cc.startedAt.isBetween(startDate, endDate, 'day', '[]'))
						.map(cc => ({
							...cc,
							workItemCode: wi.code,
							workItemName: wi.name,
						}))
					this.allChanges = this.allChanges.concat(currentCardColumns)
				}
				if(wi.type == 1){
					if(wi.reportedByType == 'External' && wi.createdAt.isSameOrAfter(startDate, 'day') && wi.createdAt.isSameOrBefore(endDate, 'day')){
						reportedBugsQuantity++
					}
				}
			}
			this.createdUserStoriesQuantity = createdUserStoriesQuantity
			this.startedUserStoriesQuantity = startedUserStoriesQuantity
			this.finishedUserStoriesQuantity = finishedUserStoriesQuantity
			this.reportedBugsQuantity = reportedBugsQuantity
			this.allChanges = this.allChanges.sort((a, b) => a.startedAt.isSameOrBefore(b.startedAt) ? -1 : 1)
			this.totalChanges = this.allChanges.length

			this.risksFoundQuantity = this.risks.filter(r => {
				if(r.finishedAt && r.finishedAt.isSameOrBefore(startDate, 'day'))
					return false

				return r.createdAt.isSameOrBefore(endDate, 'day') && !r.createdAt.isSame(r.finishedAt, 'day')
			}).length
		},
		showMoreTransactions(){
			if(this.shownTransactionsQuantity >= this.shownTransactions.length){
				this.shownTransactionsQuantity = 4
			}
			else if(this.shownTransactionsQuantity < this.shownTransactions.length){
				this.shownTransactionsQuantity += 4
			}
		},
		showAllTransactions() {
			if(this.shownTransactionsQuantity < this.shownTransactions.length){
				this.shownTransactionsQuantity = this.shownTransactions.length
			}
		},
		saveHighlight(highlight){
			this.$q.save({
        api: `/api/project/${this.id}/highlight`,
        data: highlight,
        successMsg: 'Saved successfully',
        afterSuccessUrl: null,
        errorMsg: "Cannot save highlight.",
        afterErrorUrl: null,
      }).then((res) => {
				this.$set(highlight, 'deleted', true)
				this.highlights.push(res.data)
      })
		},
		addHighlight(){
			this.highlights.unshift({
				isNew: true,
				date: moment().format('YYYY-MM-DD'),
			})
		},
		editHighlight(highlight){
			this.highlightDialog = true
			this.newHighlight = _.clone(highlight)
		},
		deleteHighlight(highlight){
			this.$q.confirmDelete({
				api: `/api/project/${this.id}/highlight/${highlight.id}`,
				messageBody: "Are you sure you want to delete this highlight?", 
				sucessMsg: "Highlight deleted",
				errorMsg: "The highlight was not deleted.",
			})
			.then(res => {
				this.highlightDialog = false
				this.$set(highlight, 'deleted', true)
			})
		},
		saveTask(){
			let success = (res, message) => {
				if (!res.data.id) {
					window.getApp.error(res.data)
					return
				}
				window.getApp.success(message)
				this.taskDialog = false
				this.$http.get(`/api/project/${this.id}/overviewTask`)
					.then(res => {
						this.tasks = res.data
					})
			}

			if(this.newTask.id){
				this.newTask.cardColumns = []
				this.$http
					.put(`api/WorkItem/${this.newTask.id}`, this.newTask)
					.then((res) => {
						success(res, "Task updated successfully.")
					})
					.catch((response) => {
						window.getApp.error("Cannot update task.")
						console.log(response)
					})
			} else {
				this.$http
					.post(`api/WorkItem`, this.newTask)
					.then((res) => {
						success(res, "Task created successfully.")
					})
					.catch((response) => {
						window.getApp.error("Cannot create task.")
						console.log(response)
					})
			}
		},
		openTaskDialog(){
			this.taskDialog = true
			let curStatus = this.taskStatuses[0]
			let defaultStatus = this.taskStatuses.find(s => s.isDefault)
			if(defaultStatus)
				curStatus = defaultStatus

			this.newTask = {
				assignedToId: "",
				dueDate: "",
				name: "",
				priority: 3,
				value: 3,
				type: 3,
				projectId: this.$parent.item.id,
				showInOverview: true,
				statusId: curStatus.id,
			}
		},
		editTask(task){
			this.taskDialog = true
			this.newTask = _.clone(task)
		},
		switchView(){
			this.timelapseView = !this.timelapseView
			this.timelapsePlaying = false
			this.repeatTimelapse = false
			if(this.timelapseView){
				this.sliderVal = 0
			}
			else{
				this.sliderVal = this.totalDays
			}
		},
		toggleTimelapse(val){
			if(val && this.sliderVal >= this.totalUnits)
				this.sliderVal = 0
			this.timelapsePlaying = val
		},
		playTimelapse(){
			if(this.sliderVal + 1 > this.totalUnits){
				if(this.repeatTimelapse)
					this.sliderVal = 0
				else
					this.timelapsePlaying = false
			}
			else if(this.timelapsePlaying && this.timelapseView && this.sliderVal < this.totalUnits)
				this.sliderVal++
			setTimeout(() => {
				this.playTimelapse()
			}, 1100 - this.timelapseVelocity * 100)
		},
		openWorkItemDetailsDialog(id, callback) {
      let defaults = {
        projectId: this.id,
      }

			let title = document.title
      this.$refs.workItemDetailsDialog.open(id, defaults, this.socket)
        .finally(result => {
					document.title = title
					this.workItemUpdated(id)
        })
    },
		workItemUpdated(workItemId){
      this.$http.get(`api/workItem/${workItemId}`, {headers: {hideLoading: true}})
        .then(res => {
          let newWorkItem = res.data
					newWorkItem.createdAt = moment(newWorkItem.createdAt)
					newWorkItem.initiatedAt = moment(newWorkItem.initiatedAt)
					newWorkItem.finishedAt = moment(newWorkItem.finishedAt)
					for(let cc of newWorkItem.cardColumns)
						cc.startedAt = moment(cc.startedAt)
					newWorkItem.cardColumns = newWorkItem.cardColumns.sort((a, b) => a.startedAt.isSameOrBefore(b.startedAt) ? -1 : 1)

					if(!newWorkItem){
						this.workItems = this.workItems.filter(wi => wi.id != workItemId && wi.code != workItemId)
					}

					let oldWorkItem = this.workItems.find(wi => wi.id == newWorkItem.id)
					if(!oldWorkItem)
						this.workItems.push(newWorkItem)
					else{
						for(let [i, wi] of this.workItems.entries()){
							if(wi.id == newWorkItem.id){
								this.workItems[i] = newWorkItem
							}
						}
					}
					this.workItems = this.workItems.sort((a, b) => {
						if(a.createdAt < b.createdAt) return -1
						return 1
					})
					this.getIndicators()
        })
    },
    signalRConnection(){
      if(this.socket.state != 'Connected'){
        setTimeout(this.signalRConnection, 1000)
        return
      }
      
      this.socket.off('ProjectUpdate')
      this.socket.off('WorkItemDelete')
      this.socket.invoke("JoinGroup", this.id)

      this.socket.on("ProjectUpdate", this.workItemUpdated)

      this.socket.on('WorkItemDelete', workItemId => {
        this.workItems = this.workItems.filter(wi => wi.id != workItemId)
      })
    },
		toggleFullscreen(){
			if(!this.activeFullscreen) document.documentElement.requestFullscreen()
			else document.exitFullscreen()
		},
		createListeners (menu, tooltip) {
      return {
        ...menu,
        ...tooltip,
        mouseenter: e => {
          menu.mouseenter(e)
          tooltip.mouseenter(e)
        },
        mouseleave: e => {
          menu.mouseleave(e)
          tooltip.mouseleave(e)
        }
      }
		},
	},
}
</script>

<style lang="scss" scoped>
.fullscreen {
	display: inline-block;
	position: absolute;
	background: var(--v-background-base);
	left: -100px; top: -75px;
	padding: 20px;
	padding-top: 30px;
	margin: 0 !important;
	width: calc(100vw - 10px);
	min-height: 100vh;
	z-index: 201;
	
	.board {
		min-height: 62vh;
		max-height: 80vh;
	}
}

.indicator{
	display: grid;
	grid-template-columns: 50px auto;
	background: var(--v-background-lighten);
	padding: 10px;
	border-radius: 8px;
	align-items: center;

	.v-icon{
		width: 40px;
		height: 40px;
		border-radius: 50%;
	}
}

.board-switch{
	background: var(--v-background-lighten2);
	display: flex;
	gap: 3px;
	padding: 5px;

	.icon-container{
		display: flex;
		align-items: center;
		justify-content: center;
		width: 32px;
		height: 32px;
	}
	.selected{
		background: var(--v-primary-base);
		.v-icon{
			color: white;
		}
	}
}

.slider-container{
	display: flex;
	align-items: center;
	gap: 10px;
}
.slider ::v-deep .v-slider__thumb-label{
	width: 64px !important;
	transform: translateX(-50%) translateY(-50%) !important;
	border-radius: 5px !important;

	*{
		transform: none !important;
	}

	&::after{
		content: "";
		position: absolute;
		bottom: -8px;
		border-left: 8px solid transparent;
		border-right: 8px solid transparent;
		border-top: 8px solid var(--v-primary-base);
	}
}

.board-container{
	border-top: 1px solid var(--v-lightgray-base);
	overflow: scroll;
	background: var(--v-coloredBack-base);
	margin-left: -16px;
	margin-right: -32px;
	padding: 10px 0 10px 10px;

	&::-webkit-scrollbar-thumb{
		background: #AED6F1;
	}
	&::-webkit-scrollbar-track{
		background: var(--v-coloredBack-base);
	}
	&::-webkit-scrollbar-corner { background: var(--v-coloredBack-base); }
}
.board {
  min-height: 500px;
	max-height: 75vh;
  margin: 0;
}
.column {
  flex-grow: 1;
  flex-shrink: 1;
  flex-basis: 0;
  width: 200px;
  min-width: 200px;
}
.status-button-title {
  max-width: 100%;
  overflow: hidden;
  letter-spacing: 0.05em;
}
.status-name {
  white-space: normal;
  word-break: normal;
  max-height: 31px;
  text-overflow: ellipsis;
  overflow: hidden;
  margin-right: 5px;
}
.status-button-title ::v-deep .v-btn__content {
  max-width: 100%;
}
.workitem{
	height: 100px;
	display: flex;
	align-items: center;
	justify-content: center;
}

.transaction{
	display: grid;
	grid-template-columns: auto auto;
	align-items: center;
	background: var(--v-background-lighten);
	.date{
		font-size: .9em;
		color: var(--v-text-lighten2);
	}
}

.highlight {
	background: var(--v-background-lighten);
	span {
		font-size: .8em;
		color: var(--v-text-lighten2);
	}
	.hide { opacity: 0; }

	&:hover{
		.hide { opacity: 1; }
	}
	.new-highlight-btn{
		display: flex;
		justify-content: flex-end;
		align-items: flex-end;
		gap: 5px;
	}
}
.task {
	display: grid;
	grid-template-columns: 60px auto;
	position: relative;
	background: var(--v-background-lighten);
	height: 100%;

	span {
		font-size: .9em;
		color: var(--v-text-lighten2);
	}
	.hide { opacity: 0; }

	&:hover{
		.hide { opacity: 1; }
	}
}
.source-bug-select {
	position: absolute;
	right: 4px;
	top: 4px;
}
.relative {
	position: relative;
}
.transactions-title {
	display: flex;
  justify-content: space-between;
}
</style>