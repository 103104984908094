<template>
  <v-dialog
    scrollable
    v-model="dialog"
    :width="1100"
    height="auto"
  >
      <v-card >
        <div class="main-container">
          <div class="left-container py-5" :style="{ backgroundColor: 'rgba(22, 123, 215, 0.08) !important', padding: '20px' }">
            <v-form v-model="form" ref="form">
              <div class="code-summary">
                <div class="code"> 
                  {{ item.code ? `ISSUE-${item.code}` : 'New' }}
                </div>
                <q-text-field
                  placeholder="Summary" class="summary"
                  :handleEsc="true" v-model="item.name"
                  max-width="340"
                  :handleSave="true"
                  large reduce-margin :hide-details="true"
                  :rules="[v => !!v || 'Required']"
                >
                </q-text-field>
              </div>
              <v-row dense justify="center">
                <v-col class="col-login" :class="'mb-1'">
                  <span>Status</span>
                  <v-select 
                    class="mt-2"
                    outlined
                    v-model="item.statusId"
                    hide-details
                    ref="project" 
                    :persistent-hint="true"
                    :items="config.workItemStatuses" item-value="id" item-text="name"
                    :rules="[v => (!!v || v === 0) || 'Required']"
                  >
                    <template v-slot:item="{ item }">
                      <v-chip class="cursor-pointer my-2" label :style="{ width: '100%' }" dark :color="item.color">
                        <v-icon class="mr-2">{{item.iconClass}}</v-icon>
                        {{item.name}}
                      </v-chip>
                    </template>
                    <template v-slot:selection="{ item }">
                      <v-icon :color="item.color" class="mr-2">{{item.iconClass}}</v-icon>
                      {{item.name}}
                    </template>
                  </v-select>
                </v-col>
              </v-row>
              <v-row dense justify="center">
                <v-col class="col-login" :class="'mb-1'">
                  <span>Reported by*</span>
                  <q-user-autocomplete
                    v-model="item.reportedById"
                    label="Reported by*"
                    handle-esc
                    handle-save
                    hide-details
                    outlined
                    :items="users"
                    item-value="id"
                    item-text="blueTag"
                    item-subtitle="fullName"
                    :rules="rules"
                  />
                </v-col>
              </v-row>
              <v-row dense justify="center">
                <v-col class="col-login" :class="'mb-1'">
                  <span>Reported To*</span>
                  <!-- change global propertie name to reportedTo -->
                  <q-user-autocomplete
                    v-model="item.reportedToId"
                    label="Reported to*"
                    handle-esc
                    handle-save
                    hide-details
                    outlined
                    :items="users"
                    item-value="id"
                    item-text="blueTag"
                    item-subtitle="fullName"
                    :rules="rules"
                  />
                </v-col>
              </v-row>
              <v-row dense justify="center">
                <v-col class="col-login" :class="'mb-1'">
                  <span>Description</span>
                  <pre></pre>
                  <v-textarea
                    outlined
                    v-model="item.description"
                    label=""
                    :rows="2"
                  />
                </v-col>
              </v-row>
            </v-form>
            <v-card-actions class="justify-end mt-10">
              <div v-show="showWarning" class="status-warning">
                <div style="width: 25px">
                  <v-icon color="#cc3300">warning</v-icon>
                </div>
                <div class="txt">
                  It is necessary to have at least one status of type Issue
                </div>
              </div>
              <v-btn text color="primary" @click="close">Close</v-btn>
              <v-btn color="primary" @click="createScallingBinnacle" :disabled="showWarning">Save</v-btn>
            </v-card-actions>
          </div>
          <div class="right-container">
            <div class="right-title">
            <h2>Actions</h2>
            <div>
              <v-btn
                fab
                color="primary"
                @click="openCreateWorkItem"
                small
                class="ml-1"
              >
              <v-icon dark>add</v-icon>
              </v-btn>
            </div>
          </div>
            <v-list class="ml-3 mt-3">
              <LinkedWorkItemsItem
                v-for="(wi, ix) in workItems"
                :key="wi.id || ix +'22331'"
                :link="wi"
                :items="workItems"
                :deleteItem="(link, e) => deleteWorkItem(ix, wi, e)"
                @open="() => editWorkItemNotCreatedYet(wi, ix)"
              />
            </v-list>
          </div>
        </div>
        
      </v-card>
    <TaskCreateWorkItemDialog
      :projectId="projectId"
      :risks="[]"
      ref="taskCreateDialog"
    />
  </v-dialog>
</template>

<script>
import _ from "lodash"
import TaskCreateWorkItemDialog from "./TaskCreateWorkItemDialog.vue"
import LinkedWorkItemsItem from "../components/LinkedWorkItemsItem.vue"
export default {
  components: { 
    TaskCreateWorkItemDialog,
    LinkedWorkItemsItem,
  },
  props: {
    projectId: { type: String, default: '' },
    returnScaling: { type: Boolean, default: false },
  },
  data: () => ({
    dialog: false,
    rules: [v => (!!v || v === 0 || v === '') || 'Required'],
    item: {},
    action: {
      name: "",
      description: "",
      done: false,
      model: false,
    },
    reject: null,
    form: false,
    resolve: null,
    actionDialog: false,
    depressed: false,
    members: [],
    actions: [],
    status: [],
    users: [],
    config: {},
    types: {},
    workItems: [],
    healthcheckId: null,
    showWarning: false,
    workItemIssueType: 2
  }),
  computed: {
  },
  methods: {
    editWorkItemNotCreatedYet(wi, index) {
      this.$refs.taskCreateDialog.open(wi.relatedWorkItemId || null, this.item.id, _.cloneDeep(wi))
        .then(res => {
          if(wi.relatedWorkItemId) {
            this.init(this.projectId, this.item)
          }
          this.$set(this.workItems, index, res)
        })
    },
    deleteWorkItem(index, wi, e) {
      e.stopPropagation()
      if(!this.item.id) {
        this.workItems.splice(index, 1)
        return
      }
      this.$q.confirmDelete({
        api: `/api/WorkItem/unlikedDelete/${wi.relatedWorkItemId}/${this.item.id}`,
        messageBody: "Are you sure you want to unliked this action and delete it?", 
        sucessMsg: "Action deleted.",
        errorMsg: "Action was not deleted.",
      }).then(async res => {
        this.workItems.splice(index, 1)
      });
    },
    openCreateWorkItem() {
      this.$refs.taskCreateDialog.open(null, this.item.id, {})
        .then(wi => {
          if(this.item.id) {
            this.saveLink('relatesTo', wi.id)
            return
          }
          this.workItems.push(wi)
        })
    },
    saveLink(relationship, relatedWorkItemId, link = true) {
      this.$q.save({
        api: `/api/WorkItem/${this.item.id}/link/${relatedWorkItemId}/${relationship}/${true}`,
        data: null,
        successMsg: null,
        afterSuccessUrl: null,
        errorMsg: "Cannot save changes.",
        afterErrorUrl: null,
      }).then(async (res) => {
        this.item = res.data
        this.workItems = this.item.linkedWorkItems
      })
    },
    open(projectId, baseItem, healthcheckId) {
      this.workItems = []
      this.init(projectId, baseItem, healthcheckId)
      this.dialog = true
      return new Promise((resolve, reject) => {
        this.resolve = resolve
        this.reject = reject
      })
    },
    async init(projectId, baseItem, healthcheckId) {
      let id = baseItem.id || ''
      this.dialog = true
      this.projectId = projectId
      this.showWarning = false
      this.$http
        .get(`api/nucleiManagement/${projectId}/members`)
        .then((res) => {
          this.members = res.data.map((x) => 
          ({
            id: x.assignment.userId,
            blueTag: x.assignment.userBlueTag,
            fullName: x.assignment.userFullName,
            profilePictureId: x.assignment.user.profilePictureId,
          }))
        })
      this.$http
        .get(`/api/project/${projectId}/config?active=true`)
        .then(res => {
          this.config = res.data
          this.config.workItemStatuses = this.config.workItemStatuses.filter(s => s.workItemType === this.workItemIssueType)
          this.showWarning = !this.config.workItemStatuses.length
          this.$nextTick(() => this.setWorkItem(id, healthcheckId, baseItem))
        })
        .catch(res => {
          window.getApp.error("Cannot get project sprints, tags and status.")
          console.log(res)
        })
      this.$http
        .get(`/api/project/${projectId}/members`)
        .then((res) => {
          let members = res.data
          this.users = members.map((member) => {
            if (!member.user.blueTag)
              member.user.blueTag = member.user.fullName
            return member.user
          })
          this.$q.addLastItem(this.users, "Unassigned", "blueTag")
        })
        .catch((res) => {
          console.log(res)
          window.getApp.error("Cannot obtain project members.")
        })
    },
    setWorkItem(id = '', healthcheckId = null, baseItem = {}) {
      this.healthcheckId = healthcheckId
      if (id) {
        this.$http.get(`/api/workItem/${id}`)
          .then(res => {
            this.item = res.data
            this.workItems = this.item.linkedWorkItems
          })
        return
      }
      this.$refs.form.resetValidation()
      if(baseItem?.temporalId) {
        this.item = baseItem
        this.workItems = baseItem.actions
        return
      }
      this.item =  {
        temporalId: Date.now(),
        name: "",
        reportedToId: null,
        reportedById: null,
        assignedToId: null,
        projectId: this.config.id,
        type: 7, // issue
        priority: 3, // medium
        value: 3, // medium
        description: '',
        tags: [],
        statusId: this.config.workItemStatuses.find(s => s.isDefault).id,
        healthcheckId,
        isFromHealthcheck: true,

        // preserve previous data
        linkedWorkItems: [],
        boardColor: '#ffffff',
      }
      this.actions = []
    },
    async save() {
      let isItemCreated = !!this.item.id
      if(!this.form) {
        this.$refs.form.validate()
        return
      }
      if(isItemCreated) {
        this.$http.put(`api/WorkItem/${this.item.id}`, this.item)
          .then((res) => {
            if (!res.data.id) {
              window.getApp.error(res.data)
              return
            }
            window.getApp.success("Incident updated.")
            this.resolve({item: res.data, linkedWorkItems: res.data.linkedWorkItems})
            this.dialog = false
          })
          .catch((response) => {
            window.getApp.error("Cannot update incident.")
            this.reject()
            this.dialog = false
            console.error(response)
          })
      }
      else {
        let incident = this.item
        let actions = this.workItems
        actions.forEach(ac => {
          delete ac.assignedTo
          delete ac.assignedToBlueTag
          delete ac.id
          delete ac.temporalId
          delete ac.status
          delete ac.relatedWorkItemName
          delete ac.relatedWorkItemStatusColor
          delete ac.relatedWorkItemStatusIconClass
          delete ac.relatedWorkItemStatusName
          delete ac.relatedWorkItemTypeColor
          delete ac.relatedWorkItemTypeIconClass
        })
        this.$q.save({
          api: `api/WorkItems/createIncident/${this.healthcheckId}`,
          data: { incident, actions },
          successMsg: "Incident saved.",
          afterSuccessUrl: null,
          errorMsg: "Cannot save record.",
          afterErrorUrl: null,
        }).then(res => {
          console.log('returning promise resolved')
          this.resolve({item: res.data, linkedWorkItems: res.data.linkedWorkItems})
          this.dialog = false
          return
        })
        .catch(() => {
          this.reject()
          this.dialog = false
        })
        return
      }
    },
    createScallingBinnacle() {
      if(this.returnScaling && !this.healthcheckId){
        let item = _.cloneDeep(this.item)
        item.reportedBy = this.users.find(user => user.id === item.reportedById)
        item.reportedTo = this.users.find(user => user.id === item.reportedToId)
        this.resolve({ item, actions: this.workItems })
        this.dialog = false
        return
      }
      this.save()
    },
    close() {
      this.dialog = false
      this.reject()
    },
  },
}
</script>

<style scoped lang="scss">
.description ::v-deep textarea{
  margin-top: 0 !important;
}
.action-btn {
  color: var(--v-primary-base);
  &:hover {
    opacity: 0.7;
  }
}
.action-container {
  border: 1px solid var(--v-lightgray-base);
  position: relative;
  transition: all 2s;
}
.action-icon {
  position: absolute;
  top: 8px;
  right: 5px;
}
.rounded-action {
  &:first-child {
    border-radius: 10px 10px 0 0 !important;
  }
  &:last-child {
    border-radius: 0 0 10px 10px !important;
  }
  &:not(:last-child) {
    border-bottom: none !important;
  }
}
.rounded-table{
  border-radius: 10px;
}
.flex-end {
  justify-content: flex-end;
}

.user-autocomplete{
  height: 62px;
}
.user-autocomplete ::v-deep fieldset{
  border: none;
}
.main-container {
	display: flex;
	width: 1100px;
	flex-direction: row !important;
	flex-wrap: nowrap;
}
.left-container {
	width: 40%
}
.right-container {
	width: 60%;
	padding: 20px 20px 20px 0;
}
.code-summary{
  display: flex;
  align-items: center;

  .code{
    font-size: 1.2em;
    margin-top: -5px;
    margin-right: 30px;
  }
  .summary{
    flex: 1;
  }
}
.col-login {
  padding-bottom: 0px !important;
  max-height: 120px !important;

  span {
    font-weight: 400;
    color:darkgrey;
    margin-bottom: 8px;
  }
}
.right-title {
	display: flex;
	padding: 0 30px;
	justify-content: space-between;
}
.wi-container {
	width: 100%;
	display: flex;
	flex-direction: column;
	align-items: center;
	gap: 20px;
	max-height: 547px;
	padding: 20px 0;
	overflow-y: auto;
	box-sizing: border-box;
}

.status-warning{
	display: flex;
	align-items: center;
	div {
		display: inline-block;
	}
	.txt{
		margin-left: 12px;
		width: calc(100% - 45px);
		font-size: 14px;
	}
}
</style>
