<template>

  <v-dialog :fullscreen="$vuetify.breakpoint.xsOnly" scrollable persistent 
    v-model="dialog" :width="720" v-if="dialog" @keydown.esc="cancel" @click:outside="cancel">
		<v-card class="card">
			<div :style="{ display: 'block' }">
				<q-date-picker
					v-model="startDate" 
					class="inline-block ml-6 data-picker-width" label="Start date"
					outlined inner dense
					hide-details
				/>
				<q-date-picker
					v-model="endDate"
					class="inline-block ml-4 data-picker-width" label="End date"
					outlined inner dense
					hide-details
				/>
			</div>
			<v-card-title>
				<div :style="{display: 'flex', alignItems: 'center', marginRight: '10px'}">
					<div :style="`background:${status.color};`" class="status-container">
						<v-icon v-if="status.iconClass" color="white">{{status.iconClass}}</v-icon>
						{{setMaxText(status.name, 45)}}
					</div>
				</div>
				<div class="cycle-time">
					<div>
						Cycle Time:
						<span
							:style="{
								color: 
									status.cycleTimeGoal ?
										( toMinutes(status.cycleTimeGoal, status.cycleTimeGoalUnit, usingWorkingHours) < cycleTime ? 'salmon' : 'lightgreen' )
										: 'text'
							}"
						>
							{{
								!selectedUnit ? 
									status.cycleTimeGoal ? 
                    formatTimeByUnit(cycleTime, status.cycleTimeGoalUnit, usingWorkingHours) :
                    getIndicatorTime(cycleTime, false, usingWorkingHours, usingWorkingHours)
									:
									formatTime(cycleTime)
							}}
						</span>
						<span v-if="!!status.cycleTimeGoal">
							vs.
							<span>
								{{ 
									!selectedUnit ? 
										formatTimeByUnit(toMinutes(status.cycleTimeGoal, status.cycleTimeGoalUnit, usingWorkingHours), status.cycleTimeGoalUnit, usingWorkingHours) :
										formatTime(toMinutes(status.cycleTimeGoal, status.cycleTimeGoalUnit, usingWorkingHours))
								}}
							</span>
						</span>
					</div>
				</div>

				<v-btn
					class="units-selector"
					@click="selectedUnit++; selectedUnit%=units.length"
					:color="selectedUnit ? 'primary' : 'secondary'"
				>
					{{ units[selectedUnit].name }}
				</v-btn>

				<v-tooltip top>
					<template v-slot:activator="{ on }">
						<v-btn text small v-on="on" @click="cancel" class="close">
							<v-icon>close</v-icon>
						</v-btn>
					</template>
					<span>Cancel</span>
				</v-tooltip>

			</v-card-title>
			<v-card-text class="ay">
				<v-data-table
					class="colored-header"
					style="border: 1px solid #888"
					:items="columnCards"
					:headers="headersWorkItems"
					:sortable="false"
					:items-per-page="-1"
					hide-default-footer
					@click:row="clickWorkItem"
					:item-class="v => 'cursor-pointer'"
				>
					<template v-slot:[`item.code`]="{ item }">
						<div>
							{{item.workItem.code}}
						</div>
					</template>
					<template v-slot:[`item.name`]="{ item }">
						<div>
							{{item.workItem.name}}
						</div>
					</template>
					<template v-slot:[`item.time`]="{ item }">
						<div>
							{{formatTime(item.time)}}
						</div>
					</template>
				</v-data-table>
			</v-card-text>
		</v-card>
  </v-dialog>
</template>

<script>
import moment from 'moment';
export default {
	props: [
		'usingWorkingHours',
		'projectCreatedAt',
	],
	data(){
		return {
			id: null,
			dialog: false,
			status: {},
			columnCards: [],
			cycleTime: 0,
			units: [
				{ name: 'Minutes', },
				{ name: 'Minutes', shortname: 'mins', minutes: 1, workingMinutes: 1 },
				{ name: 'Hours',	 shortname: 'hrs', 	minutes: 60, workingMinutes: 60 },
				{ name: 'Days',  	 shortname: 'days',	minutes: 1440, workingMinutes: 540 },
			],
			headersWorkItems: [
				{
					text: "ID",
					value: "workItem.code", sort: (a, b) => {
						let indexA = a.indexOf('-')
						let indexB = a.indexOf('-')
						a = a.substr(indexA+1)
						b = b.substr(indexB+1)
						return parseInt(a) - parseInt(b)
					},
					width: 95,
				},
				{ text: "Summary", value: "workItem.name", },
				{ text: "Time", 	 value: "time", align: 'right' },
			],
			selectedUnit: 0,
			startDate: moment().subtract(1, 'week').format('YYYY-MM-DD'),
			endDate: moment().add(1, 'day').format('YYYY-MM-DD'),
		}
	},
	watch: {
		startDate() {
			this.$nextTick(() => this.init())
		},
		endDate() {
			this.$nextTick(() => this.init())
		}
	},
	methods: {
		init(){
			this.$http.get(`api/Project/Status/CycleTime/${this.id}?startDate=${this.startDate}&endDate=${this.endDate}`).then((res) => {
				this.status = res.data.status
				this.columnCards = res.data.workItemCards
				this.cycleTime = res.data.cycleTime
			})
		},
		open(id){
			this.startDate = this.projectCreatedAt
			this.selectedUnit = 0
			this.id = id
			this.dialog = true
			this.init()
		},
		clickWorkItem(v){ this.$emit('clickWorkItem', v.workItem.code, this.init) },
		cancel(){
			this.dialog = false
		},
    setMaxText(text, max){
			if(!text)
				return ''
      if(text.length > max)
        text = text.substr(0, max) + '...'
      return text
    },
		formatTime(time){
			if(!this.selectedUnit)
				return this.getIndicatorTime(time, false, this.usingWorkingHours)

			if(time == null || isNaN(time) || time == -1)
				return '-'
			
			if(this.usingWorkingHours)
				time /= this.units[this.selectedUnit].workingMinutes
			else
				time /= this.units[this.selectedUnit].minutes
			let units = this.units[this.selectedUnit].shortname

			time = Math.round(time);
			if(time == 1)
				units = units.substring(0, units.length-1)
			return  new Intl.NumberFormat('en-US').format(time) + ' ' + units
		},
	}
}
</script>

<style scoped lang="scss">
.card{
	padding: 30px 0;
}
.ay::-webkit-scrollbar {
	width: 6px;
	height: 5px;
}
.ay::-webkit-scrollbar-thumb{
  background: #AED6F1;
}
.status-container{
	white-space:pre-wrap;
  word-break:break-word;
  box-shadow: 0 0 5px #888;
  display: inline-block;
  min-height: 30px;
  max-height: 70px;
	width: 100%;
  color: white;
  overflow: hidden;
  margin: 5px 0;
  border-radius: 5px;
  padding: 5px 10px;
	text-align: center;
	font-size: .8em;
}

.close{
	position: absolute;
	right: 10px;
	top: 10px;
}

.cycle-time{
	font-size: .8em;
	margin-left: auto;

	div{
		background-color: var(--v-background-lighten2);
		padding: 10px;
		border-radius: 10px;
	}
}

.units-selector{
	text-transform: none;
	margin-left: auto;
}

.table{
	padding: 10px;
	border: 1px solid #888;
	color: #333;

	.header{
		background: #1976D2;
		color: white;
		.col{
			display: flex;
			align-items: center;
		}
	}

	.body{
		border: 1px solid #888;
		border-top: none;
	}
}
</style>