import moment from 'moment'

function linearRegression(y, x){
	let lr = {};
	let n = y.length;
	let sum_x = 0;
	let sum_y = 0;
	let sum_xy = 0;
	let sum_xx = 0;
	let sum_yy = 0;

	for (let i = 0; i < n; i++) {
		sum_x += x[i];
		sum_y += y[i];
		sum_xy += (x[i]*y[i]);
		sum_xx += (x[i]*x[i]);
		sum_yy += (y[i]*y[i]);
	} 

	lr['slope'] = (n * sum_xy - sum_x * sum_y) / (n*sum_xx - sum_x * sum_x);
	lr['intercept'] = (sum_y - lr.slope * sum_x)/n;
	lr['r2'] = Math.pow((n*sum_xy - sum_x*sum_y)/Math.sqrt((n*sum_xx-sum_x*sum_x)*(n*sum_yy-sum_y*sum_y)),2);

	return lr;
}

export default function ThroughputVelocity(
		initialDate,
		finalDate,
		status,
		type,
		timeUnit,
		cardColumns,
		handleZoom,
		setCategories,
		setTotals
	) {
	let categories = []
	let current = moment(initialDate)
	let dataWorkItems = [], dataStoryPoints = []

	cardColumns = cardColumns.filter(cc => 
		cc.projectWorkItemStatusId == status &&
		cc.referencedWorkItem.type == type
	)
	cardColumns = cardColumns.map(cc => {
		cc.startedAt = moment(cc.startedAt)
		return cc
	})


	while(1){
		if(timeUnit == 'Days'){

			categories.push({ display: current.format('DD MMM'), date: current.format('YYYY-MM-DD') })

			let workItems = cardColumns.filter( cc => cc.startedAt.isSame(current, 'day') ).length
			let storyPoints = 0
			for(let cc of cardColumns.filter( cc => cc.startedAt.isSame(current, 'day') )){
				storyPoints += cc.referencedWorkItem.estimatedEffort || 0
			}
			dataWorkItems.push(workItems)
			dataStoryPoints.push(storyPoints)

			if(current.add(1, 'day') > moment(finalDate))
				break

		} else if(timeUnit == 'Weeks'){
			categories.push({ display: current.isoWeek(), date: current.format('YYYY-MM-DD') })

			let workItems = cardColumns.filter( cc => cc.startedAt.isSame(current, 'isoweek') ).length
			let storyPoints = 0
			for(let cc of cardColumns.filter( cc => cc.startedAt.isSame(current, 'isoweek') )){
				storyPoints += cc.referencedWorkItem.estimatedEffort || 0
			}
			dataWorkItems.push(workItems)
			dataStoryPoints.push(storyPoints)

			if(current.add(1, 'isoweek') >= moment(finalDate)){
				if(categories[categories.length-1].display != moment(finalDate).isoWeek()){
					current = moment(finalDate)
					categories.push({ display: current.isoWeek(), date: current.format('YYYY-MM-DD') })
					workItems = cardColumns.filter( cc => cc.startedAt.isSame(current, 'isoweek') ).length
					dataWorkItems.push(workItems)
					storyPoints = 0
					for(let cc of cardColumns.filter( cc => cc.startedAt.isSame(current, 'isoweek') )){
						storyPoints += cc.referencedWorkItem.estimatedEffort || 0
					}
					dataStoryPoints.push(storyPoints)
				}
				break
			}

		} else if(timeUnit == 'Months'){

			categories.push({ display: current.format('MMM'), date: current.format('YYYY-MM-DD') })

			let workItems = cardColumns.filter( cc => cc.startedAt.isSame(current, 'month') ).length
			let storyPoints = 0
			for(let cc of cardColumns.filter( cc => cc.startedAt.isSame(current, 'month') )){
				storyPoints += cc.referencedWorkItem.estimatedEffort || 0
			}
			dataWorkItems.push(workItems)
			dataStoryPoints.push(storyPoints)

			if(current.add(1, 'month') >= moment(finalDate)){
				if(categories[categories.length-1].display != moment(finalDate).format('MMM')){
					current = moment(finalDate)
					categories.push({ display: moment(finalDate).format('MMM'), date: current.format('YYYY-MM-DD') })
					workItems = cardColumns.filter( cc => cc.startedAt.isSame(current, 'month') ).length
					storyPoints = 0
					for(let cc of cardColumns.filter( cc => cc.startedAt.isSame(current, 'month') )){
						storyPoints += cc.referencedWorkItem.estimatedEffort || 0
					}
					dataWorkItems.push(workItems)
					dataStoryPoints.push(storyPoints)
				}
				break
			}

		} else {
			return
		}
	}

	let numberArray = []
	for(let i = 0; i < categories.length; i++){
		numberArray.push(i)
	}
	setCategories(categories)
	setTotals(dataWorkItems, dataStoryPoints)

	let regressionWorkItems = linearRegression(dataWorkItems, numberArray)
	let regressionStoryPoints = linearRegression(dataStoryPoints, numberArray)

	let coordWorkItems = [[0, regressionWorkItems.intercept], [dataWorkItems.length-1, regressionWorkItems.intercept + regressionWorkItems.slope*(dataWorkItems.length-1)]]
	let coordStoryPoints = [[0, regressionStoryPoints.intercept], [dataStoryPoints.length-1, regressionStoryPoints.intercept + regressionStoryPoints.slope*(dataStoryPoints.length-1)]]

	let options = {
		chart: {
			zoomType: 'x',
			resetZoomButton: {
				theme: {
					display: 'none'
				},
			}
		},
		title: {
			text: '',
		},
		xAxis: {
			categories: categories.map(c => c.display),
			events: {
				setExtremes: handleZoom,
			},
		},
		yAxis: [
			{
				title: {
					text: 'Work Items',
					style: {
						fontSize: '23px',
						color: '#2f7ed8',
					}
				},
				min: 0
			},
			{
				title: {
					text: 'Story Points',
					style: {
						fontSize: '23px',
						color: '#7ab06e',
					}
				},
				min: 0,
				opposite: true
			}
		],
		plotOptions: {
			area: {
				fillOpacity: 0.3
			}
		},
		series: [
			{
				type: 'area',
				name: 'Work Items',
				data: dataWorkItems,
				marker: {
					radius: 4
				},
				lineWidth: 3,
			},
			{
				type: 'area',
				name: 'Story Points',
				data: dataStoryPoints,
				marker: {
					radius: 4
				},
				yAxis: 1,
				lineWidth: 3,
			},
			{
				type: 'line',
				name: 'Work Items Trend',
				data: coordWorkItems,
				marker: {
					enabled: false
				},
				states: {
					hover: {
						lineWidth: 0
					}
				},
				enableMouseTracking: false,
				zoneAxis: 'x',
				zones: [
					{ value: 0 },
					{ dashStyle: 'dash' },
				],
			},
			{
				type: 'line',
				name: 'Story Points Trend',
				data: coordStoryPoints,
				marker: {
					enabled: false
				},
				states: {
					hover: {
						lineWidth: 0
					}
				},
				enableMouseTracking: false,
				yAxis: 1,
				zoneAxis: 'x',
				zones: [
					{ value: 0 },
					{ dashStyle: 'dash' },
				],
			},
		],
		colors: ['#2f7ed8', '#7ab06e', '#2f7ed8', '#7ab06e', '#1aadce',
        '#492970', '#f28f43', '#77a1e5', '#c42525', '#a6c96a']
	}
	return options
}