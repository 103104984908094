<template>
	<div>
		<div class="chart bordered rounded mt-3" id="chart" :style="{ maxHeight: `calc(100vh - 225px)` }">
			<div style="width: max-content">

				<div class="line header">
					<div class="sticky">
						<div class="st head caption" style="color: var(--v-text-lighten2)">
							<div style="width: 70px"><b>BlueTag</b></div>
							<div style="width: 120px"><b>Role</b></div>
							<div style="width: 120px" title="Department"><b>Department</b></div>
							<div style="width: 150px; margin-right: 0"><b>Project</b></div>
						</div>
					</div>
					<div v-for="(date, ix) in dates" :key="ix" class="date new-back" :class="{ 'no-border': !ix }">
						<b class="head">{{ date.children.length > 3 ? date.title : date.title.substr(0, 3) }}</b>
						<div class="children">
							<div v-for="(child, jx) in date.children" :key="jx" :id="child.isToday ? 'today' : null"
								:class="{
									'weekend-date': isWeekend(child),
									'top-date': true,
								}">
								<div class="weekend-overlay" v-if="isWeekend(child)" :style="{ height: getOverlayHeight }"></div>
								{{ child.text }}
							</div>
						</div>
					</div>
				</div>

				<div v-if="!itemsPerUser.length">
					No data available
				</div>

				<div
					v-for="(item, ix) in itemsPerUser" :key="ix"
					class="line new-back"
				>
					<div class="sticky">
						<div class="st">
							<div style="width: 70px;" :title="item.requestorBlueTag || item.requestorFullName">
								<v-tooltip top style="z-index: 124; background: black;">
									<template v-slot:activator="{ on }">
										<b v-on="on">{{ item.requestorBlueTag }}</b>
									</template>
									<span>{{ item.requestorFullName }}</span>
								</v-tooltip>
							</div>
							<div
								style="width: 120px; overflow: hidden; text-overflow: ellipsis;"
							>
								{{ item.requestorJobTitle }}
							</div>
							<div style="width: 120px">
								{{ departments[item.requestorDepartment] }}
							</div>
							<div
								style="width: 150px" class="mr-0 overflow-hidden"
								:title="'Project'"
							>
								<span v-for="project in item.projects" :key="project.id">
									{{ project.name }}
								</span>
							</div>
						</div>
					</div>
					<div v-for="(period, jx) in item.periods" :key="jx" class="ranges-cont">
						<div
							class="date-range"
							:style="{
								width: getDateRangeWidth(period),
								left: getDateRangeLeftPosition(period),
								backgroundColor: 'var(--v-primary-base)',
							}"
						>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import moment from 'moment'

export default {
	props: {
		items: {
			type: Array,
			required: true
		},
		startDate: {
			type: String,
			required: true
		},
		endDate: {
			type: String,
			required: true
		}
	},
	data: () => ({
		departments: [],
	}),
	computed: {
		dates() {
			let startDate = moment(this.startDate).clone()
			let endDate = moment(this.endDate).clone()
			let dates = []

			let parentUnit = 'MMM YYYY'
			if (this.dateUnitVal == 2)
				parentUnit = 'YYYY'

			while (startDate.isSameOrBefore(endDate, 'day')) {
				let length = dates.length
				if (!length || startDate.format(parentUnit) != dates[length - 1].title) {
					dates.push({
						title: startDate.format(parentUnit),
						children: [],
					})
					length++
				}
				let text = startDate.format('D')
				if (this.dateUnitVal == 1)
					text = startDate.isoWeek()
				else if (this.dateUnitVal == 2)
					text = startDate.format('MMM')

				dates[length - 1].children.push({ text, value: startDate.clone() })
				startDate = startDate.add(1, 'day')
			}
			return dates
		},
		itemsPerUser(){
			const perUser = this.items
				.filter(x => x.status === 2)
				.reduce((acc, item) => {
					if(!acc[item.requestorId]) {
						acc[item.requestorId] = {
							requestorBlueTag: item.requestorBlueTag,
							requestorFullName: item.requestorFullName,
							requestorJobTitle: item.requestorJobTitle,
							requestorDepartment: item.requestorDepartment,
							projects: item.basicCustomerProjects,
							lines: []
						}
					}
					acc[item.requestorId].lines = acc[item.requestorId].lines.concat(item.lines)
					return acc
				}, {})

			let arr = Object.keys(perUser).map(key => {
				return {
					requestorBlueTag: perUser[key].requestorBlueTag,
					requestorFullName: perUser[key].requestorFullName,
					requestorJobTitle: perUser[key].requestorJobTitle,
					requestorDepartment: perUser[key].requestorDepartment,
					projects: perUser[key].projects,
					lines: perUser[key].lines
				}
			})

			arr.forEach(x => {
				x.lines = x.lines
					.filter(x => x.date && moment(x.date).isBetween(this.startDate, this.endDate, 'day', '[]'))
					.sort((a, b) => moment(a.date).diff(b.date))
				
				x.periods = []
				let currentPeriod = null
				x.lines.forEach(line => {
					if(!currentPeriod || currentPeriod.endDate != line.date) {
						if(currentPeriod) {
							if(moment(currentPeriod.endDate).clone().add(1, 'day').isSame(line.date))
								currentPeriod.endDate = line.date
							else {
								x.periods.push(currentPeriod)
								currentPeriod = {
									startDate: line.date,
									endDate: line.date,
								}
							}
						} else {
							currentPeriod = {
								startDate: line.date,
								endDate: line.date,
							}
						}
					}
				})
				x.periods.push(currentPeriod)
			})

			return arr
		},
		getOverlayHeight() {
			const heightPx = this.itemsPerUser.length * 23.2; // Assuming each item adds 23.2px height
			return `${heightPx}px`;
		}
	},
	created() {
		this.$http.get('api/enums/departments')
			.then(res => {
				this.departments = res.data.map(x => x.name)
			})
	},
	methods: {
		getDateRangeWidth(period) {
			let startDate = moment(period.startDate)
			let endDate = moment(period.endDate)

			if(startDate.isBefore(this.startDate))
				startDate = moment(this.startDate)
			if(endDate.isAfter(this.endDate))
				endDate = moment(this.endDate)

			let days = endDate.diff(startDate, 'days') + 1
			return `${days * 27}px`
		},
		getDateRangeLeftPosition(period) {
			let startDate = moment(period.startDate)
			let endDate = moment(period.endDate)

			if(startDate.isBefore(this.startDate))
				startDate = moment(this.startDate)
			if(endDate.isAfter(this.endDate))
				endDate = moment(this.endDate)

			let days = startDate.diff(moment(this.startDate), 'days')
			return `${days * 27}px`
		},
		isWeekend(date) {
			return date.value.day() == 0 || date.value.day() == 6
		},
	},
}
</script>

<style lang="scss" scoped>
.chart::-webkit-scrollbar {
	width: 10px;
	height: 10px;
	background: var(--v-newDesignBackground-base)
}

.chart::-webkit-scrollbar-thumb {
	background: var(--v-background-lighten2)
}

.chart {
	width: calc(100vw - 200px);
	overflow-x: scroll;
	margin-top: 5px;
	font-size: .8em;

	.header {
		position: sticky;
		top: 0;
		z-index: 2;
	}

	.line {
		display: flex;
		width: max-content;
		width: 100%;

		.sticky {
			display: flex;
			position: sticky;
			align-items: flex-end;
			background: var(--v-newDesignBackground-base);
			left: 0;
			z-index: 2;
			min-height: 14px;
			border-right: 2px solid var(--v-lightgray-lighten);
			width: 460px;

			&.type-header {
				padding: 10px 5px;
				user-select: none;

				.st {
					gap: 10px;
					width: 100%;
					padding: 5px 5px 5px 10px;
				}
			}

			.st {
				display: flex;
				align-items: center;
				padding: 2px 5px 2px 10px;

				div {
					white-space: nowrap;
					overflow: hidden;
					text-overflow: ellipsis;
				}
			}

			.head {
				border-bottom: 2px solid var(--v-lightgray-lighten);
			}
		}

		.date {
			padding: 5px 0;
			border: 1px solid var(--v-lightgray-lighten);
			border-bottom-width: 2px;
			border-top: 0;

			&.no-border {
				border-left: none !important;
			}

			.head {
				position: sticky;
				left: 460px;
				font-size: 1.2em;
				padding: 0 8px;
			}

			.children {
				display: flex;

				div {
					text-align: center;
					padding: 0 8px;
					width: 27px;
					height: 27px;
					display: inline-flex;
					align-items: center;
					justify-content: center;
					border-radius: 50%;
				}

				.weekend-date {
					background: gray;
					color: white;
				}
				.weekend-overlay {
    			top: 100%;
    			border-radius: 0;
					position: absolute;
					background-color: rgba(160, 160, 160, 0.3);
				}
			}
		}
	}
}

.ranges-cont {
	position: relative;
	display: flex;
	align-items: center;

	.date-range {
		display: inline-block;
		position: absolute;
		height: 16px;
		border-radius: 20px;
	}
}
</style>