<template>
	<div>
		<div class="d-flex justify-space-between align-end">
			<div>
				<div class="mb-3">
					<v-text-field
						v-model="search"
						prepend-inner-icon="search"
						class="inline-block" label="Search"
						outlined hide-details
					/>
					<q-date-picker
						@change="getBalance"
						v-model="endDate"
						prepend-inner-icon="search"
						class="inline-block ml-3" label="(To Calculate) End date"
						outlined hide-details inner
					/>
				</div>
			</div>
			<v-btn v-if="$refs.pdfDialog" @click="$refs.pdfDialog.open(id)" color="#ff6347" outlined>
				<v-icon left color="#ff6347">mdi-file-pdf-box</v-icon>
				Export
			</v-btn>
		</div>

		<div class="balance-charts pt-3">

			<div class="balance-chart elevation-1 rounded d-flex flex-column new-back lighten2 pa-5">
				<span
					class="body-1"
					style="color: var(--v-text-lighten)"
				>
					Total Billable
				</span>
				<div
					v-for="(amount, currency, ix) in balance.billablesSubtotalAmount" :key="ix"
					v-show="amount" class="flex-column my-1"
				>
					<div :is="linkComponent" class="text--text decoration-none" :to="`/admin/billable?customerId=${id}&currency=${currency.toUpperCase()}`" disabled>
						<h2>{{ currency.toUpperCase() }} {{ currencyFormat(amount) }}</h2>
					</div>
					<progress-linear
						class="mt-n2"
						:items="[
							{
								value: balance.billedAmount[currency]/amount*100,
								color: '#0270fc',
							},
							{
								value: balance.pendingToBill[currency]/amount*100,
								color: 'var(--v-lightgray-base)',
							}
						]"
					/>
				</div>

				<div class="mt-3">
					<div class="d-flex align-center">
						<div class="dot mr-1" style="background: #0270fc"/>
						Billed
					</div>
					<div class="amounts">
						<span v-if="isEmptyAmount(balance.billedAmount)">$0.00</span>
						<span v-for="(amount, currency, ix) in balance.billedAmount" :key="ix" v-show="amount">
							<div :is="linkComponent" class="text--text" :to="`/admin/billableItem?customerId=${id}&currency=${currency.toUpperCase()}`">
								<b>{{ currency.toUpperCase() }}</b>
								{{ currencyFormat(amount) }}
							</div>
						</span>
					</div>
				</div>
				<div class="mt-0">
					<div class="d-flex align-center">
						<div class="dot mr-1" style="background: var(--v-lightgray-base)"/>
						Pending to Bill
					</div>
					<div class="amounts">
						<span v-if="isEmptyAmount(balance.pendingToBill)">$0.00</span>
						<span v-for="(amount, currency, ix) in balance.pendingToBill" :key="ix" v-show="amount">
							<b>{{ currency.toUpperCase() }}</b>
							{{ currencyFormat(amount) }}
						</span>
					</div>
				</div>
			</div>
			
			<div class="balance-chart elevation-1 rounded new-back lighten2 pa-5">
				<span class="body-1" style="color: var(--v-text-lighten)">Total Invoiced</span>
				<div
					v-for="(amount, currency, ix) in balance.invoicesSubtotalAmount" :key="ix"
					v-show="amount" class="flex-column my-1"
				>
					<div :is="linkComponent" class="text--text decoration-none" :to="`/admin/invoice?customerId=${id}&currency=${currency.toUpperCase()}`">
						<h2>{{ currency.toUpperCase() }} {{ currencyFormat(amount) }}</h2>
					</div>
					<progress-linear
						class="mt-n2"
						:items="[
							{
								value: balance.invoiceRelatedAmount[currency]/(amount + balance.pendingToInvoice[currency])*100,
								color: '#31a6db',
							},
							{
								value: balance.invoiceNotRelatedAmount[currency]/(amount + balance.pendingToInvoice[currency])*100,
								color: '#9cd4ee',
							},
							{
								value: balance.pendingToInvoice[currency]/(amount + balance.pendingToInvoice[currency])*100,
								color: 'var(--v-lightgray-base)',
							}
						]"
					/>
				</div>
				<div class="mt-3">
					<div>
						<div class="dot mr-1" style="background: #31a6db"/>
						Related to Billable Items
					</div>
					<div class="amounts">
						<span v-if="isEmptyAmount(balance.invoiceRelatedAmount)">$0.00</span>
						<div
							v-for="(amount, currency, ix) in getOnlyValidAmounts(balance.invoiceRelatedAmount)"
							:key="ix" class="d-flex flex-column"
						>
							<span>
								<b>{{ currency.toUpperCase() }}</b>
								{{ currencyFormat(amount) }}
							</span>
							<div
								v-for="(subAmount, currency, jx) in balance.invoiceRelatedAmount['distribution' + currency.toUpperCase()]"
								:key="jx" v-show="subAmount" class="caption"
							>
								<span style="color: var(--v-text-lighten)">
									<b>{{ currency.toUpperCase() }}</b>
									{{ currencyFormat(subAmount) }}
								</span>
							</div>
						</div>
					</div>
				</div>
				<div>
					<div>
						<div class="dot mr-1" style="background: #9cd4ee"/>
						Not Related
					</div>
					<div class="amounts">
						<span v-if="isEmptyAmount(balance.invoiceNotRelatedAmount)">$0.00</span>
						<span v-for="(amount, currency, ix) in balance.invoiceNotRelatedAmount" :key="ix" v-show="amount">
							<b>{{ currency.toUpperCase() }}</b>
							{{ currencyFormat(amount) }}
						</span>
					</div>
				</div>
				<div>
					<div class="d-flex align-center">
						<div class="dot mr-1" style="background: var(--v-lightgray-base)"/>
						Pending to Invoice
					</div>
					<div class="amounts">
						<span v-if="isEmptyAmount(balance.pendingToInvoice)">$0.00</span>
						<span v-for="(amount, currency, ix) in balance.pendingToInvoice" :key="ix" v-show="amount">
							<div :is="linkComponent" class="text--text" :to="`/admin/billableItem?customerId=${id}&invoiced=false&currency=${currency.toUpperCase()}`">
								<b>{{ currency.toUpperCase() }}</b>
								{{ currencyFormat(amount) }}
							</div>
						</span>
					</div>
				</div>
			</div>

			<div class="balance-chart elevation-1 rounded new-back lighten2 pa-5">
				<span class="body-1" style="color: var(--v-text-lighten)">Total Paid</span>
				<div
					v-for="(amount, currency, ix) in balance.paymentsTotalAmount" :key="ix"
					v-show="amount" class="flex-column my-1"
				>
					<div :is="linkComponent" class="text--text decoration-none" :to="`/admin/payment?customerId=${id}&currency=${currency.toUpperCase()}`">
						<h2>{{ currency.toUpperCase() }} {{ currencyFormat(amount) }}</h2>
					</div>
					<progress-linear
						class="mt-n2"
						:items="[
							{
								value: balance.paymentsAppliedAmount[currency]/(amount + balance.pendingToPay[currency])*100,
								color: '#5dca9e',
							},
							{
								value: balance.paymentsNotAppliedAmount[currency]/(amount + balance.pendingToPay[currency])*100,
								color: '#b1e6d0',
							},
							{
								value: balance.pendingToPay[currency]/(amount + balance.pendingToPay[currency])*100,
								color: 'var(--v-lightgray-base)',
							}
						]"
					/>
				</div>
				<div class="mt-3">
					<div>
						<div class="dot mr-2" style="background: #5dca9e"/>
						Applied to Invoices
					</div>
					<div class="amounts">
						<span v-if="isEmptyAmount(balance.paymentsAppliedAmount)">$0.00</span>
						<div
							v-for="(amount, currency, ix) in getOnlyValidAmounts(balance.paymentsAppliedAmount)"
							:key="ix" class="d-flex flex-column"
						>
							<div :is="linkComponent" class="text--text" :to="`/admin/payment?customerId=${id}&applied=true&currency=${currency.toUpperCase()}`">
								<span>
									<b>{{ currency.toUpperCase() }}</b>
									{{ currencyFormat(amount) }}
								</span>
							</div>
							<div
								v-for="(subAmount, currency, jx) in balance.paymentsAppliedAmount['distribution' + currency.toUpperCase()]"
								:key="jx" v-show="subAmount" class="caption"
							>
								<span style="color: var(--v-text-lighten)">
									<b>{{ currency.toUpperCase() }}</b>
									{{ currencyFormat(subAmount) }}
								</span>
							</div>
						</div>
					</div>
				</div>
				<div>
					<div>
						<div class="dot mr-2" style="background: #b1e6d0"/>
						Not Applied
					</div>
					<div class="amounts">
						<span v-if="isEmptyAmount(balance.paymentsNotAppliedAmount)">$0.00</span>
						<span v-for="(amount, currency, ix) in balance.paymentsNotAppliedAmount" :key="ix" v-show="amount">
							<div :is="linkComponent" class="text--text" :to="`/admin/payment?customerId=${id}&applied=false&currency=${currency.toUpperCase()}`">
								<b>{{ currency.toUpperCase() }}</b>
								{{ currencyFormat(amount) }}
							</div>
						</span>
					</div>
				</div>
				<div>
					<div>
						<div class="dot mr-2" style="background: var(--v-lightgray-base)"/>
						Pending to Pay
					</div>
					<div class="amounts">
						<span v-if="isEmptyAmount(balance.pendingToPay)">$0.00</span>
						<span v-for="(amount, currency, ix) in balance.pendingToPay" :key="ix" v-show="amount">
							<div :is="linkComponent" class="text--text" :to="`/admin/invoice?customerId=${id}&paid=false&currency=${currency.toUpperCase()}`">
								<b>{{ currency.toUpperCase() }}</b>
								{{ currencyFormat(amount) }}
							</div>
						</span>
					</div>
				</div>
			</div>
		</div>

		<billable-items-panel :billable-items="shownBillableItems" class="mt-10"/>
		<invoices-panel :invoices="shownInvoices" class="mt-10"/>
		<payments-panel :payments="shownPayments" class="mt-10"/>
		<customer-pdf-dialog ref="pdfDialog"/>
	</div>
</template>

<script>
import moment from 'moment'
import BillableItemsPanel from './AccountBalance/BillableItemsPanel'
import InvoicesPanel from './AccountBalance/InvoicesPanel'
import PaymentsPanel from './AccountBalance/PaymentsPanel'
import ProgressLinear from '../../ProgressLinear'
import CustomerPdfDialog from '../../../dialogs/adminSurf/CustomerPdfDialog'

export default {
	props: {
		id: String,
		disabled: Boolean,
	},
	components: {
		BillableItemsPanel,
		InvoicesPanel,
		PaymentsPanel,
		ProgressLinear,
		CustomerPdfDialog,
	},
	data: () => ({
		search: '',
		endDate: moment().format('YYYY-MM-DD'),
		balance: {
			billables: [],
			billableItems: [],
			invoices: [],
			payments: [],

			invoiceRelatedAmount: {},
			paymentsAppliedAmount: {}
		},
	}),
	computed: {
		shownBillableItems(){
			let billableItems = this.balance.billableItems
			if(this.search) billableItems = this.applySearch(this.search, billableItems)
			return billableItems
		},
		shownInvoices(){
			let invoices = this.balance.invoices
			if(this.search) invoices = this.applySearch(this.search, invoices)
			return invoices
		},
		shownPayments(){
			let payments = this.balance.payments
			if(this.search) payments = this.applySearch(this.search, payments)
			return payments
		},
		linkComponent(){
			return this.disabled ? 'div' : 'router-link'
		}
	},
	created() {
		this.getBalance()
	},
	methods: {
		getBalance(){
			this.$http.get(`api/Customer/${this.id}/balance?endDate=${this.endDate}`)
				.then(res => {
					this.balance = res.data
				})
				.catch(err => {
					console.error(err)
					this.$root.error('Cannot get balance')
				})
		},
		getOnlyValidAmounts(amount){
			if(!amount) amount = {}
			let validAmount = {
				mxn: amount.mxn,
				usd: amount.usd,
				eur: amount.eur,
			}
			if(!validAmount.mxn) delete validAmount.mxn
			if(!validAmount.usd) delete validAmount.usd
			if(!validAmount.eur) delete validAmount.eur
			return validAmount
		},
		isEmptyAmount(amount){
			if(!amount) return true
			return !amount.mxn && !amount.usd && !amount.eur
		}
	},
}
</script>

<style lang="scss" scoped>

.balance-charts{
	display: grid;
	gap: 20px;
	grid-template-columns: repeat(3, 1fr);

	.balance-chart{
		> div{
			// display: flex;
			display: grid;
			grid-template-columns: 40% 60%;
			gap: 10px;
			justify-content: space-between;
			// align-items: center;
			margin: 10px 0;

			.amounts{
				display: flex;
				flex-wrap: wrap;
				gap: 15px;
			}
		}
		> div.flex-column{
			display: flex;
			justify-content: space-between;
		}
	}
}
</style>