<template>
  <v-card 
    class="item-individual-container cursor-pointer"
    v-bind="$attrs"
    @click="$emit('click')"
  >
  <div class="mt-3 ml-3">
    <user-avatar :showCard="false" :userId="item.assignedToId" :size="30" hideLoading></user-avatar>
  </div>
    <v-card-text class="pt-3 pb-3">
      <div>
        {{ item.name }}
      </div>
      <div class="extra-info">
        <div class="created-date">
          {{ item.dueDate ? formatDate(item.dueDate) : 'Due Date not specified' }} ({{ item.assignedToBlueTag }})
        </div>
        <v-card-actions>
          <v-menu :readonly="readonly" offset-y class="mb-1">
            <template v-slot:activator="{ on }">
              <v-btn dark :color="getColor(item.status.color) || 'white'" class="status-button-title" v-on="on">
                <span :style="{ color: item.status.color || 'white' }">
                  Status: {{ item.status.name }}
                </span>
                <v-icon :color="item.status.color" right>keyboard_arrow_down</v-icon>
              </v-btn>
            </template>
            <v-list v-if="!isNew" dense>
              <v-list-item 
                v-for="(status, ix) in statuses"
                :key="ix"
                @click="saveStatus(status, item.id, ix)"
                :color="{ backgroundColor: getColor(status.color)}"
              >
                <v-list-item-title :style="{ color: status.color }" :color="status.color">
                  {{ status.name }}
                </v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
          <v-btn class="ml-2" v-if="isNew" small text @click="deleteFunc($event)" >
            <v-icon :style="{fontSize: '20px', color: 'red'}">delete</v-icon>
          </v-btn>
        </v-card-actions>
      </div>
    </v-card-text>
  </v-card>
</template>

<script>
import moment from 'moment'
export default {
  props: {
    item: { type: Object, default: {} },
    statuses: { type: Array, default: () => {[]} },
    deleteFunc: { type: Function, default: () => {} },
    readonly: { type: Boolean, default: false },
  },
  data: () => ({

  }),
  computed: {
    isNew() {
      return !this.item.id
    }
  },
  methods: {
    formatDate(date) {
      if(!moment(date).isValid()) return false
      return `Due Date: ${moment(date).format("MMM D")}`
    },
    getColor(color) {
      return _.dropRight(color, 2).join("") + '1A'
    },
    saveStatus(status, id, index) {
      let property = "statusId"
      let value = status.id
      
      if(status.confetti){
        this.$confetti.start()
        setTimeout(() => { this.$confetti.stop() }, 2000)
      }

      this.$q.save({
        api: `/api/WorkItem/${id}/byProperty/${property}`,
        data: {
          value: value
        },
        successMsg: 'Status changed successfully',
        afterSuccessUrl: null,
        errorMsg: "Cannot save changes.",
        afterErrorUrl: null,
      }).then(async (res) => {
        this.$emit('statusChange')
      })
    }
  }
}
</script>

<style scoped>
  .item-individual-container {
    display: flex;
    width: 90%;
    box-sizing: border-box;
  }
  .extra-info {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .status-button-title {
    height: 20px !important;
    text-transform: capitalize !important;
  }
</style>