<template>
  <v-card class="pa-6 rounded" :max-width="miniVersion ? 200 : 300" z-index="101">
    <v-row>
      <v-col cols="3" v-if="!workItem">
        <div class="color cursor-pointer" :class="{ big: !miniVersion, small: miniVersion }" @click="changeColor('#1f1f1f')" :style="{ background: '#1f1f1f' }">
          <v-icon :small="miniVersion" v-show="'#1f1f1f' == value" :color="isBlackText('#1f1f1f') ? 'white' : 'black'">check</v-icon>
        </div>
      </v-col>
      <v-col cols="3" v-if="!workItem">
        <div class="color cursor-pointer" :class="{ big: !miniVersion, small: miniVersion }" @click="changeColor('#d2d2d2')" :style="{ background: '#d2d2d2' }">
          <v-icon :small="miniVersion" v-show="'#d2d2d2' == value" :color="isBlackText('#d2d2d2') ? 'white' : 'black'">check</v-icon>
        </div>
      </v-col>
      <v-col cols="3" v-if="!sprints">
        <div class="color cursor-pointer" :class="{ big: !miniVersion, small: miniVersion }" @click="changeColor('#ffffff')" :style="{ background: '#ffffff', border: '1px solid grey' }">
          <v-icon :small="miniVersion" v-show="'#ffffff' == value" :color="isBlackText('#ffffff') ? 'white' : 'black'">check</v-icon>
        </div>
      </v-col>
    </v-row>
    <v-divider class="my-3"></v-divider>
    <v-row class="" v-if="workItem">
      <v-col cols="3" v-for="(color , ix) in showingColors.workItem" :key="ix">
        <div class="color cursor-pointer" :class="{ big: !miniVersion, small: miniVersion }" @click="changeColor(color)"  :style="{ background: color }">
          <v-icon :small="miniVersion" v-show="color == value" :color="isBlackText(color) ? 'white' : 'black'">check</v-icon>
        </div>
      </v-col>
    </v-row>
    <v-row class="" v-else-if="sprints">
      <v-col cols="3" v-for="(color , ix) in showingColors.element" :key="ix">
        <div class="color cursor-pointer" :class="{ big: !miniVersion, small: miniVersion }" @click="changeColor(color)"  :style="{ background: color }">
          <v-icon :small="miniVersion" v-show="color == value" :color="isBlackText(color) ? 'white' : 'black'">check</v-icon>
        </div>
      </v-col>
    </v-row>
    <v-row class="" v-else-if="tags">
      <template>
        <v-col cols="6" v-for="(color , ix) in showingColors.tags" :key="ix" style="display: flex"  @click="changeColor(color)" class="testo cursor-pointer">
          <div class="color cursor-pointer mr-4 tag select-option" :class="{ big: !miniVersion, small: miniVersion }" :style="{ background: color }">
            <v-icon :small="miniVersion" v-show="color == value" :color="isBlackText(color) ? 'white' : 'black'">check</v-icon>
          </div>
          <div class="color cursor-pointer tag" :class="{ big: !miniVersion, small: miniVersion }" :style="{ background: color + '30' }">
            <v-icon :small="miniVersion" v-show="color == value" :color="'black'">check</v-icon>
          </div>
        </v-col>
      </template>
    </v-row>
  </v-card>
</template>

<script>
import colors from '../json/colors.json'
export default {
  props: {
    workItem: Boolean,
    elements: Boolean,
    sprints: Boolean,
    value: String,
    tags: Boolean,
    miniVersion: Boolean,
  },
  model: {
    event: 'update:color'
  },
  data: () => ({
    showingColors: {
      workItem: colors.workItems,
      element: colors.elements,
      tags: colors.tags
    },
  }),
  methods: {
    changeColor(color) {
      this.$emit('update:color', color)
    }
  }
}
</script>

<style lang="scss" scoped>
  .color {
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    &:not(.tag):hover {
      opacity: .5;
    }
  }
  .big {
    width: 50px;
    height: 50px;
  }
  .small{
    width: 30px;
    height: 30px;
  }
  .testo:hover .select-option{
 
      opacity: 0.5;
  }
</style>