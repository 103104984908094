<template>
  <v-container grid-list-md fluid>
    <v-layout>
      <v-row class="profile-container" :style="{ 'max-width': '100%' }" >
        <v-col :class="id == 'me' ? 'pb-0 pt-1' : 'py-0'" fill-height>
          <profile-card :key="profileCardKey" :onEdit="id == 'me' ? edit : null" :userId="id" :small="small" :editable="id == 'me'">
            <template #[`google-btn`] class="text-right" v-if="id == 'me'">
              <v-list-item>
                <div class="google-logo mr-2">
                  <v-img width="25" src="../../assets/google-logo.png"></v-img>
                </div>
                <v-list-item-content>
                  <v-list-item-subtitle >Google Account</v-list-item-subtitle>
                  <v-list-item-title  class="mt-1">
                    <a @click="googleAuth" :style="{ textDecoration: 'underline !important' }">
                      {{ item.isLinkedToGoogle ? 'Already linked' : 'Link your Google account' }}
                    </a>
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </template>
          </profile-card>
          <v-dialog :fullscreen="$vuetify.breakpoint.xsOnly" scrollable persistent 
            v-model="editing" :width="700" v-if="editing" @keydown.esc="editing = false">
            <v-card class="card">
              <v-card-title class="pt-5">
                <h2><v-icon large style="margin-top: -3px" color="primary">mdi-account</v-icon> My Profile</h2>
              </v-card-title>
              <v-card-text>
                <v-form v-model="validForm">
                  <v-row class="mt-5 pa-3">
                    <v-col cols="12" sm="6">
                      <v-text-field prepend-inner-icon="mdi-email-outline" label="Personal Email" v-model="editItem.personalEmail" outlined dense></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="6">
                      <q-date-picker inner label="Birthdate" v-model="editItem.birthdate" outlined dense></q-date-picker>
                    </v-col>
                    <v-col cols="12" sm="6">
                      <v-text-field prepend-inner-icon="mdi-cellphone" label="Mobile" hint="Please keep consistent format" placeholder="(542) 231-7652" v-model="editItem.mobile" outlined dense></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="6">
                      <v-text-field prepend-inner-icon="mdi-cellphone-key" label="Other Mobile" hint="Please keep consistent format" placeholder="(542) 231-7652" v-model="editItem.otherMobile" outlined dense></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="6">
                      <v-text-field prepend-inner-icon="mdi-home-outline" label="Address" v-model="editItem.address" outlined dense></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="6">
                      <v-autocomplete
                        @change="getStates" :filter="(item, queryText, itemText) => normalizeIncludes(itemText, queryText)"
                        v-model="editItem.country" outlined dense :items="countries" :rules="[v => !!v || 'Required']"
                        prepend-inner-icon="mdi-map-marker-outline" label="Country*" item-text="name"
                      />
                    </v-col>
                    <v-col cols="12" sm="6">
                      <v-autocomplete
                        @change="getCities" :filter="(item, queryText, itemText) => normalizeIncludes(itemText, queryText)"
                        v-model="editItem.state" outlined dense :items="states" :rules="[v => !!v || 'Required']"
                        prepend-inner-icon="mdi-selection-marker" label="State*" item-text="name"
                      />
                    </v-col>
                    <v-col cols="12" sm="6">
                      <v-autocomplete
                        :filter="(item, queryText, itemText) => normalizeIncludes(itemText, queryText)"
                        v-model="editItem.city" outlined dense :items="cities" :rules="[v => !!v || 'Required']"
                        prepend-inner-icon="mdi-city" label="City*" item-text="name"
                      />
                    </v-col>
                    <v-col cols="12" sm="6">
                      <v-text-field  prepend-inner-icon="mdi-lock" label="Password" type="password" v-model="editItem.password"  outlined dense></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="6">
                      <v-text-field  prepend-inner-icon="mdi-lock-reset" label="Confirm Password" type="password" :rules="confirmPasswordRules" v-model="editItem.confirmPassword" outlined dense></v-text-field>
                    </v-col>
                  </v-row>
                </v-form>
              </v-card-text>
              <v-card-actions class="px-6">
                <v-spacer></v-spacer>
                <v-btn color="primary" text @click="editing = false"> Cancel </v-btn>
                <v-tooltip top :disabled="editItem.password == editItem.confirmPassword">
                  <template v-slot:activator="{ on }">
                    <div v-on="on">
                      <v-btn
                        color="primary" depressed @click="save"
                        :disabled="editItem.password != editItem.confirmPassword || !validForm"
                      >
                        Save
                      </v-btn>
                    </div>
                  </template>
                  <span>Password confirmation must match with password.</span>
                </v-tooltip>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-col>
      </v-row>
    </v-layout>
  </v-container>
</template>

<script>
import _ from "lodash"
import moment from "moment"
import ProfileCard from "./../../components/panels/ProfileCard"

export default {
  props: ["id"],
  components: {
    ProfileCard,
  },
  data: () => ({ 
    small: false,
    item: {},
    linkedAccount: '',
    editItem: {},
    editing: false,
    profileCardKey: 0,
    countries: [],
    states: [],
    cities: [],
    validForm: false,
  }),
  created() {
    document.title = 'Home'
    this.$http.get('/api/instance/currentUser')
      .then(res => {
        this.item = res.data
        this.editItem = res.data
        this.editItem.password = ''
        this.editItem.confirmPassword = ''

        if(!this.item.country){
          this.item.country = 'Mexico'
        }
        this.$http.get(`api/location/states?countryName=${this.item.country}`)
          .then(res => { this.states = res.data })
        if(this.item.state){
          this.$http.get(`api/location/cities?countryName=${this.item.country}&stateName=${this.item.state}`)
            .then(res => { this.cities = res.data })
        }
      })
    this.$http.get('api/google/linkedAccount')
      .then(res => {
        this.linkedAccount = res.data.email
      })
    this.$http.get('api/location/countries')
      .then(res => {
        this.countries = res.data
      })
  },
  computed: {
    confirmPasswordRules(v) {
      return [
        (v) => this.editItem.password === this.editItem.confirmPassword || 'Password confirmation must match with password.',
      ];
    },
  },
  methods: {
    getStates(){
      this.editItem.state = ''
      this.editItem.city = ''
      this.$http.get(`api/location/states?countryName=${this.editItem.country}`)
        .then(res => {
          this.states = res.data
        })
    },
    getCities(){
      this.editItem.city = ''
      this.$http.get(`api/location/cities?countryName=${this.editItem.country}&stateName=${this.editItem.state}`)
        .then(res => {
          this.cities = res.data
        })
    },
    googleAuth(){
      this.$gapi.grantOfflineAccess().then(res => {
        this.$http.post(`api/google/linkAccount`, {code: res, url: window.location.origin})
          .then(res => {
            this.item.googleRefreshToken = res.data
            this.item.isLinkedToGoogle = true
            window.getApp.success("User linked.")
            this.$http.get('api/google/linkedAccount')
              .then(res => {
                this.linkedAccount = res.data.email
              })
          })
          .catch(() => {
            window.getApp.error("Error while getting user.")
          })
      })
    },
    edit(){
      this.editing = true
      this.editItem = Object.assign({}, this.item)
    },
    save(){
      this.$q.save({
        api: "/api/user/current",
        data: this.editItem,
        successMsg: "User saved.",
        afterSuccessUrl: null,
        errorMsg: "Cannot save record.",
        afterErrorUrl: null,
      }).then(res => {
        this.item = res.data
        this.editing = false
        this.profileCardKey++
      });
    },
  },
}
</script>

<style scoped lang="scss">
i{
  font-size: .95em;
}
.google-btn{
  border-width: 2px;
  margin-top: 10px;
  margin-bottom: 5px;
  color: white;
  padding-left: 5px !important;
  .google-logo{
    border-radius: 2px;
    background: white;
    text-align: center;
    margin-right: 10px;
    padding: 5px;
  }
}
.profile-container {
  padding: 0 9%;
}
</style> 