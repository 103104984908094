<template>
	<div class="pa-3 full-height new-back">
		<v-menu :value="amountMenu.show" :position-x="amountMenu.x" :position-y="amountMenu.y" zIndex="200">
			<v-list dense class="py-1">
				<v-list-item v-for="(currency, ix) in currencies.filter(x => amountMenu.amount[x.toLowerCase()])" :key="ix"
					class="px-2">
					<v-list-item-content class="caption py-0">
						<div>
							<div class="d-flex align-center" style="gap: 5px">
									<v-avatar size="22">
										<v-img :src="require(`../../assets/currencies/${currency.toLowerCase()}.png`)" />
									</v-avatar>
									<span>
										<b>{{ currency }}</b> {{ negativeCurrencyFormat(amountMenu.amount[currency.toLowerCase()]) }}
									</span>
							</div>
							<div v-if="amountMenu.amount.details && amountMenu.amount.details.length" class="d-flex flex-column">
								<div
									v-for="(detail, ix) in amountMenu.amount.details.filter(x => x.currency == currency)" :key="ix"
									class="d-inline-flex align-center" style="gap: 5px"
								>
									<span class="caption">
										{{ detail.detailTitle }}
									</span>
									<div class="flex" style="border-top: 1px solid black; min-width: 10px"></div>
									<span class="caption">
										{{ negativeCurrencyFormat(detail.amount) }}
									</span>
								</div>
							</div>
						</div>
					</v-list-item-content>
				</v-list-item>
			</v-list>
		</v-menu>

		<div class="d-flex justify-space-between align-center">
			<div class="admin-filters">
				<div class="d-flex flex-column" style="gap: 10px; max-width: 500px">
					<v-row dense>
						<v-col :cols="6">
							<v-autocomplete
								v-model="filters.customer"
								@change="getReport"
								:items="customers" label="Customer"
								item-text="name" item-value="id"
								dense outlined hide-details clearable multiple
								class="flex"
							/>
						</v-col>
						<v-col :cols="6">
							<v-select
								v-model="filters.currency"
								:items="currencies"
								dense outlined hide-details single-line
								label="Currency" class="flex"
							>
								<template #item="{ item }">
									<v-avatar size="24" class="mr-2">
										<v-img :src="require(`../../assets/currencies/${item.toLowerCase()}.png`)" />
									</v-avatar>
									<b>{{ item }}</b>
								</template>
								<template #selection="{ item }">
									<v-avatar size="24" class="mr-2">
										<v-img :src="require(`../../assets/currencies/${item.toLowerCase()}.png`)" />
									</v-avatar>
									<b>{{ item }}</b>
								</template>
							</v-select>
						</v-col>
						<v-col :cols="6">
							<q-date-picker
								v-model="filters.startDate"
								@input="getReport"
								outlined inner hide-details dense single-line clearable
								label="Start Date" class="flex"
								:type="timeUnit === 1 ? 'date' : 'month'"
							/>
						</v-col>
						<v-col :cols="6">
							<q-date-picker
								v-model="filters.endDate"
								@input="getReport"
								outlined inner hide-details dense clearable
								label="End Date" class="flex"
								:type="timeUnit === 1 ? 'date' : 'month'"
							/>
						</v-col>
					</v-row>
				</div>

				<div v-if="exchangeRateCurrency" class="d-flex align-center ml-10 mr-5">
					<input-mask v-model="exchangeRateOrigin" @change="handleExchangeRate($event, 'exchangeRateDestination')"
						outlined dense hide-details style="width: 150px" type="number" :mask="x => currencyFormat(x, true)">
						<template #append>
							<div class="d-flex align-center justify-center" style="margin-top: 2px">
								<v-avatar color="indigo" size="20" class="mr-1">
									<v-img
										:src="require(`../../assets/currencies/${filters.currency.toLowerCase()}.png`)"
									/>
								</v-avatar>
								{{ filters.currency }}
							</div>
						</template>
					</input-mask>
					<v-icon class="mx-1">mdi-cash-sync</v-icon>
					<input-mask v-model="exchangeRateDestination" @change="handleExchangeRate($event, 'exchangeRateOrigin')"
						outlined dense hide-details style="width: 150px" type="number" :mask="x => currencyFormat(x, true)">
						<template #append>
							<div class="d-flex align-center justify-center" style="margin-top: 2px">
								<v-avatar color="indigo" size="20" class="mr-1">
									<v-img
										:src="require(`../../assets/currencies/${exchangeRateCurrency.toLowerCase()}.png`)"
									/>
								</v-avatar>
								{{ exchangeRateCurrency }}
							</div>
						</template>
					</input-mask>
				</div>

				<div v-if="exchangeRateCurrency2" class="d-flex align-center">
					<input-mask v-model="exchangeRateOrigin2"
						@change="handleExchangeRate($event, 'exchangeRateDestination2')" outlined dense hide-details
						style="width: 150px" type="number" :mask="x => currencyFormat(x, true)">
						<template #append>
							<div class="d-flex align-center justify-center" style="margin-top: 2px">
								<v-avatar color="indigo" size="20" class="mr-1">
									<v-img
										:src="require(`../../assets/currencies/${filters.currency.toLowerCase()}.png`)"
									/>
								</v-avatar>
								{{ filters.currency }}
							</div>
						</template>
					</input-mask>
					<v-icon class="mx-1">mdi-cash-sync</v-icon>
					<input-mask v-model="exchangeRateDestination2"
						@change="handleExchangeRate($event, 'exchangeRateOrigin2')" outlined dense hide-details
						style="width: 150px" type="number" :mask="x => currencyFormat(x, true)">
						<template #append>
							<div class="d-flex align-center justify-center" style="margin-top: 2px">
								<v-avatar color="indigo" size="20" class="mr-1">
									<v-img
										:src="require(`../../assets/currencies/${exchangeRateCurrency2.toLowerCase()}.png`)"
									/>
								</v-avatar>
								{{ exchangeRateCurrency2 }}
							</div>
						</template>
					</input-mask>
				</div>
				<div class="d-flex flex-column gap-2">
					<v-switch
						v-model="filters.showOnZero"
						@change="getReport"
						label="Include billables on zero"
						class="mt-0 pt-0"
						style="height: 24px"
						hide-details
					/>
					<v-switch
						v-model="filters.receivablesDeferredOnly"
						@change="getReport"
						label="Receivables/Deferred amounts only"
						class="mt-0 pt-0"
						style="height: 24px"
						hide-details
					/>
				</div>
			</div>
			<v-btn-toggle v-model="timeUnit" @change="getReport" tile mandatory group color="primary">
				<v-btn small :value="1">Weeks</v-btn>
				<v-btn small :value="2">Months</v-btn>
			</v-btn-toggle>
		</div>

		<div class="chart new-back mt-2 bordered rounded-lg lighten" ref="chart">
			<table>
				<tbody>
					<tr class="sticky-header colored-header">
						<th @click="toggleExpandAll" rowspan="2" class="td-nb-x td-bb" style="background: var(--v-newDesignBackground-base) !important;">
							<div class="d-flex justify-center align-center cursor-pointer gap-1">
								Client
								<v-icon color="primary" size="1.2rem">
									mdi-{{allExpanded ? 'collapse' : 'expand'}}-all-outline
								</v-icon>
							</div>
						</th>
						<th @click="sortBillables" rowspan="2" class="td-nb-x td-bb">
							<div class="d-flex justify-center align-center cursor-pointer" style="gap: 3px">
								Billables
								<span
									class="material-symbols-outlined"
									style="font-size: 1.1rem; color: var(--v-primary-base)"
								>
									{{ billablesSortedByDate ? 'calendar_month' : 'sort_by_alpha' }}
								</span>
							</div>
						</th>
						<th rowspan="2" class="td-nb-x td-bb" style="background: var(--v-newDesignBackground-base) !important;">Projects</th>
						<th
							v-for="(date, ix) in dates" :key="ix"
							colspan="12" scope="colgroup" class="td-nb-y"
						>
							{{ date.format(dateFormat) }}
						</th>
					</tr>
					<tr class="colored-header">
						<template
							v-for="(_, ix) in dates"
						>
							<th scope="col" class="td-nb-y td-nb-r" :key="ix + 'a'">Revenue</th>
							<th scope="col" class="td-nb-y td-nb-x" :key="ix + 'b'">Total Revenue</th>
							<th scope="col" class="td-nb-y td-nb-x" :key="ix + 'c'">Paid</th>
							<th scope="col" class="td-nb-y td-nb-x" :key="ix + 'd'">Total Paid</th>
							<th scope="col" class="td-nb-y td-nb-x" :key="ix + 'e'">Exchange Gain (Loss)</th>
							<th scope="col" class="td-nb-y td-nb-x" :key="ix + 'f'">Total Exchange Gain (Loss)</th>
							<th scope="col" class="td-nb-y td-nb-x" :key="ix + 'g'">Adjustment Surplus</th>
							<th scope="col" class="td-nb-y td-nb-x" :key="ix + 'h'">Total Adjustment Surplus</th>
							<th scope="col" class="td-nb-y td-nb-x" :key="ix + 'i'">Credit Surplus</th>
							<th scope="col" class="td-nb-y td-nb-x" :key="ix + 'j'">Total Credit Surplus</th>
							<th scope="col" class="td-nb-y td-nb-x" :key="ix + 'k'">Deferred Revenue</th>
							<th scope="col" class="td-nb-y td-nb-l" :key="ix + 'l'">Accounts Receivable</th>
						</template>
					</tr>
					<template v-for="customer in report">
						<tr
							@click="doHighlight(customer.id)"
							:key="customer.id + 'a'"
							class="sticky-header customer-header"
							:class="{ 'highlighted': customer.id == selectedId }"
						>
							<th class="td-nb-x">
								<div
									@click.stop="$set(customer, 'expanded', !customer.expanded)"
									class="px-2 ml-2 d-flex align-center rounded-l-lg cursor-pointer td-nb-r"
									style="height: 32px; width: 120px; position: relative; white-space: nowrap; overflow: hidden; text-overflow: ellipsis"
									:class="$vuetify.theme.isDark ? 'new-back lighten2 bordered' : 'lightgray--lighten'"
								>
									<v-icon small :class="{ 'rotate-180': customer.expanded }">
										mdi-chevron-down
									</v-icon>
									{{ customer.name }}
								</div>
							</th>
							<th class="td-nb-x">
								<div
									@click.stop="$set(customer, 'expanded', !customer.expanded)"
									class="px-2 d-flex align-center justify-center cursor-pointer td-nb-x" style="height: 32px; width: 70px"
									:class="$vuetify.theme.isDark ? 'new-back lighten2 bordered' : 'lightgray--lighten'"
								>
									
									<v-menu open-on-hover offset-x nudge-right="10">
										<template #activator="{ on, attrs }">
											<span v-on="on" v-bind="attrs">
												{{ customer.billables.filter(x => x.code != 'Without Billable').length }}
											</span>
										</template>
										<div class="d-flex flex-column py-2 px-1 caption align-center background"
											style="gap: 10px">
											<span v-for="billable in customer.billables.filter(x => x.code != 'Without Billable')" :key="billable.id">
												{{ billable.code }}
											</span>
										</div>
									</v-menu>
								</div>
							</th>
							<th class="td-nb-l">
								<div
									@click.stop="$set(customer, 'expanded', !customer.expanded)"
									class="px-2 mr-2 d-flex align-center justify-center rounded-r-lg cursor-pointer td-nb-l" style="height: 32px; width: 110px"
									:class="$vuetify.theme.isDark ? 'new-back lighten2 bordered' : 'lightgray--lighten'"
								>
									<v-menu open-on-hover offset-x nudge-right="10">
										<template #activator="{ on, attrs }">
											<span v-on="on" v-bind="attrs">
												{{ customer.customerProjects.length }}
											</span>
										</template>
										<div class="d-flex flex-column py-2 px-1 background" style="gap: 10px">
											<div v-for="project in customer.customerProjects" :key="project.id"
												class="project d-flex"
												:style="{ background: projectTypes.find(t => t.value == project.type).lightColor }">
												<div class="letter caption"
													:style="{ background: projectTypes.find(t => t.value == project.type).color }">
													{{ projectTypes.find(t => t.value == project.type).small }}
												</div>
												<div class="name caption">{{ project.name }}</div>
											</div>
										</div>
									</v-menu>
								</div>
							</th>
	
							<template v-for="(period, ix) in customer.periodRevenues">
								<td :key="ix + 'a'" class="quantity-cell-customer">
									<div>
										<div
											v-if="period.finalRevenue"
											@mouseenter="openAmountMenu($event, period.revenue)"
											@mouseleave="amountMenu.show = false"
											:style="{ background: getBackground(period.finalRevenue, period.revenue) }">
											<b>{{ currencyFormat(period.finalRevenue) }}</b>
										</div>
										<b v-else>-</b>
									</div>
								</td>
								<td :key="ix + 'b'" class="quantity-cell-customer">
									<div>
										<div v-if="period.finalTotalRevenue"
											@mouseenter="openAmountMenu($event, period.totalRevenue)"
											@mouseleave="amountMenu.show = false"
											:style="{ background: getBackground(period.finalTotalRevenue, period.totalRevenue) }">
											<b>
												{{ currencyFormat(period.finalTotalRevenue) }}
											</b>
										</div>
										<b v-else>-</b>
									</div>
								</td>
								<td :key="ix + 'c'" class="quantity-cell-customer">
									<div>
										<div v-if="period.finalPaid" @mouseenter="openAmountMenu($event, period.paid)"
											@mouseleave="amountMenu.show = false"
											:style="{ background: getBackground(period.finalPaid, period.paid) }">
											<b>{{ currencyFormat(period.finalPaid) }}</b>
										</div>
										<b v-else>-</b>
									</div>
								</td>
								<td :key="ix + 'd'" class="quantity-cell-customer">
									<div>
										<div v-if="period.finalTotalPaid"
											@mouseenter="openAmountMenu($event, period.totalPaid)"
											@mouseleave="amountMenu.show = false"
											:style="{ background: getBackground(period.finalTotalPaid, period.totalPaid) }">
											<b>{{ currencyFormat(period.finalTotalPaid) }}</b>
										</div>
										<b v-else>-</b>
									</div>
								</td>
								<td :key="ix + 'e'" class="quantity-cell-customer">
									<div>
										<div v-if="period.finalExchangeGainLoss" @mouseenter="openAmountMenu($event, period.exchangeGainLoss)"
											@mouseleave="amountMenu.show = false"
											:style="{ background: getBackground(period.finalExchangeGainLoss, period.exchangeGainLoss) }">
											<b>{{ negativeCurrencyFormat(period.finalExchangeGainLoss) }}</b>
										</div>
										<span v-else>-</span>
									</div>
								</td>
								<td :key="ix + 'f'" class="quantity-cell-customer">
									<div>
										<div v-if="period.finalTotalExchangeGainLoss" @mouseenter="openAmountMenu($event, period.totalExchangeGainLoss)"
											@mouseleave="amountMenu.show = false"
											:style="{ background: getBackground(period.finalTotalExchangeGainLoss, period.totalExchangeGainLoss) }">
											<b>{{ negativeCurrencyFormat(period.finalTotalExchangeGainLoss) }}</b>
										</div>
										<span v-else>-</span>
									</div>
								</td>
								<td :key="ix + 'g'" class="quantity-cell-customer">
									<div>
										<div v-if="period.finalAdjustmentSurplus" @mouseenter="openAmountMenu($event, period.adjustmentSurplus)"
											@mouseleave="amountMenu.show = false"
											:style="{ background: getBackground(period.finalAdjustmentSurplus, period.adjustmentSurplus) }">
											<b>{{ negativeCurrencyFormat(period.finalAdjustmentSurplus) }}</b>
										</div>
										<span v-else>-</span>
									</div>
								</td>
								<td :key="ix + 'h'" class="quantity-cell-customer">
									<div>
										<div v-if="period.finalTotalAdjustmentSurplus" @mouseenter="openAmountMenu($event, period.totalAdjustmentSurplus)"
											@mouseleave="amountMenu.show = false"
											:style="{ background: getBackground(period.finalTotalAdjustmentSurplus, period.totalAdjustmentSurplus) }">
											<b>{{ negativeCurrencyFormat(period.finalTotalAdjustmentSurplus) }}</b>
										</div>
										<span v-else>-</span>
									</div>
								</td>
								<td :key="ix + 'i'" class="quantity-cell-customer">
									<div>
										<div v-if="period.finalCreditSurplus" @mouseenter="openAmountMenu($event, period.creditSurplus)"
											@mouseleave="amountMenu.show = false"
											:style="{ background: getBackground(period.finalCreditSurplus, period.creditSurplus) }">
											<b>{{ negativeCurrencyFormat(period.finalCreditSurplus) }}</b>
										</div>
										<span v-else>-</span>
									</div>
								</td>
								<td :key="ix + 'j'" class="quantity-cell-customer">
									<div>
										<div v-if="period.finalTotalCreditSurplus" @mouseenter="openAmountMenu($event, period.totalCreditSurplus)"
											@mouseleave="amountMenu.show = false"
											:style="{ background: getBackground(period.finalTotalCreditSurplus, period.totalCreditSurplus) }">
											<b>{{ negativeCurrencyFormat(period.finalTotalCreditSurplus) }}</b>
										</div>
										<span v-else>-</span>
									</div>
								</td>
								<td :key="ix + 'k'" class="quantity-cell-customer">
									<div>
										<b>
											{{ period.finalDeferredRevenue ? currencyFormat(period.finalDeferredRevenue) : '-' }}
										</b>
									</div>
								</td>
								<td :key="ix + 'l'" class="quantity-cell-customer td-br">
									<div>
										<b>
											{{ period.finalAccountsReceivable ? currencyFormat(period.finalAccountsReceivable) : '-' }}
										</b>
									</div>
								</td>
							</template>
						</tr>
						<template v-if="customer.expanded">
							<template v-for="billable in customer.billables">
								<tr
									v-for="(project, jx) in billable.projects" :key="billable.id + project.id"
									@click="doHighlight(billable.id)" class="sticky-header"
									:class="{ 'highlighted': billable.id == selectedId, 'non-billable': billable.code == 'Without Billable' }"
								>
									<td v-if="!jx" :rowspan="billable.projects.length" class="td-nb"></td>
									<td v-if="!jx"  :rowspan="billable.projects.length" class="td-nb">
										<div class="st py-1" style="width: 69px">
											<div
												style="
													width: 139px; margin-left: -110px;
													white-space: nowrap; overflow: hidden; text-overflow: ellipsis;
												"
											>
												{{ billable.code }}
											</div>
										</div>
									</td>
									<td class="td-nb-l td-nb-y text-right pr-2 third">
										<div
											v-if="project.id"
											class="project d-inline-flex my-1"
											:style="{ background: projectTypes.find(t => t.value == project.type).lightColor }"
										>
											<div class="letter caption"
												:style="{ background: projectTypes.find(t => t.value == project.type).color }"
											>
												{{ projectTypes.find(t => t.value == project.type).small }}
											</div>
	
											<div
												:title="project.name" class="sticky align-center fill-height pr-1"
												style="display: block; max-width: 90px; color: black; overflow: hidden; text-overflow: ellipsis;"
											>
												{{ project.name }}
											</div>
										</div>
										<div v-else>-</div>
									</td>
									
									<template v-for="(period, kk) in billable.periodRevenues">
										<td v-if="!jx" :key="kk + 'a'" :rowspan="billable.projects.length" class="quantity-cell">
											<div v-if="period.finalRevenue"
												@mouseenter="openAmountMenu($event, period.revenue)"
												@mouseleave="amountMenu.show = false"
												:style="{ background: getBackground(period.finalRevenue, period.revenue) }">
												<span>{{ currencyFormat(period.finalRevenue) }}</span>
											</div>
											<span v-else>-</span>
										</td>
										<td v-if="!jx" :key="kk + 'b'" :rowspan="billable.projects.length" class="quantity-cell">
											<div v-if="period.finalTotalRevenue"
												@mouseenter="openAmountMenu($event, period.totalRevenue)"
												@mouseleave="amountMenu.show = false"
												:style="{ background: getBackground(period.finalTotalRevenue, period.totalRevenue) }">
												<span>{{ currencyFormat(period.finalTotalRevenue) }}</span>
											</div>
											<span v-else>-</span>
										</td>
										<td v-if="!jx" :key="kk + 'c'" :rowspan="billable.projects.length" class="quantity-cell">
											<div v-if="period.finalPaid" @mouseenter="openAmountMenu($event, period.paid)"
												@mouseleave="amountMenu.show = false"
												:style="{ background: getBackground(period.finalPaid, period.paid) }">
												<span>{{ currencyFormat(period.finalPaid) }}</span>
											</div>
											<span v-else>-</span>
										</td>
										<td v-if="!jx" :key="kk + 'd'" :rowspan="billable.projects.length" class="quantity-cell">
											<div v-if="period.finalTotalPaid"
												@mouseenter="openAmountMenu($event, period.totalPaid)"
												@mouseleave="amountMenu.show = false"
												:style="{ background: getBackground(period.finalTotalPaid, period.totalPaid) }">
												<span>{{ currencyFormat(period.finalTotalPaid) }}</span>
											</div>
											<span v-else>-</span>
										</td>
										<td v-if="!jx" :key="kk + 'e'" :rowspan="billable.projects.length" class="quantity-cell">
											<div v-if="period.finalExchangeGainLoss" @mouseenter="openAmountMenu($event, period.exchangeGainLoss)"
												@mouseleave="amountMenu.show = false"
												:style="{ background: getBackground(period.finalExchangeGainLoss, period.exchangeGainLoss) }">
												<span>{{ negativeCurrencyFormat(period.finalExchangeGainLoss) }}</span>
											</div>
											<span v-else>-</span>
										</td>
										<td v-if="!jx" :key="kk + 'f'" :rowspan="billable.projects.length" class="quantity-cell">
											<div v-if="period.finalTotalExchangeGainLoss" @mouseenter="openAmountMenu($event, period.totalExchangeGainLoss)"
												@mouseleave="amountMenu.show = false"
												:style="{ background: getBackground(period.finalTotalExchangeGainLoss, period.totalExchangeGainLoss) }">
												<span>{{ negativeCurrencyFormat(period.finalTotalExchangeGainLoss) }}</span>
											</div>
											<span v-else>-</span>
										</td>
										<td v-if="!jx" :key="kk + 'g'" :rowspan="billable.projects.length" class="quantity-cell">
											<div v-if="period.finalAdjustmentSurplus" @mouseenter="openAmountMenu($event, period.adjustmentSurplus)"
												@mouseleave="amountMenu.show = false"
												:style="{ background: getBackground(period.finalAdjustmentSurplus, period.adjustmentSurplus) }">
												<span>{{ negativeCurrencyFormat(period.finalAdjustmentSurplus) }}</span>
											</div>
											<span v-else>-</span>
										</td>
										<td v-if="!jx" :key="kk + 'h'" :rowspan="billable.projects.length" class="quantity-cell">
											<div v-if="period.finalTotalAdjustmentSurplus" @mouseenter="openAmountMenu($event, period.totalAdjustmentSurplus)"
												@mouseleave="amountMenu.show = false"
												:style="{ background: getBackground(period.finalTotalAdjustmentSurplus, period.totalAdjustmentSurplus) }">
												<span>{{ negativeCurrencyFormat(period.finalTotalAdjustmentSurplus) }}</span>
											</div>
											<span v-else>-</span>
										</td>
										<td v-if="!jx" :key="kk + 'i'" :rowspan="billable.projects.length" class="quantity-cell">
											<div v-if="period.finalCreditSurplus" @mouseenter="openAmountMenu($event, period.creditSurplus)"
												@mouseleave="amountMenu.show = false"
												:style="{ background: getBackground(period.finalCreditSurplus, period.creditSurplus) }">
												<span>{{ negativeCurrencyFormat(period.finalCreditSurplus) }}</span>
											</div>
											<span v-else>-</span>
										</td>
										<td v-if="!jx" :key="kk + 'j'" :rowspan="billable.projects.length" class="quantity-cell">
											<div v-if="period.finalTotalCreditSurplus" @mouseenter="openAmountMenu($event, period.totalCreditSurplus)"
												@mouseleave="amountMenu.show = false"
												:style="{ background: getBackground(period.finalTotalCreditSurplus, period.totalCreditSurplus) }">
												<span>{{ negativeCurrencyFormat(period.finalTotalCreditSurplus) }}</span>
											</div>
											<span v-else>-</span>
										</td>
										<td v-if="!jx" :key="kk + 'k'" :rowspan="billable.projects.length" class="quantity-cell">
											<span>
												{{ period.finalDeferredRevenue ? currencyFormat(period.finalDeferredRevenue) : '-' }}
											</span>
										</td>
										<td v-if="!jx" :key="kk + 'l'" :rowspan="billable.projects.length" class="quantity-cell td-br">
											<span>
												{{ period.finalAccountsReceivable ? currencyFormat(period.finalAccountsReceivable) : '-' }}
											</span>
										</td>
									</template>
								</tr>
							</template>

						</template>
					</template>
				</tbody>
			</table>
			<div class="pagination-container">
				<div class=" d-flex justify-end py-2">
					<div class="d-flex align-center mr-5" style="gap: 15px; white-space: nowrap">
						Rows per page:
						<v-select v-model="pagination.pageSize" :items="[5, 10, 20, 'All']" dense hide-details
							style="width: 50px" class="caption" />
					</div>
					<div style="position: relative">
						<v-pagination
							v-model="pagination.page"
							@input="getReport"
							:length="pagination.pages"
							:total-visible="0"
						/>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import moment from "moment"
import BillableJSON from '../../json/billable.json'
export default {
	data: () => ({
		selectedId: null,
		report: [],
		billablesSortedByDate: true,
		timeUnit: 2,
		filters: {
			customer: [],
			currency: 'MXN',
			showOnZero: false,
			receivablesDeferredOnly: false,
		},
		currencies: ['MXN', 'USD', 'EUR'],
		projectTypes: [],

		exchangeRateOrigin: 1,
		exchangeRateDestination: 1,
		exchangeRateCurrency: '',
		exchangeRateOrigin2: 1,
		exchangeRateDestination2: 1,
		exchangeRateCurrency2: '',
		customers: [],
		filteredCustomers: [],
		amountMenu: {
			amount: {},
		},

		pagination: {
			page: 1,
			pageSize: 10,
			pages: 1,
		},
	}),
	created() {
		document.title = 'Revenue Report'
		this.$q.log(4, 28, 3, 'NA')
		this.setUserPreferences('revenueReport', 'filters')
		this.setUserPreferences('revenueReport', 'exchangeRateOrigin')
		this.setUserPreferences('revenueReport', 'exchangeRateDestination')
		this.setUserPreferences('revenueReport', 'exchangeRateOrigin2')
		this.setUserPreferences('revenueReport', 'exchangeRateDestination2')
		this.filters.startDate = moment().startOf('year').format('YYYY-MM-DD')

		this.projectTypes = BillableJSON.projectTypes

		this.$http.get('api/customer')
			.then(res => {
				this.customers = res.data
			})
			.catch(err => {
				console.error(err)
				this.$root.error('Cannot get customers')
			})
		this.getReport()
	},
	computed: {
		dates() {
			if (!this.report.length) return []

			return this.report[0].periodRevenues
				.map(x => moment(x.date))
		},
		dateFormat() {
			let format = 'MMM YYYY'
			if (this.timeUnit == 0)
				format = 'MMM D, YYYY'
			if (this.timeUnit == 1)
				format = 'W, YYYY'
			return format
		},
		allExpanded() {
			return this.report.every(x => x.expanded)
		},
	},
	methods: {
		sortBillables() {
			if(this.billablesSortedByDate) {
				this.report = this.report.map(x => {
					x.billables = x.billables.sort((a, b) => {
						if (a.code == 'Without Billable') return 1
						if (b.code == 'Without Billable') return -1
						return a.code.localeCompare(b.code)
					})
					return x
				})
			}
			else {
				this.report = this.report.map(x => {
					x.billables = x.billables.sort((a, b) => {
						if (a.code == 'Without Billable') return 1
						if (b.code == 'Without Billable') return -1
						return moment(a.createdAt).isBefore(moment(b.createdAt)) ? -1 : 1
					})
					return x
				})
			}
			this.billablesSortedByDate = !this.billablesSortedByDate
		},

		doHighlight(id){
			if(id == this.selectedId){
				this.selectedId = null
			}
			else{
				this.selectedId = id
			}
		},

		toggleExpandAll() {
			let expanded = !this.allExpanded
			this.report.forEach(x => this.$set(x, 'expanded', expanded))
		},

		getReport() {
			if(this.timeUnit == 1) {
				if(this.filters.startDate)
					this.filters.startDate = moment(this.filters.startDate).startOf('week').format('YYYY-MM-DD')
				if(this.filters.endDate)
					this.filters.endDate = moment(this.filters.endDate).startOf('week').format('YYYY-MM-DD')
			}

			const filters = {
				page: this.pagination.page,
				pageSize: this.pagination.pageSize != 'All' ? this.pagination.pageSize : -1,
				customerIds: this.filters.customer,
				timeUnit: this.timeUnit,
				showOnZero: this.filters.showOnZero,
				receivablesDeferredOnly: this.filters.receivablesDeferredOnly,
				startDate: this.filters.startDate,
				endDate: this.filters.endDate,
				currency: this.filters.currency,
				exchangeRateOrigin: this.exchangeRateOrigin,
				exchangeRateDestination: this.exchangeRateDestination,
				exchangeRateCurrency: this.exchangeRateCurrency,
				exchangeRateOrigin2: this.exchangeRateOrigin2,
				exchangeRateDestination2: this.exchangeRateDestination2,
				exchangeRateCurrency2: this.exchangeRateCurrency2,
			}

			this.$http.post('api/customer/revenue', filters)
				.then(res => {
					this.report = res.data.customers
						.map(x => {
							x.billables = x.billables.map(y => {
								if(!y.projects.length)
									y.projects.push({})
								return y
							})
							return x
						})
					
					for (let customer of this.report) {
						this.setFinalPeriodValues(customer.periodRevenues)

						for (let billable of customer.billables) {
							this.setFinalPeriodValues(billable.periodRevenues)
						}
					}

					this.pagination.pages = res.data.totalPages
					if(this.pagination.page > this.pagination.pages){
						this.pagination.page = this.pagination.pages
					}

					this.$nextTick(() => {
						const yearBegin = moment().startOf('year')
						const scrollXFactor = this.dates.filter(x => x.isBefore(yearBegin, 'month')).length
						this.$nextTick(() => {
							this.$refs.chart.scrollLeft = scrollXFactor * 110.5 * 12
						})
					})

				})
				.catch(err => {
					console.error(err)
					this.$root.error('Cannot get data')
				})
		},
		negativeCurrencyFormat(amount) {
			if (amount < 0) return `(${this.currencyFormat(-amount)})`
			return this.currencyFormat(amount)
		},
		setFinalPeriodValues(periods) {
			for (let period of periods) {
				this.$set(period, 'finalRevenue', this.getFinalAmount(period.revenue))
				this.$set(period, 'finalTotalRevenue', this.getFinalAmount(period.totalRevenue))
				this.$set(period, 'finalPaid', this.getFinalAmount(period.paid))
				this.$set(period, 'finalTotalPaid', this.getFinalAmount(period.totalPaid))
				this.$set(period, 'finalAdjustmentSurplus', this.getFinalAmount(period.adjustmentSurplus))
				this.$set(period, 'finalTotalAdjustmentSurplus', this.getFinalAmount(period.totalAdjustmentSurplus))
				this.$set(period, 'finalCreditSurplus', this.getFinalAmount(period.creditSurplus))
				this.$set(period, 'finalTotalCreditSurplus', this.getFinalAmount(period.totalCreditSurplus))
				this.$set(period, 'finalExchangeGainLoss', this.getFinalAmount(period.exchangeGainLoss))
				this.$set(period, 'finalTotalExchangeGainLoss', this.getFinalAmount(period.totalExchangeGainLoss))
				
				let diff = {
					eur: period.totalRevenue.eur - (period.totalPaid.eur - period.totalExchangeGainLoss?.eur) + period.totalAdjustmentSurplus?.eur + period.totalCreditSurplus?.eur,
					usd: period.totalRevenue.usd - (period.totalPaid.usd - period.totalExchangeGainLoss?.usd) + period.totalAdjustmentSurplus?.usd + period.totalCreditSurplus?.usd,
					mxn: period.totalRevenue.mxn - (period.totalPaid.mxn - period.totalExchangeGainLoss?.mxn) + period.totalAdjustmentSurplus?.mxn + period.totalCreditSurplus?.mxn,
				}
				let finalDiff = this.getFinalAmount(diff)

				if(finalDiff < 0) {
					this.$set(period, 'finalDeferredRevenue', -finalDiff)
					this.$set(period, 'finalAccountsReceivable', 0)
				}
				else {
					this.$set(period, 'finalDeferredRevenue', 0)
					this.$set(period, 'finalAccountsReceivable', finalDiff)
				}
			}
		},
		getFinalAmount(amount) {
			let finalAmount = 0
			for (const key in amount) {
				const upperKey = key.toUpperCase()
				if(!this.currencies.includes(upperKey)) continue
				if (amount[key]) {
					if (upperKey != this.filters.currency) {
						if (this.exchangeRateCurrency && upperKey != this.exchangeRateCurrency) {
							this.exchangeRateCurrency2 = upperKey
							finalAmount += amount[key] * this.exchangeRateOrigin2 / (this.exchangeRateDestination2 || 1)
						}
						else {
							this.exchangeRateCurrency = upperKey
							finalAmount += amount[key] * this.exchangeRateOrigin / (this.exchangeRateDestination || 1)
						}
					}
					else {
						finalAmount += amount[key]
					}
				}
			}
			return finalAmount
		},
		handleExchangeRate(newValue, property) {
			if (newValue == 1) return
			this[property] = 1
			this.getReport()
		},
		getBackground(final, amount) {
			if (final == amount[this.filters.currency.toLowerCase()] || !final) return ''
			return this.$vuetify.theme.isDark ? '#0C2D48' : '#FFFDD0'
		},
		openAmountMenu(e, amount) {
			const some = this.currencies.some(x => amount[x.toLowerCase()] && x != this.filters.currency)
			if (!some && !amount.details?.length) return
			const rect = e.target.getBoundingClientRect()
			this.amountMenu = {
				show: true,
				amount,
				x: rect.x + 120,
				y: rect.y,
			}
		},
	},
	watch: {
		pagination: {
			handler() {
				this.getReport()
			},
			deep: true,
		},
		exchangeRateCurrency() {
			this.getReport()
		},
		exchangeRateCurrency2() {
			this.getReport()
		},
	},
}
</script>

<style lang="scss" scoped>
.highlighted {
	background-color: #c4dcec !important;
	td, th {
		background-color: #c4dcec !important;
	}
}

::v-deep tr.sticky-header{
	th:nth-child(1),
	th:nth-child(2),
	th:nth-child(3),
	td:nth-child(1),
	td:nth-child(3),
	td:nth-child(2) {
		position: sticky;
	}
	&:first-child {
		th:nth-child(1),
		th:nth-child(2),
		th:nth-child(3) {
			z-index: 3;
		}
	}
	th:nth-child(1), td:nth-child(1) {
		left: -1px;
	}
	th:nth-child(2), td:nth-child(2) {
		left: 128px;
	}
	th:nth-child(3), td:nth-child(3), td.third {
		left: 198px;

		&::after {
			content: "";
			position: absolute;
			right: -1px; top: 0;
			height: 100%;
			width: 1px;
			background: var(--v-lightgray-lighten);
		}
	}
}

table, th, td {
  border: 1px solid var(--v-lightgray-lighten);
  border-collapse: collapse;
	background: var(--v-newDesignBackground-base);
}

table tr{
	background: var(--v-newDesignBackground-base);
	&.colored-header{
		border-top: 1px solid var(--v-lightgray-lighten);
		th:nth-child(odd) {
			background: var(--v-coloredBack-base) !important;
		}
		th {
			width: 110px;
		}
	}
}

tbody {
	tr:first-child, tr:nth-child(2), .customer-header {
		position: sticky;
	}
	tr:first-child {
		top: -1px;
		&::after {
			content: "";
			position: absolute;
			bottom: -1px; left: 0;
			width: 100%;
			height: 1px;
			background: var(--v-lightgray-lighten);
		}
		z-index: 2;
	}
	tr:nth-child(2) {
		top: 20px;
		z-index: 1;
		&::after {
			content: "";
			position: absolute;
			bottom: -1px; left: 0;
			width: 100%;
			height: 1px;
			background: var(--v-lightgray-lighten);
		}
	}
	.customer-header {
		top: 58px;
		z-index: 2;
		
		&::after {
			content: "";
			position: absolute;
			top: 1px; left: 0;
			width: 100%; height: calc(100% - 1px);
			background: transparent;
			border-top: 1px solid var(--v-lightgray-lighten);
			border-bottom: 1px solid var(--v-lightgray-lighten);
			pointer-events: none;
		}
	}
}

tfoot {
	border-top: 1px solid var(--v-lightgray-lighten);
}

tr:not(.highlighted, .not-hover):hover {
	background: var(--v-lightgray-lighten2);

	> th, > td {
		background: var(--v-lightgray-lighten2) !important;
	}
}

.td-nb {
	border: none !important;
}
.td-nb-y {
	border-top: none !important;
	border-bottom: none !important;
}
.td-nb-x {
	border-left: none !important;
	border-right: none !important;
}
.td-nb-l {
	border-left: none !important;
}
.td-nb-r {
	border-right: none !important;
}
.td-br {
	border-right: 1px solid var(--v-lightgray-lighten) !important;
}
.td-bb::before{
	content: "";
	position: absolute;
	bottom: -1px; left: 0;
	width: 100%;
	height: 1px;
	background: var(--v-lightgray-lighten);
}

tr > td.sticky,
tr > th.sticky {
	position: sticky !important;
	left: 0;
	z-index: 1;
	background: var(--v-newDesignBackground-base);
  border-collapse: separate;
	border-spacing: 0;
}

.quantity-cell-customer {
	text-align: right;
	border: none;
	border-top: 1px solid var(--v-lightgray-lighten) !important;
	height: 54px;
	
	> * > * {
		padding-right: 5px;
	}

	> div {
		width: 110px;
		height: 54px;
		line-height: 54px;
	}
}
.quantity-cell {
	text-align: right;
	border: none;
	height: 26px;
	
	> * {
		padding-right: 5px;
	}
	
	> div {
		width: 110px;
		height: 26px;
		line-height: 26px;
	}
}

.project {
	border-radius: 10px;
	white-space: nowrap;
	height: 20px;

	div {
		display: inline-flex;
		align-items: center;
	}

	.letter {
		width: 20px;
		height: 20px;
		padding: 5px;
		justify-content: center;
		color: white;
		border-radius: 10px;
	}

	.name {
		padding: 0 10px 0 2px;
		color: black;
	}
}

.chart::-webkit-scrollbar {
	height: 8px;
	background: var(--v-newDesignBackground-base);
	border-radius: 15px;
}

.chart::-webkit-scrollbar-thumb {
	background: var(--v-background-lighten2);
	border: 4px solid transparent;
	border-radius: 8px;

	&:hover {
		background: var(--v-coloredBack-base);
	}
}

.chart {
	max-height: calc(100vh - 20px);
	width: calc(100vw - 190px);
	overflow: scroll;
	font-size: .8em;
}

.header {
	position: sticky;
	top: 0;
	z-index: 2;
}

.customer-header-cont {
	padding: 10px 10px;

	.customer-header {
		padding: 5px 5px 5px 10px !important;
	}
}

.line {
	display: flex;
	width: max-content;
	width: 100%;
	background: var(--v-newDesignBackground-base);

	&:hover {
		background: var(--v-lightgray-lighten2);
	}

	.sticky {
		display: flex;
		position: sticky;
		align-items: flex-end;
		background: inherit;
		left: 0;
		z-index: 1;
		border-right: 2px solid var(--v-lightgray-lighten);
		overflow: hidden;
		width: 325px;

		.st {
			display: flex;
			align-items: center;
			padding: 10px 5px 10px 10px;

			div {
				white-space: nowrap;
				overflow: hidden;
				text-overflow: ellipsis;
			}
		}

		.head {
			border-bottom: 2px solid var(--v-lightgray-lighten);
		}
	}

	.date {
		width: 670px;
		padding-top: 5px;
		border: 1px solid var(--v-lightgray-lighten);
		background: var(--v-newDesignBackground-base);
		border-bottom-width: 2px;
		border-top: 0;

		&.no-border {
			border-left: none !important;
		}

		.head {
			font-size: 1.2em;
		}
	}

	.period-grid {
		display: grid;
		grid-template-columns: repeat(6, 1fr);
		width: 670px;

		::v-deep>* {
			padding-right: 5px !important;
		}
	}
}

.data-container {
	.customer-lines {
		border-bottom: 1px solid var(--v-lightgray-lighten);

		.line {
			contain: paint;
		}
	}
}

.pagination-container {
	position: sticky;
	left: 0px;
}

.non-billable{
	font-style: italic;
}

::v-deep .v-pagination__navigation {
	box-shadow: none !important;
	background: transparent !important;
}
</style>