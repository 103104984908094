import Vue from "vue"
import moment from 'moment'

Vue.filter('formatDateCustom', function(value, format) {
  if (value) {
    return moment(String(value)).format(format)
  }
});

Vue.filter('formatDateTime', function(value) {
  if (value) {
    return moment(String(value)).format('MMM D, YYYY h:mm A')
  }
});

Vue.filter('formatDate', function(value) {
  if (value) {
    return moment(String(value)).format('MMM D, YYYY');
  }
});

Vue.filter('formatDateWeekDay', function(value) {
  if (value) {
    return moment(String(value)).format('(ddd) MMM D, YYYY');
  }
});

Vue.filter('formatDateWeekYear', function(value) {
  if (value) {
    return moment(String(value)).format('GGGG-WW');
  }
});
Vue.filter('formatDateMonthYear', function(value) {
  if (value) {
    return moment(String(value)).format('MMM-YYYY');
  }
});
Vue.filter('number', function(value, decimalPlaces, minDecimalPlaces, scriptOnZero = false) {
  if(!value && scriptOnZero) return '-';

  if (value === null || value === undefined || !value.toFixed) return;
  if (!decimalPlaces) {
    decimalPlaces = 0;
  }
  if (!minDecimalPlaces && minDecimalPlaces !== 0) {
    minDecimalPlaces = decimalPlaces;
  }
  return value.toLocaleString('en', {
    maximumFractionDigits: decimalPlaces,
    minimumFractionDigits: minDecimalPlaces,
  });
});
