<template>
	<v-dialog fullscreen scrollable v-if="dialog" v-model="dialog" @keydown.esc="cancel">
		<div class="carousel" @click="cancel">
			<div class="icon-close">
				<v-icon x-large color="white" @click="cancel">close</v-icon>
			</div>

			<v-carousel
				v-model="model"
				hide-delimiters
				show-arrows-on-hover
				style="height: calc(100% - 100px); width: 90%;"
			>
				<v-carousel-item
					v-for="(img, i) in images"
					:key="i"
					style="height: 100%;"
					@click="cancel"
				>
					<div class="main-img-cont">
						<img @click.stop="() => {}" :src="img.url">
					</div>
				</v-carousel-item>
				<template v-slot:prev="{ on, attrs }">
					<div class="cont" v-bind="attrs" v-on="on">
						<v-btn fab color="secondary">
							<v-icon x-large>mdi-chevron-left</v-icon>
						</v-btn>
					</div>
				</template>
				<template v-slot:next="{ on, attrs }">
					<div class="cont" v-bind="attrs" v-on="on">
						<v-btn fab color="secondary">
							<v-icon x-large>mdi-chevron-right</v-icon>
						</v-btn>
					</div>
				</template>
			</v-carousel>
			<div class="delimiters">
				<div
					class="mini-img-cont"
					v-for="(img, i) in images"
					:key="i"
					:style="{borderColor: model == i ? 'var(--v-accent-base)' : 'transparent'}"
				>
					<v-img
						:src="img.url"
						@click.stop="model = i"
						height="70px" width="70px"
						class="mini-img"
						:class="{'cursor-pointer': model != i}"
					>
						<div class="action-btns" v-if="model == i">
							<v-icon v-if="!hideDownload" color="accent" @click="downloadFile(img.url)">mdi-download</v-icon>
							<v-icon v-if="onDelete" color="error"  @click="handleDelete(img)">delete</v-icon>
						</div>
					</v-img>
				</div>
			</div>
		</div>
	</v-dialog>
</template>

<script>
export default {
	data(){
		return {
			dialog: false,
			images: [],
			model: 0,
			onDelete: null,
			hideDownload: false,
		}
	},
	mounted(){
		window.addEventListener("keydown", this.keyPress)
	},
	methods: {
		keyPress(evt) {
			if(!this.dialog)
				return
			let sum = 0
			if(evt.key == 'ArrowRight' || evt.code == 'ArrowRight'){
				sum = 1
			}
			if(evt.key == 'ArrowLeft' || evt.code == 'ArrowLeft'){
				sum = -1
			}
			const mod = (n, m) => (n % m + m) % m;
			this.model = mod(this.model+sum, this.images.length)
		},
		open(images, selected, onDelete = null, hideDownload = false){
			this.images = images
			this.model = this.images.findIndex(img => img.url == selected.url)
			this.onDelete = onDelete
			this.hideDownload = hideDownload
			this.dialog = true
		},
		cancel(){
			this.dialog = false
		},
		downloadFile(url) {
			this.$q.download(url, 'file')
		},
		handleDelete(img){
			this.onDelete(img)
		},
	}
}
</script>

<style scoped lang="scss">
.icon-actions{
	position: absolute;
	left: 10px;
	top: 10px;
}
.icon-close{
	position: absolute;
	right: 30px;
	top: 10px;
}
.carousel{
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	background: rgba(0, 0, 0, 0.8);

	.main-img-cont{
		height: 100%;
		display: flex;
		justify-content: center;

		img{
			max-width: 90%;
			object-fit: contain;
		}
	}
}
.delimiters{
	width: 100%;
	color: white;
	text-align: center;
	margin-top: 10px;

	.mini-img-cont{
		display: inline-block;
		margin-right: 10px;
		padding-bottom: 5px;
		.mini-img{
			border-radius: 5px;

			.action-btns{
				width: 100%;
				height: 100%;
				background: rgba(0, 0, 0, 0.6);
				display: flex;
				justify-content: space-evenly;
				align-items: center;
			}
		}
		border-bottom: 2px solid transparent;
	}
}
::v-deep .v-window__container, ::v-deep .v-image.v-carousel__item{
	height: 100% !important;
}
::v-deep .v-responsive__sizer{
	padding: 0 !important;
}
::v-deep .v-window__prev, ::v-deep .v-window__next{
	top: 0;
	height: 100% !important;
	background-color: transparent;
	.cont{
		height: 100% !important;
		display: flex;
		align-items: center;
		cursor: pointer;
		transition: all 0.5s;
	}
}
::v-deep .v-ripple__container{
	display: none;
}
</style>