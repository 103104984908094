<template>
	<div class="pa-3 new-back full-height">

		<filter-search-input v-model="filters.search" :applied-filters="appliedFilters" outlined dense>
			<template #append>
				<v-tooltip v-if="permissions.add" top z-index="200">
					<template #activator="{ on }">
						<v-btn v-on="on" @click="$router.push('supplierInvoice/new')" fab color="primary" class="float-right" small>
							<v-icon>mdi-plus</v-icon>
						</v-btn>
					</template>
					<span class="caption">Add new</span>
				</v-tooltip>
			</template>
			<q-filter-input
				label="Invoice No."
				v-model="filters.invoice" :items="invoices"
				dense outlined hide-details clearable multiple
				item-text="code" item-value="id"
				:parent-shown-items="shownInvoices"
				:data-comparer="(item, value) => item.id == value"
			>
			</q-filter-input>

			<q-filter-input
				label="Supplier"
				v-model="filters.supplier" :items="suppliers"
				dense outlined hide-details clearable multiple
				item-text="name" item-value="id"
				:parent-shown-items="shownInvoices"
				:data-comparer="(item, value) => item.supplierId == value"
			>
			</q-filter-input>

			<q-date-picker
				label="Start Date"
				inner outlined dense hide-details clearable
				v-model="filters.startDate" :max="filters.endDate"
			/>

			<q-date-picker
				label="End Date"
				inner outlined dense hide-details clearable
				v-model="filters.endDate" :min="filters.startDate"
			/>

			<q-filter-input
				v-model="filters.currency" :items="currencies"
				label="Currency"
				dense outlined hide-details clearable input-select multiple
				item-text="text" item-value="text"
				:parent-shown-items="shownInvoices"
				:data-comparer="(item, value) => item.currency == value"
			>
			</q-filter-input>
			
			<q-filter-input
				v-model="filters.status" :items="statuses"
				label="Status"
				dense outlined hide-details clearable input-select multiple hide-input
				:parent-shown-items="shownInvoices"
				:data-comparer="(item, value) => item.status == value"
			>
			</q-filter-input>
		</filter-search-input>

		<data-table
			:item-class="() => permissions.detail ? 'cursor-pointer' : ''"
			:items="shownInvoices"
			:headers="headers"
			@click:row="item => permissions.detail ? $router.push('/admin/supplierInvoice/' + item.id) : null"
		>
			<template #[`item.supplier`]="{ item }">
				<div class="d-flex flex-column">
					<b>{{ item.supplierName }}</b>
					<span class="caption">{{ item.supplierBusinessName }}</span>
				</div>
			</template>
			<template #[`item.date`]="{ item }">
				{{ item.date | formatDate }}
			</template>
			<template #[`item.amount`]="{ item }">
				<b>{{ item.currency }}</b>
				{{ currencyFormat(item.total) }}
			</template>
			<template #[`item.status`]="{ item }">
				<v-btn
					:color="item.statusColor"
					outlined small style="border-radius: 20px"
					:style="{
						background: $vuetify.theme.isDark ? '' : item.statusLightColor
					}"
				>{{item.statusDisplayName}}</v-btn>
			</template>
			<template #[`item.actions`]="{ item }">
				<v-menu v-if="permissions.actions && item.status != 3" left offset-x>
					<template #activator="{ on }">
						<v-btn v-on="on" icon><v-icon>mdi-dots-vertical</v-icon></v-btn>
					</template>
					<v-list dense class="py-1">
						<v-list-item class="pa-1 py-0">
							<v-tooltip right z-index="200">
								<template #activator="{ on }">
									<v-btn v-on="on" @click="cancelInvoice(item)" text small color="error">
										<v-icon>mdi-cancel</v-icon>
									</v-btn>
								</template>
								<span class="caption">Cancel</span>
							</v-tooltip>
						</v-list-item>
					</v-list>
				</v-menu>
			</template>
		</data-table>
	</div>
</template>

<script>
import moment from "moment"

export default {
	data: () => ({
		invoices: [],
		suppliers: [],
		currencies: [{ text: 'MXN' }, { text: 'USD' }, { text: 'EUR'}],
		filters: {
			invoice: [],
			supplier: [],
			currency: [],
			status: [],
		},
		headers: [
			{ text: 'Invoice No.', value: 'code' },
			{ text: 'Supplier', value: 'supplier' },
			{ text: 'Invoice Date', value: 'date' },
			{ text: 'Amount', value: 'amount' },
			{ text: 'Status', value: 'status' },
			{ text: '', value: 'actions', sortable: false, width: 1 },
		],
		statuses: [
			{ text: 'Draft', value: 0 },
			{ text: 'Processed', value: 2 },
			{ text: 'Canceled', value: 3 },
		],

		permissions: {
			detail: false,
			add: false,
			actions: false,
		},
	}),
	computed: {
		shownInvoices(){
			let invoices = this.invoices

			if(this.filters.invoice && this.filters.invoice.length){
				invoices = invoices.filter(i => this.filters.invoice.includes(i.id))
			}
			if(this.filters.startDate){
				invoices = invoices.filter(i => moment(i.date).isSameOrAfter(this.filters.startDate))
			}
			if(this.filters.endDate){
				invoices = invoices.filter(i => moment(i.date).isSameOrBefore(this.filters.endDate))
			}
			if(this.filters.supplier && this.filters.supplier.length){
				invoices = invoices.filter(i => this.filters.supplier.includes(i.supplierId))
			}
			if(this.filters.currency && this.filters.currency.length){
				invoices = invoices.filter(i => this.filters.currency.includes(i.currency))
			}
			if(this.filters.status && this.filters.status.length){
				invoices = invoices.filter(i => this.filters.status.includes(i.status))
			}

			if(this.filters.search){
				invoices = invoices.filter(i =>
					this.normalizeIncludes(i.code, this.filters.search) ||
					this.normalizeIncludes(i.supplierName, this.filters.search) ||
					this.normalizeIncludes(this.formatDate(i.date), this.filters.search)
				)
			}

			return invoices
		},
		appliedFilters(){
			return this.countActiveFilters(this.filters, ['search'])
		},
	},
	mounted() {
		document.title = 'Supplier Invoices'

		this.$security.hasRequiredPermission('detail')
			.then(res => this.permissions.detail = res)
		this.$security.hasRequiredPermission('add')
			.then(res => this.permissions.add = res)
		this.$security.hasRequiredPermission('full_access')
			.then(res => this.permissions.actions = res)

    this.$q.log(4, 45, 3, 'NA')
		this.setUserPreferences("Invoices", "filters")
		this.init()
	},
	methods: {
		init(){
			this.$http.get('api/supplierInvoice')
				.then(res => {
					this.invoices = res.data
				})
				.catch(err => {
					console.error(err)
					this.$root.error('Failed to load invoices')
				})
			this.$http.get('api/supplier')
				.then(res => {
					this.suppliers = res.data
				})
				.catch(err => {
					console.error(err)
					this.$root.error('Failed to load suppliers')
				})
		},
		cancelInvoice(item){
			this.$adminSurf('cancelSupplierInvoice', item.id)
				.then(res => {
					if(!res) return
					this.init()
				})
				.catch(err => {
					this.$root.error('Cannot cancel invoice')
					console.error(err)
				})
		},
	},
}
</script>

<style lang="scss" scoped>
::v-deep .v-expansion-panel-content__wrap{
	padding: 0 !important;
}
</style>