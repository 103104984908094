<template>
	<div class="pa-3 new-back fill-height">

		<v-dialog
			:fullscreen="$vuetify.breakpoint.xsOnly" persistent
			v-model="dialog" v-if="dialog" width="600" content-class="dialog-content"
		>
			<v-card>
				<v-card-title>
					<h2 style="word-break: normal;"> {{selectedBadge.name}} </h2>
				</v-card-title>
				<v-card-text>
					<p style="font-size: 1.3em !important"> {{selectedBadge.description}} </p>
					<v-img height="300" contain :src="formatHttp(selectedBadge.imgUrl)"></v-img>
				</v-card-text>
				<v-card-actions>
					<div style="width: 100%">
						<div class="inline-block points">
							Points: {{selectedBadge.points}}
						</div>
						<v-btn @click="dialog = false" color="primary" class="float-right">
							Close
						</v-btn>
					</div>
				</v-card-actions>
			</v-card>
		</v-dialog>

		<filter-search-input v-model="filters.search" :applied-filters="appliedFilters" outlined dense>
			<q-user-autocomplete
				:value="filters.users" :items="users"
				multiple outlined label="People" @save="(e) => filters.users = e"
			/>
			<q-date-picker
				label="Initial Date" v-model="filters.initialDate"
				inner outlined dense hide-details clearable
				:max="filters.finalDate"
			/>
			<q-date-picker
				label="Final Date" v-model="filters.finalDate"
				inner outlined dense hide-details clearable
				:min="filters.initialDate"
			/>
			<v-btn @click="removeFilters" small fab color="primary">
				<v-icon>mdi-filter-variant-remove</v-icon>
			</v-btn>
		</filter-search-input>

		<div class="badges-filter mb-15">
			<v-btn :disabled="!canScrollLeft" fab text color="rgb(0, 186, 255)" class="mr-3" @mouseenter="sliderMovementSpeed = -3" @mouseleave="sliderMovementSpeed = 0">
				<v-icon v-if="canScrollLeft" style="font-size: 5em">mdi-chevron-left</v-icon>
			</v-btn>
			<div id="slider">
				<div class="badges-container">
					<div class="badge mx-2" :class="{selected: filters.badge.id == badge.id}" v-for="(badge, ix) in badges" :key="ix" @click="toggleFilterBadge(badge)">
						<div class="img-container text-center">
							<v-img style="display:inline-block" height="200" :src="formatHttp(badge.imgUrl)" contain></v-img>
						</div>
					</div>
				</div>
			</div>
			<v-btn :disabled="!canScrollRight" fab text color="rgb(0, 186, 255)" class="ml-3" @mouseenter="sliderMovementSpeed = 3" @mouseleave="sliderMovementSpeed = 0">
				<v-icon v-if="canScrollRight" style="font-size: 5em">mdi-chevron-right</v-icon>
			</v-btn>
		</div>

		<div v-if="filters.users.length == 1" class="pl-5">
			<div class="user-info">
				<UserAvatar :userId="selectedUser.id" hideLoading :size="70"/>
				<div class="ml-5" style="display: inline-block">
					<h2>{{selectedUser.blueTag}} ({{ selectedUser.fullName }})</h2>
					<span>Blueniversary: {{formatDate(selectedUser.blueniversary)}}</span><br>
					<span>Points: {{selectedUser.points}}</span>
				</div>
			</div>
			<br>
			<template v-if="selectedUser.badges.some(b => (!filters.badge.id || filters.badge.id == b.badgeId) && isInInterval(b.dateEarned))">
				<div
					class="float-left mr-5 mb-5 cursor-pointer"
					style="width: 200px"
					v-for="(badge, ix) in selectedUser.badges.filter(b => (!filters.badge.id || filters.badge.id == b.badgeId) && isInInterval(b.dateEarned))"
					:key="ix"
					@click="openBadge(badge.badgeId)"
				>
					<v-img height="200" contain :src="formatHttp(imgUrls[badge.badgeId])"></v-img>
					<div class="badge-details">
						<div>
							<b>Date:</b> <br>
							{{ formatDate( badge.dateEarned ) }}
						</div>
						<div class="text-right">
							<b>Points:</b> <br>
							{{ badge.points }}
						</div>
					</div>
				</div>
			</template>
			<div v-else>
				No data available <v-btn v-if="filters.badge.id" class="ml-2" color="primary" small @click="openBadge(filters.badge.id)">Badge Details</v-btn>
			</div>
		</div>

		<div v-else-if="filters.badge.id" class="pl-5">
			<h2 class="mb-3">{{filters.badge.name}}</h2>

			<v-row v-if="shownUsers.length">
				<v-col cols="12" sm="6" md="4" lg="3" v-for="user in shownUsers" :key="user.id" class="user">
					<v-card>
						<v-card-title>
							{{user.blueTag}}
							<div class="profile-image-container">
								<UserAvatar :userId="user.id" hideLoading :size="70"/>
							</div>
						</v-card-title>
						<v-card-text>
							<div class="float-left cursor-pointer" style="width: 120px" @click="openBadge(filters.badge.id)">
								<v-img height="120" style="display: inline-block" contain :src="formatHttp(imgUrls[filters.badge.id])"></v-img>
							</div>
							<div class="date pl-3 pt-3">
								<b>Date:</b> <br>
								{{ formatDate( user.badges.find(b => b.badgeId == filters.badge.id).dateEarned ) }} <br>
								<b>Points:</b> <br>
								{{ user.badges.find(b => b.badgeId == filters.badge.id).points }}
							</div>
							<div class="clearfix"></div>

							<div class="others">
								Others
								<br>
								<div class="other-badges">
									<div v-for="(badge, ix) in user.badges.filter(b => b.badgeId != filters.badge.id)" :key="ix" style="width: 70px" class="float-left mr-2 cursor-pointer" @click="openBadge(badge.badgeId)">
										<v-img height="70" style="display: inline-block" contain :src="formatHttp(imgUrls[badge.badgeId] || '')"></v-img>
									</div>
								</div>
								<div class="clearfix"></div>
							</div>
						</v-card-text>
					</v-card>
				</v-col>
			</v-row>
			<div v-else style="font-size: 1.2em">
				No data available. <v-btn class="ml-2" color="primary" small @click="openBadge(filters.badge.id)">Badge Details</v-btn>
			</div>
		</div>

		<div v-else>
			<v-data-table
				:headers="headersUsers" :items="shownUsers"
				sort-by="blueTag"
				class="rounded-lg new-back lighten bordered"
			>
				<template v-slot:[`item.badges`]="{ item }">
					<div class="mini-image-container cursor-pointer" v-for="(badge, ix) in item.badges" :key="ix" @click="openBadge(badge.badgeId)">
						<v-img height="45" contain :src="formatHttp(imgUrls[badge.badgeId] || '')"></v-img>
					</div>
				</template>
			</v-data-table>
		</div>
	</div>
</template>

<script>
import moment from "moment"
import UserAvatar from '../../components/UserAvatar'
import QDatePicker from '../../components/QDatePicker'

export default {
	components: { QDatePicker, UserAvatar },
	data(){
		return {
			dialog: false,
			badges: [],
			users: [],
			filters: {
				users: [],
				badge: {},
				initialDate: '',
				finalDate: '',
			},
			sliderMovementSpeed: 0,

			headersUsers: [
				{ text: "BlueTag", value: "blueTag" },
				{ text: "Name", value: "fullName" },
				{ text: "Points", value: "points" },
				{ class: "header-badges", text: "Badges", value: "badges", sort: (a, b) => b.length - a.length},
			],

			imgUrls: {},
    	profilePicFailover: 'https://cdn.vuetifyjs.com/images/parallax/material2.jpg',
			canScrollLeft: false,
			canScrollRight: false,
			selectedBadge: null,
		}
	},
	mounted(){
    document.title = 'Badges Report'
    this.$q.log(7, 31, 3, 'NA')
		let usersPr = this.$http.get('api/badges/users')
			.then(res => {
				this.users = res.data
				this.users.forEach(this.$q.profilePicSrc)
				this.users.forEach(user => {
					user.badges = user.badges.sort((a, b) => {
						if(moment(a.dateEarned) > moment(b.dateEarned))
							return 1
						else if(moment(a.dateEarned) < moment(b.dateEarned))
							return -1
						return 0
					})
				})
				
			})
			.catch(err => {
				console.log(err)
				window.getApp.error("Cannot obtain users")
			})
		let badgePr = this.$http.get('api/badge')
			.then(res => {
				this.badges = res.data
				for(let badge of this.badges){
					this.imgUrls[badge.id] = badge.imgUrl
				}
			})
			.catch(err => {
				window.getApp.error("Cannot obtain badges")
			})
		setInterval(this.scrollSlider, 8)

		Promise.all([usersPr, badgePr])
			.then(() => {
				this.users.forEach(user => {
					for(let badge of user.badges){
						badge.points = this.badges.find(b => b.id == badge.badgeId).points || 0
					}

					if(user.badges.length)
						user.points = user.badges.map(b => b.points).reduce((prev, cur) => {
							return prev + cur
						})
				})
				this.$forceUpdate()
			})
	},
	computed: {
		selectedUser(){
			if(this.filters.users.length == 1)
				return this.users.find(u => u.id == this.filters.users[0])
			return {}
		},
		shownUsers(){
			let users = this.users

			if(this.filters.users.length){
				users = users.filter(user => this.filters.users.includes(user.id))
			}
			if(this.filters.badge.id){
				users = users.filter(user => user.badges.some(badge => badge.badgeId == this.filters.badge.id && this.isInInterval(badge.dateEarned)))
				users = users.sort((u1, u2) => {
					let a = u1.badges.find(badge => badge.badgeId == this.filters.badge.id)
					let b = u2.badges.find(badge => badge.badgeId == this.filters.badge.id)
					if(moment(a.dateEarned) > moment(b.dateEarned))
						return 1
					else if(moment(a.dateEarned) < moment(b.dateEarned))
						return -1
					return 0
				})
			} else {
				users = users.filter(user => user.badges.filter(b => this.isInInterval(b.dateEarned)).length)
			}
			users = this.applySearch(this.search, users, this.headersUsers.map(h => h.value).filter(h => h))
			return users
		},
    appliedFilters(){ return this.countActiveFilters(this.filters, ['search', 'badge']) },
	},
	methods: {
		openBadge(id){
			this.selectedBadge = this.badges.find(b => b.id == id)
			if(!this.selectedBadge)
				return
			this.dialog = true
		},
		isInInterval(date){
			date = moment(date)
			let ans = true
			if(moment(this.filters.initialDate) && date < moment(this.filters.initialDate)){
				ans = false
			}
			if(moment(this.filters.finalDate) && date > moment(this.filters.finalDate)){
				ans = false
			}
			return ans
		},
		removeFilters(){
			this.filters = {
				users: [],
				badge: {},
				initialDate: '',
				finalDate: '',
			}
		},
		removeUser(id){
			this.filters.users = this.filters.users.filter(u => u != id)
		},
		scrollSlider(){
			let slider = this.$el.querySelector("#slider")
			slider.scrollLeft += this.sliderMovementSpeed
			this.canScrollLeft = slider.scrollLeft > 0
			this.canScrollRight = slider.scrollLeft < slider.scrollWidth - slider.offsetWidth
		},
		toggleFilterBadge(badge){
			if(this.filters.badge.id == badge.id)
				this.filters.badge = {}
			else
				this.filters.badge = badge
		},
	},
}
</script>

<style scoped lang="scss">

.badges-filter{
	display: flex;
	align-items: center;
}

#slider{
	width: calc(100vw - 400px);
	overflow-x: hidden;
}
@media (max-width: 1262px) {
  #slider{
    width: calc(100vw - 170px);
  }
}

.badges-container {
	height: 200px;
	width: max-content;

	.badge {
		float: left;
		width: 200px;
		cursor: pointer;
		transition: all 0.2s;
		border-bottom: 8px solid var(--v-background-base);
	}
	.badge.selected {
		border-bottom: 8px solid #1976d2;
	}
}

::v-deep .header-badges {
	width: 50%;
}

b{
	color: var(--v-text-lighten);
}

.mini-image-container{
	float: left;
	padding-right: 5px;
	width: 45px;
}

.profile-image-container{
	position: absolute;
	right: 35px;
	top: 10px;
	width: 45px;
}
.date{
	float: left;
	font-size: 1.1em;
}

.others{
	height: 110px;
	overflow: scroll;
	.other-badges{
		width: max-content;
	}
}
.others::-webkit-scrollbar{
	height: 6px;
	border-radius: 0 20px 20px 0;
	background: transparent;
}
.others::-webkit-scrollbar-corner { background: transparent; }
.others::-webkit-scrollbar-thumb:horizontal{
	border-radius: 20px;
	background: #AED6F1;
}

.user-info{
	display: flex;
	word-break: normal;
}
.badge-details{
	display: grid;
	grid-template-columns: 50% 50%;
}
.points{
	padding: 2px 7px;
	border-radius: 5px;
	background: darkblue;
	color: var(--v-background-base);
}
</style>