<template>
  <div class="new-back fill-height">
    <div class="mx-auto" style="width: 80%">
      <div class="new-back lighten2 bordered darken rounded-lg overflow-hidden pt-3">
        <v-data-table
          :headers="headers"
          :items="items"
          :footer-props="{ itemsPerPageOptions: [ 10, 25, 50, { text:'All', 'value': -1 } ] }"
          :options="{ rowsPerPage: -1 }"
          class="new-back lighten2"
          item-key="id"
          @click:row="editRequest"
        >
          <template #[`item.lines`]="{ item }">
            <span >
              {{ item.lines.length + ` day${item.lines.length > 1 ? 's' : '' }` }}
              <span v-if="item.lines.some(x => x.date)">
                - {{ item.lines | formatDates }}
              </span>
            </span>
          </template>
          <template #[`item.status`]="{ item }">
            <v-menu
              open-on-hover
              style="z-index: 230"
              class="menu "
            >
              <template #activator="{ on }">
                <span :set="status = requestStatus.find(s => s.value == item.status)">
                  <v-chip
                    :color="status.background"
                    :style="{ color: status.color }"
                    v-on="on"
                    small
                  >
                    {{ status.name }}
                  </v-chip>
                </span>
              </template>
              <v-list dense>
                <v-list-item
                  v-for="(requestApprover, ix) in item.requestApprovers"
                  :key="ix"
                >
                  <div
                    class="d-flex justify-space-between align-center"
                    style="min-width: 20px; width: 100%"
                  >
                    <span class="mr-2 body-2">
                      <user-avatar :user-id="requestApprover.approverId" :size="28" hide-loading/>
                      {{ requestApprover.approverBlueTag }}
                    </span>
                    <span :set="status = requestApproverStatus.find(s => s.value == requestApprover.status)">
                        <v-chip
                          :color="status.background"
                          :style="{ color: status.color }"
                          small
                        >
                          {{ status.name }}
                        </v-chip>
                      </span>
                  </div>
                </v-list-item>
              </v-list>
            </v-menu>
          </template>
          <template #[`item.createdAt`]="{ item }">
            <span >
							{{ item.createdAt | formatDate }}
            </span>
          </template>
          <template #[`item.actions`]="{ item }">
            <div class="nobr">
              <v-btn
                @click.stop="editRequest(item)"
                icon
                fab
                x-small
                outlined
                color="primary"
                :disabled="!item.canEdit"
              >
                <v-icon>edit</v-icon>
              </v-btn>
            </div>
          </template>
        </v-data-table>
      </div>
    </div>
    <vacation-request-dialog ref="vacationRequestDialog" />
  </div>
</template>
<script>
import moment from "moment"
import VacationRequestDialog from "../../dialogs/VacationRequestDialog.vue";

export default {
  props: ['id'],
  components: {
    VacationRequestDialog
  },
  data: () => ({
    headers: [
      { text: 'Requested Days', value: 'lines' },
      { text: 'Comments',       value: 'requestorComments' },
      { text: 'Request Date',   value: 'createdAt' },
      { text: 'Status',         value: 'status' },
      { text: '',         value: 'actions', align: 'right' },
    ],
    items: [],
    requestStatus: [
      { name: 'Requested', value: 0, background: '#E5E5E5', color: '#00000080' },
      { name: 'Draft',     value: 1, background: '#E0EDFE', color: '#0270FC' },
      { name: 'Approved',  value: 2, background: '#CEEAD6', color: '#098129' },
      { name: 'Rejected',  value: 3, background: '#F8DAD7', color: '#DC4437' },
      { name: 'Cancelled', value: 4, background: 'red',     color: '#fff' },
    ],
    requestApproverStatus : [
      { name: 'Pending Approval', value: 0, background: '#E5E5E5', color: '#00000080' },
      { name: 'Draft',            value: 1, background: '#E0EDFE', color: '#0270FC' },
      { name: 'Approved',         value: 2, background: '#CEEAD6', color: '#098129' },
      { name: 'Rejected',         value: 3, background: '#F8DAD7', color: '#DC4437' },
      { name: 'Cancelled',        value: 4, background: 'red',     color: '#fff' },
    ],
  }),
  watch: {},
  filters: {
    formatDates: function(dates) {
      dates = dates.map(d => moment(d.date))

      if(dates.length == 1)
        return dates[0].format("MMM DD").toString();
      else if(dates.length == 2)
        return `${dates[0].format("MMM DD").toString()} and ${dates[1].format("MMM DD").toString()}`
      
      let startDate = moment.min(dates)
      let endDate = moment.max(dates)
      let allDatesBewteenStartAndEnd = true

      dates.forEach(date => {
        if(!date.isSameOrBefore(endDate) && !date.isSameOrAfter(startDate)) {
          allDatesBewteenStartAndEnd = false
        }
      })

      if(endDate.diff(startDate, 'days') == dates.length -1 && allDatesBewteenStartAndEnd) {
        startDate = startDate.format("MMM DD").toString()
        endDate = endDate.format("MMM DD").toString()
        return `(${startDate} - ${endDate})`
      }

      return dates.map(d => d.format("MMM DD").toString()).join(", ")
    }
  },
  mounted() {
    this.$q.log(8, 43, 3, 'NA')
    this.init()
  },
  computed: {},
  methods: {
    editRequest(item) {
      let canEdit = item.status == 1 || item.status == 0
      if(canEdit)
        this.$refs.vacationRequestDialog.open(item)
          .then(this.init)
    },
    init() {
      this.$http.get(`api/recess/requests/current`)
        .then(res => {
          this.items = res.data.map(item => {
            let canEdit = false
            if(item.status == 1 || item.status == 0)
              canEdit = true
            return {
              ...item,
              canEdit
            }
          })
          
        })
        .catch(err => {
          console.error(err)
        })
    },
  },
}
</script>

<style scoped>
.v-menu__content {
  border-radius: 12px;
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
  border: 2px solid var(--v-lightgray-lighten) !important;
}
.menu {
  background: linear-gradient(0deg, #E5E5E5, #E5E5E5);
}
</style>