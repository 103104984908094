<template>
	<div class="pa-5 pt-7 new-back full-height">
		<div class="d-none">
      <input type="file" ref="csfUploader" @change="handleCsfUpload"/>
      <input type="file" ref="coverUploader" @change="handleCoverUpload"/>
		</div>

		<div v-if="!editing && canEdit" class="text-right">
			<v-menu z-index="200" :close-on-content-click="false">
				<template #activator="{ on }">
					<v-btn v-on="on" color="primary" outlined>
						Actions <v-icon right>mdi-chevron-down</v-icon>
					</v-btn>
				</template>
				<v-list>
					<v-list-item @click="editing = true">
						<v-list-item-content class="px-3">
							<v-list-item-title class="px-0">
								<v-icon left color="primary">mdi-pencil</v-icon> Edit supplier
							</v-list-item-title>
						</v-list-item-content>
					</v-list-item>
				</v-list>
			</v-menu>
		</div>

		<v-row>
			<v-col :sm="3" style="min-width: 500px">
				<v-card class="new-back lighten2 elevation-0 bordered darken rounded-xl pa-3 pb-5">
					<v-card-text>
						<v-form ref="form">
							<div class="d-flex justify-space-between mb-1">
								<div>
									<label class="caption">
										Supplier
										<span v-show="editing">*</span>
									</label>
									<h2>
										<v-text-field
											v-model="supplier.name"
											:readonly="!editing"
											outlined dense hide-details
											class="small-input"
											:rules="rules"
										/>
									</h2>
								</div>
								<div class="d-flex flex-column align-center">
									<label class="caption">
										Active
										<span v-show="editing">*</span>
									</label>
									<v-switch
										v-model="supplier.active"
										:readonly="!editing"
										class="mt-1 ml-n3"
										style="width: 24px"
									/>
								</div>
							</div>

							<h3 class="text--text">Business Information</h3>
							<div class="mt-3">
								<label class="caption">
									Legal Name
									<span v-show="editing">*</span>
								</label>
								<v-text-field
									v-model="supplier.businessName"
									:readonly="!editing"
									outlined dense hide-details
									class="small-input"
									:rules="rules"
								/>
							</div>

							<v-row class="mt-2" dense>
								<v-col :cols="6">
									<div>
										<label class="caption">
											Address
										</label>
										<v-textarea
											v-if="editing"
											v-model="supplier.address"
											:readonly="!editing"
											outlined dense hide-details
											:no-resize="!editing"
										/>
										<div
											v-else
											class="body-1 text--text"
											style="white-space: pre-wrap"
										>{{ supplier.address }}
										</div>
									</div>
								</v-col>
								<v-col :cols="6">
									<div class="text-right">
										<label class="caption">
											Legal Address
										</label>
										<v-textarea
											v-if="editing"
											v-model="supplier.legalAddress"
											:readonly="!editing"
											outlined dense hide-details
											:no-resize="!editing"
										/>
										<div
											v-else
											class="body-1 text--text"
											style="white-space: pre-wrap"
										>{{ supplier.legalAddress }}
										</div>
									</div>
								</v-col>
							</v-row>

							<div class="mt-5">
								<label class="caption">
									RFC/EIN
									<span v-show="editing">*</span>
								</label>
								<v-text-field
									v-model="supplier.rfc"
									:readonly="!editing"
									outlined dense hide-details
									class="small-input"
									:rules="rules"
								/>
							</div>
							
							<div class="mt-3 d-flex justify-space-between">
								<div style="width: 100px">
									<label class="caption">
										Credit days
										<span v-show="editing">*</span>
									</label>
									<v-text-field
										v-model="supplier.creditDays"
										:readonly="!editing"
										outlined dense hide-details
										class="small-input"
										:rules="rules"
										type="number"
										min="0"
									/>
								</div>
								<div>
									<label class="caption">
										CSF/FWG
									</label>
									<v-btn v-if="editing && !supplier.csfFileName" @click="openFileInput('csfUploader')" color="secondary" block depressed style="height: 35px">
										Upload file
									</v-btn>
									<div v-else class="d-flex align-center" style="gap: 10px">
										<a v-if="supplier.csfFileName" :href="getCsfUrl()" target="_blank" class="mt-1">
											<h3>{{ setMaxText(supplier.csfFileName, 25) }}</h3>
										</a>
										<h3 v-else>-</h3>
										<v-tooltip v-if="editing && (newCsf || supplier.csfFileName)" top>
											<template #activator="{ on, attrs }">
												<v-btn
													v-on="on" v-bind="attrs"
													@click="openFileInput('csfUploader')"
													x-small fab depressed
													color="secondary"
												>
													<v-icon>mdi-upload</v-icon>
												</v-btn>
											</template>
											<span>Replace file</span>
										</v-tooltip>
									</div>
								</div>
							</div>
						</v-form>
					</v-card-text>
				</v-card>
			</v-col>
			<v-col>
				<div>
					<div class="d-flex align-center">
						<h3>Main Contacts</h3>
						<v-spacer/>
						<v-btn v-if="editing" @click="openContactDialog(null)" color="primary" fab small depressed>
							<v-icon>mdi-plus</v-icon>
						</v-btn>
					</div>
					<v-data-table
						:headers="contactHeaders" :items="supplier.contacts"
						class="bordered darken new-back lighten2 rounded-xl my-3"
						disable-pagination hide-default-footer
					>
						<template #[`item.actions`]="{ item }">
							<v-menu v-if="editing" bottom z-index="200">
								<template #activator="{ on }">
									<v-btn v-on="on" icon><v-icon>mdi-dots-vertical</v-icon></v-btn>
								</template>
								<v-list dense class="py-1">
									<v-list-item class="pa-1 py-0">
										<v-tooltip right z-index="200">
											<template #activator="{ on }">
												<v-btn v-on="on" @click="openContactDialog(item)" color="primary" text small>
													<v-icon>mdi-pencil</v-icon>
												</v-btn>
											</template>
											<span class="caption">Edit</span>
										</v-tooltip>
									</v-list-item>
									<v-list-item class="pa-1 py-0">
										<v-tooltip right z-index="200">
											<template #activator="{ on }">
												<v-btn v-on="on" @click="remove(item, 'contact')" color="error" text small>
													<v-icon>mdi-delete</v-icon>
												</v-btn>
											</template>
											<span class="caption">Remove</span>
										</v-tooltip>
									</v-list-item>
								</v-list>
							</v-menu>
						</template>
					</v-data-table>
				</div>
				<div class="mt-10">
					<div class="d-flex align-center">
						<h3>Payment Information</h3>
						<v-spacer/>
						<v-btn v-if="editing" @click="openAccountDialog(null)" color="primary" fab small depressed>
							<v-icon>mdi-plus</v-icon>
						</v-btn>
					</div>
					<v-data-table
						:headers="paymentHeaders" :items="supplier.bankAccounts"
						class="bordered darken new-back lighten2 rounded-xl my-3"
						disable-pagination hide-default-footer
					>
						<template #[`item.active`]="{ item }">
							<v-icon class="ml-n3">
								mdi-{{ item.active ? 'check' : 'close' }}
							</v-icon>
						</template>
						<template #[`item.cover`]="{ item }">
							<a :href="getCoverUrl(item)" target="_blank">
								{{ item.coverFileName }}
							</a>
						</template>
						<template #[`item.actions`]="{ item }">
							<v-menu v-if="editing" bottom z-index="200">
								<template #activator="{ on }">
									<v-btn v-on="on" icon><v-icon>mdi-dots-vertical</v-icon></v-btn>
								</template>
								<v-list dense class="py-1">
									<v-list-item class="pa-1 py-0">
										<v-tooltip right z-index="200">
											<template #activator="{ on }">
												<v-btn v-on="on" @click="openAccountDialog(item)" color="primary" text small>
													<v-icon>mdi-pencil</v-icon>
												</v-btn>
											</template>
											<span class="caption">Edit</span>
										</v-tooltip>
									</v-list-item>
									<v-list-item class="pa-1 py-0">
										<v-tooltip right z-index="200">
											<template #activator="{ on }">
												<v-btn v-on="on" @click="remove(item, 'account')" color="error" text small>
													<v-icon>mdi-delete</v-icon>
												</v-btn>
											</template>
											<span class="caption">Remove</span>
										</v-tooltip>
									</v-list-item>
								</v-list>
							</v-menu>
						</template>
					</v-data-table>
					<div v-if="editing" class="mt-5 text-right">
						<v-btn @click="$router.go(-1)" color="primary" class="mr-2" text>Cancel</v-btn>
						<v-btn @click="save" color="primary" depressed>Save Supplier</v-btn>
					</div>
				</div>
			</v-col>
		</v-row>
		
		<blue-confirm-dialog ref="contactDialog" persistent>
			<template #title> Add Main Contact </template>
			<template #text>
				<v-form ref="contactForm">
					<v-text-field label="Name*" v-model="newContact.name" :rules="rules"/>
					<v-text-field label="Email*" v-model="newContact.email" :rules="emailRules"/>
					<v-text-field label="Phone number" v-model="newContact.phone" v-only-numbers/>
				</v-form>
			</template>
		</blue-confirm-dialog>
		<blue-confirm-dialog ref="accountDialog" persistent>
			<template #title>
				Add Bank Account
				<v-spacer></v-spacer>
				<v-checkbox
					label="Active"
					v-model="newAccount.active"
				/>
			</template>
			<template #text>
				<v-form ref="accountForm">
					<v-row dense>
						<v-col :cols="4">
							<v-autocomplete
								label="Bank*"
								@change="x => newAccount.bankName = banks.find(b => b.id == x).name"
								v-model="newAccount.bankId"
								:rules="rules" :items="banks"
								item-value="id" item-text="name"
								:search-input.sync="bankSearch"
							>
								<template #prepend-item>
									<v-tooltip right :disabled="!cannotAddBankReason">
										<template #activator="{ on }">
											<v-list-item
												@click="!cannotAddBankReason && addNewBank()" v-on="on"
												:class="{ 'text--disabled': !!cannotAddBankReason }"
											>
												<v-list-item-content>
													<div class="d-flex align-center">
														<v-icon left color="primary">mdi-plus-circle-outline</v-icon>
														Add new bank
													</div>
												</v-list-item-content>
											</v-list-item>
										</template>
										<span>{{ cannotAddBankReason }}</span>
									</v-tooltip>
								</template>
								<template #no-data>
									<div></div>
								</template>
							</v-autocomplete>
						</v-col>
						<v-col :cols="4">
							<v-text-field label="Account No.*" v-model="newAccount.accountNumber" :rules="rules" v-only-numbers/>
						</v-col>
						<v-col :cols="4">
							<v-select label="Currency*" v-model="newAccount.currency" :rules="rules" :items="currencies"/>
						</v-col>
						<v-col :cols="4">
							<v-text-field label="Routing No." v-model="newAccount.routingNumber" v-only-numbers/>
						</v-col>
						<v-col :cols="4">
							<v-text-field label="Branch*" v-model="newAccount.branch" :rules="rules"/>
						</v-col>
						<v-col :cols="4">
							<v-text-field label="SWIFT" v-model="newAccount.swift"/>
						</v-col>
						<v-col :cols="4">
							<label>Cover</label>
							<v-btn v-if="editing && !newAccount.coverFileName" @click="openFileInput('coverUploader')" color="secondary" block depressed style="height: 35px">
								Upload file
							</v-btn>
							<div v-else class="d-flex align-center" style="gap: 10px">
								<a v-if="newAccount.coverFileName" :href="getCoverUrl(newAccount)" target="_blank" class="mt-1">
									<h3>{{ setMaxText(newAccount.coverFileName, 25) }}</h3>
								</a>
								<h3 v-else>-</h3>
								<v-tooltip v-if="newAccount.coverFileName" top>
									<template #activator="{ on, attrs }">
										<v-btn
											v-on="on" v-bind="attrs"
											@click="openFileInput('coverUploader')"
											x-small fab depressed
											color="secondary"
										>
											<v-icon>mdi-upload</v-icon>
										</v-btn>
									</template>
									<span>Replace file</span>
								</v-tooltip>
							</div>
						</v-col>
					</v-row>
				</v-form>
			</template>
		</blue-confirm-dialog>
	</div>
</template>

<script>
export default {
	props: {
		id: { type: String, default: '' },
	},
	data: () => ({
		canEdit: false,
		supplier: {
			active: true,
			contacts: [],
			bankAccounts: [],
		},
		newContact: {},
		newAccount: {},
		editing: false,
		accountDialog: false,
		newCsf: null,

		banks: [],
		bankSearch: '',
		currencies: ['MXN', 'USD', 'EUR'],

		rules: [
			v => !!v || v === 0 || 'Required',
		],
		emailRules: [
			(v) => /\S+@\S+\.\S+/.test(v) || 'E-mail must be valid'
		],
		contactHeaders: [
			{ text: 'Name', value: 'name' },
			{ text: 'Email Address', value: 'email' },
			{ text: 'Phone', value: 'phone' },
			{ text: '', value: 'actions', sortable: false, width: 0 },
		],
		paymentHeaders: [
			{ text: 'Bank', value: 'bankName' },
			{ text: 'Account', value: 'accountNumber' },
			{ text: 'Currency', value: 'currency' },
			{ text: 'Routing No.', value: 'routingNumber' },
			{ text: 'Branch', value: 'branch' },
			{ text: 'SWIFT', value: 'swift' },
			{ text: 'Active', value: 'active', align: 'center' },
			{ text: 'Cover', value: 'cover' },
			{ text: '', value: 'actions', sortable: false, width: 0 },
		],
	}),
	created(){
		this.$q.changeHeaderTitle(`${this.isNew ? 'New ' : 'Edit '} supplier`)

		if(!this.isNew){
			this.$security.hasRequiredPermission('edit')
				.then(res => this.canEdit = res)
		}
		document.title = 'New Supplier'

		this.editing = this.isNew

		if(!this.isNew){
			this.$http.get(`api/supplier/${this.id}`)
				.then(res => {
					this.supplier = res.data
					document.title = `${this.supplier.name} - Supplier`
				})
				.catch(err => {
					console.error(err)
					this.$root.error('Error retrieving supplier')
				})
		}

		this.$http.get('api/supplier/bank')
			.then(res => {
				this.banks = res.data
			})
			.catch(err => {
				console.error(err)
				this.$root.error('Error retrieving banks')
			})
	},
	computed: {
		isNew(){
			return this.id === 'new';
		},
		cannotAddBankReason() {
			if(!this.bankSearch)
				return 'Type new bank name'
			if(
				this.banks
					.some(
						x => x.name.trim().toLocaleLowerCase() ===
							this.bankSearch.trim().toLocaleLowerCase()
					)
			)
				return 'Bank already exists'
		},
	},
	methods: {
		openContactDialog(contact){
			if(!contact){
				this.newContact = {
					active: true,
					isNew: true,
				}
			}
			else this.newContact = structuredClone(contact)

			this.$refs.contactDialog.open({
				width: 400,
			})
				.then(res => {
					if(!res) return
					if(!this.$refs.contactForm.validate()){
						this.openContactDialog(contact)
						return
					}

					if(!this.newContact.isNew){
						contact = Object.assign(contact, this.newContact)
						this.$forceUpdate()
					} else {
						delete this.newContact.isNew
						this.supplier.contacts.push(this.newContact)
					}

					this.$refs.contactDialog.close()
				})
		},
		openAccountDialog(account){
			if(!account){
				this.newAccount = {
					active: true,
					isNew: true,
				}
			}
			else this.newAccount = structuredClone(account)

			this.$refs.accountDialog.open({
				width: 650,
			})
				.then(res => {
					if(!res) return
					if(!this.$refs.accountForm.validate()){
						this.openAccountDialog(this.newAccount)
						return
					}

					if(!this.newAccount.isNew){
						account = Object.assign(account, this.newAccount)
						this.$forceUpdate()
					} else {
						delete this.newAccount.isNew
						this.supplier.bankAccounts.push(structuredClone(this.newAccount))
					}

					this.$refs.accountDialog.close()
				})
		},
		remove(entity, entityClass){
			this.$openConfirmDialog({
				title: `Remove ${entityClass}`,
				description: 'Are you sure you want to remove this item?'
			})
				.then(res => {
					if(!res) return
					this.$set(entity, 'deleted', true)
					this.supplier.contacts = this.supplier.contacts.filter(c => !c.deleted)
					this.supplier.bankAccounts = this.supplier.bankAccounts.filter(a => !a.deleted)
				})
		},

		openFileInput(ref){
			if(!this.editing) return
			this.$refs[ref].click()
		},
		handleCsfUpload(){
			if(!this.$refs.csfUploader.files.length) return

			this.newCsf = this.$refs.csfUploader.files[0]
			this.$set(this.supplier, 'csfFileName', this.newCsf.name)
			this.$forceUpdate()
		},
		handleCoverUpload(){
			if(!this.$refs.coverUploader.files.length) return
			
			const file = this.$refs.coverUploader.files[0]
			const formData = new FormData()
			formData.append('file', file)
			this.$http.post('api/supplier/tempCover', formData, {
				headers: {
					'Content-Type': 'multipart/form-data'
				}
			})
				.then(res => {
					this.$set(this.newAccount, 'coverFileId', res.data)
					this.$set(this.newAccount, 'coverFileName', file.name)
					this.$set(this.newAccount, 'coverFileUrl', URL.createObjectURL(file))
				})
				.catch(err => {
					console.error(err)
					this.$root.error('Error uploading cover')
				})
		},

		getCsfUrl(){
			return this.newCsf ? URL.createObjectURL(this.newCsf) : this.getBackendUrl(`api/supplier/${this.supplier.id}/csf`)
		},
		getCoverUrl(account){
			return account.coverFileUrl || this.getBackendUrl(`api/supplier/${this.supplier.id}/account/${account.id}/cover`)
		},

		addNewBank() {
			this.$openConfirmDialog({
				title: 'New bank',
				description: `Are you sure you want to add <b>${this.bankSearch}</b> to bank list?`
			})
				.then(res => {
					if(!res) return
					this.$http.post('/api/supplier/bank', { name: this.bankSearch })
						.then(res => {
							this.banks.push(res.data)
							this.newAccount.bankId = res.data.id
							this.newAccount.bankName = res.data.name
							this.bankSearch = ''
						})
						.catch(err => {
							console.error(err)
							if(!err.notified)
								this.$root.error('Error adding bank')
						})
				})
		},

		save(){
			if(!this.$refs.form.validate()) return

			this.$http.post('api/supplier', this.supplier)
				.then(res => {

					if(this.newCsf){
						const formData = new FormData()
						formData.append('file', this.newCsf)
						this.$http.post(`api/supplier/${res.data.id}/csf`, formData, {
							headers: {
								'Content-Type': 'multipart/form-data'
							}
						})
							.catch(err => {
								console.error(err)
								this.$root.error('Error uploading CSF')
							})
					}

					this.$router.go(-1)
					this.$root.success('Supplier saved')
				})
				.catch(err => {
					console.error(err)
					this.$root.error('Error saving supplier')
				})
		},
	},
}
</script>

<style lang="scss" scoped>
::v-deep .v-input--is-readonly{
	.v-input__slot{
		padding-left: 0 !important;
		margin-top: -10px !important;
	}
	fieldset{
		border: none;
	}
}

.small-input ::v-deep .v-input__slot{
	height: 35px;
	min-height: 35px !important;
}
label{
	color: var(--v-text-lighten);
}
</style>