<template>
	<div style="position: relative; margin-bottom: 30px;">
		<h4 class="ma-0">General Details</h4><br>
		<v-layout row wrap >
			<v-flex xs12 sm3 md2 lg2>
				<label>Small Code</label>
				<span>
					{{generalDetails.smallCode}}
				</span>
			</v-flex>
			<v-flex xs12 sm9 md4 lg4>
				<label>Name</label>
				<span class="">
					{{generalDetails.name}}
				</span>
			</v-flex>
			<v-flex xs12 sm6 md3 lg3>
				<label>Code</label>
				<span>
					{{generalDetails.code}}
				</span>
			</v-flex>

			<v-flex xs12 sm6 md3 lg3>
				<label>Customer</label>
				<span>
					{{generalDetails.otherCustomer}}
				</span>
			</v-flex>

			<v-flex xs12 sm6 md3>
				<label>Product</label>
				<span>
					{{generalDetails.otherProduct}}
				</span>
			</v-flex>

			<v-flex xs12 sm6 md3>
				<label>Total Resources</label>
				<span>
					{{generalDetails.currentTotalResources }}
				</span>
			</v-flex>


			<v-flex xs12 sm6 md3>
				<label>Type</label>
				<span>
					{{generalDetails.typeName}}
				</span>
			</v-flex>

			<v-flex xs12 sm6 md3>
				<label>Status</label>
				<span>
					{{generalDetails.statusName}}
				</span>
			</v-flex>

			<v-flex xs12>
				<v-layout row wrap>
							
					<v-flex xs12 sm6 md3 lg3>
						<label>Start Date</label>
						<span>
							{{generalDetails.startDate | formatDateWeekDay }}
						</span>
					</v-flex>

					<v-flex xs12 sm6 md3 lg3>
						<label>Estimated End Date</label>
						<span>
							{{generalDetails.estimatedEndDate | formatDateWeekDay }}
						</span>
					</v-flex>

					<v-flex xs12 sm6 md3 lg3>
						<label>Planned End Date</label>
						<span>
							{{generalDetails.plannedEndDate | formatDateWeekDay }}
						</span>
					</v-flex>

					<v-flex xs12 sm6 md3 lg3>
						<label>Agreed End Date</label>
						<span>
							{{generalDetails.agreedEndDate | formatDateWeekDay }}
						</span>
					</v-flex>

				</v-layout>
			</v-flex>

			<v-flex xs12 sm12 lg6>
				<label>Description</label>
				<span class="multiline">{{generalDetails.description}}</span>
			</v-flex>

			<v-flex xs12 sm12 lg6>
				<label>Notes</label>
				<span class="multiline"> {{generalDetails.notes}}</span>
			</v-flex>
		</v-layout>
	</div>
</template>

<script>
import moment from "moment"
export default {
	props: ['id'],
	data(){
		return{
			generalDetails: {},
		}
	},
	mounted(){
    this.$http
      .get(`/api/project/${this.id}`)
      .then(res => {
        this.generalDetails = res.data
      })
	},
}
</script>

<style scoped>
.flex {
	margin-bottom: 10px;
}
.flex > label {
	display: block;
	color: #777;
	font-size: 0.85em;
	padding: 0 3px;
	font-weight: 700;
}
.flex > span {
	display: block;
	padding: 3px 7px;
	min-height: 29px;
	background: var(--v-background-lighten);
	border: 1px solid var(--v-background-lighten2);
	border-radius: 15px;
}
</style>