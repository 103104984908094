<template>
  <v-container grid-list-md fill-height fluid class="new-back">
    <v-layout column fill-height justify-start>
      <v-flex class="nav-container">
        <div class="pa-2">
          <router-view
            v-if="contextProjectId"
            :canEditProject="canEditProject"
            :projectName="item ? item.name : ''"
            :id="contextProjectId"
            :socket="socket"
          ></router-view>
        </div>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
import _ from "lodash";

export default {
  props: ['socket'],
  data: function () {
    return {
      componentName: "ProjectDetails",
      item: {},
      activeTab: null,
      tabs: [],
      projects: [],
      canEditProject: false,
    };
  },
  mounted() {
    this.init()
  },
  watch: {
    $route(){
      document.title = this.$route.meta?.title + ' - ' + this.item.name
    },
  },
  computed: {
    contextProjectId(){
      return this.$q.getCookie('contextProjectId')
    },
  },
  methods: {
    init() {
      if (this.contextProjectId) {
        this.$http
          .get(`/api/project/${this.contextProjectId}/header`)
          .then((response) => {
            this.item = response.data
            document.title = this.$route.meta?.title + ' - ' + this.item.name
          })
          .catch((response) => {
            window.getApp.error("Cannot get record.");
            console.log(response);
          });
        this.$http
          .get(`api/Project/${this.contextProjectId}/currentPermissions`)
          .then(res => {
            this.canEditProject = res.data.canEdit == 3;
          }).catch( res => {
            window.getApp.error("Cannot get permissions.");
            console.log(res);
          })
      }
      else
        this.$router.push('/external/project/')
    },
  },
};
</script>

<style scoped lang="scss">
</style> 