<template>
	<div>
		<h2 class="mb-3">
			Status
			<v-btn
				@click="openNewStatusDialog"
				:disabled="!$parent.canEditProject"
				x-small fab depressed color="primary"
			>
				<v-icon dark>add</v-icon>
			</v-btn>
		</h2>
		
		<data-table :headers="headers" :items="$parent.config.workItemStatuses">
			<template #[`item.sortIndex`]="props">
				<div>
					<v-btn @click="moveStatusUp(props.item.id)" text icon x-small color="primary" class="sort-icon" :disabled="!$parent.canEditProject">
						<v-icon class="cursor-pointer">arrow_drop_up</v-icon>
					</v-btn>
					<br>
					<v-btn @click="moveStatusDown(props.item.id)" text icon x-small color="primary" class="sort-icon" :disabled="!$parent.canEditProject">
						<v-icon class="cursor-pointer">arrow_drop_down</v-icon>
					</v-btn>
				</div>
			</template>
			<template #[`item.name`]="{ item }">
				<v-btn :min-width="130" dark small :color="item.color" class="text-capitalize">
					<v-icon v-if="item.iconClass" small left>{{ item.iconClass }}</v-icon>
					{{ item.name }}
				</v-btn>
			</template>
			<template #[`item.destinations`]="{ item }">
				<v-chip
					v-if="item.destinations.length === 1"
					:color="item.destinations[0].destinyColor"
					label small dark
				>
					<v-icon left small>{{ item.destinations[0].destinyIconClass }}</v-icon>
					{{ item.destinations[0].destinyName }}
				</v-chip>
				<span v-else>{{ item.destinations.length }}</span>
			</template>
			<template #[`item.description`]="{ item }">
				<div :style="{minWidth: '100px'}">
					{{ item.description }}
				</div>
			</template>
			<template #[`item.limit`]="{ item }">
				<div :style="{minWidth: '50px'}">
					{{ item.limit ? item.limit : '' }}
				</div>
			</template>
			<template #[`item.cycleTimeGoal`]="{ item }">
				<div v-if="item.cycleTimeGoal">
					{{
						item.cycleTimeGoal + ' ' + 
						cycleTimeGoalUnits[item.cycleTimeGoalUnit].shortname + (item.cycleTimeGoal != 1 ? 's' : '')
					}}
				</div>
			</template>
			<template #[`item.definitionOfDone`]="{ item }">
				<div>
					{{setMaxText(item.definitionOfDone ? JSON.parse(item.definitionOfDone) : "", 33)}}
				</div>
			</template>
			<template #[`item.workItemtype`]="{ item }">
				<div :style="{minWidth: '103px'}">
					<v-btn fab x-small dark :color="item.workItemTypeColor">
						<v-icon small>{{item.workItemTypeIconClass}}</v-icon>
					</v-btn>
					{{item.workItemTypeDisplayName}}
				</div>
			</template>
			<template #[`item.active`]="props">
				<div :style="{minWidth: '54px'}">
					<v-icon v-if="props.item.active">check</v-icon>
					<v-icon v-if="!props.item.active">close</v-icon>
				</div>
			</template>
			<template #[`item.isDefault`]="props">
				<div :style="{minWidth: '58px'}">
					<v-icon v-if="props.item.isDefault">check</v-icon>
					<v-icon v-if="!props.item.isDefault">close</v-icon>
				</div>
			</template>
			<template #[`item.isInitial`]="props">
				<div :style="{minWidth: '63px'}">
					<v-icon v-if="props.item.isInitial">check</v-icon>
					<v-icon v-if="!props.item.isInitial">close</v-icon>
				</div>
			</template>
			<template #[`item.isFinal`]="props">
				<div :style="{minWidth: '59px'}">
					<v-icon v-if="props.item.isFinal">check</v-icon>
					<v-icon v-if="!props.item.isFinal">close</v-icon>
				</div>
			</template>
			<template #[`item.confetti`]="props">
				<div :style="{minWidth: '64px'}">
					<v-icon v-if="props.item.confetti">check</v-icon>
					<v-icon v-if="!props.item.confetti">close</v-icon>
				</div>
			</template>
			<template #[`item.actions`]="{ item }">
				<div class="nobr">
					<v-btn @click="deleteStatus(item.id)" depressed outlined icon fab color="primary" x-small  :disabled="!$parent.canEditProject">
						<v-icon>delete</v-icon>
					</v-btn>
					<v-btn fab x-small @click="openEditStatusDialog(item)" color="primary" :disabled="!$parent.canEditProject">
						<v-icon>edit</v-icon>
					</v-btn>
				</div>
			</template>
		</data-table>
		<a @click="openCreateDefaultStatusDialog" v-show="$parent.canEditProject">Create default status list</a>
		<edit-status-dialog ref="editDialog" :types="shownTypes" :statuses="statuses"/>
		<default-status-list-dialog ref="defaultListDialog" :types="shownTypes"/>
	</div>
</template>

<script>
import EditStatusDialog from '../../dialogs/projectConfig/EditStatusDialog'
import DefaultStatusListDialog from '../../dialogs/projectConfig/DefaultStatusListDialog'

export default {
	components: {
		EditStatusDialog,
		DefaultStatusListDialog,
	},
	data: () => ({
    headers: [
      { text: "Sort Index", value: "sortIndex", align: 'center', sortable: false },
      { text: "Status", value: "name" },
      { text: "Destinations", value: "destinations", align: 'center', sortable: false },
      { text: "Description", value: "description" },
      { text: "Limit", value: "limit" },
      { text: "Objective Cycle Time", value: "cycleTimeGoal", width: 110, sortable: false },
      { text: "Definition of Done", value: "definitionOfDone" },
      { text: "For Type", value: "workItemtype" },
      { text: "Active", value: "active" },
      { text: "Default", value: "isDefault" },
      { text: "Is Initial", value: "isInitial" },
      { text: "Is Final", value: "isFinal" },
      { text: "Confetti", value: "confetti" },
      { value: "actions", class: "nobr", width: 1, sortable: false }
    ],
    editStatus: {},
	}),
	computed: {
    shownTypes() {
      if (!this.$parent.boardConfigs || !this.$parent.types) return []
      return this.$parent.types.filter(t => this.$parent.boardConfigs.some(s => s.workItemType == t.value))
    },
    statuses() {
      if (!this.$parent.config || !this.$parent.config.workItemStatuses) return []
      return this.$parent.config.workItemStatuses
    },
	},
	methods: {
    deleteStatus(projectWorkItemStatusId) {
			this.$q.confirmDelete({
				api: `/api/projectWorkItemStatus/${projectWorkItemStatusId}`,
				messageBody: "Are you sure you want to delete this status?", 
				successMsg: "Status deleted",
				errorMsg: "The status was not deleted.",
			}).then(res => {
				this.$emit('saved')
			})
		},
		openNewStatusDialog() {
			let editStatus = {
				projectId: this.$parent.project.id,
				status: 0,
				color: '#5259EFFF',
				active: true,
			}
			if(this.isPersonal)
				editStatus.workItemType = 3
			this.$refs.editDialog.open(editStatus)
				.then(this.saveStatus)
		},
		openEditStatusDialog(projectStatus) {
			let editStatus = _.clone(projectStatus, true)
			if(editStatus.definitionOfDone)
				editStatus.definitionOfDone = JSON.parse(editStatus.definitionOfDone)
			this.$refs.editDialog.open(editStatus)
				.then(this.saveStatus)
		},
		saveStatus(status) {
			if(!status) return
			this.$q.save({
				api: `/api/projectWorkItemStatus`,
				data: status,
				successMsg: "Status saved.",
				afterSuccessUrl: null,
				errorMsg: "Cannot save status.",
				afterErrorUrl: null,
			}).then(() => {
				this.$emit('saved')
			})
		},
    openCreateDefaultStatusDialog() {
			this.$refs.defaultListDialog.open()
				.then(async res => {
					if(!res) return

					let defaultStatuses = _.map(res, arr => arr)
            .flat()
            .filter(s => s.selected)

          for (let i = 0; i < defaultStatuses.length; i++) {
            let status = defaultStatuses[i]
            status.projectId = this.$parent.project.id
            await this.$q.save({
              api: `/api/projectWorkItemStatus`,
              data: status,
              successMsg: null,
              afterSuccessUrl: null,
              errorMsg: "Cannot save status.",
              afterErrorUrl: null,
            })
          }

					this.$emit('saved')
				})
    },
		moveStatusUp(id) {
			this.$http.post(`/api/projectWorkItemStatus/${id}/MoveToPreviousPosition`)
				.then(res => {
					this.$parent.config.workItemStatuses = res.data
					window.getApp.success("Order updated.")
				})
				.catch(res => {
					window.getApp.error("The order was not changed.")
					console.error(res)
				})
		},
		moveStatusDown(id) {
			this.$http.post(`/api/projectWorkItemStatus/${id}/MoveToNextPosition`)
				.then(res => {
					this.$parent.config.workItemStatuses = res.data
					window.getApp.success("Order updated.")
				})
				.catch(res => {
					window.getApp.error("The order was not changed.")
					console.error(res)
				})
		},
	},
}
</script>