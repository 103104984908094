
<template>
  <div id="pageTable" class="new-back fill-height">
    <v-container grid-list-xl fluid>
      <v-layout row wrap>
        <v-flex lg12>
          <v-card flat>
            <v-btn v-if="permissions.add" fab absolute bottom left color="accent" to="/userGroup/new">
              <v-icon dark>add</v-icon>
            </v-btn>
            <div class="new-back lighten2 rounded-lg bordered overflow-hidden">
              <v-app-bar flat color="transparent">
                <v-text-field
                  flat
                  solo
                  prepend-icon="search"
                  placeholder="Search"
                  v-model="search"
                  hide-details
                  class="hidden-sm-and-down"
                ></v-text-field>
                <v-btn icon @click="search = ''">
                  <v-icon>filter_list</v-icon>
                </v-btn>         
              </v-app-bar>
              <v-divider></v-divider>
              <v-data-table
                :headers="shownHeaders"
                :items="shownItems"
                :footer-props="{ itemsPerPageOptions: [10,25,50,{text:'All','value':-1}] }"
                class="new-back lighten2"
                item-key="id"
                :item-class="() => permissions.detail ? 'cursor-pointer' : ''"
                @click:row="(item) => permissions.detail ? $router.push({ name: 'UserGroupEdit', params: { id: item.id }}) : null"
              >
                <template v-slot:item.actions="props">
                  <v-menu left offset-x z-index="200">
                    <template #activator="{ on }">
                      <v-btn v-on="on" icon>
                        <v-icon>mdi-dots-vertical</v-icon>
                      </v-btn>
                    </template>
                    <v-list dense class="py-1">
                      <!-- <v-list-item class="pa-1 py-0">
                        <v-tooltip right z-index="200">
                          <template #activator="{ on }">
                            <v-btn v-on="on" color="secondary" small text :to="{ name: 'UserGroupDetails', params: { id: props.item.id }}">
                              <v-icon>mdi-eye-outline</v-icon>
                            </v-btn>
                          </template>
                          <span>Details</span>
                        </v-tooltip>
                      </v-list-item>
                      <v-list-item class="pa-1 py-0">
                        <v-tooltip right z-index="200">
                          <template #activator="{ on }">
                            <v-btn v-on="on" color="primary" small text :to="{ name: 'UserGroupEdit', params: { id: props.item.id }}">
                              <v-icon>edit</v-icon>
                            </v-btn>
                          </template>
                          <span>Edit</span>
                        </v-tooltip>
                      </v-list-item> -->
                      <v-list-item class="pa-1 py-0">
                        <v-tooltip right z-index="200">
                          <template #activator="{ on }">
                            <v-btn v-on="on" color="error" small text @click="doDelete(props.item.id)">
                              <v-icon>delete</v-icon>
                            </v-btn>
                          </template>
                          <span>Delete</span>
                        </v-tooltip>
                      </v-list-item>
                    </v-list>
                  </v-menu>
                </template>
              </v-data-table>
            </div>
          </v-card>
        </v-flex>  
      </v-layout>
    </v-container>
  </div>
</template>

<script>
export default {
  title: 'User Groups',
  mounted() {
    this.$security.hasRequiredPermission('detail')
      .then(res => this.permissions.detail = res)
    this.$security.hasRequiredPermission('add')
      .then(res => this.permissions.add = res)
    this.$security.hasRequiredPermission('full_access')
      .then(res => this.permissions.actions = res)

    this.$q.log(6, 34, 3, 'NA')
    this.$http
      .get("/api/userGroup")
      .then(response => {
        this.items = response.data;
      })
      .catch(response => {
        window.getApp.error("Cannot get records.");
        console.log(response);
      });
  },
  data() {
    return {
      search: "",
      selected: [],
      headers: [
        { text: "Name", value: "name" },
        { text: "Description", value: "description" },
        { value: "actions", class: "nobr", width: 1 }
      ],
      items: [],
      
      permissions: {
        detail: false,
        add: false,
        actions: false,
      },
    };
  },
  computed: {
    shownItems(){
      let items = this.items
      items = this.applySearch(this.search, items, this.headers.map(h => h.value).filter(h => h))
      return items
    },
    shownHeaders(){
      return this.headers.filter(x => x.value != 'actions' || this.permissions.actions)
    },
  },
  methods: {
    doDelete(id) {
        this.$q.confirmDelete({
            api: `/api/userGroup/${id}`,
        }).then(res => {
            this.$q.removeById(this.items, id);
        });
    }
  }
};
</script>

<style scoped lang="scss">
::v-deep .new-back .v-input__slot{
  background: inherit !important;
}
</style>