<template>
  <v-tooltip right>
    <template #activator="{ on }">
      <v-list-item
        v-on="on"
        @click="disabled ? null : $emit('open',link)"
        class="action-container" :style="{ cursor: disabled ? 'default' : 'pointer' }"
        :class="itemsLength > 1 ? 'rounded-linked-work-item' : 'rounded-linked'"
      >
        <v-list-item-action class="mr-0 ml-n3">
          <v-btn @click="deleteItem(link, $event)" x-small text fab >
            <v-icon small>clear</v-icon>
          </v-btn>
        </v-list-item-action>
        <v-list-item-content>
          <v-list-item-title class="link-details">
            <div style="display: flex; align-items: center;">
              <v-chip dark class="type-dot ml-1 mr-2" x-small :color="link.relatedWorkItemTypeColor">
                <v-icon x-small>{{link.relatedWorkItemTypeIconClass}}</v-icon>
              </v-chip>
              <span>
                {{link.relatedWorkItemCode ? link.relatedWorkItemCode : 'NEW'}} {{link.relatedWorkItemName}}
              </span>
            </div>
            <v-btn
              small dark depressed
              :color="link.relatedWorkItemStatusColor"
              class="text-capitalize ml-1 mx-0 status-button-title rounded-lg"
            >
              <v-icon v-if="link.relatedWorkItemStatusIconClass" left>{{link.relatedWorkItemStatusIconClass}}</v-icon>
              <span class="status-name">
                {{link.relatedWorkItemStatusName}}
              </span>
            </v-btn>
          </v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </template>
    <span>{{ link.relationshipDisplayName }}</span>
  </v-tooltip>
</template>

<script>
export default {
  props: {
    deleteItem: { type: Function, default: () => {} },
    itemsLength: { type: Number, default: 0 },
    link: { type: Object, default: () => ({}) },
    disabled: { type: Boolean, default: false },
  }
}
</script>

<style lang="scss" scoped>
.action-container {
  border: 1px solid var(--v-scroll-lighten);
  position: relative;
  transition: all 2s;
}
.rounded-linked {
  border-radius: 10px 10px;
}
.rounded-linked-work-item {
  &:first-child {
    border-radius: 10px 10px 0 0 !important;
  }
  &:last-child {
    border-radius: 0 0 10px 10px !important;
  }
  &:not(:last-child) {
    border-bottom: none !important;
  }
}
.link-details {
  display: inline-flex;
  justify-content: space-between;
  align-items: center;
  flex: 1;
  width: 100px;
  overflow: hidden;
}
.type-dot {
  height: 18px;
  width: 18px;
  vertical-align: middle;
  border-radius: 11px !important;
  padding: 0px 4px;
  .v-icon {
    font-size: 10px !important;
  }
}
.status-button-title {
  position: absolute;
  right: 5px;
  max-width: 100%;
  overflow: hidden;
}
.status-name {
  white-space: normal;
}
.status-button-title ::v-deep .v-btn__content {
  max-width: 100%;
}
</style>