export default {
  methods: {
		$openConfirmDialog(opts){
			return this.$root.$refs.confirmDialog?.open(opts)
		},
		$addSpinner(){ this.$root.addSpinner() },
		$removeSpinner(){ this.$root.removeSpinner() },
		$addTemporalSpinner(time = 0){ 
			this.$root.addSpinner()
			setTimeout(this.$root.removeSpinner, time)
		},
	},
}