<template>
  <div class="q-text-field" :class="{ large: large, small: !large, medium: medium }">
    <v-textarea
      @keydown.enter="save" :rows="rows" auto-grow ref="vTextField"
      :placeholder="placeholder" :hide-details="hideDetails" v-model="innerValue" solo
      :flat="!editting" @blur="(event) => save(event)" @focus="editting = true"
      class="summary" :class="{'selected': editting, 'reduceMargin': !reduceMargin }" :disabled="disabled"
      :spellcheck="false"
      @keyup.esc="cancel"
      @focusin="focus"
      v-bind="$attrs"
    >
      <template v-slot:append>
        <v-btn fab x-small v-if="editting && !handleSave" @click="save" color="primary">
          <v-icon>done</v-icon>
        </v-btn>
        <v-btn icon v-if="editting && !handleEsc" @click="cancel">
          <v-icon>close</v-icon>
        </v-btn>
      </template>
    </v-textarea>
  </div>
</template>

<script>
  import moment from 'moment'
  import _ from "lodash"

  export default {
    props: {
      handleEsc: { type: Boolean, default: false  },
      handleSave: { type: Boolean, default: false  },
      placeholder: String,
      value: { default: null},
      large: { type: Boolean, default: false },
      medium: { type: Boolean, default: false },
      disabled: { type: Boolean, default: false},
      rows: { type: Number, default: 1},
      reduceMargin: { type: Boolean, default: false},
      hideDetails: { type: Boolean, default: true},
    },
    data: () => ({
      innerValue: null,
      editting: false,
    }),
    watch: {
      value: function (val) {
        this.innerValue = val;
      },
    },
    computed: {
    },
    methods: {
      focus() {
        this.$emit('focus')
      },
      save(e) {
        // remove line breaks
        if(typeof this.innerValue !== 'number') {
          this.innerValue = this.innerValue.replace(/\r?\n?/g, '')
        }
        let id = null
        if(e.relatedTarget?.id) {
          id = e.relatedTarget.id
        }

        this.$emit('save', this.innerValue, id)
        this.$emit('input', this.innerValue)
        this.editting = false
        this.$refs.vTextField.blur()
      },
      cancel() {
        this.innerValue = this.value
        this.editting = false
        this.$refs.vTextField.blur()
      },
    },
    created() {
    },
   }
</script>

<style scoped lang="scss">

  .q-text-field.large .summary ::v-deep textarea {
    font-size: 22px;
    font-weight: 700;
    color: var(--v-text-lighten2);
    margin-bottom: 10px;
    padding-bottom: 10px;
  }
  .q-text-field.medium .summary ::v-deep textarea {
    font-size: 22px;
    font-weight: 700;
    color: var(--v-text-lighten2);
  }
  .q-text-field.large ::v-deep .v-input__control {
    min-height: 36px;
  }
  .q-text-field.medium ::v-deep .v-input__control {
    min-height: 15px;
  }
  .reduceMargin{
    margin-left: -12px;
    margin-right: -12px;
  }
  .selected ::v-deep .v-input__slot{
    background: var(--v-background-lighten) !important;
  }
</style>
