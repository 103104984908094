<template>
	<div class="pa-3 new-back fill-height">
		<filter-search-input v-model="filters.search" :applied-filters="appliedFilters" outlined dense>
			<q-user-autocomplete
				:value="filters.users" :items="allUsers"
				multiple outlined label="People" @save="(e) => filters.users = e"
			/>
			<v-autocomplete
				v-model="filters.country"
				item-text="name" item-value="id" :items="countries"
				outlined hide-details dense clearable return-object
				label="Country" @change="changeCountry"
			/>
			<v-autocomplete
				v-model="filters.state"
				item-text="name" item-value="id" :items="shownStates"
				outlined hide-details dense clearable return-object
				label="State" @change="changeState"
			/>
			<v-autocomplete
				v-model="filters.city"
				item-text="name" item-value="id" :items="shownCities"
				outlined hide-details dense clearable return-object
				label="City" @change="changeCity"
			/>
			<v-btn @click="resetFilters" small fab color="primary" class="ml-1">
				<v-icon>mdi-filter-variant-remove</v-icon>
			</v-btn>
		</filter-search-input>

		<div class="map-container elevation-5">
			<l-map
				style="height: 100%; z-index: 0"
				:zoom.sync="zoom"
				:min-zoom="2"
				:max-zoom="11"
				:center="center"
			>
				<l-tile-layer :url="url" :attribution="attribution"></l-tile-layer>
				<template v-if="zoom >= 6">
					<l-marker
						v-for="(city) in cities" :key="city.id"
						:lat-lng="[city.latitude, city.longitude]" :icon="icon"
						@click="changeCity(city)"
					>
						<l-tooltip
							v-if="city.quantity>1" class="tooltip"
							:options="{permanent: true, direction: 'right'}"
							:style="{
								fontSize: zoom < 6 ? '1em' : '1.6em',
								marginLeft: zoom < 6 ? '0' : '5px',
								marginTop: zoom < 6 ? '0' : '-5px'
							}"
						>+{{city.quantity-1}}</l-tooltip>
					</l-marker>
				</template>
				<template v-else-if="zoom >= 5">
					<l-marker
						v-for="(state, ix) in states" :key="ix+'state'"
						:lat-lng="[state.latitude, state.longitude]" :icon="icon"
						@click="changeState(state)"
					>
						<l-tooltip
							v-if="state.quantity>1" class="tooltip"
							:options="{permanent: true, direction: 'right'}"
							:style="{
								fontSize: zoom < 6 ? '1em' : '1.6em',
								marginLeft: zoom < 6 ? '0' : '5px',
								marginTop: zoom < 6 ? '0' : '-5px'
							}"
						>+{{state.quantity-1}}</l-tooltip>
					</l-marker>
				</template>
				<template v-else>
					<l-marker
						v-for="(country, ix) in countries" :key="ix+'country'"
						:lat-lng="[country.latitude, country.longitude]" :icon="icon"
						@click="changeCountry(country)"
					>
						<l-tooltip
							v-if="country.quantity>1" class="tooltip"
							:options="{permanent: true, direction: 'right'}"
							:style="{
								fontSize: zoom < 6 ? '1em' : '1.6em',
								marginLeft: zoom < 6 ? '0' : '5px',
								marginTop: zoom < 6 ? '0' : '-5px'
							}"
						>+{{country.quantity-1}}</l-tooltip>
					</l-marker>
				</template>
			</l-map>
			<div class="people-container pa-3">
				<div class="accent rounded pa-2 elevation-5">
					<v-icon color="white" large>mdi-map-marker-radius-outline</v-icon>
					<div v-if="!resultsText" class="title"> People Around <br> The World </div>
					<div v-else class="title"> {{resultsText}} </div>
				</div>
				<div @click="focusUser(user)" v-for="user in shownPeople" :key="user.id" class="people my-2 pa-2 cursor-pointer rounded">
					<UserAvatar :userId="user.id" hideLoading :size="50"/>
					<div>
						{{user.blueTag}}
						<br>
						<span>{{user.fullName}}</span>
					</div>
					<div class="city-state mt-1">
						<v-icon style="margin-top: -5px">mdi-city-variant-outline</v-icon>
						{{ user.city }}, {{ user.stateCode }}, {{ user.countryCode }}
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import UserAvatar from '../../components/UserAvatar'

export default {
	components: { UserAvatar },
	data(){
		return {
			attribution: '&copy; <a target="__blank" href="http://osm.org/copyright">OpenStreetMap</a> contributors',
			url: 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png',
      zoom: 5,
      center: [23.7345, -102.5528],

			allUsers: [],
			usersByCity: {},
			usersByState: {},

			selectedText: '',
			selectedUsers: [],

			filters: {
				search: '',
				users: [],
				country: '',
				state: '',
				city: '',
			},
			countries: [],
			states: [],
			cities: [],
		}
	},
	mounted(){
		document.title = 'People Map'
    this.$q.log(5, 30, 3, 'NA')
		this.$http
      .get(`/api/user/includeLocation`)
      .then(response => {
        this.allUsers = response.data
        this.allUsers.forEach(this.$q.profilePicSrc)

				let countries = {}
				let states = {}
				let cities = {}
				for(let user of this.allUsers){
					if(!countries[user.countryId]){
						countries[user.countryId] = {
							quantity: 0,
							latitude: user.countryLatitude,
							longitude: user.countryLongitude,
							name: user.country,
							code: user.countryCode,
							id: user.countryId,
						}
					}
					countries[user.countryId].quantity++

					if(!states[user.stateId]){
						states[user.stateId] = {
							quantity: 0,
							latitude: user.stateLatitude,
							longitude: user.stateLongitude,
							name: user.state,
							code: user.stateCode,
							countryCode: user.countryCode,
							country: user.country,
							id: user.stateId,
							countryId: user.countryId,
						}
					}
					states[user.stateId].quantity++

					if(!cities[user.cityId]){
						cities[user.cityId] = {
							quantity: 0,
							latitude: user.cityLatitude,
							longitude: user.cityLongitude,
							name: user.city,
							stateCode: user.stateCode,
							countryCode: user.countryCode,
							state: user.state,
							country: user.country,
							id: user.cityId,
							stateId: user.stateId,
							countryId: user.countryId,
						}
					}
					cities[user.cityId].quantity++
				}

				this.countries = this.jsonToArray(countries)
				this.states = this.jsonToArray(states)
				this.cities = this.jsonToArray(cities)
      })
      .catch(response => {
        window.getApp.error("Cannot get records.")
        console.log(response)
      });
	},
	computed: {
		shownPeople(){
			let users = this.allUsers
			if(this.filters.users && this.filters.users.length){
				users = users.filter(u => this.filters.users.includes(u.id))
			}
			if(this.filters.country){
				users = users.filter(u => this.filters.country == u.countryId)
			}
			if(this.filters.state){
				users = users.filter(u => this.filters.state == u.stateId)
			}
			if(this.filters.city){
				users = users.filter(u => this.filters.city == u.cityId)
			}
      users = this.applySearch(this.filters.search, users, ['fullName', 'blueTag', 'city', 'state', 'country'])
			return users
		},
		shownStates(){
			return this.states.filter(s => !this.filters.country || s.countryId == this.filters.country)
		},
		shownCities(){
			return this.cities.filter(c => 
				(!this.filters.country || c.countryId == this.filters.country) &&
				(!this.filters.state || c.stateId == this.filters.state)
			)
		},
		icon(){
			let size = [41, 40]
			if(this.zoom == 5)
				size = [31, 30]
			if(this.zoom == 7 || this.zoom == 11)
				size = [51, 50]
			return L.icon({
        iconUrl: require('../../assets/avatar_blue.png'),
        iconSize: size,
      })
		},
		resultsText(){
			let city = this.cities.find(c => c.id == this.filters.city)
			let state = this.states.find(s => s.id == this.filters.state)
			let country = this.countries.find(c => c.id == this.filters.country)
			if(city)
				return city.name + ', ' + state.name + ', ' + country.code
			if(state)
				return state.name + ', ' + country.name
			if(country)
				return country.name
			return ''
		},
    appliedFilters(){ return this.countActiveFilters(this.filters, ['search']) },
	},
	methods: {
		resetFilters(){
			this.filters = {
				users: [],
				country: '',
				state: '',
				city: '',
			}
		},
		focusUser(user){
			this.center = [user.cityLatitude, user.cityLongitude]
		},
		removeUser(userId){
			this.filters.users = this.filters.users.filter(u => u != userId)
		},
		changeCountry(country){
			this.filters.city = ''
			this.filters.state = ''
			this.filters.country = country?.id || ''
			if(country){
				this.center = [country.latitude, country.longitude]
			}
		},
		changeState(state){
			this.filters.city = ''
			this.filters.state = state?.id || ''
			if(state){
				this.center = [state.latitude, state.longitude]
				this.filters.country = state?.countryId || ''
			}
		},
		changeCity(city){
			this.filters.city = city?.id || ''
			if(city){
				this.center = [city.latitude, city.longitude]
				this.filters.state = city?.stateId || ''
				this.filters.country = city?.countryId || ''
			}
		},
	},
}
</script>

<style scoped lang="scss">
.map-container {
	display: grid;
	grid-template-columns: 80% auto;

	.tooltip{
		color: var(--v-primary-base);
		background-color: rgba(255, 255, 255, .7);
		width: 28px; height: 28px;
		text-align: center;
		border-radius: 50%;
		font-weight: bold;
		letter-spacing: 0;
	}
}

.people-container {
	background: var(--v-background-lighten);
	height: calc(100vh - 110px);
	overflow-y: scroll;
	.accent{
		position: sticky;
		top: -12px;
		z-index: 1;
		color: white;
		display: grid;
		grid-template-columns: 30px auto;
		align-items: center;
		
		div{
			text-align: center;
			padding: 0 10px;
		}
	}
	.people{
		display: grid;
		grid-template-columns: 60px auto;
		align-items: center;
		background: var(--v-coloredBack-base);
		row-gap: 5px;
		span{
			color: var(--v-text-lighten2);
		}
		.city-state{
			.v-icon{
				font-size: 1.4em;
			}

			padding-left: 5px;
			color: var(--v-text-lighten2);
			justify-content: center;
			grid-column: 1/3;
			font-size: .9em;
		}
	}
}
</style>