import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    // root store states
    // TODO: divide into modules once has grow
    mainTitle: 'Blue Surf',
    showPreviousRedirect: false,
    user: {},
    permissions: {},
    permissionsPromise: null,
    isAnniversary: false,
  },
  mutations: {
    definedMainTitle(state, title) {
      state.mainTitle = title
    },
    setUser(state, user) {
      state.user = user
    },
    setPreviousRedirect(state, val) {
      state.showPreviousRedirect = val
    },
    setProperty(state, payload = { property: '', value: '' }) {
      state[payload.property] = payload.value
    },
  },
  getters: {
    getUser: (state) => {
      return state.user
    },
  },
  actions: {
  },
  modules: {
  }
})
