import moment from "moment";

const billablesMixin = {
  install (Vue, options) {
    Vue.mixin({
      data: () => ({
        durationUnits: [
          { value: 3, hours: 1, text: 'Hours' },
          { value: 0, hours: 8, text: 'Days' },
          { value: 1, hours: 40, text: 'Weeks' },
          { value: 2, hours: 168, text: 'Months' },
        ],
      }),
      methods: {
        mappedAssignmentsToBillables(originalAssignments) {
          let assignments = JSON.parse(JSON.stringify(originalAssignments))
          let linesJSON = {}
          for(let assignment of assignments){
            if(!linesJSON[assignment.assignmentBillableLineId]){
              linesJSON[assignment.assignmentBillableLineId] = {
                ...assignment.assignmentBillableLine,
                billableCode: assignment.assignmentBillableCode,
                billableId: assignment.assignmentBillableId,
                currency: assignment.assignmentBillableCurrency,
                assignments: [],
                ratePerHour: 0,
              }
            }
            let billableLine = assignment.assignmentBillableLine
            if(assignment.assignmentBillableType === 0){
              assignment.ratePerHour = billableLine.rate / this.durationUnits.find(du => du.value == billableLine.durationUnit).hours
              linesJSON[assignment.assignmentBillableLineId].ratePerHour = billableLine.rate / this.durationUnits.find(du => du.value == billableLine.durationUnit).hours
            }
            else{
              assignment.ratePerHour = billableLine.rate
              linesJSON[assignment.assignmentBillableLineId].ratePerHour = billableLine.rate
            }
            delete assignment.billableLine
            delete assignment.billable
            linesJSON[assignment.assignmentBillableLineId].assignments.push(assignment)
          }

          let lines = this.jsonToArray(linesJSON)
          let billablesJSON = {}
          for(let line of lines) {
            if(!billablesJSON[line.billableId]){
              billablesJSON[line.billableId] = {
                code: line.billableCode,
                billableId: line.billableId,
                currency: line.currency,
                billableLines: [],
              }
            }
            delete line.billable
            billablesJSON[line.billableId].billableLines.push(line)
          }
          let billables = this.jsonToArray(billablesJSON)
          return billables
        },
        assignmentsToBillables(originalAssignments) {
          let assignments = JSON.parse(JSON.stringify(originalAssignments))
          let linesJSON = {}
          for(let assignment of assignments){
            if(!linesJSON[assignment.billableLineId]){
              linesJSON[assignment.billableLineId] = {
                ...assignment.billableLine,
                billableCode: assignment.billableCode,
                billableId: assignment.billableId,
                currency: assignment.billableCurrency,
                assignments: [],
                ratePerHour: 0,
              }
            }
            if(assignment.billableType === 0){
              assignment.ratePerHour = assignment.billableLine.rate / this.durationUnits.find(du => du.value == assignment.billableLine.durationUnit).hours
              linesJSON[assignment.billableLineId].ratePerHour = assignment.billableLine.rate / this.durationUnits.find(du => du.value == assignment.billableLine.durationUnit).hours
            }
            else{
              assignment.ratePerHour = assignment.billableLine.rate
              linesJSON[assignment.billableLineId].ratePerHour = assignment.billableLine.rate
            }
            delete assignment.billableLine
            delete assignment.billable
            linesJSON[assignment.billableLineId].assignments.push(assignment)
          }

          let lines = this.jsonToArray(linesJSON)
          let billablesJSON = {}
          for(let line of lines) {
            if(!billablesJSON[line.billableId]){
              billablesJSON[line.billableId] = {
                code: line.billableCode,
                billableId: line.billableId,
                currency: line.currency,
                billableLines: [],
              }
            }
            delete line.billable
            billablesJSON[line.billableId].billableLines.push(line)
          }
          let billables = this.jsonToArray(billablesJSON)
          return billables
        },
        jsonToArray(json){
          let arr = []
          for(let key in json){
            arr.push(json[key])
          }
          return arr
        },
        getHolidays(startDate, endDate, holidays = []){
          startDate = moment(startDate)
          endDate = moment(endDate)
          let ans = 0
          while(startDate <= endDate){
            if(holidays.some(h => moment(h.date).isSame(startDate, 'day'))){
              ans++
            }
            startDate = startDate.add(1, 'day')
          }
          return ans
        },
      }
    })
  }
}
export default billablesMixin