<template>
	<v-select 
		@change="emitChange"
		@input="handleInput"
		dense outlined hide-details
		:label="label"
		v-model="content"
		item-value="id" item-text="name"
		:items="items"
	>
		<template v-slot:item="{ item }">
			<v-chip class="cursor-pointer pa-2 mt-1 mb-1" label :style="{ width: '100%'}" dark :color="item.color">
				<v-icon class="mr-2">{{item.iconClass}}</v-icon>
				{{item.name}}
			</v-chip>
		</template>
		<template v-slot:selection="{ item }">
			<v-icon :color="item.color" class="mr-2">{{item.iconClass}}</v-icon>
			{{item.name}}
		</template>
	</v-select>
</template>

<script>
export default {
	props: ['value', 'label', 'items'],
	data(){
		return {
			content: this.value,
		}
	},
	watch: {
		value(){
			this.content = this.value
		},
	},
	methods: {
		emitChange(){
			this.$emit('change', this.content)
		},
		handleInput (e) {
      this.$emit('input', this.content)
    },
	},
}
</script>

