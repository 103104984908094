<template>
  <!-- <div> -->
    <!-- <v-btn @click="show = !show">
    </v-btn> -->
    <v-tooltip class="pin-button" top>
      <template v-slot:activator="{ on }">
        <v-icon v-on="on" small :color="pinned ? 'primary': ''">
          {{pinned ? 'visibility': 'visibility_off'}}
        </v-icon>
      </template>
      <span>{{ pinned ? 'Unpin column' : 'Pin column' }}</span>
    </v-tooltip>
  <!-- </div> -->
</template>

<script>
import moment from 'moment'
import _ from "lodash"

export default {
  components: {  },
  props: {
    pinned: { type: Boolean, default: false},
  },
}

</script>

<style scoped lang="scss">
  .pin-button { display: inline-block; }
  .v-icon { display: inline-block; }
</style>