var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.$security.can('add'))?_c('div',{staticClass:"text-right mb-5"},[_c('v-btn',{attrs:{"color":"primary","fab":"","small":""},on:{"click":_vm.add}},[_c('v-icon',[_vm._v("mdi-plus")])],1)],1):_vm._e(),_c('data-table',{attrs:{"headers":_vm.headers,"items":_vm.categories},scopedSlots:_vm._u([{key:"item.name",fn:function(ref){
var item = ref.item;
return [_c('v-text-field',{attrs:{"background-color":"transparent","flat":!item.editing,"solo":!item.editing,"outlined":item.editing,"hide-details":"","dense":"","readonly":!item.editing},model:{value:(item.name),callback:function ($$v) {_vm.$set(item, "name", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"item.name"}})]}},{key:"item.smallCode",fn:function(ref){
var item = ref.item;
return [_c('v-text-field',{staticStyle:{"width":"100px"},attrs:{"background-color":"transparent","flat":!item.editing || !!item.id,"solo":!item.editing || !!item.id,"outlined":item.editing && !item.id,"hide-details":"","dense":"","readonly":!item.editing || !!item.id},model:{value:(item.smallCode),callback:function ($$v) {_vm.$set(item, "smallCode", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"item.smallCode"}})]}},{key:"item.active",fn:function(ref){
var item = ref.item;
return [_c('check-switch',{attrs:{"disabled":!item.editing},model:{value:(item.active),callback:function ($$v) {_vm.$set(item, "active", $$v)},expression:"item.active"}})]}},{key:"item.createdAt",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("formatDate")(item.createdAt))+" ")]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"text-right"},[(_vm.$security.can('edit') && !item.editing)?_c('v-menu',{attrs:{"left":"","offset-x":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"icon":""}},on),[_c('v-icon',[_vm._v("mdi-dots-vertical")])],1)]}}],null,true)},[_c('v-list',{staticClass:"py-1",attrs:{"dense":""}},[_c('v-list-item',{staticClass:"pa-1 py-0"},[_c('v-tooltip',{attrs:{"right":"","z-index":"200"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"color":"primary","text":"","small":""},on:{"click":function($event){return _vm.$set(item, 'editing', true)}}},on),[_c('v-icon',[_vm._v("mdi-pencil")])],1)]}}],null,true)},[_c('span',{staticClass:"caption"},[_vm._v("Edit")])])],1),(_vm.$security.can('delete'))?_c('v-list-item',{staticClass:"pa-1 py-0"},[_c('v-tooltip',{attrs:{"right":"","z-index":"200"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"color":"error","text":"","small":""},on:{"click":function($event){return _vm.remove(item)}}},on),[_c('v-icon',[_vm._v("mdi-delete")])],1)]}}],null,true)},[_c('span',{staticClass:"caption"},[_vm._v("Edit")])])],1):_vm._e()],1)],1):_c('div',{staticClass:"d-flex",staticStyle:{"gap":"5px"}},[_c('v-btn',{attrs:{"color":"primary","icon":"","small":"","disabled":!item.name || !item.smallCode},on:{"click":function($event){return _vm.save(item)}}},[_c('v-icon',[_vm._v("mdi-check")])],1),_c('v-btn',{attrs:{"icon":"","small":""},on:{"click":function($event){return _vm.cancel(item)}}},[_c('v-icon',[_vm._v("mdi-close")])],1)],1)],1)]}}],null,true)})],1)}
var staticRenderFns = []

export { render, staticRenderFns }