<template>
	<div>
		<v-card class="mb-7 background--lighten">
			<v-card-subtitle class="py-2">Resources</v-card-subtitle>
			<v-card-text>
				<h1 class="mb-4 text--text">{{totalResources}} Total Resources in {{projects.filter(x => x.active).length}} projects</h1>

				<div class="types-legend">
					<div v-for="(type) in projectTypes" :key="type.value" class="type">
						<div :style="'background:' + type.color" class="circle"></div>
						{{type.text}}
					</div>
				</div>

				<div class="charts-cont">
					<div v-for="(chartOptions, ix) in deliveryCharts" :key="ix">
						<highcharts style="height: 250px; width: 400px" :options="chartOptions" :key="chartOptions.key + 'ix'"/>
					</div>
				</div>
			</v-card-text>
		</v-card>

		<div class="d-flex justify-space-between align-center mb-3">
			<div>
				<v-btn color="primary" style="float: left;" :outlined="filterActive" @click="filterActive = !filterActive"> <v-icon small>mdi-filter</v-icon> Filter</v-btn>
				<v-text-field
				class="pl-3" style="max-width: 300px;" label="Search"
				prepend-inner-icon="search"
				outlined dense hide-details single-line clearable
				v-model="filters.search"
				/>
			</div>
			<div>
				<v-btn @click="downloadExcel" class="mr-1" color="primary" text>
					<v-icon left>mdi-upload</v-icon> Export
				</v-btn>
				<v-btn @click="openDialog" color="primary" fab small>
					<v-icon>mdi-plus</v-icon>
				</v-btn>
			</div>
		</div>
		<div v-show="filterActive" class="filters mb-3">
			<v-autocomplete
				label="Project"
				outlined dense hide-details single-line clearable multiple
				v-model="filters.project" :items="shownFilterProjects"
				item-text="name" item-value="id"
			/>
			<v-select
				label="Type" class="select"
				outlined dense hide-details single-line clearable multiple
				v-model="filters.type" :items="projectTypes"
			>
				<template #item="{ item }">
					<div class="project" :style="{background: item.lightColor}">
						<div class="letter" :style="{background: item.color}" >
							{{item.small}}
						</div>
						<div class="name">{{item.text}}</div>
					</div>
				</template>
				<template #selection="{ item }">
					<div class="project" :style="{background: item.lightColor}">
						<div class="letter" :style="{background: item.color}" >
							{{item.small}}
						</div>
						<div class="name">{{item.text}}</div>
					</div>
				</template>
			</v-select>
			<v-autocomplete
				label="Customer"
				outlined dense hide-details single-line clearable multiple
				v-model="filters.customer" :items="shownCustomers"
				item-text="name" item-value="id"
			>
				<template v-slot:item="{ item }">
					<div class="opt" :style="{color: item.filtered ? '' : 'grey'}">
						<v-icon v-if="filters.customer.includes(item.id)" color="primary">mdi-checkbox-marked</v-icon>
						<v-icon v-else>mdi-checkbox-blank-outline</v-icon>
						{{item.name}}
					</div>
				</template>
			</v-autocomplete>
			<v-select
				label="Status" class="select"
				outlined dense hide-details single-line clearable
				v-model="filters.status" :items="statuses"
			/>
			<q-user-autocomplete
				label="DM"
				dense outlined hide-details clearable multiple
				v-model="filters.delivery" :items="shownUsers"
				item-text="blueTag" item-value="id"
			/>
		</div>
		<v-data-table
			class="colored-header rounded elevation-2"
			style="overflow: hidden"
			:items="shownProjects" :headers="projectHeaders"
		>
			<template #[`item.type`]="{ item }">
				<div
					class="project"
					:style="{background: projectTypes.find(t => t.value == item.type).lightColor}"
				>
					<div
						class="letter"
						:style="{background: projectTypes.find(t => t.value == item.type).color}"
					>
						{{projectTypes.find(t => t.value == item.type).small}}
					</div>
					<div class="name">{{projectTypes.find(t => t.value == item.type).text}}</div>
				</div>
			</template>
			<template #[`item.status`]="{ item }">
				<div>
					<v-btn
						:color="item.active ? 'green' : 'error'"
						:style="{background: item.active ? '#e1f8dd' : '#f5d0d0'}"
						outlined small style="border-radius: 20px"
					>{{item.active ? 'Active' : 'Inactive'}}</v-btn>
				</div>
			</template>
		</v-data-table>

		<v-dialog v-if="showDialog" v-model="showDialog" width="400" persistent>
			<v-card>
				<v-btn @click="showDialog = false" absolute icon right><v-icon>mdi-close</v-icon></v-btn>
				<v-card-title>New Project</v-card-title>
				<v-card-text>
					<v-form v-model="validForm">
						<label>Name*</label>
						<v-text-field
							v-model="newProject.name"
							outlined hide-details dense
							:rules="rules" class="mb-3"
						/>

						<label>Type*</label>
						<v-select
							v-model="newProject.type" :items="projectTypes"
							outlined hide-details dense
							:rules="rules" class="mb-3"
						>
							<template v-slot:item="{ item }">
								<div class="my-1">
									<div class="project" :style="{background: item.lightColor}">
										<div class="letter" :style="{background: item.color}">{{item.small}}</div>
										<div class="name">{{item.text}}</div>
									</div>
								</div>
							</template>
							<template v-slot:selection="{ item }">
								<div class="mt-1">
									<div class="project" :style="{background: item.lightColor}">
										<div class="letter" :style="{background: item.color}">{{item.small}}</div>
										<div class="name">{{item.text}}</div>
									</div>
								</div>
							</template>
						</v-select>

						<label>Customer*</label>
						<v-autocomplete
							v-model="newProject.customerId"
							outlined hide-details dense
							:rules="rules" :items="customers"
							item-text="name" item-value="id" class="mb-3"
						/>
						<label>Delivery Manager</label>
						<q-user-autocomplete
							v-model="newProject.deliveryManagerId"
							outlined hide-details :confirmable="false"
							:items="users" :rules="rules" class="mb-3"
						/>
					</v-form>
					<div class="d-flex">
						<v-btn @click="showDialog = false" color="primary" outlined width="49%">Cancel</v-btn>
						<v-spacer></v-spacer>
						<v-btn @click="save" :disabled="!validForm" color="primary" width="49%">Save</v-btn>
					</div>
				</v-card-text>
			</v-card>
		</v-dialog>
	</div>
</template>

<script>
import moment from 'moment'
import DeliveryProjectsChart from '../../charts/DeliveryProjectsChart'
import billableJson from '../../json/billable.json'

export default {
	data: () => ({
		projectTypes: billableJson.projectTypes,
		projects: [],
		projectsPerDelivery: [],
		projectHeaders: [
			{ text: "Project", value: "name", },
			{ text: "Type", value: "type", },
			{ text: "Customer", value: "customerName", },
			{ text: "Status", value: "status", },
			{ text: "Resources", value: "assignments.length", },
			{ text: "DM", value: "deliveryManagerBlueTag", },
		],
		deliveryCharts: [],
		totalResources: 0,

		customers: [],
		users: [],

		filterActive: false,
		filters: {
			search: '',
			project: [],
			type: [],
			customer: [],
			status: null,
			delivery: [],
		},
		statuses: [
			{ value: 0, text: 'Inactive' },
			{ value: 1, text: 'Active' },
		],

		showDialog: false,
		newProject: {},
		validForm: false,
		rules: [v => !!v || v === 0 || 'Required'],
	}),
	mounted(){
		this.init()
	},
	computed: {
		shownProjects(){
			let projects = this.projects

			if(this.filters.project?.length){
				projects = projects.filter(p => this.filters.project.includes(p.id))
			}

			if(this.filters.type?.length){
				projects = projects.filter(p => this.filters.type.includes(p.type))
			}

			if(this.filters.customer?.length){
				projects = projects.filter(p => this.filters.customer.includes(p.customerId))
			}

			if(this.filters.status !== undefined && this.filters.status !== null){
				projects = projects.filter(p => !!p.active == !!this.filters.status)
			}

			if(this.filters.delivery?.length){
				projects = projects.filter(p => this.filters.delivery.includes(p.deliveryManagerId))
			}

			projects = this.applySearch(this.filters.search, projects)

			return projects
		},
		shownFilterProjects(){
			let projects = this.projects
			projects.map(x => {
				x.filtered = this.shownProjects.some(p => p.id == x.id)
			})
			projects = projects.sort((a, b) => {
				if(b.filtered == a.filtered)
					return a.name.localeCompare(b.name)
				return b.filtered-a.filtered
			})
			return projects
		},
		shownCustomers(){
			let customers = this.customers
			customers.map(c => {
				c.filtered = this.shownProjects.some(p => p.customerId == c.id)
			})
			customers = customers.sort((a, b) => {
				if(b.filtered == a.filtered)
					return a.name.localeCompare(b.name)
				return b.filtered-a.filtered
			})
			return customers
		},
		shownUsers(){
			let users = this.users.filter(u => this.projects.some(a => a.deliveryManagerId == u.id))
			users.map(u => {
				u.filtered = this.shownProjects.some(a => a.deliveryManagerId == u.id)
			})
			users = users.sort((a, b) => {
				if(b.filtered == a.filtered)
					return a.blueTag.localeCompare(b.blueTag)
				return b.filtered-a.filtered
			})
			return users
		},
	},
	methods: {
		init(){
			const today = moment().startOf('day')
			this.$http.get('api/ProjectReport')
				.then(res => {
					this.projects = res.data.customerProjects
						.map(p => ({
							...p,
							active: p.assignments.some(x => today.isSameOrAfter(x.startDate) && today.isSameOrBefore(x.endDate))
						}))
					this.projectsPerDelivery = res.data.projectsPerDelivery

					this.deliveryCharts = this.projectsPerDelivery
						.filter(d => d.activeResources)
						.map(d => DeliveryProjectsChart(d, this.projectTypes))
					this.totalResources = this.projectsPerDelivery.reduce((partial, a) => partial + a.activeResources, 0)
				})
			this.$http.get('api/user?active=true&onlyInterns=true')
				.then(res => {
					this.users = res.data
				})
			this.$http.get("/api/customer?onlyWithCustomerProjects=true")
				.then(res => {
					this.customers = res.data
				})
				.catch(res => {
					window.getApp.error("Cannot obtain customers.")
				})
		},
		save(){
			this.$q.save({
        api: 'api/CustomerProject',
        data: this.newProject,
        successMsg: 'New project created',
        afterSuccessUrl: null,
        errorMsg: "Cannot save changes.",
        afterErrorUrl: null,
      }).then(res => {
				this.init()
				this.showDialog = false
			})
		},
		openDialog(){
			this.newProject = {}
			this.showDialog = true
		},
		downloadExcel(){
			let props = {
        Title: 'Customer Projects',
        Subject: "Projects",
        Author: 'BlueSurf',
        CreatedDate: new Date()
      }

      // Fill sheet info
      let data = [], row = []

      row.push('Project')
      row.push('Type')
      row.push('Customer')
      row.push('Resources')
      row.push('DM')
      data.push(row)

			for(let project of this.shownProjects){
				row = []
				row.push(project.name)
				row.push(project.typeName)
				row.push(project.customerName)
				row.push(project.assignments.length)
				row.push(project.deliveryManagerBlueTag || project.deliveryManagerFullName)
				data.push(row)
			}

      let sheets = [{
        name: 'Customer Projects',
        data
      }]

      this.createExcel(props, sheets)
		},
	},
}
</script>

<style lang="scss" scoped>
.types-legend{
	display: flex;
	flex-wrap: wrap;
	gap: 30px;

	.type{
		display: flex;
		align-items: center;
		gap: 5px;
		font-size: 15px;
		user-select: none;

		.circle{
			height: 15px;
			width: 15px;
			border-radius: 50%;
		}
	}
}
.charts-cont{
	display:flex;
	flex-wrap: wrap;
	justify-content: center;
}

.filters{
	display: inline-flex;
	gap: 10px;

	::v-deep .v-select__selections input{
		max-width: 70px;
	}
}

.project{
	display: inline-block;
	border-radius: 15px;
	white-space:nowrap;
  height: 30px;
	div{
		display: inline-flex;
		align-items: center;
		justify-content: center;
    height: 30px;
	}
	.letter{
		width: 30px;
		padding: 5px;
		color: white;
		border-radius: 15px;
	}
	.name{
		padding: 0 10px 0 5px;
		color: black;
	}
}


</style>