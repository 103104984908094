var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"pa-5 new-back fill-height"},[_c('filter-search-input',{attrs:{"outlined":"","dense":""},model:{value:(_vm.filters.search),callback:function ($$v) {_vm.$set(_vm.filters, "search", $$v)},expression:"filters.search"}},[_c('v-autocomplete',{attrs:{"items":_vm.permissions,"label":"Interface","item-text":"name","item-value":"value","outlined":"","dense":"","clearable":"","hide-details":""},on:{"change":function($event){_vm.filters.accessLevels = []}},model:{value:(_vm.filters.interface),callback:function ($$v) {_vm.$set(_vm.filters, "interface", $$v)},expression:"filters.interface"}}),_c('q-user-autocomplete',{attrs:{"items":_vm.users,"label":"Users","outlined":"","dense":"","multiple":"","clearable":"","hide-details":"","confirmable":false,"open-user-card":false},model:{value:(_vm.filters.users),callback:function ($$v) {_vm.$set(_vm.filters, "users", $$v)},expression:"filters.users"}}),(_vm.filters.interface !== null && _vm.filters.interface !== undefined)?_c('v-select',{attrs:{"items":_vm.shownAccessLevels,"label":"Access Level","item-text":"text","item-value":"value","outlined":"","dense":"","clearable":"","multiple":"","hide-details":""},model:{value:(_vm.filters.accessLevels),callback:function ($$v) {_vm.$set(_vm.filters, "accessLevels", $$v)},expression:"filters.accessLevels"}}):_vm._e()],1),_c('data-table',{attrs:{"headers":_vm.headers,"items":_vm.shownItems,"item-key":"_key","multi-sort":""},scopedSlots:_vm._u([{key:"item.userBlueTag",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex align-center my-1",staticStyle:{"gap":"10px"}},[_c('user-avatar',{attrs:{"user-id":item.userId,"size":32,"show-card":false}}),_c('div',[_c('b',[_vm._v(_vm._s(item.userBlueTag))]),_c('br'),_c('span',[_vm._v(_vm._s(item.userFullName))])])],1)]}},{key:"item.userPermission",fn:function(ref){
var item = ref.item;
return [_c('div',{attrs:{"set":_vm.pem = _vm.permissions.find(function (x) { return x.value == item.permissionType; })}},[(_vm.pem)?_c('access-level-select',{attrs:{"value":item.userPermission,"type":_vm.pem.levelTypes,"disabled":""}}):_c('i',{staticClass:"caption"},[_vm._v("Obsolete")])],1)]}},{key:"item.groupPermission",fn:function(ref){
var item = ref.item;
return [_c('div',{attrs:{"set":_vm.pem = _vm.permissions.find(function (x) { return x.value == item.permissionType; })}},[(_vm.pem)?_c('access-level-select',{attrs:{"value":item.groupPermission,"type":_vm.pem.levelTypes,"disabled":""}}):_c('i',{staticClass:"caption"},[_vm._v("Obsolete")])],1)]}},{key:"item.permission",fn:function(ref){
var item = ref.item;
return [_c('div',{attrs:{"set":_vm.pem = _vm.permissions.find(function (x) { return x.value == item.permissionType; })}},[(_vm.pem)?_c('access-level-select',{attrs:{"value":item.permission,"type":_vm.pem.levelTypes,"disabled":""}}):_c('i',{staticClass:"caption"},[_vm._v("Obsolete")])],1)]}}],null,true)}),_c('div',{staticClass:"my-10"},[_c('h2',[_c('v-icon',{attrs:{"color":"primary","large":""}},[_vm._v("mdi-shield-crown-outline")]),_vm._v(" Super Admins "),_c('v-icon',{attrs:{"color":"primary","large":""}},[_vm._v("mdi-shield-crown-outline")])],1),_c('div',{staticClass:"d-flex flex-wrap mt-2",staticStyle:{"gap":"15px"}},_vm._l((_vm.superAdmins),function(user){return _c('div',{key:user.id,staticClass:"d-flex align-center justify-center pa-3 bordered darken elevation-1 rounded-lg",staticStyle:{"gap":"10px","width":"200px","position":"relative"}},[_c('div',{staticClass:"gradient"}),_c('user-avatar',{attrs:{"user-id":user.id,"show-card":false}}),_c('div',{staticClass:"text-center"},[_c('b',[_vm._v(_vm._s(user.blueTag))]),_c('br'),_c('span',{staticClass:"body-2"},[_vm._v(_vm._s(user.fullName))])])],1)}),0)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }