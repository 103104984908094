<template>
	<div>
		<v-menu
			v-model="operativeProjectsMenu.show" absolute
			:position-x="operativeProjectsMenu.x" :position-y="operativeProjectsMenu.y"
		>
			<v-card color="coloredBack" max-height="200" class="overflow-y-auto">
				<v-card-text class="pa-0">
					<v-list color="coloredBack" dense>
						<v-list-item v-for="project in operativeProjectsMenu.projects" :key="project.id">
							<v-list-item-content>{{ project.smallCode }} - {{ project.name }}</v-list-item-content>
						</v-list-item>
					</v-list>
				</v-card-text>
			</v-card>
		</v-menu>

		<div class="grid-6 align-center">
			<div class="elevated high-back rounded-xl mt-1 mb-4 pa-2" style="position: relative;">
				<b
					class="title"
					style="font-size: 1.1rem !important; position: absolute; top: -1.8rem; left: 15px"
				>
					Report date
				</b>
				<q-date-picker
					v-model="dashboardDateFilter" label="Date"
					@change="dashboardDateFilterChanged"
					outlined dense inner hide-details single-line
					class="no-border ml-1"
				/>
			</div>
		</div>
		<v-row>

			<v-col :cols="12" :lg="6" class="d-flex align-end">
				<div class="grid-3" style="gap:10px; width: 100%">
					<v-menu open-on-hover bottom offset-y>
						<template #activator="{ on }">
							<div
								v-on="on"
								class="flex indicator elevated high-back rounded-xl"
								:class="{ 'indicator-error': !!indicators.differenceUsers.length }"
							>
								<v-icon
									:size="32"
									:style="{ background: $vuetify.theme.isDark ? '' : '#e8f2fb' }"
									color="#167bd7"
								>
									mdi-account-multiple-outline
								</v-icon>
								<div>
									<h2>{{ indicators.currentUsers }}</h2>
									<span>People</span>
								</div>
							</div>
						</template>
						<v-card max-height="400" class="overflow-y-auto">
							<v-card-text class="pa-0">
								<v-list dense>
									<v-list-item
										v-for="(u, ix) in indicators.differenceUsers" :key="u.id"
										:style="{
											borderBottom: ix < indicators.differenceUsers.length - 1
												? '1px solid var(--v-lightgray-base)' : ''
										}"
									>
										<v-list-item-avatar>
											<user-avatar :user-id="u.id" :show-card="false"/>
										</v-list-item-avatar>
										<v-list-item-content>
											<v-list-item-title>{{u.blueTag}}</v-list-item-title>
											<v-list-item-subtitle>{{u.fullName}}</v-list-item-subtitle>
										</v-list-item-content>
									</v-list-item>
								</v-list>
							</v-card-text>
						</v-card>
					</v-menu>

					<v-menu bottom offset-y open-on-hover>
						<template #activator="{ on }">
							<div
								v-on="on"
								class="flex indicator elevated high-back rounded-xl"
								:class="{ 'indicator-error': !!indicators.differenceUsers.length }"
							>
								<v-icon :size="32" :style="{ background: $vuetify.theme.isDark ? '' : '#e8f2fb' }"
									color="#167bd7">
									mdi-wrench-outline </v-icon>
								<div>
									<h2>{{ indicators.currentWorkforce }}</h2>
									<span>Workforce</span>
								</div>
							</div>
						</template>
						<v-card max-height="400" class="overflow-y-auto">
							<v-card-text class="pa-0">
								<v-list dense>
									<v-list-item
										v-for="(u, ix) in indicators.differenceWorkforce" :key="u.id"
										:style="{
											borderBottom: ix < indicators.differenceWorkforce.length - 1
												? '1px solid var(--v-lightgray-base)' : ''
										}"
									>
										<v-list-item-avatar>
											<user-avatar :user-id="u.id" :show-card="false"/>
										</v-list-item-avatar>
										<v-list-item-content>
											<v-list-item-title>{{u.blueTag}}</v-list-item-title>
											<v-list-item-subtitle>{{u.fullName}}</v-list-item-subtitle>
											<div class="text-right" style="position: absolute; right: 15px;">
												<v-list-item-title>{{u.difference > 0 ? 'Sub-Assigned' : 'Over-Assigned'}}</v-list-item-title>
												<v-list-item-subtitle>
													{{Math.abs(u.difference)}}
												</v-list-item-subtitle>
											</div>
										</v-list-item-content>
									</v-list-item>
								</v-list>
							</v-card-text>
						</v-card>
					</v-menu>

					<div class="flex indicator elevated high-back rounded-xl">
						<v-icon
							:size="32" :style="{ background: $vuetify.theme.isDark ? '' : '#e8f2fb' }"
							color="#167bd7"
						>
							mdi-card-account-details-outline
						</v-icon>
						<div>
							<h2>{{ indicators.activeNuclei }}</h2>
							<span>Active Nuclei</span>
						</div>
						<v-spacer></v-spacer>
						<v-menu
							v-model="activeNucleiMenu"
							:close-on-content-click="false"
							offset-y z-index="200"
						>
							<template v-slot:activator="{ on, attrs }">
								<v-btn
									v-on="on" v-bind="attrs"
									class="mr-1 mt-1" icon
								>
									<v-icon>mdi-cog</v-icon>
								</v-btn>
							</template>

							<v-card color="high-back">
								<v-card-text>
									<h3>Active Nuclei Project Types</h3>
									<v-row style="width:380px" dense class="mt-2">
										<v-col
											v-for="projectType in activeNucleiProjectTypes" :key="projectType.value"
											cols="12" sm="6"
										>
											<v-checkbox
												v-model="nucleiProjectArray" class="ma-0 pa-0"
												hide-details :value="projectType.value"
												:label="projectType.text"
											/>
										</v-col>
										<v-col cols="12" class="text-right">
											<v-btn
												@click="saveNucleiProjects"
												color="primary" depressed
											>
												<v-icon left>check</v-icon>Save
											</v-btn>
										</v-col>
									</v-row>

								</v-card-text>
							</v-card>

						</v-menu>
					</div>
				</div>
			</v-col>

			<v-col :cols="12" :lg="6">
				<div>
					<v-row dense>
						<v-col
							v-for="(project) in poolcampProjects" :key="project.id"
							:cols="6" :md="4" :xl="3"
						>
							<div class="pool-indicator elevated rounded-xl" :style="{ 'border-left-color': colorByProject[project.id] }">
								<h2> {{ project.quantity }} </h2>
								<span class="body-2">{{ project.name }}</span>
							</div>
						</v-col>
					</v-row>
				</div>
			</v-col>

			<v-col :cols="12" :lg="6" class="d-flex flex-column">
				<div
					class="flex rounded-xl pa-5 high-back mt-2 elevated chart-container"
					style="border: 1px solid var(--v-background-lighten2); padding: 10px; position: relative;"
				>
					<h3 style="border: none">Active Nuclei vs Workforce</h3>

					<v-btn @click="nucleiAssignmentsFilters.active = !nucleiAssignmentsFilters.active" absolute right top icon>
						<v-icon>mdi-cog</v-icon>
					</v-btn>

					<v-expansion-panels :value="nucleiAssignmentsFilters.active ? 0 : null" flat class="my-3">
						<v-expansion-panel>
							<v-expansion-panel-content color="high-back">
								<div class="d-flex" style="gap: 10px">
									<q-date-picker
										v-model="nucleiAssignmentsFilters.initialDate"
										@change="updateActiveNucleiDevCenter"
										label="" inner outlined dense hide-details
									/>
									<q-date-picker
										v-model="nucleiAssignmentsFilters.finalDate"
										@change="updateActiveNucleiDevCenter"
										label="" inner outlined dense hide-details
									/>
									<v-select
										v-model="nucleiAssignmentsFilters.timeUnit"
										@change="updateActiveNucleiDevCenter"
										:items="timeUnits"
										dense outlined hide-details
										prepend-inner-icon="mdi-clock-time-three-outline"
									/>
								</div>
							</v-expansion-panel-content>
						</v-expansion-panel>
					</v-expansion-panels>

					<highcharts :options="nucleiDiagramOptions" :key="nucleiKey"/>

				</div>
			</v-col>
			<v-col :cols="12" :lg="6" class="d-flex flex-column">
				<div class="flex elevated high-back rounded-xl mt-2 pa-5" style="position: relative;">
					<!-- <v-menu :close-on-content-click="false" offset-y z-index="200">
						<template v-slot:activator="{ on, attrs }">
							<v-btn v-on="on" v-bind="attrs" class="float-right mr-1 mt-1"
								icon><v-icon>mdi-cog</v-icon></v-btn>
						</template>
						<v-card>
							<v-card-text>
								
							</v-card-text>
						</v-card>
					</v-menu> -->
					<h3 style="border: none">Poolcamp</h3>

					<v-btn @click="poolcampFilters.active = !poolcampFilters.active" absolute right top icon>
						<v-icon>mdi-cog</v-icon>
					</v-btn>

					<v-expansion-panels :value="poolcampFilters.active ? 0 : null" flat class="my-3">
						<v-expansion-panel>
							<v-expansion-panel-content color="high-back">
								<div class="d-flex" style="gap: 10px">
									<q-date-picker
										v-model="poolcampFilters.initialDate"
										@change="setPoolcampChart"
										label="" inner outlined dense hide-details
									/>
									<q-date-picker
										v-model="poolcampFilters.finalDate"
										@change="setPoolcampChart"
										label="" inner outlined dense hide-details
									/>
									<v-select
										v-model="poolcampFilters.timeUnit"
										@change="setPoolcampChart"
										:items="timeUnits"
										dense outlined hide-details
										prepend-inner-icon="mdi-clock-time-three-outline"
									/>
									<v-text-field
										:value="poolcampObjective"
										@change="changeObjective"
										label="Objective" type="number"
										dense outlined hide-details
									/>
								</div>
							</v-expansion-panel-content>
						</v-expansion-panel>
					</v-expansion-panels>

					<highcharts :options="poolcampChartOpts" :key="poolcamp" />
				</div>

			</v-col>
		</v-row>
		<v-row class="my-2">
			<v-col :cols="12" :md="6" :lg="4" :xl="3"> 
				<div class="chart-container elevated high-back rounded-xl pa-3">
					<h3>Workforce by Project Type</h3>
					<div>
						<highcharts style="height: 350px" :options="workforceChartOpts" :key="workforceKey" />
					</div>
				</div>
			</v-col>
			<v-col :cols="12" :md="6" :lg="4" :xl="3">
				<div class="chart-container elevated high-back rounded-xl pa-3">
					<h3>Project Type</h3>
					<div>
						<highcharts style="height: 350px" :options="projectsChartOpts" :key="projectKey" />
					</div>
				</div>
			</v-col>
			<v-col :cols="12" :md="6" :lg="4" :xl="3">
				<div class="chart-container elevated high-back rounded-xl pa-3">

					<h3>Workforce by Customer</h3>
					<div>
						<highcharts
							style="height: 350px" :options="workforceByClientChartOpts"
							:key="workforceByCustomerKey"
						/>
					</div>
				</div>
			</v-col>
			<v-col :cols="12" :md="6" :lg="4" :xl="3">
				<div class="chart-container elevated high-back rounded-xl pa-3" style="position: relative">
					<h3>Customer Satisfaction</h3>
					
					<v-btn @click="customerSatisfactionFilters.active = !customerSatisfactionFilters.active" absolute right top icon>
						<v-icon>mdi-cog</v-icon>
					</v-btn>

					<v-expansion-panels :value="customerSatisfactionFilters.active ? 0 : null" flat class="my-3">
						<v-expansion-panel>
							<v-expansion-panel-content color="high-back">
								<div class="d-flex" style="gap: 10px">
									<v-select
                    v-model="customerSatisfactionFilters.types"
										@change="setCustomerSatisfactionChart"
										:items="operativeProjectTypes"
                    label="Type" item-value="id" item-text="name"
                    outlined dense hide-details clearable multiple
                  >
                    <template #selection="{ item }">
                      <v-chip class="ma-1">
                        {{ item.name }}
                      </v-chip>
                    </template>
                  </v-select>
                  <v-select
                    v-model="customerSatisfactionFilters.statuses"
										@change="setCustomerSatisfactionChart"
										:items="operativeProjectStatuses"
                    label="Status" item-value="id" item-text="name"
                    outlined dense hide-details clearable multiple
                  />
								</div>
							</v-expansion-panel-content>
						</v-expansion-panel>
					</v-expansion-panels>

					<div>
						<highcharts
							style="height: 350px" :options="healthcheckChartOpts"
							:key="healthcheckKey"
						/>
					</div>
				</div>
			</v-col>
		</v-row>
	</div>
</template>

<script>
import AdminBoardCharts from "../../../charts/AdminBoardCharts"
import NucleiDevCenterDiagram from "../../../charts/NucleiDevCenterDiagram"
import billableJson from '../../../json/billable.json'
import moment from "moment"

export default {
	data: () => ({
		customers: [],
		assignmentsByType: [],
		holidays: [],
		operativeProjects: [],
		operativeProjectTypes: [],
		operativeProjectStatuses: [],

		indicators: {
			differenceUsers: [],
			differenceWorkforce: [],
		},

		nucleiProjectArray: [0],
		activeNucleiMenu: false,
		healthchecks: [],

		nucleiAssignmentsFilters: {
			initialDate: moment("01 01 2023").format("YYYY-MM-DD"),
			finalDate: moment().format("YYYY-MM-DD"),
			timeUnit: 'Months',
			active: false,
		},

		poolcampFilters: {
			initialDate: moment("01 01 2023").format("YYYY-MM-DD"),
			finalDate: moment().format("YYYY-MM-DD"),
			timeUnit: 'Months',
			active: false,
		},

		customerSatisfactionFilters: {
			types: [],
			statuses: [],
			active: false,
		},
		operativeProjectsMenu: {
			show: false,
			x: 0,
			y: 0,
			projects: [],
		},

		today: moment().format('YYYY-MM-DD'),
		timeUnits: ['Days', 'Weeks', 'Months'],

		workforceChartOpts: {},
		workforceKey: 0,
		workforceByClientChartOpts: {},
		workforceByCustomerKey: 0,
		healthcheckChartOpts: {},
		healthcheckKey: 0,

		projectsChartOpts: {},
		projectKey: 0,
		poolcampChartOpts: {},
		poolcamp: 0,
		poolcampAssignments: [],

		poolcampObjective: 0,
		activeNucleiProjects: '0',

		dashboardDateFilter: moment().format('YYYY-MM-DD'),
		nucleiDevChart: [],
		nucleiKey: 0,

		colors: [
			'#00B300', '#FF0000', '#BF0069', '#FFCC00', '#82D900',
			'#FF8C00', '#800080', '#6B00B3', '#0000FF', '#009090',
			'#FF008C', '#0099FF', '#196D19', '#02A4ED', '#E94B01',
			'#003980', '#039D93', '#2E1C88', '#B50206', '#7139E5',
		],
		colorByProject: {},
		
		activeNucleiProjectTypes: [
			{ "value": 0, "small": "C", "text": "Cells", "color": "#004889", "lightColor": "#c9daec", "nuclei": false },
			{ "value": 1, "small": "PC", "text": "Product Cells", "color": "#68c0d7", "lightColor": "#c9f3fe", "nuclei": true },
			{ "value": 3, "small": "N", "text": "Nuclei", "color": "#6f3cfe", "lightColor": "#dfd5ff", "nuclei": false },
			{ "value": 4, "small": "WC", "text": "Wucleus", "color": "#e085ef", "lightColor": "#fadffe", "nuclei": true },
			{ "value": 2, "small": "FS", "text": "Fixed Scope", "color": "#fcaf54", "lightColor": "#fbe7d0", "nuclei": false },
			{ "value": 5, "small": "S", "text": "Support", "color": "#ff7a00", "lightColor": "#fcdfc4", "nuclei": true },
			{ "value": 10, "small": "O", "text": "Others", "color": "#717171", "lightColor": "#e0e0e0", "nuclei": false },
			{ "value": 9, "small": "P", "text": "Poolcamp", "color": "#20641e", "lightColor": "#d1ddcf", "nuclei": false },
			{ "value": 8, "small": "HS", "text": "Hero Support", "color": "#fc5754", "lightColor": "#fdcdcc", "nuclei": false },
			{ "value": 7, "small": "UM", "text": "Upper Management", "color": "#004889", "lightColor": "#c9daec", "nuclei": false },
			{ "value": 6, "small": "BH", "text": "Bag of Hours", "color": "#449894", "lightColor": "#cae2e0", "nuclei": false },
			{ "value": 11, "small": "MS", "text": "Marketing Services", "color": "#e085ef", "lightColor": "#fadffe", "nuclei": false }
		],
	}),
	
	mounted() {
		this.setUserPreferences('AdminBoard', 'customerSatisfactionFilters')
		this.projectTypes = _.cloneDeep(billableJson.projectTypes)
		this.init()
	},

	watch: {
		'$vuetify.theme.dark'() {
			this.$nextTick(() => this.setCharts())
		},
	},

	computed: {
		nucleiDiagramOptions() {
			return NucleiDevCenterDiagram(
				this.nucleiDevChart,
				this.nucleiAssignmentsFilters.timeUnit,
				this.handleZoomNucleiDev
			)
		},
		poolcampProjects() {
			let projects = new Set()
			let projectsInfo = []

			for (
				let assignment of this.poolcampAssignments
					.filter(a => a.startDate.isSameOrBefore(this.dashboardDateFilter, this.dateUnit) &&
						a.endDate.isSameOrAfter(this.dashboardDateFilter, this.dateUnit))
			) {
				if (!projects.has(assignment.customerProjectId)) {
					projectsInfo.push({
						id: assignment.customerProjectId,
						name: assignment.customerProjectName,
						quantity: 0,
					})
					projects.add(assignment.customerProjectId)
				}
				let currentProject = projectsInfo.find(p => p.id == assignment.customerProjectId)
				currentProject.quantity += assignment.quantity
			}

			let colorKey = 0
			projectsInfo.forEach(x => {
				if (!this.colorByProject[x.id]) {
					this.colorByProject[x.id] = this.colors[colorKey]
					colorKey++
					colorKey %= this.colors.length
				}
			})

			return projectsInfo
		},
		shownOperativeProjects() {
			let projects = this.operativeProjects

			if(this.customerSatisfactionFilters.types?.length) {
				projects = projects.filter(p => this.customerSatisfactionFilters.types.includes(p.typeId))
			}
			if(this.customerSatisfactionFilters.statuses?.length) {
				projects = projects.filter(p => this.customerSatisfactionFilters.statuses.includes(p.status))
			}

			return projects
		},
	},

	methods: {
		init() {
			this.updateDashboard()
			this.updateActiveNucleiDevCenter()

			const healthcheckPr = this.$http.get(`api/nucleiManagement/healthchecks`)
				.then(res => {
					this.healthchecks = res.data
				})
				.catch(err => {
					console.error(err),
						this.$root.error('Cannot get healthchecks')
				})

				const holidaysPr = this.$http.get('api/admin/holidays')
				.then(res => {
					this.holidays = res.data
				})
				.catch(err => {
					console.error(err)
					this.$root.error('Cannot get holidays')
				})

			this.$http.get('api/assignment/poolcamp')
				.then(res => {
					let colorKey = 0
					this.poolcampAssignments = res.data
						.map(a => {
							a.startDate = moment(a.startDate)
							a.endDate = moment(a.endDate)

							if (!this.colorByProject[a.customerProjectId]) {
								this.colorByProject[a.customerProjectId] = this.colors[colorKey]
								colorKey++
								colorKey %= this.colors.length
							}

							return a
						})
					this.setPoolcampChart()
				})

			const objectivePr = this.$http.get('api/GlobalSetting/adminSurf/poolcampObjective')
				.then(res => {
					this.poolcampObjective = res.data.value || 0
				})

			this.$http.get('api/GlobalSetting/adminSurf/activeNucleiTypes')
				.then(res => {
					this.activeNucleiProjects = res.data.value
					let array = this.activeNucleiProjects.split(',')
					this.nucleiProjectArray = array.map(el => parseInt(el))
				}).catch(res => {
					window.getApp.error("Cannot obtain activeNucleiProjects.")
				})
			
			const customerPr = this.$http.get("/api/customer?onlyWithCustomerProjects=true")
				.then(res => {
					this.customers = res.data
					this.operativeProjects = this.customers
						.flatMap(x => x.projects)
				})
				.catch(res => {
					window.getApp.error("Cannot obtain customers.")
				})

			this.$http.get('api/project/types')
        .then(res => {
          this.operativeProjectTypes = res.data
        })

			this.$http
        .get(`/api/Enums/ProjectStatus`)
        .then(res => {
          this.operativeProjectStatuses = res.data
        })
        .catch(res => {
          window.getApp.error("Cannot obtain project status.")
          console.log(res)
        });  

			Promise.all([healthcheckPr, customerPr, holidaysPr, objectivePr])
				.then(this.setDateDependentCharts)
		},

		saveNucleiProjects() {
			this.activeNucleiMenu = false
			let stringArray = this.nucleiProjectArray.map(el => el.toString())
			let stringReduced = stringArray.reduce((accumulator, currentValue) => accumulator + ',' + currentValue, '')
			this.$http.post(`api/GlobalSetting/adminSurf/activeNucleiTypes/${stringReduced.substring(1)}/0`)
				.then(res => {
					this.activeNucleiProjects = res.data.value
					window.getApp.success('Update successfully')
					this.updateDashboard()
					this.updateActiveNucleiDevCenter()
				})
				.catch(err => {
					window.getApp.success('Cannot update successfully')
				})
		},
		setNucleiDefaults() {
			this.nucleiAssignmentsFilters.initialDate = moment().startOf('year').format('YYYY-MM-DD')
			this.nucleiAssignmentsFilters.finalDate = this.today
			this.updateActiveNucleiDevCenter()
		},
		
		dashboardDateFilterChanged() {
			this.updateDashboard()
			this.setDateDependentCharts()
		},

		updateDashboard(){
			this.$http.get('api/admin/board/indicators?date=' + this.dashboardDateFilter)
				.then(res => {
					this.indicators = res.data
				})
				.catch(err => {
					console.error(err)
					this.$root.error('Cannot get indicators')
				})
		},
		updateActiveNucleiDevCenter(){
			this.$http.get(
				`api/admin/board/nucleiDevCenter?
					startDate=${ this.nucleiAssignmentsFilters.initialDate }&
					endDate=${ this.nucleiAssignmentsFilters.finalDate }&
					timeUnit=${ this.nucleiAssignmentsFilters.timeUnit }
				`
			)
				.then(res => {
					this.nucleiDevChart = res.data
					this.nucleiKey++
				})
				.catch(err => {
					console.error(err)
					this.$root.error('Cannot get nuclei')
				})
		},

		getPoolCampFontSize(str) {
			let factor = Math.min(6, this.poolcampProjects.length)
			return (1 / Math.log2(str.length * 10)) * (1 / Math.log2(factor * 10)) * 40 + 'em'
		},
		changeObjective(v) {
			if (v < 0) v = 0
			this.$http.post(`api/GlobalSetting/adminSurf/poolcampObjective/${v}/1`)
				.then(res => {
					this.poolcampObjective = res.data.value
					window.getApp.success('Update successfully')
				})
				.catch(err => {
					window.getApp.success('Cannot update successfully')
				})
				.finally(() => {
					this.setPoolcampChart()
				})
		},
		setCharts() {
		},
		setPoolcampChart() {
			this.poolcampChartOpts = AdminBoardCharts.poolcampChart(
				this.poolcampAssignments,
				{
					startDate: moment(this.poolcampFilters.initialDate),
					endDate: moment(this.poolcampFilters.finalDate)
				},
				this.poolcampFilters.timeUnit,
				this.poolcampObjective,
				this.colorByProject,
				(assignment, startDate, endDate) => {
					return this.holidays
						.filter(h =>
							assignment.calendars.some(c => c.id == h.calendarId) &&
							moment(h.date).isBetween(startDate, endDate, 'day', '[]')
						)
						.map(h => moment(h.date).startOf('day'))
						.filter((d, ix, arr) => arr.findIndex(dd => dd.isSame(d, 'day')) == ix)
						.length
				}
			)
			this.poolcamp++
		},
		async setDateDependentCharts(){
			await this.$http.post(`api/admin/board`, { startDate: this.dashboardDateFilter, endDate: this.dashboardDateFilter, })
				.then(res => {
					this.projectTypes
						.forEach((t, ix) => {
							let type = res.data.find(r => r.type == t.value)
							if (type) {
								type.sortIndex = ix
								type.color = t.color
							}
						})
					this.assignmentsByType = res.data
						.sort((a, b) => a.sortIndex < b.sortIndex ? -1 : 1)
				})

			this.workforceChartOpts = AdminBoardCharts.workforceChart(this.assignmentsByType, this.dashboardDateFilter)
			this.workforceByClientChartOpts = AdminBoardCharts.workforceCustomerChart(this.customers, this.dashboardDateFilter)
			this.projectsChartOpts = AdminBoardCharts.projectsChart(this.assignmentsByType, this.dashboardDateFilter)
			this.workforceKey++
			this.workforceByCustomerKey++
			this.projectKey++
			this.setCustomerSatisfactionChart()
		},
		setCustomerSatisfactionChart() {
			this.healthcheckChartOpts = AdminBoardCharts
				.healthcheckChart(this.healthchecks, this.shownOperativeProjects, this.dashboardDateFilter, this.showOperativeProjects)
			this.healthcheckKey++
		},
		showOperativeProjects(event, projects) {
			this.operativeProjectsMenu.projects = projects
			this.operativeProjectsMenu.x = event.clientX
			this.operativeProjectsMenu.y = event.clientY
			setTimeout(() => {
				this.operativeProjectsMenu.show = true
			}, 10)
		},
	},
}
</script>

<style lang="scss" scoped>
.no-border ::v-deep fieldset{
	border: none !important;
}

.indicator {
	display: flex;
	gap: 10px;
	padding: 5px 10px;
	border-radius: 8px;
	align-items: center;
	height: 80px;
	border-radius: 50%;

	.v-icon {
		width: 50px;
		height: 50px;
		border-radius: 50%;
	}

	&.indicator-error {
		background: var(--v-error-lighten) !important;
		color: white;

		.v-icon {
			color: white !important;
			background: none !important;
		}
	}

	h2{
		line-height: 20px;
	}
}
.grid-3 {
	display: grid;
	grid-template-columns: 1fr 1fr 1fr;
}
.grid-6 {
	display: grid;
	grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
	gap: 10px;
}

.pool-indicator {
	background: var(--v-high-back-base);
	padding: 1.25rem;

	display: flex;
	flex-direction: column;
	justify-content: center;
	height: 80px;

	border-left: 25px solid var(--v-primary-base);

	&.indicator-error {
		background: var(--v-error-lighten);
	}
	h2{
		line-height: 20px;
	}
}

.poolcamp-projects {
	display: flex;
	flex-wrap: wrap;
	justify-content: space-evenly;
	gap: 5px;


	.project {
		display: flex;
		flex: 1 1 0px;
		align-items: center;
		justify-content: center;
		flex-direction: column;
		text-align: center;
		padding: 10px 0;
		color: black;
		min-width: calc(95% / 6);
		border-left: 15px;
		border-left-color: blue;
		background-color: white;

		h3 {
			border: none;
			padding: 0;
		}
	}
}
</style>