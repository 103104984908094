import moment from "moment"
const NumberMixin = {
  install (Vue, options) {
    Vue.mixin({
      methods: {
				round(quantity, decimals = 2){
					if(!quantity) quantity = 0
					let number = parseFloat(quantity)
					let factor = Math.pow(10, decimals)
					return Math.round(number * factor) / factor
				},
				trunc(quantity, low, high = null){
					if(quantity < low) return low
					if(high != null && quantity > high) return high
					return quantity
				},
				random(between = null){
					between = between ? between : 10000000
					return Math.random() * between
				},
			}
		})
	}
}
export default NumberMixin