<template>
	<div class="px-1 pb-5">
		<highcharts v-if="chartOptions" ref="gantt" :constructor-type="'ganttChart'" :options="chartOptions" :key="chartKey"/>
    <work-item-details-dialog ref="workItemDetailsDialog"/>
	</div>
</template>

<script>
import SprintWorkItemGanttChart from "../../charts/SprintWorkItemGanttChart"
import WorkItemDetailsDialog from "../../dialogs/WorkItemDetailsDialog"

export default {
	props: {
		id: { type: String, required: true },
    projectId: { type: String },
		sprint: { type: Object, default: () => ({}) },
	},
	components: {
		WorkItemDetailsDialog,
	},
	data: () => ({
		workItems: [],
		chartOptions: null,
		chartKey: 0,
	}),
	created() {
		this.init()
	},
	computed: {
		validWorkItems() {
			return this.workItems
				.filter(x => x.assignedToId && x.estimatedEffort)
		},
		invalidWorkItems() {
			return this.workItems
				.filter(x => !x.assignedToId || !x.estimatedEffort)
		},
	},
	methods: {
		async init() {
			try {
				const res = await this.$http.post(
					`api/workItem/list?projectId=${this.id}&sprintBacklogId=${this.sprint?.id}`,
					{ sprints: [this.sprint.id], page: 1, pageSize: -1, }
				)
				this.workItems = res.data.workItems
			} catch(err) {
				console.error(err)
				this.$root.error('Failed to load work items')
			}

			this.chartOptions = SprintWorkItemGanttChart(this.sprint, this.validWorkItems, this.invalidWorkItems, this.getBackendUrl, this.openWorkItemDetailsDialog)
			this.chartKey++
		},
		openWorkItemDetailsDialog(id) {
      let defaults = {
        projectId: this.id,
      }

      this.$refs.workItemDetailsDialog.open(id, defaults, this.$parent.socket)
        .finally(result => {
          document.title = 'Sprints - ' + this.$parent.$parent.project.name
          this.init()
        })
    },
	},
	watch: {
		'$vuetify.theme.dark'(){
			this.chartKey++
		}
	},
}
</script>

<style lang="scss" scoped>
::v-deep {
	.textured {
		background-image: url("../../assets/textures/diagonals.png");
		background-repeat: repeat;
	}
	.with-triangle::before {
		content: "";
		position:absolute;
		margin-top:-6px;
		margin-left:-5px;
		border-left: 12px solid transparent;
		border-right: 12px solid transparent;
		border-bottom: 12px solid var(--v-coloredBack-base);
		transform:rotate(-90deg);
		left: -12px;
		top: 20px;
	}
}
</style>