<template>
  <div class="pa-3 fill-height new-back">
    <div class="mx-auto" style="max-width: 1200px;">
      <v-row class="justify-end">
        <v-card-actions>
          <v-btn outlined class="rounded-lg" @click="cancel" color="primary">Cancel</v-btn>
          <v-btn @click="save" color="primary" class="rounded-lg">Save Changes</v-btn>
        </v-card-actions>
      </v-row>
      <v-row>
        <v-col cols="8">
          <v-form ref="form" v-model="form">
            <v-col class="col-login">
              <span>Name</span>
              <v-text-field
                v-model="item.name"
                outlined
                :rules="[v => !!v || 'Required']"
                dense hide-details
              />
            </v-col>
            <v-col class="col-login">
              <span>Description</span>
              <v-textarea
                v-model="item.description"
                outlined auto-grow hide-details
                :rules="[v => !!v || 'Required']"
                rows="2"
              />
            </v-col>
            <v-col class="col-login">
              <span>Calendars</span>
              <v-select
								v-model="selectedCalendars"
								:items="calendars"
								item-value="id" item-text="name"
								outlined multiple
							>
								<template #selection="{ item }">
									<v-chip :color="item.color" outlined>
										<div class="dot mr-1" :style="{ background: item.color }"></div>
										<span class="text--text"> {{ item.name }} </span>
									</v-chip>
								</template>
							</v-select>
            </v-col>
          </v-form>
          <v-col>
            <div class="d-flex mb-3">
              <h2>Days Configuration</h2>
              <v-btn color="primary" icon @click="addNewLine">
                <v-icon>
                  mdi-plus
                </v-icon>
              </v-btn>
            </div>
            <div>
              <div v-if="!item.lines.length">
                No items to show
              </div>
              <RecessLineElement
                v-for="(configurationLine, ix) in item.lines"
                :key="configurationLine.id || ix"
                :item="configurationLine"
                @delete="() => deleteNewLine(ix)"
              />
            </div>
          </v-col>
        </v-col>
        <v-col cols="4" style="max-height: calc(100vh - 100px);position: sticky; top: 60px">
          <div
            class="d-flex justify-space-between align-center"
            style="width: 100%"
          >
            <h2>Assignees</h2>
            <div class="cursor-pointer opacity-on-hover">
              <h4 class="primary-color" @click="addAssignees">
                <v-icon color="primary">mdi-plus</v-icon>
                Add
              </h4>
            </div>
          </div>
          <v-list
            class="py-0"
            :class="{ 'action-container': !!shownUsers.length }"
            style="max-height: calc(100vh - 130px); overflow-y: scroll; overflow-x: hidden; "
          >
            <v-list-item
              class="item py-2"
              v-for="(user) in item.users.filter(u => users.some(activeU => activeU.id == u.applicationUserId))"
              :key="user.id || user.applicationUserId"
              :set="mUser = users.find(u => u.id == user.applicationUserId)"
            >
              <div class="d-flex align-center justify-space-between" style="width: 100%">
                <div class="d-flex">
                  <user-avatar
                    :showCard="false"
                    :userId="mUser.id"
                    hideLoading
                    :size="40"
                  />
                  <div class="ml-2">
                    <v-list-item-title v-if="mUser">{{ mUser.blueTag }}</v-list-item-title>
                    <v-list-item-subtitle v-if="mUser">{{ mUser.fullName }}</v-list-item-subtitle>
                  </div>
                </div>
                <v-btn color="error" icon @click="deleteAssignee(user)">
                  <span :style="{ 'fontSize': '25px' }" class="material-symbols-outlined">
                    delete
                  </span>
                </v-btn>
              </div>
            </v-list-item>
          </v-list>
        </v-col>
      </v-row>
      <select-users-dialog ref="selectUsersDialog" :users="users" />
    </div>
  </div>
</template>

<script>
import RecessLineElement from "../../components/recess/RecessLineElement.vue";
import SelectUsersDialog from "../../dialogs/SelectUsersDialog.vue";

export default {
  props: ["id"],
  components: {
    RecessLineElement,
    SelectUsersDialog,
  },
  data: () => ({
    users: [],
    form: false,
    item: {
      name: "",
      description: "",
      lines: [],
      users: [],
    },
    recessConfigurationLineUnities: [
      { value: 0, name: "Days" },
      { value: 1, name: "Weeks" },
      { value: 2, name: "Months" },
      { value: 3, name: "Years" },
    ],
    selectedCalendars: [],
    calendars: [],
  }),
  watch: {},
  mounted() {
    this.init();
  },
  computed: {
    isNew() {
      return this.id === "new";
    },
    shownUsers() {
      return this.users.filter((u) => this.item.users.some(iUser => iUser.applicationUserId == u.id));
    },
  },
  methods: {
    save() {
      if(!this.form) {
        this.$refs.form.validate()
        return
      }
      let recessConfiguration = Object.assign({}, this.item);
      recessConfiguration.calendars = this.selectedCalendars.map(c => ({ calendarId: c }))
      this.$q.save({
        api: 'api/recess/configuration',
        data: recessConfiguration,
        successMsg: 'Recess configuration created successfully',
        afterSuccessUrl: null,
        errorMsg: "Cannot save changes.",
        afterErrorUrl: null,
      })
      .then(this.cancel)
    },
    init() {
      this.$http.get('api/admin/calendars')
        .then(res => {
          this.calendars = res.data
        })
      this.$http.get(`/api/user?onlyInterns=true&active=true`)
        .then((res) => {
          this.users = res.data;
          this.users.forEach(this.$q.profilePicSrc);
        })
        .catch((err) => {
          window.getApp.error("Cannot get users.");
          console.error(err);
        });
      if(this.isNew)
        return;
      this.$http
        .get(`api/recess/configuration/${this.id}`)
        .then((res) => {
          this.item = res.data;
          this.selectedCalendars = this.item.calendars.map(c => c.calendarId)
        })
        .catch((err) => {
          window.getApp.error("Cannot get Recess config.");
        });
    },
    addNewLine() {
      this.item.lines.splice(0, 0, {
        unity: this.item.lines[0]?.unity >= 0 ? this.item.lines[0].unity : 3,
        frequency: this.item.lines[0]?.frequency ? this.item.lines[0].frequency + 1 : 1,
        days: null,
      });
    },
    deleteNewLine(index) {
      this.item.lines.splice(index, 1);
    },
    addAssignees() {
      this.$refs.selectUsersDialog.open(this.item.users.map(u => u.applicationUserId))
        .then((ids) => {
          this.item.users = ids.map(u => ({ applicationUserId: u }))
        });
    },
    deleteAssignee(user) {
      this.$set(user, 'deleted', true)
      this.item.users = this.item.users.filter(x => !x.deleted)
    },
    cancel() {
      this.$router.push("/recess/config/")
    },
  },
};
</script>

<style scoped lang="scss">
.col-login {
  padding-bottom: 0px !important;
  max-height: 120px !important;

  span {
    font-weight: 400;
    color: var(--v-text-lighten2);
  }
}
.comments ::v-deep .v-input__slot {
  &.v-input__slot {
    border: 2px solid #e5e5e5;
  }
  fieldset {
    border: none;
  }
}
.main-container {
  width: 100%;
  background: var(--v-newDesignBackground-base);
}
.action-container {
  border: 2px solid #e5e5e5;
  &::-webkit-scrollbar {
    width: 5px;
  }
  &::-webkit-scrollbar-track {
    -webkit-box-shadow: none;
    box-shadow: none;
    background-color: none;
  }
  border-radius: 10px 10px 10px 10px !important;
}
.item {
  border-bottom: 2px solid #e5e5e5;
  background: var(--v-newDesignBackground-lighten2) !important;
  &:last-child {
    border-radius: 0 0 12px 12px;
    border-bottom: none !important;
  }
  &:first-child {
    border-radius: 12px 12px 0 0 ;
  }
  &:only-child {
    border-radius: 12px;
  }
}
.v-btn {
  text-transform: capitalize !important;
}
</style>
