<template>
	<v-tooltip top>
		<template v-slot:activator="{ on }">
			<div small class="excel-btn" text v-on="on" @click="$emit('click', $event)">
				<img :src="excelIcon"/>
			</div>
		</template>
		<span>Download Excel</span>
	</v-tooltip>
</template>

<script>
import excelIcon from '@/assets/excel-icon.svg'
export default {
	data: () => ({
		excelIcon
	}),
}
</script>

<style lang="scss">
.excel-btn{
  background: #21a366;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  height: 39px;
  width:  39px;
  color: white;
  border-radius: 50%;
  cursor: pointer;
  box-shadow: 0 2px 5px #888;
  
  img{
    filter: invert(99%) sepia(7%) saturate(35%) hue-rotate(255deg) brightness(110%) contrast(99%);
    width: 28px;
  }
}
</style>