<template>
	<v-container fluid class="new-back fill-height align-start" style="gap: 20px">
		<v-row>
			<v-col v-if="!projects.length">No projects</v-col>
			<v-col
				v-for="project in projects" :key="project.id"
				:sm="12" :md="4" :lg="3" :xl="2"
			>
				<div
					@click.left.exact="selectProject(project, false)"
					@click.middle.exact="selectProject(project, true)"
					@click.left.ctrl="selectProject(project, true)"
					class="d-flex flex-column justify-space-between bordered rounded-lg fill-height cursor-pointer py-3 px-5"
					style="gap: 20px"
				>
					<span class="title ellipsis-text">{{ project.name }}</span>
		
					<div class="body-1 ellipsis-text">
						Small Code: {{ project.smallCode }}
						<br>
						Project Type: {{ project.typeName ? project.typeName.replace(/([A-Z])/g, " $1") : '' }}
					</div>
		
					<div :set="statusColor = colorByStatus[project.status]">
						<div
							class="inline-block bordered rounded-pill body-2 px-3 py-1"
							:style="{
								'border-color': `${statusColor.color} !important`,
								'color': `${statusColor.color} !important`,
								'background': $vuetify.theme.isDark ? '' : `${statusColor.lightColor} !important`,
							}"
						>
							{{ project.statusName }}
						</div>
					</div>
				</div>
			</v-col>
		</v-row>
	</v-container>
</template>

<script>
export default {
	data: () => ({
		projects: [],
		colorByStatus: [
			{ color: '#808080', 							lightColor: '#eee' },
			{ color: '#16c946',								lightColor: '#e1f8dd' },
			{ color: '#f57c00', 							lightColor: '#fbe7d0' },
			{ color: '#808080', 							lightColor: '#eee' },
			{ color: 'var(--v-error-base)', 	lightColor: '#f5d0d0' },
		],
	}),
	mounted(){
		document.title = 'Projects'
		this.$http
			.get("/api/user/current/project")
			.then((res) => {
				this.projects = res.data
				if(this.projects.length == 1 && !this.contextProjectId){
					this.selectProject(this.projects[0], false)
				}
				else if(this.projects.length && !this.contextProjectId){
					this.$q.setCookie("contextProjectId", this.projects[0].smallCode)
      		localStorage.setItem('currentProjectImage', this.getBackendUrl(`api/project/${this.projects[0].smallCode}/image`))
					this.$root.updateMenu()
				}
			})
			.catch((res) => {
				window.getApp.error("Cannot get projects.");
				console.error(res);
			})
	},
  computed: {
    contextProjectId(){
      return this.$q.getCookie('contextProjectId')
    },
  },
	methods: {
		selectProject(project, anotherTab){
			localStorage.setItem('currentProjectImage', this.getBackendUrl(`api/project/${project.smallCode}/image`))
			this.$q.setCookie("contextProjectId", project.smallCode)
			this.$root.updateMenu()

			if(anotherTab){
				const routeData = this.$router.resolve('/external/workItems')
				window.open(routeData.href)
			}
			else this.$router.push('/external/workItems')
		},
	},
}
</script>

<style lang="scss" scoped>
.ellipsis-text{
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
}
a { text-decoration: none; color: inherit !important; }
</style>