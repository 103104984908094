<template>
  <v-select
    v-bind="$attrs"
    ref="select"
    v-on="$listeners"
    v-if="type === 'select'"
  >
    <template v-slot:item="info">
      <slot name="item" v-bind="info">
        {{ info.item[$attrs['item-text'] || 'name'] }}
      </slot>
    </template>
    <template v-slot:selection="info">
      <slot name="item" v-bind="info">
        {{ info.item[$attrs['item-text'] || 'name'] }}
      </slot>
    </template>
  </v-select>
  <v-autocomplete
    v-bind="$attrs"
    ref="select"
    v-on="$listeners"
    v-else
  >
    <template v-slot:item="info">
      <slot name="item" v-bind="info">
        {{ info.item[$attrs['item-text'] || 'name'] }}
      </slot>
    </template>
    <template v-slot:selection="info">
      <slot name="item" v-bind="info">
        {{ info.item[$attrs['item-text'] || 'name'] }}
      </slot>
    </template>
  </v-autocomplete>
</template>

<script>
export default {
  props: { type: String },
  mounted() { this.$refs.select.activateMenu() },
}
</script>