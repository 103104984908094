import { render, staticRenderFns } from "./DailyReportList.vue?vue&type=template&id=506cb2f6&scoped=true&"
import script from "./DailyReportList.vue?vue&type=script&lang=js&"
export * from "./DailyReportList.vue?vue&type=script&lang=js&"
import style0 from "./DailyReportList.vue?vue&type=style&index=0&id=506cb2f6&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "506cb2f6",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VAlert } from 'vuetify/lib/components/VAlert';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VDatePicker } from 'vuetify/lib/components/VDatePicker';
import { VFlex } from 'vuetify/lib/components/VGrid';
installComponents(component, {VAlert,VDataTable,VDatePicker,VFlex})
