<template>
	<div>
		<filter-search-input v-model="filters.search" :applied-filters="appliedFilters" outlined dense>
			<v-autocomplete
				v-model="filters.users" :items="users"
				label="Bluetag"
				dense outlined hide-details clearable multiple
				item-text="blueTag" item-value="id"
			/>
		</filter-search-input>

		<data-table
			:headers="headers"
			:items="shownSuggestions"
			item-key="_key"
			show-expand single-expand
		>
			<template #[`item.recessRequest.requestorBlueniversary`]="{ item }">
				{{ item.recessRequest.requestorBlueniversary | formatDate }}
			</template>
			<template #[`item.requestedDates`]="{ item }">
				<span>
					<span v-if="item.recessRequest.lines.some(x => x.date)">
						{{ item.recessRequest.lines | formatDates }}
					</span>
				</span>
			</template>
			<template #[`item.recessRequest.createdAt`]="{ item }">
				{{ item.recessRequest.createdAt | formatDate }}
			</template>
			<template #[`item.actions`]="{ item }">
				<div class="d-flex justify-center">
					<v-btn @click="accept(item)" color="primary" icon>
						<v-icon>mdi-check-circle-outline</v-icon>
					</v-btn>
					<v-tooltip :disabled="item.originalAssignmentId && item.originalAssignmentId !== emptyId" top>
						<template #activator="{ on }">
							<div v-on="on">
								<v-btn :disabled="!item.originalAssignmentId || item.originalAssignmentId === emptyId" @click="reject(item)" icon>
									<v-icon>mdi-close-circle-outline</v-icon>
								</v-btn>
							</div>
						</template>
						<span>Please, first save changes</span>
					</v-tooltip>
				</div>
			</template>

			<template #expanded-item="{ headers: innerHeaders, item }">
				<td :colspan="innerHeaders.length">
					<div class="my-3">
						<b>Original Assignment</b>
						<assignments-table
							class="mt-1"
							:items="[item.originalAssignment]"
							readonly disable-sort disable-pagination hide-default-footer
							:hidden-headers="['userBlueTag', 'actions']"
						/>
					</div>
					<div class="my-3">
						<b>Suggested Assignments</b>
						<assignments-table
							class="mt-1"
							item-key="_key"
							:items="item.suggested"
							:billable-lines="billableLines"
							:billables="billables"
							:users="users"
							:customer-projects="customerProjects"
							:hidden-headers="['userBlueTag']"
							:disabled-headers="['billableCode', 'billableLineRole', 'customerProjectName']"
							disable-sort disable-pagination hide-default-footer

							v-on="$listeners"
						/>
					</div>
				</td>
			</template>
		</data-table>
		<assignments-delete-dialog ref="deleteDialog"/>
	</div>
</template>

<script>
import moment from 'moment'
import BillablePercentageBar from '../billable/BillablePercentageBar'
import AssignmentsTable from './AssignmentsTable'
import AssignmentsDeleteDialog from '../../../dialogs/adminSurf/AssignmentsDeleteDialog.vue'

export default {
	components: { BillablePercentageBar, AssignmentsTable, AssignmentsDeleteDialog },
	props: {
		billableLines: Array,
		customerProjects: Array,
		users: Array,
		billables: Array,
		statuses: Array,
		setHours: Function,
		setStatus: Function,
	},
	data: () => ({
		filters: {
			search: '',
			users: [],
		},
		suggestions: [],
		headers: [
			{ text: 'Bluetag', value: 'recessRequest.requestorBlueTag' },
			{ text: 'Blueniversary', value: 'recessRequest.requestorBlueniversary' },
			{ text: 'Available days', value: 'recessRequest.availableDays' },
			{ text: 'Requested Dates', value: 'requestedDates' },
			{ text: 'Comments', value: 'recessRequest.requestorComments' },
			{ text: 'Request Date', value: 'recessRequest.createdAt' },
			{ text: 'Actions', value: 'actions', align: 'center', sortable: false, },
		],
		emptyId: '00000000-0000-0000-0000-000000000000',
	}),
	created() {
		this.init()
	},
	computed: {
    appliedFilters() {
			return this.countActiveFilters(this.filters, ['search'])
		},
		shownSuggestions() {
			let suggestions = this.suggestions.filter(x => !x.deleted)

			if(this.filters.users.length) {
				suggestions = suggestions.filter(x => this.filters.users.includes(x.recessRequest.requestorId))
			}

			return suggestions
		},
	},
	methods: {
		init() {
			this.$http.get('api/assignment/suggestion')
				.then(res => {
					let suggestions = res.data
						.map(x => ({
							...x,
							_key: x.recessRequest.id + '-' + x.originalAssignment.id,
						}))

					suggestions.forEach(x => {
						this.setHours(x.originalAssignment)
						this.setStatus(x.originalAssignment)
						x.suggested.forEach(y => {
							this.setHours(y)
							this.setStatus(y)
							this.$set(y, 'isNew', true)
						})
					})
					
					this.suggestions = suggestions
				})
				.catch(err => {
					console.error(err)
					this.$root.error('Failed to load suggestions')
				})
		},
		async accept(suggestion) {
			let sameAssignment = []

			if(!suggestion.originalAssignment.id || suggestion.originalAssignment.id === this.emptyId) {
				this.$emit('delete', suggestion.originalAssignment)
				this.$emit('add', suggestion.suggested)
				sameAssignment = this.suggestions.filter(x => !x.originalAssignment.id || x.originalAssignment.id == this.emptyId)
				sameAssignment = sameAssignment.filter(x =>
					x.originalAssignment.userId == suggestion.originalAssignment.userId &&
					x.originalAssignment.recessRequestId == suggestion.originalAssignment.recessRequestId &&
					x.originalAssignment.billableId == suggestion.originalAssignment.billableId &&
					x.originalAssignment.startDate == suggestion.originalAssignment.startDate &&
					x.originalAssignment.endDate == suggestion.originalAssignment.endDate
				)
			}
			else {
				suggestion.suggested[0].id = suggestion.originalAssignment.id
				suggestion.suggested[0].isNew = false
				this.$emit('register-change', suggestion.originalAssignment, 'quantity', suggestion.suggested[0].quantity)
				this.$emit('register-change', suggestion.originalAssignment, 'isBillable', suggestion.suggested[0].isBillable)
				this.$emit('register-change', suggestion.originalAssignment, 'startDate', suggestion.suggested[0].startDate)
				this.$emit('register-change', suggestion.originalAssignment, 'endDate', suggestion.suggested[0].endDate)
				this.$emit('add', suggestion.suggested.slice(1))
				sameAssignment = this.suggestions.filter(x => x.originalAssignment.id == suggestion.originalAssignment.id)
			}

			this.$emit('suggestion-accepted', suggestion.originalAssignment.id, suggestion.recessRequestId)

			sameAssignment.forEach(x => {
				x.deleted = true
			})
			suggestion.deleted = true
			this.suggestions = this.suggestions.filter(x => !x.deleted)

			let requests = sameAssignment.map(x => x.recessRequest)
			try {
				let res = await this.$http.post('api/assignment/suggestion/compute', {
					recessRequests: requests,
					assignments: suggestion.suggested,
				})
				let suggestions = res.data
					.map(x => ({
						...x,
						_key: x.recessRequest.id + '-' + x.originalAssignment.id,
					}))

				suggestions.forEach(x => {
					this.setHours(x.originalAssignment)
					this.setStatus(x.originalAssignment)

					x.suggested.forEach(y => {
						this.setHours(y)
						this.setStatus(y)
						this.$set(y, 'isNew', y.id === this.emptyId)
					})
				})
				
				this.suggestions = this.suggestions.concat(suggestions)
				this.$emit('update:suggestions', this.suggestions.length)
			} catch (error) {
				if(!error.notified) {
					console.error(error)
					this.$root.error('Failed to compute suggestions')
				}
			}
		},
		async reject(suggestion) {
			let res = await this.$openConfirmDialog({
					title: 'Reject Suggestion',
					description: 'Are you sure you want to reject this suggestion?',
					confirmBtnText: 'Reject',
					confirmBtnColor: 'error',
				})
			
			if(!res)
				return

			try {
				await this.$http.post(`api/assignment/${suggestion.originalAssignmentId}/suggestion/${suggestion.recessRequestId}/reject`)
				suggestion.deleted = true
				this.suggestions = this.suggestions.filter(x => !x.deleted)
				this.$emit('update:suggestions', this.suggestions.length)
			} catch (error) {
				if(!error.notified) {
					console.error(error)
					this.$root.error('Failed to reject suggestion')
				}
			}
		},
	},
	filters: {
    formatDates: function(dates) {
      dates = dates.map(d => moment(d.date))

      if(dates.length == 1)
        return dates[0].format("MMM DD").toString();
      else if(dates.length == 2)
        return `${dates[0].format("MMM DD").toString()} and ${dates[1].format("MMM DD").toString()}`
      
      let startDate = moment.min(dates)
      let endDate = moment.max(dates)
      let allDatesBetweenStartAndEnd = true

      dates.forEach(date => {
        if(!date.isSameOrBefore(endDate) && !date.isSameOrAfter(startDate)) {
          allDatesBetweenStartAndEnd = false
        }
      })

      if(endDate.diff(startDate, 'days') == dates.length -1 && allDatesBetweenStartAndEnd) {
        startDate = startDate.format("MMM DD").toString()
        endDate = endDate.format("MMM DD").toString()
        return `${startDate} - ${endDate}`
      }

      return dates.map(d => d.format("MMM DD").toString()).join(", ")
    }
	},
}
</script>

<style lang="scss" scoped>
::v-deep .v-data-table__expanded__content{
  box-shadow: none !important;
}
</style>