<template>
  <div class="pa-5">
    <v-alert :value="showError" type="error" prominent dismissible icon="priority_high">
      On the last weeks you have reported extremely late. 
      Late reporting affects activity timing precision, metrics and overall visibility of the projects you are working on.
      Please report on the same day the activity is performed.
    </v-alert>
    <v-alert :value="showWarning" type="warning" prominent dismissible icon="warning">
      On the last weeks you have not reported on time. 
      Late reporting affects activity timing precision, metrics and overall visibility of the projects you are working on.
      Please report on the same day the activity is performed.
    </v-alert>
    <v-alert :value="showSuccess" type="success" prominent dismissible icon="done">
      Thanks for reporting on time!
    </v-alert>
    <div>
      <v-flex sm12>

        <v-data-table
          :items="reports"
          :headers="header"
          class="elevation-1 dense-table colored-header">
          <template v-slot:item="props">
            <tr class="cursor-pointer" @click="goToReport(props.item.date)">
              <td>
                  {{props.item.date | formatDateWeekDay}}
              </td>
              <td class="text-center">
                  {{props.item.reporterFullName}}
              </td>
              <td class="text-center">
                  {{props.item.activitiesCount}}
              </td>
              <td class="text-center">
                  {{props.item.executedHours | number(1)}}
              </td>
            </tr>
          </template>
        </v-data-table>
      </v-flex>
      <v-flex>
        <v-date-picker @update:picker-date="evaluateRetrieveAllReports" full-width no-title v-model="picker" @change="goToReport(picker)" :events="date => true" :event-color="getColor"></v-date-picker>
        <small class="mt-2">
          <i>Click on any date to view or edit report.</i>
        </small>
      </v-flex>
      <v-flex>
        <highcharts :key="chartKey" class="reporting-delay-chart" :style="{ height: '250px' }" v-if="reportingDelayChartOptions" :options="reportingDelayChartOptions"></highcharts>
      </v-flex>
    </div>
  </div>
</template>

<script>
import _ from "lodash"
import Highcharts from 'highcharts'
import heatmap from 'highcharts/modules/heatmap'
import moment from 'moment'
import OnTimeReportingPersonalChart from "./../../charts/OnTimeReportingPersonalChart";

export default {
  title: 'Daily Report',
  props: [],
  data: () => ({ 
    today: moment().format('YYYY-MM-DD'),
    picker: null,
    reports: [],
    header: [
      { text: 'Date', value: 'date'},
      { text: 'Reporter', value: 'reporter', align: "center" },
      { text: 'Activities', value: 'activities', align: "center" },
      { text: 'Hours', value: 'hours', align: "center" },
    ],
    currentUser: {},
    reportingDelayChartOptions: null,
    showError: false,
    showWarning: false,
    showSuccess: false,
    chartKey: 0,
    allReportsRetrieved: false,
  }),
  async created() {
    heatmap(Highcharts);

    this.init();
  },
  watch: {
    '$vuetify.theme.dark'(){
      this.$nextTick(() => this.chartKey++)
    },
  },
  methods: {
    evaluateRetrieveAllReports(e) {
      if(this.allReportsRetrieved)
        return
      let showingReportsFromTwoMonthsAgo = moment(e).isSameOrBefore(moment().subtract(2, 'months'))
      if(showingReportsFromTwoMonthsAgo) {
        this.allReportsRetrieved = true
        this.getDailyReports(false)
      }
    },
    goToReport(date) {
      if (date) {
        var date = moment(date).format('YYYY-MM-DD');
        this.$router.push(`/dailyReport/${date}`);
      }
    },
    getColor(date) {
      const statusClosed = 2
      // After today
      if (moment().isBefore(date, 'date')) {
        return "blue-grey lighten-3";
      }
      if(moment(this.currentUser.blueniversary).isAfter(date, 'date')){
        return "blue-grey lighten-3";
      }
      var report = _(this.reports)
          .filter(report => {
            return moment(report.date).isSame(date, 'date');
          })
          .first()

      let isWeekend = moment(date).day() == 0 || moment(date).day() == 6;
      if(report)
        report.executedHours = Math.round(report.executedHours*100)/100
      let green = "var(--v-success-lighten)";
      let red = "var(--v-error-base)";
      let orange = "var(--v-warning-darken)";
      let grey = "grey";
      if (!report) {
        return isWeekend ? grey : red;
      }
      if (report.status != statusClosed) {
        return isWeekend ? green : orange;
      }
      return green;
    },
    getDailyReports(limit) {
      this.$http(`/api/Effort/DailyReports/current?limit=${limit}`, { headers: { hideLoading: !limit } })
        .then(res => {
          this.reports = res.data;

          let data = res.data.map(r => ({
            delay: r.lastClosed ? moment(r.lastClosed).subtract(6, 'hours').diff(r.date) / (24*60*60*1000) - 1 : null,
            date: r.date,
            executedHours: Math.round(r.executedHours),
          }));

          this.reportingDelayChartOptions = OnTimeReportingPersonalChart('On-time reporting', data);

          // Show delay message
          let lastFiveDays = data.filter(r => moment(r.date).add(5, 'days').isAfter(moment.today));
          if (lastFiveDays.some(r => r.delay > 2)) {
            this.showError = true;
          } else if (lastFiveDays.some(r => r.delay > 0)) {
            this.showWarning = true;
          } else {
            this.showSuccess = true;
          }
        })
        .catch(res => {
          window.getApp.error("Cannot obtain reports.");
          console.log(res);
        });
    },
    init() {
      this.$blueSystem.getCurrentUser()
        .then(user => {
          this.currentUser = user
        })
      this.getDailyReports(true)
    },
  }
}

</script>

<style scoped lang="scss">

  ::v-deep .v-date-picker-table__events > div {
    height: 32px;
    width: 100%;
    opacity: 1;
    top: -17px;
    left: -2px;
    position: relative;
    border-radius: 28px;
    
  }


  ::v-deep  .v-date-picker-table .v-btn.v-btn--rounded {

    &.v-btn--outlined {
      border-width: 0px;    
    }
    color: white !important;
    .v-date-picker-table__events {
      z-index: -1;
    }
    
    .v-btn__content {
      font-family: cursive;
    }
  }
  .v-picker--date ::v-deep .v-btn:hover .v-date-picker-table__events > div {
    opacity: 0.5;
  }

        .full button.btn {
            background-color: #8ae68a;
        }
        .partially button.btn {
            background-color: #efcc8c;
        }
        .none button.btn {
            background-color: #ff9393;
        }
        .notyet button.btn {
        }

        .datepicker.well.well-sm {
            padding: 0;
            border: none;
            margin-bottom: 0;
        }

        .datepicker .btn-sm, .datepicker .btn-group-sm > .btn {
            padding: 3px 8px;
            font-size: 10px;
            line-height: 1.5;
            border-radius: 1px;
        }

        .datepicker h6, .datepicker .h6, .datepicker th small {
            font-size: 10px;
        }
        #graph {
            width: 390px;
            height: 180px;
            border: 1px solid #eee;
            background: #f6f6f6;
            margin: 0 10px;
        }

</style> 