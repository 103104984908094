<template>
	<div class="main-grid px-1">
		<div
			class="d-flex justify-space-between align-center pb-3 mr-n1 pl-2 top"
		>
			<template v-if="!isAdmin">
				<h3>BluePeople Support</h3>
				<v-btn @click="$emit('close')" icon><v-icon>close</v-icon></v-btn>
			</template>
			<template v-else>
				<div class="d-flex align-center">
					<v-btn @click="$emit('input', null)" icon class="ml-n3 mr-1">
						<v-icon>mdi-chevron-left</v-icon>
					</v-btn>
					<user-avatar :user-id="chat.userId" :show-card="false" :size="40"/>
					<div class="ml-2">
						<b>{{ chat.user.blueTag || chat.user.fullName }}</b>
						<div class="body-2 mt-n1"> {{ chat.user.roles }} </div>
					</div>
				</div>

				<v-btn @click="requestCloseChat" class="mr-2" icon :disabled="!chat.isActive || chat.isRequestingClosure">
					<v-icon>mdi-check-circle-outline</v-icon>
				</v-btn>
			</template>
		</div>

		<div class="messages-container" ref="messagesContainer">
			<div v-if="!chat.messages.length" class="support">
				<img height="50px" src="../assets/avatar_blue.png" contain/>
				<h3>BluePeople Support</h3>
			</div>
			<div class="messages">
				<div v-for="(message, ix) in chat.messages" :key="ix" :class="{'text-right': message.type == isAdmin}">
					<div
						class="message"
						:class="{
							received: message.type == !isAdmin,
							sent: message.type == isAdmin,
							event: message.type == 2,
						}"
					>
						{{message.text}}
					</div>
					<br>
				</div>
				<div
					v-if="chat.isRequestingClosure"
					class="closure-request rounded text-center py-2 px-2 mt-2"
				>
					<span v-if="isAdmin">Waiting for closing request response</span>
					<div v-else>
						<span>Could we close this chat for now?</span>
						<div class="d-flex justify-center mt-1" style="gap: 10px">
							<v-btn @click="resolveClosureRequest(true)" depressed x-small>Yes</v-btn>
							<v-btn @click="resolveClosureRequest(false)" depressed x-small>No</v-btn>
						</div>
					</div>
					
				</div>
			</div>
		</div>

		<div class="new-message text-center px-2 mr-n1">
			<v-form @submit.prevent="sendMessage">
				<v-text-field
					v-model="newMessage"
					outlined dense hide-details
					placeholder="How can we help you?"
					style="border-radius: 10px"
				>
					<template #append>
						<v-icon @click="sendMessage" color="primary" class="cursor-pointer">send</v-icon>
					</template>
				</v-text-field>
			</v-form>
		</div>
	</div>	
</template>

<script>
export default {
	props: {
		chat: Object,
		isAdmin: Boolean,
	},
	data: () => ({
		newMessage: '',
	}),
	mounted(){
		this.$nextTick(this.scrollToBottom)
	},
	methods: {
		sendMessage(){
			if(!this.newMessage) return
			const endpoints = {
				false: 'api/support/message',
				true: `api/support/${this.chat.userId}/message`,
			}
			this.$http.post(endpoints[this.isAdmin], { text: this.newMessage }, { headers: { hideLoading: true }})
				.then(res => {
					this.newMessage = ''
				})
				.catch(err => {
					console.error(err)
					this.$root.error('Cannot send message')
				})
		},
		requestCloseChat(){
			this.$openConfirmDialog({
				title: 'Close chat',
				description: `Are you sure you want to close the chat with <b>${this.chat.user.blueTag || this.chat.user.fullName}</b>?`,
			})
				.then(res => {
					if(!res) return

					this.$http.post(`api/support/chat/${this.chat.userId}/request-close`)
						.catch(err => {
							console.error(err)
							this.$root.error('Cannot request closure')
						})
				})
		},
		resolveClosureRequest(response){
			this.$http.post(`api/support/chat/close/${response}`)
				.catch(err => {
					console.error(err)
					this.$root.error('Cannot send response')
				})
		},
		scrollToBottom(){
			const container = this.$refs.messagesContainer
			if(container)
				container.scrollTop = container.scrollHeight
			else
				this.$nextTick(this.scrollToBottom)
		},
	},
	watch: {
		'chat.messages.length'(){
			this.$nextTick(this.scrollToBottom)
		},
		'chat.isRequestingClosure'(){
			this.$nextTick(this.scrollToBottom)
		},
		'$parent.$parent.$parent.menuElementActive'(v){
			if(v == 3){
				this.$nextTick(this.scrollToBottom)
			}
		}
	},
}
</script>

<style lang="scss" scoped>
.main-grid{
	display: grid;
	grid-template-rows: 60px calc(100vh - 115px) 55px;
}

.top{
	border-bottom: 1px solid var(--v-lightgray-base);
	position: sticky; top: 0;
	z-index: 10;
	padding-top: 10px;
}

.messages-container{
	display: flex;
	flex-flow: column nowrap;
	overflow-y: scroll;
	overflow-x: visible;

	&::-webkit-scrollbar {
		display: none;
	}
}

.support{
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	
	width: 100%;
	height: 100%;
}

.messages{
	margin-top: auto;
	.message{
		position: relative;
		display: inline-block;
		border-radius: 10px;
		padding: 10px 15px;
		margin-top: 5px;

		&.event{
			color: var(--v-text-lighten);
			text-align: center;
			font-size: .9em;
			width: 100%;
			padding: 0;
		}
		&.received{
			margin-right: 30px;
			background: var(--v-background-lighten2);
		}
		&.sent{
			background: var(--v-primary-base);
			color: white;
			margin-left: 30px;
			align-self: flex-end;
		}
	}
}

.closure-request{
	background: var(--v-secondary-base);
	color: white;
}

.new-message{
	position: sticky;
	bottom: 0;
	padding-top: 5px;
}
.new-message ::v-deep fieldset{
	border-color: var(--v-lightgray-base) !important;
}
</style>