<template>
	<div class="d-flex flex-column">
		<div class="d-flex justify-end align-center my-2 gap-2">
			<v-tooltip top>
				<template v-slot:activator="{ on }">
					<v-btn
						v-on="on" @click="singleLineTable = !singleLineTable"
						color="primary" small fab depressed
					>
						<v-icon v-if="singleLineTable">mdi-wrap-disabled</v-icon>
						<v-icon v-else>mdi-wrap</v-icon>
					</v-btn>
				</template>
				{{ singleLineTable ? 'Unwrap' : 'Wrap' }}
			</v-tooltip>
			<v-menu :close-on-content-click="false" bottom offset-y>
				<template v-slot:activator="{ on }">
					<v-btn color="primary" fab small depressed v-on="on">
						<v-icon>mdi-table-eye</v-icon>
					</v-btn> 
				</template>

				<v-card :width="$vuetify.breakpoint.mdAndUp ? '17vw' : ''">
					<v-list dense>
						<v-row class="clear-select-container">
							<v-col >
								<div class="mt-2 mb-2 toggleSelect" >
									<a @click="toggleColsSelect(true)" class="cursor-pointer" >
										Select All
									</a>
									<a @click="toggleColsSelect(false)" class="cursor-pointer" >
										Clear All
									</a>
								</div>
							</v-col>
						</v-row>
						<v-list-item v-for="header in headers" :key="header.value" v-show="header.text">
							<v-list-item-action class="mr-2">
								<v-checkbox
									v-model="showCol[header.value]"
									dense color="primary"
								/>
							</v-list-item-action>
							<v-list-item-title style="font-size: .95em">
								{{header.text}}
							</v-list-item-title>
						</v-list-item>
					</v-list>
				</v-card>
			</v-menu>
			<span class="title">
				<b>Selected Hours: </b>
				{{ selectedHours | number(2) }} / {{ totalHours }}
			</span>
		</div>
		<div @drop="toSprintDropped" @dragover.prevent>
			<work-items-table
				v-model="selectedSprintWorkItems"
				:headers="shownWorkItemHeaders" :work-items="sprintWorkItems"
				show-select :single-line="singleLineTable"
				@click:row="openWorkItemDetailsDialog($event.id)"
			/>
		</div>
		<template v-if="isEmployee">
			<div class="d-flex justify-center align-center gap-1 mt-3 mb-n10">
				<v-btn @click="addToSprint" x-large icon><v-icon>mdi-arrow-up</v-icon></v-btn>
				<v-btn @click="removeFromSprint" x-large icon><v-icon>mdi-arrow-down</v-icon></v-btn>
			</div>
			<filter-search-input
				v-model="search" 
				@input="applySearch"
				@clear:filters="resetFilters"
				outlined hide-details dense show-clear
				:appliedFilters="appliedFilters"
			>
				<v-autocomplete
					v-model="filters.types" :items="$parent.$parent.shownTypes"
					label="Type" item-text="displayName" item-value="value"
					outlined multiple hide-details dense
				/>

				<v-autocomplete
					v-model="filters.statuses"
					:items="$parent.$parent.projectConfig.workItemStatuses.filter(s => !filters.types.lenght || filters.types.includes(s.workItemType))"
					label="Status" item-value="id" item-text="name"
					outlined multiple hide-details dense
				>
					<template v-slot:item="{ item }">
						<v-chip class="my-2 cursor-pointer" label :style="{ width: '100%' }" dark :color="item.color">
							<v-icon class="mr-2">{{item.iconClass}}</v-icon>
							{{item.name}}
						</v-chip>
					</template>
					<template v-slot:selection="{ item, index }">
						<v-chip 
							v-if="index === 0"
							label dark
							class="mt-2 cursor-pointer"
							:color="item.color"
						>
							<v-icon class="mr-2" style="font-size: 1.3em">{{item.iconClass}}</v-icon>
							{{item.name[0]}}
						</v-chip>
						<span
							v-if="index === 1"
							class="grey--text text-caption mt-2 ml-1"
						>
							(+{{ filters.statuses.length - 1 }})
						</span>
					</template>
				</v-autocomplete>
				
				<v-autocomplete
					v-model="filters.tags"
					:items="$parent.$parent.tags"
					label="Tags" item-value="id"  item-text="name"
					outlined multiple hide-details dense
				>
					<template v-slot:item="{ item, attrs }">
						<v-list-item-action>
							<v-checkbox v-model="attrs.inputValue"></v-checkbox>
						</v-list-item-action>
						<v-list-item-content>
							<v-list-item-title>
								<v-chip class="cursor-pointer" dark small :color="item.id !== null ? item.color : 'white'"
									:style="`color: ${isBlackText(item.color) ? 'white' : 'black'}`"
								>
									{{item.name}}
								</v-chip>
							</v-list-item-title>
						</v-list-item-content>
					</template>
					<template v-slot:selection="{ item, index, attrs, selected }">
						<v-chip 
							small
							v-bind="attrs"
							:input-value="selected"
							v-if="item === Object(item) && index === 0"
							:color="item.id !== null ? item.color : ''"
							:class="item.id !== null ? '' : 'transparent'"
							:style="`color: ${item.id !== null ? isBlackText(item.color) ? 'white' : 'black' : ''}`"
						>
							<span>{{ item.name }}</span>
						</v-chip>
						<span
							v-if="index === 1"
							class="grey--text text-caption"
						>
							(+{{ filters.tags.length - 1 }})
						</span>
					</template>
				</v-autocomplete>

				<v-autocomplete
					v-model="filters.sprints"
					:items="$parent.$parent.sprints"
					label="Sprint" item-value="id"  item-text="name" 
					outlined multiple hide-details dense
				>
					<template v-slot:selection="{ attrs, item, parent, selected, index }">
						<v-chip 
							v-if="item === Object(item) && index === 0"
							v-bind="attrs"
							class="accent--lighten"
							:class="item.id !== null ? '' : 'transparent'"
							:input-value="selected"
							label
							small
						>
							<span class="pr-2">
								{{ item.name }}
							</span>
							<v-icon small @click="parent.selectItem(item)">close</v-icon>
						</v-chip>
						<span
							v-if="index === 1"
							class="grey--text text-caption mt-1 ml-1"
						>
							(+{{ filters.sprints.length - 1 }})
						</span>
					</template>
				</v-autocomplete>

				<v-autocomplete
					v-model="filters.priorities"
					:items="$parent.$parent.priorities"
					label="Priority" item-value="value" item-text="displayName" 
					outlined multiple hide-details dense
				>
					<template v-slot:item="{ item, attrs }">
						<v-list-item-action>
							<v-checkbox v-model="attrs.inputValue"></v-checkbox>
						</v-list-item-action>
						<v-list-item-content>
							<v-list-item-title>
								<v-icon :color="item.color" class="mr-2">{{item.iconClass}}</v-icon>
								{{item.displayName}}
							</v-list-item-title>
						</v-list-item-content>
					</template>
					<template v-slot:selection="{ attrs, item, parent, selected, index }">
						<v-chip v-if="item === Object(item) && index === 0"
							v-bind="attrs" class="accent--lighten" :input-value="selected" label small>
							<span class="pr-1">
								<v-icon :color="item.color" class="">{{item.iconClass}}</v-icon>
								{{ item.displayName }}
							</span>
							<v-icon small @click="parent.selectItem(item)">close</v-icon>
						</v-chip>
						<span
							v-if="index === 1"
							class="grey--text text-caption mt-1 ml-1"
						>
							(+{{ filters.priorities.length - 1 }})
						</span>
					</template>
				</v-autocomplete>

				<v-autocomplete
					v-model="filters.assignedTo"
					:items="$parent.$parent.users"
					label="Assigned to" item-value="id" item-text="blueTag"
					outlined multiple hide-details dense
					:filter="(item, queryText, itemText) => normalizeIncludes(item.blueTag + ' ' + item.fullName, queryText)"
				>
					<template v-slot:item="{ item, attrs }">
						<v-list-item-action>
							<v-checkbox v-model="attrs.inputValue"></v-checkbox>
						</v-list-item-action>
						<v-list-item-content class="pa-0">
							<v-list-item class="pa-0">
								<v-list-item-avatar color="indigo" class="headline font-weight-light white--text">
									{{ (item.blueTag || '-').charAt(0) }}
								</v-list-item-avatar>
								<v-list-item-content>
									<v-list-item-title>{{ item.blueTag }}</v-list-item-title>
									<v-list-item-subtitle>{{ item.fullName }}</v-list-item-subtitle>
								</v-list-item-content>
							</v-list-item>
						</v-list-item-content>
					</template>
					<template v-slot:selection="{ attrs, item, parent, selected, index }">
						<v-chip v-if="item === Object(item) && index === 0"
							v-bind="attrs" class="accent--lighten" :input-value="selected" label small
						>
							<span class="pr-2">
								{{ item.blueTag }}
							</span>
							<v-icon small @click="parent.selectItem(item)">close</v-icon>
						</v-chip>
						<span
							v-if="index === 1"
							class="grey--text text-caption mt-1 ml-1"
						>
							(+{{ filters.assignedTo.length - 1 }})
						</span>
					</template>
				</v-autocomplete>

				<v-autocomplete
					v-model="filters.reportedBy"
					:items="$parent.$parent.users"
					label="Reported by" item-value="id" item-text="blueTag"
					:filter="(item, queryText, itemText) => normalizeIncludes(item.blueTag + ' ' + item.fullName, queryText)"
					outlined multiple hide-details dense
				>
					<template v-slot:item="{ item, attrs }">
						<v-list-item-action>
							<v-checkbox v-model="attrs.inputValue"></v-checkbox>
						</v-list-item-action>
						<v-list-item-content class="pa-0">
							<v-list-item class="pa-0">
								<v-list-item-avatar color="indigo" class="headline font-weight-light white--text">
									{{ (item.blueTag || '-').charAt(0) }}
								</v-list-item-avatar>
								<v-list-item-content>
									<v-list-item-title>{{ item.blueTag }}</v-list-item-title>
									<v-list-item-subtitle>{{ item.fullName }}</v-list-item-subtitle>
								</v-list-item-content>
							</v-list-item>
						</v-list-item-content>
					</template>
					<template v-slot:selection="{ attrs, item, parent, selected, index }">
						<v-chip v-if="item === Object(item) && index === 0"
							v-bind="attrs" class="accent--lighten" :input-value="selected" label small>
							<span class="pr-2">
								{{ item.blueTag }}
							</span>
							<v-icon small @click="parent.selectItem(item)">close</v-icon>
						</v-chip>
						<span
							v-if="index === 1"
							class="grey--text text-caption mt-1 ml-1"
						>
							(+{{ filters.reportedBy.length - 1 }})
						</span>
					</template>
				</v-autocomplete>
			</filter-search-input>
			<div @drop="fromSprintDropped" @dragover.prevent>
				<work-items-table
					v-model="selectedNoSprintWorkItems"
					:headers="shownWorkItemHeaders" :work-items="noSprintWorkItems"
					show-select :single-line="singleLineTable"
					@click:row="openWorkItemDetailsDialog($event.id)"
				/>
			</div>
		</template>
    <work-item-details-dialog ref="workItemDetailsDialog"/>
    <work-item-create-dialog ref="workItemCreateDialog"/>
	</div>
</template>

<script>
import moment from "moment"	
import WorkItemsTable from "../work-item/WorkItemsTable"
import WorkItemDetailsDialog from "../../dialogs/WorkItemDetailsDialog"
import WorkItemCreateDialog from "../../dialogs/WorkItemCreateDialog"

export default {
	props: {
		id: { type: String, required: true },
    isEmployee: { type: Boolean },
    projectId: { type: String },
		sprint: { type: Object, default: () => ({}) },
	},
	components: {
		WorkItemsTable,
		WorkItemDetailsDialog,
		WorkItemCreateDialog,
	},
	data: () => ({
    headers: [
      { text: "Code", value: "code", editable: false,
        sort: (a, b) => {
          let indexA = a.lastIndexOf('-')
          let indexB = a.lastIndexOf('-')
          a = a.substr(indexA+1)
          b = b.substr(indexB+1)
          return parseInt(a) - parseInt(b)
        },
      },
      { text: "Type", value: "type", align: 'center', oneClickEdit: false, },
      { text: "Summary", value: "name", oneClickEdit: false, },
      { text: "Description", value: "description", oneClickEdit: false, },
      { text: "Status", value: "statusName", align: 'center', oneClickEdit: false, },
      { text: "Priority", value: "priority", align: 'center', oneClickEdit: false, },
      { text: "Value", value: "value", align: 'center', oneClickEdit: false, },
      { text: "Estimated Dev", value: "estimatedEffort", align: 'center', oneClickEdit: false, },
      { text: "Total Hours", value: "totalHours", align: 'center', oneClickEdit: false, },
      { text: "Sprint", value: "currentSprintName", align: 'center', oneClickEdit: false, },
      { text: "Tags", value: "allTags", oneClickEdit: false, },
      { text: "Assigned to", value: "assignedToBlueTag", oneClickEdit: false, },
      { text: "Reported by", value: "reportedByBlueTag", oneClickEdit: false, },
      { text: "Created on", value: "createdAt", editable: false, },
      { text: "Created by", value: "createdByBlueTag", editable: false, },
    ],
    singleLineTable: false,

		search: '',
    searchTimeout: null,
    filters: {
      types: [],
      statuses: [],
      tags: [],
      sprints: [],
      priorities: [],
    },
    tagCondition: 'and',
    workItems: [],
    selectedSprintWorkItems: [],
    selectedNoSprintWorkItems: [],

		showCol: {
      code: true,
      type: true,
      name: true,
      description: true,
      statusName: true,
      priority: true,
      estimatedEffort: true,
      totalHours: true,
      currentSprintName: true,
      allTags: true,
      assignedToBlueTag: true,
      reportedByBlueTag: true,
      createdAt: true,
      createdByBlueTag: true,
    },
	}),
	created() {
		this.fetchWorkItems()
    this.setUserPreferences("SprintList", "showCol")
	},
	computed: {
		sprintWorkItems() {
      return this.workItems
        .filter(workItem =>
          workItem.sprints.some(sprint => sprint.sprintId === this.sprint.id)
        )
    },
    noSprintWorkItems() {
      return this.workItems
        .filter(workItem =>
          !workItem.sprints.some(sprint => sprint.sprintId === this.sprint.id)
        )
    },
    selectedHours() {
      return this.sprintWorkItems.reduce((acc, workItem) => acc + workItem.totalHours, 0)
    },
    totalHours() {
      let startDate = moment(this.sprint.startDate)
      let endDate = moment(this.sprint.endDate)
      let workingDays = 0

      while(startDate.isSameOrBefore(endDate)) {
        if(startDate.day() !== 0 && startDate.day() !== 6) {
          workingDays++
        }
        startDate.add(1, 'day')
      }

      return workingDays * this.sprint.devsQuantity * 8
    },
    shownWorkItemHeaders(){
      return this.headers.filter(h => this.showCol[h.value])
    },
		appliedFilters(){
			return this.countActiveFilters(this.filters, ['search'])
		},
	},
	methods: {
		fetchWorkItems() {
			this.$http
				.post(
					`api/workItem/list?projectId=${this.id}&sprintBacklogId=${this.sprint?.id}`,
					{
						...this.filters,
						search: this.search,
						page: 1,
						pageSize: -1,
					},
				)
				.then(res => {
					this.workItems = res.data.workItems
				})
				.catch(res => {
					window.getApp.error("Cannot obtain work items.")
					console.error(res)
				})
    },
		applySearch() {
      clearTimeout(this.searchTimeout)
      this.searchTimeout = setTimeout(() => {
        this.fetchWorkItems()
      }, 400)
    },

		toSprintDropped(e) {
      const id = e.dataTransfer.getData('id')
      const workItem = this.workItems.find(wi => wi.id === id)
      if(!workItem || workItem.sprints.some(x => x.sprintId == this.sprint.id))
        return
      
      if(this.selectedNoSprintWorkItems.some(wi => wi.id === id))
        this.toggleSprint(this.selectedNoSprintWorkItems, true)
      else
        this.toggleSprint([workItem], true)
    },
    fromSprintDropped(e) {
      const id = e.dataTransfer.getData('id')
      const workItem = this.workItems.find(wi => wi.id === id)
      if(!workItem || !workItem.sprints.some(x => x.sprintId == this.sprint.id))
        return
      
      if(this.selectedSprintWorkItems.some(wi => wi.id === id))
        this.toggleSprint(this.selectedSprintWorkItems, false)
      else
        this.toggleSprint([workItem], false)
    },
    addToSprint() {
      this.toggleSprint(this.selectedNoSprintWorkItems, true)
    },
    removeFromSprint() {
      this.toggleSprint(this.selectedSprintWorkItems, false)
    },
    toggleSprint(workItems, add) {
			if(this.sprint.canceled) return

      this.$q.save({
        api: `/api/workItems/byProperty/sprint`,
        data: {
          value: add+'',
          workItems: workItems.map(wi => wi.id),
          entityId: this.sprint.id,
        },
        successMsg: null,
        afterSuccessUrl: null,
        afterErrorUrl: null,
      })
        .then(res => {
          this.fetchWorkItems()
        })
        .catch(err => {
          console.error(err)
          this.$root.error('Failed to save sprint')
        })
    },
    
    openWorkItemDetailsDialog(id) {
      let defaults = {
        projectId: this.id,
      }

      this.$refs.workItemDetailsDialog.open(id, defaults, this.$parent.socket)
        .finally(result => {
          document.title = 'Sprints - ' + this.$parent.$parent.project.name
          this.fetchWorkItems()
        })
    },
    openWorkItemCreateDialog() {
      let defaults = {
        projectId: this.id,
        sprintId: this.sprint.id,
				type: 0,
      }
      this.$refs.workItemCreateDialog.open(defaults, this.$parent.socket)
        .then(result => {
          this.fetchWorkItems()
        })
    },

		toggleColsSelect(select) {
			const showColKeys = _.keys(this.showCol)
			showColKeys.forEach(col => this.showCol[col] = select)
		},

		resetFilters() {
			this.filters = {
				types: [],
				statuses: [],
				tags: [],
				sprints: [],
				priorities: [],
			}
			this.search = ''
		},
	},
	watch: {
    filters: {
      handler() {
        this.fetchWorkItems()
      },
      deep: true,
    },
  },
}
</script>

<style lang="scss" scoped>
.toggleSelect {
  display: flex;
  gap: 10px;
  justify-content: flex-start;
  padding-left: 10px;
  text-decoration: underline !important;
}

.container-tag-options {
  position: sticky;
  bottom: 0px;
  height: 70px;
  background: var(--v-background-lighten);
}
.active-tag-condition {
  color: var(--v-primary-base) !important;
  border: 1px solid var(--v-primary-base)
}
.tag-condition {
  padding: 4px 4px;
  color: var(--v-text-base);
  border-radius: 4px;
  font-weight: bold;
}
.behavior-container {
  display: flex;
  justify-content: space-around;
  align-items: center;
  height: 100%
}
.behavior-text {
  font-weight: 550;
}
.divider {
  max-width: 80%;
  margin: 0 10%;
}
</style>