<template>
	<div>
		<div class="d-flex align-center justify-space-between">
			<div class="d-flex align-center gap-1">
				<v-text-field
					v-bind="$attrs" v-on="$listeners"
					prepend-inner-icon="search" placeholder="Search"
					hide-details clearable
				>
					<template #append>
						<v-badge
							offset-x="12" offset-y="13"
							color="red" :value="!!appliedFilters" :content="appliedFilters"
							right small style="margin-top: -6px"
						>
							<v-btn icon @click="filtersActive = !filtersActive">
								<v-icon>mdi-tune</v-icon>
							</v-btn>
						</v-badge>
					</template>
				</v-text-field>
				<v-btn v-if="showClear && appliedFilters" @click="$emit('clear:filters')" class="ml-1" color="primary" fab x-small>
					<v-icon>mdi-close</v-icon>
				</v-btn>
				<slot name="append-inner"/>
			</div>
			<slot name="append"/>
		</div>

		<v-expansion-panels :value="filtersActive ? 0 : null" flat class="my-3">
			<v-expansion-panel>
				<v-expansion-panel-content color="newDesignBackground">
					<div class="d-inline-flex flex-wrap filters" style="gap: 10px">
						<slot/>
					</div>
				</v-expansion-panel-content>
			</v-expansion-panel>
		</v-expansion-panels>
	</div>
</template>

<script>
export default {
	props: {
		appliedFilters: Number,
		showClear: Boolean,
	},
	data: () => ({
		filtersActive: false,
	}),
}
</script>

<style lang="scss" scoped>
::v-deep {
	.v-expansion-panel-content__wrap{
		padding: 0 !important;
	}
	.v-select__selection + input,
	.v-select__selections > * + input {
		width: 10px;
	}
	.filters > *{
		flex-grow: 0;
	}
}
</style>