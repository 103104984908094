const ViewLevelOptions = Object.freeze({
    DEFAULT_KEY: 0,
    CANNOT_VIEW_BY_DEFAULT: {
        key: 0,
        properties: { 
            viewLevel: 0, 
            nextValue: 3, 
            textClass: 'grey--text', 
            tooltip: 'Cannot view by default', 
            icon: 'remove',
            color: 'gray' 
        },
    },
    CAN_VIEW: {
        key: 3,
        properties: { 
            viewLevel: 3, 
            nextValue: 1, 
            textClass: 'green--text', 
            tooltip: 'Can view', 
            icon: 'check',
            color: 'green' 
        },
    },
    CAN_NOT_VIEW: {
        key: 1,
        properties: { 
            viewLevel: 1, 
            nextValue: 0, 
            textClass: 'red--text', 
            tooltip: 'Cannot view', 
            icon: 'clear',
            color: 'red'
        },
    },
});
  
const EditLevelOptions = Object.freeze({
    DEFAULT_KEY: 0,
    CANNOT_EDIT_BY_DEFAULT: {
        key: 0,
        properties: { 
            viewLevel: 0, 
            nextValue: 3, 
            textClass: 'grey--text', 
            tooltip: 'Cannot edit by default', 
            icon: 'remove' 
        },
    },
    CAN_EDIT: {
        key: 3,
        properties: { 
            viewLevel: 3, 
            nextValue: 1, 
            textClass: 'green--text', 
            tooltip: 'Can edit', 
            icon: 'check' 
        },
    },
    CAN_NOT_EDIT: {
        key: 1,
        properties: { 
            viewLevel: 1, 
            nextValue: 0, 
            textClass: 'red--text', 
            tooltip: 'Cannot edit', 
            icon: 'clear'
        },
    },
});

const DeleteLevelOptions = Object.freeze({
    DEFAULT_KEY: 0,
    CANNOT_DELETE_BY_DEFAULT: {
        key: 0,
        properties: { 
            viewLevel: 0, 
            nextValue: 3, 
            textClass: 'grey--text', 
            tooltip: 'Cannot delete by default', 
            icon: 'remove' 
        },
    },
    CAN_DELETE: {
        key: 3,
        properties: { 
            viewLevel: 3, 
            nextValue: 1, 
            textClass: 'green--text', 
            tooltip: 'Can delete', 
            icon: 'check' 
        },
    },
    CAN_NOT_DELETE: {
        key: 1,
        properties: { 
            viewLevel: 1, 
            nextValue: 0, 
            textClass: 'red--text', 
            tooltip: 'Cannot delete', 
            icon: 'clear'
        },
    },
});

const ReportLevelOptions = Object.freeze({
    DEFAULT_KEY: 0,
    CANNOT_REPORT_BY_DEFAULT: {
        key: 0,
        properties: { 
            viewLevel: 0, 
            nextValue: 3, 
            textClass: 'grey--text', 
            tooltip: 'Cannot report by default', 
            icon: 'remove',
            color: 'gray'
        },
    },
    CAN_REPORT: {
        key: 3,
        properties: { 
            viewLevel: 3, 
            nextValue: 1, 
            textClass: 'green--text', 
            tooltip: 'Can report', 
            icon: 'check',
            color: 'green'
        },
    },
    CAN_NOT_REPORT: {
        key: 1,
        properties: { 
            viewLevel: 1, 
            nextValue: 0, 
            textClass: 'red--text', 
            tooltip: 'Cannot report', 
            icon: 'clear',
            color: 'red' 
        },
    },
});

const PermissionsOptions = Object.freeze({
    DEFAULT_KEY: 0,
    CANNOT_BY_DEFAULT: {
        key: 0,
        properties: { 
            viewLevel: 0, 
            nextValue: 3, 
            textClass: 'grey--text', 
            tooltip: 'Action not allow by default', 
            icon: 'remove',
            color: 'gray'
        },
    },
    CAN: {
        key: 3,
        properties: { 
            viewLevel: 3, 
            nextValue: 1, 
            textClass: 'green--text', 
            tooltip: 'Action Allow', 
            icon: 'check',
            color: 'green'  
        },
    },
    CAN_NOT: {
        key: 1,
        properties: { 
            viewLevel: 1, 
            nextValue: 0, 
            textClass: 'red--text', 
            tooltip: 'Action Not Allow', 
            icon: 'clear',
            color: 'red' 
        },
    },
});

export {
    ViewLevelOptions,
    EditLevelOptions,
    ReportLevelOptions,
    DeleteLevelOptions,
    PermissionsOptions,
};