var render = function () {
var _obj;
var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-col',_vm._b({staticClass:"pb-0 card-container test2 mb-4",style:({
    'min-width': _vm.width + 'px',
    'max-width': _vm.width + 'px',
  })},'v-col',_vm.$attrs,false),[_c('v-card',{class:( _obj = {}, _obj['hover-' +
      _vm.getDarkColor(_vm.risk.probability * _vm.risk.impact).substr(1)] = true, _obj.selected = _vm.risk.id == _vm.selectedRisk.id || _vm.risk.id == _vm.tempSelectedRisk.id, _obj.mainContainer = true, _obj ),style:({
      borderColor: _vm.createdRisk.id == _vm.risk.id ? _vm.getDarkColor(_vm.risk.probability * _vm.risk.impact) : 'transparent',
      background: _vm.risk.hover
        ? _vm.getDarkColor(_vm.risk.probability * _vm.risk.impact)
        : '',
    }),on:{"click":function($event){return _vm.edit(_vm.risk)}},nativeOn:{"mouseover":function($event){return _vm.selectRiskAsTemporal.apply(null, arguments)},"mouseleave":function($event){return _vm.unselectRiskAsTemporal.apply(null, arguments)}}},[_c('v-card-title',[_c('user-avatar',{attrs:{"hide-loading":"","userId":_vm.risk.reporter.id,"showCard":false,"size":35}})],1),_c('v-card-text',[_c('div',{staticClass:"reporter-name",style:({
          color: _vm.getPropertyBySeverity(_vm.risk.probability, _vm.risk.impact, 'color'),
        })},[_vm._v(" "+_vm._s(_vm.risk.code.replace("/-/", " "))+" ")]),_c('div',{staticClass:"description",domProps:{"innerHTML":_vm._s(
          JSON.parse(_vm.risk.description.replaceAll('\\n', '<br/>'))
        )}}),_c('div',{staticClass:"created-date"},[_vm._v(" "+_vm._s(_vm.formatDate(_vm.risk.createdAt))+" by "+_vm._s(_vm.risk.reporter.blueTag)+" ")]),_c('div',{staticClass:"severity",style:({
          backgroundColor: _vm.getPropertyBySeverity(_vm.risk.probability, _vm.risk.impact, 'backColor'),
          color: _vm.getPropertyBySeverity(_vm.risk.probability, _vm.risk.impact, 'color') 
        })},[_vm._v(" Severity: "+_vm._s(_vm.getSeverityName(_vm.risk.probability, _vm.risk.impact) || "")+" ")]),_c('div',{staticClass:"icon-container"},[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
        var on = ref.on;
        var attrs = ref.attrs;
return [_c('div',_vm._g(_vm._b({staticStyle:{"cursor":"pointer"},on:{"click":function($event){$event.stopPropagation();return _vm.open(_vm.risk.id)}}},'div',attrs,false),on),[_c('v-icon',{style:({ fontSize: '26px' })},[_vm._v("mdi-clipboard-check-outline")]),_c('span',{staticClass:"workitems-quantity"},[_vm._v(" "+_vm._s(_vm.workItemsDone(_vm.activeWorkItems(_vm.risk.workItems)))+"/"+_vm._s(_vm.activeWorkItems(_vm.risk.workItems).length)+" ")])],1)]}}])},[_c('span',[_vm._v(" "+_vm._s("Work item" + (_vm.risk.workItems.length != 1 ? "s" : ""))+" ")])])],1)])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }