var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-data-table',{staticClass:"bordered rounded-lg",class:{ 'new-back lighten2': _vm.newBack },attrs:{"hide-default-footer":"","disable-pagination":"","disable-sort":"","show-expand":"","headers":_vm.headers,"items":_vm.item.lines,"expanded":_vm.expanded,"item-key":"billableLineId"},on:{"update:expanded":function($event){_vm.expanded=$event}},scopedSlots:_vm._u([{key:"item.seat",fn:function(ref){
var item = ref.item;
return [_c('b',[_vm._v(_vm._s(item.billableLineRole))])]}},{key:"item.rate",fn:function(ref){
var item = ref.item;
return [_vm._v(" $"+_vm._s(_vm._f("money")(item.rate))+" ")]}},{key:"item.quantity",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("number")(item.quantity,2))+" ")]}},{key:"item.total",fn:function(ref){
var item = ref.item;
return [_vm._v(" $"+_vm._s(_vm._f("money")(item.total))+" ")]}},{key:"expanded-item",fn:function(ref){
var lineHeaders = ref.headers;
var line = ref.item;
return [_c('td',{staticClass:"text-center",attrs:{"colspan":lineHeaders.length}},[_c('v-data-table',{staticClass:"bordered mx-5 my-3 rounded-lg overflow-hidden",class:{ 'new-back lighten2': _vm.newBack },attrs:{"items":line.members,"headers":_vm.assignmentHeaders,"hide-default-footer":"","disable-pagination":"","disable-sort":"","show-select":!!_vm.editing},on:{"input":function($event){return _vm.computeTotals(line)}},scopedSlots:_vm._u([{key:"item.teamMember",fn:function(ref){
var item = ref.item;
return [_c('b',{class:_vm.userDisplayName === 'BlueTag' ? 'subtitle-1' : 'caption'},[_vm._v(_vm._s(item['user' + _vm.userDisplayName]))])]}},{key:"item.startDate",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("formatDate")(item.startDate))+" ")]}},{key:"item.endDate",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("formatDate")(item.endDate))+" ")]}},{key:"item.hours",fn:function(ref){
var item = ref.item;
return [(item.hours)?_c('span',[_vm._v(_vm._s(_vm._f("number")(item.hours,2)))]):_c('span',[_vm._v("-")])]}},{key:"item.total",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.hours ? _vm.currencyFormat(item.hours * line.rate) : '-')+" ")]}}],null,true),model:{value:(line.selectedMembers),callback:function ($$v) {_vm.$set(line, "selectedMembers", $$v)},expression:"line.selectedMembers"}})],1)]}},{key:"foot",fn:function(){return [_c('td',{staticStyle:{"border-top":"1px solid var(--v-lightgray-lighten)"},attrs:{"colspan":_vm.headers.length-2}}),_c('td',{staticClass:"px-4 py-2",staticStyle:{"border-top":"1px solid var(--v-lightgray-lighten)"}},[_c('b',[_vm._v("Totals")])]),_c('td',{staticClass:"px-4",staticStyle:{"border-top":"1px solid var(--v-lightgray-lighten)"}},[_vm._v(" "+_vm._s(_vm._f("number")(_vm.item.hours,2))+" ")]),_c('td',{staticClass:"px-4",staticStyle:{"border-top":"1px solid var(--v-lightgray-lighten)"}},[_vm._v(" $"+_vm._s(_vm._f("money")(_vm.item.total))+" ")])]},proxy:true}],null,true)})}
var staticRenderFns = []

export { render, staticRenderFns }