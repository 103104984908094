var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"new-back pa-3 fill-height"},[_c('filter-search-input',{attrs:{"applied-filters":_vm.appliedFilters,"outlined":"","dense":""},model:{value:(_vm.filters.search),callback:function ($$v) {_vm.$set(_vm.filters, "search", $$v)},expression:"filters.search"}},[_c('q-user-autocomplete',{attrs:{"label":"People","items":_vm.users,"multiple":"","show-number-of-selection":"","hide-details":"","dense":"","outlined":"","clearable":"","confirmable":false},model:{value:(_vm.filters.users),callback:function ($$v) {_vm.$set(_vm.filters, "users", $$v)},expression:"filters.users"}}),_c('v-select',{staticClass:"input",attrs:{"hide-details":"","items":_vm.departments,"multiple":"","label":"Department","item-value":"value","item-text":"name","outlined":"","dense":"","clearable":""},model:{value:(_vm.filters.department),callback:function ($$v) {_vm.$set(_vm.filters, "department", $$v)},expression:"filters.department"}}),_c('v-switch',{staticClass:"input",attrs:{"inset":"","label":"Only Active"},model:{value:(_vm.filters.onlyActive),callback:function ($$v) {_vm.$set(_vm.filters, "onlyActive", $$v)},expression:"filters.onlyActive"}})],1),_c('v-data-table',{staticClass:"rounded-lg bordered new-back lighten2",attrs:{"items":_vm.recessDays,"headers":_vm.headers},scopedSlots:_vm._u([{key:"item.blueniversary",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("formatDate")(item.blueniversary))+" ")]}},{key:"item.totalRecessDays",fn:function(ref){
var item = ref.item;
return [(item.recessDays.length)?_c('v-menu',{staticClass:"menu ",attrs:{"open-on-hover":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('span',_vm._g({staticStyle:{"max-width":"60px"}},on),[_vm._v(" "+_vm._s(item.totalRecessDays)+" ")])]}}],null,true)},[_c('v-list',{staticClass:"new-back",staticStyle:{"min-width":"200px"},attrs:{"dense":""}},[_c('v-list-item',[_c('div',{staticClass:"d-flex justify-space-between align-center",staticStyle:{"min-width":"20px","width":"100%"}},[_c('span',{staticClass:"mr-6"},[_vm._v(" Days ")]),_c('span',[_vm._v(" Date ")])])]),_vm._l((item.recessDays),function(recessDay,ix){return _c('v-list-item',{key:ix},[_c('div',{staticClass:"d-flex justify-space-between align-center",staticStyle:{"min-width":"20px","width":"100%"}},[_c('span',{staticClass:"mr-6"},[_vm._v(" "+_vm._s(recessDay.days)+" ")]),_c('span',[_vm._v(" "+_vm._s(_vm._f("formatDate")(recessDay.createdAt))+" ")])])])})],2)],1):_c('div',[_vm._v(" "+_vm._s(item.totalRecessDays)+" ")])]}},{key:"item.totalDaysRequested",fn:function(ref){
var item = ref.item;
return [(item.recessRequests.length)?_c('v-menu',{staticClass:"menu ",attrs:{"open-on-hover":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('span',_vm._g({staticStyle:{"max-width":"60px"}},on),[_vm._v(" "+_vm._s(item.totalDaysRequested)+" ")])]}}],null,true)},[_c('v-list',{staticClass:"new-back",staticStyle:{"min-width":"200px"},attrs:{"dense":""}},[_c('v-list-item',[_c('div',{staticClass:"d-flex justify-space-between align-center",staticStyle:{"min-width":"20px","width":"100%"}},[_c('span',{staticClass:"mr-6"},[_vm._v(" Days ")]),_c('span',[_vm._v(" Date ")])])]),_vm._l((item.recessRequests),function(request,ix){return _c('v-list-item',{key:ix},[_c('div',{staticClass:"d-flex justify-space-between align-center",staticStyle:{"min-width":"20px","width":"100%"}},[_c('span',{staticClass:"mr-6"},[_vm._v(" "+_vm._s(request.lines.length)+" ")]),_c('span',[_vm._v(" "+_vm._s(_vm._f("formatDate")(request.createdAt))+" ")])])])})],2)],1):_c('div',[_vm._v(" "+_vm._s(item.totalDaysRequested)+" ")])]}}],null,true)})],1)}
var staticRenderFns = []

export { render, staticRenderFns }