<template>
	<div>
		<blue-confirm-dialog ref="editDialog" persistent>
			<template #text>
				<v-form v-model="validForm" class="pt-2">
					<v-row>
						<v-col :cols="6" v-if="invoiceDetailsView">
							<label>Receiving account*</label>
							<v-autocomplete
								v-model="newPayment.accountId" :rules="rules"
								outlined hide-details dense :items="$parent.accounts"
								item-value="id" item-text="name"
							>
								<template #item="{item}">
									<v-list-item-content>
										<v-list-item-title>
											{{item.name}}
										</v-list-item-title>
										<v-list-item-subtitle>
											{{item.accountNumber}}
										</v-list-item-subtitle>
									</v-list-item-content>
								</template>
								<template #selection="{item}">
									<v-list-item-content class="py-0">
										<v-list-item-title>
											{{item.name}}
										</v-list-item-title>
										<v-list-item-subtitle>
											{{item.accountNumber}}
										</v-list-item-subtitle>
									</v-list-item-content>
								</template>
							</v-autocomplete>
						</v-col>
						<v-col :cols="6">
							<label>Customer*</label>
							<v-autocomplete
								v-model="newPayment.customerId" :rules="rules"
								outlined dense hide-details :items="$parent.customers"
								item-text="name" item-value="id"
							/>
						</v-col>
						<v-col :cols="6">
							<label>Concept</label>
							<v-text-field
								v-model="newPayment.concept"
								outlined dense hide-details
							/>
						</v-col>
						<v-col :cols="6">
							<label>Reference*</label>
							<v-text-field
								v-model="newPayment.reference" :rules="rules"
								outlined dense hide-details
							/>
						</v-col>
						<v-col :cols="6">
							<label>Payment date*</label>
							<q-date-picker
								v-model="newPayment.date" :rules="rules"
								outlined dense hide-details inner
							/>
						</v-col>
						<v-col :cols="6">
							<label>Amount*</label>
							<input-mask
								v-model="newPayment.amount" :rules="rules"
								outlined dense hide-details
								:mask="currencyFormat" type="number"
							/>
						</v-col>
						<v-col :cols="12">
							<v-checkbox
								v-model="newPayment.multipleInvoices" hide-details
								class="mt-0 mb-2 inline-block" label="This payment belongs to multiple invoices"
							/>
						</v-col>
	
						<v-col :cols="12">
							<v-row v-for="(invoice, ix) in newPayment.invoices.filter(i => !i.deleted)" :key="ix">
								<v-col :cols="6">
									<label>Corresponding Invoice</label>
									<v-autocomplete
										v-model="invoice.invoiceId"
										outlined dense hide-details :items="shownInvoices"
										item-text="code" item-value="id"
									>
										<template #item="{ item }">
											<div class="my-1" v-if="!newPayment.invoices.some(i => i.invoiceId == item.id)">
												{{item.code}}
											</div>
											<div v-else></div>
										</template>
										<template #no-data>
											<div class="py-1 px-2 body-2">
												No pending invoices
											</div>
										</template>
									</v-autocomplete>
								</v-col>
								<v-col>
									<label>Amount</label>
									<input-mask
										v-model="invoice.amount" :rules="rules"
										outlined dense hide-details
										:mask="currencyFormat" type="number"
									/>
								</v-col>
								<v-col :cols="1" class="d-flex align-end">
									<v-btn @click="removeInvoice(invoice)" color="error" icon>
										<v-icon>mdi-delete</v-icon>
									</v-btn>
								</v-col>
							</v-row>
						</v-col>
					</v-row>
					<v-btn v-if="newPayment.multipleInvoices" @click="addInvoice" class="mt-5" color="primary" icon>
						<v-icon>mdi-plus-circle-outline</v-icon>
					</v-btn>
				</v-form>
			</template>
			<template #actions="{cancel, confirm}">
				<div v-show="newPayment.deleted" class="background--lighten pa-4 pl-3 mb-2 rounded">
					<v-icon left color="warning">warning</v-icon>
					The payment will be reactivated
				</div>
				<v-spacer/>
				<v-btn @click="cancel" text>Cancel</v-btn>
				<v-btn
					@click="requestComment(confirm)"
					:disabled="!validForm"
					color="primary" text
				>
					Save Payment
				</v-btn>
			</template>
		</blue-confirm-dialog>

		<v-dialog v-model="showCommentDialog" persistent width="600">
			<v-card>
				<v-card-title>Comment</v-card-title>
				<v-card-text>
					<v-text-field v-model="comment" hide-details outlined :rules="[v => !!v || 'Comment required']"/>
					<div class="text-right mt-3">
						<v-btn @click="commentResolve(false)" class="mr-2" color="primary" outlined>Cancel</v-btn>
						<v-btn @click="commentResolve(true)" :disabled="!comment" color="primary">Save</v-btn>
					</div>
				</v-card-text>
			</v-card>
		</v-dialog>
	</div>
</template>

<script>
export default {
	props: {
		invoiceDetailsView: { type: Boolean, default: false },
	},
	data: () => ({
		validForm: false,
		rules: [v => !!v || 'Required'],
		newPayment: {
			invoices: [],
		},
		resolve: null,
		reject: null,
		showCommentDialog: false,
		comment: '',
	}),	
	watch: {
		'newPayment.multipleInvoices'(){
			if(!this.newPayment.invoices)
				this.newPayment.invoices = []
			if(!this.newPayment.multipleInvoices)
				this.newPayment.invoices = this.newPayment.invoices.slice(0, 1)
		},
	},
	computed: {
		shownInvoices(){
			return this.$parent.invoices
				.filter(i => this.newPayment.customerId == i.customerId)
		},
	},
	methods: {
		open(payment){
			this.newPayment = _.cloneDeep(payment)
			if(!this.newPayment.invoices || !this.newPayment.invoices.length){
				this.$set(this.newPayment, 'invoices', [])
				this.addInvoice()
			}
			this.$refs.editDialog
				.open({
					title: (payment.id ? 'Edit' : 'Register') + ' Payment',
					width: 600, addClose: true,
				})
				.then(res => {
					if(!res) return
					this.save()
				})
			return new Promise((resolve, reject) => {
				this.resolve = resolve
				this.reject = reject
			})
		},
		save(){
			this.$refs.editDialog.close()
			this.newPayment.amount = Number(this.newPayment.amount)
			this.newPayment.invoices = this.newPayment.invoices
				.filter(i => !i.deleted && i .invoiceId)
			this.newPayment.concept = this.newPayment.concept || ''
			this.$q
				.save({
					api: `/api/payment`,
					data: {comment: this.comment, payment:this.newPayment},
					successMsg: 'Payment saved successfully',
					errorMsg: "Cannot save changes",
				})
				.then(this.resolve)
		},
		addInvoice(){
			this.newPayment.invoices
				.push({
					invoiceId: '',
					amount: 0
				})
		},
		removeInvoice(invoice){
			this.$openConfirmDialog({
				title: 'Remove Invoice',
				description: 'Are you sure you want to delete this invoice?',
			})
				.then(res => {
					if(!res) return
					this.$set(invoice, 'deleted', true)
				})
		},
		requestComment(){
			let invoicedAmount = this.newPayment.invoices
				.reduce((sum, v) => sum + v.amount, 0)
			if(invoicedAmount > this.newPayment.amount){
				this.$root.error('Applied amount cannot be greater than the payment amount')
				return
			}
			if(!this.newPayment.id){
				this.commentResolve(true)
				return
			}
			this.showCommentDialog = true
		},
		commentResolve(res){
			this.showCommentDialog = false
			if(!res) return
			this.save()
		},
	},
}
</script>

<style lang="scss" scoped>
::v-deep .v-list-item--link:before{
	display: none;
}

::v-deep .v-list-item{
  min-height: 0 !important;
}

.status-warning{
	display: flex;
	align-items: center;
}
</style>