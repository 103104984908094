<template>
  <div>
    <h4>Related {{ title }}</h4>
    <p v-if="!linked || !linked.length" class="ma-0">
      <i>No linked {{ title }}</i>
    </p>
    <v-list dense class="py-1 high-back">
      <LinkedWorkItemsItem
        v-for="(link, ix) in sortedLinked" :key="ix"
        @open="open"
        :items-length="linked.length"
        :deleteItem="deleteLink"
        :link="link"
        :disabled="!projects.some(x => link.relatedProjectId == x.id)"
      />
    </v-list>

    <v-row v-if="addingLink" class="my-0 px-2">
      <v-col :cols="6" class="pa-1">
        <v-autocomplete
          @change="linkProjectChange"
          placeholder="Project" :items="projects" item-text="name" item-value="id"
          solo dense hide-details v-model="newLink.relatedProjectId"
        />
      </v-col>
      <v-col :cols="6" class="pa-1">
        <v-autocomplete
          :placeholder="title.slice(0, -1)"
          :items="projectWorkItems.filter(wi => (type === undefined && wi.type != 1) || wi.type == type)"
          item-text="code"
          item-value="id"
          solo
          dense
          hide-details
          v-model="newLink.relatedWorkItemId"
          :filter="(item, queryText, itemText) => $q.searchByWords(queryText, [item.code, item.name])"
        >
          <template v-slot:selection="{ item }">
            
            <small :style="{ verticalAlign: 'middle' }" v-text="item.code"></small>:
            <small :style="{ verticalAlign: 'middle' }" v-text="item.name"></small>
              
          </template>
          <template v-slot:item="{ item }">
              <v-list-item-content>
              <v-list-item-title>
                <v-chip :style="{ verticalAlign: 'middle' }" dark class="type-dot mr-1" x-small :color="item.typeColor">
                  <v-icon x-small>{{item.typeIconClass}}</v-icon>
                </v-chip>
                <span :style="{ verticalAlign: 'middle' }" v-text="item.code"></span>
                <v-btn x-small dark outlined :color="item.statusColor" class="text-capitalize ml-1 mx-0 mb-1 status-button-title">
                  <v-icon v-if="item.statusIconClass" left>{{item.statusIconClass}}</v-icon>
                  <span class="status-name">
                    {{item.statusName}}
                  </span>
                </v-btn>  
              </v-list-item-title>
              <v-list-item-subtitle>{{item.name}}</v-list-item-subtitle>
            </v-list-item-content>
          </template>
        </v-autocomplete>
      </v-col>
      <v-col :cols="6" class="px-1 my-0">
        <v-select placeholder="Relation" :items="linkRelationships" item-text="displayName" item-value="name" solo dense hide-details v-model="newLink.relationship"></v-select>
      </v-col>
    </v-row>
    <v-row v-if="(!addingLink && !createWorkItem)" class="my-0 px-2">
      <v-col class="pa-1">
        <v-btn
          v-if="!addingLink"
          color="default" class="px-2"
          depressed small
          @click="openAddLink"
        >
          <v-icon small>mdi-plus</v-icon>
          <span class="caption" style="text-transform: none !important;">Add new</span>
        </v-btn>
      </v-col>
    </v-row>
    <v-row v-if="(!addingLink && createWorkItem)" class="my-0 px-2">
      <v-menu
        bottom
        offset-y
        :close-on-content-click="true"
        style="z-index: 200"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            v-if="!addingLink"
            color="default" class="px-2"
            depressed small
            v-bind="attrs"
            v-on="on"
          >
            <v-icon small>mdi-plus</v-icon>
            <span class="caption" style="text-transform: none !important;">Add new</span>
          </v-btn>
        </template>
        <v-list dense class="dropdown-menu">
          <v-list-item @click="openAddLink" class="cursor-pointer" style="border-bottom: 1px solid var(--v-scroll-lighten) !important;">
            Relate an existing {{ title.slice(0, -1) }}
          </v-list-item>
          <v-list-item class="cursor-pointer" @click="create(type)">
            Create a {{ title.slice(0, -1) }}
          </v-list-item>
        </v-list>
      </v-menu>
    </v-row>
    <v-row v-if="addingLink" class="px-2 mb-2">
      <v-col class="pa-1">
        <v-btn small color="default" depressed @click="addingLink = false">
          Cancel
        </v-btn>
        <v-btn small color="primary" depressed @click="addLink">
          Save
        </v-btn>
      </v-col>
    </v-row>
  </div>  
</template>

<script>
import LinkedWorkItemsItem from './LinkedWorkItemsItem.vue'
import moment from 'moment'

export default {
  components: { LinkedWorkItemsItem },
  name: 'LinkedWorkItems',
  props: [
    'type',
    'linked',
    'creationMenu',
    'title',
    'projectConfig',
    'linkRelationships',
    'projects',
    'save',
    'createWorkItem',
    'create',
    'open',
  ],
  data: () => ({
    projectWorkItems: [],
    newLink: {},
    addingLink: false
  }),
  computed: {
    sortedLinked() {
      return this.linked.sort((a, b) => {
        if(a.relatedWorkItemType === b.relatedWorkItemType)
          return moment(a.relatedWorkItemCreatedAt).isBefore(b.relatedWorkItemCreatedAt) ? 1 : -1
        if (a.relatedWorkItemType < b.relatedWorkItemType) return -1
        if (a.relatedWorkItemType > b.relatedWorkItemType) return 1
      })
    },
  },
  methods: {
    deleteLink(link, $event) {
      $event.stopImmediatePropagation()
      this.save(link.relationship, link.relatedWorkItemId, false)
    },
    addLink() {
      this.addingLink = false
      this.save(this.newLink.relationship, this.newLink.relatedWorkItemId, true)
      this.addingLink = false
    },
    openAddLink() {
      this.addingLink = true
      this.newLink = {}
      this.newLink.relatedProjectId = this.projectConfig.id
      this.linkProjectChange()
    },
    linkProjectChange(){
      let statuses = []
      let statusPr = this.$http
        .get(`/api/project/${this.newLink.relatedProjectId}/config?active=true`)
        .then(res => {
          statuses = res.data.workItemStatuses
        })
        .catch(res => {
          window.getApp.error("Cannot get project sprints, tags and status.")
          console.error(res)
        })
      let workItemsPr = this.$http
        .get(`/api/project/${this.newLink.relatedProjectId}/workItem`)
        .then(res => {
          return res
        })
        .catch(res => {
          window.getApp.error("Cannot get work items.")
          console.error(res)
        })
      
      Promise.all([statusPr, workItemsPr])
        .then((res) => {
          let workItems = res[1].data.sort((a, b) => {
            if(a.type === b.type)
              return moment(a.createdAt).isBefore(b.createdAt) ? 1 : -1
            if (a.type < b.type) return -1
            if (a.type > b.type) return 1
          })
          this.projectWorkItems = []
          workItems.forEach(wi => {
            let status = statuses.find(s => s.id === wi.statusId)
            if (!status) return

            wi.statusIconClass = status.iconClass
            wi.statusColor = status.color
            wi.statusName = status.name
            wi.statusIsFinal = status.isFinal

            this.projectWorkItems.push(wi)
          })
        })
    },
  },
}
</script>

<style lang="scss" scoped>
.action-container {
  border: 1px solid var(--v-scroll-lighten);
  position: relative;
  transition: all 2s;
}
.rounded-linked {
  border-radius: 10px 10px;
}
.rounded-linked-work-item {
  &:first-child {
    border-radius: 10px 10px 0 0 !important;
  }
  &:last-child {
    border-radius: 0 0 10px 10px !important;
  }
  &:not(:last-child) {
    border-bottom: none !important;
  }
}
.link-details > * {
  vertical-align: middle;
}
.link-details {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.type-dot {
  height: 18px;
  width: 18px;
  vertical-align: middle;
  border-radius: 11px !important;
  padding: 0px 4px;
  .v-icon {
    font-size: 10px !important;
  }
}
.status-button-title {
  position: absolute;
  right: 5px;
  max-width: 100%;
  overflow: hidden;
}
.status-name {
  white-space: normal;
}
.icon-size {
  font-size: 15px;
}
.add-text {
  white-space: normal;
  text-transform: none;
  margin-bottom: 0px;
  font-size: 10px;
}
.add-button {
  display: 'flex';
  justify-content: 'center'
}
.status-button-title ::v-deep .v-btn__content {
  max-width: 100%;
}
.v-menu__content {
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
}
.dropdown-menu {
  border: 1px solid var(--v-scroll-lighten);
  border-radius: 10px;
}
</style>