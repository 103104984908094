export default [
  { "name": "360", "categories": [ "maps" ], "tags": [ "arrow", "av", "rotation", "camera", "vr", "360" ], },
  { "name": "3d_rotation", "categories": [ "action" ], "tags": [ "symbol", "D", "arrow", "rotation", "arrows", "alphabet", "type", "3d", "number", "character", "av", "letter", "text", "camera", "vr", "digit", "font" ], },
  { "name": "4k", "categories": [ "av" ], "tags": [ "symbol", "4000", "display", "alphabet", "video", "type", "resolution", "number", "character", "pixels", "4K", "letter", "text", "pixel", "digit", "font" ], },
  { "name": "ac_unit", "categories": [ "places" ], "tags": [], },
  { "name": "access_alarm", "categories": [ "device" ], "tags": [], },
  { "name": "access_alarms", "categories": [ "device" ], "tags": [], },
  { "name": "access_time", "categories": [ "device" ], "tags": [], },
  { "name": "accessibility", "categories": [ "action" ], "tags": [ "accessible", "help", "accessibility", "handicap", "person", "body", "human" ], },
  { "name": "accessibility_new", "categories": [ "action" ], "tags": [ "accessible", "help", "accessibility", "handicap", "person", "body", "human" ], },
  { "name": "accessible", "categories": [ "action" ], "tags": [ "accessible", "help", "accessibility", "handicap", "person", "body", "human" ], },
  { "name": "accessible_forward", "categories": [ "action" ], "tags": [ "accessible", "help", "accessibility", "handicap", "person", "body", "human" ], },
  { "name": "account_balance", "categories": [ "action" ], "tags": [ "bill", "pay", "dollars", "commerce", "bank", "balance", "money", "online", "currency", "payment", "credit", "cash", "account", "card", "coin" ], },
  { "name": "account_balance_wallet", "categories": [ "action" ], "tags": [ "bill", "pay", "dollars", "commerce", "bank", "balance", "money", "online", "currency", "payment", "credit", "cash", "account", "card", "coin" ], },
  { "name": "account_box", "categories": [ "action" ], "tags": [ "square", "face", "thumbnail", "person", "profile", "human", "people", "user", "account" ], },
  { "name": "account_circle", "categories": [ "action" ], "tags": [ "face", "thumbnail", "person", "profile", "circle", "human", "people", "user", "account" ], },
  { "name": "account_tree", "categories": [ "notification" ], "tags": [], },
  { "name": "adb", "categories": [ "notification" ], "tags": [], },
  { "name": "add", "categories": [ "content" ], "tags": [ "add", "new", "create", "+", "plus" ], },
  { "name": "add_a_photo", "categories": [ "image" ], "tags": [], },
  { "name": "add_alarm", "categories": [ "device" ], "tags": [], },
  { "name": "add_alert", "categories": [ "alert" ], "tags": [ "add", "notification", "alert", "bell", "plus" ], },
  { "name": "add_box", "categories": [ "content" ], "tags": [ "add", "new", "square", "create", "box", "+", "plus" ], },
  { "name": "add_circle", "categories": [ "content" ], "tags": [ "add", "new", "create", "box", "+", "circle", "plus" ], },
  { "name": "add_circle_outline", "categories": [ "content" ], "tags": [ "add", "new", "create", "box", "+", "circle", "plus" ], },
  { "name": "add_comment", "categories": [ "editor" ], "tags": [ "add", "feedback", "bubble", "chat", "comment", "+", "message", "plus" ], },
  { "name": "add_ic_call", "categories": [ "communication" ], "tags": [ "add", "call", "phone", "contact", "mobile", "device", "plus" ], },
  { "name": "add_location", "categories": [ "maps" ], "tags": [ "add", "maps", "pin", "stop", "destination", "location", "place", "plus", "direction" ], },
  { "name": "add_photo_alternate", "categories": [ "image" ], "tags": [], },
  { "name": "add_shopping_cart", "categories": [ "action" ], "tags": [ "add", "pay", "dollars", "commerce", "cart", "plus", "money", "online", "currency", "payment", "credit", "cash", "checkout", "card", "coin", "shopping" ], },
  { "name": "add_to_home_screen", "categories": [ "device" ], "tags": [ "add", "tablet", "OS", "arrow", "phone", "homescreen", "mobile", "up", "iOS", "device", "Android", "hardware" ], },
  { "name": "add_to_photos", "categories": [ "image" ], "tags": [], },
  { "name": "add_to_queue", "categories": [ "av" ], "tags": [ "add", "backlog", "desktop", "watch", "display", "lineup", "monitor plus", "queue" ], },
  { "name": "adjust", "categories": [ "image" ], "tags": [], },
  { "name": "airline_seat_flat", "categories": [ "notification" ], "tags": [], },
  { "name": "airline_seat_flat_angled", "categories": [ "notification" ], "tags": [], },
  { "name": "airline_seat_individual_suite", "categories": [ "notification" ], "tags": [], },
  { "name": "airline_seat_legroom_extra", "categories": [ "notification" ], "tags": [], },
  { "name": "airline_seat_legroom_normal", "categories": [ "notification" ], "tags": [], },
  { "name": "airline_seat_legroom_reduced", "categories": [ "notification" ], "tags": [], },
  { "name": "airline_seat_recline_extra", "categories": [ "notification" ], "tags": [], },
  { "name": "airline_seat_recline_normal", "categories": [ "notification" ], "tags": [], },
  { "name": "airplanemode_active", "categories": [ "device" ], "tags": [ "mode", "airplanemode", "flight", "airplane", "active", "signal", "on" ], },
  { "name": "airplanemode_inactive", "categories": [ "device" ], "tags": [ "mode", "airplanemode", "flight", "inactive", "airplane", "disabled", "signal", "off" ], },
  { "name": "airplay", "categories": [ "av" ], "tags": [ "Airplay", "arrow", "screen", "control", "device", "signal", "connect" ], },
  { "name": "airport_shuttle", "categories": [ "places" ], "tags": [], },
  { "name": "alarm", "categories": [ "action" ], "tags": [ "alart", "notification", "alarm", "countdown", "clock", "time", "bell" ], },
  { "name": "alarm_add", "categories": [ "action" ], "tags": [ "add", "notification", "alert", "alarm", "countdown", "clock", "time", "bell", "plus" ], },
  { "name": "alarm_off", "categories": [ "action" ], "tags": [ "countdown", "clock", "bell", "enabled", "off", "alart", "notification", "stop", "alarm", "disabled", "slash", "time", "on" ], },
  { "name": "alarm_on", "categories": [ "action" ], "tags": [ "alart", "notification", "checkmark on", "ready", "alarm", "countdown", "start", "check", "clock", "time", "bell" ], },
  { "name": "album", "categories": [ "av" ], "tags": [ "play", "cd", "music", "artist", "record", "Album", "track" ], },
  { "name": "all_inbox", "categories": [ "action" ], "tags": [ "all", "delivery", "mail", "post", "letter", "send", "Inbox", "email" ], },
  { "name": "all_inclusive", "categories": [ "places" ], "tags": [], },
  { "name": "all_out", "categories": [ "action" ], "tags": [ "all", "shape", "circle", "out" ], },
  { "name": "alternate_email", "categories": [ "communication" ], "tags": [ "@", "Email", "address", "contact", "alternate" ], },
  { "name": "amp_stories", "categories": [ "content" ], "tags": [ "view", "stories", "amp", "flow", "gallery" ], },
  { "name": "android", "categories": [ "action" ], "tags": [ "character", "mascot", "android", "logo", "toy" ], },
  { "name": "announcement", "categories": [ "action" ], "tags": [ "!", "symbol", "exclamation", "danger", "error", "notification", "alert", "bubble", "speech", "attention", "warning", "caution", "mark" ], },
  { "name": "apartment", "categories": [ "places" ], "tags": [], },
  { "name": "apps", "categories": [ "navigation" ], "tags": [], },
  { "name": "archive", "categories": [ "content" ], "tags": [ "mail", "archive", "store", "inbox" ], },
  { "name": "arrow_back", "categories": [ "navigation" ], "tags": [], },
  { "name": "arrow_back_ios", "categories": [ "navigation" ], "tags": [], },
  { "name": "arrow_downward", "categories": [ "navigation" ], "tags": [], },
  { "name": "arrow_drop_down", "categories": [ "navigation" ], "tags": [], },
  { "name": "arrow_drop_down_circle", "categories": [ "navigation" ], "tags": [], },
  { "name": "arrow_drop_up", "categories": [ "navigation" ], "tags": [], },
  { "name": "arrow_forward", "categories": [ "navigation" ], "tags": [], },
  { "name": "arrow_forward_ios", "categories": [ "navigation" ], "tags": [], },
  { "name": "arrow_left", "categories": [ "navigation" ], "tags": [], },
  { "name": "arrow_right", "categories": [ "navigation" ], "tags": [], },
  { "name": "arrow_right_alt", "categories": [ "action" ], "tags": [ "shape", "arrow", "pointing", "right" ], },
  { "name": "arrow_upward", "categories": [ "navigation" ], "tags": [], },
  { "name": "art_track", "categories": [ "av" ], "tags": [ "Art", "display", "format", "insert", "track" ], },
  { "name": "aspect_ratio", "categories": [ "action" ], "tags": [ "image", "square", "expand", "size", "aspect", "scale", "ratio" ], },
  { "name": "assessment", "categories": [ "action" ], "tags": [ "analytics", "assessment", "bar", "measure", "metrics", "tracking", "bars", "graph" ], },
  { "name": "assignment", "categories": [ "action" ], "tags": [ "assignment", "document", "writing", "doc", "text", "clipboard", "article" ], },
  { "name": "assignment_ind", "categories": [ "action" ], "tags": [ "face", "assignment", "person", "profile", "clipboard", "human", "people", "user", "account" ], },
  { "name": "assignment_late", "categories": [ "action" ], "tags": [ "!", "symbol", "assignment", "exclamation", "danger", "error", "clipboard", "notification", "alert", "attention", "warning", "caution", "mark" ], },
  { "name": "assignment_return", "categories": [ "action" ], "tags": [ "arrow", "left", "assignment", "clipboard", "point" ], },
  { "name": "assignment_returned", "categories": [ "action" ], "tags": [ "arrow", "assignment", "clipboard", "down", "point" ], },
  { "name": "assignment_turned_in", "categories": [ "action" ], "tags": [ "assignment", "finished", "check", "complete", "clipboard", "done" ], },
  { "name": "assistant", "categories": [ "image" ], "tags": [], },
  { "name": "assistant_photo", "categories": [ "image" ], "tags": [], },
  { "name": "atm", "categories": [ "maps" ], "tags": [ "symbol", "dollars", "alphabet", "commerce", "type", "cart", "character", "currency", "payment", "atm", "text", "credit", "cash", "shopping", "bill", "pay", "teller", "automated", "money", "machine", "letter", "online", "circle", "card", "coin", "font" ], },
  { "name": "attach_file", "categories": [ "editor" ], "tags": [ "add", "file", "mail", "link", "attach", "media", "clip" ], },
  { "name": "attach_money", "categories": [ "editor" ], "tags": [ "add", "link", "pay", "media", "commerce", "file", "money", "online", "e-commerce", "payment", "attach", "credit", "card", "clip" ], },
  { "name": "attachment", "categories": [ "file" ], "tags": [], },
  { "name": "audiotrack", "categories": [ "image" ], "tags": [], },
  { "name": "autorenew", "categories": [ "action" ], "tags": [ "rotate", "cache", "arrow", "cached", "inprogress", "renew" ], },
  { "name": "av_timer", "categories": [ "av" ], "tags": [ "timer", "seconds", "AV", "watch", "minutes", "countdown", "clock", "time" ], },
  { "name": "backspace", "categories": [ "content" ], "tags": [ "cancel", "erase", "backspace", "correct", "arrow", "clear", "back", "delete" ], },
  { "name": "backup", "categories": [ "action" ], "tags": [ "cloud", "backup", "arrow", "upload", "up", "point" ], },
  { "name": "ballot", "categories": [ "content" ], "tags": [ "ballot", "election", "poll", "vote" ], },
  { "name": "bar_chart", "categories": [ "editor" ], "tags": [ "analytics", "diagram", "bar", "measure", "data", "infograpic", "metrics", "statstics", "chart", "tracking", "bars", "graph" ], },
  { "name": "bathtub", "categories": [ "places" ], "tags": [], },
  { "name": "battery_alert", "categories": [ "device" ], "tags": [ "!", "charge", "alert", "Battery", "mobile", "exclamation", "power", "cell", "error" ], },
  { "name": "battery_charging_full", "categories": [ "device" ], "tags": [ "lightening", "charge", "thunderbolt", "Battery", "mobile", "bolt", "power", "cell", "full" ], },
  { "name": "battery_full", "categories": [ "device" ], "tags": [ "charge", "Battery", "mobile", "power", "cell", "full" ], },
  { "name": "battery_std", "categories": [ "device" ], "tags": [ "standard", "std", "charge", "Battery", "mobile", "power", "cell", "plus" ], },
  { "name": "battery_unknown", "categories": [ "device" ], "tags": [ "charge", "question", "Battery", "mobile", "power", "cell", "plus", "unknown" ], },
  { "name": "beach_access", "categories": [ "places" ], "tags": [], },
  { "name": "beenhere", "categories": [ "maps" ], "tags": [ "here", "verified", "certified", "location", "check", "guide", "local", "mark", "direction" ], },
  { "name": "block", "categories": [ "content" ], "tags": [ "entry", "no", "stop", "prohibited", "block", "avoid" ], },
  { "name": "bluetooth", "categories": [ "device" ], "tags": [ "symbol", "bluetooth", "connection", "paring", "device" ], },
  { "name": "bluetooth_audio", "categories": [ "notification" ], "tags": [], },
  { "name": "bluetooth_connected", "categories": [ "device" ], "tags": [ "symbol", "bluetooth", "connection", "paring", "device" ], },
  { "name": "bluetooth_disabled", "categories": [ "device" ], "tags": [ "symbol", "bluetooth", "connection", "disabled", "paring", "slash", "device", "enabled", "off", "on" ], },
  { "name": "bluetooth_searching", "categories": [ "device" ], "tags": [ "symbol", "search", "bluetooth", "searching", "connection", "paring", "device" ], },
  { "name": "blur_circular", "categories": [ "image" ], "tags": [], },
  { "name": "blur_linear", "categories": [ "image" ], "tags": [], },
  { "name": "blur_off", "categories": [ "image" ], "tags": [], },
  { "name": "blur_on", "categories": [ "image" ], "tags": [], },
  { "name": "book", "categories": [ "action" ], "tags": [ "bookmark", "read", "book", "reading" ], },
  { "name": "bookmark", "categories": [ "action" ], "tags": [ "bookmarks", "remember", "bookmark", "save" ], },
  { "name": "bookmark_border", "categories": [ "action" ], "tags": [ "bookmarks", "remember", "bookmark", "outline", "save" ], },
  { "name": "bookmarks", "categories": [ "action" ], "tags": [ "bookmarks", "remember", "bookmark", "stack", "save" ], },
  { "name": "border_all", "categories": [ "editor" ], "tags": [ "border", "editor", "edit", "writing", "doc", "sheet", "text", "type", "stroke", "editing", "speadsheet" ], },
  { "name": "border_bottom", "categories": [ "editor" ], "tags": [ "border", "editor", "edit", "writing", "doc", "sheet", "text", "type", "stroke", "editing", "speadsheet" ], },
  { "name": "border_clear", "categories": [ "editor" ], "tags": [ "border", "editor", "edit", "writing", "doc", "sheet", "text", "type", "stroke", "editing", "speadsheet" ], },
  { "name": "border_horizontal", "categories": [ "editor" ], "tags": [ "border", "editor", "edit", "writing", "doc", "sheet", "text", "type", "stroke", "editing", "speadsheet" ], },
  { "name": "border_inner", "categories": [ "editor" ], "tags": [ "border", "editor", "edit", "writing", "doc", "sheet", "text", "type", "stroke", "editing", "speadsheet" ], },
  { "name": "border_left", "categories": [ "editor" ], "tags": [ "border", "editor", "edit", "writing", "doc", "sheet", "text", "type", "stroke", "editing", "speadsheet" ], },
  { "name": "border_outer", "categories": [ "editor" ], "tags": [ "border", "editor", "edit", "writing", "doc", "sheet", "text", "type", "stroke", "editing", "speadsheet" ], },
  { "name": "border_right", "categories": [ "editor" ], "tags": [ "border", "editor", "edit", "writing", "doc", "sheet", "text", "type", "stroke", "editing", "speadsheet" ], },
  { "name": "border_style", "categories": [ "editor" ], "tags": [ "border", "editor", "color", "edit", "type", "stroke", "editing", "writing", "doc", "sheet", "style", "text", "speadsheet" ], },
  { "name": "border_top", "categories": [ "editor" ], "tags": [ "border", "editor", "edit", "writing", "doc", "sheet", "text", "type", "stroke", "editing", "speadsheet" ], },
  { "name": "border_vertical", "categories": [ "editor" ], "tags": [ "border", "editor", "edit", "writing", "doc", "sheet", "text", "type", "stroke", "editing", "speadsheet" ], },
  { "name": "branding_watermark", "categories": [ "av" ], "tags": [ "copyright", "identity", "format", "warermark", "logo", "stamp", "Brandig", "emblem" ], },
  { "name": "brightness_1", "categories": [ "image" ], "tags": [], },
  { "name": "brightness_2", "categories": [ "image" ], "tags": [], },
  { "name": "brightness_3", "categories": [ "image" ], "tags": [], },
  { "name": "brightness_4", "categories": [ "image" ], "tags": [], },
  { "name": "brightness_5", "categories": [ "image" ], "tags": [], },
  { "name": "brightness_6", "categories": [ "image" ], "tags": [], },
  { "name": "brightness_7", "categories": [ "image" ], "tags": [], },
  { "name": "brightness_auto", "categories": [ "device" ], "tags": [ "A", "auto", "brightness", "phone", "mobile", "control", "monitor", "sun" ], },
  { "name": "brightness_high", "categories": [ "device" ], "tags": [ "auto", "high", "brightness", "phone", "mobile", "control", "monitor" ], },
  { "name": "brightness_low", "categories": [ "device" ], "tags": [ "auto", "brightness", "low", "phone", "mobile", "control", "monitor" ], },
  { "name": "brightness_medium", "categories": [ "device" ], "tags": [ "auto", "brightness", "phone", "mobile", "control", "monitor", "medium" ], },
  { "name": "broken_image", "categories": [ "image" ], "tags": [], },
  { "name": "brush", "categories": [ "image" ], "tags": [], },
  { "name": "bubble_chart", "categories": [ "editor" ], "tags": [ "data", "infograpic", "tracking", "bars", "graph", "analytics", "diagram", "bar", "measure", "bubble", "metrics", "statstics", "circle", "chart" ], },
  { "name": "bug_report", "categories": [ "action" ], "tags": [ "file", "bug", "insect", "report", "animal" ], },
  { "name": "build", "categories": [ "action" ], "tags": [ "build", "tool", "wrench" ], },
  { "name": "burst_mode", "categories": [ "image" ], "tags": [], },
  { "name": "business", "categories": [ "communication" ], "tags": [ "address", "company", "building", "structure", "Business" ], },
  { "name": "business_center", "categories": [ "places" ], "tags": [], },
  { "name": "cached", "categories": [ "action" ], "tags": [ "rotate", "cache", "arrow", "cached", "inprogress", "renew" ], },
  { "name": "cake", "categories": [ "social" ], "tags": [], },
  { "name": "calendar_today", "categories": [ "action" ], "tags": [ "calendar", "date", "remember", "week", "month", "reminder", "today", "event", "day" ], },
  { "name": "calendar_view_day", "categories": [ "action" ], "tags": [ "calendar", "date", "remember", "week", "month", "reminder", "today", "event", "day" ], },
  { "name": "call", "categories": [ "communication" ], "tags": [ "Call", "phone", "mobile", "talk", "device" ], },
  { "name": "call_end", "categories": [ "communication" ], "tags": [ "Call", "phone", "mobile", "end", "talk", "device" ], },
  { "name": "call_made", "categories": [ "communication" ], "tags": [ "Call", "arrow", "made", "mobile", "device" ], },
  { "name": "call_merge", "categories": [ "communication" ], "tags": [ "Call", "arrow", "merge", "mobile", "device" ], },
  { "name": "call_missed", "categories": [ "communication" ], "tags": [ "Call", "arrow", "missed", "mobile", "device" ], },
  { "name": "call_missed_outgoing", "categories": [ "communication" ], "tags": [ "Call", "outgoing", "arrow", "missed", "mobile", "device" ], },
  { "name": "call_received", "categories": [ "communication" ], "tags": [ "Call", "arrow", "mobile", "received", "device" ], },
  { "name": "call_split", "categories": [ "communication" ], "tags": [ "Call", "split", "arrow", "mobile", "device" ], },
  { "name": "call_to_action", "categories": [ "av" ], "tags": [ "call", "CTA", "notification", "bar", "alert", "action", "information", "to", "message", "info" ], },
  { "name": "camera", "categories": [ "image" ], "tags": [], },
  { "name": "camera_alt", "categories": [ "image" ], "tags": [], },
  { "name": "camera_enhance", "categories": [ "action" ], "tags": [ "photo", "camera", "lens", "picture", "enhance", "quality" ], },
  { "name": "camera_front", "categories": [ "image" ], "tags": [], },
  { "name": "camera_rear", "categories": [ "image" ], "tags": [], },
  { "name": "camera_roll", "categories": [ "image" ], "tags": [], },
  { "name": "cancel", "categories": [ "navigation" ], "tags": [], },
  { "name": "cancel_presentation", "categories": [ "communication" ], "tags": [ "cancel", "x", "screen", "share", "Presentation", "present", "close", "device" ], },
  { "name": "cancel_schedule_send", "categories": [ "action" ], "tags": [ "cancel", "schedule", "mail", "send" ], },
  { "name": "card_giftcard", "categories": [ "action" ], "tags": [ "gift", "giftcard", "certificate", "bill", "pay", "dollars", "commerce", "cart", "balance", "money", "online", "currency", "payment", "credit", "present", "cash", "account", "card", "coin", "shopping" ], },
  { "name": "card_membership", "categories": [ "action" ], "tags": [ "certificate", "bill", "pay", "dollars", "membership", "subscription", "commerce", "cart", "money", "online", "currency", "payment", "credit", "cash", "card", "coin", "shopping" ], },
  { "name": "card_travel", "categories": [ "action" ], "tags": [ "bill", "pay", "dollars", "membership", "commerce", "cart", "miles", "trip", "money", "online", "currency", "payment", "credit", "travel", "cash", "card", "coin", "shopping" ], },
  { "name": "casino", "categories": [ "places" ], "tags": [], },
  { "name": "cast", "categories": [ "hardware" ], "tags": [], },
  { "name": "cast_connected", "categories": [ "hardware" ], "tags": [], },
  { "name": "category", "categories": [ "maps" ], "tags": [ "square", "product", "cateogories", "collection", "sort", "items", "cirlce", "triangle" ], },
  { "name": "center_focus_strong", "categories": [ "image" ], "tags": [], },
  { "name": "center_focus_weak", "categories": [ "image" ], "tags": [], },
  { "name": "change_history", "categories": [ "action" ], "tags": [ "shape", "change", "history", "triangle" ], },
  { "name": "chat", "categories": [ "communication" ], "tags": [ "bubble", "speech", "Chat", "talk", "text", "message" ], },
  { "name": "chat_bubble", "categories": [ "communication" ], "tags": [ "bubble", "speech", "Chat", "talk", "text", "message" ], },
  { "name": "chat_bubble_outline", "categories": [ "communication" ], "tags": [ "bubble", "speech", "Chat", "talk", "text", "message" ], },
  { "name": "check", "categories": [ "navigation" ], "tags": [], },
  { "name": "check_box", "categories": [ "toggle" ], "tags": [], },
  { "name": "check_box_outline_blank", "categories": [ "toggle" ], "tags": [], },
  { "name": "check_circle", "categories": [ "action" ], "tags": [ "finished", "check", "circle", "complete", "done" ], },
  { "name": "check_circle_outline", "categories": [ "action" ], "tags": [ "outline", "finished", "check", "circle", "complete", "done" ], },
  { "name": "chevron_left", "categories": [ "navigation" ], "tags": [], },
  { "name": "chevron_right", "categories": [ "navigation" ], "tags": [], },
  { "name": "child_care", "categories": [ "places" ], "tags": [], },
  { "name": "child_friendly", "categories": [ "places" ], "tags": [], },
  { "name": "chrome_reader_mode", "categories": [ "action" ], "tags": [ "mode", "read", "chrome", "reader", "text" ], },
  { "name": "class", "categories": [ "action" ], "tags": [ "bookmark", "read", "book", "reading", "class" ], },
  { "name": "clear", "categories": [ "content" ], "tags": [ "cancel", "exit", "erase", "correct", "clear", "x", "back", "delete" ], },
  { "name": "clear_all", "categories": [ "communication" ], "tags": [ "all", "document", "format", "doc", "list", "Clear" ], },
  { "name": "close", "categories": [ "navigation" ], "tags": [], },
  { "name": "closed_caption", "categories": [ "av" ], "tags": [ "cc", "accessible", "subtitles", "symbol", "launguage", "caption", "alphabet", "decoder", "type", "character", "Closed", "letter", "subtitle", "text", "font" ], },
  { "name": "cloud", "categories": [ "file" ], "tags": [], },
  { "name": "cloud_circle", "categories": [ "file" ], "tags": [], },
  { "name": "cloud_done", "categories": [ "file" ], "tags": [], },
  { "name": "cloud_download", "categories": [ "file" ], "tags": [], },
  { "name": "cloud_off", "categories": [ "file" ], "tags": [], },
  { "name": "cloud_queue", "categories": [ "file" ], "tags": [], },
  { "name": "cloud_upload", "categories": [ "file" ], "tags": [], },
  { "name": "code", "categories": [ "action" ], "tags": [ "css", "code", "developer", "html", "develop", "engineering", "engineer", "platform", "brackets" ], },
  { "name": "collections", "categories": [ "image" ], "tags": [], },
  { "name": "collections_bookmark", "categories": [ "image" ], "tags": [], },
  { "name": "color_lens", "categories": [ "image" ], "tags": [], },
  { "name": "colorize", "categories": [ "image" ], "tags": [], },
  { "name": "comment", "categories": [ "communication" ], "tags": [ "Comment", "note", "bubble", "document", "doc" ], },
  { "name": "commute", "categories": [ "action" ], "tags": [ "automobile", "cars", "trip", "maps", "public", "car", "work", "cummute", "direction", "train", "transportation", "vehicle" ], },
  { "name": "compare", "categories": [ "image" ], "tags": [], },
  { "name": "compare_arrows", "categories": [ "action" ], "tags": [ "compare", "arrow", "arrows", "facing", "pointing" ], },
  { "name": "compass_calibration", "categories": [ "maps" ], "tags": [ "compass", "refresh", "location", "calibration" ], },
  { "name": "computer", "categories": [ "hardware" ], "tags": [], },
  { "name": "confirmation_number", "categories": [ "notification" ], "tags": [], },
  { "name": "contact_mail", "categories": [ "communication" ], "tags": [ "face", "address", "mail", "person", "profile", "information", "human", "people", "user", "account", "Contact", "info" ], },
  { "name": "contact_phone", "categories": [ "communication" ], "tags": [ "number", "face", "phone", "person", "profile", "information", "human", "people", "user", "account", "Contact", "info" ], },
  { "name": "contact_support", "categories": [ "action" ], "tags": [ "help", "alert", "bubble", "speech", "information", "question mark", "support", "announcement", "info" ], },
  { "name": "contactless", "categories": [ "action" ], "tags": [ "wifi", "contactless", "contact", "payment", "credit", "cash", "signal", "transaction" ], },
  { "name": "contacts", "categories": [ "communication" ], "tags": [ "address", "profile", "people", "Contact", "number", "face", "phone", "person", "information", "human", "user", "account", "info" ], },
  { "name": "control_camera", "categories": [ "av" ], "tags": [ "Control", "move", "arrow", "left", "adjust", "arrows", "right", "camera", "direction" ], },
  { "name": "control_point", "categories": [ "image" ], "tags": [], },
  { "name": "control_point_duplicate", "categories": [ "image" ], "tags": [], },
  { "name": "copyright", "categories": [ "action" ], "tags": [ "symbol", "character", "copyright", "C", "letter", "alphabet", "text", "type", "emblem", "font" ], },
  { "name": "create", "categories": [ "content" ], "tags": [ "Add", "input", "new", "compose", "edit", "create", "pencil", "editing" ], },
  { "name": "create_new_folder", "categories": [ "file" ], "tags": [], },
  { "name": "credit_card", "categories": [ "action" ], "tags": [ "charge", "money", "pay", "information", "payment", "credit", "card", "info" ], },
  { "name": "crop", "categories": [ "image" ], "tags": [], },
  { "name": "crop_16_9", "categories": [ "image" ], "tags": [], },
  { "name": "crop_3_2", "categories": [ "image" ], "tags": [], },
  { "name": "crop_5_4", "categories": [ "image" ], "tags": [], },
  { "name": "crop_7_5", "categories": [ "image" ], "tags": [], },
  { "name": "crop_din", "categories": [ "image" ], "tags": [], },
  { "name": "crop_free", "categories": [ "image" ], "tags": [], },
  { "name": "crop_landscape", "categories": [ "image" ], "tags": [], },
  { "name": "crop_original", "categories": [ "image" ], "tags": [], },
  { "name": "crop_portrait", "categories": [ "image" ], "tags": [], },
  { "name": "crop_rotate", "categories": [ "image" ], "tags": [], },
  { "name": "crop_square", "categories": [ "image" ], "tags": [], },
  { "name": "dashboard", "categories": [ "action" ], "tags": [ "square", "cards", "shapes", "rectangle", "dashboard" ], },
  { "name": "data_usage", "categories": [ "device" ], "tags": [], },
  { "name": "date_range", "categories": [ "action" ], "tags": [ "calendar", "date", "remember", "week", "month", "reminder", "today", "event", "day" ], },
  { "name": "deck", "categories": [ "social" ], "tags": [], },
  { "name": "dehaze", "categories": [ "image" ], "tags": [], },
  { "name": "delete", "categories": [ "action" ], "tags": [ "can", "bin", "garbage", "delete", "remove", "trash" ], },
  { "name": "delete_forever", "categories": [ "action" ], "tags": [ "can", "bin", "x", "garbage", "delete", "remove", "trash" ], },
  { "name": "delete_outline", "categories": [ "action" ], "tags": [ "can", "bin", "garbage", "delete", "remove", "trash" ], },
  { "name": "delete_sweep", "categories": [ "content" ], "tags": [ "can", "delete", "remove", "trash" ], },
  { "name": "departure_board", "categories": [ "maps" ], "tags": [ "automobile", "bus", "cars", "schedule", "maps", "public", "car", "clock", "time", "transportation", "vehicle" ], },
  { "name": "description", "categories": [ "action" ], "tags": [ "file", "notes", "document", "writing", "description", "doc", "information", "text", "article", "content" ], },
  { "name": "desktop_access_disabled", "categories": [ "communication" ], "tags": [ "access", "Desktop", "screen", "disabled", "monitor", "slash", "device", "enabled", "off", "on" ], },
  { "name": "desktop_mac", "categories": [ "hardware" ], "tags": [], },
  { "name": "desktop_windows", "categories": [ "hardware" ], "tags": [], },
  { "name": "details", "categories": [ "image" ], "tags": [], },
  { "name": "developer_board", "categories": [ "hardware" ], "tags": [], },
  { "name": "developer_mode", "categories": [ "device" ], "tags": [ "development", "code", "OS", "mobile", "iOS", "engineer", "Android", "tablet", "phone", "bracket", "developer", "device", "hardware" ], },
  { "name": "device_hub", "categories": [ "hardware" ], "tags": [], },
  { "name": "device_unknown", "categories": [ "hardware" ], "tags": [], },
  { "name": "devices", "categories": [ "device" ], "tags": [ "wearable", "OS", "mobile", "monitor", "iOS", "laptop", "Android", "tablet", "computer", "desktop", "phone", "watch", "web", "device", "hardware" ], },
  { "name": "devices_other", "categories": [ "hardware" ], "tags": [], },
  { "name": "dialer_sip", "categories": [ "communication" ], "tags": [ "over", "voice", "symbol", "Dialer", "session", "alphabet", "type", "call", "routing", "character", "protocol", "initiation", "phone", "letter", "sip", "text", "internet", "font" ], },
  { "name": "dialpad", "categories": [ "communication" ], "tags": [ "Dial", "call", "number", "pad", "numbers", "device" ], },
  { "name": "directions", "categories": [ "maps" ], "tags": [ "route", "maps", "arrow", "sign", "right", "direction", "traffic" ], },
  { "name": "directions_bike", "categories": [ "maps" ], "tags": [ "bicycle", "maps", "bike", "transportation", "vehicle", "automobile", "cars", "route", "public", "car", "person", "human", "direction" ], },
  { "name": "directions_boat", "categories": [ "maps" ], "tags": [ "automobile", "cars", "maps", "public", "car", "ferry", "boat", "direction", "transportation", "vehicle" ], },
  { "name": "directions_bus", "categories": [ "maps" ], "tags": [ "automobile", "bus", "cars", "maps", "public", "car", "transportation", "vehicle" ], },
  { "name": "directions_car", "categories": [ "maps" ], "tags": [ "automobile", "cars", "maps", "public", "car", "direction", "transportation", "vehicle" ], },
  { "name": "directions_railway", "categories": [ "maps" ], "tags": [ "automobile", "cars", "maps", "public", "car", "rail", "direction", "train", "transportation", "vehicle" ], },
  { "name": "directions_run", "categories": [ "maps" ], "tags": [ "route", "person", "jogging", "run", "body", "human", "people", "walk", "direction" ], },
  { "name": "directions_subway", "categories": [ "maps" ], "tags": [ "automobile", "cars", "subway", "maps", "public", "car", "rail", "direction", "train", "transportation", "vehicle" ], },
  { "name": "directions_transit", "categories": [ "maps" ], "tags": [ "automobile", "cars", "subway", "maps", "public", "car", "rail", "direction", "train", "transportation", "vehicle" ], },
  { "name": "directions_walk", "categories": [ "maps" ], "tags": [ "route", "person", "jogging", "run", "body", "human", "people", "walk", "direction" ], },
  { "name": "disc_full", "categories": [ "notification" ], "tags": [], },
  { "name": "dns", "categories": [ "action" ], "tags": [ "lookup", "server", "address", "domain", "ip", "dns", "name", "information", "list", "bars" ], },
  { "name": "dock", "categories": [ "hardware" ], "tags": [], },
  { "name": "domain", "categories": [ "social" ], "tags": [], },
  { "name": "domain_disabled", "categories": [ "communication" ], "tags": [ "website", "web", "disabled", "slash", "Domain", "building", "enabled", "internet", "off", "on" ], },
  { "name": "done", "categories": [ "action" ], "tags": [ "finished", "check", "complete", "done", "mark" ], },
  { "name": "done_all", "categories": [ "action" ], "tags": [ "multiple", "finished", "check", "complete", "done", "mark" ], },
  { "name": "done_outline", "categories": [ "action" ], "tags": [ "outline", "finished", "check", "complete", "done", "mark" ], },
  { "name": "donut_large", "categories": [ "action" ], "tags": [ "donut", "inprogress", "complete", "graph" ], },
  { "name": "donut_small", "categories": [ "action" ], "tags": [ "donut", "inprogress", "complete", "graph" ], },
  { "name": "double_arrow", "categories": [ "navigation" ], "tags": [], },
  { "name": "drafts", "categories": [ "content" ], "tags": [ "file", "read", "mail", "document", "draft", "letter", "drafts", "email" ], },
  { "name": "drag_handle", "categories": [ "editor" ], "tags": [ "move", "ui", "handle", "drag", "menu" ], },
  { "name": "drag_indicator", "categories": [ "action" ], "tags": [ "drop", "indicator", "move", "shape", "dots", "shift", "drag", "circle" ], },
  { "name": "drive_eta", "categories": [ "notification" ], "tags": [], },
  { "name": "duo", "categories": [ "communication" ], "tags": [ "call", "conference", "chat", "video", "device", "Duo" ], },
  { "name": "dvr", "categories": [ "device" ], "tags": [ "digital", "dvr", "computer", "recorder", "tv", "vidio", "electronic", "audio", "device" ], },
  { "name": "dynamic_feed", "categories": [ "content" ], "tags": [ "feed", "post", "multiple", "dynamic" ], },
  { "name": "eco", "categories": [ "action" ], "tags": [ "eco", "ecocomical", "green", "nature", "leaf" ], },
  { "name": "edit", "categories": [ "image" ], "tags": [], },
  { "name": "edit_attributes", "categories": [ "maps" ], "tags": [ "edit", "attribution", "check" ], },
  { "name": "edit_location", "categories": [ "maps" ], "tags": [ "maps", "pin", "stop", "edit", "destination", "pen", "location", "place", "pencil", "direction" ], },
  { "name": "eject", "categories": [ "action" ], "tags": [ "eject", "remove", "triangle", "player" ], },
  { "name": "email", "categories": [ "communication" ], "tags": [ "note", "receive", "Email", "mail", "post", "message", "send", "write" ], },
  { "name": "emoji_emotions", "categories": [ "social" ], "tags": [], },
  { "name": "emoji_events", "categories": [ "social" ], "tags": [], },
  { "name": "emoji_flags", "categories": [ "social" ], "tags": [], },
  { "name": "emoji_food_beverage", "categories": [ "social" ], "tags": [], },
  { "name": "emoji_nature", "categories": [ "social" ], "tags": [], },
  { "name": "emoji_objects", "categories": [ "social" ], "tags": [], },
  { "name": "emoji_people", "categories": [ "social" ], "tags": [], },
  { "name": "emoji_symbols", "categories": [ "social" ], "tags": [], },
  { "name": "emoji_transportation", "categories": [ "social" ], "tags": [], },
  { "name": "enhanced_encryption", "categories": [ "notification" ], "tags": [], },
  { "name": "equalizer", "categories": [ "av" ], "tags": [ "volume", "music", "static", "sound", "noise", "adjustment", "Equalizer" ], },
  { "name": "error", "categories": [ "alert" ], "tags": [ "!", "notification", "symbol", "alert", "attention", "warning", "exclamation", "circle", "danger", "error", "caution", "mark" ], },
  { "name": "error_outline", "categories": [ "alert" ], "tags": [ "!", "notification", "symbol", "alert", "attention", "warning", "exclamation", "circle", "danger", "error", "caution", "mark" ], },
  { "name": "euro", "categories": [ "image" ], "tags": [], },
  { "name": "euro_symbol", "categories": [ "action" ], "tags": [ "symbol", "euro", "money", "currency", "dollar" ], },
  { "name": "ev_station", "categories": [ "maps" ], "tags": [ "automobile", "cars", "ev", "maps", "car", "electric", "charging", "station", "electricity", "transportation", "vehicle" ], },
  { "name": "event", "categories": [ "action" ], "tags": [ "calendar", "date", "remember", "week", "month", "reminder", "today", "range", "event", "day", "mark" ], },
  { "name": "event_available", "categories": [ "notification" ], "tags": [], },
  { "name": "event_busy", "categories": [ "notification" ], "tags": [], },
  { "name": "event_note", "categories": [ "notification" ], "tags": [], },
  { "name": "event_seat", "categories": [ "action" ], "tags": [ "seat", "chair", "reservation", "assigned", "section", "row", "event", "assign", "sit" ], },
  { "name": "exit_to_app", "categories": [ "action" ], "tags": [ "exit", "arrow", "leave", "back", "pointing", "right" ], },
  { "name": "expand_less", "categories": [ "navigation" ], "tags": [], },
  { "name": "expand_more", "categories": [ "navigation" ], "tags": [], },
  { "name": "explicit", "categories": [ "av" ], "tags": [ "symbol", "character", "e", "letter", "lanuage", "Explicit", "alphabet", "text", "adult", "type", "content", "font" ], },
  { "name": "explore", "categories": [ "action" ], "tags": [ "explore", "compass", "destination", "location", "needle", "travel", "map" ], },
  { "name": "explore_off", "categories": [ "action" ], "tags": [ "explore", "compass", "destination", "disabled", "location", "slash", "needle", "travel", "map", "enabled", "off", "on" ], },
  { "name": "exposure", "categories": [ "image" ], "tags": [], },
  { "name": "exposure_neg_1", "categories": [ "image" ], "tags": [], },
  { "name": "exposure_neg_2", "categories": [ "image" ], "tags": [], },
  { "name": "exposure_plus_1", "categories": [ "image" ], "tags": [], },
  { "name": "exposure_plus_2", "categories": [ "image" ], "tags": [], },
  { "name": "exposure_zero", "categories": [ "image" ], "tags": [], },
  { "name": "extension", "categories": [ "action" ], "tags": [ "extension", "shape", "piece", "puzzle", "jigsaw", "extended" ], },
  { "name": "face", "categories": [ "action" ], "tags": [ "thumbnail", "unlock", "emoji", "profile", "recognition", "login", "eyes", "people", "face", "logout", "security", "person", "lock", "human", "user", "account" ], },
  { "name": "fast_forward", "categories": [ "av" ], "tags": [ "music", "forward", "control", "time", "video", "Fast", "speed" ], },
  { "name": "fast_rewind", "categories": [ "av" ], "tags": [ "music", "rewind", "control", "time", "video", "Fast", "speed" ], },
  { "name": "fastfood", "categories": [ "maps" ], "tags": [ "meal", "drink", "fastfood", "food", "hamburger" ], },
  { "name": "favorite", "categories": [ "action" ], "tags": [ "love", "remember", "shape", "like", "save", "favorite", "heart" ], },
  { "name": "favorite_border", "categories": [ "action" ], "tags": [ "love", "remember", "outline", "shape", "like", "save", "favorite", "heart" ], },
  { "name": "featured_play_list", "categories": [ "av" ], "tags": [ "play", "music", "playlist", "highlighted", "Featured", "collection", "recommended" ], },
  { "name": "featured_video", "categories": [ "av" ], "tags": [ "watch", "advertised", "highlighted", "Featured", "video", "recommended" ], },
  { "name": "feedback", "categories": [ "action" ], "tags": [ "feedback", "alert", "bubble", "speech", "comment", "exclamation", "announcement" ], },
  { "name": "fiber_dvr", "categories": [ "av" ], "tags": [ "digital", "dvr", "symbol", "electronics", "recorder", "tv", "Fiber", "alphabet", "video", "type", "network", "character", "letter", "text", "font" ], },
  { "name": "fiber_manual_record", "categories": [ "av" ], "tags": [ "play", "watch", "Fiber", "record", "dot", "circle", "manual" ], },
  { "name": "fiber_new", "categories": [ "av" ], "tags": [ "new", "symbol", "character", "Fiber", "letter", "alphabet", "text", "type", "font", "network" ], },
  { "name": "fiber_pin", "categories": [ "av" ], "tags": [ "symbol", "character", "pin", "Fiber", "letter", "alphabet", "text", "type", "font", "network" ], },
  { "name": "fiber_smart_record", "categories": [ "av" ], "tags": [ "play", "watch", "Fiber", "record", "smart" ], },
  { "name": "file_copy", "categories": [ "content" ], "tags": [ "cut", "file", "past", "document", "multiple", "doc", "copy", "duplicate", "content" ], },
  { "name": "filter", "categories": [ "image" ], "tags": [ "filter", "images", "mountain", "photo", "collection", "media" ], },
  { "name": "filter_1", "categories": [ "image" ], "tags": [], },
  { "name": "filter_2", "categories": [ "image" ], "tags": [], },
  { "name": "filter_3", "categories": [ "image" ], "tags": [], },
  { "name": "filter_4", "categories": [ "image" ], "tags": [], },
  { "name": "filter_5", "categories": [ "image" ], "tags": [], },
  { "name": "filter_6", "categories": [ "image" ], "tags": [], },
  { "name": "filter_7", "categories": [ "image" ], "tags": [], },
  { "name": "filter_8", "categories": [ "image" ], "tags": [], },
  { "name": "filter_9", "categories": [ "image" ], "tags": [], },
  { "name": "filter_9_plus", "categories": [ "image" ], "tags": [], },
  { "name": "filter_b_and_w", "categories": [ "image" ], "tags": [], },
  { "name": "filter_center_focus", "categories": [ "image" ], "tags": [], },
  { "name": "filter_drama", "categories": [ "image" ], "tags": [], },
  { "name": "filter_frames", "categories": [ "image" ], "tags": [], },
  { "name": "filter_hdr", "categories": [ "image" ], "tags": [], },
  { "name": "filter_list", "categories": [ "content" ], "tags": [ "filter", "organize", "find", "sort", "list" ], },
  { "name": "filter_none", "categories": [ "image" ], "tags": [], },
  { "name": "filter_tilt_shift", "categories": [ "image" ], "tags": [], },
  { "name": "filter_vintage", "categories": [ "image" ], "tags": [], },
  { "name": "find_in_page", "categories": [ "action" ], "tags": [ "search", "see", "paper", "find", "document", "doc", "magnifying glass", "page", "look" ], },
  { "name": "find_replace", "categories": [ "action" ], "tags": [ "rotate", "search", "see", "arrow", "find", "arrows", "replace", "inprogress", "refresh", "magnifying glass", "look" ], },
  { "name": "fingerprint", "categories": [ "action" ], "tags": [ "finger", "print", "identification", "identity", "reader", "fingerprint", "thumbprint", "id" ], },
  { "name": "fireplace", "categories": [ "social" ], "tags": [], },
  { "name": "first_page", "categories": [ "navigation" ], "tags": [], },
  { "name": "fitness_center", "categories": [ "places" ], "tags": [], },
  { "name": "flag", "categories": [ "content" ], "tags": [ "country", "flag", "goal", "nation", "report", "start", "mark" ], },
  { "name": "flare", "categories": [ "image" ], "tags": [], },
  { "name": "flash_auto", "categories": [ "image" ], "tags": [], },
  { "name": "flash_off", "categories": [ "image" ], "tags": [], },
  { "name": "flash_on", "categories": [ "image" ], "tags": [], },
  { "name": "flight", "categories": [ "maps" ], "tags": [ "plane", "flight", "trip", "airplane", "air", "travel", "airport", "transportation" ], },
  { "name": "flight_land", "categories": [ "action" ], "tags": [ "plane", "flight", "fly", "landing", "arrival", "land", "arriving", "travel", "transportation" ], },
  { "name": "flight_takeoff", "categories": [ "action" ], "tags": [ "departing", "plane", "flight", "fly", "landing", "departed", "land", "travel", "transportation" ], },
  { "name": "flip", "categories": [ "image" ], "tags": [], },
  { "name": "flip_camera_android", "categories": [ "image" ], "tags": [], },
  { "name": "flip_camera_ios", "categories": [ "image" ], "tags": [], },
  { "name": "flip_to_back", "categories": [ "action" ], "tags": [ "arrangement", "move", "arrange", "back", "front", "sort", "flip", "order" ], },
  { "name": "flip_to_front", "categories": [ "action" ], "tags": [ "arrangement", "move", "arrange", "back", "front", "sort", "flip", "order" ], },
  { "name": "folder", "categories": [ "file" ], "tags": [], },
  { "name": "folder_open", "categories": [ "file" ], "tags": [], },
  { "name": "folder_shared", "categories": [ "file" ], "tags": [], },
  { "name": "folder_special", "categories": [ "notification" ], "tags": [], },
  { "name": "font_download", "categories": [ "content" ], "tags": [ "A", "square", "symbol", "character", "download", "letter", "save", "alphabet", "text", "type", "font" ], },
  { "name": "format_align_center", "categories": [ "editor" ], "tags": [ "editor", "edit", "writing", "doc", "sheet", "text", "align", "alignment", "type", "editing", "speadsheet" ], },
  { "name": "format_align_justify", "categories": [ "editor" ], "tags": [ "editor", "edit", "writing", "doc", "sheet", "text", "align", "alignment", "type", "editing", "speadsheet" ], },
  { "name": "format_align_left", "categories": [ "editor" ], "tags": [ "editor", "edit", "writing", "doc", "sheet", "text", "align", "alignment", "type", "editing", "speadsheet" ], },
  { "name": "format_align_right", "categories": [ "editor" ], "tags": [ "editor", "edit", "writing", "doc", "sheet", "text", "align", "alignment", "type", "editing", "speadsheet" ], },
  { "name": "format_bold", "categories": [ "editor" ], "tags": [ "editor", "symbol", "edit", "alphabet", "type", "editing", "character", "letter", "writing", "doc", "sheet", "styles", "text", "font", "speadsheet" ], },
  { "name": "format_clear", "categories": [ "editor" ], "tags": [ "editor", "symbol", "edit", "clear", "alphabet", "type", "enabled", "off", "editing", "character", "letter", "writing", "doc", "disabled", "sheet", "slash", "style", "text", "font", "on", "speadsheet" ], },
  { "name": "format_color_reset", "categories": [ "editor" ], "tags": [ "editor", "color", "edit", "clear", "paint", "fill", "type", "enabled", "off", "editing", "writing", "doc", "reset", "disabled", "sheet", "slash", "style", "text", "on", "speadsheet" ], },
  { "name": "format_indent_decrease", "categories": [ "editor" ], "tags": [ "editor", "paragraph", "edit", "indent", "align", "type", "editing", "indentation", "writing", "doc", "sheet", "text", "alignment", "speadsheet" ], },
  { "name": "format_indent_increase", "categories": [ "editor" ], "tags": [ "editor", "paragraph", "edit", "indent", "align", "type", "editing", "indentation", "writing", "doc", "sheet", "text", "alignment", "speadsheet" ], },
  { "name": "format_italic", "categories": [ "editor" ], "tags": [ "editor", "symbol", "edit", "alphabet", "type", "editing", "character", "letter", "writing", "doc", "sheet", "style", "text", "font", "speadsheet" ], },
  { "name": "format_line_spacing", "categories": [ "editor" ], "tags": [ "editor", "edit", "writing", "doc", "sheet", "text", "align", "alignment", "type", "editing", "speadsheet" ], },
  { "name": "format_list_bulleted", "categories": [ "editor" ], "tags": [ "editor", "edit", "align", "list", "type", "editing", "writing", "doc", "sheet", "text", "alignment", "bullet", "speadsheet" ], },
  { "name": "format_list_numbered", "categories": [ "editor" ], "tags": [ "editor", "symbol", "edit", "align", "list", "type", "editing", "number", "writing", "doc", "sheet", "text", "alignment", "digit", "speadsheet" ], },
  { "name": "format_list_numbered_rtl", "categories": [ "editor" ], "tags": [ "editor", "symbol", "edit", "rtl", "align", "list", "type", "editing", "number", "writing", "doc", "sheet", "text", "alignment", "digit", "speadsheet" ], },
  { "name": "format_paint", "categories": [ "editor" ], "tags": [ "editor", "color", "edit", "paint", "fill", "type", "editing", "writing", "doc", "sheet", "style", "text", "speadsheet" ], },
  { "name": "format_quote", "categories": [ "editor" ], "tags": [ "editor", "quote", "edit", "writing", "doc", "sheet", "text", "type", "quotation", "editing", "speadsheet" ], },
  { "name": "format_shapes", "categories": [ "editor" ], "tags": [ "editor", "symbol", "color", "edit", "format", "paint", "alphabet", "fill", "type", "editing", "character", "letter", "writing", "doc", "sheet", "style", "text", "font", "speadsheet" ], },
  { "name": "format_size", "categories": [ "editor" ], "tags": [ "editor", "symbol", "color", "edit", "format", "paint", "alphabet", "fill", "type", "editing", "character", "letter", "writing", "doc", "sheet", "style", "text", "font", "speadsheet" ], },
  { "name": "format_strikethrough", "categories": [ "editor" ], "tags": [ "editor", "symbol", "edit", "format", "alphabet", "type", "editing", "character", "size", "letter", "writing", "doc", "sheet", "style", "strikethrough", "text", "font", "speadsheet" ], },
  { "name": "format_textdirection_l_to_r", "categories": [ "editor" ], "tags": [ "editor", "edit", "writing", "doc", "sheet", "text", "align", "alignment", "type", "editing", "speadsheet" ], },
  { "name": "format_textdirection_r_to_l", "categories": [ "editor" ], "tags": [ "editor", "edit", "writing", "doc", "sheet", "text", "align", "alignment", "type", "editing", "speadsheet" ], },
  { "name": "format_underlined", "categories": [ "editor" ], "tags": [ "editor", "symbol", "edit", "line", "alphabet", "type", "editing", "character", "letter", "writing", "doc", "sheet", "style", "text", "under", "font", "speadsheet" ], },
  { "name": "forum", "categories": [ "communication" ], "tags": [ "hub", "bubble", "speech", "talk", "community", "conversation", "Forum" ], },
  { "name": "forward", "categories": [ "content" ], "tags": [ "mail", "arrow", "forward", "playback", "right", "message", "sent" ], },
  { "name": "forward_10", "categories": [ "av" ], "tags": [ "number", "symbol", "controls", "music", "arrow", "forward", "arrows", "control", "video", "Fast", "10", "digit" ], },
  { "name": "forward_30", "categories": [ "av" ], "tags": [ "symbol", "controls", "arrow", "forward", "arrows", "control", "video", "number", "music", "30", "Fast", "10", "digit" ], },
  { "name": "forward_5", "categories": [ "av" ], "tags": [ "symbol", "controls", "arrow", "forward", "arrows", "control", "video", "number", "music", "5", "Fast", "10", "digit" ], },
  { "name": "free_breakfast", "categories": [ "places" ], "tags": [], },
  { "name": "fullscreen", "categories": [ "navigation" ], "tags": [], },
  { "name": "fullscreen_exit", "categories": [ "navigation" ], "tags": [], },
  { "name": "functions", "categories": [ "editor" ], "tags": [ "average", "editor", "edit", "count", "sum", "type", "editing", "writing", "doc", "sheet", "style", "calculate", "math", "text", "speadsheet" ], },
  { "name": "g_translate", "categories": [ "action" ], "tags": [ "speaking", "speech", "translator", "words", "logo", "google", "language", "emblem", "mark", "translate" ], },
  { "name": "gamepad", "categories": [ "hardware" ], "tags": [], },
  { "name": "games", "categories": [ "av" ], "tags": [ "controller", "move", "arrow", "left", "adjust", "Games", "arrows", "control", "right", "direction" ], },
  { "name": "gavel", "categories": [ "action" ], "tags": [ "government", "law", "police", "document", "official", "rule", "gavel", "mallet", "rules", "judge", "court" ], },
  { "name": "gesture", "categories": [ "content" ], "tags": [ "finger", "motion", "Gesture", "drawing", "gestures", "hand" ], },
  { "name": "get_app", "categories": [ "action" ], "tags": [ "download", "arrow", "get", "retrieve", "pointing", "down" ], },
  { "name": "gif", "categories": [ "action" ], "tags": [ "symbol", "character", "gif", "bitmap", "letter", "animated", "graphics interchange format", "alphabet", "text", "type", "animation", "font" ], },
  { "name": "golf_course", "categories": [ "places" ], "tags": [], },
  { "name": "gps_fixed", "categories": [ "device" ], "tags": [ "pointer", "maps", "pin", "stop", "destination", "location", "place", "tracking", "direction" ], },
  { "name": "gps_not_fixed", "categories": [ "device" ], "tags": [ "pointer", "maps", "pin", "stop", "destination", "location", "place", "tracking", "direction" ], },
  { "name": "gps_off", "categories": [ "device" ], "tags": [ "pointer", "maps", "destination", "tracking", "enabled", "off", "pin", "stop", "disabled", "location", "slash", "place", "direction", "on" ], },
  { "name": "grade", "categories": [ "action" ], "tags": [ "rated", "shape", "star", "rate", "grade", "rating", "favorite", "likes" ], },
  { "name": "gradient", "categories": [ "image" ], "tags": [], },
  { "name": "grain", "categories": [ "image" ], "tags": [], },
  { "name": "graphic_eq", "categories": [ "device" ], "tags": [ "voice", "music", "equalizer", "sound", "recording", "audio", "graphic" ], },
  { "name": "grid_off", "categories": [ "image" ], "tags": [], },
  { "name": "grid_on", "categories": [ "image" ], "tags": [], },
  { "name": "group", "categories": [ "social" ], "tags": [], },
  { "name": "group_add", "categories": [ "social" ], "tags": [], },
  { "name": "group_work", "categories": [ "action" ], "tags": [ "partnership", "alliance", "teamwork", "collaboration", "work", "together", "group" ], },
  { "name": "hd", "categories": [ "av" ], "tags": [ "symbol", "tv", "movie", "alphabet", "type", "resolution", "movies", "character", "high", "letter", "definition", "text", "Hd", "font" ], },
  { "name": "hdr_off", "categories": [ "image" ], "tags": [], },
  { "name": "hdr_on", "categories": [ "image" ], "tags": [], },
  { "name": "hdr_strong", "categories": [ "image" ], "tags": [], },
  { "name": "hdr_weak", "categories": [ "image" ], "tags": [], },
  { "name": "headset", "categories": [ "hardware" ], "tags": [], },
  { "name": "headset_mic", "categories": [ "hardware" ], "tags": [], },
  { "name": "healing", "categories": [ "image" ], "tags": [], },
  { "name": "hearing", "categories": [ "av" ], "tags": [ "accessible", "help", "Hearing", "impaired", "accessibility", "handicap", "sound", "hearing", "aid", "listen" ], },
  { "name": "height", "categories": [ "editor" ], "tags": [ "editor", "color", "edit", "format", "paint", "fill", "type", "editing", "writing", "doc", "sheet", "style", "text", "speadsheet" ], },
  { "name": "help", "categories": [ "action" ], "tags": [ "help", "symbol", "alert", "information", "question mark", "support", "?", "announcement", "info" ], },
  { "name": "help_outline", "categories": [ "action" ], "tags": [ "help", "symbol", "outline", "alert", "information", "question mark", "support", "?", "announcement", "info" ], },
  { "name": "high_quality", "categories": [ "av" ], "tags": [ "symbol", "tv", "movie", "Hq", "alphabet", "type", "resolution", "quality", "movies", "character", "letter", "definition", "text", "font" ], },
  { "name": "highlight", "categories": [ "editor" ], "tags": [ "editor", "color", "edit", "format", "paint", "fill", "type", "editing", "highlight", "writing", "doc", "sheet", "style", "text", "emphasize", "speadsheet" ], },
  { "name": "highlight_off", "categories": [ "action" ], "tags": [ "highlight", "stop", "x", "focus", "circle", "click", "close", "off", "target" ], },
  { "name": "history", "categories": [ "action" ], "tags": [ "rotate", "arrow", "back", "refresh", "clock", "history", "time", "reverse", "backwards" ], },
  { "name": "home", "categories": [ "action" ], "tags": [ "unit", "address", "place", "residence", "house", "building", "structure", "home" ], },
  { "name": "home_work", "categories": [ "navigation" ], "tags": [], },
  { "name": "horizontal_split", "categories": [ "action" ], "tags": [ "horizontal", "split", "stacked", "bars" ], },
  { "name": "hot_tub", "categories": [ "places" ], "tags": [], },
  { "name": "hotel", "categories": [ "maps" ], "tags": [ "sleep", "trip", "person", "hotel", "body", "travel", "human", "people", "stay" ], },
  { "name": "hourglass_empty", "categories": [ "action" ], "tags": [ "wait", "waiting", "minutes", "hourglass", "countdown", "time", "loading", "empty", "minute" ], },
  { "name": "hourglass_full", "categories": [ "action" ], "tags": [ "wait", "waiting", "minutes", "hourglass", "countdown", "time", "loading", "full", "minute" ], },
  { "name": "house", "categories": [ "places" ], "tags": [], },
  { "name": "how_to_reg", "categories": [ "content" ], "tags": [ "ballot", "election", "profile", "check", "poll", "people", "registeration", "face", "person", "human", "user", "vote", "account", "register" ], },
  { "name": "how_to_vote", "categories": [ "content" ], "tags": [ "ballot", "election", "poll", "vote" ], },
  { "name": "http", "categories": [ "action" ], "tags": [ "symbol", "character", "website", "transfer", "letter", "http", "alphabet", "text", "type", "url", "font" ], },
  { "name": "https", "categories": [ "action" ], "tags": [ "lock", "connection", "https", "locked", "secure", "key" ], },
  { "name": "image", "categories": [ "image" ], "tags": [], },
  { "name": "image_aspect_ratio", "categories": [ "image" ], "tags": [], },
  { "name": "image_search", "categories": [ "image" ], "tags": [], },
  { "name": "import_contacts", "categories": [ "communication" ], "tags": [ "Import", "address", "book", "information", "contacts", "friends", "open", "info" ], },
  { "name": "import_export", "categories": [ "communication" ], "tags": [ "Import", "arrow", "explort", "arrows", "up", "down", "direction" ], },
  { "name": "important_devices", "categories": [ "action" ], "tags": [ "OS", "star", "mobile", "monitor", "iOS", "Android", "important", "tablet", "desktop", "phone", "web", "device", "hardware" ], },
  { "name": "inbox", "categories": [ "content" ], "tags": [ "incoming", "mail", "archive", "message", "inbox", "email" ], },
  { "name": "indeterminate_check_box", "categories": [ "toggle" ], "tags": [], },
  { "name": "info", "categories": [ "action" ], "tags": [ "alert", "bubble", "i", "information", "announcement", "info" ], },
  { "name": "input", "categories": [ "action" ], "tags": [ "input", "arrow", "box", "right" ], },
  { "name": "insert_chart", "categories": [ "editor" ], "tags": [ "add", "data", "infograpic", "tracking", "bars", "graph", "analytics", "diagram", "bar", "measure", "metrics", "statstics", "chart" ], },
  { "name": "insert_chart_outlined", "categories": [ "editor" ], "tags": [ "add", "data", "infograpic", "tracking", "bars", "graph", "analytics", "diagram", "bar", "measure", "metrics", "statstics", "chart" ], },
  { "name": "insert_comment", "categories": [ "editor" ], "tags": [ "add", "feedback", "bubble", "chat", "comment", "message" ], },
  { "name": "insert_drive_file", "categories": [ "editor" ], "tags": [ "file", "slide", "format", "doc", "insert", "sheet", "drive" ], },
  { "name": "insert_emoticon", "categories": [ "editor" ], "tags": [ "sentiment", "face", "emoji", "person", "happy", "profile", "emoticon", "human", "people", "user", "account", "smile" ], },
  { "name": "insert_invitation", "categories": [ "editor" ], "tags": [ "calendar", "date", "remember", "week", "month", "reminder", "today", "range", "event", "day", "mark" ], },
  { "name": "insert_link", "categories": [ "editor" ], "tags": [ "add", "file", "mail", "link", "attach", "media", "clip" ], },
  { "name": "insert_photo", "categories": [ "editor" ], "tags": [ "images", "mountain", "collection", "media", "photos", "photography" ], },
  { "name": "invert_colors", "categories": [ "action" ], "tags": [ "drop", "color", "droplet", "invert", "tone", "hue", "palette", "inverted", "water" ], },
  { "name": "invert_colors_off", "categories": [ "communication" ], "tags": [ "drop", "Invert", "color", "droplet", "tone", "water", "enabled", "off", "hue", "disabled", "palette", "slash", "inverted", "opacity", "on" ], },
  { "name": "iso", "categories": [ "image" ], "tags": [], },
  { "name": "keyboard", "categories": [ "hardware" ], "tags": [], },
  { "name": "keyboard_arrow_down", "categories": [ "hardware" ], "tags": [], },
  { "name": "keyboard_arrow_left", "categories": [ "hardware" ], "tags": [], },
  { "name": "keyboard_arrow_right", "categories": [ "hardware" ], "tags": [], },
  { "name": "keyboard_arrow_up", "categories": [ "hardware" ], "tags": [], },
  { "name": "keyboard_backspace", "categories": [ "hardware" ], "tags": [], },
  { "name": "keyboard_capslock", "categories": [ "hardware" ], "tags": [], },
  { "name": "keyboard_hide", "categories": [ "hardware" ], "tags": [], },
  { "name": "keyboard_return", "categories": [ "hardware" ], "tags": [], },
  { "name": "keyboard_tab", "categories": [ "hardware" ], "tags": [], },
  { "name": "keyboard_voice", "categories": [ "hardware" ], "tags": [], },
  { "name": "king_bed", "categories": [ "social" ], "tags": [], },
  { "name": "kitchen", "categories": [ "places" ], "tags": [], },
  { "name": "label", "categories": [ "action" ], "tags": [ "indent", "sticker", "stamp", "label", "tag" ], },
  { "name": "label_important", "categories": [ "action" ], "tags": [ "importan", "mail", "indent", "sticker", "stamp", "label", "tag", "wing" ], },
  { "name": "label_off", "categories": [ "action" ], "tags": [ "indent", "sticker", "stamp", "disabled", "slash", "label", "tag", "enabled", "off", "on" ], },
  { "name": "landscape", "categories": [ "image" ], "tags": [], },
  { "name": "language", "categories": [ "action" ], "tags": [ "website", "globe", "world", "planet", "www", "language" ], },
  { "name": "laptop", "categories": [ "hardware" ], "tags": [], },
  { "name": "laptop_chromebook", "categories": [ "hardware" ], "tags": [], },
  { "name": "laptop_mac", "categories": [ "hardware" ], "tags": [], },
  { "name": "laptop_windows", "categories": [ "hardware" ], "tags": [], },
  { "name": "last_page", "categories": [ "navigation" ], "tags": [], },
  { "name": "launch", "categories": [ "action" ], "tags": [ "new", "arrow", "box", "launch", "window", "open" ], },
  { "name": "layers", "categories": [ "maps" ], "tags": [ "pages", "maps", "overlay", "arrange", "interaction", "layers", "disabled", "slash", "enabled", "off", "on" ], },
  { "name": "layers_clear", "categories": [ "maps" ], "tags": [ "maps", "overlay", "clear", "delete", "enabled", "off", "pages", "arrange", "interaction", "layers", "disabled", "slash", "on" ], },
  { "name": "leak_add", "categories": [ "image" ], "tags": [], },
  { "name": "leak_remove", "categories": [ "image" ], "tags": [], },
  { "name": "lens", "categories": [ "image" ], "tags": [], },
  { "name": "library_add", "categories": [ "av" ], "tags": [ "add", "music", "Library", "collection", "video", "plus" ], },
  { "name": "library_add_check", "categories": [ "av" ], "tags": [ "add", "music", "checkmark", "Library", "check", "collection", "video", "plus" ], },
  { "name": "library_books", "categories": [ "av" ], "tags": [ "add", "books", "book", "Library", "audio", "collection" ], },
  { "name": "library_music", "categories": [ "av" ], "tags": [ "add", "song", "music", "Library", "collection" ], },
  { "name": "line_style", "categories": [ "action" ], "tags": [ "spacing", "line", "rule", "style", "dash", "dotted" ], },
  { "name": "line_weight", "categories": [ "action" ], "tags": [ "spacing", "thickness", "line", "weight", "style", "height" ], },
  { "name": "linear_scale", "categories": [ "editor" ], "tags": [ "slider", "linear", "measure", "Ui", "scale", "menu" ], },
  { "name": "link", "categories": [ "content" ], "tags": [ "chain", "multimedia", "link", "connection", "links", "clip", "url", "linked" ], },
  { "name": "link_off", "categories": [ "content" ], "tags": [ "chain", "link", "enabled", "off", "url", "multimedia", "connection", "disabled", "links", "slash", "clip", "linked", "on" ], },
  { "name": "linked_camera", "categories": [ "image" ], "tags": [], },
  { "name": "list", "categories": [ "action" ], "tags": [ "file", "format", "index", "list", "menu" ], },
  { "name": "list_alt", "categories": [ "communication" ], "tags": [ "contained", "stacked", "format", "List", "box", "reorder", "lines", "title", "order" ], },
  { "name": "live_help", "categories": [ "communication" ], "tags": [ "help", "symbol", "question", "information", "support", "mark", "?", "announcement", "info" ], },
  { "name": "live_tv", "categories": [ "notification" ], "tags": [], },
  { "name": "local_activity", "categories": [ "maps" ], "tags": [ "activity", "star", "ticket", "things", "event", "local" ], },
  { "name": "local_airport", "categories": [ "maps" ], "tags": [ "plane", "flight", "trip", "airplane", "air", "travel", "airport", "transportation" ], },
  { "name": "local_atm", "categories": [ "maps" ], "tags": [ "symbol", "bill", "pay", "dollars", "commerce", "cart", "money", "online", "currency", "payment", "atm", "credit", "cash", "card", "coin", "shopping" ], },
  { "name": "local_bar", "categories": [ "maps" ], "tags": [ "alcohol", "bar", "cocktail", "bottle", "liquor", "drink", "food", "wine" ], },
  { "name": "local_cafe", "categories": [ "maps" ], "tags": [ "tea", "cafe", "coffee", "restaurant", "bottle", "drink", "food", "cup" ], },
  { "name": "local_car_wash", "categories": [ "maps" ], "tags": [ "auto", "car", "wash", "local", "vehicle" ], },
  { "name": "local_convenience_store", "categories": [ "maps" ], "tags": [ "24", "shop", "business", "buy", "bill", "pay", "convenience", "dollars", "store", "commerce", "building", "cart", "local", "market", "money", "online", "currency", "payment", "credit", "cash", "storefront", "card", "coin", "shopping" ], },
  { "name": "local_dining", "categories": [ "maps" ], "tags": [ "meal", "fork", "knife", "restuarant", "dining", "spoon", "eat", "food", "local" ], },
  { "name": "local_drink", "categories": [ "maps" ], "tags": [ "tree", "air", "attr", "fresh", "park" ], },
  { "name": "local_florist", "categories": [ "maps" ], "tags": [ "shop", "florist", "local", "flower" ], },
  { "name": "local_gas_station", "categories": [ "maps" ], "tags": [ "auto", "oil", "car", "gas", "station", "vehicle" ], },
  { "name": "local_grocery_store", "categories": [ "maps" ], "tags": [ "market", "shop", "grocery", "store" ], },
  { "name": "local_hospital", "categories": [ "maps" ], "tags": [ "cross", "911", "emergency", "medicine", "hospital", "aid", "first" ], },
  { "name": "local_hotel", "categories": [ "maps" ], "tags": [], },
  { "name": "local_laundry_service", "categories": [ "maps" ], "tags": [ "cleaning", "washer", "laundry", "service", "hotel", "dry", "dryer" ], },
  { "name": "local_library", "categories": [ "maps" ], "tags": [ "library", "read", "book", "community", "local" ], },
  { "name": "local_mall", "categories": [ "maps" ], "tags": [ "shop", "business", "buy", "mall", "bill", "pay", "dollars", "store", "commerce", "building", "cart", "handbag", "money", "online", "currency", "payment", "credit", "cash", "storefront", "card", "coin", "shopping" ], },
  { "name": "local_movies", "categories": [ "maps" ], "tags": [], },
  { "name": "local_offer", "categories": [ "maps" ], "tags": [ "offer", "deal", "shop", "price", "discount", "store.", "tag", "shopping" ], },
  { "name": "local_parking", "categories": [ "maps" ], "tags": [ "parking", "symbol", "auto", "character", "car", "letter", "alphabet", "text", "type", "park", "font", "vehicle" ], },
  { "name": "local_pharmacy", "categories": [ "maps" ], "tags": [ "cross", "911", "emergency", "medicine", "hospital", "aid", "first" ], },
  { "name": "local_phone", "categories": [ "maps" ], "tags": [ "call", "phone", "communication", "telecommunication", "booth" ], },
  { "name": "local_pizza", "categories": [ "maps" ], "tags": [ "meal", "pizza", "drink", "fastfood", "food" ], },
  { "name": "local_play", "categories": [ "maps" ], "tags": [], },
  { "name": "local_post_office", "categories": [ "maps" ], "tags": [ "parcel", "package", "mail", "post", "deliverey", "letter", "stamp", "office", "postal", "send", "shpping" ], },
  { "name": "local_printshop", "categories": [ "maps" ], "tags": [], },
  { "name": "local_see", "categories": [ "maps" ], "tags": [ "see", "attraction", "shop", "explore", "store", "local" ], },
  { "name": "local_shipping", "categories": [ "maps" ], "tags": [ "parcel", "package", "mail", "maps", "stamp", "office", "shpping", "transportation", "vehicle", "automobile", "cars", "post", "car", "deliverey", "letter", "postal", "send", "shopping" ], },
  { "name": "local_taxi", "categories": [ "maps" ], "tags": [ "maps", "cab", "taxi", "yellow", "lyft", "uber", "transportation", "vehicle", "automobile", "call", "cars", "public", "car", "direction" ], },
  { "name": "location_city", "categories": [ "social" ], "tags": [], },
  { "name": "location_disabled", "categories": [ "device" ], "tags": [ "pointer", "maps", "destination", "tracking", "enabled", "off", "pin", "stop", "disabled", "location", "slash", "place", "direction", "on" ], },
  { "name": "location_off", "categories": [ "communication" ], "tags": [ "maps", "pin", "stop", "destination", "disabled", "location", "slash", "place", "enabled", "off", "direction", "on" ], },
  { "name": "location_on", "categories": [ "communication" ], "tags": [ "maps", "pin", "stop", "destination", "disabled", "location", "slash", "place", "enabled", "off", "direction", "on" ], },
  { "name": "location_searching", "categories": [ "device" ], "tags": [ "pointer", "maps", "pin", "stop", "destination", "location", "place", "tracking", "direction" ], },
  { "name": "lock", "categories": [ "action" ], "tags": [ "private", "safe", "lock", "privacy", "connection", "locked", "secure", "key" ], },
  { "name": "lock_open", "categories": [ "action" ], "tags": [ "lock", "connection", "locked", "secure", "key", "open", "unlocked" ], },
  { "name": "looks", "categories": [ "image" ], "tags": [], },
  { "name": "looks_3", "categories": [ "image" ], "tags": [], },
  { "name": "looks_4", "categories": [ "image" ], "tags": [], },
  { "name": "looks_5", "categories": [ "image" ], "tags": [], },
  { "name": "looks_6", "categories": [ "image" ], "tags": [], },
  { "name": "looks_one", "categories": [ "image" ], "tags": [], },
  { "name": "looks_two", "categories": [ "image" ], "tags": [], },
  { "name": "loop", "categories": [ "av" ], "tags": [ "Loop", "rotate", "music", "arrow", "repeat", "arrows" ], },
  { "name": "loupe", "categories": [ "image" ], "tags": [], },
  { "name": "low_priority", "categories": [ "content" ], "tags": [ "arrange", "arrow", "low", "bottom", "priority", "move backward", "order" ], },
  { "name": "loyalty", "categories": [ "action" ], "tags": [ "trip", "loyalty", "membership", "program", "tag", "credit", "travel", "card", "heart", "miles", "points" ], },
  { "name": "mail", "categories": [ "content" ], "tags": [ "mail", "text", "message", "envelop", "send", "email" ], },
  { "name": "mail_outline", "categories": [ "communication" ], "tags": [], },
  { "name": "map", "categories": [ "maps" ], "tags": [ "route", "maps", "pin", "stop", "destination", "location", "place", "direction" ], },
  { "name": "markunread", "categories": [ "content" ], "tags": [ "mail", "unread", "text", "message", "envelop", "send", "email", "mark" ], },
  { "name": "markunread_mailbox", "categories": [ "action" ], "tags": [ "receive", "mailbox", "mail", "post", "postbox", "unread", "letter", "deliver", "postal", "envelop", "send" ], },
  { "name": "maximize", "categories": [ "action" ], "tags": [ "maximize", "shape", "line" ], },
  { "name": "meeting_room", "categories": [ "places" ], "tags": [], },
  { "name": "memory", "categories": [ "hardware" ], "tags": [], },
  { "name": "menu", "categories": [ "navigation" ], "tags": [], },
  { "name": "menu_book", "categories": [ "maps" ], "tags": [ "meal", "resturant", "dining", "book", "menu", "food" ], },
  { "name": "menu_open", "categories": [ "navigation" ], "tags": [], },
  { "name": "merge_type", "categories": [ "editor" ], "tags": [ "margin", "arrow", "format", "text", "type", "combine" ], },
  { "name": "message", "categories": [ "communication" ], "tags": [ "Message", "bubble", "speech", "chat", "talk", "text" ], },
  { "name": "mic", "categories": [ "av" ], "tags": [ "voice", "Mic", "record", "sound", "noise", "hearing", "microphone", "hear" ], },
  { "name": "mic_none", "categories": [ "av" ], "tags": [ "voice", "Mic", "record", "sound", "noise", "hearing", "microphone", "hear" ], },
  { "name": "mic_off", "categories": [ "av" ], "tags": [ "voice", "Mic", "sound", "enabled", "off", "record", "noise", "hearing", "disabled", "slash", "microphone", "hear", "on" ], },
  { "name": "minimize", "categories": [ "action" ], "tags": [ "minimize", "shape", "line" ], },
  { "name": "missed_video_call", "categories": [ "av" ], "tags": [ "call", "arrow", "record", "Missed", "video" ], },
  { "name": "mms", "categories": [ "notification" ], "tags": [], },
  { "name": "mobile_friendly", "categories": [ "device" ], "tags": [ "friendly", "mobile", "check", "device" ], },
  { "name": "mobile_off", "categories": [ "device" ], "tags": [ "not", "allowed", "mobile", "disabled", "slash", "silence", "device", "enabled", "off", "on" ], },
  { "name": "mobile_screen_share", "categories": [ "communication" ], "tags": [ "mirror", "tv", "arrow", "phone", "screen", "monitor", "share", "Mobile", "device" ], },
  { "name": "mode_comment", "categories": [ "editor" ], "tags": [ "bubble", "speech", "chat", "comment" ], },
  { "name": "monetization_on", "categories": [ "editor" ], "tags": [ "symbol", "bill", "pay", "dollars", "commerce", "cart", "money", "online", "currency", "payment", "circle", "credit", "cash", "card", "coin", "shopping" ], },
  { "name": "money", "categories": [ "maps" ], "tags": [ "symbol", "bill", "pay", "dollars", "commerce", "cart", "number", "money", "online", "currency", "payment", "credit", "cash", "card", "coin", "digit", "shopping" ], },
  { "name": "money_off", "categories": [ "editor" ], "tags": [ "symbol", "bill", "pay", "dollars", "commerce", "cart", "enabled", "off", "money", "online", "currency", "disabled", "payment", "slash", "credit", "cash", "card", "coin", "on", "shopping" ], },
  { "name": "monochrome_photos", "categories": [ "image" ], "tags": [], },
  { "name": "mood", "categories": [ "social" ], "tags": [], },
  { "name": "mood_bad", "categories": [ "social" ], "tags": [], },
  { "name": "more", "categories": [ "notification" ], "tags": [], },
  { "name": "more_horiz", "categories": [ "navigation" ], "tags": [], },
  { "name": "more_vert", "categories": [ "navigation" ], "tags": [], },
  { "name": "motorcycle", "categories": [ "action" ], "tags": [ "automobile", "motorcycle", "cars", "maps", "public", "car", "scooter", "sport", "bike", "direction", "transportation", "vehicle" ], },
  { "name": "mouse", "categories": [ "hardware" ], "tags": [], },
  { "name": "move_to_inbox", "categories": [ "content" ], "tags": [ "incoming", "mail", "arrow", "archive", "message", "inbox", "email" ], },
  { "name": "movie", "categories": [ "av" ], "tags": [ "Movie", "cinema", "film", "media", "video", "slate" ], },
  { "name": "movie_creation", "categories": [ "image" ], "tags": [], },
  { "name": "movie_filter", "categories": [ "image" ], "tags": [], },
  { "name": "multiline_chart", "categories": [ "editor" ], "tags": [ "data", "infograpic", "multiple", "tracking", "bars", "graph", "analytics", "diagram", "bar", "measure", "metrics", "statstics", "chart" ], },
  { "name": "museum", "categories": [ "maps" ], "tags": [ "exhibition", "see", "attraction", "shop", "explore", "museum", "store", "event", "local", "tour" ], },
  { "name": "music_note", "categories": [ "image" ], "tags": [], },
  { "name": "music_off", "categories": [ "image" ], "tags": [], },
  { "name": "music_video", "categories": [ "av" ], "tags": [ "tv", "watch", "Music", "recording", "screen", "band", "video" ], },
  { "name": "my_location", "categories": [ "maps" ], "tags": [ "navigation", "maps", "pin", "stop", "destination", "location", "place", "point", "direction" ], },
  { "name": "nature", "categories": [ "image" ], "tags": [], },
  { "name": "nature_people", "categories": [ "image" ], "tags": [], },
  { "name": "navigate_before", "categories": [ "image" ], "tags": [], },
  { "name": "navigate_next", "categories": [ "image" ], "tags": [], },
  { "name": "navigation", "categories": [ "maps" ], "tags": [ "navigation", "maps", "pin", "stop", "destination", "location", "place", "point", "direction" ], },
  { "name": "near_me", "categories": [ "maps" ], "tags": [ "navigation", "maps", "pin", "stop", "destination", "location", "place", "point", "direction" ], },
  { "name": "network_check", "categories": [ "notification" ], "tags": [], },
  { "name": "network_locked", "categories": [ "notification" ], "tags": [], },
  { "name": "new_releases", "categories": [ "av" ], "tags": [ "!", "New", "star", "alert", "release", "attention", "exclamation", "burst", "announcement", "releases" ], },
  { "name": "next_week", "categories": [ "content" ], "tags": [ "calendar", "next", "schedule", "week", "arrow", "briefcase", "work" ], },
  { "name": "nfc", "categories": [ "device" ], "tags": [ "data", "field", "mobile", "wireless", "near", "communication" ], },
  { "name": "nights_stay", "categories": [ "social" ], "tags": [], },
  { "name": "no_encryption", "categories": [ "notification" ], "tags": [], },
  { "name": "no_meeting_room", "categories": [ "places" ], "tags": [], },
  { "name": "no_sim", "categories": [ "communication" ], "tags": [ "eject", "memory", "phone", "Sim", "insert", "storage", "camera", "device", "card" ], },
  { "name": "not_interested", "categories": [ "av" ], "tags": [ "no", "dislike", "x", "circle", "Not interested", "off" ], },
  { "name": "not_listed_location", "categories": [ "maps" ], "tags": [ "symbol", "maps", "destination", "help", "pin", "stop", "information", "location", "place", "question mark", "support", "?", "info" ], },
  { "name": "note", "categories": [ "av" ], "tags": [ "bookmark", "paper", "Note", "message", "mark" ], },
  { "name": "note_add", "categories": [ "action" ], "tags": [ "add", "new", "note", "file", "documentation", "doc", "comment", "plus" ], },
  { "name": "notes", "categories": [ "editor" ], "tags": [ "note", "document", "doc", "comment", "text" ], },
  { "name": "notification_important", "categories": [ "alert" ], "tags": [ "!", "notification", "symbol", "alert", "attention", "warning", "exclamation", "danger", "error", "bell", "caution", "mark" ], },
  { "name": "notifications", "categories": [ "social" ], "tags": [], },
  { "name": "notifications_active", "categories": [ "social" ], "tags": [], },
  { "name": "notifications_none", "categories": [ "social" ], "tags": [], },
  { "name": "notifications_off", "categories": [ "social" ], "tags": [], },
  { "name": "notifications_paused", "categories": [ "social" ], "tags": [], },
  { "name": "offline_bolt", "categories": [ "action" ], "tags": [ "lightning", "spark", "bolt", "circle", "flash" ], },
  { "name": "offline_pin", "categories": [ "action" ], "tags": [ "offline", "pin", "checkmark", "circle" ], },
  { "name": "ondemand_video", "categories": [ "notification" ], "tags": [], },
  { "name": "opacity", "categories": [ "action" ], "tags": [ "drop", "color", "droplet", "invert", "tone", "hue", "palette", "inverted", "opacity", "water" ], },
  { "name": "open_in_browser", "categories": [ "action" ], "tags": [ "new", "arrow", "browser", "box", "window", "open" ], },
  { "name": "open_in_new", "categories": [ "action" ], "tags": [ "new", "arrow", "box", "window", "open" ], },
  { "name": "open_with", "categories": [ "action" ], "tags": [ "expand", "move", "arrow", "arrows", "open", "direction" ], },
  { "name": "outdoor_grill", "categories": [ "social" ], "tags": [], },
  { "name": "outlined_flag", "categories": [ "content" ], "tags": [ "country", "flag", "goal", "nation", "report", "start", "mark" ], },
  { "name": "pages", "categories": [ "social" ], "tags": [], },
  { "name": "pageview", "categories": [ "action" ], "tags": [ "search", "paper", "find", "document", "doc", "magnifying glass", "page" ], },
  { "name": "palette", "categories": [ "image" ], "tags": [], },
  { "name": "pan_tool", "categories": [ "action" ], "tags": [ "move", "hands", "stop", "scan", "fingers", "pan", "human", "tool", "gesture", "hand" ], },
  { "name": "panorama", "categories": [ "image" ], "tags": [], },
  { "name": "panorama_fish_eye", "categories": [ "image" ], "tags": [], },
  { "name": "panorama_horizontal", "categories": [ "image" ], "tags": [], },
  { "name": "panorama_vertical", "categories": [ "image" ], "tags": [], },
  { "name": "panorama_wide_angle", "categories": [ "image" ], "tags": [], },
  { "name": "party_mode", "categories": [ "social" ], "tags": [], },
  { "name": "pause", "categories": [ "av" ], "tags": [ "Pause", "controls", "music", "control", "video" ], },
  { "name": "pause_circle_filled", "categories": [ "av" ], "tags": [ "Pause", "controls", "music", "control", "video" ], },
  { "name": "pause_circle_outline", "categories": [ "av" ], "tags": [ "Pause", "controls", "music", "control", "video" ], },
  { "name": "pause_presentation", "categories": [ "communication" ], "tags": [ "screen", "share", "Presentation", "present", "device", "pause" ], },
  { "name": "payment", "categories": [ "action" ], "tags": [ "charge", "money", "pay", "information", "payment", "credit", "card", "info" ], },
  { "name": "people", "categories": [ "social" ], "tags": [], },
  { "name": "people_alt", "categories": [ "social" ], "tags": [], },
  { "name": "people_outline", "categories": [ "social" ], "tags": [], },
  { "name": "perm_camera_mic", "categories": [ "action" ], "tags": [ "mic", "speaker", "photo", "camera", "microphone" ], },
  { "name": "perm_contact_calendar", "categories": [ "action" ], "tags": [ "calendar", "face", "person", "contact", "profile", "information", "human", "people", "user", "account" ], },
  { "name": "perm_data_setting", "categories": [ "action" ], "tags": [ "settings", "data", "information", "gear", "info" ], },
  { "name": "perm_device_information", "categories": [ "action" ], "tags": [ "OS", "mobile", "i", "iOS", "Android", "important", "tablet", "alert", "phone", "information", "device", "announcement", "hardware", "info" ], },
  { "name": "perm_identity", "categories": [ "action" ], "tags": [ "identity", "person", "profile", "save", "information", "human", "info" ], },
  { "name": "perm_media", "categories": [ "action" ], "tags": [ "images", "mountain", "folder", "folders", "save", "collection", "media" ], },
  { "name": "perm_phone_msg", "categories": [ "action" ], "tags": [ "voice", "bubble", "phone", "speech", "chat", "save", "recording", "message" ], },
  { "name": "perm_scan_wifi", "categories": [ "action" ], "tags": [ "wifi", "alert", "service", "scan", "information", "internet", "announcement", "info", "network" ], },
  { "name": "person", "categories": [ "social" ], "tags": [], },
  { "name": "person_add", "categories": [ "social" ], "tags": [], },
  { "name": "person_add_disabled", "categories": [ "communication" ], "tags": [ "add", "profile", "people", "enabled", "off", "face", "person", "disabled", "slash", "human", "user", "account", "on" ], },
  { "name": "person_outline", "categories": [ "social" ], "tags": [], },
  { "name": "person_pin", "categories": [ "maps" ], "tags": [ "maps", "profile", "destination", "people", "face", "pin", "stop", "person", "location", "place", "human", "user", "account", "direction" ], },
  { "name": "person_pin_circle", "categories": [ "maps" ], "tags": [ "maps", "profile", "destination", "people", "face", "pin", "stop", "person", "location", "place", "human", "user", "account", "direction" ], },
  { "name": "personal_video", "categories": [ "notification" ], "tags": [], },
  { "name": "pets", "categories": [ "action" ], "tags": [ "paw", "animal", "dog", "pet", "hand" ], },
  { "name": "phone", "categories": [ "communication" ], "tags": [ "call", "Phone", "chat", "mobile", "text", "device" ], },
  { "name": "phone_android", "categories": [ "hardware" ], "tags": [], },
  { "name": "phone_bluetooth_speaker", "categories": [ "notification" ], "tags": [], },
  { "name": "phone_callback", "categories": [ "notification" ], "tags": [], },
  { "name": "phone_disabled", "categories": [ "communication" ], "tags": [ "OS", "mobile", "iOS", "enabled", "off", "Android", "call", "tablet", "phone", "chat", "disabled", "slash", "text", "device", "hardware", "on" ], },
  { "name": "phone_enabled", "categories": [ "communication" ], "tags": [ "call", "tablet", "OS", "phone", "chat", "mobile", "text", "iOS", "device", "enabled", "Android", "hardware" ], },
  { "name": "phone_forwarded", "categories": [ "notification" ], "tags": [], },
  { "name": "phone_in_talk", "categories": [ "notification" ], "tags": [], },
  { "name": "phone_iphone", "categories": [ "hardware" ], "tags": [], },
  { "name": "phone_locked", "categories": [ "notification" ], "tags": [], },
  { "name": "phone_missed", "categories": [ "notification" ], "tags": [], },
  { "name": "phone_paused", "categories": [ "notification" ], "tags": [], },
  { "name": "phonelink", "categories": [ "hardware" ], "tags": [], },
  { "name": "phonelink_erase", "categories": [ "communication" ], "tags": [ "call", "tablet", "erase", "OS", "phone", "chat", "mobile", "text", "iOS", "device", "Android", "hardware" ], },
  { "name": "phonelink_lock", "categories": [ "communication" ], "tags": [ "call", "tablet", "OS", "phone", "chat", "mobile", "lock", "text", "iOS", "device", "Android", "hardware" ], },
  { "name": "phonelink_off", "categories": [ "hardware" ], "tags": [], },
  { "name": "phonelink_ring", "categories": [ "communication" ], "tags": [ "call", "tablet", "OS", "phone", "ring", "chat", "mobile", "text", "iOS", "device", "Android", "hardware" ], },
  { "name": "phonelink_setup", "categories": [ "communication" ], "tags": [ "setup. settings", "OS", "mobile", "iOS", "Android", "call", "tablet", "phone", "chat", "text", "device", "hardware", "info" ], },
  { "name": "photo", "categories": [ "image" ], "tags": [], },
  { "name": "photo_album", "categories": [ "image" ], "tags": [], },
  { "name": "photo_camera", "categories": [ "image" ], "tags": [], },
  { "name": "photo_filter", "categories": [ "image" ], "tags": [], },
  { "name": "photo_library", "categories": [ "image" ], "tags": [], },
  { "name": "photo_size_select_actual", "categories": [ "image" ], "tags": [], },
  { "name": "photo_size_select_large", "categories": [ "image" ], "tags": [], },
  { "name": "photo_size_select_small", "categories": [ "image" ], "tags": [], },
  { "name": "picture_as_pdf", "categories": [ "image" ], "tags": [], },
  { "name": "picture_in_picture", "categories": [ "action" ], "tags": [ "overlap", "shape", "photo", "cropped", "crop", "picture" ], },
  { "name": "picture_in_picture_alt", "categories": [ "action" ], "tags": [ "overlap", "shape", "photo", "cropped", "crop", "picture" ], },
  { "name": "pie_chart", "categories": [ "editor" ], "tags": [ "data", "infograpic", "pie", "tracking", "bars", "graph", "analytics", "diagram", "bar", "measure", "metrics", "statstics", "chart" ], },
  { "name": "pin_drop", "categories": [ "maps" ], "tags": [ "navigation", "maps", "pin", "stop", "destination", "location", "place", "point", "direction" ], },
  { "name": "place", "categories": [ "maps" ], "tags": [ "navigation", "maps", "pin", "stop", "destination", "location", "place", "point", "direction" ], },
  { "name": "play_arrow", "categories": [ "av" ], "tags": [ "Play", "controls", "music", "arrow", "control", "video" ], },
  { "name": "play_circle_filled", "categories": [ "av" ], "tags": [ "Play", "controls", "music", "arrow", "control", "video" ], },
  { "name": "play_circle_outline", "categories": [ "av" ], "tags": [ "Play", "controls", "music", "arrow", "control", "video" ], },
  { "name": "play_for_work", "categories": [ "action" ], "tags": [ "play", "half", "arrow", "work", "google", "circle", "down" ], },
  { "name": "playlist_add", "categories": [ "av" ], "tags": [ "add", "play", "Playlist", "music", "collection", "list", "plus" ], },
  { "name": "playlist_add_check", "categories": [ "av" ], "tags": [ "add", "play", "Playlist", "music", "checkmark", "check", "collection", "list" ], },
  { "name": "playlist_play", "categories": [ "av" ], "tags": [ "play", "Playlist", "music", "collection", "list" ], },
  { "name": "plus_one", "categories": [ "social" ], "tags": [], },
  { "name": "policy", "categories": [ "content" ], "tags": [ "shield", "glass", "search", "security", "document", "legal", "certified", "protection", "secure", "magnifying", "policy" ], },
  { "name": "poll", "categories": [ "social" ], "tags": [], },
  { "name": "polymer", "categories": [ "action" ], "tags": [ "polymer", "logo", "emblem", "mark" ], },
  { "name": "pool", "categories": [ "places" ], "tags": [], },
  { "name": "portable_wifi_off", "categories": [ "communication" ], "tags": [ "usage", "portable", "enabled", "off", "connected", "service", "connection", "disabled", "slash", "Wifi", "device", "internet", "on" ], },
  { "name": "portrait", "categories": [ "image" ], "tags": [], },
  { "name": "post_add", "categories": [ "editor" ], "tags": [ "add", "post", "document", "writing", "doc", "+", "text", "plus" ], },
  { "name": "power", "categories": [ "notification" ], "tags": [], },
  { "name": "power_input", "categories": [ "hardware" ], "tags": [], },
  { "name": "power_off", "categories": [ "notification" ], "tags": [], },
  { "name": "power_settings_new", "categories": [ "action" ], "tags": [ "settings", "save", "information", "power", "off", "shutdown", "info", "on" ], },
  { "name": "pregnant_woman", "categories": [ "action" ], "tags": [ "woman", "birth", "mom", "body", "people", "mother", "maternity", "person", "baby", "lady", "female", "human", "pregnant" ], },
  { "name": "present_to_all", "categories": [ "communication" ], "tags": [ "arrow\nall", "screen", "share", "Presentation", "present", "device" ], },
  { "name": "print", "categories": [ "action" ], "tags": [ "print", "paper", "draft", "ink", "printer", "send" ], },
  { "name": "print_disabled", "categories": [ "communication" ], "tags": [ "Print", "feed", "paper", "printer", "disabled", "slash", "enabled", "off", "on" ], },
  { "name": "priority_high", "categories": [ "notification" ], "tags": [], },
  { "name": "public", "categories": [ "social" ], "tags": [], },
  { "name": "publish", "categories": [ "editor" ], "tags": [ "cloud", "file", "import", "arrow", "upload", "publish", "up" ], },
  { "name": "query_builder", "categories": [ "action" ], "tags": [ "hour", "query", "builder", "save", "clock", "time", "minute" ], },
  { "name": "question_answer", "categories": [ "action" ], "tags": [ "answer", "question", "bubble", "speech", "converse", "talk", "conversation" ], },
  { "name": "queue", "categories": [ "av" ], "tags": [ "add", "music", "playlist", "stream", "collection", "video", "list", "Queue" ], },
  { "name": "queue_music", "categories": [ "av" ], "tags": [ "add", "music", "playlist", "stream", "collection", "list", "Queue" ], },
  { "name": "queue_play_next", "categories": [ "av" ], "tags": [ "add", "next", "play", "arrow", "screen", "monitor", "collection", "video", "list", "plus", "desktop", "music", "playlist", "Queue", "device" ], },
  { "name": "radio", "categories": [ "av" ], "tags": [ "antenna", "music", "Radio", "device", "listen", "tune", "player" ], },
  { "name": "radio_button_checked", "categories": [ "toggle" ], "tags": [], },
  { "name": "radio_button_unchecked", "categories": [ "toggle" ], "tags": [], },
  { "name": "rate_review", "categories": [ "maps" ], "tags": [ "feedback", "rate", "review", "pen", "comment", "stars", "pencil", "write" ], },
  { "name": "receipt", "categories": [ "action" ], "tags": [ "sale", "paper", "bill", "payment", "receipt", "invoice", "credit" ], },
  { "name": "recent_actors", "categories": [ "av" ], "tags": [ "Recent", "actors", "cards", "profile", "carousel", "human", "user", "card" ], },
  { "name": "record_voice_over", "categories": [ "action" ], "tags": [ "voice", "profile", "recording", "people", "face", "speaking", "transcript", "speech", "person", "speak", "human", "user", "account" ], },
  { "name": "redeem", "categories": [ "action" ], "tags": [ "gift", "giftcard", "certificate", "bill", "pay", "dollars", "commerce", "cart", "money", "online", "currency", "payment", "redeem", "credit", "present", "cash", "card", "coin", "shopping" ], },
  { "name": "redo", "categories": [ "content" ], "tags": [ "next", "rotate", "undo", "arrow", "forward", "repeat", "backward", "redo" ], },
  { "name": "refresh", "categories": [ "navigation" ], "tags": [], },
  { "name": "remove", "categories": [ "content" ], "tags": [ "can", "minus", "negative", "delete", "remove", "substract", "trash" ], },
  { "name": "remove_circle", "categories": [ "content" ], "tags": [ "can", "minus", "negative", "block", "circle", "delete", "remove", "substract", "trash" ], },
  { "name": "remove_circle_outline", "categories": [ "content" ], "tags": [ "can", "minus", "negative", "block", "circle", "delete", "remove", "substract", "trash" ], },
  { "name": "remove_from_queue", "categories": [ "av" ], "tags": [ "screen", "monitor", "collection", "list", "Queue", "device", "remove" ], },
  { "name": "remove_red_eye", "categories": [ "image" ], "tags": [], },
  { "name": "remove_shopping_cart", "categories": [ "action" ], "tags": [ "disabled", "slash", "checkout", "cart", "enabled", "off", "remove", "on", "shopping" ], },
  { "name": "reorder", "categories": [ "action" ], "tags": [ "stacked", "format", "reorder", "lines", "list", "order" ], },
  { "name": "repeat", "categories": [ "av" ], "tags": [ "controls", "music", "arrow", "arrows", "Repeat", "control", "video" ], },
  { "name": "repeat_one", "categories": [ "av" ], "tags": [ "1", "number", "symbol", "controls", "music", "arrow", "arrows", "Repeat", "control", "video", "digit" ], },
  { "name": "replay", "categories": [ "av" ], "tags": [ "controls", "music", "arrow", "Replay", "repeat", "arrows", "control", "video" ], },
  { "name": "replay_10", "categories": [ "av" ], "tags": [ "number", "symbol", "controls", "music", "arrow", "Replay", "repeat", "arrows", "control", "video", "10", "digit" ], },
  { "name": "replay_30", "categories": [ "av" ], "tags": [ "number", "symbol", "controls", "music", "arrow", "Replay", "repeat", "arrows", "control", "video", "30", "digit" ], },
  { "name": "replay_5", "categories": [ "av" ], "tags": [ "number", "symbol", "controls", "music", "5", "arrow", "Replay", "repeat", "arrows", "control", "video", "digit" ], },
  { "name": "reply", "categories": [ "content" ], "tags": [ "mail", "arrow", "left", "backward", "share", "message", "reply", "send" ], },
  { "name": "reply_all", "categories": [ "content" ], "tags": [ "all", "mail", "arrow", "left", "backward", "share", "message", "reply", "send", "group" ], },
  { "name": "report", "categories": [ "content" ], "tags": [ "!", "notification", "symbol", "alert", "octagon", "attention", "warning", "exclamation", "danger", "error", "caution", "mark" ], },
  { "name": "report_off", "categories": [ "content" ], "tags": [ "!", "symbol", "exclamation", "danger", "error", "enabled", "off", "notification", "alert", "attention", "warning", "disabled", "slash", "caution", "mark", "octagon on" ], },
  { "name": "report_problem", "categories": [ "action" ], "tags": [ "!", "symbol", "exclamation", "danger", "error", "triangle", "feedback", "notification", "problem", "alert", "report", "attention", "warning", "caution", "mark" ], },
  { "name": "restaurant", "categories": [ "maps" ], "tags": [ "meal", "fork", "knife", "restuarant", "dining", "spoon", "eat", "food", "local" ], },
  { "name": "restaurant_menu", "categories": [ "maps" ], "tags": [ "meal", "fork", "knife", "restuarant", "dining", "book", "spoon", "eat", "menu", "food", "local" ], },
  { "name": "restore", "categories": [ "action" ], "tags": [ "rotate", "restore", "arrow", "back", "refresh", "clock", "history", "time", "reverse", "backwards" ], },
  { "name": "restore_from_trash", "categories": [ "action" ], "tags": [ "can", "restore", "arrow", "garbage", "up", "delete", "remove", "trash" ], },
  { "name": "restore_page", "categories": [ "action" ], "tags": [ "rotate", "paper", "restore", "arrow", "refresh", "page" ], },
  { "name": "ring_volume", "categories": [ "communication" ], "tags": [ "call", "volume", "ring", "Phone", "sound", "mobile", "device", "ringer" ], },
  { "name": "room", "categories": [ "action" ], "tags": [ "pin", "marker", "spot", "place", "map", "mark", "room" ], },
  { "name": "room_service", "categories": [ "places" ], "tags": [], },
  { "name": "rotate_90_degrees_ccw", "categories": [ "image" ], "tags": [], },
  { "name": "rotate_left", "categories": [ "image" ], "tags": [], },
  { "name": "rotate_right", "categories": [ "image" ], "tags": [], },
  { "name": "rounded_corner", "categories": [ "action" ], "tags": [ "square", "transform", "corner", "shape", "rounded" ], },
  { "name": "router", "categories": [ "hardware" ], "tags": [], },
  { "name": "rowing", "categories": [ "action" ], "tags": [ "activity", "rowing", "canoe", "person", "body", "human", "people", "sport", "water", "boat" ], },
  { "name": "rss_feed", "categories": [ "communication" ], "tags": [ "feed", "website", "Rss", "application", "blog" ], },
  { "name": "rv_hookup", "categories": [ "places" ], "tags": [], },
  { "name": "satellite", "categories": [ "maps" ], "tags": [ "images", "mountain", "maps", "location", "satellite" ], },
  { "name": "save", "categories": [ "content" ], "tags": [ "disk", "multimedia", "file", "floppy", "document", "save" ], },
  { "name": "save_alt", "categories": [ "content" ], "tags": [ "disk", "multimedia", "file", "floppy", "arrow", "document", "save", "down" ], },
  { "name": "scanner", "categories": [ "hardware" ], "tags": [], },
  { "name": "scatter_plot", "categories": [ "editor" ], "tags": [ "data", "dot", "infograpic", "tracking", "bars", "graph", "analytics", "diagram", "bar", "measure", "metrics", "statstics", "circle", "chart" ], },
  { "name": "schedule", "categories": [ "action" ], "tags": [ "calendar", "date", "schedule", "save", "clock", "time", "mark" ], },
  { "name": "school", "categories": [ "social" ], "tags": [], },
  { "name": "score", "categories": [ "editor" ], "tags": [ "symbol", "data", "infograpic", "alphabet", "type", "tracking", "bars", "graph", "analytics", "diagram", "number", "score", "bar", "character", "measure", "letter", "2k", "metrics", "statstics", "text", "chart", "digit", "font" ], },
  { "name": "screen_lock_landscape", "categories": [ "device" ], "tags": [ "rotate", "tablet", "OS", "phone", "mobile", "lock", "screen", "iOS", "device", "landscape", "Android", "hardware" ], },
  { "name": "screen_lock_portrait", "categories": [ "device" ], "tags": [ "rotate", "tablet", "OS", "phone", "mobile", "lock", "screen", "iOS", "portrait", "device", "Android", "hardware" ], },
  { "name": "screen_lock_rotation", "categories": [ "device" ], "tags": [ "rotate", "tablet", "OS", "arrow", "phone", "mobile", "lock", "screen", "iOS", "device", "Android", "hardware" ], },
  { "name": "screen_rotation", "categories": [ "device" ], "tags": [ "rotate", "tablet", "OS", "arrow", "phone", "mobile", "lock", "screen", "iOS", "device", "Android", "hardware" ], },
  { "name": "screen_share", "categories": [ "communication" ], "tags": [ "mirror", "OS", "arrow", "screen", "monitor", "iOS", "laptop", "Android", "desktop", "web", "share", "device", "hardware" ], },
  { "name": "sd_card", "categories": [ "notification" ], "tags": [], },
  { "name": "sd_storage", "categories": [ "device" ], "tags": [ "digital", "sd", "memory", "data", "storage", "camera", "secure", "card" ], },
  { "name": "search", "categories": [ "action" ], "tags": [ "filter", "cearch", "look up", "magnifying glass", "look", "magnify" ], },
  { "name": "security", "categories": [ "hardware" ], "tags": [], },
  { "name": "select_all", "categories": [ "content" ], "tags": [ "all", "square", "select", "selection", "tool" ], },
  { "name": "send", "categories": [ "content" ], "tags": [ "plane", "mail", "paper", "airplane", "right", "message", "reply", "send" ], },
  { "name": "sentiment_dissatisfied", "categories": [ "social" ], "tags": [], },
  { "name": "sentiment_satisfied", "categories": [ "social" ], "tags": [], },
  { "name": "sentiment_satisfied_alt", "categories": [ "communication" ], "tags": [ "sentiment", "face", "emoji", "person", "satisfied", "happy", "profile", "human", "people", "user", "account", "smile" ], },
  { "name": "sentiment_very_dissatisfied", "categories": [ "social" ], "tags": [], },
  { "name": "sentiment_very_satisfied", "categories": [ "social" ], "tags": [], },
  { "name": "settings", "categories": [ "action" ], "tags": [ "settings", "application", "service", "save", "details", "information", "personal", "info" ], },
  { "name": "settings_applications", "categories": [ "action" ], "tags": [ "settings", "application", "service", "save", "details", "information", "personal", "info" ], },
  { "name": "settings_backup_restore", "categories": [ "action" ], "tags": [ "rotate", "settings", "backup", "restore", "arrow", "back", "refresh", "reverse", "backwards" ], },
  { "name": "settings_bluetooth", "categories": [ "action" ], "tags": [ "settings", "wifi", "bluetooth", "connectivity", "connection", "connect" ], },
  { "name": "settings_brightness", "categories": [ "action" ], "tags": [ "mode", "settings", "brightness", "light", "dark" ], },
  { "name": "settings_cell", "categories": [ "action" ], "tags": [ "settings", "tablet", "OS", "phone", "mobile", "iOS", "device", "Android", "hardware" ], },
  { "name": "settings_ethernet", "categories": [ "action" ], "tags": [ "ethernet", "settings", "wifi", "connectivity", "dots", "arrows", "connection", "connect" ], },
  { "name": "settings_input_antenna", "categories": [ "action" ], "tags": [ "antenna", "input", "settings", "connectivity", "connection", "connect" ], },
  { "name": "settings_input_component", "categories": [ "action" ], "tags": [ "input", "settings", "component", "connectivity", "connection", "plug", "points" ], },
  { "name": "settings_input_composite", "categories": [ "action" ], "tags": [ "input", "settings", "component", "connectivity", "connection", "plug", "points" ], },
  { "name": "settings_input_hdmi", "categories": [ "action" ], "tags": [ "input", "settings", "connectivity", "plugin", "hdmi", "connection", "plug", "points" ], },
  { "name": "settings_input_svideo", "categories": [ "action" ], "tags": [ "input", "settings", "connectivity", "plugin", "svideo", "connection", "plug", "points" ], },
  { "name": "settings_overscan", "categories": [ "action" ], "tags": [ "image", "settings", "expand", "arrows", "scan", "photo", "picture" ], },
  { "name": "settings_phone", "categories": [ "action" ], "tags": [ "settings", "phone", "mobile", "cell", "device" ], },
  { "name": "settings_power", "categories": [ "action" ], "tags": [ "settings", "save", "information", "power", "off", "shutdown", "info", "on" ], },
  { "name": "settings_remote", "categories": [ "action" ], "tags": [ "settings", "wifi", "bluetooth", "remote" ], },
  { "name": "settings_system_daydream", "categories": [ "device" ], "tags": [ "cloud", "system", "daydream", "setting" ], },
  { "name": "settings_voice", "categories": [ "action" ], "tags": [ "voice", "settings", "recorder", "mic", "record", "speaker", "microphone" ], },
  { "name": "share", "categories": [ "social" ], "tags": [], },
  { "name": "shop", "categories": [ "action" ], "tags": [ "add", "play", "shop", "buy", "bill", "pay", "dollars", "google", "commerce", "cart", "money", "online", "currency", "payment", "credit", "cash", "card", "coin", "shopping" ], },
  { "name": "shop_two", "categories": [ "action" ], "tags": [ "add", "play", "shop", "arrow", "buy", "purchase", "google", "cart", "shopping" ], },
  { "name": "shopping_basket", "categories": [ "action" ], "tags": [ "add", "buy", "bill", "pay", "dollars", "commerce", "cart", "money", "online", "currency", "payment", "credit", "cash", "checkout", "card", "coin", "shopping" ], },
  { "name": "shopping_cart", "categories": [ "action" ], "tags": [ "add", "buy", "bill", "pay", "dollars", "commerce", "cart", "money", "online", "currency", "payment", "credit", "cash", "checkout", "card", "coin", "shopping" ], },
  { "name": "short_text", "categories": [ "editor" ], "tags": [ "brief", "note", "document", "doc", "short", "comment", "text" ], },
  { "name": "show_chart", "categories": [ "editor" ], "tags": [ "data", "line", "infograpic", "tracking", "bars", "graph", "analytics", "diagram", "presentation", "bar", "measure", "metrics", "statstics", "chart" ], },
  { "name": "shuffle", "categories": [ "av" ], "tags": [ "controls", "music", "Shuffle", "arrow", "arrows", "control", "video" ], },
  { "name": "shutter_speed", "categories": [ "image" ], "tags": [], },
  { "name": "signal_cellular_4_bar", "categories": [ "device" ], "tags": [ "!", "symbol", "wifi", "celluar", "data", "mobile", "exclamation", "cell", "danger", "error", "speed", "network", "notification", "alert", "phone", "attention", "warning", "wireless", "caution", "signal", "internet", "mark" ], },
  { "name": "signal_cellular_alt", "categories": [ "device" ], "tags": [ "wifi", "celluar", "data", "phone", "mobile", "wireless", "cell", "signal", "internet", "speed", "network" ], },
  { "name": "signal_cellular_connected_no_internet_4_bar", "categories": [ "device" ], "tags": [ "!", "symbol", "wifi", "celluar", "data", "mobile", "exclamation", "cell", "danger", "error", "speed", "network", "notification", "alert", "phone", "attention", "warning", "wireless", "caution", "signal", "internet", "mark" ], },
  { "name": "signal_cellular_no_sim", "categories": [ "device" ], "tags": [ "wifi", "celluar", "data", "mobile", "cell", "enabled", "off", "speed", "network", "phone", "sim", "disabled", "slash", "wireless", "signal", "internet", "on" ], },
  { "name": "signal_cellular_null", "categories": [ "device" ], "tags": [ "wifi", "celluar", "data", "null", "phone", "mobile", "wireless", "cell", "signal", "internet", "speed", "network" ], },
  { "name": "signal_cellular_off", "categories": [ "device" ], "tags": [ "wifi", "celluar", "data", "mobile", "cell", "enabled", "off", "speed", "network", "phone", "disabled", "slash", "wireless", "signal", "internet", "on" ], },
  { "name": "signal_wifi_4_bar", "categories": [ "device" ], "tags": [ "wifi", "celluar", "data", "phone", "mobile", "wireless", "cell", "signal", "internet", "speed", "network" ], },
  { "name": "signal_wifi_4_bar_lock", "categories": [ "device" ], "tags": [ "wifi", "celluar", "data", "phone", "mobile", "lock", "wireless", "cell", "signal", "internet", "speed", "network" ], },
  { "name": "signal_wifi_off", "categories": [ "device" ], "tags": [ "wifi", "celluar", "data", "mobile", "cell", "enabled", "off", "speed", "network", "phone", "disabled", "slash", "wireless", "signal", "internet", "on" ], },
  { "name": "sim_card", "categories": [ "hardware" ], "tags": [], },
  { "name": "single_bed", "categories": [ "social" ], "tags": [], },
  { "name": "skip_next", "categories": [ "av" ], "tags": [ "next", "play", "controls", "music", "previous", "arrow", "Skip", "control", "video" ], },
  { "name": "skip_previous", "categories": [ "av" ], "tags": [ "next", "play", "controls", "music", "previous", "arrow", "Skip", "control", "video" ], },
  { "name": "slideshow", "categories": [ "image" ], "tags": [], },
  { "name": "slow_motion_video", "categories": [ "av" ], "tags": [ "play", "controls", "music", "motion", "arrow", "Slow", "control", "video" ], },
  { "name": "smartphone", "categories": [ "hardware" ], "tags": [], },
  { "name": "smoke_free", "categories": [ "places" ], "tags": [], },
  { "name": "smoking_rooms", "categories": [ "places" ], "tags": [], },
  { "name": "sms", "categories": [ "notification" ], "tags": [], },
  { "name": "sms_failed", "categories": [ "notification" ], "tags": [], },
  { "name": "snooze", "categories": [ "av" ], "tags": [ "timer", "Snooze", "set", "alarm", "z", "clock", "time" ], },
  { "name": "sort", "categories": [ "content" ], "tags": [ "filter", "organize", "find", "sort", "list" ], },
  { "name": "sort_by_alpha", "categories": [ "av" ], "tags": [ "symbol", "alphabetize", "alphabet", "list", "type", "character", "organize", "letter", "Sort", "az", "text", "font", "order" ], },
  { "name": "spa", "categories": [ "places" ], "tags": [], },
  { "name": "space_bar", "categories": [ "editor" ], "tags": [ "keyboard", "bar", "line", "space" ], },
  { "name": "speaker", "categories": [ "hardware" ], "tags": [], },
  { "name": "speaker_group", "categories": [ "hardware" ], "tags": [], },
  { "name": "speaker_notes", "categories": [ "action" ], "tags": [ "note", "cards", "notes", "bubble", "speech", "speaker", "format", "comment", "list" ], },
  { "name": "speaker_notes_off", "categories": [ "action" ], "tags": [ "note", "cards", "notes", "format", "list", "enabled", "off", "bubble", "speech", "speaker", "comment", "disabled", "slash", "on" ], },
  { "name": "speaker_phone", "categories": [ "communication" ], "tags": [ "volume", "tablet", "OS", "phone", "sound", "mobile", "iOS", "device", "Android", "Speaker", "hardware" ], },
  { "name": "speed", "categories": [ "av" ], "tags": [ "play", "controls", "fast", "music", "motion", "arrow", "Slow", "control", "video", "internet", "speed" ], },
  { "name": "spellcheck", "categories": [ "action" ], "tags": [ "a", "spell", "symbol", "edit", "alphabet", "check", "type", "processor", "character", "letter", "checkmark", "text", "word", "write", "font" ], },
  { "name": "sports", "categories": [ "social" ], "tags": [], },
  { "name": "sports_baseball", "categories": [ "social" ], "tags": [], },
  { "name": "sports_basketball", "categories": [ "social" ], "tags": [], },
  { "name": "sports_cricket", "categories": [ "social" ], "tags": [], },
  { "name": "sports_esports", "categories": [ "social" ], "tags": [], },
  { "name": "sports_football", "categories": [ "social" ], "tags": [], },
  { "name": "sports_golf", "categories": [ "social" ], "tags": [], },
  { "name": "sports_handball", "categories": [ "social" ], "tags": [], },
  { "name": "sports_hockey", "categories": [ "social" ], "tags": [], },
  { "name": "sports_kabaddi", "categories": [ "social" ], "tags": [], },
  { "name": "sports_mma", "categories": [ "social" ], "tags": [], },
  { "name": "sports_motorsports", "categories": [ "social" ], "tags": [], },
  { "name": "sports_rugby", "categories": [ "social" ], "tags": [], },
  { "name": "sports_soccer", "categories": [ "social" ], "tags": [], },
  { "name": "sports_tennis", "categories": [ "social" ], "tags": [], },
  { "name": "sports_volleyball", "categories": [ "social" ], "tags": [], },
  { "name": "square_foot", "categories": [ "content" ], "tags": [ "feet", "inches", "square", "set", "school", "length", "construction", "mesurement", "ruler", "foot" ], },
  { "name": "star", "categories": [ "toggle" ], "tags": [], },
  { "name": "star_border", "categories": [ "toggle" ], "tags": [], },
  { "name": "star_half", "categories": [ "toggle" ], "tags": [], },
  { "name": "stars", "categories": [ "action" ], "tags": [ "love", "star", "like", "rate", "rating", "circle", "favorite" ], },
  { "name": "stay_current_landscape", "categories": [ "communication" ], "tags": [ "tablet", "current", "Landscape", "OS", "phone", "mobile", "iOS", "device", "Android", "hardware", "stay" ], },
  { "name": "stay_current_portrait", "categories": [ "communication" ], "tags": [ "tablet", "current", "OS", "phone", "mobile", "Portrait", "iOS", "device", "Android", "hardware", "stay" ], },
  { "name": "stay_primary_landscape", "categories": [ "communication" ], "tags": [ "tablet", "Landscape", "OS", "phone", "mobile", "iOS", "device", "Android", "hardware", "primary", "stay" ], },
  { "name": "stay_primary_portrait", "categories": [ "communication" ], "tags": [ "tablet", "OS", "phone", "mobile", "Portrait", "iOS", "device", "Android", "hardware", "primary", "stay" ], },
  { "name": "stop", "categories": [ "av" ], "tags": [ "play", "controls", "music", "vide", "Stop", "arrow", "control" ], },
  { "name": "stop_screen_share", "categories": [ "communication" ], "tags": [ "mirror", "OS", "arrow", "screen", "monitor", "iOS", "laptop", "enabled", "off", "Android", "desktop", "web", "disabled", "share", "slash", "device", "hardware", "on" ], },
  { "name": "storage", "categories": [ "device" ], "tags": [ "computer", "memory", "data", "storage", "drive" ], },
  { "name": "store", "categories": [ "action" ], "tags": [ "shop", "business", "buy", "bill", "pay", "dollars", "store", "commerce", "building", "cart", "money", "online", "currency", "payment", "credit", "cash", "storefront", "card", "coin", "shopping" ], },
  { "name": "store_mall_directory", "categories": [ "maps" ], "tags": [ "shop", "mall", "front", "place", "store", "directory", "shopping" ], },
  { "name": "storefront", "categories": [ "places" ], "tags": [], },
  { "name": "straighten", "categories": [ "image" ], "tags": [], },
  { "name": "streetview", "categories": [ "maps" ], "tags": [], },
  { "name": "strikethrough_s", "categories": [ "editor" ], "tags": [ "editor", "symbol", "edit", "cross", "alphabet", "type", "editing", "out", "character", "letter", "writing", "doc", "sheet", "styles", "text", "font", "speadsheet" ], },
  { "name": "style", "categories": [ "image" ], "tags": [], },
  { "name": "subdirectory_arrow_left", "categories": [ "navigation" ], "tags": [], },
  { "name": "subdirectory_arrow_right", "categories": [ "navigation" ], "tags": [], },
  { "name": "subject", "categories": [ "action" ], "tags": [ "note", "justify", "subject", "document", "writing", "doc", "text", "alignment", "list", "email", "full" ], },
  { "name": "subscriptions", "categories": [ "av" ], "tags": [ "subscriptions", "subscribe", "enroll", "Subscription", "signup", "order" ], },
  { "name": "subtitles", "categories": [ "av" ], "tags": [ "Cc", "Subtitle", "accessible", "subtitles", "accessibility", "caption", "language", "translate" ], },
  { "name": "subway", "categories": [ "maps" ], "tags": [ "maps", "rail", "bike", "transportation", "vehicle", "automobile", "cars", "subway", "car", "underground", "vespa", "scooter", "tunnel", "train" ], },
  { "name": "supervised_user_circle", "categories": [ "action" ], "tags": [ "profile", "control", "avatar", "people", "face", "supervised", "person", "circle", "human", "parental control", "user", "account", "parental", "supervisor", "parents" ], },
  { "name": "supervisor_account", "categories": [ "action" ], "tags": [ "profile", "control", "avatar", "people", "face", "supervised", "person", "human", "parental control", "user", "account", "parental", "supervisor", "parents" ], },
  { "name": "surround_sound", "categories": [ "av" ], "tags": [ "system", "speaker", "volumn", "Sound", "circle", "surround" ], },
  { "name": "swap_calls", "categories": [ "communication" ], "tags": [ "call", "Swap", "arrow", "calls", "arrows", "mobile", "share", "device", "direction" ], },
  { "name": "swap_horiz", "categories": [ "action" ], "tags": [ "horizontal", "arrow", "arrows", "back", "direction" ], },
  { "name": "swap_horizontal_circle", "categories": [ "action" ], "tags": [ "horizontal", "arrow", "arrows", "back", "circle", "direction" ], },
  { "name": "swap_vert", "categories": [ "action" ], "tags": [ "arrow", "arrows", "back", "vertical", "direction" ], },
  { "name": "swap_vertical_circle", "categories": [ "action" ], "tags": [ "arrow", "arrows", "back", "vertical", "circle", "direction" ], },
  { "name": "switch_camera", "categories": [ "image" ], "tags": [], },
  { "name": "switch_video", "categories": [ "image" ], "tags": [], },
  { "name": "sync", "categories": [ "notification" ], "tags": [], },
  { "name": "sync_alt", "categories": [ "action" ], "tags": [ "horizontal", "wifi", "arrow", "alt", "arrows", "update", "technology", "up", "sync", "internet" ], },
  { "name": "sync_disabled", "categories": [ "notification" ], "tags": [], },
  { "name": "sync_problem", "categories": [ "notification" ], "tags": [], },
  { "name": "system_update", "categories": [ "notification" ], "tags": [], },
  { "name": "system_update_alt", "categories": [ "action" ], "tags": [ "instal", "system", "load", "system update", "web", "installation", "mobile", "cellphone", "update", "dowload", "cell", "device" ], },
  { "name": "tab", "categories": [ "action" ], "tags": [ "website", "folder", "tab", "documents", "web", "browser", "document", "tabs", "window", "windows" ], },
  { "name": "tab_unselected", "categories": [ "action" ], "tags": [ "website", "folder", "unselect", "tab", "documents", "web", "browser", "document", "tabs", "unselected", "window", "windows" ], },
  { "name": "table_chart", "categories": [ "editor" ], "tags": [ "data", "infograpic", "tracking", "bars", "graph", "analytics", "diagram", "bar", "measure", "metrics", "statstics", "chart", "table" ], },
  { "name": "tablet", "categories": [ "hardware" ], "tags": [], },
  { "name": "tablet_android", "categories": [ "hardware" ], "tags": [], },
  { "name": "tablet_mac", "categories": [ "hardware" ], "tags": [], },
  { "name": "tag_faces", "categories": [ "image" ], "tags": [], },
  { "name": "tap_and_play", "categories": [ "notification" ], "tags": [], },
  { "name": "terrain", "categories": [ "maps" ], "tags": [ "mountain", "geography", "terrain", "landscape" ], },
  { "name": "text_fields", "categories": [ "editor" ], "tags": [ "add", "input", "symbol", "character", "T", "field", "letter", "alphabet", "text", "type", "font" ], },
  { "name": "text_format", "categories": [ "content" ], "tags": [ "symbol", "character", "square A", "letter", "format", "style", "alphabet", "text", "type", "font" ], },
  { "name": "text_rotate_up", "categories": [ "action" ], "tags": [ "A", "rotate", "symbol", "move", "arrow", "alphabet", "type", "character", "field", "letter", "text", "up", "font" ], },
  { "name": "text_rotate_vertical", "categories": [ "action" ], "tags": [ "A", "rotate", "symbol", "move", "arrow", "alphabet", "type", "character", "field", "letter", "verticle", "text", "font" ], },
  { "name": "text_rotation_angledown", "categories": [ "action" ], "tags": [ "A", "rotate", "symbol", "move", "arrow", "alphabet", "type", "angledown", "character", "field", "letter", "text", "font" ], },
  { "name": "text_rotation_angleup", "categories": [ "action" ], "tags": [ "A", "angleup", "rotate", "symbol", "move", "arrow", "alphabet", "type", "character", "field", "letter", "text", "font" ], },
  { "name": "text_rotation_down", "categories": [ "action" ], "tags": [ "A", "rotate", "symbol", "move", "arrow", "alphabet", "type", "down", "character", "field", "letter", "text", "font" ], },
  { "name": "text_rotation_none", "categories": [ "action" ], "tags": [ "A", "rotate", "symbol", "move", "arrow", "alphabet", "none", "type", "character", "field", "letter", "text", "font" ], },
  { "name": "textsms", "categories": [ "communication" ], "tags": [ "bubble", "dots", "speech", "chat", "sms", "Text", "talk", "message" ], },
  { "name": "texture", "categories": [ "image" ], "tags": [], },
  { "name": "theaters", "categories": [ "action" ], "tags": [ "movies", "movie", "theaters", "watch", "theater", "show", "showtimes", "film" ], },
  { "name": "thumb_down", "categories": [ "action" ], "tags": [ "hands", "like", "rate", "dislike", "rating", "up", "fingers", "down", "favorite", "gesture", "hand" ], },
  { "name": "thumb_down_alt", "categories": [ "social" ], "tags": [], },
  { "name": "thumb_up", "categories": [ "action" ], "tags": [ "hands", "like", "rate", "dislike", "rating", "up", "fingers", "down", "favorite", "gesture", "hand" ], },
  { "name": "thumb_up_alt", "categories": [ "social" ], "tags": [], },
  { "name": "thumbs_up_down", "categories": [ "action" ], "tags": [ "hands", "like", "rate", "dislike", "rating", "up", "fingers", "down", "favorite", "gesture", "hand" ], },
  { "name": "time_to_leave", "categories": [ "notification" ], "tags": [], },
  { "name": "timelapse", "categories": [ "image" ], "tags": [], },
  { "name": "timeline", "categories": [ "action" ], "tags": [ "trending", "data", "line", "timeline", "history", "movement", "tracking", "point", "points", "zigzag" ], },
  { "name": "timer", "categories": [ "image" ], "tags": [], },
  { "name": "timer_10", "categories": [ "image" ], "tags": [], },
  { "name": "timer_3", "categories": [ "image" ], "tags": [], },
  { "name": "timer_off", "categories": [ "image" ], "tags": [], },
  { "name": "title", "categories": [ "editor" ], "tags": [ "symbol", "character", "t", "subject", "letter", "header", "alphabet", "text", "title", "type", "font" ], },
  { "name": "toc", "categories": [ "action" ], "tags": [ "stacked", "format", "reorder", "titles", "lines", "list", "title", "content", "table", "order" ], },
  { "name": "today", "categories": [ "action" ], "tags": [ "calendar", "date", "remember", "week", "month", "reminder", "today", "range", "event", "day", "mark" ], },
  { "name": "toggle_off", "categories": [ "toggle" ], "tags": [], },
  { "name": "toggle_on", "categories": [ "toggle" ], "tags": [], },
  { "name": "toll", "categories": [ "action" ], "tags": [ "ticket", "bill", "pay", "dollars", "commerce", "toll", "booth", "money", "car", "online", "currency", "payment", "credit", "highway", "cash", "card", "coin" ], },
  { "name": "tonality", "categories": [ "image" ], "tags": [], },
  { "name": "touch_app", "categories": [ "action" ], "tags": [ "hands", "arrow", "swipe", "touch", "fingers", "apps", "gesture", "hand" ], },
  { "name": "toys", "categories": [ "hardware" ], "tags": [], },
  { "name": "track_changes", "categories": [ "action" ], "tags": [ "evolve", "rotate", "shift", "changes", "circle", "lines", "track", "movement", "bullseye", "target" ], },
  { "name": "traffic", "categories": [ "maps" ], "tags": [ "light", "street", "map", "signal", "direction", "traffic" ], },
  { "name": "train", "categories": [ "maps" ], "tags": [ "automobile", "cars", "subway", "maps", "public", "car", "rail", "direction", "train", "transportation", "vehicle" ], },
  { "name": "tram", "categories": [ "maps" ], "tags": [ "automobile", "cars", "subway", "maps", "public", "car", "rail", "direction", "train", "transportation", "vehicle" ], },
  { "name": "transfer_within_a_station", "categories": [ "maps" ], "tags": [ "arrow", "right", "body", "people", "transportation", "vehicle", "route", "transfer", "public", "stop", "left", "person", "station", "human", "walk", "direction" ], },
  { "name": "transform", "categories": [ "image" ], "tags": [], },
  { "name": "transit_enterexit", "categories": [ "maps" ], "tags": [ "route", "public", "arrow", "map", "direction", "transportation", "vehicle" ], },
  { "name": "translate", "categories": [ "action" ], "tags": [ "speaking", "speech", "translator", "words", "language", "translate" ], },
  { "name": "trending_down", "categories": [ "action" ], "tags": [ "trending", "data", "arrow", "metric", "rate", "change", "track", "down", "movement", "tracking" ], },
  { "name": "trending_flat", "categories": [ "action" ], "tags": [ "trending", "data", "arrow", "metric", "rate", "flat", "change", "track", "movement", "tracking" ], },
  { "name": "trending_up", "categories": [ "action" ], "tags": [ "trending", "data", "arrow", "metric", "rate", "change", "up", "track", "movement", "tracking" ], },
  { "name": "trip_origin", "categories": [ "maps" ], "tags": [ "trip", "origin", "departure", "circle" ], },
  { "name": "tune", "categories": [ "image" ], "tags": [], },
  { "name": "turned_in", "categories": [ "action" ], "tags": [ "remember", "bookmark", "turned", "in", "save" ], },
  { "name": "turned_in_not", "categories": [ "action" ], "tags": [ "remember", "bookmark", "not", "outline", "turned", "in", "save" ], },
  { "name": "tv", "categories": [ "hardware" ], "tags": [], },
  { "name": "tv_off", "categories": [ "notification" ], "tags": [], },
  { "name": "two_wheeler", "categories": [ "maps" ], "tags": [ "automobile", "motorcycle", "cars", "maps", "public", "car", "scooter", "sport", "bike", "direction", "transportation", "vehicle" ], },
  { "name": "unarchive", "categories": [ "content" ], "tags": [ "undo", "mail", "arrow", "archive", "store", "up", "inbox" ], },
  { "name": "undo", "categories": [ "content" ], "tags": [ "rotate", "undo", "previous", "arrow", "repeat", "backward", "redo" ], },
  { "name": "unfold_less", "categories": [ "navigation" ], "tags": [], },
  { "name": "unfold_more", "categories": [ "navigation" ], "tags": [], },
  { "name": "unsubscribe", "categories": [ "communication" ], "tags": [ "newsletter", "mail", "subscribe", "Unsubscribe", "close", "envelop", "off" ], },
  { "name": "update", "categories": [ "action" ], "tags": [ "rotate", "arrow", "back", "refresh", "update", "clock", "history", "time", "reverse", "backwards" ], },
  { "name": "usb", "categories": [ "device" ], "tags": [ "wire", "usb", "connection", "cable", "device" ], },
  { "name": "verified_user", "categories": [ "action" ], "tags": [ "shield", "security", "audit", "checkmark", "verified", "certified", "check", "user" ], },
  { "name": "vertical_align_bottom", "categories": [ "editor" ], "tags": [ "editor", "arrow", "edit", "bottom", "align", "type", "down", "editing", "writing", "doc", "sheet", "text", "alignment", "speadsheet" ], },
  { "name": "vertical_align_center", "categories": [ "editor" ], "tags": [ "editor", "arrow", "edit", "center", "align", "type", "down", "editing", "writing", "doc", "sheet", "text", "up", "alignment", "speadsheet" ], },
  { "name": "vertical_align_top", "categories": [ "editor" ], "tags": [ "editor", "arrow", "edit", "align", "type", "editing", "top", "writing", "doc", "sheet", "text", "up", "alignment", "speadsheet" ], },
  { "name": "vertical_split", "categories": [ "action" ], "tags": [ "paragraph", "website", "split", "format", "vertical", "text" ], },
  { "name": "vibration", "categories": [ "notification" ], "tags": [], },
  { "name": "video_call", "categories": [ "av" ], "tags": [ "call", "conference", "Video", "chat", "screen", "camera", "plus" ], },
  { "name": "video_label", "categories": [ "av" ], "tags": [ "Video", "screen", "label", "device" ], },
  { "name": "video_library", "categories": [ "av" ], "tags": [ "play", "libary", "Video", "collection", "camera" ], },
  { "name": "videocam", "categories": [ "av" ], "tags": [ "conference", "Video", "chat", "screen", "camera" ], },
  { "name": "videocam_off", "categories": [ "av" ], "tags": [ "conference", "Video", "chat", "screen", "disabled", "slash", "camera", "enabled", "off", "on" ], },
  { "name": "videogame_asset", "categories": [ "hardware" ], "tags": [], },
  { "name": "view_agenda", "categories": [ "action" ], "tags": [ "view", "website", "cards", "stacked", "format", "agenda" ], },
  { "name": "view_array", "categories": [ "action" ], "tags": [ "view", "website", "array", "format" ], },
  { "name": "view_carousel", "categories": [ "action" ], "tags": [ "images", "view", "cards", "format", "banner", "carousel" ], },
  { "name": "view_column", "categories": [ "action" ], "tags": [ "view", "website", "grid", "column", "format", "vertical" ], },
  { "name": "view_comfy", "categories": [ "image" ], "tags": [], },
  { "name": "view_compact", "categories": [ "image" ], "tags": [], },
  { "name": "view_day", "categories": [ "action" ], "tags": [ "calendar", "view", "cards", "week", "format", "carousel", "day" ], },
  { "name": "view_headline", "categories": [ "action" ], "tags": [ "paragraph", "view", "website", "format", "text", "headline" ], },
  { "name": "view_list", "categories": [ "action" ], "tags": [ "view", "stacked", "format", "reorder", "lines", "list", "title", "order" ], },
  { "name": "view_module", "categories": [ "action" ], "tags": [ "square", "view", "stacked", "grid", "module", "format", "squares", "reorder", "title", "order" ], },
  { "name": "view_quilt", "categories": [ "action" ], "tags": [ "square", "quilt", "view", "stacked", "grid", "format", "squares", "reorder", "title", "order" ], },
  { "name": "view_stream", "categories": [ "action" ], "tags": [ "view", "stacked", "stream", "format", "reorder", "lines", "list", "title", "order" ], },
  { "name": "view_week", "categories": [ "action" ], "tags": [ "view", "week", "columns", "format", "day", "bars" ], },
  { "name": "vignette", "categories": [ "image" ], "tags": [], },
  { "name": "visibility", "categories": [ "action" ], "tags": [ "eye", "see", "view", "reveal", "show", "visability", "on" ], },
  { "name": "visibility_off", "categories": [ "action" ], "tags": [ "eye", "see", "view", "reveal", "show", "disabled", "slash", "enabled", "off", "visability", "on" ], },
  { "name": "voice_chat", "categories": [ "notification" ], "tags": [], },
  { "name": "voice_over_off", "categories": [ "action" ], "tags": [ "voice", "profile", "recording", "people", "enabled", "off", "face", "speaking", "transcript", "speech", "person", "disabled", "slash", "speak", "human", "user", "account", "on" ], },
  { "name": "voicemail", "categories": [ "communication" ], "tags": [ "call", "voice", "phone", "missed", "mobile", "Voicemail", "recording", "message", "device" ], },
  { "name": "volume_down", "categories": [ "av" ], "tags": [ "tv", "music", "Volume", "sound", "control", "audio", "down" ], },
  { "name": "volume_mute", "categories": [ "av" ], "tags": [ "tv", "music", "Volume", "sound", "control", "mute", "audio" ], },
  { "name": "volume_off", "categories": [ "av" ], "tags": [ "tv", "music", "Volume", "sound", "control", "disabled", "slash", "audio", "enabled", "off", "on" ], },
  { "name": "volume_up", "categories": [ "av" ], "tags": [ "tv", "music", "Volume", "sound", "control", "audio", "up" ], },
  { "name": "vpn_key", "categories": [ "communication" ], "tags": [ "password", "code", "unlock", "Vpn", "lock", "key", "passcode" ], },
  { "name": "vpn_lock", "categories": [ "notification" ], "tags": [], },
  { "name": "wallpaper", "categories": [ "device" ], "tags": [ "image", "wallpaper", "background", "landscape" ], },
  { "name": "warning", "categories": [ "alert" ], "tags": [ "important", "notification", "attention", "warning", "exclamation", "danger", "caution", "mark", "triangle" ], },
  { "name": "watch", "categories": [ "hardware" ], "tags": [], },
  { "name": "watch_later", "categories": [ "action" ], "tags": [ "hour", "watch", "clock", "time", "minute" ], },
  { "name": "waves", "categories": [ "content" ], "tags": [ "waves", "ocean", "beach", "pool", "water", "sea", "swim", "wave" ], },
  { "name": "wb_auto", "categories": [ "image" ], "tags": [], },
  { "name": "wb_cloudy", "categories": [ "image" ], "tags": [], },
  { "name": "wb_incandescent", "categories": [ "image" ], "tags": [], },
  { "name": "wb_iridescent", "categories": [ "image" ], "tags": [], },
  { "name": "wb_sunny", "categories": [ "image" ], "tags": [], },
  { "name": "wc", "categories": [ "notification" ], "tags": [], },
  { "name": "web", "categories": [ "av" ], "tags": [ "site", "website", "Web", "www", "browser", "screen", "page", "internet" ], },
  { "name": "web_asset", "categories": [ "av" ], "tags": [ "image", "download", "website", "Web", "www", "browser", "screen", "video", "asset", "internet" ], },
  { "name": "weekend", "categories": [ "content" ], "tags": [ "calendar", "next", "schedule", "living", "week", "lounge", "furniture", "chair", "weekend", "couch", "room" ], },
  { "name": "whatshot", "categories": [ "social" ], "tags": [], },
  { "name": "where_to_vote", "categories": [ "content" ], "tags": [ "ballot", "election", "pin", "checkmark", "location", "check", "poll", "vote" ], },
  { "name": "widgets", "categories": [ "device" ], "tags": [ "app", "square", "ui", "box", "menu", "widgets", "setting" ], },
  { "name": "wifi", "categories": [ "notification" ], "tags": [], },
  { "name": "wifi_lock", "categories": [ "device" ], "tags": [ "wifi", "celluar", "data", "phone", "mobile", "lock", "wireless", "cell", "internet", "speed", "network" ], },
  { "name": "wifi_off", "categories": [ "notification" ], "tags": [], },
  { "name": "wifi_tethering", "categories": [ "device" ], "tags": [ "wifi", "celluar", "data", "phone", "mobile", "thethering", "wireless", "cell", "internet", "speed", "network" ], },
  { "name": "work", "categories": [ "action" ], "tags": [ "suitcase", "briefcase", "work", "suit", "job" ], },
  { "name": "work_off", "categories": [ "action" ], "tags": [ "suitcase", "briefcase", "work", "disabled", "slash", "suit", "job", "enabled", "off", "on" ], },
  { "name": "work_outline", "categories": [ "action" ], "tags": [ "suitcase", "briefcase", "work", "suit", "job" ], },
  { "name": "wrap_text", "categories": [ "editor" ], "tags": [ "editor", "edit", "writing", "doc", "sheet", "text", "type", "wrap", "editing", "speadsheet" ], },
  { "name": "youtube_searched_for", "categories": [ "action" ], "tags": [ "searched", "youtube", "rotate", "search", "restore", "arrow", "back", "refresh", "reverse", "backwards" ], },
  { "name": "zoom_in", "categories": [ "action" ], "tags": [ "big", "glass", "grow", "size", "in", "bigger", "scale", "zoom", "magnify", "magnifying", "plus" ], },
  { "name": "zoom_out", "categories": [ "action" ], "tags": [ "small", "minus", "glass", "negative", "smaller", "size", "scale", "zoom", "magnify", "magnifying", "out" ], },
  { "name": "zoom_out_map", "categories": [ "maps" ], "tags": [ "move", "maps", "pin", "stop", "arrow", "in", "destination", "location", "zoom", "place", "out" ], }, 
]