var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{staticClass:"work-item my-2 pa-1",attrs:{"color":_vm.workItem.boardColor || '#fff'},on:{"click":function($event){return _vm.$emit('click')},"contextmenu":function($event){return _vm.$emit('clickRight', $event)},"mouseenter":function($event){return _vm.$emit('mouseenter')},"mouseleave":function($event){return _vm.$emit('mouseleave')}}},[_c('v-card-subtitle',{staticClass:"pa-1 pb-0",staticStyle:{"line-height":"1rem"}},[_c('div',{staticClass:"float-left"},[(_vm.workItem.type == 3)?_c('div',{staticClass:"task-badges"},[(_vm.workItem.showInOverview)?_c('v-tooltip',{attrs:{"top":"","content-class":"dense-tooltip"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-icon',_vm._g({attrs:{"color":'black',"small":""}},on),[_vm._v("mdi-file-chart-check-outline")])]}}],null,false,308285527)},[_vm._v(" In overview ")]):_vm._e(),(_vm.workItem.projectRiskId)?_c('v-tooltip',{attrs:{"top":"","content-class":"dense-tooltip"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-icon',_vm._g({attrs:{"color":'black',"small":""}},on),[_vm._v("mdi-clipboard-check-outline")])]}}],null,false,1121494460)},[_vm._v(" Risk action ")]):_vm._e()],1):_vm._e(),_c('div',{staticClass:"subtitle-right"},[(_vm.workItem.currentSprintId)?_c('div',{style:({
            background: 'transparent',
            boxSizing: 'border-box',
            border: ("2px solid " + (_vm.workItem.currentSprintColor)),
            color: _vm.workItem.currentSprintColor
          })},[_c('v-tooltip',{attrs:{"content-class":"dense-tooltip","z-index":"111","top":"","disabled":!_vm.getSprintById(_vm.workItem.currentSprintId).goal},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
          var on = ref.on;
return [(_vm.workItem.sprints.length > 1)?_c('v-icon',{staticClass:"ml-n1",attrs:{"color":_vm.workItem.currentSprintColor,"small":""}},[_vm._v(" mdi-plus ")]):_vm._e(),_c('span',_vm._g({staticStyle:{"font-size":"10px"}},on),[_vm._v(_vm._s(_vm.workItem.currentSprintName))])]}}],null,false,4143279688)},[_c('div',{staticStyle:{"white-space":"pre-wrap","word-break":"break-word","max-width":"280px"},domProps:{"innerHTML":_vm._s(_vm.getSprintById(_vm.workItem.currentSprintId) ? _vm.getSprintById(_vm.workItem.currentSprintId).goal : '')}})])],1):_c('div',{style:({
            background: 'transparent',
            boxSizing: 'border-box',
            border: "2px solid var(--v-secondary-base)",
            color: 'var(--v-secondary-base)'
          })},[_c('span',{staticStyle:{"font-size":"10px"}},[_vm._v("Backlog")])]),(_vm.workItem.totalHours != undefined && _vm.workItem.totalHours != null)?_c('div',{style:({
            background: 'transparent',
            boxSizing: 'border-box',
            border: ("2px solid " + (_vm.workItem.currentSprintId ? _vm.workItem.currentSprintColor : 'var(--v-primary-base)')),
            color: _vm.workItem.currentSprintId ? _vm.workItem.currentSprintColor : 'var(--v-primary-base)'
          })},[_c('span',{staticStyle:{"font-size":"10px"}},[_vm._v(" "+_vm._s(_vm.roundedTotalHours(_vm.workItem.totalHours) + ' hrs')+" ")])]):_vm._e(),_vm._l((_vm.shownTags),function(tag,ix){return _c('div',{key:ix,style:({
            background: _vm.getColorWithTransparency(tag.tagColor),
            boxSizing: 'border-box',
            border: ("2px solid " + (_vm.getColorWithTransparency(tag.borderColor))),
            color:  tag.borderColor
          })},[_c('v-tooltip',{attrs:{"content-class":"dense-tooltip","z-index":"111","top":"","disabled":!tag.tagDescription || !JSON.parse(tag.tagDescription)},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
          var on = ref.on;
return [_c('span',_vm._g({staticStyle:{"font-size":"10px"}},on),[_vm._v(" "+_vm._s(tag.tagName)+" ")])]}}],null,true)},[_c('div',{staticStyle:{"white-space":"pre-wrap","word-break":"break-word","max-width":"280px"},domProps:{"innerHTML":_vm._s(tag.tagDescription ? JSON.parse(tag.tagDescription) : '')}})])],1)})],2)]),_c('div',{staticClass:"clearfix"})]),_c('v-card-title',{staticClass:"px-1 pt-1 pb-0 summary body-2 grey--text text--darken-4"},[_vm._v(" "+_vm._s(_vm.workItem.name)+" ")]),_c('v-card-text',{staticClass:"px-1 py-0"},[_c('div',{staticClass:"avatar-priority-container"},[_c('div',{staticClass:"priority-code-container"},[(_vm.workItem.priority)?_c('v-tooltip',{attrs:{"content-class":"dense-tooltip","top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
          var on = ref.on;
return [_c('img',_vm._g({staticStyle:{"flex":"none"},attrs:{"width":_vm.workItem.priority == 3 ? '28px' : '20px',"height":_vm.workItem.priority == 3 ? '28px' : '22px',"src":require(("../assets/priority-icons/" + (_vm.workItem.priority) + ".png"))}},on))]}}],null,false,2608240034)},[_c('span',[_vm._v("Priority: "+_vm._s(_vm.workItem.priorityName))])]):_vm._e(),_c('div',{staticClass:"text-sm grey--text text--darken-1",staticStyle:{"line-height":"24px","font-size":".95em","display":"flex","align-items":"center"}},[_c('span',[_vm._v(" "+_vm._s(_vm.workItem.code)+" ")])])],1),_c('user-avatar',{attrs:{"userId":_vm.workItem.assignedToId,"size":24,"hideLoading":""}})],1),(_vm.workItem.dueDate)?_c('div',{staticClass:"caption grey--text text--darken-1"},[_vm._v(" Due Date: "+_vm._s(_vm._f("formatDate")(_vm.workItem.dueDate))+" ")]):_vm._e(),_c('v-divider',{staticClass:"mt-2",attrs:{"light":""}})],1),_c('v-card-actions',{staticClass:"pa-0 "},[_c('div',{staticClass:"actions-box"},[_c('div',{staticClass:"indicators black--text"},[(_vm.workItem._selected)?_c('v-icon',{staticClass:"multiselect-icon",attrs:{"color":"accent"}},[_vm._v("mdi-check-circle")]):(_vm.someSelected)?_c('v-icon',{staticClass:"multiselect-icon"},[_vm._v("mdi-checkbox-blank-circle-outline")]):_vm._e(),_c('v-tooltip',{attrs:{"right":"","content-class":"dense-tooltip"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
          var on = ref.on;
return [_c('div',_vm._g({},on),[_c('v-icon',{staticStyle:{"color":"#616161"},attrs:{"small":""}},[_vm._v(" mdi-progress-clock ")]),_c('span',{staticClass:"grey--text text--darken-2",staticStyle:{"white-space":"nowrap"}},[_vm._v(" "+_vm._s(_vm.getIndicatorTime( (_vm.isNullDate(_vm.workItem.initiatedAt) || _vm.workItem.leadTime) ? null : _vm.workItem.age, false, _vm.usingWorkingHours ))+" ")])],1)]}}])},[_vm._v(" Work Item Age ")]),(!_vm.status.isFinal)?_c('v-tooltip',{attrs:{"right":"","content-class":"dense-tooltip"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
          var on = ref.on;
return [_c('div',_vm._g({},on),[_c('v-icon',{staticStyle:{"color":"#616161"},attrs:{"small":""}},[_vm._v("mdi-clock-time-four-outline")]),_c('span',{staticClass:"grey--text text--darken-2"},[_vm._v(" "+_vm._s(_vm.getIndicatorTime(_vm.workItem.currentColumnAge, false, _vm.usingWorkingHours))+" ")])],1)]}}],null,false,113818330)},[_vm._v(" Current Columns WIA"),_c('br'),_vm._v("(Work Item Age) ")]):_vm._e(),(_vm.status.isFinal)?_c('v-tooltip',{attrs:{"right":"","content-class":"dense-tooltip"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
          var on = ref.on;
return [_c('div',_vm._g({},on),[_c('v-icon',{style:({
                  color: _vm.leadTimeGoal.leadTimeGoal ?
                    (_vm.toMinutes(_vm.leadTimeGoal.leadTimeGoal, _vm.leadTimeGoal.leadTimeGoalUnit, _vm.usingWorkingHours) < _vm.workItem.leadTime ? 'red' : '#616161' )
                    : '#616161'
                  }),attrs:{"small":""}},[_vm._v(" mdi-alarm ")]),_c('span',{staticStyle:{"white-space":"nowrap"},style:({
                  color: _vm.leadTimeGoal.leadTimeGoal ?
                    (_vm.toMinutes(_vm.leadTimeGoal.leadTimeGoal, _vm.leadTimeGoal.leadTimeGoalUnit, _vm.usingWorkingHours) < _vm.workItem.leadTime ? 'red' : '#616161' )
                    : '#616161'
                })},[_vm._v(" "+_vm._s(_vm.getIndicatorTime( (_vm.isNullDate(_vm.workItem.initiatedAt) || _vm.workItem.age || !_vm.status.isFinal) ? null : _vm.workItem.leadTime, false, _vm.usingWorkingHours ))+" ")])],1)]}}],null,false,2680990950)},[_vm._v(" Lead Time ")]):_vm._e()],1)])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }