
import ProjectList from './pages/project/ProjectList'
import ProjectDetails from './pages/project/ProjectDetails'
import ProjectBasicDetails from './pages/project/ProjectBasicDetails'
import ProjectBoard from './pages/project/ProjectBoard'
import ProjectWorkItems from './pages/project/ProjectWorkItems'
import ProjectTraceability from './pages/project/ProjectTraceability'
import ProjectRiskManagement from './pages/project/ProjectRiskManagement'
import ProjectConfig from './pages/project/ProjectConfig'
import ProjectLinks from './pages/project/ProjectLinks'
import ProjectUsersPermissions from './pages/project/ProjectUsersPermissions'
import ProjectMembers from './pages/project/ProjectMembers'
import ProjectDashboard from './pages/project/ProjectDashboard'
import ProjectNucleiManagement from './pages/project/ProjectNucleiManagement'
import ProjectAccount from './pages/project/ProjectAccount'
import ProjectHealthchecks from './pages/project/ProjectHealthchecks'
import ProjectActivityLog from './pages/project/ProjectActivityLog'
import ProjectOverview from './pages/project/ProjectOverview'

import ExternalProjectList from './pages/external/ExternalProjectList'
import ExternalProjectDetails from './pages/external/ExternalProjectDetails'
import ExternalAccountBalance from './pages/external/ExternalAccountBalance'

import ProjectOnePagerList from './pages/projectOnePager/ProjectOnePagerList'

import MyKanban from './pages/myKanban/MyKanban'

import WorkItemList from './pages/workItem/WorkItemList'

import BadgesReport from './pages/badges/BadgesReport'
import DailyHoursReport from './pages/reports/DailyHoursReport'
import OnTimeReportingReport from './pages/reports/OnTimeReportingReport'
import ActivityLogReport from './pages/reports/ActivityLogReport'
import ActionLogReport from './pages/reports/ActionLogReport'
import TeamMemberReport from './pages/reports/TeamMemberReport'
import RecessDaysReport from './pages/reports/RecessDaysReport'
import PermissionsReport from './pages/reports/PermissionsReport'
import ExecutedHoursReport from './pages/reports/ExecutedHoursReport'
import RPEHReport from './pages/reports/RPEHReport'

import DailyReportList from './pages/dailyReport/DailyReportList'
import DailyReportEdit from './pages/dailyReport/DailyReportEdit'

import CategoryList from './pages/category/CategoryList'
import EditCustomer from './pages/customer/EditCustomer'
import CustomerList from './pages/customer/CustomerList'

import ProfilePage from './pages/profile/ProfilePage'
import UserDirectory from './pages/profile/UserDirectory'
import PeopleMap from './pages/profile/PeopleMap'

import Board from './pages/adminSurf/Board'
import Assignments from './pages/adminSurf/Assignments'
import Billable from './pages/adminSurf/Billable'
import BillableDetails from './pages/adminSurf/BillableDetails'
import BillableExecutedHours from './pages/adminSurf/BillableExecutedHours'
import BillableItem from './pages/adminSurf/BillableItem'
import BillableItemDetails from './pages/adminSurf/BillableItemDetails'
import CreateBillableItem from './pages/adminSurf/CreateBillableItem'
import EditBillable from './pages/adminSurf/EditBillable'
import Invoice from './pages/adminSurf/Invoice'
import InvoiceDetails from './pages/adminSurf/InvoiceDetails'
import CreditNote from './pages/adminSurf/CreditNote'
import Payment from './pages/adminSurf/Payment'
import PaymentDetails from './pages/adminSurf/PaymentDetails'
import ManageAccounts from './pages/adminSurf/ManageAccounts'
import RevenueReport from './pages/adminSurf/RevenueReport'
import BillingReport from './pages/adminSurf/BillingReport'
import ValidationReport from './pages/adminSurf/ValidationReport'
import Supplier from './pages/adminSurf/Supplier'
import SupplierDetails from './pages/adminSurf/SupplierDetails'
import SupplierInvoice from './pages/adminSurf/SupplierInvoice'
import SupplierInvoiceDetails from './pages/adminSurf/SupplierInvoiceDetails'
import AdminSurfConfig from './pages/adminSurf/AdminSurfConfig'

import BadgesConfig from './pages/badges/BadgesConfig'

import UserList from './pages/user/UserList'
import UserEdit from './pages/user/UserEdit'

import UserGroupList from './pages/userGroup/UserGroupList'
import UserGroupEdit from './pages/userGroup/UserGroupEdit'

import ProjectTypeList from './pages/projectType/ProjectTypeList'

import loginRoot from './components/LoginRoot';
import ForbiddenPage from './pages/ForbiddenPage';

import RecessConfigList from './pages/recess/RecessConfigList';
import RecessConfigEdit from './pages/recess/RecessConfigEdit';
import RecessRequestList from './pages/recess/RecessRequestList';
import RecessRequests from './pages/recess/RecessRequests';

export const routes = [
    
    { props: true, path: '/login', name: 'Login', component: loginRoot, meta: { allowAnonymous: true } },
    { props: true, path: '/setPassword', component: loginRoot, meta: { allowAnonymous: true } },
    { props: true, path: '/resetPassword', component: loginRoot, meta: { allowAnonymous: true } },

    { props: true, path: '/project', component: ProjectList, meta: { permission: 1, title: 'Projects' } },
    { props: true, path: '/project/new', name: 'ProjectCreate', component: ProjectBasicDetails, meta: { permission: 1, requiredLevel: 'add', title: 'New Project', addPreviousRedirect: true }, props: route => ({ ...route.query, ...route.params }) },
    { 
        props: true, 
        path: '/project/:id', 
        name: 'ProjectDetails', 
        component: ProjectDetails,
        meta: { permission: 1, requiredLevel: 'detail' },
        children: [
            { props: true, path: 'details', name: 'ProjectDetails.Basic', component: ProjectBasicDetails },
            { path: 'workItems', name: 'ProjectWorkItems', component: ProjectWorkItems, props: route => ({ ...route.query, ...route.params }) },
            { path: 'board', name: 'ProjectBoard', component: ProjectBoard, props: route => ({ ...route.query, ...route.params }) },
            { props: true, path: 'dashboard', name: 'ProjectDashboard', component: ProjectDashboard },
            { props: true, path: 'nucleiManagement', name: 'NucleiManagement', component: ProjectNucleiManagement },
            { props: true, path: 'account', name: 'ProjectAccount', component: ProjectAccount },
            { props: true, path: 'healthchecks', name: 'ProjectHealthchecks', component: ProjectHealthchecks },
            { props: true, path: 'traceability', name: 'ProjectTraceability', component: ProjectTraceability },
            { props: true, path: 'riskManagement', name: 'ProjectRiskManagement', component: ProjectRiskManagement },
            { props: true, path: 'config', name: 'ProjectConfig', component: ProjectConfig },
            { props: true, path: 'links', name: 'ProjectLinks', component: ProjectLinks },
            { props: true, path: 'usersPermissions', name: 'ProjectUsersPermissions', component: ProjectUsersPermissions },
            { props: true, path: 'members', name: 'ProjectMembers', component: ProjectMembers },
            { props: true, path: 'activityLog', name: 'ProjectActivityLog', component: ProjectActivityLog },
            { props: true, path: 'overview', name: 'ProjectOverview', component: ProjectOverview },
        ]
    },
    { props: true, path: '/risks', component: ProjectRiskManagement, meta: { permission: 2, title: 'Risks' } },
    
    { props: true, path: '/external/project', component: ExternalProjectList, meta: { allowExternal: true, title: 'Your Projects' } },
    { props: true, path: '/external/accountBalance', component: ExternalAccountBalance, meta: { allowExternal: true, title: 'Account Balance' } },
    {
        path: '/external/', 
        name: 'ExternalProjectDetails', 
        component: ExternalProjectDetails,
        meta: { permission: 1, allowExternal: true },
        props: route => ({ ...route.query, ...route.params }),
        children: [
            { props: true, path: 'workItems', name: 'ProjectWorkItems', component: ProjectWorkItems, meta: { title: 'Work Items' } },
            { props: true, path: 'dashboard', name: 'ProjectDashboard', component: ProjectDashboard, meta: { title: 'Dashboard' } },
            { props: true, path: 'account', name: 'ProjectAccount', component: ProjectAccount, meta: { title: 'Account' } },
            { props: true, path: 'healthchecks', name: 'ProjectHealthchecks', component: ProjectHealthchecks, meta: { title: 'Healthchecks' } },
            { props: true, path: 'riskManagement', name: 'ProjectRiskManagement', component: ProjectRiskManagement, meta: { title: 'Risks' } },
            { props: true, path: 'links', name: 'ProjectLinks', component: ProjectLinks, meta: { title: 'Links' } },
            { props: true, path: 'members', name: 'ProjectMembers', component: ProjectMembers, meta: { title: 'Team Members' } },
            { props: true, path: 'activityLog', name: 'ProjectActivityLog', component: ProjectActivityLog, meta: { title: 'Activity Log' } },
            { props: true, path: 'overview', name: 'ProjectOverview', component: ProjectOverview, meta: { title: 'Overview' } },
        ]
    },
    { 
        props: true, 
        path: '/myKanban', 
        name: 'MyKanban', 
        component: MyKanban,
        meta: { title: 'My Kanban' },
        children: [
            { props: true, path: '', name: 'MyWorkItems', component: ProjectWorkItems },
            { props: true, path: 'links', name: 'MyLinks', component: ProjectLinks },
            { props: true, path: 'config', name: 'MyProjectConfig', component: ProjectConfig },
        ]
    },

    { props: true, path: '/workItem', name: 'WorkItemList', component: WorkItemList, meta: { permission: 0, title: 'Work Items' } },

    { props: true, path: '/report/badges', component: BadgesReport, meta: { permission: 18, title: 'Badges Report' } },
    { props: true, path: '/report/dailyHours', component: DailyHoursReport, meta: { permission: 5, title: 'Report - Daily Hours Reported' } },
    { props: true, path: '/report/onTimeReporting', component: OnTimeReportingReport, meta: { permission: 6, title: 'Report - On-time Reporting' } },
    { props: true, path: '/report/activityLog', component: ActivityLogReport, meta: { permission: 7, title: 'Report - Activity Log' } },
    { props: true, path: '/report/logReport', component: ActionLogReport, meta: { permission: 31, title: 'Log Report' } },
    { props: true, path: '/report/teamMemberReport', component: TeamMemberReport, meta: { permission: 34, title: 'Report - Team Members' } },
    { props: true, path: '/report/recessDaysReport', component: RecessDaysReport, meta: { permission: 35, title: 'Vacations' } },
    { props: true, path: '/report/onePager', component: ProjectOnePagerList, meta: { permission: 41, title: 'One Pager' } },
    { props: true, path: '/report/permissions', component: PermissionsReport, meta: { permission: 43, title: 'User Permissions' } },
    { props: true, path: '/report/executedHours', component: ExecutedHoursReport, meta: { permission: 44, title: 'Executed Hours' } },
    { props: true, path: '/report/RPEHReport', component: RPEHReport, meta: { permission: 46, title: 'RPEH Report' } },

    { props: true, path: '/dailyReport', name: 'DailyReportList', component: DailyReportList, meta: { permission: 3, title: 'My Daily Reports' } },
    { props: true, path: '/dailyReport/:date', name: 'DailyReportEdit', component: DailyReportEdit, meta: { permission: 3, title: 'Daily Report', addPreviousRedirect: true } },

    { props: true, path: '/category', name: 'CategoryList', component: CategoryList, meta: { permission: 13, title: 'Categories' } },
    { props: true, path: '/map', name: 'PeopleMap', component: PeopleMap, meta: { permission: 19, title: 'Blue People around the world' } },


    //#region ADMIN
    { props: true, path: '/admin/board', name: 'AdminBoard', component: Board, meta: { permission: 23, title: 'Board' } },

    { props: true, path: '/admin/billable', name: 'Billables', component: Billable, meta: { permission: 24, title: 'Billables' }, props: route => ({ ...route.query, ...route.params }) },
    { props: true, path: '/admin/billable/:id', name: 'EditBillable', component: EditBillable, meta: { permission: 24, requiredLevel: 'add', addPreviousRedirect: true } },
    { props: true, path: '/admin/billable/details/:id', name: 'BillableDetails', component: BillableDetails, meta: { permission: 24, requiredLevel: 'detail', title: 'Billable Details', addPreviousRedirect: true } },
    { props: true, path: '/admin/billable/executedHours/:id', name: 'BillableExecutedHours', component: BillableExecutedHours, meta: { permission: 24, requiredLevel: 'detail', title: 'Executed Hours', addPreviousRedirect: true } },
    
    { props: true, path: '/admin/assignment', name: 'Assignments', component: Assignments, meta: { permission: 25, title: 'Assignments' } },
    
    { props: true, path: '/admin/billableItem', name: 'BillableItems', component: BillableItem, meta: { permission: 26, title: 'Billable Items' }, props: route => ({ ...route.query, ...route.params }) },
    { props: true, path: '/admin/billableItem/new', name: 'CreateBillableItem', component: CreateBillableItem, meta: { permission: 26, requiredLevel: 'add', addPreviousRedirect: true } },
    { props: true, path: '/admin/billableItem/:id', name: 'BillableItemDetails', component: BillableItemDetails, meta: { permission: 26, requiredLevel: 'detail', addPreviousRedirect: true } },

    { props: true, path: '/admin/customer', name: 'CustomerList', component: CustomerList, meta: { permission: 27, title: 'Customers' } },
    { props: true, path: '/admin/customer/:id', name: 'EditCustomer', component: EditCustomer, meta: { permission: 27, requiredLevel: 'detail', addPreviousRedirect: true } },

    { props: true, path: '/admin/invoice', name: 'Invoice', component: Invoice, meta: { permission: 28, title: 'Invoices' }, props: route => ({ ...route.query, ...route.params }) },
    { props: true, path: '/admin/invoice/:id', name: 'InvoiceDetails', component: InvoiceDetails, meta: { permission: 28, requiredLevel: 'detail', addPreviousRedirect: true }, props: route => ({ ...route.query, ...route.params }) },
    { props: true, path: '/admin/creditNote/:invoiceId', name: 'CreditNote', component: CreditNote, meta: { permission: 28, requiredLevel: 'detail', addPreviousRedirect: true }, props: route => ({ ...route.query, ...route.params }) },
    
    { props: true, path: '/admin/payment', name: 'Payment', component: Payment, meta: { permission: 29, title: 'Payments' }, props: route => ({ ...route.query, ...route.params }) },
    { props: true, path: '/admin/payment/:id', name: 'PaymentDetails', component: PaymentDetails, meta: { permission: 29, requiredLevel: 'detail', addPreviousRedirect: true }, props: route => ({ ...route.query, ...route.params }) },
    { props: true, path: '/admin/manageAccounts', name: 'ManageAccounts', component: ManageAccounts, meta: { permission: 29, requiredLevel: 'detail', addPreviousRedirect: true, title: 'Manage Accounts' } },

    { props: true, path: '/admin/supplier', name: 'SupplierList', component: Supplier, meta: { permission: 39, title: 'Suppliers' }, props: route => ({ ...route.query, ...route.params }) },
    { props: true, path: '/admin/supplier/:id', name: 'SupplierDetails', component: SupplierDetails, meta: { permission: 39, requiredLevel: 'detail', addPreviousRedirect: true }, props: route => ({ ...route.query, ...route.params }) },

    { props: true, path: '/admin/supplierInvoice', name: 'SupplierInvoice', component: SupplierInvoice, meta: { permission: 40, title: 'Supplier Invoices' }, props: route => ({ ...route.query, ...route.params }) },
    { props: true, path: '/admin/supplierInvoice/:id', name: 'SupplierInvoiceDetails', component: SupplierInvoiceDetails, meta: { permission: 40, requiredLevel: 'detail', addPreviousRedirect: true }, props: route => ({ ...route.query, ...route.params }) },

    { props: true, path: '/admin/revenue', name: 'Revenue', component: RevenueReport, meta: { permission: 30, title: 'Revenue' }, props: route => ({ ...route.query, ...route.params }) },

    { props: true, path: '/admin/billing', name: 'Billing Report', component: BillingReport, meta: { permission: 47, title: 'Billing Report' } },

    { props: true, path: '/admin/validation', name: 'Validation Report', component: ValidationReport, meta: { permission: 45, title: 'Validation Report' } },

    { props: true, path: '/admin/config', name: 'AdminSurfConfig', component: AdminSurfConfig, meta: { permission: 42, title: 'Admin Surf Config' } },
    //#endregion ADMIN
    
    
    { props: true, path: '/profile/directory', name: 'UserDirectory', component: UserDirectory, meta: { permission: 4, title: 'Directory' } },
    { props: true, path: '/profile/:id', name: 'ProfilePage', component: ProfilePage },

    { props: true, path: '/badges', name: 'BadgesConfig', component: BadgesConfig, meta: { permission: 17, title: 'Badges Config' } },

    { props: true, path: '/recess/config', name: 'RecessConfig', component: RecessConfigList, meta: { permission: 32, title: 'Recess Configurations' } },
    { props: route => ({ ...route.query, ...route.params }), path: '/recess/config/:id', name: 'RecessConfigItem', component: RecessConfigEdit, meta: { permission: 32, title: 'Recess Configuration', addPreviousRedirect: true  }},
    { props: true, path: '/recess/requests/:id', name: 'RecessRequestList', component: RecessRequestList, meta: { title: 'Recess Requests', addPreviousRedirect: true  }},
    { props: true, path: '/recess/requests', name: 'RecessRequests', component: RecessRequests, meta: { permission: 36, title: 'Recess Requests' }, props: route => ({ ...route.query, ...route.params })},

    { props: true, path: '/user', component: UserList, meta: { permission: 14, title: 'People' } },
    { props: true, path: '/user/new', name: 'UserCreate', component: UserEdit, meta: { permission: 14, requiredLevel: 'add', addPreviousRedirect: true, title: 'Create User' } },
    { props: true, path: '/user/:id/edit', name: 'UserEdit', component: UserEdit, meta: { permission: 14, requiredLevel: 'detail', addPreviousRedirect: true, title: 'Edit User' } },

    { props: true, path: '/userGroup', component: UserGroupList, meta: { permission: 15, title: 'User Groups' }  },
    { props: true, path: '/userGroup/new', name: 'UserGroupCreate', component: UserGroupEdit, meta: { permission: 15, requiredLevel: 'add', title: 'User Group' } },
    { props: true, path: '/userGroup/:id/edit', name: 'UserGroupEdit', component: UserGroupEdit, meta: { permission: 15, requiredLevel: 'detail', title: 'User Group' } },

    { props: true, path: '/projectTypes', name: 'ProjectTypes', component: ProjectTypeList, meta: { permission: 38, requiredLevel: 'list', title: 'Project Types' } },

    { props: true, path: '/forbidden', component: ForbiddenPage, name: 'ForbiddenPage' },
]