<template>
	<div>
		<support-chat
			v-if="chat"
			v-model="chat"
			:is-admin="isAdmin" :chat="chat || {messages: []}"
			@close="$emit('close')"
		/>
		<support-chats-list
			v-else
			v-model="chat"
			:chats="chats"
			@close="$emit('close')"
		/>
	</div>
</template>

<script>
import SupportChat from './SupportChat'
import SupportChatsList from './SupportChatsList'

export default {
  components: { SupportChat, SupportChatsList },
	data: () => ({
		chat: null,
		chats: [],
		isAdmin: false,
		socket: null,
	}),
	mounted(){
		this.socketConnection()
	},
	beforeDestroy(){
		this.socket.invoke('LeaveGroup')
	},
	methods: {
		init(){
			this.$http.get(`/api/instance/currentUser/permissions`)
				.then(res => {
					this.isAdmin = res.data.permissions.some(p => p.type == 22 && p.canView)

					if(!this.isAdmin){
						this.$http.get('api/support/chat')
							.then(res => {
								this.chat = res.data
								this.updatePendings()
							})
							.catch(err => {
								console.error(err)
								this.$root.error('Cannot get chat')
							})
					}
					else{
						this.$http.get('api/support/chats')
							.then(res => {
								this.chats = res.data
								this.updatePendings()
							})
							.catch(err => {
								console.error(err)
								this.$root.error('Cannot get chats')
							})
					}
				})
		},
		updateChat(userChatId){
			this.$http.get(`api/support/chat/${userChatId}`, { headers: { hideLoading: true }})
				.then(res => {
					this.chats = this.chats.filter(x => x.id != res.data.id)
					this.chats.unshift(res.data)
					if(this.chat && this.chat.id == res.data.id){
						this.chat = res.data
					}
					this.updatePendings()
				})
		},
		updatePendings(){
			if(this.isAdmin){
				this.chats.forEach(x => {
					x.unreadChatsMessages = 0
					for(let message of [...x.messages].reverse()){
						if(!message.type)
							x.unreadChatsMessages++
						else break
					}
				})
				this.$emit('update:pending', this.chats.filter(x => x.isActive && x.unreadChatsMessages).length)
			}
			else{
				if(!this.chat.isActive){
					this.$emit('update:pending', 0)
					return
				}
				let unreadChatsMessages = 0
				for(let message of [...this.chat.messages].reverse()){
					if(message.type == 1)
						unreadChatsMessages++
					else break
				}
				if(this.chat.isRequestingClosure) unreadChatsMessages++
				this.$emit('update:pending', unreadChatsMessages)
			}
		},
		handleNewMessage(messageOrUserChatId){
			if(this.isAdmin){
				this.updateChat(messageOrUserChatId)
			}
			else{
				this.chat.messages.push(messageOrUserChatId)
				this.updatePendings()
			}
		},
		handleClosureRequest(userChatId){
			this.$set(this.chat, 'isRequestingClosure', true)
			this.chat.isRequestingClosure = true
			this.updatePendings()
		},
		handleResolveCloseRequest(response, message){
			this.$set(this.chat, 'isActive', !response)
			this.$set(this.chat, 'isRequestingClosure', false)
			this.chat.messages.push(message)
			this.updatePendings()
		},
    socketConnection(){
      try{
        let backend = this.$http.defaults.baseURL
        const signalR = require("@microsoft/signalr")

        if(!backend.endsWith("/"))
          backend += "/";
  
        this.socket = new signalR.HubConnectionBuilder()
          .withUrl(`${backend}api/hub/support`)
          .withAutomaticReconnect({
            nextRetryDelayInMilliseconds: () => {
              return Math.random() * 10000;
            }
          })
          .build();
        this.socket.start()

      } catch(e){
        console.log(e)
      }
    },
	},
	watch: {
		'socket.state'(v){
			if(v == 'Connected'){
				this.init()
				this.socket.invoke('JoinGroup')
				this.socket.off('NewMessage')
				this.socket.off('RequestClosure')
				this.socket.off('ResolveCloseRequest')
				this.socket.on('NewMessage', this.handleNewMessage)
				this.socket.on('RequestClosure', this.handleClosureRequest)
				this.socket.on('ResolveCloseRequest', this.handleResolveCloseRequest)
			}
		},
	},
}
</script>

<style lang="scss" scoped>

</style>