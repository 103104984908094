<template>
  <v-card
    class="work-item my-2 pa-1"
    @click="$emit('click')"
    @click.right="$emit('clickRight', $event)"
    @mouseenter="$emit('mouseenter')"
    @mouseleave="$emit('mouseleave')"
    :color="workItem.boardColor || '#fff'"
  >
    <v-card-subtitle
      class="pa-1 pb-0"
      style="line-height: 1rem"
    >
      <div class="float-left">
        <div v-if="workItem.type == 3" class="task-badges">
          <v-tooltip v-if="workItem.showInOverview" top content-class="dense-tooltip">
            <template #activator="{ on }">
              <v-icon v-on="on" :color="'black'" small>mdi-file-chart-check-outline</v-icon>
            </template>
            In overview
          </v-tooltip>
          <v-tooltip v-if="workItem.projectRiskId" top content-class="dense-tooltip">
            <template #activator="{ on }">
              <v-icon v-on="on" :color="'black'" small>mdi-clipboard-check-outline</v-icon>
            </template>
            Risk action
          </v-tooltip>
        </div>
        <div class="subtitle-right">
          <div v-if="workItem.currentSprintId"
            :style="{
              background: 'transparent',
              boxSizing: 'border-box',
              border: `2px solid ${workItem.currentSprintColor}`,
              color: workItem.currentSprintColor
            }"
          >
            <v-tooltip content-class="dense-tooltip" z-index="111" top :disabled="!getSprintById(workItem.currentSprintId).goal">
              <template v-slot:activator="{ on }">
                <v-icon v-if="workItem.sprints.length > 1" :color="workItem.currentSprintColor" small class="ml-n1">
                  mdi-plus
                </v-icon>
                <span style="font-size: 10px" v-on="on">{{workItem.currentSprintName}}</span>
              </template>
              <div
                style="white-space:pre-wrap; word-break:break-word; max-width: 280px;" 
                v-html="getSprintById(workItem.currentSprintId) ? getSprintById(workItem.currentSprintId).goal : ''"
              >
              </div>
            </v-tooltip>
          </div>
          <div v-else
            :style="{
              background: 'transparent',
              boxSizing: 'border-box',
              border: `2px solid var(--v-secondary-base)`,
              color: 'var(--v-secondary-base)'
            }"
          >
            <span style="font-size: 10px">Backlog</span>
          </div>

          <div
            v-if="workItem.totalHours != undefined && workItem.totalHours != null"
            :style="{
              background: 'transparent',
              boxSizing: 'border-box',
              border: `2px solid ${workItem.currentSprintId ? workItem.currentSprintColor : 'var(--v-primary-base)'}`,
              color: workItem.currentSprintId ? workItem.currentSprintColor : 'var(--v-primary-base)'
            }"
          >
            <span style="font-size: 10px"> {{roundedTotalHours(workItem.totalHours) + ' hrs'}} </span>
          </div>
          <div
            v-for="(tag, ix) in shownTags"
            :key="ix"
            
            :style="{
              background: getColorWithTransparency(tag.tagColor),
              boxSizing: 'border-box',
              border: `2px solid ${getColorWithTransparency(tag.borderColor)}`,
              color:  tag.borderColor
            }"
          > 
            <v-tooltip
              content-class="dense-tooltip"
              z-index="111"
              top
              :disabled="!tag.tagDescription || !JSON.parse(tag.tagDescription)"
            >
              <template v-slot:activator="{ on }">
                <span v-on="on" style="font-size: 10px">
                  {{tag.tagName}}
                </span>
              </template>
              <div
                style="white-space:pre-wrap; word-break:break-word; max-width: 280px;"
                v-html="tag.tagDescription ? JSON.parse(tag.tagDescription) : ''"
              >
              </div>
            </v-tooltip>
          </div>
        </div>
      </div>
      <div class="clearfix"></div>
    </v-card-subtitle>
    <v-card-title class="px-1 pt-1 pb-0 summary body-2 grey--text text--darken-4">
      {{workItem.name}}
    </v-card-title>
    <v-card-text class="px-1 py-0">
      <div class="avatar-priority-container">
        <div class="priority-code-container">
          <v-tooltip v-if="workItem.priority" content-class="dense-tooltip" top>
            <template #activator="{ on }">
              <img
                v-on="on" style="flex: none"
                :width="workItem.priority == 3 ? '28px' : '20px'"
                :height="workItem.priority == 3 ? '28px' : '22px'"
                :src="require(`../assets/priority-icons/${workItem.priority}.png`)"
              />
            </template>
            <span>Priority: {{workItem.priorityName}}</span>
          </v-tooltip>
          <div class="text-sm grey--text text--darken-1" style="line-height: 24px; font-size: .95em; display: flex; align-items: center">
            <span>
              {{workItem.code}}
            </span>
          </div>
        </div>
        <user-avatar :userId="workItem.assignedToId" :size="24" hideLoading></user-avatar>
      </div>
      <div v-if="workItem.dueDate" class="caption grey--text text--darken-1">
        Due Date: {{ workItem.dueDate | formatDate }}
      </div>
      <v-divider light class="mt-2"></v-divider>
    </v-card-text>
    <v-card-actions class="pa-0 ">
      <div class="actions-box">
        <div class="indicators black--text">
          <v-icon v-if="workItem._selected" class="multiselect-icon" color="accent" >mdi-check-circle</v-icon>
          <v-icon v-else-if="someSelected" class="multiselect-icon">mdi-checkbox-blank-circle-outline</v-icon>
          <v-tooltip right content-class="dense-tooltip">
            <template v-slot:activator="{ on }">
              <div v-on="on">
                <v-icon
                  style="color: #616161" small
                >
                  mdi-progress-clock
                </v-icon>
                <span style="white-space: nowrap" class="grey--text text--darken-2">
                  {{ getIndicatorTime(
                    (isNullDate(workItem.initiatedAt) || workItem.leadTime) ? null : workItem.age,
                    false, usingWorkingHours
                  ) }}
                </span>
              </div>
            </template>
            Work Item Age
          </v-tooltip>
          <v-tooltip v-if="!status.isFinal" right content-class="dense-tooltip">
            <template v-slot:activator="{ on }">
              <div v-on="on">
                <v-icon style="color: #616161" small>mdi-clock-time-four-outline</v-icon>
                <span class="grey--text text--darken-2">
                  {{getIndicatorTime(workItem.currentColumnAge, false, usingWorkingHours)}}
                </span>
              </div>
            </template>
            Current Columns WIA<br/>(Work Item Age)
          </v-tooltip>
          <v-tooltip v-if="status.isFinal" right content-class="dense-tooltip">
            <template v-slot:activator="{ on }">
              <div v-on="on">
                <v-icon
                  :style="{
                    color: leadTimeGoal.leadTimeGoal ?
                      (toMinutes(leadTimeGoal.leadTimeGoal, leadTimeGoal.leadTimeGoalUnit, usingWorkingHours) < workItem.leadTime ? 'red' : '#616161' )
                      : '#616161'
                    }"
                  small
                >
                  mdi-alarm
                </v-icon>
                <span
                  style="white-space: nowrap"
                  :style="{
                    color: leadTimeGoal.leadTimeGoal ?
                      (toMinutes(leadTimeGoal.leadTimeGoal, leadTimeGoal.leadTimeGoalUnit, usingWorkingHours) < workItem.leadTime ? 'red' : '#616161' )
                      : '#616161'
                  }"
                >
                  {{
                    getIndicatorTime(
                      (isNullDate(workItem.initiatedAt) || workItem.age || !status.isFinal) ? null : workItem.leadTime,
                      false, usingWorkingHours
                    )
                  }}
                </span>
              </div>
            </template>
            Lead Time
          </v-tooltip>
        </div>
      </div>
    </v-card-actions>
  </v-card>
</template>

<script>
export default {
  props: {
    workItem: Object,
    tags: Array,
    sprints:  Array,
    someSelected: Boolean,
    usingWorkingHours: Boolean,
    leadTimeGoal: Object,
    status: Object,
    dense: Boolean,
  },
  computed: {
    shownTags(){
      let tags = _.clone(this.tags)
      tags.forEach(tag => {
        tag.borderColor = tag.tagColor
        if(!this.workItem.boardColor || tag.tagColor.toLowerCase() == this.workItem.boardColor.substring(0, 7).toLowerCase()){
          tag.borderColor = '#000'
        }
      })
      return tags
    },
  },
  methods: {
    getColorWithTransparency(color) {
      if(color.length == 7) {
        color = color + '33'
      } else {
        color = _.dropRight(color, 2).join("") + '33'
      }
      return color
    },
    roundedTotalHours(hours) {
      return Math.round(hours)
    },
    getSprintById(id) {
      return this.sprints.find(sprint => sprint.id == id)
    },
  }
}
</script>

<style lang="scss" scoped>
.work-item{
  cursor: grab;
  border-radius: 10px;
  overflow: hidden;

  .task-badges{
    display: flex;
    justify-content: flex-end;
    gap: 3px;
    margin-top: -5px;
    margin-bottom: 5px;
  }

  .subtitle-right{
    display: flex;
    gap: 5px;
    align-items: center;
    justify-content: flex-start;
    flex-wrap: wrap;
  
    > div{
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 1px 5px;
      height: 100%;
      background: rgba(0,0,0,.1);
      border-radius: 5px;
      font-size: .8em;

      img{
        filter: drop-shadow(1px 1px 0 rgba(100, 100, 100,.1)) drop-shadow(-1px -1px 0 rgba(100, 100, 100,.1))drop-shadow(-1px 1px 0 rgba(100, 100, 100,.1)) drop-shadow(1px -1px 0 rgba(100, 100, 100,.1));
      }
    }
  }
  .summary {
    word-break: normal;
  }
  &.sortable-ghost {
    border: 2px dashed #0072ff !important;
    cursor: grabbing !important;
  }
  .tags-assignedto-grid{
    display: grid;
    grid-template-columns: auto 32px;
  }

  
  .actions-box{
    width: 100%;
    .indicators{
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin: 0;
      padding: 0 10px;

      > div{
        display: inline-flex;
        align-items: center;
        padding: 5px 0;
        line-height: 15px;
        font-size: 11px;

        span{
          margin-left: 2px;
          display: inline-block;
          font-size: 11px;
        }
      }
    }
  }
}
.dense-tooltip{
  padding: 3px 5px;
  font-size: .8em;
  line-height: 1rem;
}
.hover-status {
  border: 2px dashed;
}
.no-border {
  border: none;
}
.leadtime-icon {
  position: absolute;
  left: -25px;
  bottom: -2px; 
}
.workitems-quantity {
  background: #FFFFFF1A;
  position: absolute;
  width: 20px;
  height: 30px;
  right: 0;
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.workitems-quantity-spacer {
  width: 10px;
}
.avatar-priority-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.priority-code-container {
  display: flex;
}
</style>