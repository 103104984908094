<template>
	<div>
		<h3 class="mb-5">Payments</h3>
		<admin-balance-panel
			:items="payments"
			:pathToChild="null"
			:mainHeaders="paymentHeaders"
			:secondaryHeaders="[]"
			mainTemplateColumns="1fr 2fr repeat(3, 1fr)"
			secondaryTemplateColumns=""
		>
			<template #[`item.date`]="item">
				{{ item.date | formatDate }}
			</template>
			<template #[`item.amount`]="item">
				<v-btn fab width="23" height="23" class="mr-1" depressed color="#b9d7fe">
					<v-icon size="15" color="primary">mdi-cash-multiple</v-icon>
				</v-btn>
				{{ item.accountCurrency }} {{ currencyFormat(item.amount) }}
			</template>
			<template #[`item.invoicedAmount`]="item">
				<v-btn fab width="23" height="23" class="mr-1" depressed color="#bce3c9">
					<v-icon size="15" color="success">mdi-cash-multiple</v-icon>
				</v-btn>
				{{ item.accountCurrency }} {{ currencyFormat(item.invoicedAmount) }}
			</template>
			<template #[`item.notInvoicedAmount`]="item">
				<v-btn fab width="23" height="23" class="mr-1" depressed color="#f5c4c5">
					<v-icon size="15" color="error">mdi-cash-multiple</v-icon>
				</v-btn>
				{{ item.accountCurrency }} {{ currencyFormat(item.amount - item.invoicedAmount) }}
			</template>
		</admin-balance-panel>
	</div>
</template>

<script>
import AdminBalancePanel from './AdminBalancePanel'
export default {
	props: {
		payments: Array,
	},
	components: { AdminBalancePanel },
	data: () => ({
		paymentHeaders: [
			{ text: 'Reference number', value: 'reference' },
			{ text: 'Payment date', value: 'date' },
			{ text: 'Amount', value: 'amount' },
			{ text: 'Applied', value: 'invoicedAmount' },
			{ text: 'Not applied', value: 'notInvoicedAmount' },
		],
	}),
}
</script>