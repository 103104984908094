var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('h2',{staticClass:"mb-3"},[_vm._v(" Status "),_c('v-btn',{attrs:{"disabled":!_vm.$parent.canEditProject,"x-small":"","fab":"","depressed":"","color":"primary"},on:{"click":_vm.openNewStatusDialog}},[_c('v-icon',{attrs:{"dark":""}},[_vm._v("add")])],1)],1),_c('data-table',{attrs:{"headers":_vm.headers,"items":_vm.$parent.config.workItemStatuses},scopedSlots:_vm._u([{key:"item.sortIndex",fn:function(props){return [_c('div',[_c('v-btn',{staticClass:"sort-icon",attrs:{"text":"","icon":"","x-small":"","color":"primary","disabled":!_vm.$parent.canEditProject},on:{"click":function($event){return _vm.moveStatusUp(props.item.id)}}},[_c('v-icon',{staticClass:"cursor-pointer"},[_vm._v("arrow_drop_up")])],1),_c('br'),_c('v-btn',{staticClass:"sort-icon",attrs:{"text":"","icon":"","x-small":"","color":"primary","disabled":!_vm.$parent.canEditProject},on:{"click":function($event){return _vm.moveStatusDown(props.item.id)}}},[_c('v-icon',{staticClass:"cursor-pointer"},[_vm._v("arrow_drop_down")])],1)],1)]}},{key:"item.name",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{staticClass:"text-capitalize",attrs:{"min-width":130,"dark":"","small":"","color":item.color}},[(item.iconClass)?_c('v-icon',{attrs:{"small":"","left":""}},[_vm._v(_vm._s(item.iconClass))]):_vm._e(),_vm._v(" "+_vm._s(item.name)+" ")],1)]}},{key:"item.destinations",fn:function(ref){
var item = ref.item;
return [(item.destinations.length === 1)?_c('v-chip',{attrs:{"color":item.destinations[0].destinyColor,"label":"","small":"","dark":""}},[_c('v-icon',{attrs:{"left":"","small":""}},[_vm._v(_vm._s(item.destinations[0].destinyIconClass))]),_vm._v(" "+_vm._s(item.destinations[0].destinyName)+" ")],1):_c('span',[_vm._v(_vm._s(item.destinations.length))])]}},{key:"item.description",fn:function(ref){
var item = ref.item;
return [_c('div',{style:({minWidth: '100px'})},[_vm._v(" "+_vm._s(item.description)+" ")])]}},{key:"item.limit",fn:function(ref){
var item = ref.item;
return [_c('div',{style:({minWidth: '50px'})},[_vm._v(" "+_vm._s(item.limit ? item.limit : '')+" ")])]}},{key:"item.cycleTimeGoal",fn:function(ref){
var item = ref.item;
return [(item.cycleTimeGoal)?_c('div',[_vm._v(" "+_vm._s(item.cycleTimeGoal + ' ' + _vm.cycleTimeGoalUnits[item.cycleTimeGoalUnit].shortname + (item.cycleTimeGoal != 1 ? 's' : ''))+" ")]):_vm._e()]}},{key:"item.definitionOfDone",fn:function(ref){
var item = ref.item;
return [_c('div',[_vm._v(" "+_vm._s(_vm.setMaxText(item.definitionOfDone ? JSON.parse(item.definitionOfDone) : "", 33))+" ")])]}},{key:"item.workItemtype",fn:function(ref){
var item = ref.item;
return [_c('div',{style:({minWidth: '103px'})},[_c('v-btn',{attrs:{"fab":"","x-small":"","dark":"","color":item.workItemTypeColor}},[_c('v-icon',{attrs:{"small":""}},[_vm._v(_vm._s(item.workItemTypeIconClass))])],1),_vm._v(" "+_vm._s(item.workItemTypeDisplayName)+" ")],1)]}},{key:"item.active",fn:function(props){return [_c('div',{style:({minWidth: '54px'})},[(props.item.active)?_c('v-icon',[_vm._v("check")]):_vm._e(),(!props.item.active)?_c('v-icon',[_vm._v("close")]):_vm._e()],1)]}},{key:"item.isDefault",fn:function(props){return [_c('div',{style:({minWidth: '58px'})},[(props.item.isDefault)?_c('v-icon',[_vm._v("check")]):_vm._e(),(!props.item.isDefault)?_c('v-icon',[_vm._v("close")]):_vm._e()],1)]}},{key:"item.isInitial",fn:function(props){return [_c('div',{style:({minWidth: '63px'})},[(props.item.isInitial)?_c('v-icon',[_vm._v("check")]):_vm._e(),(!props.item.isInitial)?_c('v-icon',[_vm._v("close")]):_vm._e()],1)]}},{key:"item.isFinal",fn:function(props){return [_c('div',{style:({minWidth: '59px'})},[(props.item.isFinal)?_c('v-icon',[_vm._v("check")]):_vm._e(),(!props.item.isFinal)?_c('v-icon',[_vm._v("close")]):_vm._e()],1)]}},{key:"item.confetti",fn:function(props){return [_c('div',{style:({minWidth: '64px'})},[(props.item.confetti)?_c('v-icon',[_vm._v("check")]):_vm._e(),(!props.item.confetti)?_c('v-icon',[_vm._v("close")]):_vm._e()],1)]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"nobr"},[_c('v-btn',{attrs:{"depressed":"","outlined":"","icon":"","fab":"","color":"primary","x-small":"","disabled":!_vm.$parent.canEditProject},on:{"click":function($event){return _vm.deleteStatus(item.id)}}},[_c('v-icon',[_vm._v("delete")])],1),_c('v-btn',{attrs:{"fab":"","x-small":"","color":"primary","disabled":!_vm.$parent.canEditProject},on:{"click":function($event){return _vm.openEditStatusDialog(item)}}},[_c('v-icon',[_vm._v("edit")])],1)],1)]}}],null,true)}),_c('a',{directives:[{name:"show",rawName:"v-show",value:(_vm.$parent.canEditProject),expression:"$parent.canEditProject"}],on:{"click":_vm.openCreateDefaultStatusDialog}},[_vm._v("Create default status list")]),_c('edit-status-dialog',{ref:"editDialog",attrs:{"types":_vm.shownTypes,"statuses":_vm.statuses}}),_c('default-status-list-dialog',{ref:"defaultListDialog",attrs:{"types":_vm.shownTypes}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }