
<template>
  <div class="new-back fill-height pa-3">
    <filter-search-input v-model="search" :applied-filters="appliedFilters" outlined dense>
      <template #append>
        <v-btn v-if="permissions.add" fab small depressed color="primary" to="/user/new">
          <v-icon dark>add</v-icon>
        </v-btn>
      </template>

      <v-autocomplete
        class="input" clearable dense multiple outlined hide-details
        :items="items" item-value="id"
        :item-text="v => v.blueTag + v.fullName"
        :filter="(item, queryText, itemText) => normalizeIncludes(item.blueTag + ' ' + item.fullName, queryText)"
        v-model="filters.users" label="People"
      >
        <template v-slot:item="{ item }">
          <v-list-item-avatar color="indigo" class="headline font-weight-light white--text">
            <span v-if="item && !item.profilePictureId">
              {{ (item.blueTag || item.fullName).charAt(0) }}
            </span>
            <v-img v-if="item.profilePictureId" :src="item.profilePicSrc" @error="item.profilePicError = true"></v-img>
          </v-list-item-avatar>
          <v-list-item-content>
            <v-list-item-title v-if="item" v-text="item.blueTag"></v-list-item-title>
            <v-list-item-subtitle v-if="item" v-text="item.fullName"></v-list-item-subtitle>
          </v-list-item-content>
        </template>

        <template v-slot:selection="{ item }">
          <v-chip color="accent" class="mt-1">
            <v-icon small left @click="removeUser(item.id)">clear</v-icon> 
            {{item.blueTag || item.fullName}}
          </v-chip>
        </template>
      </v-autocomplete>
      <v-select :items="userTypes" label="Type" item-value="value" item-text="name" v-model="filters.type" outlined dense clearable hide-details></v-select>
      <v-select :items="reportTypes" label="Report Type" item-value="value" item-text="name" v-model="filters.reportType" outlined dense clearable hide-details></v-select>
      <v-select :items="departments" multiple label="Department" item-value="value" item-text="name" v-model="filters.department" outlined dense clearable hide-details></v-select>
      <v-select :items="contractTypes" label="Contract Type" item-value="value" item-text="name" v-model="filters.contractType" outlined dense clearable hide-details></v-select>
      <v-autocomplete
        clearable dense multiple outlined hide-details
        :items="cities" item-value="id"
        :item-text="v => v.city + ', ' + v.state + ', ' + v.country"
        v-model="filters.cities" label="City"
      >
      </v-autocomplete>
      <QDatePicker
        label="Start Date"
        inner outlined dense hide-details clearable
        v-model="filters.startDate" :max="filters.endDate"
      />
      <QDatePicker
        label="End Date"
        inner outlined dense hide-details clearable
        v-model="filters.endDate" :min="filters.startDate"
      />
      <v-select :items="evaluationResolutionTypes" label="Evaluation Resolution" v-model="filters.evaluationResolution" outlined dense clearable hide-details></v-select>
      <v-switch class="mt-1" inset label="Only Users" v-model="filters.onlyUsers"></v-switch>
      <v-switch class="mt-1" inset label="Only Active" v-model="filters.onlyActive"></v-switch>
    </filter-search-input>

    <data-table
      :headers="shownHeaders"
      :items="shownItems"
      :footer-props="{ itemsPerPageOptions: [10,25,50,{text:'All','value':-1}] }"
      class="new-back lighten2"
      item-key="id"
      v-model="selected"
      :item-class="() => permissions.detail ? 'cursor-pointer' : ''"
      @click:row="(item) => (permissions.detail && canEdit(item)) ? $router.push({ name: 'UserEdit', params: { id: item.id }}) : null"
    >
      <template #[`item.cityState`]="{ item }">
        <span v-show="item.city || item.country">
          {{item.city}}, {{item.state}}, {{item.country}}
        </span>
      </template>
      <template #[`item.department`]="{ item }">
        {{ getDepartmentName(item.department) }}
      </template>
      <template #[`item.blueniversary`]="{ item }">
        {{ item.blueniversary ? formatDate(item.blueniversary) : '' }}
      </template>
      <template #[`item.isEvaluationPeriodActive`]="{ item }" >
        <div class="dot" :style="{ background: `var(--v-${item.evaluationColor}-base)` }"></div>
      </template>
      <template #[`item.actions`]="props">
        <v-menu left offset-x z-index="200">
          <template #activator="{ on }">
            <v-btn v-on="on" icon>
              <v-icon>mdi-dots-vertical</v-icon>
            </v-btn>
          </template>
          <v-list dense class="py-1">
            <v-list-item class="pa-1 py-0">
              <v-tooltip right z-index="200">
                <template #activator="{ on }">
                  <v-btn v-on="on" color="error" @click="doDelete(props.item.id)" text small >
                    <v-icon>delete</v-icon>
                  </v-btn>
                </template>
                <span>Delete</span>
              </v-tooltip>
            </v-list-item>
          </v-list>
        </v-menu>
      </template>
    </data-table>
  </div>
</template>

<script>
import moment from 'moment';
export default {
  title: 'Users',
  mounted() {
    this.$security.hasRequiredPermission('detail')
      .then(res => this.permissions.detail = res)
    this.$security.hasRequiredPermission('add')
      .then(res => this.permissions.add = res)
    this.$security.hasRequiredPermission('full_access')
      .then(res => this.permissions.actions = res)

    this.$q.log(6, 33, 3, 'NA')
    this.setUserPreferences("UserList", "filters")
    this.locations = new Set();
    this.$http
      .get(`/api/user`)
      .then(response => {
        this.items = response.data;
        this.items.forEach(this.$q.profilePicSrc)
        this.items.forEach(item => {
          const today = moment().startOf('day')
          let evaluationColor = 'error'
          let deadline = moment(item.evaluationPeriodEndDate).diff(today, 'day')
          if(item.isEvaluationPeriodActive) evaluationColor = 'success'
          else if(deadline > 30)
            evaluationColor = 'lightgray'
          else if(deadline > 15)
            evaluationColor = 'warning'
          this.$set(item, 'evaluationColor', evaluationColor)

          if (item.city != undefined && item.state != undefined && item.country != undefined)
            this.locations.add(item.city + ', ' + item.state + ', ' + item.country)
        })
        let id = 0;
        this.locations.forEach(location => {
          let [city, state, country] = location.split(",");
          this.cities.push({id: id, city: city, state: state, country: country });
          id +=1;
        })
      })
      .catch(response => {
        window.getApp.error("Cannot get records.");
        console.log(response);
      });
    
    this.$blueSystem.getCurrentUser()
      .then(user => {
        this.currentUser = user
      })
  },
  data() {  
    return {
      search: "",
      selected: [],
      headers: [
        { text: "Blue Tag", value: "blueTag" },
        { text: "Full Name", value: "fullName" },
        { text: "Department", value: "department"},
        { text: "Job Title", value: "jobTitle" },
        { text: "Experience Level", value: "experienceLevelName" },
        { text: "Bluenniversary", value: "blueniversary" },
        { text: "English Level", value: "englishLevel" },
        { text: "City", value: "cityState" },
        { text: "Evaluation Resolution", value: "isEvaluationPeriodActive", align: "center"},
        { text: "", value: "actions", class: "nobr", width: 1 }
      ],
      items: [],
      filters: {
        type: 0,
        onlyUsers: false,
        onlyActive: true,
      },
      userTypes: [
        { value: 0, name: 'Employee' },
        { value: 1, name: 'Not Employee' },
      ],
      reportTypes: [
        { value: 0, name: 'Automatic + Manual' },
        { value: 1, name: 'Manual' },
      ],
      departments: [
        { value: 1, name: 'Administration' },
        { value: 5, name: 'Capability Management' },
        { value: 2, name: 'Commercial' },
        { value: 0, name: 'Dev Center' },
        { value: 4, name: 'Marketing' },
        { value: 3, name: 'Talent Acquisition' },
      ],
      contractTypes: [
        { value: 0, name: 'Full Time' },
        { value: 1, name: 'Part Time' },
      ],
      evaluationResolutionTypes: [
        { value: true, text: 'True' },
        { value: false, text: 'False' },
      ],
      cities: [],
      locations: null,

      permissions: {
        detail: false,
        add: false,
        actions: false,
      },

      currentUser: null,
    };
  },
  computed: {
    shownItems(){
      let items = this.items
      if(!this.items)
        return []
      
      if(this.filters.evaluationResolution != null && this.filters.evaluationResolution != undefined){
        items = items.filter(i => i.isEvaluationPeriodActive == this.filters.evaluationResolution)
      }
      if(this.filters.onlyActive){
        items = items.filter(i => i.active)
      }
      if(this.filters.onlyUsers){
        items = items.filter(i => i.isUser)
      }
      if(this.filters.type != undefined){
        items = items.filter(i => i.type == this.filters.type)
      }
      if(this.filters.users != undefined && this.filters.users.length){
        items = items.filter(i => this.filters.users.includes(i.id))
      }
      if(this.filters.reportType != undefined){
        items = items.filter(i => i.reportType == this.filters.reportType)
      }
      if(this.filters.department != undefined && this.filters.department.length){
        items = items.filter(i => this.filters.department.includes(i.department))
      }
      if(this.filters.contractType != undefined){
        items = items.filter(i => i.contractType == this.filters.contractType)
      }
      if(this.filters.cities != undefined){
        let filteredLocation = this.cities[this.filters.cities];
        if (filteredLocation != undefined) {
          items = items.filter(i => i.country == filteredLocation.country.trim() && 
            i.state == filteredLocation.state.trim() && 
            i.city == filteredLocation.city.trim()) 
        }
      }
      if(this.filters.startDate){
				items = items.filter(i => moment(i.blueniversary) >= moment(this.filters.startDate))
			}
			if(this.filters.endDate){
				items = items.filter(i => moment(i.blueniversary).format("YYYY-MM-DD") <= moment(this.filters.endDate).format("YYYY-MM-DD"))
			}
      items = this.applySearch(this.search, items, this.headers.map(h => h.value).filter(h => h))
      return items
    },
    shownHeaders(){
      return this.headers.filter(x => x.value != 'actions' || this.permissions.actions)
    },
    appliedFilters() { return this.countActiveFilters(this.filters) },
  },
  methods: {
    formatDate: (date) => moment(date).format('MMM D, YYYY'),
    removeUser(id){
      this.filters.users = this.filters.users.filter(u => u != id)
    },
    doDelete(id) {
      this.$q.confirmDelete({
        api: `/api/user/${id}`,
      }).then(res => {
        this.$q.removeById(this.items, id);
      });
    },
    canEdit(user){
      return this.currentUser?.isSuperAdmin || !user.isSuperAdmin
    },
    getDepartmentName(value) {
      const department = this.departments.find(dep => dep.value === value);
      return department ? department.name : '';
    }
  }
};
</script>

<style scoped lang="scss">
.dot{
	display: inline-block;
	width: 18px;
	height: 18px;
	border-radius: 50%;
}
::v-deep .new-back .v-input__slot{
  background: inherit !important;
}
</style>