<template>
	<div>
		<div class="d-flex justify-space-between align-center mb-3">
			<h2>
				Boards
				<v-btn
					@click="addBoardConfig"
					:disabled="!notShownTypes.length"
					fab x-small depressed color="primary"
				>
					<v-icon>add</v-icon>
				</v-btn>
			</h2>
			<div class="text-right">
				<v-switch
					v-model="$parent.config.usingWorkingHours"
					@change="changeWorkingHours"
					class="inline-block" label="Using working hours"
					hide-details inset :disabled="!$parent.canEditProject"
				/>
			</div>
		</div>
		
		<data-table :headers="headers" :items="$parent.boardConfigs">
			<template v-slot:[`item.type`]="{ item }">
				<div v-if="item.id" :style="{minWidth: '103px'}" :set="type = $parent.types.find(t => t.value == item.workItemType) || {}">
					<v-btn fab x-small dark depressed :color="type.color">
						<v-icon small>{{type.iconClass}}</v-icon>
					</v-btn>
					{{type.displayName}}
				</div>
				<div v-else style="max-width: 240px">
					<v-autocomplete
						:items="notShownTypes" item-value="value" item-text="displayName"
						v-model="item.workItemType" hide-details outlined dense
					>
						<template #item="{ item: type }">
							<v-icon :color="type.color" class="mr-2">{{ type.iconClass }}</v-icon>
							{{ type.displayName }}
						</template>
						<template #selection="{ item: type }">
							<v-icon :color="type.color" class="mr-2">{{ type.iconClass }}</v-icon>
							{{ type.displayName }}
						</template>
					</v-autocomplete>
				</div>
			</template>

			<template v-slot:[`item.defaultColor`]="{ item }">
				<div v-if="!item.editing" style="width: 40px">
					<v-list-item
						class="color-preview"
						:style="{
							background: item.defaultColor,
							color: isBlackText(item.defaultColor) ? 'white' : 'black',
							justifyContent: 'center'
						}"
					>
						<v-icon :color="isBlackText(item.defaultColor) ? '#eee' : 'black'">invert_colors</v-icon>
					</v-list-item>
				</div>
				<div v-else style="width: 150px">
					<v-menu open-on-hover bottom offset-x :close-on-content-click="false">
						<template v-slot:activator="{ on }">
							<v-list-item
								v-on="$parent.canEditProject ? on : null" class="color-preview"
								:style="{background: item.defaultColorTemporal, color: isBlackText(item.defaultColorTemporal) ? 'white' : 'black'}"
							>
								<v-icon left :color="isBlackText(item.defaultColorTemporal) ? '#eee' : 'black'">invert_colors</v-icon>
								<v-list-item-content>
									<v-list-item-title>Color</v-list-item-title>
								</v-list-item-content>
								<v-list-item-action>
									<v-icon :color="isBlackText(item.defaultColorTemporal) ? '#eee' : 'black'">chevron_right</v-icon>
								</v-list-item-action>
							</v-list-item>
						</template>

						<color-picker
							v-model="item.defaultColorTemporal"
							:workItem="true"
						></color-picker>
					</v-menu>
				</div>
			</template>

			<template v-slot:[`item.leadTimeGoal`]="{ item }">
				<div v-if="item.leadTimeGoal && !item.editing">
					{{
						item.leadTimeGoal + ' ' + 
							cycleTimeGoalUnits[item.leadTimeGoalUnit].shortname + (item.leadTimeGoal != 1 ? 's' : '')
					}}
				</div>
				<div v-else-if="item.editing" style="max-width: 280px; margin: 5px 0">
					<v-row>
						<v-col>
							<v-text-field
								hide-details dense outlined type="number"
								v-model="item.leadTimeGoalTemporal"
							/>
						</v-col>
						<v-col :cols="7">
							<v-select
								hide-details dense outlined
								v-model="item.leadTimeGoalUnitTemporal"
								:items="cycleTimeGoalUnits"
								:item-value="it => it.value"
								:item-text="it => item.leadTimeGoalTemporal != 1 ? it.name + 's' : it.name"
							/>
						</v-col>
					</v-row>
				</div>
			</template>

			<template v-slot:[`item.actions`]="{ item, index }">
				<div class="nobr">
					<div v-if="!item.editing">
						<v-btn @click="deleteBoardConfig(item.workItemType)" depressed outlined icon fab color="primary" x-small :disabled="!$parent.canEditProject" >
							<v-icon>delete</v-icon>
						</v-btn>
						<v-btn rounded fab x-small color="primary" :disabled="!$parent.canEditProject" @click="item.editing = true">
							<v-icon>edit</v-icon>
						</v-btn>
					</div>
					<div v-else>
						<v-btn class="mr-1" @click="saveBoardConfig(item)" rounded fab depressed x-small color="primary" invert :disabled="!$parent.canEditProject">
							<v-icon>mdi-check-bold</v-icon>
						</v-btn>
						<v-btn @click="stopEditBoardConfig(item, index)" outlined rounded fab x-small color="primary" invert :disabled="!$parent.canEditProject">
							<v-icon>mdi-close</v-icon>
						</v-btn>
					</div>
				</div>
			</template>
		</data-table>
	</div>
</template>

<script>
export default {
	data: () => ({
    headers: [
      { text: "For Type", value: "type", align: 'left' },
      { text: "Default Color", value: "defaultColor", align: 'left' },
      { text: "Lead Time Objective", value: "leadTimeGoal", align: 'left' },
      { text: "", value: "actions", align: 'right' },
    ],
    cycleTimeGoalUnits: [
      { name: 'minute', shortname: 'min', value: 0 },
      { name: 'hour', shortname: 'hr', value: 1 },
      { name: 'day', shortname: 'day', value: 2 },
    ],
	}),
	computed: {
    notShownTypes() {
      if (!this.$parent.config || !this.$parent.config.boardConfigs || !this.$parent.types) return []
      return this.$parent.types.filter(t => !this.$parent.config.boardConfigs.some(s => s.workItemType == t.value))
    },
	},
	methods: {
    changeWorkingHours(){
      this.$http.post(`api/Project/${this.$parent.id}/usingWorkingHours/${this.$parent.config.usingWorkingHours}`)
        .then(res => {
          window.getApp.success("Saved successfully")
        })
        .catch(err => {
          console.log(err)
          window.getApp.error("Cannot save working hours")
          this.$parent.config.usingWorkingHours = !this.$parent.config.usingWorkingHours
        })
    },
    addBoardConfig(){
      const config = {
        leadTimeGoal: 0,
        leadTimeGoalUnit: 0,
        value: this.notShownTypes[0].value,
        nickname: '',
        defaultColor: '',
      }
      this.$parent.boardConfigs = [{
        ...config,
        leadTimeGoalTemporal: config.leadTimeGoal,
        leadTimeGoalUnitTemporal: config.leadTimeGoalUnit,
        defaultColorTemporal: config.defaultColor || '#fff',
        nicknameTemporal: config.nickname || '',
        defaultColor: config.defaultColor || '#fff',
        editing: true,
      }].concat(this.$parent.boardConfigs)
    },
    deleteBoardConfig(value){
      this.$q.confirmDelete({
        api: `api/Project/${this.$parent.id}/boardConfig/${value}`,
        messageTitle: "IMPORTANT - Irreversible action",
        messageBody: "This action will remove all status and work items associated with this board. Are you sure you want to delete the entire board?", 
        sucessMsg: "Board deleted",
        errorMsg: "The board was not deleted.",
      }).then(res => {
        this.$parent.boardConfigs = this.$parent.boardConfigs.filter((bc) => bc.workItemType != value)
      })
    },
    saveBoardConfig(boardConfig){
      this.$http.post(`api/Project/${this.$parent.id}/boardConfig/${boardConfig.workItemType}`, 
        {
          workItemType: boardConfig.workItemType,
          projectId: this.$parent.config.id,
          nickname: boardConfig.nicknameTemporal,
          defaultColor: boardConfig.defaultColorTemporal,
          leadTimeGoal: boardConfig.leadTimeGoalTemporal,
          leadTimeGoalUnit: boardConfig.leadTimeGoalUnitTemporal,
        })
        .then(res => {
          let config = res.data
          let index = this.$parent.boardConfigs.findIndex(b => b.workItemType == boardConfig.workItemType)
          this.$parent.boardConfigs[index] = {
            ...config,
            leadTimeGoalTemporal: config.leadTimeGoal,
            leadTimeGoalUnitTemporal: config.leadTimeGoalUnit,
            defaultColorTemporal: config.defaultColor || '#fff',
            nicknameTemporal: config.nickname || '',
            defaultColor: config.defaultColor || '#fff',
            editing: false,
          }
          this.$parent.boardConfigs = this.$parent.boardConfigs.filter((bc) => true)
        })
    },
    stopEditBoardConfig(boardConfig, index){
      boardConfig.editing = false
      this.$parent.boardConfigs = this.$parent.boardConfigs.filter((bc, ix) => ix != index || bc.id)
    },
	},
}
</script>

<style lang="scss" scoped>

.color-preview{
	min-height: 40px;
	height: 40px;
	background: var(--v-kanban-base);
	color: var(--v-text-base);
	border-radius: 3px;
	border: 1px solid var(--v-text-lighten);
}
</style>