<template>
	<div class="pt-1">

		<filter-search-input
			v-model="filters.search"
			outlined dense
		>
			<template #append>
				<v-btn @click="processBI" color="primary" depressed>
					Process BI
				</v-btn>
			</template>
			<q-date-picker
				v-model="date"
				@change="init"
				type="month"
				inner outlined dense hide-details
			/>
			<q-user-autocomplete
				v-model="filters.deliveryManagers"
				:items="deliveryManagers"
				label="Delivery Manager"
				outlined dense hide-details multiple
			/>
			<v-autocomplete
				v-model="filters.billables"
				:items="billables"
				label="Billable" item-text="code" item-value="id"
				outlined dense hide-details multiple clearable
			/>
			<v-autocomplete
				v-model="filters.customers"
				:items="customers"
				label="Customer" item-text="name" item-value="id"
				outlined dense hide-details multiple clearable
			/>
			<v-select
				v-model="filters.billableTypes"
				:items="billableTypes"
				label="Type" item-text="code" item-value="id"
				outlined dense hide-details multiple clearable
			/>
		</filter-search-input>

		<data-table
			:headers="headers"
			:items="shownItems"
			show-expand item-key="billableId"
		>
			<template #[`item.deliveryManagers`]="{ item }">
				<div class="d-flex flex-column">
					<span v-for="deliveryManager in item.deliveryManagers" :key="deliveryManager.id">
						{{ deliveryManager.blueTag }}
					</span>
				</div>
			</template>
			<template #[`item.customerProjects`]="{ item }">
				<div
					v-for="project in item.customerProjects" :key="project.id"
					class="project" :style="{background: projectTypes.find(t => t.value == project.type).lightColor}"
				>
					<div
						class="letter"
						:style="{background: projectTypes.find(t => t.value == project.type).color}"
					>
						{{projectTypes.find(t => t.value == project.type).small}}
					</div>
					<div class="name">{{project.name}}</div>
				</div>
			</template>
			<template #[`item.billableType`]="{ item }">
				<span :set="type = billableTypes.find(t => t.id == item.billableType)">
					{{ type ? type.code : '' }}
				</span>
			</template>
			<template #[`item.hours`]="{ item }">
				{{ getHours(item) | number(2) }}
			</template>
			<template #[`item.billed`]="{ item }">
				${{ getBilled(item) | money }}
			</template>

			<template #expanded-item="{ headers: innerHeaders, item }">
				<td :colspan="innerHeaders.length">
					<v-data-table
						v-model="item.selectedLines"
						class="mx-3 my-5 new-back lighten"
						:headers="lineHeaders" :items="item.lines"
						item-key="_key"
						:item-class="item => item.type === 2 && 'success--text'"
						hide-default-footer disable-pagination show-select
					>
						<template #[`header.rate`]>
							{{ item.billableType === 0 ? 'Unitary Cost' : 'Rate per Hour' }}
						</template>
						<template #[`header.quantity`]>
							{{ item.billableType === 0 ? 'Quantity' : 'Hours' }}
						</template>

						<template #[`item.startDate`]="{ item }">	
							{{ item.startDate | formatDate }}
						</template>
						<template #[`item.endDate`]="{ item }">	
							{{ item.endDate | formatDate }}
						</template>
						<template #[`item.rate`]="{ item }">	
							${{ item.rate | money }}
						</template>
						<template #[`item.quantity`]="{ item }">
							<v-tooltip :disabled="item.type !== 1" top>
								<template #activator="{ on }">
									<span v-on="on" :class="{ 'error--text': item.type === 1 }">
										{{ item.quantity | number(2) }}
									</span>
								</template>
								<span>Has unassigned hours</span>
							</v-tooltip>
						</template>
						<template #[`item.total`]="{ item }">	
							${{ item.total | money }}
						</template>
					</v-data-table>
				</td>
			</template>
		</data-table>

		<blue-confirm-dialog ref="processDialog">
			<template #title>
				<div class="d-flex justify-space-between flex align-center">
					<div class="d-flex flex-column">
						<span class="primary--text">Confirm Billable Items</span>
						<span class="body-1">{{ billableItems.length }} items selected</span>
					</div>
					<div class="subtitle-1">
						{{ startDate }} - {{ endDate }}
					</div>
				</div>
			</template>
			<template #text>
				<div class="d-flex flex-column gap-5">
					<v-expand-transition v-for="(item, ix) in billableItems" :key="ix">
						<v-expansion-panels
							v-model="item.expanded" flat
						>
							<v-expansion-panel class="bordered darken rounded-lg overflow-hidden">
								<v-expansion-panel-header color="newDesignBackground">
									<div class="d-flex justify-space-between align-center flex">
										<h3> {{ item.billableCode }} </h3>
										<div class="d-flex align-center">
											<v-avatar color="indigo" size="22" class="mr-1">
												<v-img :src="require(`../../../assets/currencies/${item.billableCurrency.toLowerCase()}.png`)" />
											</v-avatar>
											<span class="body-1">{{ item.billableCurrency }}</span>
										</div>
									</div>
								</v-expansion-panel-header>
								<v-expansion-panel-content class="pt-3" color="newDesignBackground">
									<v-data-table
										:headers="billableItemLineHeaders"
										:items="item.lines"
										hide-default-footer disable-pagination show-expand
										item-key="billableLineId" class="newDesignBackground"
									>
										<template #[`header.rate`]>
											{{ item.billableType === 0 ? 'Unitary Cost' : 'Rate per Hour' }}
										</template>
										<template #[`header.quantity`]>
											{{ item.billableType === 0 ? 'Quantity' : 'Hours' }}
										</template>
										
										<template #[`item.rate`]="{ item }">	
											${{ item.rate | money }}
										</template>
										<template #[`item.quantity`]="{ item }">	
											{{ item.quantity | number(2) }}
										</template>
										<template #[`item.total`]="{ item }">	
											${{ item.total | money }}
										</template>
										<template #expanded-item="{ item }">
											<td></td>
											<td>
												<span v-for="member in item.members" :key="member.userId">
													{{ member.userBlueTag }} <br>
												</span>
											</td>
											<td>
												<span v-for="member in item.members" :key="member.userId">
													${{ item.rate | money }} <br>
												</span>
											</td>
											<td>
												<span v-for="member in item.members" :key="member.userId">
													{{ member.hours | number(2) }} <br>
												</span>
											</td>
											<td>
												<span v-for="member in item.members" :key="member.userId">
													${{ (item.rate * member.hours) | money }} <br>
												</span>
											</td>
										</template>
									</v-data-table>
								</v-expansion-panel-content>
							</v-expansion-panel>
						</v-expansion-panels>
					</v-expand-transition>
				</div>
			</template>
		</blue-confirm-dialog>
	</div>
</template>

<script>
import BillableJSON from '../../../json/billable.json'
import moment from 'moment'

export default {
	data: () => ({
		date: moment().format('YYYY-MM-01'),
		headers: [
			{
				value: 'deliveryManagers',
				text: 'Delivery Manager',
				sort: (a, b) => {
					if (a.length === 0) return -1
					if (b.length === 0) return 1
					return a[0].blueTag.localeCompare(b[0].blueTag)
				}
			},
			{ value: 'billableCode', text: 'Billable' },
			{ value: 'customerName', text: 'Customer' },
			{ value: 'customerProjects', text: 'Projects' },
			{ value: 'currency', text: 'Currency' },
			{ value: 'billableType', text: 'Type' },
			{ value: 'hours', text: 'Hours', align: 'right' },
			{ value: 'billed', text: 'Billed', align: 'right' },
		],
		lineHeaders: [
			{ value: 'billableItemCode', text: 'Billable Item' },
			{ value: 'billableLineRole', text: 'Seat' },
			{ value: 'userBlueTag', text: 'Assignee' },
			{ value: 'startDate', text: 'Start Date' },
			{ value: 'endDate', text: 'End Date' },
			{ value: 'rate', text: 'Rate per Hour', align: 'right' },
			{ value: 'quantity', text: 'Hours', align: 'right' },
			{ value: 'total', text: 'Amount', align: 'right' },
		],
		billableItemLineHeaders: [
			{ value: 'billableLineRole', text: 'Seat' },
			{ value: 'rate', text: 'Rate per hour' },
			{ value: 'quantity', text: 'Hours' },
			{ value: 'total', text: 'Amount' },
		],
		items: [],
		billableTypes: [],
		projectTypes: [],
		billableItems: [],

		filters: {
			search: '',
			date: moment().format('YYYY-MM-01'),
			deliveryManagers: [],
			billables: [],
			customers: [],
			billableTypes: [],
		},
	}),
	created() {
		this.projectTypes = BillableJSON.projectTypes
		this.init()
	},
	computed: {
		shownItems() {
			let items = this.items
			if (this.filters.search) {
				items = this.applySearch(this.filters.search, items)
			}
			if (this.filters.deliveryManagers.length > 0) {
				items = items.filter(item => item.deliveryManagers.some(dm => this.filters.deliveryManagers.includes(dm.id)))
			}
			if (this.filters.billables.length > 0) {
				items = items.filter(item => this.filters.billables.includes(item.billableId))
			}
			if (this.filters.customers.length > 0) {
				items = items.filter(item => this.filters.customers.includes(item.customerId))
			}
			if (this.filters.billableTypes.length > 0) {
				items = items.filter(item => this.filters.billableTypes.includes(item.billableType))
			}
			return items
		},
		startDate() {
			return moment(this.date).startOf('month').format('MMM DD, YYYY')
		},
		endDate() {
			return moment(this.date).endOf('month').format('MMM DD, YYYY')
		},
		deliveryManagers() {
			let deliveryManagers = this.items
				.flatMap(item => item.deliveryManagers)
				.map(dm => ({ id: dm.id, blueTag: dm.blueTag }))
			deliveryManagers = deliveryManagers.filter((dm, ix) => deliveryManagers.findIndex(d => d.id === dm.id) === ix)
			return deliveryManagers
		},
		billables() {
			let billables = this.items.map(item => ({ id: item.billableId, code: item.billableCode }))
			billables = billables.filter((b, ix) => billables.findIndex(bi => bi.id === b.id) === ix)
			return billables
		},
		customers() {
			let customers = this.items.map(item => ({ id: item.customerId, name: item.customerName }))
			customers = customers.filter((c, ix) => customers.findIndex(cu => cu.id === c.id) === ix)
			return customers
		},
	},
	methods: {
		init() {
			this.$http.post('api/billableItem/suggested', { value: this.date })
				.then(res => {
					let items = res.data
					items.forEach(item => {
						this.$set(item, 'selectedLines', [])
						this.$set(item, 'deliveryManagerString', item.deliveryManagers.map(dm => dm.blueTag).join(', '))
						item.lines.forEach(line => {
							this.$set(line, '_key', line.assignmentId + line.startDate + line.billableLineId)
							this.$set(line, 'isSelectable', line.type === 0)
						})
					})
					this.items = items
				})
				.catch(error => {
					console.error(error)
					this.$root.error('Failed to fetch suggested billable items')
				})
			this.$http.get('api/enums/admin/billable/type')
				.then(res => {
					this.billableTypes = res.data
				})
				.catch(error => {
					console.error(error)
					this.$root.error('Failed to fetch billable types')
				})
		},
		getHours(item) {
			return item.selectedLines?.reduce((acc, line) => acc + line.quantity, 0) || 0
		},
		getBilled(item) {
			return item.selectedLines?.reduce((acc, line) => acc + line.total, 0) || 0
		},

		async processBI() {
			let selectedItems = structuredClone(this.items.filter(item => item.selectedLines?.length > 0))
			if (selectedItems.length === 0) {
				this.$root.error('No billable items selected')
				return
			}
			selectedItems.forEach(item => {
				item.lines = item.selectedLines
			})

			try {
				const res = await this.$http.post('api/billableItem/suggested/build', selectedItems)
				this.billableItems = res.data
				const confirmed = await this.$refs.processDialog.open({ width: 700 })

				if(!confirmed) return

				await this.$http.post('api/billableItem/bulk', this.billableItems)

				this.init()
			} catch (error) {
				console.error(error)
				this.$root.error('Failed to build billable items')
			}

		},
	},
}
</script>

<style lang="scss" scoped>
::v-deep .v-data-table__expanded__content{
  box-shadow: none !important;
}
.project{
	display: inline-block;
	border-radius: 15px;
	margin-right: 5px;
	white-space:nowrap;
	height: 30px;
	div{
		display: inline-flex;
		align-items: center;
		justify-content: center;

	}
	.letter{
		width: 30px;
		padding: 5px;
		color: white;
		border-radius: 15px;
	}
	.name{
		padding: 0 10px 0 5px;
		color: black;
	}
}
</style>