<template>
	<html-to-pdf
		v-if="!!invoice"
		v-model="show"
		:name="invoice.code"
		display-flex no-margin prevent
		:pre-export="preExport"
	>

		<div class="d-flex flex-column">
			<v-img
				width="175" src="../../assets/invoice/people-icon.png"
				style="position: absolute; left: 0; top: 454px; z-index: 0"
				contain
			/>
			<v-img
				height="333" src="../../assets/invoice/rectangle.png"
				style="position: absolute; right: 0; top: 175px; z-index: 0"
			/>
			<v-img
				v-if="invoice.isDraft"
				width="500" src="../../assets/invoice/draft.png"
				style="position: absolute; right: 100px; top: 130px; z-index: 0; opacity: 0.2"
			/>
			<div ref="header" class="d-flex" style="z-index: 1; gap: 5px">
				<div class="d-flex justify-space-between flex-column">
					<v-img
						src="../../assets/bluePeople.png"
						height="90" width="150" contain
						class="mt-n2"
					/>
					<div v-if="invoice.businessDetails" class="caption" style="line-height: 1.10em">
						<b>{{ invoice.businessDetails.businessName }}</b> <br/>
						{{ invoice.businessDetails.addressLine }} {{ invoice.businessDetails.addressLine2 }} <br/>
						{{ invoice.businessDetails.city }} {{ invoice.businessDetails.state }} <br/>
						{{ invoice.businessDetails.zipCode }} <br/>
					</div>
					<div v-if="invoice.businessDetails">{{ invoice.businessDetails.email }}</div>
				</div>
				<div class="text-center" style="width: 350px">
					<div class="d-inline-flex justify-space-between flex-column text-left" style="gap: 6px">
						<b>Bill to:</b>
						<div class="body-2"><b>{{ invoice.rfcName }}</b></div>
						<div class="caption" style="line-height: 1.10rem;">
							{{ invoice.businessAddress }}
						</div>
					</div>
				</div>
				<v-row dense>
					<v-col :cols="6">
						<div class="bordered darken py-2 pl-1 pr-2 rounded-lg">
							<div>Balance Due</div>
							<div class="text-right nobr" :class="{ caption: invoice.total > 1000000 }">
								<b>{{ showCurrency ? invoice.currency : '' }} {{ currencyFormat(invoice.total) }}</b>
							</div>
						</div>
					</v-col>
					<v-col :cols="6">
						<div class="bordered darken elevated py-2 pl-1 pr-2 rounded-lg">
							<div>Invoice</div>
							<div class="text-right">
								<b>{{ invoice.code }}</b>
							</div>
						</div>
					</v-col>
					<v-col :cols="6">
						<div class="bordered darken elevated py-2 pl-1 pr-2 rounded-lg">
							<div>Invoice Date</div>
							<div class="text-right">
								<b>{{ invoice.issueDate | formatDate }}</b>
							</div>
						</div>
					</v-col>
					<v-col :cols="6">
						<div class="bordered darken elevated py-2 pl-1 pr-2 rounded-lg">
							<div>Due Date</div>
							<div class="text-right">
								<b>{{ invoice.paymentDay | formatDate }}</b>
							</div>
						</div>
					</v-col>
				</v-row>
			</div>
			
			<v-data-table
				v-for="(pair, i) in invoiceLineItemsPerHeader" :key="i"
				ref="tables"
				:headers="invoiceLineHeaders"
				:items="invoice.lines.slice(pair.previous, pair.previous + pair.quantity)"
				class="mt-5 rounded-lg overflow-hidden colored"
				:class="{ 'page-break': i }"
				style="border: 1px solid var(--v-primary-base); position: relative; background: transparent; z-index: 1"
				hide-default-footer disable-sort
			>
				<template #[`item.description`]="{ item }">
					<b>{{ item.description }}</b><br>
					<div class="caption">{{ item.details }}</div>
				</template>
				<template #[`item.rate`]="{ item }">
					{{ currencyFormat(item.unitPrice) }}
				</template>
				<template #[`item.amount`]="{ item }">
					{{ currencyFormat(item.unitPrice * item.quantity) }}
				</template>
				<template v-if="i === invoiceLineItemsPerHeader.length - 1" #footer>
					<div class="d-flex flex-column align-end text-right pa-3">
						<div v-if="!invoice.notRequireTax" class="d-flex" style="gap: 5px">
							<div class="caption">Subtotal:</div> 
							<b>{{ currencyFormat(invoice.subtotal) }}</b>
						</div>
						<div v-if="!invoice.notRequireTax" class="d-flex" style="gap: 5px">
							<div class="caption">Tax:</div> 
							<b>{{ currencyFormat(invoice.tax) }}</b>
						</div>
						<div class="d-flex" style="gap: 5px">
							<div class="caption">Total:</div> 
							<b>{{ showCurrency ? invoice.currency : '' }} {{ currencyFormat(invoice.total) }}</b>
						</div>
					</div>
				</template>
			</v-data-table>

			<v-spacer/>
	
			<div ref="bottom">
				<h3 class="text-center mb-2">Payment Info</h3>
				<v-data-table
					class="dense rounded-lg"
					:items="[paymentInfo]" :headers="paymentInfoHeaders"
					hide-default-footer disable-sort
				>
				</v-data-table>
			</div>
		</div>

	</html-to-pdf>
</template>

<script>
import moment from 'moment'

export default {
	data: () => ({
		show: false,
		invoice: null,
		showCurrency: false,

		invoiceLineHeaders: [
			{ text: 'Description', value: 'description' },
			{ text: 'Quantity', value: 'quantity', align:'right' },
			{ text: 'Rate', value: 'rate' },
			{ text: 'Amount', value: 'amount', align:'right' },
		],
		paymentInfoHeaders: [
			{ text: 'Bank', value: 'bank' },
			{ text: 'Account Number', value: 'accountNumber', align: 'center' },
			{ text: 'ABA Routing Number', value: 'routingNumber' },
			{ text: 'Company Name', value: 'companyName' },
			{ text: 'Address', value: 'address' },
			{ text: 'Domestic Wire', value: 'domesticWire' },
		],

		invoiceLineItemsPerHeader: [],

		paymentInfo: {
			bank: 'Bank of America',
			accountNumber: '488107206264',
			routingNumber: '111000025',
			companyName: 'Blue People LLC',
			address: '3006 N. Fry Rd. Katy TX 77449',
			domesticWire: '026009593',
		},
	}),
	methods: {
		open(invoice){
			this.invoice = invoice
			this.showCurrency = moment(invoice.createdAt).isSameOrAfter('2023-10-18')
			this.paymentInfo.companyName = invoice.businessDetails.businessName
			this.paymentInfo.address = `${invoice.businessDetails.addressLine}, ${invoice.businessDetails.addressLine2}, ${invoice.businessDetails.city}, ${invoice.businessDetails.state}, ${invoice.businessDetails.country}, ${invoice.businessDetails.zipCode}`
			this.show = true
			this.invoiceLineItemsPerHeader = []
		},
		preExport(){
			console.log('preExport')
			return new Promise(resolve => {
				this.invoiceLineItemsPerHeader.push({
					quantity: this.invoice.lines.length,
					previous: 0,
				})
	
				this.$nextTick(async () => {
					let headerHeight = this.$refs.header.offsetHeight
					let lastTableIndex = 0
					
					while(lastTableIndex < this.invoiceLineItemsPerHeader.length){
						while(this.$refs.tables[lastTableIndex].$el.offsetHeight + headerHeight > 700) {
							if(lastTableIndex === this.invoiceLineItemsPerHeader.length - 1) {
								this.invoiceLineItemsPerHeader.push({
									quantity: 0,
									previous: this.invoiceLineItemsPerHeader[lastTableIndex].quantity + this.invoiceLineItemsPerHeader[lastTableIndex].previous,
								})
							}
		
							if(this.invoiceLineItemsPerHeader[lastTableIndex].quantity === 1)
								break
							
							this.invoiceLineItemsPerHeader[lastTableIndex].quantity--
							this.invoiceLineItemsPerHeader[lastTableIndex+1].quantity++
							this.invoiceLineItemsPerHeader[lastTableIndex+1].previous--
		
							this.$forceUpdate()
							await this.$nextTick()
						}
						if(this.invoiceLineItemsPerHeader[lastTableIndex+1])
							this.$refs.tables[lastTableIndex].$el.style.marginBottom = Math.max(1000 - this.$refs.tables[lastTableIndex].$el.offsetHeight - headerHeight, 0) + 'px'
						lastTableIndex++
						headerHeight = 0
					}
					
					console.log(this.invoiceLineItemsPerHeader)
					resolve()
				})
			})
		}
	},
}
</script>

<style lang="scss" scoped>
.colored ::v-deep table {
	td{
		border-bottom: 1px solid var(--v-primary-base);
	}
	.v-data-table-header {
		background: var(--v-primary-base);
		
		span {
			color: white;
		}
	}
}
.dense ::v-deep table {
	border: 1px solid var(--v-primary-base);
	border-radius: 10px;
	th {
		border-bottom: 1px solid var(--v-primary-base) !important;
		color: black !important;
	}
	td, th {
		font-size: 11px !important;
		padding: 0 6px !important;
	}
}
</style>