<template>
  <div class="view pb-0">
    <v-row>
      <v-col style="max-height: var(--view-height); overflow-y: scroll">
        <h3>Event timeline</h3>
        <v-timeline align-top :dense="$vuetify.breakpoint.mdAndDown">
          <v-timeline-item
            v-for="item in eventTimeline"
            :key="item.id"
            
            :color="item.color"
            :icon="item.icon"
            fill-dot
          >
            <div>
              <v-card @click="openTimelineItem(item)" class="elevated" color="high-back" outlined>
                <v-card-title>
                  <div class="d-flex align-center justify-space-between flex" style="">
                    <div class="d-flex flex-column">
                      <span class="title" style="text-transform: capitalize;">
                        {{ item.type }}
                      </span>
                      <span class="body-2" style="color: var(--v-text-lighten);">
                        {{ item.date | formatDate }}
                      </span>
                    </div>
                    <div class="d-flex">
                      <v-tooltip v-if="item.reportedToId" top>
                        <template #activator="{ on }">
                          <div v-on="on" class="mr-n1" style="z-index: 1">
                            <user-avatar :user-id="item.reportedToId" :size="36" hide-loading />
                          </div>
                        </template>
                        Reported to
                      </v-tooltip>
                      <v-tooltip v-if="item.reportedById" top>
                        <template #activator="{ on }">
                          <div v-on="on">
                            <user-avatar :user-id="item.reportedById" :size="36" hide-loading/>
                          </div>
                        </template>
                        {{ type === 'incident' ? 'Reported by' : 'Responsible' }}
                      </v-tooltip>
                    </div>
                  </div>
                </v-card-title>
                <v-card-text v-if="item.type === 'incident'" class="pb-3">
                  <span class="body-1">{{ item.code }}</span>
                  <div class="d-flex align-center gap-3">
                    <v-btn 
                      depressed small text
                      class="btn-persistent-background flex"
                      :color="item.statusColor"
                      :style="{textTransform: 'none'}"
                    >{{ item.statusName }}
                    </v-btn>
                    <div class="flex text-right">
                      Actions
                      <v-btn
                        class="btn-persistent-background"
                        color="error"
                        text x-small
                      >
                        <span class="body-2">{{ item.linkedWorkItems.length }}</span>
                      </v-btn>
                    </div>
                  </div>
                  <div class="body-2 mt-4">
                    {{ item.description }}
                  </div>
                </v-card-text>
                <v-card-text v-else class="pb-3">
                  <span class="title">Incidents</span>
                  <div class="d-flex flex-column gap-4 mt-4">
                    <div
                      v-for="wi in item.workItems" :key="wi.id"
                      class="pt-4"
                      style="border-top: 1px solid var(--v-lightgray-lighten)"
                    >
                      <span class="body-1">{{ wi.code }}</span>
                      <div class="d-flex align-center gap-3">
                        <v-btn 
                          depressed small text
                          class="btn-persistent-background flex"
                          :color="wi.statusColor"
                          :style="{textTransform: 'none'}"
                        >{{ wi.statusName }}
                        </v-btn>
                        <div class="flex text-right">
                          Actions
                          <v-btn
                            class="btn-persistent-background"
                            color="error"
                            text x-small
                          >
                            <span class="body-2">{{ wi.linkedWorkItems.length }}</span>
                          </v-btn>
                        </div>
                      </div>
                      <div class="body-2 mt-2">
                        {{ wi.description }}
                      </div>
                    </div>
                    <span v-if="!item.workItems.length" class="body-1">
                      No incidents reported
                    </span>
                  </div>
                </v-card-text>
              </v-card>
            </div>
          </v-timeline-item>
        </v-timeline>
      </v-col>
      <v-col class="overflow-scroll" style="max-height: var(--view-height); overflow-y: scroll">
        <div class="d-flex" style="gap: 20px">
          <div style="width: calc(100%)">
            <h3 class="ml-2">Healthchecks</h3>
            <div class="flex-space-between align-center justify mt-2 mb-3 px-2">
              <div class="d-flex align-center">
                <div>
                  <h5 class="text--text">
                    Customer Satisfaction:
                    <span :class="customerSatisfaction.color+'--text'">{{customerSatisfaction.description}}</span>
                  </h5>
                  <div class="body-2">
                    Last healthcheck
                    <span v-if="customerSatisfaction.daysAgo == 0">today</span>
                    <span v-else-if="customerSatisfaction.daysAgo > 0 && customerSatisfaction.daysAgo < 30">
                      {{customerSatisfaction.daysAgo}} days ago
                    </span>
                    <span v-else>{{customerSatisfaction.date}}</span>
                  </div>
                </div>
                <div
                  class="background--lighten2 body-2 text-center rounded-lg py-2 px-3 ml-3"
                >
                  <v-icon>mdi-information-outline</v-icon>
                  Healthcheck {{ isNextHealthcheckOverdue ? 'overdue' : 'due' }}: {{ nextHealthcheck }}
                </div>
                <v-btn @click="openSettings" icon class="ml-1">
                  <v-icon>mdi-cog-outline</v-icon>
                </v-btn>
              </div>
              <div
                @click="openHealthcheckDialog"
                class="cursor-pointer opacity-on-hover"
              >
                <h4 class="primary-color">
                  <v-icon color="primary">mdi-plus</v-icon>
                  New Healthcheck
                </h4>
              </div>
            </div>
            <div class="grid pa-2 pt-1">
              <div
                v-for="healthcheck in healthchecks.slice(0, showHealthchecksQuantity)" :key="healthcheck.id"
                class="high-back bordered elevated pa-4 rounded-lg cursor-pointer"
                @click="openHealthcheckDialog(healthcheck)"
              >
                <div class="d-flex align-center mb-5 gap-2">
                  <user-avatar :userId="healthcheck.createdById" :size="40" hide-loading/>
                  <div>
                    <div class="d-flex align-center">
                      <span class="body-1 mr-2">
                        <b>{{healthcheck.createdByBlueTag || healthcheck.createdByFullName}}</b>
                      </span>
                      <span class="caption" style="color: var(--v-lightgray-darken)">
                        <span v-if="healthcheck.daysAgo == 0">Today</span>
                        <span v-else-if="healthcheck.daysAgo > 0 && healthcheck.daysAgo < 30">
                          {{healthcheck.daysAgo}} days ago
                        </span>
                        <span v-else>{{healthcheck.date | formatDate}}</span>
                      </span>
                    </div>
                    <div
                      class="d-flex align-center px-2 rounded"
                      :style="{
                        fontSize: '.9em',
                        color: `var(--v-${healthcheck.customerPerceptionColor}-base)`,
                        background: !$vuetify.theme.isDark ? healthcheck.customerPerceptionLightColor : '',
                        border: $vuetify.theme.isDark ? '1px solid' : '',
                        height: '25px',
                      }"
                    >
                      Customer Satisfaction: {{healthcheck.customerPerceptionDescription}}
                    </div>
                  </div>
                </div>
    
                <div class="mb-3">
                  <label class="body-2" style="color: var(--v-lightgray-darken)">Comments:</label>
                  <div class="body-1 multiline">
                    {{healthcheck.comments}}
                  </div>
                </div>
                <div class="mt-2 primary--text">
                  {{healthcheck.workItems.length}}
                  {{`reported incident${healthcheck.workItems.length > 1 || healthcheck.workItems.length == 0 ? 's' : ''}`}}
                </div>
              </div>
            </div>
            <v-btn v-if="healthchecks.length > 2" @click="showMoreHealthChecks" block text color="primary">
              {{ showHealthchecksQuantity >= healthchecks.length ? 'Show less' : 'Show more' }}
            </v-btn>
            <div class="flex-space-between align-center justify mt-8 mb-3 px-2">
              <h3>Incidents ({{ incidents.length }})</h3>
              <div
                @click="openIncidentDialog"
                class="cursor-pointer opacity-on-hover"
              >
                <h4 class="primary-color">
                  <v-icon color="primary">mdi-plus</v-icon>
                  New Incident
                </h4>
              </div>
            </div>
            <div
              class="grid pa-2 pt-1"
            >
              <incident-card
                v-for="(item) in incidents.slice(0, showIncidentsQuantity)"
                :key="item.id"
                :item="item"
                :statuses="projectConfig.workItemStatuses"
                @statusChange="loadHealthchecksAndIncidents"
                @click="openIncidentDialog(item)"
              />
            </div>
            
            <v-btn v-if="incidents.length > 2" @click="showMoreIncidents" block text color="primary">
              {{ showIncidentsQuantity >= incidents.length ? 'Show less' : 'Show more' }}
            </v-btn>
          </div>
        </div>
      </v-col>
    </v-row>

    <nuclei-member-dialog
      :id="id"
      ref="nucleiMemberDialog"
    />
    <nuclei-scaling-dialog
      ref="NucleiScalingDialog"
      :projectId="id"
      :members="members"
    />
		<healthcheck-dialog :project-id="id" ref="healthcheckDialog"/>
		<healthcheck-reminder-dialog ref="settingsDialog"/>
  </div>
</template>

<script>
import moment from "moment";
import _ from 'lodash';
import HealthcheckReminderDialog from "../../dialogs/HealthcheckReminderDialog";
import NucleiMemberDialog from "../../dialogs/NucleiMemberDialog";
import NucleiScalingDialog from "../../dialogs/NucleiScalingDialog";
import HealthcheckDialog from "../../dialogs/HealthcheckDialog"
import IncidentCard from "../../components/IncidentCard.vue";

export default {
  props: ["id"],
  components: {
		NucleiMemberDialog,
		NucleiScalingDialog,
    HealthcheckDialog,
    IncidentCard,
    HealthcheckReminderDialog,
	},
  data: () => ({
    headers: [
      { text: "Name", value: "assignment.userFullName", view: 1 },
      { text: "BlueTag", value: "assignment.userBlueTag" },
      { text: "Start Date", value: "startDate" },
      { text: "End Date", value: "endDate", view: 1 },
      { text: "Role", value: "assignment.billableLineRole" },
      {
        text: "C. Perception",
        value: "customerPerception",
        align: "center",
        sortable: false,
      },
      {
        text: "Requirements",
        value: "requirements",
        align: "center",
        sortable: false,
      },
      {
        text: "Nuclei Happiness",
        value: "nucleiHappiness",
        align: "center",
        sortable: false,
      },
      {
        text: "Highlights",
        value: "highlights.length",
        align: "center",
        sortable: false,
      },
    ],

		healthchecks: [],
    project: {},
    members: [],
    today: moment().startOf('day'),
    incidents: [],
    healthchecks: [],
    projectConfig: [],
    showIncidentsQuantity: 2,
    showHealthchecksQuantity: 2,

    semaphoreColors: [
			{ color: 'error', icon: 'mdi-emoticon-sad-outline' },
			{ color: 'warning', icon: 'mdi-emoticon-neutral-outline' },
			{ color: 'success', icon: 'mdi-emoticon-excited-outline' }
		],
  }),
  created() {
    this.$q.log(1, 5, 3, this.id, this.$parent.item.id)
    this.init();
  },
  computed: {
    eventTimeline() {
      // healthchecks and incidents
      return this.healthchecks
        .map((x) => ({
          ...x,
          type: "healthcheck",
          date: moment(x.date),
          color: this.semaphoreColors[x.customerPerception].color,
          icon: this.semaphoreColors[x.customerPerception].icon,
          reportedById: x.responsibleId,
        }))
        .concat(
          this.incidents.map((x) => ({
            ...x,
            type: "incident",
            date: moment(x.createdAt),
            color: "#fdc37f",
            icon: "mdi-alert-outline",
            reportedToId: x.reportedToId,
            reportedById: x.reportedById,
          }))
        )
        .sort((a, b) => b.date.diff(a.date))
    },
    shownMembers() {
      return this.members.filter((x) => this.view == 1 || x.isCurrent);
    },
    customerSatisfaction(){
      let satisfaction = {
        color: 'lightgray',
        description: '-',
        daysAgo: null,
        date: null,
      }
      if(this.healthchecks.length){
        const lastHealthcheck = this.healthchecks[0]
        satisfaction.color = lastHealthcheck.customerPerceptionColor
        satisfaction.description = lastHealthcheck.customerPerceptionDescription
        satisfaction.daysAgo = lastHealthcheck.daysAgo
        satisfaction.date = moment(lastHealthcheck.date).format('MMM D, YYYY')
      }
      return satisfaction
    },
		nextHealthcheck(){
			const timeIncreasers = {
				0: date => moment(date).clone().add(2, 'week'),
				1: date => moment(date).clone().add(1, 'month'),
			}
      let lastHealthcheckDate = this.project.createdDateTime
      if(this.healthchecks.length)
        lastHealthcheckDate = this.healthchecks[0].date
			return timeIncreasers[this.project.healthcheckReminderRecurrence || 0]
        (lastHealthcheckDate)
        .format('MMM D, YYYY')
		},
    isNextHealthcheckOverdue(){
      return moment(this.nextHealthcheck).isBefore(moment())
    },
  },
  methods: {
    init() {
      this.$http.get(`api/project/${this.id}/config?active=true`).then(res => {
        this.projectConfig = res.data
        this.projectConfig.workItemStatuses = this.projectConfig.workItemStatuses.filter(s => s.workItemType === 7)
      })
      this.$http.get(`api/project/${this.id}/header`)
        .then(res => {
          this.project = res.data
        })
      this.$http.get(`api/nucleiManagement/${this.id}/members`)
        .then((res) => {
          this.members = res.data.map((x) => ({
            ...x,
            userId: x.assignment.userId,
            startDate: moment(x.assignment.startDate),
            endDate: moment(x.assignment.endDate),
            isCurrent:
              moment(x.assignment.startDate).isSameOrBefore(this.today, "day") &&
              moment(x.assignment.endDate).isSameOrAfter(this.today, "day"),
          }))
        })
        .catch(response => {
          window.getApp.error("Cannot get nuclei members.");
        })

      this.loadHealthchecksAndIncidents()
		},
    loadHealthchecksAndIncidents(){
      this.$http.get(`/api/nucleiManagement/workItems/${this.id}`)
        .then(res => {
          this.incidents = res.data
        })
        .catch(res => {
          window.getApp.error("Cannot get work items.")
        })
			this.$http.get(`api/nucleiManagement/${this.id}/healthchecks`)
				.then(res => {
					this.healthchecks = res.data
						.map(h => ({
							...h,
							daysAgo: this.today.diff(moment(h.date), 'day')
						}))
				})
				.catch(err => {
					console.error(err)
					this.$root.error('Cannot get healthchecks')
				})
    },

    showMoreIncidents(){
			if(this.showIncidentsQuantity >= this.incidents.length){
				this.showIncidentsQuantity = 2
			}
			else if(this.showIncidentsQuantity < this.incidents.length){
				this.showIncidentsQuantity += 2
			}
		},
    showMoreHealthChecks(){
			if(this.showHealthchecksQuantity >= this.healthchecks.length){
				this.showHealthchecksQuantity = 2
			}
			else if(this.showHealthchecksQuantity < this.healthchecks.length){
				this.showHealthchecksQuantity += 2
			}
		},

		openHealthcheckDialog(item){
			this.$refs.healthcheckDialog.open(_.cloneDeep(item))
				.then(res => {
					if(!res) return
				})
				.catch(err => {
					console.error(err)
					this.$root.error('Cannot save healthcheck')
				})
        .finally(this.loadHealthchecksAndIncidents)
		},
    openSettings() {
      let lastHealthcheckDate = this.project.createdDateTime
      if(this.healthchecks.length)
        lastHealthcheckDate = this.healthchecks[0].date
      this.$refs.settingsDialog.open(this.id, this.project.healthcheckReminderRecurrence, lastHealthcheckDate)
        .then((res) => {
          if (res) this.init()
        })
    },
    openIncidentDialog(item) {
      this.$refs.NucleiScalingDialog.open(this.id, item)
        .then(() => this.init())
    },
    openTimelineItem(item) {
      if (item.type === "incident") {
        this.$refs.NucleiScalingDialog.open(this.id, item)
          .then(() => this.init())
      }
      else if (item.type === "healthcheck") {
        this.$refs.healthcheckDialog.open(item)
          .then(() => this.init())
      }
    },
  },
};
</script>

<style lang="scss" scoped>

.v-card--link:focus:before{
  opacity: 0;
}
.grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(340px, 1fr));
  grid-gap: 35px;
}
</style>
