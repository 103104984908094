
<template>
  <div style="width: calc(100vw - 206px)">
    <v-menu v-model="showColumnContextMenu" :min-width="150" :position-x="xColumnMenu" :position-y="yColumnMenu" absolute offset-y >
      <v-list dense class="py-1">
        <v-list-item class="pr-2" @click="openWorkItemCreateDialog(contextStatusId)">
          <v-icon left>add</v-icon>
          <small>Create new</small>
        </v-list-item>
      </v-list>
    </v-menu>
    <v-menu v-model="showWorkItemContextMenu" :min-width="150" :position-x="xMenu" :position-y="yMenu" absolute offset-y >
      <v-list dense class="py-1">
        <v-list-item v-if="someSelected && viewBoard" class="pr-2" @click="deselectAll">
          <v-icon left>mdi-checkbox-blank-off-outline</v-icon>
          <small>Deselect All</small>
        </v-list-item>
        <v-list-item v-else-if="viewBoard" class="pr-2" @click="toggleSelect">
          <v-icon left> mdi-checkbox-blank-outline </v-icon>
          <small> Select </small>
        </v-list-item>

        <v-list-item v-if="viewList && (contextWorkItem || {}).isNew" class="pr-2" @click="removeNew(contextWorkItem.code)">
          <v-icon left>mdi-card-remove-outline</v-icon>
          <small>Remove New Item</small>
        </v-list-item>

        <v-menu v-if="viewBoard" open-on-hover bottom offset-x :close-on-content-click="false">
          <template v-slot:activator="{ on }">

            <v-list-item v-on="on" class="pr-2">
              <v-icon left>invert_colors</v-icon>
              <v-list-item-content>
                <v-list-item-title>Color</v-list-item-title>
              </v-list-item-content>
              <v-list-item-action>
                <v-icon color="">chevron_right</v-icon>
              </v-list-item-action>
            </v-list-item>
          </template>
          <color-picker
            :workItem="true"
            :miniVersion="true"
            v-model="color"
            @update:color="colorChanged"
          />
        </v-menu>

        <v-menu open-on-hover bottom offset-x :close-on-content-click="false">
          <template v-slot:activator="{ on }">

            <v-list-item v-on="on" class="pr-2">
              <v-icon left>label</v-icon>
              <v-list-item-content>
                <v-list-item-title>Tags</v-list-item-title>
              </v-list-item-content>
              <v-list-item-action>
                <v-icon color="">chevron_right</v-icon>
              </v-list-item-action>
            </v-list-item>
          </template>
          <v-list dense class="py-1 tags-container">
            <v-list-item @click="openTagDialog">
              <v-list-item-content>
                <v-list-item-title>
                  <v-chip
                    :style="{ display: 'block', cursor: 'pointer', color: 'black' }" small color="white"
                  >
                    <v-icon small left>add</v-icon>
                    Create tag
                  </v-chip>
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-list-item v-if="!tags || !tags.length">
                <v-list-item-title>
                  <i>No tags available</i>
                </v-list-item-title>
            </v-list-item>
            <v-tooltip
              right
              :disabled="!tag.description || !JSON.parse(tag.description)"
              v-for="(tag, ix) in tags.filter(tag => tag.id && tagNotActiveButAssigned(tag))"
              :key="ix"
            >
              <template v-slot:activator="{ on }">
                <v-list-item class="" v-on="on"
                    @click="toggleTag(tag)">
                  <v-list-item-content>
                    <v-list-item-title>
                      <v-chip
                        :style="{ display: 'block', cursor: 'pointer', color: isBlackText(tag.color) ? 'white' : 'black' }" small dark :color="tag.color"
                      >
                        <v-icon small left v-if="contextWorkItem && contextWorkItem.tags && contextWorkItem.tags.some(t => t.tagId == tag.id)">check</v-icon>
                        <v-icon small left v-else color="grey lighten-1">clear</v-icon>
                        {{tag.name}}
                      </v-chip>
                    </v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </template>
              <div style="white-space:pre-wrap; word-break:break-word; max-width: 280px;" v-html="tag.description ? JSON.parse(tag.description) : ''"></div>
            </v-tooltip>
          </v-list>
        </v-menu>

        <v-menu v-if="!isPersonal" open-on-hover bottom offset-x :close-on-content-click="false">
          <template v-slot:activator="{ on }">
            <v-list-item v-on="on" class="pr-2">
              <v-icon left>mdi-account-star</v-icon>
              <v-list-item-content>
                <v-list-item-title>Assigned to</v-list-item-title>
              </v-list-item-content>
              <v-list-item-action>
                <v-icon color="">chevron_right</v-icon>
              </v-list-item-action>
            </v-list-item>
          </template>
          <v-list dense class="py-1 tags-container">
            <v-list-item v-if="!users || !users.length">
              No users available
            </v-list-item>
            <v-list-item v-for="(user, ix) in users" :key="ix" @click="saveProperty(user.id, 'assignedToId', contextWorkItem)">
              <v-list-item-avatar class="font-weight-light white--text ma-0 mr-1">
                <user-avatar :showCard="false" :userId="user.id" hideLoading :size="30"></user-avatar>
              </v-list-item-avatar>  
              <v-list-item-content>
                <v-list-item-title>
                  {{user.blueTag || user.fullName}}
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </v-menu>

        <v-menu v-if="!isPersonal" open-on-hover bottom offset-x :close-on-content-click="false">
          <template v-slot:activator="{ on }">
            <v-list-item v-on="on" class="pr-2">
              <v-icon left>mdi-account-badge</v-icon>
              <v-list-item-content>
                <v-list-item-title>Reported by</v-list-item-title>
              </v-list-item-content>
              <v-list-item-action>
                <v-icon color="">chevron_right</v-icon>
              </v-list-item-action>
            </v-list-item>
          </template>
          <v-list dense class="py-1 tags-container">
            <v-list-item v-if="!users || !users.length">
              No users available
            </v-list-item>
            <v-list-item
              v-for="(user, ix) in users.filter(u => u.blueTag != 'Unassigned')" :key="ix"
              @click="saveProperty(user.id, 'reportedById', contextWorkItem)"
            >
              <v-list-item-avatar class="font-weight-light white--text ma-0 mr-1">
                <user-avatar :showCard="false" :userId="user.id" hideLoading :size="30"></user-avatar>
              </v-list-item-avatar>  
              <v-list-item-content>
                <v-list-item-title>
                  {{user.blueTag || user.fullName}}
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </v-menu>

        <v-menu :open-on-hover="!someActivedMenu" bottom offset-x :close-on-content-click="false">
          <template v-slot:activator="{ on }">
            <v-list-item v-on="on" class="pr-2">
              <v-icon left>mdi-arrow-right-bold-hexagon-outline</v-icon>
              <v-list-item-content>
                <v-list-item-title>{{viewBoard ? 'Move' : 'Type'}}</v-list-item-title>
              </v-list-item-content>
              <v-list-item-action>
                <v-icon color="">chevron_right</v-icon>
              </v-list-item-action>
            </v-list-item>
          </template>
          <v-list dense class="py-1">
            <v-menu
              v-model="activedMenus[type.value]"
              v-for="(type, ix) in shownTypes" :key="ix"
              open-on-hover bottom offset-x :close-on-content-click="false"
            >
              <template v-slot:activator="{ on }">
                <v-list-item v-on="on">
                  <v-icon left small>{{type.iconClass}}</v-icon>
                  <v-list-item-content>
                    <v-list-item-title>{{type.displayName}}</v-list-item-title>
                  </v-list-item-content>
                  <v-list-item-action>
                    <v-icon color="">chevron_right</v-icon>
                  </v-list-item-action>
                </v-list-item>
              </template>
              <v-list dense>
                <v-list-item @click="moveToStatus(status)" v-for="(status, jx) in getStatusesByType(type.value)" :key="jx">
                  <v-icon left small>{{status.iconClass}}</v-icon>
                  <v-list-item-content>
                    <v-list-item-title>{{status.name}}</v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </v-list>
            </v-menu>
          </v-list>
        </v-menu>

        <v-menu open-on-hover bottom offset-x :close-on-content-click="false">
          <template v-slot:activator="{ on }">
            <v-list-item v-on="on" class="pr-2">
              <v-icon left>mdi-chart-timeline-variant</v-icon>
              <v-list-item-content>
                <v-list-item-title>Sprint</v-list-item-title>
              </v-list-item-content>
              <v-list-item-action>
                <v-icon color="">chevron_right</v-icon>
              </v-list-item-action>
            </v-list-item>
          </template>
          <v-list dense class="py-1 tags-container">
            <v-list-item v-if="!sprints || !sprints.length">
              No sprints available
            </v-list-item>
            <v-list-item v-for="(sprint, ix) in sprints.filter(x => x.id)" :key="ix" @click="toggleSprint(sprint)">
              <v-list-item-content>
                <v-list-item-title>
                  <v-chip
                    class="d-block cursor-pointer"
                    :style="{ color: isBlackText(sprint.color) ? 'white' : 'black' }" small dark :color="sprint.color"
                  >
                    <v-icon small left v-if="contextWorkItem && contextWorkItem.sprints && contextWorkItem.sprints.some(t => t.sprintId == sprint.id)">check</v-icon>
                    <v-icon small left v-else color="grey lighten-1">clear</v-icon>
                    {{sprint.name}}
                  </v-chip>
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </v-menu>

        <v-menu open-on-hover bottom offset-x :close-on-content-click="false">
          <template v-slot:activator="{ on }">
            <v-list-item v-on="on" class="pr-2">
              <v-icon left>mdi-chevron-double-up</v-icon>
              <v-list-item-content>
                <v-list-item-title>Priority</v-list-item-title>
              </v-list-item-content>
              <v-list-item-action>
                <v-icon color="">chevron_right</v-icon>
              </v-list-item-action>
            </v-list-item>
          </template>
          <v-list dense class="py-1 tags-container">
            <v-list-item v-if="!priorities || !priorities.length">
              No priorities available
            </v-list-item>
            <v-list-item
              v-for="(item, ix) in priorities" :key="ix"
              @click="saveProperty(item.value, 'priority', contextWorkItem)"
            >
              <v-img class="mr-2" width="24px" height="24px" :src="require(`../../assets/priority-icons/${item.value}.png`)" contain></v-img>
              <v-list-item-title :color="item.color">
                {{ item.displayName }}
              </v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
      </v-list>
    </v-menu>
    <v-tooltip v-if="!viewSprints" left :disabled="!activeSprint.id || showSprintGoal" >
      <template v-slot:activator="{ on }">
        <v-btn
          v-on="on" class="sprint-goal-button"
          @click="toggleSprintGoal" color="primary"
          fab tile 
        >
          <v-icon >mdi-flag</v-icon>
        </v-btn>
      </template>
      <span>{{ activeSprint.name }}</span>
    </v-tooltip>
    <Transition name="sprintGoal" >
      <div
        v-show="showSprintGoal"
        class="sprint-goal-card pa-0"
        id="sprint-goal"
      >
        <div v-click-outside="toggleClickOutsideSprintGoal" id="sprint-goal" class="sprint-goal-info py-3 px-10">
          <v-icon 
            id="sprint-goal"
            color="primary"
            large
          >
            mdi-flag
          </v-icon>
          <div class="ml-4" id="sprint-goal">
            <h3 id="sprint-goal">{{activeSprint.name}}</h3>
            <span id="sprint-goal" class="sprint-goal-text">
              Sprint Goal:
            </span>
            {{ activeSprint.goal  }}
          </div>
        </div>
      </div>
    </Transition>
    <div class="header top mt-0 mb-3" style="width: calc(100vw - 206px)">
      <v-slide-group
        v-if="!isPersonal" v-show="viewBoard" v-model="board"
        right show-arrows mandatory class="slider rounded"
      >
        <v-slide-item v-for="wiType in shownTypes" :key="wiType.value" >
          <template v-slot:default="{ active, toggle }">     
            <v-btn
            fab
            :color="active ? 'var(--v-newDesignBackground-lighten)' : 'var(--v-secondaryBackground-base)'"
            class="mx-1 text-capitalize text-center inline-block px-0 rounded-button" depressed x-small @click="toggle"
            >
              <v-tooltip top>
                <template v-slot:activator="{ on }">
                    <v-icon
                      v-on="on"
                      :style="{
                        filter: active ? '' : $vuetify.theme.isDark ? 'brightness(0) invert(1)' : 'brightness(0)'
                      }"
                      :color="wiType.color"
                    >{{wiType.iconClass}}
                    </v-icon>
                  </template>
                  <span class="caption">{{ wiType.displayName }}</span>
              </v-tooltip>
            </v-btn>     
          </template>
        </v-slide-item>
      </v-slide-group>
      <v-slide-group
          v-show="viewList" v-model="selectedTypes"
          right show-arrows multiple class="slider rounded"
        >
          <v-slide-item v-for="wiType in shownTypes" :key="wiType.value" >
            <template v-slot:default="{ active, toggle }">
              <v-btn
                fab
                :color="active ? 'var(--v-newDesignBackground-lighten)' : 'var(--v-background-lighten)'"
                class="mx-1 text-capitalize text-center inline-block rounded-button" depressed  small @click="toggle"
              >
              <v-tooltip top>
                <template v-slot:activator="{ on }">
                  <v-icon
                    v-on="on"
                    :style="{
                      filter: active ? '' : $vuetify.theme.isDark ? 'brightness(0) invert(1)' : 'brightness(0)'
                    }"
                    :color="wiType.color"
                  >{{wiType.iconClass}}</v-icon>
                </template>
                <span class="caption">{{ wiType.displayName }}</span>
              </v-tooltip>
              </v-btn>
            </template>
          </v-slide-item>
        </v-slide-group>
      <div v-if="!viewSprints" class="search ml-2" id="search">
        <v-text-field
          prepend-inner-icon="search"
          label="Search" single-line
          v-model="temporalSearch"
          @focus="search.length >= 2 ? showSmartSearch = true : ''"
          @blur="showSmartSearch = false"
          outlined dense hide-details clearable class="input"
          @keydown.esc="showSmartSearch = false"
          >
          <template v-slot:append>
            <v-badge
              offset-x="12" offset-y="13"
              color="red" :value="!!appliedFilters" :content="appliedFilters"
              right small
            >
              <v-icon class="mr-2" @click="toggleShowFilters" :color="showFilters ? 'primary' : $vuetify.theme.isDark ? 'white' : '' ">mdi-tune</v-icon>
            </v-badge>
          </template>
        </v-text-field>
        <smart-search 
          :change="changeSmartSearchProperty"
          :showSmartSearch="showSmartSearch"
          :tags="tagsBySearch"
          :sprints="sprintsBySearch"
          :priorities="prioritiesBySearch"
          :users="usersBySearch"
          :workItems="workItemsBySearch"
          close-outside
          @close="closeSmartSearch($event)"
        >
        </smart-search>
      </div>
      <v-btn
        class="mx-2"
        fab
        dark
        x-small
        color="primary"
        v-if="appliedFilters && !viewSprints"
        @click="resetFilters"
      >
        <v-icon dark>
          mdi-close
        </v-icon>
      </v-btn>
      <div class="justify-right">
        <div 
          v-if="viewBoard"
          class="leadtime-avg mr-2"
          @click="showLeadTime"
        >
          <v-icon
            :style="{ fontSize: '16px' }"
          >
            mdi-information-outline
          </v-icon>
          &nbsp
          Lead Time Real vs. Objective:
          &nbsp
          <span
            :style="{
              color: 
                leadTimeGoal.leadTimeGoal ?
                  ( toMinutes(leadTimeGoal.leadTimeGoal, leadTimeGoal.leadTimeGoalUnit, project.usingWorkingHours) < leadTime.value ? 'salmon' : '#72bb6d' )
                  : ''
            }"
          >
            {{
              leadTimeGoal.leadTimeGoal ?
                formatTimeByUnit(leadTime.value, leadTimeGoal.leadTimeGoalUnit, project.usingWorkingHours) :
                getIndicatorTime(leadTime.value, false, project.usingWorkingHours)
            }}
          </span>
          <span v-if="leadTimeGoal.leadTimeGoal">
            &nbsp
            vs
            {{ formatTimeByUnit(toMinutes(leadTimeGoal.leadTimeGoal, leadTimeGoal.leadTimeGoalUnit), leadTimeGoal.leadTimeGoalUnit) }}
          </span>
          <v-tooltip bottom v-if="leadTimeGoal.leadTimeGoal && toMinutes(leadTimeGoal.leadTimeGoal, leadTimeGoal.leadTimeGoalUnit, project.usingWorkingHours) < leadTime.value">
            <template v-slot:activator="{ on }">
              <v-icon v-on="on" color="error">warning</v-icon>
            </template>
            <span>The cycle time is greater than the Lead Time objective</span>
          </v-tooltip>
        </div>

        <toggle-view
          v-if="!isPersonal" v-model="view"
          :items="views" dense
        />
      </div>
      <v-btn
        @click="viewSprints ? triggerSprintAdd() : openWorkItemCreateDialog()"
        :disabled="viewSprints && $refs.sprints && !$refs.sprints.canAdd"
        class="mx-2" color="primary"
        fab x-small depressed
      >
        <v-icon>mdi-plus</v-icon>
      </v-btn>
    </div>

    <v-expansion-panels :value="showFilters ? 0 : ''" flat>
      <v-expansion-panel :style="{background: 'var(--v-newDesignBackground-base)'}">
        <v-expansion-panel-content >
          <div class="filter-container">
            <v-row>
              <v-col class="px-1" v-show="viewList">
                <v-autocomplete
                  label="Status" item-value="id" item-text="name"
                  v-model="filters.statuses" :items="projectConfig.workItemStatuses.filter(s => selectedTypeValues.includes(s.workItemType))"
                  outlined multiple hide-details dense
                  :disabled="someFavoriteSelected"
                >
                  <template v-slot:prepend-item>
                    <div class="mt-2 mb-2 toggleSelect" >
                      <a @click="toggleFilterSelectAll('statuses', true)" class="cursor-pointer" depressed label dark :color="'white'">
                        Select All
                      </a>
                      <a @click="toggleFilterSelectAll('statuses', false)" class="cursor-pointer" label dark :color="'dark'">
                        Clear All
                      </a>
                    </div>
                  </template>
                  <template v-slot:item="{item, attrs }">
                    <v-checkbox v-model="attrs.inputValue">
                    </v-checkbox>
                    <v-chip class="my-2 cursor-pointer" label :style="{ width: '100%' }" dark :color="item.color">
                      <v-icon class="mr-2">{{item.iconClass}}</v-icon>
                      {{item.name}}
                    </v-chip>
                  </template>
                  <template v-slot:selection="{ item, index }">
                    <v-chip 
                      v-if="index === 0"
                      label dark
                      class="mt-2 cursor-pointer"
                      :color="item.color"
                    >
                      <v-icon class="mr-2" style="font-size: 1.3em">{{item.iconClass}}</v-icon>
                      {{item.name[0]}}
                    </v-chip>
                    <span
                      v-if="index === 1"
                      class="grey--text text-caption mt-2 ml-1"
                    >
                      (+{{ filters.statuses.length - 1 }})
                    </span>
                  </template>
                </v-autocomplete>
              </v-col>

              <v-col class="px-1">
                <v-autocomplete label="Tags" :items="tags"
                  item-value="id"  item-text="name"
                  :search-input.sync="inputSearches.tags"
                  v-model="filters.tags" outlined multiple hide-details dense
                  @change="applyFilters()"
                >
                  
                  <template v-slot:prepend-item>
                    <div class="mt-2 mb-2 toggleSelect" >
                      <a @click="toggleFilterSelectAll('tags', true)" class="cursor-pointer" depressed label dark :color="'white'">
                        Select All
                      </a>
                      <a @click="toggleFilterSelectAll('tags', false)" class="cursor-pointer" label dark :color="'dark'">
                        Clear All
                      </a>
                    </div>
                  </template>
                  <template  v-slot:append-item>
                    <div class="container-tag-options pt-1">
                      <v-divider class="divider px-auto"></v-divider>
                      <div class="behavior-container">
                        <div class="behavior-text">Behavior:</div>
                        <div>
                          <a
                            @click="filters.tagCondition = 'and'"
                            class="mr-1 tag-condition"
                            :class="{
                              'active-tag-condition': filters.tagCondition == 'and'
                            }"
                          >AND</a>
                          <a
                            @click="filters.tagCondition = 'or'"
                            class="tag-condition"
                            :class="{'active-tag-condition': filters.tagCondition == 'or',}"
                          >OR</a>
                        </div>
                      </div>
                    </div>
                  </template>
                  <template v-slot:item="{ item, attrs }">
                    <v-list-item-action>
                      <v-checkbox v-model="attrs.inputValue"></v-checkbox>
                    </v-list-item-action>
                    <v-list-item-content>
                      <v-list-item-title>
                        <v-chip class="cursor-pointer" dark small :color="item.id !== null ? item.color : 'white'"
                          :style="`color: ${isBlackText(item.color) ? 'white' : 'black'}`"
                        >
                          {{item.name}}
                        </v-chip>
                      </v-list-item-title>
                    </v-list-item-content>
                  </template>
                  <template v-slot:selection="{ item, index, attrs, selected }">
                    <v-chip 
                      small
                      v-bind="attrs"
                      :input-value="selected"
                      v-if="item === Object(item) && index === 0"
                      :color="item.id !== null ? item.color : ''"
                      :class="item.id !== null ? '' : 'transparent'"
                      :style="`color: ${item.id !== null ? isBlackText(item.color) ? 'white' : 'black' : ''}`"
                    >
                      <span>{{ item.name }}</span>
                    </v-chip>
                    <span
                      v-if="index === 1"
                      class="grey--text text-caption"
                    >
                      (+{{ filters.tags.length - 1 }})
                    </span>
                  </template>
                </v-autocomplete>
              </v-col>

              <v-col class="px-1"
                v-show="showDefectsOriginsFilter"
              >
                <v-autocomplete label="Source" :items="projectConfig.defectsOrigins"
                  item-value="id"  item-text="name" 
                  v-model="filters.defectsOrigins" outlined multiple hide-details dense
                  :disabled="someFavoriteSelected"
                >
                  <template v-slot:prepend-item>
                    <div class="mt-2 mb-2 toggleSelect" >
                      <a @click="toggleFilterSelectAll('defectsOrigins', true)" class="cursor-pointer" depressed label dark :color="'white'">
                        Select All
                      </a>
                      <a @click="toggleFilterSelectAll('defectsOrigins', false)" class="cursor-pointer" label dark :color="'dark'">
                        Clear All
                      </a>
                    </div>
                  </template>
                  <template v-slot:selection="{ attrs, item, parent, selected, index }">
                    <v-chip 
                      v-if="item === Object(item) && index === 0"
                      v-bind="attrs"
                      class="accent--lighten"
                      :class="item.id !== null ? '' : 'transparent'"
                      :input-value="selected"
                      label
                      small
                    >
                      <span class="pr-2">
                        {{ item.name }}
                      </span>
                      <v-icon small @click="parent.selectItem(item)">close</v-icon>
                    </v-chip>
                    <span
                      v-if="index === 1"
                      class="grey--text text-caption mt-1 ml-1"
                    >
                      (+{{ filters.defectsOrigins.length - 1 }})
                    </span>
                  </template>
                </v-autocomplete>
              </v-col>

              <v-col class="px-1">
                <v-autocomplete label="Sprint" :items="sprints"
                  item-value="id"  item-text="name" 
                  v-model="filters.sprints" outlined multiple hide-details dense
                  :disabled="someFavoriteSelected"
                >
                  <template v-slot:prepend-item>
                    <div class="mt-2 mb-2 toggleSelect" >
                      <a @click="toggleFilterSelectAll('sprints', true)" class="cursor-pointer" depressed label dark :color="'white'">
                        Select All
                      </a>
                      <a @click="toggleFilterSelectAll('sprints', false)" class="cursor-pointer" label dark :color="'dark'">
                        Clear All
                      </a>
                    </div>
                  </template>
                  <template v-slot:selection="{ attrs, item, parent, selected, index }">
                    <v-chip 
                      v-if="item === Object(item) && index === 0"
                      v-bind="attrs"
                      class="accent--lighten"
                      :class="item.id !== null ? '' : 'transparent'"
                      :input-value="selected"
                      label
                      small
                    >
                      <span class="pr-2">
                        {{ item.name }}
                      </span>
                      <v-icon small @click="parent.selectItem(item)">close</v-icon>
                    </v-chip>
                    <span
                      v-if="index === 1"
                      class="grey--text text-caption mt-1 ml-1"
                    >
                      (+{{ filters.sprints.length - 1 }})
                    </span>
                  </template>
                </v-autocomplete>
              </v-col>

              <v-col class="px-1">
                <v-autocomplete label="Priority" :items="priorities" item-value="value" item-text="displayName" 
                  v-model="filters.priorities" outlined multiple hide-details dense
                  :disabled="someFavoriteSelected"
                >
                  <template v-slot:prepend-item>
                    <div class="mt-2 mb-2 toggleSelect" >
                      <a @click="toggleFilterSelectAll('priorities', true)" class="cursor-pointer" depressed label dark :color="'white'">
                        Select All
                      </a>
                      <a @click="toggleFilterSelectAll('priorities', false)" class="cursor-pointer" label dark :color="'dark'">
                        Clear All
                      </a>
                    </div>
                  </template>
                  <template v-slot:item="{ item, attrs }">
                    <v-list-item-action>
                      <v-checkbox v-model="attrs.inputValue"></v-checkbox>
                    </v-list-item-action>
                    <v-list-item-content>
                      <v-list-item-title>
                        <v-icon :color="item.color" class="mr-2">{{item.iconClass}}</v-icon>
                        {{item.displayName}}
                      </v-list-item-title>
                    </v-list-item-content>
                  </template>
                  <template v-slot:selection="{ attrs, item, parent, selected, index }">
                    <v-chip v-if="item === Object(item) && index === 0"
                      v-bind="attrs" class="accent--lighten" :input-value="selected" label small>
                      <span class="pr-1">
                        <v-icon :color="item.color" class="">{{item.iconClass}}</v-icon>
                        {{ item.displayName }}
                      </span>
                      <v-icon small @click="parent.selectItem(item)">close</v-icon>
                    </v-chip>
                    <span
                      v-if="index === 1"
                      class="grey--text text-caption mt-1 ml-1"
                    >
                      (+{{ filters.priorities.length - 1 }})
                    </span>
                  </template>
                </v-autocomplete>
              </v-col>

              <v-col v-if="!isPersonal" class="px-1">
                <v-autocomplete label="Assigned to" :items="users"
                  item-value="id" item-text="blueTag"
                  :filter="(item, queryText, itemText) => normalizeIncludes(item.blueTag + ' ' + item.fullName, queryText)"
                  :search-input.sync="inputSearches.assignedTo"
                  v-model="filters.assignedTo" outlined multiple hide-details dense
                  @change="applyFilters()"
                >
                  <template v-slot:prepend-item>
                    <div class="mt-2 mb-2 toggleSelect" >
                      <a @click="toggleFilterSelectAll('assignedTo', true)" class="cursor-pointer" depressed label dark :color="'white'">
                        Select All
                      </a>
                      <a @click="toggleFilterSelectAll('assignedTo', false)" class="cursor-pointer" label dark :color="'dark'">
                        Clear All
                      </a>
                    </div>
                  </template>
                  <template v-slot:item="{ item, attrs }">
                    <v-list-item-action>
                      <v-checkbox v-model="attrs.inputValue"></v-checkbox>
                    </v-list-item-action>
                    <v-list-item-content class="pa-0">
                      <v-list-item class="pa-0">
                        <v-list-item-avatar color="indigo" class="headline font-weight-light white--text">
                          {{ (item.blueTag || '-').charAt(0) }}
                        </v-list-item-avatar>
                        <v-list-item-content>
                          <v-list-item-title v-text="item.blueTag"></v-list-item-title>
                          <v-list-item-subtitle v-text="item.fullName"></v-list-item-subtitle>
                        </v-list-item-content>
                      </v-list-item>
                    </v-list-item-content>
                  </template>
                  <template v-slot:selection="{ attrs, item, parent, selected, index }">
                    <v-chip v-if="item === Object(item) && index === 0"
                      v-bind="attrs" class="accent--lighten" :input-value="selected" label small
                    >
                      <span class="pr-2">
                        {{ item.blueTag }}
                      </span>
                      <v-icon small @click="parent.selectItem(item)">close</v-icon>
                    </v-chip>
                    <span
                      v-if="index === 1"
                      class="grey--text text-caption mt-1 ml-1"
                    >
                      (+{{ filters.assignedTo.length - 1 }})
                    </span>
                  </template>
                </v-autocomplete>
                <div class="mt-1 mx-1" v-if="!client()"><a @click="setAssigedToMe()">Assigned to me</a></div>
              </v-col>

              <v-col v-if="!isPersonal" class="px-1">
                <v-autocomplete label="Reported by" :items="users"
                  item-value="id" item-text="blueTag"
                  :filter="(item, queryText, itemText) => normalizeIncludes(item.blueTag + ' ' + item.fullName, queryText)"
                  :search-input.sync="inputSearches.reportedBy"
                  v-model="filters.reportedBy" outlined multiple hide-details dense
                  @change="applyFilters()"
                >
                  <template v-slot:prepend-item>
                    <div class="mt-2 mb-2 toggleSelect" >
                      <a @click="toggleFilterSelectAll('reportedBy', true)" class="cursor-pointer" depressed label dark :color="'white'">
                        Select All
                      </a>
                      <a @click="toggleFilterSelectAll('reportedBy', false)" class="cursor-pointer" label dark :color="'dark'">
                        Clear All
                      </a>
                    </div>
                  </template>
                  <template v-slot:item="{ item, attrs }">
                    <v-list-item-action>
                      <v-checkbox v-model="attrs.inputValue"></v-checkbox>
                    </v-list-item-action>
                    <v-list-item-content class="pa-0">
                      <v-list-item class="pa-0">
                        <v-list-item-avatar color="indigo" class="headline font-weight-light white--text">
                          {{ (item.blueTag || '-').charAt(0) }}
                        </v-list-item-avatar>
                        <v-list-item-content>
                          <v-list-item-title v-text="item.blueTag"></v-list-item-title>
                          <v-list-item-subtitle v-text="item.fullName"></v-list-item-subtitle>
                        </v-list-item-content>
                      </v-list-item>
                    </v-list-item-content>
                  </template>
                  <template v-slot:selection="{ attrs, item, parent, selected, index }">
                    <v-chip v-if="item === Object(item) && index === 0"
                      v-bind="attrs" class="accent--lighten" :input-value="selected" label small>
                      <span class="pr-2">
                        {{ item.blueTag }}
                      </span>
                      <v-icon small @click="parent.selectItem(item)">close</v-icon>
                    </v-chip>
                    <span
                      v-if="index === 1"
                      class="grey--text text-caption mt-1 ml-1"
                    >
                      (+{{ filters.reportedBy.length - 1 }})
                    </span>
                  </template>
                </v-autocomplete>
                <div class="mt-1 mx-1"><a @click="setReportedByMe()">Reported by me</a></div>
              </v-col>

              <v-col class="px-1" v-show="viewBoard">
                <v-menu bottom offset-x :close-on-content-click="false">
                  <template v-slot:activator="{ on }">
                    <div class="color-filter cursor-pointer" v-on="on">
                      <div v-if="!filters.colors.length">
                        <v-icon left>invert_colors</v-icon>
                        <span>Color</span>
                      </div>
                      <div class="colors-container" v-else>
                        <div 
                          v-for="(color, ix) in filters.colors" :key="ix"
                          @click="filters.colors = filters.colors.filter(c => c != color)"
                          class="color cursor-pointer"
                          :style="{background: color}"
                        >
                          <v-icon small :color="isBlackText(color) ? 'white' : 'black'">close</v-icon>
                        </div>
                      </div>
                      <v-icon>chevron_right</v-icon>
                    </div>
                  </template>

                  <v-color-picker
                    @update:color="addColorFilter"
                    hide-canvas hide-inputs hide-mode-switch
                    show-swatches swatches-max-height="300px"
                  ></v-color-picker>
                </v-menu>
                <div class="primary--text mt-1 cursor-pointer" @click="filters.colors = []">Clear colors</div>
              </v-col>
            </v-row>
            <v-btn @click="resetFilters" small fab color="primary" class="ml-1">
              <v-icon>mdi-filter-variant-remove</v-icon>
            </v-btn>
          </div>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>

    <project-kanban v-if="viewBoard" ref="kanban" :selected-work-items.sync="selectedWorkItems" :type="selectedType" :id="id"/>
    <project-work-item-list v-else-if="viewList" ref="list" :selected-work-items.sync="selectedWorkItems" :id="id"/>
    <project-sprints v-else-if="viewSprints" :isEmployee="isEmployee" ref="sprints" @update="fetchConfig" :id="id" :project-id="project.id" :socket="socket"/>
    <work-item-details-dialog ref="workItemDetailsDialog" @newTag="init" :isPersonal="isPersonal"></work-item-details-dialog>
    <work-item-create-dialog ref="workItemCreateDialog" :isPersonal="isPersonal"></work-item-create-dialog>
    <lead-time-dialog 
      ref="leadTimeDialog"
      @clickWorkItem="openWorkItemDetailsDialog"
      :usingWorkingHours="project.usingWorkingHours"
      :projectCreatedAt="project.createdDateTime"
      :activeSprint="activeSprint"
    ></lead-time-dialog>
    <create-tag-dialog ref="createTagDialog" :callback="addNewTagToWorkItem"></create-tag-dialog>
  </div>
</template>

<script>
import _ from "lodash"
import ProjectKanban from './ProjectKanban'
import ProjectWorkItemList from './ProjectWorkItemList'
import ProjectSprints from "./ProjectSprints.vue"
import WorkItemDetailsDialog from "./../../dialogs/WorkItemDetailsDialog"
import WorkItemCreateDialog from "./../../dialogs/WorkItemCreateDialog"
import LeadTimeDialog from "./../../dialogs/LeadTimeDialog"
import CreateTagDialog from '../../dialogs/CreateTagDialog.vue'
import moment from "moment"
import SmartSearch from '../../components/SmartSearch'
import ColorPicker from '../../components/ColorPicker'

export default {
  props: ["id", "wi", 'canEditProject', 'isPersonal', 'socket'],
  components: {
    ProjectKanban,
    ProjectWorkItemList,
    ProjectSprints,
    WorkItemDetailsDialog,
    WorkItemCreateDialog,
    LeadTimeDialog,
    CreateTagDialog,
    SmartSearch,
    ColorPicker
},
  data: () => ({
    board: 0,
    view: 0,
    kanbanKey: 0,
    showSmartSearch: false,
    showFilters: false,
    search: '',
    temporalSearch: '',
    searchTimeout: null,
    filters: {
      statuses: [],
      tags: [],
      sprints: [], 
      priorities: [], 
      assignedTo: [],
      reportedBy: [],
      colors: [],
      defectsOrigins: [],
      tagCondition: 'and',
    },
    selectedTypes: [0,1,2,3,4,5,6,7,8], 
    inputSearches: {},
    creatingItem: false,
    headers: [],
    someFavoriteSelected: false,
    views: [
      { text: 'Board', iconClass: 'mdi-view-dashboard-outline' },
      { text: 'List', iconClass: 'mdi-playlist-check' },
      { text: 'Sprints', iconClass: 'mdi-run-fast' },
    ],
    project: {},
    projectConfig: {
      workItemStatuses: [],
    },
    users: [],
    types: [],
    priorities: [],
    workItems: [],
    leadTime: {
      value: 0,
      type: -1,
    },
    activedMenus: {},
    selectedWorkItems: [],

    showWorkItemContextMenu: false,
    xMenu: 0,
    yMenu: 0,
    contextWorkItem: {
      tags: [],
    },
    color: null,

    showColumnContextMenu: false,
    xColumnMenu: 0,
    yColumnMenu: 0,
    contextStatusId: null,
    currentUser: {},
    isEmployee: false,
    newWorkItems: [],
    currentQueryString: {},
    filtersToggleSelectAll: {
      tags: false,
      sprints: false,
      priorities: false,
      statuses: false,
      colors: false,
      reportedBy: false,
      assignedTo: false,
      defectsOrigins: false,
    },
    showSprintGoal: false,
    handleClickOutsideSprintGoal: false,
  }),
  watch: {
    view(value){
      this.$parent.setNewBackground(!value)
      this.workItems.forEach(wi => this.$set(wi, '_selected', false) )
      this.changeQueryPath({ view: value })
    },
    'filters.tagCondition'(value) {
      this.changeQueryPath({ tagCondition: value })
    },
    filters: {
      handler(value) {
        const filters = JSON.parse(JSON.stringify(value))
        let newFilters = {}
        _.forIn(filters, (value, key) => {
          if(!!value.length) {
            newFilters[key] = value
          } else {
            newFilters[key] = []
          }
        })
        this.changeQueryPath(newFilters)
      },
      deep: true
    },
    'socket.state'(){
      if(this.socket.state == 'Connected'){
        this.signalRConnection()
      }
    },
    temporalSearch(val){
      clearTimeout(this.searchTimeout)
      this.searchTimeout = setTimeout(() => {
        if(!this.temporalSearch) this.temporalSearch = ''
        this.search = this.temporalSearch
        if(val.length >= 2) {
          this.showSmartSearch = true
        }
      }, 500)
    },
  },
  beforeMount() {
    this.$parent.setNewBackground(true) //temporary while we made the design migration
  },
  mounted() {
    this.signalRConnection()
    this.setUserPreferences("ProjectWorkItems-" + this.id, "board", "selectedTypes", "view", 'filters')
    if(this.isPersonal){
      this.$nextTick(() => { this.view = 0 })
    }
    this.init()
    this.initialAssignOfQueryPaths()
    if (this.wi) {
      this.$nextTick(() => this.openWorkItemDetailsDialog(this.wi))
    }
    this.$q.log(1, 0, 3, this.id, this.$parent.item?.id || this.id)
  },
  beforeDestroy(){
    this.socket.invoke('LeaveGroup', this.id)
  },
  computed: {
    viewBoard(){ return this.view === 0 },
    viewList(){ return this.view === 1 },
    viewSprints(){ return this.view === 2 },

    showDefectsOriginsFilter(){ 
      let bugWorkItemType = 1
      if(this.viewBoard) {
        return this.board === bugWorkItemType
      }
      return this.selectedTypes.includes(bugWorkItemType)
    },
    includeWorkItemsWithoutTags() {
      if(this.filters.tags.indexOf(null) != -1) {
        return true
      }
      return false
    },
    includeWorkItemsWithoutSprints() {
      if(this.filters.sprints.indexOf(null) != -1) {
        return true
      }
      return false
    },
    appliedFilters(){
      let appliedQuantity = 0
      for(let key in this.filters){
        if(this.filters[key] && this.filters[key].length)
          appliedQuantity++
      }
      if(this.filters.statuses && this.filters.statuses.length && this.viewBoard)
        appliedQuantity--
      if(this.filters.defectsOrigins && this.filters.defectsOrigins.length && !this.showDefectsOriginsFilter)
        appliedQuantity--
      if(this.filters.colors && this.filters.colors.length && this.viewList)
        appliedQuantity--
      return appliedQuantity
    },
    usersBySearch() {
      if(this.search.length <= 1 || this.search === "") return []
      return this.applySearch(this.search, _.cloneDeep(this.users), ['blueTag', 'fullName'])
    },
    tagsBySearch() {
      if(this.search.length <= 1 || this.search === "") return []
      return this.applySearch(this.search, _.cloneDeep(this.tags), ['name'])
    },
    prioritiesBySearch() {
      if(this.search.length <= 1 || this.search === "") return []
      return this.applySearch(this.search, _.cloneDeep(this.priorities), ['name'])
    },
    sprintsBySearch() {
      if(this.search.length <= 1 || this.search === "") return []
      return this.applySearch(this.search, _.cloneDeep(this.sprints), ['name'])
    },
    workItemsBySearch() {
      if(this.search.length <= 1 || this.search === "") return []
      let items = this.workItems
      items = this.viewBoard ? items.filter(wi => wi.type == this.selectedType) : items
      let searchWords = _.words(this.search.toLowerCase())

      let searchByName = _.cloneDeep(items).filter(wi => {
        let name = wi.name.toLowerCase()
        let pass = true
        searchWords.forEach(word => {
          if(!name.includes(word)) {
            pass = false
            return
          }
        })
        return pass
      })
      let searchByWorkItemCodeAndDescription = this.applySearch(this.search, _.cloneDeep(items), ['code', 'description'])
      items = _.unionBy(searchByName, searchByWorkItemCodeAndDescription, 'id')
      return items || []
    },
    shownWorkItems(){
      let items = this.workItems
      let newItems =  items.filter(wi => wi.isNew)
      this.creatingItem = !!newItems.length;
      items = items.filter(wi => !wi.isNew)
      if((this.viewList || this.viewBoard) && this.showDefectsOriginsFilter) {
        if(this.filters.defectsOrigins.length) {
          items =  items.filter(wi => this.filters.defectsOrigins.includes(wi.defectOriginId))
        }
      }
      if (this.viewList && this.filters.statuses.length) {
        items =  items.filter(wi => this.filters.statuses.some(s => wi.statusId == s))
      }
      if(this.includeWorkItemsWithoutTags && this.filters.tags.length === 1) {
        items =  items.filter(wi => !wi.tags.length)
      }
      if (this.filters.tags.length) {
        items =  items.filter(wi => {
          if(this.filters.tagCondition == 'and') {
            if(this.includeWorkItemsWithoutTags) {
              let tags = JSON.parse(JSON.stringify(this.filters.tags))
              return !_.remove(tags, null).some(t =>
                !wi.tags.map(t2 => t2.tagId).includes(t)
              ) || !wi.tags.length
            }
            return !this.filters.tags.some(t =>
              !wi.tags.map(t2 => t2.tagId).includes(t)
            )
          }
          else {
            if(this.includeWorkItemsWithoutTags) {
              return this.filters.tags.some(tagId => wi.tags.some(wit => wit.tagId == tagId)) || !wi.tags.length
            } 
            return this.filters.tags.some(tagId => wi.tags.some(wit => wit.tagId == tagId))
          }
        })
      }
      if(this.filters.sprints.length === 1 && this.includeWorkItemsWithoutSprints) {
        items =  items.filter(wi => !wi.sprintId)
      }
      if (this.filters.sprints.length) {
        if(this.includeWorkItemsWithoutSprints) {
          items =  items.filter(wi => this.filters.sprints.includes(wi.sprintId) || !wi.sprintId)
        } else {
          items =  items.filter(wi => this.filters.sprints.includes(wi.sprintId))
        }
      }
      if (this.filters.priorities.length) {
        items =  items.filter(wi => this.filters.priorities.includes(wi.priority))
      }
      if (this.filters.assignedTo.length) {
        items =  items.filter(wi => this.filters.assignedTo.includes(wi.assignedToId))
      }
      if (this.filters.reportedBy.length) {
        items =  items.filter(wi => this.filters.reportedBy.includes(wi.reportedById))
      }
      if(this.viewBoard && this.filters.colors.length) {
        items =  items.filter(wi => this.filters.colors.includes(wi.boardColor))
      }
      items = items.filter(wi => this.viewBoard || this.selectedTypeValues.includes(wi.type))
      if(this.search.length >= 2) {
        let searchWords = _.words(this.search.toLowerCase())
        let searchByName = _.cloneDeep(items).filter(wi => {
          let name = wi.name.toLowerCase()
          let pass = true
          searchWords.forEach(word => {
            if(!name.includes(word)) {
              pass = false
              return
            }
          })
          return pass
        })
        let searchByWorkItemCodeAndDescription = this.applySearch(this.search, _.cloneDeep(items), ['code', 'description'])
        items = _.unionBy(searchByName, searchByWorkItemCodeAndDescription, 'id')
      }
      
      return items.concat(newItems).sort(
        (a, b) => {
          let indexA = a.code.lastIndexOf('-')
          let indexB = a.code.lastIndexOf('-')
          a = a.code.substr(indexA+1)
          b = b.code.substr(indexB+1)
          return parseInt(b) - parseInt(a)
        }
      )
    },
    selectedTypeValues() {
      let shownTypes = this.shownTypes
      if (shownTypes && this.selectedTypes) {
        return this.selectedTypes
          .map(st => shownTypes[st])
          .filter(type => !!type)
          .map(type => type.value)
      }
      return []
    },
    leadTimeGoal(){
      if(this.projectConfig && this.projectConfig.boardConfigs)
        return this.projectConfig.boardConfigs.find(lt => lt.workItemType == this.selectedType) || {}
      return {}
    },
    someActivedMenu(){
      for(let key in this.activedMenus){
        if(this.activedMenus[key])
          return true
      }
      return false
    },
    shownTypes() {
      if (!this.projectConfig || !this.projectConfig.workItemStatuses || !this.types) return []
      return this.types.filter(t =>
        this.projectConfig.workItemStatuses.some(s => s.workItemType == t.value)
      ).map(t => {
        let cur = this.projectConfig.boardConfigs.find(s => s.workItemType == t.value)
        t.displayName = (cur && cur.nickname) ? cur.nickname : t.displayName
        return t
      })
    },
    selectedType() {
      let shownTypes = this.shownTypes
      if (shownTypes && this.board < this.shownTypes.length && this.board != -1) {
        let type = shownTypes[this.board].value
        if(this.leadTime.type != type){
          this.$http.get(`api/Project/${this.id}/leadTime/${type}`, {headers: {hideLoading: true}}).then(res => {
            this.leadTime = {
              value: res.data.leadTime,
              type: type
            }
          })
        }
        return shownTypes[this.board].value
      }
      this.board = 0
      return 0
    },
    filterActive(){
      return (this.filters.tags && this.filters.tags.length) ||
        (this.filters.reportedBy && this.filters.reportedBy.length) ||
        (this.filters.assignedTo && this.filters.assignedTo.length) ||
        (this.filters.colors && this.filters.colors.length) ||
        (this.search)
    },
    tags() {
      if (!this.projectConfig || !this.projectConfig.tags) return []
      let tags = _.orderBy(this.projectConfig.tags, ['sortIndex'])
      tags = tags.filter(t => t.types.includes(this.selectedType))
      this.$q.addFirstItem(tags, '(blank)', 'name')
      return tags
    },
    
    sprints() {
      if (!this.projectConfig || !this.projectConfig.sprints) return []
      let sprints = _.cloneDeep(this.projectConfig.sprints.filter(s => s.id != null))
      this.$q.addFirstItem(sprints, 'Backlog')
      return sprints
    },
    someSelected(){
      return !!this.selectedWorkItems.length
    },
    activeSprint() {
      let date = moment().format()
      let activeSprint = {}
      if (!this.projectConfig || !this.projectConfig.sprints) return activeSprint
      this.projectConfig.sprints.forEach(sprint => {
        if(!sprint.startDate || !sprint.endDate) return
        if(moment(date).isBetween(sprint.startDate, sprint.endDate, 'day', [])) {
          activeSprint = sprint
        }
      })
      return activeSprint
    },
  },
  methods: {
    tagNotActiveButAssigned(tag) {
      return tag.active || this.contextWorkItem?.tags.some(t => t.tagId == tag.id)
    },
    createNewSprint(sprint) {
      this.projectConfig.sprints.push(sprint)
      this.$nextTick(() => this.saveProperty(sprint.id, 'sprintId', this.contextWorkItem))
    },
    triggerSprintAdd() {
      this.$refs.sprints.onAddButton()
    },
    closeSmartSearch(e) { 
      if(this.search.length >= 2 && e.path?.indexOf(document.getElementById('search')) == -1)
        this.showSmartSearch = false
    },
    toggleSprintGoal() {
      this.showSprintGoal = !this.showSprintGoal
      setTimeout(() => {
        this.handleClickOutsideSprintGoal = this.showSprintGoal
      }, 200)
    },
    toggleClickOutsideSprintGoal() {
      if(this.handleClickOutsideSprintGoal && this.showSprintGoal)
        this.showSprintGoal = !this.showSprintGoal
        this.$nextTick(() => this.handleClickOutsideSprintGoal = this.showSprintGoal)
    },
    changeSmartSearchProperty(property, value) {
      if(this.filters[property].indexOf(value) == -1) {
        this.temporalSearch = ''
        this.showSmartSearch = false
        this.filters[property].push(value)
      }
    },
    toggleShowFilters() {
      this.showFilters = !this.showFilters
      setImmediate(() => {
        document.activeElement.blur()
      })
    },
    toggleFilterSelectAll(property, value) {
      const valuesToSelect = {
        tags: this.tags.map(tag => tag.id),
        sprints: this.projectConfig.sprints.map(sprint => sprint.id),
        priorities: this.priorities.map(priority => priority.value),
        statuses: this.projectConfig.workItemStatuses.filter(s => this.selectedTypeValues.includes(s.workItemType)).map(status => status.id),
        colors: [],
        reportedBy: this.users.map(user => user.id),
        assignedTo: this.users.map(user => user.id),
        defectsOrigins: this.projectConfig.defectsOrigins.map(defect => defect.id),
      }
      if(value) {
        this.filters[property] = valuesToSelect[property]
        return
      }
      this.filters[property] = [] 
    },
    addNewTagToWorkItem(tag) {
      this.toggleTag(tag, this.contextWorkItem, true)
    },
    openTagDialog() {
      let project = this.project
      this.$refs.createTagDialog.open(project.id, null, this.selectedType)
    },
    assignQueryPathToFilter(query, filter) {
      if(!this.filters[filter] || !this.filters[filter].length)
        return
      if(query === null)
        query = [null]
      if(typeof query === 'string') {
        filter === 'priorities' ? this.filters[filter].push(parseInt(query)) : this.filters[filter].push(query)
      } else {
        if(filter === 'priorities') {
          query = query.map(prioritie => parseInt(prioritie))
        }
        else if((filter === 'reportedBy' || filter === 'assignedTo')  && (query[0] === null)) {
          this.filters[filter].push(query)
        } 
        this.filters[filter] = query
      }
    },
    changeQueryPath(query) {
      query = _.clone(query)
      this.$router.push({ 
        path: this.$router.currentRoute.path,
        query: { 
          ...this.$route.query,
          ...query
        }
      })
    },
    initialAssignOfQueryPaths() {
      if(this.$route.query.view !== null && this.$route.query.view != undefined)
        this.view = parseInt(this.$route.query.view) || 0
      this.filters.tagCondition = this.$route.query.tagCondition || this.filters.tagCondition
      const queryPathKeys = _.keys(this.$route.query)
      queryPathKeys.forEach(queryPath => {
        this.assignQueryPathToFilter(this.$route.query[queryPath], queryPath)
      })
    },
    init() {
      this.selectedWorkItems = []
      this.fetchConfig()

      this.$http.get(`/api/Enums/WorkItemType`)
        .then(res => {
          this.types = res.data
        })
        .catch(res => {
          window.getApp.error("Cannot obtain types.")
          console.log(res)
        })

      let users = []
      let membersPr = this.$http
        .get(`/api/project/${this.id}/members`)
        .then(res => {
          let members = res.data
          users = users.concat(
            members.map(member => {
              if(!member.user.blueTag)
                member.user.blueTag = member.user.fullName
              return member.user
            })
          )
        })
        .catch(res => {
          console.log(res)
          window.getApp.error("Cannot obtain project members.")
        })
      let billableMembersPr = null
      if(!this.isPersonal){
        billableMembersPr = this.$http.get(`api/nucleiManagement/${this.id}/members`)
          .then((res) => {
            users = users.concat(
              res.data.map(x => ({
                id: x.assignment.userId,
                blueTag: x.assignment.userBlueTag,
                fullName: x.assignment.userFullName,
              }))
            )
          })
          .catch(err => {
            console.error(err)
            window.getApp.error("Cannot get nuclei members.");
          })
      
      }
      
      let curUserPr = this.$blueSystem.getCurrentUser()
        .then(user => {
          this.currentUser = user
          this.isEmployee = user?.type === 0
        })

      Promise.all([membersPr, billableMembersPr, curUserPr])
        .then(() => {
          this.users = []
          users.forEach(x => {
            if(!this.users.some(u => u.id == x.id))
              this.users.push(x)
          })
          this.$q.addLastItem(this.users, 'Unassigned', 'blueTag')
          let current = this.users.find(u => u.id === this.currentUser.id)
          if(current){
            this.$set(current, 'blueTag', current.blueTag + ' (me)')
            this.users = [current].concat(this.users.filter(u => u.id !== this.currentUser.id))
          }
        })

      this.$http.get(`/api/Enums/WorkItemPriority`)
        .then(res => {
          this.priorities = res.data
        })
        .catch(res => {
          window.getApp.error("Cannot obtain priorities.")
          console.error(res)
        })
      
      this.$http
        .get(`/api/project/${this.id}`)
        .then(response => {
          this.project = response.data
        })
        .catch(response => {
          console.log(response)
          window.getApp.error("Cannot get project.");
        });
    },
    fetchConfig() {
      this.$http
        .get(`/api/project/${this.id}/config?active=true`)
        .then(res => {
          this.projectConfig = res.data
        })
        .catch(res => {
          window.getApp.error("Cannot get project sprints, tags and status.")
          console.log(res)
        })
    },
    computeIndicators(){
      let promiseConfig = this.$http
        .get(`/api/project/${this.id}/config?active=true`, {headers: {hideLoading: true}})
        .then(res => {
          this.projectConfig.boardConfigs = res.data.boardConfigs
          for(let status of res.data.workItemStatuses){
            let curStatus = this.projectConfig.workItemStatuses.find(s => s.id == status.id)
            curStatus.cycleTime = status.cycleTime
          }
        })
        .catch(res => {
          window.getApp.error("Cannot get project sprints, tags and status.")
          console.log(res)
        })
      let promiseLeadTime = this.$http.get(`api/Project/${this.id}/leadTime/${this.selectedType}`, {headers: {hideLoading: true}})
        .then(res => {
          this.leadTime = {
            value: res.data.leadTime,
            type: this.selectedType
          }
        })

      Promise.all([promiseConfig, promiseLeadTime]).then(() => {
        this.$forceUpdate()
      })
    },
    showLeadTime(){
      this.$refs.leadTimeDialog.open(this.id, this.selectedType)
    },
    resetFilters(){
      this.filters = {
        statuses: [],
        tags: [],
        sprints: [], 
        priorities: [], 
        assignedTo: [],
        reportedBy: [],
        colors: [],
        defectsOrigins: [],
      }
      this.temporalSearch = ''
    },
    applyFilters(){
      for(let key in this.inputSearches){
        this.inputSearches[key] = ''
      }
    },
    setAssigedToMe(){
      if (this.currentUser)
        this.$set(this.filters, 'assignedTo', [ this.currentUser.id ])
    },
    setReportedByMe(){
      if (this.currentUser)
        this.$set(this.filters, 'reportedBy', [ this.currentUser.id ])
    },
    addColorFilter(color){
      if(color.hex.toLowerCase() != '#ff0000' && !this.filters.colors.includes(color.hexa)){
        this.filters.colors.push(color.hexa)
      }
    },
    getStatusesByType(type){
      if (!this.projectConfig || !this.projectConfig.workItemStatuses) return []
      return this.projectConfig.workItemStatuses.filter(wi => wi.workItemType == type)
    },
    handleNewChange(value, property, workItem){
      let workItems = [workItem]
      workItems = workItems.concat(this.newWorkItems.concat(this.workItems).filter(wi => wi._selected))
      switch(property){
        case 'type':
          let type = this.types.find(t => t.value == value)
          let statuses = this.projectConfig.workItemStatuses
          let defaultStatus = statuses.find(s => s.workItemType === value && s.isDefault)
          let curStatus = statuses.find(s => s.workItemType === value)
          if(defaultStatus)
            curStatus = defaultStatus
          for(let wi of workItems){
            this.$set(wi, 'type', value)
            if(!wi.isNew){
              this.moveToStatus(curStatus, workItem)
              continue
            }
            this.$set(wi, 'typeColor', type.color)
            this.$set(wi, 'typeIconClass', type.iconClass)
            this.$set(wi, 'typeDisplayName', type.displayName)
            this.$set(wi, 'typeName', type.name)
            this.$set(wi, 'statusId', curStatus.id)
            this.$set(wi, 'statusColor', curStatus.color)
            this.$set(wi, 'statusIconClass', curStatus.iconClass)
            this.$set(wi, 'statusName', curStatus.name)
          }
          break
        case 'statusId':
          let status = this.projectConfig.workItemStatuses.find(s => s.id == value)
          for(let wi of workItems){
            if(!wi.isNew){
              this.moveToStatus(status, wi)
              continue
            }
            this.$set(wi, 'statusId', status.id)
            this.$set(wi, 'statusColor', status.color)
            this.$set(wi, 'statusIconClass', status.iconClass)
            this.$set(wi, 'statusName', status.name)
          }
          break
        case 'priority':
			    let priority = this.priorities.find(p => p.value == value)
          this.$set(workItem, 'priority', priority.value)
          this.$set(workItem, 'priorityColor', priority.color)
          this.$set(workItem, 'priorityIconClass', priority.iconClass)
          this.$set(workItem, 'priorityDisplayName', priority.displayName)
          this.$set(workItem, 'priorityName', priority.name)
          break
        case 'sprintId':
			    let sprint = this.sprints.find(s => s.id == value)
          this.$set(workItem, 'sprintId', sprint.id)
          this.$set(workItem, 'sprintName', sprint.name)
          break
        case 'assignedToId':
          let assignedTo = this.users.find(u => u.id == value)
          this.$set(workItem, 'assignedToId', assignedTo.id)
          this.$set(workItem, 'assignedToBlueTag', assignedTo.blueTag)
          this.$set(workItem, 'assignedToFullName', assignedTo.fullName)
          break
        case 'reportedById':
          let reportedBy = this.users.find(u => u.id == value)
          this.$set(workItem, 'reportedById', reportedBy.id)
          this.$set(workItem, 'reportedByBlueTag', reportedBy.blueTag)
          this.$set(workItem, 'reportedByFullName', reportedBy.fullName)
          break
        case 'tags':
          let currentTags = workItem.tags.map(t => t.tagId)
          let unique1 = value.filter(t => currentTags.indexOf(t) === -1)
          let unique2 = currentTags.filter(t => value.indexOf(t) === -1)
          let tagId = unique1.concat(unique2)[0]
          let tag = this.projectConfig.tags.find(t => t.id == tagId)
          let hasTag = workItem.tags.some(t => t.tagId == tag.id)
          for(let wi of workItems){
            let currentHasTag = wi.tags.some(t => t.tagId == tag.id)
            if(currentHasTag != hasTag)
              continue
            if(!wi.isNew){
              this.toggleTag(tag, wi)
              continue
            }
            if (hasTag) {
              wi.tags = wi.tags.filter(t => t.tagId != tag.id)
            } else {
              wi.tags.push({
                tagId: tag.id,
                tagName: tag.name,
                tagColor: tag.color,
                tagSortIndex: tag.sortIndex,
              })
            }
          }
          break
      }
    },
    saveProperty(value, property, workItem) {
      if(this.someSelected && property != 'name' && property != 'description'){
        this.newWorkItems
          .filter(wi => wi._selected)
          .forEach(wi => {
            wi[property] = value
            this.handleNewChange(value, property, wi)
          })
        this.saveMultipleProperty(value, property, '', this.viewList)
        return
      }
      let original = workItem[property]
      workItem[property] = value
      if (original === value) {
        return
      }

      if(workItem.isNew){
        this.handleNewChange(value, property, workItem)
        return
      }

      this.$q.save({
        api: `/api/WorkItem/${workItem.id}/byProperty/${property}`,
        data: {
          value: value+''
        },
        successMsg: null,
        afterSuccessUrl: null,
        afterErrorUrl: null,
      }).then(res => {
        workItem = Object.assign(workItem, res.data)
        this.socket.invoke("ProjectUpdated", this.id, workItem.id)
        this.showWorkItemContextMenu = false
        this.setDarkenWorkItem(workItem)
      }).catch(res => {
        if(res.response.status){
          window.getApp.error("You don't have permission to edit this project.")
        } else {
          window.getApp.error("Cannot save changes.")
        }
        workItem[property] = original
        this.setDarkenWorkItem(workItem)
      })
    },
    confirmSaveMultipleProperty(value, property, entityId, updated) {
      this.$q.save({
        api: `/api/WorkItems/byProperty/${property}`,
        data: {
          value: value+'',
          workItems: updated.map(wi => wi.id),
          entityId: entityId || null,
        },
        successMsg: null,
        afterSuccessUrl: null,
        afterErrorUrl: null,
      }).then(res => {
        this.init()
        this.showWorkItemContextMenu = false
      })
    },
    async saveMultipleProperty(value, property, entityId = '', confirmChange, callback) {
      let updated = this.selectedWorkItems
      if(updated.length == 0)
        return
      let text = `${updated.length} work items`
      let entityName = ''
      if(!!entityId) {
        if(property == 'tag')
          entityName = this.tags.find(tag => tag.id == entityId).name
        else if(property == 'sprint')
          entityName = this.sprints.find(sprint => sprint.id == entityId).name
      }
      const propertyToShow = {
        'assignedToId': `change the Assigment of ${text}`,
        'reportedById': `change the reported by of ${text}`,
        'priority': `change the Priority of ${text}`,
        'sprintId': `change the Sprint of ${text}`,
        'estimatedEffort': `change the estimated effort of ${text}`,
        'tag': `change the Tag '${entityName || ''}' in ${text}`,
        'sprint': `change the Sprint '${entityName || ''}' in ${text}`,
      }
      if(confirmChange) {
        const confirmed = await this.$openConfirmDialog({
          title: "Confirm changes",
          description: `Are you sure you want to ${propertyToShow[property] || property}?`,
        })
        if (!confirmed) return
      }

      this.confirmSaveMultipleProperty(value, property, entityId, updated)
      if(typeof callback == 'function') callback()
    },
    confirmMultipleWorkItemChange(status, index, updated) {
      return this.$q.save({
        api: `/api/WorkItems/multipleMoveOnBoard/${status.id}/${index}`,
        data: { workItems: updated },
        successMsg: `Work Items updated.`,
        afterSuccessUrl: null,
        afterErrorUrl: null,
      })
    },
    multipleWorkItemChanged(status, index, confirmChange){
      let updated = this.selectedWorkItems.map(wi => wi.id)
      if(updated.length == 0)
        return
      if(confirmChange) {
        this.$vuetifyConfirmDialog.open("Confirm changes", `Are you sure you want to change the type of ${updated.length} work items?`, "Cancel", "Confirm")
        .then(confirmed => {
          if (!confirmed) return
          this.confirmMultipleWorkItemChange(status, index, updated)
        })
      } else {
        return this.confirmMultipleWorkItemChange(status, index, updated)
      }
    },
    workItemChanged($event, status) {
      let eventInfo = $event.added || $event.moved
      if (!eventInfo) return
      let workItem = eventInfo.element
      let newIndex = eventInfo.newIndex

      if(this.someSelected){
        return this.multipleWorkItemChanged(status, newIndex, this.viewList)
      }
      
      return new Promise((resolve, reject) => {
        this.$q.save({
          api: `/api/WorkItem/${workItem.id}/moveOnBoard/${status.id}/${newIndex}`,
          data: null,
          successMsg: `${workItem.typeDisplayName} updated.`,
          afterSuccessUrl: null,
          afterErrorUrl: null,
        }).then(res => {
          if(status.confetti && workItem.statusId != status.id){
            this.$confetti.start()
            setTimeout(() => { this.$confetti.stop() }, 2000)
          }
          this.showWorkItemContextMenu = false
          this.socket.invoke("ProjectUpdated", this.id, workItem.id)
          this.computeIndicators()
          resolve(res)
        })
        .catch(err => {
          console.error(err)
          window.getApp.error("Cannot update work item")
          reject(err)
        })
      })
    },
    toggleSelect(){
      this.$set(this.contextWorkItem, '_selected', !this.contextWorkItem._selected)
    },
    deselectAll(){
      this.selectedWorkItems.forEach(wi => this.$set(wi, '_selected', false))
    },
    moveToStatus(status, workItem = this.contextWorkItem){
      let newIndex = 0
      let previousStatus = this.projectConfig.workItemStatuses.find(st => st.id == workItem.statusId)
      status = this.projectConfig.workItemStatuses.find(st => st.id == status.id)
      let previousIndex = workItem.boardColumnSortIndex
      
      if(status.confetti && workItem.statusId != status.id){
        this.$confetti.start()
        setTimeout(() => { this.$confetti.stop() }, 2000)
      }

      if(this.someSelected){
        this.multipleWorkItemChanged(status, newIndex, this.viewList)
          .then(() => {
            this.$refs.kanban.loadKanbans()
            this.board = this.shownTypes.findIndex((t, i) => t.value == status.workItemType)
          })
        return
      }

      this.leadTime = {
        value: this.leadTime.value,
        type: this.leadTime.type
      }

      this.$q.save({
        api: `/api/WorkItem/${workItem.id}/moveOnBoard/${status.id}/${newIndex}`,
        data: null,
        successMsg: `${workItem.typeDisplayName} updated.`,
        afterSuccessUrl: null,
        afterErrorUrl: null,
      }).then(res => {
        this.$refs.kanban.loadKanbans()

        this.board = this.shownTypes.findIndex((t, i) => t.value == res.data.type)
        this.socket.invoke("ProjectUpdated", this.id, workItem.id)
        this.computeIndicators();
      }).catch(res => {
        console.log(res)
        if(res.response.status){
          window.getApp.error("You don't have permission to edit this project.")
        } else {
          window.getApp.error("Cannot save changes.")
        }
      })
    },
    toggleTag(tag, workItem = this.contextWorkItem, newTagCreated) {
      let hasTag = workItem.tags.some(t => t.tagId == tag.id)
      let addTag = !hasTag
      if(this.someSelected){
        this.multiToggleTag(tag, addTag)
        return
      }
      if (hasTag) {
        workItem.tags = workItem.tags.filter(t => t.tagId != tag.id)
      } else {
        workItem.tags.push({
          tagId: tag.id,
          tagName: tag.name,
          tagColor: tag.color,
          tagSortIndex: tag.sortIndex,
        })
      }

      this.$q.save({
        api: `/api/WorkItem/${workItem.id}/tag/${tag.id}/${addTag}`,
        data: null,
        successMsg: null,
        afterSuccessUrl: null,
        afterErrorUrl: null,
      }).then(res => {
        for(let [i, wi] of this.workItems.entries()){
          if(wi.id == workItem.id){
            this.workItems[i] = res.data
            this.setDarkenWorkItem(this.workItems[i])  
          }
        }

        this.socket.invoke("ProjectUpdated", this.id, workItem.id)
        this.$forceUpdate()
        if(newTagCreated) {
          this.init()
        }
      }).catch(res => {
        if(res.response.status){
          window.getApp.error("You don't have permission to edit this project.")
        } else {
          window.getApp.error("Cannot save changes.")
        }
        workItem.tags = workItem.tags.filter(t => !!t.id)
        this.setDarkenWorkItem(workItem)
      })

    },
    multiToggleTag(tag, addTag){
      const toggleLocalTags = () => {
        for(let wi of this.workItems.filter(wi => wi._selected)){
          let tags = wi.tags;
          let wiHas = tags.some(t => t.tagId == tag.id)
          if(!wiHas && addTag){
            tags.push({
              tagId: tag.id,
              tagName: tag.name,
              tagColor: tag.color,
              tagSortIndex: tag.sortIndex,
            })
          }
          else if(wiHas && !addTag)
            tags = tags.filter(t => t.tagId != tag.id)
          wi.tags = tags
        }
      }
      this.saveMultipleProperty(addTag, 'tag', tag.id, this.viewList, toggleLocalTags)
    },
    toggleSprint(sprint, workItem = this.contextWorkItem) {
      let hasSprint = workItem.sprints.some(t => t.sprintId == sprint.id)
      if(this.someSelected){
        this.multiToggleSprint(sprint, !hasSprint)
        return
      }

      this.$q.save({
        api: `/api/WorkItem/${workItem.id}/sprint/${sprint.id}/${!hasSprint}`,
        data: null,
        successMsg: null,
        afterSuccessUrl: null,
        afterErrorUrl: null,
      }).then(res => {
        for(let [i, wi] of this.workItems.entries()){
          if(wi.id == workItem.id){
            this.workItems[i] = res.data
            this.setDarkenWorkItem(this.workItems[i])  
          }
        }

        this.socket.invoke("ProjectUpdated", this.id, workItem.id)
        this.$forceUpdate()
      }).catch(res => {
        if(res.response.status){
          window.getApp.error("You don't have permission to edit this project.")
        } else {
          window.getApp.error("Cannot save changes.")
        }
      })
    },
    multiToggleSprint(sprint, addSprint){
      const toggleLocalSprints = () => {
        for(let wi of this.workItems.filter(wi => wi._selected)){
          let sprints = wi.sprints;
          let wiHas = sprints.some(t => t.sprintId == sprint.id)
          if(!wiHas && addSprint){
            sprints.push({
              sprintId: sprint.id,
              sprintName: sprint.name,
              sprintColor: sprint.color,
            })
          }
          else if(wiHas && !addSprint)
          sprints = sprints.filter(t => t.tagId != tag.id)
          wi.sprints = sprints
        }
      }
      this.saveMultipleProperty(addSprint, 'sprint', sprint.id, this.viewList, toggleLocalSprints)
    },
    openWorkItemDetailsDialog(id, callback) {
      this.$router.push({
        query: { wi: id }
      }).catch(()=>{})

      let project = this.$q.getParentVariable("ProjectDetails", "item")
      let defaults = {
        projectId: this.id,
        openCreateDialog: this.openWorkItemCreateDialog
      }

      this.$refs.workItemDetailsDialog.open(id, defaults, this.socket)
        .finally(result => {
          this.$router.replace({
            query: {}
          })
          document.title = 'Work Items - ' + this.project.name
          
          if(typeof callback == 'function')
            callback()

          this.workItemUpdated(id)
        })
    },
    openWorkItemCreateDialog(statusId, createFrom) {
      let project = this.$q.getParentVariable("ProjectDetails", "item")
      let defaults = {
        projectId: this.id,
        type: this.selectedType,
        statusId: statusId,
        createFrom
      }
      this.$refs.workItemCreateDialog.open(defaults, this.socket)
        .then(result => {
          this.$refs.kanban.loadKanbans()
          // Reload
          this.init()
        })
    },
    openWorkItemContextMenu(e, workItem) {
      if(!this.canEditProject) return
      e.preventDefault()
      e.stopPropagation()
      this.xMenu = e.clientX
      this.yMenu = e.clientY
      this.showColumnContextMenu = false
      this.$nextTick(() => {
        this.showWorkItemContextMenu = true
        this.contextWorkItem = workItem
        this.color = workItem.boardColor || "#FFFFFFFF"
      })
    },
    openColumnContextMenu(e, statusId) {
      if(!this.canEditProject) return
      e.preventDefault()
      this.xColumnMenu = e.clientX
      this.yColumnMenu = e.clientY
      this.showWorkItemContextMenu = false
      this.$nextTick(() => {
        this.showColumnContextMenu = true
        this.contextStatusId = statusId
      })
    },
    colorChanged(color) {
      if (!this.contextWorkItem) {
        console.error("No context work item")
      }

      if (!color) {
        color = null
      } else {
        color = color.hexa || color
      }

      this.saveProperty(color, 'boardColor', this.contextWorkItem)
    },
    workItemTags(workItem) {
      if (!workItem.tags) return []
      return _.orderBy(workItem.tags, ['tagSortIndex'])
    },
    workItemUpdated(workItemId){
      this.$http.get(`api/workItem/${workItemId}`, {headers: {hideLoading: true}})
        .then(res => {
          let newWorkItem = res.data
          if(!newWorkItem){
            this.workItems = this.workItems.filter(wi => wi.id != workItemId && wi.code != workItemId)
            this.computeIndicators()
            this.projectConfig.workItemStatuses.forEach(status => {
              this.$set(status, 'workItems', [])
              status.workItems = _.orderBy(this.workItems.filter(wi => wi.statusId == status.id), ['boardColumnSortIndex'])
            })
            return
          }
          
          this.setDarkenWorkItem(newWorkItem);
          let oldWorkItem = this.workItems.find(wi => wi.id == newWorkItem.id)

          if(!oldWorkItem)
            this.workItems.push(newWorkItem);
          else {
            oldWorkItem = Object.assign(oldWorkItem, newWorkItem)
            let currentSortIndex = 0
            let sameColumnWorkItems = this.workItems
              .filter(wi => wi.statusId == newWorkItem.statusId)
              .sort( (a, b) => a.boardColumnSortIndex - b.boardColumnSortIndex )
            for(let wi of sameColumnWorkItems){
              if(wi.id != newWorkItem.id){
                if(currentSortIndex == newWorkItem.boardColumnSortIndex)
                  currentSortIndex++
                if(wi.boardColumnSortIndex != currentSortIndex)
                  wi.boardColumnSortIndex = currentSortIndex
                currentSortIndex++
              }
            }
          }
          this.computeIndicators()
        })
    },
    signalRConnection(){
      if(this.socket.state != 'Connected'){
        setTimeout(this.signalRConnection, 1000)
        return
      }
      
      this.socket.off('ProjectUpdate')
      this.socket.off('WorkItemDelete')
      this.socket.invoke("JoinGroup", this.id)

      this.socket.on("ProjectUpdate", this.workItemUpdated)

      this.socket.on('WorkItemDelete', workItemId => {
        this.workItems = this.workItems.filter(wi => wi.id != workItemId)
        this.computeIndicators()
      })
    },
    setDarkenWorkItem(workItem) {
      let color = workItem.boardColor
      if (!color) {
        this.$set(workItem, 'dark', false)
        return
      }

      const hex   = color.replace(/#/, '')
      if(hex.includes('000000')) {
        this.$set(workItem, 'dark', this.$vuetify.theme.isDark)
        return
      }

      const r     = parseInt(hex.substr(0, 2), 16)
      const g     = parseInt(hex.substr(2, 2), 16)
      const b     = parseInt(hex.substr(4, 2), 16)

      let luma = [
          0.299 * r,
          0.587 * g,
          0.114 * b
      ].reduce((a, b) => a + b) / 255

      this.$set(workItem, 'dark', (luma < 0.65))
    },
		removeNew(code){
      this.$refs.list.removeNew(code)
		},
    client() { return this.$router.history.current.path.startsWith('/client') },
  }

}
</script>

<style scoped lang="scss">
.header{
  display: flex;
  align-items: center;
  gap: 5px;
  position: relative;
  z-index: 120;
}
.header .justify-right{
  display: flex;
  align-items: center;
  margin-left: auto;
}
.filter-container{
  display: flex;
  gap: 10px;
  margin-top: 10px;
}
.search{
  display: inline-block;
  min-width: 317px;
  position: relative;
  z-index: 120;
}
.leadtime-container{
  display: inline-flex;
  align-items: center;
  justify-content: center;
}
.leadtime-avg{
  background: var(--v-secondaryBackground-base);
  padding: 4px 15px;
  border-radius: 3px;
  cursor: pointer;
  font-size: .8em;
  height: 40px;
  display: flex;
  align-items: center;
  border-radius: 7px;  
}
.leadtime-avg div{
  text-align: center;
}

.color-filter{
  border: 1px solid #555;
  border-radius: 5px;
  padding: 7px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: var(--v-text-lighten2);

  .v-icon{
    color: var(--v-text-lighten2);
  }

  .colors-container{
    display: flex;
    flex-wrap: wrap;

    .color{
      display: inline-block;
      margin: 2px 4px 2px 0;
      border: 1px solid var(--v-text-lighten);
      width: 24px;
      height: 24px;
      border-radius: 50%;

      &:hover .v-icon{
        display: initial;
      }

      .v-icon{
        display: none;
        position: relative;
        left: 3px;
      }
    }
  }
}
.slider{
  display: inline-block;
  width: auto !important;
  padding: 5px 1px;
}
.colored-back {
  background-color: rgba(22, 123, 215, 0.08) !important;
}
.top{
  position: sticky;
  left: 120px;
}
.v-color-picker ::v-deep .v-color-picker__preview {
  display: none;
}
.tags-container{
  max-height: 400px;
  overflow-y: scroll;
}
.toggleSelect {
  display: flex;
  gap: 10px;
  justify-content: flex-start;
  padding-left: 10px;
  text-decoration: underline !important;
}
.v-text-field.v-text-field--enclosed:not(.v-text-field--rounded) > .v-input__control > .v-input__slot, .v-text-field.v-text-field--enclosed .v-text-field__details {
  padding: 0 6px;
}
.container-tag-options {
  position: sticky;
  bottom: 0px;
  height: 70px;
  background: var(--v-background-lighten);
}
.active-tag-condition {
  color: var(--v-primary-base) !important;
  border: 1px solid var(--v-primary-base)
}
.tag-condition {
  padding: 4px 4px;
  color: var(--v-text-base);
  border-radius: 4px;
  font-weight: bold;
}
.behavior-container {
  display: flex;
  justify-content: space-around;
  align-items: center;
  height: 100%
}
.behavior-text {
  font-weight: 550;
}
.divider {
  max-width: 80%;
  margin: 0 10%;
}
.active-blank {
  background: rgba(22, 123, 215, 0.08) !important;
}
.transparent {
  background: rgba(76, 175, 80, 0) !important;
}
.view-toggle .v-item--active.v-btn:before,
.v-slide-group .v-slide-item--active.v-btn:before
{
  background: none !important;
}

.view-toggle .v-btn:not(.v-item--active),
.v-slide-group .v-btn:not(.v-slide-item--active)
{
  &:hover{
    .v-icon{
      filter: none !important;
    }
  }
}
.sprint-goal-button {
  position: absolute;
  right: 0;
  top: 340px;
  z-index: 3;
  border-top-left-radius: 30px;
  border-bottom-left-radius: 30px;
}
.sprint-goal-card {
  border: 2px solid var(--v-primary-base);
  border-radius: 10px;
  position: absolute;
  z-index:2;
  right: 60px;
  top: 325px;
  background: var(--v-background-lighten);
  max-width: 80%;
}
.sprint-goal-info {
  display: flex;
  flex-direction: row;
  font-size: 18px;
  background: rgba(22, 123, 215, 0.08);
}
.sprint-goal-text {
  font-weight: bold;
}
.sprintGoal-enter-active {
  animation: sprintGoalIn 0.7s;
}
.sprintGoal-leave-active {
  animation: sprintGoalIn 0.7s reverse;
}

@keyframes sprintGoalIn {
  from {
    transform: translateX(-1000px);
    opacity: 0;
  }
  to {
    transform: translateX(0%);
    opacity: 1;
  }
}
.rounded-button {
  border-radius: 10px !important;
}
.toggle-view {
  height: 40px;
}
.input {
  border-radius: 7px;
}
</style>