var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('h2',{staticClass:"mb-3"},[_vm._v(" Tags "),_c('v-btn',{attrs:{"disabled":!_vm.$parent.canEditProject,"x-small":"","fab":"","depressed":"","color":"primary"},on:{"click":_vm.openNewTagDialog}},[_c('v-icon',{attrs:{"dark":""}},[_vm._v("add")])],1)],1),_c('data-table',{attrs:{"headers":_vm.headers,"items":_vm.$parent.config.tags,"sort-by":"sortIndex"},scopedSlots:_vm._u([{key:"item.sortIndex",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{staticClass:"sort-icon",attrs:{"text":"","icon":"","x-small":"","color":"primary","disabled":!_vm.$parent.canEditProject},on:{"click":function($event){return _vm.moveTagUp(item.id)}}},[_c('v-icon',{staticClass:"cursor-pointer"},[_vm._v("arrow_drop_up")])],1),_c('br'),_c('v-btn',{staticClass:"sort-icon",attrs:{"text":"","icon":"","x-small":"","color":"primary","disabled":!_vm.$parent.canEditProject},on:{"click":function($event){return _vm.moveTagDown(item.id)}}},[_c('v-icon',{staticClass:"cursor-pointer"},[_vm._v("arrow_drop_down")])],1)]}},{key:"item.name",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{style:(("color: " + (_vm.isBlackText(item.color) ? 'white' : 'black'))),attrs:{"small":"","dark":"","color":item.color}},[_vm._v(" "+_vm._s(item.name)+" ")])]}},{key:"item.description",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"tag-desc",style:({ minWidth: '120px', maxWidth: '240px' })},[_vm._v(" "+_vm._s(item.description ? JSON.parse(item.description) : "")+" ")])]}},{key:"item.types",fn:function(ref){
var item = ref.item;
return _vm._l((item.types),function(typeValue,ix){return _c('div',{key:ix,staticClass:"inline-block mr-2 mb-1 rounded pa-2",attrs:{"set":(_vm.type = _vm.$parent.types.find(function (t) { return t.value == typeValue; }) || {})}},[_c('v-icon',{staticClass:"mr-0",attrs:{"color":_vm.type.color}},[_vm._v(_vm._s(_vm.type.iconClass))]),_vm._v(" "+_vm._s(_vm.type.displayName)+" ")],1)})}},{key:"item.active",fn:function(ref){
var item = ref.item;
return [(item.active)?_c('v-icon',[_vm._v("check")]):_vm._e(),(!item.active)?_c('v-icon',[_vm._v("close")]):_vm._e()]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"nobr"},[_c('v-btn',{attrs:{"disabled":!_vm.$parent.canEditProject,"depressed":"","outlined":"","icon":"","fab":"","x-small":"","color":"primary"},on:{"click":function($event){return _vm.deleteTag(item.id)}}},[_c('v-icon',[_vm._v("delete")])],1),_c('v-btn',{attrs:{"disabled":!_vm.$parent.canEditProject,"fab":"","x-small":"","color":"primary"},on:{"click":function($event){return _vm.openEditTagDialog(item)}}},[_c('v-icon',[_vm._v("edit")])],1)],1)]}}],null,true)}),_c('create-tag-dialog',{ref:"createTagDialog",attrs:{"callback":function () { return _vm.$emit('saved'); }}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }