<template>
	<v-dialog
		:value="value"
		@input="v => $emit('input', v)"
		:width="containerWidth"
	>
		<v-theme-provider light>
			<v-card :min-height="this.landscape ? 'unset' : '90vh'" ref="card">
				<v-btn @click="$emit('input', false)" style="position: absolute; right: 5px; top: 5px" icon>
					<v-icon>close</v-icon>
				</v-btn>
				<v-card-text
					v-if="value" ref="content"
					class="pt-5 black--text"
					:class="{ 'd-flex': displayFlex }"
					style="width: 100%" :style="{ minHeight: contentMinHeight + 'px' }"
				>
					<slot/>
				</v-card-text>
				<v-btn
					v-if="prevent" @click="download"
					color="primary" fab
					:style="{
						position: 'sticky',
						bottom: landscape ? '5%' : '45px',
						left: `90%`,
						zIndex: 100,
					}"
				>
					<v-icon>mdi-download</v-icon>
				</v-btn>
			</v-card>
		</v-theme-provider>
	</v-dialog>
</template>

<script>
import { jsPDF } from "jspdf"
import html2canvas from 'html2canvas'

export default {
	props: {
		value: Boolean,
		name: String,
		displayFlex: Boolean,
		noMargin: Boolean,
		prevent: Boolean,
		preExportAtClick: Boolean,
		preExport: Function,
		landscape: Boolean,
	},
	watch: {
		value(v){
			if(v) {
				if(!this.prevent)
					this.download()
				else if(typeof this.preExport == 'function' && !this.preExportAtClick)
					this.preExport().then(this.download)
			}
		},
	},
	mounted(){
		if(this.value && !this.prevent)
			this.download()
		else if(typeof this.preExport == 'function' && !this.preExportAtClick){
			this.preExport().then(this.download)
		}
	},
	computed: {
		contentMinHeight(){
			if(this.landscape)
				return this.noMargin ? 650 : 750

			return this.noMargin ? 1000 : 1100
		},
		pageHeight() {
			if(this.landscape)
				return this.noMargin ? 205 : 210
			return this.noMargin ? 290 : 295
		},
		imageWidth() {
			return this.landscape ? 297 : 210
		},
		containerWidth() {
			return this.landscape ? 1000 : 730
		},
	},
	methods: {
		async download(){
			this.$root.addSpinner()
			if (this.preExportAtClick) {
				await this.preExport()
			}
			setTimeout(() => {
				const opts = {
					useCORS: true,
					scale: 2,
				}

				let contentHeight = this.$refs.content.offsetHeight
				let factor = Math.ceil(contentHeight / this.contentMinHeight)
				this.$refs.content.style.height = `${this.contentMinHeight * factor}px`

				html2canvas(this.$refs.content, opts)
					.then(canvas => {
						const doc = new jsPDF(this.landscape ? 'l' : 'p', 'mm')
						const margin = this.noMargin ? 0 : 10
						const imgWidth = this.imageWidth - margin*2
						const pageHeight = this.pageHeight
						let position = 5

						const imgData = canvas.toDataURL('image/png')
						const imgHeight = canvas.height * imgWidth / canvas.width
						let heightLeft = imgHeight

						doc.addImage(imgData, 'PNG', margin, position, imgWidth, imgHeight, undefined, 'FAST')
						heightLeft -= pageHeight

						while (heightLeft >= 0) {
							position = heightLeft - imgHeight
							doc.addPage()
							doc.addImage(imgData, 'PNG', margin, position, imgWidth, imgHeight)
							heightLeft -= pageHeight
						}

						doc.addImage(imgData, 'PNG', margin, position, imgWidth, imgHeight)
						doc.save(`${this.name}.pdf`)
					})
					.finally(() => this.$root.removeSpinner())
			}, 1000)
		},
	},
}
</script>

<style lang="scss" scoped>
</style>