<template>
	<v-dialog
		v-model="dialog" v-if="dialog"
		v-bind="$attrs" :width="width"
		persistent
		content-class="confirm-dialog"
	>
		<v-card color="high-back" class="pb-3">
			<v-card-title class="headline">
				<slot name="title">
					{{title}}
					<v-btn v-if="addClose" @click="close(false)" x-large icon absolute right><v-icon>mdi-close</v-icon></v-btn>
				</slot>
			</v-card-title>
			<v-card-text>
				<slot name="text">
					<div v-html="description"></div>
				</slot>
			</v-card-text>
			<v-card-actions >
				<slot name="actions" v-bind="{cancel: () => close(false), confirm: () => close(true)}">
					<v-spacer/>
					<v-btn v-if="!confirmOnly" @click="close(false)" :color="cancelBtnColor" text>{{cancelBtnText}}</v-btn>
					<v-btn @click="close(true)" :color="confirmBtnColor" text>{{confirmBtnText}}</v-btn>
				</slot>
			</v-card-actions>
		</v-card>
	</v-dialog>	
</template>

<script>
export default {
	props: {
		value: Boolean,
		persistent: Boolean,
		_title: String,
	},
	data: () => ({
		dialog: false,
		title: 'Confirm',
		description: 'Are you sure you want to do this change?',
		cancelBtnText: 'Cancel',
		confirmBtnText: 'Confirm',
		cancelBtnColor: '',
		confirmBtnColor: 'primary',
		confirmOnly: false,
		resolve: () => {},
		width: 500,
		addClose: false,
	}),
	watch: {
		value(){
			if(!this.value) this.close(false)
			else this.dialog = true
		},
	},
	created(){
		this.title = this._title
	},
	methods: {
		open(opts){
			this._data = Object.assign(this._data, opts)
			this.dialog = true
			this.$emit('input', true)

			return new Promise(resolve => {
				this.resolve = resolve
			})
		},
		close(res){
			this.resolve(res)
			if(!this.persistent || !res){
				this.dialog = false
				this.$emit('input', false)
				this._data = Object.assign(this._data, this.$options.data())
			}
		}
	},
	watch: {
		_title(){
			this.title = this._title
		}
	}
}
</script>

<style lang="scss" scoped>

</style>
