<template>
	<div class="pa-3 new-back full-height">
		<v-tabs
			v-model="activeTab"
			background-color="newDesignBackground"
			class="px-5 mb-5"
			style="border-bottom: 1px solid var(--v-lightgray-base)"
		>
			<v-tab> Billable Items </v-tab>
			<v-tab> Suggested Billable Items </v-tab>
		</v-tabs>

		<v-tabs-items v-model="activeTab" class="new-back">
			<v-tab-item :value="0">
				<BillableItemList
					v-if="activeTab === 0"
					:customerId="customerId"
					:invoiced="invoiced"
					:currency="currency"
				/>
			</v-tab-item>
			<v-tab-item :value="1">
				<SuggestedBillableItems
					v-if="activeTab === 1"
					:customerId="customerId"
					:invoiced="invoiced"
					:currency="currency"
				/>
			</v-tab-item>
		</v-tabs-items>
	</div>
</template>

<script>
import BillableItemList from "../../components/admin-surf/billable-item/BillableItemList"
import SuggestedBillableItems from "../../components/admin-surf/billable-item/SuggestedBillableItems"

export default {
	components: { BillableItemList, SuggestedBillableItems },
	props: ['customerId', 'invoiced', 'currency'],
	data: () => ({
		activeTab: 0,
	}),
	mounted(){
		document.title = 'Billable Items'
	},
	methods: {
		
	},
}
</script>

<style lang="scss" scoped>

</style>