import moment from "moment";

const indicatorsMixin = {
  install (Vue, options) {
    Vue.mixin({
      data() {
        return{
          cycleTimeGoalUnits: [
            { name: 'minutes', shortname: 'min',  value: 0, minsPerUnit: 1, workingMinsPerUnit: 1 },
            { name: 'hours',   shortname: 'hr',   value: 1, minsPerUnit: 60, workingMinsPerUnit: 60 },
            { name: 'days',    shortname: 'day',  value: 2, minsPerUnit: 1440, workingMinsPerUnit: 540 },
          ],
        }
      },
      methods: {

        getIndicatorTime(time, allowNegatives = false, workingHours = false, shortFormat = false){
          if(time == null || isNaN(time) || time == -1)
            return '-';
          let quantityByUnit = {
            hrs: 60,
            days: 24,
            months: 30,
            years: 12,
          }
          if(workingHours){
            quantityByUnit = {
              hrs: 60,
              days: 9,
              months: 21,
              years: 12,
            }
          }
          let mult = 1
          if(allowNegatives && time < 0){
            time = Math.abs(time)
            mult = -1
          }
          if(time < 0) time = 0;
          let units = 'mins';
          if(time >= quantityByUnit.hrs){
            time = Math.round(time / quantityByUnit.hrs);
            units = shortFormat ? 'h' : 'hrs';
            if(time >= quantityByUnit.days){
              time = Math.round(time / quantityByUnit.days);
              units = shortFormat ? 'd' : 'days';
              if(time >= quantityByUnit.months){
                time = Math.round(time / quantityByUnit.months);
                units = shortFormat ? 'm' : 'months';
                if(time >= quantityByUnit.years){
                  time = Math.round(time / quantityByUnit.years);
                  units = shortFormat ? 'y' :  'years';
                }
              }
            }
          }
          if(time == 1)
            units = units.substring(0, units.length-1);
          return shortFormat ? new Intl.NumberFormat('en-US').format(time*mult) + units : new Intl.NumberFormat('en-US').format(time*mult) + ' ' + units;
        },

        toMinutes(quantity, unit, workingHours = false){
          for(let goalUnit of this.cycleTimeGoalUnits){
            if(goalUnit.name == unit || goalUnit.shortname == unit || goalUnit.value == unit){
              if(workingHours)
                return quantity * goalUnit.workingMinsPerUnit
              return quantity * goalUnit.minsPerUnit
            }
          }
          return 0
        },

        formatTimeByUnit(mins, unit, workingHours = false){
          let quantity = 0
          let shortname = ''
          for(let goalUnit of this.cycleTimeGoalUnits){
            if(goalUnit.name == unit || goalUnit.shortname == unit || goalUnit.value == unit){
              if(workingHours)
                quantity = Math.round(mins / goalUnit.workingMinsPerUnit)
              else
                quantity = Math.round(mins / goalUnit.minsPerUnit)
              shortname = goalUnit.shortname
              break
            }
          }
          if(quantity <= 0){
            quantity = 0
          }
          return new Intl.NumberFormat('en-US').format(quantity) + ' ' + shortname + (quantity != 1 ? 's' : '')
        },

        isNullDate(date){
          return !date || date == '0001-01-01T00:00:00' || (typeof date.format == 'function' && date.format('YYYY-MM-DD') == '0001-01-01')
        },
        
      }
    })
  }
}
export default indicatorsMixin