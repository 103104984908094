<template>
	<div class="py-2 px-10 new-back full-height">
		<div class="d-none">
      <input
				type="file" ref="fileUploader"
				@change="handleFileUpload"
			/>
		</div>

		<div
			v-if="editing"
			class="d-flex align-center px-5 top-btn"
			:style="{background: $vuetify.theme.isDark ? 'var(--v-newDesignBackground-base)' : '#fafcfe'}"
		>
			<v-btn @click="save" color="primary">Save</v-btn>
		</div>

		<div class="text-right mb-2">
			<v-menu v-if="!isNew && !editing && permissions.edit" z-index="200">
				<template #activator="{ on }">
					<v-btn v-on="on" color="primary" outlined>
						Actions
						<v-icon right>mdi-chevron-down</v-icon>
					</v-btn>
				</template>
				<v-list>
					<v-list-item v-if="!editing" @click="editing = true" class="py-0 px-1">
						<v-list-item-content class="px-3">
							<v-list-item-title class="px-0">
								<v-icon left color="primary">mdi-pencil</v-icon> Edit invoice
							</v-list-item-title>
						</v-list-item-content>
					</v-list-item>
				</v-list>
			</v-menu>
		</div>

		<v-form ref="form" v-model="validForm">

			<v-row style="max-width: 1400px; margin-left: auto; margin-right: auto">
				<v-col :cols="8">
					<div class="bordered rounded-lg pa-5">
						<h4 class="mb-5">General details</h4>
						<v-row>
							<v-col :cols="6">
								<label>Invoice No.*</label>
								<v-text-field
									v-model.trim="item.code"
									v-bind="inputProps"
									:rules="requiredRules"
								/>
							</v-col>
							<v-col :cols="3">
								<label>Supplier*</label>
								<v-autocomplete
									@change="handleSupplierChange"
									v-model="item.supplierId"
									v-bind="inputProps"
									:value="item.supplierId" :items="suppliers"
									item-text="name" item-value="id" :rules="requiredRules"
								/>
							</v-col>
							<v-col :cols="3">
								<label>RFC/EIN*</label>
								<v-text-field
									:value="item.supplierRfc" disabled
									v-bind="inputProps"
								/>
							</v-col>
							<v-col :cols="4">
								<label>Invoice Date*</label>
								<q-date-picker
									v-bind="inputProps" inner
									:rules="requiredRules"
									v-model="item.date"
									label=""
								/>
							</v-col>
							<v-col :cols="4">
								<label>Due Date*</label>
								<q-date-picker
									v-bind="inputProps" inner
									:rules="requiredRules" :min="item.date"
									v-model="item.dueDate"
									label=""
								/>
							</v-col>
							<v-col :cols="4">
								<label>Status*</label>
								<v-select
									v-model="item.status"
									:items="statuses"
									v-bind="inputProps"
									:rules="requiredRules"
								/>
							</v-col>
							<v-col :cols="12">
								<label>Legal Address</label>
								<v-text-field
									:value="item.legalAddress"
									v-bind="inputProps" disabled
								/>
							</v-col>
						</v-row>
					</div>
					<div class="bordered rounded-lg pa-5 mt-5">
						<h4>Files</h4>
						<div
							class="mt-2 px-2 drop-files text-center" :class="{ hovered: isFileDropHovered }"
							@dragover.prevent="isFileDropHovered = true"
							@dragenter.prevent @dragleave="isFileDropHovered = false"
							@drop.prevent="handleDrop"
						>
							<template v-if="!item.files || !item.files.length">
								<template v-if="editing">
									<v-icon large>mdi-cloud-upload-outline</v-icon>
									<span class="body-1 mt-n2">
										Drag and drop your files here or
										<a @click="openFileUpload">Browse your computer</a>
									</span>
								</template>
								<template v-else>
									<v-icon>mdi-file-multiple-outline</v-icon>
									<span class="body-1 mt-n2">No files attached to this invoice</span>
								</template>
							</template>
							<template v-else>
								<div class="files">
								<div class="file-large bordered rounded-lg background--lighten cursor-pointer" @click="openFile(file)" v-for="(file, jx) in item.files" :key="jx">
									<img contain width="40px" src="@/assets/pdf.png" v-if="isPDF(file.name)"/>
									<img contain width="40px" src="@/assets/file.png" v-else/>
									<div class="f-info">
										<div> {{file.name}} </div>
										<div> {{formatSize(file.size)}} </div>
									</div>
									<div class="text-right">
										<v-btn
											@click.stop="deleteFile(file)" class="remove-file"
											v-if="editing"
											icon small fab
											color="error"
										>
											<v-icon>mdi-delete</v-icon>
										</v-btn>
									</div>
								</div>
							</div>
							<v-btn
								@click="openFileUpload"
								v-if="editing"
								color="primary" depressed
							>
								Add File
							</v-btn>
							</template>
						</div>
					</div>
				</v-col>
				<v-col :cols="4">
					<div class="bordered rounded pa-5" style="position: relative">
						<h4>Invoice amount</h4>

						<v-menu :disabled="!editing" offset-y>
							<template #activator="{ on, attrs }">
								<v-btn v-on="on" v-bind="attrs" text absolute right top>
									<v-avatar color="indigo" size="20" class="mr-1">
										<v-img :src="require(`../../assets/currencies/${item.currency.toLowerCase()}.png`)" />
									</v-avatar>
									<span>{{item.currency}}</span>
									<v-icon v-show="editing" right>mdi-chevron-down</v-icon>
								</v-btn>
							</template>
							<v-list dense>
								<v-list-item @click="item.currency = currency" v-for="(currency, ix) in currencies" :key="ix">
									<v-list-item-content>
										<div class="d-flex align-center">
											<v-avatar color="indigo" size="20" class="mr-2">
												<v-img :src="require(`../../assets/currencies/${currency.toLowerCase()}.png`)" />
											</v-avatar>
											<span>{{currency}}</span>
										</div>
									</v-list-item-content>
								</v-list-item>
							</v-list>
						</v-menu>

						<v-checkbox
							v-if="editing"
							@change="validTotal"
							v-bind="inputProps" :dense="false"
							label="Tax not required"
							v-model="item.notRequireTax"
						/>

						<div class="mt-8">
							<label>Subtotal*</label>
							<v-text-field
								:value="currencyFormat(item.subtotal)"
								v-bind="inputProps"
								readonly
							/>
						</div>
						<div class="mt-4">
							<label>Tax*</label>
							<v-text-field
								v-bind="inputProps"
								:value="currencyFormat(item.tax)" :disabled="item.notRequireTax"
								readonly
							/>
						</div>
						<div class="mt-4">
							<label>Total amount*</label>
							<v-text-field
								v-bind="inputProps"
								:value="currencyFormat(item.total)"
								readonly
							/>
						</div>
					</div>
				</v-col>

				<v-col :cols="12">
					<div class="bordered rounded-lg pa-5">
						<h4 class="mb-5">Invoice lines</h4>
						<div class="d-flex flex-column">
							<div
								v-for="(line, ix) in item.lines" :key="ix"
								class="d-flex" style="gap: 10px"
							>
								<div class="flex">
									<label>Description*</label>
									<v-text-field
										v-model="line.description"
										v-bind="inputProps"
										:rules="requiredRules"
									/>
								</div>
								<div class="flex">
									<label>Additional Details</label>
									<v-textarea
										v-model="line.details"
										v-bind="inputProps"
										:rows="1"
									/>
								</div>
								<div style="width: 100px">
									<label>Quantity*</label>
									<v-text-field
										v-model="line.quantity"
										@change="updateSubtotal"
										v-bind="inputProps"
										:rules="requiredRules"
										type="number"
									/>
								</div>
								<div style="width: 130px">
									<label>Unit Price*</label>
									<input-mask
										v-model="line.unitPrice"
										@change="updateSubtotal"
										v-bind="inputProps"
										:mask="currencyFormat"
										:rules="requiredRules"
										v-only-numbers
									/>
								</div>
								<div style="width: 130px">
									<label>Amount</label>
									<input-mask
										:value="line.unitPrice * line.quantity"
										v-bind="inputProps"
										:mask="currencyFormat"
										v-only-numbers
										disabled
									/>
								</div>
								<v-btn v-if="editing" @click="removeLine(line)" color="error" icon class="mt-6">
									<v-icon>mdi-delete</v-icon>
								</v-btn>
							</div>
						</div>
						<v-btn v-if="editing" @click="addLine" class="mt-2" depressed small>
							<v-icon left>mdi-plus</v-icon> Add line
						</v-btn>
					</div>
				</v-col>
			</v-row>
		</v-form>
	</div>
</template>

<script>
import moment from "moment"

export default {
	props: {
		id: String,
	},
	data: () => ({
		item: {
			files: [],
			status: 0,
			currency: 'MXN',
		},
		suppliers: [],
		currencies: [ 'MXN', 'USD', 'EUR' ],
		requiredRules: [v => (!!v && (!v.trim || !!v.trim())) || v === 0 || 'Required'],
		validForm: false,
		editing: false,
		statuses: [
			{ text: 'Draft', value: 0 },
			{ text: 'Processed', value: 2 },
			{ text: 'Cancelled', value: 3 },
		],
		permissions: {
			edit: false,
		},

		isFileDropHovered: false,
	}),
	mounted(){
		this.$security.hasRequiredPermission('edit')
			.then(res => this.permissions.edit = res)
		this.init()
	},
	computed: {
		inputProps(){
			return {
				outlined: true,
				dense: true,
				hideDetails: true,
				readonly: !this.editing,
			}
		},
		isNew(){ return this.id == 'new' },
	},
	methods: {
		init(){
			if(this.id && !this.isNew){
				this.$http.get('api/supplierInvoice/' + this.id)
					.then(res => {
						this.item = res.data
						this.$q.changeHeaderTitle(this.item.code)
						document.title = this.item.code + ' - Supplier Invoice'
					})
					.catch(err => {
						this.$root.error('Error loading supplier')
						console.error(err)
					})
			}
			else{
				this.editing = true
				this.$q.changeHeaderTitle('New Invoice')
				document.title = 'New Invoice'
			}

			this.$http.get('api/supplier')
				.then(res => {
					this.suppliers = res.data
				})
		},
		save(){
			if(!this.$refs.form.validate()) return

			this.$http.post('api/supplierInvoice', this.item)
				.then(res => {
					this.$root.success('Supplier saved')
					this.$router.go(-1)
				})
				.catch(err => {
					this.$root.error('Error saving supplier')
					console.error(err)
				})
		},
		updateSubtotal(){
			this.item.subtotal = this.item.lines.reduce((acc, x) => acc + ((x.quantity || 0) * (x.unitPrice || 0)), 0)
			this.validTotal()
		},
		handleSupplierChange(){
			const supplier = this.suppliers.find(x => x.id == this.item.supplierId)
			if(!supplier) return

			this.item.supplierRfc = supplier.rfc
			this.item.legalAddress = supplier.legalAddress
		},
		addLine(){
			if(!this.item.lines) this.$set(this.item, 'lines', [])
			this.item.lines.push({})
		},
		removeLine(line){
			this.$openConfirmDialog({
				title: 'Remove line',
				description: 'Are you sure you want to remove this line?',
			})
				.then(res => {
					if(!res) return
					line.deleted = true
					this.item.lines = this.item.lines.filter(x => !x.deleted)
				})
		},
		openFile(file){
			window.open(this.getBackendUrl(`api/supplierInvoice/${this.id}/file/${file.name}`), "_blank")
		},
		formatSize(size){
			if(size <= 1048576)
				return Math.round(size/1024*100)/100 + 'KB'
			return Math.round(size/1048576*100)/100 + 'MB'
		},
		handleDrop(e){
			if(!this.editing) return
			let files = Array.from(e.dataTransfer.files)

			for(let file of files){
				let formData = new FormData()
				formData.append('file', file)
				this.$http.put(
					`api/supplierInvoice/file/`,
					formData,
					{ headers: { 'Content-Type': 'multipart/form-data' } }
				).then(res => {
					this.item.files.push({
						temporalId: res.data,
						name: file.name,
						size: file.size,
					})
				}).catch((err) => {
					if(file.size > 30000000){
						window.getApp.error("The file exceeds maximum allowed size. (28MB)")
						return
					}
					window.getApp.error("Error while upload file.")
				})
			}
			this.isFileDropHovered = false
		},
		openFileUpload(){
			this.$refs.fileUploader.click()
		},
		handleFileUpload(){
			if(this.$refs.fileUploader.files.length){
				let file = this.$refs.fileUploader.files[0]
				let formData = new FormData()
				formData.append('file', file)
				this.$http.put(
					'api/supplierInvoice/file/',
					formData,
					{ headers: { 'Content-Type': 'multipart/form-data' } }
				).then(res => {
					if(!this.item.files)
						this.$set(this.item, 'files', [])
					this.item.files.push({
						temporalId: res.data,
						name: file.name,
						size: file.size,
					})
				}).catch((err) => {
					console.error(err)
					if(file.size > 30000000){
						window.getApp.error("The file exceeds maximum allowed size. (28MB)")
						return
					}
					window.getApp.error("Error while upload file.")
				})
			}
		},
		deleteFile(file){
			this.$openConfirmDialog({
				title: 'Delete file',
				description: `Are you sure you want to delete <b>${file.name}</b>?`,
			})
				.then(res => {
					if(!res)
						return
					if(file.temporalId){
						this.item.files = this.item.files.filter(x => x.temporalId != file.temporalId)
					}
					else{
						this.$http.delete(`api/supplierInvoice/${this.id}/file/${file.name}`)
							.then(res => {
								file.deleted = true
								this.item.files = this.item.files.filter(x => !x.deleted)
							})
							.catch(err => {
								console.error(err)
								this.$root.error(`Error deleting file ${file.name}`)
							})
					}
				})
		},
		isPDF(filename){
			if(!filename)
				return false;
			filename = filename.split('.');
			return filename[filename.length - 1] == 'pdf';
		},
		validTotal(){
			let subtotal = this.item.subtotal || 0

			if(this.item.notRequireTax){
				this.$set(this.item, 'tax', 0)
			}
			else{
				this.$set(this.item, 'tax', subtotal * .16)
			}

			this.$set(this.item, 'total', subtotal + this.item.tax)
		},
	},
}
</script>

<style lang="scss" scoped>
::v-deep .v-input--is-readonly{
	.v-input__slot{
		background: var(--v-coloredBack-base);
	}
	fieldset{
		border: none;
	}
}

label{
	color: var(--v-text-lighten) !important;
	font-size: .9em;
}

.top-btn{
	position: absolute;
	top: -75px; right: 0;
	z-index: 100;
	height: 75px;
	gap: 10px
}

.drop-files{
	display: flex;
	flex-direction: column;
	gap: 10px;
	align-items: center;
	justify-content: center;
	padding: 15px;
	border: 2px dashed lightgrey;

	&.hovered{
		border-color: var(--v-primary-base);
	}

	span{
		font-size: 1.2em;
	}

	.files{
		display: flex;
		flex-wrap: wrap;
		padding: 10px;
		width: 100%;
		gap: 15px;

		.file{
			display: flex;
			flex-direction: column;
			align-items: center;
			text-align: center;
			position: relative;
			margin-bottom: 10px;
			width: 140px;
			padding: 10px;
			gap: 5px;
			background: var(--v-background-lighten3);
			border-radius: 5px;

			.remove-file{
				display: none;
				align-items: center;
				justify-content: center;
				height: 20px;
				width: 20px;
				position: absolute;
				right: -8px;
				top: -8px;
			}
			.f-info{
				margin: auto 0;
				width: 100%;
				display: -webkit-box;
				-webkit-line-clamp: 2;
				-webkit-box-orient: vertical;
				overflow: hidden;
				text-overflow: ellipsis;
			}

		}
		.file-large{
			display: grid;
			grid-template-columns: 45px auto 45px;
			padding: 10px;
			gap: 10px;
		}

		.file:hover{ .remove-file{ display: flex; } }
	}
}

::v-deep .v-list-item{
	min-height: 0 !important;
	div{
		width: 100%;
	}
}

</style>