<template>
	<blue-confirm-dialog ref="deleteDialog">
		<template #text>
			{{
				!!invoices.length ?
				'This billable item is currently linked to the next invoices:' :
				'Are you sure you want to delete this billable item?'
			}}
			<div class="mt-1" style="display: grid; grid-template-columns: 33% 33% 33%; gap: 15px">
				<div
					v-for="invoice in invoices" :key="invoice.id"
					class="elevation-2 py-1 px-2 rounded background--lighten2"
				>
					<span style="font-size: 1.2em" class="flex-space-between">
						{{invoice.code}}
						<v-chip outlined x-small :color="statusColors[invoice.status]" class="ml-1">
							{{invoice.statusDisplayName}}
						</v-chip>
					</span>
					<div>
						<span> <b>Customer</b>: {{invoice.customerName}} </span> <br>
						<span> <b>Invoice Date</b>: {{invoice.issueDate | formatDate}} </span>
					</div>
				</div>
			</div>
		</template>
	</blue-confirm-dialog>
</template>

<script>
export default {
	data: () => ({
		resolve: () => {},
		invoices: [],
		statusColors: ['', '#f57c00', 'success', 'error'],
	}),
	methods: {
		open(id){
			this.$http.get(`api/BillableItem/CanDelete/${id}`)
				.then((res) => {
					this.invoices = res.data.invoices
				})
				.catch(err => {
					console.log(err)
					this.$root.error('Cannot get billable item info')
					return new Promise(resolve => resolve(false))
				})
			this.$refs.deleteDialog
				.open({
					title: 'Delete Billable Item',
					description: 'Are you sure you want to delete this billable item?',
					width: 700, addClose: true,
				})
				.then((res) => this.resolve(res))
			
			return new Promise(resolve => this.resolve = resolve)
		},
	}
}
</script>

<style lang="scss" scoped>
::v-deep .v-stepper__content{
	padding-top: 0;
}
</style>