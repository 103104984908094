<template>
	<div class="py-4 px-10 new-back full-height">

		<div
			v-if="editing"
			class="d-flex align-center px-5 top-btn"
			:style="{background: $vuetify.theme.isDark ? 'var(--v-newDesignBackground-base)' : '#fafcfe'}"
		>
			<v-btn @click="save" color="primary">Save Payment</v-btn>
		</div>

		<div class="text-right mb-2">
			<v-menu v-if="!isNew" z-index="200">
				<template #activator="{ on }">
					<v-btn v-on="on" color="primary" outlined>
						Actions
						<v-icon right>mdi-chevron-down</v-icon>
					</v-btn>
				</template>
				<v-list>
					<v-list-item v-if="!editing" @click="editing = true" class="px-1 py-0">
						<v-list-item-content class="px-3">
							<v-list-item-title class="px-0">
								<v-icon left color="primary">mdi-pencil</v-icon> Edit payment
							</v-list-item-title>
						</v-list-item-content>
					</v-list-item>
					<v-list-item @click="$adminSurf('openHistory', payment)" class="px-1 py-0">
						<v-list-item-content class="px-3">
							<v-list-item-title class="px-0">
								<v-icon left color="secondary">mdi-clock-outline</v-icon> Version history
							</v-list-item-title>
						</v-list-item-content>
					</v-list-item>
					<v-list-item @click="cancelPayment" class="px-1 py-0">
						<v-list-item-content class="px-3">
							<v-list-item-title class="px-0">
								<v-icon left color="error">mdi-cancel</v-icon>
								Cancel payment
							</v-list-item-title>
						</v-list-item-content>
					</v-list-item>
					<watchers-menu
						@watcher-added="payment.subscribers.push($event)"
						@watcher-removed="payment.subscribers.splice(payment.subscribers.findIndex(x => x.userId == $event), 1)"
						:entity-id="payment.id"
						:watchers="payment.subscribers"
						required-permission="payments"
					>
						<template #default="{ on }">
							<v-list-item v-on="on" class="px-1 py-0">
								<v-list-item-content class="px-3">
									<v-list-item-title class="px-0">
										<v-icon left>mdi-eye</v-icon>
										Watchers
									</v-list-item-title>
								</v-list-item-content>
							</v-list-item>
						</template>
					</watchers-menu>
				</v-list>
			</v-menu>
		</div>
		
		<v-form ref="form">
			<div style="max-width: 1200px; margin: 0 auto">
				<div class="bordered rounded-lg pa-5 pb-10">
					<h4>Payment general detail</h4>
					<div class="customer-account-container mt-5">
						<v-menu :disabled="!editing" offset-y>
							<template #activator="{ on, attrs }">
								<div
									v-on="on" v-bind="attrs"
									class="customer-account rounded-lg pa-5"
									:class="{ 'background--colored': !editing }"
									:style="{border: (!payment.customerId && sended) ? '2px solid var(--v-error-base)' : ''}"
								>
									<v-avatar size="55" class="cursor-pointer" style="background: gray">
										<v-img
											v-if="payment.customerId"
											class="cursor-pointer" contain
											:src="getBackendUrl(`/api/customer/image/${payment.customerId}`)"
										/>
										<v-icon v-else size="30" color="white">mdi-account-tie</v-icon>
									</v-avatar>
									<div>
										<label class="body-1">Customer/Organization</label><br>
										<b>{{ payment.customerName || 'Select customer' }}</b>
									</div>
									<div v-if="editing" class="text-right">
										<v-icon>mdi-chevron-down</v-icon>
									</div>
								</div>
							</template>
							<v-card height="400">
								<v-card-text class="background">
									<v-list>
										<v-list-item
											v-for="customer in customers.filter(x => x.id != payment.customerId)" :key="customer.id"
											@click="setCustomer(customer)"
										>
											<v-list-item-content>
												<div>
													<v-avatar size="30" class="mr-2" style="background: gray">
														<v-img
															class="cursor-pointer" contain
															:src="getBackendUrl(`/api/customer/image/${customer.id}`)"
														/>
													</v-avatar>
													{{ customer.name }}
												</div>
											</v-list-item-content>
										</v-list-item>
									</v-list>
								</v-card-text>
							</v-card>
						</v-menu>
						<v-icon color="lightgray" x-large>mdi-dots-horizontal</v-icon>
						<v-menu :disabled="!editing" offset-y>
							<template #activator="{ on, attrs }">
								<div
									v-on="on" v-bind="attrs"
									class="customer-account rounded-lg pa-5"
									:class="{ 'background--colored': !editing }"
									:style="{border: (!payment.accountId && sended) ? '2px solid var(--v-error-base)' : ''}"
								>
									<div class="pr-3">
										<v-img :src="require('../../assets/card-icon.svg')"/>
									</div>
									<div v-if="payment.accountId">
										<label>{{ payment.accountName }}</label><br>
										<b>{{ payment.accountAccountNumber }}</b>
									</div>
									<div v-else>
										<label>Bank Account</label><br>
										<b>Select account</b>
									</div>
									<div v-if="payment.accountId" class="d-flex align-center justify-end">
										<v-avatar color="indigo" size="25" class="mr-1">
											<v-img v-if="payment.accountCurrency" :src="require(`../../assets/currencies/${payment.accountCurrency.toLowerCase()}.png`)"/>
										</v-avatar>
										{{ payment.accountCurrency }}
									</div>
									<div v-else class="d-flex align-center justify-end">
										<v-icon>mdi-chevron-down</v-icon>
									</div>
								</div>
							</template>
							<v-list>
								<v-list-item
									v-for="account in accounts" :key="account.id"
									@click="setAccount(account)"
								>
									<v-list-item-content>
										<div class="d-flex align-center justify-space-between">
											<div>
												<label>{{ account.name }}</label><br>
												<b>{{ account.accountNumber }}</b>
											</div>
											<div>
												<v-avatar color="indigo" size="25" class="mr-1">
													<v-img v-if="account.currency" :src="require(`../../assets/currencies/${account.currency.toLowerCase()}.png`)"/>
												</v-avatar>
												{{ account.currency }}
											</div>
										</div>
									</v-list-item-content>
								</v-list-item>
							</v-list>
						</v-menu>
					</div>
		
					<v-row class="mt-5 input-row">
						<v-col :cols="3">
							<label class="body-2">Reference*</label>
							<v-text-field
								v-model="payment.reference" :rules="rules"
								outlined dense hide-details :readonly="!editing"
							/>
						</v-col>
						<v-col :cols="3">
							<label class="body-2">Date*</label>
							<q-date-picker
								v-model="payment.date" :rules="rules"
								outlined dense hide-details inner :readonly="!editing"
								label=""
							/>
						</v-col>
						<v-col :cols="6">
							<label class="body-2">Amount*</label>
							<input-mask
								v-model="payment.amount" :rules="rules"
								@change="setAmount"
								outlined dense hide-details :readonly="!editing"
								:mask="currencyFormat" type="number"
							/>
						</v-col>
						<v-col :cols="6">
							<label class="body-2">Concept</label>
							<v-text-field
								v-model="payment.concept"
								outlined dense hide-details :readonly="!editing"
							/>
						</v-col>
						<v-col v-if="payment.accountId && exchangeRateCurrency" :cols="6">
							<label class="body-2">Exchange rate</label>
							<v-row dense style="margin-top: -10px">
								<v-col>
									<input-mask
										v-model="payment.exchangeRateDestination"
										@change="handleExchangeRate($event, 'destination')"
										outlined dense hide-details :rules="rules" :readonly="!editing"
										type="number" :mask="x => currencyFormat(x, true)"
									>
										<template #append>
											<div class="d-flex align-center justify-center" style="margin-top: 2px">
												<v-avatar color="indigo" size="20" class="mr-1">
													<v-img v-if="exchangeRateCurrency" :src="require(`../../assets/currencies/${exchangeRateCurrency.toLowerCase()}.png`)" />
												</v-avatar>
												{{ exchangeRateCurrency }}
											</div>
										</template>
									</input-mask>
								</v-col>
								<v-col>
									<input-mask
										v-model="payment.exchangeRateOrigin"
										@change="handleExchangeRate($event, 'origin')"
										outlined dense hide-details :rules="rules" :readonly="!editing"
										type="number" :mask="x => currencyFormat(x, true)"
									>
										<template #append>
											<div class="d-flex align-center justify-center" style="margin-top: 2px">
												<v-avatar color="indigo" size="20" class="mr-1">
													<v-img v-if="payment.accountCurrency" :src="require(`../../assets/currencies/${payment.accountCurrency.toLowerCase()}.png`)" />
												</v-avatar>
												{{ payment.accountCurrency }}
											</div>
										</template>
									</input-mask>
								</v-col>
							</v-row>
						</v-col>
					</v-row>
				</div>
	
				<v-divider class="my-8"/>
	
				<div class="bordered rounded-lg pa-5 pb-1">
					<div class="d-flex justify-space-between">
						<h4 class="mb-5">Amount distribution</h4>
						<div style="width: 300px">
							<v-progress-linear
								height="10" class="rounded"
								:color="invoicesAmount <= payment.amount ? 'primary' : 'error'"
								:value="invoicesAmount/payment.amount*100"
							/>
							<div class="d-flex justify-space-between body-2 mt-1">
								<label>{{ invoicesAmount }}/{{ payment.amount || 0 }}</label>
								<b>Pending: {{ currencyFormat(payment.amount - invoicesAmount) }}</b>
							</div>
						</div>
					</div>
					
					<div class="mx-n5">
						<invoices-table
							:items="payment.invoices.filter(x => !x.deleted)" :complete="false" payment-view
							class="new-back invoices-table"
							:item-class="() => hasInvoicePermission ? 'cursor-pointer' : ''"
							hide-default-footer disable-pagination disable-sort
							@click:row="row => hasInvoicePermission && $q.openNewTab(`/admin/invoice/${row.invoiceId}`)"
						>
							<template #[`item.amount`]="{ item }">
								<div @click.stop class="d-flex align-center py-1">
									<v-row style="width: 350px">
										<v-col>
											<label>Amount {{ exchangeRateCurrency ? '(Payment)' : '' }}</label>
											<input-mask
												outlined dense
												v-model="item.amount"
												type="number" :mask="currencyFormat"
												:rules="rules" :readonly="!editing"
												@change="v => { updatePaymentAmount(); $set(item, 'amount', trunc(v, 0, Math.round(item.maxAmount / exchangeRateFactor * 100) / 100)) }"
												:max="item.maxAmount / exchangeRateFactor"
												:messages="'Max: ' + currencyFormat(getMaxAmount(item))"
											>
												<template #append>
													<label class="mt-1">{{ payment.accountCurrency }}</label>
												</template>
											</input-mask>
										</v-col>
										<v-col v-if="payment.accountId && item.invoiceCurrency != payment.accountCurrency">
											<label>Amount (Invoice)</label>
											<input-mask
												outlined dense :readonly="!editing"
												:value="item.amount * exchangeRateFactor"
												type="number" :mask="currencyFormat" disabled
												:messages="'Max: ' + currencyFormat(item.maxAmount)"
											>
												<template #append>
													<label class="mt-1">{{ item.invoiceCurrency }}</label>
												</template>
											</input-mask>
										</v-col>
									</v-row>
									<v-btn v-if="editing" @click="$set(item, 'deleted', true)" icon>
										<v-icon>mdi-minus-circle-outline</v-icon>
									</v-btn>
								</div>
							</template>
						</invoices-table>
					</div>
					<div v-if="editing" class="distribution-line">
						<v-btn @click="openRelateInvoicesDialog" depressed small :disabled="!payment.customerId">
							<v-icon>mdi-plus</v-icon>
							Add New
						</v-btn>
					</div>
				</div>

				<div
					class="text-right mt-5"
				>
					<v-btn v-if="!editing && !payment.balanceInFavor && payment.amount - invoicesAmount >= 0.01" @click="openBalanceInFavorDialog" color="primary">
						Set balance in favor
					</v-btn>
					<div v-if="payment.balanceInFavor" class="d-inline-block">
						<div class="d-flex align-center justify-space-between">
							<h3>Balance in Favor</h3>
							<v-btn @click="removeBalanceInFavor" icon color="error">
								<v-icon>delete</v-icon>
							</v-btn>
						</div>
						<div class="pt-3">
							<div>
								<v-text-field
									:value="currencyFormat(payment.balanceInFavor)"
									outlined readonly dense
									class="d-inline-block input-right"
								/>
							</div>
						</div>
					</div>
				</div>
			</div>
		</v-form>

		<relate-invoices-dialog ref="invoicesDialog"/>
		<payment-balance-in-favor-dialog ref="balanceInFavorDialog"/>
	</div>
</template>

<script>
import RelateInvoicesDialog from '../../dialogs/adminSurf/RelateInvoicesDialog'
import InvoicesTable from '../../components/InvoicesTable'
import WatchersMenu from '../../components/admin-surf/WatchersMenu.vue'
import PaymentBalanceInFavorDialog from '../../dialogs/adminSurf/PaymentBalanceInFavorDialog'

export default {
	props: {
		id: { type: String },
		customerId: { type: String },
		invoiceId: { type: String },
		accountId: { type: String },
	},
	components: { RelateInvoicesDialog, InvoicesTable, WatchersMenu, PaymentBalanceInFavorDialog },
	data: () => ({
		payment: {
			invoices: [],
			exchangeRateOrigin: 1,
			exchangeRateDestination: 1,
		},
		customers: [],
		invoices: [],
		accounts: [],
		comment: '',
		rules: [v => !!v || 'Required'],
		sended: false,
		editing: false,
		amountSet: false,

		hasInvoicePermission: false,
	}),
	mounted(){
		this.$security.hasRequiredPermission('detail', 'adminSurfInvoices')
			.then(res => this.hasInvoicePermission = res)
		this.init()
	},
	computed: {
		isNew(){ return this.id == 'new' },
		invoicesAmount(){
			const amount = this.payment.invoices
				.filter(x => !x.deleted)
				.reduce((prev, x) => prev + (Number(x.amount || 0) || 0), 0)
			return Math.round(amount * 100) / 100 + (this.payment.balanceInFavor || 0)
		},
		exchangeRateCurrency(){
			let diffs = this.payment.invoices
				.filter(x => !x.deleted && x.invoiceCurrency != this.payment.accountCurrency)
			if(!diffs.length) return null
			return diffs[0].invoiceCurrency
		},
		exchangeRateFactor(){
			if(!this.exchangeRateCurrency){
				this.payment.exchangeRateDestination = 1
				this.payment.exchangeRateOrigin = 1
			}
			return this.payment.exchangeRateDestination / this.payment.exchangeRateOrigin
		},
	},
	methods: {
		init() {
			if(!this.isNew){
			this.amountSet = true
			this.$q.changeHeaderTitle('Edit Payment')
			this.$http.get(`api/payment/${this.id}`)
				.then(res => {
					this.payment = res.data
					this.payment.exchangeRateOrigin = this.payment.exchangeRateOrigin || 1
					this.payment.exchangeRateDestination = this.payment.exchangeRateDestination || 1
				})
		}
		else{
			this.editing = true
			this.$q.changeHeaderTitle('New Payment')
		}
		let customerPr = this.$http.get('api/customer')
			.then(res => {
				this.customers = res.data
			})
		let invoicePr = this.$http.post('api/invoice/list', { statuses: [1, 4] })
			.then(res => {
				this.invoices = res.data.invoices
			})
		let accountPr = this.$http.get('api/bankAccount')
			.then(res => {
				this.accounts = res.data
				this.accounts.forEach(x => {
					x.accountNumber = '**** **** **** ' +
						x.accountNumber.substr(x.accountNumber.length-4)
				})
				if(this.accountId){
					const account = this.accounts.find(x => x.id == this.accountId)
					this.setAccount(account)
				}
			})
		
		Promise.all([customerPr, invoicePr, accountPr])
			.then(() => {
				if(this.isNew){
					if(this.invoiceId){
						const invoice = this.invoices.find(x => x.id == this.invoiceId)
						this.addInvoice(invoice)
					}
					if(this.customerId){
						const customer = this.customers.find(x => x.id == this.customerId)
						this.setCustomer(customer)
					}
				}

				this.customers = this.customers
					.filter(c => this.invoices.some(i => i.customerId == c.id))
			})
		},
		save(){
			this.payment.invoices = this.payment.invoices
				.filter(i => !i.deleted && i .invoiceId)

			if(!this.$refs.form.validate() || !this.payment.customerId || !this.payment.accountId){
				this.sended = true
				return
			}

			if(this.payment.amount < this.invoicesAmount){
				this.$root.error('The total amount of the billable items is invalid')
				return
			}

			this.payment.exchangeRateCurrency = this.exchangeRateCurrency
			this.payment.invoices
				.forEach(x => {
					if(x.invoiceCurrency != this.payment.accountCurrency)
						x.exchangeRateAmount = x.amount * this.exchangeRateFactor
					else
						x.exchangeRateAmount = x.amount
				})

			this.$q
				.save({
					api: `/api/payment`,
					data: {comment: this.comment, payment: this.payment},
					successMsg: 'Payment saved successfully',
					errorMsg: "Cannot save changes",
				})
					.then(res => {
						if(this.isNew){
							this.payment = Object.assign({}, res.data)
							this.$router.push({path: res.data.id, query: { redirect: '/admin/invoice' }})
							this.$router.push(`/admin/payment/${res.data.id}`)
						}
						this.editing = false
						this.init()
					})
		},
		async cancelPayment(){
			let res = await this.$openConfirmDialog({
					title: 'Confirm cancellation',
					description: 'Are you sure you want to cancel this payment?',
					confirmBtnColor: 'error',
				})
			if(!res) return

			this.$http.delete(`/api/payment/${this.id}`)
				.then(() => {
					this.$root.success('Payment canceled')
				})
				.catch(err => {
					console.error(err)
					this.$root.error('Cannot cancel payment')
				})
		},
		addInvoice(invoice){
			this.payment.invoices
				.push({
					invoiceId: invoice.id,
					invoiceCode: invoice.code,
					invoiceIssueDate: invoice.issueDate,
					invoiceCurrency: invoice.currency,
					invoiceTotal: invoice.total,
					invoicePaidAmount: invoice.paidAmount,
					invoicePendingAmount: invoice.pendingAmount,
					invoiceStatus: invoice.status,
					invoiceStatusColor: invoice.statusColor,
					invoiceStatusLightColor: invoice.statusLightColor,
					invoiceStatusName: invoice.statusName,
					invoiceProjects: invoice.projects,
					maxAmount: invoice.pendingAmount,
				})
		},
		setCustomer(customer){
			this.$set(this.payment, 'customerId', customer.id)
			this.$set(this.payment, 'customerName', customer.name)
		},
		setAccount(account){
			this.$set(this.payment, 'accountId', account.id)
			this.$set(this.payment, 'accountName', account.name)
			this.$set(this.payment, 'accountAccountNumber', account.accountNumber)
			this.$set(this.payment, 'accountCurrency', account.currency)
		},
		openRelateInvoicesDialog(){
			this.$refs.invoicesDialog
				.open(
					this.invoices.filter(x =>
						x.status != 2 &&
						x.customerId == this.payment.customerId &&
						!this.payment.invoices
							.some(relation => !relation.deleted && relation.invoiceId == x.id)
					)
				)
				.then(invoices => {
					invoices.forEach(this.addInvoice)
				})
		},
		openBalanceInFavorDialog() {
			this.$refs.balanceInFavorDialog.open(this.payment.id)
				.then(this.init)
		},
		removeBalanceInFavor() {
			this.$openConfirmDialog({
				title: 'Remove balance in favor',
				description: 'Are you sure you want to remove the balance in favor?',
				confirmBtnColor: 'error',
			})
				.then(res => {
					if(!res) return
					this.$http.delete(`/api/payment/${this.payment.id}/balanceInFavor`)
						.then(() => {
							this.$root.success('Balance in favor removed')
							this.init()
						})
						.catch(err => {
							console.error(err)
							this.$root.error('Cannot remove balance in favor')
						})
				})
		},
		handleExchangeRate(newValue, property){
			if(newValue == 1) return
			if(property == 'origin'){
				this.payment.exchangeRateDestination = 1
				if(newValue == 0) this.payment.exchangeRateOrigin = 1
			}
			else{
				this.payment.exchangeRateOrigin = 1
				if(newValue == 0) this.payment.exchangeRateDestination = 1
			}
		},
		getMaxAmount(invoice){
			if(this.payment.accountId && invoice.invoiceCurrency != this.payment.accountCurrency){
				if(!this.exchangeRateFactor) return 0
				return invoice.maxAmount / this.exchangeRateFactor
			}
			return invoice.maxAmount
		},
		updatePaymentAmount(){
			if(!this.amountSet){
				const amount = this.payment.invoices
					.reduce((partial, cur) => partial + cur.amount, 0)
				this.$set(this.payment, 'amount', amount)
			}
		},
		setAmount(){
			this.amountSet = true
		},
	},
}
</script>

<style lang="scss" scoped>
::v-deep .v-input--is-readonly{
	.v-input__slot{
		background: var(--v-coloredBack-base);
	}
	fieldset{
		border: none;
	}
}
::v-deep .invoices-table{
	table{
		border-top: 1px solid var(--v-lightgray-lighten);
	}
	table > tbody > tr > td:last-child,
  table > thead > tr > th:last-child {
    position: sticky !important;
    right: 0; 
    z-index: 0;
    background: var(--v-newDesignBackground-base);
		border-left: 1px solid var(--v-lightgray-lighten);
  }
  table > thead > tr > th:last-child {
    z-index: 1;
  }
}

label{
	color: var(--v-text-lighten) !important;
}
.top-btn{
	position: absolute;
	top: -75px; right: 0;
	z-index: 100;
	height: 75px;
	gap: 10px
}

.customer-account-container{
	display: grid;
	grid-template-columns: 1fr 60px 1fr;
	align-items: center;

	.customer-account{
		display: grid;
		grid-template-columns: 80px auto 100px;
		height: 100%;
		align-items: center;
		background: var(--v-background-lighten2);
	}
}

.input-row .col{
	padding-top: 10px;
	padding-bottom: 0;
}

.distribution-line{
	margin: 0 -20px;
	border-top: 1px solid var(--v-lightgray-lighten);
	padding: 15px 20px;
	align-items: center;
}
</style>