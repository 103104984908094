<template>
  <v-app :class="{ fullscreen: fullscreen }">
    <app-menu v-if="!login()" ref="appMenu"/>

    <v-main>
      <div class="spinner-wrap" v-if="loading">
        <LoadingCircle/>
      </div>
      <router-view
        :key="viewKey"
        @addSpinner="this.addSpinner"
        @removeSpinner="this.removeSpinner"
        :socket="socket"
      ></router-view>
      <v-snackbar v-model="snackbar" :color="snackbarColor" :bottom="true" :right="true" :timeout="5000">
        {{ snackText }}
        <v-btn text @click="snackbar = false">
          Close
        </v-btn>
      </v-snackbar>
    </v-main>
    <reminder-daily-report :dialog.sync="rememberDialog" :blocked="pastDueReports >= 3"/>
    <blue-confirm-dialog ref="confirmDialog"/>
    <admin-surf-controller ref="adminSurfController"/>

    <v-menu :value="!!userCard.id" :position-x="userCard.x" :position-y="userCard.y">
      <profile-card v-if="userCard.id" small :userId="userCard.id" hideLoading></profile-card>
    </v-menu>
  </v-app>
</template>

<script>
import Vue from "vue"

import AdminSurfController from "../controllers/AdminSurfController"
import ReminderDailyReport from "../dialogs/ReminderDailyReport"
import AppMenu from "./AppMenu"
import AppToolbar from "./AppToolbar"
import CategoryChip from "./CategoryChip"
import UserChip from "./UserChip"
import PinButton from "./PinButton"
import ToggleView from "./ToggleView"
import CheckSwitch from "./CheckSwitch"
import DataTable from "./DataTable"
import InfiniteDataTable from "./InfiniteDataTable"
import FilterSearchInput from "./FilterSearchInput"
import QDatePicker from "./QDatePicker"
import QDateRangePicker from "./QDateRangePicker"
import QTextField from "./QTextField"
import QAutocomplete from "./QAutocomplete"
import QUserAutocomplete from "./QUserAutocomplete"
import QEmojiPicker from "./QEmojiPicker"
import QRichEditor from "./QRichEditor"
import QFilterInput from "./QFilterInput"
import QColorPicker from "./QColorPicker"
import AutoWidthInput from "./AutoWidthInput"
import RichEditor from "./RichEditor"
import InputMask from "./InputMask"
import TaskChooser from "./TaskChooser"
import WorkItemChange from "./WorkItemChange"
import UserAvatar from "./UserAvatar"
import LoadingCircle from "./LoadingCircle.vue"
import SingleClickAutocomplete from "./SingleClickAutocomplete.vue"
import SidePanel from "./SidePanel.vue"
import ConfirmDialog from "./ConfirmDialog.vue"
import ColorPicker from "./ColorPicker.vue"
import ProfileCard from './panels/ProfileCard.vue'
import HtmlToPdf from "../dialogs/HtmlToPdf.vue"

import "vue-swatches/dist/vue-swatches.min.css"

Vue.component("AppMenu", AppMenu)
Vue.component("AppToolbar", AppToolbar)
Vue.component("CategoryChip", CategoryChip)
Vue.component("UserChip", UserChip)
Vue.component("PinButton", PinButton)
Vue.component("ToggleView", ToggleView)
Vue.component("CheckSwitch", CheckSwitch)
Vue.component("DataTable", DataTable)
Vue.component("InfiniteDataTable", InfiniteDataTable)
Vue.component("FilterSearchInput", FilterSearchInput)
Vue.component("QDatePicker", QDatePicker)
Vue.component("QDateRangePicker", QDateRangePicker)
Vue.component("QTextField", QTextField)
Vue.component("QAutocomplete", QAutocomplete)
Vue.component("QUserAutocomplete", QUserAutocomplete)
Vue.component("QEmojiPicker", QEmojiPicker)
Vue.component("QRichEditor", QRichEditor)
Vue.component("QFilterInput", QFilterInput)
Vue.component("QColorPicker", QColorPicker)
Vue.component("AutoWidthInput", AutoWidthInput)
Vue.component("RichEditor", RichEditor)
Vue.component("InputMask", InputMask)
Vue.component("TaskChooser", TaskChooser)
Vue.component("WorkItemChange", WorkItemChange)
Vue.component("UserAvatar", UserAvatar)
Vue.component("LoadingCircle", LoadingCircle)
Vue.component("SingleClickAutocomplete", SingleClickAutocomplete)
Vue.component("SidePanel", SidePanel)
Vue.component("BlueConfirmDialog", ConfirmDialog)
Vue.component("ColorPicker", ColorPicker)
Vue.component("HtmlToPdf", HtmlToPdf)

var spinnerCount = 0
export default {
  components: {
    ReminderDailyReport,
    AdminSurfController,
    ProfileCard,
  },
  data() {
    return {
      rememberDialog: null,
      loading: false,
      snackbar: false,
      fullscreen: false,
      snackText: "",
      snackbarColor: "info",
      ignoreUrls: [
        "/api/user/current/unreadNotification",
        '/moveOnBoard/',
        'api/Effort/Current/DueReports',
      ],
      totalViewableTabs: [
        'DailyReportList',
        'DailyReportEdit',
        'ProfilePage',
        'Login',
      ],

      socket: null,
      reconnectionInterval: null,
      viewKey: 0,
      pastDueReports: 0,
      dueReportsTimeout: null,
      securityInfo: null,
      userCard: {
        id: null,
        x: null,
        y: null,
      },
    };
  },
  watch: {
    $route(current, prev){
      if(current.path != prev.path)
        this.getDueReports()
    }
  },
  computed: {
    
  },
  created() {
    this.signalRConnection()
    this.$blueSystem.getCurrentUser() 
      .then(user => {
        if(!this.login()) {
          this.$blueSystem.checkConfetti()
        }
      })
    this.$on("APP_TOGGLE_MAIN_FULLSCREEN", () => {
      this.fullscreen = !this.fullscreen
    })

    window.getApp = this

    // Spinner
    this.$on('before-request', this.addSpinner);
    this.$on('request-error',  this.removeSpinner);
    this.$on('after-response', this.removeSpinner);
    this.$on('response-error', this.removeSpinner)
  },
  beforeDestroy() {
    this.$off('before-request', this.addSpinner);
    this.$off('request-error',  this.removeSpinner);
    this.$off('after-response', this.removeSpinner);
    this.$off('response-error', this.removeSpinner);
  },
  methods: {
    getDueReports(){
      if(this.dueReportsTimeout)
        clearTimeout(this.dueReportsTimeout)

      this.dueReportsTimeout = setTimeout(() => {
        this.$http.get('api/Effort/Current/DueReports', {headers: {hideLoading: true}})
          .then(res => {
            window.getApp.dueReports = res.data
          })
          .catch(err => {
            window.getApp.dueReports = 0
          })
          .finally(() => {
            let newDueReports = window.getApp.dueReports

            if(newDueReports > this.pastDueReports && !this.login())
              this.rememberDialog = true

            if(this.pastDueReports != newDueReports) {
              this.updateMenu()
            }
            this.pastDueReports = newDueReports
            
            if(this.totalViewableTabs.includes(this.$route.name))
              return

            if(newDueReports >= 3) {
              this.$router.push(`/forbidden`)
            }
          })
      }, 100)
    },
    signalRConnection(){
      try{
        let backend = this.$http.defaults.baseURL;
  
        const signalR = require("@microsoft/signalr");
        if(!backend.endsWith("/"))
          backend += "/";
  
        this.socket = new signalR.HubConnectionBuilder()
          .withUrl(`${backend}api/hub/projectHub`)
          .withAutomaticReconnect({
            nextRetryDelayInMilliseconds: retryContext => {
              return Math.random() * 10000;
            }
          })
          .build();
  
        this.socket.start()
      } catch(e){
        console.log(e)
      }
    },
    login: function() {
      return this.$router.history.current.path === '/login' || this.$router.history.current.path === '/resetPassword' || this.$router.history.current.path === '/setPassword';
    },
    
    showUserDialog(id, event){
      this.userCard.id = id
      this.userCard.x = event.clientX
      this.userCard.y = event.clientY
    },
    hideUserDialog(){
      this.userCard.id = null
    },
    
    addSpinner(conf) {
      if (conf && conf.url && this.ignoreUrls.some(url => conf.url.includes(url))) {
        return
      }
      if(conf && conf.headers && conf.headers.hideLoading){
        return
      }
      spinnerCount++
      this.loading = true
    },
    removeSpinner(conf) {
      if (conf && conf.config && conf.config.url && this.ignoreUrls.some(url => conf.config.url.includes(url))) {
        return
      }
      if(conf && conf.config && conf.config.headers && conf.config.headers.hideLoading){
        return
      }
      spinnerCount--
      if (spinnerCount <= 0) {
        this.loading = false
        spinnerCount = 0;
      }
    },
    success(message) {
      this.snackbar = true
      this.snackText = message
      this.snackbarColor = "info"
      console.log(`success: ${message}`)
      
    },
    error(message) {
      this.snackbar = true
      this.snackText = message
      this.snackbarColor = "error"
      console.log(`error: ${message}`)
    },
    updateAll(){
      this.updateMenu()
      this.viewKey++
    },
    updateMenu(){
      if(this.$refs.appMenu)
        this.$refs.appMenu.createMenu()
    },
  }
};
</script>

<style scoped lang="scss">
  @font-face {
    font-family: "Roboto-Condensed";
    src: url("../assets/fonts/RobotoCondensed.ttf");
  }
  @font-face {
    font-family: "Roboto";
    src: url("../assets/fonts/Roboto.ttf");
  }


  .app-footer {
    z-index: 7;
    min-height: 24px;  
    padding: 0 10px;
    font-size: .8em;
  }
  .spinner-wrap {
    background: #00000044;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    position: fixed;
    z-index: 1000;
  }
  .v-progress-circular {
    position: fixed;
    z-index: 1000;
    top: 50%;
    left: 50%;
    margin: -16px 0 0 -16px;
  }
  .fade-enter-active, .fade-leave-active {
    transition: opacity .5s;
  }
  .fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
    opacity: 0;
  }
  
  .fullscreen .v-main {
    padding: 0 !important;
    z-index: 150;
    background: white;
  }
</style>