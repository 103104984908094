<template>
	<div class="pa-3 new-back full-height">
		<v-row>
			<v-col :cols="3" style="min-width: 385px">
				<div class="accounts">
					<div
						v-for="account in accounts" :key="account.id"
						@click="selectAccount(account)" class="account cursor-pointer"
						:class="{selected: selectedAccounts.includes(account.id)}"
					>
						<svg width="95" height="69" viewBox="0 0 112 81" fill="none" xmlns="http://www.w3.org/2000/svg">
							<path d="M103.25 0H8.75C3.92547 0 0 3.92547 0 8.75V71.75C0 76.5745 3.92547 80.5 8.75 80.5H103.25C108.075 80.5 112 76.5745 112 71.75V8.75C112 3.92547 108.075 0 103.25 0Z" fill="#155086"/>
							<path d="M31.5 29H16.5C15.1192 29 14 27.8808 14 26.5V16.5C14 15.1192 15.1192 14 16.5 14H31.5C32.8808 14 34 15.1192 34 16.5V26.5C34 27.8808 32.8808 29 31.5 29Z" fill="#FFD100"/>
							<path d="M26.25 57.75H17.5C15.5669 57.75 14 56.1831 14 54.25V52.5C14 50.5669 15.5669 49 17.5 49H26.25C28.1831 49 29.75 50.5669 29.75 52.5V54.25C29.75 56.1831 28.1831 57.75 26.25 57.75Z" fill="#44739E"/>
							<path d="M49 57.75H40.25C38.3169 57.75 36.75 56.1831 36.75 54.25V52.5C36.75 50.5669 38.3169 49 40.25 49H49C50.9331 49 52.5 50.5669 52.5 52.5V54.25C52.5 56.1831 50.9331 57.75 49 57.75Z" fill="#44739E"/>
							<path d="M71.75 57.75H63C61.0669 57.75 59.5 56.1831 59.5 54.25V52.5C59.5 50.5669 61.0669 49 63 49H71.75C73.6831 49 75.25 50.5669 75.25 52.5V54.25C75.25 56.1831 73.6831 57.75 71.75 57.75Z" fill="#44739E"/>
							<path d="M94.5 57.75H85.75C83.8169 57.75 82.25 56.1831 82.25 54.25V52.5C82.25 50.5669 83.8169 49 85.75 49H94.5C96.4331 49 98 50.5669 98 52.5V54.25C98 56.1831 96.4331 57.75 94.5 57.75Z" fill="#44739E"/>
							<path d="M50.75 68.25H15.75C14.7827 68.25 14 67.4664 14 66.5C14 65.5336 14.7827 64.75 15.75 64.75H50.75C51.7173 64.75 52.5 65.5336 52.5 66.5C52.5 67.4664 51.7173 68.25 50.75 68.25Z" fill="#44739E"/>
							<path d="M73.5 68.25H57.75C56.7827 68.25 56 67.4664 56 66.5C56 65.5336 56.7827 64.75 57.75 64.75H73.5C74.4673 64.75 75.25 65.5336 75.25 66.5C75.25 67.4664 74.4673 68.25 73.5 68.25Z" fill="#44739E"/>
							<path d="M33.9998 22.25H24V20.75H33.9998V19.25H24V14H22.5V29H24V23.75H33.9998V22.25Z" fill="#FF9500"/>
						</svg>

						<div class="currency">
							<v-img width="28" height="28" contain :src="require(`../../assets/currencies/${account.currency.toLowerCase()}.png`)" />
							{{account.currency}}
						</div>
						<div class="d-flex flex-column justify-center">
							<h3>{{account.name}}</h3>
							<div>{{account.bankName}}</div>
							<div class="d-flex justify-space-between">
								<span>
									**** **** **** {{account.accountNumber.substr(account.accountNumber.length - 4, 4)}}
								</span>
								<h3>{{ getCardTypeText(account.cardType) }}</h3>
							</div>
						</div>
						<v-icon :color="selectedAccounts.includes(account.id) ? 'primary' : ''" large>mdi-chevron-right</v-icon>
					</div>
					<v-btn v-if="permissions.detail" to="/admin/manageAccounts" text>
						<v-icon color="primary" left>mdi-cog</v-icon>
						Manage accounts
					</v-btn>
				</div>
			</v-col>
			<v-col>
				<payment-table
					@updated="fetchPayments"
					:payments="shownPayments" :accounts="accounts"
					:selectedAccounts="selectedAccounts"
					:customerId="customerId" :applied="applied"
				/>
			</v-col>
		</v-row>

	</div>
</template>

<script>
import PaymentTable from "../../components/PaymentTable"

export default {
	components: { PaymentTable },
	props: ['customerId', 'applied'],
	data: () => ({
		payments: [],
		accounts: [],
		selectedAccounts: [],
    cardTypes: [
			{ value: 0, text: 'CC' },
			{ value: 1, text: 'DC' },
		],
		permissions: {
			detail: false,
			add: false,
			actions: false,
		},
	}),
	mounted(){
		document.title = 'Payments'

		this.$security.hasRequiredPermission('detail')
			.then(res => this.permissions.detail = res)
		this.$security.hasRequiredPermission('add')
			.then(res => this.permissions.add = res)
		this.$security.hasRequiredPermission('full_access')
			.then(res => this.permissions.actions = res)

		this.setUserPreferences("Payments", "selectedAccounts")

    this.$q.log(4, 27, 3, 'NA')
		this.init()
	},
	computed: {
		shownPayments(){
			return this.payments.filter(p => !this.selectedAccounts?.length || this.selectedAccounts.includes(p.accountId))
		},
	},
	methods: {
		init(){
			this.fetchPayments()
			this.$http.get('api/bankAccount')
				.then(res => {
					this.accounts = res.data
				})
		},
		fetchPayments(){
			this.$http.get('api/payment')
				.then(res => {
					this.payments = res.data
				})
		},
		selectAccount(account){
			if(this.selectedAccounts.includes(account.id))
				this.selectedAccounts = this.selectedAccounts.filter(a => a != account.id)
			else
				this.selectedAccounts.push(account.id)
		},
    getCardTypeText(cardTypeValue) {
      const cardType = this.cardTypes.find((type) => type.value === cardTypeValue);
      return cardType?.text || '';
    },
	},
}
</script>

<style lang="scss" scoped>
.account{
	position: relative;
	display: grid;
	grid-template-columns: 95px auto 30px;
	gap: 20px;
	border: 1px solid transparent;
	border-radius: 10px;
	margin-bottom: 15px;
	padding: 14px 15px;
	padding-right: 0;

	&.selected{
		border-color: var(--v-primary-base);
	}

	.currency{
		position: absolute;
		right: 10px;
		top: 5px;
		display: flex;
		align-items: center;
		gap: 3px;
	}
}
</style>