<template>
    <v-container class="px-1">
    <div style="display: flex; justify-content: space-between" class="mb-2">
      <h3>
        <v-icon @click="$emit('toggleView')">chevron_left</v-icon>
        {{ project.name ? setMaxText(project.name, 23)  : 'Add a Task'}}
      </h3>
      <v-icon @click="$emit('close')">mdi-close</v-icon>
    </div>
    <v-form v-model="form" ref="form">
      <v-row dense justify="center">
        <v-col cols="6" class="col-login">
          <span>Treatment</span>
          <v-select
            outlined
            dense
            :items="riskStatus"
            item-text="name"
            class="rounded-lg"
            v-model="risk.status"
            :rules="[v => (!!v || v === 0) || 'Required']"
          />
        </v-col>
        <v-col cols="6" class="col-login">
          <span>Risk Type</span>
          <v-select
            outlined
            dense
            :items="viewStatus"
            item-text="name"
            class="rounded-lg"
            v-model="risk.internalViewOnly"
          />
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <h4>
            Description
            <v-icon
              v-on:click="infoDialog = true"
              :style="{ fontSize: '20px' }"
            >
              mdi-information-outline
            </v-icon>
          </h4>
        </v-col>
      </v-row>
      <v-row class="mb-0" justify="center">
        <v-col class="col-login">
          <span>Threat*</span>
          <v-textarea
            outlined
            :rows="2"
            no-resize
            class="rounded-lg"
            v-model="risk.description"
            hide-details
            :rules="[v => (!!v || v === 0) || 'Required']"
          />
        </v-col>
      </v-row>
      <v-row class="mb-0" justify="center">
        <v-col class="col-login">
          <span>Vulnerability</span>
          <v-textarea
            outlined
            :rows="2"
            no-resize
            class="rounded-lg"
            v-model="risk.vulnerability"
            hide-details
          />
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="6" class="col-login">
          <span>Probability</span>
          <v-select
            :items="probabilityImpactStatus"
            item-text="name"
            outlined
            dense
            class="rounded-lg"
            v-model="risk.probability"
            :rules="[v => !!v || 'Required']"
          >
            <template v-slot:item="{ item }">
              <v-img class="mr-2" style="flex: none" width="24px" height="24px" :src="require(`../assets/priority-icons/${item.value}.png`)" contain></v-img>
              {{item.name}}
            </template>
            <template v-slot:selection="{ item }">
              <v-img class="mr-1" style="flex: none" width="24px" height="24px" :src="require(`../assets/priority-icons/${item.value}.png`)" contain></v-img>
              {{ setMaxText(item.name, 4)}}
            </template>
          </v-select>
        </v-col>
        <v-col cols="6" class="col-login">
          <span>Impact</span>
          <v-select
            :items="probabilityImpactStatus"
            item-text="name"
            outlined
            dense
            class="rounded-lg"
            v-model="risk.impact"
            :rules="[v => !!v || 'Required']"
          >
            <template v-slot:item="{ item }">
              <v-img class="mr-2" style="flex: none" width="24px" height="24px" :src="require(`../assets/priority-icons/${item.value}.png`)" contain></v-img>
              {{item.name}}
            </template>
            <template v-slot:selection="{ item }">
              <v-img class="mr-1" style="flex: none" width="24px" height="24px" :src="require(`../assets/priority-icons/${item.value}.png`)" contain></v-img>
              {{ setMaxText(item.name, 4)}}
            </template>
          </v-select>
        </v-col>
      </v-row>
    </v-form>
    <h3 class="mt-8" style="color: var(--v-text-lighten2);">
      Actions
    </h3>
    <h4 @click="openCreateWorkItem()" class="cursor-pointer py-4">
      <v-icon color="primary">add</v-icon>
      Add an Action
    </h4>
    <v-container class="px-0 py-1">
      <v-col 
        v-for="(risk, ix) in workItemsToCreate"
        :key="ix"
        class="pb-0 card-container test2 mb-4"
        v-bind="$attrs"
        :cols="6" :md="4"
        :style="{
          'min-width': '285px',
          'max-width': '285px',
        }"
      >
        <v-card
          class="mainContainer"
          @click="openCreateWorkItem(risk, ix)"
        >
          <v-card-title>
            <v-avatar class="avatar" color="grey" :size="35">
              <v-img
                :src="profilePicFailover"
              ></v-img>
            </v-avatar>
          </v-card-title>
          <v-card-text>
            <div
              class="description"
            >
              {{risk.name}}
            </div>
            <div class="created-date">
              {{ formatDate(risk.dueDate) }} by {{ risk.assignedTo.blueTag }}
            </div>
            <v-menu>
              <template v-slot:activator="{ on }">
                <v-btn depressed dark :color="getColor(risk.status.color) || 'white'" class="status-button-title" v-on="on">
                  <span :style="{ color: risk.status.color || 'white' }">
                    Status: {{ risk.status.name }}
                  </span>
                  <v-icon :color="risk.status.color" right>keyboard_arrow_down</v-icon>
                </v-btn>
              </template>

            </v-menu>
          </v-card-text>
        </v-card>
      </v-col>
      <div style="height: 38px"></div>
      <v-row  dense justify="center" class="save-task-button ml-1">
        <v-col class="mt-1 py-2 " :style="{ background: 'var(--v-newDesignBackground-base)' }">
          <v-btn
            block
            large
            @click="save"
            color="primary"
          >
            <span style="color:white;">
              Create
            </span>
          </v-btn>
        </v-col>
      </v-row>
    </v-container>
    <v-dialog
      v-model="infoDialog"
      persistent
      max-width="370"
    >
      <v-card>
        <br>
        <v-card-text :style="{ fontSize: '17px' }">
          <h4>Risk Example: </h4>
            "Riesgo de retraso del proyecto, por curva de aprendizaje del Desarrollador"
            <br />
            <br />
            <b>Threat: </b>: "Riesgo de retraso del proyecto..." 
            <br />
            Potencial ocurrencia de un hecho que pueda manifestarse.
            <br/>
            <br/>
            <b>Vulnerability:  </b>
            : "...por curva de aprendizaje del Desarrollador." 
            <br/>
            Debilidad o grado de exposición de un sujeto, objeto o persona. Efecto que va a suceder en un proyecto a causa de la Amenaza.
        </v-card-text>
        <v-card-actions class="dialog-actions">
          <v-btn
            color="primary"
            class="mb-2 mr-2"
            @click="infoDialog = false"
            :style="{ width: '140px !important', }"
          >
            Got it
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <risk-work-item-dialog
      ref="riskWorkItemDialog"
      :projectId="project.id"
      :risks="[]"
      :newRiskCreation="true"
      />
  </v-container>
</template>

<script>
import RiskWorkItemDialog from '../dialogs/RiskWorkItemDialog.vue'
import RiskCard from './RiskCard';
export default {
  components: {
    RiskWorkItemDialog,
    RiskCard,
  },
  props: [
    'project'
  ],
  data: () => ({
    profilePicFailover:
				"https://cdn.vuetifyjs.com/images/parallax/material2.jpg",
    riskStatus: [
      { name: 'Active', value: 0, color: "" },
      { name: 'Eliminated', value: 1, color: "" },
      { name: 'Materialized', value: 2, color: "" },
    ],
    viewStatus: [
      { name: 'Internal', value: false },
      { name: 'External', value: true },
    ],
    probabilityImpactStatus: [
      { name: 'Very High', value: 5 },
      { name: 'High', value: 4 },
      { name: 'Medium', value: 3 },
      { name: 'Low', value: 2 },
      { name: 'Very low', value: 1 },
    ],
    form: false,
    infoDialog: false,
    risk: {
      status: 0,
      internalViewOnly: false,
      probability: 3,
      impact: 3,
    },
    workItemsToCreate: [],
    projectConfig: { workItemStatuses: [] }
  }),
  methods: {
    openCreateWorkItem(wi = {}, index){
      let alreadyCreated = !!wi.temporalId
      this.$refs.riskWorkItemDialog.open(null, null, alreadyCreated, JSON.parse(JSON.stringify(wi)))
        .then(wi => this.addNewWorkItem(wi, index))
    },
    getColor(color) {
      return _.dropRight(color, 2).join("") + '1A'
    },
    addNewWorkItem(wi, index) {
      wi.temporalId = wi.temporalId ? wi.temporalId : Date.now()
      if(!isNaN(index)) {
        this.$set(this.workItemsToCreate, index, wi)
        return
      }
      this.workItemsToCreate.push(wi)
    },
    init() {
      if(!this.projectConfig.workItemStatuses.length) {
				this.$http.get(`api/project/${projectId}/config?active=true`).then(res => {
					this.projectConfig = res.data
					this.projectConfig.workItemStatuses = this.projectConfig.workItemStatuses.filter(s => s.workItemType === 3)
				})
			}
    },
    save(){
      if(!this.form) {
        this.$refs.form.validate()
        return
      }
      let riskToSave = JSON.parse(JSON.stringify(this.risk))
      riskToSave.description = JSON.stringify(riskToSave.description || "")
      riskToSave.vulnerability = JSON.stringify(riskToSave.vulnerability || "")
      let projectId = this.project.id || riskToSave.projectId
      this.$http.post(`api/Project/${projectId}/Risk`, {...riskToSave, workItems: null}).then(async res => {
        if(!res.data.id){
          window.getApp.error(res.data)
          return
        }
        if(!!this.workItemsToCreate.length) {
          let wis = JSON.parse(JSON.stringify(this.workItemsToCreate))
          wis.forEach(wi => {
            delete wi.assignedTo
            delete wi.assignedToBlueTag
            delete wi.status
            delete wi.temporalId
            wi.projectRiskId = res.data.id
          })
          this.$q.save({
            api: `/api/WorkItems`,
            data: wis,
            successMsg: 'Work items saved successfully',
            afterSuccessUrl: null,
            errorMsg: "Cannot save work items.",
            afterErrorUrl: null,
          }).then(() => {
            window.getApp.success(`Risk created successfully.`)
          })
        }
        this.risk = {
          status: 0,
          internalViewOnly: 0,
          probability: 3,
          impact: 3,
        }
        this.workItemsToCreate = []
        this.$refs.form.resetValidation()
        this.$emit('saved', res.data)
      }).catch(response => {
        window.getApp.error("Cannot save risk.")
        console.log(response)
      })
    },
  },
}
</script>

<style lang="scss" scoped>
.col-login {
  padding-bottom: 0px !important;
  max-height: 120px !important;

  span {
    font-weight: 400;
    color: var(--v-text-lighten2);
    margin-bottom: 8px;
  }
  
}
.card-container {
  padding-top: 0;
  flex: 1 1 1;
  min-width: 330px;
  max-width: 330px;
  .v-card {
    cursor: pointer;
    width: 100%;
    border: 1px solid #aaa;
    height: 100%;
    background: var(--v-background-lighten);
    .description {
      font-size: 1.2em;
      color: var(--v-text-lighten2);
      margin-bottom: 10px;
      margin-left: 5px;
      word-break: break-word;
    }
    .created-date {
      font-size: 1.0em;
      color: var(--v-text-lighten2);
      margin-bottom: 19px;
      margin-left: 4px;
    }
    .severity {
      position: absolute;
      bottom: 0;
      margin: 0 0 11px 6px;
      font-size: 1em;
      font-weight: bold;
      padding: 2px 7px;
      border-radius: 5px;
    }
  }
}

.status-button-title {
	height: 20px !important;
	text-transform: capitalize !important;
}

.mainContainer {
  display: flex;
  padding: 0 0 10px 0;
  .v-card__title {
    padding: 16px 2px 0 16px;
    align-self: flex-start;
  }
  .v-card__text {
    margin: 18px 0 0 0;
    padding-left: 7px;
  }
}
.test2 {
  max-width: none;
}
.save-task-button {
  position: fixed;
  bottom: 5px;
  width: 281px
}
</style>