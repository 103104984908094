<template>
  <div class="mt-5" style="width: calc(100vw - 190px); overflow: hidden">
    <div class="d-flex" style="gap: 20px">
      <div style="width: 360px">
				<v-card style="height: calc(100vh - 150px); padding-left: 10px" class="elevation-0 rounded" :class="$vuetify.theme.isDark ? '' : 'bordered-card'">
					<v-card-title>
            <div class="mb-7">
              <h5 class="text--text">
                Customer Satisfaction:
                <span :class="customerSatisfaction.color+'--text'">{{customerSatisfaction.description}}</span>
              </h5>
              <div class="body-2">
                Last health check
                <span v-if="customerSatisfaction.daysAgo == 0">today</span>
                <span v-else-if="customerSatisfaction.daysAgo > 0 && customerSatisfaction.daysAgo < 30">
                  {{customerSatisfaction.daysAgo}} days ago
                </span>
                <span v-else>{{customerSatisfaction.date}}</span>
              </div>
            </div>
            <div class="d-flex" style="width: 100%">
              <h5 class="text--text">Healthchecks</h5>
              <v-spacer></v-spacer>
              <v-btn @click="openHealthcheckDialog" fab x-small depressed color="primary">
                <v-icon>mdi-plus</v-icon>
              </v-btn>
            </div>
            <div
              class="background--lighten2 body-2 text-center rounded py-2 mt-2"
              style="width: 100%"
            >
              <v-icon>mdi-information-outline</v-icon>
              Healthcheck overdue: {{nextHealthcheck}}
            </div>
					</v-card-title>
					<v-card-text>
						<div
							v-for="healthcheck in healthchecks" :key="healthcheck.id"
							class="healthcheck elevation-2 pa-3 rounded mb-5 cursor-pointer"
              @click="openHealthcheckDialog(healthcheck)"
						>
							<div class="d-flex align-center">
								<user-avatar :userId="healthcheck.createdById" :size="40" hide-loading/>
								<div class="ml-3">
                  <div>
                    <b class="text--text">
                      {{healthcheck.createdByBlueTag || healthcheck.createdByFullName}}
                    </b>
                    <span v-if="healthcheck.daysAgo == 0">today</span>
                    <span v-else-if="healthcheck.daysAgo > 0 && healthcheck.daysAgo < 30">
                      {{healthcheck.daysAgo}} days ago
                    </span>
                    <span v-else>{{healthcheck.date | formatDate}}</span>
                  </div>
                  <div
                    class="px-2 rounded"
                    :style="{
                      fontSize: '.9em',
                      color: `var(--v-${healthcheck.customerPerceptionColor}-base)`,
                      background: !$vuetify.theme.isDark ? healthcheck.customerPerceptionLightColor : '',
                      border: $vuetify.theme.isDark ? '1px solid' : '',
                    }"
                  >
                    Customer Satisfaction: {{healthcheck.customerPerceptionDescription}}
                  </div>
								</div>
							</div>

							<div class="mt-3">
								<label>Comments:</label>
								<div class="multiline text--text">
									{{healthcheck.comments}}
								</div>
							</div>
              <div class=" mt-2 body-2 primary--text">
                {{healthcheck.workItems.length}} {{`incident${healthcheck.workItems.length > 1 ? 's' : ''}`}}
              </div>
						</div>
					</v-card-text>
				</v-card>
			</div>
      <div style="width: calc(100% - 360px)">
        <div>
          <div class="d-flex justify-space-between mt-3">
            <h3>Team Members</h3>
            <div>
              <toggle-view v-model="view" :items="views" class="mr-3" />
              <v-btn @click="openSettings" icon>
                <v-icon>mdi-cog-outline</v-icon>
              </v-btn>
            </div>
          </div>

          <v-data-table
            @click:row="openMemberDetails"
            :headers="
              headers.filter((x) => x.view === undefined || x.view == view)
            "
            :items="shownMembers"
            hide-default-footer
            disable-pagination
            class="mt-2"
            :item-class="() => 'cursor-pointer'"
          >
            <template #[`item.startDate`]="{ item }">
              {{ item.startDate | formatDate }}
            </template>
            <template #[`item.endDate`]="{ item }">
              <span v-if="item.isCurrent">Present</span>
              <span v-else>{{ item.endDate | formatDate }}</span>
            </template>
            <template #[`item.customerPerception`]="{ item }">
              <div
                class="dot"
                :style="{
                  background: `var(--v-${item.customerPerceptionColor}-base)`,
                }"
              ></div>
            </template>
            <template #[`item.requirements`]="{ item }">
              <v-chip
                v-if="item.requirements.some((x) => !x.checked)"
                color="error"
                outlined
              >
                {{item.requirements.filter((x) => !x.checked).length}}
                Pending
              </v-chip>
              <v-chip v-else color="success" outlined>Completed</v-chip>
            </template>
            <template #[`item.nucleiHappiness`]="{ item }">
              <div
                class="dot"
                :style="{
                  background: `var(--v-${item.nucleiHappinessColor}-base)`,
                }"
              ></div>
            </template>
          </v-data-table>
        </div>
        <div class="d-flex align-center mt-8 mb-3">
          <h3>Incidents</h3>
          <div>
            <v-btn class="ml-2" @click="openIncidentDialog" fab x-small depressed color="primary">
              <v-icon>mdi-plus</v-icon>
            </v-btn>
          </div>
        </div>
        <div class="d-flex pa-2 pt-1 overflow-auto" style="gap: 20px">
          <incident-card
            v-for="(item) in incidents"
            :key="item.id"
            :item="item"
            :statuses="projectConfig.workItemStatuses"
            @statusChange="loadHealthchecksAndIncidents"
            @click="openIncidentDialog(item)"
            width="300"
          />
        </div>
        
        
      </div>
    </div>

    <nuclei-settings-dialog :showHealthcheckReminder="true" ref="settingsDialog" />
    <nuclei-member-dialog
      :id="id"
      ref="nucleiMemberDialog"
    />
    <nuclei-scaling-dialog
      ref="NucleiScalingDialog"
      :projectId="id"
      :members="members"
    />
		<healthcheck-dialog :project-id="id" ref="healthcheckDialog"/>
  </div>
</template>

<script>
import moment from "moment";
import _ from 'lodash';
import NucleiSettingsDialog from "../../dialogs/NucleiSettingsDialog";
import NucleiMemberDialog from "../../dialogs/NucleiMemberDialog";
import NucleiScalingDialog from "../../dialogs/NucleiScalingDialog";
import HealthcheckDialog from "../../dialogs/HealthcheckDialog"
import IncidentCard from "../../components/IncidentCard.vue";

export default {
  props: ["id"],
  components: {
		NucleiSettingsDialog,
		NucleiMemberDialog,
		NucleiScalingDialog,
    HealthcheckDialog,
    IncidentCard,
	},
  data: () => ({
    view: 0,
    views: [
      { iconClass: "mdi-account-group", text: "Team" },
      { iconClass: "mdi-history", text: "Team Details" },
    ],
    headers: [
      { text: "Name", value: "assignment.userFullName", view: 1 },
      { text: "BlueTag", value: "assignment.userBlueTag" },
      { text: "Start Date", value: "startDate" },
      { text: "End Date", value: "endDate", view: 1 },
      { text: "Role", value: "assignment.billableLineRole" },
      {
        text: "C. Perception",
        value: "customerPerception",
        align: "center",
        sortable: false,
      },
      {
        text: "Requirements",
        value: "requirements",
        align: "center",
        sortable: false,
      },
      {
        text: "Nuclei Happiness",
        value: "nucleiHappiness",
        align: "center",
        sortable: false,
      },
      {
        text: "Highlights",
        value: "highlights.length",
        align: "center",
        sortable: false,
      },
    ],
    
		healthcheckHeaders: [
			{ text: 'Name', value: 'assignment.user.fullName', view: 1 },
			{ text: 'BlueTag', value: 'assignment.userBlueTag' },
			{ text: 'Start Date', value: 'startDate' },
			{ text: 'End Date', value: 'endDate', view: 1 },
			{ text: 'Role', value: 'assignment.billableLineRole' },
			{ text: 'C. Perception', value: 'customerPerception', align: 'center', sortable: false },
			{ text: 'Requirements', value: 'requirements', align: 'center', sortable: false },
			{ text: 'Nuclei Happiness', value: 'nucleiHappiness', align: 'center', sortable: false },
			{ text: 'Highlights', value: 'highlights.length', align: 'center', sortable: false },
		],

		healthchecks: [],
    project: {},
    members: [],
    today: moment().startOf('day'),
    incidents: [],
    healthchecks: [],
    projectConfig: [],
  }),
  created() {
    this.init();
    this.$q.log(1, 3, 3, this.id, this.$parent.item.id)
  },
  computed: {
    shownMembers() {
      return this.members.filter((x) => this.view == 1 || x.isCurrent);
    },
    customerSatisfaction(){
      let satisfaction = {
        color: 'lightgray',
        description: '-',
        daysAgo: null,
        date: null,
      }
      if(this.healthchecks.length){
        const lastHealthcheck = this.healthchecks[0]
        satisfaction.color = lastHealthcheck.customerPerceptionColor
        satisfaction.description = lastHealthcheck.customerPerceptionDescription
        satisfaction.daysAgo = lastHealthcheck.daysAgo
        satisfaction.date = moment(lastHealthcheck.date).format('MMM D, YYYY')
      }
      return satisfaction
    },
		nextHealthcheck(){
			const timeIncreasers = {
				0: date => moment(date).clone().add(2, 'week'),
				1: date => moment(date).clone().add(1, 'month'),
			}
      let lastHealthcheckDate = this.project.createdDateTime
      if(this.healthchecks.length)
        lastHealthcheckDate = this.healthchecks[0].date
			return timeIncreasers[this.project.healthcheckReminderRecurrence || 0]
        (lastHealthcheckDate)
        .format('MMM D, YYYY')
		},
  },
  methods: {
    init() {
      this.$http.get(`api/project/${this.id}/config?active=true`).then(res => {
        this.projectConfig = res.data
        this.projectConfig.workItemStatuses = this.projectConfig.workItemStatuses.filter(s => s.workItemType === 7)
      })
      this.$http.get(`api/project/${this.id}/header`)
        .then(res => {
          this.project = res.data
        })
      this.$http.get(`api/nucleiManagement/${this.id}/members`)
        .then((res) => {
          this.members = res.data.map((x) => ({
            ...x,
            userId: x.assignment.userId,
            startDate: moment(x.assignment.startDate),
            endDate: moment(x.assignment.endDate),
            isCurrent:
              moment(x.assignment.startDate).isSameOrBefore(this.today, "day") &&
              moment(x.assignment.endDate).isSameOrAfter(this.today, "day"),
          }))
        })
        .catch(response => {
          window.getApp.error("Cannot get nuclei members.");
        })
        
      this.loadHealthchecksAndIncidents()
		},
    loadHealthchecksAndIncidents(){
      this.$http.get(`/api/nucleiManagement/workItems/${this.id}`)
        .then(res => {
          this.incidents = res.data
        })
        .catch(res => {
          window.getApp.error("Cannot get work items.")
        })
			this.$http.get(`api/nucleiManagement/${this.id}/healthchecks`)
				.then(res => {
					this.healthchecks = res.data
						.map(h => ({
							...h,
							daysAgo: this.today.diff(moment(h.date), 'day')
						}))
				})
				.catch(err => {
					console.error(err)
					this.$root.error('Cannot get healthchecks')
				})
    },
		openHealthcheckDialog(item){
			this.$refs.healthcheckDialog.open(_.cloneDeep(item))
				.then(res => {
					if(!res) return
				})
				.catch(err => {
					console.error(err)
					this.$root.error('Cannot save healthcheck')
				})
        .finally(this.loadHealthchecksAndIncidents)
		},
    openSettings() {
      let lastHealthcheckDate = this.project.createdDateTime
      if(this.healthchecks.length)
        lastHealthcheckDate = this.healthchecks[0].date
      this.$refs.settingsDialog.open(this.id, this.project.healthcheckReminderRecurrence, lastHealthcheckDate)
        .then((res) => {
          if (res) this.init()
        })
    },
    openMemberDetails(member) {
			this.$refs.nucleiMemberDialog.open(member)
    },
    openIncidentDialog(item) {
      this.$refs.NucleiScalingDialog.open(this.id, item)
        .then(() => this.init())
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep .v-data-table__expanded__content{
  box-shadow: none !important;
}
.dot {
  display: inline-block;
  width: 18px;
  height: 18px;
  border-radius: 50%;
}
.status-button-title {
	height: 30px !important;
	text-transform: capitalize !important;
}
.scalling-binnacles-container {
  gap: 20px 20px;
  flex-wrap: wrap;
  padding: 0 auto;
  margin: auto;
}
.scalling-binnacles-card {
  min-width: 300px;
}
.bordered-card{
	border: 1px solid var(--v-lightgray-lighten);
	overflow: scroll;
}
.healthcheck{
	background: var(--v-background-lighten);
}

.v-card--link:focus:before{
  opacity: 0;
}
</style>
