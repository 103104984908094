<template>
	<div>
		<div v-if="$security.can('add')" class="text-right mb-5">
			<v-btn @click="add" color="primary" fab small>
				<v-icon>mdi-plus</v-icon>
			</v-btn>
		</div>
		<data-table
			:headers="headers"
			:items="categories"
		>
			<template #[`item.name`]="{ item }">
				<v-text-field
					v-model.trim="item.name"
					background-color="transparent"
					:flat="!item.editing" :solo="!item.editing" :outlined="item.editing"
					hide-details dense :readonly="!item.editing"
				/>
			</template>
			<template #[`item.smallCode`]="{ item }">
				<v-text-field
					v-model.trim="item.smallCode"
					background-color="transparent"
					:flat="!item.editing || !!item.id" :solo="!item.editing || !!item.id" :outlined="item.editing && !item.id"
					hide-details dense :readonly="!item.editing || !!item.id"
					style="width: 100px;"
				/>
			</template>
			<template #[`item.active`]="{ item }">
				<check-switch v-model="item.active" :disabled="!item.editing"/>
			</template>
			<template #[`item.createdAt`]="{ item }">
				{{ item.createdAt | formatDate }}
			</template>
			<template #[`item.actions`]="{ item }">
				<div class="text-right">
					<v-menu v-if="$security.can('edit') && !item.editing" left offset-x>
						<template #activator="{ on }">
							<v-btn v-on="on" icon><v-icon>mdi-dots-vertical</v-icon></v-btn>
						</template>
						<v-list dense class="py-1">
							<v-list-item class="pa-1 py-0">
								<v-tooltip right z-index="200">
									<template #activator="{ on }">
										<v-btn v-on="on" @click="$set(item, 'editing', true)" color="primary" text small>
											<v-icon>mdi-pencil</v-icon>
										</v-btn>
									</template>
									<span class="caption">Edit</span>
								</v-tooltip>
							</v-list-item>
							<v-list-item v-if="$security.can('delete')" class="pa-1 py-0">
								<v-tooltip right z-index="200">
									<template #activator="{ on }">
										<v-btn v-on="on" @click="remove(item)" color="error" text small>
											<v-icon>mdi-delete</v-icon>
										</v-btn>
									</template>
									<span class="caption">Edit</span>
								</v-tooltip>
							</v-list-item>
						</v-list>
					</v-menu>
					<div v-else class="d-flex" style="gap: 5px">
						<v-btn
							@click="save(item)" color="primary" icon small
							:disabled="!item.name || !item.smallCode"
						>
							<v-icon>mdi-check</v-icon>
						</v-btn>
						<v-btn @click="cancel(item)" icon small>
							<v-icon>mdi-close</v-icon>
						</v-btn>
					</div>
				</div>
			</template>
		</data-table>
	</div>
</template>

<script>
export default {
	data: () => ({
		categories: [],
		headers: [
			{ text: 'Name', value: 'name' },
			{ text: 'Small Code', value: 'smallCode' },
			{ text: 'Status', value: 'active' },
			{ text: 'Creation Date', value: 'createdAt' },
			{ text: '', value: 'actions', width: 1, sortable: false },
		],
	}),
	created() {
		this.$http.get('api/billable/category?onlyActive=false')
			.then(res => {
				this.categories = res.data
			})
			.catch(err => {
				console.error(err)
				this.$root.error('Failed to fetch billable categories')
			})
	},
	methods: {
		add() {
			this.categories.unshift({
				name: '',
				smallCode: '',
				active: true,
				editing: true,
			})
		},
		remove(item) {
			this.$openConfirmDialog({
				title: 'Delete Billable Category',
				description: `Are you sure you want to delete the billable category "${item.name}"?`,
			})
				.then(res => {
					if(!res) return

					this.$http.delete(`api/billable/category/${item.id}`)
						.then(res => {
							this.$root.success('Billable category deleted')
							this.categories = this.categories.filter(x => x.id !== item.id)
						})
						.catch(err => {
							console.error(err)
							this.$root.error('Failed to delete billable category')
						})
				})
		},
		save(item) {
			this.$http.post(`api/billable/category`, item)
				.then(res => {
					item = Object.assign(item, res.data)
					this.$root.success('Billable category updated')
					this.$set(item, 'editing', false)
					this.$set(item, 'id', res.data.id)
				})
				.catch(err => {
					console.error(err)
					if(!err.notified) {
						this.$root.error('Failed to update billable category')
					}
				})
		},
		cancel(item) {
			if(!item.id) {
				item.deleted = true
				this.categories = this.categories.filter(x => !x.deleted)
			}
			else {
				this.$set(item, 'editing', false)
			}
		},
	},
}
</script>

<style lang="scss" scoped>

</style>