var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"view"},[_c('filter-search-input',{attrs:{"applied-filters":_vm.appliedFilters,"dense":"","outlined":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}},[_c('q-date-picker',{attrs:{"outlined":"","hide-details":"","inner":"","dense":"","label":"Start Date","type":"month"},model:{value:(_vm.filters.startDate),callback:function ($$v) {_vm.$set(_vm.filters, "startDate", $$v)},expression:"filters.startDate"}}),_c('q-date-picker',{attrs:{"outlined":"","hide-details":"","inner":"","dense":"","label":"End Date","type":"month"},model:{value:(_vm.filters.endDate),callback:function ($$v) {_vm.$set(_vm.filters, "endDate", $$v)},expression:"filters.endDate"}}),_c('v-autocomplete',{attrs:{"outlined":"","hide-details":"","dense":"","multiple":"","clearable":"","label":"Customer","item-text":"name","item-value":"id","items":_vm.customers},model:{value:(_vm.filters.customers),callback:function ($$v) {_vm.$set(_vm.filters, "customers", $$v)},expression:"filters.customers"}})],1),_c('v-data-table',{staticClass:"rounded-lg bordered new-back lighten2",staticStyle:{"width":"var(--view-width)"},attrs:{"headers":_vm.shownHeaders,"items":_vm.report,"items-per-page":_vm.pagination.pageSize,"page":_vm.pagination.page,"server-items-length":_vm.pagination.totalItems,"disable-sort":""},on:{"pagination":_vm.updateReport,"update:itemsPerPage":function($event){return _vm.$set(_vm.pagination, "pageSize", $event)},"update:items-per-page":function($event){return _vm.$set(_vm.pagination, "pageSize", $event)},"update:page":function($event){return _vm.$set(_vm.pagination, "page", $event)}},scopedSlots:_vm._u([{key:"item.customerName",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"px-4",staticStyle:{"width":"150px"}},[_vm._v(_vm._s(item.customerName))])]}},{key:"item.billableCode",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"px-4",staticStyle:{"width":"170px"}},[_vm._v(_vm._s(item.billableCode))])]}},{key:"item.project",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"py-1",staticStyle:{"width":"160px"},attrs:{"set":_vm.type = _vm.projectTypes.find(function (t) { return t.value == item.projectType; })}},[_c('div',{staticClass:"admin-project dense",style:({ background: _vm.type.lightColor })},[_c('div',{staticClass:"letter",style:({ background: _vm.type.color })},[_vm._v(" "+_vm._s(_vm.type.small)+" ")]),_c('div',{staticClass:"name"},[_vm._v(_vm._s(item.projectName))])])])]}},{key:"item.billableStatus",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"text-center",staticStyle:{"width":"110px"}},[_c('v-btn',{staticClass:"rounded-xl",style:({
						background: _vm.$vuetify.theme.isDark ? '' : item.billableStatusLightColor,
					}),attrs:{"color":item.billableStatusColor,"outlined":"","x-small":""}},[_vm._v(_vm._s(item.billableStatusDisplayName))])],1)]}},_vm._l((_vm.timeColumns),function(ix){return {key:_vm.getHeaderSlotName(ix-1),fn:function(ref){
					var item = ref.item;
return [_c('div',{key:ix},[_c('span',{attrs:{"set":_vm.status = _vm.statuses.find(function (s) { return s.id == item.statuses[ix-1]; })}},[_c('v-chip',{style:({ color: _vm.status.color }),attrs:{"color":_vm.status.lightColor,"small":""}},[_vm._v(" "+_vm._s(_vm.status.description)+" ")])],1)])]}}})],null,true)})],1)}
var staticRenderFns = []

export { render, staticRenderFns }