<template>
	<div>
		<div v-if="$security.can('add')" class="text-right mb-3">
			<v-btn color="primary" @click="add" small fab depressed>
				<v-icon>mdi-plus</v-icon>
			</v-btn>
		</div>
		<data-table
			:headers="headers"
			:items="items"
		>
			<template #[`item.addressLine`]="{ item }">
				<div class="my-1">
					{{ item.addressLine }},
					{{ item.addressLine2 }},
					<br>
					{{ item.city }},
					{{ item.state }},
					<br>
					{{ item.countryName }},
					<br>
					{{ item.zipCode }}
				</div>
			</template>
			<template #[`item.actions`]="{ item }">
				<v-menu v-if="$security.can('edit')" left offset-x>
					<template #activator="{ on }">
						<v-btn v-on="on" icon><v-icon>mdi-dots-vertical</v-icon></v-btn>
					</template>
					<v-list dense class="py-1" color="high-back">
						<v-list-item class="pa-0">
							<v-tooltip right z-index="200">
								<template #activator="{ on }">
									<v-btn v-on="on" @click="edit(item)" color="primary" text small>
										<v-icon>mdi-pencil</v-icon>
									</v-btn>
								</template>
								<span class="caption">Edit</span>
							</v-tooltip>
						</v-list-item>
						<v-list-item v-if="$security.can('delete')" class="pa-0">
							<v-tooltip right z-index="200">
								<template #activator="{ on }">
									<v-btn v-on="on" @click="deleteItem(item)" color="error" text small>
										<v-icon>mdi-delete</v-icon>
									</v-btn>
								</template>
								<span class="caption">Delete</span>
							</v-tooltip>
						</v-list-item>
					</v-list>
				</v-menu>
			</template>
		</data-table>

		<v-dialog v-model="showDialog" width="800" persistent>
			<v-card color="high-back">
				<v-card-title>Add Bussiness Name</v-card-title>
				<v-card-text>
					<v-form v-if="showDialog" ref="form">
						<v-row>
							<v-col v-for="field in itemFields" :key="field.value" :cols="4">
								<v-select
									v-if="field.value === 'country'"
									label="Country*"
									v-model="selectedItem.country"
									:items="countries"
									item-text="name"
									item-value="id"
								/>
								<v-text-field
									v-else
									:label="field.text + ' *'"
									v-model="selectedItem[field.value]"
									:rules="field.rules || [$rules.required]"
									hide-details
								/>
							</v-col>
						</v-row>
						<div class="text-right mt-10">
							<v-btn color="primary" @click="showDialog = false" text>Cancel</v-btn>
							<v-btn color="primary" @click="save">Save Business Name</v-btn>
						</div>
					</v-form>
				</v-card-text>
			</v-card>
		</v-dialog>
	</div>
</template>

<script>
export default {
	data: () => ({
		headers: [
			{ text: 'Business Name', value: 'businessName' },
			{ text: 'RFC/EIN', value: 'rfc' },
			{ text: 'Address', value: 'addressLine' },
			{ text: 'Email', value: 'email' },
			{ text: '', value: 'actions', sortable: false, width: 1 },
		],
		countries: [
			{ name: 'Mexico', id: 0 },
			{ name: 'United States', id: 1 },
		],
		items: [],
		showDialog: false,
		selectedItem: null,
	}),
	created() {
		this.init()
	},
	computed: {
		itemFields() {
			return [
				{ text: 'Business Name', value: 'businessName' },
				{ text: 'RFC/EIN', value: 'rfc' },
				{ text: 'Trade Name', value: 'tradeName' },
				{ text: 'Address Line', value: 'addressLine' },
				{ text: 'Address Line 2', value: 'addressLine2' },
				{ text: 'Zip Code', value: 'zipCode' },
				{ text: 'City', value: 'city' },
				{ text: 'State', value: 'state' },
				{ text: 'Country', value: 'country' },
				{ text: 'Email', value: 'email', rules: [this.$rules.email] },
			]
		},
	},
	methods: {
		init() {
			this.$http.get('api/invoice/businessDetails')
				.then(res => {
					this.items = res.data
				})
				.catch(err => {
					console.error(err)
					this.$root.error('Failed to fetch invoice addresses')
				})
		},
		add() {
			this.selectedItem = {}
			this.showDialog = true
		},
		edit(item) {
			this.selectedItem = { ...item }
			this.showDialog = true
		},
		async deleteItem(item) {
			const confirm = await this.$openConfirmDialog({
				title: 'Delete business name',
				description: `Are you sure you want to delete <b>${item.businessName}</b>?`,
			})

			if(!confirm)
				return

			this.$http.delete(`api/invoice/businessDetails/${item.id}`)
				.then(res => {
					this.$root.success('Invoice address deleted')
					this.init()
				})
				.catch(err => {
					console.error(err)
					this.$root.error('Failed to delete invoice address')
				})
		},
		save() {
			if(!this.$refs.form.validate())
				return

			this.$http.post('api/invoice/businessDetails', this.selectedItem)
				.then(res => {
					this.$root.success('Invoice address saved')
					this.init()
					this.showDialog = false
				})
				.catch(err => {
					console.error(err)
					this.$root.error('Failed to save invoice address')
				})
		},
	},
}
</script>

<style lang="scss" scoped>

</style>