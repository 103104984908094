
<template>
  <div class="py-3 px-10 fill-height new-back">
    <v-form ref="form">
      <v-row>
        <v-col :cols="12" :lg="8">
          <h3>About the Project</h3>

          <div class="d-flex align-center my-3" style="gap: 20px">
            <div>
              <label>Logo</label>
              <v-badge :value="editing" overlap offset-x="20" offset-y="20">
                <v-avatar color="coloredBack" size="90">
                  <v-img v-if="newImage" :src="newImage.url"/>
                  <v-img v-else :src="getBackendUrl(`api/project/${this.id}/image`)"/>
                </v-avatar>
                <template #badge>
                  <v-icon @click="() => $refs.imageUploader.click()" class="cursor-pointer">
                    mdi-plus
                  </v-icon>
                </template>
              </v-badge>
            </div>
            <v-row dense>
              <v-col :cols="12">
                <label>Project Name*</label>
                <v-text-field
                  v-model="item.name"
                  outlined hide-details dense :readonly="!editing"
                  :rules="rules"
                />
              </v-col>
              <v-col :cols="3">
                <label>Small Code*</label>
                <v-text-field
                  v-model="item.smallCode"
                  outlined hide-details dense :readonly="!editing"
                  :rules="rules"
                />
              </v-col>
              <v-col :cols="3">
                <label>Code</label>
                <v-text-field
                  v-model="item.code"
                  outlined hide-details dense :readonly="!editing"
                />
              </v-col>
              <v-col :cols="3">
                <label>Resources</label>
                <v-text-field
                  v-model="item.currentTotalResources"
                  outlined hide-details dense :readonly="!editing"
                  type="number"
                />
              </v-col>
              <v-col :cols="3">
                <label>Status*</label>
                <v-select
                  v-model="item.status"
                  :items="statuses"
                  item-text="name" item-value="id"
                  outlined hide-details dense :readonly="!editing"
                  :rules="rules"
                />
              </v-col>
            </v-row>
          </div>

          <label>Description</label>
          <v-textarea
            v-model="item.description"
            outlined hide-details dense :readonly="!editing"
            :rows="2"
          />
          <br>
          <v-row dense>
            <v-col>
              <label>Customer*</label>
              <v-autocomplete
                v-model="item.customerId"
                @change="clearBillables"
                :items="customers"
                item-text="name" item-value="id"
                outlined hide-details dense :readonly="!editing"
                :rules="rules"
              />
            </v-col>
            <v-col>
              <label>Industry</label>
              <v-select
                v-model="item.industry"
                :items="industries"
                item-text="name" item-value="id"
                outlined hide-details dense :readonly="!editing"
              />
            </v-col>
            <v-col>
              <label>Type*</label>
              <v-select
                v-model="item.typeId"
                :items="shownTypes"
                item-text="name" item-value="id"
                outlined hide-details dense :readonly="!editing"
                :rules="rules"
              />
            </v-col>
            <v-col>
              <label>Product</label>
              <v-text-field
                v-model="item.otherProduct"
                outlined hide-details dense :readonly="!editing"
              />
            </v-col>
            <v-col>
              <label>Endpoints</label>
              <v-select
                v-model="item.endpointList"
                :items="endpoints"
                item-text="name" item-value="id"
                outlined hide-details dense multiple :readonly="!editing"
              />
            </v-col>
          </v-row>

          <v-row dense class="mt-3">
            <v-col :cols="3">
              <label>Start Date</label>
              <q-date-picker
                v-model="item.startDate"
                outlined hide-details dense inner :readonly="!editing"
                label=""
              />
            </v-col>
            <v-col :cols="3">
              <label>Estimated End Date</label>
              <q-date-picker
                v-model="item.estimatedEndDate"
                outlined hide-details dense inner :readonly="!editing"
                label=""
              />
            </v-col>
            <v-col :cols="3">
              <label>Planned End Date</label>
              <q-date-picker
                v-model="item.plannedEndDate"
                outlined hide-details dense inner :readonly="!editing"
                label=""
              />
            </v-col>
            <v-col :cols="3">
              <label>Agreed End Date</label>
              <q-date-picker
                v-model="item.agreedEndDate"
                outlined hide-details dense inner :readonly="!editing"
                label=""
              />
            </v-col>
            <v-col :cols="6">
              <label>Reporting Categories</label>
              <v-autocomplete
                v-model="selectedCategories"
                :items="categories"
                item-text="name" item-value="id"
                outlined hide-details dense inner multiple :readonly="!editing"
              >
                <template #selection="{ item }">
                  <category-chip class="mt-1" :text="item.name" :key="item.id" small/>
                </template>
              </v-autocomplete>
            </v-col>
            <v-col :cols="6">
              <label>
                Billables
                <v-btn
                  v-if="editing"
                  @click="addBillable"
                  width="20" height="20"
                  depressed fab
                  color="primary" class="mt-n1"
                >
                  <v-icon small>mdi-plus</v-icon>
                </v-btn>
              </label>
              <div class="mt-1">
                <div v-for="(bill, ix) in item.billables" :key="ix" class="d-flex align-center mb-1">
                  <v-checkbox
                    @change="setActiveBillable(ix)"
                    color="primary" class="ma-0"
                    hide-details v-model="bill.active"
                    :disabled="bill.active || !editing"
                  />
                  <v-autocomplete
                    v-model="bill.billableId"
                    :items="shownBillables"
                    outlined hide-details dense :readonly="!editing"
                    item-text="code" item-value="id"
                    :rules="rules"
                  >
                    <template v-slot:item="props">
                      <div class="py-2" v-if="!item.billables.some(b => b.billableId == props.item.id)">
                        {{props.item.code}}
                      </div>
                      <div v-else></div>
                    </template>
                  </v-autocomplete>
                  <v-btn v-if="editing" @click="removeBillable(ix)" icon color="error">
                    <v-icon>mdi-delete</v-icon>
                  </v-btn>
                </div>
              </div>
            </v-col>
            <v-col :cols="12">
              <label>Notes</label>
              <v-textarea
                v-model="item.notes"
                outlined hide-details dense :readonly="!editing"
                :rows="2"
              />
            </v-col>
            <v-col :cols="12">
              <div :style="{ border: '1px solid #cccccc', borderRadius: '5px'}" >
                <div :style="{ padding: '20px'}">
                  <label :style="{ fontSize: '16px', color: 'primary', fontWeight: 700, padding: '20px 20px 20px 8px'}">Image Slideshow</label>
                  <div>
                    <v-row :style="{ maxWidth: '100%', margin: 0}">
                      <div :style="{ maxWidth: '100px', display: 'flex', justifyContent: 'center', alignItems: 'center', margin: '12px'}" v-for="(wiFile, ix) in files"  :key="ix">
                        <v-tooltip top >
                          <template v-slot:activator="{ on }">
                            <div :style="{margin: '8px'}">
                              <v-img
                                v-on="on"
                                class="cursor-pointer img-container"
                                @click="openImage(wiFile, $event)"
                                :src="getImageUrl(wiFile)"
                                width="100px"
                                max-height="100px"
                                contain
                                :style="{ width: '100px',}"
                              >
                              <v-btn
                                color="red"
                                class="icon-cont"
                                @click.stop="removeFile('files', ix, wiFile)" 
                                x-small
                                dark
                              >
                                <v-icon small>delete</v-icon>
                              </v-btn>
                            </v-img>
                            </div>
                          </template>
                          <span>{{ wiFile.name }}</span>
                        </v-tooltip>
                      </div>
                    </v-row>
                  </div>
                  <div :style="{ display: !editing ? 'none' : '', color: 'gray'}" @click="$refs.newImage.click()" class="small img ma-1 add-file-button cursor-pointer">
                    <div :style="{ cursor: 'pointer', padding: '20px 20px 20px 20px', display: 'flex', flexDirection: 'column'}">
                      <v-icon color="black" medium>mdi-cloud-upload-outline</v-icon>
                      <div :style="{ cursor: 'pointer'}">
                        <label :style="{ cursor: 'pointer', color: 'black' }">Drag and drop your images here or</label>
                        <label :style="{ cursor: 'pointer', color: '#4194fd', marginLeft: '8px' }">Browse your computer</label>
                      </div>
                    </div>
                  </div>
                    <input class="d-none" type="file" id="newImage" ref="newImage" v-on:change="addImage()"/>
                </div>
              </div>
            </v-col>
          </v-row>

        </v-col>
        <v-col :cols="12" :lg="4">
          <div>
            <div class="d-flex justify-space-between my-3" style="height: 36px">
              <h3>Technologies</h3>
              <v-btn v-if="editing" @click="addTechnology" color="primary" text>
                <v-icon left>add</v-icon>
                Add
              </v-btn>
            </div>
            <v-data-table
              :headers="technologyHeaders" :items="item.technologies"
              class="rounded-lg bordered darken new-back lighten2 mt-3"
              disable-pagination disable-sort hide-default-footer
            >
              <template #[`item.name`]="{ item }">
                <b>{{ item.name }}</b>
              </template>
              <template #[`item.actions`]="props">
                <v-menu v-if="editing" left>
                  <template #activator="{ on }">
                    <v-btn v-on="on" icon><v-icon>mdi-dots-vertical</v-icon></v-btn>
                  </template>
                  <v-list dense class="py-1">
                    <v-list-item class="pa-1 py-0">
                      <v-tooltip right z-index="200">
                        <template #activator="{ on }">
                          <v-btn v-on="on" @click="editTechnology(props.item)" color="primary" icon>
                            <v-icon>edit</v-icon>
                          </v-btn>
                        </template>
                        Edit
                      </v-tooltip>
                    </v-list-item>
                    <v-list-item class="pa-1 py-0">
                      <v-tooltip right z-index="200">
                        <template #activator="{ on }">
                          <v-btn v-on="on" @click="removeTechnology(props.index)" color="error" icon>
                            <v-icon>delete</v-icon>
                          </v-btn>
                        </template>
                        Delete
                      </v-tooltip>
                    </v-list-item>
                  </v-list>
                </v-menu>
              </template>
            </v-data-table>
          </div>

          <v-divider class="my-10"></v-divider>
          
          <div>
            <h3>Project Tabs</h3>
            <v-row v-if="editing" class="mt-2" dense>
              <v-col v-for="tab in projectTabs" :key="tab.value" cols="6" sm="4">
                <div class="d-flex align-center rounded bordered darken new-back lighten2 pa-2">
                  <v-checkbox
                    v-model="getProjectTab(tab).active"
                    class="ma-0 pa-0"
                    hide-details
                    :disabled="tab.value == 1"
                  />
                  <div class="single-line">
                    {{ tab.name }}
                  </div>
                </div>
              </v-col>
            </v-row>
            <v-row v-else class="mt-2" dense>
              <v-col v-for="tab in projectTabs.filter(x => getProjectTab(x).active)" :key="tab.value" cols="6" sm="4">
                <div class="d-flex align-center rounded bordered darken new-back lighten2 pa-2">
                  <v-icon v-if="!tab.googleIcon" color="text">{{ tab.icon }}</v-icon>
                  <span class="menu-icon material-symbols-outlined" v-else>{{ tab.icon }}</span>
                  <div class="single-line ml-1">
                    {{ tab.name }}
                  </div>
                </div>
              </v-col>
            </v-row>
          </div>
        </v-col>
      </v-row>
    </v-form>

    <div style="position: fixed; bottom: 15px; right: 70px">
      <v-btn v-if="!editing" @click="editing = true" color="primary" fab>
        <v-icon>edit</v-icon>
      </v-btn>
      <v-btn v-else @click="save" color="primary">
        <v-icon left>check</v-icon>
        Save
      </v-btn>
    </div>

    <blue-confirm-dialog ref="technologyDialog">
      <template #title>
        <span class="title text--text">
          Add Technology
        </span>
      </template>
      <template #text>
        <v-form>
          <div class="mb-2">
            <label>Type</label>
            <v-select
              v-model="newTechnology.type"
              @change="v => newTechnology.typeName = technologyTypes.find(x => x.id == v).name"
              :items="technologyTypes"
              item-text="name" item-value="id"
              outlined hide-details dense
            />
          </div>
          <div>
            <label>Name</label>
            <v-text-field
              v-model="newTechnology.name"
              outlined hide-details dense
            />
          </div>
        </v-form>
      </template>
      <template #actions="{ cancel, confirm }">
        <v-spacer/>
        <v-btn @click="cancel" color="primary" text>Cancel</v-btn>
        <v-btn
          @click="confirm"
          :disabled="(!newTechnology.type && newTechnology.type !== 0) || !newTechnology.name"
          color="primary"
        >
          Save Technology
        </v-btn>
      </template>
    </blue-confirm-dialog>
    <input
      @change="uploadImage"
      class="d-none"
      type="file" ref="imageUploader"
      accept="image/png, image/gif, image/jpeg"
    />
  </div>
</template>

<script>
import _ from "lodash"

export default {
  props: ["id", "canEditProject", 'projectName'],
  data: () => ({
    files: [],
    item: {
      technologies: [],
      billables: [],
      tabs: [],
    },
    newTechnology: {},

    projectTabs: [
      { name: 'Work Items',                 value: 2,  icon: 'mdi-playlist-check'},
      { name: 'Dashboard',                  value: 15, icon: 'mdi-chart-bar'},
      { name: 'Nuclei Management',          value: 17, icon: 'mdi-folder-wrench-outline'},
      { name: 'Account',                    value: 18, icon: 'mdi-file-document-multiple-outline'},
      { name: 'Healthchecks',               value: 19, icon: 'monitor_heart', googleIcon: true },
      { name: 'Risks',                      value: 11, icon: 'mdi-clipboard-pulse-outline'},
      { name: 'Links',                      value: 3,  icon: 'mdi-link-variant'},
      { name: 'Team Members',               value: 14, icon: 'mdi-account-tie'},
      { name: 'General Details',            value: 1,  icon: 'mdi-application-cog-outline'},
      { name: 'Activity Log',               value: 10, icon: 'mdi-file-chart-outline'},
      { name: 'Users Permissions',          value: 13, icon: 'mdi-account-cog'},
      { name: 'Config',                     value: 12, icon: 'mdi-cog'},
      { name: 'Overview',                   value: 16, icon: 'mdi-chart-timeline-variant-shimmer'},
    ],
    technologyHeaders: [
      { text: 'Name', value: 'name' },
      { text: 'Type', value: 'typeName' },
      { text: '', value: 'actions', width: 0 },
    ],
    rules: [v => typeof v == 'number' || (!!v && v.trim && !!v.trim()) || 'Required'],

    statuses: [],
    customers: [],
    types: [],
    categories: [],
    billables: [],
    technologyTypes: [],
    prevTabs: [],
    editing: false,
    selectedCategories: [],
    industries: [],
    endpoints: [],
    newImage: null,
  }),
  created() {
    if(this.$parent.item?.id)
		  this.$q.log(1, 9, 3, this.id, this.$parent.item.id)
    if (this.id) {
      this.$http
        .get(`/api/project/${this.id}`)
        .then(res => {
          this.item = res.data
          this.prevTabs = _.cloneDeep(res.data.tabs.filter(tab => tab.active))
          this.selectedCategories = this.item.categories
            .map(x => x.categoryId)
        })
        .catch(res => {
          window.getApp.error("Cannot get record.")
          console.log(res)
        })
    }
    else{
      this.editing = true
    }
    this.$http
      .get(`/api/enums/projectStatus`)
      .then(res => {
        this.statuses = res.data
      })
      .catch(err => {
        window.getApp.error("Cannot get project status.")
        console.error(err)
      })
    this.$http.get('api/customer')
      .then(res => {
        this.customers = res.data
      })
      .catch(err => {
        window.getApp.error("Cannot get customers.")
        console.error(err)
      })
    this.$http.get('api/project/types')
      .then(res => {
        this.types = res.data
      })
      .catch(err => {
        console.error(err)
        this.$root.error('Error loading project types')
      })
    this.$http
      .get(`/api/enums/technologyTypes`)
      .then(res => {
        this.technologyTypes = res.data    
      })
      .catch(err => {
        window.getApp.error("Cannot get technology types.")
        console.error(err)
      })
    this.$http
      .get(`/api/category`)
      .then(res => {
        this.categories = res.data
      })
      .catch(err => {
        window.getApp.error("Cannot get categories.")
        console.error(err)
      })
    this.$http.get('api/billable')
      .then(res => {
        this.billables = res.data
      })
      .catch(err => {
        window.getApp.error("Cannot get billables.")
        console.error(err)
      })
    this.$http
      .get('api/enums/ProjectIndustries')
      .then(res => {
        this.industries = res.data
      })
    this.$http
      .get('api/enums/ProjectEndpoints')
      .then(res => {
        this.endpoints = res.data
      })
    this.$http.get(`/api/project/${this.id}/slideshow`)
    .then(res => {
      this.files = res.data
    })
    .catch(err => {
      window.getApp.error("Cannot get slideshow.")
      console.error(err)
    })
},
  computed: {
    shownBillables(){
      return this.billables.filter(b => b.customerId == this.item.customerId)
    },
    shownTypes(){
      return this.types.filter(x => x.active || x.id == this.item.typeId)
    },
    isNew(){
      return !this.id || this.id == 'new'
    },
  },
  methods: {
    save() {
      if(!this.$refs.form.validate()) {
        return
      }
      let newTabs = _.cloneDeep(this.item.tabs.filter(tab => tab.active))
      let oldAndNewTabsDifferent =
        _.differenceBy(this.prevTabs,newTabs, 'tab').length >= 1 ||
        _.differenceBy(newTabs, this.prevTabs, 'tab').length >= 1

      this.item.categories = this.selectedCategories.map(cat => ({
        categoryId: cat
      }))
      this.item.endpoints = this.item.endpointList?.join(',')
      this.item.otherCustomer = ''
      this.$q.save({
        api: "/api/project",
        data: this.item,
        afterSuccessUrl: null,
        errorMsg: "Cannot save record.",
        afterErrorUrl: null,
      }).then(async res => {
        let project = res.data
        let error = typeof project == 'string'
        if(error) {
          window.getApp.error(project);
          return
        }

        //upload image
        if(this.newImage){
          let formData = new FormData()
          formData.append('file', this.newImage.image)
          try{
            await this.$http.post(`/api/project/${project.id}/image`, formData, {
              headers: {
                'Content-Type': 'multipart/form-data'
              }
            })
            caches.open("v1").then((cache) => {
              cache.delete(getBackendUrl(`/api/project/${this.id}/image`)).then((response) => {
                this.newImage = null
              })
            })
          }
          catch(err){
            window.getApp.error("Cannot upload image.")
            console.error(err)
          }
        }
        for(let file of this.files){
          this.handleImageUpload(file, project.id)
        }

        window.getApp.success('Project saved.');
        if(this.isNew){
          this.$router.push(`/project/${project.smallCode}/details`);
          return
        }
        if(oldAndNewTabsDifferent) {
          this.prevTabs = _.cloneDeep(project.tabs)
          this.$nextTick(() => this.$emit('reload'))
        }
        this.item = project
        this.editing = false
      })
    },

    addBillable(){
      this.item.billables.unshift({
        billableId: '',
        active: !this.item.billables.length,
      })
    },
    removeBillable(index){
      this.$openConfirmDialog({
        title: 'Remove Billable',
        text: 'Are you sure you want to remove this billable?',
      })
        .then(res => {
          if(!res) return
          this.item.billables = this.item.billables.filter((b, ix) => ix != index)
          if(this.item.billables.length && !this.item.billables.some(b => b.active)){
            this.item.billables[0].active = true
          }
        })
    },
    setActiveBillable(index){
      for(let [i, bill] of this.item.billables.entries()){
        bill.active = index == i
      }
    },
    clearBillables(){
      this.item.billables = []
    },

    addTechnology(){
      this.newTechnology = {}
      this.$refs.technologyDialog
        .open({
          width: 450,
        })
        .then(res => {
          if(!res) return
          this.item.technologies
            .push(this.newTechnology)
        })
    },
    editTechnology(technology){
      this.newTechnology = _.cloneDeep(technology)
      this.$refs.technologyDialog
        .open({
          width: 450,
        })
        .then(res => {
          if(!res) return
          technology.name = this.newTechnology.name
          technology.type = this.newTechnology.type
          technology.typeName = this.newTechnology.typeName
        })
    },
    removeTechnology(index){
      this.$openConfirmDialog({
        title: 'Remove Technology',
        text: 'Are you sure you want to remove this technology?',
      })
        .then(res => {
          if(!res) return
          this.item.technologies = this.item.technologies.filter((b, ix) => ix != index)
        })
    },

    isFile(file) {
      return !file.type.includes('image')
    },

    openImage(selected){
      let allImages = this.files.filter(file => file.type.includes('image'))
      for(let img of allImages){
        img.url = this.getImageUrl(img)
      }
      if(!selected){
        if(!allImages.length){
          this.$refs.imageCarousel.cancel()
          return
        } else {
          selected = allImages[0]
        }
      }
      selected.url = this.getImageUrl(selected)
      this.$refs.imageCarousel.open(allImages, selected, this.deleteImage)
    },
    getImageUrl(image){
      if(!image.url)
        image.url = URL.createObjectURL(image)
      return image.url
    },
    equalsFileType(filename, filetype){
      filetype = filetype.map(type => type.toLowerCase())
      if(!filename)
        return false;
      filename = filename.split('.');
      return filetype.indexOf(filename[filename.length - 1]) != -1
    },
    async removeFile(from ,index, file){
      await this.$http.delete(`/api/project/${this.id}/slideshow/${file.name}`)
      .then(res => {
        this[from] = this[from].filter((file, i) => i != index)
      })
      .catch(err => {
        window.getApp.error("Cannot delete image.")
        console.error(err)
      })
    },
    addImage(file = this.$refs.newImage.files[0], isImage = false){
        let isFile = this.isFile(file) && !isImage;
        if(!isFile)
          file.big = false;
        this.files.push(file)
        this.$refs.newImage.value = null
      },
    getProjectTab(tab){
      let any = this.item.tabs.some(x => x.tab == tab.value)

      if(!any)
        this.item.tabs.push({ tab: tab.value, active: tab.value == 1})

      return this.item.tabs.find(x => x.tab == tab.value) || {}
    },

    async handleImageUpload(file, projectId){
      let isFile = !file.type.includes('image')
      let formData = new FormData()
      formData.append('file', file)
      formData.append('filename', file.name,)
      await this.$http.post(`/api/project/${projectId}/slideshow`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      }).then(async () => {
        window.getApp.success(`${isFile ? 'file' : 'image'} uploaded.`)
      })
      .catch(() => {
        window.getApp.error(`Cannot upload ${isFile ? 'file' : 'image'}.`)
      })
    },

    uploadImage(){
      if(this.$refs.imageUploader.files.length){
        const image = this.$refs.imageUploader.files[0]
				this.newImage = {
          image,
          url: URL.createObjectURL(image)
        }
			}
    },
  },
}
</script>

<style scoped lang="scss">
.content{
  width: 100%;
}
::v-deep .v-input--is-readonly{
	fieldset{
		border-color: var(--v-lightgray-base);
	}
}
label{
  color: var(--v-text-lighten);
}
::v-deep .v-list-item{
  min-height: 0 !important;
}
.single-line{
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
::v-deep .v-expansion-panel-header{
    min-height: 48px !important;
    &::before{
      opacity: 0 !important;
    }
  }
  ::v-deep .v-expansion-panel::before{
    box-shadow: none !important;
  }
  ::v-deep .v-expansion-panel::after{
    content: none !important;
  }
  .v-expansion-panels > div {
    width: 100%;
  }
  .remove-border-bottom{
    border-bottom: none !important;
  }

  .tags-container{
    max-height: 400px;
    overflow-y: scroll;
  }
  
  .type-button {
    position: absolute;
    top: 0;
    left: 0;
    border-radius: 0 0 5px 0;

    ::v-deep .v-btn__content {
      justify-content: left;
    }
  }

  .user-tile {
    border-bottom: 1px solid #949494 !important;
  }

  .files .img {
    transition: all 200ms;
    width: 100%;
    max-height: 50px;
    min-height: 30px;
    border-radius: 3px;
    border: 1px solid #cccccc;
  }

  .files .img .delete-btn {
    display: none;
  }

  .files .img.big:hover .delete-btn {
    position: absolute;
    display: inline-block;
    top: 1px;
    right: 3px;
  }

  .files .img.small {
    max-width: 70px;
    max-height: 70px;
    height: 70px;
    border: 1px solid #cccccc;
  }


  .files .file-card {

    transition: all 200ms;
    width: 100%;
    max-height: 400px;
    min-height: 30px;
    height: 70px;
    border-radius: 3px;
    border: 1px solid #cccccc;

    &.big:hover .delete-btn {
      position: absolute;
      display: inline-block;
      top: 1px;
      right: 3px;
    }

    &.small {
      max-width: 70px;
      max-height: 70px;
    }

    .delete-btn {
      display: none;
    }
  }

  .files .file-card {
    display: flex;
    justify-content: center;

    i {
      font-size: 40px;
    }
  }

  .files-container {
    margin: 10px 0;
  }
  .files-container .file-card:nth-child(odd){
    margin-right: 3%;
  }
  .files-container .file-card{
    display: inline-flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
    height: 50px;
    width: 45%;

    & .file-info{
      display: inline-flex;
      align-items: center;
    }

    & img{
      height: 40px;
      margin-right: 10px;
    }

    & .delete-btn{
      display: none;
      position: relative;
      align-self: start;
    }
    &:hover .delete-btn{
      display: inline-block;
    }
  }
  
  .color-tags{
    min-height: 28px;
    height: 28px;
    background: var(--v-kanban-base);
    color: var(--v-text-base);
    border-radius: 3px;
  }
  
  .showed-tag{
    display: inline-block;
    border: 1px solid #888 !important;
    margin-right: 5px;
    margin-bottom: 5px;
  }

  .files .img {
    transition: all 200ms;
    width: 100%;
    max-height: 400px;
    min-height: 30px;
    border-radius: 3px;
    border: 1px solid #cccccc;
  }

  .files .img .delete-btn {
    display: none;
  }

  .files .img.big:hover .delete-btn {
    position: absolute;
    display: inline-block;
    top: 1px;
    right: 3px;
  }

  .files .img.small {
    max-width: 70px;
    max-height: 70px;
    height: 70px;
    border: 1px solid #cccccc;
    border-radius: 5px;
  }


  .files .file-card {

    transition: all 200ms;
    width: 100%;
    max-height: 400px;
    min-height: 30px;
    height: 70px;
    border-radius: 3px;
    border: 1px solid #cccccc;

    &.big:hover .delete-btn {
      position: absolute;
      display: inline-block;
      top: 1px;
      right: 3px;
    }

    &.small {
      max-width: 70px;
      max-height: 70px;
      // border: 1px solid #cccccc;
    }

    .delete-btn {
      display: none;
    }
  }

  .files .file-card {
    // width: 70px;
    // height: 70px;
    // margin: 4px;
    display: flex;
    justify-content: center;

    i {
      font-size: 40px;
    }
  }

  .files-container {
    margin: 10px 0;
  }
  .files-container .file-card:nth-child(odd){
    margin-right: 3%;
  }
  .files-container .file-card{
    display: inline-flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
    height: 50px;
    width: 45%;

    & .file-info{
      display: inline-flex;
      align-items: center;
    }

    & img{
      height: 40px;
      margin-right: 10px;
    }

    & .delete-btn{
      display: none;
      position: relative;
      align-self: start;
    }
    &:hover .delete-btn{
      display: inline-block;
    }
  }
  .relative-ctn2 {
  position: relative;
}
.add-file-button {
  margin: 4px;
  border-radius: 10px !important;
  border: 2px solid #c1c1c1 !important;
  border-style: dashed !important;
  color: gray !important;
  display: flex;
  justify-content: center;
}
.first-delete {
  top: 15px;
}
.second-delete {
  top: 25px;
}
.file-container:hover img {
  opacity: 0.5 !important;
}
.icon-cont {
  position: absolute;
  z-index: 99;
  display: none;
}
.img-container:hover {
  & .icon-cont {
    display: block !important;
  }
}
.file-container:hover .icon-cont {
  display: block !important;
}
.priority ::v-deep .v-input__slot {
  padding: 0 4px !important;
}
</style> 