<template>
  <v-menu :open-on-hover="true" :offset-y="true" v-model="menu" :nudge-width="500" :disabled="!showCard">
    <template v-slot:activator="{ on }">
      <v-avatar v-on="on" :size="size" color="indigo">
        <v-img v-if="userId" :src="profilePicSrc(userId)" @error="profilePicError = true"></v-img>
      </v-avatar>
    </template>

    <profile-card v-if="showCard && userId" :small="true" :userId="userId" :hideLoading="hideLoading"></profile-card>
  </v-menu>
</template>

<script>
import _ from "lodash"
import ProfileCard from "./panels/ProfileCard"

export default {
  components: {
    ProfileCard,
  },
  props: {
    showCard: { type: Boolean, default: true },
    userId: { type: String },
    size: { type: Number, default: 48 },
    hideLoading: { type: Boolean, default: false },
  },
  data: () => ({
    menu: false,
    profilePicError: false,
    profilePicFailover: 'https://cdn.vuetifyjs.com/images/parallax/material2.jpg',
  }),
  watch: {
  },
  computed: {
  },
  methods: {
    profilePicSrc(userId) {
      if (this.profilePicError) {
        return this.profilePicFailover
      }
      let src = `api/user/${userId}/profilePicture`;
      if(!this.$http.defaults.baseURL.endsWith('/'))
        src = '/' + src;
      return this.$http.defaults.baseURL + src;
    },
  },
  created() {
  },
  beforeDestroy () {
  },
}
</script>

<style scoped lang="scss">

</style>