<template>
  <div>
    <slot
      v-if="editting || alwaysEdditting"
      :name="'itemEdit.' + name"
      v-bind="{ name, blur: () => toggleEdditing(false) }"
    >
      <q-date-picker
        v-if="isDate"
        @change="editting = false; $emit('change', $event)"
        v-model="item[name]"
        outlined
        dense
        :autofocus="!alwaysEdditting"
        v-bind="$attrs"
        @blur="toggleOutsideDateClick"
        @update:pickerMenu="e => showingPickerMenu = e"
      />
      <v-select
        v-else-if="isMultiSelect"
        v-model="item[name]"
        outlined
        dense
        :autofocus="!alwaysEdditting"
        v-bind="$attrs"
        @blur="editting = false"
        @change="editting = false"
      />
      <v-text-field
        v-else
        v-model="item[name]"
        outlined
        dense
        :autofocus="!alwaysEdditting"
        v-bind="$attrs"
        @blur="editting = false"
      />
    </slot>
    <slot
      v-else
      :name="'item.' + name" v-bind="{ value: item[name], click: () => toggleEdditing(true) }"
    >
      <div
        @click="editting = true"
      >
        {{
          item[name] ?
          isDate ? formatDate(item[name]) : item[name] :
          '-'
        }} 
      </div>
    </slot>
  </div>
</template>

<script>
import moment from 'moment';
export default {
  props: {
		name: { type: String, default: "" },
		item: { type: Array|Object, default: () => ({}) },
		alwaysEdditting: { type: Boolean, default: false },
		isDate: { type: Boolean, default: false },
		isMultiSelect: { type: Boolean, default: false },
	},
  data: () => ({
    editting: false,
    showingPickerMenu: false,
  }),
  watch: {
    showingPickerMenu(val) {
      if(!val)
        this.editting = val
    }
  },
  methods: {
    toggleEdditing(val) {
      this.editting = val
    },
    toggleOutsideDateClick() {
      if(!this.showingPickerMenu)
        setTimeout(() => this.editting = false, 100)
    },
    formatDate: (date) => moment(date).format('MMM D, YYYY'),
  }
}
</script>

<style>

</style>