<template>
	<div class="mb-10" ref="members">
		<h4 ref="title">Team Members</h4>
		<v-row v-for="n in length" :key="n">
			<v-col v-for="(member, ix) in members.slice((n-1)*4, n*4)" :key="ix" :cols="3" style="min-width: 350px">
				<div class="member-container">
					<v-avatar class="avatar" color="grey" :size="100">
						<v-img :lazy-src="member.profilePicSrc" :src="member.profilePicSrc" @error="member.profilePicSrc = profilePicFailover"></v-img>
					</v-avatar>

					<div class="content">
						<b>
							{{member.user.blueTag}}
						</b>
						<br>
						{{member.user.fullName}}
						<div class="role-container">
							<span>
								{{ roles.filter(r => r.value == member.role)[0].name }}
							</span>
						</div>
					</div>
				</div>
			</v-col>
		</v-row>
	</div>
</template>

<script>
export default {
	props: ["id"],
	data() {
		return {
			members: [],
			profilePicFailover: 'https://cdn.vuetifyjs.com/images/parallax/material2.jpg',
			roles: [
				{ name: 'Business Analyst', value: 0 },
				{ name: 'Delivery Manager', value: 1 },
				{ name: 'Developer', value: 2 },
				{ name: 'Key User', value: 3 },
				{ name: 'Product Owner', value: 4 },
				{ name: 'Program Manager', value: 5 },
				{ name: 'Project Manager', value: 6 },
				{ name: 'QA', value: 7 },
				{ name: 'Tech Lead', value: 12 },
				{ name: 'Solutions Architect', value: 8 },
				{ name: 'Stakeholder', value: 9 },
				{ name: 'UX/UI', value: 10 },
				{ name: 'Other', value: 11 },
			],
			length: 0,
		}
	},
	mounted() {
		this.$http
			.get(`/api/project/${this.id}/members`)
			.then(res => {
				this.members = res.data
				this.length = parseInt(this.members.length / 4)
				if(this.members.length%4 > 0){
					this.length++
				}
				this.members.forEach(this.$q.profilePicSrc)
				this.members = this.members.map(member => {
					if(!member.user.blueTag){
						member.user.blueTag = member.user.fullName
						member.user.fullName = '-'
					}
					return member
				})
			})
	},
	methods: {
		getCanvases(){
			let lineElements = this.$refs.members.children
			let elements = []
			for(let line of lineElements)
				elements.push(line)
			return elements
		},
	},
}
</script>

<style scoped lang="scss">
.member-container{
	text-align: center;

	.avatar{
		margin-bottom: 10px;
	}

	.content{
		background: var(--v-background-lighten2);
		border-radius: 5px;
		padding: 20px 30px;
		padding-top: 65px;
		margin-top: -55px;

		.role-container{
			border-top: 1px solid #7fafe0;
			margin-top: 10px;
			padding: 10px 0;
			color: var(--v-accent-base);
			text-align: center;
		}
	}

}
</style>