<template>
  <div class="new-back pa-3 fill-height">

    <filter-search-input v-model="filters.search" :applied-filters="appliedFilters" outlined dense>
      <q-user-autocomplete
        v-model="filters.users"
        label="People"
        :items="users"
        multiple
        show-number-of-selection
        hide-details
        dense
        outlined 
        clearable
        :confirmable="false"
      />
      <v-select
        hide-details
        class="input"
        :items="departments"
        multiple
        label="Department"
        item-value="value"
        item-text="name"
        v-model="filters.department"
        outlined
        dense
        clearable
      />
      <v-switch class="input" inset label="Only Active" v-model="filters.onlyActive"></v-switch>
    </filter-search-input>

    <v-data-table
      class="rounded-lg bordered new-back lighten2"
      :items="recessDays"
      :headers="headers"
    >
      <template #[`item.blueniversary`]="{ item }">
        {{ item.blueniversary | formatDate }}
      </template>
      <template #[`item.totalRecessDays`]="{ item }">
        <v-menu
          open-on-hover
          class="menu "
          v-if="item.recessDays.length"
        >
          <template #activator="{ on }">
            <span v-on="on" style="max-width: 60px;">
              {{ item.totalRecessDays }}
            </span>
          </template>
          <v-list
            dense
            style="min-width: 200px;"
            class="new-back"
          >
            <v-list-item>
              <div
                class="d-flex justify-space-between align-center"
                style="min-width: 20px; width: 100%"
              >
                <span
                  class="mr-6"
                >
                  Days
                </span>
                <span>
                  Date
                </span>
              </div>
            </v-list-item>
            <v-list-item
              v-for="(recessDay, ix) in item.recessDays"
              :key="ix"
              class=""
            >
              <div
                class="d-flex justify-space-between align-center"
                style="min-width: 20px; width: 100%"
              >
                <span
                  class="mr-6"
                >
                  {{ recessDay.days }}
                </span>
                <span>
                  {{ recessDay.createdAt | formatDate }}
                </span>
              </div>
            </v-list-item>
          </v-list>
        </v-menu>
        <div v-else>
          {{ item.totalRecessDays }}
        </div>
      </template>
      <template #[`item.totalDaysRequested`]="{ item }">
        <v-menu
          open-on-hover
          class="menu "
          v-if="item.recessRequests.length"
        >
          <template #activator="{ on }">
            <span v-on="on" style="max-width: 60px; ">
              {{ item.totalDaysRequested }}
            </span>
          </template>
          <v-list
            dense
            style="min-width: 200px;"
            class="new-back"
          >
            <v-list-item>
              <div
                class="d-flex justify-space-between align-center"
                style="min-width: 20px; width: 100%"
              >
                <span
                  class="mr-6"
                >
                  Days
                </span>
                <span>
                  Date
                </span>
              </div>
            </v-list-item>
            <v-list-item
              v-for="(request, ix) in item.recessRequests"
              :key="ix"
              class=""
            >
              <div
                class="d-flex justify-space-between align-center"
                style="min-width: 20px; width: 100%"
              >
                <span
                  class="mr-6"
                >
                  {{ request.lines.length }}
                </span>
                <span>
                  {{ request.createdAt | formatDate }}
                </span>
              </div>
            </v-list-item>
          </v-list>
        </v-menu>
        <div v-else>
          {{ item.totalDaysRequested }}
        </div>
      </template>
    </v-data-table>
  </div>
</template>
<script>
export default {
  data() {
    return {
      filters: {
        users: [],
        department: [],
        onlyActive: true,
      },
      users: [],
      items: [],
      headers: [
        { text: 'BlueTag', value: 'blueTag' },
        { text: 'Name', value: 'fullName' },
        { text: 'Bluenniversary', value: 'blueniversary' },
        { text: 'Recess Days', value: 'totalRecessDays', align: 'right' },
        { text: 'Requested Days', value: 'totalDaysRequested', align: 'right' },
        { text: 'Available Days', value: 'availableDays', align: 'right' },
      ],
      departments: [
        { value: 1, name: 'Administration' },
        { value: 5, name: 'Capability Management' },
        { value: 2, name: 'Commercial' },
        { value: 0, name: 'Dev Center' },
        { value: 4, name: 'Marketing' },
        { value: 3, name: 'Talent Acquisition' },
      ],
    }
  },
  computed: {
    recessDays() {
      let items = JSON.parse(JSON.stringify(this.items))
      if(this.filters.users.length) {
        items = items.filter(x => this.filters.users.indexOf(x.id) > -1)
      }
      if(this.filters.department.length){
        items = items.filter(i => this.filters.department.includes(i.department))
      }
      if(this.filters.onlyActive){
        items = items.filter(i => i.active)
      }

      items = this.applySearch(this.filters.search, items, ['fullName', 'blueTag'])

      return items
    },
    appliedFilters() { return this.countActiveFilters(this.filters, ['search']) },
  },
  mounted() {
    document.title = 'Vacations'
    this.$q.log(7, 50, 3, 'NA')
    this.init()
  },
  methods: {
    init() {
      this.$http.get('api/recess/users/days')
        .then(res => {
          this.users = res.data
          this.items = res.data.map(this.formatUserWithDays)
        })
    },
    formatUserWithDays(user) {
      let totalRecessDays = user.recessDays.reduce((acc, current) => acc + current.days, 0)
      let totalDaysRequested = user.recessRequests.reduce((acc, current) => acc + current.lines.length, 0)
      let availableDays = totalRecessDays - totalDaysRequested
      return {
        ...user,
        totalRecessDays,
        totalDaysRequested,
        availableDays,
      }
    }
  },
}
</script>
<style scoped lang="scss">
.v-menu__content {
  border-radius: 12px;
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
  border: 2px solid var(--v-lightgray-lighten) !important;
}
.input{
  margin-left: 15px;
  margin-top: 0;
}
</style>