<template>
  <!-- v-bind="$attrs" -->
  <v-card
    v-on="$listeners" v-bind="$attrs" flat
    class="rounded-lg member-containr align-start bordered"
    >
    <div
      :class="{
        'core-background': isHeroMember,
        'hero-background': isCoreMember,
        'stakeholder-background': isStakeholder
      }"
      class="cover-image"
    >
    </div>
    <v-card-text class="content mx-1 fill-height pt-0 px-5 pb-5">
      <div class="d-flex flex-column fill-height">
        <div class="user-info-container mt-12">
          <user-avatar
            hide-loading
            :userId="item.user ? item.user.id : item.assignment.userId"
            :size="60"
          />
          <div class="name ml-3 mt-5" style="width: 155px">
            <b class="text--text" style="font-size: 14px">{{ setMaxText(item.user ? item.user.blueTag : item.assignment.userBlueTag, 18) }}</b>
            <div class="caption" style="font-size: 12px">{{ setMaxText(item.user ? item.user.fullName : item.assignment.userFullName, 18)}}</div>
          </div>
        </div>
        <v-divider class="my-3"/>
        <h3 class="text--text mb-3">
          {{
            item.assignment ? item.assignment.billableLineRole :
              item.role !== undefined ? roles.find(x => x.value == item.role).name :
              ''
          }}
        </h3>
        <div class="badge-container badge-small mb-3">
          <div class="badge-container-small mr-1" v-for="(badge, ix) in badges" :key="ix">
            <v-img 
              class="inline-block mr-0"
              :src="getBackendUrl(`/api/badge/image/${badge.badgeId}`)"
              @error="badge.badgeImgUrl = profilePicFailover"
              height="30"
              width="30"
              contain
            />
          </div>
        </div>
        <v-spacer></v-spacer>
        <div class="caption" style="justify-self: flex-end">
          <div v-if="isCoreMember" class="mb-2">Start Date: {{ item.assignment ? formatDate(item.assignment.startDate) : formatDate(item.user.blueniversary) }}</div> <!-- change for start date on team members -->
          <div v-if="isCoreMember && (item.customerPerception || item.customerPerception === 0)">
            Customer perception:
            <v-icon
              small
              :color="semaphoreColors[item.customerPerception].color"
            >
              {{ semaphoreColors[item.customerPerception].icon }}
            </v-icon>
          </div>
        </div>
      </div>
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  props: {
    item: { type: Object, default: () => ({}) },
    startDate: { type: String, default: '' },
    isHeroMember: { type: Boolean, default: false },
    isStakeholder: { type: Boolean, default: false },
    isCoreMember: { type: Boolean, default: false },
  },
  data: () => ({
    semaphoreColors: [
			{ color: 'error', icon: 'mdi-emoticon-sad-outline' },
			{ color: '#fdc37f', icon: 'mdi-emoticon-neutral-outline' },
			{ color: 'success', icon: 'mdi-emoticon-excited-outline' }
		],
    profilePicFailover: 'https://cdn.vuetifyjs.com/images/parallax/material2.jpg',
    roles: [
      { name: 'Business Analyst', value: 0 },
      { name: 'Delivery Manager', value: 1 },
      { name: 'Developer ', value: 2 },
      { name: 'Key User', value: 3 },
      { name: 'Product Owner', value: 4 },
      { name: 'Program Manager', value: 5 },
      { name: 'Project Manager', value: 6 },
      { name: 'QA', value: 7 },
      { name: 'Solutions Architect', value: 8 },
      { name: 'Stakeholder', value: 9 },
      { name: 'UX/UI', value: 10 },
      { name: 'Other', value: 11 },
    ],
  }),
  watch: {},
  computed: {
    badges() {
      if(this.item.user?.badges) {
        return this.item.user.badges
      }
      else if(this.item.assignment?.user?.badges) {
        return this.item.assignment.user.badges
      }
      return []
    }
  },
  methods: {}
}
</script>

<style lang="scss" scoped>
.cover-image {
  position: absolute;
  z-index: 0;
  width: 100%;
  border-bottom-left-radius: 0px !important;
  border-bottom-right-radius: 0px !important;
  height: 60px;
}
.core-background {
  background: linear-gradient(96.39deg, #0371FD 0%, #06EBFF 100%);
}
.hero-background {
  background: linear-gradient(96.39deg, #F74187 0%, #9E1F98 100%);
}
.stakeholder-background {
  background: linear-gradient(96.39deg, #FCCD54 0%, #F66C1E 100%);
}
.member-containr {
  width: 273px;
  height: 100%;
}
.user-info-container {
  display: flex;

  .name{
    display: flex;
    flex-direction: column;
    width: 100%;
    div{
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      position: relative;
    }
  }
}
.badge-container {
  display: flex;
  max-width: 240px;
  gap: 10px 0px;
  flex-direction: row;
  justify-content: flex-start;
  flex-wrap: wrap;
}
.badge-small {
  width: 100% !important;
  box-sizing: border-box;
}
.badge-container-small {
  display: flex;
  max-width: 50px;
}
</style>