var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-data-table',_vm._g(_vm._b({attrs:{"checkbox-color":"primary","headers":_vm.shownHeaders,"items":_vm.items},on:{"input":function($event){return _vm.$emit('input', $event)}},scopedSlots:_vm._u([{key:"item.code",fn:function(ref){
var item = ref.item;
return [_c('b',[_vm._v(_vm._s(item.code || item.billableItemCode))])]}},{key:"item.billableCode",fn:function(ref){
var item = ref.item;
return [_c('b',[_vm._v(_vm._s(item.billableCode || item.billableItemBillableCode))])]}},{key:"item.startDate",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("formatDate")((item.startDate || item.billableItemStartDate)))+" ")]}},{key:"item.endDate",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("formatDate")((item.endDate || item.billableItemEndDate)))+" ")]}},{key:"item.hours",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("number")((item.hours || item.billableItemHours),2))+" ")]}},{key:"item.total",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.currencyFormat(item.total || item.billableItemTotal))+" ")]}},{key:"item.invoiced",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.currencyFormat(item.invoiced || item.billableItemInvoiced))+" ")]}},{key:"item.adjustmentAmount",fn:function(ref){
var item = ref.item;
return [(
				_vm.showAdjustmentButton &&
				!item.adjustmentAmount &&
				!item.billableItemAdjustmentAmount &&
				(_vm.adjustmentNeeded || item.billableItemPending >= 0.01)
			)?_c('v-btn',{attrs:{"color":"primary","x-small":""},on:{"click":function($event){$event.stopPropagation();return _vm.$emit('adjust', item)}}},[_vm._v(" Adjust ")]):_c('span',[_vm._v(" "+_vm._s((item.adjustmentAmount || item.billableItemAdjustmentAmount) ? _vm.currencyFormat(item.adjustmentAmount || item.billableItemAdjustmentAmount) : '-')+" ")])]}},{key:"item.pending",fn:function(ref){
			var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.currencyFormat(item.pending || item.billableItemPending))+" ")]}},{key:"item.currency",fn:function(ref){
			var item = ref.item;
return [_c('v-avatar',{staticClass:"mr-1",attrs:{"size":"24"}},[_c('v-img',{attrs:{"src":require(("../assets/currencies/" + (item.billableCurrency.toLowerCase()) + ".png"))}})],1),_c('b',[_vm._v(" "+_vm._s(item.billableCurrency)+" ")])]}},{key:"item.isClosed",fn:function(ref){
			var item = ref.item;
return [_c('check-switch',{attrs:{"value":item.isClosed}})]}},{key:"item.status",fn:function(ref){
			var item = ref.item;
return [_c('v-btn',{staticStyle:{"border-radius":"20px"},style:({
				background: _vm.$vuetify.theme.isDark ? '' : item.statusLightColor
			}),attrs:{"color":item.statusColor,"outlined":"","small":""}},[_vm._v(_vm._s(item.statusDisplayName))])]}},{key:"item.amount",fn:function(props){return [_vm._t("item.amount",null,null,props)]}}],null,true),model:{value:(_vm.innerValue),callback:function ($$v) {_vm.innerValue=$$v},expression:"innerValue"}},'v-data-table',_vm.$attrs,false),_vm.$listeners))}
var staticRenderFns = []

export { render, staticRenderFns }