<template>
	<div class="pa-5 new-back fill-height">
		<filter-search-input
			v-model="filters.search" outlined dense
		>
			<v-autocomplete
				v-model="filters.interface" :items="permissions"
				label="Interface" item-text="name" item-value="value"
				outlined dense clearable hide-details
				@change="filters.accessLevels = []"
			/>
			<q-user-autocomplete
				v-model="filters.users" :items="users"
				label="Users"
				outlined dense multiple clearable hide-details
				:confirmable="false" :open-user-card="false"
			/>
			<v-select
				v-if="filters.interface !== null && filters.interface !== undefined"
				v-model="filters.accessLevels"
				:items="shownAccessLevels"
				label="Access Level" item-text="text" item-value="value"
				outlined dense clearable multiple hide-details
			/>
		</filter-search-input>

		<data-table
			:headers="headers" :items="shownItems"
			item-key="_key" multi-sort
		>
			<template #[`item.userBlueTag`]="{ item }">
				<div class="d-flex align-center my-1" style="gap: 10px">
					<user-avatar :user-id="item.userId" :size="32" :show-card="false"/>
					<div>
						<b>{{ item.userBlueTag }}</b>
						<br>
						<span>{{ item.userFullName }}</span>
					</div>
				</div>
			</template>
			<template #[`item.userPermission`]="{ item }">
				<div :set="pem = permissions.find(x => x.value == item.permissionType)">
					<access-level-select
						v-if="pem"
						:value="item.userPermission"
						:type="pem.levelTypes"
						disabled
					/>
					<i v-else class="caption">Obsolete</i>
				</div>
			</template>
			<template #[`item.groupPermission`]="{ item }">
				<div :set="pem = permissions.find(x => x.value == item.permissionType)">
					<access-level-select
						v-if="pem"
						:value="item.groupPermission"
						:type="pem.levelTypes"
						disabled
					/>
					<i v-else class="caption">Obsolete</i>
				</div>
			</template>
			<template #[`item.permission`]="{ item }">
				<div :set="pem = permissions.find(x => x.value == item.permissionType)">
					<access-level-select
						v-if="pem"
						:value="item.permission"
						:type="pem.levelTypes"
						disabled
					/>
					<i v-else class="caption">Obsolete</i>
				</div>
			</template>
		</data-table>

		<div class="my-10">
			<h2>
				<v-icon color="primary" large>mdi-shield-crown-outline</v-icon>
				Super Admins
				<v-icon color="primary" large>mdi-shield-crown-outline</v-icon>
			</h2>
			<div class="d-flex flex-wrap mt-2" style="gap: 15px">
				<div
					v-for="user in superAdmins" :key="user.id"
					class="d-flex align-center justify-center pa-3 bordered darken elevation-1 rounded-lg"
					style="gap: 10px; width: 200px; position: relative"
				>
					<div class="gradient"></div>
					<user-avatar :user-id="user.id" :show-card="false"/>
					<div class="text-center">
						<b>{{ user.blueTag }}</b>
						<br>
						<span class="body-2">{{ user.fullName }}</span>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import AccessLevelSelect from '../../components/AccessLevelSelect'
import permissionsJSON from "../../json/permissions.json"

export default {
	components: {
		AccessLevelSelect,
	},
	data: () => ({
		headers: [
			{ text: 'Interface', value: 'permissionTypeName' },
			{ text: 'BlueTag', value: 'userBlueTag' },
			{ text: 'User Group Permission', value: 'groupPermission' },
			{ text: 'User Permission', value: 'userPermission' },
			{ text: 'Permission', value: 'permission' },
		],
		items: [],
		permissions: permissionsJSON.permissions
			.map(x => x.children ? x.children : [x])
			.flat(),
		filters: {
			search: '',
			users: [],
			interface: null,
			accessLevels: [],
		},
		users: [],
		accessLevels: permissionsJSON.accessLevels,
	}),
	created() {
		this.$http.get('/api/permission/report').then(res => {
			this.items = res.data
				.map(x => ({
					...x,
					_key: x.permissionType + '-' + x.userId,
				}))
		}).catch(err => {
			this.$root.error('Error getting permissions report')
			console.log(err)
		})
		this.$http.get('api/user?active=true&onlyInterns=true&usersOnly=true').then(res => {
			this.users = res.data
		}).catch(err => {
			this.$root.error('Error getting users')
			console.log(err)
		})
	},
	computed: {
		shownAccessLevels() {
			const currentPermission = this.permissions.find(x => x.value == this.filters.interface)
			return this.accessLevels[currentPermission.levelTypes].filter(x => !!x.value)
		},
		shownItems() {
			let items = this.items

			if(this.filters.users.length) {
				items = items.filter(x => this.filters.users.includes(x.userId))
			}
			if(this.filters.interface !== null && this.filters.interface !== undefined) {
				items = items.filter(x => x.permissionType == this.filters.interface)
			}
			if(this.filters.accessLevels.length) {
				items = items.filter(x => this.filters.accessLevels.includes(x.permission))
			}

			if(this.filters.search) {
				items = this.applySearch(this.filters.search, items)
			}

			return items
		},
		superAdmins() {
			return this.users.filter(x => x.isSuperAdmin)
		},
	},
}
</script>

<style lang="scss" scoped>
.gradient {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background: linear-gradient(90deg, rgba(131,58,180,.1) 0%, rgba(253,29,29,.1) 50%, rgba(252,176,69,.1) 100%);
	z-index: 1;
}
</style>