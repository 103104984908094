<template>
	<blue-confirm-dialog ref="dialog">
		<template #text>
			<div class="d-flex justify-space-between">
				<div>
					<span>Code:</span>
					<h3>{{ billable.code }}</h3>
				</div>
				<div>
					<span>Currency:</span>
					<div class="d-flex align-center">
						<v-avatar color="indigo" size="28" class="mr-1">
							<v-img
								v-if="billable.currency"
								:src="require(`../../assets/currencies/${billable.currency.toLowerCase()}.png`)"
							/>
						</v-avatar>
						<h3>{{billable.currency}}</h3>
					</div>
				</div>
			</div>
			<div class="my-5 d-flex flex-column" style="gap: 20px">
				<v-divider></v-divider>
				<div class="d-flex flex-column" style="gap: 5px">
					<b>Contracted Hours vs. Billable Executed Hours</b>
					<billable-percentage-bar
						:primary-amount="billable.executedBillableHours"
						:objective="billable.contractedHours"
						:amount-formatter="m => m.toFixed(0) + ' hrs'"
					/>
				</div>
				<v-divider></v-divider>
				<div class="d-flex flex-column" style="gap: 5px">
					<b>Billable Executed Hours vs. Billable items</b>
					<billable-percentage-bar
						:primary-amount="billable.billedHours"
						:objective="billable.executedBillableHours"
						:amount-formatter="m => m.toFixed(0) + ' hrs'"
						overflow-error
					/>
				</div>
				<v-divider></v-divider>
				<div class="d-flex flex-column" style="gap: 5px">
					<b>Billable Items vs. Invoices</b>
					<billable-percentage-bar
						:primary-amount="billable.invoiced"
						:objective="billable.billed"
						:amount-formatter="currencyFormat"
					/>
				</div>
				<v-divider></v-divider>
			</div>
			<div class="mb-n5 body-1 text-right">Are you sure you want to {{ isReopen ? 'reopen' : 'close' }} this billable?</div>
		</template>
	</blue-confirm-dialog>
</template>

<script>
import BillablePercentageBar from '../../components/admin-surf/billable/BillablePercentageBar'

export default {
	components: {
		BillablePercentageBar,
	},
	data: () => ({
		billable: {},
		resolve: null,
		isReopen: false,
	}),
	methods: {
		async open(id, isReopen = false){
			this.isReopen = isReopen
			try {
				const res = await this.$http.get(`api/billable/${id}`)
				this.billable = res.data
			} catch(e) {
				console.error(err)
				this.$root.error('Failed to load billable')
			}

			const confirmed = await this.$refs.dialog.open({ title: isReopen ? 'Reopen Billable' : 'Close Billable' })
			if(!confirmed) return

			try {
				const billableRes = await this.$http.post(`api/billable/${id}/${ isReopen ? 'reopen' : 'close' }`)
				return billableRes.data
			} catch(e) {
				console.error(e)
				this.$root.error('Failed to close billable')
			}
		},
	},
}
</script>

<style lang="scss" scoped>

</style>