<template>
	<v-dialog
		:fullscreen="$vuetify.breakpoint.xsOnly" persistent
		v-model="dialog" v-if="dialog" width="900" content-class="dialog-content rounded"
	>
		<v-card v-if="!editionCalendar" color="newDesignBackground">
			<v-btn @click="close" fab icon class="float-right"><v-icon>mdi-close</v-icon></v-btn>
			<v-card-title>
				Calendars
			</v-card-title>
			<v-card-text>
				<div class="bordered rounded-lg overflow-hidden new-back">
					<v-tabs
						v-model="selectedCalendar"
						class="tabs"
						active-class="active-calendar"
						hide-slider
					>
						<v-tab v-for="calendar in calendars" :key="calendar.id" class="tab body-2">
							<div class="dot mr-2" :style="{ background: calendar.color }"></div>
							<v-btn
								@click="editCalendar(calendar)"
								color="secondary" class="mt-n1"
								icon x-small
							>
								<v-icon>mdi-pencil</v-icon>
							</v-btn>
							{{ calendar.name }}
						</v-tab>
					</v-tabs>
					<v-tabs-items v-model="selectedCalendar">
						<v-tab-item v-for="calendar in calendars" :key="calendar.id">
							<v-card color="newDesignBackground">
								<v-card-text class="pt-1">
									<v-data-table
										:headers="headers"
										:items="calendar.holidays"
										hide-default-footer
										disable-pagination
										class="new-back"
										style="max-height: 400px; overflow: scroll"
									>
										<template #[`item.date`]="{ item }">
											{{ item.date | formatDate }}
										</template>
										<template #[`item.description`]="{ item }">
											<div
												class="holiday-description rounded-lg d-flex align-center body-1"
												:style="{
													background: calendar.color,
													color: !isBlackText(calendar.color) ? 'black' : 'white',
												}"
											>
												{{ item.description }}
											</div>
										</template>
									</v-data-table>
								</v-card-text>
							</v-card>
						</v-tab-item>
					</v-tabs-items>
				</div>
				<v-btn @click="createNew" color="primary" class="mt-3" fab small depressed>
					<v-icon>mdi-plus</v-icon>
				</v-btn>
			</v-card-text>
		</v-card>

		<v-card v-else color="newDesignBackground">
			<v-btn @click="close" fab icon class="float-right"><v-icon>mdi-close</v-icon></v-btn>
			<v-card-title>
				<v-btn @click="editionCalendar = null" icon color="primary" class="mr-1">
					<v-icon>mdi-chevron-left</v-icon>
				</v-btn>
				{{ editionCalendar.id ? 'Edit calendar' : 'New calendar' }}
			</v-card-title>
			<v-card-text class="pr-º">
				<v-form ref="form">
					<v-row class="ma-0">
						<v-col :cols="6">
							<span>Name of the Calendar</span>
							<v-text-field
								v-model="editionCalendar.name"
								outlined dense hide-details
								:rules="[v => !!v || 'Name is required']"
							/>
						</v-col>
						<v-col :cols="6">
							<span>Color</span>
							<q-color-picker
								v-model="editionCalendar.color"
								outlined dense hide-details
							/>
						</v-col>
						<v-col :cols="6" class="px-0">
							<div class="text-center caption mb-2">Select the dates individually on the calendar</div>
							<v-date-picker
								:value="editionHolidays"
								@input="handleDateInput"
								multiple no-title scrollable :show-current="false"
								width="100%"
							/>
						</v-col>
						<v-col :cols="6">
							<div class="text-center caption mb-2">Your selected calendar dates</div>
							<div
								v-if="sortedHolidays.length"
								class="d-flex flex-column"
								style="gap: 10px; max-height: 400px; overflow-y: scroll; overflow-x:hidden"
							>
								<div
									v-for="holiday in sortedHolidays" :key="holiday.date"
									class="d-flex" style="gap: 10px"
								>
									<div class="inline-block text-center">
										<h5>{{ holiday.date | formatDateCustom('YYYY') }}</h5>
										<h4 class="mt-n1">{{ holiday.date | formatDateCustom('MMM DD') }}</h4>
									</div>
									<v-text-field
										v-model="holiday.description"
										outlined dense hide-details
										:disabled="!holiday.editing"
										:rules="[v => !!v || 'Name is required']"
									/>
									<div class="d-flex align-center" style="gap: 3px">
										<v-icon
											@click="editHoliday(holiday)"
											:disabled="holiday.editing"
										>
											mdi-pencil-outline
										</v-icon>
										<v-icon
											@click="removeHoliday(holiday)"
											color="error"
										>
											mdi-trash-can-outline
										</v-icon>
									</div>
								</div>
							</div>
							<div v-else class="text-center pt-5">
								<v-img src="../../assets/misc/window.png" height="200" contain/>
								<div class="caption mt-5">You haven't selected any calendar dates yet</div>
							</div>
						</v-col>
					</v-row>
				</v-form>
				<div class="d-flex justify-space-between align-end" >
					<v-btn v-if="editionCalendar.id" @click="deleteCalendar" color="error" outlined small>
						Delete calendar
					</v-btn>
					<div v-else></div>
					<v-btn @click="save" color="primary" depressed>
						{{ editionCalendar.id ? 'Save changes' : 'Create calendar' }}
					</v-btn>
				</div>
			</v-card-text>
		</v-card>
	</v-dialog>	
</template>

<script>
import moment from 'moment'

export default {
	data: () => ({
		dialog: false,
		calendars: [],
		selectedCalendar: 0,
		editionCalendar: null,
		resolve: null,

		headers: [
			{ text: 'Date', value: 'date', width: 120 },
			{ text: 'Holiday', value: 'description' },
		],
	}),
	computed: {
		editionHolidays(){
			return this.editionCalendar.holidays.
				map(holiday => moment(holiday.date).format('YYYY-MM-DD')) || []
		},
		sortedHolidays(){
			return this.editionCalendar.holidays
				.sort((a, b) => {
					return moment(a.date).isBefore(b.date) ? -1 : 1
				})
		},
	},
	methods: {
		open(){
			this.init()
			
			return new Promise(resolve => {
				this.resolve = resolve
			})
		},
		init(){
			this.editionCalendar = null
			this.dialog = true
			this.$http.get('api/admin/calendars')
				.then(res => {
					this.calendars = res.data
				})
				.catch(err => {
					console.error(err)
					window.getApp.error('Cannot get calendars')
				})
		},
		close(){
			this.dialog = false
			this.resolve()
		},
		createNew(){
			this.editionCalendar = {
				holidays: [],
			}
		},
		editCalendar(calendar){
			this.editionCalendar = structuredClone(calendar)
			this.editionCalendar.holidays
				.forEach(x => x.editing = false)
		},
		handleDateInput(e){
			if(e.length > this.editionHolidays.length){
				const newDates = e.filter(d => !this.editionHolidays.includes(d))

				this.editionCalendar.holidays.push(...newDates.map(date => ({
					date,
					description: '',
					editing: true,
				})))
			}
		},
		editHoliday(holiday){
			this.$set(holiday, 'editing', true)
			this.$forceUpdate()
		},
		removeHoliday(holiday){
			this.$openConfirmDialog({
				title: 'Delete holiday',
				description: 'Are you sure you want to delete this holiday?',
			})
				.then(res => {
					if(!res) return
					this.editionCalendar.holidays = this.editionCalendar.holidays
						.filter(x => x.date !== holiday.date)
				})
		},
		save(){
			if(!this.$refs.form.validate()) return
			if(!this.editionCalendar.holidays.length){
				this.$root.error('You must select at least one holiday')
				return
			}

			this.$http.post('api/admin/calendar', this.editionCalendar)
				.then(res => {
					this.init()
					this.$root.success('Calendar saved')
				})
				.catch(err => {
					console.error(err)
					if (!err.notified) {
						this.$root.error('Cannot save calendar')
					}
				})
		},
		deleteCalendar(){
			this.$openConfirmDialog({
				title: 'Delete calendar',
				description: 'Are you sure you want to delete this calendar?',
			})
				.then(res => {
					if(!res) return
					this.$http.delete(`api/admin/calendar/${this.editionCalendar.id}`)
						.then(res => {
							this.init()
							this.$root.success('Calendar deleted')
						})
						.catch(err => {
							console.error(err)
							this.$root.error('Cannot delete calendar')
						})
				})
		},
	},
}
</script>

<style lang="scss" scoped>
.dot{
	display: inline-block;
	width: 12px; height: 12px;
	border-radius: 50%;
}

.tabs{
	border-bottom: 1px solid var(--v-background-lighten2);
}

.active-calendar{
	background-color: var(--v-background-lighten2) !important;
}

.tab{
	color: var(--v-text-lighten);
	text-transform: none !important;
	background: var(--v-newDesignBackground-base);

	.v-btn{
		display: none;
	}
	&:hover{
		.v-btn{
			display: inline-block;
		}
		.dot{
			display: none;
		}
	}
}

::v-deep .v-tabs-bar{
	background: var(--v-newDesignBackground-base) !important;

}

.holiday-description {
	height: 40px;
	padding: 0 1em;
}

::v-deep {
	table, tbody, tr, td, th {
    border: none !important;
	}
	tr:hover{
		background: transparent !important;
	}
}

</style>