<template>
	<v-text-field
		@change="v => $emit('change', v)"
		:value="value"
		@input="handleInput"
		class="inline-block"
		ref="input"
		:placeholder="placeholder"
		:loading="inputOnly"
	>
		<template v-if="inputOnly" #progress><div/></template>
	</v-text-field>
</template>

<script>
export default {
	props: {
		value: { default: '' },
		minWidth: { type: Number|String, default: 100 },
		maxWidth: { type: Number|String, default: null },
		paddingLeft: { type: Number|String, default: 0 },
		paddingRight: { type: Number|String, default: 0 },
		placeholder: { type: String, default: '' },
		inputOnly: { type: Boolean, default: false },
	},
	mounted(){
		this.resize()
	},
	methods: {
		resize(){
			const textField = this.$refs.input.$el
			const input = textField.querySelector('input')
			const styles = window.getComputedStyle(input)

			input.mirror = document.createElement("div")

			Object.assign(input.mirror.style, {
				position: "absolute",
				top: "0",
				left: "0",
				visibility: "hidden",
				height: "0",
				overflow: "hidden",
				whiteSpace: "pre",
				fontSize: styles.fontSize,
				fontFamily: styles.fontFamily,
				fontWeight: styles.fontWeight,
				fontStyle: styles.fontStyle,
				letterSpacing: styles.letterSpacing,
				textTransform: styles.textTransform
			})
			document.body.appendChild(input.mirror)

			textField.style.maxWidth = this.maxWidth + 'px'
			textField.style.minWidth = this.minWidth + 'px'
			input.style.paddingLeft = this.paddingLeft + 'px'
			input.style.paddingRight = this.paddingRight + 'px'
			input.mirror.style.paddingLeft = this.paddingLeft + 'px'
			input.mirror.style.paddingRight = this.paddingRight + 'px'

			let value = input.value
			value = value || input.placeholder || ""

			while (input.mirror.childNodes.length) {
				input.mirror.removeChild(input.mirror.childNodes[0])
			}
			input.mirror.appendChild(document.createTextNode(value))

			let newWidth = input.mirror.scrollWidth
			if (newWidth != textField.scrollWidth) {
				textField.style.width = `${newWidth}px`;
			}
			input.mirror.remove()
		},
		handleInput(value){
			this.$emit('input', value)
			this.$nextTick(this.resize)
		},
	},
}
</script>

<style lang="scss" scoped>

</style>