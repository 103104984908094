<template>
  <div class="pa-3 fill-height new-back">
    <div class="mx-auto" style="width: 50%">
      <div class="d-flex justify-space-between align-center my-4" style="width: 100%">
        <h2></h2>
        <div
          v-if="permissions.add"
          @click="createNewConfig"
          class="cursor-pointer opacity-on-hover"
        >
          <h4 class="primary-color">
            <v-icon color="primary">mdi-plus</v-icon>
            Add New
          </h4>
        </div>
      </div>
      <v-expansion-panels>
        <v-expansion-panel
          v-for="(config, ix) in items" :key="config.id || ix"
          class="bordered rounded-lg mt-0 mb-5"
        >
          <v-expansion-panel-header
            class="py-0 "
            style="min-height: 80px;"
          >
            <template v-slot:actions>
              <div
                style="width: 100%; height: 100%;"
                class="d-flex justify-space-between align-center"
              >
                <div class="d-flex align-center">
                  <div
                    style="
                      border: 1px solid #E0E0E0;
                      width: 30px;
                      height: 30px;
                    "
                    class="d-flex align-center justify-center rounded mr-3"
                  >
                    <v-icon>$expand</v-icon>
                  </div>
                  <div>
                    <h3>{{ config.name }}</h3>
                    <br>
                    <div>{{ config.description }}</div>
                  </div>
                </div>
                <v-btn
                  v-if="permissions.edit"
                  outlined
                  plain
                  text
                  @click.stop="editConfig(config.id)"
                  class="rounded-lg"
                >
                  Edit
                </v-btn>
              </div>
            </template>
          </v-expansion-panel-header>
          <v-expansion-panel-content class="pt-3 panel" >
            <div class="mb-4 d-flex justify-space-between">
              <h4>Criteria</h4>
              <h4>Vacation days</h4>
            </div>
            <div v-if="!config.lines.length">No items to show</div>
            <div
              class="d-flex justify-space-between mb-2"
              v-for="(line, ix) in config.lines"
              :key="line.id || ix"
            >
              <div>
                <b>
                  {{ line.frequency }}
                  {{ `${configurationLineUnities[line.unity].name}${ line.frequency > 1 ? 's' : '' }`  }}
                </b>
                of seniority
              </div>
              <div>
                {{ line.days }}
              </div>
            </div>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
    </div>
  </div>
</template>

<script>
export default {
  data: () => ({
    items: [],
    configurationLineUnities: [
      { value: 0, name: "Day" },
      { value: 1, name: "Week" },
      { value: 2, name: "Month" },
      { value: 3, name: "Year" },
    ],

    permissions: {
      add: false,
      edit: false,
    },
  }),
  watch: {},
  mounted() {
    document.title = 'Recess Config'

    this.$security.hasRequiredPermission('add')
      .then(res => this.permissions.add = res)
    this.$security.hasRequiredPermission('edit')
      .then(res => this.permissions.edit = res)

    this.init()
  },
  computed: {},
  methods: {
    createNewConfig() {
      this.$router.push("/recess/config/new")
    },
    editConfig(id) {
      this.$router.push(`/recess/config/${id}`)
    },
    init() {
      this.$http.get('api/recess/configurations')
      .then(res => {
          this.items = res.data
        })
        .catch(err => console.error(err))
    },
  },
}
</script>

<style scoped>
::v-deep .v-expansion-panel-header__icon {
  margin-left: 0;
  width: 100%;
}
::v-deep .v-expansion-panel {
  background: var(--v-newDesignBackground-lighten2) !important;
}
</style>