var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"pa-3 new-back fill-height",style:({ width: 'calc(100vw - 206px);' })},[_c('div',{staticClass:"d-flex justify-space-between"},[(!_vm.overviewView)?_c('filter-search-input',{attrs:{"applied-filters":_vm.appliedFilters,"outlined":"","dense":""},model:{value:(_vm.filters.search),callback:function ($$v) {_vm.$set(_vm.filters, "search", $$v)},expression:"filters.search"}},[_c('v-text-field',{staticClass:"input",attrs:{"prefix":"RISK -","label":"ID","placeholder":"ID","outlined":"","dense":"","hide-details":"","clearable":"","maxlength":"10"},model:{value:(_vm.filters.id),callback:function ($$v) {_vm.$set(_vm.filters, "id", $$v)},expression:"filters.id"}}),_c('v-select',{attrs:{"items":_vm.riskTypes,"label":"Status","item-value":"value","item-text":"name","outlined":"","dense":"","hide-details":"","multiple":"","clearable":""},model:{value:(_vm.filters.status),callback:function ($$v) {_vm.$set(_vm.filters, "status", $$v)},expression:"filters.status"}}),_c('v-autocomplete',{attrs:{"label":"Reported by","items":_vm.users,"item-value":"id","item-text":"blueTag","outlined":"","multiple":"","hide-details":"","dense":"","clearable":""},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var item = ref.item;
var attrs = ref.attrs;
return [_c('v-list-item-action',[_c('v-checkbox',{model:{value:(attrs.inputValue),callback:function ($$v) {_vm.$set(attrs, "inputValue", $$v)},expression:"attrs.inputValue"}})],1),_c('v-list-item-content',{staticClass:"pa-0"},[_c('v-list-item',{staticClass:"pa-0"},[_c('v-list-item-avatar',{staticClass:"headline font-weight-light white--text",attrs:{"color":"indigo"}},[_vm._v(" "+_vm._s((item.blueTag || '-').charAt(0))+" ")]),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(_vm._s(item.blueTag))]),_c('v-list-item-subtitle',[_vm._v(_vm._s(item.fullName))])],1)],1)],1)]}},{key:"selection",fn:function(ref){
var attrs = ref.attrs;
var item = ref.item;
var parent = ref.parent;
var selected = ref.selected;
return [(item === Object(item))?_c('v-chip',_vm._b({staticClass:"accent--lighten",attrs:{"input-value":selected,"label":"","small":""}},'v-chip',attrs,false),[_c('span',{staticClass:"pr-2"},[_vm._v(" "+_vm._s(item.blueTag)+" ")]),_c('v-icon',{attrs:{"small":""},on:{"click":function($event){return parent.selectItem(item)}}},[_vm._v("close")])],1):_vm._e()]}}],null,false,3913797325),model:{value:(_vm.filters.reportedBy),callback:function ($$v) {_vm.$set(_vm.filters, "reportedBy", $$v)},expression:"filters.reportedBy"}}),_c('v-autocomplete',{attrs:{"label":"Projects","items":_vm.projects,"item-value":"id","item-text":"name","outlined":"","multiple":"","hide-details":"","dense":"","clearable":""},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
var attrs = ref.attrs;
var item = ref.item;
var parent = ref.parent;
var selected = ref.selected;
return [(item === Object(item))?_c('v-chip',_vm._b({staticClass:"accent--lighten",attrs:{"input-value":selected,"label":"","small":""}},'v-chip',attrs,false),[_c('span',{staticClass:"pr-2"},[_vm._v(" "+_vm._s(item.name)+" ")]),_c('v-icon',{attrs:{"small":""},on:{"click":function($event){return parent.selectItem(item)}}},[_vm._v("close")])],1):_vm._e()]}}],null,false,3388872451),model:{value:(_vm.filters.projects),callback:function ($$v) {_vm.$set(_vm.filters, "projects", $$v)},expression:"filters.projects"}})],1):_vm._e(),_c('v-btn',{directives:[{name:"show",rawName:"v-show",value:(!!_vm.id),expression:"!!id"}],attrs:{"color":"primary","disabled":!_vm.canEditProject && !!_vm.id,"small":"","fab":""},on:{"click":_vm.editFromTable}},[_c('v-icon',{attrs:{"dark":""}},[_vm._v("add")])],1)],1),(!_vm.overviewView)?_c('div',{staticClass:"severity-container"},_vm._l((_vm.severityValue),function(severity,i){return _c('v-col',{key:severity.value[0],attrs:{"cols":"3"}},[_c('v-card',{staticClass:"severity-card",style:({ borderColor: severity.active ? severity.color : '' }),on:{"click":function($event){return _vm.filterBySeverity(i)}}},[_c('v-card-title',{staticClass:"severity-title"},[_c('div',{staticClass:"severity-icon",style:({ background: severity.backColor })},[_c('v-icon',{attrs:{"color":severity.color}},[_vm._v("mdi-alert-outline")])],1),_c('div',[_c('h4',[_vm._v(_vm._s(_vm.getNumberOfRisksBySeverity(severity.value)))]),_c('span',{staticClass:"risk-name"},[_vm._v(" "+_vm._s(severity.name)+" Risk ")])])])],1)],1)}),1):_vm._e(),_c('br'),_c('div',{staticClass:"max-container",class:!_vm.overviewView ? 'mx-auto' : ''},[(!_vm.overviewView)?_c('div',{staticClass:"matrix-container",style:({ opacity: _vm.$vuetify.theme.isDark ? 0.8 : 1 })},_vm._l((_vm.probabilityRows),function(row){return _c('v-row',{key:row,staticClass:"probability-row"},_vm._l((_vm.impactCols),function(col){return _c('v-col',{key:col,staticClass:"impact-col",style:({ background: _vm.getBackground(row, col) })},[(
              _vm.risksBySeverity[col + '-' + (6 - row)] &&
              _vm.risksBySeverity[col + '-' + (6 - row)].length <= 3
            )?_c('div',_vm._l((_vm.risksBySeverity[col + '-' + (6 - row)]),function(risk,ix){return _c('div',{key:ix,staticClass:"risk",style:({
                background: risk.color,
                color: 'white',
                border:
                  risk.id == _vm.selectedRisk.id ||
                  risk.id == _vm.tempSelectedRisk.id
                    ? '2px solid white'
                    : '',
                fontSize:
                  risk.code.length < 12 ? '1em' :
                    risk.code.length < 16 ? '.75em' : '.6em'
              }),on:{"click":function($event){_vm.selectedRisk = risk},"mouseover":function($event){_vm.tempSelectedRisk = risk;
                _vm.scrollTo(risk.id);},"mouseleave":function($event){_vm.tempSelectedRisk = {}}}},[_c('b',[_vm._v(" "+_vm._s(risk.code.split("-")[1])+" "+_vm._s(risk.code.split("-")[2])+" ")])])}),0):(_vm.risksBySeverity[col + '-' + (6 - row)])?_c('div',[_c('v-menu',{attrs:{"bottom":"","origin":"center center","transition":"scale-transition"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                var menu = ref.on;
                var attrs = ref.attrs;
return [_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                var tooltip = ref.on;
return [_c('div',_vm._g(_vm._b({staticClass:"risk risk-center",style:({
                        border: _vm.risksBySeverity[col + '-' + (6 - row)].some(
                          function (risk) { return risk.id == _vm.selectedRisk.id ||
                            risk.id == _vm.tempSelectedRisk.id; }
                        )
                          ? '2px solid white'
                          : '',
                      })},'div',attrs,false),Object.assign({}, tooltip, menu)),[_c('div',{staticClass:"number"},[_vm._v(" "+_vm._s(_vm.risksBySeverity[col + "-" + (6 - row)].length)+" ")])])]}}],null,true)},[_c('span',[_vm._v("Show all")])])]}}],null,true)},[_c('v-list',{staticStyle:{"max-height":"320px","overflow-y":"auto"}},_vm._l((_vm.risksBySeverity[
                    col + '-' + (6 - row)
                  ]),function(risk,ix){return _c('v-list-item',{key:ix,style:({
                    cursor: 'pointer',
                    background:
                      risk.id == _vm.selectedRisk.id ||
                      risk.id == _vm.tempSelectedRisk.id
                        ? '#eee'
                        : '',
                  }),on:{"click":function($event){_vm.selectedRisk = risk}},nativeOn:{"mouseover":function($event){_vm.tempSelectedRisk = risk;
                    _vm.scrollTo(risk.id);},"mouseleave":function($event){_vm.tempSelectedRisk = {}}}},[_c('v-list-item-title',[_c('v-icon',{staticClass:"mr-2",attrs:{"color":risk.color}},[_vm._v(" mdi-alert-circle ")]),_vm._v(" "+_vm._s(risk.code)+" ")],1)],1)}),1)],1)],1):_vm._e()])}),1)}),1):_vm._e(),(!_vm.overviewView)?_c('div',{staticClass:"info-card-container",style:({ opacity: _vm.$vuetify.theme.isDark ? 0.8 : 1 })},[_c('div',{staticClass:"all-risks-container",attrs:{"id":"all-risks-container"}},_vm._l((_vm.shownRisks),function(risk){
                    var _obj;
return _c('div',{key:risk.id,ref:risk.id,refInFor:true,staticClass:"card-container"},[_c('v-card',{class:( _obj = {}, _obj['hover-' +
              _vm.getDarkColor(risk.probability * risk.impact).substr(1)] = true, _obj.selected = risk.id == _vm.selectedRisk.id || risk.id == _vm.tempSelectedRisk.id, _obj.mainContainer = true, _obj ),style:({
              borderColor: _vm.getDarkColor(risk.probability * risk.impact),
              background: risk.hover
                ? _vm.getDarkColor(risk.probability * risk.impact)
                : '',
            }),on:{"click":function($event){return _vm.editFromTable(risk)}},nativeOn:{"mouseover":function($event){_vm.tempSelectedRisk = risk},"mouseleave":function($event){_vm.tempSelectedRisk = {}}}},[_c('v-card-title',[_c('v-avatar',{staticClass:"avatar",attrs:{"color":"grey","size":35}},[_c('v-img',{attrs:{"src":risk.reporter.profilePicSrc},on:{"error":function($event){risk.reporter.profilePicSrc = _vm.profilePicFailover}}})],1)],1),_c('v-card-text',[_c('div',{staticClass:"reporter-name",style:({
                  color: _vm.getPropertyBySeverity(risk.probability, risk.impact, 'color'),
                })},[_vm._v(" "+_vm._s(risk.code.replace("/-/", " "))+" ")]),_c('div',{staticClass:"description",domProps:{"innerHTML":_vm._s(
                  JSON.parse(risk.description.replaceAll('\\n', '<br/>'))
                )}}),_c('div',{staticClass:"created-date"},[_vm._v(" "+_vm._s(_vm.formatDate(risk.createdAt))+" by "+_vm._s(risk.reporter.blueTag)+" ")]),_c('div',{staticClass:"severity",style:({
                  backgroundColor: _vm.getPropertyBySeverity(risk.probability, risk.impact, 'backColor'),
                  color: _vm.getPropertyBySeverity(risk.probability, risk.impact, 'color') 
                })},[_vm._v(" Severity: "+_vm._s(_vm.getSeverityName(risk.probability, risk.impact) || "")+" ")]),_c('div',{staticClass:"icon-container"},[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                var on = ref.on;
                var attrs = ref.attrs;
return [_c('div',_vm._g(_vm._b({staticStyle:{"cursor":"pointer"},on:{"click":function($event){$event.stopPropagation();return _vm.openCreateWorkItem(risk.id)}}},'div',attrs,false),on),[_c('v-icon',{style:({ fontSize: '26px' })},[_vm._v("mdi-clipboard-check-outline")]),_c('span',{staticClass:"workitems-quantity"},[_vm._v(" "+_vm._s(_vm.workItemsDone(_vm.activeWorkItems(risk.workItems)))+"/"+_vm._s(_vm.activeWorkItems(risk.workItems).length)+" ")])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s("Work item" + (risk.workItems.length != 1 ? "s" : "")))])])],1)])],1)],1)}),0)]):_c('v-row',{staticClass:"mb-3 mx-n1"},_vm._l((_vm.shownRisks.filter(function (risk) { return risk.internalViewOnly; })),function(risk,ix){return _c('risk-card',{key:ix,ref:risk.id,refInFor:true,attrs:{"risk":risk,"open":_vm.openCreateWorkItem,"edit":_vm.editFromTable,"width":"330","selectedRisk":_vm.selectedRisk,"tempSelectedRisk":_vm.tempSelectedRisk,"createdRisk":_vm.createdRisk,"selectRiskAsTemporal":function (risk) { return _vm.tempSelectedRisk = risk; },"unselectRiskAsTemporal":function () { return _vm.tempSelectedRisk = {}; },"cols":6,"md":4}})}),1)],1),_c('project-risk-create-dialog',{ref:"projectRiskCreateDialog",attrs:{"overviewView":_vm.overviewView,"projectId":_vm.id,"risks":_vm.risks,"canEditProject":_vm.canEditProject,"isEmployee":_vm.isEmployee}}),_c('risk-work-item-dialog',{ref:"riskWorkItemDialog",attrs:{"projectId":_vm.id,"risks":_vm.risks,"canEditProject":_vm.canEditProject}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }