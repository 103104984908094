let innerHTML = ''

const addListeners = (el, binding, vNode) => {
	setTimeout(() => {
		if(el.innerHTML !== innerHTML){
			innerHTML = el.innerHTML

			const mentions = Array.from(el.querySelectorAll('span[data-type="mention"]'))
			if(el.getAttribute('data-type') === 'mention'){
				mentions.push(el)
			}

			mentions.forEach(mention => {
				mention.addEventListener('mouseover', e => {
					vNode.context.$root.showUserDialog(mention.getAttribute('data-id'), e)
				})
				mention.addEventListener('mouseleave', () => {
					vNode.context.$root.hideUserDialog()
				})
			})
		}
	})
}

export default {
	inserted(el, binding, vNode){
		addListeners(el, binding, vNode)
	},
	componentUpdated(el, binding, vNode){
		addListeners(el, binding, vNode)
	},
}