<template>
  <div :style="{width: '100%'}" v-if="editor">
    <div>
      <div v-if="!hideMenuBar" class="toolbar inline-block new-editor" @mouseenter="canSave = false" @mouseleave="canSave = true">
        <v-btn tabindex="-1" small text class="menubar__button" @click="editor.chain().focus().undo().run()" :disabled="!editor.can().chain().focus().undo().run()">
          <v-icon small>undo</v-icon>
        </v-btn>
        <v-btn tabindex="-1" small text class="menubar__button"  @click="editor.chain().focus().redo().run()" :disabled="!editor.can().chain().focus().redo().run()">
          <v-icon small>redo</v-icon>
        </v-btn>
        <v-btn tabindex="-1" small text class="menubar__button"	 @click="editor.chain().focus().toggleBold().run()" :disabled="!editor.can().chain().focus().toggleBold().run()" :class="{ 'is-active': editor.isActive('bold') }">
          <v-icon small>format_bold</v-icon>
        </v-btn>
        <v-btn tabindex="-1" small text class="menubar__button"	@click="editor.chain().focus().toggleItalic().run()" :disabled="!editor.can().chain().focus().toggleItalic().run()" :class="{ 'is-active': editor.isActive('italic') }">
          <v-icon small>format_italic</v-icon>
        </v-btn>
        <v-btn tabindex="-1" small text class="menubar__button"	@click="editor.chain().focus().toggleStrike().run()" :disabled="!editor.can().chain().focus().toggleStrike().run()" :class="{ 'is-active': editor.isActive('strike') }">
          <v-icon small>format_strikethrough</v-icon>
        </v-btn>
        <v-btn tabindex="-1" small text class="menubar__button"	@click="editor.chain().focus().toggleCode().run()" :disabled="!editor.can().chain().focus().toggleCode().run()" :class="{ 'is-active': editor.isActive('code') }">
          <v-icon small>code</v-icon>
        </v-btn>
        <v-btn tabindex="-1" small text class="menubar__button"	@click="editor.chain().focus().setParagraph().run()" :class="{ 'is-active': editor.isActive('paragraph') }">
          <v-icon small>short_text</v-icon>
        </v-btn>
        <v-btn tabindex="-1" small text class="menubar__button"	@click="editor.chain().focus().toggleHeading({ level: 1 }).run()" :class="{ 'is-active': editor.isActive('heading', { level: 1 }) }">
          H1
        </v-btn>
        <v-btn tabindex="-1" small text class="menubar__button"	@click="editor.chain().focus().toggleHeading({ level: 2 }).run()" :class="{ 'is-active': editor.isActive('heading', { level: 2 }) }">
          H2
        </v-btn>
        <v-btn tabindex="-1" small text class="menubar__button"	@click="editor.chain().focus().toggleHeading({ level: 3 }).run()" :class="{ 'is-active': editor.isActive('heading', { level: 3 }) }">
          H3
        </v-btn>
        <v-btn tabindex="-1" small text class="menubar__button"	@click="editor.chain().focus().toggleBulletList().run()" :class="{ 'is-active': editor.isActive('bulletList') }">
          <v-icon small>format_list_bulleted</v-icon>
        </v-btn>
        <v-btn tabindex="-1" small text class="menubar__button"	@click="editor.chain().focus().toggleOrderedList().run()" :class="{ 'is-active': editor.isActive('orderedList') }">
          <v-icon small>format_list_numbered</v-icon>
        </v-btn>
        <v-btn tabindex="-1" small text class="menubar__button"	@click="editor.chain().focus().toggleBlockquote().run()" :class="{ 'is-active': editor.isActive('blockquote') }">
          <v-icon small>format_quote</v-icon>
        </v-btn>
        <v-btn tabindex="-1" small text class="menubar__button"	@click="editor.chain().focus().toggleTaskList().run()" :class="{ 'is-active': editor.isActive('taskList') }">
          <v-icon small>mdi-format-list-checks</v-icon>
        </v-btn>
        <v-btn tabindex="-1" v-if="!!imageHandler" @click="() => imageHandler(editor.commands)" small text class="menubar__button float-right">
          <v-icon small>mdi-image-plus</v-icon>
        </v-btn>
      </div>
      <editor-content class="editor__content rich-text-format t1" :class="{ rounded: hideMenuBar }" :editor="editor" />
    </div>
    <bubble-menu
      class="bubble-menu"
      :tippy-options="{ duration: 100 }"
      :editor="editor"
    >
      <button @click.stop.prevent="editor.chain().focus().toggleBold().run()" :class="{ 'is-active': editor.isActive('bold') }">
        <v-icon color="white">format_bold</v-icon>
      </button>
      <button @click.stop.prevent="editor.chain().focus().toggleItalic().run()" :class="{ 'is-active': editor.isActive('italic') }">
        <v-icon color="white">format_italic</v-icon>
      </button>
      <button @click.stop.prevent="editor.chain().focus().toggleStrike().run()" type="button" :class="{ 'is-active': editor.isActive('strike') }">
        <v-icon color="white">format_strikethrough</v-icon>
      </button>
    </bubble-menu>
  </div>
</template>

<script>
import { Editor, EditorContent, BubbleMenu } from '@tiptap/vue-2'
import StarterKit from '@tiptap/starter-kit'
import Paragraph from '@tiptap/extension-paragraph'
import Document from '@tiptap/extension-document'
import TaskItem from '@tiptap/extension-task-item'
import TaskList from '@tiptap/extension-task-list'
import Text from '@tiptap/extension-text'
import Image from '@tiptap/extension-image'
import Link from '@tiptap/extension-link'
import Heading from '@tiptap/extension-heading'
import Mention from '@tiptap/extension-mention'
import generateSuggestion from '../plugins/suggestion'

export default {
  components: {
    BubbleMenu,
    EditorContent,
  },
  props: {
    value: { type: String, default: '' },
    handlePaste: { type: Function, default: () => {} },
    imageHandler: { type: Function, default: null },
    autofocus: { type: Boolean, default: false },
    handleEsc: { type: Function, default: () => {} },
    hideMenuBar: { type: Boolean, default: false },
    userSuggestions: { type: Array },
  },
  data() {
    return {
      editor: null,
      canSave: true,
    }
  },
  methods: {
    handleMouseEnter(){
      this.canSave = false
    },
    onPaste(e){
      this.handlePaste(e);
    },
    focus(){
      this.editor.commands.focus()
    },
    init(){
      const extensions = [
        Document,
        StarterKit.configure({
          document: false,
          heading: false,
        }),
        Link.configure({
          linkOnPaste: false,
        }),
        TaskList,
        TaskItem.configure({
          nested: true,
        }),
        Image.configure({
          HTMLAttributes: {
            class: 'rich-image',
          },
        }),
        Heading.extend({
          addKeyboardShortcuts: () => ({
            'Control-Alt-2': () => null,
          }),
        }),
      ]

      if(this.userSuggestions){
        const suggestion = generateSuggestion(this.userSuggestions, this)
        extensions.push(
          Mention.configure({
            suggestion
          })
        )
      }

      this.editor = new Editor({
        content: ``,
        extensions,
        onUpdate: () => {
          this.$emit('input', this.editor?.getHTML())
        },
        onBlur: (e) => {
          if(!this.canSave) return
          this.$emit('change', this.editor?.getHTML())
        },
        onFocus: () =>{
          this.$emit('focus')
        }
      })
      this.$nextTick(() => {
        this.editor.commands.setContent(this.value)
        if(this.autofocus)
          this.editor.commands.focus()
      })
    },
  },
  watch: {
    value(val) {
      if (this.editor?.getHTML() != val) {
        this.editor.commands.setContent(val, false)
      }
    },
    userSuggestions(v, p){
      this.editor = null
      this.$nextTick(this.init)
    },
  },
  mounted() {
    this.init()
  },

  beforeUnmount() {
    this.editor.destroy()
  },
}
</script>

<style lang="scss">
.editor__content.rounded .ProseMirror {
  padding-right: 24px;
}

ul[data-type="taskList"] {
  list-style: none;
  padding: 0;
  p {
    margin: 0;
    word-break: break-word;
  }
  li {
    display: flex;
    align-items: flex-start !important;
    > label {
      flex: 0 0 auto;
      margin-right: 0.5rem;
      user-select: none;
    }
    > div {
      flex: 1 1 auto;
    }
  }
}
.rich-image {
  max-width: 100%;
  min-width: 100%;
}
.editor {
  border-radius: 3px;
  padding: 0px;
}
.editor__content {
  & .ProseMirror {
    border-radius: 0 0 5px 5px;
    color: var(--v-text-base);
    min-height: 90px !important;
    overflow-y: auto;
    padding: 4px;
    height: auto;
    word-break: break-word;
    &:focus {
      outline-width: 0;
      outline: none;
    }
  }
}

.rounded .ProseMirror {
  border-radius: 5px !important;
}

.menubar__button {
  padding-left: 0px !important;
  padding-right: 0px !important;
  min-width: 28px !important;
}
.new-editor {
  width: 100%;
  padding: 10px 0;
    border-radius: 7px 7px 0 0;
    border: 1px solid;
}
.t1 {
  & .ProseMirror {
    border: 1px solid;
  }
}
.new-border {
  border: 1px solid
}
.bubble-menu {
  display: flex;
  background-color: #0D0D0D;
  padding: 0.2rem;
  border-radius: 0.5rem;

  button {
    border: none;
    background: none;
    color: #FFF;
    font-size: 0.85rem;
    font-weight: 500;
    padding: 0 0.2rem;
    opacity: 0.6;

    &:hover,
    &.is-active {
      opacity: 1;
    }
  }
}
.ProseMirror .is-empty:nth-child(-n+2)::before {
  content: attr(data-placeholder);
  float: left;
  color: #ced4da;
  pointer-events: none;
  height: 0;
}
span[data-type="mention"] {
  border: 1px solid var(--v-text-base);
  border-radius: 0.4rem;
  padding: 0.2rem 0.3rem;
  box-decoration-break: clone;
}
</style>