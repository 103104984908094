<template>
	<v-dialog
		:fullscreen="$vuetify.breakpoint.xsOnly" persistent
		v-model="dialog" v-if="dialog" width="600" content-class="dialog-content"
	>
		<div class="d-none">
      <input type="file" v-if="dialog" id="imageUploader" accept="image/*" ref="imageUploader" v-on:change="handleImageUpload"/>
		</div>
		<v-card class="pb-1">
			<v-card-title>
				New Badge
			</v-card-title>
			<v-card-text>
				<div class="form">
					<v-form v-model="validForm" style="width: 360px">
						<b> Name* : </b>
						<v-text-field
							class="mb-3"
							hide-details outlined
							name="name" type="text"
							v-model="badge.name"
							:rules="[v => (!!v && !!v.trim()) || 'Required']"
						></v-text-field>
						<b> Description: </b>
						<v-textarea
							outlined rows="5"
							name="name" type="text"
							class="text-area"
							hide-details
							v-model="badge.description"
						></v-textarea>
					</v-form>
					<div>
						<b>
							Icon* :
						</b>
						<div class="image-container mb-2" @click="openImageUpload">
							<v-icon v-if="!badge.imgUrl" size="80" color="accent">mdi-shield-star-outline</v-icon>
							<v-img v-else class="ma-1 cursor-pointer" height="145" contain :src="formatHttp(badge.imgUrl)"></v-img>
						</div>

						<b>Points*: </b>
						<v-text-field
							class="mb-3" style="width: 80px"
							hide-details outlined dense
							name="name" type="number"
							v-model.trim="badge.points"
							:rules="[v => !!v || 'Required']"
						/>
					</div>
				</div>
			</v-card-text>

			<v-card-actions class="px-6">
				<v-btn v-if="!!badge.id" color="error" @click="deleteBadge">
					Delete
				</v-btn>
				<v-spacer></v-spacer>
				<v-btn color="primary" text @click="close">
					Cancel
				</v-btn>
				<v-btn color="primary" @click="save" :disabled="!validForm || (!newImage && !badge.imgUrl)">
					Save
				</v-btn>
			</v-card-actions>
		</v-card>
	</v-dialog>
</template>

<script>
import moment from 'moment'
export default {
	data(){
		return {
			dialog: false,
			resolve: null,
			reject: null,
			badge: {
				name: '',
				description: '',
				users: [],
			},
			newImage: null,
			validForm: false,
			headersUsers: [
				{ text: "BlueTag", value: "applicationUserBlueTag" },
				{ text: "Name", value: "applicationUserName" },
				{ text: "Date", value: "dateEarned" },
			],
			users: [],
		}
	},
	methods: {
		close(){
			this.reject()
			this.dialog = false
		},
		open(badge){
			this.badge = badge
			this.newImage = null
			this.dialog = true

			return new Promise((resolve, reject) => {
				this.resolve = resolve
				this.reject = reject
			})
		},
		formatDate: (date) => moment(date).format('YYYY-MM-DD'),
		handleImageUpload(){
			if(this.$refs.imageUploader.files.length){
				this.newImage = this.$refs.imageUploader.files[0]
				this.badge.imgUrl = URL.createObjectURL(this.newImage)
			}
		},
		openImageUpload(){
			this.$refs.imageUploader.click()
		},
		deleteBadge(){
			this.$vuetifyConfirmDialog.open(
				'Delete Badge Confirmation.',
				'Are you sure you want to delete the badge and the people related to it?',
				"Cancel",
				"Confirm"
			).then(res => {
				if(!res)
					return
				this.$http.delete(`/api/badge/${this.badge.id}`)
					.then(res => {
						window.getApp.success('Badge deleted successfully')
        		this.resolve(null)
						this.dialog = false
					})
					.catch(err => {
						console.log(err)
						window.getApp.error('Cannot delete badge')
					})
			})
		},
		save(){
			this.$http.post(`/api/badge`, this.badge).then(res => {
				// Upload image
				if(!this.newImage){
					window.getApp.success("Badge saved.")
					this.resolve(res.data)
					this.dialog = false
					return
				}
				let formData = new FormData()
				formData.append('file', this.newImage)
				this.$http.put(`/api/badge/image/${res.data.id}`,
					formData,
					{
						headers: {
							'Content-Type': 'multipart/form-data'
						}
					})
					.then(() => {
						window.getApp.success("Badge saved.")
					})
					.catch(err => {
						window.getApp.error("Error while saving image.")
					})
					.finally(() => {
						this.resolve(res.data)
						this.dialog = false
					})
			})
			.catch(err => {
				window.getApp.error("Cannot save badge.")
			})
		},
	},
}
</script>

<style lang="scss" scoped>
.inline-block {
	display: inline-block;
}
.form {
	display: flex;
	justify-content: space-between;

	.image-container{
		display: flex;
		cursor: pointer;
		align-items: center;
		justify-content: center;
		border: 2px solid #aaa;
		border-radius: 5px;
		height: 155px;
		width: 155px;
	}
}

::v-deep .text-area .v-text-field__slot{
	margin-right: -10px !important;
}

::v-deep textarea::-webkit-scrollbar {
	width: 5px;
	margin-right: 2px;
	height: 5px;
}
::v-deep textarea::-webkit-scrollbar-thumb{
	background: var(--v-background-lighten2);
}
</style>