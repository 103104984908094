<template>
	<div class="px-5">
		<div class="d-flex align-center mt-8 mb-3">
			<h3>Billable Items</h3>
		</div>
		<div class="mb-5">
			<v-data-table
				:headers="billableItemHeaders"
				:items="billableItems"
				hide-default-footer disable-pagination show-expand single-expand
				class="mt-2" :item-class="() => 'cursor-pointer'"
				@click:row="(item, data) => data.expand(!data.isExpanded)"
			>
				<template #[`item.data-table-expand`]="{ isExpanded }">
					<div
						class="d-flex align-center bordered darken rounded"
					>
						<v-icon>mdi-chevron-{{ isExpanded ? 'up' : 'down' }}</v-icon>
					</div>
				</template>
				<template #[`item.startDate`]="{ item }">
					{{ item.startDate | formatDate }}
				</template>
				<template #[`item.endDate`]="{ item }">
					{{ item.endDate | formatDate }}
				</template>
				<template #[`item.status`]="{ item }">
					<v-btn
						:color="item.statusColor"
						outlined small style="border-radius: 20px"
						:style="{
							background: $vuetify.theme.isDark ? '' : item.statusLightColor
						}"
					>{{item.statusDisplayName}}</v-btn>
				</template>

				<template #expanded-item="{ headers, item }">
					<td :colspan="headers.length">
						<div class="py-8 px-3">
							<v-data-table
								:headers="billableItemLineHeaders" :items="item.billableItemLines"
								hide-default-footer disable-pagination disable-sort
								class="bordered rounded-lg background--lighten"
							>
								<template #[`item.startDate`]="{ item: billableItemLine }">
									{{ (billableItemLine.assignmentStartDate || billableItemLine.billableItemStartDate) | formatDate }}
								</template>
								<template #[`item.endDate`]="{ item: billableItemLine }">
									{{ (billableItemLine.assignmentEndDate || billableItemLine.billableItemEndDate) | formatDate }}
								</template>
								<template #[`item.workingDays`]="{ item: billableItemLine }">
									{{ billableItemLine.hours / 8 }}
								</template>
							</v-data-table>
						</div>
					</td>
				</template>
			</v-data-table>
		</div>
	</div>
</template>

<script>
export default {
	props: ['id'],
	data: () => ({
    billableItems: [],
		billableItemHeaders: [
      { text: 'Work force', value: 'code' },
      { text: 'Start date', value: 'startDate' },
      { text: 'End date', value: 'endDate' },
      { text: 'Status', value: 'status', align: 'center' },
      { text: 'Total hours', value: 'hours', align: 'center' },
    ],
    billableItemLineHeaders: [
      { text: 'Team member', value: 'userBlueTag' },
      { text: 'Start date', value: 'startDate' },
      { text: 'End date', value: 'endDate' },
      { text: 'Working days', value: 'workingDays', align: 'center' },
      { text: 'Total hours', value: 'hours', align: 'center' },
    ],
	}),
	mounted(){
    this.$q.log(1, 4, 3, this.id, this.$parent.item.id)
		this.$http.get(`api/billableItem?projectId=${this.id}`)
			.then(res => {
				this.billableItems = res.data
			})
			.catch(err => {
				console.error(err)
				this.$root.error('Cannot get billable items')
			})
	},
}
</script>

<style lang="scss" scoped>
::v-deep .v-data-table__expanded__content{
  box-shadow: none !important;
}
</style>