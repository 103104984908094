<template>
	<blue-confirm-dialog ref="deleteDialog">
		<template #text>
			<v-expansion-panels :value="0">
				<v-expansion-panel v-for="(item, ix) in assignments" :key="ix">
					<v-expansion-panel-header expand-icon="">
						<div>
							<v-tooltip top>
								<template #activator="{ on }">
									<v-icon v-if="item.billableItems.length" v-on="on" color="error">mdi-alert-circle</v-icon>
									<v-icon v-else v-on="on" color="success">mdi-check-circle</v-icon>
								</template>
								<span v-if="item.billableItems.length">Cannot delete this assignment</span>
								<span v-else>This assignment will be deleted</span>
							</v-tooltip>
							<b> {{ item.assignment.customerProject.name }} </b>
							- {{item.assignment.user.blueTag}}
						</div>
						{{item.assignment.startDate | formatDate}}
						-
						{{item.assignment.endDate | formatDate}}
					</v-expansion-panel-header>
					<v-expansion-panel-content>
						<div>
							<v-alert v-if="!item.billableItems.length" text type="success">This assignment will be deleted</v-alert>
							<template v-else>
								<v-alert text type="error">This assignment will not be deleted</v-alert>
								Cancel or edit required billable items
								<div class="mt-1" style="display: grid; grid-template-columns: auto auto auto; gap: 15px; padding: 0 5px">
									<div
										v-for="(billableItem, ix) in item.billableItems" :key="ix"
										class="elevation-2 py-1 px-2 rounded background--lighten2 cursor-pointer"
										@click="$q.openNewTab(`/admin/billableItem/${billableItem.id}`)"
									>
										<div>
											<span style="font-size: .9em">
												{{billableItem.startDate | formatDate}} - {{billableItem.endDate | formatDate}}
											</span>
										</div>
										<span style="font-size: 1.2em">
											{{billableItem.code}}
										</span>
									</div>
								</div>
							</template>
						</div>
					</v-expansion-panel-content>
				</v-expansion-panel>
			</v-expansion-panels>
		</template>
	</blue-confirm-dialog>
</template>

<script>
export default {
	data: () => ({
		resolve: () => {},
		assignments: [],
	}),
	methods: {
		open(assignments){
			this.assignments = []
			this.$http.post('api/Assignment/CanDelete/', assignments.filter(a => a.id))
					.then(res => {
						this.assignments = res.data
						this.assignments.forEach(a => {
							this.$set(a, 'canDelete', !a.billableItems.length)
							this.$set(a, 'id', a.assignment.id)
						})
					})
					.catch(err => {
						console.log(err)
						this.$root.error('Cannot delete assignments')
						return new Promise(resolve => resolve({confirmed: false, data: []}))
					})
			this.$refs.deleteDialog
				.open({
					title: `Delete assignment${assignments.length != 1 ? 's' : ''}`,
					description: '',
					width: 700,
				})
				.then(res => this.resolve({confirmed: res, data: this.assignments}))
			
			return new Promise(resolve => this.resolve = resolve)
		},
	}
}
</script>

<style lang="scss" scoped>
</style>