var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.dialog)?_c('v-dialog',{attrs:{"fullscreen":_vm.$vuetify.breakpoint.xsOnly,"scrollable":"","persistent":"","width":680},on:{"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"esc",27,$event.key,["Esc","Escape"])){ return null; }return _vm.cancel.apply(null, arguments)},"click:outside":_vm.cancel},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',{staticClass:"card"},[_c('div',{staticClass:"d-flex justify-space-between mr-7 mt-5"},[_c('div',[_c('q-date-picker',{staticClass:"inline-block ml-6 data-picker-width",attrs:{"label":"Start date","outlined":"","inner":"","dense":"","hide-details":""},model:{value:(_vm.startDate),callback:function ($$v) {_vm.startDate=$$v},expression:"startDate"}}),_c('q-date-picker',{staticClass:"inline-block ml-4 data-picker-width",attrs:{"label":"End date","outlined":"","inner":"","dense":"","hide-details":""},model:{value:(_vm.endDate),callback:function ($$v) {_vm.endDate=$$v},expression:"endDate"}})],1),_c('excel-btn',{on:{"click":_vm.downloadExcel}})],1),_c('v-card-title',[_c('div',{staticClass:"cycle-time"},[_c('div',[_vm._v(" Lead Time: "),_c('span',{style:({
              color: 
                _vm.goal.leadTimeGoal ?
                  ( _vm.toMinutes(_vm.goal.leadTimeGoal, _vm.goal.leadTimeGoalUnit, _vm.usingWorkingHours) < _vm.leadTime ? 'salmon' : 'lightgreen' )
                  : 'text'
            	})},[_vm._v(" "+_vm._s(!_vm.selectedUnit ? _vm.goal.leadTimeGoal ? _vm.formatTimeByUnit(_vm.leadTime, _vm.goal.leadTimeGoalUnit, _vm.usingWorkingHours) : _vm.getIndicatorTime(_vm.leadTime, false, _vm.usingWorkingHours, _vm.usingWorkingHours) : _vm.formatTime(_vm.leadTime))+" ")]),(!!_vm.goal.leadTimeGoal)?_c('span',[_vm._v(" vs. "),_c('span',[_vm._v(" "+_vm._s(!_vm.selectedUnit ? _vm.formatTimeByUnit(_vm.toMinutes(_vm.goal.leadTimeGoal, _vm.goal.leadTimeGoalUnit, _vm.usingWorkingHours), _vm.goal.leadTimeGoalUnit, _vm.usingWorkingHours) : _vm.formatTime(_vm.toMinutes(_vm.goal.leadTimeGoal, _vm.goal.leadTimeGoalUnit, _vm.usingWorkingHours)))+" ")])]):_vm._e()])]),_c('div',{staticClass:"units-selector"},[_c('v-btn',{staticStyle:{"text-transform":"none"},attrs:{"color":_vm.selectedUnit ? 'primary' : 'secondary'},on:{"click":function($event){_vm.selectedUnit++; _vm.selectedUnit%=_vm.units.length}}},[_vm._v(" "+_vm._s(_vm.units[_vm.selectedUnit].name)+" ")]),(_vm.activeSprint.id)?_c('v-btn',{staticClass:"ml-3",staticStyle:{"text-transform":"none"},attrs:{"color":"primary"},on:{"click":_vm.showSprintLeadTime}},[_vm._v(" Current Sprint ")]):_vm._e()],1),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
            	var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"close",attrs:{"text":"","small":""},on:{"click":_vm.cancel}},on),[_c('v-icon',[_vm._v("close")])],1)]}}],null,false,2375693462)},[_c('span',[_vm._v("Cancel")])])],1),_c('v-card-text',{staticClass:"ay"},[_c('v-data-table',{staticClass:"colored-header",staticStyle:{"border":"1px solid #888"},attrs:{"items":_vm.workItems,"headers":_vm.headersWorkItems,"sortable":false,"items-per-page":-1,"hide-default-footer":"","item-class":function (v) { return 'cursor-pointer'; }},on:{"click:row":_vm.clickWorkItem},scopedSlots:_vm._u([{key:"item.code",fn:function(ref){
            	var item = ref.item;
return [_c('div',[_vm._v(" "+_vm._s(item.code)+" ")])]}},{key:"item.name",fn:function(ref){
            	var item = ref.item;
return [_c('div',[_vm._v(" "+_vm._s(item.name)+" ")])]}},{key:"item.leadTime",fn:function(ref){
            	var item = ref.item;
return [_c('div',[_vm._v(" "+_vm._s(_vm.formatTime(item.leadTime))+" ")])]}}],null,true)})],1)],1)],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }