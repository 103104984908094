<template>
	<div class="py-5 px-10 new-back full-height">
		<div class="d-flex justify-space-between align-center mb-5">
			<h3>{{ selectedBillables.length }} Billables selected</h3>
			<v-btn @click="openBillableItemsCreation" color="primary">Create billable items</v-btn>
		</div>
		
		<div class="filters">
			<QDatePicker
				v-model="filters.startDate" :max="filters.endDate"
				class="inline-block" label="Start Date"
				inner outlined dense hide-details clearable
			/>
			<QDatePicker
				v-model="filters.endDate" :min="filters.startDate"
				class="inline-block ml-2" label="End Date"
				inner outlined dense hide-details clearable
			/>
			<q-filter-input
				v-model="filters.billable" :items="billableSimpleList"
				@change="fetchBillables"
				class="inline-block ml-2" label="Billable Code"
				dense outlined hide-details clearable multiple
				item-text="code" item-value="id"
				parent-shown-items="billables"
				:data-comparer="(item, value) => item.id == value"
			/>
			<q-filter-input
				v-model="filters.customer" :items="customers"
				@change="changeCustomer"
				class="inline-block ml-2" label="Customer"
				dense outlined hide-details clearable multiple
				item-text="name" item-value="id"
				parent-shown-items="billables"
				:data-comparer="(item, value) => item.customerId == value"
			/>
			<q-filter-input
				v-model="filters.project" :items="projects"
				@change="fetchBillables"
				class="inline-block ml-2" label="Project"
				dense outlined hide-details clearable multiple
				item-text="name" item-value="id"
				parent-shown-items="billables"
				:data-comparer="(item, value) => item.projects.some(p => p.id == value)"
			/>
		</div>
		<billables-table
			v-model="selectedBillables"
			@pagination="fetchBillables"
			:billables="billables"
			:pagination="pagination"
			small show-select
		/>
		<billable-item-create-dialog ref="billableItemCreateDialog"/>
	</div>
</template>

<script>
import moment from "moment"
import BillableJSON from '../../json/billable.json'
import BillableItemCreateDialog from "../../dialogs/adminSurf/BillableItemCreateDialog"
import BillablesTable from '../../components/BillablesTable'

export default {
	components: { BillableItemCreateDialog, BillablesTable },
	data: () =>({
		billables: [],
		billableSimpleList: [],
		headers: [
			{ text: "Code", value: "code", },
			{ text: "Customer", value: "code", },
			{ text: "Code", value: "code", },
			{ text: "Code", value: "code", },
			{ text: "Code", value: "code", },
		],
		durationUnits: [
			{ value: 3, hours: 1, text: 'Hours' },
			{ value: 0, hours: 8, text: 'Days' },
			{ value: 1, hours: 40, text: 'Weeks' },
			{ value: 2, hours: 168, text: 'Months' },
		],
		selectedBillables: [],
		filters: {
			billable: [],
			status: [],
			customer: [],
			project: [],
		},
		customers: [],
		projects: [],
		projectTypes: [],
		
		pagination: {
			page: 1,
			itemsPerPage: 10,
			totalItems: 0,
		},
	}),
	mounted(){
		this.$q.changeHeaderTitle(`New billable item`);
		this.projectTypes = BillableJSON.projectTypes
		this.init()
	},
	methods: {
		init(){
			this.selectedBillables = []
			this.$http.get("/api/customer?onlyWithCustomerProjects=true")
				.then(res => {
					this.customers = _.orderBy(res.data, ['name'])

					for(let customer of this.customers){
						for(let project of customer.customerProjects){
							if(!this.projects.some(p => p.id == project.id)){
								this.projects.push(project)
							}
						}
					}
					this.projects = _.orderBy(this.projects, ['name'])
				})
				.catch(res => {
					console.error(res)
					window.getApp.error("Cannot obtain customers.")
				})
			this.$http.get('api/billable')
				.then(res => {
					this.billableSimpleList = res.data
				})
			this.fetchBillables()
		},
		fetchBillables(){
			return this.$http.post
			(
				'api/billable/detailed',
				{
					ableToBill: true,
					billableId: this.filters.billable,
					customerId: this.filters.customer,
					customerProjectId: this.filters.project,
					page: this.pagination.page,
					pageSize: this.pagination.itemsPerPage,
				}
			)
				.then(res => {
					this.pagination.totalItems = res.data.total
					this.billables = res.data.billables
				})
		},
		openBillableItemsCreation(){
			this.$refs.billableItemCreateDialog.open(
				this.selectedBillables,
				this.filters.startDate,
				this.filters.endDate
			)
				.then(() => {
					this.init()
				})
		},
		changeCustomer(){
			this.filters.project = null
			this.fetchBillables()
		},
	},
}
</script>

<style lang="scss" scoped>
.filters{
	display: flex;
	max-width: 1200px;
	margin: 15px 0;
}
.project{
	display: inline-block;
	border-radius: 15px;
	margin-right: 5px;
	white-space:nowrap;
	div{
		display: inline-flex;
		align-items: center;
		justify-content: center;

	}
	.letter{
		width: 30px;
		padding: 5px;
		color: white;
		border-radius: 15px;
	}
	.name{
		padding: 0 10px 0 5px;
		color: black;
	}
}
</style>