var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"pa-5"},[_c('v-row',[_c('v-col',{attrs:{"sm":12}},[_c('v-data-table',{staticClass:"bordered rounded-lg new-back lighten2 overflow-hidden",staticStyle:{"width":"calc(100vw - 220px)"},attrs:{"headers":_vm.headers,"items":_vm.members,"disable-sort":"","disable-pagination":"","hide-default-footer":""},scopedSlots:_vm._u([{key:"item.blueTag",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex align-center my-3",staticStyle:{"gap":"10px"}},[_c('user-avatar',{attrs:{"user-id":item.userId,"size":40}}),_c('div',{staticClass:"d-flex flex-column"},[_c('b',[_vm._v(_vm._s(item.userBlueTag))]),_c('span',[_vm._v(_vm._s(item.userFullName))])])],1)]}},{key:"item.active",fn:function(ref){
var item = ref.item;
return [(item.active)?_c('div',[_c('v-icon',{attrs:{"color":"grey"}},[_vm._v("mdi-checkbox-marked")])],1):_c('div',[_c('v-icon',{attrs:{"color":"grey"}},[_vm._v("mdi-checkbox-blank-outline")])],1)]}},{key:"item.canView",fn:function(ref){
var item = ref.item;
return [(item.canView)?_c('div',[_c('v-icon',{attrs:{"color":"grey"}},[_vm._v("mdi-checkbox-marked")])],1):_c('div',[_c('v-icon',{attrs:{"color":"grey"}},[_vm._v("mdi-checkbox-blank-outline")])],1)]}},{key:"item.canEdit",fn:function(ref){
var item = ref.item;
return [(item.canEdit)?_c('div',[_c('v-icon',{attrs:{"color":"grey"}},[_vm._v("mdi-checkbox-marked")])],1):_c('div',[_c('v-icon',{attrs:{"color":"grey"}},[_vm._v("mdi-checkbox-blank-outline")])],1)]}},{key:"item.canReport",fn:function(ref){
var item = ref.item;
return [(item.canReport)?_c('div',[_c('v-icon',{attrs:{"color":"grey"}},[_vm._v("mdi-checkbox-marked")])],1):_c('div',[_c('v-icon',{attrs:{"color":"grey"}},[_vm._v("mdi-checkbox-blank-outline")])],1)]}},_vm._l((_vm.projectTabs),function(tab){return {key:_vm.getItemSlot(tab.tab),fn:function(ref){
var item = ref.item;
return [_c('div',{key:tab.tab},[(_vm.canView(item, tab))?_c('div',[_c('v-icon',{attrs:{"color":"grey"}},[_vm._v("mdi-checkbox-marked")])],1):_c('div',[_c('v-icon',{attrs:{"color":"grey"}},[_vm._v("mdi-checkbox-blank-outline")])],1)])]}}})],null,true)})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }