import { render, staticRenderFns } from "./AdminSurfConfig.vue?vue&type=template&id=5cc35364&scoped=true&"
import script from "./AdminSurfConfig.vue?vue&type=script&lang=js&"
export * from "./AdminSurfConfig.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5cc35364",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VTab } from 'vuetify/lib/components/VTabs';
import { VTabItem } from 'vuetify/lib/components/VTabs';
import { VTabs } from 'vuetify/lib/components/VTabs';
import { VTabsItems } from 'vuetify/lib/components/VTabs';
installComponents(component, {VTab,VTabItem,VTabs,VTabsItems})
