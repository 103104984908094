<template>
  <v-col 
    class="pb-0 card-container test2 mb-4"
    v-bind="$attrs"
    :style="{
      'min-width': width + 'px',
      'max-width': width + 'px',
    }">
    <v-card
      :class="{
        ['hover-' +
        getDarkColor(risk.probability * risk.impact).substr(1)]: true,
        selected:
          risk.id == selectedRisk.id || risk.id == tempSelectedRisk.id,
        mainContainer: true
      }"
      :style="{
        borderColor: createdRisk.id == risk.id ? getDarkColor(risk.probability * risk.impact) : 'transparent',
        background: risk.hover
          ? getDarkColor(risk.probability * risk.impact)
          : '',
      }"
      @click="edit(risk)"
      @mouseover.native="selectRiskAsTemporal"
      @mouseleave.native="unselectRiskAsTemporal"
    >
      <v-card-title>
        <user-avatar
          hide-loading
          :userId="risk.reporter.id"
          :showCard="false"
          :size="35"
        />
      </v-card-title>
      <v-card-text>
        <div
          :style="{
            color: getPropertyBySeverity(risk.probability, risk.impact, 'color'),
          }"
          class="reporter-name"
        >
            {{ risk.code.replace("/-/", " ") }}
        </div>
        <div
          class="description"
          v-html="
            JSON.parse(risk.description.replaceAll('\\n', '<br/>'))
          "
        ></div>
        <div class="created-date">
          {{ formatDate(risk.createdAt) }} by {{ risk.reporter.blueTag }}
        </div>
        <div
          class="severity"
          :style="{
            backgroundColor: getPropertyBySeverity(risk.probability, risk.impact, 'backColor'),
            color: getPropertyBySeverity(risk.probability, risk.impact, 'color') 
          }"
        >
              Severity: {{getSeverityName(risk.probability, risk.impact) || ""}}
        </div>
        <div class="icon-container">
          <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
              <div
                v-bind="attrs"
                v-on="on"
                style="cursor: pointer"
                @click.stop="open(risk.id)"
              >
                <v-icon :style="{ fontSize: '26px' }"
                  >mdi-clipboard-check-outline</v-icon
                >
                <span class="workitems-quantity">
                  {{ workItemsDone(activeWorkItems(risk.workItems))}}/{{activeWorkItems(risk.workItems).length }}
                </span>
              </div>
            </template>
            <span>
              {{
                "Work item" + (risk.workItems.length != 1 ? "s" : "")
              }}
            </span>
          </v-tooltip>
        </div>
      </v-card-text>
    </v-card>
  </v-col>
</template>

<script>
import UserAvatar from './UserAvatar.vue';
export default {
  props: [
    'risk',
    'selectedRisk',
    'tempSelectedRisk',
    'edit',
    'open',
    'width',
    'createdRisk',
    'selectRiskAsTemporal',
    'unselectRiskAsTemporal',
  ],
  data: () => ({
    severityByDarkColor: {
      "#ff6968": [20, 25],
      "#efab45": [12, 15, 16],
      "#e9cc75": [5, 6, 8, 9, 10],
      "#72bb6d": [1, 2, 3, 4],
    },
    severityValue: [
      { name: "Critical", value: [20, 25], color: "#ff6968", backColor: "#f5b5b560", active: false },
      { name: "High", value: [12, 15, 16], color: "#efab45", backColor: "#ffd28e60", active: false },
      { name: "Medium", value: [5, 6, 8, 9, 10], color: "#e9cc75", backColor: "#fff1c460", active: false },
      { name: "Low", value: [1, 2, 3, 4], color: "#72bb6d", backColor: "#b5f1b160", active: false },
    ],
    severityNameByNumber: {
      "Critical": [20, 25],
      "High": [12, 15, 16],
      "Medium": [5, 6, 8, 9, 10],
      "Low": [1, 2, 3, 4],
    },
  }),
  methods: {
    getDarkColor(severity) {
      for (let color in this.severityByDarkColor) {
        if (this.severityByDarkColor[color].includes(severity)) {
          return color;
        }
      }
      return "white";
    },
    getPropertyBySeverity(probability, impact, property) {
      let value = ''
      this.severityValue.forEach(severity => {
        if(severity.value.includes(probability * impact)) {
          value = severity[property]
          return
        }
      })
      return value;
    },
    getSeverityName(probability, impact) {
			for (let name in this.severityNameByNumber) {
				if (this.severityNameByNumber[name].includes(probability * impact)) return name;
			}
			return "";
		},
    workItemsDone(workItems) {
      if(workItems.length) {
        return workItems.filter(wi => wi.status.isFinal).length
      }
      return 0
    },
    activeWorkItems(workItems) {
      if(workItems.length) {
        let activeWorkItems = workItems.filter(wi => {
          if(!wi.deleted && !wi.status.deleted) {
            return wi
          }
        })
        return activeWorkItems
      }
      return []
    },
  }
}
</script>

<style scoped lang="scss">
.card-container {
  padding-top: 0;
  flex: 1 1 1;
  min-width: 330px;
  max-width: 330px;
  .v-card {
    cursor: pointer;
    width: 100%;
    border: 1px solid #aaa;
    height: 100%;
    background: var(--v-background-lighten);

    .reporter-name {
      margin-left: 10px;
      align-self: flex-start;
      font-size: 0.8em;
    }
    .icon-container {
      display: flex;
      align-items: center;
      position: absolute;
      right: 10px;
      bottom: 10px;
      .workitems-quantity {
        color: var(--v-text-lighten2);
        font-size: 1.2em;
        font-weight: bold;
      }
    }
    .description {
      font-size: 1.2em;
      color: var(--v-text-lighten2);
      margin-bottom: 10px;
      margin-left: 5px;
      word-break: break-word;
    }
    .created-date {
      font-size: 1.0em;
      color: var(--v-text-lighten2);
      margin-bottom: 19px;
      margin-left: 4px;
    }
    .severity {
      position: absolute;
      bottom: 0;
      margin: 0 0 11px 6px;
      font-size: 1em;
      font-weight: bold;
      padding: 2px 7px;
      border-radius: 5px;
    }
  }

  .v-card:hover,
  .v-card.selected {
    
    color: white;
    span {
      color: white;
    }
    .description {
      color: white;
    }
    h3 {
      color: white;
    }
    .v-icon {
      color: white;
    }
    .workitems-quantity {
      color: white;
    }
    .reporter-name, .severity {
      color: white !important;
    }
  }

  .v-card.hover-72bb6d:hover,
  .v-card.hover-72bb6d.selected {
    background: #72bb6d;
  }
  .v-card.hover-e9cc75:hover,
  .v-card.hover-e9cc75.selected {
    background: #e9cc75;
  }
  .v-card.hover-ff6968:hover,
  .v-card.hover-ff6968.selected {
    background: #ff6968;
  }
  .v-card.hover-efab45:hover,
  .v-card.hover-efab45.selected {
    background: #efab45;
  }
}



.mainContainer {
  display: flex;
  padding: 0 0 10px 0;
  .v-card__title {
    padding: 16px 2px 0 16px;
    align-self: flex-start;
  }
  .v-card__text {
    margin: 18px 0 0 0;
    padding-left: 7px;
     .reporter-name {
      margin-left: 0;
      align-self: flex-start;
      // font-size: 0.6em;
      color: var(--v-text-lighten2);
      font-size: 1.1em;
      font-weight: bold;
    }
  }
}

@media only screen and (min-width: 2400px) {
  .card-container {
      min-width: 450px ! important;
  }
}
.test2 {
  max-width: none;
}
</style>