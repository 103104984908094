
const Q = {
    install (Vue, options) {

        Vue.mixin({
            created() {
                let vueInstance = this
                this.$q = {
                    changeHeaderTitle(title) {
                        vueInstance.$store.commit('definedMainTitle', title)
                    },
                    download(link, name) {
                        var aElement = document.createElement('a')
                        aElement.target = "_blank"
                        aElement.href = link
                        aElement.download = name
                        document.body.appendChild(aElement)
                        aElement.click()
                        document.body.removeChild(aElement)
                    },
                    getParentVariable(componentName, variableName) {
                        
                        let parent = vueInstance.$parent
                        while (parent != null) {
                            if (parent.componentName === componentName) {
                                return parent[variableName]
                            }
                            parent = parent.$parent
                        }
                        return null
                    },
                    /* Searches a queryText inside any of the texts in textArr. The whole query text must match any of the texts. */
                    searchByText(queryText, textArr) {
                        console.log("search")
                        if (!textArr || !textArr.length) return true
                        return textArr.some(
                            text => (text || '').toLowerCase().includes(queryText.toLowerCase()))
                    },
                    /* Searches a queryText inside any of the texts in textArr. Every word of the query text must match any of the texts. */
                    searchByWords(queryText, textArr) {
                        console.log("searchAny")
                        if (!textArr || !textArr.length) return true
                        queryText = queryText.split(" ").filter(q => !!q).map(q => q.toLowerCase())
                        textArr = textArr.filter(t => !!t).map(t => t.toLowerCase())
                        return queryText.every(q =>
                            textArr.some(t => t.includes(q)))
                    },
                    setCookie(name,value,days) {
                        var expires = ""
                        if (days) {
                            var date = new Date()
                            date.setTime(date.getTime() + (days*24*60*60*1000))
                            expires = "; expires=" + date.toUTCString()
                        }
                        document.cookie = name + "=" + (JSON.stringify(value) || "")  + expires + "; path=/"
                    },
                    getCookie(name) {
                        let nameEQ = name + "="
                        let ca = document.cookie.split(';')
                        for (let i=0;i < ca.length;i++) {
                            let c = ca[i]
                            while (c.charAt(0)==' ') c = c.substring(1,c.length)
                            if (c.indexOf(nameEQ) == 0) {
                                let stringValue = c.substring(nameEQ.length,c.length)
                                try {
                                    return JSON.parse(stringValue)
                                } catch (e) {
                                    return stringValue
                                }
                            }
                        }
                        return null;
                    },
                    eraseCookie(name) {   
                        document.cookie = name+'=; Max-Age=-99999999;'
                    },
                    /**
                     * Adds first 'Select' item to array.
                     * Adds a { id: null, name: 'Select' } object to index 0;
                     */
                    addFirstItem: (array, label, textField) => {
                        textField = textField || 'name'
                        let newItem = { id: null }
                        newItem[textField] = label || 'Select'
                        array.splice(0, 0, newItem)
                    },
                    addLastItem: (array, label, textField) => {
                        textField = textField || 'name'
                        let newItem = { id: null }
                        newItem[textField] = label || 'Select'
                        array.push(newItem)
                    },
                    /**
                     * Adds an item if it does not exists, updates the corresponding index if it exists.
                     * Tests if it exists using the 'id' field or named field of each object.
                     */
                    addOrUpdateById: (array, item, col) => {
                        if (!col) col = 'id'
                        let ix = _.findIndex(array, c => c[col] == item[col])
                        if (ix == -1) {
                            array.push(item)
                        } else {
                            array.splice(ix, 1, item)
                        }
                    },
                    /**
                     * Removes the corresponding object from the array
                     * Tests if it exists using the 'id' field or named field of each object.
                     */
                    removeById: (array, id, col) => {
                        if (!col) col = 'id'
                        var ix = array.findIndex(x => x[col] === id)
                        if (ix >= 0) {
                            array.splice(ix, 1)
                        }
                    },
                    /**
                     *  this.$q.confirmDelete({
                     *      api: `/api/opportunity/${id}`,
                     *      data: {},
                     *      messageTitle: "Delete Confirmation",
                     *      messageBody: "Are you sure you want to delete this opportunity?", 
                     *      cancelBtnText: "Cancel", 
                     *      confirmBtnText: "Confirm",
                     *      sucessMsg: "Opportunity deleted",
                     *      errorMsg: "The opportunity was not deleted.",
                     *  }).then(res => {
                     *      this.$q.removeById(this.item.opportunities, id)
                     *  })
                     */
                    log(module, entityClass, type, register, entityId){
                        const log = { module, entityClass, type, register, entityId }
                        vueInstance.$http.post('api/log', log, { headers: { 'hideLoading': true } })
                    },
                    confirmDelete: (options) => {
                        let defaultOptions = {
                            messageTitle: "Delete Confirmation",
                            messageBody: "Are you sure you want to delete this record?", 
                            cancelBtnText: "Cancel", 
                            confirmBtnText: "Confirm",
                            sucessMsg: "Record deleted",
                            errorMsg: "The record was not deleted.",
                        }
                        options = Object.assign(defaultOptions, options)

                        let promise = new Promise((resolve, reject) => {

                            vueInstance.$vuetifyConfirmDialog.open(
                                options.messageTitle,
                                options.messageBody,
                                options.cancelBtnText,
                                options.confirmBtnText)
                            .then(confirmed => {
                                if (!confirmed) return

                                vueInstance.$http.delete(options.api, { data: options.data })
                                    .then(res => { 
                                        window.getApp.success(options.sucessMsg)
                                        resolve(res)
                                    })
                                    .catch(res => {
                                        window.getApp.error(options.errorMsg)
                                        console.log(res)

                                        reject(res)
                                    })
                            })
                        })

                        return promise
                    },
                    /**
                     * this.$q.save({
                     *   api: "/api/lead",
                     *   data: this.item,
                     *   successMsg: "Lead saved.",
                     *   afterSuccessUrl: "/lead",
                     *   errorMsg: "Cannot save record.",
                     *   afterErrorUrl: null,
                     * })
                     */
                    
                    save: (options) => {
                        let promise = new Promise((resolve, reject) => {


                            let p
                            if(options.headers) {
                                p = vueInstance.$http.post(options.api, options.data || null, { headers: options.headers })
                            }
                            else {
                                p = vueInstance.$http.post(options.api, options.data || null)
                            }
    
                            // if (options.successMsg || options.afterSuccessUrl) {
                                p = p.then(response => {
                                    if (options.successMsg) {
                                        window.getApp.success(options.successMsg)
                                    }
                                    if (options.afterSuccessUrl) {
                                        vueInstance.$router.push(options.afterSuccessUrl)
                                    }
    
                                    // return response
                                    resolve(response)
                                })
                            // }
    
                            // if (options.errorMsg || options.afterErrorUrl) {
                                p = p.catch(response => {
                                    console.log(response)
                                    if (options.errorMsg) {
                                        window.getApp.error(options.errorMsg)
                                    }
                                    if (options.afterErrorUrl) {
                                        vueInstance.$router.push(options.afterErrorUrl)
                                    }
    
                                    // return response
                                    reject(response)
                                })
                            // }

                        })


                        return promise

                    },
                    profilePicSrc: (item) =>  {
                        const profilePicFailover = 'https://cdn.vuetifyjs.com/images/parallax/material2.jpg'
                        if (item.profilePicError) {
                            return profilePicFailover;
                        }
                        let src = `api/user/${item.id}/profilePicture`;
                        if (!vueInstance.$http.defaults.baseURL.endsWith("/")) src = "/" + src;
                        vueInstance.$set(item, "profilePicSrc", vueInstance.$http.defaults.baseURL + src);
                    },
                    getMentions(text) {
                        const parseMentions = (node) => {
                            const children = [...(node.children || [])]
                            const mentions = children.flatMap(parseMentions)
                            if(node.getAttribute('data-type') === 'mention'){
                                mentions.push(node.getAttribute('data-id'))
                            }
                            return mentions
                        }

                        let div = document.createElement('div')
                        div.innerHTML = text
                        return parseMentions(div)
                    },
                    distinct(arr){
                      return [...new Set(arr)]
                    },
                    distinctByProperty: (array, prop) => {
                        return array.filter((x, ix, arr) => {
                            return arr.map(y => y[prop]).indexOf(x[prop]) === ix
                        })
                    },
                    openNewBackendTab(url) {
                        let _url = new URL(url, window._config.backendUrl).href
                        window.open(_url, '_blank')
                    },
                    openNewTab(url) {
                        let _url = new URL(url, window.location.origin).href
                        window.open(_url, '_blank')
                    },
                    goTo(url, event = window.event) {
                        console.log({event})
                        if (event?.ctrlKey || event?.metaKey) {
                            this.openNewTab(url)
                            return
                        }

                        vueInstance.$router.push(url)
                    },
                    setComplete(prev, data) {
                        Object.keys(prev)
                            .forEach(key => prev[key] = null)
                        Object.entries(data)
                            .forEach(entry => vueInstance.$set(prev, entry[0], entry[1]))
                   }
                }
            }
        })
    }
}
export default Q
