<template>
  <v-container class="px-1">
    <div style="display: flex; justify-content: space-between" class="mb-2">
      <h3>Notes</h3>
      <v-icon @click="$emit('close')">mdi-close</v-icon>
    </div>
    <div v-show="!showNoteCreate">
      <h4 @click="showNoteCreate = !showNoteCreate" class="cursor-pointer py-2">
        <v-icon color="primary">add</v-icon>
        Add a Note
      </h4>
    </div>
      <div class="pa-2" v-show="showNoteCreate">
        <note-editor
          ref="richEditorNote"
          v-model="selectedNote.content"
          :image-handler="openFileUpload"
          @close="closeEditNote"
        />
        <div class="d-flex" style="justify-content: flex-end">
          <v-btn @click="save" class="mt-1 " text color="primary">Save</v-btn>
        </div>
        <input
          type="file" ref="fileUploader" class="d-none"
          accept="image/*" @change="handleFileUpload"
        />
      </div>
      <v-card v-for="(note, ix) in shownNotes" :key="ix" class="pa-2 mb-2 cursor-pointer task-container">
        <v-menu
          bottom
          offset-y
          :close-on-content-click="true"
          style="z-index: 204"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-icon
              v-bind="attrs"
              v-on="on"
              class="cursor-pointer float-right"
            >
              mdi-dots-vertical
            </v-icon>
          </template>
          <v-list dense>
            <v-list-item 
              @click="deleteNote(note.id)"
            >
              Delete 
              <v-icon
                class="cursor-pointer ml-1"
                color="error"
                @click="deleteNote"
              >
                mdi-delete
              </v-icon>
            </v-list-item>
          </v-list>
        </v-menu>
        <v-icon class="cursor-pointer float-right"></v-icon>
        <div v-html="note.content" @click="editNote(note.id)" style="word-break: break-word;"></div>
      </v-card>
  </v-container>
</template>

<script>
import NoteEditor from './NoteEditor.vue';
import { mapState } from 'vuex'
import _ from 'lodash'
export default {
  components: {
    NoteEditor,
  },
  data: () => ({
    showNoteCreate: false,
    files: [],
    text: '',
    selectedNote: {
      content: '',
      createdById: '',
      images: []
    },
    notes: []
  }),
  computed: {
    ...mapState(['user']),
    shownNotes() {
      if(this.selectedNote.id) {
        return this.notes.filter(note => note.id !== this.selectedNote.id)
      }
      return this.notes
    },
  },
  watch: {
    showNoteCreate(newVal) {
      if(newVal) {
        this.focusEditor()
      }
    },
  },
  mounted() {
    this.init()
  },
  methods: {
    focusEditor() {
      this.$refs.richEditorNote.focus()
    },
    save(){
      if(!this.selectedNote.id) {
        const note = this.selectedNote
        note.content = this.deleteTemporalImageIndicator(note.content)
        this.$http.post(`api/n/note`, note).then(res => {
          this.init()
        })
        .then(() => {
          this.$root.success('Note created Successfully.')
        })
      } else {
        const note = _.cloneDeep(this.selectedNote)
        note.content = this.deleteTemporalImageIndicator(note.content)
        note.images = note.images.filter(img => note.content.indexOf(img.imageId) != -1)
        note.images.forEach(img => img.noteId = note.id)
        this.$http.put(`api/n/note/${note.id}`, note).then(res => {
          this.init()
        })
        .then(() => {
          this.$root.success('Note updated Successfully.')
        })
      }
		},
    closeEditNote() {
      this.selectedNote = {}
      this.showNoteCreate = false
    },
    editNote(id) {
      this.selectedNote = _.cloneDeep(this.notes.find(note => note.id == id))
      this.showNoteCreate = true
      this.focusEditor()
    },
    deleteTemporalImageIndicator(note) {
      let regex = /\?temporalImage=true/g
      return note.replace(regex, '')
    },
		openFileUpload(commands){
			this.commands = commands
			this.$refs.fileUploader.click()
		},
    handleFileUpload(){
			if(this.$refs.fileUploader.files.length){
				let file = this.$refs.fileUploader.files[0]
				let formData = new FormData()
				formData.append('file', file)
				this.$http.put(
					`api/n/uploadImage`,
					formData,
					{ headers: { 'Content-Type': 'multipart/form-data', hideLoading: true } }
				).then(res => {
					let id = res.data
          let backendUrl = this.getBackendUrl()
          backendUrl = backendUrl.endsWith('/') ? backendUrl : backendUrl + '/'
					let url = `${backendUrl}api/n/image/${id}?temporalImage=true` 
					this.commands.setImage({ src: url })
          this.selectedNote.images.push({
            imageId: id,
          })
				}).catch((err) => {
					console.error(err)
					let errMsg = "Error while upload file."
					if(file.size > 30000000) errMsg = "The file exceeds maximum allowed size. (28MB)"
					this.$root.error(errMsg)
				})
			}
		},
    init() {
      this.selectedNote = {
        content: ``,
        createdById: this.user.id,
        images: []
      }
      this.showNoteCreate = false
      this.$http.get(`api/n/note/all`).then(res => {
        this.notes = res.data.reverse()
      })
      .catch((err) => {
        console.error(err)
        let errMsg = "Error while getting notes."
        this.$root.error(errMsg)
      })
    },
    deleteNote(id) {
      this.$http.delete(`api/n/note/${id}`).then(res => {
        let id = res.data
        this.notes = this.notes.filter(note => note.id != id)
      })
      .then(() => this.$root.error('Note deleted Successfully.'))
    },
  },
}
</script>

<style lang="scss">
.rich-image {
  max-width: 100%;
}
.task-container {
  background: var(--v-newDesignBackground-base);
}
</style>