import moment from 'moment'

const colors = [
	{ base: '#D5F7CB', progress: '#CFEBDF' },
	{ base: '#EAE8FF', progress: '#C7C3F0' },
	{ base: '#B3D3F8', progress: '#A0B1F0' },
	{ base: '#E9D6EC', progress: '#D3A7DA' },
	{ base: '#F2FFBF', progress: '#FAF7A3' },
]

export default (sprint, validWorkItems, invalidWorkItems, getBackendUrl, openWorkItemsDetails) => {
	let data = []
	let workItemPerAssignee = {}
	let max = moment(sprint.endDate).clone().add(1, 'day').utc(true).valueOf()
	const timePerDay = moment().add(1, 'day').utc(true).valueOf() - moment().utc(true).valueOf()

	validWorkItems.forEach(workItem => {
		if (!workItemPerAssignee[workItem.assignedToId]) workItemPerAssignee[workItem.assignedToId] = []
		workItemPerAssignee[workItem.assignedToId].push(workItem)
	})

	let colorsIndex = 0
	for (let assignee in workItemPerAssignee) {
		let startDate = moment(sprint.startDate).clone()
		let remainingHoursInDay = 8

		let assigneeWorkItems = workItemPerAssignee[assignee]
			.sort((a, b) => {
				if (a.priority == b.priority && a.value == b.value) return a.estimatedEffort - b.estimatedEffort
				if (a.priority == b.priority) return b.value - a.value
				return b.priority - a.priority
			})

		for (let workItem of assigneeWorkItems) {
			let currentTime = startDate.clone().utc(true).valueOf()
			let dataItem = {
				id: workItem.id,
				code: workItem.code,
				name: workItem.name,
				priority: workItem.priority,
				statusName: workItem.statusName,
				statusColor: workItem.statusColor,
				statusIconClass: workItem.statusIconClass,
				estimatedEffort: workItem.estimatedEffort,
				totalHours: workItem.estimatedEffort * (1 + sprint.criticalPath / 100),
				totalExecuted: workItem.totalExecuted,
				start: currentTime + timePerDay * (8 - remainingHoursInDay) / 8,
				end: currentTime + timePerDay * (8 - remainingHoursInDay) / 8,
				assignedToId: workItem.assignedToId,
				assignedToBlueTag: workItem.assignedToBlueTag,
				color: 'transparent',
				background: colors[colorsIndex].base,
				background2: colors[colorsIndex].progress,
				completed: { amount: workItem.totalExecuted / (workItem.estimatedEffort || 0) },
			}

			if(moment(dataItem.start).utc(false).day() == 6)
				dataItem.start = moment(dataItem.start).add(1, 'days').utc(false).valueOf()
			if(moment(dataItem.start).utc(false).day() == 0)
				dataItem.start = moment(dataItem.start).add(1, 'days').utc(false).valueOf()

			let hours = workItem.totalHours
			while (hours > 0) {
				let day = startDate.day()
				if (day == 0 || day == 6) {
					startDate.add(1, 'days')
					continue
				}

				if (hours > remainingHoursInDay) {
					hours -= remainingHoursInDay
					startDate.add(1, 'days')
					remainingHoursInDay = 8
				}
				else {
					remainingHoursInDay -= hours
					hours = 0
					if(remainingHoursInDay <= 0) {
						startDate.add(1, 'days')
						remainingHoursInDay = 8
					}
				}

				dataItem.end = startDate.clone().utc(true).valueOf() + timePerDay * (8 - remainingHoursInDay) / 8
				
				if(dataItem.end > max) {
					max = dataItem.end
				}
			}

			dataItem.end = dataItem.start + ((dataItem.end - dataItem.start) / (1 + sprint.criticalPath / 100))

			data.push(dataItem)
		}

		colorsIndex++
		colorsIndex %= colors.length
	}

	invalidWorkItems.forEach(workItem => {
		data.push({
			id: workItem.id,
			code: workItem.code,
			name: workItem.name,
			priority: workItem.priority,
			statusName: workItem.statusName,
			statusColor: workItem.statusColor,
			statusIconClass: workItem.statusIconClass,
			start: moment(sprint.startDate).clone().utc(true).valueOf(),
			end: moment(sprint.startDate).clone().add(2, 'day').utc(true).valueOf(),
			assignedToId: workItem.assignedToId,
			assignedToBlueTag: workItem.assignedToBlueTag,
			invalid: true,
			color: '#eee',
		})
	})
	
	const plotLines = [{
		value: moment().clone().utc(true).valueOf(),
		color: '#097688',
		width: 2,
		zIndex: 10,
		label: {
			text: 'Today',
			rotation: 0,
			x: -40,
			y: 15,
			style: {
				fontSize: '1em',
				fontFamily: 'Arial',
			}
		},
	}]

	if(moment(sprint.endDate).clone().add(1, 'day').utc(true).valueOf() < max) {
		plotLines.push({
			value: moment(sprint.endDate).clone().add(1, 'day').utc(true).valueOf(),
			color: '#097688',
			dashStyle: 'LongDash',
			width: 2,
			zIndex: 10,
			label: {
				text: 'End of sprint',
				rotation: 0,
				y: 15,
				style: {
					fontSize: '1em',
					fontFamily: 'Arial',
				}
			}
		})
	}

	return {
		xAxis: [
			{
				min: moment(sprint.startDate).clone().utc(true).valueOf(),
				max,
				plotLines,
				breaks: [{
					from: moment('2018-11-10').clone().utc(true).valueOf()-1,
					to: moment('2018-11-12').clone().utc(true).valueOf(),
					breakSize: 0,
					repeat: 7 * 24 * 36e5 //every week/7 days
				}],
				tickInterval: 1000 * 60 * 60 * 24,
				labels: {
					format: '{value:%d %b}',
					formatter() {
						let diff = moment(max).diff(moment(sprint.startDate).utc(true), 'days') + 1
						
						if(diff > 26)
							return moment(this.value).utc(false).format('MMM D')

						return moment(this.value).utc(false).format('ddd, D MMM')
					},
				},
			},
			{
				labels: {
					format: '{value:%d %b}',
					formatter() {
						return 'Week ' + moment(this.value).utc(false).format('W')
					},
				},
			},
		],

		yAxis: [{ visible: false, }],

		tooltip: {
			useHTML: true,
			formatter: function () {
				if (this.point.invalid)
					return `
						<div style="z-index: 9999; background: white; padding: 5px">
							Work item ${this.point.code} is missing one or more key parameters
						</div>
					`

				return `<div
						style="
							display: flex; align-items: center; gap: 10px;
							z-index: 9999; background: white; padding: 5px
						"
					>
						<div>
							<b>${this.point.code}: ${this.point.name}</b>
							<br>
							<div>${this.point.statusName}</div>
						</div>
					</div>`
			},
			padding: 0,
		},
		plotOptions: {
			gantt: {
				states: {
					hover: { enabled: false },
					select: { enabled: false },
				}
			}
		},
		credits: { enabled: false },

		series: [
			{
				data,
				borderColor: 'transparent',
				borderRadius: 22,
				pointPadding: -.3,
				point: {
					events: {
						click(e) {
							openWorkItemsDetails(e.point.id)
						},
					},
				},
				dataLabels: [{
					enabled: true,
					formatter: function () {
						const sweetSpot = moment(max).diff(moment(sprint.startDate).utc(true), 'week') * 40 / 10
						if (this.point.invalid) {
							return `
								<div style="
									max-width: ${this.point.shapeArgs.width - 12}px; overflow: hidden;
									font-size: 1.3em; font-family: Roboto; font-weight: 400;
									text-align: center; width: 100%; padding-left: 3px;
								">
									Work item ${this.point.code} is missing one or more key <br> parameters
								</div>
							`
						}

						let infoDiv = `
							<div style="position: relative; min-width: 35px; height: 35px">
								<div style="
									display: inline-flex; width: 35px; height: 35px;
									background: gray; border-radius: 50%; overflow: hidden; color: white;
									font-size: 1.3em; font-family: Roboto; font-weight: 400;
									position: relative;
								">
									<div style="position: absolute; left: 50%; top: 50%; transform: translate(-50%, -50%)">${this.point.assignedToBlueTag?.substring(0, 2).toUpperCase()}</div>
									<img
										style="position: absolute; left: 50%; top: 50%; transform: translate(-50%, -50%); object-fit: cover;"
										src="${getBackendUrl('api/user/' + this.point.assignedToId + '/profilePicture')}"
										onerror="this.style.display='none'" height="35" width="35"
									>
								</div>
								<div style="background: #eee; width: 17px; height: 17px; border-radius: 50%; position: absolute; bottom: -1px; right: -7px">
									<img src="${require(`../assets/priority-icons/${this.point.priority}.png`)}" height="17">
								</div>
							</div>
							<div>
								<span style="font-size: 1.1em; font-family: Roboto; font-weight: 400; color: var(--v-text-base);">${this.point.code}: ${this.point.name.substr(0, 20)}${ this.point.name.length > 20 ? '...' : '' }</span>
								<br>
								<div style="display: inline-block; background: ${this.point.statusColor}; color: white; border-radius: 5px; padding: 1px 5px">
									<i class="v-icon notranslate material-icons theme--light white--text" style="font-size: .9em;">
										${this.point.statusIconClass}
									</i>
									${this.point.statusName}
								</div>
							</div>
						`
						let completedPercent = this.point.completed?.amount * 100
						let template = `
							<div
								style="
									display: flex; align-items: center; gap: 8px;
									width: ${this.point.shapeArgs.width}px;
									overflow: hidden !important; position: relative;
									height: 100%;
									${
										this.point.completed?.amount > 1 ? `background-color: ${this.point.background2};` :
										`
											background: linear-gradient(
												to right,
												${this.point.background2} ${completedPercent}%,
												${this.point.background} ${completedPercent}% ${100}%
											);
										`
									}
									min-height: 45px;
									padding: 5px 10px; border-radius: 22px;
									margin-left: -5px; margin-top: -1px;
									box-shadow: ${this.point.shapeArgs.width * sprint.criticalPath / 100}px 0 1px var(--v-secondary-base);
								"
								class="${this.point.completed?.amount > 1 ? 'textured' : ''}"
							>
								${ this.point.estimatedEffort >= sweetSpot ? infoDiv : '' }
							</div>
						`
						if(this.point.estimatedEffort < sweetSpot) {
							template += `
								<div
									style="
										position: absolute; left: calc(${100 + sprint.criticalPath}% + 20px); top: 0;
										display: flex; align-items: center; gap: 8px;
										height: 100%; min-height: 45px;
										padding: 5px 10px; border-radius: 5px;
										margin-left: -5px; margin-top: -1px;
										background: var(--v-coloredBack-base);
									"
									class="with-triangle"
								>
									${infoDiv}
								<div>
							`
						}

						return template
					},
					useHTML: true,
					align: 'left'
				}],
			}
		],
	}
}