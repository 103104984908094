<template>
	<div>
		<h3 class="mb-5">Billable Items</h3>

		<admin-balance-panel
			:items="billableItems"
			:pathToChild="x => x.invoices"
			:mainHeaders="billableItemHeaders"
			:secondaryHeaders="invoiceHeaders"
			mainTemplateColumns="3fr repeat(3, 1fr)"
			secondaryTemplateColumns="repeat(3, 1fr) repeat(3, 1fr)"
		>
			<template #[`item.code`]="item">
				<div class="d-flex flex-column align-start justify-center">
					<h4>{{item.code}}</h4>
					<span style="color: var(--v-text-lighten)" class="body-2">
						{{ item.startDate | formatDate }} - {{ item.endDate | formatDate }}
					</span>
				</div>
			</template>
			<template #[`item.total`]="item">
				<v-btn fab width="23" height="23" class="mr-1" depressed color="#b9d7fe">
					<v-icon size="15" color="primary">mdi-tag-outline</v-icon>
				</v-btn>
				{{item.billableCurrency}} {{ currencyFormat(item.total) }}
			</template>
			<template #[`item.invoiced`]="item">
				<v-btn fab width="23" height="23" class="mr-1" depressed color="#bce3c9">
					<v-icon size="15" color="success">mdi-script-text-outline</v-icon>
				</v-btn>
				{{item.billableCurrency}} {{ currencyFormat(item.invoiced) }}
			</template>
			<template #[`item.notInvoiced`]="item">
				<v-btn fab width="23" height="23" class="mr-1" depressed color="#f5c4c5">
					<v-icon size="15" color="error">mdi-clipboard-file-outline</v-icon>
				</v-btn>
				{{item.billableCurrency}} {{ currencyFormat(item.total - item.invoiced) }}
			</template>
			
			<template #[`subitem.invoiceCode`]="item">
				<b>{{item.invoiceCode}}</b>
			</template>
			<template #[`subitem.invoiceIssueDate`]="item">
				{{ item.invoiceIssueDate | formatDate }}
			</template>
			<template #[`subitem.invoicePaymentDay`]="item">
				{{ item.invoicePaymentDay | formatDate }}
			</template>
			<template #[`subitem.invoiceSubtotal`]="item">
				<v-btn fab width="23" height="23" class="mr-1" depressed color="#b9d7fe">
					<v-icon size="15" color="primary">mdi-script-text-outline</v-icon>
				</v-btn>
				{{ item.invoiceCurrency }} {{ currencyFormat(item.invoiceSubtotal) }}
			</template>
			<template #[`subitem.amount`]="item">
				<v-btn fab width="23" height="23" class="mr-1" depressed color="#bce3c9">
					<v-icon size="15" color="success">mdi-check-circle-outline</v-icon>
				</v-btn>
				<div style="display: grid; grid-template-columns: 1fr 1fr; gap: 10px; width: 100%">
					<span>{{ item.invoiceCurrency }} {{ currencyFormat(item.amount) }}</span>
					<span v-if="item.exchangeRateAmount">
						{{ item.billableItemBillableCurrency }} {{ currencyFormat(item.exchangeRateAmount) }}
					</span>
				</div>
			</template>
		</admin-balance-panel>
	</div>
</template>

<script>
import AdminBalancePanel from './AdminBalancePanel'
export default {
	props: {
		billableItems: Array,
	},
	components: { AdminBalancePanel },
	data: () => ({
		billableItemHeaders: [
			{ text: 'Billable Item', value: 'code' },
			{ text: 'Billed', value: 'total' },
			{ text: 'Invoiced(subtotal)', value: 'invoiced' },
			{ text: 'Not invoiced', value: 'notInvoiced' },
		],
		invoiceHeaders: [
			{ text: 'Invoice No.', value: 'invoiceCode' },
			{ text: 'Invoice Date', value: 'invoiceIssueDate' },
			{ text: 'Due Date', value: 'invoicePaymentDay' },
			{ text: 'Invoice subtotal', value: 'invoiceSubtotal' },
			{ text: 'Applied amount', value: 'amount' },
		],
	}),
}
</script>