var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"chart bordered rounded mt-3",style:({ maxHeight: "calc(100vh - 225px)" }),attrs:{"id":"chart"}},[_c('div',{staticStyle:{"width":"max-content"}},[_c('div',{staticClass:"line header"},[_vm._m(0),_vm._l((_vm.dates),function(date,ix){return _c('div',{key:ix,staticClass:"date new-back",class:{ 'no-border': !ix }},[_c('b',{staticClass:"head"},[_vm._v(_vm._s(date.children.length > 3 ? date.title : date.title.substr(0, 3)))]),_c('div',{staticClass:"children"},_vm._l((date.children),function(child,jx){return _c('div',{key:jx,class:{
								'weekend-date': _vm.isWeekend(child),
								'top-date': true,
							},attrs:{"id":child.isToday ? 'today' : null}},[(_vm.isWeekend(child))?_c('div',{staticClass:"weekend-overlay",style:({ height: _vm.getOverlayHeight })}):_vm._e(),_vm._v(" "+_vm._s(child.text)+" ")])}),0)])})],2),(!_vm.itemsPerUser.length)?_c('div',[_vm._v(" No data available ")]):_vm._e(),_vm._l((_vm.itemsPerUser),function(item,ix){return _c('div',{key:ix,staticClass:"line new-back"},[_c('div',{staticClass:"sticky"},[_c('div',{staticClass:"st"},[_c('div',{staticStyle:{"width":"70px"},attrs:{"title":item.requestorBlueTag || item.requestorFullName}},[_c('v-tooltip',{staticStyle:{"z-index":"124","background":"black"},attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
							var on = ref.on;
return [_c('b',_vm._g({},on),[_vm._v(_vm._s(item.requestorBlueTag))])]}}],null,true)},[_c('span',[_vm._v(_vm._s(item.requestorFullName))])])],1),_c('div',{staticStyle:{"width":"120px","overflow":"hidden","text-overflow":"ellipsis"}},[_vm._v(" "+_vm._s(item.requestorJobTitle)+" ")]),_c('div',{staticStyle:{"width":"120px"}},[_vm._v(" "+_vm._s(_vm.departments[item.requestorDepartment])+" ")]),_c('div',{staticClass:"mr-0 overflow-hidden",staticStyle:{"width":"150px"},attrs:{"title":'Project'}},_vm._l((item.projects),function(project){return _c('span',{key:project.id},[_vm._v(" "+_vm._s(project.name)+" ")])}),0)])]),_vm._l((item.periods),function(period,jx){return _c('div',{key:jx,staticClass:"ranges-cont"},[_c('div',{staticClass:"date-range",style:({
							width: _vm.getDateRangeWidth(period),
							left: _vm.getDateRangeLeftPosition(period),
							backgroundColor: 'var(--v-primary-base)',
						})})])})],2)})],2)])])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"sticky"},[_c('div',{staticClass:"st head caption",staticStyle:{"color":"var(--v-text-lighten2)"}},[_c('div',{staticStyle:{"width":"70px"}},[_c('b',[_vm._v("BlueTag")])]),_c('div',{staticStyle:{"width":"120px"}},[_c('b',[_vm._v("Role")])]),_c('div',{staticStyle:{"width":"120px"},attrs:{"title":"Department"}},[_c('b',[_vm._v("Department")])]),_c('div',{staticStyle:{"width":"150px","margin-right":"0"}},[_c('b',[_vm._v("Project")])])])])}]

export { render, staticRenderFns }