<template>
	<side-panel v-model="innerValue" close-outside v-on="$listeners" v-bind="$attrs" :width="450">
		<template>
			<v-card min-height="100vh">
				<v-card-title>
					<div class="d-flex flex justify-space-between">
						<h2 class="text--text title mb-0">Details</h2>
						<v-btn @click="$emit('input', false)" icon>
							<v-icon>close</v-icon>
						</v-btn>
					</div>
				</v-card-title>
				<v-card-text style="height: 100%">
					<div v-if="variant === 'assignment'">
						<div class="subtitle">Assigned to</div>
						<div
							class="rounded-lg bordered py-3 px-5"
							:set="type = customerProjectTypes.find(x => x.value == item.customerProjectType)"
						>
							<div
								v-if="type"	
								class="flex d-flex justify-space-between align-center"
							>
								<div class="admin-project" :style="{background: type.lightColor}">
									<div class="letter" :style="{background: type.color}">
										{{type.small}}
									</div>
									<div class="name">{{item.customerProjectName}}</div>
								</div>
								<v-btn class="currency-btn" :color="item.isBillable ? 'success' : 'error'" icon>
									<v-icon>mdi-currency-usd</v-icon>
								</v-btn>
							</div>
							<div class="d-flex my-5" style="gap: 10px">
								<user-avatar :user-id="item.userId" :size="40"></user-avatar>
								<div>
									<b>{{ item.userBlueTag }}</b>
									<div class="mt-n1">{{ item.userFullName }}</div>
								</div>
							</div>
							<div class="d-flex justify-space-between">
								<div>
									{{ item.startDate | formatDate }} > {{ item.endDate | formatDate }}
								</div>
								<div>Qty. {{ item.quantity }}</div>
							</div>
						</div>
					</div>
					<div v-else-if="variant === 'billable'">
						<div class="subtitle">Billable Code</div>
						<div class="body-1">{{ item.code }}</div>
					</div>
					<div v-else-if="variant === 'billableItem'">
						<div class="subtitle">Billable Item Code</div>
						<div class="body-1">{{ item.code }}</div>
					</div>
					<div v-else-if="variant === 'invoice'">
						<div class="subtitle">Invoice</div>
						<div class="body-1">{{ item.code }}</div>
					</div>
					<div v-else-if="variant === 'payment'">
						<div class="subtitle">Invoice</div>
						<div class="body-1">{{ item.reference }}</div>
					</div>
					<v-divider class="my-5"/>

					<div class="mb-3">
						<div class="subtitle mb-1">Activity</div>
						<v-btn-toggle
							v-model="activityFilter" color="primary"
							multiple dense borderless mandatory
						>
							<v-btn small class="rounded" style="text-transform: none">Comments</v-btn>
							<v-btn small class="ml-2 rounded" style="text-transform: none">History</v-btn>
						</v-btn-toggle>
					</div>
					
					<div v-if="urls.creation" class="d-flex align-center mb-3" style="gap: 5px">
						<v-text-field
							v-model="newComment"
							@keypress.enter="saveComment"
							label="Add a comment..."
							outlined dense hide-details single-line
						/>
						<v-btn @click="saveComment" color="primary">Save</v-btn>
					</div>

					<div v-if="!changeGroups.some(g => g.changes.some(showChange))" class="text-center" style="position: absolute; margin-top: 10em; width: 100%">
						<v-icon class="mb-2" :size="64">mdi-clock-check-outline</v-icon>
						<h2>
							No data found
						</h2>
					</div>
					<div v-for="group in changeGroups.filter(g => g.changes.some(showChange))" :key="group.id" class="change-group mb-2">
						<div class="user-info">
							<user-avatar :userId="group.createdById" hideLoading :size="45"/>
						</div>
						<div>
							<b>{{group.createdByBlueTag || group.createdByFullName}} </b>
							<span class="float-right"> {{group.dateTime | formatDateTime}} </span>
							<div class="d-flex flex-column" style="gap: 7px">
								<admin-surf-change v-for="change in group.changes.filter(showChange)" :key="change.id" :change="change"/>
							</div>
						</div>
					</div>
				</v-card-text>
			</v-card>
		</template>
	</side-panel>
</template>

<script>
import moment from "moment"
import AdminSurfChange from './AdminSurfChange'
import BillableJSON from '../json/billable.json'

export default {
	props: {
		value: Boolean,
		item: Object,
		variant: String,
		changes: Array,
		urls: { type: Object, default: () => ({}) },
	},
	components: { AdminSurfChange },
	data: () => ({
		innerValue: false,
		activityFilter: [0, 1],
		customerProjectTypes: [],
		newComment: '',
	}),
	mounted(){
		this.innerValue = this.value
		this.customerProjectTypes = BillableJSON.projectTypes
	},
	watch: {
		value(v) { this.innerValue = v }
 	},
	computed: {
		changeGroups(){
			if(!this.changes) return []
			let groups = {}
			for(let change of this.changes.filter(x => x.groupId && !x.deleted)){
				if(!groups[change.groupId]){
					groups[change.groupId] = {
						createdById: change.createdById,
						createdByBlueTag: change.createdByBlueTag,
						createdByFullName: change.createdByFullName,
						dateTime: moment(change.dateTime),
						changes: []
					}
				}
				groups[change.groupId].changes.push(change)
			}
			let array = _.toArray(groups)
			array.forEach(g => g.changes = g.changes.sort((a, b) => {
				if(a.changedProperty == 'comment') return -1
				if(b.changedProperty == 'comment') return 1
				return 0
			}))
			return array.sort((a, b) => {
				if(a.dateTime.isBefore(b.dateTime)) return 1
				if(b.dateTime.isBefore(a.dateTime)) return -1
				return 0
			})
		},
	},
	methods: {
		showChange(c){
			return (this.activityFilter.includes(0) && c.changedProperty === 'comment') ||
				(this.activityFilter.includes(1) && c.changedProperty !== 'comment')
		},
		saveComment(){
			if(!this.newComment) return
			if(!this.item?.id || this.item.id == '00000000-0000-0000-0000-000000000000' || this.item.isNew){
				this.$blueSystem.getCurrentUser()
					.then(user => {
						this.$emit('new-comment', {
							changedProperty: 'comment',
							createdByBlueTag: user.blueTag,
							createdByFullName: user.fullName,
							createdById: user.id,
							dateTime: moment().format('YYYY-MM-DD'),
							deleted: false,
							groupId: this.random(),
							newText: this.newComment,
							newValue: this.newComment,
						})
						this.newComment = ''
					})
				return
			}
			this.$http.post(this.urls.creation, { value: this.newComment })
				.then(res => {
					this.$emit('new-comment', res.data)
					this.newComment = ''
				})
				.catch(err => {
					console.error(err)
					this.$root.error('Failed to save comment')
				})
		},
	},
}
</script>

<style lang="scss" scoped>
.change-group{
	display: grid;
	grid-template-columns: 60px auto;

	.user-info{
		display: flex;
		height: 100%;
		flex-direction: column;
		align-items: center;
		gap: 10px;
	}
}

.currency-btn::before{
	opacity: .24;
}
</style>