<template>
	<div>
		<filter-search-input v-model="filters.search" :applied-filters="appliedFilters" outlined dense>
			<template #append>
				<v-btn v-if="$security.can('add')" @click="addValidationRule" color="primary" fab small depressed>
					<v-icon>mdi-plus</v-icon>
				</v-btn>
			</template>

			<q-filter-input
				v-model="filters.approvers"
				:items="users"
				dense outlined hide-details clearable multiple
				label="Approver" item-text="blueTag" item-value="id"
				:parent-shown-items="shownValidationRules"
				:data-comparer="(item, value) => item.userId == value"
			/>

			<v-select
				v-model="filters.entities"
				:items="entities"
				dense outlined hide-details clearable multiple
				label="Entity" item-text="name" item-value="value"
				:parent-shown-items="shownValidationRules"
				:data-comparer="(item, value) => item.entity = value"
			/>

			<q-filter-input
				v-model="filters.statuses"
				:items="allStatuses"
				dense outlined hide-details clearable input-select multiple
				label="Status" item-text="description" item-value="description"
				:parent-shown-items="shownValidationRules"
				:data-comparer="(item, value) => item.statusDisplayName == value"
			/>
		</filter-search-input>

		<data-table
			:headers="headers" :items="shownValidationRules"
			disable-sort
		>
			<template #[`item.user`]="{ item }">
				<q-user-autocomplete
					v-model="item.userId"
					:readonly="!$security.can('edit')"
					:items="users.filter(x => x.id === item.userId || (!validationRules.some(y => y.userId === x.id && y.entity === item.entity && y.status === item.status)))"
					@input="setApprover(item)"
					:confirmable="false" :open-user-card="false"
					flat solo dense hide-details
					background-color="transparent"
					class="hide-input inline-block"
				/>
			</template>

			<template #[`item.entity`]="{ item }">
				<v-autocomplete
					v-model="item.entity" :items="entities"
					:readonly="!$security.can('edit')"
					@input="setEntity(item)"
					flat solo dense hide-details
					background-color="transparent"
					class="hide-input"
					item-text="name" item-value="value"
				/>
			</template>
			
			<template #[`item.status`]="{ item }">
				<v-menu :disabled="!$security.can('edit')">
					<template #activator="{ on }">
						<v-btn
							v-on="on"
							:color="item.statusColor"
							outlined small style="border-radius: 20px"
							:style="{
								background: $vuetify.theme.isDark ? '' : item.statusLightColor
							}"
						>{{item.statusDisplayName}}</v-btn>
					</template>
					<v-card max-height="400" class="overflow-y-auto">
						<v-card-text class="pa-0">
							<v-list dense>
								<v-list-item
									v-for="status in statuses[item.entity].filter(x => x.id === item.status || (!validationRules.some(y => y.userId === item.userId && y.entity === item.entity && y.status === x.id)))"
									:key="status.id" @click="setStatus(item, status)"
								>
									<v-list-item-title>
										<v-btn
											:color="status.color"
											outlined small style="border-radius: 20px"
											:style="{
												background: $vuetify.theme.isDark ? '' : status.lightColor
											}"
										>{{status.description}}</v-btn>
									</v-list-item-title>
								</v-list-item>
							</v-list>
						</v-card-text>
					</v-card>
				</v-menu>
			</template>

			<template #[`item.actions`]="{ item }">
				<v-menu v-if="$security.can('delete')" left offset-x>
					<template #activator="{ on }">
						<v-btn v-on="on" icon><v-icon>mdi-dots-vertical</v-icon></v-btn>
					</template>
					<v-list dense class="py-1">
						<v-list-item class="pa-1 py-0">
							<v-tooltip right z-index="200">
								<template #activator="{ on }">
									<v-btn v-on="on" @click="deleteRule(item)" color="error" text small>
										<v-icon>mdi-delete</v-icon>
									</v-btn>
								</template>
								<span class="caption">Delete</span>
							</v-tooltip>
						</v-list-item>
					</v-list>
				</v-menu>
			</template>
		</data-table>
	</div>
</template>

<script>
export default {
	data: () => ({
		filters: {
			search: '',
			approvers: [],
			entities: [],
			statuses: [],
		},

		headers: [
			{ text: 'Bluetag', value: 'user' },
			{ text: 'Documents', value: 'entity' },
			{ text: 'Status', value: 'status', width: 400 },
			{ text: '', value: 'actions', width: 1 },
		],
		validationRules: [],
		users: [],
		entities: [
			{ name: 'Billables', value: 24 },
			{ name: 'Billable Items', value: 26 },
		],
		statuses: {
			24: [], // billables
			26: [], // billable items
		},

		userSearch: '',
	}),

	created() {
		this.$http.get('api/admin/validationRule')
			.then(res => {
				this.validationRules = res.data
			})
			.catch(err => {
				console.error(err)
				this.$root.error('Failed to fetch validation rules')
			})

		this.$http.get('api/user?onlyInterns=true&active=true')
			.then(res => {
				this.users = res.data
			})
			.catch(err => {
				console.error(err)
				this.$root.error('Failed to fetch users')
			})
		
			this.$http.get('api/enums/admin/billableItem/status')
			.then(res => {
				this.statuses[26] = res.data
					.map(x => ({ ...x, type: 'billableItem' }))
			})
			.catch(err => {
				console.error(err)
				this.$root.error('Failed to fetch statuses')
			})

		this.$http.get('api/enums/admin/billable/status')
			.then(res => {
				this.statuses[24] = res.data
					.map(x => ({ ...x, type: 'billable' }))
			})
			.catch(err => {
				console.error(err)
				this.$root.error('Failed to fetch statuses')
			})
	},

	computed: {
		shownValidationRules() {
			let newRules = this.validationRules.filter(x => !x.id)
			let rules = this.validationRules.filter(x => x.id)

			if(this.filters.approvers.length)
				rules = rules.filter(rule => this.filters.approvers.includes(rule.userId))
			if(this.filters.entities.length)
				rules = rules.filter(rule => this.filters.entities.includes(rule.entity))
			if(this.filters.statuses.length)
				rules = rules.filter(rule => this.filters.statuses.includes(rule.statusDisplayName))

			if(this.filters.search) {
				rules = rules.filter(rule =>
					(rule.userBlueTag+rule.userFullName).toLowerCase().includes(this.filters.search.toLowerCase()) ||
					(rule.entity && this.entities.find(x => x.value == rule.entity).name.toLowerCase().includes(this.filters.search.toLowerCase()))
				)
			}

			return newRules.concat(rules)
		},
		shownUsers() {
			if(!this.userSearch)
				return this.users
			return this.users
				.filter(user =>
					(user.blueTag + user.fullName)
						.includes(this.userSearch)
				)
		},
		allStatuses() {
			return [
				...this.statuses[24],
				...this.statuses[26],
			]
				.sort((a, b) => a.id > b.id ? -1 : 1)
		},
		appliedFilters(){
			return this.countActiveFilters(this.filters, ['search'])
		},
	},

	methods: {
		save(validationRule) {
			if(!validationRule.userId || !validationRule.entity)
				return

			return this.$http.post('api/admin/validationRule', validationRule)
				.then(res => {
					this.$set(validationRule, 'id', res.data.id)
				})
				.catch(err => {
					if(!err.notified)
						this.$root.error('Failed to save changes')
					console.error(err)
				})
		},
		deleteRule(validationRule) {
			this.$openConfirmDialog({
				title: 'Delete Validation',
				description: 'Are you sure you want to delete this validation rule?',
			})
				.then(res => {
					if(!res)
						return

					if(!validationRule.id)
						return this.validationRules = this.validationRules.filter(x => x != validationRule)

					this.$http.delete('api/admin/validationRule/' + validationRule.id)
						.then(res => {
							this.validationRules = this.validationRules.filter(x => x.id != validationRule.id)
						})
						.catch(err => {
							console.error(err)
							this.$root.error('Failed to delete validation rule')
						})
				})
		},

		addValidationRule() {
			let status = this.statuses[26]
				.find(x => x.id == 0)
			this.validationRules.unshift({
				userId: '',
				entity: 26,
				status: status.id,
				statusDisplayName: status.description,
				statusColor: status.color,
				statusLightColor: status.lightColor
			})
		},
		setApprover(validationRule) {
			let user = this.users.find(x => x.id == validationRule.userId)
			validationRule.userId = user.id
			validationRule.userBlueTag = user.blueTag
			validationRule.userFullName = user.fullName
			this.save(validationRule)
		},
		setEntity(validationRule) {
			let entity = this.entities.find(x => x.value == validationRule.entity)
			let status = this.statuses[entity.value]
				.find(x => x.id == 0)

			validationRule.entity = entity.value
			this.save(validationRule)
			this.setStatus(validationRule, status)
		},
		setStatus(validationRule, status) {
			validationRule.status = status.id
			validationRule.statusDisplayName = status.description
			validationRule.statusColor = status.color
			validationRule.statusLightColor = status.lightColor
			this.save(validationRule)
		},
	},
}
</script>

<style lang="scss" scoped>

::v-deep .v-data-table tbody {
	.v-input__slot {
		box-shadow: none !important;
	}
	.v-input__append-inner{
		display: none;
	}
}
</style>