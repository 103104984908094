<template>
	<div>
		<div
			class="grid body-2"
			:class="{'pl-5': !pathToChild}"
			:style="{
				color: 'var(--v-text-lighten)',
				gridTemplateColumns: mainTemplateColumns
			}"
		>
			<div v-for="(header, ix) in mainHeaders" :key="ix">
				{{header.text}}
			</div>
		</div>
		<v-expansion-panels class="mt-2" multiple flat :readonly="!pathToChild">
			<v-expansion-panel
				v-for="(item, ix) in items" :key="ix"
				class="bordered panel new-back lighten2"
				:class="{ 'rounded-t-lg': !ix, 'rounded-b-lg': ix === items.length - 1 }"
				active-class="active-panel"
			>
				<v-expansion-panel-header class="py-3 px-0" style="min-height: 60px" hide-actions>
					<div
						class="grid" :class="{'pl-5': !pathToChild}"
						:style="{gridTemplateColumns: mainTemplateColumns}"
					>
						<div v-for="({value}, jx) in mainHeaders" :key="jx">
							<slot :name="`item.${value}`" v-bind="item">
								{{item[value]}}
							</slot>
						</div>
					</div>

					<v-icon v-if="!!pathToChild" class="chevron">mdi-chevron-down</v-icon>
				</v-expansion-panel-header>
				<v-expansion-panel-content
					v-if="!!pathToChild"
					class="px-0 pt-3" :class="{'new-back': $vuetify.theme.isDark}"
					style="background: var(--v-lightgray-lighten2)"
				>
					<div style="margin-left: -24px; margin-right: -24px">
						<div
							class="grid caption text--text"
							:style="{
								color: 'var(--v-text-lighten)',
								gridTemplateColumns: secondaryTemplateColumns
							}"
						>
							<div v-for="(header, jx) in secondaryHeaders" :key="jx">
								{{header.text}}
							</div>
						</div>
						<div
							v-for="(subitem, jx) in pathToChild(item)" :key="jx"
							class="grid my-2" :style="{gridTemplateColumns: secondaryTemplateColumns}"
						>
							<div v-for="({value}, kx) in secondaryHeaders" :key="kx">
								<slot :name="`subitem.${value}`" v-bind="subitem">
									{{subitem[value]}}
								</slot>
							</div>
						</div>
					</div>
				</v-expansion-panel-content>
			</v-expansion-panel>
		</v-expansion-panels>
	</div>
</template>

<script>
export default {
	props: {
		items: Array,
		pathToChild: {type: Function, default: null},
		mainHeaders: Array,
		secondaryHeaders: Array,
		mainTemplateColumns: String,
		secondaryTemplateColumns: String,
	},
}
</script>

<style lang="scss" scoped>
.grid{
	display: grid;
	padding-left: 60px;

	div{
		display: inline-flex;
		align-items: center;
	}
}
.chevron{
	border: 1px solid lightgray;
	border-radius: 5px;
	position: absolute;
	left: 20px;
}
::v-deep .active-panel .chevron{
	transform: rotate(-180deg) !important;
}
</style>