<template>
	<blue-confirm-dialog ref="dialog" >
		<template #text>
			<div>
				<p>
					The following balance in favor will be saved to the payment. Please review and confirm.
				</p>

				<div class="d-flex">
					<div class="flex">
						Balance in Favor: <br>
						<b class="headline">
							{{ currencyFormat(balanceInFavor) }}
						</b>
					</div>
				</div>
			</div>
		</template>
		<template #actions="{ cancel, confirm }">
			<v-spacer/>
			<v-btn @click="cancel" text>Cancel</v-btn>
			<v-btn @click="_confirm(confirm)" color="primary">Save Balance in Favor</v-btn>
		</template>
	</blue-confirm-dialog>
</template>

<script>
export default {
	data: () => ({
		id: null,
		balanceInFavor: 0,
	}),
	methods: {
		async open(id){
			this.id = id
			try {
				const res = await this.$http.get(`api/payment/${id}/balanceInFavor/generate`)
				this.balanceInFavor = res.data
				return this.$refs.dialog.open({
					title: 'Save Balance in Favor',
					description: 'We have detected a mismatch between the total of this payment and its related invoice(s).',
					width: 700,
				})
			} catch (err) {
				if(err.notified) return
				console.error(err)
				this.$root.error('Failed to generate balance in favor')
			}
		},
		async _confirm(callback){
			try {
				await this.$http.post(`api/payment/${this.id}/balanceInFavor`)
				callback()
			} catch (err) {
				console.error(err)
				this.$root.error('Failed to save balance in favor')
			}
		},
	},
}
</script>

<style lang="scss" scoped>

</style>