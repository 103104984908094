<template>
	<div class="change">
		<span>
			<span v-if="listProperties.includes(change.changedProperty)">
				{{ change.originalValue ? 'removed' : 'added' }}
				<b>{{ camelToNormal(displayNames[change.changedProperty] || change.changedProperty).toLowerCase() }}</b>
			</span>
			<span v-else-if="change.changedProperty != 'comment' && change.changedProperty != 'createdBy'">
				changed <b>{{ camelToNormal(displayNames[change.changedProperty] || change.changedProperty).toLowerCase() }}</b>:
			</span>
		</span>
		<div v-if="change.changedProperty == 'comment'">
			<div class="background--colored pa-2">
				{{change.originalText || change.newText}}
			</div>
		</div>
		<div v-else-if="change.changedProperty == 'createdBy'">
			<div class="background--colored pa-2 mt-1 d-inline-block" v-html="change.originalText"></div>
		</div>
		<div v-else-if="listProperties.includes(change.changedProperty)">
			<div class="inline-block background--colored pa-2">
				{{change.originalText || change.newText}}
			</div>
		</div>
		<div v-else-if="dateProperties.includes(change.changedProperty)" class="diffs">
			<p class="background--colored pa-2 mb-0">{{ change.originalText | formatDate }}</p>
			<v-icon>mdi-arrow-right</v-icon>
			<p class="background--colored pa-2 mb-0">{{ change.newText | formatDate }}</p>
		</div>
		<div v-else class="diffs">
			<p class="background--colored pa-2 mb-0" v-html="change.originalText"></p>
			<v-icon>mdi-arrow-right</v-icon>
			<p class="background--colored pa-2 mb-0" v-html="change.newText"></p>
		</div>
	</div>
</template>

<script>
export default {
	props: ['change'],
	data: () => ({
		displayNames: {
			customerId: 'customer',
			billableId: 'billable',
			issueDate: 'invoice date',
			paymentDay: 'due date',
			rfcId: 'RFC',
			wayToPay: 'payment type',
			wayToPay: 'payment type',
		},
		dateProperties: [
			'deliveryDate',
			'issueDate',
			'paymentDay',
			'startDate',
			'endDate',
			'date',
		],
		listProperties: [
			'line',
			'billableItem',
			'file',
			'seat',
			'invoice',
			'link',
			'adjustment',
			'balanceInFavor',
		],
	}),
}
</script>

<style lang="scss" scoped>
.diffs{
	display: grid;
	grid-template-columns: 1fr 20px 1fr;
	gap: 10px;
}
</style>