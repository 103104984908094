import Vue from 'vue'
import Vuetify from 'vuetify/lib'
import 'material-design-icons-iconfont/dist/material-design-icons.css'
import vuetifyLoader from "vuetify-vuejs-loader"
import {VDialog,VCard,VCardText,VBtn,VSpacer,VCardTitle,VCardActions} from "vuetify/lib"
Vue.use(Vuetify, {
  components: {VDialog,VCard,VCardText,VBtn,VSpacer,VCardTitle,VCardActions},
});
Vue.use(vuetifyLoader);

const vuetifyOptions = {
  icons: {
    iconfont: 'md', 
  },
  theme: {
    options: {
      customProperties: true,
    },
    themes: {
      light: {
        primary: "#167bd7",
        secondary: "#7986CB",
        accent: {
          base: "#03A9F4",
          lighten: "#EBF5FB",
        },
        error: {
          base: "#f44336",
          lighten: "#ff9595",
          darken: '#b52323',
        },
        warning: {
          base: "#eed202",
          darken: "#FF9100"
        },
        info: "#2196f3",
        success: {
          base: "#4caf50",
          lighten: "#64DD17",
        },
        coloredBack: "#E3F2FD",
        text: {
          base: "#000",
          lighten: "#888",
          lighten2: "#666",
        },
        
        background: {
          base: "#fff",
          lighten: "#fff",
          lighten2: "#eee",
          lighten3: "#f7f9f9",
        },
        secondaryBackground: '#fafcfe',
        navBar: '#fafcfe',
        menu: {
          base: "#5099b4",
          lighten: "#52a7b4",
          lighten2: "#424eb2",
        },
        scroll: {
          base: "#fff",
          lighten: "#d1d1d1",
          lighten2: "#d1d1d1",
        },
        kanban: {
          base: "#eee",
        },
        card: {
          base: "#fff",
          lighten: "#666",
        },
        lightgray: {
          base: '#d3d3d3',
          lighten: '#eee',
          lighten2: '#fafafa',
          darken: '#929191',
        },
        newDesignBackground: {
          base: '#fff',
          lighten: "#E0EDFE",
          lighten2: '#fff',
        },
        newDesignText: {
          base: '#1f1f1f'
        },
        newDesignPrimary: '#0B7EF4',
        'high-back': '#fff',
      },
      dark: {
        primary: "#167bd7",
        secondary: "#7986CB",
        accent: {
          base: "#03A9F4",
          lighten: "#1F292F",
        },
        error: {
          base: "#d43a2f",
          lighten: "#db7f7f",
          darken: '#b52323',
        },
        warning: {
          base: "#c7b72e",
          darken: "#F57C00",
        },
        info: "#2196f3",
        success: {
          base: "#39823c",
          lighten: "#4CAF50",
        },
        coloredBack: "#37474F",
        text: {
          base: "#fff",
          lighten: "#999",
          lighten2: "#ccc",
        },

        background: {
          base: "#121212",
          lighten: "#282828",
          lighten2: "#424242",
          lighten3: "#424242",
        },
        secondaryBackground: '#1e1e2c',
        navBar: '#1E1E2C',
        menu: {
          base: "#424242",
          lighten: "#424242",
          lighten2: "#424242",
        },
        scroll: {
          base: "#121212",
          lighten: "#424242",
          lighten2: "#424242",
        },

        kanban: {
          base: "#282828",
        },
        card: {
          base: "#1E1E1E",
          lighten: "#dedede",
        },
        lightgray: {
          base: '#404040',
          lighten: '#888',
          lighten2: '#111',
          darken: '#929191',
        },
        newDesignBackground: {
          base: '#1E1E2C',
          lighten: "#E0EDFE",
          lighten2: '#1E1E3A',
        },
        newDesignText: {
          base: '#ffffff',
          lighten: "#E0EDFE",
        },
        newDesignPrimary: '#0B7EF4',
        'high-back': '#374151',
      },
    },
  },
}


export default new Vuetify(vuetifyOptions)
