<template>
  <div>
    <h2 class="mb-3">
      Tags
      <v-btn
        @click="openNewTagDialog"
        :disabled="!$parent.canEditProject"
        x-small fab depressed color="primary"
      >
        <v-icon dark>add</v-icon>
      </v-btn>
    </h2>

    <data-table
      :headers="headers"
      :items="$parent.config.tags"
      sort-by="sortIndex"
    >
      <template #[`item.sortIndex`]="{ item }">
        <v-btn
          @click="moveTagUp(item.id)"
          text icon x-small color="primary" class="sort-icon"
          :disabled="!$parent.canEditProject"
        >
          <v-icon class="cursor-pointer">arrow_drop_up</v-icon>
        </v-btn>
        <br />
        <v-btn
          @click="moveTagDown(item.id)"
          text icon x-small color="primary" class="sort-icon"
          :disabled="!$parent.canEditProject"
        >
          <v-icon class="cursor-pointer">arrow_drop_down</v-icon>
        </v-btn>
      </template>
      <template #[`item.name`]="{ item }">
        <v-chip
          small dark :color="item.color"
          :style="`color: ${isBlackText(item.color) ? 'white' : 'black'}`"
        >
          {{ item.name }}
        </v-chip>
      </template>
      <template #[`item.description`]="{ item }">
        <div :style="{ minWidth: '120px', maxWidth: '240px' }" class="tag-desc">
          {{ item.description ? JSON.parse(item.description) : "" }}
        </div>
      </template>
      <template #[`item.types`]="{ item }">
        <div
          v-for="(typeValue, ix) in item.types"
          :key="ix"
          :set="(type = $parent.types.find((t) => t.value == typeValue) || {})"
          class="inline-block mr-2 mb-1 rounded pa-2"
        >
          <v-icon :color="type.color" class="mr-0">{{ type.iconClass }}</v-icon>
          {{ type.displayName }}
        </div>
      </template>
      <template #[`item.active`]="{ item }">
        <v-icon v-if="item.active">check</v-icon>
        <v-icon v-if="!item.active">close</v-icon>
      </template>
      <template #[`item.actions`]="{ item }">
        <div class="nobr">
          <v-btn
            @click="deleteTag(item.id)"
            :disabled="!$parent.canEditProject"
            depressed outlined icon fab x-small color="primary"
          >
            <v-icon>delete</v-icon>
          </v-btn>
          <v-btn
            @click="openEditTagDialog(item)"
            :disabled="!$parent.canEditProject"
            fab x-small color="primary"
          >
            <v-icon>edit</v-icon>
          </v-btn>
        </div>
      </template>
    </data-table>

    <create-tag-dialog ref="createTagDialog" :callback="() => $emit('saved')" />
  </div>
</template>

<script>
import CreateTagDialog from "../../dialogs/CreateTagDialog"

export default {
  components: { CreateTagDialog },
  name: 'ProjectConfigTags',
  data: () => ({
    headers: [
      { text: "Sort Index", value: "sortIndex", align: "center", width: 120 },
      { text: "Tag", value: "name" },
      { text: "Description", value: "description" },
      { text: "For Types", value: "types", align: "left" },
      { text: "Active", value: "active", align: "left" },
      { value: "actions", class: "nobr", width: 1, sortable: false },
    ],
  }),
  methods: {
    deleteTag(projectTagId) {
      this.$q
        .confirmDelete({
          api: `/api/projectTag/${projectTagId}`,
          messageBody: "Are you sure you want to delete this tag?",
          successMsg: "Tag deleted",
          errorMsg: "The tag was not deleted.",
        })
        .then((res) => {
          this.$emit('saved')
        });
    },
    openNewTagDialog() {
      this.$refs.createTagDialog.open(this.$parent.project.id)
    },
    openEditTagDialog(tag) {
      let project = this.$q.getParentVariable("ProjectDetails", "item");
      this.$refs.createTagDialog.open(project.id, _.clone(tag, true));
    },
    moveTagUp(id) {
      this.$http
        .post(`/api/projectTag/${id}/MoveToPreviousPosition`)
        .then((res) => {
          this.config.tags = res.data;
          this.config.tags.forEach((tag) => {
            tag.types = tag.types.filter((t) =>
              this.boardConfigs.some((c) => c.workItemType == t)
            );
          });
          window.getApp.success("Order updated.");
        })
        .catch((res) => {
          window.getApp.error("The order was not changed.");
          console.error(res);
        });
    },
    moveTagDown(id) {
      this.$http
        .post(`/api/projectTag/${id}/MoveToNextPosition`)
        .then((res) => {
          this.config.tags = res.data;
          this.config.tags.forEach((tag) => {
            tag.types = tag.types.filter((t) =>
              this.boardConfigs.some((c) => c.workItemType == t)
            );
          });
          window.getApp.success("Order updated.");
        })
        .catch((res) => {
          window.getApp.error("The order was not changed.");
          console.error(res);
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.tag-desc {
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}
</style>