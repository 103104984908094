var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(_vm.infinite ? 'infinite-data-table' : 'data-table',_vm._g(_vm._b({tag:"infinite-data-table",attrs:{"value":_vm.value,"headers":_vm.shownHeaders,"items":_vm.items,"server-items-length":_vm.totalItems,"total-items":_vm.totalItems},on:{"#input":function($event){return _vm.$emit('input', $event)}},scopedSlots:_vm._u([{key:"header.actualWorkingHours",fn:function(){return [_vm._v(" Actual"),_c('br'),_c('span',{staticClass:"header-span"},[_vm._v("Working Hours")])]},proxy:true},{key:"header.totalWorkingHours",fn:function(){return [_vm._v(" Total"),_c('br'),_c('span',{staticClass:"header-span"},[_vm._v("Working Hours")])]},proxy:true},{key:"header.months",fn:function(){return [_vm._v(" Months"),_c('br'),_c('span',{staticClass:"header-span"},[_vm._v("Base 168 hrs")])]},proxy:true},{key:"header.weeks",fn:function(){return [_vm._v(" Weeks"),_c('br'),_c('span',{staticClass:"header-span"},[_vm._v("Base 168 hrs")])]},proxy:true},{key:"item.userBlueTag",fn:function(ref){
var item = ref.item;
return [(_vm.readonly || _vm.disabledHeaders.includes('userBlueTag'))?_c('span',[_vm._v(_vm._s(item.userBlueTag))]):_c('v-autocomplete',{staticClass:"fill-height align-center body-2 hide-input",attrs:{"value":item.userId,"items":_vm.users,"item-value":"id","item-text":"blueTag","background-color":"transparent","flat":"","solo":"","hide-details":"","dense":""},on:{"change":function($event){return _vm.$emit('user-changed', item, $event)}},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.blueTag)+" ")]}},{key:"item",fn:function(ref){
var item = ref.item;
return [_c('v-list-item-avatar',[_c('user-avatar',{attrs:{"user-id":item.id,"hide-loading":""}})],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(_vm._s(item.blueTag))]),_c('v-list-item-subtitle',[_vm._v(_vm._s(item.fullName))])],1)]}}],null,true)})]}},{key:"item.billableCode",fn:function(ref){
var item = ref.item;
return [(_vm.readonly || _vm.disabledHeaders.includes('billableCode'))?_c('span',[_vm._v(_vm._s(item.billableCode))]):_c('v-tooltip',{attrs:{"disabled":!item.billableCustomerName,"right":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('div',_vm._g({staticClass:"billable-autocomplete"},on),[_c('v-autocomplete',{staticClass:"fill-height align-center body-2",staticStyle:{"width":"170px"},attrs:{"value":item.billableId,"items":_vm.billables,"item-value":"id","item-text":"code","background-color":"transparent","flat":"","solo":"","hide-details":"","dense":""},on:{"change":function($event){return _vm.$emit('billable-changed', item, $event)}},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var item = ref.item;
return [(item.isCancelled || item.isClosed)?_c('div'):_c('div',{staticClass:"d-flex align-center fill-height body-2",staticStyle:{"min-height":"32px"}},[_vm._v(_vm._s(item.code))])]}}],null,true)})],1)]}}],null,true)},[_vm._v(" "+_vm._s(item.billableCustomerName)+" ")])]}},{key:"item.billableLineRole",fn:function(ref){
var item = ref.item;
return [(_vm.readonly || _vm.disabledHeaders.includes('billableLineRole'))?_c('span',[_vm._v(_vm._s(item.billableLineRole || item.otherBillableLine))]):(item.billableId)?_c('v-select',{staticClass:"fill-height align-center body-2 hide-input",attrs:{"value":item.billableLineId,"items":_vm.billableLines.filter(function (bl) { return bl.billableId == item.billableId; }),"item-value":"id","item-text":"role","background-color":"transparent","flat":"","solo":"","hide-details":"","dense":""},on:{"change":function($event){return _vm.$emit('register-change', item, 'billableLineId', $event)}},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"right":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('div',_vm._g({staticStyle:{"width":"100%","min-width":"150px"}},on),[_c('div',{staticClass:"select-item"},[_c('span',{staticClass:"caption"},[_vm._v("Seat "+_vm._s(item.seat))]),_c('div',{staticClass:"d-flex align-center justify-space-between",staticStyle:{"gap":"10px"}},[_vm._v(" "+_vm._s(item.role)+" "),_c('span',[_vm._v(_vm._s(_vm.currencyFormat(item.ratePerHour)))])])]),_c('billable-percentage-bar',{attrs:{"primaryAmount":item.assignedHours,"objective":item.hours}})],1)]}}],null,true)},[_c('div',[_vm._l((item.assignments.slice(0, 20)),function(h,ix){return _c('div',{key:ix},[_vm._v(" "+_vm._s(h.userBlueTag)+" - "+_vm._s(h.hours)+" hrs. ")])}),(item.assignments.length > 20)?_c('div',[_vm._v("...")]):_vm._e()],2)])]}},{key:"selection",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.role)+" ")]}}],null,true)}):_c('v-text-field',{staticClass:"fill-height align-center body-2",attrs:{"value":item.otherBillableLine,"background-color":"transparent","flat":"","solo":"","hide-details":"","dense":""},on:{"change":function($event){return _vm.$emit('register-change', item, 'otherBillableLine', $event)}}})]}},{key:"item.customerProjectName",fn:function(ref){
var item = ref.item;
return [(_vm.readonly || _vm.disabledHeaders.includes('customerProjectName'))?_c('div',{staticClass:"py-1",attrs:{"set":_vm.type = _vm.projectTypes.find(function (t) { return t.value == item.customerProjectType; })}},[_c('div',{staticClass:"admin-project",style:({background: _vm.type.lightColor})},[_c('div',{staticClass:"letter",style:({background: _vm.type.color})},[_vm._v(" "+_vm._s(_vm.type.small)+" ")]),_c('div',{staticClass:"name"},[_vm._v(_vm._s(item.customerProjectName))])])]):_c('v-tooltip',{attrs:{"disabled":!item.billableCustomerName,"right":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('div',_vm._g({},on),[_c('v-select',{staticClass:"fill-height align-center body-2 hide-input",attrs:{"value":item.customerProjectId,"items":_vm.customerProjects.filter(function (p) { return !item.billableId || p.customerId == item.billableCustomerId; }),"item-value":"id","item-text":"name","background-color":"transparent","flat":"","solo":"","hide-details":"","dense":""},on:{"change":function($event){return _vm.$emit('customer-project-changed', item, $event)}},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var project = ref.item;
return [_c('div',{staticClass:"py-1",attrs:{"set":_vm.type = _vm.projectTypes.find(function (t) { return t.value == project.type; })}},[_c('div',{staticClass:"admin-project",style:({background: _vm.type.lightColor})},[_c('div',{staticClass:"letter",style:({background: _vm.type.color})},[_vm._v(" "+_vm._s(_vm.type.small)+" ")]),_c('div',{staticClass:"name"},[_vm._v(_vm._s(project.name))])])])]}},{key:"selection",fn:function(ref){
var project = ref.item;
return [_c('div',{staticClass:"py-1",attrs:{"set":_vm.type = _vm.projectTypes.find(function (t) { return t.value == project.type; })}},[_c('div',{staticClass:"admin-project",style:({background: _vm.type.lightColor})},[_c('div',{staticClass:"letter",style:({background: _vm.type.color})},[_vm._v(" "+_vm._s(_vm.type.small)+" ")]),_c('div',{staticClass:"name"},[_vm._v(_vm._s(project.name))])])])]}}],null,true)})],1)]}}],null,true)},[_vm._v(" "+_vm._s(item.billableCustomerName)+" ")])]}},{key:"item.quantity",fn:function(ref){
var item = ref.item;
return [(_vm.readonly || _vm.disabledHeaders.includes('quantity'))?_c('span',[_vm._v(_vm._s(item.quantity))]):_c('v-text-field',{staticClass:"fill-height align-center body-2 center-input",attrs:{"value":item.quantity,"background-color":"transparent","flat":"","solo":"","hide-details":"","dense":"","type":"number","step":".5"},on:{"change":function($event){return _vm.$emit('register-change', item, 'quantity', $event)}}})]}},{key:"item.isBillable",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{staticClass:"currency-btn",attrs:{"color":item.isBillable ? 'success' : 'error',"icon":""},on:{"click":function($event){(item.billableType !== 3 && !_vm.readonly && !_vm.disabledHeaders.includes('isBillable')) ? _vm.$emit('register-change', item, 'isBillable', !item.isBillable) : null}}},[_c('v-icon',[_vm._v("mdi-currency-usd")])],1)]}},{key:"item.timeStatus",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"py-1"},[_c('v-btn',{staticStyle:{"border-radius":"20px"},style:({
					background: _vm.$vuetify.theme.isDark ? '' :
						item.timeStatusLightColor
				}),attrs:{"color":item.timeStatusColor,"outlined":"","x-small":""}},[_vm._v(_vm._s(item.timeStatusName))])],1)]}},{key:"item.status",fn:function(ref){
				var item = ref.item;
return [_c('div',{staticClass:"py-1"},[_c('v-btn',{staticStyle:{"border-radius":"20px"},style:({
					background: _vm.$vuetify.theme.isDark ? '' : item.statusLightColor
				}),attrs:{"color":item.statusColor,"outlined":"","x-small":""}},[_vm._v(_vm._s(item.statusName))])],1)]}},{key:"item.startDate",fn:function(ref){
				var item = ref.item;
return [(_vm.readonly || _vm.disabledHeaders.includes('startDate'))?_c('span',[_vm._v(_vm._s(_vm._f("formatDate")(item.startDate)))]):_c('v-text-field',{staticClass:"fill-height align-center body-2 center-input",attrs:{"value":_vm._f("formatDate")(item.startDate),"background-color":"transparent","readonly":"","flat":"","solo":"","hide-details":"","dense":""},on:{"click":function($event){return _vm.$emit('open-date-picker', item, 'startDate', $event)}}})]}},{key:"item.endDate",fn:function(ref){
				var item = ref.item;
return [(_vm.readonly || _vm.disabledHeaders.includes('endDate'))?_c('span',[_vm._v(_vm._s(_vm._f("formatDate")(item.endDate)))]):_c('v-text-field',{staticClass:"fill-height align-center body-2 center-input",attrs:{"value":_vm._f("formatDate")(item.endDate),"background-color":"transparent","readonly":"","flat":"","solo":"","hide-details":"","dense":""},on:{"click":function($event){return _vm.$emit('open-date-picker', item, 'endDate', $event)}}})]}},{key:"item.calendars",fn:function(ref){
				var item = ref.item;
return [_c('div',{staticStyle:{"width":"100px"}},_vm._l((item.calendars),function(calendar,ix){return _c('div',{key:ix,staticStyle:{"white-space":"nowrap","text-overflow":"ellipsis","overflow":"hidden"}},[_c('div',{staticClass:"dot",style:({ background: calendar.color })}),_c('span',{staticClass:"text--text caption"},[_vm._v(" "+_vm._s(calendar.name)+" ")])])}),0)]}},{key:"item.months",fn:function(ref){
				var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("number")(item.months,2))+" ")]}},{key:"item.weeks",fn:function(ref){
				var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("number")(item.weeks,2))+" ")]}},{key:"item.actions",fn:function(ref){
				var item = ref.item;
return [_c('v-menu',{attrs:{"left":"","offset-x":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
				var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"icon":""}},on),[_c('v-icon',[_vm._v("mdi-dots-vertical")])],1)]}}],null,true)},[_c('v-list',{staticClass:"py-1",attrs:{"dense":""}},[_c('v-list-item',{staticClass:"pa-1 py-0"},[_c('v-tooltip',{attrs:{"right":"","z-index":"200"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
				var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"icon":""},on:{"click":function($event){return _vm.$adminSurf('openHistory', item, 'assignment')}}},on),[_c('v-icon',[_vm._v("mdi-history")])],1)]}}],null,true)},[_c('span',{staticClass:"caption"},[_vm._v("History")])])],1),_c('v-list-item',{staticClass:"pa-1 py-0"},[_c('v-tooltip',{attrs:{"right":"","z-index":"200"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
				var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"icon":"","color":"error"},on:{"click":function($event){return _vm.$emit('delete-assignment', item)}}},on),[_c('v-icon',[_vm._v("mdi-delete")])],1)]}}],null,true)},[_c('span',{staticClass:"caption"},[_vm._v("Delete")])])],1)],1)],1)]}}],null,true)},'infinite-data-table',_vm.$attrs,false),_vm.$listeners))}
var staticRenderFns = []

export { render, staticRenderFns }