<template>
  <work-items-panel/>
</template>

<script>
import _ from "lodash"
import moment from 'moment'
import WorkItemsPanel from "./../../components/panels/WorkItemsPanel"

export default {
  title: 'Work Items',
  components: {
    WorkItemsPanel,
  },
  data: () => ({
  }),
  watch: {
  },
  created() {
    this.init()
    this.$q.log(0, 0, 3, 'NA')
  },
  computed: {
  },
  methods: {
    init() {
    },
  }

}
</script>

<style scoped lang="scss">

</style>