<template>
	<div class="py-2 px-10 new-back full-height">
		<div class="d-none">
      <input
				type="file" id="fileUploader" ref="fileUploader"
				@change="handleFileUpload"
			/>
		</div>

		<div
			v-if="editing"
			class="d-flex align-center px-5 top-btn"
			:style="{background: $vuetify.theme.isDark ? 'var(--v-newDesignBackground-base)' : '#fafcfe'}"
		>
			<v-btn
				@click="save(true)" color="primary"
				outlined
			>
				Save Draft
			</v-btn>
			<v-btn @click="save(false)" color="primary">Save Invoice</v-btn>
		</div>

		<div class="text-right mb-2">
			<v-menu v-if="!isNew && permissions.actions" z-index="200">
				<template #activator="{ on }">
					<v-btn v-on="on" color="primary" outlined>
						Actions
						<v-icon right>mdi-chevron-down</v-icon>
					</v-btn>
				</template>
				<v-list>
					<v-list-item v-if="!editing" @click="editing = true" class="py-0 px-1">
						<v-list-item-content class="px-3">
							<v-list-item-title class="px-0">
								<v-icon left color="primary">mdi-pencil</v-icon> Edit invoice
							</v-list-item-title>
						</v-list-item-content>
					</v-list-item>
					<v-list-item @click="$adminSurf('invoiceToPdf', item)" class="py-0 px-1">
						<v-list-item-content class="px-3">
							<v-list-item-title class="px-0">
								<v-icon left color="#ff6347">mdi-file-pdf-box</v-icon> Export to PDF
							</v-list-item-title>
						</v-list-item-content>
					</v-list-item>
					<v-list-item @click="$adminSurf('openHistory', item, 'invoice')" class="py-0 px-1">
						<v-list-item-content class="px-3">
							<v-list-item-title class="px-0">
								<v-icon left color="secondary">mdi-clock-outline</v-icon> Version history
							</v-list-item-title>
						</v-list-item-content>
					</v-list-item>
					<v-list-item @click="cancelInvoice" class="py-0 px-1">
						<v-list-item-content class="px-3">
							<v-list-item-title class="px-0">
								<v-icon left color="error">mdi-cancel</v-icon>
								Cancel Invoice
							</v-list-item-title>
						</v-list-item-content>
					</v-list-item>
					<watchers-menu
						@watcher-added="item.subscribers.push($event)"
						@watcher-removed="item.subscribers.splice(item.subscribers.findIndex(x => x.userId == $event), 1)"
						:entity-id="item.id"
						:watchers="item.subscribers"
						required-permission="invoices"
					>
						<template #default="{ on }">
							<v-list-item v-on="on" class="py-0 px-1">
								<v-list-item-content class="px-3">
									<v-list-item-title class="px-0">
										<v-icon left>mdi-eye</v-icon>
										Watchers
									</v-list-item-title>
								</v-list-item-content>
							</v-list-item>
						</template>
					</watchers-menu>
				</v-list>
			</v-menu>
		</div>

		<v-form ref="invoiceForm" v-model="validForm">
			<v-row style="max-width: 1400px; margin-left: auto; margin-right: auto">
				<v-col :cols="12">
					<v-row v-if="!isNew">
						<v-col>
							<div
								class="indicator new-back lighten2 elevation-1 rounded"
							>	
								<v-icon
									color="primary"
									:style="{background: $vuetify.theme.isDark ? '' : '#e8f2fb'}"
								>
									mdi-bank-outline
								</v-icon>
								<div> <h3>{{ currencyFormat(item.total) }}</h3> <span>Total value</span> </div>
							</div>
						</v-col>
						<v-col>
							<div
								class="indicator new-back lighten2 elevation-1 rounded"
							>
								<v-btn :color="$vuetify.theme.isDark ? 'transparent' : '#e6f4e5'" small depressed fab>
									<v-icon color="success" class="mt-n1">
										mdi-cash-multiple
									</v-icon>
								</v-btn>
								<div> <h3>{{ currencyFormat(collectedBalance) }}</h3> <span>Total Paid</span> </div>
							</div>
						</v-col>
						<v-col>
							<div class="indicator new-back lighten2 elevation-1 rounded">
								<v-btn :color="$vuetify.theme.isDark ? 'transparent' : '#fcdbdb'" small depressed fab>
									<v-icon color="error" class="mt-n1">
										mdi-cash-multiple
									</v-icon>
								</v-btn>
								<div> <h3>{{ currencyFormat(item.total - collectedBalance) }}</h3> <span>Pending to Pay</span> </div>
							</div>
						</v-col>
					</v-row>
				</v-col>

				<v-col :cols="12">
					<div class="bordered rounded-lg pa-5">
						<h4 class="mb-5">Company details</h4>
						<div v-if="item.isDraft || !item.id" class="d-flex" style="gap: 10px">
							<div>
								<label>Business Name*</label>
								<v-select
									v-model="item.businessDetailsId"
									:items="businessDetails"
									item-text="businessName" item-value="id"
									v-bind="inputProps"
									:rules="requiredRules"
								/>
							</div>
							<div>
								<label>RFC/EIN</label>
								<v-text-field
									:value="selectedBusiness.rfc"
									readonly v-bind="inputProps"
								/>
							</div>
							<div class="flex">
								<label>Business Addresss</label>
								<v-text-field
									:value="selectedBusiness.address"
									readonly v-bind="inputProps"
								/>
							</div>
						</div>
						<div v-else class="d-flex" style="gap: 10px">
							<div>
								<label>Business Name*</label>
								<v-text-field
									:value="item.businessDetailsBusinessName"
									v-bind="inputProps"
								/>
							</div>
							<div>
								<label>RFC/EIN</label>
								<v-text-field
									:value="item.businessDetailsRfc"
									readonly v-bind="inputProps"
								/>
							</div>
							<div class="flex">
								<label>Business Addresss</label>
								<v-text-field
									:value="item.businessDetailsFullAddress"
									readonly v-bind="inputProps"
								/>
							</div>
						</div>
					</div>
				</v-col>

				<v-col :cols="8">
					<div class="bordered rounded-lg pa-5">
						<h4 class="mb-5">General details</h4>
						<v-row>
							<v-col :cols="4">
								<label>Invoice No.*</label>
								<v-text-field
									v-if="!isSerial || (item.id && !item.isDraft)"
									v-bind="inputProps" :rules="requiredRules"
									v-model.trim="item.code" :disabled="item.id && !item.isDraft"
								/>
								<v-text-field
									v-else
									v-bind="inputProps" :value="nextSerialCode"
									readonly
								/>
							</v-col>
							<v-col :cols="4">
								<label>Customer*</label>
								<v-autocomplete
									@change="handleCustomerChange"
									v-bind="inputProps"
									:value="item.customerId" :items="customers"
									item-text="name" item-value="id" :rules="requiredRules"
								/>
							</v-col>
							<v-col :cols="4" class="pb-0">
								<label>RFC*</label>
								<v-autocomplete
									v-if="!item.id || item.isDraft"
									@change="handleRfcChange" :disabled="!canEdit"
									v-bind="inputProps"
									v-model="item.rfcId" :items="shownRfcs"
									:item-text="v => v.name + ' - ' + v.rfc" item-value="id" :rules="requiredRules"
								>
									<template v-slot:item="{item}">
										<v-list-item-content class="py-1">
											<v-list-item-title>{{item.name}}</v-list-item-title>
											<v-list-item-subtitle>{{item.rfc}}</v-list-item-subtitle>
										</v-list-item-content>
									</template>
									<template v-slot:selection="{ item }">
										<v-list-item-content class="py-0">
											<v-list-item-subtitle>{{item.rfc}}</v-list-item-subtitle>
										</v-list-item-content>
									</template>
								</v-autocomplete>
								<v-text-field
									v-else
									v-bind="inputProps"
									:value="item.rfcRFC"
									readonly
								/>
								<div class="mt-1" style="color: var(--v-text-lighten)">
									{{ item.rfcName }}
									<span v-if="item.rfcId && !item.rfcName" class="error--text">
										Not name found, please insert a name
									</span>
								</div>
							</v-col>
							<v-col :cols="12">
								<label>Business Address*</label>
								<v-text-field
									:value="item.businessAddress"
									v-bind="inputProps" readonly
									:rules="[v => (!!v && v !== 'Missing') || 'Required']"
								/>
							</v-col>
							<v-col :cols="3">
								<label>Invoice Date*</label>
								<q-date-picker
									v-bind="inputProps" inner
									:rules="requiredRules"
									v-model="item.issueDate"
									label=""
									@change="handleDeadlineChange('invoice')"
								/>
							</v-col>
							<v-col :cols="3">
								<label>Delivery date*</label>
								<q-date-picker
									v-bind="inputProps" inner
									:rules="requiredRules"
									v-model="item.deliveryDate"
									label=""
								/>
							</v-col>
							<v-col :cols="3">
								<label>Due Date*</label>
								<q-date-picker
									v-bind="inputProps" inner
									:rules="requiredRules" :min="item.issueDate"
									v-model="item.paymentDay"
									label=""
									@change="handleDeadlineChange('due')"
								/>
							</v-col>
							<v-col :cols="3">
								<label>Payment deadline*</label>
								<div class="display-flex align-center" style="font-size: 1.2em">
									<v-text-field
										v-bind="inputProps" inner type="number" :min="0"
										class="mr-2" :rules="requiredRules"
										v-model="item.paymentDeadline" @change="handleDeadlineChange('deadline')"
									/>
									days
								</div>
							</v-col>
							<v-col :cols="item.isCancelled ? 3 : 4">
								<label>Payment type*</label>
								<v-autocomplete
									v-bind="inputProps" inner single-line
									:items="waysToPay" label="Select Type"
									:rules="requiredRules" v-model="item.wayToPay"
								/>
							</v-col>
							<v-col :cols="item.isCancelled ? 3 : 4">
								<label>Payment method*</label>
								<v-autocomplete
									v-bind="inputProps" inner single-line
									:items="paymentMethods" label="Select Method"
									:rules="requiredRules" v-model="item.paymentMethod"
								/>
							</v-col>
							<v-col :cols="item.isCancelled ? 3 : 4">
								<label>Use*</label>
								<v-autocomplete
									v-bind="inputProps" inner single-line
									:items="uses" label="Use Description"
									:rules="requiredRules" v-model="item.use"
								/>
							</v-col>
							<v-col v-if="item.isCancelled" :cols="3">
								<label>Status*</label>
								<v-text-field
									v-model="item.cancellationReason"
									label="Cancellation Reason*" :rules="[v => !!v || 'Required']"
									v-bind="inputProps" class="mt-2" disabled
								/>
							</v-col>
						</v-row>
					</div>
					<div class="bordered rounded-lg pa-5 mt-5">
						<h4>Files</h4>
						<div v-if="!item.files || !item.files.length" class="mt-2 drop-files text-center" @drop="handleDrop">
							<template v-if="editing">
								<v-icon large>mdi-cloud-upload-outline</v-icon>
								<span class="body-1 mt-n2">
									Drag and drop your files here or
									<a @click="openFileUpload">Browse your computer</a>
								</span>
							</template>
							<template v-else>
								<v-icon>mdi-file-multiple-outline</v-icon>
								<span class="body-1 mt-n2">No files attached to this invoice</span>
							</template>
						</div>
						<div v-else class="mt-2 px-2 drop-files outlined dense" @drop="handleDrop">
							<div class="files">
								<div class="file-large bordered rounded-lg background--lighten cursor-pointer" @click="openFile(file)" v-for="(file, jx) in item.files" :key="jx">
									<img contain width="40px" src="@/assets/pdf.png" v-if="isPDF(file.name)"/>
									<img contain width="40px" src="@/assets/file.png" v-else/>
									<div class="f-info">
										<div> {{file.name}} </div>
										<div> {{formatSize(file.size)}} </div>
									</div>
									<div class="text-right">
										<v-btn
											@click.stop="removeFile(jx)" class="remove-file"
											:disabled="!canEdit || !editing"
											icon small fab
											color="error"
										>
											<v-icon>mdi-delete</v-icon>
										</v-btn>
									</div>
								</div>
							</div>
							<v-btn @click="openFileUpload" :disabled="!canEdit || !editing" color="primary" depressed>Add File</v-btn>
						</div>
					</div>
				</v-col>
				<v-col :cols="4">
					<div class="bordered rounded pa-5" style="position: relative">
						<h4>Invoice amount</h4>

						<v-menu :disabled="!editing" offset-y>
							<template #activator="{ on, attrs }">
								<v-btn v-on="on" v-bind="attrs" text absolute right top>
									<v-avatar color="indigo" size="20" class="mr-1">
										<v-img :src="require(`../../assets/currencies/${item.currency.toLowerCase()}.png`)" />
									</v-avatar>
									<span>{{item.currency}}</span>
									<v-icon v-show="editing" right>mdi-chevron-down</v-icon>
								</v-btn>
							</template>
							<v-list dense>
								<v-list-item @click="item.currency = currency" v-for="(currency, ix) in currencies" :key="ix">
									<v-list-item-content>
										<div class="d-flex align-center">
											<v-avatar color="indigo" size="20" class="mr-2">
												<v-img :src="require(`../../assets/currencies/${currency.toLowerCase()}.png`)" />
											</v-avatar>
											<span>{{currency}}</span>
										</div>
									</v-list-item-content>
								</v-list-item>
							</v-list>
						</v-menu>

						<v-checkbox
							v-if="editing"
							@change="validTotal"
							v-bind="inputProps" :dense="false"
							label="Tax not required"
							v-model="item.notRequireTax"
						/>

						<div class="mt-8">
							<label>Subtotal*</label>
							<v-text-field
								:value="currencyFormat(item.subtotal)"
								v-bind="inputProps"
								readonly
							/>
						</div>
						<div class="mt-4">
							<label>Tax*</label>
							<v-text-field
								v-bind="inputProps"
								:value="currencyFormat(item.tax)" :disabled="item.notRequireTax || !canEdit"
								readonly
							/>
						</div>
						<div class="mt-4">
							<label>Total amount*</label>
							<v-text-field
								v-bind="inputProps"
								:value="currencyFormat(item.total)" :disabled="!canEdit"
								readonly
							/>
						</div>

						<div v-if="exchangeRateCurrency" class="mt-5">
							<h4>Exchange rate</h4>
							<input-mask
								v-model="item.exchangeRateOrigin"
								@change="handleExchangeRate($event, 'origin')"
								v-bind="inputProps" :readonly="false" :rules="numberRules"
								type="number" :mask="x => currencyFormat(x, true)"
								class="mt-4" ref="exchangeRateInput"
							>
								<template #append>
									<div class="d-flex align-center justify-center" style="margin-top: 2px">
										<v-avatar color="indigo" size="20" class="mr-1">
											<v-img :src="require(`../../assets/currencies/${item.currency.toLowerCase()}.png`)" />
										</v-avatar>
										{{ item.currency }}
									</div>
								</template>
							</input-mask>
							<input-mask
								v-model="item.exchangeRateDestination"
								@change="handleExchangeRate($event, 'destination')"
								v-bind="inputProps" :readonly="false" :rules="numberRules"
								type="number" :mask="x => currencyFormat(x, true)"
								class="mt-4"
							>
								<template #append>
									<div class="d-flex align-center justify-center" style="margin-top: 2px">
										<v-avatar color="indigo" size="20" class="mr-1">
											<v-img :src="require(`../../assets/currencies/${exchangeRateCurrency.toLowerCase()}.png`)" />
										</v-avatar>
										{{ exchangeRateCurrency }}
									</div>
								</template>
							</input-mask>
						</div>
					</div>
				</v-col>

				<v-col :cols="12">
					<div class="bordered rounded-lg pa-5">
						<h4 class="mb-5">Invoice lines</h4>
						<div class="d-flex flex-column gap-2">
							<div
								v-for="(line, ix) in item.lines" :key="ix"
								class="d-flex align-center" style="gap: 10px"
							>
								<div class="flex">
									<label>Description*</label>
									<v-text-field
										v-model="line.description"
										v-bind="inputProps"
										:rules="requiredRules"
									/>
								</div>
								<div class="flex">
									<label>Additional Details</label>
									<v-textarea
										v-model="line.details"
										v-bind="inputProps"
										:rows="1"
									/>
								</div>
								<div style="width: 100px">
									<label>Quantity*</label>
									<v-text-field
										v-model="line.quantity"
										@change="updateSubtotal"
										v-bind="inputProps"
										:rules="requiredRules"
										type="number"
									/>
								</div>
								<div style="width: 130px">
									<label>Unit Price*</label>
									<input-mask
										v-model="line.unitPrice"
										@change="updateSubtotal"
										v-bind="inputProps"
										:mask="currencyFormat"
										:rules="requiredRules"
										v-only-numbers
									/>
								</div>
								<div style="width: 130px">
									<label>Amount</label>
									<input-mask
										:value="line.unitPrice * line.quantity"
										v-bind="inputProps"
										:mask="currencyFormat"
										v-only-numbers
										disabled
									/>
								</div>
								<v-btn v-if="editing" @click="removeLine(line)" color="error" icon class="mt-5">
									<v-icon>mdi-delete</v-icon>
								</v-btn>
							</div>
						</div>
						<v-btn v-if="editing && canEdit" @click="addLine" class="mt-2" depressed small>
							<v-icon left>mdi-plus</v-icon> Add line
						</v-btn>
					</div>
				</v-col>

				<v-col :cols="12">
					<div class="bordered rounded-lg pa-5 pb-1">
						<div class="d-flex justify-space-between">
							<h4 class="mb-5">Amount distribution</h4>
							<div style="width: 300px">
								<v-progress-linear
									height="10" class="rounded"
									:color="Math.abs(billableItemsAmount - item.subtotal) < .01 ? 'primary' : 'error'"
									:value="billableItemsAmount/item.subtotal*100"
								/>
								<div class="d-flex justify-space-between body-2 mt-1">
									<label>{{ billableItemsAmount }}/{{ item.subtotal | number(2) }}</label>
									<b>Pending: {{ currencyFormat(item.subtotal - billableItemsAmount) }}</b>
								</div>
							</div>
						</div>
						<div class="mx-n5">
							<billable-items-table
								:items="item.billableItems.filter(x => !x.deleted)" :complete="false" invoice-view
								class="new-back billable-items-table"
								hide-default-footer disable-pagination
								:item-class="() => permissions.billableItems ? 'cursor-pointer' : ''"
								@click:row="openBillableItemDetails"
								:show-adjustment-button="!editing"
								:adjustment-needed="Math.abs(item.subtotal - billableItemsAmount) >= 0.01"
								@adjust="adjustBillableItem"
							>
								<template #[`item.amount`]="{item: bi}">
									<div @click.stop class="d-flex align-center py-1">
										<v-row style="width: 350px">
											<v-col>
												<label>Amount {{ exchangeRateCurrency ? '(Invoice)' : '' }}</label>
												<input-mask
													v-bind="inputProps" :hide-details="false" :readonly="!editing"
													@change="v => $set(bi, 'amount', trunc(v, 0, Math.round(bi.maxAmount / exchangeRateFactor * 100) / 100))"
													v-model="bi.amount" :max="bi.maxAmount / exchangeRateFactor"
													:messages="'Max: ' + currencyFormat(getMaxAmount(bi))"
													type="number" :mask="currencyFormat"
													:rules="requiredRules"
												>
													<template #append>
														<label class="mt-1">{{ item.currency }}</label>
													</template>
												</input-mask>
											</v-col>
											<v-col v-if="bi.billableItemBillableCurrency != item.currency">
												<label>Amount (Billable Item)</label>
												<input-mask
													v-bind="inputProps" :hide-details="false"
													:value="editing ? bi.amount * exchangeRateFactor : bi.exchangeRateAmount"
													:messages="'Max: ' + currencyFormat(bi.maxAmount)"
													type="number" :mask="currencyFormat" disabled
												>
													<template #append>
														<label class="mt-1">{{ bi.billableItemBillableCurrency }}</label>
													</template>
												</input-mask>
											</v-col>
										</v-row>
										<v-btn v-if="editing" @click="removeBillableItem(bi)" icon>
											<v-icon>mdi-minus-circle-outline</v-icon>
										</v-btn>
									</div>
								</template>
							</billable-items-table>
						</div>
						<div v-if="editing" class="distribution-line">
							<v-btn @click="openRelateWorkItemsDialog" depressed small>
								<v-icon>mdi-plus</v-icon>
								Add New
							</v-btn>
						</div>
					</div>
				</v-col>

				<v-col v-if="!isNew" :cols="12">
					<div class="bordered rounded-lg pa-5">
						<h4 class="mb-5">Payments</h4>
						<payment-table
							@updated="loadPayments"
							:payments="shownPayments" :accounts="accounts"
							invoice-details-view
							:referenced-invoice-id="id"
							:referenced-invoice="item"
						/>
					</div>
				</v-col>

				<v-col v-if="!isNew" :cols="12">
					<div class="bordered rounded-lg pa-5">
						<div class="d-flex justify-space-between align-center mb-3">
							<h4>Credit Note</h4>
							<v-btn
								v-if="!item.creditNote && Math.abs(item.total - collectedBalance) >= 0.01"
								@click="$router.push('/admin/creditNote/' + id)"
								color="primary" fab small
							>
								<v-icon>add</v-icon>
							</v-btn>
						</div>
						<data-table
							@click:row="$router.push('/admin/creditNote/' + id)"
							:headers="creditNoteHeaders"
							:items="item.creditNote ? [item.creditNote] : []"
						>
							<template #[`item.dateTime`]="{ item }">
								{{ item.dateTime | formatDate }}
							</template>
							<template #[`item.amount`]="{ item }">
								{{ currencyFormat(item.amount) }}
							</template>
							<template #[`item.status`]>
								Processed
							</template>
						</data-table>
					</div>
				</v-col>
			</v-row>
		</v-form>

		<v-dialog v-model="showCommentDialog" persistent width="600">
			<v-card>
				<v-card-title>Comment</v-card-title>
				<v-card-text>
					<v-text-field v-model="comment" hide-details outlined :rules="[v => !!v || 'Comment required']"/>
					<div class="text-right mt-3">
						<v-btn @click="commentResolve(false)" class="mr-2" color="primary" outlined>Cancel</v-btn>
						<v-btn @click="commentResolve(true)" :disabled="!comment" color="primary">Save</v-btn>
					</div>
				</v-card-text>
			</v-card>
		</v-dialog>

		<relate-billable-items-dialog ref="billableItemsDialog"/>
		<billable-item-adjustment-dialog ref="adjustmentDialog"/>
	</div>
</template>

<script>
import moment from "moment"
import PaymentTable from '../../components/PaymentTable'
import RelateBillableItemsDialog from "../../dialogs/adminSurf/RelateBillableItemsDialog"
import BillableItemsTable from "../../components/BillableItemsTable"
import WatchersMenu from "../../components/admin-surf/WatchersMenu.vue"
import BillableItemAdjustmentDialog from '../../dialogs/adminSurf/BillableItemAdjustmentDialog'

export default {
	props: {
		id: String,
		copyFrom: String,
	},
	components: { PaymentTable, RelateBillableItemsDialog, BillableItemsTable, WatchersMenu, BillableItemAdjustmentDialog },
	data: () => ({
		resolve: () => {},
		reject: () => {},
		item: {
			billableItems: [],
			lines: [],
			files: [],
			multipleItems: false,
			cancellationReason: '',
			status: 1,
			currency: 'MXN',
			exchangeRateOrigin: 1,
			exchangeRateDestination: 1,
		},
		nextSerialCode: '',
		originalStatus: 1,
		file: {},
		title: '',
		customers: [],
		billableItems: [],
		holidays: [],
		currencies: [ 'MXN', 'USD', 'EUR' ],
		numberRules: [v => (!!v) || 'Required'],
		requiredRules: [v => (!!v && (!v.trim || !!v.trim())) || v === 0 || 'Required'],
		validForm: false,
		editing: false,
		showCommentDialog: false,
		comment: '',
		waysToPay: [
			'Transferencia electrónica de fondos',
			'Cheque nominativo',
			'Transferencia electrónica',
			'Efectivo',
			'Tarjeta de débito',
			'Tarjeta de crédito',
			'Tarjeta de servicios',
			'Monedero electronico',
			'Dinero electrónico',
			'Vales de despensa',
			'Dación en pago',
			'Pago por subrogación',
			'Pago por consignación',
			'Condonación',
			'Compensación',
			'Novación',
			'Confusión',
			'Remisión de deuda',
			'Prescripción o caducidad',
			'A satisfacción del acreedor',
			'Intermediario pagos',
			'Aplicación de anticipos',
			'Por definir',
		],
		paymentMethods: [
			'Una sola exhibición',
			'Parcialidades o Diferido',
		],
		uses: [
			'Gastos en general',
			'Adquisición de mercancias',
			'Devoluciones, descuentos o bonificaciones',
			'Construcciones',
			'Mobiliario y equipo de oficina por inversiones',
			'Equipo de transporte',
			'Equipo de computo y accesorios',
			'Dados, troqueles, moldes, matrices y herramental',
			'Comunicaciones telefónicas',
			'Comunicaciones satelitales',
			'Otra maquinaria y equipo',
			'Honorarios médicos, dentales y gastos hospitalarios',
			'Gastos médicos por incapacidad o discapacidad',
			'Gastos funerales',
			'Donativos',
			'Intereses reales efectivamente pagados por créditos hipotecarios (casa habitación)',
			'Aportaciones voluntarias al SAR',
			'Primas por seguros de gastos médicos',
			'Gastos de transportación escolar obligatoria',
			'Depósitos en cuentas para el ahorro, primas que tengan como base planes de pensiones',
			'Pagos por servicios educativos (colegiaturas)',
			'Por definir',
		],
		businessDetails: [],

		payments: [],
		accounts: [],

		billableItemMaxMemo: {},

		creditNoteHeaders: [
			{ text: 'Customer', value: 'invoiceCustomerName' },
			{ text: 'Document date', value: 'dateTime' },
			{ text: 'Amount', value: 'amount' },
			{ text: 'Status', value: 'status' },
		],

		permissions: {
			actions: false,
			billableItems: false,
			payments: false,
		},
	}),
	mounted(){
		this.$security.hasRequiredPermission('full_access')
			.then(res => this.permissions.actions = res)
		this.$security.hasRequiredPermission('detail', 'adminSurfBillableItems')
			.then(res => this.permissions.billableItems = res)
		this.$security.hasRequiredPermission('detail', 'adminSurfPayments')
			.then(res => this.permissions.payments = res)
		this.init()
	},
	watch: {
		'item.multipleItems'(){
			if(!this.item.billableItems)
				this.item.billableItems = []
			if(!this.item.multipleItems)
				this.item.billableItems = this.item.billableItems.slice(0, 1)
		},
	},
	computed: {
		shownPayments(){
			return this.payments.filter(p => p.customerId == this.item.customerId)
		},
		collectedBalance(){
			return this.shownPayments
				.filter(p => !p.deleted && p.status != 3)
				.reduce((partial, p) => {
						let relation = p.invoices.find(i => i.invoiceId == this.id)
						let amount = 0
						if(relation){
							amount = relation.exchangeRateAmount || relation.amount
						}
						return partial + amount
					},
					0
				) + (this.item.creditNote ? this.item.creditNote.amount : 0)
		},
		selectedBusiness() {
			const businessDetails = this.businessDetails.find(x => x.id == this.item.businessDetailsId)
			if(!businessDetails) return {}

			businessDetails.address = `${businessDetails.addressLine}, ${businessDetails.addressLine2}, ${businessDetails.city}, ${businessDetails.state}, ${businessDetails.countryName}, ${businessDetails.zipCode}`
			return businessDetails
		},
		requireComment(){ return this.item.status == 1 && this.item.status == 2 },
		validBillableItemsAmount(){
			return this.billableItemsAmount - (this.item.subtotal || 0) < 0.01
		},
		billableItemsAmount(){
			const amount = this.item.billableItems
				.filter(x => !x.deleted)
				.reduce((partial, relation) => {
					let amount = partial + (relation.amount || 0)
					
					if(relation.billableItemAdjustmentAmount && relation.billableItemAdjustmentInvoiceId == this.id){
						let adjustment = relation.billableItemAdjustmentAmount

						if(this.item.currency != this.item.exchangeRateCurrency)
							adjustment = adjustment / this.exchangeRateFactor

						amount += adjustment
					}

					return amount
				}, 0)
			return Math.round(amount * 100) / 100
		},
		shownBillableItems(){
			return this.billableItems.filter(bi => bi.customerId == this.item.customerId )
		},
		shownRfcs(){
			if(this.item.customer) return this.item.customer.rfcs
			return this.item.customerRfcs || []
		},
		canEdit() {
			return this.item.status === 0 || this.item.status === 1
		},
		inputProps(){
			return {
				outlined: true,
				dense: true,
				hideDetails: true,
				readonly: !this.editing || !this.canEdit,
			}
		},
		isNew(){ return this.id == 'new' },
		exchangeRateCurrency(){
			let diffs = this.item.billableItems
				.filter(x => !x.deleted && x.billableItemBillableCurrency != this.item.currency)
			if(!diffs.length) return null
			return diffs[0].billableItemBillableCurrency
		},
		exchangeRateFactor(){
			if(!this.exchangeRateCurrency){
				this.item.exchangeRateDestination = 1
				this.item.exchangeRateOrigin = 1
			}
			return this.item.exchangeRateDestination / this.item.exchangeRateOrigin
		},
		isSerial() {
			return this.selectedBusiness.businessName == 'Blue People LLC'
		},
	},
	methods: {
		init(){
			let invoicePr = null

			if(this.id && !this.isNew){
				invoicePr = this.$http.get(`api/invoice/${this.id}`)
					.then(res => {
						let item = res.data
						item.exchangeRateOrigin = item.exchangeRateOrigin || 1
						item.exchangeRateDestination = item.exchangeRateDestination || 1
						this.originalStatus = item.status
						this.item = item
						this.$q.changeHeaderTitle(item.code)
						this.handleDeadlineChange('due')

						if(!this.item.businessAddress) this.$set(this.item, 'businessAddress', 'Missing')
					})
					.catch(err => {
						console.error(err)
						window.getApp.error('Cannot get invoice')
					})
				this.$http.get('api/bankAccount')
					.then(res => {
						this.accounts = res.data
					})
				this.loadPayments()
			}
			else{
				this.editing = true
				this.$q.changeHeaderTitle('New Invoice')

				if(this.copyFrom) {
					invoicePr = this.$http.get(`api/invoice/${this.copyFrom}`)
						.then(res => {
							let base = res.data

							this.item = {
								...this.item,
								businessDetailsId: base.businessDetailsId,
								businessAddress: 'Missing',
								currency: base.currency,
								customerId: base.customerId,
								exchangeRateDestination: base.exchangeRateDestination,
								exchangeRateOrigin: base.exchangeRateOrigin,
								invoiceDetailsId: base.invoiceDetailsId,
								isCancelled: false,
								isDraft: true,
								lines: base.lines.map(x => ({
									description: x.description,
									quantity: x.quantity,
									unitPrice: x.unitPrice,
								})),
								multipleItems: base.multipleItems,
								notRequireTax: base.notRequireTax,
								paymentMethod: base.paymentMethod,
								rfcId: base.rfcId,
								use: base.use,
								wayToPay: base.wayToPay,
							}
						})
						.catch(err => {
							console.error(err)
							window.getApp.error('Cannot duplicate invoice')
						})
				}
			}

			const customersPr = this.$http.get("/api/customer?onlyWithCustomerProjects=true")
				.then(res => {
					this.customers = _.orderBy(res.data, ['name'])
				})
				.catch(err => {
					console.error(err)
					window.getApp.error("Cannot obtain customers.")
				})
			let billableItemsPr = this.$http.get("/api/billableItem")
				.then(res => {
					this.billableItems = res.data.filter(x => x.reviewed)
				})
				.catch(res => {
					window.getApp.error("Cannot obtain billable items.")
				})
			this.$http.get('api/invoice/businessDetails')
				.then(res => {
					this.businessDetails = res.data
				})
				.catch(err => {
					console.error(err)
					this.$root.error('Failed to fetch invoice business details')
				})
			this.$http.get('api/invoice/nextSerial')
				.then(res => {
					this.nextSerialCode = res.data
				})
				.catch(err => {
					console.error(err)
					this.$root.error('Failed to fetch invoice serial')
				})

			Promise.all([invoicePr, billableItemsPr, customersPr])
				.then(async () => {
					if(this.copyFrom) {
						let rfcId = this.item.rfcId
						await this.handleCustomerChange(this.item.customerId)
						this.item.rfcId = rfcId
						this.handleRfcChange(this.item.rfcId)
						this.updateSubtotal()
					}

					if(this.item.businessAddress === 'Missing' && this.item.rfcId){
						const rfc = this.shownRfcs.find(x => x.id == this.item.rfcId)
						if(
							!rfc ||
							!rfc.city ||
							!rfc.state ||
							!rfc.country ||
							!rfc.zipCode
						)
							this.item.businessAddress = 'Missing'
						else
							this.item.businessAddress = rfc.addressLine + ', ' + rfc.city + ', ' + rfc.state + ', ' + rfc.country + ', ' + rfc.zipCode
					}

					this.billableItems
						.forEach(bi => {
							let relation = this.item.billableItems.find(rel => rel.billableItemId == bi.id)
							bi.max = bi.total - bi.invoiced
							if(relation) {
								bi.max += relation.amount
							}
						})
				})
		},
		async save(isDraft){
			if(!this.$refs.invoiceForm.validate()){
				this.$root.error('Please fill in all the required fields')
				return
			}
			if(!this.item.rfcName) {
				this.$refs.invoiceForm.validate()
				this.$root.error('RFC is missing. Please fill it in the Customer/RFC detail.')
				return
			}
			if(!this.item.businessAddress || this.item.businessAddress === 'Missing'){
				this.$refs.invoiceForm.validate()
				this.$root.error('Business address is missing. Please fill it in the Customer/RFC detail.')
				return
			}
			if(!this.validBillableItemsAmount){
				this.$root.error('The total amount of the billable items is invalid')
				return
			}

			if(isDraft && this.originalStatus != 0){
				let res = await this.checkPayments('isDraft', true, 'Save as draft')
				if(!res) return
			}
			if(!isDraft && !this.item.subtotal) {
				this.$root.error('Some invoice line is required')
				return
			}
			this.item.isDraft = !!isDraft

			if([1, 2, 4].includes(this.item.status) && this.item.id){
				let res = await this.requestComment()
				this.showCommentDialog = false
				if(!res) return
			}
			let toSave = JSON.parse(JSON.stringify(this.item))
			toSave.billableItems = toSave.billableItems.filter(bi => bi.billableItemId && !bi.deleted)
			toSave.billableItems
				.forEach(x => {
					if(x.billableItemBillableCurrency != this.item.currency)
						x.exchangeRateAmount = x.amount * this.exchangeRateFactor
					else x.exchangeRateAmount = x.amount
				})
			delete toSave.customer
			delete toSave.rfc
			toSave.exchangeRateCurrency = this.exchangeRateCurrency

			this.$http.post('api/invoice', {comment: this.comment, invoice: toSave})
				.then(res => {
					if(typeof res.data == 'string'){
						window.getApp.error(res.data)
						return
					}
					if(this.isNew){
						this.item = Object.assign({}, res.data)
						this.$router.push({path: res.data.id, query: { redirect: '/admin/invoice' }})
						this.copyFrom = ''
					}
					this.init()
					this.editing = false
				})
				.catch(err => {
					console.log(err)
					window.getApp.error("Cannot save invoice")
				})
		},
		cancelInvoice(){
			this.$adminSurf('cancelInvoice', this.item.id)
				.then(res => {
					if(!res) return
					this.init()
				})
				.catch(err => {
					this.$root.error('Cannot cancel invoice')
					console.error(err)
				})
		},
		updateSubtotal(){
			this.item.subtotal = this.item.lines.reduce((acc, x) => acc + ((x.quantity || 0) * (x.unitPrice || 0)), 0)
			this.validTotal()
		},
		addLine(){
			if(!this.item.lines) this.$set(this.item, 'lines', [])
			this.item.lines.push({})
		},
		removeLine(line){
			this.$openConfirmDialog({
				title: 'Remove line',
				description: 'Are you sure you want to remove this line?',
			})
				.then(res => {
					if(!res) return
					line.deleted = true
					this.item.lines = this.item.lines.filter(x => !x.deleted)

					const bi = this.item.billableItems.find(x => x.billableItemId == line.billableItemId)
					if(bi) this.$set(bi, 'deleted', true)
					this.updateSubtotal()
				})
		},
		checkPayments(property, newValue, title){
			return new Promise(resolve => {
				let prevValue = this.item[property]
				this.$set(this.item, property, newValue)

				if(property == 'isCancelled' && !newValue){
					this.item.status = this.originalStatus
					return
				}

				if(!this.item.id){
					resolve(true)
					return
				}
				
				this.$openConfirmDialog({
					title,
					description: 'All the payments associated to this invoice will be unlinked.',
				})
					.then(res => {
						if(!res){
							this.item[property] = prevValue
						}
						else if(property == 'isCancelled') this.item.status = 3
						resolve(res)
					})
			})
		},
		requestComment(){
			this.showCommentDialog = true
			return new Promise(res => {
				this.commentResolve = res
			})
		},
		loadPayments(){
			this.$http.get('api/payment?invoiceId=' + this.id)
				.then(res => {
					this.payments = res.data
				})
		},
		openFile(file){
			this.$http.get(`api/invoice/${this.id}/file/${file.fileId}`)
				.then(res => {
					window.open(res.data, "_blank")
				})
		},
		addBillableItem(){
			let newItem = {
				billableItem: { },
				billableItemId: '',
				amount: '',
				isNew: true,
				startDate: '',
				endDate: '',
			}
			this.item.billableItems.push(newItem)
		},
		removeBillableItem(bi){
			this.$openConfirmDialog(
				{
					title: 'Remove Billable Item',
					description: 'Are you sure you want to remove this billable item?',
				}
			)
				.then(res => {
					if(!res) return
					this.$set(bi, 'deleted', true)
					this.item.lines = this.item.lines.filter(x => x.billableItemId != bi.billableItemId)
					this.updateSubtotal()
				})
		},
		openBillableItemDetails(bi) {
			const billableItem = this.billableItems
				.find(x => x.id == bi.billableItemId)
			this.$adminSurf('billableItemToPdf', billableItem, false)
		},
		async adjustBillableItem(item) {
			this.$refs.adjustmentDialog.open(item.billableItemId, this.item.id)
				.then(this.init)
		},
		toggleExpanded(line){
			line.expanded = !line.expanded
			this.$forceUpdate()
		},
		formatSize(size){
			if(size <= 1048576)
				return Math.round(size/1024*100)/100 + 'KB'
			return Math.round(size/1048576*100)/100 + 'MB'
		},
		handleDeadlineChange(change){
			let invoiceDate = this.item.issueDate ? moment(this.item.issueDate) : null
			let dueDate = this.item.paymentDay ? moment(this.item.paymentDay) : null

			if(invoiceDate && dueDate && invoiceDate.isAfter(dueDate)){
				this.item.paymentDay = this.item.issueDate
				dueDate = moment(this.item.paymentDay)
			}

			if(this.item.paymentDeadline)
				this.item.paymentDeadline = Math.abs(this.item.paymentDeadline)

			if(
				(change == 'invoice' && this.item.paymentDeadline) ||
				(change == 'deadline' && invoiceDate)
			){
				this.item.paymentDay = invoiceDate
					.clone()
					.add(this.item.paymentDeadline, 'days')
					.format('YYYY-MM-DD')
			}
			else if(
				(change == 'invoice' && dueDate) ||
				(change == 'due' && invoiceDate)
			){
				this.item.paymentDeadline = dueDate
					.diff(this.item.issueDate, 'days')
			}
			else if(
				(change == 'deadline' && dueDate) ||
				(change == 'due' && this.item.paymentDeadline)
			){
				this.item.issueDate = dueDate
					.subtract(this.item.paymentDeadline, 'days')
					.format('YYYY-MM-DD')
			}
		},
		async handleCustomerChange(id){
			const res = await this.checkPayments('customerId', id, 'Customer change')
      if(!res)
        return
      this.$set(this.item, 'customer', this.customers.find(c_1 => c_1.id==id))
      this.$set(this.item, 'rfcName', '')
      this.$set(this.item, 'businessAddress', 'Missing')
			this.$set(this.item, 'paymentDeadline', this.item.customer.creditDays)
      this.item.rfcId=''
      this.item.rfc=null
		},
		handleRfcChange(id){
			const rfc = this.item.customer.rfcs.find(c => c.id == id)
			this.item.rfc = rfc
			this.$set(this.item, 'rfcName', rfc?.name || '')
			this.item.rfcName = rfc?.name

			if(
				!rfc ||
				!rfc.addressLine ||
				!rfc.colony ||
				!rfc.city ||
				!rfc.state ||
				!rfc.country ||
				!rfc.zipCode
			)
				this.item.businessAddress = 'Missing'
			else
				this.item.businessAddress = rfc?.addressLine + ', ' + rfc?.colony + ', ' + rfc?.city + ', ' + rfc?.state + ', ' + rfc?.country + ', ' + rfc?.zipCode
		},
		handleDrop(e){
			let files = Array.from(e.dataTransfer.files)

			for(let file of files){
				let formData = new FormData()
				formData.append('file', file)
				this.$http.put(
					`api/invoice/file/`,
					formData,
					{ headers: { 'Content-Type': 'multipart/form-data' } }
				).then(res => {
					this.item.files.push({
						fileId: res.data,
						name: file.name,
						size: file.size,
					})
				}).catch((err) => {
					if(file.size > 30000000){
						window.getApp.error("The file exceeds maximum allowed size. (28MB)")
						return
					}
					window.getApp.error("Error while upload file.")
				})
			}
		},
		openFileUpload(){
			this.$refs.fileUploader.click()
		},
		handleFileUpload(){
			if(this.$refs.fileUploader.files.length){
				let file = this.$refs.fileUploader.files[0]
				let formData = new FormData()
				formData.append('file', file)
				this.$http.put(
					`api/invoice/file/`,
					formData,
					{ headers: { 'Content-Type': 'multipart/form-data' } }
				).then(res => {
					if(!this.item.files)
						this.$set(this.item, 'files', [])
					this.item.files.push({
						fileId: res.data,
						name: file.name,
						size: file.size,
					})
				}).catch((err) => {
					console.error(err)
					if(file.size > 30000000){
						window.getApp.error("The file exceeds maximum allowed size. (28MB)")
						return
					}
					window.getApp.error("Error while upload file.")
				})
			}
		},
		removeFile(index){
			this.$vuetifyConfirmDialog.open(
				"Remove File",
				"Are you sure you want to remove this file?",
				"Cancel", "Confirm"
			)
				.then(res => {
					if(!res)
						return
					this.item.files = this.item.files.filter((f, ix) => ix != index)
					this.$forceUpdate()
				})
		},
		isPDF(filename){
			if(!filename)
				return false;
			filename = filename.split('.');
			return filename[filename.length - 1] == 'pdf';
		},
		validTotal(){
			let subtotal = this.item.subtotal || 0

			if(this.item.notRequireTax){
				this.$set(this.item, 'tax', 0)
			}
			else{
				this.$set(this.item, 'tax', subtotal * .16)
			}

			this.$set(this.item, 'total', subtotal + this.item.tax)
		},
		closeInvoiceDialog(){
			this.$router.push('/admin/invoice')
		},
		openRelateWorkItemsDialog(){
			const showingExchangeRate = !!this.exchangeRateCurrency
			this.$refs.billableItemsDialog.open(
				this.shownBillableItems
					.filter(x =>
						!this.item.billableItems
							.some(bi => !bi.deleted && bi.billableItemId == x.id)
					)
			)
				.then(billableItems => {
					if(!this.item.lines) this.$set(this.item, 'lines', [])

					for(const bi of billableItems){
						this.item.billableItems.push({
							billableItemId: bi.id,
							billableItemCode: bi.code,
							billableItemBillableCode: bi.billableCode,
							billableItemStartDate: bi.startDate,
							billableItemEndDate: bi.endDate,
							billableItemHours: bi.hours,
							billableItemTotal: bi.total,
							billableItemInvoiced: bi.invoiced,
							billableItemPending: bi.pending,
							maxAmount: bi.pending,
							billableItemBillableCurrency: bi.billableCurrency,
							amount: bi.pending,
						})

						if(!this.item.lines.find(x => x.id)) {
							this.item.lines.push({
								description: bi.code,
								quantity: bi.hours,
								unitPrice: bi.pending / bi.hours,
								billableItemId: bi.id,
							})
						}

					}
					this.$nextTick(() => {
						if(!showingExchangeRate && this.exchangeRateCurrency){
							this.$refs.exchangeRateInput.focus()
						}
						this.updateSubtotal()
					})
				})
		},
		handleExchangeRate(newValue, property){
			if(newValue == 1) return
			if(property == 'origin'){
				this.item.exchangeRateDestination = 1
				if(newValue == 0) this.item.exchangeRateOrigin = 1
			}
			else{
				this.item.exchangeRateOrigin = 1
				if(newValue == 0) this.item.exchangeRateDestination = 1
			}
		},
		getMaxAmount(billableItem){
			if(billableItem.billableItemBillableCurrency != this.item.currency){
				if(!this.exchangeRateFactor) return 0
				return billableItem.maxAmount / this.exchangeRateFactor
			}
			return billableItem.maxAmount
		},
	},
}
</script>

<style lang="scss" scoped>
::v-deep .v-input--is-readonly{
	.v-input__slot{
		background: var(--v-coloredBack-base);
	}
	fieldset{
		border: none;
	}
}

::v-deep .billable-items-table{
	table{
		border-top: 1px solid var(--v-lightgray-lighten);
	}
	table > tbody > tr > td:last-child,
  table > thead > tr > th:last-child {
    position: sticky !important;
    right: 0; 
    z-index: 0;
    background: var(--v-newDesignBackground-base);
		border-left: 1px solid var(--v-lightgray-lighten);
  }
  table > thead > tr > th:last-child {
    z-index: 1;
  }
}

label{
	color: var(--v-text-lighten) !important;
	font-size: .9em;
}

.top-btn{
	position: absolute;
	top: -75px; right: 0;
	z-index: 100;
	height: 75px;
	gap: 10px
}

.indicator{
	display: grid;
	grid-template-columns: 50px auto;
	padding: 10px;
	border-radius: 8px;
	align-items: center;

	.v-icon{
		width: 40px;
		height: 40px;
		border-radius: 50%;
	}
	&.indicator-error{
		background: var(--v-error-lighten);
	}
}
.drop-files{
	display: flex;
	flex-direction: column;
	gap: 10px;
	align-items: center;
	justify-content: center;
	padding: 15px;
	border: 2px dashed lightgrey;

	span{
		font-size: 1.2em;
	}

	.files{
		display: flex;
		flex-wrap: wrap;
		padding: 10px;
		width: 100%;
		gap: 15px;

		.file{
			display: flex;
			flex-direction: column;
			align-items: center;
			text-align: center;
			position: relative;
			margin-bottom: 10px;
			width: 140px;
			padding: 10px;
			gap: 5px;
			background: var(--v-background-lighten3);
			border-radius: 5px;

			.remove-file{
				display: none;
				align-items: center;
				justify-content: center;
				height: 20px;
				width: 20px;
				position: absolute;
				right: -8px;
				top: -8px;
			}
			.f-info{
				margin: auto 0;
				width: 100%;
				display: -webkit-box;
				-webkit-line-clamp: 2;
				-webkit-box-orient: vertical;
				overflow: hidden;
				text-overflow: ellipsis;
			}

		}
		.file-large{
			display: grid;
			grid-template-columns: 45px auto 45px;
			padding: 10px;
			gap: 10px;
		}

		.file:hover{ .remove-file{ display: flex; } }
	}
}
.amount-alert{
	display: grid;
	grid-template-columns: 40px auto;
	align-items: center;
	padding: 5px 2px;
	border-radius: 5px;
	background-color: var(--v-background-lighten2);
}
.assignments{
	border: 1px solid lightgrey;
	border-radius: 5px;
	margin-bottom: 20px;
	padding: 10px;

	.header{
		display: flex;
		justify-content: space-between;
		align-items: center;
		border-bottom: 1px solid lightgrey;
		margin-top: -10px;
		padding: 0 10px;
	}

	.row + .row{
		margin-top: 0;
	}

	.assignment{
		border-bottom: 1px solid lightgray;
		margin: -12px 0;
	}
}

.distribution-line{
	margin: 0 -20px;
	border-top: 1px solid var(--v-lightgray-lighten);
	padding: 15px 20px;
	align-items: center;
}

::v-deep .v-list-item{
	min-height: 0 !important;
	div{
		width: 100%;
	}
}

</style>