<template>
  <v-container grid-list-md fluid class="pa-0">

    <div v-if="project">
      <div class="mt-3 d-flex align-center">
        <h2 class="inline-block">{{project.name}} Tasks</h2>
        <toggle-view
          v-model="relationType" :items="relationTypes"
          dense
        />
      </div>

      <div class="d-flex align-center pt-2 mb-5">
        <v-slide-group
          v-if="relationType"
          v-model="selectedTypes" multiple
          class="slider rounded mr-3"
        >
          <v-slide-item v-for="wiType in workItemTypes" :key="wiType.value" v-slot:default="{ active, toggle }" >
            <v-btn
              fab style="border-radius: 10px"
              :color="active ? 'var(--v-newDesignBackground-lighten)' : 'var(--v-secondaryBackground-base)'"
              class="mx-1 text-capitalize text-center inline-block px-0" depressed small @click="toggle"
            >
              <v-icon
                :style="{
                  filter: active ? '' : $vuetify.theme.isDark ? 'brightness(0) invert(1)' : 'brightness(0)'
                }"
                :color="wiType.color"
              >{{wiType.iconClass}}</v-icon>
            </v-btn>
          </v-slide-item>
        </v-slide-group>
        <div>
          <v-text-field
            v-model="search"
            label="Search" prepend-inner-icon="search"
            outlined dense hide-details single-line
          />
        </div>
        <v-spacer/>
        <div>
          <v-select
            v-model="userId" :items="users"
            item-value="id" item-text="fullName"
            label="Assigned To" outlined dense hide-details
          >
            <template v-slot:selection="data">
              <user-chip :text="data.item.fullName" close @click:close="userId = null" class="mt-1"/>
            </template>
          </v-select>
        </div>
        <div>
          <v-select
            label="Status" item-value="id" item-text="name"
            v-model="status" :items="relationType ? workItemStatuses : taskStatuses"
            outlined multiple hide-details dense
            class="ml-3" :class="{'hidden-input': !!status && status.length}"
          >
            <template #item="{ item }">
              <v-chip class="my-2 cursor-pointer" label :style="{ width: '100%' }" dark :color="item.color">
                <v-icon class="mr-2">{{item.iconClass}}</v-icon>
                {{item.name}}
              </v-chip>
            </template>
            <template #selection="{ item }">
              <v-chip
                label dark
                class="mt-2 cursor-pointer"
                :color="item.color"
              >
                <v-icon class="mr-2" style="font-size: 1.3em">{{item.iconClass}}</v-icon>
                {{item.name[0]}}
              </v-chip>
            </template>
          </v-select>
        </div>
      </div>

      <table v-if="!relationType" class="table table-condensed table-hover">
        <thead>
          <tr>
            <th v-if="$vuetify.breakpoint.smAndUp"></th>
            <th class="text-left">
              <v-btn v-if="!relationType" class="xxxs" tooltip="Collapse/uncollapse all"
                  @click="collapseAll()">
                <v-icon v-if="project.allTasksCollapsed">add</v-icon>
                <v-icon v-if="!project.allTasksCollapsed">remove</v-icon>
              </v-btn>
              Task
            </th>
            <th v-if="$vuetify.breakpoint.smAndUp">Responsible</th>
            <th v-if="$vuetify.breakpoint.smAndUp" class="text-center">Status</th>
            <th v-if="$vuetify.breakpoint.smAndUp" class="text-center">Estimated</th>
            <th v-if="$vuetify.breakpoint.smAndUp" class="text-center">Executed</th>
            <th v-if="$vuetify.breakpoint.smAndUp" class="text-center">Pending</th>
          </tr>
        </thead>
        <tbody>
          <tr @click="chooseTask(task)"
            class="task-row"
            v-for="(task, ix) in sortedTasks" :key="task.id"
            v-show="!task.hidden && !task.filteredOut"
            :class="{
              'selected-task': selected == task, 
              'cursor-pointer': true, 
              'parent-task': task.isParent, 
              'collapsed-task': task.collapsed, 
              ['level' + task.level]: true 
            }">
            <td v-if="$vuetify.breakpoint.smAndUp" style="width: 1%">
              {{(ix + 1)}}
            </td>
            <td>
              <span :style="{ display: 'inline-block', width: (((task.level || 0) - 1) * 25) + 'px' }"></span>
              <v-btn small class="xxxs" :disabled="!task.isParent" @click.stop="collapseTask(task)">
                <v-icon v-if="task.collapsed">add</v-icon>
                <v-icon v-if="!task.collapsed">remove</v-icon>
              </v-btn>
              <small>
                {{task.number}}.
              </small>
              {{task.name}}
              <div class="text-right pr-1" v-if="$vuetify.breakpoint.xs">
                <small class="task-status" :class="task.statusName">{{task.statusName}}</small>
                <small v-if="task.reportable">
                  by
                  <i v-if="!task.responsibleFullName">Unassigned</i>
                  {{task.responsibleFullName}}
                </small>
              </div>
            </td>
            <td v-if="$vuetify.breakpoint.smAndUp" class="text-center">
              <i v-if="task.reportable && !task.responsibleFullName">Unassigned</i>
              {{task.reportable ? task.responsibleFullName : '' }}
            </td>
            <td v-if="$vuetify.breakpoint.smAndUp" class="text-center">
              <small class="task-status" :class="task.statusName">
                {{task.statusName}}
              </small>
            </td>
            <td v-if="$vuetify.breakpoint.smAndUp" class="text-center">
              {{task.reportable ? task.estimatedEffort || '?' : '-'}}
            </td>
            <td v-if="$vuetify.breakpoint.smAndUp" class="text-center">
              <span v-if="task.reportable">
                {{task.totalExecuted | number(1)}}
              </span>
              <span v-if="!task.reportable">-</span>
            </td>
            <td v-if="$vuetify.breakpoint.smAndUp" class="text-center">
              <span v-if="task.reportable">
                {{task.pendingEffort | number(1)}}
              </span>
              <span v-if="!task.reportable">-</span>
            </td>
          </tr>
        </tbody>
      </table>
      <v-data-table
        v-else dense disable-sort
        class="cursor-pointer"
        @click:row="choseWorkItem"
        :items="shownWorkItems" :headers="workItemHeaders"
      >
        <template #[`item.code`]="{ item }">
          <h3>
            <v-icon :color="item.typeColor">{{ item.typeIconClass }}</v-icon>
            {{ item.code }}
          </h3>
        </template>
        <template #[`item.assignedTo`]="{ item }">
          {{(item.assignedToBlueTag || item.assignedToFullName || 'Unassigned')}}
        </template>
        <template #[`item.status`]="{ item }">
          <v-chip class="my-1 cursor-pointer" label dark :color="item.statusColor">
            <v-icon class="mr-2">{{item.statusIconClass}}</v-icon>
            {{item.statusName}}
          </v-chip>
        </template>
        <template #[`item.estimatedEffort`]="{ item }">
          {{
            item.estimatedEffort ?
              item.estimatedEffort + ' hrs' : '-'
          }}
        </template>
        <template #[`item.totalExecuted`]="{ item }">
          {{
            item.totalExecuted ?
              item.totalExecuted + ' hrs' : '-'
          }}
        </template>
        <template #[`item.pendingEffort`]="{ item }">
          {{
            item.pendingEffort ?
              item.pendingEffort + ' hrs' : '-'
          }}
        </template>
      </v-data-table>
    </div>
  </v-container>
</template>

<script>
import moment from "moment"
import TaskListManager from './../helpers/task-list-manager'

export default {
  props: {
    users: Array,
    project: Object,
    workItems: Array,
    selectParentTasks: Boolean,
  },
  data() {
    return {
      userId: null,
      search: null,
      status: [],
      selectedTypes: [],
      relationType: 0,
      relationTypes: [
        { text: 'Task', iconClass: 'mdi-file-tree' },
        { text: 'Work Item', iconClass: 'mdi-view-dashboard-outline' },
      ],
      taskStatuses: [
        {
          name: "Not Started", id: 'Not Started',
          iconClass: 'mdi-progress-clock', color: 'primary'
        },
        {
          name: "In Progress", id: 'In Progress',
          iconClass: 'mdi-autorenew', color: '#fcaf54'
        },
        {
          name: "Done", id: 'Done',
          iconClass: 'mdi-checkbox-marked-circle-outline', color: ''
        },
      ],
      workItemHeaders: [
        { text: 'Work Item', value: 'code' },
        { text: 'Summary', value: 'name' },
        { text: 'Assigned To', value: 'assignedTo' },
        { text: 'Status', value: 'status' },
        { text: 'Estimated', value: 'estimatedEffort', align: 'center' },
        { text: 'Executed', value: 'totalExecuted', align: 'right' },
        { text: 'Pending', value: 'pendingEffort', align: 'right' },
      ],
      selected: null,
    }
  },
  computed: {
    sortedTasks() {
      if (!this.project || !this.project.tasks) return []

      return _.orderBy(this.project.tasks, ["sortIndex"], ['asc'])
    },
    workItemTypes(){
      if(!this.relationType) return []
      let types = {}
      for(let workItem of this.workItems){
        types[workItem.type] = {
          value: workItem.type,
          displayName: workItem.typeDisplayName,
          color: workItem.typeColor,
          iconClass: workItem.typeIconClass,
        }
      }
      return _.toArray(types)
    },
    shownWorkItems(){
      let types = this.workItemTypes
        .filter(
          (t, ix) => this.selectedTypes.some(jx => jx == ix)
        )
      let workItems = this.workItems
        .filter(
          wi =>
          (!types.length || types.some(t => t.value == wi.type)) &&
          (wi.assignedToId == this.userId || !this.userId) &&
          (this.status.some(s => s == wi.statusId) || !this.status.length)
        )
      workItems = this.applySearch(this.search, workItems, ['code', 'name', 'assignedToFullName', 'assignedToBlueTag', 'statusName', 'typeName'])
      return workItems
    },
    workItemStatuses(){
      if(!this.relationType) return []
      let statuses = {}
      for(let workItem of this.shownWorkItems.filter(wi => !wi.statusIsFinal)){
        statuses[workItem.statusId] = {
          id: workItem.statusId,
          name: workItem.statusName,
          color: workItem.statusColor,
          iconClass: workItem.statusIconClass,
          sortIndex: workItem.statusSortIndex,
        }
      }
      return _.toArray(statuses).sort((a, b) => a.sortIndex - b.sortIndex)
    },
  },
  watch: {
    "project.tasks.length"() {
      if (this.project && this.project.tasks) {
        // TODO: add as part of the taskListManager
        _.each(this.project.tasks, t => {
          this.$set(t, "collapsed", false)
          this.$set(t, "filteredOut", false)
          this.$set(t, "hidden", false)
        })
        handler.init(this.project)
        handler.setStatus(this.status)
        handler.collapseAll()
      }
    },
    "userId"() {
      handler.setUserId(this.userId)
    },
    "search"() {
      handler.setSearch(this.search)
    },
    "status"() {
      handler.setStatus(this.status)
    },
  },
  mounted() {
    window.addEventListener("keydown", this.keyPress)
    this.setUserPreferences('TaskChooser', 'relationType', 'selectedTypes')
  },
  destroyed() {
    window.removeEventListener("keydown", this.keyPress)
  },
  methods: {
    keyPress(evt) {
      evt.stopPropagation()
      
      let leftKey = evt.keyCode === 37
      let upKey = evt.keyCode === 38
      let rightKey = evt.keyCode === 39
      let downKey = evt.keyCode === 40
      let enter = evt.keyCode === 13

      if (downKey || upKey) {

        let visibleTasks = this.sortedTasks.filter(t => !t.hidden)
        if (visibleTasks.length === 0) return

        let nextIx = visibleTasks.indexOf(this.selected)
        if (downKey && nextIx === -1) {
          nextIx = 0
        } else if (upKey && nextIx === -1) {
          nextIx = visibleTasks.length - 1
        } else if (downKey) {
          nextIx = (nextIx + 1) % visibleTasks.length
        } else if (upKey) {
          nextIx = (nextIx - 1 + visibleTasks.length) % visibleTasks.length
        }
        this.selected = visibleTasks[nextIx]
        
        return
      }

      if (rightKey && this.selected && this.selected.collapsed) {
        this.collapseTask(this.selected)
        return
      }
      if (leftKey && this.selected) {
        if (this.selected.isParent && !this.selected.collapsed) {
          this.collapseTask(this.selected)
        } else if (!this.selected.isParent) {
          // TODO: Set parent task selected
        }
        return
      }
      if (enter && this.selected) {
        this.chooseTask(this.selected)
        return
      }

    },
    
    collapseTask(taskChosen) {
      if (!taskChosen.reportable) {
        handler.collapseTask(taskChosen)
        return
      }
    },

    chooseTask(taskChosen) {
      if (!this.selectParentTasks && !taskChosen.reportable) {
        handler.collapseTask(taskChosen)
        return
      }

      this.$emit('itemChosen', taskChosen)

    },
    choseWorkItem(workItemChosen){
      workItemChosen.isWorkItem = true
      this.$emit('itemChosen', workItemChosen)
    },
    collapseAll() {
      handler.collapseAll()
    },
  },
  created() {
    handler = TaskListManager()
  },
}
var handler = null
</script>

<style scoped lang="scss">
::v-deep .hidden-input input{
  display: none !important;
}
.slider ::v-deep .v-btn::before{
  opacity: 0 !important;
}
.v-slide-group .v-btn:not(.v-slide-item--active)
{
  &:hover .v-icon{
    filter: none !important;
  }
}
.table {
  color: var(--v-text-lighten2);
}
.xxxs.v-btn {
  margin: 2px;
  height: 13px;
  min-width: 13px;
  margin: 0;
  padding: 0;
}
.xxxs.v-btn ::v-deep i.v-icon {
  font-size: 11px;
}
.v-dialog__content {
  align-items: start;
}
.task-status {
    font-size: 85%;
    padding: 1px 6px;
    border-radius: 2px;
    white-space: nowrap;
}
.task-status.In.Progress {
    background: #e8a300;
    color: #fff;
}
.task-status.Done {
    background: #0090a9;
    color: #fff;
}
.task-status.Not.Started {
    background: #797979;
    color: #fff;
}

.parent-task { font-weight: 700; }
.parent-task.collapsed-task td { border-bottom-style: groove; }
.level1 { background: var(--v-background-lighten); }
.level2 { background: var(--v-background-lighten2); }
.level3 { background: var(--v-background-lighten3); }
.level4 { background: var(--v-background-base); }

table {
  border-spacing: 0;
  width: 100%;
}
.task-row:hover {
  color: #087aad;
}
.task-row.parent-task:hover {
  color: inherit;
}
.task-row.parent-task:hover .v-btn {
  color: #087aad;
}
.parent-selectable .task-row.parent-task:hover {
  color: #087aad;
}
.parent-selectable .task-row.parent-task:hover .v-btn {
  color: initial;
}

.task-row td {
    border-bottom: 1px solid var(--v-background-lighten2);
    border-spacing: 0px;
    padding: 4px 1px;
}
.selected-task {
  background: #7adaff;
}
</style>