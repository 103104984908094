<template>
  <v-chip pill :small="small" class="v-chip--select-multi">
    <v-avatar :class="[chipColor, small ? 'small' : '']"
      class="white--text"
      left
      v-text="initials"
    ></v-avatar>
    {{ text }}
  </v-chip>
</template>

<script>
import moment from 'moment'
import _ from "lodash"

export default {
  components: {  },
  props: {
    small: { type: Boolean, default: false},
    color: { type: String, default: null},
    text: { type: String, default: ''},
    code: { type: String, default: ''},
  },
  data: () => ({
    colors: ['red', 'pink', 'purple', 'deep-purple', 'indigo', 
      'blue', 'light-blue', 'cyan', 'teal', 'green', 'light-green', 
      'lime', 'yellow', 'amber', 'orange', 'deep-orange', 'brown', 
      'blue-grey'],
    defaultColor: 'grey',
  }),
  watch: {
  },
  computed: {
    initials() {
      return (this.code || this.text).split(' ').splice(0, 3).map(s => s.slice(0, 1)).join('').toUpperCase()
    },
    chipColor() {
      if (this.color) return this.color
      let len = this.colors.length
      if (this.code || this.text) {
        let ix = (this.code || this.text)
          .split('')
          .map(c => c.charCodeAt(0))
          .reduce((a,b) => (a + b) % len) % len
        return this.colors[ix]
      }
      return this.defaultColor
    },
  },
  methods: {
  },
  created() {
  },
  beforeDestroy() {
  },
}


</script>

<style scoped lang="scss">
  .small {
    font-size: .85em;
  }
</style>