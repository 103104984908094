<template>
  <v-dialog 
    :fullscreen="$vuetify.breakpoint.mdAndDown"
    scrollable 
    v-if="dialog" 
    v-model="dialog"
    :min-width="1100"
    max-width="70%"
    @keydown.esc="currentlyEditingElement ? () => {} : cancel"
    :persistent="currentlyEditingElement"
    @click:outside="currentlyEditingElement ? () => {} : cancel"
  >
    <v-card class="rounded-lg" color="high-back">
      <v-card-title class="px-2 py-0" style="background: var(--v-lightgray-lighten)">
        <div style="display: grid; grid-template-columns: 1fr 96px; width: 100%;">
          <div class="header d-flex align-center">
            <div class="d-flex align-center" style="gap: 5px; height: 48px;">
              <div class="d-flex align-center">
                <div
                  class="d-flex align-center justify-center rounded mr-1"
                  style="width: 1.3rem; height: 1.3rem"
                  :style="{ backgroundColor: workItem.statusColor }"
                >
                  <v-icon v-if="workItem.statusIconClass" class="white--text" x-small>
                    {{workItem.statusIconClass}}
                  </v-icon>
                </div>
                <span class="body-2 nobr">
                  Status:
                </span>
                <v-menu offset-x>
                  <template #activator="{ on }">
                    <v-btn v-on="on" text small class="px-1 header-btn">
                      <span class="body-2" style="max-width: 100px; overflow: hidden; text-overflow: ellipsis;">
                        {{ workItem.statusName }}
                      </span>
                      <v-icon right class="mx-0">keyboard_arrow_down</v-icon>
                    </v-btn>
                  </template>
                  <v-list dense>
                    <v-list-item
                      v-for="(item, ix) in statuses" :key="ix"
                      @click="saveStatus(item)"
                    >
                      <v-list-item-icon>
                        <v-icon :color="item.color">{{ item.iconClass }}</v-icon>
                      </v-list-item-icon>
                      <v-list-item-title :color="item.color">
                        {{ item.name }}
                      </v-list-item-title>
                    </v-list-item>
                  </v-list>
                </v-menu>
              </div>

              <div class="d-flex align-center">
                <span class="body-2 nobr">
                  Sprint:
                </span>
                <v-menu offset-x>
                  <template #activator="{ on }">
                    <v-btn v-on="on" text small class="px-1 header-btn">
                      <span class="body-2">
                        {{ workItem.currentSprintName || 'Backlog' }}
                      </span>
                      <v-icon right class="mx-0">keyboard_arrow_down</v-icon>
                    </v-btn>
                  </template>
                  <v-list dense class="tags-container">
                    <v-list-item
                      v-for="item in sprints" :key="item.id"
                      @click="toggleSprint(item.id)" color="red"
                    >
                      <v-list-item-title class="d-flex align-center">
                        <v-icon left small>
                          {{
                            workItem.sprints && workItem.sprints.some(x => x.sprintId == item.id) ?
                              'mdi-checkbox-marked' : 'mdi-checkbox-blank-outline'
                          }}
                        </v-icon>
                        <v-chip :color="item.color" dark small>
                          {{ item.name }}
                        </v-chip>
                      </v-list-item-title>
                    </v-list-item>
                  </v-list>
                </v-menu>
              </div>
              <div class="d-flex align-center">
                <span class="body-2 nobr">
                  Priority:
                </span>
                <v-menu offset-x>
                  <template #activator="{ on }">
                    <v-btn v-on="on" text small class="px-1 header-btn">
                      <v-img width="24px" height="24px" :src="getPriorityImage()" contain></v-img>
                      <v-icon right class="mx-0">keyboard_arrow_down</v-icon>
                    </v-btn>
                  </template>
                  <v-list dense>
                    <v-list-item
                      v-for="(item, ix) in priorities" :key="ix"
                      @click="save(item.value, 'priority')"
                    >
                      <v-img class="mr-2" width="24px" height="24px" :src="require(`../assets/priority-icons/${item.value}.png`)" contain></v-img>
                      <v-list-item-title :color="item.color">
                        {{ item.displayName }}
                      </v-list-item-title>
                    </v-list-item>
                  </v-list>
                </v-menu>
              </div>

              <div class="d-flex align-center">
                <span class="body-2 nobr">
                  Value:
                </span>
                <v-menu offset-x>
                  <template #activator="{ on }">
                    <v-btn v-on="on" text small class="px-1 header-btn">
                      <v-img width="24px" height="24px" :src="getValueImage()" contain></v-img>
                      <v-icon right class="mx-0">keyboard_arrow_down</v-icon>
                    </v-btn>
                  </template>
                  <v-list dense>
                    <v-list-item
                      v-for="(item, ix) in priorities" :key="ix"
                      @click="save(item.value, 'value')"
                    >
                      <v-img class="mr-2" width="24px" height="24px" :src="require(`../assets/priority-icons/${item.value}.png`)" contain></v-img>
                      <v-list-item-title :color="item.color">
                        {{ item.displayName }}
                      </v-list-item-title>
                    </v-list-item>
                  </v-list>
                </v-menu>
              </div>
    
              <div v-if="isBug" class="d-flex align-center">
                <span class="body-2 nobr">
                  Source:
                </span>
                <v-menu offset-x>
                  <template #activator="{ on }">
                    <v-btn v-on="on" text small class="px-1 header-btn">
                      <span class="body-2" style="max-width: 100px; overflow: hidden; text-overflow: ellipsis;">
                        {{ workItem.defectOriginName }}
                      </span>
                      <v-icon right class="mx-0">keyboard_arrow_down</v-icon>
                    </v-btn>
                  </template>
                  <v-list dense>
                    <v-list-item
                      v-for="(item, ix) in projectConfig.defectsOrigins" :key="ix"
                      @click="save(item.id, 'defectOriginId')"
                    >
                      <v-list-item-title> {{ item.name }}  </v-list-item-title>
                    </v-list-item>
                  </v-list>
                </v-menu>
              </div>
    
              <div class="d-flex align-center">
                <span class="body-2 nobr">
                  Effort:
                </span>
                <estimated-dev-effort :value="workItem.estimatedEffort" @change="(value) => save(value, 'estimatedEffort')">
                  <template #[`menu.activator`]="{ on }">
                    <v-btn v-on="on" text x-small class="px-1 header-btn">
                      <span class="body-2">
                        {{ workItem.estimatedEffort }}
                      </span>
                      <v-icon small>keyboard_arrow_down</v-icon>
                    </v-btn>
                  </template>
                </estimated-dev-effort>
              </div>
              
              <div class="d-flex align-center">
                <span class="body-2 mr-2 nobr">
                  Hours:
                </span>
                <span class="body-2" style="font-weight: 500;">
                  {{ roundedTotalHours(workItem.totalHours) }}
                </span>
              </div>
            </div>

            <v-spacer/>
            <div class="d-flex" style="gap: 10px">
              <div class="d-flex align-center header-btn" style="gap: 2px">
                <v-icon small>mdi-clock-check-outline</v-icon>
                <span class="caption nobr">
                  {{
                    getIndicatorTime(
                      (isNullDate(workItem.initiatedAt) || workItem.leadTime) ? null : workItem.age,
                      false, projectConfig.usingWorkingHours, true
                    )
                  }}
                </span>
              </div>
              <div class="d-flex align-center header-btn" style="gap: 2px">
                <v-icon small>mdi-clock-outline</v-icon>
                <span class="caption nobr">
                  {{ getIndicatorTime(workItem.currentColumnAge, false, projectConfig.usingWorkingHours, true) }}
                </span>
              </div>
              <div class="d-flex align-center header-btn" style="gap: 2px">
                <v-icon small>mdi-alarm</v-icon>
                <span class="caption nobr">
                  {{
                    getIndicatorTime(
                      (isNullDate(workItem.initiatedAt) || workItem.age || !workItem.statusIsFinal) ? null : workItem.leadTime,
                      false, projectConfig.usingWorkingHours, true
                    )
                  }}
                </span>
              </div>
            </div>

            <template v-if="isTask">
              <v-spacer/>
              <div class="d-flex align-center">
                <span class="body-2 mr-2 nobr">
                  Due Date:
                </span>
                <q-date-picker
                  :value="workItem.dueDate"
                  @input="save($event, 'dueDate')"
                  icon=""
                  flat solo hide-details
                  background-color="var(--v-lightgray-lighten)"
                  label="-"
                  class="body-2"
                  style="width: 120px; font-weight: 500;"
                />
              </div>
            </template>

            <v-spacer/>
          </div>

  
          <div class="d-flex justify-end align-center" style="width: 96px">
            <v-menu offset-y>
              <template #activator="{ on: menuOn }">
                <v-tooltip top>
                  <template #activator="{ on }">
                    <v-btn v-on="{ ...on, ...menuOn }" text fab x-small>
                      <v-icon size="22">more_vert</v-icon>
                    </v-btn>
                  </template>
                  <span>Options</span>
                </v-tooltip>
              </template>
    
              <v-list :style="{padding: '3px 0'}" class="extra-options">
                <v-list-item>
                  <v-menu offset-y>
                    <template v-slot:activator="menuActivator">
                      <v-tooltip top>
                        <template v-slot:activator="{ on }">
                          <span v-on="on">
                            <v-btn text small v-on="menuActivator.on">
                              <v-icon :color="workItem.watching ? 'blue darken-2' : ''">visibility</v-icon>
                              <span v-if="workItem.subscribedUsers && workItem.subscribedUsers.length">
                                {{workItem.subscribedUsers.length}}
                              </span>
                            </v-btn>
                          </span>
                        </template>
                        <span>Watch</span>
                      </v-tooltip>
                    </template>
                    <v-list dense>
                      <v-list-item @click="toggleSubscribe">
                        <v-list-item-title v-if="workItem.watching">Stop watching</v-list-item-title>
                        <v-list-item-title v-else>Start watching</v-list-item-title>
                      </v-list-item>
                      <v-divider></v-divider>
                      <v-list-item v-if="workItem.subscribedUsers && workItem.subscribedUsers.length">
                        <v-list-item-title>Watchers:</v-list-item-title>
                      </v-list-item>
                      <v-list-item v-for="(subscribedUser, ix) in workItem.subscribedUsers" :key="ix">
                        <v-list-item-avatar :size="20" color="indigo" class="font-weight-light white--text">
                          <span v-if="subscribedUser && subscribedUser.subscriberBlueTag">
                            {{ subscribedUser.subscriberBlueTag.charAt(0) }}
                          </span>
                        </v-list-item-avatar>
                        <v-list-item-title>
                          {{subscribedUser.subscriberBlueTag}}
                        </v-list-item-title>
                      </v-list-item>
                    </v-list>
                  </v-menu>
                </v-list-item>
                <v-list-item v-if="isTask && !isPersonal">
                  <v-tooltip left>
                    <template v-slot:activator="{ on }">
                      <v-btn @click="save(!workItem.showInOverview, 'showInOverview')" small text v-on="on" :disabled="!canEditProject" :style="{textTransform: 'none', padding: '0'}">
                        <v-icon v-if="workItem.showInOverview">mdi-file-chart-check-outline</v-icon>
                        <v-icon v-else>mdi-file-chart-outline</v-icon>
                      </v-btn>
                    </template>
                    <span>Show{{workItem.showInOverview ? 'ing' : ''}} in Overview</span>
                  </v-tooltip>
                </v-list-item>
    
                <v-list-item>
                  <v-tooltip left>
                    <template v-slot:activator="{ on }">
                      <v-btn small text v-on="on" @click="cloneWorkItem" :disabled="!canEditProject" :style="{textTransform: 'none', padding: '0'}" v-if="canClone">
                        <v-icon>mdi-content-copy</v-icon>
                      </v-btn>
                      <v-btn v-else small text class="loading-icon">
                        <v-icon >mdi-loading</v-icon>
                      </v-btn>
                    </template>
                    <span>Copy/Clone</span>
                  </v-tooltip>
                </v-list-item>
    
                <v-list-item>
                  <v-tooltip left>
                    <template v-slot:activator="{ on }">
                      <v-btn small text v-on="on" @click="deleteWorkItem" :disabled="!canDeleteProject" :style="{textTransform: 'none', padding: '0'}">
                        <v-icon>delete</v-icon>
                      </v-btn>
                    </template>
                    <span>Delete</span>
                  </v-tooltip>
                </v-list-item>
    
              </v-list>
            </v-menu>
    
            <v-tooltip top>
              <template #activator="{ on }">
                <v-btn v-on="on" text fab x-small @click.stop="drawer = !drawer">
                  <v-icon :color="drawer ? 'blue darken-2' : '#666'" size="22">mdi-book-open-outline</v-icon>
                </v-btn>
              </template>
              <span>{{ drawer? 'Close Drawer' : 'Open Drawer' }}</span>
            </v-tooltip>

            <v-tooltip right>
              <template #activator="{ on }">
                <v-btn v-on="on" ref="cancel" text fab x-small @click="cancel">
                  <v-icon :style="{color: 'var(--v-text-lighten2)'}" size="22">close</v-icon>
                </v-btn>
              </template>
              <span>Close</span>
            </v-tooltip>
          </div>
        </div>

      </v-card-title>
      <v-card-text class="pb-0" :style="{ overflow: 'hidden', paddingRight: '8px !important' }">
        <v-row :style="{ height: '100%'}" class="mt-0">
          <v-col :style="{ maxHeight: '100%', overflow: 'auto', 'flex-grow': '0' }" cols="12" :sm="drawer ? 8 : 12" class="hide-scrollbar">
            <div class="d-flex align-center mb-2" style="gap: 20px">
              <v-btn class="btn-persistent-background rounded-lg" :color="workItem.typeColor" fab small icon>
                <v-icon>{{ workItem.typeIconClass }}</v-icon>
              </v-btn>
              
              <div class="d-flex flex-column justify-space-between flex">
                <div>
                  <q-text-field
                    placeholder="Summary" class="summary" background-color="high-back"
                    :disabled="!canEditProject"
                    medium
                    v-model="workItem.name"
                    @focus="currentlyEditingElement = true"
                    @save="(value, event) => saveAndCheckIfClosedDialog(value, 'name', event)"
                    :handleEsc="true"
                    :handleSave="true"
                  />
                </div>
                <div class="d-flex align-center mb-2">
                  <p :style="{ marginBottom: 0, }">
                    {{ workItem.code }}
                  </p>
                  <div class="py-0" cols="2">
                    <v-menu open-on-click bottom offset-x :close-on-content-click="false">
                      <template v-slot:activator="{ on }">
                        <div
                          v-on="canEditProject ? on : null" class="color-tags"
                          :style="{ marginLeft: '12px', cursor: 'pointer', display: 'flex', justifyContent: 'center', alignItems: 'center', background: color, width: '28px', color: isBlackText(color) ? 'white' : 'black', borderRadius: '50%'}"
                        >
                          <v-icon :color="isBlackText(color) ? '#eee' : 'black'">invert_colors</v-icon>
                        </div>
                      </template>
                      <color-picker
                        :workItem="true"
                        v-model="color"
                        @update:color="colorChanged"
                      />
                    </v-menu>
                  </div>
                </div>
              </div>

              <div class="d-flex mt-3" style="align-self: flex-start;">
                <v-menu offset-y :close-on-content-click="false">
                  <template #activator="{ on: onMenu }">
                    <v-tooltip top>
                      <template #activator="{ on: onTooltip }">
                        <div v-on="{ ...onMenu, ...onTooltip }">
                          <user-avatar
                            :user-id="workItem.assignedToId"
                            :show-card="false" :size="34"
                          />
                        </div>
                      </template>
                      <span>Assigned To</span>
                    </v-tooltip>
                  </template>
                  <v-card color="high-back">
                    <v-card-text>
                      <div>
                        Assigned to
                        <q-user-autocomplete
                          v-model="workItem.assignedToId" :items="users" 
                          item-value="id" item-text="blueTag" item-subtitle="fullName"
                          class="mt-n3"
                          @save="save($event, 'assignedToId')"
                        />
                      </div>
                    </v-card-text>
                  </v-card>
                </v-menu>
                <v-menu offset-y :close-on-content-click="false">
                  <template #activator="{ on: onMenu }">
                    <v-tooltip top>
                      <template #activator="{ on: onTooltip }">
                        <div v-on="{ ...onMenu, ...onTooltip }">
                          <user-avatar
                            :user-id="workItem.reportedById"
                            :show-card="false" :size="34"
                          />
                        </div>
                      </template>
                      <span>Reported By</span>
                    </v-tooltip>
                  </template>
                  <v-card color="high-back">
                    <v-card-text>
                      <div>
                        Reported by
                        <q-user-autocomplete
                          v-model="workItem.reportedById" :items="users" 
                          item-value="id" item-text="blueTag" item-subtitle="fullName"
                          class="mt-n3"
                          @save="save($event, 'reportedById')"
                        />
                      </div>
                    </v-card-text>
                  </v-card>
                </v-menu>
              </div>
            </div>
            <div class="d-flex align-center flex-wrap mb-3" style="gap: 5px">
              <span v-if="!workItem.tags || !workItem.tags.length" class="body-1">
                No tags
              </span>
              <v-chip 
                v-for="(tag, ix) in workItem.tags" :key="ix"
                :style="{ color: isBlackText(tag.tagColor) ? 'white' : 'black' }"
                small dark label :color="tag.tagColor"
              >
                {{tag.tagName}}
              </v-chip>
              <v-menu open-on-click bottom offset-x :close-on-content-click="false">
                <template v-slot:activator="{ on }">
                  <v-chip v-on="on" label small>
                    <v-icon small left>label</v-icon>
                    <div class="mx-2">Tags</div>
                    <v-icon right size="20">chevron_right</v-icon>
                  </v-chip>
                </template>

                <v-list dense class="py-1 tags-container">
                  <v-list-item @click="openTagDialog">
                    <v-list-item-content>
                      <v-list-item-title>
                        <v-chip
                          :style="{ display: 'block', cursor: 'pointer', color: 'black' }" small dark color="white"
                        >
                          <v-icon small left>add</v-icon>
                          Create tag
                        </v-chip>
                      </v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                  <v-tooltip right :disabled="!tag.description"  v-for="(tag, ix) in tags" :key="ix">
                    <template v-slot:activator="{ on }">
                      <v-list-item class="" v-on="on"
                          @click="toggleTag(tag)">
                        <v-list-item-content>
                          <v-list-item-title>
                            <v-chip
                              :style="{ display: 'block', cursor: 'pointer', color: isBlackText(tag.color) ? 'white' : 'black' }" small dark :color="tag.color"
                            >
                              <v-icon small left v-if="workItem && workItem.tags && workItem.tags.some(t => t.tagId == tag.id)">check</v-icon>
                              <v-icon small left v-else color="grey lighten-1">clear</v-icon>
                              {{tag.name}}
                            </v-chip>
                          </v-list-item-title>
                        </v-list-item-content>
                      </v-list-item>
                    </template>
                    <div style="white-space:pre-wrap; word-break:break-word; max-width: 280px;" v-html="tag.description ? JSON.parse(tag.description) : ''"></div>
                  </v-tooltip>
                </v-list>
              </v-menu>
            </div>
            <br>

            <div class="progress-container">
              <h3>Effort Report</h3>
              <div :style="{width: '50%', display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'flex-end'}">
                <v-progress-linear
                  :style="{width: '20%', marginRight: '20px'}"
                  color="#0171fc"
                  rounded
                  height="10"
                  :value="(workItem.totalExecuted / workItem.estimatedEffort) * 100"
                ></v-progress-linear>
                <span class="body-1">
                  {{ workItem.totalExecuted }}h/{{ workItem.estimatedEffort }}h
                </span>
              </div>
            </div>
            <h3 class="mt-6">Description</h3>
            <q-rich-editor
              @close="currentlyEditingElement = false"
              @focus="currentlyEditingElement = true"
              class="pb-0"
              include-buttons autofocus
              v-model="workItem.description"
              :disabled="!canEditProject"
              @save="(value, id) => saveDescription(value, id)"
              :user-suggestions="users"
              v-user-mention="!currentlyEditingElement"
            >
            </q-rich-editor>

            <div v-show="isBug">
              <br>
              <h3>Steps to Reproduce</h3>
              <q-rich-editor
                @close="currentlyEditingElement = false"
                @focus="currentlyEditingElement = true"
                class="pb-0"
                v-model="workItem.stepsToReproduce"
                :disabled="!canEditProject"
                @save="(value, id) => save(value, 'stepsToReproduce', id)"
                includeButtons
              >
              </q-rich-editor> 
              <div class="mt-1" v-if="originals.stepsToReproduce != workItem.stepsToReproduce">
              </div>
              <br>

              <h3>Expected Result</h3>
              <q-rich-editor
                @close="currentlyEditingElement = false"
                @focus="currentlyEditingElement = true"
                class="pb-0"
                v-model="workItem.expectedResult"
                :disabled="!canEditProject"
                @save="(value, id) => save(value, 'expectedResult', id)"
                includeButtons
              >
              </q-rich-editor> 
              <div class="mt-1" v-if="originals.expectedResult != workItem.expectedResult">
              </div>
              <br>
            </div>

            <h3 class="mt-3">Files</h3>
            <div wrap class="d-flex flex-wrap" style="gap: 10px">
              <div v-for="(wiFile, ix) in workItem.files" :key="ix" class="d-flex align-center" :title="wiFile.name">
                <v-img v-if="wiFile.isImage"
                  class="ma-1 cursor-pointer rounded-lg" style="width: 90px; height: 90px; position: relative;"
                  @click="openImage(wiFile)" :src="getBackendUrl(`/api/workItem/${workItem.id}/image/${wiFile.id}`)"
                />
                <div
                  v-else
                  class="d-flex flex-column align-center justify-center bordered rounded-lg overflow-hidden"
                  style="width: 90px; height: 90px; position: relative"
                >
                  <v-icon large>mdi-file-document-outline</v-icon>
                  <span class="caption" style="overflow: hidden; white-space: nowrap; text-overflow: ellipsis; width: 80px">{{ wiFile.name }}</span>

                  <div
                    class="d-flex flex-column align-center justify-center show-on-hover"
                    style="height: 90px; width: 90px; position: absolute; left: 0; top: 0; gap: 10px; background: rgba(0,0,0,0.5)"
                  >
                    <v-btn color="red" @click.stop="downloadFile(wiFile)" x-small outlined>
                      <v-icon small class="rotate-90">forward</v-icon>
                    </v-btn>
                    <v-btn color="red" @click.stop="deleteImage(wiFile, !wiFile.isImage)" x-small dark :disabled="!canEditProject">
                      <v-icon small>delete</v-icon>
                    </v-btn>
                  </div>
                </div>
              </div>
              <div class="d-flex align-center justify-center" style="height: 90px">
                <v-btn @click="$refs.newImage.click()" class="secondary" small fab depressed>
                  <v-icon color="gray">mdi-plus</v-icon>
                </v-btn>
              </div>
            </div>
            <input class="d-none" type="file" id="newImage" ref="newImage" @change="handleImageUpload()"/>

            <h3 class="mt-3 mb-n2">Videos</h3>
            <div class="d-flex flex-column" :style="{gap: '10px'}">
              <div
                v-for="video in videos" :key="video.key"
                class="video d-flex align-stretch"
              >
                <div>
                  <div class="text-right"> {{ video.dateTime | formatDateTime }} </div>
                  <video width="500" preload="metadata" controls="controls">
                    <source :src="video.url" type="video/mp4">
                  </video>
                </div>
                <div class="actions d-flex align-center">
                  <v-btn @click="downloadVideo(video.url)" color="primary" large icon>
                    <v-icon>mdi-download</v-icon>
                  </v-btn>
                  <v-btn @click="removeVideo(video.key)" color="error" large icon>
                    <v-icon>mdi-delete</v-icon>
                  </v-btn>
                </div>
              </div>
              <div>
                <div v-if="!this.recording" class="d-flex align-center" style="height: 90px">
                  <v-btn @click="recordScreen" class="secondary" small fab depressed>
                    <v-icon color="gray">mdi-plus</v-icon>
                  </v-btn>
                </div>
                <div v-else class="d-flex align-center px-2" style="gap: 10px">
                  <div id="record"></div>
                  <div id="counter">
                    <h1>{{ formatTime(recordingTime/60) }}:{{ formatTime(recordingTime%60) }}</h1>
                  </div>
                
                  <div @click="closePanel" class="d-flex flex-column align-center cursor-pointer ml-5">
                    <span
                      style="font-size: 2.5em; color: #167bd7"
                      class="material-symbols-outlined"
                    >
                      stop
                    </span>
                    Stop
                  </div>
                </div>
              </div>
            </div>

            <div class="mb-3">
              <div class="d-flex justify-space-between align-center my-1">
                <h3>Acceptance Requirements</h3>
                <div :style="{display: 'flex', flexDirection: 'row', width: '50%', alignItems: 'center', justifyContent: 'flex-end'}" v-if="workItem.acceptanceRequirements && workItem.acceptanceRequirements.length">
                  <v-progress-linear
                    :style="{width: '20%', marginRight: '20px'}"
                    color="#0171fc"
                    rounded
                    height="10"
                    :value="acceptancePercentage"
                  ></v-progress-linear>
                  <span class="body-1">
                    {{ acceptancePercentage }}% Complete
                  </span>
                </div>
                <span v-else class="body-1">No acceptance requirements</span>
              </div>
              <v-expansion-panels>
                <draggable v-model="workItem.acceptanceRequirements" @change="moveRequirement" class="rounded-lg pb-1" style="width: 100%">
                  <v-expansion-panel
                    v-for="(requirement, ix) in workItem.acceptanceRequirements" :key="ix"
                    class="bordered rounded-lg overflow-hidden mt-0" :class="{ 'remove-border-bottom': workItem.acceptanceRequirements.length-1 != ix }"
                  >
                    <v-expansion-panel-header class="py-0" v-slot:default="{ open }" color="high-back">
                      <div>
                        <v-checkbox
                          v-model="requirement.done"
                          @change="checkRequirement(requirement)"
                          @click.stop :label="open ? requirement.name : setMaxText(requirement.name, 67)"
                          hide-details class="mt-0 inline-block"
                          style="word-break: break-word;"
                        />
                      </div>
                    </v-expansion-panel-header>
                    <v-expansion-panel-content color="high-back">
                      <span v-user-mention v-html="requirement.description"></span>
                      <div class="text-right">
                        <v-btn @click="deleteRequirement(requirement.id)" small outlined color="error">
                          <v-icon small>mdi-delete</v-icon>
                          Delete
                        </v-btn>
                        <v-btn @click="openRequirementDialog(requirement)" small outlined class="ml-1" color="primary">
                          <v-icon small>mdi-pencil</v-icon>
                          Edit
                        </v-btn>
                      </div>
                    </v-expansion-panel-content>
                  </v-expansion-panel>
                </draggable>
              </v-expansion-panels>
              <v-btn
                color="default" class="px-2"
                depressed small
                @click="openRequirementDialog"
              >
                <v-icon small>mdi-plus</v-icon>
                <span class="caption" style="text-transform: none !important;">Add new</span>
              </v-btn>
            </div>

            <br>
            <linked-work-items
              :workItems="projectWorkItems"
              :type="1"
              :linked="linkedBugs"
              title="Bugs"
              :projectConfig="projectConfig"
              :projects="projects"
              :linkRelationships="linkRelationships"
              @update="getWorkItem"
              :save="saveLink"
              :create="createWorkItem"
              :createWorkItem="true"
              :open="openRelatedWorkItem"
            />
            <br>
            <linked-work-items
              :workItems="projectWorkItems"
              :linked="linkedWorkItems"
              title="Work items"
              class="mt-5"
              :projectConfig="projectConfig"
              :projects="projects"
              :linkRelationships="linkRelationships"
              @update="getWorkItem"
              :save="saveLink"
              :open="openRelatedWorkItem"
            />

          </v-col>
          <v-navigation-drawer
            :permanent="drawer"
            right
            class="navigation-drawer-bg high-back"
            v-model="drawer"
            height=""
            :style="{width: '33%', height: '100%', display: drawer ? 'block' : 'none'}"
          >
            <v-col cols="12" class="d-flex flex-column" :style="{ height: '100%' }">
              <div>
                <v-btn-toggle v-model="showActivity" multiple small class="high-back mb-1">
                  <v-btn :style="showActivity.some(v => v === 'comments') ? selectedToggleButton : unselectedToggleButton" class="button-toggle" value="comments">Comments</v-btn>
                  <v-btn :style="showActivity.some(v => v === 'history') ? selectedToggleButton : unselectedToggleButton" class="button-toggle" value="history">History</v-btn>
                  <v-btn :style="showActivity.some(v => v === 'efforts') ? selectedToggleButton : unselectedToggleButton" class="button-toggle" value="efforts">Efforts</v-btn>
                </v-btn-toggle>
              </div>
              <div class="flex overflow-hidden" style="min-height: 0;">
                <p class="mt-3" v-if="!history.length">
                  <i>No changes yet.</i>
                </p>
                <input class="d-none" type="file" accept="image/*" id="commentImage" ref="commentImage" v-on:change="addCommentImage()"/>
                <div :style="{ height: '100%', overflowY: 'scroll', overflowX: 'hidden'}" class="hide-scrollbar">
                  <div>
                    
                    <div class="mb-3" v-for="record in history" :key="record.id">
                      <v-row v-if="record.type === 'comment'" class="comment" style="margin: 0;">
                        <v-col cols="2" :style="{ display: 'flex', alignItems: 'center'}" class="py-0">
                          <user-avatar :showCard="true" :userId="record.createdById" :size="40" hide-loading></user-avatar>
                        </v-col>
                        <v-col cols="10" class="py-0 pr-0" v-if="!record.deleted">
                          <b>{{record.createdByBlueTag || record.createdByFullName}}</b>
                          <div class="edit-comment comment" v-if="commentSelected && commentSelected.id == record.id">
                            <rich-editor
                              v-model="commentSelected.comment"
                              :handlePaste="handlePasteComment"
                              :imageHandler="() => $refs.commentImage.click()"
                              id="comment-editor"
                              :handleEsc="unselectComment"
                              @focus="currentlyEditingElement = true"
                              @save="(id) => editComment(id)"
                              autofocus
                              :user-suggestions="users"
                            ></rich-editor>
                            <v-btn color="primary" small @click="editComment"><v-icon color="white">check</v-icon></v-btn>
                            <v-btn id="cancel-btn" style="margin-right: 8px" small @click="unselectComment"><v-icon>close</v-icon></v-btn>
                          </div>
                          <v-sheet
                            v-else
                            :class="(currentUser && record.createdById == currentUser.id) ? 'comment-me' : 'comment-other'"
                            class="ma-1 pa-1 mr-0"
                            v-user-mention
                            v-html="record.comment"
                            @click="setEditComment(record)"
                          ></v-sheet>
                      
                          <div class="reactions px-1">

                            <v-tooltip top v-for="(reaction, ix) in groupedReactions(record)" :key="ix">
                              <template v-slot:activator="{ on }">
                                <v-btn class="emoji-btn px-1 mr-1 mb-1 background-lighten" v-on="on" @click="toggleEmoticon(reaction.emoji, record)"
                                  :class="reaction.selected ? 'blue--text' : ''" x-small depressed>
                                  <v-icon v-if="reaction.emoji == 'like'" :color="reaction.count ? 'blue' : 'grey'">thumb_up</v-icon>
                                  <v-icon v-else-if="reaction.emoji == 'love'" :color="reaction.count ? 'red' : 'grey'">favorite</v-icon>
                                  <span class="emoji" v-else>{{reaction.emoji}}</span>
                                  <span class="emoji-count">{{reaction.count}}</span>
                                </v-btn>
                              </template>
                              <span>{{reaction.users}}</span>
                            </v-tooltip>
                            <q-emoji-picker @select="addEmoticon($event, record, true)"></q-emoji-picker>
                            <v-btn @click="deleteComment(record)" x-small color="error" class="float-right" v-if="currentUser && record.createdById == currentUser.id">
                              <v-icon small>mdi-delete</v-icon>
                            </v-btn>

                          </div>

                          <v-row wrap class="files pa-0 ma-1" v-if="record.files && record.files.length">
                            <v-img 
                              v-for="(img, ix) in record.files"
                              :key="ix" :src="img.url"
                              class="img cursor-pointer small"
                              @click="openCommentImages(record.files, img, currentUser && record.createdById == currentUser.id)"
                            ></v-img>
                          </v-row>

                          <span class="date-time">
                            {{record.createdAt | formatDateTime}}
                          </span>
                        </v-col>

                        <v-col cols="11" class="py-0" v-else>
                          <b>{{record.createdByBlueTag || record.createdByFullName}}</b>
                          <br>
                          <p class="coloredBack pa-2 rounded ml-1">
                            <v-icon>mdi-minus-circle-outline</v-icon> This comment was deleted.
                          </p>
                        </v-col>

                      </v-row>
                      <v-row v-if="record.type === 'change'" class="flex-nowrap" style="margin: 15px 0px;">
                        <div class="pa-2">
                          <user-avatar :showCard="true" :userId="record.createdById" :size="40" hide-loading></user-avatar>
                        </div>
                        <div class="flex-grow-1 px-1">
                          <work-item-change  :record="record"></work-item-change>
                          <span class="date-time">
                            {{record.dateTime | formatDateTime}}
                          </span>
                        </div>
                      </v-row>
                      <v-row v-if="record.type === 'effort'" class="flex-nowrap" style="margin: 15px 0px;">
                        <div class="pa-2">
                          <user-avatar :showCard="true" :userId="record.reporterId" :size="40" hide-loading></user-avatar>
                        </div>
                        <div class="flex-grow-1 px-1">
                          <work-item-change  :record="record"></work-item-change>
                          <div :style="{ height: '100%' }" class="d-flex justify-center flex-column">
                            <div :style="{ width: '100%' }">
                              <span>
                                {{record.executed}} hours were added
                              </span>
                              <v-icon>mdi-arrow-right</v-icon>
                            </div>
                            <span >
                              {{record.pending}} hours pending
                            </span>
                          </div>
                          <span class="date-time">
                            {{record.dateTime | formatDateTime}}
                          </span>
                        </div>
                      </v-row>
                    
                    </div>
                    <div v-if="showActivity.some(v => v === 'history')">
                      <v-row class="justify-left flex-nowrap" style="margin: 15px 0px;">
                        <div class="pa-2">
                          <user-avatar :showCard="true" :userId="workItem.createdById" :size="40" hide-loading></user-avatar>
                        </div>
                        <div class="px-1">
                          <div>
                            <b>{{workItem.createdByBlueTag || workItem.createdByFullName}}</b>
                            created the
                            {{workItem.typeDisplayName}}.
                          </div>
                          <span class="date-time">
                            {{workItem.createdAt | formatDateTime}}
                          </span>
                        </div>
                      </v-row>
                    </div>

                  </div>
                </div>
              </div>
              <div style="position: relative">
                <div>
                  <rich-editor
                    class=""
                    @focus="currentlyEditingElement = true"
                    @save="(id) => saveComment(id)"
                    v-model="newComment"
                    :imageHandler="() => $refs.newCommentImage.click()"
                    :handlePaste="handlePasteNewComment"
                    :style="{ borderRadius: '5px'}"
                    id="new-comment-editor"
                    autofocus
                    :hideMenuBar="hideMenuBar"
                    :user-suggestions="users"
                  >
                  </rich-editor>
                  <input class="d-none" type="file" accept="image/*" id="newCommentImage" ref="newCommentImage" v-on:change="addNewCommentImage()"/>
                  <v-row v-if="newCommentImages && newCommentImages.length" wrap class="files pa-2 my-1">
                    <v-img 
                      v-for="(img, ix) in newCommentImages"
                      :key="ix" :src="img.url"
                      class="img mx-1 cursor-pointer small"
                      @click="openNewCommentImages(img)"
                    ></v-img>
                  </v-row>
                  <div :style="{ top: hideMenuBar ? '4px' : '82px', right: '0px', position: 'absolute', display: 'flex', flexDirection: 'row', justifyContent: 'flex-end', marginLeft: '8px'}">
                    <v-btn @click="hideMenuBar = !hideMenuBar" :style="{width: '5px', minWidth: '5px'}" text small>
                      <v-icon>more_vert</v-icon>
                    </v-btn>
                  </div>
                  <div :style="{ bottom: '8px', right: '8px', position: 'absolute', display: 'flex', flexDirection: 'row', justifyContent: 'flex-end', marginLeft: '8px'}" class="mt-1">
                    <v-btn :style="{ width: '20px', height: '20px', minWidth: '15px'}" id="cancel-btn" outlined small color="grey" @click="cancelAddComment">
                      <v-icon x-small>close</v-icon>
                    </v-btn>  
                    <v-btn :style="{ width: '20px', height: '20px', marginLeft: '8px', minWidth: '15px', color: '#fff', backgroundColor: '#548ffd'}" small @click="saveComment">
                      <v-icon x-small>mdi-send</v-icon>
                    </v-btn>
                  </div>
                </div>
              </div>
            </v-col>
          </v-navigation-drawer>
        </v-row>
      </v-card-text>
    </v-card>
    <ImageCarousel ref="imageCarousel"/>
    <WorkItemDetailsDialog ref="relatedWorkItem" :isPersonal="false"/>
    <create-tag-dialog
      ref="createTagDialog"
      :callback="addNewTagToWorkItem"
    >
    </create-tag-dialog>
    <confirm-form-dialog
      ref="createDefectSourceDialog"
    />
    <WorkItemCreateDialog
      create-item-for-work-item
      ref="WorkItemCreateDialog"
    />
    <requirement-dialog ref="requirementDialog" :users="users"/>
  </v-dialog>
</template>

<script>
import moment from 'moment'
import ImageCarousel from '../components/ImageCarousel'
import _ from "lodash"
import CreateTagDialog from './CreateTagDialog'
import ConfirmFormDialog from './ConfirmFormDialog.vue'
import WorkItemCreateDialog from './WorkItemCreateDialog.vue'
import LinkedWorkItems from '../components/LinkedWorkItems'
import RequirementDialog from '../dialogs/RequirementDialog'
import draggable from 'vuedraggable'
import EstimatedDevEffort from '../components/EstimatedDevEffort.vue'

export default {
  name: 'WorkItemDetailsDialog',
  components: {
    ImageCarousel,
    CreateTagDialog,
    ConfirmFormDialog,
    WorkItemCreateDialog,
    LinkedWorkItems,
    RequirementDialog,
    draggable,
    EstimatedDevEffort
  }, 
  props: ['isPersonal', 'taskShow'],
  data: () => ({
    drawer: true,
    selectedToggleButton: {
      backgroundColor: '#0270FC !important',
      color: '#fff',
      boxShadow: 'none !important',
      border: 'thin solid currentColor',
    },
    unselectedToggleButton: {
      backgroundColor: 'var(--v-card-base) !important',
      color: '#0270FC',
      border: 'thin solid',
      borderColor: '#0270FC !important',
      boxShadow: 'none !important',
    },
    showActivity: ['comments'],
    dialog: false,
    hideMenuBar: true,
    resolve: null,
    reject: null,
    currentUser: null,
    priorities: [],
    users: [],
    projectConfig: {},
    statuses: [],
    defaultStatus: null,
    workItem: {},
    newComment: null,
    newCommentImages: [],
    addingComment: true,
    thumbUpCount: 0,
    thumbDownCount: 0,
    favoriteCount: 0,
    projectWorkItems: [],
    linkRelationships: [],
    commentSelected: null,
    color: '#ffffff',
    canEditProject: false,
    canDeleteProject: false,
    canClone: false,
    projects: [],
    originals: {
      stepsToReproduce: '',
      expectedResult: '',
    },
    currentlyEditingElement: false,
    newTagCreated: false,
    videos: [],
    recordPanel: null,
    recording: false,
    recordingTime: 0,
    recordingInterval: null,
  }),
  created() {
    window.addEventListener("keydown", this.keyPress)
    this.setUserPreferences("WorkItemDetailsDialog", "showActivity")
  },
  watch: {
    dialog(newVal) {
      if(!newVal) {
        if(this.newTagCreated)
          this.$emit('newTag')
        this.reject()
        this.addingComment = false
        this.newComment = ''
        if(this.taskShow)
          this.resolve()
      }
    },
   },
  destroyed() {
    window.removeEventListener("keydown", this.keyPress)
  },
  computed: {
    acceptancePercentage(){
      const requirements = this.workItem.acceptanceRequirements
      return Math.round(requirements.filter(x => x.done).length / requirements.length * 10000) / 100
    },
    linkedBugs() {
      return this.workItem.linkedWorkItems?.filter(wi => wi.relatedWorkItemType == 1) || []
    },
    linkedWorkItems() {
      return this.workItem.linkedWorkItems?.filter(wi => wi.relatedWorkItemType != 1) || []
    },
    history() {
      let changes = this.workItem.changes || []
      let comments = this.workItem.comments || []
      let efforts = this.workItem.efforts || []

      changes = changes.map(c => {
        c.date = c.dateTime
        c.type = 'change'
        return c
      })
      comments = comments.map(c => {
        c.date = c.createdAt
        c.type = 'comment'
        return c
      })
      efforts = efforts.map(c => {
        c.date = c.createdAt
        c.type = 'effort'
        return c
      })

      let history = []

      if (this.showActivity.includes('comments')) {
        history = history.concat(comments)
      }
      if (this.showActivity.includes('history')) {
        history = history.concat(changes)
      }
      if (this.showActivity.includes('efforts')) {
        history = history.concat(efforts)
      }

      history = _.orderBy(history, [c => moment(c.date).valueOf()], ['desc'])
      return history

    },
    usersWithoutUnassigned() {
        return this.users.filter(user => user.blueTag !== "Unassigned")
    },
    tags() {
      if (!this.projectConfig || !this.projectConfig.tags || !this.workItem) return []
      let tags = _.orderBy(this.projectConfig.tags, ['sortIndex'])
      const tagNotActiveButAssigned = (tag) => tag.active || this.workItem.tags.some(t => t.tagId == tag.id)
      return tags.filter(t => t.types.includes(this.workItem.type) && tagNotActiveButAssigned(t))
    },
    sprints() {
      let sprints = this.projectConfig.sprints || []

      sprints = sprints.filter(s => s.id)

      if(this.workItem.sprints) 
        sprints = sprints.sort((a, b) => this.workItem.sprints.some(x => x.sprintId == a.id) ? -1 : 1 )
      return sprints
    },
    isTask() {
      return this.workItem.type === 3
    },
    isBug() {
      return this.workItem.type === 1
    },
  },
  methods: {
    getPriorityImage(){
      if (this.workItem.priority) {
        return require(`../assets/priority-icons/${this.workItem.priority}.png`);
      }
    },
    getValueImage() {
      if (this.workItem.value) {
        return require(`../assets/priority-icons/${this.workItem.value}.png`);
      }
    },
    getWorkItem() {
      this.$http.get(`/api/workItem/${this.workItem.id}`)
        .then(res => {
          this.workItem = res.data;
        })
    },
    createWorkItem(type) {
      let projectCode = this.workItem.code.substring(0, this.workItem.code.lastIndexOf("-"))
      const defaults = {
        projectId: projectCode,
        type,
        statusId: null,
        createFrom: undefined,
      }
      this.$refs.WorkItemCreateDialog.open(defaults, this.socket)
        .then((id) => {
          window.getApp.success('Bug created successfully')
          this.saveLink('relatesTo', id, true)
          this.socket.invoke("ProjectUpdated", this.workItem.projectId, id)
        })
    },
    createDefectOrigin() {
      this.$refs.createDefectSourceDialog.open('Create Defect Source', 'Defect Source Name', 'The name is required').then(data => {
        if (!data.confirmed)
          return
        this.$q.save({
          api: `/api/Project/${this.$parent.id}/defect/${data.name}`,
          data: null,
          successMsg: 'Defect Origin created successfully',
          afterSuccessUrl: null,
          errorMsg: "Cannot save changes.",
          afterErrorUrl: null,
        }).then(res => {
          this.projectConfig.defectsOrigins.push(res.data)
          const response = res.data
          this.$nextTick(() => this.save(response.id, 'defectOriginId'))
          this.socket.invoke("ProjectUpdated", this.project.id, this.workItem.id)
        }).catch(res => {
          console.log(res)
        })
      })
    },

    openRequirementDialog(requirement){
      this.$refs.requirementDialog
        .open(requirement)
        .then(requirement => {
          const mentions = this.$q.getMentions(requirement.description)
          this.notifyAndSubscribeMentions(mentions, 'Acceptance Requirement')

          this.$http.post(`api/workItem/${this.workItem.id}/acceptanceRequirement`, requirement)
            .then(res => {
              this.workItem.acceptanceRequirements = res.data
            })
            .catch(err => {
              console.error(err)
              this.$root.error('Cannot save acceptance requirement')
            })
        })
    },
    moveRequirement(e){
      this.$http.post(`api/WorkItem/acceptanceRequirement/${e.moved.element.id}/move/${e.moved.newIndex}`, null, {headers: {hideLoading: true}})
        .then(res => {
          this.workItem.acceptanceRequirements = res.data
        })
        .catch(err => {
          console.error(err)
          this.$root.error('Cannot save changes')
        })
    },
    checkRequirement(item){
      this.$http.post(`api/WorkItem/acceptanceRequirement/${item.id}/done/${item.done}`, null, {headers: {hideLoading: true}})
        .catch(err => {
          item.done = !item.done
          console.error(err)
          this.$root.error('Cannot save changes')
        })
    },
    deleteRequirement(id){
      this.$openConfirmDialog({
        title: 'Delete confirmation',
        text: 'Are you sure you want to delete this requirement?',
      })
        .then(res => {
          if(!res) return
          this.$http.delete(`api/WorkItem/acceptanceRequirement/${id}`)
            .then(res => {
              this.workItem.acceptanceRequirements = this.workItem.acceptanceRequirements.filter(x => x.id != id)
            })
            .catch(err => {
              console.error(err)
              this.$root.error('Cannot delete requirement')
            })
        })
    },

    addNewTagToWorkItem(tag) {
      this.toggleTag(tag, true)
    },
    openTagDialog() {
      this.$refs.createTagDialog.open(this.workItem.projectId, null, this.workItem.type)
    },
    roundedTotalHours(hours) {
      return Math.round(hours)
    },
    saveAndCheckIfClosedDialog(value, property, e) {
      this.currentlyEditingElement = false
      this.save(value, property, e)
    },
    unselectComment() {
      this.currentlyEditingElement = false
      this.commentSelected = null
    },
    openRelatedWorkItem(link){
      let defaults = {
        projectId: link.relatedProjectId,
        openCreateDialog: () => {}
      }
      this.$refs.relatedWorkItem.open(link.relatedWorkItemCode, defaults)
        .finally(() => {
          this.$http
            .get(`/api/workItem/${this.workItem.id}`)
            .then(res => {
              this.workItem = res.data;
            })
        })
    },
    keyPress(evt) {
      let ctrlDown = evt.ctrlKey || evt.metaKey
      let vKey = evt.key == 'v' || evt.code == 'KeyV' || evt.which == 86 || evt.keyCode === 86
      let fromComment = evt.srcElement.className.includes('ProseMirror')
      if(ctrlDown && vKey && !fromComment  && this.dialog){
        navigator.permissions.query({ name: "clipboard-read" }).then(res => {
          if(res.state == 'granted' || res.state == 'prompt'){
            navigator.clipboard.read().then(data => {
              this.handlePaste({clipboardData: {items: data}})
            })
          }
        })
      }
    },
    toggleTag(tag, newTag){
      let hasTag = this.workItem.tags.some(t => t.tagId == tag.id)
      if (hasTag) {
        this.workItem.tags = this.workItem.tags.filter(t => t.tagId != tag.id)
      } else {
        // temporary tag
        this.workItem.tags.push({
          tagId: tag.id,
          tagName: tag.name,
          tagColor: tag.color,
          tagSortIndex: tag.sortIndex,
        })
      }

      let addTag = !hasTag
      this.$q.save({
        api: `/api/WorkItem/${this.workItem.id}/tag/${tag.id}/${addTag}`,
        data: null,
        successMsg: null,
        afterSuccessUrl: null,
        errorMsg: "Cannot save changes.",
        afterErrorUrl: null,
      }).then(res => {
        if(newTag) {
          this.projectConfig.tags.unshift(tag)
        }
        this.workItem = res.data
        this.socket.invoke("ProjectUpdated", this.projectConfig.id, this.workItem.id)
      }).catch(res => {
        this.workItem.tags = this.workItem.tags.filter(t => !!t.id)
        this.setDarkenWorkItem(this.workItem)
      })
    },
    toggleSprint(sprintId){
      let hasSprint = this.workItem.sprints.some(t => t.sprintId == sprintId)
      this.$q.save({
        api: `/api/WorkItem/${this.workItem.id}/sprint/${sprintId}/${!hasSprint}`,
        data: null,
        successMsg: null,
        afterSuccessUrl: null,
        errorMsg: "Cannot save changes.",
        afterErrorUrl: null,
      }).then(res => {
        this.workItem = Object.assign({}, res.data)
        this.socket.invoke("ProjectUpdated", this.projectConfig.id, this.workItem.id)
      }).catch(res => {
        console.error(res)
        this.$root.error("Cannot save changes.")
      })
    },
    colorChanged(color){
      if (!this.workItem) {
        console.error("No context work item")
      }
      if (!color) {
        color = null
      } else {
        color = color.hexa || color
      }
      this.save(color, 'boardColor')
    },
    setEditComment(comment){
      if(this.currentUser && this.currentUser.id == comment.createdById)
        this.commentSelected = JSON.parse(JSON.stringify(comment))
    },
    editComment(elementID){
      let formData = new FormData()
      formData.append('comment', JSON.stringify({
        comment: this.commentSelected.comment,
        id: this.commentSelected.id
      }))
      this.$q.save({
        api: `/api/WorkItem/${this.workItem.id}/comment`,
        data: formData,
        successMsg: null,
        afterSuccessUrl: null,
        errorMsg: "Cannot save comment.",
        afterErrorUrl: null,
      }).then(async (res) => {
        this.commentSelected = null
        this.currentlyEditingElement = false
        this.workItem.comments = res.data.comments.map(comment => {
          comment.createdAt = this.removeFromPlus(comment.createdAt)
          comment.files.forEach(file => {
            file.url = this.getBackendUrl(`api/WorkItem/Comment/${comment.id}/image/${file.id}`)
          })
          return comment
        })
        this.workItem.changes = res.data.changes;
        if(elementID === this.$refs.cancel.$attrs.id)
            this.cancel()
      }).catch(res => {
        console.log(res)
      })
    },
    downloadFile(wiFile) {
      this.$q.download(
        this.getBackendUrl(`/api/workItem/${wiFile.workItemId}/file/${wiFile.id}`), 'file')
    },
    client() {
      return this.$router.history.current.path.startsWith('/client');
    },
    handleImageUpload(file = this.$refs.newImage.files[0]){
      let isFile = !file.type.includes('image')
      let formData = new FormData()
      formData.append('file', file)
      this.$http.post(`/api/workItem/${this.workItem.id}/${isFile ? 'file' : 'image'}`,
          formData,
          {
            headers: {
              'Content-Type': 'multipart/form-data'
            }
          }
      ).then(async res => {
        let files = await this.getFileNames(res.data.files);
        this.workItem.files = files;
        this.$forceUpdate()
        window.getApp.success(`${isFile ? 'file' : 'image'} uploaded.`)
      })
      .catch(res => {
        console.error(res)
        window.getApp.error(`Cannot upload ${isFile ? 'file' : 'image'}.`)
      })
      .finally(() => {
        this.$refs.newImage.value = null
      })
    },
    deleteImage(wiFile, isFile = false) {
      this.$q.confirmDelete({
        api: `/api/workItem/${this.workItem.id}/image/${wiFile.id}`,
        messageBody: "Are you sure you want to delete this image?", 
        sucessMsg: "Image deleted",
        errorMsg: "The image was not deleted.",
      }).then(async res => {
        let files = await this.getFileNames(res.data.files);
        this.$set(this.workItem, 'files', files)
        if(!isFile) {
          this.openImage()
        }
      });
    },
    saveLink(relationship, relatedWorkItemId, linked) {

      let currentLink = this.workItem.linkedWorkItems && this.workItem.linkedWorkItems
        .find(lwi => lwi.relationship === relationship && lwi.relatedWorkItemId === relatedWorkItemId)

      if (linked == !!currentLink) return

      let toAdd = null
      let toRemove = null
      if (linked) {
        // add
        toAdd = {
          relationship: relationship,
          relatedWorkItemId: relatedWorkItemId,
        }
        this.workItem.linkedWorkItems.push(toAdd)
      } else {
        toRemove = currentLink
        this.workItem.linkedWorkItems = this.workItem.linkedWorkItems.filter(r => r != toRemove)
      }

      this.$q.save({
        api: `/api/WorkItem/${this.workItem.id}/link/${relatedWorkItemId}/${relationship}/${linked}`,
        data: null,
        successMsg: null,
        afterSuccessUrl: null,
        errorMsg: "Cannot save changes.",
        afterErrorUrl: null,
      }).then(async (res) => {
        
        this.workItem = res.data
        this.$set(this.workItem, 'watching', (await this.isUserSubscriber()))
      
      }).catch(res => {
        if (toAdd) {
          this.workItem.linkedWorkItems = this.workItem.linkedWorkItems.filter(r => r != toAdd)
        } else if (toRemove) {
          this.workItem.linkedWorkItems.push(toRemove)
        }
        console.error(res)
      })
    },
    groupedReactions(comment) {
      if (!comment.reactions) return []

      let grouped = _.groupBy(comment.reactions, r => r.emoji)
      let mapped = _.map(grouped, (arr, key) => ({
        emoji: key,
        count: arr.length,
        selected: arr.some(r => r.createdById == this.currentUser.id),
        users: arr.map(r => r.createdByBlueTag).join(', '),
      }))
      let like = mapped.find(r => r.emoji == 'like') || { emoji: 'like', count: 0, selected: false, users: 'None yet' }
      let love = mapped.find(r => r.emoji == 'love') || { emoji: 'love', count: 0, selected: false, users: 'None yet' }

      return [like, love, ...mapped.filter(r => r.emoji != 'like' && r.emoji != 'love')]
    },
    async isUserSubscriber() {
      var currentUser = await this.$blueSystem.getCurrentUser()
      if (currentUser && this.workItem && this.workItem.subscribedUsers) {
        return this.workItem.subscribedUsers.some(u => u.subscriberId == currentUser.id)
      }
      return false
    },
    open(id, defaults, socket, newTag) {
      this.newTagCreated = newTag
      this.socket = socket;
      this.workItem = {}
      this.defaults = defaults;
      this.commentSelected = null;
      this.newCommentImages = []

      this.$http
        .get(`/api/workItem/${id}`)
        .then(async (res) => {
          this.workItem = res.data
          if(!this.workItem.assignedToId) this.workItem.assignedToId = null
          if(this.isTask && this.workItem.dueDate) this.workItem.dueDate = moment(this.workItem.dueDate).format('YYYY-MM-DD')
          this.originals.stepsToReproduce = this.workItem.stepsToReproduce
          this.originals.expectedResult = this.workItem.expectedResult

          this.$http
            .get(`/api/project/${this.workItem.projectId}/config?active=true`)
            .then(res => {
              this.projectConfig = res.data
              this.statuses = this.projectConfig.workItemStatuses.filter(s => s.workItemType === this.workItem.type)
              this.canClone = true
              this.$q.addFirstItem(this.projectConfig.sprints, 'Backlog')
            })
            .catch(res => {
              window.getApp.error("Cannot get project sprints, tags and status.")
              console.error(res)
            })
          this.$http
            .get(`api/Project/${this.workItem.projectId}/currentPermissions`)
            .then(res => {
              this.canEditProject = res.data.canEdit == 3
              this.canDeleteProject = res.data.canDelete == 3
            }).catch( res => {
              window.getApp.error("Cannot get permissions.");
              console.log(res);
            })
          if(!this.isPersonal){
            this.users = []
            const membersPr = this.$http
              .get(`/api/project/${this.workItem.projectId}/members`)
              .then(res => {
                let members = res.data
                this.users = this.users.concat(
                  members.map(member => {
                    if(!member.user.blueTag)
                      member.user.blueTag = member.user.fullName
                    return member.user
                  })
                )
              })
              .catch(res => {
                console.log(res)
                window.getApp.error("Cannot obtain project members.")
              })
            const billableMembersPr = this.$http.get(`api/nucleiManagement/${this.workItem.projectId}/members`)
              .then((res) => {
                this.users = this.users.concat(
                  res.data.map(x => ({
                    id: x.assignment.userId,
                    blueTag: x.assignment.userBlueTag,
                    fullName: x.assignment.userFullName,
                  }))
                )
              })
              .catch(response => {
                window.getApp.error("Cannot get nuclei members.");
              })
            Promise.all([membersPr, billableMembersPr]).then(() => this.$q.addLastItem(this.users, 'Unassigned', 'blueTag'))
          } else {
            this.$blueSystem.getCurrentUser() 
              .then(user => {
                this.users = [user]
                this.workItem.assignedToId = user.id
              })
          }

          this.workItem.comments.forEach(comment => {
            comment.files.forEach(file => {
              file.url = this.getBackendUrl(`api/WorkItem/Comment/${comment.id}/image/${file.id}`)
            })
          })
          document.title = this.workItem.code + ' - ' + this.workItem.name

          if(!this.isNullDate(this.workItem.finishedAt))
            this.workItem.age = null;
          this.workItem.files = await this.getFileNames(this.workItem.files);
          this.$set(this.workItem, 'watching', (await this.isUserSubscriber()))
          this.color = this.workItem.boardColor || '#ffffff';
        })
        .catch(res => {
          window.getApp.error("Cannot get work item.")
          console.error(res)
        })

      this.$http.get('api/Project?active=true')
        .then(res => {
          this.projects = res.data
        })
        .catch(res => {
          window.getApp.error("Cannot obtain projects.")
          console.error(res)
        })

      this.$http.get(`/api/Enums/WorkItemPriority`)
        .then(res => {
          this.priorities = res.data
        })
        .catch(res => {
          window.getApp.error("Cannot obtain priorities.")
          console.error(res)
        })

      this.$http
        .get(`/api/workItem/options/linking`)
        .then(async (res) => {
          this.linkRelationships = res.data
        })
        .catch(res => {
          window.getApp.error("Cannot get linking options.")
          console.error(res)
        })

      // Get current user
      this.$blueSystem.getCurrentUser()
        .then(user => {
          this.currentUser = user
        })

      this.$http
        .get(`api/WorkItem/${id}/videos`)
        .then(res => {
          this.videos = res.data
        })

      // Open dialog and return promise
      this.dialog = true
      return new Promise((resolve, reject) => {
        this.resolve = resolve
        this.reject = reject
      })
    },
    toggleEmoticon(emoji, comment) {
      let currentValue = comment.reactions && comment.reactions.some(r => r.emoji === emoji && r.createdById === this.currentUser.id)
      this.addEmoticon(emoji, comment, !currentValue)
    },
    addEmoticon(emoji, comment, value) {
      if (!comment.reactions) {
        this.$set(comment, 'reactions', [])
      }
      let currentValue = comment.reactions && comment.reactions.some(r => r.emoji === emoji && r.createdById === this.currentUser.id)

      if (value == currentValue) return

      let toAdd = null
      let toRemove = null
      if (value) {
        // add
        toAdd = {
          emoji: emoji,
          createdById: this.currentUser.id,
          createdByBlueTag: this.currentUser.blueTag,
        }
        comment.reactions.push(toAdd)
      } else {
        toRemove = comment.reactions.find(r => r.emoji === emoji && r.createdById === this.currentUser.id)
        comment.reactions = comment.reactions.filter(r => r != toRemove)
      }
      
      this.$q.save({
        api: `/api/WorkItem/${this.workItem.id}/comment/${comment.id}/reaction/${emoji}/${value}`,
        data: null,
        successMsg: null,
        afterSuccessUrl: null,
        errorMsg: "Cannot save changes.",
        afterErrorUrl: null,
      }).catch(res => {
        if (toAdd) {
          comment.reactions = comment.reactions.filter(r => r != toAdd)
        } else if (toRemove) {
          comment.reactions.push(toRemove)
        }
        console.error(res)
      })
    },
    cancel() {
      this.reject()
      this.dialog = false
    },
    toggleSubscribe() {
      
      this.$q.save({
        api: `/api/WorkItem/${this.workItem.id}/toggleSubscribe`,
        data: null,
        successMsg: null,
        afterSuccessUrl: null,
        errorMsg: "Cannot save changes.",
        afterErrorUrl: null,
      }).then(async (res) => {
        this.workItem.subscribedUsers = res.data.subscribedUsers
        this.$set(this.workItem, 'watching', (await this.isUserSubscriber()))
      }).catch(res => {
        console.error(res)
      })
    },
    saveDescription(value, id){
      const curMentions = this.$q.getMentions(this.workItem['description'])
      let newMentions = this.$q.getMentions(value)

      newMentions = this.$q.distinct(newMentions, 'id')
      newMentions = newMentions.filter(m => !curMentions.some(cm => cm === m))

      this.notifyAndSubscribeMentions(newMentions, 'description')
      this.save(value, 'description', id, false)
    },
    notifyAndSubscribeMentions(mentions, origin){
      return this.$http.post(`api/WorkItem/${this.workItem.id}/mentions/${origin}`, mentions)
        .catch(err => {
          this.$root.error("Cannot save notify mentions.")
          console.error(err)
        })
    },
    save(value, property, elementID, forceSave) {
      let original = this.workItem[property]
      this.$set(this.workItem, property, value)
      if (original === value && !forceSave) {
        return
      }
      value = value + ''
      this.$q.save({
        api: `/api/WorkItem/${this.workItem.id}/byProperty/${property}`,
        data: {
          value
        },
        successMsg: null,
        afterSuccessUrl: null,
        errorMsg: "Cannot save changes.",
        afterErrorUrl: null,
      }).then(async (res) => {
        this.workItem.changes = res.data.changes
        this.workItem.subscribedUsers = res.data.subscribedUsers
        this.$set(this.workItem, 'watching', (await this.isUserSubscriber()))
        this.$set(this.workItem, 'totalHours', res.data.totalHours)
        this.$set(this.workItem, 'sprintCriticalPath', res.data.sprintCriticalPath)
        this.$set(this.workItem, 'sprintName', res.data.sprintName)
        this.$set(this.workItem, 'defectOriginName', res.data.defectOriginName)
        
        if(this.defaults.projectId && this.socket){
          this.socket.invoke("ProjectUpdated", this.defaults.projectId, this.workItem.id)
        }
        this.currentlyEditingElement = false
        if(elementID === this.$refs.cancel.$attrs.id && elementID) 
          this.cancel()
      })
      .catch(res => {
        console.log(res)
        this.workItem[property] = original
      })
    },
    cancelAddComment() {
      this.currentlyEditingElement = false
      this.addingComment = false
      this.newComment = ''
    },
    saveComment(elementID) {
      if(this.newComment !== '' || !!this.newCommentImages.length) {
        const mentions = this.$q.getMentions(this.newComment)
        this.notifyAndSubscribeMentions(mentions, 'comment')

        let formData = new FormData()
        formData.append('comment', JSON.stringify({comment: this.newComment}))
        for(let img of this.newCommentImages){
          formData.append('files', img)
        }
        this.$http.post(
          `/api/WorkItem/${this.workItem.id}/comment`,
          formData,
        ).then(async (res) => {
          this.addingComment = false
          this.currentlyEditingElement = false
          this.newComment = ''
          this.workItem.comments = res.data.comments.map(comment => {
            comment.createdAt = this.removeFromPlus(comment.createdAt)
            comment.files.forEach(file => {
              file.url = this.getBackendUrl(`api/WorkItem/Comment/${comment.id}/image/${file.id}`)
            })
            return comment
          })
          this.workItem.subscribedUsers = res.data.subscribedUsers
  
          this.newCommentImages = []
          this.$set(this.workItem, 'watching', (await this.isUserSubscriber()))
          if(elementID === this.$refs.cancel.$attrs.id)
            this.cancel()
        }).catch(res => {
          console.log(res)
          window.getApp.error('Cannot save comment')
        })
      } else {
        this.addingComment = false
        return
      }
    },
    removeFromPlus(str){
      return str.replace(/\+[0-9][0-9]\:[0-9][0-9]/, '').replace(/\-[0-9][0-9]\:[0-9][0-9]/, '');
    },
    saveStatus(status) {
      let property = "statusId"
      let value = status.id

      if(status.confetti && this.workItem.statusId != status.id){
        this.$confetti.start()
        setTimeout(() => { this.$confetti.stop() }, 2000)
      }

      this.$q.save({
        api: `/api/WorkItem/${this.workItem.id}/byProperty/${property}`,
        data: { value },
        successMsg: null,
        afterSuccessUrl: null,
        errorMsg: "Cannot save changes.",
        afterErrorUrl: null,
      }).then(async (res) => {
        this.workItem = res.data;
        if(!this.isNullDate(this.workItem.finishedAt))
          this.workItem.age = null;
        this.$set(this.workItem, 'watching', (await this.isUserSubscriber()))
        
        if(this.defaults.projectId)
          this.socket.invoke("ProjectUpdated", this.defaults.projectId, this.workItem.id)
      }).catch(res => {
        this.workItem[property] = original
      })
    },
    equalsFileType(filename, filetype){
      filetype = filetype.map(type => type.toLowerCase())
      if(!filename)
        return false;
      filename = filename.split('.');
      return filetype.indexOf(filename[filename.length - 1]) != -1
    },
    getFileNames: async function(files) {
      let promises = [];
      for(let file of files)
        promises.push(this.$http.get(`/api/workItem/${file.workItemId}/fileName/${file.id}`));

      let ress = await Promise.all(promises);
      for(let [i, file] of ress.entries())
        files[i].name = file.data;
      return files;
    },
    handlePaste(pasteEvent){
      var item = pasteEvent.clipboardData.items[0]
      if (item.types.some(type => !type.indexOf("image"))){
        item.getType("image/png").then((blob) => {
          blob.name = 'image.jpg'
          const file = new File([blob], "image.jpg", { type: "image/jpeg" })
          this.handleImageUpload(file, true)
        });
      }
    },
    deleteWorkItem(){
      this.$q.confirmDelete({
        api: `/api/WorkItem/${this.workItem.id}`,
        messageBody: "Are you sure you want to delete this work item?", 
        sucessMsg: "Work Item deleted",
        errorMsg: "The work item was not deleted.",
      }).then(res => {
        this.socket.invoke("WorkItemDeleted", this.defaults.projectId, this.workItem.id)
        this.reject()
        this.dialog = false
      });
    },
    deleteComment(comment){
      this.$q.confirmDelete({
        api: `/api/WorkItem/comment/${comment.id}`,
        messageBody: "Are you sure you want to this comment?", 
        sucessMsg: "Comment deleted",
        errorMsg: "The comment was not deleted.",
      }).then(res => {
        this.workItem.comments = res.data.comments.map(comment => {
          comment.files.forEach(file => {
            file.url = this.getBackendUrl(`api/WorkItem/Comment/${comment.id}/image/${file.id}`)
          })
          return comment
        })
      });
    },
    cloneWorkItem(){
      let statusId = this.statuses.filter(status => status.isDefault)[0].id
      this.defaults.openCreateDialog(statusId || this.workItem.statusId, this.workItem.id)
      this.dialog = false;
    },
    openImage(selected){
      let allImages = this.workItem.files.filter(file => file.isImage)
      for(let img of allImages){
        img.url = this.getBackendUrl(`/api/workItem/${this.workItem.id}/image/${img.id}`)
      }
      if(!selected){
        if(!allImages.length){
          this.$refs.imageCarousel.cancel()
          return
        } else {
          selected = allImages[0]
        }
      }
      selected.url = this.getBackendUrl(`/api/workItem/${this.workItem.id}/image/${selected.id}`)
      this.$refs.imageCarousel.open(allImages, selected, this.deleteImage)
    },
    openNewCommentImages(selected){
      if(!selected){
        if(!this.newCommentImages.length){
          this.$refs.imageCarousel.cancel()
          return
        } else {
          selected = this.newCommentImages[0]
        }
      }
      let removeImg = img => {
        this.newCommentImages = this.newCommentImages.filter(i => i.url != img.url)
        this.openNewCommentImages()
      }
      this.$refs.imageCarousel.open(this.newCommentImages, selected, removeImg, true)
    },
    openCommentImages(images, selected, canDelete){
      if(!selected){
        if(!images.length){
          this.$refs.imageCarousel.cancel()
          return
        } else {
          selected = images[0]
        }
      }
      this.$refs.imageCarousel.open(images, selected, canDelete ? this.deleteCommentImage : null)
    },
    deleteCommentImage(file){
      this.$q.confirmDelete({
        api: `api/WorkItem/Comment/${file.workItemCommentId}/image/${file.id}`,
        messageBody: "Are you sure you want to delete this image?", 
        sucessMsg: "Image deleted",
        errorMsg: "The image was not deleted.",
      }).then(async res => {
        this.workItem.comments = res.data.comments.map(comment => {
          comment.files.forEach(file => {
            file.url = this.getBackendUrl(`api/WorkItem/Comment/${comment.id}/image/${file.id}`)
          })
          return comment
        })
        this.$refs.imageCarousel.cancel()
      });
    },
    handlePasteNewComment(pasteEvent){
      var item = pasteEvent.clipboardData.items[0]
      if (!item.type.indexOf("image")){
        let blob = item.getAsFile();
        var file = new File([blob], "image.jpg")
        this.addNewCommentImage(file)
      }
    },
    addNewCommentImage(file = this.$refs.newCommentImage.files[0]){
      file.url = URL.createObjectURL(file)
      this.newCommentImages.push(file)
    },
    handlePasteComment(pasteEvent){
      var item = pasteEvent.clipboardData.items[0]
      if (!item.type.indexOf("image")){
        let blob = item.getAsFile();
        var file = new File([blob], "image.jpg")
        this.addCommentImage(file)
      }
    },
    addCommentImage(file = this.$refs.commentImage.files[0]){
      let formData = new FormData()
      formData.append('file', file)
      this.$http.post(`/api/WorkItem/Comment/${this.commentSelected.id}/image`,
          formData,
          {
            headers: {
              'Content-Type': 'multipart/form-data'
            }
          }
      ).then(async res => {
        this.commentSelected = null
        this.workItem.comments = res.data.comments.map(comment => {
          comment.files.forEach(file => {
            file.url = this.getBackendUrl(`api/WorkItem/Comment/${comment.id}/image/${file.id}`)
          })
          return comment
        })
        window.getApp.success("Image uploaded.")
      })
      .catch(res => {
        console.error(res)
        window.getApp.error("Cannot upload image.")
      })
    },

    closePanel(){
      if(this.recordPanel)
        this.recordPanel.close()
      this.recordPanel = null

      if(this.recorder.state == 'recording')
        this.recorder.stop()

      this.recording = false
    },

    formatTime(time){
      time = Math.floor(time)
			return time < 10 ? `0${time}` : time
    },


    async recordScreen(){
      const stream = await navigator.mediaDevices.getDisplayMedia({ video: true })
			const audio = await navigator.mediaDevices.getUserMedia({
				audio: {
					echoCancellation: true,
					noiseSuppression: true,
					sampleRate: 44100,
				}
			})

      stream.addEventListener('inactive', () => {
        this.closePanel()
      })
      audio.addEventListener('inactive', () => {
        this.closePanel()
      })

      let chunks = []
			const mixedStream = new MediaStream([...stream.getTracks(), ...audio.getTracks()])
			this.recorder = new MediaRecorder(mixedStream, { mimeType: 'video/webm; codecs=vp9' })
			this.recorder.ondataavailable = e => chunks.push(e.data)
      
      this.recorder.onstart = () => {
        this.recording = true
        this.recordingTime = 0
        clearInterval(this.recordingInterval)
        this.recordingInterval = setInterval(() => {
          if(!this.recording) return
          this.recordingTime++
        }, 1000)

        this.recordPanel = window.open(`${window.location.origin}/record-panel.html`, "", "popup=true, width=320, height=100, top=0, left=0")
        this.recordPanel.addEventListener('beforeunload', () => {
          this.closePanel()
        })
        this.recordPanel.addEventListener('stop', () => {
          this.closePanel()
        })
        this.recordPanel.addEventListener('pause', () => {
          this.recorder.pause()
        })
        this.recordPanel.addEventListener('resume', () => {
          this.recorder.resume()
        })
      }

      const workItemId = this.workItem.id
      const workItemCode = this.workItem.code
			this.recorder.onstop = async e => {
        this.closePanel()
        const blob = new Blob(chunks, { 'type' : 'video/webm' })
        const fixDuration = require('webm-duration-fix').default
        const fixedBlob = await fixDuration(blob)
        chunks = []

				stream.getTracks().forEach(t => t.stop())
				audio.getTracks().forEach(t => t.stop())

        const file = new File([fixedBlob], moment().format('YYYY-MM-DD hh:mm') + '.webm')
        let formData = new FormData()
        formData.append('file', file)
        this.$http.post(`/api/workItem/${workItemId}/video`, formData, {headers: {'Content-Type': 'multipart/form-data'}})
          .then(() => {
            if(this.workItem?.id != workItemId)
              return
            this.$http
              .get(`api/WorkItem/${workItemCode}/videos`)
              .then(res => {
                this.videos = res.data
              })
          })
			}
			this.recorder.start(1000)
    },
    
    downloadVideo(url){
      this.$q.openNewBackendTab(url)
    },

    async removeVideo(key){

      const res = await this.$openConfirmDialog({
        title: 'Delete video',
        description: 'Are you sure you want to delete this video?',
      })

      if(!res) return

      this.$http.post(`api/workItem/${this.workItem.code}/video/delete`, { value: key })
        .then((res) => {
          this.videos = res.data
        })
        .catch(res => {
          console.error(res)
          this.$root.error("Cannot delete video.")
        })
    },
  },
}
</script>

<style scoped lang="scss">
.header {
  flex-wrap: wrap;

  .header-btn {
    text-transform: unset !important;
  
    span {
      font-weight: 500;
    }
  }
}

.hide-scrollbar::-webkit-scrollbar{
  display: none;
}

::v-deep .v-expansion-panel-header{
  min-height: 48px !important;
  &::before{
    opacity: 0 !important;
  }
}
::v-deep .v-expansion-panel::before{
  box-shadow: none !important;
}
::v-deep .v-expansion-panel::after{
  content: none !important;
}
.v-expansion-panels > div {
  width: 100%;
}
.button-toggle{
  width: 73px !important;
  height: 24px !important;
  text-transform: none;
  border-radius: 5px !important;
  font-size: 10px;
  margin-left: 8px;
  color: #fff;
}

.add-tag{
  width: 105px;
  height: 24px;
  font-size: 10px;
  font-weight: 700px;
  margin-right: 5px;
  margin-bottom: 5px;
  cursor: pointer;
  border-radius: 5px;
  background-color: var(--v-kanban-base);
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.progress-container{
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.remove-border-bottom{
  border-bottom: none !important;
}

#record{
  display: inline-block;
  width: 25px;
  height: 25px;
  border-radius: 50%;
  background-color: red;
  animation: pulse 1s infinite;
}
@keyframes pulse {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.2);
  }
  100% {
    transform: scale(1);
  }
}

.extra-options{
  .v-list-item{
    padding: 0 8px;
    min-height: 35px;
  }
  .v-icon{
    font-size: 20px;
    color: var(--v-text-lighten2);
  }
}
.tags-container{
  max-height: 400px;
  overflow-y: scroll;
}
.emoji-btn {
  min-width: 40px !important;
  
  .emoji {
    font-size: 13px;
  }
  .v-icon {
    margin-top: 1px;
    font-size: 16px;
  }
  .emoji-count {
    margin-top: 2px;
    margin-left: 3px;
    font-size: 12px;
  }
}

.date-time {
  margin-top: 7px;
  font-size: 11px;
  color: #777;
}

.color-tags{
  min-height: 28px;
  height: 28px;
  background: var(--v-kanban-base);
  color: var(--v-text-base);
  border-radius: 5%;
}
.loading-icon{
  text-transform: 'none';
  padding: '0';
  animation: loading-1 1.5s linear infinite;
}
@keyframes loading-1 {
  0%{
      transform: rotate(-.125turn)
  }
  50%{
      transform: rotate(.875turn)
  }
  85%{
      transform: rotate(.925turn)
  }
  100%{
      transform: rotate(.875turn)
  }
}
.add-file-button {
  width: 24px;
  height: 24px;
  border-radius: 5px !important;
  background-color: var(--v-kanban-base);
  border: none !important;
  display: flex;
  justify-content: center;
  box-sizing: border-box;
}

.video {
  .actions {
    width: 0;
    overflow: hidden;
    transition: all 1s ease-in-out;
  }  &:hover .actions {
    width: 100%;
  }
}
</style>