<template>
  <span class="q-emoji-picker">

    <v-menu v-model="menu" :close-on-content-click="false" bottom offset-y :max-height="400" :allow-overflow="true" :max-width="330">
      <template v-slot:activator="{ on }">

        <v-btn x-small text v-on="on" :color="'#777777'"
          class="emoji-invoker px-1">
          <v-icon small>add</v-icon>
          <svg fill="#777777" height="18" width="18" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
            <path d="M0 0h24v24H0z" fill="none"/>
            <path d="M11.99 2C6.47 2 2 6.48 2 12s4.47 10 9.99 10C17.52 22 22 17.52 22 12S17.52 2 11.99 2zM12 20c-4.42 0-8-3.58-8-8s3.58-8 8-8 8 3.58 8 8-3.58 8-8 8zm3.5-9c.83 0 1.5-.67 1.5-1.5S16.33 8 15.5 8 14 8.67 14 9.5s.67 1.5 1.5 1.5zm-7 0c.83 0 1.5-.67 1.5-1.5S9.33 8 8.5 8 7 8.67 7 9.5 7.67 11 8.5 11zm3.5 6.5c2.33 0 4.31-1.46 5.11-3.5H6.89c.8 2.04 2.78 3.5 5.11 3.5z"/>
          </svg>
        </v-btn>
      </template>
      <v-card>
        <v-card-text class="pa-2">
          <v-text-field ref="search" rounded hide-details dense flat placeholder="search" v-model="search" outlined></v-text-field>
          <div v-for="(emojiGroup, category) in emojis" :key="category">
            <h4 class="mt-2 mb-1">{{ category }}</h4>
            <div class="emojis">
              <span class="emoji" v-for="(emoji, emojiName) in emojiGroup" :key="emojiName"
                @click="choose(emoji)" :title="emojiName">{{ emoji }}</span>
            </div>
          </div>
        </v-card-text>
      </v-card>
    </v-menu>
  </span>
</template>

<script>
import moment from 'moment'
import _ from "lodash"
import emojis from 'vue-emoji-picker/src/emojis'

export default {
  props: {
    emojiTable: {
      type: Object,
      required: false,
      default() {
        return emojis
      },
    },
  },
  components: {
    // EmojiPicker,
  }, 
  data: () => ({
    search: '',
    menu: false,
  }),
  watch: {
    menu() {
      if (this.menu) {
        this.search = ''
        setTimeout(() => this.$refs.search.focus(), 500)
      }
    },
  },
  computed: {
    
    emojis() {
      if (this.search) {
        const obj = {}
        for (const category in this.emojiTable) {
          obj[category] = {}
          for (const emoji in this.emojiTable[category]) {
            if (new RegExp(`.*${this.search}.*`).test(emoji)) {
              obj[category][emoji] = this.emojiTable[category][emoji]
            }
          }
          if (Object.keys(obj[category]).length === 0) {
            delete obj[category]
          }
        }
        return obj
      }
      return this.emojiTable
    },
  },
  methods: {
    append(emoji) {
      this.input += emoji
    },
    choose(emoji) {
      this.menu = false
      this.$emit('select', emoji)
    },
  },
  created() {
  },
}
</script>

<style scoped lang="scss">
  .emoji {
    display: inline-block;
    width: 36px;
    height: 36px;
    cursor: pointer;
    font-size: 24px;
    padding: 8px 1px 1px 1px;
    text-align: center;
    border-radius: 3px;
    vertical-align: middle;
    color: #000000;
  }
  .emoji:hover {
    background: #e7e7ff;
  }
</style>
