<template>
  <v-container class="new-back full-height" grid-list-xl fluid>
    <v-layout row wrap>
      <v-flex sm12 class="pb-0">
        <div v-if="view" class="d-flex justify-space-between">
          <h1>
            Projects Report
          </h1>
          <v-btn-toggle tile borderless class="slider view-toggle colored-back rounded toggle-view" v-model="view" dense mandatory>
            <v-tooltip top :disabled="!view">
              <template #activator="{ on }">
                <v-btn
                  :color="!view ? 'var(--v-background-lighten)' : 'transparent'"
                  class="mx-1 text-center inline-block" small
                  :style="{ height: '100%'}" v-on="on"
                >
                  <v-icon
                    :style="{
                      filter: !view ? '' : $vuetify.theme.isDark ? 'brightness(0) invert(1)' : 'brightness(.5)'
                    }"
                    color="primary"
                  >mdi-playlist-check</v-icon>
                  <span class="navigation-btn ml-1">{{!view ? 'Customers' : ''}}</span>
                </v-btn>
              </template>
              Customers
            </v-tooltip>
            <v-tooltip top :disabled="!!view">
              <template #activator="{ on }">
                <v-btn
                  :color="view ? 'var(--v-background-lighten) !important' : 'transparent'"
                  class="mx-1 text-center inline-block" small
                  :style="{ height: '100%'}" v-on="on"
                >
                  <v-icon
                    :style="{
                      filter: view ? '' : $vuetify.theme.isDark ? 'brightness(0) invert(1)' : 'brightness(.5)'
                    }"
                    color="primary"
                  >mdi-chart-arc</v-icon>
                  <span class="navigation-btn ml-1">{{view ? 'Projects' : ''}}</span>
                </v-btn>
              </template>
              Projects
            </v-tooltip>
          </v-btn-toggle>
        </div>
        <filter-search-input v-else v-model="filters.search" :applied-filters="appliedFilters" outlined dense>
          <template #append>
            <div>
              <v-btn-toggle tile borderless class="slider view-toggle colored-back rounded toggle-view" v-model="view" dense mandatory>
                <v-tooltip top :disabled="!view">
                  <template #activator="{ on }">
                    <v-btn
                      :color="!view ? 'var(--v-background-lighten)' : 'transparent'"
                      class="mx-1 text-center inline-block" small
                      :style="{ height: '100%'}" v-on="on"
                    >
                      <v-icon
                        :style="{
                          filter: !view ? '' : $vuetify.theme.isDark ? 'brightness(0) invert(1)' : 'brightness(.5)'
                        }"
                        color="primary"
                      >mdi-playlist-check</v-icon>
                      <span class="navigation-btn ml-1">{{!view ? 'Customers' : ''}}</span>
                    </v-btn>
                  </template>
                  Customers
                </v-tooltip>
                <v-tooltip top :disabled="!!view">
                  <template #activator="{ on }">
                    <v-btn
                      :color="view ? 'var(--v-background-lighten) !important' : 'transparent'"
                      class="mx-1 text-center inline-block" small
                      :style="{ height: '100%'}" v-on="on"
                    >
                      <v-icon
                        :style="{
                          filter: view ? '' : $vuetify.theme.isDark ? 'brightness(0) invert(1)' : 'brightness(.5)'
                        }"
                        color="primary"
                      >mdi-chart-arc</v-icon>
                      <span class="navigation-btn ml-1">{{view ? 'Projects' : ''}}</span>
                    </v-btn>
                  </template>
                  Projects
                </v-tooltip>
              </v-btn-toggle>
              <v-btn fab small color="primary" class="ml-3" to="/admin/customer/new">
                <v-icon dark>add</v-icon>
              </v-btn>
            </div>
          </template>
          <q-filter-input
            class="inline-block" label="Customer"
            :items="customers" item-text="name" item-value="id"
            v-model="filters.customer"
            dense outlined hide-details clearable multiple
            :parent-shown-items="shownCustomers"
            :data-comparer="(item, value) => item.id == value"
          />
          <q-filter-input
            class="inline-block ml-3" label="Project"
            :items="customerProjects" item-text="name" item-value="id"
            v-model="filters.customerProject"
            dense outlined hide-details clearable multiple
            :parent-shown-items="shownCustomers"
            :data-comparer="(item, value) => item.customerProjects.some(p => p.id == value)"
          />
        </filter-search-input>
      </v-flex>
      <v-flex v-show="!view" sm12>
        <v-data-table
          :headers="headers"
          :items="shownCustomers"
          class="rounded-lg bordered new-back lighten2"
          :item-class="() => permissions.detail ? 'cursor-pointer' : ''"
          @click:row="item => permissions.detail ? $router.push(`/admin/customer/${item.id}`) : null"
        >
          <template v-slot:[`item.reportable`]="props">
            <v-icon v-if="props.item.reportable">check</v-icon>
            <v-icon v-if="!props.item.reportable">close</v-icon>
          </template>
          <template v-slot:[`item.customerContacts`]="{ item }">
            <div
              v-for="(contact, ix) in item.customerContacts" :key="ix"
            >
              {{contact.name}}{{ix == item.customerContacts.length-1 ? '' : ', '}}
            </div>
          </template>
          <template v-slot:[`item.customerProjects`]="{ item }">
            <div
              v-for="(project, ix) in item.customerProjects" :key="ix" class="project"
              :style="{background: customerProjectTypes.find(t => t.value == project.type).lightColor}"
            >
              <div
                class="letter"
                :style="{background: customerProjectTypes.find(t => t.value == project.type).color}"
              >
                {{customerProjectTypes.find(t => t.value == project.type).small}}
              </div>
              <div class="name">{{project.name}}</div>
            </div>
          </template>
          <template v-slot:[`item.active`]="{ item }">
            <v-btn
              :color="item.active ? 'green' : 'error'"
              :style="{background: item.active ? '#e1f8dd' : '#f5d0d0'}"
              outlined small style="border-radius: 20px"
            >{{item.active ? 'Active' : 'Inactive'}}</v-btn>
          </template>
          <template v-slot:[`item.accountManagerBlueTag`]="{ item }">
            <span>{{ item.accountManagerBlueTag }}</span>
          </template>
        </v-data-table>

      </v-flex>

      <v-flex v-show="view" sm12>
        <project-report/>
      </v-flex>
    </v-layout>
  </v-container>  
  
</template>

<script>
import _ from "lodash"
import BillableJSON from '../../json/billable.json'
import ProjectReport from '../adminSurf/ProjectReport'

export default {
  title: 'Customers',
  props: [],
  components: { ProjectReport },
  data: () => ({
    customers: [],
    headers: [
      { text: "Customer", value: "name" },
      { text: "Account Manager", value: "accountManagerBlueTag" },
      { text: "Main Contacts", value: "customerContacts" },
      { text: "Projects", value: "customerProjects" },
    ],
    vo: {},
    customerProjectTypes: [],
    customerProjects: [],
    view: 0,

    filters: {
      search: '',
      customer: [],
      customerProject: [],
    },

    permissions: {
      detail: false,
      add: false,
      actions: false,
    },
  }),
  created() {
		this.customerProjectTypes = BillableJSON.projectTypes

		this.$security.hasRequiredPermission('detail')
			.then(res => this.permissions.detail = res)
		this.$security.hasRequiredPermission('add')
			.then(res => this.permissions.add = res)
		this.$security.hasRequiredPermission('full_access')
			.then(res => this.permissions.actions = res)

    this.$q.log(4, 25, 3, 'NA')
    this.setUserPreferences('Customers', 'filters')
    this.init();
  },
  computed: {
    shownCustomers(){
      let customers = this.customers
      if(this.filters.customer.length){
        customers = customers.filter(c => this.filters.customer.includes(c.id))
      }
      if(this.filters.customerProject.length){
        customers = customers.filter(c => c.customerProjects.some(p => this.filters.customerProject.includes(p.id)))
      }
      if(this.filters.search){
        let searchResult = this.applySearch(this.filters.search, customers)
        customers = customers
          .filter(c =>
            searchResult.some(r => r.id == c.id) ||
            c.customerContacts.some(contact => this.normalizeIncludes(contact.name, this.filters.search)) ||
            c.customerProjects.some(p => this.normalizeIncludes(p.name, this.filters.search))
          )
      }

      return customers
    },
    appliedFilters(){
			return this.countActiveFilters(this.filters, ['search'])
		},
  },
  methods: {
    deleteCustomer(customerId) {
      this.$q.confirmDelete({
        api: `/api/customer/${customerId}`,
        messageBody: "Are you sure you want to delete this customer?", 
        sucessMsg: "Customer deleted",
        errorMsg: "The customer was not deleted.",
      }).then(res => {
        if(res.data && typeof res.data == "string"){
          window.getApp.error(res.data)
          return
        }
        this.init()
      });
    },
    init() {
      this.$http.get("/api/customer?getInactive=true")
        .then(res => {
          let customers = res.data;
          customers = _.orderBy(customers, ['name']);
          this.customers = customers;
        })
        .catch(res => {
          window.getApp.error("Cannot obtain customers.");
        })
      
			this.$http.get('api/CustomerProject')
				.then(res => {
					this.customerProjects = res.data
				})
    }
  }

    
}
</script>

<style scoped lang="scss">
  .slider{
    display: inline-block;
    width: auto !important;
    padding: 5px 1px;
  }
  .view-toggle .v-item--active.v-btn:before,
  .v-slide-group .v-slide-item--active.v-btn:before
  {
    background: none !important;
  }
  .colored-back {
    background-color: rgba(22, 123, 215, 0.08) !important;
  }
  .toggle-view {
    height: 40px;
  }
  .inactive {
    color: #999;
  }
  .inactive ::v-deep .v-chip {
    color: #999;
  }
  .default {
    font-weight: 700;
  }
  .v-dialog .v-card {
    padding: 15px;
  }
  .headline {
    width: 100%;
  }
  .input-btn{
    display: grid;
    grid-template-columns: auto 32px;
  }
  
.project{
	display: inline-block;
	border-radius: 15px;
	margin-right: 5px;
	white-space:nowrap;
	div{
		display: inline-flex;
		align-items: center;
		justify-content: center;

	}
	.letter{
		width: 30px;
		padding: 5px;
		color: white;
		border-radius: 15px;
	}
	.name{
		padding: 0 10px 0 5px;
		color: black;
	}
}

.chip{
  background: var(--v-success-)
}
</style> 