
<template>
  <div class="fill-height new-back pa-5">
    <div class="pb-2 pr-2 text-right">
      <v-menu v-if="!isNewUser && canCloseDailyReports" z-index="200" :close-on-content-click="false">
				<template #activator="{ on }">
					<v-btn v-on="on" color="primary" outlined>
						Actions
						<v-icon right>mdi-chevron-down</v-icon>
					</v-btn>
        </template>
        <v-list>
          <v-list-item @click="closeDailyReports" class="pa-1 py-0">
            <v-list-item-content class="px-3">
							<v-list-item-title class="px-0">
								<v-icon left color="primary">mdi-calendar-clock</v-icon> Close Daily Reports
							</v-list-item-title>
						</v-list-item-content>
          </v-list-item>
        </v-list>
      </v-menu>
    </div>
    <v-form v-model="valid" ref="form">
      <v-row no-gutters class="rounded-lg bordered darken">

        <v-col
          :cols="4" class="pa-5"
          style="border-right: 1px solid var(--v-lightgray-base); max-width: 450px"
        >
          <div class="d-flex flex-column align-center">
            <user-avatar v-if="item.id" :userId="item.id" :showCard="false" :size="120"/>
            <v-text-field
              v-if="!item.type"
              v-model="item.blueTag"
              class="input-center mt-3"
              label="Blue Tag*"
              v-bind="getProps(item.type)"
              style="font-size: 1.7rem; font-weight: bold"
              :rules="rules.field"
            />
            <v-text-field
              v-model="item.fullName"
              class="input-center"
              label="Full Name*"
              v-bind="getProps(item.fullName)"
              style="width: 100%"
              :rules="rules.field"
            />
            <v-text-field
              v-if="!item.type"
              v-model="item.roles"
              class="input-center mt-n1"
              label="Roles"
              v-bind="inputProps"
              style="font-size: 1rem; font-weight: bold; width: 100%"
            />
          </div>
          <div class="left-side-list mt-10" style="gap: 15px">
            <div>
              <span class="subtitle-1">User</span>
              <check-switch v-model="item.isUser"/>
            </div>
            <div>
              <span class="subtitle-1">Active</span>
              <check-switch v-model="item.active"/>
            </div>
            <div>
              <span class="subtitle-1">Type*</span>
              <div style="max-width: 180px">
                <v-select
                  v-model="item.type"
                  :items="userTypes"
                  item-text="name" item-value="value"
                  label="-" class="input-right"
                  v-bind="getProps(item.type)"
                  :rules="rules.field"
                >
                  <template v-slot:selection="{ item }">
                    <span class="d-flex justify-end" style="width: 100%;">
                      {{ item.name }}
                    </span>
                  </template>
                </v-select>
              </div>
            </div>
            <div>
              <span class="subtitle-1">Job Title</span>
              <v-text-field
                v-model="item.jobTitle"
                class="input-right"
                v-bind="inputProps"
                label="-"
              />
            </div>
            <div v-if="!item.type">
              <span class="subtitle-1">Experience Level</span>
              <div style="max-width: 200px">
                <v-select
                  v-model="item.experienceLevel"
                  :items="experienceLevels"
                  item-text="name" item-value="id"
                  label="-" class="input-right"
                  v-bind="inputProps"
                >
                  <template v-slot:selection="{ item }">
                    <span class="d-flex justify-end" style="width: 100%;">
                      {{ item.name }}
                    </span>
                  </template>
                </v-select>
              </div>
            </div>
            <div v-if="!item.type">
              <span class="subtitle-1">English Level</span>
              <v-text-field
                v-model="item.englishLevel"
                class="input-right"
                v-bind="inputProps"
                label="-"
              />
            </div>
            <div v-if="!item.type">
              <span class="subtitle-1">Bluenniversary</span>
              <q-date-picker
                v-model="item.blueniversary"
                class="input-right"
                label="-"
                v-bind="inputProps"
                inner
                style="width: 145px;"
              />
            </div>
            <div v-if="isEmployee && !item.active">
              <label class="subtitle-1">Termination Date</label>
              <q-date-picker
                v-model="item.terminationDate"
                class="input-right"
                label="-"
                v-bind="inputProps"
                inner
                style="width: 145px;"
                :rules="rules.field"
              />
            </div>
            <div v-if="currentIsSuperAdmin && !isNewUser && !showResetPassword && item.isUser">
              <v-btn @click="showResetPassword = true" color="primary" text>Reset Password</v-btn>
            </div>
            <div v-if="showResetPassword && item.isUser">
              <span class="subtitle-1">Password*</span>
              <v-text-field
                v-model="item.password"
                class="input-right"
                type="password"
                v-bind="getProps(item.password)"
                outlined
                :rules="passwordRules"
                style="max-width: 200px"
              />
            </div>
            <div v-if="showResetPassword && item.isUser">
              <span class="subtitle-1">Confirm Password*</span>
              <v-text-field
                v-model="item.confirmPassword"
                class="input-right"
                type="password"
                v-bind="getProps(item.confirmPassword)"
                outlined
                :rules="confirmPasswordRules"
                style="max-width: 200px"
              />
            </div>
          </div>
        </v-col>

        <v-col class="d-flex flex-column">
          <div class="flex px-10 py-5" style="border-bottom: 1px solid var(--v-lightgray-base);">
            <v-row class="fill-height">
              <v-col :cols="4">
                <label class="subtitle-2">Birth Date</label>
                <q-date-picker
                  v-model="item.birthdate"
                  v-bind="inputProps"
                  inner
                  class="ml-n3"
                />
              </v-col>
              <v-col :cols="4">
                <label class="subtitle-2">Email*</label>
                <v-text-field
                  @change="checkEmail"
                  v-model="item.email"
                  v-bind="getProps(item.email)"
                  :error-messages="isValidEmail ? null : ['Email already exists']"
                  :rules="rules.email"
                  class="ml-n3"
                />
              </v-col>
              <v-col :cols="4">
                <label class="subtitle-2">Personal Email</label>
                <v-text-field
                  v-model="item.personalEmail"
                  v-bind="inputProps"
                  class="ml-n3"
                />
              </v-col>
              <v-col :cols="4">
                <label class="subtitle-2">Mobile</label>
                <v-text-field
                  v-model="item.mobile"
                  v-bind="inputProps"
                  class="ml-n3"
                  label="-"
                />
              </v-col>
              <v-col v-if="isEmployee" :cols="4">
                <label class="subtitle-2">Other Mobile</label>
                <v-text-field
                  v-model="item.otherMobile"
                  v-bind="inputProps"
                  class="ml-n3"
                  label="-"
                />
              </v-col>
              <v-col :cols="4">
                <label class="subtitle-2">Address</label>
                <v-text-field
                  v-model="item.address"
                  v-bind="inputProps"
                  class="ml-n3"
                  label="-"
                />
              </v-col>
              <v-col :cols="4">
                <label class="subtitle-2">Country*</label>
                <v-autocomplete
                  v-model="item.country"
                  @change="getStates"
                  :items="countries"
                  :filter="(item, queryText, itemText) => normalizeIncludes(itemText, queryText)"
                  item-text="name" item-value="name"
                  :rules="rules.field"
                  v-bind="getProps(item.country)"
                  class="ml-n3"
                  prepend-inner-icon="mdi-map-marker-outline"
                />
              </v-col>
              <v-col :cols="4">
                <label class="subtitle-2">State*</label>
                <v-autocomplete
                  v-model="item.state"
                  @change="getCities"
                  :items="states"
                  :filter="(item, queryText, itemText) => normalizeIncludes(itemText, queryText)"
                  item-text="name" item-value="name"
                  :rules="rules.field"
                  v-bind="getProps(item.state)"
                  class="ml-n3"
                  prepend-inner-icon="mdi-map-marker-outline"
                />
              </v-col>
              <v-col :cols="4">
                <label class="subtitle-2">City*</label>
                <v-autocomplete
                  v-model="item.city"
                  :items="cities"
                  :filter="(item, queryText, itemText) => normalizeIncludes(itemText, queryText)"
                  item-text="name" item-value="name"
                  :rules="rules.field"
                  v-bind="getProps(item.city)"
                  class="ml-n3"
                  prepend-inner-icon="mdi-map-marker-outline"
                />
              </v-col>
            </v-row>
          </div>
          <div class="flex px-10 py-5">
            <v-row class="fill-height">
              <v-col v-if="isEmployee" :cols="4">
                <label class="subtitle-2">Evaluation End Date*</label>
                <q-date-picker
                  v-model="item.evaluationPeriodEndDate"
                  v-bind="getProps(item.evaluationPeriodEndDate)"
                  inner
                  class="ml-n3"
                  @change="setDeadline"
                  :rules="rules.field"
                />
              </v-col>
              <v-col v-if="isEmployee" :cols="4">
                <label class="subtitle-2">Resolution deadline</label>
                <v-text-field
                  v-model="item.resolutionDeadline"
                  v-bind="inputProps"
                  class="ml-n3"
                  @change="setEndDate"
                  label="-"
                />
                <v-checkbox
                  v-model="item.isEvaluationPeriodActive" hide-details
                  class="mt-0" label="Evaluation Resolution"
                />
              </v-col>
              <v-col v-if="isEmployee && item.isUser" :cols="4">
                <label class="subtitle-2">User Groups</label>
                <v-autocomplete
                  v-model="selectedUserGroups"
                  :items="userGroups"
                  filled chips multiple
                  item-text="name" item-value="id"
                  v-bind="inputProps"
                  class="user-groups ml-n3"
                  label="-"
                >
                  <template v-slot:selection="data">
                    <v-chip :input-value="data.selected" close class="chip--select-multi" @click:close="remove(data.item)" style="margin: 7px 0 0 0">
                      {{ data.item.name }}
                    </v-chip>
                  </template>
                  <template v-slot:item="data">
                    <v-list-item-content class="included">
                      <v-list-item-title>{{ data.item.name }}</v-list-item-title>
                    </v-list-item-content>
                  </template>
                </v-autocomplete>
              </v-col>
              <v-col v-if="isEmployee" :cols="4">
                <label class="subtitle-2">Department*</label>
                <v-select
                  v-model="item.department"
                  :items="departments"
                  item-text="name" item-value="value"
                  v-bind="getProps(item.department)"
                  class="ml-n3"
                  label="-"
                  :rules="rules.field"
                />
              </v-col>
              <v-col v-if="isEmployee" :cols="4">
                <label class="subtitle-2">Contract Type*</label>
                <v-select
                  v-model="item.contractType"
                  :items="contractTypes"
                  item-text="name" item-value="value"
                  v-bind="getProps(item.contractType)"
                  class="ml-n3"
                  label="-"
                  :rules="rules.field"
                />
              </v-col>
              <v-col v-if="isEmployee && item.isUser" :cols="4">
                <label class="subtitle-2">Report Type*</label>
                <v-select
                  v-model="item.reportType"
                  :items="reportTypes"
                  item-text="name" item-value="value"
                  v-bind="getProps(item.reportType)"
                  class="ml-n3"
                  label="-"
                  :rules="rules.field"
                />
              </v-col>
              <v-col v-if="isEmployee && item.isUser" :cols="4">
                <label class="subtitle-2">Daily Hours to Report</label>
                <v-text-field
                  v-model="item.dailyHoursToReport"
                  v-bind="inputProps"
                  class="ml-n3"
                  label="-"
                  type="number"
                />
              </v-col>
              <v-col v-if="isEmployee" :cols="4">
                <label class="subtitle-2">Notes</label>
                <v-textarea
                  v-model="item.notes"
                  v-bind="inputProps"
                  class="ml-n3"
                  label="-"
                  auto-grow
                  rows="1"
                />
              </v-col>
              <v-col v-if="isExternal" :cols="4">
                <label class="subtitle-2">Organization</label>
                <v-text-field
                  v-model="item.organization"
                  v-bind="inputProps"
                  class="ml-n3"
                  label="-"
                />
              </v-col>
              <v-col v-if="isExternal" :cols="4">
                <label class="subtitle-2">Customer</label>
                <div class="d-flex flex-column">
                  <v-autocomplete
                    v-model="item.customerId" :items="customers"
                    v-bind="inputProps"
                    class="ml-n3" item-text="name" item-value="id"
                    label="-"
                  />
                  <v-checkbox
                    v-if="item.customerId && isExternal && item.isUser"
                    v-model="item.showAccountBalance" hide-details
                    class="mt-0" label="Show Account Balance"
                  />
                </div>
              </v-col>
              <v-col v-if="isEmployee" :cols="4">
                <label class="subtitle-2">Recess Approvers*</label>
                <v-autocomplete
                  v-model="recessApprovers"
                  :items="usersForRecessApprovers"
                  item-value="id" item-text="blueTag"
                  v-bind="getProps(recessApprovers)"
                  class="ml-n3"
                  label="-"
                  multiple
                  :rules="[() => !!recessApprovers.length || 'Field is required']"
                >
                  <template v-slot:item="{ item, attrs }">
                    <v-list-item-action>
                      <v-checkbox v-model="attrs.inputValue"></v-checkbox>
                    </v-list-item-action>
                    <v-list-item-content class="pa-0">
                      <v-list-item class="pa-0">
                        <v-list-item-avatar color="indigo" class="headline font-weight-light white--text">
                          {{ (item.blueTag || '-').charAt(0) }}
                        </v-list-item-avatar>
                        <v-list-item-content>
                          <v-list-item-title>{{ item.blueTag }}</v-list-item-title>
                          <v-list-item-subtitle>{{ item.fullName }}</v-list-item-subtitle>
                        </v-list-item-content>
                      </v-list-item>
                    </v-list-item-content>
                  </template>
                  <template v-slot:selection="{ attrs, item: user, parent, selected, index }">
                    <v-chip v-if="user === Object(user) && index === 0"
                      v-bind="attrs" class="accent--lighten" :input-value="selected" label small
                    >
                      <span class="pr-2">
                        {{ user.blueTag }}
                      </span>
                      <v-icon small @click.stop="parent.selectItem(user)">close</v-icon>
                    </v-chip>
                    <span
                      v-if="index === 1"
                      class="grey--text text-caption mt-1 ml-1"
                    >
                      (+{{ recessApprovers.length - 1 }})
                    </span>
                  </template>
                </v-autocomplete>
              </v-col>
              <v-col v-if="isEmployee && !item.active" :cols="4">
                <label class="subtitle-2">Termination remarks*</label>
                <v-textarea
                  v-model="item.terminationRemarks"
                  v-bind="inputProps"
                  class="ml-n3"
                  label="-"
                  auto-grow
                  rows="1"
                  :rules="rules.field"
                />
              </v-col>
            </v-row>
          </div>
        </v-col>

      </v-row>
      <v-row>
        <v-col :cols="12" v-if="item.isUser && (item.id != currentUser.id || currentUser.isSuperAdmin)">
          
          <v-tabs v-model="activeTab">
            <v-tab href="#Projects">Projects</v-tab>
            <v-tab href="#Categories" v-if="!item.type">Categories</v-tab>
            <v-tab href="#Permissions" v-if="!item.type">Permissions</v-tab>
          </v-tabs>
          <v-tabs-items v-model="activeTab">
            <v-tab-item
              v-if="activeTab === 'Projects'"
              value="Projects"
            >
              <v-card flat>
                <v-card-text class="px-0">
                  <v-col :cols="12" lg="9" xl="8" class="ml-2">
                    <v-row>
                      <v-col cols="5">
                        <v-text-field
                          flat
                          outlined
                          solo
                          prepend-inner-icon="search"
                          placeholder="Search"
                          v-model="search"
                          hide-details
                          class="hidden-sm-and-down"
                          deletable
                        ></v-text-field>
                      </v-col>
                      <v-col cols="7">
                        <div class="float-right">
                          <v-flex xs12 class="text-sm-left">
                            <label class="filter-label ml-2">Project Status</label>
                            <br />
                            <v-slide-group multiple left show-arrows v-model="selectedStatuses" class="slide-group-left">
                              <v-slide-item v-for="status in statuses" :key="status.id" v-slot:default="{ active, toggle }">
                                <v-btn class="ma-1" :input-value="active" depressed active-class="primary" small @click="toggle" >
                                  {{status.name}}
                                </v-btn>
                              </v-slide-item>
                            </v-slide-group>
                          </v-flex>
                        </div>
                      </v-col>
                    </v-row>

                  </v-col>
                  
                  <v-col :cols="12" lg="9" xl="8" class="elevation-2 ml-2">
                    <div class="line header">
                      <div class="line-title">Name</div>
                      <div class="content">
                        <div class="item-title noselect"> Visibility </div>
                        <div class="item-title noselect"> Can Edit </div>
                        <div class="item-title noselect" v-if="!item.type"> Can Report </div>
                        <div class="item-title noselect"> Delete Work Items </div>
                      </div>
                    </div>
                    <div class="line select-all-container">
                      <div class="content">
                        <div class="item-title">
                          <v-tooltip top>
                            <template v-slot:activator="{ on }">
                              <v-btn small class="ml-1 project-toggle-btn" @click="selectAll('view')" :disabled="item.isSuperAdmin"
                                v-on="on" :class="viewLevelOptions[selectAllStates['viewLevel']].textClass" :text="true" input-value="true"><v-icon>{{viewLevelOptions[selectAllStates['viewLevel']].icon}}</v-icon></v-btn>
                            </template>
                            <span>
                              <span v-text="viewLevelOptions[selectAllStates['viewLevel']].tooltip"></span>
                            </span>
                          </v-tooltip>
                        </div>

                        <div class="item-title">
                          <v-tooltip top>
                            <template v-slot:activator="{ on }">
                              <v-btn small class="ml-1 project-toggle-btn" @click="selectAll('edit')" :disabled="item.isSuperAdmin"
                                v-on="on" :class="editLevelOptions[selectAllStates['editLevel']].textClass" :text="true" input-value="true"><v-icon>{{editLevelOptions[selectAllStates['editLevel']].icon}}</v-icon></v-btn>                    
                            </template>
                            <span>
                              <span v-text="editLevelOptions[selectAllStates['editLevel']].tooltip"></span>
                            </span>
                          </v-tooltip>
                        </div>

                        <div class="item-title" v-if="!item.type">
                          <v-tooltip top v-if="!item.type">
                            <template v-slot:activator="{ on }">
                              <v-btn small class="ml-1 project-toggle-btn" @click="selectAll('report')" :disabled="item.isSuperAdmin"
                                v-on="on" :class="reportLevelOptions[selectAllStates['reportLevel']].textClass" :text="true" input-value="true"><v-icon>{{reportLevelOptions[selectAllStates['reportLevel']].icon}}</v-icon></v-btn>                    
                            </template>
                            <span>
                              <span v-text="reportLevelOptions[selectAllStates['reportLevel']].tooltip"></span>
                            </span>
                          </v-tooltip>
                        </div>

                        <div class="item-title">
                          <v-tooltip top>
                            <template v-slot:activator="{ on }">
                              <v-btn small class="ml-1 project-toggle-btn" @click="selectAll('delete')" :disabled="item.isSuperAdmin"
                                v-on="on" :class="editLevelOptions[selectAllStates['deleteLevel']].textClass" :text="true" input-value="true"><v-icon>{{editLevelOptions[selectAllStates['deleteLevel']].icon}}</v-icon></v-btn>                    
                            </template>
                            <span>
                              <span v-text="deleteLevelOptions[selectAllStates['deleteLevel']].tooltip"></span>
                            </span>
                          </v-tooltip>
                        </div>
                      </div>
                    </div>

                    <v-expansion-panels v-model="expandedPanel" flat focusable accordion active-class="selected-project" :disabled="item.isSuperAdmin">
                      <v-expansion-panel v-for="(project, ix) in shownProjects" :key="project.id" >
                        <v-expansion-panel-header disable-icon-rotate class="panel-header">
                          <div>
                            <v-icon :color="item.isSuperAdmin ? '#ccc' : 'auto'">{{ expandedPanel == ix ? 'mdi-minus' : 'mdi-plus' }}</v-icon>
                            {{project.name}}
                          </div>
                          <template v-slot:actions>
                            <div class="item-title">
                              <v-tooltip top>
                                <template v-slot:activator="{ on }">
                                  <v-btn small class="ml-1 project-toggle-btn" @click.stop="handleViewChange(project)"
                                    v-on="on" :class="viewLevelOptions[project.viewLevel].textClass" :text="true" input-value="true"><v-icon>{{viewLevelOptions[project.viewLevel].icon}}</v-icon></v-btn>                    
                                </template>
                                <span>
                                  <span v-text="viewLevelOptions[project.viewLevel].tooltip"></span>
                                </span>
                              </v-tooltip>
                            </div>

                            <div class="item-title">
                              <v-tooltip top>
                                <template v-slot:activator="{ on }">
                                  <v-btn small class="ml-1 project-toggle-btn" @click.stop="project.canEdit = editLevelOptions[project.canEdit].nextValue"
                                    v-on="on" :class="editLevelOptions[project.canEdit].textClass" :text="true" input-value="true"><v-icon>{{editLevelOptions[project.canEdit].icon}}</v-icon></v-btn>                    
                                </template>
                                <span>
                                  <span v-text="editLevelOptions[project.canEdit].tooltip"></span>
                                </span>
                              </v-tooltip>
                            </div>

                            <div class="item-title" v-if="!item.type">
                              <v-tooltip top v-if="!item.type">
                                <template v-slot:activator="{ on }">
                                  <v-btn small class="ml-1 project-toggle-btn" @click.stop="project.canReport = reportLevelOptions[project.canReport].nextValue"
                                    v-on="on" :class="reportLevelOptions[project.canReport].textClass" :text="true" input-value="true"><v-icon>{{reportLevelOptions[project.canReport].icon}}</v-icon></v-btn>                    
                                </template>
                                <span>
                                  <span v-text="reportLevelOptions[project.canReport].tooltip"></span>
                                </span>
                              </v-tooltip>
                            </div>

                            <div class="item-title">
                              <v-tooltip top>
                                <template v-slot:activator="{ on }">
                                  <v-btn small class="ml-1 project-toggle-btn" @click.stop="project.canDelete = editLevelOptions[project.canDelete].nextValue"
                                    v-on="on" :class="editLevelOptions[project.canDelete].textClass" :text="true" input-value="true"><v-icon>{{editLevelOptions[project.canDelete].icon}}</v-icon></v-btn>                    
                                </template>
                                <span>
                                  <span v-text="deleteLevelOptions[project.canEdit].tooltip"></span>
                                </span>
                              </v-tooltip>
                            </div>
                          </template>
                        </v-expansion-panel-header>
                        <v-expansion-panel-content class="panel-content">
                          <div
                            class="line" v-for="(subpermission, ix) in projectSubpermissions.filter(sp => sp.value !== 0 && (!item.type || sp.viewableForExternal))" :key="ix"
                            v-show="subpermission.persistent || project.tabs.some(t => t.tab == subpermission.value && t.show)"
                          >
                            <div class="line-title">{{subpermission.name}}</div>
                            <div class="content">

                              <div class="item-title">
                                <v-tooltip top>
                                  <template v-slot:activator="{ on }">
                                    <v-btn
                                      small class="ml-1 project-toggle-btn" @click="tabChange(project, subpermission.value)"
                                      v-on="on" :class="viewLevelOptions[project.tabs[subpermission.value].viewLevel].textClass" :text="true" input-value="true"
                                    >
                                      <v-icon
                                        :class="viewLevelOptions[project.tabs[subpermission.value].viewLevel].textClass"
                                      >
                                        {{viewLevelOptions[project.tabs[subpermission.value].viewLevel].icon}}
                                      </v-icon>
                                    </v-btn>
                                  </template>
                                  <span v-if="project.tabs && project.tabs.length && project.tabs[subpermission.value]">
                                    {{ viewLevelOptions[project.tabs[subpermission.value].viewLevel].tooltip }}
                                  </span>
                                </v-tooltip>
                              </div>

                            </div>
                          </div>
                        </v-expansion-panel-content>
                      </v-expansion-panel>
                    </v-expansion-panels>
                  </v-col>
                </v-card-text>
              </v-card>
            </v-tab-item>
            <v-tab-item
              value="Categories"
            >
              <v-card flat>
                <v-card-text class="px-0" style="width: 600px">
                  <v-col :cols="12" class="px-0">
                    <v-list subheader>
                      <v-list-item v-for="category in categories" :key="category.id" :disabled="item.isSuperAdmin">

                        <v-list-item-title>
                          <span>{{category.name}}</span>
                        </v-list-item-title>
                        <PermissionButtons 
                          v-show="!item.isSuperAdmin"
                          :viewOnly="false"
                          :value="category"
                          attr="viewLevel"
                          type="ViewLevelOptions"
                          @input="(newCategory) => {category = newCategory}"
                        />
                      </v-list-item>
                    </v-list>
                  </v-col>
                </v-card-text>
              </v-card>
            </v-tab-item>
            
            <v-tab-item
              value="Permissions"
            >
              <v-card class="ml-1" flat>
                <v-card-text class="px-0 d-flex">
                  <v-expansion-panels
                    v-model="expandedPanel"
                    focusable accordion
                    class="permissions"
                    :disabled="item.isSuperAdmin"
                    style="width: 600px"
                  >
                    <v-expansion-panel
                      v-for="(permission) in permissions.filter(x => !x.deprecated)"
                      :key="permission.id"
                      :readonly="!permission.children"
                    >
                      <v-expansion-panel-header  class="panel-header">
                        <div>
                          {{permission.name}}
                        </div>
                        <template v-slot:actions>
                          <div v-if="!permission.children">
                            <access-level-select
                              :value="getPermissionByValue(permission.value).accessLevel"
                              :type="permission.levelTypes"
                              @input="(v) => {
                                if(permission){
                                  let pem = getPermissionByValue(permission.value)
                                  pem.accessLevel = v
                                }
                              }"
                            />
                          </div>
                        </template>
                      </v-expansion-panel-header>
                      <v-expansion-panel-content class="panel-content" v-if="permission.children">
                        <div class="line" v-for="(subpermission, ix) in permission.children.filter(x => !x.deprecated)" :key="ix">
                          <div class="line-title">{{subpermission.name}}</div>
                          <div class="content">
                            <access-level-select
                              :value="getPermissionByValue(subpermission.value).accessLevel"
                              :type="subpermission.levelTypes"
                              @input="(v) => {
                                let pem = getPermissionByValue(subpermission.value)
                                pem.accessLevel = v
                              }"
                            />
                          </div>
                        </div>
                      </v-expansion-panel-content>
                    </v-expansion-panel>
                  </v-expansion-panels>
                  <v-switch v-if="currentIsSuperAdmin" label="SuperAdmin" class="ml-5 pa-0 mt-3" v-model="item.isSuperAdmin"/>
                </v-card-text>
              </v-card>
            </v-tab-item>
          </v-tabs-items>
        </v-col>
        <v-col :cols="12">
          <p class="text-sm">
            <v-btn class="mx-2" color="default" to="/user">
              Cancel
            </v-btn>
            <v-btn color="accent" @click="save" >
              Save
              <v-icon right>cloud_upload</v-icon>
            </v-btn>
          </p>
        </v-col>
      </v-row>
    </v-form>
  </div>
</template>

<script>
import _ from "lodash"
import moment from "moment"
import {
  ViewLevelOptions, 
  EditLevelOptions, 
  DeleteLevelOptions, 
  ReportLevelOptions, 
  PermissionsOptions
} from '../../enums/permissions';
import PermissionButtons from "../../components/PermissionButtons"
import EditTxtCamp from "../../components/EditTxtCamp";
import permissionsJSON from "../../json/permissions.json"
import AccessLevelSelect from '../../components/AccessLevelSelect'

export default {
  props: ["id"],
  components: {
    PermissionButtons,
    AccessLevelSelect,
    EditTxtCamp
},
  data: () => ({
    canCloseDailyReports: false,
    activeTab: 'Permissions',
    countries: [],
    states: [],
    cities: [],
    valid: false,
    userTypes: [
      { value: 0, name: 'Employee' },
      { value: 1, name: 'Not Employee' },
    ],
    experienceLevels: [],
    reportTypes: [
      { value: 0, name: 'Automatic + Manual' },
      { value: 1, name: 'Manual' },
    ],
    departments: [
      { value: 0, name: 'Dev Center' },
      { value: 1, name: 'Administration' },
      { value: 2, name: 'Commercial' },
      { value: 3, name: 'Talent Acquisition' },
      { value: 4, name: 'Marketing' },
      { value: 5, name: 'Capability Management' },
    ],
    contractTypes: [
      { value: 0, name: 'Full Time' },
      { value: 1, name: 'Part Time' },
    ],
    userGroups: [],
    selectedUserGroups: [],
    categories: [],
    projects: [],
    viewLevelOptions: {
      [ViewLevelOptions.CANNOT_VIEW_BY_DEFAULT.key]: ViewLevelOptions.CANNOT_VIEW_BY_DEFAULT.properties,
      [ViewLevelOptions.CAN_VIEW.key]: ViewLevelOptions.CAN_VIEW.properties,
      [ViewLevelOptions.CAN_NOT_VIEW.key]: ViewLevelOptions.CAN_NOT_VIEW.properties,
    },
    editLevelOptions: {
      [EditLevelOptions.CANNOT_EDIT_BY_DEFAULT.key]: EditLevelOptions.CANNOT_EDIT_BY_DEFAULT.properties,
      [EditLevelOptions.CAN_EDIT.key]: EditLevelOptions.CAN_EDIT.properties,
      [EditLevelOptions.CAN_NOT_EDIT.key]: EditLevelOptions.CAN_NOT_EDIT.properties,
    },
    reportLevelOptions: {
      [ReportLevelOptions.CANNOT_REPORT_BY_DEFAULT.key]: ReportLevelOptions.CANNOT_REPORT_BY_DEFAULT.properties,
      [ReportLevelOptions.CAN_REPORT.key]: ReportLevelOptions.CAN_REPORT.properties,
      [ReportLevelOptions.CAN_NOT_REPORT.key]: ReportLevelOptions.CAN_NOT_REPORT.properties,
    },
    deleteLevelOptions: {
      [DeleteLevelOptions.CANNOT_DELETE_BY_DEFAULT.key]: DeleteLevelOptions.CANNOT_DELETE_BY_DEFAULT.properties,
      [DeleteLevelOptions.CAN_DELETE.key]: DeleteLevelOptions.CAN_DELETE.properties,
      [DeleteLevelOptions.CAN_NOT_DELETE.key]: DeleteLevelOptions.CAN_NOT_DELETE.properties,
    },
    permissionsOptions: {
      [PermissionsOptions.CANNOT_BY_DEFAULT.key]: PermissionsOptions.CANNOT_BY_DEFAULT.properties,
      [PermissionsOptions.CAN.key]: PermissionsOptions.CAN.properties,
      [PermissionsOptions.CAN_NOT.key]: PermissionsOptions.CAN_NOT.properties,
    },
    item: { 
      active: true,
      canCreateProjects: PermissionsOptions.DEFAULT_KEY,
      canEditCategories: PermissionsOptions.DEFAULT_KEY,
      canEditUsers: PermissionsOptions.DEFAULT_KEY,
      canViewReports: PermissionsOptions.DEFAULT_KEY,
      permissions: [],
      recessApprovers: [],
      isUser: false,
      isEvaluationPeriodActive: false,
      country: 'Mexico',
    },
    profilePicFailover: 'https://cdn.vuetifyjs.com/images/parallax/material2.jpg',

    currentUser: {},
    rules: {
      field: [
        (v) => (!!v || v === 0) || 'Field is required',
      ],
      email: [
        (v) => !!v || 'Field is required',
        (v) => /\S+@\S+\.\S+/.test(v) || 'E-mail must be valid'
      ],
      password: [
        (v) => !!v || 'Password cannot be blank',
      ], 
    },
    selectAllStates: {
      viewLevel: 0,
      editLevel: 0,
      reportLevel: 0,
      deleteLevel: 0,
    },
    projectSubpermissions: [
      { name: 'Board',                      value: 0, },
      { name: 'Work Items',                 value: 2, viewableForExternal: true },
      { name: 'Dashboard',                  value: 15, viewableForExternal: true },
      { name: 'NucleiManagement',           value: 17, },
      { name: 'Account',                    value: 18, viewableForExternal: true },
      { name: 'Healthchecks',               value: 19, viewableForExternal: true },
      { name: 'Risks',                      value: 11, viewableForExternal: true },
      { name: 'Links',                      value: 3, viewableForExternal: true },
      { name: 'Team Members',               value: 14, viewableForExternal: true },
      { name: 'Team Members Details',       value: 20, viewableForExternal: true, persistent: true },
      { name: 'General Details',            value: 1, },
      { name: 'Activity Log',               value: 10, viewableForExternal: true },
      { name: 'Users Permissions',          value: 13, },
      { name: 'Config',                     value: 12, },
      { name: 'Overview',                   value: 16, viewableForExternal: true },
    ],
    inputProps: {
      singleLine: true,
      hideDetails: true,
      dense: true,
      flat: true,
      solo: true,
      backgroundColor: 'transparent',
    },
    permissions: [],
    expandedPanel: null,
    selectedStatuses: [1],
    statuses: [],
    search: '',
    cities: [],
    users: [],
    recessApprovers: [],
    customers: [],
    
    showResetPassword: false,
    isValidEmail: true,
    sended: false,
  }),
  computed: {
    passwordRules(v) {
      return [
        (v) => !!v || !!this.id || 'Password cannot be blank',
      ];
    },
    confirmPasswordRules(v) {
      return [
        (v) => this.item.password === this.item.confirmPassword || 'Password confirmation must match with password.',
      ];
    },
    shownProjects() {
      let projects = this.projects.filter(x => !x.deleted)
      projects = this.applySearch(this.search, projects, ['name', 'smallCode'])
      return projects.filter( p => {
        let hidden = this.selectedStatuses.indexOf(p.status) === -1
        return !hidden
      });
    },
    usersForRecessApprovers() {
      return this.users
        .filter(u => u.id != this.item.id)
        .sort((a, b) => this.recessApprovers.includes(a.id) ? -1 : this.recessApprovers.includes(b.id) ? 1 : 0)
    },
    isEmployee(){
      return this.item.type == 0
    },
    isExternal(){
      return this.item.type == 1
    },
    isNewUser() {
      return !this.id?.length
    },
    currentIsSuperAdmin(){
      return this.currentUser?.isSuperAdmin
    },
  },
  mounted() {
    this.$refs.form.resetValidation()
  },
  created() {
    this.$security.hasRequiredPermission(5, 3)
      .then(canCloseDailyReports => {
        this.canCloseDailyReports = canCloseDailyReports
      })
    this.permissions = permissionsJSON.permissions
    this.$http.get(`/api/user?active=true&onlyInterns=true`)
      .then((res) => {
        this.users = res.data;
        this.users.forEach(this.$q.profilePicSrc);
      })
      .catch((err) => {
        window.getApp.error("Cannot get users.");
        console.error(err);
      });
    this.$http.get('api/location/countries')
      .then(res => {
        this.countries = res.data
      })
    this.$blueSystem.getCurrentUser()
      .then(user => {
        this.currentUser = user
      })
    this.$http
      .get(`/api/userGroup`)
      .then(res => {
        this.userGroups = res.data;
      })
      .catch(res => {
        window.getApp.error("Cannot get user groups.");
        console.log(res);
      });
    let getCategoriesPromise = this.$http
      .get(`/api/category`)
      .then(res => {
        this.categories = res.data
        this.categories.forEach(c => this.$set(c, "viewLevel", ViewLevelOptions.DEFAULT_KEY))
      })
      .catch(res => {
        window.getApp.error("Cannot get categories.")
        console.log(res)
      })
    this.$http.get("/api/customer")
			.then(res => {
				this.customers = _.orderBy(res.data, ['name'])
			})
			.catch(err => {
        console.error(err)
				window.getApp.error("Cannot obtain customers.")
			})
    let getProjectsPromise = this.$http
      .get(`/api/user/config/project`)
      .then(res => {
        this.projects = res.data
        this.projects.forEach(c => this.$set(c, "viewLevel", ViewLevelOptions.DEFAULT_KEY))
        this.projects.forEach(c => this.$set(c, "canEdit", EditLevelOptions.DEFAULT_KEY))
        this.projects.forEach(c => this.$set(c, "canReport", ReportLevelOptions.DEFAULT_KEY))
        this.projects.forEach(c => this.$set(c, "canDelete", ReportLevelOptions.DEFAULT_KEY))
      })
      .catch(res => {
        window.getApp.error("Cannot get projects.")
        console.log(res)
      })
    this.$http
      .get(`/api/Enums/ProjectStatus`)
      .then(res => {
        this.statuses = res.data;
      })
      .catch(res => {
        window.getApp.error("Cannot obtain project status.");
        console.log(res);
      })
    this.$http
      .get('/api/enums/experienceLevels')
      .then(res => {
        this.experienceLevels = res.data
      })
      .catch(err => {
        console.error(err)
        this.$root.error('Cannot get experience levels')
      })

    if (this.id) {
      this.$http
        .get(`/api/user/${this.id}`)
        .then(res => {
          this.item = res.data
          this.recessApprovers = this.item.recessApprovers.map(x => x.approverId)
          const permissionsBase = this.permissions
            .map(x => x.children ? x.children : x)
            .flat(1)
          
          permissionsBase.forEach(x => {
            if(!this.item.permissions.some(p => p.type == x.value)){
              this.item.permissions.push({type: x.value, accessLevel: 0})
            }
          })

          this.setDeadline()
          if(!this.item.country){
            this.item.country = 'Mexico'
          }
          this.$http.get(`api/location/states?countryName=${this.item.country}`)
            .then(res => { this.states = res.data })
          if(this.item.state){
            this.$http.get(`api/location/cities?countryName=${this.item.country}&stateName=${this.item.state}`)
              .then(res => { this.cities = res.data })
          }
          this.isEvaluationPeriodActive = this.item.isEvaluationPeriodActive
          document.title = 'Edit User (' + (this.item.blueTag || this.item.fullName) + ')'
          this.selectedUserGroups = this.item.groups.map(ug => ug.userGroupId)

          getCategoriesPromise.then(() => {
            this.categories.forEach(c => {
              let uCat = _.find(this.item.categories, uc => uc.categoryId === c.id)
              if (uCat) {
                c.viewLevel = uCat.viewLevel
              }
            })
          })
          getProjectsPromise.then(() => {
            this.projects.forEach(p => {
              let ugProj = _.find(this.item.projects, ugc => ugc.projectId === p.id)
              if (ugProj) {
                p.viewLevel = ugProj.viewLevel
                p.canEdit = ugProj.canEdit
                p.canReport = ugProj.canReport
                p.canDelete = ugProj.canDelete
                let tabs = p.tabs
                p.tabs = ugProj.viewableTabs.sort( (a, b) => a.tab < b.tab )
                p.tabs.forEach(tab => tab.show = (tabs.find(t => t.tab == tab.tab) || {}).active)
                p.userProjectId = ugProj.id

              }
              else {
                let tabs = []
                for(let tab of p.tabs) {
                  tabs.push({
                    show: tab.active,
                    tab: tab.tab,
                    viewLevel: parseInt(ViewLevelOptions.DEFAULT_KEY),
                  })
                }
                p.tabs = tabs.sort( (a, b) => a.tab < b.tab )
              }
            })
          })
        })
        .catch(res => {
          window.getApp.error("Cannot get record.");
          console.log(res);
       });
    } else {
      this.showResetPassword = true
      if(!this.item.country){
        this.item.country = 'Mexico'
      }
      this.setDeadline()
      this.$http.get(`api/location/states?countryName=${this.item.country}`)
        .then(res => { this.states = res.data })
      document.title = 'New User'
      getProjectsPromise.then(() => {
        this.projects.forEach(p => {
          let tabs = []
          for(let tab of p.tabs) {
            tabs.push({
              show: tab.active,
              tab: tab.tab,
              viewLevel: parseInt(ViewLevelOptions.DEFAULT_KEY),
            })
          }
          p.tabs = tabs.sort( (a, b) => a.tab < b.tab )
        })
      })
      for(let permission of this.permissions) {
        if(permission.children){
          for(let subpermission of permission.children){
            this.item.permissions.push({
              type: subpermission.value,
              accessLevel: 0,
            })
          }
        } else {
          this.item.permissions.push({
            type: permission.value,
            accessLevel: 0,
          })
        }
      }
    }
  },
  methods: {
    async closeDailyReports() {
      const confirm = await this.$openConfirmDialog({
        title: 'Close Daily Reports',
        description: 'You are about to close the daily reports until today. Are you sure you want to proceed?',
      })

      if(!confirm) return

      try {
        await this.$http.post(`api/Effort/CloseDailyReport/${this.item.id}`)
        this.$root.success('Daily reports closed successfully.')
      } catch (error) {
        if(error.notified) return
        this.$root.error('An error occurred while trying to close the daily reports.')
        console.error(error)
      }
    },
    setEndDate(v){
      if(!this.item.blueniversary) return
      this.$set(this.item, 'evaluationPeriodEndDate', moment(this.item.blueniversary).startOf('day').add(v, 'day').format('YYYY-MM-DD'))
    },
    setDeadline(){
      if(!this.item.blueniversary) return
      let deadline = moment(this.item.evaluationPeriodEndDate)
        .diff(moment(this.item.blueniversary).startOf('day'), 'day')
      this.$set(this.item, 'resolutionDeadline', deadline > 0 ? deadline : 0)
    },
    getStates(){
      this.item.state = ''
      this.item.city = ''
      this.$http.get(`api/location/states?countryName=${this.item.country}`)
        .then(res => {
          this.states = res.data
        })
    },
    getCities(){
      this.item.city = ''
      this.$http.get(`api/location/cities?countryName=${this.item.country}&stateName=${this.item.state}`)
        .then(res => {
          this.cities = res.data
        })
    },
    checkEmail(){
      let id = this.id || 'new'
      this.$http
        .post(
          `/api/user/validMail/${id}`,
          {email: this.item.email},
          {headers: {hideLoading: true}}
        )
        .then(res => {
          this.isValidEmail = res.data
          if(!this.isValidEmail){
            this.$root.error('Email already exists')
          }
        })
    },
    getPermissionByValue(value){
      if(!this.item.permissions){
        return {}
      }
      return this.item.permissions.find(p => p.type == value)
    },
    handleViewChange(project){
      project.viewLevel = this.viewLevelOptions[project.viewLevel].nextValue
      for(let tab of project.tabs){
        tab.viewLevel = project.viewLevel
      }
    },
    isCanPermission(permission){
      return permission == 3
    },
    selectAll(permission){
      const projectPermissions = {
        view: 'viewLevel',
        edit: 'canEdit',
        report: 'canReport',
        delete: 'canDelete',
      }
      for(let project of this.projects){
        project[projectPermissions[permission]] = this.selectAllStates[permission+'Level']
        if(permission == 'view'){
          for(let tab of project.tabs){
            tab.viewLevel = this.selectAllStates[permission+'Level']
          }
        }
      }
      this.selectAllStates[permission+'Level'] = this[permission+'LevelOptions'][this.selectAllStates[permission+'Level']].nextValue
    },
    remove(item) {
      const index = this.selectedUserGroups.indexOf(item.id)
      if (index >= 0) this.selectedUserGroups.splice(index, 1)
    },
    save() {
      this.sended = true
      if(!this.item.active && this.isEmployee && !this.item.terminationDate) {
        this.$refs.form.validate()
        window.getApp.error('Please insert Termination date')
        return
      }

      if (!this.$refs.form.validate()) {
        window.getApp.error('Fill all required fields')
        return
      }

      if (this.item.type == 1) { 
        this.item.blueTag = null;
        this.item.experienceLevel = null;
        this.item.blueniversary = null;
      }
      
      this.item.dailyHoursToReport = this.item.dailyHoursToReport || null
      this.item.canCreateProjects = this.item.permissions.find(p => p.type == 16)?.viewLevel
      this.item.canEditCategories = this.item.permissions.find(p => p.type == 13)?.viewLevel
      this.item.canEditUsers = this.item.permissions.find(p => p.type == 14).viewLevel || this.item.permissions.find(p => p.type == 15)?.viewLevel
      // Transform object to include groups and categories
      this.item.groups = this.selectedUserGroups.map(id => ({
        userGroupId: id
      }))

      // Transform approvers to match the model
      let totalApprovers = JSON.parse(JSON.stringify(this.recessApprovers))
      let approvers = totalApprovers.filter(id => !this.item.recessApprovers.some(r => r.approverId == id)).map(id => ({ approverId: id }))
      this.item.recessApprovers = this.item.recessApprovers.filter(approver => totalApprovers.some(id => id == approver.approverId))
      this.item.recessApprovers.forEach(r => {
        delete r.approver
        delete r.user
      })
      this.item.recessApprovers = this.item.recessApprovers.concat(approvers)

      this.item.categories = _(this.categories)
        .filter(c => c.viewLevel != -1)
        .map(c => ({
          categoryId: c.id,
          viewLevel: c.viewLevel,
        }))
        .value()
      this.item.projects = _(this.projects)
        // .filter(c => c.viewLevel != -1)
        .map(c => ({
          projectId: c.id,
          viewLevel: c.viewLevel,
          canEdit: c.canEdit,
          canReport: c.canReport,
          canDelete: c.canDelete,
          id: c.userProjectId,
          userId: this.item.id,
          viewableTabs: c.tabs,
        }))
        .value()

      if(!this.item.customerId){
        this.item.customerId = null
        this.item.showAccountBalance = false
      }

      this.$q.save({
        api: "/api/user",
        data: {...this.item, newPassUrl: window.location.origin},
        successMsg: "User saved.",
        afterSuccessUrl: "/user",
        errorMsg: null,
        afterErrorUrl: null,
      })
        .catch(err => {
          console.error(err)
          if(!err.notified)
            this.$root.error('Cannot save user')
        })
    },
    tabChange(project, index){
      project.tabs[index].viewLevel = this.viewLevelOptions[project.tabs[index].viewLevel].nextValue

      let allCant = true
      for(let tab of project.tabs){
        if(tab.viewLevel != 1){
          allCant = false
          break
        }
      }
      if(allCant){
        project.viewLevel = 1
      }
    },
    getProps(value) {
      const props = structuredClone(this.inputProps)
      if((value === null || value === undefined || value === '' || value.length === 0) && this.sended)
        props.outlined = true
      return props
    },
  }
};
</script>

<style scoped lang="scss">
::v-deep {
  .v-input__append-inner{
		display: none;
	}
  .input-center {
    input {
      text-align: center;
    }
    .v-label {
      left: 50% !important;
      transform: translateX(-50%);
      &.v-label--active {
        transform: translateY(-18px) scale(.75) translateX(-50%);
      }
    }
  }
  .input-right {
    input {
      text-align: right;
    }
    .v-label {
      right: 0 !important;
      left: unset !important;
    }
  }
}

.left-side-list {
  display: flex;
  flex-direction: column;

  > div {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
}

.small-switch{
  margin-top:0;
  display: inline-block;
  margin-right: 40px
}

.line{
  margin-left: 10px;
  height: 45px;
  padding: 0 10px;
  border-radius: 3px;
  .line-title{
    font-size: 1.2em;
    display: inline-flex;
    align-items: center;
    height: 45px;
    color: #333;
  }
  .content{
    height: 45px;
    display: inline-flex;
    align-items: center;
    float: right;

    .item-title{
      display: inline-block;
      text-align: center;
      width: 72px;
      margin-left: 3px;
    }
  }  
}
.line:hover{
  background-color: var(--v-lightgray-lighten2);
}
.line.header:hover{
  background: transparent;
}
.line.header{
  margin-bottom: 5px;
  .line-title{
    font-size: 1em;
    color: #888;
    margin-top: 20px;
  }
}

.panel-header{
  padding: 16px 24px;
  padding-right: 10px;
}

.panel-header:hover{
  background-color: var(--v-accent-lighten);
}
.panel-header::before{
  background-color: var(--v-accent-lighten);
}

::v-deep .v-expansion-panel-content__wrap{
  padding: 0;
}

::v-deep .v-expansion-panel-header__icon .v-icon {
  color: inherit !important;
}

.selected-project {
  margin: 5px;
  margin-left: 15px;
  padding-bottom: 5px;
  
  .panel-header{
    background-color: var(--v-accent-lighten);
  }
  .panel-header::before{
    opacity: 0 !important;
  }
  .panel-content {
    background-color: var(--v-background-lighten);
    padding-top: 5px;
  }
}

.panel-content{
  .line{
    margin: 0;
    height: 35px;
    padding: 0 50px;
    padding-right: 236px;
    border-radius: 3px;
    font-size: .9em;
  }
  .content{
    height: 35px;
  }
  .line-title{
    font-size: 1.2em;
    display: inline-flex;
    align-items: center;
    height: 35px;
    color: var(--v-text-lighten);
  }
}

.permissions {
  .v-expansion-panel-header{
    padding: 5px 24px;
  }
  .panel-content{
    .line{
      margin: 0;
      height: 40px;
      padding: 0 50px;
      border-radius: 3px;
      font-size: .9em;
    }
    .content{
      height: 40px;
    }
    .line-title{
      font-size: 1.2em;
      display: inline-flex;
      align-items: center;
      height: 40px;
      color: var(--v-text-base);
    }
  }
}

.item-title{
  display: inline-block;
  text-align: center;
  width: 72px;
  margin-left: 3px;
}

.list-item{
  border-radius: 3px;
}
.list-item:hover{
  background: var(--v-lightgray-lighten2);
}
.project-toggle-btn.v-btn {
  min-width: 62px;
}
.project-item-title{
  display: inline-block; 
  width:64px; 
  background: red;
  height: auto;
  max-width: 64px;
}

.main-subheader {
  flex: auto;
}

.right-title {
  display: inline-flex;
  width: 64px;
  text-align: center;
  font-size: 0.85em;
}

.v-tab {
  border-bottom: 2px solid gray;
  margin: 0px 10px;
  text-transform: capitalize;
  min-width: 150px;
}
.select-all-container{
  margin-bottom: 5px;
  border-bottom: 2px solid #ccc;
  .line-title{
    font-size: 1em;
    color: #888;
  }
  .item-title{
    cursor: pointer;
    display: inline-block;
    text-align: center;
    width: 72px;
    margin-left: 3px;
  }
}
.select-all-container:hover{
  background-color: transparent;
}
.user-groups fieldset{
  background-color: var(--v-background-base);
}
.textarea {
  min-height: 40px !important;
}
.user-details-container {
  display: flex;
  flex-wrap: wrap;
  border: 2px solid #E5E5E5;
  width: 100%;
  margin: 10px 20px;
}

.user-details-left {
  width: 30%;
  padding: 35px 30px;
  border-right: 2px solid #E5E5E5;
}
.user-details-right {
  width: 70%;
  display: flex;
  flex-wrap: wrap;
}
.details-right-top {
  border-bottom: 2px solid #E5E5E5;
  width: 100%;
  min-height: fit-content;
}
.details-right-bottom {
  width: 100%;
  height: 50%;
}
.information-details-grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: repeat(3, 1fr);
  grid-column-gap: 30px;
  grid-row-gap: 30px;
  padding: 20px 60px;
}
</style>