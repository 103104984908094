
<template>
  <div class="pa-3 new-back fill-height">
    <div class="d-flex align-center mb-3" style="gap: 10px">
      <q-date-picker label="From" v-model="filters.dateStart" outlined inner dense hide-details/>
      <q-date-picker label="To" v-model="filters.dateEnd" outlined inner dense hide-details/>
      <v-btn rounded color="primary" @click="updateReport()">
        Update
      </v-btn>
    </div>
    <highcharts :key="chartKey" class="reporting-delay-chart" :style="{ height: '250px' }" v-if="reportingDelayChartOptions" :options="reportingDelayChartOptions"></highcharts>
  </div>
</template>

<script>
import _ from "lodash"
import moment from 'moment'
import OnTimeReportingChart from "./../../charts/OnTimeReportingChart"
import Highcharts from 'highcharts'
import heatmap from 'highcharts/modules/heatmap'

export default {
  title: 'On-time Reporting',
  props: [],
  data: () => ({ 
    vm: {
      unformatted: false,
    },
    filters: {
      dateStart: moment().startOf('day').add(-7, 'days').format("YYYY-MM-DD"),
      dateEnd: moment().startOf('day').format("YYYY-MM-DD"),
    },
    reportData: [],
    dates: [],
    data: {},
    reportingDelayChartOptions: null,
    chartKey: 0,
  }),
  watch: {
    '$vuetify.theme.dark'(){
      this.$nextTick(this.initTable)
    },
  },
  computed: {
    orderedUsers() {
      return _(this.data.users)
        .filter(u => u.visible)
        .orderBy(['reporterFullName'])
        .value()
    },
    selectAllLabel() {
      var unselected = _.filter(this.data.users, { visible: false }).length
      return unselected ? 'Select all' : 'Unselect all'
    }
  },
  created() {
    heatmap(Highcharts)
    this.$q.log(7, 36, 3, 'NA')

    this.setUserPreferences("DailyHoursReport", "vm", "filters")
    if (this.$route.query.from) {
        this.filters.dateStart = moment(this.$route.querys.from).toDate()
    }
    if (this.$route.query.to) {
        this.filters.dateEnd = moment(this.$route.querys.to).toDate()
    }

    this.init()
  },
  methods: {
    updateReport() {
      this.init()
    },
    selectAll() {
      var unselected = _.filter(this.data.users, { visible: false }).length

      _.each(this.data.users, user => {
          user.visible = !!unselected
      })

    },
    initTable() {
      heatmap(Highcharts)
      if (!this.reportData) return

      let data = _.orderBy(this.reportData, 'reporterFullName')
      this.reportingDelayChartOptions = OnTimeReportingChart(
          'On-time reporting', 
          this.filters.dateStart,
          this.filters.dateEnd,
          data)
      this.chartKey++
    },
    init() {
      this.$http
        .post(`/api/report/dailyHours`, this.filters)
        .then(res => {
          this.reportData = res.data
          this.initTable()
        })
        .catch(res => {
          window.getApp.error("Cannot obtain report.")
          console.log(res)
        })
    }
  },
}
</script>

<style scoped lang="scss">

</style> 