<template>
	<div class="infinite-data-table">
		<data-table
			v-bind="$attrs" v-on="$listeners"
			:items="items" :headers="headers"
			hide-default-footer disable-pagination
			ref="table"
			
			@scroll="handleScroll"
			style="position: relative;"
		>
			<template v-for="(slot, name) in $slots" #[name]="props">
				<slot :name="name" v-bind="props"/>
			</template>
			<template v-for="(slot, name) in $scopedSlots" #[name]="props">
				<slot :name="name" v-bind="props"/>
			</template>

			<template #[`foot`]>
				<td :colspan="headers.length">
					<div v-if="items.length < totalItems" class="text-center py-2" ref="loadingMore">
						<v-progress-circular
							indeterminate
							color="primary"
							width="3"
							size="24"
						/>
					</div>
				</td>
			</template>
		</data-table>	
	</div>
</template>

<script>
export default {
	props: {
		headers: Array,
		items: Array,
		totalItems: Number,
	},
	data: () => ({
		loadMoreTimeout: null,
	}),
	mounted(){
		this.$refs.table.$el.querySelector('.v-data-table__wrapper')
			.addEventListener('scroll', this.handleScroll)
		window.removeEventListener('scroll', this.handleScroll)
    window.addEventListener('scroll', this.handleScroll)
	},
	beforeDestroy(){
    window.removeEventListener('scroll', this.handleScroll);
	},
	methods: {
		handleScroll(){
			this.loadMoreTimeout && clearTimeout(this.loadMoreTimeout)

			this.loadMoreTimeout = setTimeout(() => {
				let rect = this.$refs.loadingMore.getBoundingClientRect()
				if(rect.bottom < (window.innerHeight || document.documentElement.clientHeight)){
					this.$emit('load-more')
				}
			}, 100)

		},
	},
}
</script>

<style lang="scss" scoped>
::v-deep thead{
	position: sticky;
	top: 0;
	background: var(--v-newDesignBackground-lighten2);
	z-index: 1;
}
</style>