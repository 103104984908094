<template>
    <div id="loading">
        <div id="circle-1">
        </div>
        <div v-bind:style="{backgroundImage: `url(${getLoadingPic})`}" id="circle-2">
        </div>
    </div>
</template>

<script>
export default {
    computed: {
        getLoadingPic(){
            if (
                (this.$router.history.current.path.includes("/project/") || this.$router.history.current.path.includes("/external/")) &&
                localStorage.getItem('currentProjectImage')
            ) {
                return localStorage.getItem('currentProjectImage');
            }
            return require("../assets/surf.svg");
        }
    },
    
}
</script>

<style scoped>
#loading {
    position: fixed;
    width: 100vw;
    height: 100vh;
    top: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
}
#circle-1 {
    /* display: none; */
    position: fixed;
    top: calc(50% - 95px);
    width: 110px;
    height: 110px;
    border: 5px transparent;
    border-style: dashed;
    border-bottom-color: #0470fc;
    border-radius: 50%;
    animation: loading-1 1.5s linear infinite;
    z-index: 100;
}

#image {
    /* display: none; */
    position: fixed;
    border: 7px solid transparent;
    object-fit: cover;
    z-index: 100;
}

#circle-2 {
    position: fixed;
    top: calc(50% - 76px);
    display: flex;
    justify-content: center;
    align-items: center;
   background-size: contain;
   background-repeat: no-repeat;
   background-position: center;
    width: 70px;
    height: 70px;
    border: 7px solid transparent;
    border-radius: 50%;
    z-index: 99;
}

@keyframes loading-1 {
    0%{
        transform: rotate(-.125turn)
    }
    50%{
        transform: rotate(.875turn)
    }
    85%{
        transform: rotate(.925turn)
    }
    100%{
        transform: rotate(.875turn)
    }
}

</style>