
<template>
  <v-list @keydown.up="upHandler" @keydown.down="downHandler" @keydown.enter="enterHandler" dense>
    <v-list-item
      v-for="(item, ix) in shownItems" :key="item.id"
      @click="selectItem(item)"
      :class="{ selected: ix === selectedIndex }"
    >
      <v-list-item-avatar class="font-weight-light white--text ma-0 mr-1">
        <user-avatar
          :user-id="item.id"
          :size="32" hide-loading
        />
      </v-list-item-avatar>
      <v-list-item-content>
        {{ item.blueTag || item.fullName }}
      </v-list-item-content>
    </v-list-item>
  </v-list>
</template>

<script>
export default {
  props: {
    items: { type: Array, required: true },
    command: { type: Function, required: true },
  },
  data: () => ({
    selectedIndex: 0,
  }),
  watch: {
    items() {
      this.selectedIndex = 0
    },
  },

  computed: {
    shownItems(){
      let uniqueIds = new Set()
      return this.items.filter(x => x.id && (uniqueIds.has(x.id) ? false : uniqueIds.add(x.id)))
    },
  },

  methods: {
    onKeyDown({ event }) {
      if (event.key === 'ArrowUp') {
        this.upHandler()
        return true
      }

      if (event.key === 'ArrowDown') {
        this.downHandler()
        return true
      }

      if (event.key === 'Enter') {
        this.enterHandler()
        return true
      }

      return false
    },

    upHandler() {
      this.selectedIndex = ((this.selectedIndex + this.shownItems.length) - 1) % this.shownItems.length
    },
    downHandler() {
      this.selectedIndex = (this.selectedIndex + 1) % this.shownItems.length
    },
    enterHandler() {
      this.selectItem(this.shownItems[this.selectedIndex])
    },

    selectItem(item) {
      this.command({ label: item.blueTag || item.fullName, id: item.id })
    },
  },
}
</script>

<style lang="scss">
.v-list-item.selected:before {
  opacity: .04;
}
</style>