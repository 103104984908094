<template>
	<div>

		<filter-search-input v-model="filters.search" :applied-filters="appliedFilters" outlined dense>
			<template #append>
				<div v-if="permissions.add">
					<v-tooltip top z-index="200">
						<template #activator="{ on }">
							<v-btn v-on="on" @click="editPayment('new')" fab color="primary" small>
								<v-icon>mdi-plus</v-icon>
							</v-btn>
						</template>
						<span class="caption">Add new</span>
					</v-tooltip>
				</div>
			</template>
			<v-autocomplete
				v-if="!invoiceDetailsView"
				label="Customer"
				outlined dense hide-details single-line clearable multiple
				v-model="filters.customer" :items="shownCustomers"
				item-text="name" item-value="id"
			>
				<template v-slot:item="{ item }">
					<div class="opt" :style="{color: item.filtered ? '' : 'grey'}">
						<v-icon v-if="filters.customer.includes(item.id)" color="primary">mdi-checkbox-marked</v-icon>
						<v-icon v-else>mdi-checkbox-blank-outline</v-icon>
						{{item.name}}
					</div>
				</template>
			</v-autocomplete>
			
			<q-date-picker
				label="Start Date"
				inner outlined dense hide-details clearable
				v-model="filters.startDate" :max="filters.endDate"
			/>

			<q-date-picker
				label="End Date"
				inner outlined dense hide-details clearable
				v-model="filters.endDate" :min="filters.startDate"
			/>
			
			<v-autocomplete
				v-if="!invoiceDetailsView"
				label="Invoice"
				outlined dense hide-details single-line clearable multiple
				v-model="filters.invoice" :items="shownInvoices"
				item-text="code" item-value="id"
			>
				<template v-slot:item="{ item }">
					<div class="opt" :style="{color: item.filtered ? '' : 'grey'}">
						<v-icon v-if="filters.invoice.includes(item.id)" color="primary">mdi-checkbox-marked</v-icon>
						<v-icon v-else>mdi-checkbox-blank-outline</v-icon>
						{{item.code}}
					</div>
				</template>
			</v-autocomplete>

			<q-filter-input
				v-model="filters.status" :items="statuses"
				label="Status"
				dense outlined hide-details clearable input-select multiple hide-input
				:parent-shown-items="shownPayments"
				:data-comparer="(item, value) => item.status == value"
			>
			</q-filter-input>
		</filter-search-input>

		<v-data-table
			@click:row="x => permissions.detail ? editPayment(x.id) : null"
			class="rounded-lg bordered new-back lighten2"
			:item-class="() => permissions.detail ? 'cursor-pointer' : ''"
			:items="shownPayments" :headers="shownHeaders"
		>
			<template #[`item.customer`]="{ item }">
				<div class="my-1">
					<h3>{{item.customerName}}</h3>
					<span>{{item.concept}}</span>
				</div>
			</template>

			<template #[`item.date`]="{ item }">
				{{item.date | formatDate}}
			</template>

			<template #[`item.amount`]="{ item }">
				<span v-if="invoiceDetailsView">
					{{
						currencyFormat(
							item.invoices.find(x => x.invoiceId == referencedInvoiceId).exchangeRateAmount ||
							item.invoices.find(x => x.invoiceId == referencedInvoiceId).amount
						)
					}}
				</span>
				<span v-else>{{currencyFormat(item.amount)}}</span>
			</template>

			<template #[`item.balanceInFavor`]="{ item }">
				{{ item.balanceInFavor ? currencyFormat(item.balanceInFavor) : '-' }}
			</template>

			<template #[`item.deleted`]="{ item }">
				<div>
					<v-btn
						:color="item.statusColor"
						outlined small style="border-radius: 20px"
						:style="{
							background: $vuetify.theme.isDark ? '' : item.statusLightColor
						}"
					>{{item.statusDisplayName}}</v-btn>
				</div>
			</template>

			<template #[`item.actions`]="{ item }">
				<v-menu left offset-x>
					<template #activator="{ on }">
						<v-btn v-on="on" icon><v-icon>mdi-dots-vertical</v-icon></v-btn>
					</template>
					<v-list dense class="py-1">
						<v-list-item class="pa-1 py-0">
							<v-tooltip right z-index="200">
								<template #activator="{ on }">
									<v-btn
										v-on="on"
										@click="$adminSurf('openHistory', item, 'payment')"
										text small
									>
										<v-icon>mdi-history</v-icon>
									</v-btn>
								</template>
								<span class="caption">History</span>
							</v-tooltip>
						</v-list-item>
						<v-list-item class="pa-1 py-0">
							<v-tooltip right z-index="200">
								<template #activator="{ on }">
									<v-btn
										v-on="on"
										@click="cancelPayment(item)"
										text small color="error" :disabled="item.deleted"
									>
										<v-icon>mdi-cancel</v-icon>
									</v-btn>
								</template>
								<span class="caption">Cancel</span>
							</v-tooltip>
						</v-list-item>
						<v-list-item class="pa-1 py-0">
							<watchers-menu
								@watcher-added="item.subscribers.push($event)"
								@watcher-removed="item.subscribers.splice(item.subscribers.findIndex(x => x.userId == $event), 1)"
								:entity-id="item.id"
								:watchers="item.subscribers"
								required-permission="payments"
							/>
						</v-list-item>
					</v-list>
				</v-menu>

			</template>
		</v-data-table>
		
		<payment-create-dialog ref="paymentCreateDialog" :invoiceDetailsView="invoiceDetailsView"/>
		<blue-confirm-dialog ref="confirmDialog"/>
	</div>
</template>

<script>
import moment from "moment"
import PaymentCreateDialog from '../dialogs/adminSurf/PaymentCreateDialog'
import WatchersMenu from "./admin-surf/WatchersMenu.vue"

export default {
	components: { PaymentCreateDialog, WatchersMenu },
	props: {
		payments: { type: Array, default: () => [] },
		accounts: { type: Array },
		invoiceDetailsView: { type: Boolean, default: false },
		referencedInvoiceId: { type: String, default: '' },
		referencedInvoice: { type: Object },

		customerId: { type: String, default: null },
		applied: { type: String, default: null },
	},
	data: () => ({
		headers: [
			{ text: 'Customer', value: 'customer' },
			{ text: 'Reference Number', value: 'reference' },
			{ text: 'Payment Date', value: 'date' },
			{ text: 'Amount', value: 'amount', align: 'right' },
			{ text: 'Balance in Favor', value: 'balanceInFavor', align: 'right' },
			{ text: 'Status', value: 'deleted', align: 'center' },
		],
		customers: [],
		invoices: [],
		currencies: ['MXN', 'USD', 'EUR'],
		statuses: [
			{ text: 'Not Applied', value: 1 },
			{ text: 'Applied', value: 5 },
			{ text: 'Cancelled', value: 3 },
			{ text: 'Partial', value: 4 },
		],

		filters: {
			customer: [],
			invoice: [],
			status: [],
		},

		cancellationReasons: [
			'Comprobante emitido con relación de relación',
			'Comprobante emitido con errores sin relación',
			'No se llevó a cabo la operación',
		],

		permissions: {
			detail: false,
			add: false,
			actions: false,
		},
	}),
	mounted() {
		this.filters.status = this.invoiceDetailsView ? [] : [1, 2, 4]

		this.$security.hasRequiredPermission('detail')
			.then(res => this.permissions.detail = res)
		this.$security.hasRequiredPermission('add')
			.then(res => this.permissions.add = res)
		this.$security.hasRequiredPermission('full_access')
			.then(res => this.permissions.actions = res)

		if(!this.invoiceDetailsView){
			this.setUserPreferences("Payments", "filters")
		}
		if(this.customerId){
			this.filters = {
				customer: [],
				invoice: [],
				status: [1, 2, 4],
			}
		  this.filters.customer.push(this.customerId)

			if(this.applied == 'true'){
				this.filters.status = [2, 4]
			}
			else if(this.applied == 'false'){
				this.filters.status = [1]
			}
		}

		let customerPr = this.$http.get('api/customer')
			.then(res => {
				this.customers = res.data
			})
		let invoicePr = this.$http.get('api/invoice')
			.then(res => {
				this.invoices = res.data
			})
		
		Promise.all([customerPr, invoicePr])
			.then(() => {
				this.customers = this.customers
					.filter(c => this.invoices.some(i => i.customerId == c.id))
			})
	},
	computed: {
		shownPayments(){
			let payments = this.payments

			if(this.filters.startDate){
				payments = payments.filter(i => moment(i.date) >= moment(this.filters.startDate))
			}
			if(this.filters.endDate){
				payments = payments.filter(i => moment(i.date) <= moment(this.filters.endDate))
			}
			if(this.filters.customer && this.filters.customer.length){
				payments = payments.filter(i => this.filters.customer.includes(i.customerId))
			}
			if(this.filters.invoice && this.filters.invoice.length){
				payments = payments.filter(p => p.invoices.some(i => this.filters.invoice.includes(i.invoiceId)))
			}
			if(this.filters.status && this.filters.status.length){
				payments = payments.filter(p => this.filters.status.includes(p.status))
			}

			if(this.filters.search){
				payments = this.applySearch(this.filters.search, payments)
			}

			return payments
		},
		shownInvoices(){
			let invoices = this.invoices
			invoices.map(inv => {
				inv.filtered = this.shownPayments.some(p => p.invoices.some(i => i.invoiceId == inv.id))
			})
			invoices = invoices.sort((a, b) => {
				if(b.filtered == a.filtered)
					return a.code?.localeCompare(b.code)
				return b.filtered-a.filtered
			})
			return invoices
		},
		shownCustomers(){
			let customers = this.customers
			customers.map(c => {
				c.filtered = this.shownPayments.some(i => i.customerId == c.id)
			})
			customers = customers.sort((a, b) => {
				if(b.filtered == a.filtered)
					return a.name.localeCompare(b.name)
				return b.filtered-a.filtered
			})
			return customers
		},
		shownHeaders(){
			let headers = _.clone(this.headers)
			if(this.invoiceDetailsView){
				headers = headers.filter(h => !h.hideWhenDetails)
			}
			if(this.permissions.actions)
				headers.push({ text: '', value: 'actions', width: 1 })
			return headers
		},
		appliedFilters(){
			return this.countActiveFilters(this.filters, ['search'])
		},
	},
	methods: {
		editPayment(id){
			let query = {}
			if(id == 'new' && this.invoiceDetailsView && this.referencedInvoice){
				query.customerId = this.referencedInvoice.customerId
				query.invoiceId = this.referencedInvoice.id
			}
			else if(id != 'new' && this.invoiceDetailsView) {
				this.$q.openNewTab('/admin/payment/' + id)
				return
			}
			this.$router.push({path: '/admin/payment/' + id, query})
		},
		async cancelPayment(payment){
			let res = await this.$refs.confirmDialog
				.open({
					title: 'Confirm cancellation',
					description: 'Are you sure you want to cancel this payment?',
					confirmBtnColor: 'error',
				})
			if(!res) return

			this.$http.delete(`/api/payment/${payment.id}`)
				.then(() => {
					this.$refs.confirmDialog.close()
					this.$emit('updated')
					this.$root.success('Payment canceled')
				})
				.catch(err => {
					console.error(err)
					this.$root.error('Cannot cancel payment')
					this.cancelPayment(payment)
				})
		},
	}
}
</script>

<style lang="scss" scoped>
.col{
	padding: 6px 12px;
}
</style>