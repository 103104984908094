var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"new-back fill-height"},[_c('div',{staticClass:"mx-auto",staticStyle:{"width":"80%"}},[_c('div',{staticClass:"new-back lighten2 bordered darken rounded-lg overflow-hidden pt-3"},[_c('v-data-table',{staticClass:"new-back lighten2",attrs:{"headers":_vm.headers,"items":_vm.items,"footer-props":{ itemsPerPageOptions: [ 10, 25, 50, { text:'All', 'value': -1 } ] },"options":{ rowsPerPage: -1 },"item-key":"id"},on:{"click:row":_vm.editRequest},scopedSlots:_vm._u([{key:"item.lines",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(" "+_vm._s(item.lines.length + " day" + (item.lines.length > 1 ? 's' : ''))+" "),(item.lines.some(function (x) { return x.date; }))?_c('span',[_vm._v(" - "+_vm._s(_vm._f("formatDates")(item.lines))+" ")]):_vm._e()])]}},{key:"item.status",fn:function(ref){
var item = ref.item;
return [_c('v-menu',{staticClass:"menu ",staticStyle:{"z-index":"230"},attrs:{"open-on-hover":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('span',{attrs:{"set":_vm.status = _vm.requestStatus.find(function (s) { return s.value == item.status; })}},[_c('v-chip',_vm._g({style:({ color: _vm.status.color }),attrs:{"color":_vm.status.background,"small":""}},on),[_vm._v(" "+_vm._s(_vm.status.name)+" ")])],1)]}}],null,true)},[_c('v-list',{attrs:{"dense":""}},_vm._l((item.requestApprovers),function(requestApprover,ix){return _c('v-list-item',{key:ix},[_c('div',{staticClass:"d-flex justify-space-between align-center",staticStyle:{"min-width":"20px","width":"100%"}},[_c('span',{staticClass:"mr-2 body-2"},[_c('user-avatar',{attrs:{"user-id":requestApprover.approverId,"size":28,"hide-loading":""}}),_vm._v(" "+_vm._s(requestApprover.approverBlueTag)+" ")],1),_c('span',{attrs:{"set":_vm.status = _vm.requestApproverStatus.find(function (s) { return s.value == requestApprover.status; })}},[_c('v-chip',{style:({ color: _vm.status.color }),attrs:{"color":_vm.status.background,"small":""}},[_vm._v(" "+_vm._s(_vm.status.name)+" ")])],1)])])}),1)],1)]}},{key:"item.createdAt",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(" "+_vm._s(_vm._f("formatDate")(item.createdAt))+" ")])]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"nobr"},[_c('v-btn',{attrs:{"icon":"","fab":"","x-small":"","outlined":"","color":"primary","disabled":!item.canEdit},on:{"click":function($event){$event.stopPropagation();return _vm.editRequest(item)}}},[_c('v-icon',[_vm._v("edit")])],1)],1)]}}],null,true)})],1)]),_c('vacation-request-dialog',{ref:"vacationRequestDialog"})],1)}
var staticRenderFns = []

export { render, staticRenderFns }