<template>
	<div class="pa-3 full-height new-back">
		<div class="d-inline-flex flex-wrap align-center gap-3">
			<q-date-picker
				v-model="filters.startDate"
				@change="getReport"
				label="Start Date" type="month"
				outlined hide-details inner dense
			/>
			<q-date-picker
				v-model="filters.endDate"
				@change="getReport"
				label="End Date" type="month"
				outlined hide-details inner dense
			/>
			<v-autocomplete
				v-model="filters.billables"
				@change="getReport"
				:items="billables"
				label="Billable" item-value="id" item-text="code"
				outlined hide-details dense multiple
			/>
			<v-select
				v-model="filters.billableTypes"
				@change="getReport"
				:items="billableTypes"
				label="Billable Type" item-value="id" item-text="code"
				outlined hide-details dense multiple
			/>
			<q-user-autocomplete
				v-model="filters.users"
				@input="getReport"
				:items="users"
				label="User"
				outlined hide-details dense multiple
			/>
			<v-autocomplete
				v-model="filters.customers"
				@change="getReport"
				:items="customers"
				label="Customer" item-value="id" item-text="name"
				outlined hide-details dense multiple
			/>
			<v-checkbox
				v-model="filters.includeWithoutBillable"
				@change="getReport"
				label="Without Billable"
				color="primary"
				class="mt-0 pt-0"
				style="height: 24px"
				hide-details
			/>
			<v-checkbox
				v-model="filters.includeBillablesOnZero"
				@change="getReport"
				label="Billables on Zero"
				color="primary"
				class="mt-0 pt-0"
				style="height: 24px"
				hide-details
			/>
		</div>

		<div class="chart new-back mt-2 bordered rounded-lg lighten" ref="chart">
			<table :class="{ 'fix-totals': fixTotals }">
				<tbody>
					<tr class="sticky-header colored-header">
						<th rowspan="3" class="td-nb-x td-bb" style="background: var(--v-newDesignBackground-base) !important;">
							<div>
								Billable
							</div>
						</th>
						<th rowspan="3" class="td-nb-x td-bb" style="background: var(--v-newDesignBackground-base) !important;">
							<div class="fill-w">
								Type
							</div>
						</th>
						<th rowspan="3" class="td-nb-x td-bb" style="background: var(--v-newDesignBackground-base) !important;">
							<div>
								BlueTag
							</div>
						</th>
						<th
							v-for="(date, ix) in dates" :key="ix"
							colspan="6" scope="colgroup"
							class="td-nb-y" :class="{'fixed-total-header': date.isTotal}"
						>
							<div v-if="date.isTotal" class="relative">
								Totals
								<v-btn @click="fixTotals = !fixTotals" style="position: absolute; right: 10px" x-small icon>
									<v-icon>{{ fixTotals ? 'mdi-pin-off-outline' : 'mdi-pin-outline' }}</v-icon>
								</v-btn>
							</div>
							<span v-else>
								{{ date.format('MMM YYYY') }}
							</span>
						</th>
					</tr>
					<tr>
						<template
							v-for="(date, ix) in dates"
						>
							<th
								scope="col" colspan="2"
								class="td-nb-y td-nb-r" :class="{'fixed-total-subheader-1': date.isTotal}"
								:key="ix + 'a'"
							>
								Assigned
							</th>
							<th
								scope="col" colspan="3"
								class="td-nb-y td-b-x" :class="{'fixed-total-subheader-2': date.isTotal}"
								:key="ix + 'b'"
							>
								Executed
							</th>
							<th
								scope="col"
								class="td-nb-y td-nb-x" :class="{'fixed-total-subheader-3': date.isTotal}"
								:key="ix + 'c'"
							></th>
						</template>
					</tr>
					<tr class="colored-header">
						<template
							v-for="(date, ix) in dates"
						>
							<th
								scope="col"
								class="td-nb-y td-nb-r" :class="{'fixed-total-cell-1': date.isTotal}"
								:key="ix + 'a'"
							>
								Billable
							</th>
							<th
								scope="col"
								class="td-nb-y td-nb-x" :class="{'fixed-total-cell-2': date.isTotal}"
								:key="ix + 'b'"
							>
								Non Billable
							</th>
							<th
								scope="col"
								class="td-nb-y td-nb-x" :class="{'fixed-total-cell-3': date.isTotal}"
								:key="ix + 'c'"
							>
								Billable
							</th>
							<th
								scope="col"
								class="td-nb-y td-nb-x" :class="{'fixed-total-cell-4': date.isTotal}"
								:key="ix + 'd'"
							>
								Non Billable
							</th>
							<th
								scope="col"
								class="td-nb-y td-nb-x" :class="{'fixed-total-cell-5': date.isTotal}"
								:key="ix + 'e'"
							>
								Unclassified
							</th>
							<th
								scope="col"
								class="td-nb-y td-nb-l" :class="{'fixed-total-cell-6': date.isTotal}"
								:key="ix + 'f'"
							>
								Billed
							</th>
						</template>
					</tr>
					<template v-for="line in shownLines">
						<tr
							@click="doHighlight(line.key)"
							:key="line.key + 'a'"
							class="sticky-header customer-header"
							:class="{ 'highlighted': line.key == selectedId }"
						>
							<th class="td-nb-x">
								<div class="nobr text-center" style="width: 128px">{{ line.billableCode }}</div>
							</th>
							<th class="td-nb-x">
								<div class="text-center" style="width: 50px">
									{{ line.billableType !== undefined ? billableTypes[line.billableType].code : '-' }}
								</div>
							</th>
							<th class="td-nb-l">
								<div class="text-center" style="width: 110px">{{ line.userBlueTag }}</div>
							</th>
	
							<template v-for="(period, ix) in line.periods">
								<td :key="ix + 'a'" class="quantity-cell-customer" :class="{'fixed-total-cell-1': period.isTotal}">
									<div>{{ period.assignedBillableHours | number(2, 0, true) }}</div>
								</td>
								<td :key="ix + 'b'" class="quantity-cell-customer" :class="{'fixed-total-cell-2': period.isTotal}">
									<div>{{ period.assignedNonBillableHours | number(2, 0, true) }}</div>
								</td>
								<td :key="ix + 'c'" class="quantity-cell-customer" :class="{'fixed-total-cell-3': period.isTotal}">
									<div>{{ period.executedBillableHours | number(2, 0, true) }}</div>
								</td>
								<td :key="ix + 'd'" class="quantity-cell-customer" :class="{'fixed-total-cell-4': period.isTotal}">
									<div>{{ period.executedNonBillableHours | number(2, 0, true) }}</div>
								</td>
								<td :key="ix + 'e'" class="quantity-cell-customer" :class="{'fixed-total-cell-5': period.isTotal}">
									<div>{{ period.executedUnclassifedHours | number(2, 0, true) }}</div>
								</td>
								<td :key="ix + 'f'" class="quantity-cell-customer" :class="{'fixed-total-cell-6': period.isTotal}">
									<div>{{ period.billedHours | number(2, 0, true) }}</div>
								</td>
							</template>
						</tr>
					</template>
					<template v-for="line in billableTypeTotals">
						<tr
							@click="doHighlight(line.key)"
							:key="line.key + 'a'"
							class="sticky-header customer-header billable-totals"
							:class="{ 'highlighted': line.key == selectedId }"
						>
							<th class="td-nb-x">
								<div class="nobr text-center" style="width: 128px"></div>
							</th>
							<th class="td-nb-x">
								<div class="text-center" style="width: 50px"></div>
							</th>
							<th class="td-nb-l">
								<div class="text-center" style="width: 110px">{{ billableTypes[line.billableType].code }}</div>
							</th>
	
							<template v-for="(period, ix) in line.periods">
								<td :key="ix + 'a'" class="quantity-cell-customer" :class="{'fixed-total-cell-1': period.isTotal}">
									<div>{{ period.assignedBillableHours | number(2, 0, true) }}</div>
								</td>
								<td :key="ix + 'b'" class="quantity-cell-customer" :class="{'fixed-total-cell-2': period.isTotal}">
									<div>{{ period.assignedNonBillableHours | number(2, 0, true) }}</div>
								</td>
								<td :key="ix + 'c'" class="quantity-cell-customer" :class="{'fixed-total-cell-3': period.isTotal}">
									<div>{{ period.executedBillableHours | number(2, 0, true) }}</div>
								</td>
								<td :key="ix + 'd'" class="quantity-cell-customer" :class="{'fixed-total-cell-4': period.isTotal}">
									<div>{{ period.executedNonBillableHours | number(2, 0, true) }}</div>
								</td>
								<td :key="ix + 'e'" class="quantity-cell-customer" :class="{'fixed-total-cell-5': period.isTotal}">
									<div>{{ period.executedUnclassifedHours | number(2, 0, true) }}</div>
								</td>
								<td :key="ix + 'f'" class="quantity-cell-customer" :class="{'fixed-total-cell-6': period.isTotal}">
									<div>{{ period.billedHours | number(2, 0, true) }}</div>
								</td>
							</template>
						</tr>
					</template>
				</tbody>
			</table>
			<div class="pagination-container">
				<div class=" d-flex justify-end py-2">
					<div class="d-flex align-center mr-5 gap-1 nobr">
						Page: <b>{{ pagination.page }} / {{ pagination.pages }}</b>
					</div>
					<div class="d-flex align-center mr-5 gap-1 nobr">
						Rows per page:
						<v-select v-model="pagination.pageSize" :items="[5, 10, 20, 'All']" dense hide-details
							style="width: 50px" class="caption" />
					</div>
					<div style="position: relative">
						<v-pagination
							v-model="pagination.page"
							:length="pagination.pages"
							:total-visible="0"
						/>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import moment from "moment"

export default {
	title: 'Billing Report',
	data: () => ({
		selectedId: null,
		report: [],
		billableTypeTotals: [],
		filters: {
			startDate: moment().startOf('year').format('YYYY-MM-DD'),
			endDate: moment().endOf('year').format('YYYY-MM-DD'),
			billables: [],
			billableTypes: [],
			users: [],
			customers: [],
			includeWithoutBillable: false,
			includeBillablesOnZero: false,
		},
		pagination: {
			page: 1,
			pageSize: 10,
			pages: 1,
		},
		billables: [],
		billableTypes: [],
		users: [],
		customers: [],

		fixTotals: true,
	}),
	created() {
		this.$q.log(4, 54, 3, 'NA')

		this.$http.get('api/enums/admin/billable/type')
			.then(res => {
				this.billableTypes = res.data
			})
			.catch(error => {
				console.error(error)
				this.$root.error('Failed to fetch billable types')
			})
		this.$http.get('api/billable')
			.then(res => {
				this.billables = res.data
			})
			.catch(error => {
				console.error(error)
				this.$root.error('Failed to fetch billables')
			})
		this.$http.get('api/user?onlyInterns=true')
			.then(res => {
				this.users = res.data
			})
			.catch(error => {
				console.error(error)
				this.$root.error('Failed to fetch users')
			})
		this.$http.get('api/customer')
			.then(res => {
				this.customers = res.data
			})
			.catch(error => {
				console.error(error)
				this.$root.error('Failed to fetch customers')
			})

		this.getReport()
	},
	computed: {
		shownLines() {
			let size = this.pagination.pageSize

			if(size == 'All') size = this.report.length

			let start = (this.pagination.page - 1) * size
			let end = start + size
			return this.report.slice(start, end)
		},
		dates() {
			if (!this.report.length) return []

			return this.report[0].periods
				.map(x => {
					let m = moment(x.period)
					m.isTotal = x.isTotal
					return m
				})
		},
	},
	methods: {
		getReport() {
			this.$http.post('api/admin/billingReport', this.filters)
				.then(res => {
					this.report = res.data.lines
					this.billableTypeTotals = res.data.billableTypeTotals
					if(this.pagination.pageSize == 'All')
						this.pagination.pages = 1
					else
						this.pagination.pages = Math.ceil(this.report.length / this.pagination.pageSize)
					this.pagination.page = 1
				})
				.catch(err => {
					console.error(err)
					this.$root.error('Error getting report')
				})
		},
		doHighlight(id){
			if(id == this.selectedId)
				this.selectedId = null
			else
				this.selectedId = id
		},
	},
	watch: {
		'pagination.pageSize'(v){
			if(v == 'All'){
				this.pagination.pages = 1
			}
			else {
				this.pagination.pages = Math.ceil(this.report.length / this.pagination.pageSize)
			}
		}
	}
}
</script>

<style lang="scss" scoped>
.highlighted {
	background-color: #c4dcec !important;
	td, th {
		background-color: #c4dcec !important;
	}
}

tr.sticky-header{
	th:nth-child(1),
	th:nth-child(2),
	th:nth-child(3),
	td:nth-child(1),
	td:nth-child(3),
	td:nth-child(2) {
		position: sticky;
	}
	&:first-child {
		th:nth-child(1),
		th:nth-child(2),
		th:nth-child(3) {
			z-index: 3;
		}
	}
	th:nth-child(1), td:nth-child(1) {
		left: 0;
	}
	th:nth-child(2), td:nth-child(2) {
		left: 128px;
	}
	th:nth-child(3), td:nth-child(3), td.third {
		left: 178px;

		&::after {
			content: "";
			position: absolute;
			right: -1px; top: 0;
			height: 100%;
			width: 1px;
			background: var(--v-lightgray-lighten);
		}
	}
}

table.fix-totals {
	.fixed-total-header,
	.fixed-total-subheader-1,
	.fixed-total-subheader-2,
	.fixed-total-subheader-3,
	.fixed-total-cell-1,
	.fixed-total-cell-2,
	.fixed-total-cell-3,
	.fixed-total-cell-4,
	.fixed-total-cell-5,
	.fixed-total-cell-6
	{
		position: sticky;
		z-index: 3;
		border-right: none !important;
		border-left: none !important;
		&::after {
			content: "";
			position: absolute;
			bottom: 0; right: 0;
			width: 1px;
			height: 100%;
			background: var(--v-lightgray-lighten);
		}
	}
	.fixed-total-header { left: 289px }
	.fixed-total-subheader-1 { left: 289px }
	.fixed-total-subheader-2 { left: calc(289px + 110px*2 + 1px) }
	.fixed-total-subheader-3 { left: calc(289px + 110px*5 + 1px) }
	.fixed-total-cell-1 { left: 289px }
	.fixed-total-cell-2 { left: calc(289px + 110px + 1px) }
	.fixed-total-cell-3 { left: calc(289px + 110px*2 + 1px) }
	.fixed-total-cell-4 { left: calc(289px + 110px*3 + 1px) }
	.fixed-total-cell-5 { left: calc(289px + 110px*4 + 1px) }
	.fixed-total-cell-6 { left: calc(289px + 110px*5 + 1px) }
}

table, th, td {
	border: 1px solid var(--v-lightgray-lighten);
  border-collapse: collapse;
	background: var(--v-newDesignBackground-base);
}

table tr{
	background: var(--v-newDesignBackground-base);
	&.colored-header{
		border-top: 1px solid var(--v-lightgray-lighten);
		th:nth-child(odd) {
			background: var(--v-coloredBack-base) !important;
		}
		th {
			width: 110px;
		}
	}
}

tbody {
	tr:first-child, tr:nth-child(2), tr:nth-child(3), .customer-header {
		position: sticky;
	}
	tr:first-child {
		top: -1px;
		&::after {
			content: "";
			position: absolute;
			bottom: -1px; left: 0;
			width: 100%;
			height: 1px;
			background: var(--v-lightgray-lighten);
		}
		z-index: 2;
	}
	tr:nth-child(2) {
		top: 20px;
		z-index: 1;
		&::after {
			content: "";
			position: absolute;
			bottom: -1px; left: 0;
			width: 100%;
			height: 1px;
			background: var(--v-lightgray-lighten);
		}
	}
	tr:nth-child(3) {
		top: 40px;
		z-index: 1;
		&::after {
			content: "";
			position: absolute;
			bottom: -1px; left: 0;
			width: 100%;
			height: 1px;
			background: var(--v-lightgray-lighten);
		}
	}
	.customer-header {
		top: 58px;
		z-index: 2;

		&::after {
			content: "";
			position: absolute;
			top: 1px; left: 0;
			width: 100%; height: calc(100% - 1px);
			background: transparent;
			border-top: 1px solid var(--v-lightgray-lighten);
			border-bottom: 1px solid var(--v-lightgray-lighten);
			pointer-events: none;
		}
	}
	.billable-totals {
		th, td {
			background: var(--v-background-lighten3);
		}
	}
}

tfoot {
	border-top: 1px solid var(--v-lightgray-lighten);
}

tr:not(.highlighted, .not-hover):hover {
	background: var(--v-lightgray-lighten2);

	> th, > td {
		background: var(--v-lightgray-lighten2) !important;
	}
}

.td-nb {
	border: none !important;
}
.td-nb-y {
	border-top: none !important;
	border-bottom: none !important;
}
.td-nb-x {
	border-left: none !important;
	border-right: none !important;
}
.td-nb-l {
	border-left: none !important;
}
.td-nb-r {
	border-right: none !important;
}
.td-br {
	border-right: 1px solid var(--v-lightgray-lighten) !important;
}
.td-b-x {
	border-left: 1px solid var(--v-lightgray-lighten) !important;
	border-right: 1px solid var(--v-lightgray-lighten) !important;
}
.td-bb::before{
	content: "";
	position: absolute;
	bottom: -1px; left: 0;
	width: 100%;
	height: 1px;
	background: var(--v-lightgray-lighten);
}

tr > td.sticky,
tr > th.sticky {
	position: sticky !important;
	left: 0;
	z-index: 1;
	background: var(--v-newDesignBackground-base);
  border-collapse: separate;
	border-spacing: 0;
}

.quantity-cell-customer {
	text-align: center;
	height: 32px;
	
	> * > * {
		padding-right: 5px;
	}

	> div {
		width: 110px;
		height: 32px;
		line-height: 32px;
	}
}

.chart::-webkit-scrollbar {
	height: 8px;
	background: var(--v-newDesignBackground-base);
	border-radius: 15px;
}

.chart::-webkit-scrollbar-thumb {
	background: var(--v-background-lighten2);
	border: 4px solid transparent;
	border-radius: 8px;

	&:hover {
		background: var(--v-coloredBack-base);
	}
}

.chart {
	max-height: calc(100vh - 20px);
	width: calc(100vw - 190px);
	overflow: scroll;
	font-size: .8em;
}

.header {
	position: sticky;
	top: 0;
	z-index: 2;
}

.customer-header-cont {
	padding: 10px 10px;

	.customer-header {
		padding: 5px 5px 5px 10px !important;
	}
}

.line {
	display: flex;
	width: max-content;
	width: 100%;
	background: var(--v-newDesignBackground-base);

	&:hover {
		background: var(--v-lightgray-lighten2);
	}

	.sticky {
		display: flex;
		position: sticky;
		align-items: flex-end;
		background: inherit;
		left: 0;
		z-index: 1;
		border-right: 2px solid var(--v-lightgray-lighten);
		overflow: hidden;
		width: 325px;

		.st {
			display: flex;
			align-items: center;
			padding: 10px 5px 10px 10px;

			div {
				white-space: nowrap;
				overflow: hidden;
				text-overflow: ellipsis;
			}
		}

		.head {
			border-bottom: 2px solid var(--v-lightgray-lighten);
		}
	}

	.date {
		width: 670px;
		padding-top: 5px;
		border: 1px solid var(--v-lightgray-lighten);
		background: var(--v-newDesignBackground-base);
		border-bottom-width: 2px;
		border-top: 0;

		&.no-border {
			border-left: none !important;
		}

		.head {
			font-size: 1.2em;
		}
	}

	.period-grid {
		display: grid;
		grid-template-columns: repeat(6, 1fr);
		width: 670px;

		::v-deep>* {
			padding-right: 5px !important;
		}
	}
}

.data-container {
	.customer-lines {
		border-bottom: 1px solid var(--v-lightgray-lighten);

		.line {
			contain: paint;
		}
	}
}

.pagination-container {
	position: sticky;
	left: 0px;
}

.non-billable{
	font-style: italic;
}

::v-deep .v-pagination__navigation {
	box-shadow: none !important;
	background: transparent !important;
}
</style>