var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"pa-3 new-back fill-height"},[_c('filter-search-input',{attrs:{"appliedFilters":_vm.appliedFilters,"outlined":"","dense":""},on:{"input":_vm.searchChanged},scopedSlots:_vm._u([{key:"append",fn:function(){return [_c('v-row',{staticClass:"mb-n5 mr-1",attrs:{"justify":"end"}},[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('div',_vm._g({staticClass:"excel-btn",attrs:{"small":"","text":""},on:{"click":_vm.exportToExcel}},on),[_c('img',{attrs:{"src":_vm.excelIcon}})])]}}])},[_c('span',[_vm._v("Download Excel")])]),_c('v-menu',{attrs:{"close-on-content-click":false,"left":"","offset-x":"","z-index":"200"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('div',[_c('v-btn',_vm._g({attrs:{"color":"primary","small":"","fab":""}},on),[_c('v-icon',[_vm._v("mdi-table-eye")])],1)],1)]}}]),model:{value:(_vm.menu),callback:function ($$v) {_vm.menu=$$v},expression:"menu"}},[_c('v-card',{attrs:{"color":"high-back","width":"200"}},[_c('v-list',{attrs:{"color":"high-back","dense":""}},_vm._l((_vm.headers),function(header){return _c('v-list-item',{directives:[{name:"show",rawName:"v-show",value:(header.text),expression:"header.text"}],key:header.value},[_c('v-list-item-action',[_c('v-checkbox',{attrs:{"dense":"","color":"primary"},model:{value:(_vm.showCol[header.value]),callback:function ($$v) {_vm.$set(_vm.showCol, header.value, $$v)},expression:"showCol[header.value]"}})],1),_c('v-list-item-title',[_vm._v(" "+_vm._s(header.text)+" ")])],1)}),1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"primary","text":""},on:{"click":function($event){_vm.menu = false}}},[_vm._v("Close")])],1)],1)],1)],1)]},proxy:true}]),model:{value:(_vm.filters.search),callback:function ($$v) {_vm.$set(_vm.filters, "search", $$v)},expression:"filters.search"}},[_c('v-select',{attrs:{"items":_vm.projectStatuses,"label":"Project Status","item-value":"id","item-text":"name","outlined":"","multiple":"","hide-details":"","dense":"","clearable":""},on:{"change":_vm.fetchWorkItems},model:{value:(_vm.filters.projectStatuses),callback:function ($$v) {_vm.$set(_vm.filters, "projectStatuses", $$v)},expression:"filters.projectStatuses"}}),_c('v-autocomplete',{attrs:{"items":_vm.shownProjects,"label":"Project","item-value":"id","item-text":"name","filter":function (item, queryText, itemText) { return (item.customerProduct + ': ' + item.name).toLocaleLowerCase().indexOf(queryText.toLocaleLowerCase()) > -1; },"outlined":"","multiple":"","hide-details":"","dense":"","clearable":""},on:{"change":_vm.fetchWorkItems},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
var item = ref.item;
return [_c('category-chip',{attrs:{"small":"","code":item.customerProduct,"text":item.name}})]}},{key:"item",fn:function(ref){
var item = ref.item;
var attrs = ref.attrs;
return [_c('v-list-item-action',[_c('v-checkbox',{model:{value:(attrs.inputValue),callback:function ($$v) {_vm.$set(attrs, "inputValue", $$v)},expression:"attrs.inputValue"}})],1),_c('v-list-item-content',[_c('v-list-item-title',[_c('span',[_vm._v(_vm._s(item.name))])]),_c('v-list-item-subtitle',[_vm._v(_vm._s(item.customerProduct))])],1)]}}]),model:{value:(_vm.filters.projects),callback:function ($$v) {_vm.$set(_vm.filters, "projects", $$v)},expression:"filters.projects"}}),_c('v-autocomplete',{attrs:{"items":_vm.statuses,"outlined":"","multiple":"","hide-details":"","dense":"","clearable":"","label":"Status"},on:{"change":_vm.fetchWorkItems},model:{value:(_vm.filters.statusNames),callback:function ($$v) {_vm.$set(_vm.filters, "statusNames", $$v)},expression:"filters.statusNames"}}),_c('v-select',{attrs:{"items":_vm.types,"label":"Type","item-value":"value","item-text":"displayName","outlined":"","dense":"","hide-details":"","multiple":"","clearable":""},on:{"change":_vm.fetchWorkItems},model:{value:(_vm.filters.types),callback:function ($$v) {_vm.$set(_vm.filters, "types", $$v)},expression:"filters.types"}}),_c('v-autocomplete',{attrs:{"items":_vm.priorities,"outlined":"","multiple":"","hide-details":"","dense":"","clearable":"","label":"Priority","item-value":"value","item-text":"displayName"},on:{"change":_vm.fetchWorkItems},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var item = ref.item;
var attrs = ref.attrs;
return [_c('v-list-item-action',[_c('v-checkbox',{model:{value:(attrs.inputValue),callback:function ($$v) {_vm.$set(attrs, "inputValue", $$v)},expression:"attrs.inputValue"}})],1),_c('v-list-item-content',[_c('v-list-item-title',[_c('v-icon',{staticClass:"mr-2",attrs:{"color":item.color}},[_vm._v(_vm._s(item.iconClass))]),_vm._v(" "+_vm._s(item.displayName)+" ")],1)],1)]}},{key:"selection",fn:function(ref){
var attrs = ref.attrs;
var item = ref.item;
var parent = ref.parent;
var selected = ref.selected;
return [(item === Object(item))?_c('v-chip',_vm._b({staticClass:"accent--lighten",attrs:{"input-value":selected,"label":"","small":""}},'v-chip',attrs,false),[_c('span',{staticClass:"pr-2"},[_c('v-icon',{attrs:{"color":item.color}},[_vm._v(_vm._s(item.iconClass))]),_vm._v(" "+_vm._s(item.displayName)+" ")],1),_c('v-icon',{attrs:{"small":""},on:{"click":function($event){return parent.selectItem(item)}}},[_vm._v("close")])],1):_vm._e()]}}]),model:{value:(_vm.filters.priorities),callback:function ($$v) {_vm.$set(_vm.filters, "priorities", $$v)},expression:"filters.priorities"}}),_c('v-autocomplete',{attrs:{"items":_vm.shownProjects,"label":"Related Project","item-text":"name","item-value":"id","outlined":"","dense":"","hide-details":"","multiple":""},on:{"change":_vm.fetchWorkItems},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var item = ref.item;
var attrs = ref.attrs;
return [_c('v-list-item-action',[_c('v-checkbox',{model:{value:(attrs.inputValue),callback:function ($$v) {_vm.$set(attrs, "inputValue", $$v)},expression:"attrs.inputValue"}})],1),_c('v-list-item-content',{staticClass:"pa-0"},[_c('v-list-item',{staticClass:"pa-0"},[_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(_vm._s(item.name))]),_c('v-list-item-subtitle',[_vm._v(_vm._s(item.smallCode))])],1)],1)],1)]}},{key:"selection",fn:function(ref){
var attrs = ref.attrs;
var item = ref.item;
var parent = ref.parent;
var selected = ref.selected;
return [(item === Object(item))?_c('v-chip',_vm._b({staticClass:"accent--lighten",attrs:{"input-value":selected,"label":"","small":""}},'v-chip',attrs,false),[_c('span',{staticClass:"pr-2"},[_vm._v(" "+_vm._s(item.name)+" ")]),_c('v-icon',{attrs:{"small":""},on:{"click":function($event){return parent.selectItem(item)}}},[_vm._v("close")])],1):_vm._e()]}}]),model:{value:(_vm.filters.relatedProjectId),callback:function ($$v) {_vm.$set(_vm.filters, "relatedProjectId", $$v)},expression:"filters.relatedProjectId"}}),_c('v-text-field',{attrs:{"label":"Related Work Item","outlined":"","dense":"","hide-details":""},on:{"input":_vm.linkedWorkItemCodeChanged},model:{value:(_vm.filters.linkedWorkItemCode),callback:function ($$v) {_vm.$set(_vm.filters, "linkedWorkItemCode", $$v)},expression:"filters.linkedWorkItemCode"}}),_c('div',{staticClass:"text-right"},[_c('v-autocomplete',{attrs:{"items":_vm.users,"outlined":"","multiple":"","hide-details":"","dense":"","label":"Assigned to","item-value":"id","item-text":"blueTag","filter":function (item, queryText, itemText) { return _vm.normalizeIncludes(item.blueTag + ' ' + item.fullName, queryText); }},on:{"change":_vm.fetchWorkItems},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var item = ref.item;
var attrs = ref.attrs;
return [_c('v-list-item-action',[_c('v-checkbox',{model:{value:(attrs.inputValue),callback:function ($$v) {_vm.$set(attrs, "inputValue", $$v)},expression:"attrs.inputValue"}})],1),_c('v-list-item-content',{staticClass:"pa-0"},[_c('v-list-item',{staticClass:"pa-0"},[_c('v-list-item-avatar',{staticClass:"headline font-weight-light white--text",attrs:{"color":"indigo"}},[_vm._v(" "+_vm._s((item.blueTag || '-').charAt(0))+" ")]),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(_vm._s(item.blueTag))]),_c('v-list-item-subtitle',[_vm._v(_vm._s(item.fullName))])],1)],1)],1)]}},{key:"selection",fn:function(ref){
var attrs = ref.attrs;
var item = ref.item;
var parent = ref.parent;
var selected = ref.selected;
return [(item === Object(item))?_c('v-chip',_vm._b({attrs:{"color":"accent","input-value":selected,"label":"","small":""}},'v-chip',attrs,false),[_c('span',{staticClass:"pr-2"},[_vm._v(" "+_vm._s(item.blueTag)+" ")]),_c('v-icon',{attrs:{"small":""},on:{"click":function($event){return parent.selectItem(item)}}},[_vm._v("close")])],1):_vm._e()]}}]),model:{value:(_vm.filters.assignedTo),callback:function ($$v) {_vm.$set(_vm.filters, "assignedTo", $$v)},expression:"filters.assignedTo"}}),_c('v-btn',{staticStyle:{"text-transform":"none"},attrs:{"color":"primary","text":""},on:{"click":_vm.setAssignedToMe}},[_vm._v("Assigned to me")])],1),_c('div',{staticClass:"text-right"},[_c('v-autocomplete',{attrs:{"items":_vm.users,"outlined":"","multiple":"","hide-details":"","dense":"","label":"Reported by","item-value":"id","item-text":"blueTag","filter":function (item, queryText, itemText) { return _vm.normalizeIncludes(item.blueTag + ' ' + item.fullName, queryText); }},on:{"change":_vm.fetchWorkItems},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var item = ref.item;
var attrs = ref.attrs;
return [_c('v-list-item-action',[_c('v-checkbox',{model:{value:(attrs.inputValue),callback:function ($$v) {_vm.$set(attrs, "inputValue", $$v)},expression:"attrs.inputValue"}})],1),_c('v-list-item-content',{staticClass:"pa-0"},[_c('v-list-item',{staticClass:"pa-0"},[_c('v-list-item-avatar',{staticClass:"headline font-weight-light white--text",attrs:{"color":"indigo"}},[_vm._v(" "+_vm._s((item.blueTag || '-').charAt(0))+" ")]),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(_vm._s(item.blueTag))]),_c('v-list-item-subtitle',[_vm._v(_vm._s(item.fullName))])],1)],1)],1)]}},{key:"selection",fn:function(ref){
var attrs = ref.attrs;
var item = ref.item;
var parent = ref.parent;
var selected = ref.selected;
return [(item === Object(item))?_c('v-chip',_vm._b({attrs:{"color":"accent","input-value":selected,"label":"","small":""}},'v-chip',attrs,false),[_c('span',{staticClass:"pr-2"},[_vm._v(" "+_vm._s(item.blueTag)+" ")]),_c('v-icon',{attrs:{"small":""},on:{"click":function($event){return parent.selectItem(item)}}},[_vm._v("close")])],1):_vm._e()]}}]),model:{value:(_vm.filters.reportedBy),callback:function ($$v) {_vm.$set(_vm.filters, "reportedBy", $$v)},expression:"filters.reportedBy"}}),_c('v-btn',{staticStyle:{"text-transform":"none"},attrs:{"color":"primary","text":""},on:{"click":_vm.setReportedByMe}},[_vm._v("Reported by me")])],1)],1),_c('v-data-table',{staticClass:"new-back lighten2 rounded-lg bordered overflow-hidden",attrs:{"headers":_vm.shownHeaders,"items":_vm.workItems,"item-class":function () { return 'cursor-pointer'; },"items-per-page":_vm.pagination.itemsPerPage,"page":_vm.pagination.page,"server-items-length":_vm.pagination.totalItems,"sort-by":_vm.pagination.sortBy,"sort-desc":_vm.pagination.sortDesc},on:{"click:row":function($event){return _vm.openWorkItemDetailsDialog($event.id)},"pagination":_vm.fetchWorkItems,"update:itemsPerPage":function($event){return _vm.$set(_vm.pagination, "itemsPerPage", $event)},"update:items-per-page":function($event){return _vm.$set(_vm.pagination, "itemsPerPage", $event)},"update:page":function($event){return _vm.$set(_vm.pagination, "page", $event)},"update:sortBy":function($event){return _vm.$set(_vm.pagination, "sortBy", $event)},"update:sort-by":[function($event){return _vm.$set(_vm.pagination, "sortBy", $event)},_vm.fetchWorkItems],"update:sortDesc":function($event){return _vm.$set(_vm.pagination, "sortDesc", $event)},"update:sort-desc":[function($event){return _vm.$set(_vm.pagination, "sortDesc", $event)},_vm.fetchWorkItems]},scopedSlots:_vm._u([{key:"item.code",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"text-no-wrap"},[_vm._v(" "+_vm._s(item.code)+" ")])]}},{key:"item.priority",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-icon',_vm._g({attrs:{"color":item.priorityColor}},on),[_vm._v(_vm._s(item.priorityIconClass))])]}}],null,true)},[_c('span',{staticClass:"text-capitalize",domProps:{"textContent":_vm._s(item.priorityDisplayName)}})])]}},{key:"item.type",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"dark":"","elevation":0,"fab":"","x-small":"","color":item.typeColor}},on),[_c('v-icon',[_vm._v(_vm._s(item.typeIconClass))])],1)]}}],null,true)},[_c('span',{domProps:{"textContent":_vm._s(item.typeDisplayName)}})])]}},{key:"item.statusName",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"status-container",style:(("background:" + (item.statusColor) + ";"))},[(item.statusIconClass)?_c('v-icon',{attrs:{"color":"white","small":""}},[_vm._v(_vm._s(item.statusIconClass))]):_vm._e(),_vm._v(" "+_vm._s(_vm.setMaxText(item.statusName, 25))+" ")],1)]}},{key:"item.tags",fn:function(ref){
var item = ref.item;
return [_c('div',_vm._l((item.tags),function(tag,ix){return _c('v-chip',{key:ix,staticClass:"mr-1",style:(("color: " + (_vm.isBlackText(tag.tagColor) ? 'white' : 'black'))),attrs:{"x-small":"","dark":"","color":tag.tagColor}},[_vm._v(" "+_vm._s(tag.tagName)+" ")])}),1)]}},{key:"item.linkedWorkItems",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex flex-column"},_vm._l((item.linkedWorkItems),function(linked){return _c('span',{key:linked.id,staticClass:"nobr"},[_vm._v(" "+_vm._s(linked.relatedWorkItemCode)+" ")])}),0)]}},{key:"item.assignedToBlueTag",fn:function(ref){
var item = ref.item;
return [(item.assignedToId)?_c('span',[_vm._v(_vm._s(item.assignedToBlueTag))]):_c('i',[_vm._v("Unassigned")])]}},{key:"item.reportedByBlueTag",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(item.reportedByBlueTag || item.reportedByFullName))])]}},{key:"item.createdAt",fn:function(ref){
var item = ref.item;
return [_c('small',[_vm._v(_vm._s(_vm._f("formatDateTime")(item.createdAt)))])]}}],null,true)}),_c('work-item-details-dialog',{ref:"workItemDetailsDialog"})],1)}
var staticRenderFns = []

export { render, staticRenderFns }