<template>
	<div class="new-back fill-height py-5 px-3">
		<filter-search-input v-model="search" :applied-filters="appliedFilters" outlined dense>
			<template #append>
				<v-btn-toggle v-model="timeUnit" tile mandatory group color="primary">
					<v-btn small :value="1">Days</v-btn>
					<v-btn small :value="2">Weeks</v-btn>
					<v-btn small :value="5">Biweekly</v-btn>
					<v-btn small :value="3">Months</v-btn>
				</v-btn-toggle>
			</template>
			<q-date-picker
				v-model="filters.startDate"
				outlined hide-details inner dense
				label="Start Date"
				:type="timeUnit === 3 ? 'month' : 'date'"
			/>
			<q-date-picker
				v-model="filters.endDate"
				outlined hide-details inner dense
				label="End Date"
				:type="timeUnit === 3 ? 'month' : 'date'"
			/>
			<q-user-autocomplete
				v-model="filters.users"
				outlined hide-details dense multiple clearable
				label="User"
				:items="users"
			/>
			<v-autocomplete
				v-model="filters.billables"
				outlined hide-details dense multiple clearable
				label="Billable" item-text="code" item-value="id"
				:items="billables"
			/>
			<v-autocomplete
				v-model="filters.projects"
				outlined hide-details dense multiple clearable
				label="Project" item-text="name" item-value="id"
				:items="projects"
			/>
			<v-autocomplete
				v-model="filters.customers"
				outlined hide-details dense multiple clearable
				label="Customer" item-text="name" item-value="id"
				:items="customers"
			/>
			<div>
				<v-checkbox
					v-model="filters.includeNoProject"
					hide-details dense
					label="Include activities without project"
				/>
				<v-checkbox
					v-model="roundUp"
					hide-details dense
					label="Round up"
				/>
			</div>
		</filter-search-input>

		<v-data-table
			class="rounded-lg bordered new-back lighten2"
			style="width: var(--view-width)"
			:items="items" :headers="shownHeaders"
			@pagination="fetchReport"
			:items-per-page.sync="pagination.pageSize"
			:page.sync="pagination.page"
			:server-items-length="pagination.totalItems"
			disable-sort
		>
			<template #[`item.projectName`]="{ item }">
				<div class="px-4" style="width: 200px">{{ item.projectName }}</div>
			</template>
			<template #[`item.customerName`]="{ item }">
				<div class="px-4" style="width: 170px">{{ item.customerName }}</div>
			</template>
			<template #[`item.billableCode`]="{ item }">
				<div class="px-4" style="width: 140px">{{ item.billableCode }}</div>
			</template>
			<template #[`item.userBlueTag`]="{ item }">
				<div class="px-4" style="width: 110px">{{ item.userBlueTag }}</div>
			</template>
			<template v-for="ix in timeColumns" #[getHeaderSlotName(ix-1)]="{ item }">
				<span v-if="!item.executedHours[ix-1]" :key="ix + 'a'">-</span>
				<span v-else-if="!roundUp" :key="ix + 'b'">{{ item.executedHours[ix-1] | number(2) }}</span>
				<span v-else :key="ix">{{ item.executedHours[ix-1] | roundUp }}</span>
			</template>
		</v-data-table>
	</div>
</template>

<script>
import moment from 'moment'

export default {
	data: () => ({
		search: '',
		searchTimer: null,
		filters: {
			users: [],
			billables: [],
			projects: [],
			customers: [],
			includeNoProject: false,

			startDate: moment().startOf('year').format('YYYY-MM-DD'),
			endDate: moment().startOf('day').format('YYYY-MM-DD'),
		},
		roundUp: false,
		headers: [
			{ text: 'Project', value: 'projectName' },
			{ text: 'Customer', value: 'customerName' },
			{ text: 'Billable', value: 'billableCode' },
			{ text: 'BlueTag', value: 'userBlueTag' },
		],
		pagination: {
			page: 1,
			pageSize: 10,
			totalItems: 0,
			sortBy: '',
			sortDesc: false,
		},
		timeUnit: 3,
		items: [],
		users: [],
		billables: [],
		projects: [],
		customers: [],
		timeColumns: 0,
	}),
	created() {
		document.title = 'Executed Hours Report'
		this.init()
	},
	computed: {
		appliedFilters(){
			return this.countActiveFilters(this.filters, ['search', 'startDate', 'endDate'])
		},
		shownHeaders() {
			let timeHeaders = [];
			for (let i = 0; i < this.timeColumns; i++) {
				let text = '';
				if (this.timeUnit === 1)
					text = moment(this.filters.startDate).add(i, 'days').format('DD/MM/YYYY');
				else if (this.timeUnit === 2)
					text = moment(this.filters.startDate).add(i, 'weeks').format('W, YYYY');
				else if (this.timeUnit === 3)
					text = moment(this.filters.startDate).add(i, 'months').format('MMM, YYYY');
				else if (this.timeUnit === 5) {
					const startDate = moment(this.filters.startDate);
					const isFirstHalfOfMonth = startDate.date() < 16;
					const addMonths = isFirstHalfOfMonth ? Math.floor(i / 2) : Math.ceil(i / 2);
					const prefix = isFirstHalfOfMonth ? (i % 2 === 0 ? '1st' : '2nd') : (i % 2 === 0 ? '2nd' : '1st')
					
					const biweeklyStartDate = startDate.clone().add(addMonths, 'months');
					const monthYear = biweeklyStartDate.format('MMM, YYYY');
					text = `${prefix} ${monthYear}`;
        }

				timeHeaders.push({
					text,
					value: `executedHours.${i}`,
					align: 'center',
					width: '100px',
				})
			}

			return [
				...this.headers,
				...timeHeaders,
			]
		},
	},
	methods: {
		init() {
			this.fetchReport()

			this.$http.get('api/user?onlyInterns=true&usersOnly=true')
				.then(res => {
					this.users = res.data
				})
				.catch(err => {
					console.error(err)
					this.$root.error('Failed to load users')
				})

			this.$http.get('api/billable')
				.then(res => {
					this.billables = res.data
				})
				.catch(err => {
					console.error(err)
					this.$root.error('Failed to load billables')
				})
			
			this.$http.get('api/project?all=true')
				.then(res => {
					this.projects = res.data
				})
				.catch(err => {
					console.error(err)
					this.$root.error('Failed to load projects')
				})

			this.$http.get('api/customer')
				.then(res => {
					this.customers = res.data
				})
				.catch(err => {
					console.error(err)
					this.$root.error('Failed to load customers')
				})
		},
		fetchReport() {
			this.$http.post('api/admin/executedHours', {
				...this.filters,
				...this.pagination,
				pageSize: this.pagination.pageSize !== 'all' ? this.pagination.pageSize : -1,
				timeUnit: this.timeUnit,
				search: this.search,
			})
				.then(res => {
					this.items = res.data.items
					this.pagination.totalItems = res.data.totalItems
					if(this.items.length > 0)
						this.timeColumns = this.items[0].executedHours.length
					else
						this.timeColumns = 0
				})
				.catch(err => {
					console.error(err)
					this.$root.error('Failed to load executed hours report')
				})
		},
		getHeaderSlotName(ix) {
			return `item.executedHours.${ix}`
		},
	},
	watch: {
		search() {
			clearTimeout(this.searchTimer)
			this.searchTimer = setTimeout(() => {
				this.pagination.page = 1
				this.fetchReport()
			}, 500)
		},
		filters: {
			handler() {
				this.pagination.page = 1
				this.fetchReport()
			},
			deep: true,
		},
		timeUnit() {
			this.pagination.page = 1
			this.fetchReport()
		},
	},
}
</script>

<style lang="scss" scoped>
::v-deep {
	table > tbody > tr > td:nth-child(1),
	table > tbody > tr > td:nth-child(2),
	table > tbody > tr > td:nth-child(3),
	table > tbody > tr > td:nth-child(4)
	{
		padding: 0;
	}
	.v-data-table{
		&.theme--light {
			tbody > tr, thead > tr {
				&:hover {
					td, th {
						background: #eeeeee !important;
					}
				}
			}
		}
		&.theme--dark {
			> tbody > tr, > thead > tr {
				&:hover {
					td, th {
						background: #616161 !important;
					}
				}
			}
		}
	}

	table > tbody > tr, table > thead > tr {
		td:nth-child(1),
		th:nth-child(1),
		td:nth-child(2),
		th:nth-child(2),
		td:nth-child(3),
		th:nth-child(3),
		td:nth-child(4),
		th:nth-child(4) {
			position: sticky !important;
			z-index: 1;
			background: var(--v-newDesignBackground-lighten2);

			> div {
				overflow: hidden;
			}
		}
	}
	
	table > tbody > tr > td:nth-child(1),
	table > thead > tr > th:nth-child(1) {
		left: 0;
		width: 200px;
	}
	
	table > tbody > tr > td:nth-child(2),
	table > thead > tr > th:nth-child(2) {
		left: 200px;
		width: 170px;
	}
	
	table > tbody > tr > td:nth-child(3),
	table > thead > tr > th:nth-child(3) {
		left: calc(200px + 170px);
		width: 140px;
	}
	
	table > tbody > tr > td:nth-child(4),
	table > thead > tr > th:nth-child(4) {
		left: calc(200px + 170px + 140px);
		width: 110px;
	}
}
</style>