<template>
  <v-container grid-list-xl fluid class="new-back fill-height">
    <v-layout row wrap>
      <v-flex v-if="permissions.add" sm12>
          <h1>
          <v-btn class="ml-0 ma-0 add-btn" rounded color="primary" @click="openNewCategoryDialog()">
            <v-icon dark>add</v-icon>
            Category
          </v-btn>
          <v-btn class="ml-4 ma-0 add-btn" rounded color="primary" @click="openNewSubcategoryDialog()">
            <v-icon dark>add</v-icon>
            Subcategory
          </v-btn>
          </h1>
      </v-flex>      
      <v-flex sm12>

        <v-btn small @click="vo.showDescriptions = !vo.showDescriptions">
          <span v-if="!vo.showDescriptions">Show descriptions</span>
          <span v-if="vo.showDescriptions">Hide descriptions</span>
        </v-btn>
      </v-flex>      
      <v-flex sm12>
        <v-data-table
          :items="categories"
          class="new-back lighten2 bordered rounded-lg">
          <template v-slot:header="props">
            <th class="bordered py-1">Category</th>
            <th class="bordered py-1">Subcategory</th>
          </template>
          <template v-slot:item="props">
            <tr>

              <td class="bordered" :style="{color: props.item.active ? null: '#999'}">
                  <v-btn v-if="permissions.edit" @click="deleteCategory(props.item.id)" depressed outlined icon fab color="primary" x-small >
                    <v-icon>delete</v-icon>
                  </v-btn>
                  <v-btn v-if="permissions.edit" rounded fab depressed x-small color="primary" @click="openEditCategoryDialog(props.item)">
                    <v-icon>edit</v-icon>
                  </v-btn>
                  <span :class="{default: props.item.isDefault}">
                    <category-chip :text="props.item.name" :key="props.item.id"></category-chip>
                  </span>
                  <div v-if="vo.showDescriptions">
                      <i style="white-space: pre-line">{{ props.item.description }}</i>
                  </div>
              </td>
              <td class="subcategories bordered">
                  <table class="table table-striped table-condensed table-hover">
                      <tr v-for="subcategory in props.item.subcategories" :key="subcategory.id">
                          <td :class="{inactive: !subcategory.active}">
                              <v-btn v-if="permissions.edit" @click="deleteSubcategory(subcategory.id)" depressed outlined icon fab color="primary" x-small >
                                <v-icon>delete</v-icon>
                              </v-btn>
                              <v-btn v-if="permissions.edit" rounded fab depressed color="primary" x-small @click="openEditSubcategoryDialog(subcategory)">
                                <v-icon>edit</v-icon>
                              </v-btn>
                              <span :class="{default: subcategory.isDefault}">
                                <category-chip :text="subcategory.name"></category-chip>
                              </span>
                              <div v-if="vo.showDescriptions">
                                  <i style="white-space: pre-line">{{ subcategory.description }}</i>
                              </div>
                          </td>
                      </tr>
                      <tr>
                        <p>
                          <v-btn v-if="permissions.add" class="ml-4 ma-0 add-btn" rounded small color="primary" @click="openNewSubcategoryDialog(props.item.id)">
                            <v-icon dark>add</v-icon>
                            Subcategory
                        </v-btn>
                      </p>
                    </tr>
                  </table>

              </td>
            </tr>
          </template>
        </v-data-table>

      </v-flex>
      <v-flex></v-flex>
    </v-layout>
    <v-dialog v-model="editCategoryDialog" persistent max-width="500px">
        <v-card>
            <v-card-title>
                <span class="headline">
                    Category
                </span>
                <br>
                <div class="cyan--text" v-if="!editCategory.id">New</div>
                <div class="cyan--text" v-if="editCategory.id">Edit</div>
            </v-card-title>
            <v-card-text>
              <v-layout wrap>
                <v-flex xs12>
                  <v-text-field label="Name" v-model="editCategory.name"></v-text-field>
                </v-flex>
                <v-flex xs12>
                  <v-checkbox label="Default" v-model="editCategory.isDefault"></v-checkbox>
                </v-flex>
                <v-flex xs12>
                  <v-checkbox label="Active" v-model="editCategory.active"></v-checkbox>
                </v-flex>
                <v-flex xs12>
                  <v-textarea label="Description" v-model="editCategory.description"></v-textarea>
                </v-flex>
              </v-layout>
            </v-card-text>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="blue darken-1" text @click.native="editCategoryDialog = false">Close</v-btn>
                <v-btn color="blue darken-1" text @click.native="saveCategory()">Save</v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
    <v-dialog v-model="editSubcategoryDialog" persistent max-width="500px">
        <v-card>
            <v-card-title>
                <span class="headline">
                    Subcategory
                </span>
                <br>
                <div class="cyan--text" v-if="!editSubcategory.id">New</div>
                <div class="cyan--text" v-if="editSubcategory.id">Edit</div>
            </v-card-title>
            <v-card-text>
              <v-layout wrap>
                <v-flex xs12>
                  <v-text-field label="Name" v-model="editSubcategory.name"></v-text-field>
                </v-flex>
                <v-flex xs12>
                  <v-select label="Category" v-model="editSubcategory.categoryId" :items="categories" item-text="name" item-value="id">
                     <template slot="item" slot-scope="data">
                       <v-list-item-avatar></v-list-item-avatar>
                        <v-list-item-content>
                          <v-list-item-title v-html="data.item.name"></v-list-item-title>
                        </v-list-item-content>
                      </template>
                  </v-select>
                </v-flex>
                <v-flex xs12>
                  <v-checkbox label="Default" v-model="editSubcategory.isDefault"></v-checkbox>
                </v-flex>
                <v-flex xs12>
                  <v-checkbox label="Active" v-model="editSubcategory.active"></v-checkbox>
                </v-flex>
                <v-flex xs12>
                  <v-textarea label="Description" v-model="editSubcategory.description"></v-textarea>
                </v-flex>
              </v-layout>
            </v-card-text>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="blue darken-1" text @click.native="editSubcategoryDialog = false">Close</v-btn>
                <v-btn color="blue darken-1" text @click.native="saveSubcategory()">Save</v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
  </v-container>  
  
</template>

<script>
import _ from "lodash"

export default {
  title: 'Categories',
  props: [],
  data: () => ({ 
    categories: [],
    editCategoryDialog: false,
    editCategory: {},
    editSubcategoryDialog: false,
    editSubcategory: {},
    vo: {
      showDescriptions: false,
    },

    permissions: {
      add: false,
      edit: false,
    },
  }),
  created() {
    this.$security.hasRequiredPermission('add')
      .then(res => this.permissions.add = res)
    this.$security.hasRequiredPermission('edit')
      .then(res => this.permissions.edit = res)

    this.$q.log(6, 32, 3, 'NA')
    this.init();
  },
  methods: {
    deleteCategory(categoryId) {

      this.$q.confirmDelete({
        api: `/api/category/${categoryId}`,
        messageBody: "Are you sure you want to delete this category?", 
        sucessMsg: "Category deleted",
        errorMsg: "The category was not deleted.",
      }).then(res => {
        // this.$q.removeById(this.categories, id);
        this.init(); // TODO: modify data instead, consider new default
      });
    },
    deleteSubcategory(subcategoryId) {

      this.$q.confirmDelete({
        api: `/api/subcategory/${subcategoryId}`,
        messageBody: "Are you sure you want to delete this subcategory?", 
        sucessMsg: "Subcategory deleted",
        errorMsg: "The subcategory was not deleted.",
      }).then(res => {
        // this.$q.removeById(this.categories, id);
        this.init(); // TODO: modify data instead, consider new default
      });
    },
    openNewCategoryDialog() {
        this.editCategoryDialog = true;
        this.editCategory = {
          active: true,
        };
    },
    openEditCategoryDialog(category) {
        this.editCategoryDialog = true;
        this.editCategory = _.clone(category, true);
    },
    saveCategory() {
      this.$q.save({
        api: `/api/category`,
        data: this.editCategory,
        successMsg: "Category saved.",
        afterSuccessUrl: null,
        errorMsg: "Cannot save category.",
        afterErrorUrl: null,
      }).then(res => {
        this.editCategoryDialog = false;
        // this.$q.addOrUpdateById(this.categories, res.data);
        this.init(); // TODO: modify data instead, consider new default
      });
    },
    openNewSubcategoryDialog(categoryId) {
        this.editSubcategoryDialog = true;
        this.editSubcategory = {
          active: true,
        };
        if (categoryId) {
          this.editSubcategory.categoryId = categoryId;
        }
    },
    openEditSubcategoryDialog(subcategory) {
        this.editSubcategoryDialog = true;
        this.editSubcategory = _.clone(subcategory, true);
    },
    saveSubcategory() {
      this.$q.save({
        api: `/api/subcategory`,
        data: this.editSubcategory,
        successMsg: "Subcategory saved.",
        afterSuccessUrl: null,
        errorMsg: "Cannot save subcategory.",
        afterErrorUrl: null,
      }).then(res => {
        this.editSubcategoryDialog = false;
        // this.$q.addOrUpdateById(this.subcategories, res.data);
        this.init(); // TODO: modify data instead, consider new default
      });
    },
    init() {
      this.$http.get("/api/category?getInactive=true")
        .then(res => {
          let categories = res.data;
          categories = _.orderBy(categories, ['name']);
          this.categories = categories;
        })
        .catch(res => {
          window.getApp.error("Cannot obtain categories.");
        });

    }
  }

    
}
</script>

<style scoped lang="scss">
  .inactive {
    color: #999;
  }
  .inactive ::v-deep .v-chip {
    color: #999;
  }
  .table .subcategories {
    padding: 0;
  }
  .subcategories .table {
    margin-bottom: 0;
  }
  .add-subcategory {
    margin: 5px;
  }
  .default {
    font-weight: 700;
  }
  .v-dialog .v-card {
    padding: 15px;
  }
  .headline {
    width: 100%;
  }
</style> 