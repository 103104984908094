var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('admin-change-list',{attrs:{"changes":_vm.history.changes,"item":_vm.history.item,"variant":_vm.history.type,"urls":_vm.history.urls},on:{"new-comment":_vm.addComment},model:{value:(_vm.history.show),callback:function ($$v) {_vm.$set(_vm.history, "show", $$v)},expression:"history.show"}}),_c('blue-confirm-dialog',{ref:"validateBillableDialog",scopedSlots:_vm._u([{key:"actions",fn:function(ref){
var confirm = ref.confirm;
return [_c('div',{staticClass:"flex text-right"},[_c('v-btn',{attrs:{"color":"error","outlined":"","small":""},on:{"click":function($event){_vm.lastResponse = false; confirm()}}},[_vm._v("Send to draft")]),_c('v-btn',{staticClass:"ml-2",attrs:{"color":"primary","depressed":"","small":""},on:{"click":function($event){_vm.lastResponse = true, confirm()}}},[_vm._v("Approve")])],1)]}}])}),_c('billable-close-dialog',{ref:"closeBillableDialog"}),_c('billable-delete-dialog',{ref:"deleteDialog"}),_c('billable-item-pdf-dialog',{ref:"billableItemPdfDialog"}),_c('blue-confirm-dialog',{ref:"validateBillableItemDialog",scopedSlots:_vm._u([{key:"actions",fn:function(ref){
var confirm = ref.confirm;
return [_c('div',{staticClass:"flex text-right"},[_c('v-btn',{attrs:{"color":"error","outlined":"","small":""},on:{"click":function($event){_vm.lastResponse = false; confirm()}}},[_vm._v("Send to Draft")]),_c('v-btn',{staticClass:"ml-2",attrs:{"color":"primary","depressed":"","small":""},on:{"click":function($event){_vm.lastResponse = true, confirm()}}},[_vm._v("Approve")])],1)]}}])}),_c('blue-confirm-dialog',{ref:"invoiceCancellationReason",scopedSlots:_vm._u([{key:"text",fn:function(){return [_c('v-form',[_c('v-select',{attrs:{"items":_vm.cancellationReasons,"outlined":"","hide-details":""},model:{value:(_vm.cancellationReason),callback:function ($$v) {_vm.cancellationReason=$$v},expression:"cancellationReason"}})],1)]},proxy:true},{key:"actions",fn:function(ref){
var cancel = ref.cancel;
var confirm = ref.confirm;
return [_c('div',{staticClass:"d-flex mb-2 align-center",staticStyle:{"gap":"10px"}},[_c('div',{staticClass:"warning-msg pa-2 rounded"},[_c('v-icon',{attrs:{"color":"warning"}},[_vm._v(" mdi-alert ")]),_vm._v(" All the payments associated to this invoice will be unlinked. ")],1),_c('div',{staticClass:"d-flex"},[_c('v-btn',{attrs:{"text":""},on:{"click":cancel}},[_vm._v("Back")]),_c('v-btn',{attrs:{"color":"error","disabled":!_vm.cancellationReason,"text":""},on:{"click":confirm}},[_vm._v("Cancel Invoice")])],1)])]}}])}),_c('invoice-pdf-dialog',{ref:"invoicePdfDialog"})],1)}
var staticRenderFns = []

export { render, staticRenderFns }