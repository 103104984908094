<template>
  <div @click.stop="(disabled || readonly) ? null : datePickerMenu = true">
    <v-menu
      ref="datePickerMenu"
      :close-on-content-click="false"
      v-model="datePickerMenu"
      :nudge-right="40"
      transition="scale-transition"
      offset-y
      max-width="290px"
      min-width="290px"
      :zIndex="zIndex"
      :disabled="readonly"
    >
      <template v-slot:activator="{ on }">
        <v-text-field
          v-on="on"
          v-bind="$attrs"
					@click:clear="date = ['', '']; $emit('click:clear', $event)"
          v-model="dateFormatted"
          :label="pickerLabel"
          :prepend-icon="inner ? null:icon"
          :prepend-inner-icon="!inner ? null:icon"
          :disabled="disabled"
          readonly
					style="min-width: 280px;"
        ></v-text-field>
      </template>
      <v-date-picker
        v-model="date"
        :min="min" :max="max"
        v-on="listeners"
        no-title @input="input"
        :allowed-dates="allowedDates"
				range :show-current="showCurrent"
      />
    </v-menu>
  </div>
</template>

<script>
  import moment from 'moment'
  import _ from "lodash"

  export default {
    props: {
      label: String,
      value: Array,
      inner: Boolean,
      min: String,
      max: String,
      disabled: Boolean,
      allowedDates: Function,
      icon: { type:String, default:'event' },
      zIndex: { type:String|Number },
      readonly: Boolean,
			showCurrent: Boolean,
    },
    data: () => ({
      datePickerMenu: false,
      date: ['', ''],
      dateFormatted: null,
    }),
    watch: {
      date: function (val) {
				if(this.date[0])
					this.dateFormatted = this.formatDate(this.date[0]) + ' - '
				if(this.date[1])
        	this.dateFormatted += this.formatDate(this.date[1]);
        this.$emit('input', val);
      },
      value: function (val) {
        this.date = val;
      },
    },
    computed: {
      pickerLabel() {
        if(this.label === '')
          return ''
        return this.label || "Date";
      },
      listeners() {
        const { input, ...listeners } = this.$listeners;
        return listeners;
      },
    },
    methods: {
      input($event) {
        this.$emit('input', this.date)
      },
      formatDate (date) {
        if (!date) return null;
        return moment(String(date)).format('MMM D, YYYY');
      },
      parseDate (date) {
        if (!date) return null;
        let compatibleFormats = ["YYYY-MM-DD", "MMM D, YYYY", "MMM/D/YYYY", "DD-MM-YYYY", "DD/MMM/YYYY", "DD/MM/YYYY", "MM-D-YYYY",];
        return moment(date, compatibleFormats)
          .format('YYYY-MM-DD');
      },
    },
    created() {
			if(!this.value) return
      if (this.value[0]) {
        this.date[0] = this.parseDate(this.value[0]);
        this.dateFormatted = this.formatDate(this.date[0]) + ' - '
      }
      if (this.value[1]) {
        this.date[1] = this.parseDate(this.value[1]);
        this.dateFormatted += this.formatDate(this.date[1]);
      }
    },
   }
</script>

<style scoped lang="scss">

</style>