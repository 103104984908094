<template>
  <v-menu v-model="show" :close-on-content-click="false">
    <template #activator="{ on }">
      <slot name="menu.activator" v-bind="{ value, on }">
        <v-btn v-on="on">
          Estimated dev effort
        </v-btn>
      </slot>
    </template>
    <div class="main-container rounded-xl bordered">
      <div class="estimated-hours-container">
        <div
          v-for="(number, ix) in fibonacciNumbers"
          :key="ix"
          class="number rounded-lg cursor-pointer"
          @click="show = false; $emit('input', number); $emit('change', number)"
          :class="{ 'primary': number == value }"
          :style="{ color: number == value ? 'white' : 'black' }"
        >
          {{ number }}
        </div>
      </div>
      <div class="custom-container">
        <v-text-field
          outlined
          dense
          autofocus
          class="custom-txt"
          type="number"
          hide-details
          label="Custom"
          :value="value"
          v-on="$listeners"
        ></v-text-field>
        <div @click="show = false" class="number rounded-lg primary-background cursor-pointer">
          <v-icon color="white">mdi-check</v-icon>
        </div>
      </div>
    </div>
  </v-menu>
</template>

<script>
export default {
  props: {
    value: { type: Number|String, default: 0 }
  },
  data: () => ({
    fibonacciNumbers: [1, 2, 3, 5, 8, 13, 21, 34],
    show: false,
  }),
}
</script>

<style scoped lang="scss">
.main-container {
  width: 220px;
  padding: 15px 10px;
  background: var(--v-background-base);
}
.estimated-hours-container {
  width: 199px;
  height: 100px;
  display: flex;
  height: 100px;
  flex-wrap: wrap;
  justify-content: space-around;
}
.number {
  width: 40px;
  background: #F0F0F0;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  &:hover {
    opacity: 0.7;
  }
}
.custom-txt {
  max-width: 140px !important;
  display: inline-block;
}
.custom-container {
  display: flex;
  justify-content: space-around;
}
.v-menu__content {
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
}
</style>