<template>
	<div>
		<filter-search-input v-model="filters.search" :applied-filters="appliedFilters" outlined dense>
			<template #append>
				<div class="d-flex align-center gap-2">
					<excel-btn @click="generateExcel"/>
					<v-tooltip v-if="permissions.add" top z-index="200">
						<template #activator="{ on }">
							<v-btn v-on="on" to="/admin/billableItem/new" fab small depressed color="primary">
								<v-icon>mdi-plus</v-icon>
							</v-btn>
						</template>
						<span class="caption">Add new</span>
					</v-tooltip>
				</div>
			</template>

			<q-filter-input
				label="Billable Item Code"
				dense outlined hide-details clearable multiple
				v-model="filters.billableItem" :items="billableItems"
				item-text="code" item-value="id"
				:parentShownItems="shownBillableItems"
				:data-comparer="(x, value) => x.id === value"
			/>

			<q-filter-input
				label="Billable Code"
				dense outlined hide-details clearable multiple
				v-model="filters.billable" :items="billables"
				item-text="code" item-value="id"
				:parentShownItems="shownBillableItems"
				:data-comparer="(x, value) => x.billableId === value"
			/>

			<q-filter-input
				label="Customer"
				outlined dense hide-details single-line clearable multiple
				v-model="filters.customer" :items="customers"
				item-text="name" item-value="id"
				:parentShownItems="shownBillableItems"
				:data-comparer="(x, value) => x.customerId === value"
			/>

			<QDatePicker
				label="Start Date"
				inner outlined dense hide-details clearable
				v-model="filters.startDate" :max="filters.endDate"
			/>

			<QDatePicker
				label="End Date"
				inner outlined dense hide-details clearable
				v-model="filters.endDate" :min="filters.startDate"
			/>

			<q-filter-input
				label="Currency"
				dense outlined hide-details clearable multiple
				v-model="filters.currency" :items="currencies"
				item-text="text" item-value="text"
				:parentShownItems="shownBillableItems"
				:data-comparer="(x, value) => x.billableCurrency === value"
			/>

			<v-select
				v-model="filters.status" :items="statuses"
				dense outlined hide-details clearable multiple
				label="Status" item-text="description" item-value="id"
			>
				<template #selection="{ item }">
					<v-btn
						:color="item.color"
						outlined small class="rounded-xl"
						:style="{
							background: $vuetify.theme.isDark ? '' : item.lightColor
						}"
					>{{item.description}}</v-btn>
				</template>
				<template #item="{ item }">
					<v-btn
						:color="item.color"
						outlined small class="rounded-xl"
						:style="{
							background: $vuetify.theme.isDark ? '' : item.lightColor
						}"
					>{{item.description}}</v-btn>
				</template>
			</v-select>
		</filter-search-input>

		<v-data-table
			class="rounded-lg bordered new-back lighten2"
			:item-class="() => permissions.detail ? 'cursor-pointer' : ''"
			:headers="shownHeaders" :items="shownBillableItems"
			@click:row="item => permissions.detail ? this.$router.push(`billableItem/${item.id}`) : null"
		>
			<template v-slot:[`item.customer`]="{ item }">
				<div class="py-1">
					{{ item.customerName }}
				</div>
			</template>
			<template v-slot:[`item.startDate`]="{ item }">
				<div class="py-1">
					{{ item.startDate | formatDate }}
				</div>
			</template>
			<template v-slot:[`item.endDate`]="{ item }">
				<div class="py-1">
					{{ item.endDate | formatDate }}
				</div>
			</template>
			<template v-slot:[`item.currency`]="{ item }">
				<div class="py-1">
					<v-avatar size="24" class="mr-1">
						<v-img :src="require(`../../../assets/currencies/${item.billableCurrency.toLowerCase()}.png`)" />
					</v-avatar>
					<b>
						{{ item.billableCurrency }}
					</b>
				</div>
			</template>
			<template #[`item.status`]="{ item }">
				<v-menu offset-x right open-on-hover>
					<template #activator="{ on }">
						<v-btn
							v-on="on"
							:color="item.statusColor"
							outlined small class="rounded-xl"
							:style="{
								background: $vuetify.theme.isDark ? '' : item.statusLightColor
							}"
						>{{item.statusDisplayName}}</v-btn>
					</template>
					
					<v-list dense>
						<v-list-item
							v-for="approver in item.approvers.filter(x => x.statusToApprove === 2)"
							:key="approver.id"
						>
							<div
								class="d-flex justify-space-between align-center"
								style="min-width: 20px; width: 100%"
							>
								<span class="mr-2 body-2">
									<user-avatar :user-id="approver.approverId" :size="28" :show-card="false"/>
									{{ approver.approverBlueTag }}
								</span>
								<span :set="status = approverStatus.find(s => s.id == approver.status)">
									<v-chip
										:color="status.lightColor"
										:style="{ color: status.color }"
										small
									>
										{{ status.description }}
									</v-chip>
								</span>
							</div>
						</v-list-item>
					</v-list>
				</v-menu>
			</template>
			<template #[`item.hours`]="{ item }">
				<div class="py-1">
					{{ item.hours | number(2) }}
				</div>
			</template>
			<template #[`item.isClosed`]="{ item }">
				<check-switch :value="item.isClosed"/>
			</template>
			<template #[`item.total`]="{ item }">
				<div class="py-1">
					{{ currencyFormat(item.total) }}
				</div>
			</template>
			<template #[`item.invoiced`]="{ item }">
				<div class="py-1">
					{{ currencyFormat(item.invoiced) }}
				</div>
			</template>
			<template #[`item.adjustmentAmount`]="{ item }">
				<div class="py-1" :class="{ 'error--text': item.adjustmentAmount < 0, 'success--text': item.adjustmentAmount > 0 }">
					{{ item.adjustmentAmount ? currencyFormat(item.adjustmentAmount) : '-' }}
				</div>
			</template>
			<template #[`item.actions`]="{ item }">
				<div class="py-1 nobr">
					<v-menu left offset-x>
						<template #activator="{ on }">
							<v-btn v-on="on" icon><v-icon>mdi-dots-vertical</v-icon></v-btn>
						</template>
						<v-list dense class="py-1">
							<v-list-item v-if="item.canApprove" class="pa-1 py-0">
								<v-tooltip right z-index="200">
									<template #activator="{ on }">
										<v-btn v-on="on" color="primary" @click.stop="approve(item)" icon>
											<v-icon>mdi-check</v-icon>
										</v-btn>
									</template>
									<span class="caption">Validate</span>
								</v-tooltip>
							</v-list-item>
							<v-list-item class="pa-1 py-0">
								<v-tooltip right z-index="200">
									<template #activator="{ on }">
										<v-btn v-on="on" color="#ff6347" @click.stop="$adminSurf('billableItemToPdf', item)" icon>
											<v-icon>mdi-file-pdf-box</v-icon>
										</v-btn>
									</template>
									<span class="caption">Export to PDF</span>
								</v-tooltip>
							</v-list-item>
							<v-list-item class="pa-1 py-0">
								<v-tooltip right z-index="200">
									<template #activator="{ on }">
										<v-btn v-on="on" @click.stop="$adminSurf('openHistory', item, 'billableItem')" icon>
											<v-icon>mdi-history</v-icon>
										</v-btn>
									</template>
									<span class="caption">History</span>
								</v-tooltip>
							</v-list-item>
							<v-list-item class="pa-1 py-0">
								<v-tooltip right z-index="200">
									<template #activator="{ on }">
										<v-btn v-on="on" @click.stop="deleteBillableItem(item)" icon color="error">
											<v-icon>mdi-cancel</v-icon>
										</v-btn>
									</template>
									<span class="caption">Cancel</span>
								</v-tooltip>
							</v-list-item>
							<v-list-item class="pa-1 py-0">
								<watchers-menu
									@watcher-added="item.subscribers.push($event)"
									@watcher-removed="item.subscribers.splice(item.subscribers.findIndex(x => x.userId == $event), 1)"
									:entity-id="item.id"
									:watchers="item.subscribers"
									required-permission="billableItems"
								>
									<template #default="{ on }">
										<v-btn v-on="on" icon>
											<v-icon>mdi-eye</v-icon>
										</v-btn>
									</template>
								</watchers-menu>
							</v-list-item>
						</v-list>
					</v-menu>
				</div>
			</template>
		</v-data-table>
		<billable-item-delete-dialog ref="deleteDialog"/>
	</div>
</template>

<script>
import moment from "moment"
import BillableItemDeleteDialog from '../../../dialogs/adminSurf/BillableItemDeleteDialog'
import WatchersMenu from "../../admin-surf/WatchersMenu.vue"
import CheckSwitch from '../../CheckSwitch.vue'
import ExcelBtn from "../../ExcelBtn"

export default {
	components: { BillableItemDeleteDialog, WatchersMenu, CheckSwitch, ExcelBtn },
	props: ['customerId', 'invoiced', 'currency'],
	data: () => ({
		headers: [
			{ text: "Billable Item Code", value: "code", },
			{ text: "Billable", value: "billableCode", align: "right" },
			{ text: "Customer", value: "customer", },
			{ text: "Start Date", value: "startDate", },
			{ text: "End Date", value: "endDate", },
			{ text: "Currency", value: "currency", align: "center" },
			{ text: "Status", value: "status", align: "center" },
			{ text: "Hours", value: "hours", align: "right" },
			{ text: "Closed", value: "isClosed", align: "center" },
			{ text: "Billed", value: "total", align: "right" },
			{ text: "Invoiced", value: "invoiced", align: "right" },
			{ text: "Adjustment", value: "adjustmentAmount", align: "right" },
			{ text: "", value: "actions", align: 'right', sortable: false},
		],
		billableItems: [],
		billables: [],
		customers: [],
		currencies: [
			{text: 'MXN'},
			{text: 'USD'},
			{text: 'EUR'},
		],
		durationUnits: [
			{ value: 3, hours: 1, text: 'Hours' },
			{ value: 0, hours: 8, text: 'Days' },
			{ value: 1, hours: 40, text: 'Weeks' },
			{ value: 2, hours: 168, text: 'Months' },
		],
		filters: {
			billableItem: [],
			billable: [],
			customer: [],
			currency: [],
			status: [],
		},
		statuses: [],
		approverStatus : [],
		currentUser: {},

		permissions: {
			detail: false,
			add: false,
			actions: false,
		},
	}),
	mounted(){
		document.title = 'Billable Items'

		this.$security.hasRequiredPermission('detail')
			.then(res => this.permissions.detail = res)
		this.$security.hasRequiredPermission('add')
			.then(res => this.permissions.add = res)
		this.$security.hasRequiredPermission('full_access')
			.then(res => this.permissions.actions = res)

    this.$q.log(4, 24, 3, 'NA')
		this.setUserPreferences("BillableItems", "filters")
		if(this.customerId){
			this.filters = {
			  billableItem: [],
			  billable: [],
			  customer: [],
			  currency: [],
				status: [],
			}
		  this.filters.customer.push(this.customerId)

			if(this.invoiced == 'true'){
				this.filters.status.push(1, 2)
			}
			else if(this.invoiced == 'false'){
				this.filters.status.push(0, 1)
			}
			if(this.currency){
				this.filters.currency.push(this.currency)
			}
		}

		this.init()
	},
	computed: {
		shownBillableItems(){
			let billableItems = this.billableItems

			if(this.filters.billableItem && this.filters.billableItem.length){
				billableItems = billableItems.filter(bi => this.filters.billableItem.includes(bi.id))
			}
			if(this.filters.billable && this.filters.billable.length){
				billableItems = billableItems.filter(bi => this.filters.billable.includes(bi.billableId))
			}
			if(this.filters.customer && this.filters.customer.length){
				billableItems = billableItems.filter(bi => this.filters.customer.includes(bi.customerId))
			}
			if(this.filters.startDate){
				billableItems = billableItems.filter(bi => moment(bi.endDate) >= moment(this.filters.startDate))
			}
			if(this.filters.endDate){
				billableItems = billableItems.filter(bi => moment(bi.startDate) <= moment(this.filters.endDate))
			}
			if(this.filters.currency && this.filters.currency.length){
				billableItems = billableItems.filter(bi => this.filters.currency.includes(bi.billableCurrency))
			}
			if(this.filters.status && this.filters.status.length){
				billableItems = billableItems.filter(bi => this.filters.status.includes(bi.status))
			}

			if(this.filters.search){
				billableItems = billableItems.filter(bi =>
					this.normalizeIncludes(bi.code, this.filters.search) ||
					this.normalizeIncludes(bi.billableCode, this.filters.search) ||
					this.normalizeIncludes(bi.customerName, this.filters.search) ||
					this.normalizeIncludes(this.formatDate(bi.startDate), this.filters.search) ||
					this.normalizeIncludes(this.formatDate(bi.endDate), this.filters.search) ||
					this.normalizeIncludes(bi.billableCurrency, this.filters.search) ||
					this.normalizeIncludes(bi.hours, this.filters.search) ||
					this.normalizeIncludes(bi.total, this.filters.search)
				)
			}

			return billableItems
		},
		appliedFilters(){
			return this.countActiveFilters(this.filters, ['search'])
		},
		shownHeaders(){
			return this.headers.filter(x => this.permissions.actions || x.value != 'actions')
		},
	},
	methods: {
		async init(){
			await this.$blueSystem.getCurrentUser()
        .then(user => {
          this.currentUser = user
        })
				.catch(error => this.$root.error('Failed to get user'))

				this.$http.get('api/enums/approver/status')
					.then(res => {
						this.approverStatus = res.data
					})

				this.$http.get('api/enums/admin/billableItem/status')
					.then(res => {
						this.statuses = res.data
					})
					.catch(err => {
						console.error(err)
						this.$root.error('Cannot obtain billable item status')
					})

			this.$http.get('api/billableItem?includeDeleted=true')
				.then(res => {
					this.billableItems = res.data
						.map(x => ({
							...x,
							canApprove: x.approvers
								.some(y =>
									!x.isDraft && !x.reviewed &&
									y.approverId == this.currentUser.id &&
									y.statusToApprove === 2 &&
									y.status === 0
								)
						}))
				})
				.catch(err => {
					console.error(err)
					this.$root.error('Cannot obtain billable items')
				})

			this.$http.get('api/Billable')
				.then(res => {
					this.billables = res.data
				})
				.catch(res => {
					window.getApp.error("Cannot obtain billables.")
				})

			this.$http.get("/api/customer?onlyWithCustomerProjects=true")
				.then(res => {
					this.customers = res.data
				})
				.catch(res => {
					window.getApp.error("Cannot obtain customers.")
				})
		},
		approve(item) {
			this.$adminSurf('approveBillableItem', item)
				.then(res => {
					item = Object.assign(item, res.data)
				})
				.catch(err => {
					console.error(err)
					this.$root.error('Cannot approve billable item')
				})
		},
		deleteBillableItem(item){
			this.$refs.deleteDialog.open(item.id)
				.then(res => {
          if(!res)
						return
          this.$http.delete(`api/billableItem/${item.id}`)
						.then(res => {
							window.getApp.success('Deleted successfully')
							this.init()
						})
						.catch(err => {
							console.log(err)
							window.getApp.error('Cannot delete billable item')
						})
				})
		},
		generateExcel(){
			const excelHeaders = [
				{ name: 'Code', value: x => x.code || '' },
				{ name: 'Billable', value: x => x.billableCode || '' },
				{ name: 'Customer', value: x => x.customerName || '' },
				{ name: 'Start Date', value: x => this.formatDate(x.startDate) || '' },
				{ name: 'End Date', value: x => this.formatDate(x.endDate) || '' },
				{ name: 'Currency', value: x => x.billableCurrency || '' },
				{ name: 'Status', value: x => x.statusDisplayName || '' },
				{ name: 'Hours', value: x => x.hours || '' },
				{ name: 'Closed', value: x => x.isClosed ? 'Yes' : 'No' },
				{ name: 'Billed', value: x => x.total || '' },
				{ name: 'Invoiced', value: x => x.invoiced || '0' },
				{ name: 'Adjustment', value: x => x.adjustmentAmount || '-' },
			];
			this.singleSheetExcel(this.shownBillableItems, excelHeaders, 'Billable Items')
		},
	},
}
</script>

<style lang="scss" scoped>
.project{
	display: inline-block;
	border-radius: 15px;
	margin-right: 5px;
	div{
		display: inline-flex;
		align-items: center;
		justify-content: center;
	}
	.letter{
		width: 30px;
		padding: 5px;
		color: white;
		border-radius: 15px;
	}
	.name{
		padding: 0 10px 0 5px;
		color: black;
	}
}
.bar{
	width: 100%;
	height: 10px;
	background: lightgray;
	border-radius: 5px;
	
	overflow: hidden;
	div{
		height: 20px;
		display: inline-block;
	}
	.no-billable{
		background: var(--v-error-darken);
	}
	.billable{
		background: var(--v-success-base);
	}
}
</style>