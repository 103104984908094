<template>
	<div class="forbidden">
		<div class="content text-center">
			<v-img src="../../public/logo-surf.png"></v-img>
			<div class="text-center">
				<h1 class="noselect">403</h1>
				<h2 class="noselect">Access Denied</h2>
			</div>
		</div>
	</div>	
</template>

<script>
export default {
	
}
</script>

<style scoped lang="scss">
.forbidden {
	display: flex;
	align-items: center;
	justify-content: center;
	height: 85%;
}
.content {
	display: grid;
	grid-template-columns: 210px auto;
	gap: 20px;
}
h1 {
	display: inline-block;
  font-size: 200px;
	line-height: 150px;
  background: linear-gradient(to right, #52a7b4, #424eb2);
  background-clip: text;
  -webkit-text-fill-color: transparent;
}
h2 {
	color: #fff;
	line-height: 50px;
	background: #154360;
	font-size: 50px;
}
</style>