<template>
  <v-dialog v-if="dialog" v-model="dialog" width="500" >
    <v-card class="new-back">
      <v-card-title>
        Select Users
      </v-card-title>
      <v-card-text>
        <v-row class="mb-3 align-center">
          <v-col cols="7">
            <v-select
              class="input"
              :items="departments"
              multiple
              label="Department"
              item-value="value"
              item-text="name"
              v-model="filters.department"
              outlined
              dense
              clearable
              hide-details
            />
          </v-col>
          <v-col cols="5">
            <a @click="toggleSelectUsers(true)" class="cursor-pointer mr-4 opacity-on-hover" >
              Select All
            </a>
            <a @click="toggleSelectUsers(false)" class="cursor-pointer opacity-on-hover" >
              Clear All
            </a>
          </v-col>
        </v-row>
        <v-list
          class="py-0"
          :class="{'action-container': !!shownUsers.length}"
        >
          <v-list-item
            class="item py-0"
            v-for="(item, ix) in shownUsers" :key="item.id || ix"
            >
            <div class="d-flex align-center">
              <v-checkbox
                v-model="selectedUsers"
                :value="item.id"
              ></v-checkbox>
              <div class="d-flex">
                <user-avatar :showCard="false" :userId="item.id" hideLoading :size="40"/>
                <div class="ml-2">
                  <v-list-item-title v-if="item" v-text="item.blueTag"></v-list-item-title>
                  <v-list-item-subtitle v-if="item" v-text="item.fullName"></v-list-item-subtitle>
                </div>
              </div>
            </div>
          </v-list-item>
        </v-list>
      </v-card-text>
      <v-card-actions class="justify-end pb-2">
        <v-btn @click="cancel" color="error">Cancel</v-btn>
        <v-btn @click="save" color="primary">Save</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  props: {
    users: { type: Array, default: () => ([]) },
  },
  data: () => ({
    dialog: false,
    resolve: null,
    reject: null,
    selectedUsers: [],
    filters: {},
    departments: [
      { value: 1, name: 'Administration' },
      { value: 5, name: 'Capability Management' },
      { value: 2, name: 'Commercial' },
      { value: 0, name: 'Dev Center' },
      { value: 4, name: 'Marketing' },
      { value: 3, name: 'Talent Acquisition' },
    ],
  }),
  computed: {
    shownUsers() {
      let items = this.users
      if(this.filters.department != undefined && this.filters.department.length){
        items = items.filter(i => this.filters.department.includes(i.department))
      }
      return items
    }
  },
  methods: {
    open(selectedUsers = []) {
      this.selectedUsers = JSON.parse(JSON.stringify(selectedUsers))
      this.dialog = true
      return new Promise((resolve, reject) => {
        this.resolve = resolve
        this.reject = reject
      })
    },
    cancel() {
      this.selectedUsers = []
      this.dialog = false
      this.reject()
    },
    save() {
      let selectedUsers = JSON.parse(JSON.stringify(this.selectedUsers))
      this.resolve(selectedUsers)
      this.dialog = false
      this.selectedUsers = []
    },
    toggleSelectUsers(selectAll) {
      if(selectAll) {
        this.selectedUsers = this.users.map(u => u.id)
        return
      }
      this.selectedUsers = []
    }
  },
}
</script>

<style scoped lang="scss">
.action-container {
  border: 2px solid #E5E5E5;
  max-height: 700px;
  overflow-y: auto;
  &::-webkit-scrollbar {
    width: 5px
  }
  &::-webkit-scrollbar-track {
    -webkit-box-shadow: none;
    box-shadow: none;
    background-color: none;
  }
  border-radius: 10px 10px 10px 10px  !important;
}
.item {
  border-bottom: 2px solid #E5E5E5;
  background: var(--v-newDesignBackground-lighten2) !important;
  &:last-child {
    border-radius: 0 0 12px 12px;
    border-bottom: none !important;
  }
  &:first-child {
    border-radius: 12px 12px 0 0 ;
  }
  &:only-child {
    border-radius: 12px;
  }
}
</style>