var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{ref:"risks",staticClass:"mt-15"},[_c('div',{staticClass:"max-container"},[_vm._m(0),_c('div',{staticClass:"graph-container",style:({ opacity: _vm.$vuetify.theme.isDark ? 0.8 : 1 })},[_c('div',{staticClass:"sidebar"},[_vm._v("Probability")]),_c('div',{staticClass:"matrix-container"},[_vm._l((_vm.probabilityRows),function(row){return _c('v-row',{key:row,staticClass:"probability-row"},_vm._l((_vm.impactCols),function(col){return _c('v-col',{key:col,staticClass:"impact-col",style:({ background: _vm.getBackground(row, col) })},[(
                _vm.risksBySeverity[col + '-' + (6 - row)] &&
                _vm.risksBySeverity[col + '-' + (6 - row)].length <= 4
              )?_c('div',_vm._l((_vm.risksBySeverity[col + '-' + (6 - row)]),function(risk,ix){return _c('div',{key:ix,staticClass:"risk",style:(Object.assign({}, _vm.positions[ix],
                  {background: risk.color,
                  color: 'white'}))},[_vm._v(" "+_vm._s(risk.code.split("-")[1])+" "),_c('br'),_vm._v(" "+_vm._s(risk.code.split("-")[2])+" ")])}),0):(_vm.risksBySeverity[col + '-' + (6 - row)])?_c('div',[_c('v-menu',{attrs:{"bottom":"","origin":"center center","transition":"scale-transition"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                var menu = ref.on;
                var attrs = ref.attrs;
return [_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                var tooltip = ref.on;
return [_c('div',_vm._g(_vm._b({staticClass:"risk risk-center"},'div',attrs,false),Object.assign({}, tooltip, menu)),[_c('div',{staticClass:"number"},[_vm._v(" "+_vm._s(_vm.risksBySeverity[col + "-" + (6 - row)].length)+" ")])])]}}],null,true)},[_c('span',[_vm._v("Show all")])])]}}],null,true)},[_c('v-list',{staticStyle:{"max-height":"320px","overflow-y":"auto"}},_vm._l((_vm.risksBySeverity[
                      col + '-' + (6 - row)
                    ]),function(risk,ix){return _c('v-list-item',{key:ix,style:({
                      cursor: 'pointer',
                    })},[_c('v-list-item-title',[_c('v-icon',{staticClass:"mr-2",attrs:{"color":risk.color}},[_vm._v(" mdi-alert-circle ")]),_vm._v(" "+_vm._s(risk.code)+" ")],1)],1)}),1)],1)],1):_vm._e()])}),1)}),_c('div',{staticClass:"underline"},[_vm._v("Impact")])],2)])]),_vm._l((_vm.length),function(n){return _c('v-row',{key:n,staticClass:"all-risks-container",attrs:{"id":"all-risks-container"}},_vm._l((_vm.risks.slice((n-1)*4, n*4)),function(risk){
                    var _obj;
return _c('v-col',{key:risk.id,ref:risk.id,refInFor:true,staticClass:"card-container",attrs:{"cols":"3"}},[_c('v-card',{class:( _obj = {}, _obj['hover-' +
          _vm.getDarkColor(risk.probability * risk.impact).substr(1)] = true, _obj ),style:({
          borderColor: _vm.getDarkColor(risk.probability * risk.impact),
          background: risk.hover
            ? _vm.getDarkColor(risk.probability * risk.impact)
            : '',
        })},[_c('v-card-title',[_c('v-avatar',{staticClass:"avatar",attrs:{"color":"grey","size":35}},[_c('v-img',{attrs:{"lazy-src":risk.reporter.profilePicSrc,"src":risk.reporter.profilePicSrc},on:{"error":function($event){risk.reporter.profilePicSrc = _vm.profilePicFailover}}})],1),_c('div',{staticClass:"reporter-name"},[_c('span',{staticClass:"fullname"},[_vm._v(" "+_vm._s(risk.reporter.blueTag)+" ")]),_c('span',[_vm._v(" "+_vm._s(_vm.formatDate(risk.createdAt))+" ")])]),_c('div',{staticClass:"icon-container"},[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
        var on = ref.on;
        var attrs = ref.attrs;
return [_c('div',_vm._g(_vm._b({staticStyle:{"cursor":"pointer"}},'div',attrs,false),on),[_c('v-icon',{style:({ fontSize: '22px' })},[_vm._v("mdi-clipboard-check-outline")]),_c('span',{staticClass:"workitems-quantity"},[_vm._v(_vm._s(risk.workItems.length))])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s("Work item" + (risk.workItems.length != 1 ? "s" : "")))])])],1)],1),_c('v-card-text',[_c('v-row',[_c('v-col',[_c('h3',[_vm._v(_vm._s(risk.code))])])],1),_c('br'),_c('div',{staticClass:"description",domProps:{"innerHTML":_vm._s(
              JSON.parse(risk.description.replaceAll('\\n', '<br/>'))
            )}}),_c('div',{staticClass:"severity"},[_c('span',[_vm._v("Probability: "+_vm._s(risk.probability))]),_c('span',[_vm._v("Impact: "+_vm._s(risk.impact))]),_c('span',[_vm._v("Severity: "+_vm._s(risk.probability * risk.impact))])])],1)],1)],1)}),1)})],2)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticStyle:{"width":"100%"}},[_c('h4',[_vm._v("Risks")])])}]

export { render, staticRenderFns }