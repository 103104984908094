var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"d-flex justify-space-between align-center mb-3"},[_c('h2',[_vm._v(" Boards "),_c('v-btn',{attrs:{"disabled":!_vm.notShownTypes.length,"fab":"","x-small":"","depressed":"","color":"primary"},on:{"click":_vm.addBoardConfig}},[_c('v-icon',[_vm._v("add")])],1)],1),_c('div',{staticClass:"text-right"},[_c('v-switch',{staticClass:"inline-block",attrs:{"label":"Using working hours","hide-details":"","inset":"","disabled":!_vm.$parent.canEditProject},on:{"change":_vm.changeWorkingHours},model:{value:(_vm.$parent.config.usingWorkingHours),callback:function ($$v) {_vm.$set(_vm.$parent.config, "usingWorkingHours", $$v)},expression:"$parent.config.usingWorkingHours"}})],1)]),_c('data-table',{attrs:{"headers":_vm.headers,"items":_vm.$parent.boardConfigs},scopedSlots:_vm._u([{key:"item.type",fn:function(ref){
var item = ref.item;
return [(item.id)?_c('div',{style:({minWidth: '103px'}),attrs:{"set":_vm.type = _vm.$parent.types.find(function (t) { return t.value == item.workItemType; }) || {}}},[_c('v-btn',{attrs:{"fab":"","x-small":"","dark":"","depressed":"","color":_vm.type.color}},[_c('v-icon',{attrs:{"small":""}},[_vm._v(_vm._s(_vm.type.iconClass))])],1),_vm._v(" "+_vm._s(_vm.type.displayName)+" ")],1):_c('div',{staticStyle:{"max-width":"240px"}},[_c('v-autocomplete',{attrs:{"items":_vm.notShownTypes,"item-value":"value","item-text":"displayName","hide-details":"","outlined":"","dense":""},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var type = ref.item;
return [_c('v-icon',{staticClass:"mr-2",attrs:{"color":type.color}},[_vm._v(_vm._s(type.iconClass))]),_vm._v(" "+_vm._s(type.displayName)+" ")]}},{key:"selection",fn:function(ref){
var type = ref.item;
return [_c('v-icon',{staticClass:"mr-2",attrs:{"color":type.color}},[_vm._v(_vm._s(type.iconClass))]),_vm._v(" "+_vm._s(type.displayName)+" ")]}}],null,true),model:{value:(item.workItemType),callback:function ($$v) {_vm.$set(item, "workItemType", $$v)},expression:"item.workItemType"}})],1)]}},{key:"item.defaultColor",fn:function(ref){
var item = ref.item;
return [(!item.editing)?_c('div',{staticStyle:{"width":"40px"}},[_c('v-list-item',{staticClass:"color-preview",style:({
						background: item.defaultColor,
						color: _vm.isBlackText(item.defaultColor) ? 'white' : 'black',
						justifyContent: 'center'
					})},[_c('v-icon',{attrs:{"color":_vm.isBlackText(item.defaultColor) ? '#eee' : 'black'}},[_vm._v("invert_colors")])],1)],1):_c('div',{staticStyle:{"width":"150px"}},[_c('v-menu',{attrs:{"open-on-hover":"","bottom":"","offset-x":"","close-on-content-click":false},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
					var on = ref.on;
return [_c('v-list-item',_vm._g({staticClass:"color-preview",style:({background: item.defaultColorTemporal, color: _vm.isBlackText(item.defaultColorTemporal) ? 'white' : 'black'})},_vm.$parent.canEditProject ? on : null),[_c('v-icon',{attrs:{"left":"","color":_vm.isBlackText(item.defaultColorTemporal) ? '#eee' : 'black'}},[_vm._v("invert_colors")]),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v("Color")])],1),_c('v-list-item-action',[_c('v-icon',{attrs:{"color":_vm.isBlackText(item.defaultColorTemporal) ? '#eee' : 'black'}},[_vm._v("chevron_right")])],1)],1)]}}],null,true)},[_c('color-picker',{attrs:{"workItem":true},model:{value:(item.defaultColorTemporal),callback:function ($$v) {_vm.$set(item, "defaultColorTemporal", $$v)},expression:"item.defaultColorTemporal"}})],1)],1)]}},{key:"item.leadTimeGoal",fn:function(ref){
					var item = ref.item;
return [(item.leadTimeGoal && !item.editing)?_c('div',[_vm._v(" "+_vm._s(item.leadTimeGoal + ' ' + _vm.cycleTimeGoalUnits[item.leadTimeGoalUnit].shortname + (item.leadTimeGoal != 1 ? 's' : ''))+" ")]):(item.editing)?_c('div',{staticStyle:{"max-width":"280px","margin":"5px 0"}},[_c('v-row',[_c('v-col',[_c('v-text-field',{attrs:{"hide-details":"","dense":"","outlined":"","type":"number"},model:{value:(item.leadTimeGoalTemporal),callback:function ($$v) {_vm.$set(item, "leadTimeGoalTemporal", $$v)},expression:"item.leadTimeGoalTemporal"}})],1),_c('v-col',{attrs:{"cols":7}},[_c('v-select',{attrs:{"hide-details":"","dense":"","outlined":"","items":_vm.cycleTimeGoalUnits,"item-value":function (it) { return it.value; },"item-text":function (it) { return item.leadTimeGoalTemporal != 1 ? it.name + 's' : it.name; }},model:{value:(item.leadTimeGoalUnitTemporal),callback:function ($$v) {_vm.$set(item, "leadTimeGoalUnitTemporal", $$v)},expression:"item.leadTimeGoalUnitTemporal"}})],1)],1)],1):_vm._e()]}},{key:"item.actions",fn:function(ref){
					var item = ref.item;
					var index = ref.index;
return [_c('div',{staticClass:"nobr"},[(!item.editing)?_c('div',[_c('v-btn',{attrs:{"depressed":"","outlined":"","icon":"","fab":"","color":"primary","x-small":"","disabled":!_vm.$parent.canEditProject},on:{"click":function($event){return _vm.deleteBoardConfig(item.workItemType)}}},[_c('v-icon',[_vm._v("delete")])],1),_c('v-btn',{attrs:{"rounded":"","fab":"","x-small":"","color":"primary","disabled":!_vm.$parent.canEditProject},on:{"click":function($event){item.editing = true}}},[_c('v-icon',[_vm._v("edit")])],1)],1):_c('div',[_c('v-btn',{staticClass:"mr-1",attrs:{"rounded":"","fab":"","depressed":"","x-small":"","color":"primary","invert":"","disabled":!_vm.$parent.canEditProject},on:{"click":function($event){return _vm.saveBoardConfig(item)}}},[_c('v-icon',[_vm._v("mdi-check-bold")])],1),_c('v-btn',{attrs:{"outlined":"","rounded":"","fab":"","x-small":"","color":"primary","invert":"","disabled":!_vm.$parent.canEditProject},on:{"click":function($event){return _vm.stopEditBoardConfig(item, index)}}},[_c('v-icon',[_vm._v("mdi-close")])],1)],1)])]}}],null,true)})],1)}
var staticRenderFns = []

export { render, staticRenderFns }