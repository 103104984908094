<template>
	<v-data-table
		style="overflow: hidden"
		:headers="shownHeaders"
		:items="items"
		:items-per-page.sync="pagination.itemsPerPage"
		:page.sync="pagination.page"
		:sort-by.sync="pagination.sortBy"
		:sort-desc.sync="pagination.sortDesc"
		@update:sort-by="$emit('pagination', $event)"
		@update:sort-desc="$emit('pagination', $event)"
		:server-items-length="pagination.totalItems"
		v-bind="$attrs" v-on="$listeners"
	>
		<template #[`item.code`]="{ item }">
			<b>{{ item.code || item.invoiceCode }}</b>
		</template>
		<template #[`item.customerName`]="{ item }">
			<div class="my-2">
				<b>{{ item.customerName }}</b><br>
				{{item.rfcName}}
			</div>
		</template>
		<template #[`item.customerCountry`]="{ item }">
			<div
				v-if="item.customerCountry !== undefined"
				:set="country = countries.find(x => x.value === item.customerCountry)"
				class="d-flex align-center"
			>
				<v-avatar class="mr-1" size="24">
					<v-img :src="require(`../assets/currencies/${country.icon}.png`)"/>
				</v-avatar>
				<h3>{{ country.name }}</h3>
			</div>
		</template>
		<template #[`item.issueDate`]="{ item }">
			{{ (item.issueDate || item.invoiceIssueDate) | formatDate }}
		</template>
		<template #[`item.deliveryDate`]="{ item }">
			{{ (item.deliveryDate || item.invoiceDeliveryDate) | formatDate }}
		</template>
		<template #[`item.paymentDay`]="{ item }">
			{{ (item.paymentDay || item.invoicePaymentDay) | formatDate }}
		</template>
		<template #[`item.lastPaymentDate`]="{ item }">
			{{ item.lastPaymentDate | formatDate }}
		</template>
		<template #[`item.currency`]="{ item }">
			<div class="d-flex align-center justify-center">
				<v-avatar size="24" class="mr-1">
					<v-img :src="require(`../assets/currencies/${(item.currency || item.invoiceCurrency).toLowerCase()}.png`)" />
				</v-avatar>
				<b>
					{{ (item.currency || item.invoiceCurrency) }}
				</b>
			</div>
		</template>
		<template #[`item.projects`]="{ item }">
			<div
				v-for="(project, ix) in (item.projects || item.invoiceProjects)" :key="ix" class="project"
				:style="{background: customerProjectTypes[project.type].lightColor}"
			>
				<div
					class="letter"
					:style="{background: customerProjectTypes[project.type].color}"
				>
					{{customerProjectTypes[project.type].small}}
				</div>
				<div class="name">{{project.name}}</div>
			</div>
		</template>
		<template #[`item.subtotal`]="{ item }">
			{{currencyFormat(item.subtotal || item.invoiceSubtotal)}}
		</template>
		<template #[`item.total`]="{ item }">
			{{currencyFormat(item.total || item.invoiceTotal)}}
		</template>
		<template #[`item.billed`]="{ item }">
			{{currencyFormat(item.billed || item.invoiceBilled)}}
		</template>
		<template #[`item.paidAmount`]="{ item }">
			{{currencyFormat(item.paidAmount || item.invoicePaidAmount)}}
		</template>
		<template #[`item.pendingAmount`]="{ item }">
			{{currencyFormat(item.pendingAmount || item.invoicePendingAmount)}}
		</template>
		<template #[`item.status`]="{ item }">
			<v-btn
				:color="(item.statusColor || item.invoiceStatusColor)"
				outlined small style="border-radius: 20px"
				:style="{
					background: $vuetify.theme.isDark ? '' : (item.statusLightColor || item.invoiceStatusLightColor)
				}"
			>{{ item.statusName || item.invoiceStatusName }}</v-btn>
		</template>
		<template #[`item.actions`]="{ item }">
			<v-menu left offset-x>
				<template #activator="{ on }">
					<v-btn v-on="on" icon><v-icon>mdi-dots-vertical</v-icon></v-btn>
				</template>
				<v-list dense class="py-1">
					<v-list-item class="pa-1 py-0">
						<v-tooltip right z-index="200">
							<template #activator="{ on }">
								<v-btn v-on="on" @click="$router.push(`invoice/new?copyFrom=${item.id}`)" text small color="grey">
									<v-icon>mdi-content-copy</v-icon>
								</v-btn>
							</template>
							<span class="caption">Duplicate</span>
						</v-tooltip>
					</v-list-item>
					<v-list-item class="pa-1 py-0">
						<v-tooltip right z-index="200">
							<template #activator="{ on }">
								<v-btn v-on="on" color="#ff6347" @click="$adminSurf('invoiceToPdf', item)" text small>
									<v-icon>mdi-file-pdf-box</v-icon>
								</v-btn>
							</template>
							<span class="caption">Export to PDF</span>
						</v-tooltip>
					</v-list-item>
					<v-list-item class="pa-1 py-0">
						<v-tooltip right z-index="200">
							<template #activator="{ on }">
								<v-btn v-on="on" @click="$adminSurf('openHistory', item, 'invoice')" text small>
									<v-icon>mdi-history</v-icon>
								</v-btn>
							</template>
							<span class="caption">History</span>
						</v-tooltip>
					</v-list-item>
					<v-list-item class="pa-1 py-0">
						<v-tooltip right z-index="200">
							<template #activator="{ on }">
								<v-btn v-on="on" @click="cancelInvoice(item)" text small color="error">
									<v-icon>mdi-cancel</v-icon>
								</v-btn>
							</template>
							<span class="caption">Cancel</span>
						</v-tooltip>
					</v-list-item>
					<v-list-item class="pa-1 py-0">
						<watchers-menu
							@watcher-added="item.subscribers.push($event)"
							@watcher-removed="item.subscribers.splice(item.subscribers.findIndex(x => x.userId == $event), 1)"
							:entity-id="item.id"
							:watchers="item.subscribers"
							required-permission="invoices"
						/>
					</v-list-item>
				</v-list>
			</v-menu>
		</template>
		<template #[`item.amount`]="props">
			<slot name="item.amount" v-bind="props" />
		</template>
	</v-data-table>
</template>

<script>
import BillableJSON from '../json/billable.json'
import WatchersMenu from './admin-surf/WatchersMenu.vue'

export default {
	components: { WatchersMenu },
	props: {
		items: Array,
		complete: { type: Boolean, default: true },
		paymentView: { type: Boolean, default: false },
		relateView: { type: Boolean, default: false },
		showActions: { type: Boolean, default: false },
		pagination: { type: Object, default: () => ({
			page: 1,
			itemsPerPage: 10,
		}) },
	},
	data: () => ({
		headers: [
			{ text: "Invoice No.", value: "code", align: 'left' },
			{ text: "Customer", value: "customerName", align: 'left', onlyComplete: true },
			{ text: "Country", value: "customerCountry", onlyComplete: true },
			{ text: "Invoice Date", value: "issueDate", align: 'left', width: 115 },
			{ text: "Due Date", value: "paymentDay", align: 'left', onlyComplete: true },
			{ text: "Payment Date", value: "lastPaymentDate", onlyComplete: true },
			{ text: "Projects", value: "projects", align: 'left', sortable: false },
			{ text: "Currency", value: "currency", align: 'center' },
			{ text: "Status", value: "status", align: 'center', sortable: false },
			{ text: "Subtotal", value: "subtotal", align: 'right' },
			{ text: "Invoiced", value: "total", align: 'right' },
			{ text: "Billed", value: "billed", align: 'right', onlyComplete: true },
			{ text: "Total Paid", value: "paidAmount", align: 'right', relateView: true },
			{ text: "Total Paid", value: "paidAmount", align: 'right', paymentView: true },
			{ text: "Pending to Pay", value: "pendingAmount", align: 'right', relateView: true },
			{ text: "Pending to Pay", value: "pendingAmount", align: 'right', paymentView: true },
			{ text: "", value: "actions", width: '1', onlyComplete: true }, 
			{ text: "Distribution", value: "amount", paymentView: true },
		],
    customerProjectTypes: [],
		countries: [
			{ name: 'Mexico', value: 0, icon: 'mxn' },
			{ name: 'USA', 		value: 1, icon: 'usd' },
		],
	}),
	mounted(){
		this.customerProjectTypes = BillableJSON.projectTypes
	},
	computed: {
		shownHeaders(){
			return this.headers.filter(x =>
				(this.complete || !x.onlyComplete) &&
				(this.paymentView || !x.paymentView) &&
				(this.relateView || !x.relateView) &&
				(this.showActions || x.value != 'actions')
			)
		},
	},
	methods: {
		cancelInvoice(item){
			this.$adminSurf('cancelInvoice', item.id)
				.then(res => {
					if(!res) return
					this.$emit('updated')
				})
				.catch(err => {
					this.$root.error('Cannot cancel invoice')
					console.error(err)
				})
		},
	},
}
</script>

<style lang="scss" scoped>
.project{
	display: inline-block;
	border-radius: 15px;
	margin-right: 5px;
	white-space:nowrap;
	div{
		display: inline-flex;
		align-items: center;
		justify-content: center;

	}
	.letter{
		width: 30px;
		padding: 5px;
		color: white;
		border-radius: 15px;
	}
	.name{
		padding: 0 10px 0 5px;
		color: black;
	}
}
</style>