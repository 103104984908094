<template>
	<div class="py-5 px-10 new-back full-height">
		<div class="d-none">
      <input type="file" id="imageUploader" accept="image/*" ref="imageUploader" v-on:change="handleImageUpload"/>
      <input type="file" id="fileUploader" accept="*/*" ref="fileUploader" v-on:change="handleFileUpload"/>
		</div>

		<div v-if="!editing" class="text-right mb-3">
			<v-menu z-index="200" :close-on-content-click="false">
				<template #activator="{ on }">
					<v-btn v-on="on" color="primary" outlined>
						Actions <v-icon right>mdi-chevron-down</v-icon>
					</v-btn>
				</template>
				<v-list>
					<v-list-item v-if="!editing" @click="editing = true">
						<v-list-item-content class="px-3">
							<v-list-item-title class="px-0">
								<v-icon left color="primary">mdi-pencil</v-icon> Edit customer
							</v-list-item-title>
						</v-list-item-content>
					</v-list-item>
				</v-list>
			</v-menu>
		</div>

		<v-row>
			<v-col :cols="3" style="min-width: 680px">
				<v-card class="new-back elevation-0 bordered rounded px-3">
					<v-card-text>
						<v-form v-model="validForm">
							<div class="d-flex" style="gap: 15px">
								<v-avatar @click="openImageUpload" size="60" class="cursor-pointer elevation-2" :style="{background: !item.imageUrl ? 'grey' : 'var(--v-background-lighten)'}">
									<v-icon v-if="!item.imageUrl" size="30" color="white">mdi-camera</v-icon>
									<v-img v-else class="cursor-pointer" contain :src="formatHttp(item.imageUrl)"></v-img>
								</v-avatar>
								
								<div>
									<div>
										<label>Customer / Organization</label>
										<h2>
											<v-text-field
												v-model="item.name" outlined dense :readonly="!editing"
												:rules="rules.concat([
													v => !customers.some(c => normalizeText(c.name, true) == normalizeText(v, true) && c.id != item.id) || 'This Business Name is already in use'
												])"
												class="small-input"
											/>
										</h2>
									</div>
									<div v-if="item.accountManagerId || editing">
										<label>Account Manager</label>
										<h2>
											<q-user-autocomplete
												v-model="item.accountManagerId"
												:confirmable="false" :items="users" :singleLine="false"
												outlined dense :readonly="!editing"
												class="users d-inline-block"
											/>
										</h2>
									</div>
								</div>
								<div>
									<div>
										<label>Small Code</label>
										<h2>
											<v-text-field
												v-model="item.smallCode" outlined dense
												:readonly="!editing"
												@keypress="validateSmallCode"
												@keyup="formatSmallCode"
												:rules="rules.concat([
													v => !customers.some(c => normalizeText(c.smallCode, true) == normalizeText(v, true) && c.id != item.id) || 'This small code is already in use',
													v => v.length === 3 || 'The small code must have 3 characters',
												])"
												class="small-input"
											/>
										</h2>
									</div>
									<div>
										<label>Credit Days:</label>
										<h2>
											<v-text-field
												v-model="item.creditDays" outlined dense :readonly="!editing"
												:rules="$rules.notNegative"
												type="number"
												class="small-input"
											/>
										</h2>
									</div>
								</div>
								<div style="max-width: 150px">
									<label>Country</label>
									<h2>
										<v-select
											v-model="item.country"
											:items="countries"
											item-value="value" item-text="name"
											outlined dense
											:readonly="!editing"
											class="small-input"
										>
											<template #selection="{ item }">
												<v-avatar class="mr-2" size="24">
													<v-img :src="require(`../../assets/currencies/${item.icon}.png`)"/>
												</v-avatar>
												{{ item.name }}
											</template>
											<template #item="{ item }">
												<v-avatar class="mr-2" size="24">
													<v-img :src="require(`../../assets/currencies/${item.icon}.png`)"/>
												</v-avatar>
												{{ item.name }}
											</template>
										</v-select>
									</h2>
								</div>
							</div>

							<div class="mt-5 mb-2 d-flex align-center justify-space-between">
								<h2 class="text--text">Projects</h2>
								<v-btn v-if="editing" @click="addProject" x-small fab color="primary" class="ml-2">
									<v-icon>mdi-plus</v-icon>
								</v-btn>
							</div>

							<div
								v-for="(project, ix) in item.customerProjects" :key="ix"
								class="pb-3 pr-3 mb-3 d-flex justify-space-between project-container"
								:style="{
									borderBottom: ix != item.customerProjects.length-1 ? '2px solid var(--v-lightgray-base)' : ''
								}"
							>
								<template v-if="project.editing">
									<div class="inputs pr-3">
										<div class="pb-0 pr-0">
											<v-text-field
												v-model="project.name" label="Name*"
												outlined hide-details dense :rules="rules"
												style="height: 48px !important"
											/>
										</div>
										<div class="pb-0 pr-0">
											<v-select
												v-model="project.type" label="Type*"
												@change="v => changeProjectType(project, v)"
												:items="customerProjectTypes"
												outlined hide-details dense
												class="select"
												style="height: 48px !important"
											>
												<template v-slot:item="{ item }">
													<div class="my-1">
														<div class="project" :style="{background: item.lightColor}">
															<div class="letter" :style="{background: item.color}">{{item.small}}</div>
															<div class="name">{{item.text}}</div>
														</div>
													</div>
												</template>
												<template v-slot:selection="{ item }">
													<div class="mt-1">
														<div class="project" :style="{background: item.lightColor}">
															<div class="letter" :style="{background: item.color}">{{item.small}}</div>
															<div class="name">{{item.text}}</div>
														</div>
													</div>
												</template>
												<template #append>
													<div></div>
												</template>
											</v-select>
										</div>
										<div class="pb-0 pr-0">
											<q-user-autocomplete
												v-model="project.deliveryManagerId" dense
												:confirmable="false" :items="users" :singleLine="false"
												outlined label="Delivery Manager"
												class="users" ref="userSelection"
												@change="v => changeDeliveryManager(project, v)" hide-append
											/>
										</div>
									</div>
									<div class="delete-btn mt-2">
										<v-btn
											@click="removeProject(ix)"
											fab x-small icon color="error" class="mr-n2"
											:disabled="!canDeleteProject(project)"
										>
											<v-icon>mdi-delete</v-icon>
										</v-btn>
										<v-btn
											@click="$set(project, 'editing', false)"
											fab x-small icon color="primary"
											:disabled="!project.name || (!project.type && project.type !== 0)"
										>
											<v-icon>mdi-check</v-icon>
										</v-btn>
									</div>
								</template>
								<template v-else>
									<div>
										<label>Name</label>
										<div>
											<div class="project" :style="{background: project.typeObject.lightColor}">
												<div
													class="letter cursor-pointer"
													:style="{background: project.typeObject.color}"
												>
													{{project.typeObject.small}}
												</div>
												<div class="name" style="overflow: hidden; max-width: 200px">
													{{project.name}}
												</div>
											</div>
										</div>
									</div>
									<div class="text-right">
										<label>Delivery Manager</label>
										<div class="mt-1">
											<b>
												{{project.deliveryManagerBlueTag || project.deliveryManagerFullName || '-'}}
											</b>
										</div>
									</div>
									<v-btn
										v-if="editing"
										@click="$set(project, 'editing', true)" absolute
										fab x-small icon color="primary" class="edit-btn"
									>
										<v-icon>mdi-pencil</v-icon>
									</v-btn>
								</template>
							</div>
						</v-form>
					</v-card-text>
				</v-card>
			</v-col>
			<v-col>
				<div class="d-flex justify-space-between">
					<h3>Main Contacts</h3>
					<v-btn v-if="editing" @click="openContactDialog" color="primary" fab x-small>
						<v-icon>mdi-plus</v-icon>
					</v-btn>
				</div>
				<v-data-table
					@click:row="(contact, {index}) => editing ? editContact(contact, index) : null"
					:headers="mainContactHeaders" :items="item.customerContacts" :key="contactsKey + '-contacts'"
					class="bordered new-back lighten2 rounded my-3 cursor-pointer"
					disable-pagination hide-default-footer disable-sort
				>
					<template #[`item.actions`]="{ item }">
						<v-btn v-if="editing" @click.stop="removeContact(item)" color="error" icon>
							<v-icon>mdi-delete</v-icon>
						</v-btn>
					</template>
				</v-data-table>

				<div class="d-flex justify-space-between mt-10">
					<h3>Registered RFC</h3>
					<v-btn v-if="editing" @click="openRfcDialog" color="primary" fab x-small>
						<v-icon>mdi-plus</v-icon>
					</v-btn>
				</div>
				<v-data-table
					@click:row="(rfc, {index}) => editing ? editRfc(rfc, index) : null"
					:headers="rfcHeaders" :items="item.rfcs" :key="rfcsKey"
					class="bordered new-back lighten2 rounded my-3 cursor-pointer"
					disable-pagination hide-default-footer disable-sort
				>
					<template #[`item.documents`]="{ item }">
						<div class="my-2">
							<div @click.stop="openFile(file)" v-for="(file, ix) in item.files" :key="ix" class="openable-file cursor-pointer">
								{{file.name}}
							</div>
						</div>
					</template>
					<template #[`item.actions`]="{ item }">
						<v-btn v-if="editing" @click.stop="removeRfc(item)" color="error" icon>
							<v-icon>mdi-delete</v-icon>
						</v-btn>
					</template>
				</v-data-table>

				<div v-if="editing" class="mt-3 text-right">
					<v-btn @click="cancel" color="primary" class="cancel-btn mr-2" text>Cancel</v-btn>
					<v-btn @click="saveCustomer" :disabled="!validForm" color="primary">Save Customer</v-btn>
				</div>
			</v-col>
		</v-row>

		<div class="mt-15">
			<h3>Account Balance</h3>
			<account-balance v-if="id != 'new'" :id="id" class="mt-2"/>
		</div>

		<v-dialog v-model="contactDialog" v-if="contactDialog" persistent width="400">
			<v-card>
				<v-card-title>Add Main Contact</v-card-title>
				<v-card-text>
					<v-form v-model="contactForm">
						<v-text-field label="Name" v-model="newContact.name" :rules="rules"/>
						<v-text-field label="Email" v-model="newContact.email" :rules="emailRules"/>
						<v-text-field label="Phone number" v-model="newContact.phone"/>
						<v-text-field label="Position/Role" v-model="newContact.position"/>
					</v-form>
					<div class="text-right">
						<v-btn @click="contactDialog = false" color="primary" text>Cancel</v-btn>
						<v-btn @click="addContact" :disabled="!contactForm" color="primary">Save Contact</v-btn>
					</div>
				</v-card-text>
			</v-card>
		</v-dialog>

		<v-dialog v-model="rfcDialog" v-if="rfcDialog" persistent width="1000">
			<v-card>
				<v-card-title>Add Business Name</v-card-title>
				<v-card-text>
					<v-form v-model="rfcForm">
						<v-row>
							<v-col :cols="4">
								<v-text-field
									label="Business Name" v-model="newRfc.name"
									:rules="rules.concat([
										v => !rfcs.some(c => normalizeText(c.name, true) == normalizeText(v, true) && c.id != newRfc.id) || 'This Business Name is already in use'
									])"
								/>
							</v-col>
							<v-col :cols="4">
								<v-text-field
									label="RFC" v-model="newRfc.rfc"
									:rules="rules.concat([
										v =>
											v == genericRFC ||
											(
												!rfcs.some(c => normalizeText(c.rfc, true) == normalizeText(v, true) && c.id != newRfc.id) &&
												!item.rfcs.some((x, ix) => normalizeText(x.rfc, true) == normalizeText(v, true) && ix != this.editRfcIndex)
											) ||
											'This RFC is already in use'
									])"
								/>
							</v-col>
							<v-col :cols="4">
								<v-text-field
									label="Trade Name" v-model="newRfc.tradeName"
									:rules="rules.concat([
										v => !rfcs.some(c => normalizeText(c.tradeName, true) == normalizeText(v, true) && c.id != newRfc.id) || 'This Trade Name is already in use'
									])"
								/>
							</v-col>
							<v-col :cols="4">
								<v-text-field label="Address Line" v-model="newRfc.addressLine"/>
							</v-col>
							<v-col :cols="4">
								<v-text-field label="Neighborhood" v-model="newRfc.colony"/>
							</v-col>
							<v-col :cols="4">
								<v-text-field label="ZIP Code" v-model="newRfc.zipCode"/>
							</v-col>
							<v-col :cols="4">
								<v-text-field label="City" v-model="newRfc.city"/>
							</v-col>
							<v-col :cols="4">
								<v-text-field label="State" v-model="newRfc.state"/>
							</v-col>
							<v-col :cols="4">
								<v-text-field label="Country" v-model="newRfc.country"/>
							</v-col>
							<v-col :cols="4">
								<v-text-field label="Legal Representative" v-model="newRfc.legalRepresentative"/>
							</v-col>
						</v-row>
					</v-form>
					<div class="mt-5 file-cont">
						<v-btn @click="openFileUpload" color="primary" class="mr-5">
							<v-icon>attachment</v-icon>
							Attach File
						</v-btn>
						<div v-for="(file, ix) in newRfc.files" :key="ix" class="file">
							<v-icon>mdi-file</v-icon>
							{{file.name}}
							<v-icon @click="removeFile(ix)" class="close cursor-pointer">mdi-close</v-icon>
						</div>
					</div>
					<div class="text-right">
						<v-btn @click="rfcDialog = false" color="primary" text>Cancel</v-btn>
						<v-btn @click="addRfc" :disabled="!rfcForm" color="primary">Save Business Name</v-btn>
					</div>
				</v-card-text>
			</v-card>
		</v-dialog>
	</div>
</template>

<script>
import AutoWidthInput from '../../components/AutoWidthInput.vue';
import BillableJSON from '../../json/billable.json'
import AccountBalance from '../../components/admin-surf/customer/AccountBalance';

export default {
  components: {
		AutoWidthInput,
		AccountBalance,
	},
	props: ["id"],
	data: () => ({
		item: {
      customerProjects: [],
			customerContacts: [],
			rfcs: [],
			active: true,
			smallCode: ''
		},
		editing: false,
		validForm: false,
		rules: [v => (!!v && !!v.trim()) || 'Required'],
		emailRules: [
			(v) => /\S+@\S+\.\S+/.test(v) || 'E-mail must be valid'
		],
		assignments: [],
		customers: [],
		rfcs: [],
		customerProjectTypes: [],
		users: [],
		newImage: null,
		rfcDialog: false,
		newRfc: {},
		editRfcIndex: -1,
		rfcForm: false,
		contactDialog: false,
		newContact: { email: '', phone: '', position: '' },
		editContactIndex: 0,
		contactForm: false,
		files: [],
		genericRFC: 'XEXX010101000',

		mainContactHeaders: [
			{ text: 'Contact name', value: 'name' },
			{ text: 'Contact phone', value: 'phone' },
			{ text: 'Contact email', value: 'email' },
			{ text: 'Position/Role', value: 'position' },
			{ text: '', value: 'actions', width: 0 },
		],
		rfcHeaders: [
			{ text: 'Business name', value: 'name' },
			{ text: 'RFC', value: 'rfc' },
			{ text: 'Legal Representative', value: 'legalRepresentative' },
			{ text: 'Documents', value: 'documents' },
			{ text: '', value: 'actions', width: 0 },
		],
		countries: [
			{ name: 'Mexico', value: 0, icon: 'mxn' },
			{ name: 'USA', 		value: 1, icon: 'usd' },
		],
		rfcsKey: 0,
		contactsKey: 0,
	}),
  created() {
		this.customerProjectTypes = BillableJSON.projectTypes
    this.init();
  },
	mounted() {
		document.title = `${this.id == 'new' ? 'New ' : 'Edit '} customer`
		this.$q.changeHeaderTitle(`${this.id == 'new' ? 'New ' : 'Edit '} customer`);
		if(this.id == 'new')
			this.editing = true
	},
	methods: {
    init() {
			if(this.id != 'new') {
				this.$http.post('api/assignment/list', { customers: [this.id] })
					.then(res => {
						this.assignments = res.data.assignments
					})
					.catch(res => {
						window.getApp.error("Cannot obtain assignments.")
					});
			}
			this.$http.get("/api/customer?getInactive=true&includeImageId=" + this.id)
        .then(res => {
          let customers = res.data
          customers = _.orderBy(customers, ['name'])
          this.customers = customers
					for(let customer of customers){
						for(let rfc of (customer.rfcs || [])){
							this.rfcs.push(rfc)
						}
					}
					let item = customers.find(c => c.id == this.id)
					if(item){
						document.title = item.name
						for(let rfc of item.rfcs){
							if(!rfc.files)
								rfc.files = []
						}
						this.item = item
						this.item.customerProjects.forEach(p => {
							this.$set(p, 'typeObject', this.customerProjectTypes.find(t => t.value == p.type))
						})
					}
        })
        .catch(res => {
					console.log(res)
          window.getApp.error("Cannot obtain customers.")
        });
			this.$http.get('api/user?active=true&onlyInterns=true')
				.then(res => {
					this.users = res.data
				})
				.catch(err => {
					console.log(err)
					window.getApp.error('Cannot obtain users.')
				})
    },
		validateSmallCode(e){
			let key = e.keyCode
			if((key < 65 || key > 90) && (key < 97 || key > 122))
				e.preventDefault()
			if(this.item.smallCode?.length >= 3)
				e.preventDefault()
		},
		formatSmallCode(){
			this.$nextTick(() => {
				this.item.smallCode = this.item.smallCode.toUpperCase()
				this.item.smallCode = this.item.smallCode.slice(0, 3)
			})
		},
		changeProjectType(project, type){
			let typeObject = this.customerProjectTypes.find(x => x.value == type)
			project.typeObject = typeObject
		},
		openRfcDialog() {
			this.newRfc = {
				isNew: true, files: [],
				addressLine: '', colony: '', zipCode: '',
				city: '', state: '', country: '',
				legalRepresentative: '', rfd: '',
			}
			this.editRfcIndex = -1
			this.rfcDialog = true
		},
		async addRfc(){
			const isNew = this.newRfc.isNew
			delete this.newRfc.isNew
			if(this.id != 'new') {
				try {
					const res = await this.$http.post(`api/customer/${this.id}/rfc`, this.newRfc)
					this.newRfc = res.data
					this.$root.success('RFC updated')
				} catch(err) {
					console.error(err)
					this.$root.error('Cannot update RFC')
					return
				}
			}

			if(isNew){
				this.item.rfcs.push(this.newRfc)
			} else {
				this.item.rfcs[this.editRfcIndex] = this.newRfc
			}
			this.rfcsKey++
			this.rfcDialog = false
		},
		editRfc(rfc, index){
			this.newRfc = _.cloneDeep(rfc)
			this.editRfcIndex = index
			this.rfcDialog = true
		},
		removeRfc(rfc){
			this.$vuetifyConfirmDialog.open("Remove RFC", "Are you sure you want to remove this RFC?", "Cancel", "Confirm")
				.then(res => {
          if(!res)
						return
					rfc.deleted = true
					this.item.rfcs = this.item.rfcs.filter(x => !x.deleted)
				})
		},
		openContactDialog() {
			this.newContact = {isNew: true, email: '', phone: '', position: '', }
			this.contactDialog = true
		},
		async addContact(){
			const isNew = this.newContact.isNew
			delete this.newContact.isNew
			if(this.id != 'new') {
				try {
					const res = await this.$http.post(`api/customer/${this.id}/contact`, this.newContact)
					this.newContact = res.data
					this.$root.success('Contact updated')
				} catch(err) {
					console.error(err)
					this.$root.error('Cannot update contact')
					return
				}
			}

			if(isNew){
				this.item.customerContacts.push(this.newContact)
			} else {
				this.item.customerContacts[this.editContactIndex] = this.newContact
			}
			this.contactsKey++
			this.contactDialog = false
		},
		editContact(contact, index){
			this.newContact = _.cloneDeep(contact)
			this.editContactIndex = index
			this.contactDialog = true
		},
		removeContact(contact){
			this.$vuetifyConfirmDialog.open("Remove Contact", "Are you sure you want to remove this contact?", "Cancel", "Confirm")
				.then(res => {
					if(!res)
						return
					contact.deleted = true
					this.item.customerContacts = this.item.customerContacts.filter(x => !x.deleted)
				})
		},
		handleImageUpload(){
			if(this.$refs.imageUploader.files.length){
				this.newImage = this.$refs.imageUploader.files[0]
				this.item.imageUrl = URL.createObjectURL(this.newImage)
				this.$forceUpdate()
			}
		},
		openImageUpload(){
			if(!this.editing) return
			this.$refs.imageUploader.click()
		},
		handleFileUpload(){
			if(this.$refs.fileUploader.files.length){
				let file = this.$refs.fileUploader.files[0]
				let formData = new FormData()
				formData.append('file', file)
				this.$http.put(
					`api/Customer/RFC/image/`,
					formData,
					{ headers: { 'Content-Type': 'multipart/form-data' } }
				).then(res => {
					this.newRfc.files.push({
						fileId: res.data,
						name: file.name
					})
				}).catch((err) => {
					if(file.size > 30000000){
						window.getApp.error("The file exceeds maximum allowed size. (28MB)")
						return
					}
					window.getApp.error("Error while upload file.")
				})
			}
		},
		openFileUpload(){
			this.$refs.fileUploader.click()
		},
		removeFile(index){
			this.$vuetifyConfirmDialog.open("Remove File", "Are you sure you want to remove this file?", "Cancel", "Confirm")
				.then(res => {
          if(!res)
						return
					this.newRfc.files = this.newRfc.files.filter((f, ix) => ix != index)
				})
		},
		openFile(file){
			this.$q.download(
				this.getBackendUrl(`api/Customer/RFC/${file.id || 'new'}/image/${file.fileId}?name=${file.name}`), 'file')
		},
    canDeleteProject(project){
      return !this.assignments.some(a => a.customerProjectId == project.id)
    },
    saveCustomer() {
      this.$q.save({
        api: `/api/customer`,
        data: this.item,
        successMsg: "Customer saved.",
        afterSuccessUrl: null,
        errorMsg: "Cannot save customer.",
        afterErrorUrl: null,
      }).then(res => {
				if(!this.newImage){
					this.$router.push('/admin/customer')
					return
				}
				let formData = new FormData()
				formData.append('file', this.newImage)
				this.$http.put(
					`/api/customer/image/${res.data.id}`,
					formData,
					{ headers: { 'Content-Type': 'multipart/form-data' } }
				).catch(() => {
					window.getApp.error("Error while saving image.")
				}).finally(() => {
					this.$router.push('/admin/customer')
				})
      })
				.catch(err => {
					console.error(err)
					this.$root.error('Cannot save customer')
				})
    },
    addProject(){
      this.item.customerProjects.unshift(
        {
					name: '',
					type: 0,
					typeObject: this.customerProjectTypes[0],
					editing: true,
				},
      )
    },
    removeProject(index){
      this.$vuetifyConfirmDialog.open("Remove Project", "Are you sure you want to remove this project?", "Cancel", "Confirm")
				.then(res => {
          if(!res)
						return
          this.item.customerProjects = this.item.customerProjects.filter((p, ix) => ix != index)
				})
    },
		cancel(){
      this.$vuetifyConfirmDialog.open("Discard Customer", "Are you sure you want to leave this page?", "Cancel", "Confirm")
				.then(res => {
          if(!res)
						return
          this.$router.push('/admin/customer')
				})
		},
		changeDeliveryManager(project, value){
			document.activeElement.blur()
			const user = this.users.find(x => x.id == value)
			if(!user) return
			this.$set(project, 'deliveryManagerBlueTag', user.blueTag)
			this.$set(project, 'deliveryManagerFullName', user.fullName)
		},
	},
}
</script>

<style lang="scss" scoped>
::v-deep .v-input--is-readonly{
	.v-input__slot{
		padding-left: 0 !important;
	}
	fieldset{
		border: none;
	}
}

.rounded{
	border-radius: 20px !important;
	overflow: hidden;
}
.caption{
	line-height: 1.4em;
}
.small-input ::v-deep .v-input__slot{
	height: 35px;
	min-height: 35px !important;
}
.cancel-btn::before{
	opacity: 0;
}

.inputs{
	display: grid;
	grid-template-columns: auto 210px 170px;
	gap: 5px;

	.users ::v-deep .v-select__selections {
		flex-wrap: nowrap !important;
	}
	.users ::v-deep .v-select--is-menu-active .v-select__selections .v-list-item{
		display: none;
	}	
	.select ::v-deep .v-select__selections input {
		display: none;
	}
}
.users ::v-deep .v-select__selections {
	flex-wrap: nowrap !important;
}
.users ::v-deep .v-select--is-menu-active .v-select__selections .v-list-item{
	display: none;
}	
.delivery-input{
	width: 200px;

	::v-deep .v-list-item, ::v-deep .v-input__slot{
		min-height: 40px;
		height: 40px;
	}
	::v-deep .v-avatar, ::v-deep .v-list-item__content{
		padding-top: 0;
		padding-bottom: 0;
		margin-top: 0;
		margin-bottom: 0;
	}
}
.delivery-input ::v-deep .v-select--is-menu-active .v-select__selections .v-list-item {
	display: none;
}
.select ::v-deep .v-select__selections input {
	display: none;
}
.project-container{
	position: relative;
	.delete-btn{
		position: absolute;
		right: -30px;
		top: 0;
	}
	.edit-btn{
		position: absolute;
		right: -25px;
		top: 15%;
	}
}
.project{
	display: inline-block;
	border-radius: 15px;
	white-space:nowrap;
  height: 30px;
	div{
		display: inline-flex;
		align-items: center;
		justify-content: center;
	}
	.letter{
		width: 30px;
		padding: 5px;
		color: white;
		border-radius: 15px;
    height: 30px;
	}
	.name{
		padding: 0 10px 0 5px;
		color: black;
    height: 30px;

		::v-deep input{
			color: black !important;
		}
	}
}
::v-deep .v-input__slot{
	height: 48px;
}
.openable-file{
	font-size: 1em;
	color: var(--v-primary-base);
	text-decoration: underline;
	margin-top: 3px;
}

.file-cont{
	display: flex;
	flex-wrap: wrap;

	.file{
		margin-right: 10px;
		margin-bottom: 10px;
		position: relative;
		border: 1px solid var(--v-text-lighten);
		border-radius: 5px;
		padding: 5px 25px 5px 10px;
		white-space: nowrap;
		overflow: hidden;
		text-overflow: ellipsis;
		display: inline-block;
		max-width: 220px;
		.close{
			position: absolute;
			right: 0px;
		}
		.close:hover{
			color: var(--v-error-base);
		}
	}
}

.dot{
	display: inline-block;
	width: 15px;
	height: 15px;
	border-radius: 50%;
}
.decoration-none{
	text-decoration: none;
}
</style>