var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"pa-5"},[_c('div',{staticClass:"switches"},[_c('h2',{staticClass:"inline-block"},[_c('v-btn',{attrs:{"color":"primary","fab":"","small":""},on:{"click":function($event){return _vm.$router.go(-1)}}},[_c('v-icon',[_vm._v("mdi-arrow-left")])],1),_vm._v(" Traceability Report ")],1),_c('div',{staticClass:"float-right",staticStyle:{"display":"flex","align-items":"center"}},[_c('v-switch',{staticClass:"ma-0 pa-0 ml-3",attrs:{"hide-details":"","inset":"","color":"primary"},model:{value:(_vm.showAllWorkItems),callback:function ($$v) {_vm.showAllWorkItems=$$v},expression:"showAllWorkItems"}}),_c('label',{staticClass:"mr-5"},[_vm._v("Show unrelated")]),_c('v-switch',{staticClass:"ma-0 pa-0 ml-3",attrs:{"hide-details":"","inset":"","color":"primary"},model:{value:(_vm.showSummary),callback:function ($$v) {_vm.showSummary=$$v},expression:"showSummary"}}),_c('label',[_vm._v("Show Summary")])],1)]),_c('div',{staticClass:"traceabilityDiagram background--lighten rounded elevation-4 pa-5 pt-10 "},[_c('div',{staticClass:"information"},[_c('v-menu',{attrs:{"offset-y":"","open-on-hover":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"st",attrs:{"text":""}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-information-outline")])],1)]}}])},[_c('v-card',{staticStyle:{"width":"300px"}},[_c('v-card-text',[_c('v-row',_vm._l((_vm.types),function(type){return _c('v-col',{key:type.value,staticStyle:{"position":"relative"},attrs:{"cols":6}},[_c('v-icon',{attrs:{"color":type.color.substr(0, 7)}},[_vm._v(_vm._s(type.iconClass))]),_vm._v(" "+_vm._s(type.displayName)+" ")],1)}),1)],1)],1)],1)],1),_c('div',{ref:"diagram",staticClass:"text-center",attrs:{"id":"diagram"}}),(_vm.selectedWorkItem)?_c('div',{staticClass:"selected-workitem",style:({
				top: (_vm.selectedWorkItem.y + _vm.selectedWorkItem.height - 10) + 'px', 
				left: (_vm.selectedWorkItem.x + _vm.selectedWorkItem.width - 10) + 'px',
			})},[_c('v-card',[_c('v-card-text',{staticClass:"pa-0 pb-1"},[_c('div',{staticClass:"mb-1 pr-5 pl-2 py-1 white--text",style:({
							textAlign: 'left',
							background: _vm.selectedWorkItem.statusColor,
							borderRadius: '5px 5px 0 0',
						})},[_c('v-icon',{attrs:{"color":"white","x-small":""}},[_vm._v(_vm._s(_vm.selectedWorkItem.statusIconClass))]),_vm._v(" "+_vm._s(_vm.selectedWorkItem.statusName)+" ")],1),_c('h4',{staticClass:"px-2"},[_vm._v(" "+_vm._s(_vm.selectedWorkItem.code)+" - "+_vm._s(_vm.selectedWorkItem.name)+" ")]),(_vm.selectedWorkItem.assignedToBlueTag || _vm.selectedWorkItem.assignedToFullName)?_c('div',{staticClass:"px-2"},[_c('b',[_vm._v("Assigned to:")]),_vm._v(" "+_vm._s(_vm.selectedWorkItem.assignedToBlueTag || _vm.selectedWorkItem.assignedToFullName)+" ")]):_vm._e()])],1)],1):_vm._e()])])}
var staticRenderFns = []

export { render, staticRenderFns }