<template>
	<div class="fill-height new-back pa-5 pb-10">
		<div class="d-flex align-center justify-space-between mb-5">
			<div class="d-flex align-center" style="gap: 10px">
				<q-user-autocomplete
					v-model="filters.userId"
					label="People"
					:items="users"
					save-on-change
					@save="getLogs(true)"
					hide-details dense outlined
					clearable :confirmable="false"
				/>
				<q-date-picker
					v-model="filters.startDate"
					@change="getLogs(true)" 
					label="Start date"
					hide-details
					outlined inner dense clearable
				/>
				<q-date-picker
					v-model="filters.endDate"
					@change="getLogs(true)"
					class="inline-block ml-0"
					label="End date"
					hide-details
					outlined inner dense clearable
				/>
				<v-select
					v-model="filters.logType"
					@change="getLogs(true)"
					:items="logTypes"
					item-text="name" item-value="id"
					outlined hide-details dense clearable
					label="Type" style="max-width: 250px;"
				/>
				<v-select
					v-model="filters.logModule"
					@change="getLogs(true)"
					:items="logModules"
					item-text="name" item-value="id"
					outlined hide-details dense clearable
					label="Module" style="max-width: 250px;"
				/>
				<v-select
					v-model="filters.entityClass"
					@change="getLogs(true)"
					:items="entityClasses"
					item-text="name" item-value="id"
					outlined hide-details dense clearable
					label="Entity/Report" style="max-width: 250px;"
				/>
			</div>
			<div class="d-flex align-center">
				<div small class="excel-btn" text @click="generateExcel">
					<img :src="excelIcon"/>
				</div>
				<v-btn @click="getLogs" color="primary" class="ml-2" depressed>
					<v-icon>refresh</v-icon>
				</v-btn>
			</div>
		</div>
		<v-data-table
			@pagination="getLogs(false)"
			class="rounded-lg bordered new-back lighten2"
			:loading="loading"
			:headers="headers" :items="items"
			:items-per-page.sync="itemsPerPage"
			:page.sync="page"
			:server-items-length="quantity"
			disable-sort
			:footer-props="{ 'items-per-page-options': [10, 15, 20] }"
		>
			<template #[`item.type`]="{ item }">
				{{ getTypeName(item.type) }}
			</template>
			<template #[`item.module`]="{ item }">
				{{ getLogModulesName(item.module) }}
			</template>
			<template #[`item.entityClass`]="{ item }">
				{{ getEntityClassName(item.entityClass) }}
			</template>
			<template #[`item.date`]="{ item }">
				{{ item.dateTime | formatDate }}
			</template>
			<template #[`item.hour`]="{ item }">
				{{ item.dateTime | formatDateCustom('HH:mm') }}
			</template>
		</v-data-table>

		<div class="mt-10">
			<h3>Recent Exports</h3>
			<span v-if="!excels.length">No recent exports</span>
			<div class="d-flex flex-wrap mt-5 pl-2" style="gap: 20px">
				<div
					v-for="excel in excels" :key="excel.id"
					@click="excel.completed ? downloadExcel(excel.id) : null"
					class="d-flex flex-column align-center new-back lighten2 bordered rounded px-5 py-2"
					:class="{ 'cursor-pointer': excel.completed }"
				>
					<img
						v-if="excel.completed"
						width="50" :src="excelIcon"
						class="mb-1"
						:class="$vuetify.theme.isDark ? 'white-excel-icon' : 'green-excel-icon'"
					/>
					<v-icon v-else class="mb-2 mt-1" x-large>mdi-clock-time-four-outline</v-icon>
					<span class="body-2">{{ excel.id.substr(30, 6) }}.xlsx</span>
					<span class="caption mt-n1" style="color: var(--v-text-lighten)">
						By {{ excel.userBlueTag || excel.userFullName }}
					</span>
					<span class="caption mt-n1" style="color: var(--v-text-lighten)">
						{{ excel.dateTime | formatDateCustom('MMMM d, HH:mm') }}
					</span>
				</div>
			</div>
		</div>
	</div>	
</template>

<script>
import excelIcon from '../../assets/excel-icon.svg';

export default {
	data: () => ({
		excelIcon,
		items: [],
		quantity: 0,
		excels: [],
		lastExcelId: '',

		headers: [
			{ text: 'Type', value: 'type' },
			{ text: 'Module', value: 'module' },
			{ text: 'Entity/Report', value: 'entityClass' },
			{ text: 'Register', value: 'register' },
			{ text: 'Date', value: 'date' },
			{ text: 'Hour', value: 'hour' },
			{ text: 'BlueTag', value: 'userBlueTag' },
			{ text: 'Name', value: 'userFullName' },
		],
		filters: {
			logType: null,
			logModule: null,
			entityClass: null,
			startDate: null,
			endDate: null,
			userId: null,
		},

		logTypes: [],
		entityClasses: [],
		logModules: [],

		loading: false,
		itemsPerPage: 10,
		page: 1,
		users: [],
	}),
	created(){
		document.title = 'Log Report'
    this.$q.log(7, 46, 3, 'NA')
		this.getLogs(false)
		this.getExcels()
		this.$http.get('api/enums/logTypes')
			.then(res => {
				this.logTypes = res.data
			})
		this.$http.get('api/enums/logModules')
			.then(res => {
				this.logModules = res.data
			})
		this.$http.get('api/enums/entityClasses')
			.then(res => {
				this.entityClasses = res.data
			})
		this.$http.get('api/user')
			.then(res => {
				this.users = res.data
			})
	},
	methods: {
		getLogs(resetPage){
			if(resetPage) this.page = 1
			this.loading = true
			this.$http
				.get(
					`
						api/log?
						page=${this.page}&
						itemsPerPage=${this.itemsPerPage}&
						type=${this.filters.logType}&
						module=${this.filters.logModule}&
						entityClass=${this.filters.entityClass}&
						startDate=${this.filters.startDate}&
						endDate=${this.filters.endDate}&
						userId=${this.filters.userId}
					`,
					{ headers: { hideLoading: true } }
				)
				.then(res => {
					this.items = res.data.logs
					this.quantity = res.data.quantity
				})
				.finally(() => this.loading = false)
		},
		getTypeName(type){
			return this.logTypes.find(x => x.id == type)?.name
		},
		getEntityClassName(entityClass){
			return this.entityClasses.find(x => x.id == entityClass)?.name
		},
		getLogModulesName(module){
			return this.logModules.find(x => x.id == module)?.name
		},

		downloadExcel(id){
			window.open(this.getBackendUrl('api/log/excel/' + id, '_blank'))
		},
		generateExcel(){
			this.$http.post(`
				api/log/excel?
				type=${this.filters.logType}&
				module=${this.filters.logModule}&
				entityClass=${this.filters.entityClass}&
				startDate=${this.filters.startDate}&
				endDate=${this.filters.endDate}&
				userId=${this.filters.userId}
			`)
				.then(res => {
					this.lastExcelId = res.data.id
					this.excels.unshift(res.data)
					this.getExcels()
				})
				.catch(err => {
					console.error(err)
					this.$root.error('Cannot download excel')
				})
		},
		getExcels(){
			this.$http.get('api/log/excels', { headers: { hideLoading: true }})
				.then(res => {
					this.excels = res.data
				})
				.catch(err => {
					console.error(err)
					this.$root.error('Cannot get excels')
				})
				.finally(() => {
					if(this.lastExcelId && !this.excels.some(x => x.id == this.lastExcelId && x.completed))
						setTimeout(this.getExcels, 5000)
				})
		}
	},
}
</script>

<style lang="scss" scoped>
.excel-btn{
  background: #21a366;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  height: 39px;
  width:  39px;
  color: white;
  border-radius: 50%;
  cursor: pointer;
  
  img{
    filter: invert(99%) sepia(7%) saturate(35%) hue-rotate(255deg) brightness(110%) contrast(99%);
    width: 28px;
  }
}
.white-excel-icon{
	filter: invert(99%) sepia(7%) saturate(35%) hue-rotate(255deg) brightness(110%) contrast(99%);
}
.green-excel-icon{
	filter: invert(46%) sepia(62%) saturate(2406%) hue-rotate(122deg) brightness(92%) contrast(74%);
}
</style>