<template>
	<div>
		<v-data-table
			class="new-back lighten2 rounded-lg bordered overflow-hidden"
			:class="{'fix-first-col': fixFirstCol}"
			hide-default-footer
			v-bind="$attrs" v-on="$listeners"
			:items-per-page="itemsPerPage"
			:page="page"
		>
			<template v-for="(slot, name) in $slots" #[name]="props">
				<slot :name="name" v-bind="props"/>
			</template>
			<template v-for="(slot, name) in $scopedSlots" #[name]="props">
				<slot :name="name" v-bind="props"/>
			</template>

			<template #foot>
				<slot name="foot"></slot>
			</template>

			<template #footer="{ props }">
				<slot name="footer">
					<div v-if="!hideDefaultFooter" class="d-flex footer justify-space-between px-5 py-1">
						<div class="d-flex align-center">
							<v-select
								outlined dense hide-details
								style="width: 50px"
								class="rounded-lg overflow-hidden"
								:items="itemsPerPageOptions"
								item-value="value"
								item-text="text"
								v-model="itemsPerPage"
							></v-select>
							<div class="ml-2 body-1" style="font-size: 20px;">
								Entries per page
							</div>
						</div>
						<div class="d-flex align-center">
							<span class="mr-2">
								{{ props.pagination.pageStart+1 }} - {{ props.pagination.pageStop }} / {{ props.pagination.itemsLength }}
							</span>
							<v-pagination
								v-model="page"
								:length="props.pagination.pageCount"
								:total-visible="0"
							></v-pagination>
						</div>
					</div>
				</slot>
			</template>
		</v-data-table>
	</div>
</template>

<script>
export default {
	props: {
		fixFirstCol: Boolean,
		shownItemsPerPage: { type: Number, default: 10 },
		hideDefaultFooter: Boolean,
		itemsPerPageOptions: { type: Array, default: () => [5, 10, 15, 20, { text: 'All', value: -1 }] },
	},
	data: () => ({
		itemsPerPage: 10,
		page: 1,
	}),
	watch: {
		shownItemsPerPage(val) {
			this.itemsPerPage = val
		}
	}
}
</script>

<style lang="scss" scoped>
.footer{
	border-top: 1px solid var(--v-lightgray-lighten);
}

::v-deep {
	thead th {
		background: var(--v-newDesignBackground-lighten2) !important;
	}

	.v-text-field--outlined fieldset{
		border-color: var(--v-lightgray-base) !important;
	}

	.v-pagination__navigation{
		box-shadow: none !important;
		background: transparent !important;
	}
	.footer{
		.v-input__slot{
			padding: 0 !important;
			padding-left: 8px !important;
			align-items: center !important;
			min-height: 0 !important;
	
			.v-select__selection--comma{
				overflow: visible;
			}
			.v-input__append-inner{
				margin-top: 2px !important;
			}
		}
		.v-select__selections {
			padding: 1px 0 !important;
	
			input{
				display: none !important;
			}
		}
	}

	.fix-first-col{
		table > tbody > tr > td:nth-child(1),
		table > thead > tr > th:nth-child(1) {
			position: sticky !important;
			left: 0;
			z-index: 1;
			background: var(--v-newDesignBackground-lighten2);
			border-top-left-radius: 10px;
			border-right: 1px solid var(--v-lightgray-lighten);
		}
		table > thead > tr > th:nth-child(1) {
			z-index: 1;
		}
	}

	::-webkit-scrollbar-track {
		background: var(--v-newDesignBackground-lighten2);
	}
}
</style>