<template>
	<div class="pa-3 fill-height new-back">
		<filter-search-input
			v-model="filters.search"
			@clear:filters="clearFilters"
			:applied-filters="appliedFilters" outlined dense showClear
		>
			<template #append>
				<div class="d-flex gap-2 align-center">
					<toggle-view v-model="view" :items="views" dense/>
					<v-btn v-if="$security.can('add')" @click="addRecessRequest" color="primary" fab small depressed>
						<v-icon>mdi-plus</v-icon>
					</v-btn>
				</div>
			</template>
			<q-date-picker
				v-model="filters.startDate"
				label="Start Date"
				outlined dense inner hide-details
			/>
			<q-date-picker
				v-model="filters.endDate"
				label="End Date"
				outlined dense inner hide-details
			/>
			<q-user-autocomplete
				v-model="filters.users"
				label="People"
				:items="users"
				multiple
				show-number-of-selection
				hide-details
				dense
				outlined 
				clearable
				:confirmable="false"
			/>
			<q-filter-input
				v-model="filters.departments"
				label="Departments"
				:items="departments"
				item-text="name" item-value="id"
				:parent-shown-items="shownRequests"
				:data-comparer="(req, val) => req.requestorDepartment == val"
				outlined dense clearable hide-details multiple input-select
			/>
			<q-filter-input
				v-model="filters.assignments"
				label="Assignments"
				:items="shownProjects"
				item-text="name" item-value="id"
				:parent-shown-items="shownRequests"
				:data-comparer="(req, val) => req.basicCustomerProjects.some(x => x.id == val)"
				outlined dense clearable hide-details multiple
			/>
			<v-autocomplete
				v-if="view === 0"
				:items="requestStatus"
				item-value="value"
				item-text="name"
				hide-details
				v-model="filters.status"
				outlined
				multiple
				dense
				label="Status"
			>
				<template v-slot:selection="{ attrs, item: user, parent, selected, index }">
					<v-chip v-if="user === Object(user) && index === 0"
						v-bind="attrs" class="accent--lighten" :input-value="selected" label small
					>
						<span class="pr-2">
							{{ user.name }}
						</span>
						<v-icon small @click.stop="parent.selectItem(user)">close</v-icon>
					</v-chip>
					<span
						v-if="index === 1"
						class="grey--text text-caption mt-1 ml-1"
					>
						(+{{ filters.status.length - 1 }})
					</span>
				</template>
			</v-autocomplete>
			<v-checkbox
				label="Assigned to me"
				class="mt-1"
				hide-details
				v-model="filters.assignedToMe"
			/>
		</filter-search-input>
		
		<recess-request-approver-list
			v-if="view == 0"
			@update="init"
			:items="shownRequests"
		/>
		<recess-requests-gantt
			v-else
			:items="shownRequests"
			:start-date="filters.startDate"
			:end-date="filters.endDate"
		/>
		<vacation-request-dialog ref="vacationRequestDialog" user-selection/>
	</div>
</template>

<script>
import moment from 'moment'
import RecessRequestApproverList from '../../components/recess/RecessRequestApproverList';
import RecessRequestsGantt from '../../components/recess/RecessRequestsGantt';
import VacationRequestDialog from "../../dialogs/VacationRequestDialog"

export default {
	components: { RecessRequestApproverList, RecessRequestsGantt, VacationRequestDialog },
	props: {
		userId: { type: String },
	},
	data: () => ({
		view: 0,
		views: [
      { text: 'List', iconClass: 'mdi-clipboard-list-outline' },
      { text: 'Gantt', iconClass: 'mdi-chart-gantt' },
    ],
		requestStatus: [
			{ name: 'Requested', value: 0, background: '#E5E5E5', color: '#00000080' },
			{ name: 'Draft',     value: 1, background: '#E0EDFE', color: '#0270FC' },
			{ name: 'Approved',  value: 2, background: '#CEEAD6', color: '#098129' },
			{ name: 'Rejected',  value: 3, background: '#F8DAD7', color: '#DC4437' },
			{ name: 'Cancelled', value: 4, background: 'red',     color: '#fff' },
		],
		requestApproverStatus : [
			{ name: 'Pending Approval', value: 0, background: '#E5E5E5', color: '#00000080' },
			{ name: 'Draft',            value: 1, background: '#E0EDFE', color: '#0270FC' },
			{ name: 'Approved',         value: 2, background: '#CEEAD6', color: '#098129' },
			{ name: 'Rejected',         value: 3, background: '#F8DAD7', color: '#DC4437' },
			{ name: 'Cancelled',        value: 4, background: 'red',     color: '#fff' },
		],

		filters: {
			status: [],
			users: [],
			assignments: [],
			departments: [],
			assignedToMe: false,
			startDate: '',
			endDate: '',
		},
		currentUser: {},
		users: [],
		departments: [],
		requests: [],
	}),
	computed: {
		shownRequests() {
			let requests = this.requests
      if(this.filters.users.length) {
        requests = requests.filter(x => this.filters.users.includes(x.requestorId))
      }
			if(this.view === 0 && this.filters.status.length) {
				requests = requests.filter(x => this.filters.status.includes(x.status))
			}
			if(this.filters.assignedToMe) {
				requests = requests.filter(x => x.requestApprovers.some(r => r.approverId == this.currentUser.id))
			}
			if(this.filters.assignments.length) {
				requests = requests.filter(x => x.basicCustomerProjects.some(p => this.filters.assignments.includes(p.id)))
			}
			if(this.filters.departments.length) {
				requests = requests.filter(x => this.filters.departments.includes(x.requestorDepartment))
			}
			if(this.filters.startDate) {
				requests = requests.filter(x => x.endDate && moment(x.endDate).isSameOrAfter(this.filters.startDate))
			}
			if(this.filters.endDate) {
				requests = requests.filter(x => x.startDate && moment(x.startDate).isSameOrBefore(this.filters.endDate))
			}


			requests = this.applySearch(this.filters.search, requests, [
				'requestorBlueTag',
				'requestorFullName',
				'requestorJobTitle',
				'requestorComments',
			])

			return requests
		},
		appliedFilters(){ return this.countActiveFilters(this.filters, ['search']) },
		shownProjects() {
			return this.requests
				.flatMap(x => x.basicCustomerProjects)
				.filter((v, i, a) => a.findIndex(t => (t.id === v.id)) === i)
		},
	},
	created() {
    document.title = 'Recess Requests'
    this.$q.log(8, 47, 3, 'NA')

		if(this.userId) {
			this.filters.users = [this.userId]
			this.filters.assignedToMe = false
		}

		this.$http.get('api/enums/departments')
			.then(res => {
				this.departments = res.data
			})

		this.init()
	},
	methods: {
		init() {
			this.$blueSystem.getCurrentUser()
        .then(user => {
          this.currentUser = user
        })
				.catch(error => {
					console.error(error)
					this.$root.error('error getting current user')
				})
				.finally(this.getRequests)
			this.$http.get('api/user?onlyInterns=true')
        .then(res => {
          this.users = res.data
        })
		},
		getRequests() {
			this.$http.get('api/recess/requests/all')
				.then(res => {
					this.requests = res.data.map(item => {
						let canEdit = true
						if(
							!item.requestApprovers.some(x =>
								x.approverId == this.currentUser.id &&
								x.status == this.requestApproverStatus.find(x => x.value == 0).value
							) ||
							item.statusIsFinal ||
							item.status == this.requestStatus.find(x => x.value == 1).value
						) {
							canEdit = false
						}
						return {
							...item,
							canEdit,
						}
					})
				})
				.catch(err => {
					console.error(err)
					this.$root.error('error getting recess requests')
				})
		},
		addRecessRequest() {
			this.$refs.vacationRequestDialog.open()
				.then(this.init)
		},
		clearFilters() {
			this.filters = {
				status: [],
				users: [],
				assignments: [],
				departments: [],
				assignedToMe: false,
				startDate: this.view === 1 ? moment().startOf('day').format('YYYY-MM-DD') : '',
				endDate: this.view === 1 ? moment().add(1, 'month').endOf('day').format('YYYY-MM-DD') : '',
			}
		},
	},
	watch: {
		view() {
			if(this.view === 1) {
				this.filters.startDate = moment().startOf('day').format('YYYY-MM-DD')
				this.filters.endDate = moment().add(1, 'month').endOf('day').format('YYYY-MM-DD')
			}
			else {
				this.filters.startDate = ''
				this.filters.endDate = ''
			}
		},
	},
}
</script>

<style lang="scss" scoped>

</style>