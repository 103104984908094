import _ from "lodash"
import moment from 'moment'

export default function OnTimeReportingPersonalChart (
  title, 
  data) {
        
  let weeks = 12;
  let start = moment().subtract((weeks-1)*7, 'days').startOf("isoWeek");

  // Construct x categories
  let xCategories = [];
  let prevMonth = '';
  for (let current = moment(start); current.isSameOrBefore(moment(), 'week'); current.add(7, 'day')) {
    let monthName = current.format('MMM');
    if (monthName === prevMonth) {
      monthName = '';
    } else {
      prevMonth = monthName;
    }
    xCategories.push(monthName);
  }

  let chartData = data.map(d => ({
    y: moment(d.date).isoWeekday() - 1,
    x: moment(d.date).diff(start, 'week'),
    value: d.delay,
    dateFormatted: moment(d.date).format("(ddd) MMM D, YYYY"),
    delayFormatted: d.delay > 0 ? `${Number(d.delay).toFixed(1)} days delayed` : 'No delay',
    executed: d.executedHours,
  }));


  return {
    chart: {
      type: 'heatmap',
      marginTop: 40,
      marginBottom: 80,
      plotBorderWidth: 1
    },


    title: {
      text: title,
    },

    xAxis: {
      min: 0,
      max: weeks - 1,
      categories: xCategories,
      // categories: ['Alexander', 'Marie', 'Maximilian', 'Sophia', 'Lukas', 'Maria', 'Leon', 'Anna', 'Tim', 'Laura']
    },

    yAxis: {
      min: 0,
      max: 5,
      categories: ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'],
      title: null,
      reversed: true
    },

    colorAxis: {
      stops: [
          [0, '#34e813'],
          [0.01, '#efd51d'],
          [0.1, '#efd51d'],
          [0.3, '#ff0000'],
          [1, '#770000'],
      ],
      min: 0,
      max: 10,
      tickInterval: 1,
    },

    legend: {
      align: 'right',
      layout: 'vertical',
      margin: 0,
      verticalAlign: 'top',
      y: 25,
      symbolHeight: 280
    },

    tooltip: {
      formatter: function () {
        return `<b>${this.point.dateFormatted}</b><br>Reported ${this.point.executed} hrs<br>${this.point.delayFormatted}`;// + `<br>(${this.point.x},${this.point.y})`;
      }
    },

    series: [{
      name: 'S',
      borderWidth: 1,
      borderColor: '#FFFFFF',
      data: chartData,
    }],

    responsive: {
      rules: [{
        condition: {
          maxWidth: 500
        },
        chartOptions: {
          yAxis: {
            labels: {
              formatter: function () {
                return this.value.charAt(0);
              }
            }
          }
        }
      }]
    }
  };
  
}
  