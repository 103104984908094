<template>
  <div class="pa-2 new-back full-height">
    <v-row>
      <v-col cols="3" class="create-container">
        <h2 style="display: inline-block">Bank List</h2>
        <h4 style="display: inline-block">
          <v-btn @click="openCreateBankDialog" icon color="primary" class="mt-n1 ml-n1">
            <v-icon>mdi-plus-circle-outline</v-icon>
          </v-btn>
          ADD NEW
        </h4>
      </v-col>
    </v-row>
    <v-row v-if="!banks || !banks.length">
      <v-col cols="3" >
        <div class="new-banks-container rounded">
          <span style="display: block">No banks registered yet</span>
          <v-btn
            class="mt-2" 
            color="primary"
            @click="openCreateBankDialog"
          >
            Add Bank
          </v-btn>
        </div>
      </v-col>
    </v-row>
    <template v-else>
      <v-row v-for="(item, ix) in banks"  :key="ix" class="px-2 mb-2">
        <v-col cols="3" @click="item.model = !item.model" class="bank-container px-4 mx-2 mb-2 cursor-pointer">
          <h3>
            {{item.name}}
          </h3>
          <v-icon color="primary">{{ item.model ? 'mdi-chevron-right' : 'mdi-chevron-left' }}</v-icon>
        </v-col>
        <v-col v-if="item.model" class="accounts-container px-4 mx-2">
          <div class="accounts-title">
            <h3>Accounts</h3>
            <div>
              <h4 style="display: inline-block">
                <v-btn @click="openCreateAccountDialog(item.id)" icon color="primary" class="mt-n1 ml-n1">
                  <v-icon>mdi-plus-circle-outline</v-icon>
                </v-btn>
                ADD NEW
              </h4>
            </div>
          </div>
          <div class="add-account-container" v-if="!item.accounts || !item.accounts.length">
            <span style="display: block">No accounts registered yet</span>
            <v-btn
              class="mt-2" 
              color="primary"
              @click="openCreateAccountDialog(item.id)"
            >
              Add Account
            </v-btn>
          </div>
          <div class="st mb-2">
            <div style="width: 30%">ACCOUNT NICKNAME</div>
            <div style="width: 20%">CARD TYPE</div>
            <div style="width: 30%">ACCOUNT NUMBER</div>
            <div style="width: 20%">CURRENCY</div>
            <div class="st1">ACTIONS</div>
          </div>
          <div class="st mb-2" v-for="(account, ixt) in item.accounts" :key="ixt">
            <div style="width: 30%">{{account.name}}</div>
            <div style="width: 20%">{{ getCardTypeText(account.cardType) }}</div>
            <div style="width: 30%">{{account.accountNumber}}</div>
            <div style="width: 20%">
              <v-avatar color="indigo" size="32" class="mr-1">
                <v-img :src="require(`../../assets/currencies/${account.currency.toLowerCase()}.png`)" />
              </v-avatar>
              <span>{{account.currency}}</span></div>
            <div class="st1">
              <div>
                <v-icon class="cursor-pointer action-icon" color="primary" @click="openEditAccountDialog(item.id, account, ix, ixt)">edit</v-icon>
                <v-icon class="cursor-pointer pl-1 action-icon" color="error" @click="deleteAccount(account.id, ix)">delete</v-icon>
              </div>
            </div>
          </div>
        </v-col>
      </v-row>
    </template>
    <confirm-form-dialog
      ref="confirmFormDialogForBank"
      :depressed="true"
    />
    <confirm-form-dialog
      ref="confirmFormDialogForAccount"
      :depressed="true"
      :individualForm="true"
      :form="accountModel"
    >
    <v-form v-model="accountModel">
      <v-row>
        <v-col cols="6">
          <v-text-field
            class="px-3"
            v-model="account.name"
            :rules="[
              value => !!value || 'The nickname is required',
            ]"
          >
          <template v-slot:label >
            <h3>NickName*</h3>
          </template>
          </v-text-field>
        </v-col>
        <v-col cols="6">
          <v-select
            class="px-3 pt-3 select"
            dense
            :items="currencies"
            v-model="account.currency"
            item-text="text"
            item-value="text"
          >
          <template v-slot:selection="{ item }">
            <div class="padding-top">{{item.text}}</div>
          </template>
          <template v-slot:label >
            <h3>Currency*</h3>
          </template>
          </v-select>
        </v-col>
        <v-col cols="6">
          <v-text-field
            class="px-3"
            v-model="account.accountNumber"
            type="number"
            :rules="accountNumberRules"
            label="Account Number*"
          >
          <template v-slot:label >
            <h3>Account Number*</h3>
          </template>
          </v-text-field>
        </v-col>
        <v-col cols="6">
          <v-select
            class="px-3 pt-3 select"
            dense
            :items="cardTypes"
            v-model="account.cardType"
            label="Card Type*"
            item-text="text"
            item-value="value"
          >
            <template v-slot:selection="{ item }">
              <div class="padding-top">{{item.text}}</div>
            </template>
            <template v-slot:label >
              <h3>Card Type*</h3>
            </template>
          </v-select>
        </v-col>
      </v-row>
    </v-form>
    </confirm-form-dialog>
  </div>
</template>

<script>
import ConfirmFormDialog from '../../dialogs/ConfirmFormDialog.vue'
import _ from "lodash"

export default {
  components: {
    ConfirmFormDialog
  },
  data: () => ({
    account: {
      name: '',
      currency: 'MXN',
      cardType: 0,
    },
    currentBankId: '',
    accountNumberRules: [
      value => !!value || 'The account Number is required',
    ],
    accountModel: false,
    currencies: [
			{ text: 'MXN' },
			{ text: 'USD' },
		],
    cardTypes: [
			{ value: 0, text: 'Credit Card' },
			{ value: 1, text: 'Debit Card' },
		],
    banks: [],
  }),
  mounted() {
    this.init()
  },
  methods: {
    openCreateBankDialog() {
      this.$refs.confirmFormDialogForBank.open()
        .then(data => {
          this.createBank({ name: data.name })
        })
    },
    getCardTypeText(cardTypeValue) {
      const cardType = this.cardTypes.find((type) => type.value === cardTypeValue);
      return cardType?.text || '';
    },
    openCreateAccountDialog(id) {
      this.currentBankId = id
      this.$refs.confirmFormDialogForAccount.open()
        .then(() => {
          this.createAccount()
        })
        .catch(() => {
          this.resetCurrentAccount()
        })
    },
    openEditAccountDialog(id, newAccount, bank, accIndex) {
      this.currentBankId = id
      this.account = _.cloneDeep(newAccount)
      this.$refs.confirmFormDialogForAccount.open()
        .then(() => {
          this.editAccount(bank, accIndex)
        })
        .catch(() => {
          this.resetCurrentAccount()
        })
    },
    createBank(bank) {
      this.$http.post(`/api/bank`, bank).then(() => {
        this.init()
        window.getApp.success('Bank registered successfully')
      })
      .catch((error) => {
        window.getApp.error(error)
      })
    },
    createAccount() {
      if(!this.currentBankId)
        return
      let account = _.cloneDeep(this.account)
      account.bankId = account.bankId ? account.bankId : this.currentBankId
      account.accountNumber = account.accountNumber + ''
      this.$http.post(`/api/bankAccount`, account)
        .then(res => {
          let acc = res.data
          this.banks.find(item => item.id == acc.bankId).accounts.push(acc)
          this.resetCurrentAccount()
          window.getApp.success('Account created successfully')
        })
        .catch((error) => {
          window.getApp.error('The account was not created')
        })
    },
    editAccount(bank, accIndex) {
      if(!this.currentBankId)
        return
      let account = _.cloneDeep(this.account)
      this.$http.post(`/api/bankAccount`, account)
        .then(res => {
          let acc = res.data
          this.banks[bank].accounts[accIndex] = acc
          this.resetCurrentAccount()
          window.getApp.success('Account updated successfully')
        })
        .catch((error) => {
          window.getApp.error('The account was not updated')
          this.resetCurrentAccount()
        })
    },
    deleteAccount(id, index) {
      this.$vuetifyConfirmDialog.open("Delete Account", "Are you sure you want to delete this account?", "Cancel", "Confirm")
        .then(confirmed => {
          if (!confirmed) return
          this.$http.delete(`/api/bankAccount/${id}`)
            .then(() => {
              this.banks[index].accounts = this.banks[index].accounts.filter(acc => acc.id != id)
              window.getApp.success('Account deleted successfully')
            })
            .catch((error) => {
              window.getApp.error('The account was not deleted')
            })
        })
    },
    resetCurrentAccount() {
      this.currentBankId = ''
      this.account = {
        name: '',
        currency: 'MXN',
        cardType: 0,
        accountNumber: '',
      }
    },
    init() {
      this.$http.get(`/api/bank`)
        .then(res => {
          let banks = res.data
          banks = banks.map((item) => ({
            ...item,
            model: false,
          }))
          this.banks = banks
        })
    }
  }
}
</script>

<style lang="scss" scoped>

.new-banks-container {
  width: 100%;
  border: 2px solid grey;
  min-height: 150px;
  height: auto !important;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}
.create-container {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
}
.bank-container {
  height: 70px;
  border: 3px solid var(--v-primary-base);
  border-radius: 5px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.accounts-container {
  min-height: 70px; 
  border: 2px solid grey;
  border-radius: 10px;
}
.accounts-title {
  display: flex;
  justify-content: space-between;
}
.add-account-container {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  height: 100px;
}
.st {
  display: flex;
  width: 100%;  
  align-items: center;
}
.padding-top {
  padding: 8px 0 !important;
}
.select {
  margin-top: 1.5px !important;
}
.st1 {
  width: 10%;
  display: flex;
  justify-content: flex-end;
}
.action-icon:hover {
  opacity: .7;
}
</style>