<template>
    <v-dialog :fullscreen="$vuetify.breakpoint.xsOnly"
			scrollable :persistent="newRiskCreation || currentlyEditingElement"
      v-model="dialog" :width="1100"  v-if="dialog && risk"
			@keydown.esc="cancel" @click:outside="cancel"
		>
			<v-form ref="form" v-model="validForm">
				<v-card >
					<div class="main-container">
						<div class="form-container" :style="{ backgroundColor: 'rgba(22, 123, 215, 0.08) !important', padding: '20px' }">
							<v-card-title class="pt-2 pb-4 title-container">
								<div>
									{{
										risk.id ?
										risk.code.replace('-', ' ') :
										'New Risk'
									}}
								</div>
								<div>
									<span 
										v-if="risk.id"
										class="severity"
										:style="{
											color: getPropertyBySeverity(risk.probability, risk.impact, 'color'),
											borderColor: getPropertyBySeverity(risk.probability, risk.impact, 'color')
										}"
									>
										Severity: {{ getPropertyBySeverity(risk.probability, risk.impact, 'name') || "" }}
									</span>
								</div>
							</v-card-title>
							<v-card-text class="txt">
							<br>
								<v-select
									outlined
									:items="riskStatus"
									item-text="name"
									label="Treatment *"
									:readonly="!projectId || !canEditProject"
									v-model="risk.status"
									:rules="[v => (!!v || v === 0) || 'Required']"
									@change="saveOnChange"
								></v-select>
								<v-select
									v-if="!overviewView"
									outlined
									:items="viewStatus"
									item-text="name"
									label="Risk Type *"
									:readonly="!projectId || !canEditProject"
									v-model="risk.internalViewOnly"
									:rules="[v => (!!v || v === 0) || 'Required']"
									@change="saveOnChange"
								></v-select>
								<v-autocomplete
									v-if="!projectId"
									readonly
									outlined
									v-model="risk.projectId"
									label="Project *" ref="project" 
									:persistent-hint="true"
									:items="projects" item-value="id" item-text="name"
									:rules="[v => (!!v || v === 0) || 'Required']"
									:filter="(item, queryText, itemText) => (item.customerProduct + ': ' + item.name).toLocaleLowerCase().indexOf(queryText.toLocaleLowerCase()) > -1"
								>
									<template v-slot:selection="{ item }">
										<category-chip small :code="item.customerProduct" :text="item.name"></category-chip>
									</template>
									<template v-slot:item="{ item }">
										<v-list-item-content>
											<v-list-item-title>
												<span>{{item.name}}</span>
											</v-list-item-title>
											<v-list-item-subtitle v-text="item.customerProduct"></v-list-item-subtitle>
										</v-list-item-content>
									</template>
								</v-autocomplete>
								<v-col>
									<h4>
										Description
										<v-icon
											v-on:click="infoDialog = true"
											:style="{ fontSize: '20px' }"
										>
											mdi-information-outline
										</v-icon>
									</h4>
								</v-col>
							<v-textarea
								label="Threat *"
								outlined
								:readonly="!projectId || !canEditProject"
								:rows="(projectId || canEditProject) ? 3 : 2"
								no-resize
								v-model="risk.description"
								:rules="[v => !!v || 'Required']"
								@focus="editElement('description')"
								@blur="saveEditElement('description')"
							></v-textarea>
							<v-textarea
								label="Vulnerability"
								outlined
								:readonly="!projectId || !canEditProject"
								:rows="(projectId || canEditProject) ? 3 : 2"
								no-resize
								v-model="risk.vulnerability"
								@focus="editElement('vulnerability')"
								@blur="saveEditElement('vulnerability')"
							></v-textarea>
							<v-row>
								<v-col cols="6">
									<v-select
										:items="probabilityImpactStatus"
										label="Probability *"
										item-text="name"
										outlined
										:readonly="!projectId || !canEditProject"
										dense
										v-model="risk.probability"
										:rules="[v => !!v || 'Required']"
										@change="saveOnChange"
									></v-select>
								</v-col>
								<v-col cols="6">
									<v-select
										:items="probabilityImpactStatus"
										item-text="name"
										label="Impact *"
										outlined
										:readonly="!projectId || !canEditProject"
										dense
										v-model="risk.impact"
										:rules="[v => !!v || 'Required']"
										@change="saveOnChange"
									></v-select>
								</v-col>
							</v-row>
								<v-card-actions
									:style="{
										display: 'flex',
										justifyContent: 'center'
									}"
									v-if="newRiskCreation"
								>
									<v-btn color="primary" text @click="cancel">
										{{ !!projectId ? 'Cancel' : 'Close' }}
									</v-btn>
									<v-btn v-if="projectId && canEditProject" color="primary" depressed @click="save" :disabled="!validForm">
										{{
											risk.id ?
											'Save' :
											'Create'
										}}
									</v-btn>
								</v-card-actions>
							</v-card-text>
						</div>
						<div class="right-container">
							<div class="right-title">
								<h2>Actions</h2>
								<div>
									<v-icon
										v-if="risk.id || !newRiskCreation"
										:style="{ fontSize: '26px' }"
									>
										mdi-clipboard-check-outline
									</v-icon>
									<span v-if="risk.id || !newRiskCreation" :style="{ fontSize: '15px' }" class="workitems-quantity">
										{{ workItemsDone(risk.workItems ? risk.workItems : [])}}/{{ risk.workItems ? risk.workItems.length : 0 }}
									</span>
									<v-btn
										fab
										color="primary"
										@click="editWorkItem"
										small
										class="ml-1"
										v-if="projectId && canEditProject"
									>
										<v-icon dark>add</v-icon>
									</v-btn>
								</div>
							</div>
							<div class="wi-container">
								<work-item-list
									v-for="(wi, ix) in newRiskCreation ? workItemsToCreate : risk.workItems"
									@click="editWorkItem(wi, ix)"
									@statusChange="init(risk.id)"
									:readonly="!projectId || !canEditProject"
									:key="wi.id || ix"
									class="wi-individual-container"
									:item="wi"
									:statuses="projectConfig.workItemStatuses.filter(st => st.id != wi.statusId)"
									:isNew="newRiskCreation"
									:deleteFunc="deleteWorkItem"
								/>
							</div>
						</div>
					</div>
				</v-card>
					<risk-work-item-dialog
						ref="riskWorkItemDialog"
						:projectId="projectId"
						:risks="risks"
						:newRiskCreation="newRiskCreation"
					></risk-work-item-dialog>
					<v-dialog
						v-model="infoDialog"
						persistent
						max-width="370"
					>
						<v-card>
							<br>
							<v-card-text :style="{ fontSize: '17px' }">
								<h4>Risk Example: </h4>
									"Riesgo de retraso del proyecto, por curva de aprendizaje del Desarrollador"
									<br />
									<br />
									<b>Threat: </b>: "Riesgo de retraso del proyecto..." 
									<br />
									Potencial ocurrencia de un hecho que pueda manifestarse.
									<br/>
									<br/>
									<b>Vulnerability:  </b>
									: "...por curva de aprendizaje del Desarrollador." 
									<br/>
									Debilidad o grado de exposición de un sujeto, objeto o persona. Efecto que va a suceder en un proyecto a causa de la Amenaza.
							</v-card-text>
							<v-card-actions class="dialog-actions">
								<v-btn
									color="primary"
									class="mb-2 mr-2"
									@click="infoDialog = false"
									:style="{ width: '140px !important', }"
								>
									Got it
								</v-btn>
							</v-card-actions>
						</v-card>
					</v-dialog>
			</v-form>
    </v-dialog>
</template>
<script>
import RiskWorkItemDialog from './RiskWorkItemDialog';
import WorkItemList from '../components/WorkItemList.vue';
import moment from "moment"
import _ from 'lodash'
export default {
	props: {
			projectId: { default: null },
			risks: { default: [] },
			canEditProject: { type: Boolean, default: false },
			isEmployee: { type: Boolean, default: false },
			overviewView: { type: Boolean, default: false },
	},
	components: {
		RiskWorkItemDialog,
		WorkItemList
	},
	data(){
		return	{
			validForm: false,
			dialog: false,
			infoDialog: false,
			risk: {},
			riskStatus: [
					{ name: 'Active', value: 0, color: "" },
					{ name: 'Eliminated', value: 1, color: "" },
					{ name: 'Materialized', value: 2, color: "" },
			],
			viewStatus: [
					{ name: 'Internal', value: 0 },
					{ name: 'External', value: 1 },
			],
			probabilityImpactStatus: [
					{ name: 'Very low', value: 1 },
					{ name: 'Low', value: 2 },
					{ name: 'Medium', value: 3 },
					{ name: 'High', value: 4 },
					{ name: 'Very High', value: 5 },
			],
			projects: [],
			profilePicFailover:
				"https://cdn.vuetifyjs.com/images/parallax/material2.jpg",
			headersWorkItems: [
								{ text: "Description", value: "name" },
								{ text: "Status", value: "statusName" },
								{ text: "Assigned to", value: "assignedToBlueTag" },
								{ text: "Due Date", value: "dueDate" },
			],
			workItems: [],
			workItemsAdded: false,
			projectConfig: { workItemStatuses: [] },
			newRiskCreation: false,
			workItemsToCreate: [],
			severityValue: [
				{ name: "Critical", value: [20, 25], color: "#ff6968", backColor: "#f5b5b560" },
				{ name: "High", value: [12, 15, 16], color: "#efab45", backColor: "#ffd28e60" },
				{ name: "Medium", value: [5, 6, 8, 9, 10], color: "#e9cc75", backColor: "#fff1c460" },
				{ name: "Low", value: [1, 2, 3, 4], color: "#72bb6d", backColor: "#b5f1b160" },
			],
			currentlyEditingElement: false,
			original: {
				'description': '',
				'vulnerability': ''
			},
			profilePicFailover: 'https://cdn.vuetifyjs.com/images/parallax/material2.jpg',
		}
	},
	mounted() {
		if(this.projectId && this.canEditProject){
			this.headersWorkItems.push( { text: "", value: "actions", sortable: false } )
		}
	},
	methods:{
	saveOnChange() {
		if(this.newRiskCreation) return
		this.currentlyEditingElement = true
		this.save()
	},
	saveEditElement(property) {
		if(this.newRiskCreation) return
		if(this.original[property] !== this.risk[property]) {
			this.saveOnChange()
		} else {
			this.currentlyEditingElement = false
		}
	},
	editElement(property) {
		if(this.newRiskCreation) return
		this.currentlyEditingElement = true
		this.original[property] = this.risk[property]
	},
	getPropertyBySeverity(probability, impact, property) {
		let value = ''
		this.severityValue.forEach(severity => {
			if(severity.value.includes(probability * impact)) {
				value = severity[property]
				return
			}
		})
		return value;
	},
	deleteWorkItem(e, ix) {
		e.stopPropagation()
		this.workItemsToCreate.splice(ix, 1)
	},
	addNewWorkItem(wi, index) {
		wi.temporalId = wi.temporalId ? wi.temporalId : Date.now()
		if(!isNaN(index)) {
			this.$set(this.workItemsToCreate, index, wi)
			return
		}
		this.workItemsToCreate.push(wi)
	},
	workItemsDone(workItems) {
		if(workItems.length) {
			return workItems.filter(wi => wi.status.isFinal).length
		}
		return 0
	},
	save(){
		if(!this.validForm)
			return
		let riskToSave = JSON.parse(JSON.stringify(this.risk))
		riskToSave.description = JSON.stringify(riskToSave.description || "")
		riskToSave.vulnerability = JSON.stringify(riskToSave.vulnerability || "")
		riskToSave.internalViewOnly = !!riskToSave.internalViewOnly
		if(this.overviewView) {
			riskToSave.internalViewOnly = true
		}
		let projectId = this.projectId || riskToSave.projectId
		if(!this.projectId && !riskToSave.id)
			delete riskToSave.projectId
		this.$http.post(`api/Project/${projectId}/Risk`, {...riskToSave, workItems: null}).then(res => {
			if(!res.data.id){
					window.getApp.error(res.data)
					return
			}
			if(this.risk.id)
					window.getApp.success("Risk updated successfully.")
			else
					window.getApp.success("Risk created successfully.")
			if(this.newRiskCreation && !!this.workItemsToCreate.length) {
				let wis = JSON.parse(JSON.stringify(this.workItemsToCreate))
				wis.forEach(wi => {
					delete wi.assignedTo
					delete wi.assignedToBlueTag
					delete wi.status
					delete wi.temporalId
					wi.projectRiskId = res.data.id
				})
				this.$q.save({
					api: `/api/WorkItems`,
					data: wis,
					successMsg: 'Work items saved successfully',
					afterSuccessUrl: null,
					errorMsg: "Cannot save work items.",
					afterErrorUrl: null,
				}).then(() => {
					this.resolve({id: res.data.id, risk: this.newRiskCreation})
					this.cancel()
				})
				return
			}
			if(!this.currentlyEditingElement) {
				this.dialog = false
				this.resolve({id: res.data.id, risk: this.newRiskCreation})
			}
		}).catch(response => {
			window.getApp.error("Cannot save risk.")
			console.log(response)
		})
		.finally(() => {
			this.currentlyEditingElement = false 
		});
	},
	assignedProfilePicSrc(id) {
		let src = `api/user/${id}/profilePicture`;
		if (!this.$http.defaults.baseURL.endsWith("/")) src = "/" + src;
		return this.$http.defaults.baseURL + src
	},
	init(id) {
		this.dialog = true
		this.newRiskCreation = false
		if(!id){
			this.risk = { 
				status: 0,
				internalViewOnly: 0
			}
			this.newRiskCreation = true
		} else {
			this.$http.get(`api/Project/Risk/${id}`).then(res => {
				this.risk = res.data
				this.risk.internalViewOnly = +this.risk.internalViewOnly
				this.risk.description = JSON.parse(this.risk.description)

				this.risk.workItems = this.risk.workItems.filter(wi => !wi.deleted).map(wi => {
					wi.assignedToBlueTag = wi.assignedTo ? wi.assignedTo.blueTag : 'Unassinged'
					wi.statusName = wi.status.name
					if(wi.assignedTo) {
						wi.assignedTo.profilePicSrc = this.assignedProfilePicSrc(wi.assignedTo.id)
					}
					wi.dueDate = wi.dueDate ? moment(wi.dueDate).format('MMM DD, YYYY') : ''
					return wi
				})
			})
			const projectId = this.projectId || this.risk.projectId
			if(!this.projectConfig.workItemStatuses.length) {
				this.$http.get(`api/project/${projectId}/config?active=true`).then(res => {
					this.projectConfig = res.data
					this.projectConfig.workItemStatuses = this.projectConfig.workItemStatuses.filter(s => s.workItemType === 3)
				})
			}
		}
		if(!this.projectId){
			this.$http
				.get("/api/user/config/project")
				.then(res => {
					this.projects = res.data;
				})
				.catch(res => {
					window.getApp.error("Cannot get records.");
					console.log(res);
				});
		}
	},
	open(id) {
		this.init(id)
		return new Promise((resolve, reject) => {
			this.resolve = resolve
			this.reject = reject
		})
	},
	reload(){
		this.init(this.risk.id)
		this.workItemsAdded = true
	},
	cancel(){
		if(this.currentlyEditingElement) return
		if(this.newRiskCreation) {
			this.workItemsToCreate = []
			this.newRiskCreation = !this.newRiskCreation
		}
		this.dialog = false
		this.reject()
	},
	editWorkItem(wi = {}, index) {
		this.$refs.riskWorkItemDialog.open(wi?.id || null, this.risk.id, null, JSON.parse(JSON.stringify(wi)))
			.then(res => {
				if(this.newRiskCreation) {
					this.addNewWorkItem(res, index)
					return
				}
				this.reload()
			});
	},
	}
}
</script>
<style scoped>
.color-black{
    color: black !important;
}
.severity{
    height: 40px;
    display: flex;
    align-items: center;
}
.txt::-webkit-scrollbar {
    width: 5px;
    height: 5px;
}
.txt::-webkit-scrollbar-thumb{
  background: #AED6F1;
}
.evaluation--checkbox {
  padding-top: 8px;
}
.main-container {
	display: flex;
	width: 100%;
	flex-direction: row !important;
	flex-wrap: nowrap;
}
.form-container {
	width: 40%
}
.wi-container {
	width: 100%;
	display: flex;
	flex-direction: column;
	align-items: center;
	gap: 20px;
	max-height: 547px;
	padding: 20px 0;
	overflow-y: auto;
	box-sizing: border-box;
}
.right-container {
	width: 60%;
	padding: 20px 20px 20px 0;
}
.right-title {
	display: flex;
	padding: 0 30px;
	justify-content: space-between;
}
.title-container {
	display: flex;
	justify-content: space-between;
}
.severity {
	border: 1px solid;
	border-radius: 4px;
	font-size: 15px;
	height: 25px;
	padding: 1px 5px;
}
.dialog-actions {
	display: flex;
	justify-content: center
}
</style>