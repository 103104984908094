<template>
	<v-dialog v-if="value" v-model="value" width="600" @click:outside="value = false">
		<v-card>
			<v-card-title class="pt-5">
				<v-btn @click="value = false" absolute top right icon><v-icon>mdi-close</v-icon></v-btn>
				
				<user-avatar :userId="member.userId" hideLoading/>
				<div class="d-flex flex-column ml-5">
					<b>{{member.assignment.userBlueTag}}</b>
					<span class="subtitle-1 mt-n2">{{member.assignment.userFullName}}</span>
				</div>
			</v-card-title>

			<v-card-text class="pt-5">

				<v-row>
					<v-col>
						<label>Role</label>
						<v-text-field
							@change="saveRole" v-model="member.assignment.billableLineRole"
							disabled outlined dense hide-details
						/>
					</v-col>
					<v-col>
						<label>Start Date</label>
						<q-date-picker
							:value="member.startDate" label=""
							disabled outlined dense inner hide-details
						/>
					</v-col>
				</v-row>

				<v-row class="mb-3">
					<v-col>
						<label>Customer Perception</label>
						<div class="btn-container">
							<v-btn v-for="(color, ix) in semaphoreColors" :key="ix"
								@click="saveCustomerPerception(ix)"
								depressed :color="member.customerPerception == ix ? color.color : 'grey'"
								outlined
							>
								<v-icon>{{color.icon}}</v-icon>
							</v-btn>
						</div>
					</v-col>
					<v-col>
						<label>Nuclei Happiness</label>
						<div class="btn-container">
							<v-btn v-for="(color, ix) in semaphoreColors" :key="ix"
								@click="saveNucleiHappiness(ix)"
								depressed :color="member.nucleiHappiness == ix ? color.color : 'grey'"
								outlined
							>
								<v-icon>{{color.icon}}</v-icon>
							</v-btn>
						</div>
					</v-col>
				</v-row>

				<label>Requirements</label>
				<v-row>
					<v-col v-for="req in member.requirements" :key="req.id" :cols="6" class="pb-0">
						<div class="box pa-2 rounded">
							<v-checkbox
								v-model="req.checked"
								@change="e => toggleRequirement(req, e)" :label="req.requirementName"
								class="mt-0 pt-0" hide-details
							/>
						</div>
					</v-col>
				</v-row>
				
				<br><br>
				<div class="d-flex justify-space-between align-center mb-2">
					<h2>Highlights</h2>
					<v-btn @click="openHighlightDialog" class="primary" fab x-small><v-icon>mdi-plus</v-icon></v-btn>
				</div>
				<div
					v-if="!member.highlights.length"
					class="text-center background--lighten py-2"
				>
					No highlights found
				</div>
				<div v-for="high in member.highlights" :key="high.id" class="flex-space-between align center my-2 box rounded pa-2">
					<div class="d-flex">
						<user-avatar :userId="high.createdById" hide-loading/>
						<div class="ml-3">
							<div class="text--text multiline mb-2">
								{{high.description}}
							</div>
							<span class="caption">{{high.createdAt | formatDateTime}} by {{high.createdByBlueTag}}</span>
						</div>
					</div>
					<div class="d-flex">
						<v-tooltip top >
              <span>Edit</span>
              <template v-slot:activator="{ on }">
                <div class="d-flex align-center">
                  <v-btn v-on="on" color="primary" class="ml-1" icon @click="editHighlightDialog(high)">
                    <v-icon>edit</v-icon>
                  </v-btn>
                </div>
              </template>
            </v-tooltip>
						<v-tooltip top >
              <span>Delete</span>
              <template v-slot:activator="{ on }">
                <div class="d-flex align-center">
                  <v-btn v-on="on" color="red" class="ml-1" icon @click="deleteHiglight(high.id)">
                    <v-icon>mdi-trash-can-outline</v-icon>
                  </v-btn>
                </div>
              </template>
            </v-tooltip>
					</div>
				</div>

			</v-card-text>
		</v-card>

		<v-dialog v-if="showNewHighlight" v-model="showNewHighlight" width="500" persistent>
			<v-card>
				<v-card-title>
					{{highlight.id ? 'Edit' : 'New'}} highlight
				</v-card-title>
				<v-card-text>
					<v-textarea v-model="highlight.description" outlined :rows="3" hide-details />
				</v-card-text>
				<v-card-actions class="justify-end">
					<v-btn @click="showNewHighlight = false" color="primary" outlined>Cancel</v-btn>
					<v-btn
						@click="saveHighlight"
						color="primary"
						:disabled="!highlight.description"
					>
						{{highlight.id ? 'Update' : 'Save'}}</v-btn>
				</v-card-actions>
			</v-card>
		</v-dialog>
	</v-dialog>
</template>

<script>
import ConfirmFormDialog from './ConfirmFormDialog'

export default {
	props: {
		id: { Type: String, Default: '' },
	},
	components: { ConfirmFormDialog },
	data: () => ({
		showNewHighlight: false,
		highlight: {},
		semaphoreColors: [
			{ color: 'error', icon: 'mdi-emoticon-sad-outline' },
			{ color: '#fdc37f', icon: 'mdi-emoticon-neutral-outline' },
			{ color: 'success', icon: 'mdi-emoticon-excited-outline' }
		],
		value: false,
		member: false,
	}),
	methods: {
		open(member) {
			this.member = member;
			if(!member.customerPerception && member.customerPerception !== 0)
				this.member.customerPerception = 2
			this.value = true
		},
		changeMemberByProperty(property, value){
			return this.$http.post(
				`api/nucleiManagement/member/${this.member.id}/${property}/${value}`,
				null,
				{ headers: { hideLoading: true } }
			)
			.catch(err => {
				console.error(err)
				this.$root.error('Cannot update member')
			})
		},
		saveCustomerPerception(value){
			this.changeMemberByProperty('customerPerception', value)
				.then(res => {
					this.member.customerPerception = res.data.customerPerception
					this.member.customerPerceptionColor = res.data.customerPerceptionColor
					this.member.customerPerceptionIcon = res.data.customerPerceptionIcon
				})
		},
		saveNucleiHappiness(value){
			this.changeMemberByProperty('nucleiHappiness', value)
				.then(res => {
					this.member.nucleiHappiness = res.data.nucleiHappiness
					this.member.nucleiHappinessColor = res.data.nucleiHappinessColor
					this.member.nucleiHappinessIcon = res.data.nucleiHappinessIcon
				})
		},
		saveRole(value){
			this.changeMemberByProperty('role', value)
				.then(res => {
					this.member.assignment.billableLineRole = res.data.assignment.billableLineRole
				})
		},
		toggleRequirement(requirement, val){
			this.$http.post(
				`api/nucleiManagement/requirement/${requirement.id}/${val}`,
				null,
				{ headers: { hideLoading: true } }
			)
				.catch(err => {
					requirement.checked = !val
					console.error(err)
					this.$root.error('Cannot update requirement')
				})
		},
		openHighlightDialog(){
			this.highlight = {}
			this.showNewHighlight = true
		},
		editHighlightDialog(highlight){
			this.highlight = JSON.parse(JSON.stringify(highlight))
			this.showNewHighlight = true
		},
		saveHighlight(){
			if(this.highlight.id) {
				this.updateHighlight()
				return
			}
			this.$http.post(`api/nucleiManagement/highlight`, {
				userProjectId: this.member.id,
				description: this.highlight.description,
			})
				.then(res => {
					this.member.highlights.unshift(res.data)
					this.showNewHighlight = false
					this.$root.success('Highlight created successfully')
				})
				.catch(err => {
					console.error(err)
					this.$root.error('Cannot save highlight')
				})
		},
		updateHighlight(){
			this.$http.put(`api/nucleiManagement/highlight`, this.highlight)
				.then(res => {
					let index = this.member.highlights.indexOf(this.member.highlights.find(hg => hg.id == res.data.id))
					this.member.highlights[index] = res.data
					this.highlight = {}
					this.showNewHighlight = false
					this.$root.success('Highlight updated successfully')
				})
				.catch(err => {
					console.error(err)
					this.$root.error('Cannot save highlight')
				})
		},
		deleteHiglight(id){
			this.$http.delete(`api/nucleiManagement/highlight/${id}`)
				.then(res => {
					this.member.highlights = this.member.highlights.filter(high => high.id != res.data)
					this.$root.success('Highlight deleted successfully')
				})
				.catch(err => {
					console.error(err)
					this.$root.error('Cannot delete highlight')
				})
		},
	},
}
</script>

<style lang="scss" scoped>
.box{
	border: 1px solid var(--v-lightgray-base);
}

.selected{
	box-shadow: 0 0 10px var(--v-primary-base);
}

.btn-container{
	display: grid;
	grid-template-columns: 1fr 1fr 1fr;
	gap: 10px;

	.v-btn{
		height: 40px;
	}
}
</style>