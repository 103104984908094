<template>
  <v-dialog :fullscreen="$vuetify.breakpoint.xsOnly" scrollable persistent 
    v-model="dialog" :width="550" v-if="dialog">
		<v-card class="card">
			<v-card-title style="justify-content: center">
				<div class="pb-5">
					<v-img width="80" src="../assets/reportReminder/danger.png" v-if="blocked"></v-img>
					<v-img width="80" src="../assets/reportReminder/warning.png" v-else></v-img>
				</div>
			</v-card-title>
			<v-card-text>
				<p :class="{blocked}">On the last {{blocked ? '3 days' : 'day'}} you have not reported on time.</p>
				<p :class="{blocked}">Late reporting affects activity timing precision, metrics and overall visibility of the projects you are working on. Please report on the same day the activity is performed.</p>
				<p :class="{blocked}" v-if="blocked"><b>To continue, please make your daily report.</b></p>
				<div class="text-right">
					<v-btn v-if="!blocked" class="mt-10" style="margin-right: 10px; padding: 0 30px" @click="cancel">Close</v-btn>
					<v-btn class="primary mt-10" style="margin-right: -20px" @click="goToDailyReport">Daily Report</v-btn>
				</div>
			</v-card-text>
		</v-card>
	</v-dialog>
</template>

<script>
export default {
	props: ['dialog', 'blocked'],
	methods: {
		cancel(){
			this.$emit('update:dialog', false)
		},
		goToDailyReport(){
			this.$emit('update:dialog', false)
			this.$router.push('/dailyReport')
		},
	},
}
</script>

<style lang="scss" scoped>
.card{
	padding: 37px;
	padding-bottom: 0;
	border-radius: 20px;
}
p{
	font-family: 'Roboto';
	color: #c87601;
	font-size: 1.15em;
}
p.blocked{
	color: var(--v-error-base);
}
</style>