var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.dialog)?_c('v-dialog',{attrs:{"fullscreen":_vm.$vuetify.breakpoint.xsOnly,"scrollable":"","persistent":"","max-width":550},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-form',{ref:"form"},[_c('v-card',{staticStyle:{"padding":"20px"}},[_c('v-card-title',{staticClass:"pt-2 pb-4"},[_vm._v(" "+_vm._s(_vm.workItem.id ? "Edit Work Item" : "New Work Item")+" ")]),_c('v-card-text',{staticClass:"pt-2"},[(!_vm.projectId)?_c('v-autocomplete',{ref:"project",staticStyle:{"width":"100%"},attrs:{"disabled":true,"outlined":"","dense":"","label":"Project *","persistent-hint":true,"items":_vm.projects,"item-value":"id","item-text":"name","filter":function (item, queryText, itemText) { return (item.customerProduct + ': ' + item.name).toLocaleLowerCase().indexOf(queryText.toLocaleLowerCase()) > -1; }},on:{"change":_vm.changeProject},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
var item = ref.item;
return [_c('category-chip',{attrs:{"small":"","code":item.customerProduct,"text":item.name}})]}},{key:"item",fn:function(ref){
var item = ref.item;
return [_c('v-list-item-content',[_c('v-list-item-title',[_c('span',[_vm._v(_vm._s(item.name))])]),_c('v-list-item-subtitle',{domProps:{"textContent":_vm._s(item.customerProduct)}})],1)]}}],null,false,375398691),model:{value:(_vm.workItem.projectId),callback:function ($$v) {_vm.$set(_vm.workItem, "projectId", $$v)},expression:"workItem.projectId"}}):_vm._e(),_c('v-row',[_c('v-col',[_c('v-autocomplete',{attrs:{"items":_vm.newRiskCreation ? _vm.risks : _vm.risks.filter(function (r) { return r.projectId == _vm.workItem.projectId; }),"item-value":function (item) { return item.id; },"outlined":"","dense":"","label":"Risk *","disabled":_vm.disableRiskChange || _vm.readonly},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
var item = ref.item;
return [_c('v-icon',{staticClass:"mr-2",attrs:{"color":item.color}},[_vm._v(" mdi-alert-circle ")]),_vm._v(" "+_vm._s(item.code)+" ")]}},{key:"item",fn:function(ref){
var item = ref.item;
return [_c('v-icon',{attrs:{"color":item.color}},[_vm._v(" mdi-alert-circle ")]),_vm._v(" "+_vm._s(item.code)+" ")]}}],null,false,4130616028),model:{value:(_vm.workItem.projectRiskId),callback:function ($$v) {_vm.$set(_vm.workItem, "projectRiskId", $$v)},expression:"workItem.projectRiskId"}})],1),_c('v-col',[_c('v-select',{attrs:{"dense":"","outlined":"","label":"Status *","disabled":!!_vm.workItem.id,"item-value":"id","item-text":"name","items":this.projectConfig.workItemStatuses},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{staticClass:"cursor-pointer mt-2 mb-2",style:({ width: '100%' }),attrs:{"label":"","dark":"","color":item.color}},[_c('v-icon',{staticClass:"mr-2"},[_vm._v(_vm._s(item.iconClass))]),_vm._v(" "+_vm._s(item.name)+" ")],1)]}},{key:"selection",fn:function(ref){
var item = ref.item;
return [_c('v-icon',{staticClass:"mr-2",attrs:{"color":item.color}},[_vm._v(_vm._s(item.iconClass))]),_vm._v(" "+_vm._s(_vm.setMaxText(item.name, 25))+" ")]}}],null,false,4162365990),model:{value:(_vm.workItem.statusId),callback:function ($$v) {_vm.$set(_vm.workItem, "statusId", $$v)},expression:"workItem.statusId"}})],1)],1),_c('v-text-field',{attrs:{"outlined":"","label":"Description *","readonly":_vm.readonly},model:{value:(_vm.workItem.name),callback:function ($$v) {_vm.$set(_vm.workItem, "name", $$v)},expression:"workItem.name"}}),_c('v-row',[_c('v-col',[_c('v-autocomplete',{attrs:{"label":"Assigned to","items":_vm.users,"item-value":"id","item-text":"blueTag","filter":function (item, queryText, itemText) { return _vm.normalizeIncludes(item.blueTag + ' ' + item.fullName, queryText); },"outlined":"","dense":"","readonly":_vm.readonly},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var item = ref.item;
return [_c('v-list-item-avatar',{staticClass:"headline font-weight-light white--text",attrs:{"color":"indigo"}},[_vm._v(" "+_vm._s((item.blueTag || '').charAt(0))+" ")]),_c('v-list-item-content',[_c('v-list-item-title',{domProps:{"textContent":_vm._s(item.blueTag)}}),_c('v-list-item-subtitle',{domProps:{"textContent":_vm._s(item.fullName)}})],1)]}},{key:"selection",fn:function(ref){
var item = ref.item;
return [_c('v-list-item-content',[_c('v-list-item-title',{domProps:{"textContent":_vm._s(item.blueTag)}})],1)]}}],null,false,448263474),model:{value:(_vm.workItem.assignedToId),callback:function ($$v) {_vm.$set(_vm.workItem, "assignedToId", $$v)},expression:"workItem.assignedToId"}})],1),_c('v-col',[_c('v-menu',{attrs:{"close-on-content-click":false,"nudge-right":40,"transition":"scale-transition","offset-y":"","min-width":"auto","readonly":_vm.readonly},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"label":"Due Date","prepend-icon":"mdi-calendar","readonly":"","outlined":"","dense":"","clearable":""},model:{value:(_vm.workItem.dueDate),callback:function ($$v) {_vm.$set(_vm.workItem, "dueDate", $$v)},expression:"workItem.dueDate"}},'v-text-field',attrs,false),on))]}}],null,false,1434685152),model:{value:(_vm.menu),callback:function ($$v) {_vm.menu=$$v},expression:"menu"}},[_c('v-date-picker',{attrs:{"no-title":"","readonly":_vm.readonly},on:{"input":function($event){_vm.menu = false}},model:{value:(_vm.workItem.dueDate),callback:function ($$v) {_vm.$set(_vm.workItem, "dueDate", $$v)},expression:"workItem.dueDate"}})],1)],1)],1)],1),_c('v-card-actions',{staticClass:"px-6"},[_c('v-spacer'),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.showWarning),expression:"showWarning"}],staticClass:"status-warning"},[_c('div',{staticStyle:{"width":"25px"}},[_c('v-icon',{attrs:{"color":"#cc3300"}},[_vm._v("warning")])],1),_c('div',{staticClass:"txt"},[_vm._v(" It is necessary to have at least one status of type task ")])]),_c('v-btn',{attrs:{"color":"primary","text":""},on:{"click":_vm.cancel}},[_vm._v(" Cancel ")]),_c('v-btn',{attrs:{"color":"primary","depressed":"","disabled":_vm.showWarning || !_vm.projectId},on:{"click":_vm.save}},[_vm._v(" "+_vm._s(_vm.workItem.id || _vm.newRiskCreation ? "Save" : "Create")+" ")])],1)],1)],1)],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }