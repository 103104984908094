<template>
	<v-btn
		@click="$emit('input', !value)"
		:color="value ? trueColor : falseColor"
		icon
	>
		<v-icon>{{ value ? trueIcon : falseIcon }}</v-icon>
	</v-btn>
</template>

<script>
export default {
	props: {
		value: { type: Boolean, default: false },
		trueIcon: { type: String, default: 'mdi-check-bold' },
		falseIcon: { type: String, default: 'mdi-close-thick' },
		trueColor: { type: String, default: 'success' },
		falseColor: { type: String, default: 'error' },
	},
}
</script>