// import 'core-js/es6/promise' 
// import 'core-js/es6/array'
 
import Vue from 'vue'
import VueConfetti  from 'vue-confetti'
import axios from 'axios'
import router from './router'
import moment from 'moment'

import Highcharts from "highcharts";
import Gantt from "highcharts/modules/gantt";
import HighchartsVue from 'highcharts-vue'

import VueGapi from 'vue-gapi'
import Q from './plugins/q'
import BlueSystem from './plugins/blue-system'
import BlueSecurity from './plugins/blue-security'
import Rules from './plugins/rules'
import AdminSurf from './plugins/admin-surf'
import IndicatorsMixin from './plugins/IndicatorsMixin'
import textMixin from './plugins/textMixin'
import billablesMixin from './plugins/billablesMixin'
import NumberMixin from './plugins/number-mixin'
import vuetify from './plugins/vuetify'
import store from './store'
// import { sync } from 'vuex-router-sync'
import App from './components/AppRoot'
import confirmDialog from 'vuetify-vuejs-confirmdialog'
import "./filters/moneyFilter"
import "./filters/dateFilters"
import "./filters/numberFilter"
// import config from './config'
import './css/site.css'
import './css/admin.scss'
import './css/site.scss'
import './css/vars.css'
import titleMixin from './plugins/web-title'
import actions from './plugins/actions'
import userMention from './plugins/user-mention'
import { ClearOnSelect, OnlyNumbers } from './plugins/forms'

import { LMap, LTileLayer, LMarker, LIcon, LTooltip } from 'vue2-leaflet';
import 'leaflet/dist/leaflet.css';

(async () => {

    // const config = await (await fetch("/config.json")).json()
    
    const response = await fetch('/config.json')
    const config = await response.json()

    window._config = config

    axios.defaults.baseURL = config.backendUrl 
    axios.defaults.withCredentials = true

    axios.get(`api/google`)
        .then(res => {
            Vue.use(VueGapi, {
                apiKey: res.data.apiKey,
                clientId: res.data.clientId,
                discoveryDocs: ['https://www.googleapis.com/discovery/v1/apis/calendar/v3/rest'],
                scope: 'https://www.googleapis.com/auth/calendar.readonly https://www.googleapis.com/auth/calendar.events https://www.googleapis.com/auth/userinfo.email https://www.googleapis.com/auth/userinfo.profile',
            })
        })
    
    Vue.component('l-map', LMap);
    Vue.component('l-tile-layer', LTileLayer);
    Vue.component('l-marker', LMarker);
    Vue.component('l-icon', LIcon);
    Vue.component('l-tooltip', LTooltip);

    Vue.use(VueConfetti)
    Vue.use(confirmDialog)
    Vue.use(Q)
    Vue.use(IndicatorsMixin)
    Vue.use(textMixin)
    Vue.use(billablesMixin)
    Vue.use(NumberMixin)
    Vue.use(BlueSystem)
    Vue.use(AdminSurf)
    Gantt(Highcharts);
    Vue.use(HighchartsVue)
    
    Vue.prototype.$http = axios
    BlueSecurity.setup(axios)
    Vue.prototype.$security = BlueSecurity
    Vue.prototype.$rules = Rules

    Vue.mixin(titleMixin)
    Vue.mixin(actions)

    Vue.directive('user-mention', userMention)
    Vue.directive('clear-on-select', ClearOnSelect)
    Vue.directive('only-numbers', OnlyNumbers)

    router.beforeEach(async (to, from, next) => {
        let res = await Vue.prototype.$security.navigateHandler(to, from)
        if(res === '/login'){
            let redirect = { query: { redirect: window.location.pathname + window.location.search } }
            if(window.location.pathname === '/login') redirect = {}
            return next({path: '/login', ...redirect})
        }
        if(typeof res === 'string') return next(res)
        if(!res){
            window.getApp.error('You don\'t have enough permission')
            return next('/forbidden')
        }
        if(to.meta.title || to.meta.title === '')
            store.commit('definedMainTitle', to.meta.title)
        store.commit('setPreviousRedirect', !!to.meta.addPreviousRedirect)
        next()
    })

    const app = new Vue({
        store,
        router,
        vuetify,
        ...App
    })

    // Configure moment
    moment.updateLocale('en', {
        week: {
            dow: 1, // Configure Monday to be de first day of week
        }
    })


    axios.interceptors.request.use(
        conf => {
            app.$emit('before-request', conf)
            conf.headers.get.Pathname = window.location.pathname
            return conf
        },
        error => {
            app.$emit('request-error', error)
            return Promise.reject(error)
        }
    )
    axios.interceptors.response.use(
        response => {
            app.$emit('after-response', response)
            return response
        },
        error => {
            app.$emit('response-error', error)

            if (error.response?.data?.message) {
                error.notified = true
                app.error(error.response.data.message)
            }
            
            return Promise.reject(error)
        }
    )


    if (document.getElementById("app") != null) {
        app.$mount('#app');
    }

})()

export {
}
