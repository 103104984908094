import Vue from "vue"
import moment from "moment"

Vue.filter('limitDecimals', function(value, decimalsLimit = 2) {
  let number = parseFloat(value)
	let factor = Math.pow(10, decimalsLimit)
	return Math.floor(number * factor) / factor
});

Vue.filter('roundUp', function(value) {
  let number = parseFloat(value)
	return Math.ceil(number)
});

Vue.filter('minutesToTime', function(value) {
  let number = parseFloat(value)
	let unit = 'minutes'

	if(number === 0) return '-'

	if(number == 1) unit = 'minute'
	else if(number < 60) unit = 'minutes'
	else {
		number /= 60
		
		if(number == 1) unit = 'hour'
		else if(number < 24) unit = 'hours'
		else {
			number /= 24
			
			if(number == 1) unit = 'day'
			else if(number < 30) unit = 'days'
			else {
				number /= 30

				if(number == 1) unit = 'month'
				else unit = 'months'
			}
		}
	}

	return Math.round(number) + ' ' + unit
});
