var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"px-5"},[_vm._m(0),_c('div',{staticClass:"mb-5"},[_c('v-data-table',{staticClass:"mt-2",attrs:{"headers":_vm.billableItemHeaders,"items":_vm.billableItems,"hide-default-footer":"","disable-pagination":"","show-expand":"","single-expand":"","item-class":function () { return 'cursor-pointer'; }},on:{"click:row":function (item, data) { return data.expand(!data.isExpanded); }},scopedSlots:_vm._u([{key:"item.data-table-expand",fn:function(ref){
var isExpanded = ref.isExpanded;
return [_c('div',{staticClass:"d-flex align-center bordered darken rounded"},[_c('v-icon',[_vm._v("mdi-chevron-"+_vm._s(isExpanded ? 'up' : 'down'))])],1)]}},{key:"item.startDate",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("formatDate")(item.startDate))+" ")]}},{key:"item.endDate",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("formatDate")(item.endDate))+" ")]}},{key:"item.status",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{staticStyle:{"border-radius":"20px"},style:({
						background: _vm.$vuetify.theme.isDark ? '' : item.statusLightColor
					}),attrs:{"color":item.statusColor,"outlined":"","small":""}},[_vm._v(_vm._s(item.statusDisplayName))])]}},{key:"expanded-item",fn:function(ref){
					var headers = ref.headers;
					var item = ref.item;
return [_c('td',{attrs:{"colspan":headers.length}},[_c('div',{staticClass:"py-8 px-3"},[_c('v-data-table',{staticClass:"bordered rounded-lg background--lighten",attrs:{"headers":_vm.billableItemLineHeaders,"items":item.billableItemLines,"hide-default-footer":"","disable-pagination":"","disable-sort":""},scopedSlots:_vm._u([{key:"item.startDate",fn:function(ref){
					var billableItemLine = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("formatDate")((billableItemLine.assignmentStartDate || billableItemLine.billableItemStartDate)))+" ")]}},{key:"item.endDate",fn:function(ref){
					var billableItemLine = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("formatDate")((billableItemLine.assignmentEndDate || billableItemLine.billableItemEndDate)))+" ")]}},{key:"item.workingDays",fn:function(ref){
					var billableItemLine = ref.item;
return [_vm._v(" "+_vm._s(billableItemLine.hours / 8)+" ")]}}],null,true)})],1)])]}}],null,true)})],1)])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"d-flex align-center mt-8 mb-3"},[_c('h3',[_vm._v("Billable Items")])])}]

export { render, staticRenderFns }