
<template>
  <div class="pa-3 new-back fill-height">

    <filter-search-input v-model="filters.search" @input="searchChanged" :appliedFilters="appliedFilters" outlined dense>
      <template #append>
        <v-row justify="end" class="mb-n5 mr-1">
          <v-tooltip top>
            <template v-slot:activator="{ on }">
              <div small class="excel-btn" text v-on="on" @click="exportToExcel">
                <img :src="excelIcon"/>
              </div>
            </template>
            <span>Download Excel</span>
          </v-tooltip>
          <v-menu v-model="menu" :close-on-content-click="false" left offset-x z-index="200">
            <template v-slot:activator="{ on }">
              <div>
                <v-btn v-on="on" color="primary" small fab>
                  <v-icon>mdi-table-eye</v-icon>
                </v-btn>
              </div>
            </template>
            <v-card color="high-back" width="200">
              <v-list color="high-back" dense>
                <v-list-item v-for="header in headers" :key="header.value" v-show="header.text">
                  <v-list-item-action>
                    <v-checkbox
                      dense color="primary"
                      v-model="showCol[header.value]"
                    />
                  </v-list-item-action>
                  <v-list-item-title>
                    {{header.text}}
                  </v-list-item-title>
                </v-list-item>
              </v-list>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="primary" text @click="menu = false">Close</v-btn>
              </v-card-actions>
            </v-card>
          </v-menu>
        </v-row>
      </template>

      <v-select
        v-model="filters.projectStatuses" :items="projectStatuses"
        @change="fetchWorkItems"
        label="Project Status"
        item-value="id" item-text="name"
        outlined multiple hide-details dense clearable
      />

      <v-autocomplete
        v-model="filters.projects" :items="shownProjects"
        @change="fetchWorkItems"
        label="Project"
        item-value="id" item-text="name"
        :filter="(item, queryText, itemText) => (item.customerProduct + ': ' + item.name).toLocaleLowerCase().indexOf(queryText.toLocaleLowerCase()) > -1"
        outlined multiple hide-details dense clearable
      >
        <template v-slot:selection="{ item }">
          <category-chip small :code="item.customerProduct" :text="item.name"></category-chip>
        </template>
        <template v-slot:item="{ item, attrs }">
          <v-list-item-action>
            <v-checkbox v-model="attrs.inputValue"></v-checkbox>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title>
              <span>{{item.name}}</span>
            </v-list-item-title>
            <v-list-item-subtitle>{{ item.customerProduct }}</v-list-item-subtitle>
          </v-list-item-content>
        </template>
      </v-autocomplete>

      <v-autocomplete
        v-model="filters.statusNames" :items="statuses"
        @change="fetchWorkItems"
        outlined multiple hide-details dense clearable
        label="Status"
      />

      <v-select
        v-model="filters.types" :items="types"
        @change="fetchWorkItems"
        label="Type" item-value="value" item-text="displayName"
        outlined dense hide-details multiple clearable
      />

      <v-autocomplete
        v-model="filters.priorities" :items="priorities"
        @change="fetchWorkItems"
        outlined multiple hide-details dense clearable
        label="Priority" item-value="value" item-text="displayName" 
      >
        <template v-slot:item="{ item, attrs }">
          <v-list-item-action>
            <v-checkbox v-model="attrs.inputValue"></v-checkbox>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title>
              <v-icon :color="item.color" class="mr-2">{{item.iconClass}}</v-icon>
              {{item.displayName}}
            </v-list-item-title>
          </v-list-item-content>
        </template>
        <template v-slot:selection="{ attrs, item, parent, selected }">
          <v-chip v-if="item === Object(item)"
            v-bind="attrs" class="accent--lighten" :input-value="selected" label small>
            <span class="pr-2">
              <v-icon :color="item.color" class="">{{item.iconClass}}</v-icon>
              {{ item.displayName }}
            </span>
            <v-icon small @click="parent.selectItem(item)">close</v-icon>
          </v-chip>
        </template>
      </v-autocomplete>

      <v-autocomplete
        v-model="filters.relatedProjectId" :items="shownProjects"
        @change="fetchWorkItems"
        label="Related Project" item-text="name" item-value="id"
        outlined dense hide-details multiple
      >
        <template v-slot:item="{ item, attrs }">
          <v-list-item-action>
            <v-checkbox v-model="attrs.inputValue"></v-checkbox>
          </v-list-item-action>
          <v-list-item-content class="pa-0">
            <v-list-item class="pa-0">
              <v-list-item-content>
                <v-list-item-title>{{ item.name }}</v-list-item-title>
                <v-list-item-subtitle>{{ item.smallCode }}</v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
          </v-list-item-content>
        </template>
        <template v-slot:selection="{ attrs, item, parent, selected }">
          <v-chip v-if="item === Object(item)"
            v-bind="attrs" class="accent--lighten" :input-value="selected" label small>
            <span class="pr-2">
              {{ item.name }}
            </span>
            <v-icon small @click="parent.selectItem(item)">close</v-icon>
          </v-chip>
        </template>
      </v-autocomplete>

      <v-text-field
        v-model="filters.linkedWorkItemCode"
        @input="linkedWorkItemCodeChanged"
        label="Related Work Item"
        outlined dense hide-details
      />

      <div class="text-right">
        <v-autocomplete
          v-model="filters.assignedTo" :items="users"
          @change="fetchWorkItems"
          outlined multiple hide-details dense
          label="Assigned to" item-value="id" item-text="blueTag" 
          :filter="(item, queryText, itemText) => normalizeIncludes(item.blueTag + ' ' + item.fullName, queryText)"
        >

          <template v-slot:item="{ item, attrs }">
            <v-list-item-action>
              <v-checkbox v-model="attrs.inputValue"></v-checkbox>
            </v-list-item-action>
            <v-list-item-content class="pa-0">
              <v-list-item class="pa-0">
                <v-list-item-avatar color="indigo" class="headline font-weight-light white--text">
                  {{ (item.blueTag || '-').charAt(0) }}
                </v-list-item-avatar>
                <v-list-item-content>
                  <v-list-item-title>{{ item.blueTag }}</v-list-item-title>
                  <v-list-item-subtitle>{{ item.fullName }}</v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
            </v-list-item-content>
          </template>
          <template v-slot:selection="{ attrs, item, parent, selected }">
            <v-chip v-if="item === Object(item)"
              v-bind="attrs" color="accent" :input-value="selected" label small>
              <span class="pr-2">
                {{ item.blueTag }}
              </span>
              <v-icon small @click="parent.selectItem(item)">close</v-icon>
            </v-chip>
          </template>
        </v-autocomplete>
        <v-btn
          @click="setAssignedToMe"
          color="primary" style="text-transform: none" text
        >Assigned to me</v-btn>
      </div>

      <div class="text-right">
        <v-autocomplete
          v-model="filters.reportedBy" :items="users"
          @change="fetchWorkItems"
          outlined multiple hide-details dense
          label="Reported by" item-value="id" item-text="blueTag" 
          :filter="(item, queryText, itemText) => normalizeIncludes(item.blueTag + ' ' + item.fullName, queryText)"
        >

          <template v-slot:item="{ item, attrs }">
            <v-list-item-action>
              <v-checkbox v-model="attrs.inputValue"></v-checkbox>
            </v-list-item-action>
            <v-list-item-content class="pa-0">
              <v-list-item class="pa-0">
                <v-list-item-avatar color="indigo" class="headline font-weight-light white--text">
                  {{ (item.blueTag || '-').charAt(0) }}
                </v-list-item-avatar>
                <v-list-item-content>
                  <v-list-item-title>{{ item.blueTag }}</v-list-item-title>
                  <v-list-item-subtitle>{{ item.fullName }}</v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
            </v-list-item-content>
          </template>
          <template v-slot:selection="{ attrs, item, parent, selected }">
            <v-chip v-if="item === Object(item)"
              v-bind="attrs" color="accent" :input-value="selected" label small>
              <span class="pr-2">
                {{ item.blueTag }}
              </span>
              <v-icon small @click="parent.selectItem(item)">close</v-icon>
            </v-chip>
          </template>
        </v-autocomplete>
        <v-btn
          @click="setReportedByMe"
          color="primary" style="text-transform: none" text
        >Reported by me</v-btn>
      </div>

    </filter-search-input>

    <v-data-table
      @click:row="openWorkItemDetailsDialog($event.id)"
      @pagination="fetchWorkItems"
      
      :headers="shownHeaders" :items="workItems"

			class="new-back lighten2 rounded-lg bordered overflow-hidden"
      :item-class="() => 'cursor-pointer'"
      
      :items-per-page.sync="pagination.itemsPerPage"
      :page.sync="pagination.page"
      :server-items-length="pagination.totalItems"
      
      :sort-by.sync="pagination.sortBy"
		  :sort-desc.sync="pagination.sortDesc"
      @update:sort-by="fetchWorkItems"
		  @update:sort-desc="fetchWorkItems"
    >

      <template #[`item.code`]="{ item }">
        <span class="text-no-wrap">
          {{item.code}}
        </span>
      </template>

      <template #[`item.priority`]="{ item }">
        <v-tooltip top>
          <template v-slot:activator="{ on }">
            <v-icon v-on="on" :color="item.priorityColor">{{ item.priorityIconClass }}</v-icon>
          </template>
          <span class="text-capitalize" v-text="item.priorityDisplayName"></span>
        </v-tooltip>
      </template>

      <template #[`item.type`]="{ item }">
        <v-tooltip top>
          <template v-slot:activator="{ on }">
            <v-btn v-on="on" dark :elevation="0" fab x-small :color="item.typeColor">
              <v-icon>{{ item.typeIconClass }}</v-icon>
            </v-btn>
          </template>
          <span v-text="item.typeDisplayName"></span>
        </v-tooltip>
      </template>

      <template #[`item.statusName`]="{ item }">
        <div
          :style="`background:${item.statusColor};`" class="status-container"
        >
          <v-icon v-if="item.statusIconClass" color="white" small>{{item.statusIconClass}}</v-icon>
          {{setMaxText(item.statusName, 25)}}
        </div>
      </template>

      <template #[`item.tags`]="{ item }">
        <div>
          <v-chip class="mr-1" x-small dark v-for="(tag, ix) in item.tags" :key="ix"
            :color="tag.tagColor"
            :style="`color: ${isBlackText(tag.tagColor) ? 'white' : 'black'}`"
          >
            {{tag.tagName}}
          </v-chip>
        </div>
      </template>

      <template #[`item.linkedWorkItems`]="{ item }">
        <div class="d-flex flex-column">
          <span v-for="linked in item.linkedWorkItems" :key="linked.id" class="nobr">
            {{ linked.relatedWorkItemCode }}
          </span>
        </div>
      </template>

      <template #[`item.assignedToBlueTag`]="{ item }">
        <span v-if="item.assignedToId">{{item.assignedToBlueTag}}</span>
        <i v-else>Unassigned</i>
      </template>

      <template #[`item.reportedByBlueTag`]="{ item }">
        <span>{{ item.reportedByBlueTag || item.reportedByFullName }}</span>
      </template>

      <template #[`item.createdAt`]="{ item }">
        <small>{{item.createdAt | formatDateTime}}</small>
      </template>

    </v-data-table>

    <work-item-details-dialog ref="workItemDetailsDialog"></work-item-details-dialog>
  </div>
</template>

<script>
import _ from "lodash"
import moment from 'moment'
import excelIcon from '../../assets/excel-icon.svg';
import WorkItemDetailsDialog from "./../../dialogs/WorkItemDetailsDialog"

export default {
  components: {
    WorkItemDetailsDialog,
  },
  data: () => ({
    excelIcon,
    projectConfig: {},
    workItems: [],
    color: null,
    types: [], 
    priorities: [], 
    users: [], 
    projectStatuses: [],
    projects: [], 
    statuses: [],

    filters: {
      search: '',
      projectStatuses: [],
      projects: [], 
      statusNames: [],
      types: [], 
      priorities: [], 
      assignedTo: [], 
      reportedBy: [], 
    },
    pagination: {
			page: 1,
			itemsPerPage: 10,
			totalItems: 0,
		},
		searchTimeout: null,
    linkedWorkItemCodeTimeout: null,
    
    headers: [
      { text: "Project", value: "projectName" },
      { text: "Code", value: "code",
        sort: (a, b) => {
          let indexA = a.lastIndexOf('-')
          let indexB = a.lastIndexOf('-')
          a = a.substr(indexA+1)
          b = b.substr(indexB+1)
          return parseInt(a) - parseInt(b)
        }, width: 80
      },
      { text: "Type", value: "type", align: 'center', width: 80 },
      { text: "Summary", value: "name", },
      { text: "Description", value: "description", sortable: false },
      { text: "Status", value: "statusName", align: 'center' },
      { text: "Priority", value: "priority", align: 'center', width: 95 },
      { text: "Estimated", value: "estimatedEffort", align: 'center', width: 120 },
      { text: "Sprint", value: "sprintName", align: 'center', width: 100, sortable: false },
      { text: "Tags", value: "tags", width: 100, sortable: false },
      { text: "Related Work Items", value: "linkedWorkItems", width: 85, sortable: false },
      { text: "Assigned to", value: "assignedToBlueTag", width: 120 },
      { text: "Reported by", value: "reportedByBlueTag", width: 120 },
      { text: "Created on", value: "createdAt", width: 150 },
      { text: "Created by", value: "createdByBlueTag", width: 120 },
    ],
    menu: false,
    showCol: {
      projectName: true,
      code: true,
      type: true,
      name: true,
      statusName: true,
      priority: true,
      estimatedEffort: true,
      sprintName: true,
      tags: true,
      linkedWorkItems: true,
      assignedToBlueTag: true,
      reportedByBlueTag: true,
      createdAt: true,
      createdByBlueTag: true,
    },
  }),
  created() {
    this.setUserPreferences("WorkItemsList", "showCol", "filters")
    this.init()
  },
  computed: {
    shownHeaders(){
      return this.headers.filter(h => this.showCol[h.value])
    },
    appliedFilters(){
      console.log(this.filters)
			return this.countActiveFilters(this.filters, ['search'])
		},
    shownProjects(){
      return this.projects
        .filter(p => !this.filters.projectStatuses.length || this.filters.projectStatuses.includes(p.status))
    }
  },
  methods: {
    init() {
      this.$http.get(`/api/Enums/WorkItemType`)
        .then(res => {
          this.types = res.data
          this.$nextTick(() => {
            this.setUserPreferences(`WorkItemsPanel-general`, "selectedTypes")
          })
        })
        .catch(res => {
          window.getApp.error("Cannot obtain types.")
          console.error(res)
        })
        
      this.$http.get(`/api/Enums/WorkItemPriority`)
        .then(res => {
          this.priorities = res.data
        })
        .catch(res => {
          window.getApp.error("Cannot obtain priorities.")
          console.error(res)
        })

      this.$http
        .get("/api/user/current/project")
        .then(res => {
          this.projects = res.data
        })
        .catch(res => {
          window.getApp.error("Cannot get projects.")
          console.error(res)
        })
      this.$http
        .get(`/api/user?active=true`)
          .then(res => {
            this.users = res.data
            this.$q.addFirstItem(this.users, 'Unassigned', 'blueTag')
          })
          .catch(res => {
            window.getApp.error("Cannot obtain users.")
            console.error(res)
          })

      this.$http
        .get(`/api/Enums/ProjectStatus`)
        .then(res => {
          this.projectStatuses = res.data;
        })
        .catch(res => {
          window.getApp.error("Cannot obtain project status.");
          console.log(res);
        });

      this.$http
        .get('api/ProjectWorkItemStatus/UniqueNames')
        .then(res => {
          this.statuses = res.data
        })
        .catch(res => {
          window.getApp.error("Cannot obtain work item statuses.")
          console.error(res)
        })
    },
    fetchWorkItems() {
      this.filters.projects = this.filters.projects
        .filter(p => {
          const project = this.projects.find(x => x.id === p)
          return !this.filters.projectStatuses.length || this.filters.projectStatuses.includes(project.status)
        })

      let sortBy = Array.isArray(this.pagination.sortBy) ? this.pagination.sortBy[0] : this.pagination.sortBy
      let sortDesc = Array.isArray(this.pagination.sortDesc) ? this.pagination.sortDesc[0] : this.pagination.sortDesc

      this.$http.post(
        'api/workItem/list',
        {
          ...this.filters,
          page: this.pagination.page,
          pageSize: this.pagination.itemsPerPage,
          sortBy,
          sortDesc,
        }
      )
      .then(res => {
          this.workItems = res.data.workItems
          this.pagination.totalItems = res.data.totalItems

          if(this.workItems.length == 0 && this.pagination.totalItems){
            this.$nextTick(() => {
              this.pagination.page = 1
            })
            return
					}

          this.workItems.forEach(this.setDarkenWorkItem)
        })
        .catch(res => {
          window.getApp.error("Cannot obtain work items.")
          console.error(res)
        })
    },
    searchChanged() {
      setTimeout(this.searchTimeout)
      this.searchTimeout = setTimeout(this.fetchWorkItems, 500)
    },
    linkedWorkItemCodeChanged() {
      clearTimeout(this.linkedWorkItemCodeTimeout)
      this.linkedWorkItemCodeTimeout = setTimeout(this.fetchWorkItems, 500)
    },
    async setAssignedToMe() {

      let currentUser = await this.$blueSystem.getCurrentUser()

      if (currentUser) {
        this.$set(this.filters, 'assignedTo', [ currentUser.id ])
      }
      
    },
    async setReportedByMe() {

      let currentUser = await this.$blueSystem.getCurrentUser()

      if (currentUser) {
        this.$set(this.filters, 'reportedBy', [ currentUser.id ])
      }
      
    },
    setDarkenWorkItem(workItem) {
      let color = workItem.boardColor
      if (!color) {
        this.$set(workItem, 'dark', false)
        return
      }

      const hex   = color.replace(/#/, '')
      const r     = parseInt(hex.substr(0, 2), 16)
      const g     = parseInt(hex.substr(2, 2), 16)
      const b     = parseInt(hex.substr(4, 2), 16)

      let luma = [
          0.299 * r,
          0.587 * g,
          0.114 * b
      ].reduce((a, b) => a + b) / 255

      this.$set(workItem, 'dark', (luma < 0.5))
    },
    openWorkItemDetailsDialog(id) {
      this.$refs.workItemDetailsDialog.open(id, {openCreateDialog: this.openWorkItemCreateDialog})
        .finally(result => {
          // Reload
          this.init()
        })
    },
    async getAllWorkItems() {
      const { data } = await this.$http.post(
        'api/workItem/list',
        {
          ...this.filters,
          page: 1,
          pageSize: -1,
        }
      )
      return data?.workItems
    },
    generateExcelProperties() {
      return {
        Title: `Work Items`,
        Subject: "Work Items",
        Author: 'BlueSurf',
        CreatedDate: new Date()
      };
    },
    generateExcelData(workItems, showCol) {
      const data = [];
      const headersRow = this.generateHeadersRow(showCol);
      data.push(headersRow);
      workItems.forEach(workItem => {
        const rowData = this.generateRowData(workItem, showCol);
        data.push(rowData);
      });
      return data;
    },
    generateHeadersRow(showCol) {
      const row = [];
      for (const property in showCol) {
        if (showCol[property]) {
          const header = this.headers.find(header => header.value === property);
          row.push(header ? header.text : property);
        }
      }
      return row;
    },
    formatDate(value) {
      if (value) {
        return moment(String(value)).format('MMM D, YYYY h:mm A');
      }
    },
    getTypeValue(workItem) {
      return workItem.typeDisplayName || workItem.type;
    },
    generateRowData(workItem, showCol) {
      const rowData = [];
      for (const property in showCol) {
        if (showCol[property]) {
          let value;

          if (property === "type") {
            value = this.getTypeValue(workItem);
          } else if (property === "createdAt") {
            value = this.formatDate(workItem[property]);
          } else if (property === "tags") {
            value = this.getTagsValue(workItem[property]);
          } else if (property === "sprintName") {
            value = this.getSprintsValue(workItem['sprints']);
          } else if (property === "linkedWorkItems") {
            value = workItem[property].map(linked => linked.relatedWorkItemCode).join(', ');
          } else {
            value = workItem[property];
          }

          rowData.push(value !== undefined ? value : ''); // Add an empty string if value is undefined
        }
      }
      return rowData;
    },
    getSprintsValue(sprints) {
      return Array.isArray(sprints) ? sprints[0]?.sprintName || '' : '';
    },
    getTagsValue(tags) {
      if (Array.isArray(tags)) {
        return tags.map(tag => tag.tagName).join(', ');
      }
      return '';
    },
    async exportToExcel(){
      const workItems = await this.getAllWorkItems();
      const props = this.generateExcelProperties();
      const data = this.generateExcelData(workItems, this.showCol);
      const sheets = [{ name: 'WorkItems', data }];
      this.createExcel(props, sheets);
    }
  },
}
</script>

<style scoped lang="scss">

.column-card {
  display: flex;
  flex-direction: column;
  align-items: stretch;
}
.column-card-content {
  flex-grow: 1;
}
.code {
  font-size: 16px;
  color: #777777;
}
.summary {
  font-size: 16px;
  color: #333333 !important;
}
.work-item.sortable-ghost {
  border: 2px dashed #0072ff !important;
  cursor: grabbing !important;
}

.work-item {
  cursor: grab;
}

.v-color-picker ::v-deep .v-color-picker__preview {
  display: none;
}
.v-data-table ::v-deep tbody .first-new td{
  border-bottom: 3px solid #888 !important;
}
.selectable{
  display: inline-flex;
  width: 140px;
  margin: 10px 0;
}
.btn-group{
  margin-bottom: 12px;
}
.btn-group .v-btn{
  margin-right: 10px;
}
.btn-group .save-btn{
  border-radius: 20px;
  width: 100px;
}
.oneline-ellipsis {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.oneline{
  line-height: 48px;
  height: 48px;
  overflow: hidden;
  text-overflow: ellipsis;
}
.excel-btn{
  color: white;
  margin-right: 5px;
  background: #21a366 !important;
}
.remove-item:hover{
  color: red;
}
.status-container{
  box-shadow: 0 0 5px #888;
  display: inline-block;
  min-height: 30px;
  max-height: 60px;
  width: 145px;
  color: white;
  overflow: hidden;
  margin: 5px 0;
  border-radius: 5px;
  padding: 5px 7px;
}
</style>