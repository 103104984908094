<template>
	<v-data-table
		v-model="innerValue" @input="$emit('input', $event)"
		v-bind="$attrs" v-on="$listeners"
		checkbox-color="primary"
		:headers="shownHeaders" :items="items"
	>
		<template #[`item.code`]="{ item }">
			<b>{{ item.code || item.billableItemCode }}</b>
		</template>
		<template #[`item.billableCode`]="{ item }">
			<b>{{ item.billableCode || item.billableItemBillableCode }}</b>
		</template>
		<template #[`item.startDate`]="{ item }">
			{{ (item.startDate || item.billableItemStartDate) | formatDate }}
		</template>
		<template #[`item.endDate`]="{ item }">
			{{ (item.endDate || item.billableItemEndDate) | formatDate }}
		</template>
		<template #[`item.hours`]="{ item }">
			{{ (item.hours || item.billableItemHours) | number(2) }}
		</template>
		<template #[`item.total`]="{ item }">
			{{ currencyFormat(item.total || item.billableItemTotal) }}
		</template>
		<template #[`item.invoiced`]="{ item }">
			{{ currencyFormat(item.invoiced || item.billableItemInvoiced) }}
		</template>
		<template #[`item.adjustmentAmount`]="{ item }">
			<v-btn
				v-if="
					showAdjustmentButton &&
					!item.adjustmentAmount &&
					!item.billableItemAdjustmentAmount &&
					(adjustmentNeeded || item.billableItemPending >= 0.01)
				"
				@click.stop="$emit('adjust', item)"
				color="primary" x-small
			>
				Adjust
			</v-btn>
			<span v-else>
				{{
					(item.adjustmentAmount || item.billableItemAdjustmentAmount) ?
					currencyFormat(item.adjustmentAmount || item.billableItemAdjustmentAmount) :
					'-'
				}}
			</span>
		</template>
		<template #[`item.pending`]="{ item }">
			{{ currencyFormat(item.pending || item.billableItemPending) }}
		</template>
		<template #[`item.currency`]="{ item }">
			<v-avatar size="24" class="mr-1">
				<v-img :src="require(`../assets/currencies/${item.billableCurrency.toLowerCase()}.png`)" />
			</v-avatar>
			<b>
				{{ item.billableCurrency }}
			</b>
		</template>
		<template #[`item.isClosed`]="{ item }">
			<check-switch :value="item.isClosed"/>
		</template>
		<template #[`item.status`]="{ item }">
			<v-btn
				:color="item.statusColor"
				outlined small style="border-radius: 20px"
				:style="{
					background: $vuetify.theme.isDark ? '' : item.statusLightColor
				}"
			>{{item.statusDisplayName}}</v-btn>
		</template>
		<template #[`item.amount`]="props">
			<slot name="item.amount" v-bind="props" />
		</template>
	</v-data-table>
</template>

<script>
export default {
	props: {
		value: Array,
		items: Array,
		complete: { type: Boolean, default: true },
		invoiceView: { type: Boolean, default: false },
		showAdjustmentButton: { type: Boolean, default: false },
		adjustmentNeeded: { type: Boolean, default: false },
	},
	mounted(){
		this.innerValue = this.value
	},
	data: () => ({
		headers: [
			{ text: "Code", value: "code", },
			{ text: "Billable", value: "billableCode", },
			{ text: "Start Date", value: "startDate", },
			{ text: "End Date", value: "endDate", },
			{ text: "Hours", value: "hours", align: "right", },
			{ text: "Currency", value: "currency", onlyComplete: true },
			{ text: "Billed", value: "total", align: "right", },
			{ text: "Invoiced", value: "invoiced", },
			{ text: "Adjustment", value: "adjustmentAmount", },
			{ text: "Pending", value: "pending", },
			{ text: "Closed", value: "isClosed", onlyComplete: true },
			{ text: "Status", value: "status", onlyComplete: true },
			{ text: "Distribution", value: "amount", invoiceView: true },
		],
		innerValue: [],
	}),
	computed: {
		shownHeaders(){
			return this.headers.filter(x => (this.complete || !x.onlyComplete) && (this.invoiceView || !x.invoiceView))
		},
	},
}
</script>

<style lang="scss" scoped>

</style>