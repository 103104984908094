<template>
  <v-container grid-list-md fluid class="daily-report-edit px-10 py-5" tabindex="0">
    <v-form ref="form" v-model="valid">
      <v-layout row wrap>
        <v-flex xs12>
          <v-row>
            <v-col>
              <h1 class="title">
                <span>{{report.reporterFullName}}'s Report</span>
              </h1>
              <span class="body-2" v-if="linkedAccount">
                Linked to {{linkedAccount}}
              </span>
            </v-col>
          </v-row>
          <br>
        </v-flex>

        <div class="mb-5 workitems-container" v-if="!reportType">
          <div class="project" v-for="(project, ix) in projectWorkItems" :key="ix">
            <v-row class="pa-0 ma-0" align="center">
              <h2>{{project[Object.keys(project)[0]][0].projectName}}</h2>
              <v-divider class="ml-3"></v-divider>
            </v-row>
            <div class="type" v-for="(type, jx) in project" :key="jx">
              <div style="display: flex; align-items: center;">
                <v-btn fab x-small dark :color="type[0].typeColor">
                  <v-icon small>{{type[0].typeIconClass}}</v-icon>
                </v-btn>
                <span class="type-name">{{type[0].typeDisplayName}}</span>
              </div>
              
              <div class="workItems" v-for="workItem in type" :key="workItem.id">
                <div>
                  <v-card
                    class="work-item my-3" 
                    :color="!!workItem.boardColor ? workItem.boardColor : '#fff'"
                  >
                    <v-card-text class="pa-2 summary" :class="{'text--text': workItem.dark, 'grey--text text--darken-4': !workItem.dark }">
                      <span class="float-right estimated-effort" v-if="workItem.estimatedEffort != undefined && workItem.estimatedEffort != null">
                        <small>
                          {{workItem.estimatedEffort + ' hrs'}}
                        </small>
                        
                        <v-icon class="priority-icon" small :color="workItem.priorityColor" style="display: none">{{workItem.priorityIconClass}}</v-icon>
                      </span>
                      <div>
                        <v-tooltip z-index="111" top v-for="(tag, ix) in workItem.tags" :key="ix" :disabled="!tag.tagDescription">
                          <template v-slot:activator="{ on }">
                            <v-chip
                              v-on="on"
                              class="mr-1" x-small dark
                              :color="tag.tagColor"
                              :style="`color: ${isBlackText(tag.tagColor) ? 'white' : 'black'}`"
                            >
                              {{tag.tagName}}
                            </v-chip>
                          </template>
                          <div style="white-space:pre-wrap; word-break:break-word; max-width: 280px;" v-html="tag.tagDescription ? JSON.parse(tag.tagDescription) : ''"></div>
                        </v-tooltip>
                      </div>
                      {{workItem.name}}
                    </v-card-text>
                    <v-card-actions :style="{padding: 0}">
                      <div class="actions-box">
                        <v-row align="center" class="actions-container" :class="{'white--text': workItem.dark, 'black--text': !workItem.dark }">
                          <v-col class="pa-0 pt-2 pl-7 text-no-wrap" >
                            <v-badge class="mr-1" :icon="workItem.typeIconClass" :color="workItem.typeColor" left>
                            </v-badge>
                            <span>
                              {{workItem.code}}
                            </span>
                          </v-col>
                          <v-spacer></v-spacer>
                          <v-col class="pa-0 pr-3 text-no-wrap text-right code" :class="{'white--text': workItem.dark, 'black--text': !workItem.dark }">

                            <user-avatar :userId="workItem.assignedToId" :size="30" hideLoading></user-avatar>
                          </v-col>
                        </v-row>
                        <div class="indicators" :class="{'white--text': workItem.dark, 'black--text': !workItem.dark }">
                          <div>
                            WIA <br>
                            <span style="white-space: nowrap">
                              {{
                                getIndicatorTime(
                                  (isNullDate(workItem.initiatedAt) || workItem.leadTime) ? null : workItem.age
                                )
                              }}
                            </span>
                          </div>
                          <div style="overflow: hidden; text-overflow: ellipsis; text-align: left">
                            <span style="white-space: nowrap">
                              WIA {{getStatus(workItem.statusId).name}}
                            </span>
                            <br>
                            <span>
                              {{getIndicatorTime(workItem.currentColumnAge)}}
                            </span>
                          </div>
                          <div>
                            LT <br>
                            <span style="white-space: nowrap">
                              {{
                                getIndicatorTime(
                                  (isNullDate(workItem.initiatedAt) || workItem.age || !getStatus(workItem.statusId).isFinal) ? null : workItem.leadTime
                                )
                              }}
                            </span>
                          </div>
                        </div>
                      </div>
                    </v-card-actions>
                  </v-card>
                </div>
                <div class="my-3">
                  <div
                    v-for="(item, kx) in workItem.history" :key="kx"
                    :set="status = {
                      prev: getStatus(item.originalValue),
                      new: getStatus(item.newValue),
                    }"
                    class="changes"
                  >
                    <div v-if="item.type == 'change'" class="change status-change">
                      <div class="change-icon" style="border-color: #0580ff">
                        <v-icon color="#0580ff">mdi-transit-connection-variant</v-icon>
                      </div>
                      <div class="status-container">
                        <div :style="`background:${status.prev.color};`" class="status">
                          <v-icon v-if="status.prev.iconClass" color="white">{{status.prev.iconClass}}</v-icon>
                          {{setMaxText(status.prev.name, 45)}}
                        </div>
                      </div>
                      <v-icon>mdi-arrow-right</v-icon>
                      <div class="status-container">
                        <div :style="`background:${status.new.color};`" class="status">
                          <v-icon v-if="status.new.iconClass" color="white">{{status.new.iconClass}}</v-icon>
                          {{setMaxText(status.new.name, 45)}}
                        </div>
                      </div>
                    </div>
                    <div v-else class="change">
                      <div class="change-icon" style="border-color: #ff6666">
                        <v-icon color="#ff6666">mdi-comment-text-outline</v-icon>
                      </div>
                      <span class="ml-1" v-text="htmlToText(item.comment)"></span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <v-flex xs12>
          <v-layout v-if="!shownEfforts || !shownEfforts.length" row xl3 class="effort mb-2"> No records </v-layout>
          <v-layout row v-for="(effort, ix) in shownEfforts" :key="ix" class="effort bordered pa-5 mb-5">
            <v-flex>
              <v-layout row wrap fill-height>
                <v-flex lg3 md4 sm6 class="type-project">
                  <!-- TODO: Componentize  -->
                  <label for="Project">Project</label>
                  <v-autocomplete
                    ref="project" placeholder="No Project"
                    v-model="effort.projectId" :persistent-hint="true" :disabled="report.isClosed"
                    @change="projectChanged(effort)"
                    loading hide-details
                    class="bordered darken rounded px-3 effort-input"
                    :hint="effort.projectId ? effort.project.customerProduct : ''" 
                    :items="projects" item-value="id" item-text="name"
                    :filter="(item, queryText, itemText) => (item.customerProduct + ': ' + item.name).toLocaleLowerCase().indexOf(queryText.toLocaleLowerCase()) > -1"
                  >
                    <template v-slot:selection="{ item }">
                      <category-chip small :code="item.customerProduct" :text="item.name"></category-chip>
                    </template>
                    <template v-slot:item="{ item }">
                      <v-list-item-content>
                        <v-list-item-title>
                          <span>{{item.name}}</span>
                        </v-list-item-title>
                        <v-list-item-subtitle>{{ item.customerProduct }}</v-list-item-subtitle>
                      </v-list-item-content>
                    </template>
                    <template #progress><div/></template>
                  </v-autocomplete>
                </v-flex>
                <v-flex lg5 md8 sm6 class="category-subcategory">
                  <v-layout row>
                    <v-flex>
                      <div v-if="effort.projectId && effort.linked">
                        <label for="Description">Description (optional)</label>
                        <v-text-field
                          v-model="effort.description"
                          @change="warnOnLeave = true"
                          :disabled="report.isClosed"
                          loading hide-details
                          class="bordered darken rounded px-5 effort-input"
                        >
                          <template v-slot:prepend-inner>
                            <v-btn small text icon @click="toggleLinked(effort)" class="mt-n1">
                              <v-icon small>clear</v-icon>
                            </v-btn>
                          </template>
                          <template #progress><div/></template>
                        </v-text-field>
                        <div v-if="effort.linked" class="caption ml-5" style="color: var(--v-text-lighten)">
                          {{ effort.linkedItem.name }}
                        </div>
                      </div>
                      <div
                        v-if="effort.projectId && effort.linked" class="cursor-pointer mb-n5"
                        @click="openChooseTaskDialog(effort)"
                      >
                        <small class="detail">
                          {{effort.linkedItem.responsibleFullName}}
                          {{(effort.linkedItem.totalExecuted || 0) | number(1)}}
                          /
                          {{effort.linkedItem.estimatedEffort || 'NA'}} 
                        </small>
                      </div>
                      <div v-if="!effort.projectId || !effort.linked">
                        <label for="Description">Description (required)</label>
                        <v-text-field
                          v-model="effort.description" :disabled="report.isClosed"
                          loading required hide-details
                          class="bordered darken rounded px-5 effort-input"
                          :rules="[v => !!v || 'Item is required']"
                        >
                          <template v-slot:prepend-inner v-if="effort.projectId">
                            <v-btn small text icon @click="toggleLinked(effort)" class="mt-n1">
                              <v-icon small>link</v-icon>
                            </v-btn>
                          </template>
                          <template #progress><div/></template>
                        </v-text-field>
                      </div>
                    </v-flex>
                  </v-layout>
                </v-flex>
                <v-flex lg4 md12 sm12 class="description-hours">
                  <v-layout justify-center>
                    <v-flex>
                      <label for="Category">Category</label>
                      <v-autocomplete
                        v-model="effort.subcategoryId"
                        @change="warnOnLeave = true"
                        :items="effort.subcategories" item-value="id" item-text="name"
                        loading required hide-details
                        class="bordered darken rounded px-5 effort-input"
                        :rules="[v => !!v || 'Item is required']"
                        :filter="filterSubcategories" :disabled="report.isClosed"
                      >  
                        <template v-slot:selection="{ item }">
                          <category-chip small :code="item.name" :text="`${item.categoryName}: ${item.name}`"></category-chip>
                        </template>
                        <template v-slot:item="{ item }">
                          <category-chip :text="item.name"></category-chip>
                          <br />
                          <small>{{item.categoryName}}</small>
                        </template>
                        <template #progress><div/></template>
                      </v-autocomplete>

                    </v-flex>
                    <v-flex class="min-flex" v-if="reportType">
                      <div class="d-flex" style="gap: 10px">
                        <div>
                          <label for="Executed">Executed</label>
                          <div class="time-input bordered darken rounded mt-1">
                            <div class="text-right">
                              <auto-width-input
                                v-model="effort.executedHrs"
                                type="text" class="mt-0 pt-0" mask="##"
                                required hide-details input-only min-width="10"
                                :rules="[v => v === 0 ||!!v || 'Required', v => v <= 24 || '< 24.']"
                                @change="executedTimeChanged(effort)" @focus="e => e.target.select()"
                              />
                            </div>
                            <span :style="{color: report.isClosed ? '#9b9b9b' : 'black'}">:</span>
                            <auto-width-input
                              v-model="effort.executedMins"
                              type="text" class="mt-0 pt-0" mask="##"
                              required hide-details input-only min-width="10"
                              :rules="[v => v === 0 ||!!v || 'Required', v => v < 60 || '< 60.']"
                              @change="executedTimeChanged(effort)" @focus="e => e.target.select()"
                            />
                          </div>
                        </div>
                        <div v-if="effort.projectId && effort.linked">
                          <label>Pending</label>
                          <div class="time-input bordered darken rounded mt-1">
                            <div class="text-right">
                              <auto-width-input
                                v-model="effort.pendingHrs"
                                :disabled="report.isClosed"
                                type="text" class="mt-0 pt-0" mask="##"
                                required hide-details input-only min-width="10"
                                :rules="[v => v === 0 ||!!v || 'Required']"
                                @change="pendingTimeChanged(effort)"
                                @focus="e => e.target.select()"
                              />
                            </div>
                            <span class="colon" :style="{color: report.isClosed ? '#9b9b9b' : 'black'}">:</span>
                            <auto-width-input
                              v-model="effort.pendingMins"
                              :disabled="report.isClosed"
                              type="text" class="mt-0 pt-0" mask="##"
                              required hide-details input-only min-width="10"
                              :rules="[v => v === 0 ||!!v || 'Required', v => v < 60 || '< 60']"
                              @change="pendingTimeChanged(effort)"
                              @focus="e => e.target.select()"
                            />
                          </div>
                        </div>
                      </div>
                    </v-flex>
                  </v-layout>
                </v-flex>
              </v-layout>
            </v-flex>
            <v-tooltip top v-if="!report.isClosed">
              <span>Delete</span>
              <template v-slot:activator="{ on }">
                <div class="d-flex align-center pt-5">
                  <v-btn v-on="on" color="red" class="ml-1" icon @click="deleteEffort(effort)">
                    <v-icon>mdi-trash-can-outline</v-icon>
                  </v-btn>
                </div>
              </template>
            </v-tooltip>
          </v-layout>
        </v-flex>

        <v-flex sm6 style="margin-bottom: 80px">
          <v-tooltip right>
            <template v-slot:activator="{ on }" >
              <v-btn
                v-on="on" @click="addNew"
                :disabled="report.isClosed" depressed
                class="mt-n5"
              >
                <v-icon>add</v-icon>
              </v-btn>
            </template>
            <span>[Ctrl] + [.]</span>
          </v-tooltip>
        </v-flex>

        <div class="footer d-flex justify-space-between">
          <div v-if="!reportType">
            <h2 class="inline-block">Total:</h2>
            <div class="time-input">
              <div class="text-right">
                <auto-width-input
                  v-model="report.executedHours"
                  :disabled="report.isClosed"
                  type="text" class="mt-0 pt-0" mask="##"
                  required hide-details input-only min-width="10"
                  :rules="[v => v === 0 ||!!v || 'Required', v => v <= 24 || '< 24.']"
                  @change="updateTime"
                  @focus="e => e.target.select()"
                />
              </div>
              <span class="colon" :style="{color: report.isClosed ? '#9b9b9b' : 'black'}">:</span>
              <auto-width-input
                v-model="report.executedMins"
                :disabled="report.isClosed"
                type="text" class="mt-0 pt-0" mask="##"
                required hide-details input-only min-width="10"
                :rules="[v => v === 0 ||!!v || 'Required', v => v < 60 || '< 60.']"
                @change="updateTime"
                @focus="e => e.target.select()"
              />
            </div>
          </div>
          <div v-else>
            <h2>Total: {{totalExecuted}}</h2>
          </div>
          <div>
            <v-btn
              @click="save(false)"
              color="primary" class="mr-2"
              rounded outlined depressed
              :disabled="!valid || saving || report.isClosed"
            >
              Save
            </v-btn>
            <v-btn
              @click="save(true)"
              color="primary"
              rounded depressed
              :disabled="!valid || saving || report.isClosed"
            >
              <v-icon class="mr-1">mdi-clipboard-check-outline</v-icon>
              Save & Confirm
            </v-btn>
          </div>
        </div>
      </v-layout>
    </v-form>
    <choose-task-dialog ref="chooseTaskDialog"></choose-task-dialog>
  </v-container>
</template>

<script>
import _ from "lodash"
import moment from 'moment'
import ChooseTaskDialog from "./../../dialogs/ChooseTaskDialog";

export default {
  title(){
    return 'Daily Report - ' + this.date.replaceAll('-', '/')
  },
  components: {
    ChooseTaskDialog
  },
  props: ["date", 'socket'],
  data: () => ({
    saving: false,
    effort: {},
    activeDialogTab: 0,
    project: null,
    configMenu: false,
    keyboardMenu: false,
    warnOnLeave: false,
    disableShortcuts: false,
    viewAllCategories: false,
    valid: false,
    valid2: false,
    description: null,
    report: {
      date: new Date(),
      efforts: [],
      executedHours: 0,
      executedMins: 0,
      status: 0,
    },
    users: [],
    projects: [],
    subcategories: [],
    statuses: [],
    projectWorkItems: [],
    reportType: 0,
    calendarEvents: [],
    googleCalendarSubcategory: {},
    linkedAccount: '',
  }),
  created() {
    this.setUserPreferences("DailyReportEdit", "disableShortcuts", "viewAllCategories")
    this.init()
    this.$q.changeHeaderTitle(moment(this.date).format('dddd, MMMM D, YYYY'))
  },
  mounted() {
    window.addEventListener("keydown", this.keyPress)
    window.addEventListener('beforeunload', this.warnLeaveWindow)
  },
  destroyed() {
    window.removeEventListener("keydown", this.keyPress)
    window.removeEventListener('beforeunload', this.warnLeaveWindow)
  },
  beforeRouteLeave (to, from, next) {
      if (!this.warnOnLeave) return next()
      if(to.path == '/login')
        next()
      else{
        const answer = window.confirm('Do you really want to leave? You have unsaved changes!')
        if (answer) {
          next()
        } else {
          next(false)
        }
      }
  },
  computed: {
    totalExecuted() {

      var total = 0;
      _.each(this.report.efforts, e => {
        if (!e.deleted) {
          total += (+e.executed || 0);
        }
      });

      return (Math.round(total * 100) / 100) + 'h';
    },
    shownEfforts() {
      if (!this.report || !this.report.efforts) return null;
      return _.filter(this.report.efforts, e => !e.deleted);
    },
  },
  watch: {
    viewAllCategories() {
      this.report.efforts.forEach(e => this.updateSubcategories(e))
    }
  },
  methods: {
    addCalendarEvent(event){
      let spaceIndex = event.summary.indexOf(' ')
      let dashIndex = event.summary.indexOf('-')
      let dotIndex = event.summary.indexOf('.')

      let endOfWord = 1000
      if(spaceIndex != -1 && spaceIndex < endOfWord)
        endOfWord = spaceIndex
      if(dashIndex != -1 && dashIndex < endOfWord)
        endOfWord = dashIndex
      if(dotIndex != -1 && dotIndex < endOfWord)
        endOfWord = dotIndex
      
      if(endOfWord == -1)
        endOfWord = event.summary.length
      let smallCode = event.summary.substring(0, endOfWord)
      let project = this.projects.find(p => p.smallCode == smallCode) || { id: null }
      let hours = Math.floor(event.duration)
      let minutes = Math.round((event.duration - hours) * 60)
      if(minutes < 10)
        minutes = '0' + minutes

      let newEffort = {
        type: null,
        project: project,
        projectId: project.id,
        linked: false,
        description: event.summary,
        executed: event.duration,
        executedHrs: hours,
        executedMins: minutes,
        pending: null,
        pendingHrs: "0",
        pendingMins: "00",
        categoryId: this.googleCalendarSubcategory.categoryId,
        subcategoryId: this.googleCalendarSubcategory.id,
        deleted: false,
        subcategories: [],
      }

      this.updateSubcategories(newEffort);
      
      this.report.efforts.push(newEffort)

      this.$nextTick(() => {
        let projectInputs =  this.$refs.project;
        let input = projectInputs.length ? projectInputs[projectInputs.length - 1] : projectInputs;
        input.focus();
      })
    },
    getCardColumn(statusId, cardColumns){
      return cardColumns.find(cc => cc.projectWorkItemStatusId == statusId) || { totalTime: 0 }
    },
    getStatus(id) {
      return this.statuses.find(st => st.id == id) || {}
    },
    setDarkenWorkItem(workItem) {
      let color = workItem.boardColor
      if (!color) {
        this.$set(workItem, 'dark', false)
        return
      }

      const hex   = color.replace(/#/, '')
      const r     = parseInt(hex.substr(0, 2), 16)
      const g     = parseInt(hex.substr(2, 2), 16)
      const b     = parseInt(hex.substr(4, 2), 16)

      let luma = [
          0.299 * r,
          0.587 * g,
          0.114 * b
      ].reduce((a, b) => a + b) / 255

      this.$set(workItem, 'dark', (luma < 0.5))
    },
    workItemTags(workItem) {
      if (!workItem.tags || true) return []
      return _.orderBy(workItem.tags, ['tagSortIndex'])
    },
    warnLeaveWindow(e) {
      if (!this.warnOnLeave) return null

      const answer = window.confirm('Do you really want to leave? You have unsaved changes!')
      if (answer) {
      } else {
        // Cancel the event as stated by the standard.
        event.preventDefault();
        // Chrome requires returnValue to be set.
        event.returnValue = '';
      }

      return 'Do you really want to leave? You have unsaved changes!'
    },
    filterSubcategories(item, queryText, itemText) {
      if (!item || !item.categoryName || !item.name) return false

      let allWords = item.categoryName.split(' ').concat(item.name.split(' '))
      let allQueryWords = queryText.split(' ')
      return allQueryWords.every(qw => allWords.some(cw => cw.toLocaleLowerCase().indexOf(qw.toLocaleLowerCase())> -1))
    },
    updateSubcategories(effort) {
      let subcategories = this.subcategories
      let project = this.projects.find(p => p.id === effort.projectId)
      if (project && project.categories) {
        let projectCategoryIds = project.categories.map(c => c.categoryId)
        subcategories = this.subcategories.filter(s => projectCategoryIds.indexOf(s.categoryId) != -1)
      }
      // return this.subcategories
      let array = _(subcategories)
        .filter(s => this.viewAllCategories || !s.categoryHidden)
        .groupBy('categoryName')
        .map((g, ix) => {
          return [{ divider: true}, {header: ix}].concat(g)
        })
        .flatten()
        .value()
      array.splice(0, 1)
      effort.subcategories = array

      if (effort.subcategories && effort.subcategories.length === 1 && !effort.subcategoryId) {
        effort.subcategoryId = effort.subcategories[0].id
      }
    },
    keyPress(evt) {
      if (this.disableShortcuts) return

      let ctrlDown = evt.ctrlKey || evt.metaKey
      let leftKey = evt.keyCode === 37
      let rightKey = evt.keyCode === 39
      let dot = evt.keyCode === 190

      if (ctrlDown && dot) { // Ctrl + dot
        this.addNew()
      }
    },
    dialogFocus() {
      // Close selects
      if (this.$refs.dialogProject) this.$refs.dialogProject.blur()
      if (this.$refs.dialogCategory) this.$refs.dialogCategory.blur()

      setTimeout(() => {

        if (this.activeDialogTab === 0) {
          this.$refs.dialogProject.$refs.input.focus()
        } else if (this.activeDialogTab === 1) {
          this.$refs.dialogTaskChooser.$refs.taskSearch.focus()
        } else if (this.activeDialogTab === 2) {
          this.$refs.dialogDescription.focus()
        } else if (this.activeDialogTab === 3) {
          this.$refs.dialogCategory.focus()
        } else if (this.activeDialogTab === 4) {
          this.$refs.dialogExecutedHrs.focus()
        }
      },500);
    },
    addNew() {
      let newEffort = {
        type: null,
        project: null,
        projectId: null,
        linked: false,
        description: null,
        executed: null,
        executedHrs: "0",
        executedMins: "00",
        pending: null,
        pendingHrs: "0",
        pendingMins: "00",
        categoryId: null,
        subcategoryId: null,
        deleted: false,
        subcategories: [],
      }

      // Last effort defaults
      if (this.report.efforts.length) {
        var lastEffort = this.report.efforts[this.report.efforts.length - 1]
        newEffort.project = lastEffort.project
        newEffort.projectId = lastEffort.projectId

      }

      // Update subcategories based on project
      this.updateSubcategories(newEffort);
      
      this.report.efforts.push(newEffort)

      // Focus on new effort
      this.$nextTick(() => {
        let projectInputs =  this.$refs.project;
        let input = projectInputs.length ? projectInputs[projectInputs.length - 1] : projectInputs;
        input.focus();
      })
    },
    getEffortError(effort) {
      let warning = "";
      if (!effort.linked && !effort.description) {
        warning += "Description is required. \n"
      }
      if (!effort.subcategoryId) {
        warning += "Category is required. \n"
      }
      return warning;
    },
    getEffortWarning(effort) {
      let warning = "";
      if (!effort.executed) {
        warning += "No executed time. \n"
      }
      return warning;
    },
    subcategoryChanged(effort) {
      let subcategory = this.subcategories.find(s => s.id == effort.subcategoryId);
      if (subcategory) {
        effort.categoryName = subcategory.categoryName;
        effort.subcategoryName = subcategory.name;
      } else {
        effort.categoryName = "";
        effort.subcategoryName = "";
      }
    },
    updatePendingMins(effort) {
      effort.pendingHrs = Math.floor(+effort.pending)
      effort.pendingMins = Math.round((+effort.pending % 1) * 60, 0)
      // "00" format
      if (effort.pendingMins < 10) {
        effort.pendingMins = "0" + effort.pendingMins
      }
    },
    updateExecutedMins(effort) {
      effort.executedHrs = Math.floor(+effort.executed)
      effort.executedMins = Math.round((+effort.executed % 1) * 60)
      // "00" format
      if (effort.executedMins < 10) {
        effort.executedMins = "0" + effort.executedMins
      }
    },
    executedChanged(effort) {
      // Update pending
      if (!effort.pendingChanged && effort.originalPending) {
        
        effort.pending = effort.originalPending - effort.executed
        if (effort.pending < 0) {
          effort.pending = 0
        }
        effort.pendingHrs = Math.floor(+effort.pending)
        effort.pendingMins = Math.round((+effort.pending % 1) * 60)
      }
      updateExecutedMins()
    },
    executedTimeChanged(effort) {
      this.warnOnLeave = true
      // Remove non numbers, leading 0s and more than 2 digits
      effort.executedHrs = +("" + effort.executedHrs).replace(/[^0-9]+/g, "").replace(/^0+/, '')
      effort.executedMins = +("" + effort.executedMins).replace(/[^0-9]+/g, "").replace(/^0+/, '').substring(0,2)
      effort.executed = Math.round(((+effort.executedHrs || 0) + (+effort.executedMins || 0) / 60)*1000) / 1000
      // Update pending
      if (!effort.pendingChanged && effort.originalPending) {
        
        effort.pending = effort.originalPending - effort.executed
        if (effort.pending < 0) {
          effort.pending = 0
        }
        this.updatePendingMins(effort);
      }
      
      // "00" format
      if (+effort.executedMins < 10) {
        effort.executedMins = "0" + +effort.executedMins
      }

    },
    pendingChanged(effort) {
      effort.pendingChanged = true
      effort.pendingHrs = Math.floor(+effort.pending)
      effort.pendingMins = Math.round((+effort.pending % 1) * 60, 0)
    },
    pendingTimeChanged(effort) {
      // Remove non numbers, leading 0s and more than 2 digits
      effort.pendingHrs = +("" + effort.pendingHrs).replace(/[^0-9]+/g, "").replace(/^0+/, '')
      effort.pendingMins = +("" + effort.pendingMins).replace(/[^0-9]+/g, "").replace(/^0+/, '').substring(0,2)

      effort.pendingChanged = true
      effort.pending = Math.round(((+effort.pendingHrs || 0) + (+effort.pendingMins || 0) / 60) * 100) / 100
      
      // "00" format
      if (+effort.pendingMins < 10) {
        effort.pendingMins = "0" + +effort.pendingMins
      }
    },
    toggleLinked(effort) {

      if (effort.linked) {
        this.$set(effort, "linked", false)
        effort.linkedItem = null
        effort.taskId = null
        effort.workItemId = null
        return
      } else {
        this.openChooseTaskDialog(effort)
      }

    },
    projectChanged(effort) {
      this.warnOnLeave = true
      if (effort.projectId) {
        effort.project = _.find(this.projects, { id: effort.projectId })
        this.openChooseTaskDialog(effort)
      }
      effort.linkedItem = null
      effort.taskId = null
      effort.workItemId = null
      this.$set(effort, "linked", false)
      this.updateSubcategories(effort)
    },
    openChooseTaskDialog(effort) {
      if (!effort.project) return

      this.$refs.chooseTaskDialog.open(effort.project.id, this.users)
        .then(taskChosen => {
          if(taskChosen){
            if(taskChosen.isWorkItem){
              effort.linkedItem = {
                name: taskChosen.name,
                responsibleFullName: taskChosen.assignedToFullName,
                totalExecuted: taskChosen.totalExecuted,
                estimatedEffort: taskChosen.estimatedEffort,
              }
              effort.workItemId = taskChosen.id
              effort.taskId = null
            }
            else {
              effort.linkedItem = taskChosen
              effort.taskId = taskChosen.id
              effort.workItemId = null
            }
            effort.linked = true
            effort.originalPending = taskChosen.pendingEffort
            effort.pending = taskChosen.pendingEffort;
            this.updatePendingMins(effort);
            effort.pendingChanged = false;
          }
          else {
            effort.linked = false;
          }
        })
    },
    deleteEffort(effort) {
      effort.deleted = true;
    },
    async save(closed) {
      if(this.reportType){
        this.report.executedHours = this.totalExecuted
      }
      if(closed){
        let message = { title: 'Save and confirm', description: 'Are you sure you want to save and confirm?' }
        let res = true

        let executedHours = parseInt(this.report.executedHours) + this.report.executedMins/60
        if(executedHours == 0){
          message.title = '0 hours registered'
          message.description = 'The total hours is 0. Are you sure you want to save and confirm?'
        } else {
          if(this.report.type && !this.report.efforts.filter(e => !e.deleted).length){
            window.getApp.error("There is no activity registered for this day, please fill out your report.")
            return
          } else if (!this.report.type && !Object.keys(this.projectWorkItems).length && !this.report.efforts.filter(e => !e.deleted).length){
            window.getApp.error("There is no activity registered for this day, please fill out your report.")
            return
          }
          if (executedHours < this.dailyHoursToReport) {
            message.title = 'Less hours than required'
            message.description = 'You have registered less hours than required. Are you sure you want to save and confirm?'
          }
        }
         res =await this.$openConfirmDialog({
            title: message.title,
            description: message.description,
            })
            if(!res)
          return    
      }
      this.report.status = closed ? 2 : 0
      this.warnOnLeave = false

      var report = _.cloneDeep(this.report)
      // Clear 'garbage'
      // report.efforts = report.efforts.filter(x => x.id || !x.deleted)
      _.each(report.efforts, effort => {
        if (!effort.projectId || !effort.linked) {
          effort.taskId = null;
          effort.workItemId = null
          effort.pending = null;
          effort.pendingHrs = null;
          effort.pendingMins = null;
        }
        effort.linkedItem = null;
        effort.project = null;

        let subcategory = _.find(this.subcategories, s => s.id == effort.subcategoryId)
        if (subcategory) effort.categoryId = subcategory.categoryId
      });
      if (!report.id) {
        report.date = moment(report.date).format("YYYY-MM-DD")
      }
      report.executedHours = parseInt(report.executedHours) + report.executedMins/60

      this.saving = true
      this.$q.save({
        api: `/api/Effort/DailyReport`,
        data: report,
        successMsg: "Report saved.",
        afterSuccessUrl: "/dailyReport",
        errorMsg: "Cannot save report.",
        afterErrorUrl: null,
      })
      .then(res => {
      })
      .catch(res => {
        this.warnLeave = true
      })
      .finally(() => this.saving = false)
    },
    updateTime(){
      if(this.report.executedMins < 10)
        this.report.executedMins = '0' + this.report.executedMins
      this.report.executedHours = (''+this.report.executedHours).substr(0, 2)
      this.report.executedMins = (''+this.report.executedMins).substr(0, 2)
    },

    init() {

      if (this.date) {
        this.report.date = this.date;
      }

      this.$http.get('api/google/linkedAccount')
        .then(res => {
          this.linkedAccount = res.data.email
        })
        .catch(err => {
          console.error(err)
          this.$gapi.grantOfflineAccess().then(async res => {
            await this.$http.post(`api/google/linkAccount`, {code: res, url: window.location.origin})
          })
        })

      var dateStd = moment(this.date).format("YYYY-MM-DD");

      let calendarPromise = this.$http.get(`api/google/calendar/${dateStd}`)
        .then(res => {
          this.calendarEvents = res.data
        })

      let workItemsEffort = this.$http.get(`api/WorkItems/efforts/${dateStd}`)
        .then(res => {
          this.statuses = res.data.statuses
          let workItems = res.data.workItems

          let projectWorkItems = {}
          for(let workItem of workItems) {
            for(let change of workItem.changes){
              change.type = 'change'
            }
            for(let comment of workItem.comments){
              comment.type = 'comment'
            }

            workItem.history = workItem.changes.concat(workItem.comments)
            workItem.history.sort((a, b) => {
              let aDate = a.dateTime || a.createdAt
              let bDate = b.dateTime || b.createdAt
              return moment(aDate) - moment(bDate)
            })
            this.setDarkenWorkItem(workItem)
            if(!projectWorkItems[workItem.projectId]){
              projectWorkItems[workItem.projectId] = {}
            }
            if(!projectWorkItems[workItem.projectId][workItem.type]){
              projectWorkItems[workItem.projectId][workItem.type] = []
            }
            projectWorkItems[workItem.projectId][workItem.type].push(workItem)
          }
          this.projectWorkItems = projectWorkItems
        })

      let getReportPromise = this.$http.get(`/api/effort/myDailyReport/${dateStd}`)
        .then(res => {
          this.report = res.data
          this.report.isClosed = this.report.status == 2
          if(this.report.isClosed){
            this.warnOnLeave = false
          }
          if (this.report.efforts && this.report.efforts.length) {
            _.each(this.report.efforts, e => {
              this.$set(e, "project", {})
              this.$set(e, "task", {})
              this.$set(e, "linked", false)
              this.$set(e, "deleted", false)
              this.$set(e, "subcategories", [])

              e.project = {
                id: e.projectId,
                name: e.projectName,
                typeName: e.projectTypeName,
                customerProduct: e.projectCustomerProduct,
              }
              e.linked = !!e.taskId || !!e.workItemId
              e.deleted = false
              this.updateExecutedMins(e)
              this.updatePendingMins(e)
              this.updateSubcategories(e)
            }) 
          }
          this.report.executedHours = Math.round(this.report.executedHours*100)/100
          this.report.executedMins = Math.round((this.report.executedHours - parseInt(this.report.executedHours))*60*100)/100
          this.report.executedMins = Math.round(this.report.executedMins)
          this.report.executedHours = parseInt(this.report.executedHours)
          if(this.report.executedMins < 10)
            this.report.executedMins = '0' + this.report.executedMins
        })
        .catch(res => {
          window.getApp.error("Cannot obtain report.");
          console.log(res);
        });

      let userPromise = this.$blueSystem.getCurrentUser()
        .then(user => {
          this.report.reporterId = user.id;
          this.report.reporterFullName = user.blueTag || user.fullName || user.email;
          this.reportType = user.reportType
          this.dailyHoursToReport = user.dailyHoursToReport || 0
        });
      
      this.$http
        .get(`/api/user?active=true`)
        .then(res => {
          this.users = res.data;
          this.$q.addFirstItem(this.users, "Any", "fullName")
        })
        .catch(res => {
          window.getApp.error("Cannot obtain users.");
          console.log(res);
        });

      let getProjectsPromise = this.$http
        .get(`/api/project?includeCategories=true`)
        .then(res => {
          let projects = res.data;
          projects = _.filter(projects, { status: 1 }); // Only active
          this.projects = projects;
          this.$q.addFirstItem(this.projects, "No project")
        })
        .catch(res => {
          window.getApp.error("Cannot obtain projects.");
          console.log(res);
        });

      let getSubcategoriesPromise = this.$http
        .get(`/api/user/current/subcategory`)
        .then(res => {
          this.subcategories = res.data
          let googleCalendarSubcategory = this.subcategories.find(subcategory => subcategory.name == 'Google Calendar')
          this.googleCalendarSubcategory = googleCalendarSubcategory || {}
        })
        .catch(res => {
          window.getApp.error("Cannot obtain subcategories.");
          console.log(res);
        });

      Promise.all([getReportPromise, getSubcategoriesPromise, getProjectsPromise, workItemsEffort, userPromise, calendarPromise])
        .then(() => {
          if(this.report.status !== 2 && this.report.executedHours === 0 && this.report.executedMins == 0){
            this.report.executedHours = this.dailyHoursToReport
            this.report.executedHours = Math.round(this.report.executedHours*100)/100
            this.report.executedMins = Math.round((this.report.executedHours - parseInt(this.report.executedHours))*60*100)/100
            this.report.executedHours = parseInt(this.report.executedHours)
            if(this.report.executedMins < 10)
              this.report.executedMins = '0' + this.report.executedMins

            if(!this.report.efforts.length){
              this.calendarEvents.forEach(this.addCalendarEvent)
            }
            
            if(!this.report.efforts.length && this.report.type)
              this.addNew()
          }
          // Update all subcategory lists
          this.report.efforts.forEach(e => this.updateSubcategories(e))
        })
    }

  }
}

</script>

<style scoped lang="scss">
  .workitems-container{
    padding: 0 20px;
    width: calc(100vw - 150px);
    .project{
      padding-bottom: 20px;
      h2{
        font-weight: 600;
      }
      .type{
        .type-name{
          font-size: 1.4em;
          margin-left: 5px;
        }
      }
    }
  }

  .workItems{
    border-left: 2px solid #eee;
    margin-left: 15px;
    padding-left: 20px;
    display: grid;
    grid-template-columns: 220px calc(100% - 220px);
    gap: 25px;

    .work-item{
      cursor: default;
      .summary {
        font-size: 16px;
        min-height: 50px;
      }
      .actions-box{
        width: 100%;
      }
      .actions-container{
        margin: 0 -12px;
        padding: 0 8px;
      }
      .indicators{
        display: flex;
        justify-content: space-between;
        margin: 0;
        margin-top: 10px;
        padding: 0 5px;
        background: rgba(0,0,0,.15);
      }
      .indicators div{
        display: inline-block;
        padding: 5px 10px 5px 3px;
        line-height: 15px;
        font-size: 11px;

        span{
          font-size: 12px;
        }
      }
    }
  }

  .changes{
    .change{
      border-bottom: 2px solid var(--v-text-lighten);
      padding-bottom: 7px;
      margin-bottom: 5px;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
      .change-icon{
        padding: 2px;
        border-radius: 4px;
        border: 2px solid var(--v-text-lighten);
        display: inline-block;
        margin-right: 40px;
      }
      span{
        font-size: 1.3em;
        color: var(--v-text-lighten2);
        font-family: "Roboto", sans-serif;
      }
    }
    .status-change{
      .status-container{
        cursor: default;
        display: inline-flex;
        align-items: center;
        margin: 1px 10px;
      }
      .status{
        display: inline-block;
        box-shadow: 0 0 5px #888;
        height: 33px;
        width: 100%;
        color: white;
        border-radius: 5px;
        padding: 5px 10px;
        text-align: center;
        font-size: .85em;
      }
    }
  }
  .time-input {
    display: grid;
    grid-template-columns: 30px 4px 30px;
    gap: 2px;
    justify-content: center;
    align-items: center;
    height: 45px;

    .v-text-field{
      height: 28px;
    }
  }
  .effort-input{
    height: 45px;
    padding-top: 7px;
  }
  .min-flex {
    flex: 0 0 auto;
  }
  .daily-report-edit ::v-deep .v-select__selection {
    margin: 4px 6px 4px 0;
  }
  .daily-report-edit .v-input {
    font-size: 1em;
  }
  .daily-report-edit .v-input ::v-deep label.v-label {
    font-size: 14px;
  }
  .daily-report-edit ::v-deep input {
    padding: 4px 0;
  }
  .daily-report-edit .effort {
    background: var(--v-background-lighten);
    border-radius: 4px;
  }

  .total-row { 
    font-weight: 700; 
    font-size: 20px;
  }
  small.detail {
    display: inline-block;
    padding-bottom: 5px;
  }
  small.detail label {
    font-weight: 600;  
  }

  /* Avoid chips overflowing flex */
  .flex {
    min-width: 1px;
  }
  .v-input ::v-deep .v-select__slot,
  .v-input ::v-deep .v-select__selections,
  .v-input ::v-deep .v-chip,
  .v-input ::v-deep .v-chip__content {
    min-width: 1px;
  }
  .v-input ::v-deep .v-chip__content .v-avatar {
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .status-toggle ::v-deep .v-btn__content {
    color: white;
    text-transform: normal;
  }

  .footer{
    position: fixed;
    bottom: 0;
    border-top: 1px solid var(--v-lightgray-base);
    width: calc(100vw - 59px - var(--v-menu-width));
    margin-left: -40px;
    padding: 20px 20px 20px 40px;
    background: var(--v-background-base);
    transition: all 0.1s ease-in-out;
  }

</style>