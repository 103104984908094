import moment from "moment"
const textMixin = {
  install (Vue, options) {
    Vue.mixin({
      methods: {
				countActiveFilters(filters, skip = []){
					let quantity = 0
					for(let key in filters){
						if(skip.includes(key)) continue
						if(Array.isArray(filters[key]) && filters[key].length)
							quantity++
						else if(!Array.isArray(filters[key]) && (filters[key] || filters[key] === 0))
							quantity++
					}
					return quantity
				},
				camelToNormal(str){
					return str
						.replace(/([A-Z])/g, ' $1')
				},
				currencyFormat(quantity, precise = false){
					if(!quantity) quantity = 0

					const opts = {
						style: 'currency',
						currency: 'USD',
					}
					if(precise){
						opts.minimumFractionDigits = 0
						opts.maximumFractionDigits = 20
					}

					let formatter = new Intl.NumberFormat('en-US', opts)
					let formatted = formatter.format(quantity)

					if(formatted === '-$0.00')
						formatted = '$0.00'

					return formatted
				},
				quantityFormat(quantity){
					let formatter = new Intl.NumberFormat()
					return formatter.format(quantity)
				},
				normalizeText(text, toLower = false) {
					text = '' + text
					if(toLower)
						text = text.toLowerCase();
					return text.normalize('NFD').replace(/([^n\u0300-\u036f]|n(?!\u0303(?![\u0300-\u036f])))[\u0300-\u036f]+/gi,"$1").normalize();
				},
				applySearch(search, items, attrs = []){
					if(!search)
						return items
					if(!attrs.length){
						for(let attr in items[0]){
							attrs.push(attr)
						}
					}
					let filteredItems = []
					for(let item of items){
						for(let attr of attrs){
							if(
								( typeof item[attr] === 'string' && this.normalizeIncludes(item[attr], search) ) ||
								( typeof item[attr] === 'number' && this.normalizeIncludes(item[attr].toString(), search) )
							){
								filteredItems.push(item)
								break
							}
						}
					}
					return filteredItems
				},
				normalizeIncludes(str, search){
					return this.normalizeText( str, true ).includes( this.normalizeText(search, true) )
				},
				formatDate: (date) => moment(date).format('MMM D, YYYY'),
				isBlackText(back) {
					if (!back) {
						return false
					}
	
					const hex   = back.replace(/#/, '')
					const r     = parseInt(hex.substr(0, 2), 16)
					const g     = parseInt(hex.substr(2, 2), 16)
					const b     = parseInt(hex.substr(4, 2), 16)
	
					let luma = [
							0.299 * r,
							0.587 * g,
							0.114 * b
					].reduce((a, b) => a + b) / 255
	
					return (luma < 0.5) 
				},
				setMaxText(text, max){
					if(!text)
						return ''
					if(text.length > max)
						text = text.substr(0, max) + '...'
					return text
				},
				htmlToText(html){
					html = html.replaceAll('</', ' </')
					let divTag = document.createElement('div')
					divTag.innerHTML = html
					return divTag.innerText
				},
				singleSheetExcel(items, headers, title = 'Report', sheetName = 'Sheet1'){
					let props = {
						Title: title,
						Author: 'BlueSurf',
						CreatedDate: new Date()
					}
		
					// Fill sheet info
					let data = [], row = []
		
					for(let header of headers){
						row.push(header.name)
					}
					data.push(row)
		
					for(let item of items){
						row = []
						for(let header of headers){
							row.push(header.value(item))
						}
						data.push(row)
					}
		
					let sheets = [{
						name: sheetName,
						data
					}]
		
					this.createExcel(props, sheets)
				},
				createExcel(props, sheets, conditionLength = (i) => {return 1.2}){
					const XLSX = require('xlsx');
					let book = XLSX.utils.book_new();
		
					book.Props = props;
					sheets.forEach(sheetData => {
							book.SheetNames.push(sheetData.name);
							let sheet = XLSX.utils.aoa_to_sheet(sheetData.data);
		
							let fitToColumn = (rows) => {
									let widths = [];
									rows.forEach(cols => {
										for (let i = 0; i < cols.length; i++) {
											if (!widths[i] || widths[i].wch < cols[i].length * (conditionLength(i))) {
												widths[i] = {wch: cols[i].length * (conditionLength(i))};
											}
										}
									});
									return widths;
							}
		
							sheet['!cols'] = fitToColumn(sheetData.data);
							book.Sheets[sheetData.name] = sheet;
					});
		
		
					var binaryBook = XLSX.write(book, {bookType:'xlsx',  type: 'binary'});
					let stringToArrayBuffer = function(s) { 
							var buf = new ArrayBuffer(s.length); //convert s to arrayBuffer
							var view = new Uint8Array(buf);  //create uint8array as viewer
							for (var i=0; i<s.length; i++) view[i] = s.charCodeAt(i) & 0xFF; //convert to octet
							return buf;    
					}
		
					let tagA = document.createElement('a');
					let file = new Blob([stringToArrayBuffer(binaryBook)],{type:"application/octet-stream"});
					tagA.href = URL.createObjectURL(file);
					tagA.download = book.Props.Title + '.xlsx';
					tagA.click();
					this.downloaded = true;
				},
				formatHttp(str){
					if(str.startsWith("https://") && location.href.startsWith("http://")){
						return str.replace("https://", "http://")
					}
					return str
				},
      }
    })
  }
}
export default textMixin