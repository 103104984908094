<template>
	<v-menu
		@input="addingWatcher.active = false"
		v-bind="$attrs"
		left offset-x
		:close-on-content-click="false"
		z-index="200" max-height="500"
	>
		<template #activator="attrs">
			<slot v-bind="attrs">
				<v-btn v-on="attrs.on" text small>
					<v-icon>mdi-eye</v-icon>
				</v-btn>
			</slot>
		</template>
		<v-list v-if="!addingWatcher.active" dense>
			<v-list-item @click="activeAddingWatcher">
				<v-list-item-content>
					<div class="d-flex align-center" style="gap: 10px">
						<v-btn height="32" width="32" fab depressed color="primary">
							<v-icon>mdi-plus</v-icon>
						</v-btn>
						Add watcher
					</div>
				</v-list-item-content>
			</v-list-item>
			<v-divider/>
			<v-list-item @click="subscribe(currentUser.id, !isWatching)">
				<v-list-item-content>
					<div class="d-flex align-center" style="gap: 10px">
						<v-btn height="32" width="32" fab depressed :color="isWatching ? '#F5C4C5' : '#e1f8dd'">
							<v-icon :color="isWatching ? 'error' : 'success'">mdi-eye-{{isWatching ? 'off' : 'check'}}</v-icon>
						</v-btn>
						{{ isWatching ? 'Stop' : 'Start' }}
						watching
					</div>
				</v-list-item-content>
			</v-list-item>
			<v-divider/>
			<v-list-item>
				<v-list-item-content>
					<div class="d-flex align-center" style="gap: 10px">
						<v-btn height="32" width="32" fab depressed color="#E4E4E4">
							<v-icon color="#868686">mdi-eye</v-icon>
						</v-btn>
						Watchers
					</div>
				</v-list-item-content>
			</v-list-item>
			<v-list-item v-for="watcher in sortedWatchers" :key="watcher.id" class="background--lighten2 watcher">
				<v-list-item-content>
					<div class="d-flex align-center justify-space-between">
						<div class="d-flex align-center" style="gap: 10px">
							<user-avatar :show-card="false" :user-id="watcher.userId" :size="32" hide-loading/>
							<b>{{ watcher.userBlueTag }}</b>
						</div>
						<v-btn @click="subscribe(watcher.userId, false)" class="remove-btn" x-small icon>
							<v-icon>mdi-close</v-icon>
						</v-btn>
					</div>
				</v-list-item-content>
			</v-list-item>
		</v-list>
		<v-card v-else>
			<v-card-title class="align-center py-2">
				<v-btn @click="addingWatcher.active = false" icon color="primary" class="ml-n3">
					<v-icon>mdi-chevron-left</v-icon>
				</v-btn>
				<span class="body-1 text--text">
					Add watcher
				</span>
			</v-card-title>
			<v-divider/>
			<v-card-text>
				<v-text-field
					v-model="addingWatcher.search"
					append-icon="mdi-magnify" label="Search by bluetag"
					outlined dense hide-details
				/>
				<v-list dense>
					<v-list-item
						v-for="user in shownUsers" :key="user.id"
						@click="subscribe(user.id, true)"
						class="pl-0"
					>
						<v-list-item-content>
							<div class="d-flex align-center" style="gap: 10px">
								<user-avatar :show-card="false" :user-id="user.id" :size="32" hide-loading/>
								<b>{{ user.blueTag }}</b>
							</div>
						</v-list-item-content>
					</v-list-item>
				</v-list>
			</v-card-text>
		</v-card>
	</v-menu>
</template>

<script>
export default {
	props: {
		entityId: String,
		watchers: Array,
		requiredPermission: Number|String,
	},
	data: () => ({
		addingWatcher: {
			active: false,
			search: '',
		},
		users: [],
		currentUser: null,
	}),
	computed: {
		sortedWatchers(){
			return this.watchers.sort((a, b) => a.userBlueTag.localeCompare(b.userBlueTag))
		},
		shownUsers(){
			if(!this.addingWatcher.search) return []
			return this.applySearch(this.addingWatcher.search, this.users, ['blueTag', 'fullName'])
				.filter(x => !this.watchers.some(y => y.userId === x.id))
		},
		isWatching(){
			return this.watchers.some(x => x.userId === this.currentUser?.id)
		},
	},
	methods: {
		subscribe(userId, value){
			this.$http.post(`api/admin/${value ? 'subscribe' : 'unsubscribe'}/${this.requiredPermission}/${this.entityId}/${userId}`)
				.then(res => {
					this.addingWatcher.active = false
					this.addingWatcher.search = ''
					if(value)
						this.$emit('watcher-added', res.data)
					else
						this.$emit('watcher-removed', userId)
				})
				.catch(err => {
					console.error(err)
					window.getApp.error('Error adding watcher')
				})
		},
		async activeAddingWatcher(){
			if(!this.users?.length){
				await this.$http.get(`api/user?permission=${this.requiredPermission}`)
					.then(res => {
						this.users = res.data
					})
					.catch(err => {
						console.error(err)
						window.getApp.error('Error getting users')
					})
				
				this.$blueSystem.getCurrentUser()
					.then(user => {
						this.currentUser = user
					})
			}

			this.addingWatcher.active = true
		},
	},
}
</script>

<style lang="scss" scoped>
.watcher {
	.remove-btn{
		display: none;
	}
	&:hover {
		.remove-btn{
			display: block;
		}
	}
}
</style>