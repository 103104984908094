function findInput (el, max_depth = 5) {
	if (el.nodeName === 'INPUT') {
		return el
	}
	if (max_depth === 0) {
		return null
	}
	for (let child of el.children) {
		let input = findInput(child, max_depth - 1)
		if (input) {
			return input
		}
	}
}

export const ClearOnSelect = {
	inserted(el, binding, vNode) {
		if(!binding.value) return
		vNode.context.$on('input', () => {
			vNode.componentInstance.lazySearch = ''
		})
	}
}

export const OnlyNumbers = {
	inserted(el, binding, vNode) {
		let input = findInput(el)
		input.addEventListener('keydown', (e) => {
			if(/^\d*\.?\d*$/.test(e.key) || e.key === 'Backspace') return
			e.preventDefault()
			e.stopPropagation()
		})
	}
}
