<template>
	<v-data-table
		class="rounded-lg bordered new-back lighten2"
		checkbox-color="primary"
		:headers="shownHeaders"
		:items="billables"
		:items-per-page.sync="pagination.itemsPerPage"
		:page.sync="pagination.page"
		:sort-by.sync="pagination.sortBy"
		:sort-desc.sync="pagination.sortDesc"
		@update:sort-by="$emit('pagination', $event)"
		@update:sort-desc="$emit('pagination', $event)"
		:server-items-length="pagination.totalItems"
		v-bind="$attrs" v-on="$listeners"
	>
		<template #[`item.code`]="{ item }">
			<div class="text-left">
				{{item.code}}
			</div>
		</template>
		<template #[`item.customerName`]="{ item }">
			<div class="text-left">
				{{item.customerName}}
			</div>
		</template>
		<template #[`item.projects`]="{ item }">
			<div
				v-for="project in item.customerProjects" :key="project.id"
				class="project" :style="{background: projectTypes.find(t => t.value == project.type).lightColor}"
			>
				<div
					class="letter"
					:style="{background: projectTypes.find(t => t.value == project.type).color}"
				>
					{{projectTypes.find(t => t.value == project.type).small}}
				</div>
				<div class="name">{{project.name}}</div>
			</div>
		</template>
		<template #[`item.currency`]="{ item }">
			<v-avatar size="24" class="mr-1">
				<v-img :src="require(`../assets/currencies/${item.currency.toLowerCase()}.png`)" />
			</v-avatar>
			<b>
				{{ item.currency }}
			</b>
		</template>
		<template #[`item.type`]="{ item }">
			{{ codeByType[item.type] }}
		</template>
		<template #[`item.startDate`]="{ item }">
			{{ item.startDate | formatDate }}
		</template>
		<template #[`item.endDate`]="{ item }">
			{{ item.endDate | formatDate }}
		</template>
		<template #[`item.status`]="{ item }">
			<v-menu offset-x right open-on-hover>
				<template #activator="{ on }">
					<v-btn
						v-on="on"
						:color="item.statusColor"
						outlined small class="rounded-xl"
						:style="{
							background: $vuetify.theme.isDark ? '' : item.statusLightColor
						}"
					>{{item.statusDisplayName}}</v-btn>
				</template>
				
				<v-list dense>
					<v-list-item
						v-for="approver in item.approvers.filter(x => x.statusToApprove === 1)"
						:key="approver.id"
					>
						<div
							class="d-flex justify-space-between align-center"
							style="min-width: 20px; width: 100%"
						>
							<span class="mr-2 body-2">
								<user-avatar :user-id="approver.approverId" :size="28" :show-card="false"/>
								{{ approver.approverBlueTag }}
							</span>
							<span :set="status = approverStatus.find(s => s.id == approver.status)">
								<v-chip
									:color="status.lightColor"
									:style="{ color: status.color }"
									small
								>
									{{ status.description }}
								</v-chip>
							</span>
						</div>
					</v-list-item>
				</v-list>
			</v-menu>
		</template>
		<template #[`item.plannedHours`]="{ item }">
			<div v-if="item.type === 0 || item.type === 2">-</div>
			<billable-percentage-bar
				v-else
				:primaryAmount="item.plannedBillableHours"
				:secondaryAmount="item.plannedNonBillableHours"
				:objective="item.isOpen ? item.plannedBillableHours : item.contractedHours"
				:limit-secondary-percentage="item.type !== 3"
				:use-total-amount="item.type === 3"
				:infinity="item.isOpen"
			/>
		</template>
		<template #[`item.executedHours`]="{ item }">
			<div v-if="item.type === 0 || item.type === 2">-</div>
			<billable-percentage-bar
				v-else
				:primaryAmount="item.executedBillableHours"
				:secondaryAmount="item.executedNonBillableHours"
				:objective="item.plannedBillableHours"
				:limit-secondary-percentage="item.type !== 3"
				:use-total-amount="item.type === 3"
			/>
		</template>
		<template #[`item.billedHours`]="{ item }">
			<div v-if="item.isOpen">-</div>
			<template v-else>
				<progress-bar
					:items="[
						{ amount: item.billed },
						{ amount: -item.negativeAdjustment, skipForTotal: true },
						{ amount: item.positiveAdjustment, skipForTotal: true },
					]"
					:objective="item.subtotalWithDiscount"
					:amountFormatter="currencyFormat"
				/>
			</template>
		</template>
		<template #[`item.actions`]="{ item }">
			<v-menu v-if="$security.can('edit')" left offset-x>
				<template #activator="{ on }">
					<v-btn v-on="on" icon><v-icon>mdi-dots-vertical</v-icon></v-btn>
				</template>
				<v-list dense class="py-1">
					<v-list-item v-if="item.canApprove" class="pa-1 py-0">
						<v-tooltip right z-index="200">
							<template #activator="{ on }">
								<v-btn v-on="on" @click="$emit('validate', item)" color="primary" text small>
									<v-icon>mdi-check</v-icon>
								</v-btn>
							</template>
							<span class="caption">Validate</span>
						</v-tooltip>
					</v-list-item>
					<v-list-item class="pa-1 py-0">
						<v-tooltip right z-index="200">
							<template #activator="{ on }">
								<v-btn v-on="on" @click="$adminSurf('openHistory', item, 'billable')" text small>
									<v-icon>mdi-history</v-icon>
								</v-btn>
							</template>
							<span class="caption">History</span>
						</v-tooltip>
					</v-list-item>
					<v-list-item class="pa-1 py-0">
						<v-tooltip right z-index="200">
							<template #activator="{ on }">
								<v-btn v-on="on" @click="$emit('duplicate', item.id)" text small color="grey">
									<v-icon>mdi-content-copy</v-icon>
								</v-btn>
							</template>
							<span class="caption">Duplicate</span>
						</v-tooltip>
					</v-list-item>
					<v-list-item v-if="!item.isCancelled && item.status != 6" class="pa-1 py-0">
						<v-tooltip right z-index="200">
							<template #activator="{ on }">
								<v-btn v-on="on" @click="$adminSurf('closeBillable', item)" text small color="secondary">
									<v-icon>mdi-door</v-icon>
								</v-btn>
							</template>
							<span class="caption">Close</span>
						</v-tooltip>
					</v-list-item>
					<v-list-item v-if="!item.isCancelled && item.status == 6 && $security.can('full_access')" class="pa-1 py-0">
						<v-tooltip right z-index="200">
							<template #activator="{ on }">
								<v-btn v-on="on" @click="$adminSurf('openBillable', item)" text small color="secondary">
									<v-icon>mdi-door-open</v-icon>
								</v-btn>
							</template>
							<span class="caption">Reopen</span>
						</v-tooltip>
					</v-list-item>
					<v-list-item v-if="!item.isCancelled && item.status != 6" class="pa-1 py-0">
						<v-tooltip right z-index="200">
							<template #activator="{ on }">
								<v-btn v-on="on" @click="$emit('cancel', item)" text small color="error">
									<v-icon>mdi-cancel</v-icon>
								</v-btn>
							</template>
							<span class="caption">Cancel</span>
						</v-tooltip>
					</v-list-item>
					<v-list-item class="pa-1 py-0">
						<watchers-menu
							@watcher-added="item.subscribers.push($event)"
							@watcher-removed="item.subscribers.splice(item.subscribers.findIndex(x => x.userId == $event), 1)"
							:entity-id="item.id"
							:watchers="item.subscribers"
							required-permission="billables"
						/>
					</v-list-item>
				</v-list>
			</v-menu>
		</template>
	</v-data-table>
</template>

<script>
import BillableJSON from '../json/billable.json'
import BillablePercentageBar from './admin-surf/billable/BillablePercentageBar'
import WatchersMenu from './admin-surf/WatchersMenu'
import ProgressBar from './ProgressBar'

export default {
	props: {
		billables: Array,
		small: Boolean,
		xSmall: Boolean,
		pagination: { type: Object, default: () => ({
			page: 1,
			itemsPerPage: 10,
		}) },
	},
	components: { BillablePercentageBar, WatchersMenu, ProgressBar },
	data: () => ({
		headers: [
			{ text: "Code", value: "code", small: true, xSmall: true },
			{ text: "Customer", value: "customerName", small: true },
			{ text: "Projects", value: "projects", align: 'center', small: true, xSmall: true, sortable: false },
			{ text: "Currency", value: "currency", align: 'center' },
			{ text: "Type", value: "type", align: 'center' },
			{ text: "Start Date", value: "startDate" },
			// { text: "End Date", value: "endDate" },
			{ text: "Status", value: "status", align: 'center', small: true, sortable: false },
			{ text: "Planned vs Contracted Hours", value: "plannedHours", align: 'center', width: '250px', sortable: false },
			{ text: "Executed vs Planned Hours", value: "executedHours", align: 'center', width: '250px', sortable: false },
			{ text: "Billed vs Contracted", value: "billedHours", align: 'center', width: '250px', sortable: false },
			{ text: "", value: "actions", width: '1', small: false, sortable: false }, 
		],
		codeByType: ['FxR', 'RPAH', 'RPEH', 'Int'],
		projectTypes: [],
		approverStatus : [],
	}),
	mounted(){
		this.projectTypes = BillableJSON.projectTypes

		this.$http.get('api/enums/approver/status')
			.then(res => {
				this.approverStatus = res.data
			})
			.catch(err => {
				console.error(err)
				this.$root.error('Cannot obtain billable items')
			})
	},
	computed: {
		shownHeaders(){
			if(this.xSmall) return this.headers.filter(h => h.xSmall)
			return this.headers
				.filter(h => !this.small || h.small)
		},
	},
}
</script>

<style lang="scss" scoped>
.project{
	display: inline-block;
	border-radius: 15px;
	margin-right: 5px;
	white-space:nowrap;
	height: 30px;
	div{
		display: inline-flex;
		align-items: center;
		justify-content: center;

	}
	.letter{
		width: 30px;
		padding: 5px;
		color: white;
		border-radius: 15px;
	}
	.name{
		padding: 0 10px 0 5px;
		color: black;
	}
}
</style>