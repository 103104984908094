import moment from "moment";

export default function (nucleiDevChart, timeUnit, handleZoom) {
  let categories = []
  if(timeUnit == 'Days'){
    categories = nucleiDevChart.map(c => moment(c.date).format('YYYY-MM-DD'))
  }
  else if(timeUnit == 'Months'){
    categories = nucleiDevChart.map(c => moment(c.date).format('MMM'))
  }
  else if(timeUnit == 'Weeks'){
    categories = nucleiDevChart.map(c => moment(c.date).isoWeek())
  }
  
  return {
    chart: {
      "zoomType": "x",
      "resetZoomButton": {
        "theme": {
          "display": "none"
        }
      },
			backgroundColor: 'transparent'
    },
    "title": {
      "text": ""
    },
    "xAxis": {
      "categories": categories,
      events: {
				setExtremes: handleZoom,
			},
    },
    "yAxis": [{
      "title": {
        "text": ""
      },
      "min": 0
    }],
    "plotOptions": {
      "area": {
        "fillOpacity": 0
      }
    },
    "series": [{
        "type": "area",
        "name": "Workforce",
        "data": nucleiDevChart.map(c => c.devCenterWorkforce),
        "marker": {
          "radius": 4
        },
        "lineWidth": 3
      },
      {
        "type": "area",
        "name": "Active Nuclei",
        "data": nucleiDevChart.map(c => c.nucleiWorkforce),
        "marker": {
          "radius": 4
        },
        "lineWidth": 3
      },
    ],
    "colors": [
      "#8180fb",
      "#f57b83"
    ]
  }
}