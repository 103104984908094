var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('filter-search-input',{attrs:{"applied-filters":_vm.appliedFilters,"outlined":"","dense":""},model:{value:(_vm.filters.search),callback:function ($$v) {_vm.$set(_vm.filters, "search", $$v)},expression:"filters.search"}},[_c('v-autocomplete',{attrs:{"items":_vm.users,"label":"Bluetag","dense":"","outlined":"","hide-details":"","clearable":"","multiple":"","item-text":"blueTag","item-value":"id"},model:{value:(_vm.filters.users),callback:function ($$v) {_vm.$set(_vm.filters, "users", $$v)},expression:"filters.users"}})],1),_c('data-table',{attrs:{"headers":_vm.headers,"items":_vm.shownSuggestions,"item-key":"_key","show-expand":"","single-expand":""},scopedSlots:_vm._u([{key:"item.recessRequest.requestorBlueniversary",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("formatDate")(item.recessRequest.requestorBlueniversary))+" ")]}},{key:"item.requestedDates",fn:function(ref){
var item = ref.item;
return [_c('span',[(item.recessRequest.lines.some(function (x) { return x.date; }))?_c('span',[_vm._v(" "+_vm._s(_vm._f("formatDates")(item.recessRequest.lines))+" ")]):_vm._e()])]}},{key:"item.recessRequest.createdAt",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("formatDate")(item.recessRequest.createdAt))+" ")]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex justify-center"},[_c('v-btn',{attrs:{"color":"primary","icon":""},on:{"click":function($event){return _vm.accept(item)}}},[_c('v-icon',[_vm._v("mdi-check-circle-outline")])],1),_c('v-tooltip',{attrs:{"disabled":item.originalAssignmentId && item.originalAssignmentId !== _vm.emptyId,"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('div',_vm._g({},on),[_c('v-btn',{attrs:{"disabled":!item.originalAssignmentId || item.originalAssignmentId === _vm.emptyId,"icon":""},on:{"click":function($event){return _vm.reject(item)}}},[_c('v-icon',[_vm._v("mdi-close-circle-outline")])],1)],1)]}}],null,true)},[_c('span',[_vm._v("Please, first save changes")])])],1)]}},{key:"expanded-item",fn:function(ref){
var innerHeaders = ref.headers;
var item = ref.item;
return [_c('td',{attrs:{"colspan":innerHeaders.length}},[_c('div',{staticClass:"my-3"},[_c('b',[_vm._v("Original Assignment")]),_c('assignments-table',{staticClass:"mt-1",attrs:{"items":[item.originalAssignment],"readonly":"","disable-sort":"","disable-pagination":"","hide-default-footer":"","hidden-headers":['userBlueTag', 'actions']}})],1),_c('div',{staticClass:"my-3"},[_c('b',[_vm._v("Suggested Assignments")]),_c('assignments-table',_vm._g({staticClass:"mt-1",attrs:{"item-key":"_key","items":item.suggested,"billable-lines":_vm.billableLines,"billables":_vm.billables,"users":_vm.users,"customer-projects":_vm.customerProjects,"hidden-headers":['userBlueTag'],"disabled-headers":['billableCode', 'billableLineRole', 'customerProjectName'],"disable-sort":"","disable-pagination":"","hide-default-footer":""}},_vm.$listeners))],1)])]}}],null,true)}),_c('assignments-delete-dialog',{ref:"deleteDialog"})],1)}
var staticRenderFns = []

export { render, staticRenderFns }