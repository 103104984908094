<template>
	<div class="mt-15" ref="kanban">
		<h4 class="mb-2">Kanban Board</h4>
		<v-row v-for="n in parseInt(this.statuses.length/4) + !!(this.statuses.length%4)" :key="n">
			<v-col
				:cols="3"
				v-for="status in statuses.slice((n-1)*4, n*4)" :key="status.id"
			>
				<div class="status">
					<div class="color" :style="{background: status.color}">
						<v-icon color="white">{{status.iconClass}}</v-icon>
						<v-spacer></v-spacer>
						<span>
							{{ status.name}}
						</span>
						<v-spacer></v-spacer>
					</div>
					<div class="quantity" :style="{background: status.color}">
						{{status.workItemsQuantity}}
					</div>
				</div>
			</v-col>
		</v-row>
	</div>
</template>

<script>
export default {
	props: ['statuses'],
	methods: {
		getCanvases(){
			let lineElements = this.$refs.kanban.children
			let elements = []
			for(let line of lineElements)
				elements.push(line)
			return elements
		},
	}
}
</script>

<style scoped>
.status{
	display: grid;
	grid-template-columns: auto 40px;
	gap: 3px;
}

.color {
  color: white;
  padding: 5px 10px;
  border-radius: 5px;
  display: flex;
  justify-content: space-between;
	align-items: center;
}

.quantity{
	display: flex;
	align-items: center;
	justify-content: center;
	width: 40px; height: 40px;
	border-radius: 50%;
	/* background: var(--v-primary-base); */
	color: white;
}
</style>