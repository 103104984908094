<template>
	<div class="pa-5">
		
		<div class="switches">
			<h2 class="inline-block">
				<v-btn color="primary" fab small @click="$router.go(-1)"><v-icon>mdi-arrow-left</v-icon></v-btn>
				Traceability Report
			</h2>
			<div class="float-right" style="display: flex; align-items: center">
				<v-switch v-model="showAllWorkItems" class="ma-0 pa-0 ml-3" hide-details inset color="primary"/>
				<label class="mr-5">Show unrelated</label>
				<v-switch v-model="showSummary" class="ma-0 pa-0 ml-3" hide-details inset color="primary"/>
				<label>Show Summary</label>
			</div>
		</div>
		<div class="traceabilityDiagram background--lighten rounded elevation-4 pa-5 pt-10 ">
			<div class="information">
				<v-menu offset-y open-on-hover >
					<template v-slot:activator="{ on, attrs }">
						<v-btn class="st" text v-on="on" v-bind="attrs">
							<v-icon>mdi-information-outline</v-icon>
						</v-btn>
					</template>

					<v-card style="width: 300px">
						<v-card-text>
							<v-row>
								<v-col :cols="6" v-for="type in types" :key="type.value" style="position: relative;">
									<v-icon :color="type.color.substr(0, 7)">{{type.iconClass}}</v-icon>
									{{type.displayName}}
								</v-col>
							</v-row>
						</v-card-text>
					</v-card>
				</v-menu>
			</div>
			<div ref="diagram" id="diagram" class="text-center"></div>
			<div
				class="selected-workitem" v-if="selectedWorkItem"
				:style="{
					top: (selectedWorkItem.y + selectedWorkItem.height - 10) + 'px', 
					left: (selectedWorkItem.x + selectedWorkItem.width - 10) + 'px',
				}"
			>
				<v-card>
					<v-card-text class="pa-0 pb-1">
						<div
							class="mb-1 pr-5 pl-2 py-1 white--text"
							:style="{
								textAlign: 'left',
								background: selectedWorkItem.statusColor,
								borderRadius: '5px 5px 0 0',
							}"
						>
							<v-icon color="white" x-small>{{ selectedWorkItem.statusIconClass }}</v-icon>
							{{ selectedWorkItem.statusName }}
						</div>
						<h4 class="px-2"> {{ selectedWorkItem.code }} - {{ selectedWorkItem.name }} </h4>
						<div class="px-2" v-if="selectedWorkItem.assignedToBlueTag || selectedWorkItem.assignedToFullName">
							<b>Assigned to:</b> {{ selectedWorkItem.assignedToBlueTag || selectedWorkItem.assignedToFullName }}
						</div>
					</v-card-text>
				</v-card>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	props: ['id'],
	data(){
		return {
			linkingOptions: [],
			mainLinking: [
				'blocks',
				'cloned',
				'duplicates',
				'splitTo',
				'causes',
				'relatesTo',
			],
			workItems: [],
			workItemsSetted: new Set(),
			yumlText: '',
			types: [],
			showSummary: false,
			showAllWorkItems: false,
			svgns: 'http://www.w3.org/2000/svg',
			selectedWorkItem: null,
		}
	},
	mounted() {
		this.$http.get(`/api/Enums/WorkItemType`)
			.then(res => {
				this.types = res.data
			})

		let linkingPr = this.$http.get('api/workItem/options/linking')
			.then(res => {
				this.linkingOptions = res.data
			})

		let workItemsPr = this.$http.get(`api/WorkItems/${this.id}/Links`)
			.then(res => {
				this.workItems = res.data
			})

		Promise.all([linkingPr, workItemsPr])
			.then(this.generateYumlText)
	},
	watch: {
		'$vuetify.theme.dark'(){
			this.setYuml()
		},
		showSummary(){
			this.generateYumlText()
		},
		showAllWorkItems(){
			this.generateYumlText()
		},
	},
	methods: {
		generateYumlText(){
			let text = `// {type:class}\n`
			this.workItemsSetted = new Set()
			for(let workItem of this.workItems){
				let code = workItem.code
				let color = (workItem.boardColor || '#ffffff').substr(0, 7)
				if(this.showAllWorkItems && (!workItem.linkedWorkItems || !workItem.linkedWorkItems.length)){
					text += `[${code}{bg:${color}}]\n`
				}
				for(let linked of workItem.linkedWorkItems){
					if(this.workItemsSetted.has(linked.workItemId + '-' + linked.relatedWorkItemId))
						continue

					let reversedRelation = this.getReverseLink(linked.relationship)
					let relation = linked.relationshipDisplayName
					let linkedCode = linked.relatedWorkItemCode
					let linkedColor = (linked.relatedWorkItemBoardColor || '#ffffff').substr(0, 7)

					let summary = '', linkedSummary = ''
					if(this.mainLinking.includes(linked.relationship)){
						text += `[${code} ${summary}{bg:${color}}]-${relation}>[${linkedCode} ${linkedSummary}{bg:${linkedColor}}]\n`
					}
					else {
						text += `[${linkedCode} ${linkedSummary}{bg:${linkedColor}}]-${reversedRelation}>[${code} ${summary}{bg:${color}}]\n`
					}

					this.workItemsSetted.add(linked.workItemId + '-' + linked.relatedWorkItemId)
					this.workItemsSetted.add(linked.relatedWorkItemId + '-' + linked.workItemId)
				}
			}
			this.yumlText = text
			this.setYuml()
		},
		getReverseLink(link){
			let reversedLink = this.linkingOptions.find(l => l.name == link).reverseName
			return this.linkingOptions.find(l => l.name == reversedLink).displayName
		},
		getWorkItem(code){
			code = code.split(' ')[0]
			return this.workItems.find(wi => wi.code == code)
		},
		setSelectedWorkItem(el, workItem){
			let coords = el.getBoundingClientRect()
			if(!workItem){
				this.selectedWorkItem = null
			}
			else{
				this.selectedWorkItem = {
					...workItem,
					x: coords.x,
					y: coords.y,
					width: coords.width,
					height: coords.height,
				}
			}
		},
		setYuml(){
			const YumlDiagram = require('yuml-diagram')
			const yuml = new YumlDiagram()

			let diagram = yuml.processYumlDocument(this.yumlText, this.$vuetify.theme.isDark)
			diagram = yuml.processYumlDocument(this.yumlText, this.$vuetify.theme.isDark)
			this.$refs.diagram.innerHTML = diagram

			this.$nextTick(() => {
				let edges = document.querySelectorAll('#diagram .edge')
				for(let edge of edges){
					let box = edge.children[1].getBBox()
					let text = edge.children[3]
					let textBox = text.getBBox()
					text.setAttribute('x', (box.width)/2 + box.x)
					text.setAttribute('y', (box.height)/2 + box.y + textBox.height/2)
					this.makeBG(text)
				}
				let nodes = document.querySelectorAll('#diagram .node')
				for(let node of nodes){
					let code = node.children[2].innerHTML
					node.style.cursor = 'default'
					let wi = this.getWorkItem(code)
					node.addEventListener('mouseenter', () => {
						this.setSelectedWorkItem(node, wi)
					})
					node.addEventListener('mouseleave', () => {
						this.setSelectedWorkItem(node, null)
					})
					let typeColor = wi.typeColor.substr(0, 7)
					if(this.showSummary){
						let textTag = document.createElementNS(this.svgns, "tspan")
						textTag.innerHTML = wi.name.substr(0, 12)
						textTag.setAttribute('dy', '1.3em')
						textTag.setAttribute('dx', '-1.2em')
						textTag.setAttribute('x', node.children[2].getBBox().x)
						textTag.setAttribute('text-anchor', 'start')
						textTag.setAttribute('font-size', '10px')
						textTag.setAttribute('font-weight', '400')
						node.children[2].setAttribute('dy', '-.5em')
						node.children[2].setAttribute('dx', '-1em')
						node.children[2].append(textTag)
					}
					node.children[0].innerHTML = 'Code: ' + wi.code + '\n'
						+ 'Summary: ' + wi.name + '\n'
						+ 'Status: ' + wi.statusName + '\n'
						+ 'Assigned To: ' + wi.assignedToBlueTag
					node.children[0].innerHTML = ''
					let polygon = node.children[1]
					this.makeBG(polygon, 'fill', typeColor)
				}
			})
		},
		makeBG(elem, backColor = 'background-color', specColor = null) {
			var bounds = elem.getBBox()
			var bg = document.createElementNS(this.svgns, "rect")
			var style = getComputedStyle(elem)
			var padding_top = parseInt(style["padding-top"])
			var padding_left = parseInt(style["padding-left"])
			var padding_right = parseInt(style["padding-right"])
			var padding_bottom = parseInt(style["padding-bottom"])
			bg.setAttribute("x", bounds.x - parseInt(style["padding-left"]))
			bg.setAttribute("y", bounds.y - parseInt(style["padding-top"]))
			bg.setAttribute("width", bounds.width + padding_left + padding_right)
			bg.setAttribute("height", bounds.height + padding_top + padding_bottom)
			bg.setAttribute("fill", style[backColor])
			bg.setAttribute("rx", style["border-radius"])
			bg.setAttribute("stroke-width", style["border-top-width"])
			if(specColor){
				bg.setAttribute("stroke", specColor)
			}
			else{
				bg.setAttribute("stroke", style["border-top-color"])
			}
			if (elem.hasAttribute("transform")) {
				bg.setAttribute("transform", elem.getAttribute("transform"))
			}
			elem.parentNode.insertBefore(bg, elem)
		}
	},
}
</script>

<style lang="scss" scoped>
h2{
	display: inline-block;
	margin-bottom: 20px;
}
.traceabilityDiagram{
	position: relative;
	width: calc(100vw - 190px);
	overflow: scroll;
	display: block;
}
.information{
	position: sticky;
	display: block;
	text-align: right;
	left: 0;
	margin-top: -35px;
	margin-bottom: 15px;
}
::v-deep svg{
	padding: 4px;
}
::v-deep .node{
	polygon {
		padding: 1px 5px;
		stroke-width: 0px;
		border-radius: 5px;
		border: 2px solid black;
	}
	rect {
	}
	text {
		font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
		font-size: 12px;
		font-weight: 600;
	}
}
::v-deep .edge{
	path {
		stroke: grey;
		stroke-width: 2px;
	}
	polygon {
		fill: grey;
		stroke: grey;
	}
	text{
		font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
		background: var(--v-background-lighten);
		text-transform: uppercase;
		fill: grey;
		font-weight: 600;
	}
}

.selected-workitem{
	position: fixed;
	max-width: 240px;
}

.switches{
	position: sticky;
	left: 140px;
	max-width: calc(100vw - 190px);
}
::v-deep svg{
	padding: 4px;
}
::v-deep .node{
	polygon {
		padding: 1px 5px;
		stroke-width: 0px;
		border-radius: 5px;
		border: 2px solid black;
	}
	rect {
	}
	text {
		font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
		font-size: 12px;
		font-weight: 600;
	}
}
::v-deep .edge{
	path {
		stroke: grey;
		stroke-width: 2px;
	}
	polygon {
		fill: grey;
		stroke: grey;
	}
	text{
		font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
		background: var(--v-background-lighten);
		text-transform: uppercase;
		fill: grey;
		font-weight: 600;
	}
}

.selected-workitem{
	position: fixed;
	max-width: 240px;
}

.switches{
	position: sticky;
	left: 140px;
	max-width: calc(100vw - 190px);
}
</style>