<template>
    <v-app-bar dark color="deep-purple darken-4" app class="app-toolbar">
      <v-btn icon text @click.stop="handleDrawerToggle">
        <v-icon>reorder</v-icon>
      </v-btn>
      <!-- <v-app-bar-nav-icon @click.stop="handleDrawerToggle"></v-app-bar-nav-icon> -->
      <v-spacer></v-spacer>
      
      <v-btn icon @click="handleFullScreen()">
        <v-icon>fullscreen</v-icon>
      </v-btn>
      
      <v-badge dark :value="!!newMessages" color="pink" overlap :content="newMessages"
        offset-x="20" 
        offset-y="40">
        <v-btn icon @click="$emit('openNotifications')">
          <v-icon>notifications</v-icon>
        </v-btn>
      </v-badge>
      <v-menu offset-y origin="center center" :nudge-bottom="10" transition="slide-y-transition">
        <template v-slot:activator="{ on }">
          <v-btn icon large text v-on="on">
            <v-icon>account_circle</v-icon>
          </v-btn>
        </template>
        <v-list class="pa-0">
          <v-list-item v-for="(item,index) in items" :to="!item.href ? { name: item.name } : null" :href="item.href" @click="item.click" ripple="ripple" :disabled="item.disabled" :target="item.target" rel="noopener" :key="index">
            <v-list-item-action v-if="item.icon">
              <v-icon>{{ item.icon }}</v-icon>
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title>{{ item.title }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </v-menu>
    </v-app-bar>
</template>

<script>
import Util from "./../utils"
import moment from "moment"

export default {
  props: {
    newMessages: Number,
  },
  data() {
    return {
      toDoCount: 0,
      toDos: [],
      futureToDos: [],
      title: "BlueSurf",
      items: [
        {
          icon: "account_circle",
          href: "#",
          title: "Profile",
          click: e => {
            console.log(e)
          }
        },
        // {
        //   icon: "settings",
        //   href: "#",
        //   title: "Settings",
        //   click: e => {
        //     console.log(e)
        //   }
        // },
        {
          icon: "logout",
          href: "#",
          title: "Logout",
          click: this.logout
        }
      ]
    }
  },
  created() {
  },
  methods: {
    logout() {
      
      this.$vuetifyConfirmDialog.open("Logout", "Are you sure you want logout?", "Cancel", "Confirm")
        .then(confirmed => {
          if (!confirmed) return
          
          this.$http.get('/api/logout')
            .then((result) => {
              this.$router.push('/login');
            })
            .catch((error) => {
              window.getApp.error('cannot log out')
            })
        })
    },
    handleDrawerToggle() {
      window.getApp.$emit("APP_DRAWER_TOGGLED")
    },
    handleFullScreen() {
      Util.toggleFullScreen()
      window.getApp.$emit("APP_TOGGLE_FULLSCREEN")
    },
    handleClick: (e) => {
      console.log(e)
    }
  }
}
</script>

<style scoped lang="scss">
  .app-toolbar {
    z-index: 5;
    background: linear-gradient(to left, #52a7b4, #424eb2);
  }
  
  .logo-surf {
      margin-top: 0;
      width: 100px;
      height: auto;
      transition: width 1s;
      transition: margin-top 1s;
  }
  .v-list__tile {
    position: relative;
  }
  .toDo-icon + .toDo-icon {
    position: absolute;
  }
  .toDo-icon + .toDo-icon {
    left: 20px;
  }
  .toDo-icon + .toDo-icon + .toDo-icon {
    left: 24px;
  }
  .date {
    font-size: 80%;
    position: absolute;
    right: 4px;
    bottom: 2px;
    color: #999;
  }
  .past-due {
    color: #f00;
  }
  .past-due .date {
    color: #f99;
  }
</style>