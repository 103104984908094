<template>
  <v-row class="justify-left px-3">
    <!-- TODO: Add user avatar -->
    <!-- <v-icon small class="mx-2 mt-1 align-start">edit</v-icon> -->
    <div>
      <div v-if="false">
        <span>
          <b>{{notif.actionUserBlueTag}}</b>:
          {{notif.actionDescription}}
          <small v-if="notif.relatedWorkItemCode">{{notif.relatedWorkItemCode}}</small>
        </span>
        <span class="date-time">
          {{notif.dateTime | formatDateTime}}
        </span>
      </div>
      <div v-else-if="record.changedProperty === 'statusId'">
        <!-- On
        {{record.dateTime | formatDateTime}}, -->
        <b>{{record.createdByBlueTag || record.createdByFullName}}</b>
        changed
        <b>Status</b>:
        <br>
        <div class="text-center my-2 d-flex flex-column">
          <v-btn dark tile x-small :color="record.originalStatusColor">
            <v-icon left>{{record.originalStatusIconClass}}</v-icon>
            {{record.originalStatusName}}
          </v-btn>
          <v-icon>arrow_downward</v-icon>
          <v-btn dark tile x-small :color="record.newStatusColor">
            <v-icon left>{{record.newStatusIconClass}}</v-icon>
            {{record.newStatusName}}
          </v-btn>
        </div>
      </div>
      <div v-else-if="record.changedProperty === 'type'">
        <b>{{record.createdByBlueTag || record.createdByFullName}}</b>
        changed
        <b>Type</b>:
        <br>
        <div class="text-center my-2 d-flex flex-column">
          <v-btn dark tile x-small :color="record.originalTypeColor">
            <v-icon left>{{record.originalTypeIconClass}}</v-icon>
            {{record.originalTypeDisplayName}}
          </v-btn>
          <v-icon>arrow_downward</v-icon>
          <v-btn dark tile x-small :color="record.newTypeColor">
            <v-icon left>{{record.newTypeIconClass}}</v-icon>
            {{record.newTypeDisplayName}}
          </v-btn>
        </div>
      </div>
      <div v-else-if="record.changedProperty === 'assignedToId'">
        <!-- On
        {{record.dateTime | formatDateTime}}, -->
        <br>
        <b>{{record.createdByBlueTag || record.createdByFullName}}</b>
        changed
        <b>Assigned to</b>
        from
        <b v-if="record.originalAssignedToBlueTag">{{record.originalAssignedToBlueTag}}</b>
        <b v-if="!record.originalAssignedToBlueTag"><i>Unassigned</i></b>
        to
        <b v-if="record.newAssignedToBlueTag">{{record.newAssignedToBlueTag}}</b>
        <b v-if="!record.newAssignedToBlueTag"><i>Unassigned</i></b>
        <br><br>
      </div>
      <div v-else-if="record.changedProperty === 'defectOriginId'">
        <br>
        <b>{{record.createdByBlueTag || record.createdByFullName}}</b>
        changed
        <b>Defect source</b>
        from
        <b v-if="record.originalDefectsOriginName">{{record.originalDefectsOriginName}}</b>
        <b v-if="!record.originalDefectsOriginName"><i>None</i></b>
        to
        <b v-if="record.newDefectsOriginName">{{record.newDefectsOriginName}}</b>
        <b v-if="!record.newDefectsOriginName"><i>None</i></b>
        <br><br>
      </div>
      <div v-else-if="record.changedProperty === 'reportedById'">
        <br>
        <b>{{record.createdByBlueTag || record.createdByFullName}}</b>
        changed
        <b>Reported by</b>
        from
        <b v-if="record.originalReportedByBlueTag">{{record.originalReportedByBlueTag}}</b>
        <b v-if="!record.originalReportedByBlueTag"><i>Unassigned</i></b>
        to
        <b v-if="record.newReportedByBlueTag">{{record.newReportedByBlueTag}}</b>
        <b v-if="!record.newReportedByBlueTag"><i>Unassigned</i></b>
        <br><br>
      </div>
      <div v-else-if="record.changedProperty === 'sprintId'">
        <br>
        <b>{{record.createdByBlueTag || record.createdByFullName}}</b>
        changed
        <b>Sprint</b>
        from
        <b v-if="record.originalName">{{record.originalName}}</b>
        <b v-if="!record.originalName"><i>None</i></b>
        to
        <b v-if="record.newName">{{record.newName}}</b>
        <b v-if="!record.newName"><i>None</i></b>
        <br><br>
      </div>
      <div v-else-if="record.changedProperty === 'priority' || record.changedProperty === 'value'">
        <!-- On
        {{record.dateTime | formatDateTime}}, -->
        <b>{{record.createdByBlueTag || record.createdByFullName}}</b>
        changed
        <b style="text-transform: capitalize;">{{record.changedProperty}}</b>:
        <br>
        <div class="text-center my-2">
          <v-btn dark text x-small :color="record.originalTypeColor">
            <v-icon left>{{record.originalTypeIconClass}}</v-icon>
            {{record.originalTypeDisplayName}}
          </v-btn>
          <v-icon>arrow_forward</v-icon>
          <v-btn dark text x-small :color="record.newTypeColor">
            <v-icon left>{{record.newTypeIconClass}}</v-icon>
            {{record.newTypeDisplayName}}
          </v-btn>
        </div>
      </div>
      <div v-else-if="record.changedProperty === 'estimatedEffort'">
        <!-- On
        {{record.dateTime | formatDateTime}}, -->
        <b>{{record.createdByBlueTag || record.createdByFullName}}</b>
        changed
        <b style="text-transform: capitalize;">Estimated Dev Effort</b>:
        <br>
        <div class="text-center my-2">
          <span>{{record.originalValue}}</span>
          <v-icon>arrow_forward</v-icon>
          <span>{{record.newValue}}</span>
        </div>
      </div>
      <div v-else-if="record.changedProperty === 'name'">
        <!-- On
        {{record.dateTime | formatDateTime}}, -->
        <b>{{record.createdByBlueTag || record.createdByFullName}}</b>
        changed
        <b>Summary</b>:
        <br>
        <div class="text-center my-2 d-flex flex-column">
          <span>{{record.originalValue}}</span>
          <v-icon>arrow_downward</v-icon>
          <span>{{record.newValue}}</span>
        </div>
      </div>
      <div v-else-if="record.changedProperty === 'description' || record.changedProperty === 'stepsToReproduce' || record.changedProperty === 'expectedResult' || record.changedProperty === 'comment'" style="width: 180px">
        <b>{{record.createdByBlueTag || record.createdByFullName}}</b>
        changed
        <b v-if="record.changedProperty === 'description'">Description:</b>
        <b v-else-if="record.changedProperty === 'stepsToReproduce'">Steps to Reproduce:</b>
        <b v-else-if="record.changedProperty === 'expectedResult'">Expected Result:</b>
        <b v-else>Comment:</b>
        <br>
        <div :style="{ marginBottom: !show ? '' : '12px'}" class="rich-text-format justify-center d-flex flex-column">
          <v-sheet color="coloredBack" class="ma-1 pa-1 flex-grow-1" v-html="record.originalValue"></v-sheet>
          <div class="d-flex flex-column" :style="{ width: '100%', justifyContent: 'center', alignItems: 'center'}">
            <v-icon @click="show = !show" :style="{ cursor: 'pointer', marginBottom: show ? '' : '12px', width: '24px'}" >arrow_downward</v-icon>
          </div>
          <v-sheet v-if="show" :style="{ marginBottom: !show ? '' : '12px'}" color="coloredBack" class="ma-1 pa-1 flex-grow-1" v-html="record.newValue"></v-sheet>
        </div>
      </div>
      <div v-else-if="record.changedProperty.startsWith('tag-')">
        <b>{{record.createdByBlueTag || record.createdByFullName}}</b>
        {{record.newValue == 'True' ? 'added' : 'removed'}}
        <b>Tag:</b> <br>
        <div class="pl-10 mt-2">
          <v-chip
            class="showed-tag"
            :style="{ color: isBlackText(record.tagColor) ? 'white' : 'black' }"
            small dark :color="record.tagColor"
          >
            <v-icon small left v-if="record.newValue == 'True'">check</v-icon>
            <v-icon small left v-else color="grey lighten-1">clear</v-icon>
            {{record.tagName}}
          </v-chip>
        </div>
        <br>
      </div>
    </div>
  </v-row>
</template>

<script>
import moment from 'moment'
import _ from "lodash"

export default {
  components: {  },
  props: {
    record: { type: Object, default: { originalValue: '', newValue: '' } },
  },
  data: () => ({
    defaultColor: 'grey',
    show: false,
  }),
  watch: {
    'record'(){
      if(this.record.changedProperty == 'description' || this.record.changedProperty == 'comment'){
        this.getDiffs()
      }
    },
  },
  computed: {
    initials() {
      return (this.code || this.text).split(' ').splice(0, 3).map(s => s.slice(0, 1)).join('').toUpperCase()
    },
    chipColor() {
    },
  },
  mounted() {
    if(this.record.changedProperty == 'description' || this.record.changedProperty == 'comment'){
      this.getDiffs()
    }
  },
  methods: {
    getDiffs(){
      let originalText = this.getTextFromHtml(this.record.originalValue)
      let newText = this.getTextFromHtml(this.record.newValue)
      const Diff = require('diff');
      let diffs = Diff.diffWords(originalText, newText)
      let originalWords = diffs
        .filter(d => !d.added)
        .map(d => 
          d.value.split(' ')
            .filter(word => !!word)
            .map(word => ({
              word,
              added: !!d.added,
              removed: !!d.removed,
            }))
        ).flat(1)
      let newWords = diffs
        .filter(d => !d.removed)
        .map(d => 
          d.value.split(' ')
            .filter(word => !!word)
            .map(word => ({
              word,
              added: !!d.added,
              removed: !!d.removed,
            }))
        ).flat(1)
      this.$set(this.record, 'originalValueHtml', this.getFormattedHtml(this.record.originalValue, originalWords))
      this.$set(this.record, 'newValueHtml', this.getFormattedHtml(this.record.newValue, newWords))
    },
    getFormattedHtml(originalHtml = '', words){
      let html = ''
      let index = 0
      let divTag = document.createElement('div')
      divTag.innerHTML = originalHtml
      let dfs = (element) => {
        if(element.nodeName == '#text'){
          let curWords = element.textContent
            .split(' ')
            .filter(word => !!word)
          for(let w of curWords){
            let color = ''
            if(words[index].added)
              color = 'var(--v-success-base)'
            else if(words[index].removed)
              color = 'var(--v-error-base)'
            html += `<span style="color: ${color}">${w}</span> `
            index++
          }
          return
        }
        html += `<${element.nodeName}>`
        element.childNodes.forEach(cn => dfs(cn))
        html += `</${element.nodeName}>`
      }
      dfs(divTag)
      return html
    },
    getTextFromHtml(html){
      let text = ''
      let divTag = document.createElement('div')
      divTag.innerHTML = html
      let dfs = (element) => {
        if(element.nodeName == '#text'){
          text += ' ' + element.textContent
          return
        }
        element.childNodes.forEach(cn => dfs(cn))
      }
      dfs(divTag)
      return text
    },
  },
  created() {
  },
  beforeDestroy() {
  },
}


</script>

<style scoped lang="scss">
.date-time {
  margin-top: 7px;
  font-size: 11px;
  color: #777;
}
.format-html{
  display: grid;
  grid-template-columns: calc(50% - 20px) 40px calc(50% - 20px);
  align-items: center;

  .v-sheet{
    padding: 10px !important;
    // height: max-content;
  }
}
</style>