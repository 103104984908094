<template>
	<div class="fill-height new-back">
		<v-menu
			v-model="datePicker.show"
      ref="datePickerMenu"
      :close-on-content-click="false"
      :nudge-right="40"
      transition="scale-transition"
      offset-y
      max-width="290px"
      min-width="290px"
      z-index="100"
			:position-x="datePicker.x" :position-y="datePicker.y"
			absolute
    >
      <v-date-picker
        v-model="datePicker.value"
        no-title @input="datePickerHandler"
      />
		</v-menu>

		<v-tabs
			v-model="activeTab"
			background-color="newDesignBackground"
			class="px-5"
			style="border-bottom: 1px solid var(--v-lightgray-base)"
		>
			<v-tab>Assignments</v-tab>
			<v-tab>
				Suggested Assignments
				<v-btn v-if="pending" color="pink" class="ml-1" fab width="22" height="22">
					<span class="subtitle white--text">{{ pending }}</span>
				</v-btn>
			</v-tab>
			<v-tab>
				Roster 100%
				<v-tooltip v-if="overassignedUnderassignedMessage" top>
					<template #activator="{ on }">
						<v-btn
							v-on="on"
							color="warning" class="ml-1"
							fab x-small depressed height="24" width="24"
						>
							<span class="title white--text">!</span>
						</v-btn>
					</template>
					<span v-html="overassignedUnderassignedMessage"></span>
				</v-tooltip>
			</v-tab>
		</v-tabs>

		
		<v-tabs-items v-model="activeTab" class="new-back">
			<v-tab-item :value="0">
				<div class="pa-5">
					<filter-search-input v-model="filters.search" @input="searchChanged" :applied-filters="appliedFilters" outlined dense>
						<template #append>
							<div>
								<v-menu
									v-model="showActionsMenu"
									:left="!contextAssignment.assignment" offset-x z-index="201"
									:position-x="contextAssignment.x" :position-y="contextAssignment.y"
									:absolute="!!contextAssignment.assignment"
								>
									<template #activator="{ on }">
										<v-btn
											v-on="on" @click="clearContextAssignment"
											color="primary" class="mr-2" outlined
										>
											<v-icon left>mdi-chevron-down</v-icon>
											Actions
										</v-btn>
									</template>
									<v-list color="high-back" dense>
										<v-list-item v-show="selectedAssignments.length" @click="selectedAssignments = []">
											<v-list-item-content>
												<div>
													<v-icon left>mdi-checkbox-blank-off-outline</v-icon>
													Deselect all
												</div>
											</v-list-item-content>
										</v-list-item>
										<v-list-item v-show="selectedAssignments.length" @click="duplicate">
											<v-list-item-content>
												<div>
													<v-icon left>mdi-content-copy</v-icon>
													Duplicate
												</div>
											</v-list-item-content>
										</v-list-item>
										<v-list-item @click="exportToExcel">
											<v-list-item-content>
												<div>
													<v-icon left>mdi-upload</v-icon>
													Export (.xlsx)
												</div>
											</v-list-item-content>
										</v-list-item>
										<v-list-item @click="copyToClipboard">
											<v-list-item-content>
												<div>
													<v-icon left>mdi-clipboard-multiple-outline</v-icon>
													Copy to clipboard
												</div>
											</v-list-item-content>
										</v-list-item>
										<v-list-item v-show="selectedAssignments.length" @click="deleteAssignment">
											<v-list-item-content>
												<div>
													<v-icon left>mdi-delete</v-icon>
													Delete {{ selectedAssignments.length ? 'selected' : ''}}
												</div>
											</v-list-item-content>
										</v-list-item>
									</v-list>
								</v-menu>
								<v-tooltip v-if="permissions.edit" top>
									<template #activator="{ on }">
										<v-btn
											@click="openCalendarsDialog"
											v-on="on"
											color="primary" class="mr-1"
											outlined fab small
										>
											<v-icon>mdi-calendar-blank-outline</v-icon>
										</v-btn>
									</template>
									<span class="caption">Calendars</span>
								</v-tooltip>
								<v-tooltip v-if="permissions.add" top z-index="200">
									<template #activator="{ on }">
										<v-btn v-on="on" @click="add" fab color="primary" small depressed>
											<v-icon>mdi-plus</v-icon>
										</v-btn>
									</template>
									<span class="caption">Add new</span>
								</v-tooltip>
							</div>
						</template>
						<v-autocomplete
							v-model="filters.users" :items="users"
							@change="fetchAssignments(true, false)"
							label="Bluetag"
							dense outlined hide-details clearable multiple
							item-text="blueTag" item-value="id"
						/>
			
						<v-autocomplete
							v-model="filters.billables" :items="billables"
							@change="fetchAssignments(true, false)"
							label="Billable Code"
							outlined dense hide-details single-line clearable multiple
							item-text="code" item-value="id"
						/>
			
						<v-autocomplete
							v-model="filters.billableLines" :items="billableLines"
							@change="fetchAssignments(true, false)"
							label="Role" style="max-width: 300px"
							outlined dense hide-details single-line clearable multiple
							item-text="role" item-value="id"
						>
							<template v-slot:item="{ item }">
								<div class="select-item">
									<span class="caption">Seat {{item.seat}}</span>
									<div :style="{color: item.filtered ? '' : 'grey'}">
										<v-icon v-if="filters.billableLines.includes(item.id)" color="primary">mdi-checkbox-marked</v-icon>
										<v-icon v-else>mdi-checkbox-blank-outline</v-icon>
										{{item.role}}
									</div>
								</div>
							</template>
						</v-autocomplete>
			
						<v-autocomplete
							v-model="filters.customerProjects" :items="customerProjects"
							@change="fetchAssignments(true, false)"
							label="Project"
							outlined dense hide-details single-line clearable multiple
							item-text="name" item-value="id"
						/>
			
						<v-autocomplete
							v-model="filters.projectTypes" :items="projectTypes"
							@change="fetchAssignments(true, false)"
							label="Project Type"
							outlined dense hide-details single-line clearable multiple
							item-text="text" item-value="value"
						/>
			
						<v-text-field
							v-model="filters.quantity"
							@change="fetchAssignments(true, false)"
							label="Quantity"
							type="number" step="0.25"
							outlined dense hide-details single-line
						/>
			
						<v-select
							v-model="filters.isBillable" :items="[true, false]"
							@change="fetchAssignments(true, false)"
							label="Is Billable"
							dense outlined hide-details clearable
						/>
			
						<v-select
							v-model="filters.status" :items="statuses"
							@change="fetchAssignments(true, false)"
							label="Status"
							dense outlined hide-details clearable
						/>
			
						<q-date-picker
							v-model="filters.startDate" :max="filters.endDate"
							@input="fetchAssignments(true, false)"
							label="Start Date"
							inner outlined dense hide-details clearable
						/>
			
						<q-date-picker
							v-model="filters.endDate" :min="filters.startDate"
							@input="fetchAssignments(true, false)"
							label="End Date"
							inner outlined dense hide-details clearable
						/>
					</filter-search-input>

					<div v-if="selectedAssignments.length" class="text-right body-2 mb-1 mr-2">
						<div>
							<span>Actual working hours: </span>
							<b v-if="actualWorkingHoursSum">{{ actualWorkingHoursSum }} hrs</b>
							<b v-else>-</b>
						</div>
						<div>
							<span>Total working hours: </span>
							<b v-if="totalWorkingHoursSum">{{ totalWorkingHoursSum }} hrs</b>
							<b v-else>-</b>
						</div>
					</div>
			
					<assignments-table
						v-model="selectedAssignments"
						:items="newAssignments.concat(assignments.filter(x => !x.deleted))"
						:total-items="pagination.totalItems + newAssignments.length"
						:billable-lines="billableLines"
						:billables="billables"
						:users="users"
						:customer-projects="customerProjects"
						infinite

						@user-changed="userChanged"
						@billable-changed="billableChanged"
						@customer-project-changed="customerProjectChanged"
						@register-change="registerChange"
						@open-date-picker="openDatePicker"
						@delete-assignment="deleteAssignment"

						@load-more="loadMore" disable-sort show-select
						@contextmenu:row="setContextAssignment"
					/>
				</div>
			</v-tab-item>
			<v-tab-item :value="1">
				<div class="pa-5">
					<suggested-assignment-list
						ref="suggestedAssignmentList"
						:billable-lines="billableLines"
						:customer-projects="customerProjects"
						:users="users"
						:billables="billables"
						:statuses="statuses"
						:setHours="setHours"
						:setStatus="setStatus"

						@add="newAssignments = newAssignments.concat($event)"
						@delete="handleDeleteFromSuggestion"
						@user-changed="userChanged"
						@billable-changed="billableChanged"
						@register-change="registerChange"
						@open-date-picker="openDatePicker"
						@suggestion-accepted="addAcceptedSuggestion"
						@update:suggestions="v => pending = v"
					/>
				</div>
			</v-tab-item>
			<v-tab-item :value="2">
				<roster-report v-if="activeTab === 2"/>
			</v-tab-item>
		</v-tabs-items>


		<side-panel v-model="showChanges" close-outside>
			<v-card color="high-back" class="fill-height">
				<v-card-title class="justify-space-between">
					<span>Edit Assignments</span>
					<div class="d-flex align-center mr-2" style="gap: 10px">
						Sort by:
						<v-tooltip bottom>
							<template #activator="{ on }">
								<v-btn
									v-on="on" @click="changesSorting = (changesSorting + 1) % changeSortingTypes.length; $forceUpdate()"
									color="secondary" fab small depressed
								>
									<v-icon>{{ changeSortingTypes[changesSorting].icon }}</v-icon>
								</v-btn>
							</template>
							<span>{{ changeSortingTypes[changesSorting].name }}</span>
						</v-tooltip>
					</div>
				</v-card-title>
				<v-card-subtitle>
					Are you sure you want to apply this changes?
				</v-card-subtitle>
				<v-card-text class="overflow-auto" style="height: calc(100% - 170px);">
					<v-divider class="mt-5 mb-5"/>

					<v-expansion-panels v-if="noDuplicatedAssignments.length" :value="0" flat>
						<v-expansion-panel>
							<v-expansion-panel-header color="high-back">
								<span class="text-h5">Created</span>
							</v-expansion-panel-header>
							<v-expansion-panel-content color="high-back">
								<div
									v-for="(assignment, ix) in sortedNoDuplicatedAssignments" :key="ix"
									class="bordered rounded mb-2" :class="{ 'darken': !$vuetify.theme.isDark }"
								>
									<div class="d-flex justify-space-between align-center py-2 px-2">
										<div class="body-1">
											<v-tooltip v-if="getError(assignment)" left z-index="205">
												<template #activator="{ on }">
													<v-icon v-on="on" color="error">mdi-alert-circle-outline</v-icon>
												</template>
												{{ getError(assignment) }}
											</v-tooltip>
											Created {{ix+1}} |
											{{ assignment.customerProjectName }} -
											{{ assignment.userBlueTag || assignment.userFullName }}
										</div>
										<v-btn @click="deleteAssignment(assignment)" class="float-right" small icon>
											<v-icon>mdi-minus-circle-outline</v-icon>
										</v-btn>
									</div>
									<v-divider/>
									<div class="d-flex px-2 py-2" style="gap: 20px">
										<div>
											<b>Start Date:</b><br>
											{{ assignment.startDate | formatDate }}
										</div>
										<div>
											<b>End Date:</b><br>
											{{ assignment.endDate | formatDate }}
										</div>
									</div>
								</div>
							</v-expansion-panel-content>
						</v-expansion-panel>
					</v-expansion-panels>

					<v-divider
						v-if="noDuplicatedAssignments.length && duplicatedAssignments.length"
						class="my-5"
					/>

					<v-expansion-panels v-if="duplicatedAssignments.length" :value="0" flat>
						<v-expansion-panel>
							<v-expansion-panel-header color="high-back">
								<span class="text-h5">Duplicated</span>
							</v-expansion-panel-header>
							<v-expansion-panel-content color="high-back">
								<div
									v-for="(assignment, ix) in sortedDuplicatedAssignments" :key="ix"
									class="bordered rounded mb-2" :class="{ 'darken': !$vuetify.theme.isDark }"
								>
									<div class="d-flex justify-space-between align-center py-2 px-2">
										<div class="body-1">
											<v-tooltip v-if="getError(assignment)" left z-index="205">
												<template #activator="{ on }">
													<v-icon v-on="on" color="error">mdi-alert-circle-outline</v-icon>
												</template>
												{{ getError(assignment) }}
											</v-tooltip>
											Created {{ix+1}} |
											{{ assignment.customerProjectName }} -
											{{ assignment.userBlueTag || assignment.userFullName }}
										</div>
										<v-btn @click="deleteAssignment(assignment)" class="float-right" small icon>
											<v-icon>mdi-minus-circle-outline</v-icon>
										</v-btn>
									</div>
									<v-divider/>
									<div class="d-flex px-2 py-2" style="gap: 20px">
										<div>
											<b>Start Date:</b><br>
											{{ assignment.startDate | formatDate }}
										</div>
										<div>
											<b>End Date:</b><br>
											{{ assignment.endDate | formatDate }}
										</div>
									</div>
								</div>
							</v-expansion-panel-content>
						</v-expansion-panel>
					</v-expansion-panels>

					<v-divider
						v-if="(newAssignments.length || duplicatedAssignments.length) && changedAssignments.length"
						class="my-5"
					/>

					<v-expansion-panels v-if="changedAssignments.length" :value="0" flat>
						<v-expansion-panel>
							<v-expansion-panel-header color="high-back">
								<span class="text-h5">Changes</span>
							</v-expansion-panel-header>
							<v-expansion-panel-content color="high-back">
								<div
									v-for="(assignment, ix) in sortedChangedAssignments" :key="ix"
									class="bordered rounded mb-2" :class="{ 'darken': !$vuetify.theme.isDark }"
								>
									<div class="py-3 px-2 body-1">
										<v-tooltip v-if="getError(assignment)" left z-index="205">
											<template #activator="{ on }">
												<v-icon v-on="on" color="error">mdi-alert-circle-outline</v-icon>
											</template>
											{{ getError(assignment) }}
										</v-tooltip>
										Edit {{ix+1}} |
										{{ assignment.customerProjectName }} -
										{{ assignment.userBlueTag || assignment.userFullName }}
									</div>
									<v-divider/>
									<div class="d-flex flex-column">
										<div
											v-for="(change, jx) in assignment._changes" :key="jx"
											class="change px-2 py-2"
										>
											<div>
												{{ formatChangeProperty(change.key) }}:
												<b>{{ formatChangeValue(change.oldValue, change.key) }}</b>
											</div>
											<v-icon>mdi-arrow-right</v-icon>
											<b
												class="text-right"
												:class="{
													italic: formatChangeValue(change.newValue, change.key) == 'empty'
												}"
											>
												{{ formatChangeValue(change.newValue, change.key) }}
											</b>
											<div class="undo-btn text-right">
												<v-btn @click="undoChange(assignment, change.key)" icon>
													<v-icon>mdi-undo</v-icon>
												</v-btn>
											</div>
										</div>
									</div>
								</div>
							</v-expansion-panel-content>
						</v-expansion-panel>
					</v-expansion-panels>

					<v-divider
						v-if="(newAssignments.length || duplicatedAssignments.length || changedAssignments.length) && deletedAssignments.length"
						class="my-5"
					/>

					<v-expansion-panels v-if="deletedAssignments.length" :value="0" flat>
						<v-expansion-panel>
							<v-expansion-panel-header color="high-back">
								<span class="text-h5">Deleted</span>
							</v-expansion-panel-header>
							<v-expansion-panel-content color="high-back">
								<div
									v-for="(assignment, ix) in sortedDeletedAssignments" :key="ix"
									class="bordered rounded mb-2" :class="{ 'darken': !$vuetify.theme.isDark }"
								>
									<div class="d-flex justify-space-between align-center py-2 px-2">
										<div class="body-1">
											<v-tooltip v-if="getError(assignment)" left z-index="205">
												<template #activator="{ on }">
													<v-icon v-on="on" color="error">mdi-alert-circle-outline</v-icon>
												</template>
												{{ getError(assignment) }}
											</v-tooltip>
											Created {{ix+1}} |
											{{ assignment.customerProjectName }} -
											{{ assignment.userBlueTag || assignment.userFullName }}
										</div>
										<v-btn @click="undoDelete(assignment)" class="float-right" small icon>
											<v-icon>mdi-undo</v-icon>
										</v-btn>
									</div>
									<v-divider/>
									<div class="d-flex px-2 py-2" style="gap: 20px">
										<div>
											<b>Start Date:</b><br>
											{{ assignment.startDate | formatDate }}
										</div>
										<div>
											<b>End Date:</b><br>
											{{ assignment.endDate | formatDate }}
										</div>
									</div>
								</div>
							</v-expansion-panel-content>
						</v-expansion-panel>
					</v-expansion-panels>

				</v-card-text>
				<v-card-actions style="position: absolute; bottom: 5px; right: 5px">
					<v-btn @click="cancelChanges" color="secondary">Cancel Changes</v-btn>
					<v-btn @click="save" color="primary">Confirm</v-btn>
				</v-card-actions>
			</v-card>
		</side-panel>

		<v-badge
			v-if="changeCounter || newAssignments.length || deletedAssignments.length"
		 	class="changes-btn" color="error"
			bordered overlap :content="changeCounter + newAssignments.length + deletedAssignments.length"
			offset-x="17" offset-y="17"
		>
			<v-btn @click="showChanges = !showChanges" color="primary" fab>
				<v-icon large>mdi-playlist-edit</v-icon>
			</v-btn>
		</v-badge>

		<assignments-delete-dialog ref="deleteDialog"/>
		<admin-calendars-dialog ref="calendarsDialog"/>
	</div>
</template>

<script>
import moment from 'moment'
import BillableJSON from '../../json/billable.json'
import SuggestedAssignmentList from '../../components/admin-surf/assignments/SuggestedAssignmentList.vue'
import AssignmentsDeleteDialog from '../../dialogs/adminSurf/AssignmentsDeleteDialog'
import AdminCalendarsDialog from '../../dialogs/adminSurf/AdminCalendarsDialog'
import AssignmentsTable from '../../components/admin-surf/assignments/AssignmentsTable'
import RosterReport from './RosterReport'

export default {
  components: { AssignmentsDeleteDialog, AdminCalendarsDialog, AssignmentsTable, SuggestedAssignmentList, RosterReport },
	data: () => ({
		activeTab: 0,
		pending: 0,
		overassignedUnderassignedMessage: '',
		
		statuses: [
			{ text: "Not started", value: 0, color: '#808080', lightColor: '#eee' },
			{ text: "In Progress", value: 1, color: 'success', lightColor: '#e1f8dd' },
			{ text: "Completed", value: 2, color: '#ab57ff', lightColor: '#dbb8ff' },
		],
		selectedAssignments: [],
		newAssignments: [],
		deletedAssignments: [],
		assignments: [],
		acceptedSuggestions: [],
		pagination: {
			page: 1,
			itemsPerPage: 25,
			totalItems: 0,
		},
		contextAssignment: {
			assignment: null,
			x: null,
			y: null,
		},
		showActionsMenu: false,

		users: [],
		billables: [],
		billableLines: [],
		customerProjects: [],
		projectTypes: [],
		holidays: [],

		filters: {
			search: '',
			active: null,

			users: [],
			billables: [],
			billableLines: [],
			customerProjects: [],
			projectTypes: [],
			quantity: null,
			isBillable: null,
			status: null,
			startDate: null,
			endDate: null,
		},
		searchTimeout: null,

		showChanges: false,
		changeTracker: {},
		changeCounter: 0,

		datePicker: {
			show: false,
			item: null,
			key: null,
			x: 0,
			y: 0,
		},

		permissions: {
			add: false,
			edit: false,
			actions: false,
		},

		changesSorting: 0, // 0 -> default, 1 -> by user, 2 -> by project
		changeSortingTypes: [
			{ icon: 'mdi-minus-thick', name: 'Default', sorting: (a, b) => moment(a.startDate).isBefore(b.startDate, 'day') ? -1 : 1 },
			{ icon: 'mdi-account', name: 'User', sorting: (a, b) => (a.userBlueTag || '').localeCompare(b.userBlueTag) },
			{ icon: 'mdi-application-parentheses', name: 'Project', sorting: (a, b) => (a.customerProjectName || '').localeCompare(b.customerProjectName) },
		],
	}),
	created() {
		document.title = 'Assignments'
    this.$q.log(4, 22, 3, 'NA')
		this.projectTypes = BillableJSON.projectTypes

		this.$security.hasRequiredPermission('add')
			.then(res => this.permissions.add = res)
		this.$security.hasRequiredPermission('edit')
			.then(res => this.permissions.edit = res)
		this.$security.hasRequiredPermission('full_access')
			.then(res => this.permissions.actions = res)
		this.init()
	},
	computed: {
    appliedFilters() {
			return this.countActiveFilters(this.filters, ['search', 'active'])
		},
		noDuplicatedAssignments() {
			return this.newAssignments.filter(x => !x.isDuplicated)
		},
		duplicatedAssignments() {
			return this.newAssignments.filter(x => x.isDuplicated)
		},
		changedAssignments() {
			return Object.values(this.changeTracker)
				.map(x => ({
					...x.assignment,
					_changes: Object.keys(x)
						.filter(k => k !== 'assignment')
						.map(k => ({
							key: k,
							oldValue: x[k].oldValue,
							newValue: x[k].newValue,
						}))
				}))
		},
		sortedNoDuplicatedAssignments() {
			return this.noDuplicatedAssignments.sort(this.changeSortingTypes[this.changesSorting].sorting)
		},
		sortedDuplicatedAssignments() {
			return this.duplicatedAssignments.sort(this.changeSortingTypes[this.changesSorting].sorting)
		},
		sortedChangedAssignments() {
			return this.changedAssignments.sort(this.changeSortingTypes[this.changesSorting].sorting)
		},
		sortedDeletedAssignments() {
			return this.deletedAssignments.sort(this.changeSortingTypes[this.changesSorting].sorting)
		},
		actualWorkingHoursSum() {
			return this.selectedAssignments.reduce((acc, x) => acc + x.actualWorkingHours, 0)
		},
		totalWorkingHoursSum() {
			return this.selectedAssignments.reduce((acc, x) => acc + x.totalWorkingHours, 0)
		},
	},
	methods: {
		init() {
			this.pagination.page = 1
			this.showChanges = false
			this.changeTracker = {}
			this.changeCounter = 0
			this.newAssignments = []
			this.deletedAssignments = []
			this.acceptedSuggestions = []

			this.fetchAssignments(true, false)
			this.fetchIsOverassignedOrUnderassigned()

			this.$http.get('api/user?onlyInterns=true&department=devCenter')
				.then(res => {
					this.users = res.data
				})
				.catch(err => {
					console.error(err)
					this.$root.error('Error loading users')
				})
			
			this.$http.get('api/Billable')
				.then(res => {
					this.billables = res.data
				})
				.catch(err => {
					console.error(err)
					this.$root.error('Error loading billables')
				})
			
			this.$http.get('api/BillableLine')
				.then(res => {
					this.billableLines = res.data
				})
				.catch(err => {
					console.error(err)
					this.$root.error('Error loading billable lines')
				})
			
			this.$http.get('api/CustomerProject')
				.then(res => {
					this.customerProjects = res.data
				})
				.catch(err => {
					console.error(err)
					this.$root.error('Error loading customer projects')
				})
			
			this.$http.get('api/admin/holidays')
				.then(res => {
					this.holidays = res.data
						.map(x => ({
							...x,
							date: moment(x.date),
						}))
				})
				.catch(err => {
					console.error(err)
					this.$root.error('Error loading holidays')
				})
			
			this.$http.get('api/admin/pending', { headers: { hideLoading: true } })
				.then(res => {
					this.pending = res.data.assignmentSuggestions
				})
		},
		fetchAssignments(resetAssignments, hideLoading) {
			if(resetAssignments) {
				this.pagination.page = 1
				this.selectedAssignments = []
			}

			return this.$http.post(
				'/api/assignment/list',
				{
					...this.filters,
					page: this.pagination.page,
					pageSize: this.pagination.itemsPerPage,
					quantity: Number(this.filters.quantity) || null
				},
				{ headers: { hideLoading } }
			)
				.then(res => {
					let assignments = res.data.assignments

					for(let assignment of assignments) {
						if(this.changeTracker[assignment.id]) {
							const newValues = this.changeTracker[assignment.id]
							for(let key in newValues) {
								if(key !== 'assignment')
									assignment[key] = newValues[key].newValue
							}
						}
						if(this.deletedAssignments.some(x => x.id == assignment.id))
							assignment.deleted = true
					}

					assignments.forEach(this.setStatus)

					if(resetAssignments)
						this.assignments = assignments
					else
						this.assignments = this.assignments.concat(assignments)
					this.pagination.totalItems = res.data.total
				})
				.catch(err => {
					console.error(err)
					this.$root.error('Error loading assignments')
				})
		},
		loadMore() {
			if(this.assignments.length >= this.pagination.totalItems) return Promise.resolve()
			this.pagination.page++
			return this.fetchAssignments(false, true)
		},
		fetchIsOverassignedOrUnderassigned() {
			return this.$http.get('api/admin/roster/overassignedOrUnderassigned')
				.then(res => {
					const { overassigned, underassigned } = res.data

					this.overassignedUnderassignedMessage = ''
					if(overassigned) {
						this.overassignedUnderassignedMessage += 'Some users are over-assigned<br>'
					}
					if(underassigned) {
						this.overassignedUnderassignedMessage += 'Some users are under-assigned'
					}
				})
				.catch(err => {
					console.error(err)
					this.$root.error('Error loading overassigned or underassigned')
				})
		},
		setHours(assignment) {
			if(!assignment.startDate || !assignment.endDate) return

			const calendarIds = assignment.calendars?.map(x => x.id) || []
			const holidays = this.holidays
				.filter(x => calendarIds.includes(x.calendarId))

			var today = moment().startOf('day')
			if(today.isAfter(assignment.endDate))
				today = assignment.endDate

			this.$set(assignment, 'actualWorkingHours', 0)
			assignment.actualWorkingHours = this.getWorkingDays(
				assignment.startDate,
				today,
				holidays
			) * 8 * assignment.quantity
			if(assignment.actualWorkingHours < 0)
				assignment.actualWorkingHours = 0

			this.$set(assignment, 'totalWorkingHours', 0)
			assignment.totalWorkingHours = this.getWorkingDays(
				assignment.startDate,
				assignment.endDate,
				holidays
			) * 8 * assignment.quantity
			this.$set(assignment, 'months', this.trunc(assignment.totalWorkingHours / 168 / assignment.quantity))
			this.$set(assignment, 'weeks', this.trunc(assignment.totalWorkingHours / 40 / assignment.quantity))
		},
		setStatus(assignment) {
			const today = moment().startOf('day')
			this.$set(assignment, 'timeStatus', 0)
			if(today.isBefore(assignment.startDate)) {
				assignment.timeStatus = 0
			}
			else if(today.isSameOrBefore(assignment.endDate)) {
				assignment.timeStatus = 1
			}
			else {
				assignment.timeStatus = 2
			}

			const status = this.statuses.find(x => x.value == assignment.timeStatus)
			this.$set(assignment, 'timeStatusName', status.text)
			this.$set(assignment, 'timeStatusColor', status.color)
			this.$set(assignment, 'timeStatusLightColor', status.lightColor)
		},
		getWorkingDays(startDate, endDate, holidays) {
			const totalDays = moment(endDate).diff(startDate, 'days') + 1
			const holidaysCount = holidays
				.map(x => x.date.format('YYYY-MM-DD'))
				.filter((x, ix, arr) => arr.indexOf(x) == ix)
				.filter(x => moment(x).isBetween(startDate, endDate, 'day', '[]')).length
			return totalDays - holidaysCount
		},

		searchChanged(){
			this.searchTimeout && clearTimeout(this.searchTimeout)
			this.searchTimeout = setTimeout(() => {
				this.fetchAssignments(true, false)
			}, 800)
		},

		add() {
			this.newAssignments.push({
				quantity: 1,
				isBillable: true,
				status: 4,
				statusName: 'New',
				startDate: moment().format("YYYY-MM-DD"),
				endDate: '',

				actualWorkingHours: 0,
				totalWorkingHours: 0,
				months: 0,
				weeks: 0,

				isNew: true,
				changes: [],
				id: crypto.randomUUID(),
			})
		},
		duplicate() {
			let assignment = null
			if(this.contextAssignment.assignment) {
				assignment = this.contextAssignment.assignment
			}
			let assignments = this.selectedAssignments.filter(x => true)
			if((assignment?.id && !this.selectedAssignments.some(x => x.id == assignment.id)) || !this.selectedAssignments.length)
				assignments.push(assignment)

			assignments.forEach(x => {
				const base = structuredClone(x)
				this.newAssignments.push({
					quantity: base.quantity,
					isBillable: base.isBillable,
					userId: base.userId,
					customerProjectId: base.customerProjectId,
					customerProjectName: base.customerProjectName,
					status: 4,
					statusName: 'New',
					startDate: moment().format("YYYY-MM-DD"),
					endDate: '',
	
					actualWorkingHours: 0,
					totalWorkingHours: 0,
					months: 0,
					weeks: 0,
	
					isNew: true,
					isDuplicated: true,
					changes: [],
				})
			})
			this.clearContextAssignment()
		},
		async deleteAssignment(assignment) {
			if(this.contextAssignment.assignment) {
				assignment = this.contextAssignment.assignment
			}

			let assignments = this.selectedAssignments.filter(x => true)
			if((assignment?.id && !this.selectedAssignments.some(x => x.id == assignment.id)) || !this.selectedAssignments.length)
				assignments.push(assignment)

			if(assignments.length == 1 && assignment.isNew) {
				const confirmed = await this.$openConfirmDialog({
					title: 'Delete Assignment',
					description: 'Are you sure you want to delete this assignment?',
				})

				if(!confirmed) return false

				assignment.deleted = true
				this.newAssignments = this.newAssignments.filter(x => !x.deleted)

				return true
			}

			const res = await this.$refs.deleteDialog.open(assignments)
			if(!res.confirmed) return false
			assignments.forEach(x => {
				if(x.isNew) {
					x.deleted = true
					this.newAssignments = this.newAssignments.filter(x => !x.deleted)
					return
				}

				const canDelete = res.data.find(y => y.id === x.id).canDelete
				if(canDelete) {
					this.$set(x, 'deleted', true)
					this.deletedAssignments.push(x)
				}
			})
			this.selectedAssignments = []
			this.clearContextAssignment()
			return true
		},
		undoDelete(assignment) {
			this.$set(assignment, 'deleted', false)
			this.deletedAssignments = this.deletedAssignments
				.filter(x => x.id != assignment.id)
		},
		handleDeleteFromSuggestion(event) {
			let assignment = this.newAssignments.find(x =>
				event.userId == x.userId &&
				event.recessRequestId == x.recessRequestId &&
				event.billableId == x.billableId &&
				event.startDate == x.startDate &&
				event.endDate == x.endDate
			)
			if(!assignment) return
			this.$set(assignment, 'deleted', true)
			this.newAssignments = this.newAssignments.filter(x => !x.deleted)
		},
		getError(assignment) {
			const requiredFields = {
				userId: 'User is required',
				customerProjectId: 'Project is required',
				quantity: 'Quantity is required',
				startDate: 'Start date is required',
				endDate: 'End date is required',
			}

			for(let field in requiredFields) {
				if(assignment[field] === null || assignment[field] === undefined || assignment[field] === '')
					return requiredFields[field]
			}

			if(moment(assignment.startDate).isAfter(assignment.endDate, 'day'))
				return 'Start date cannot be greater than end date'

			if(assignment.quantity <= 0)
				return 'Quantity must be greater than 0'

			if(assignment.billableId && !assignment.billableLineId)
				return 'Role is required'
			else if(!assignment.billableId && !assignment.otherBillableLine)
				return 'Role is required'
			
			if(!assignment.isBillable && assignment.billableType !== 3 && !assignment.changes?.some(x => x.changedProperty == 'comment')) {
				return 'Comment required'
			}

			return false
		},
		registerChange(assignment, property, newValue) {
			let assignments = [assignment]
			assignments = assignments.concat(this.selectedAssignments)

			assignments.forEach(x => {
				if(x.isNew) {
					this.$set(x, property, newValue)
					if(property === 'quantity')
						this.setHours(x)
					return
				}
	
				if(!this.changeTracker[x.id])
					this.$set(this.changeTracker, x.id, {})
	
				if(this.changeTracker[x.id][property]) {
					this.changeTracker[x.id][property].newValue = newValue
				}
				else {
					this.changeCounter++
					this.$set(this.changeTracker[x.id], property, {
						oldValue: x[property],
						newValue,
					})
				}
	
				this.$set(this.changeTracker[x.id], 'assignment', x)
				let curAssignment = this.assignments.find(a => x.id === a.id)
				this.$set(x, property, newValue)
				this.$set(curAssignment, property, newValue)
				if(property === 'quantity')
					this.setHours(x)
	
				if(
					this.changeTracker[x.id][property].newValue == this.changeTracker[x.id][property].oldValue ||
					(
						(property == 'startDate' || property == 'endDate') &&
						moment(this.changeTracker[x.id][property].newValue).format('YYYY-MM-DD') ==
						moment(this.changeTracker[x.id][property].oldValue).format('YYYY-MM-DD')
					)
				) {
					delete this.changeTracker[x.id][property]
					this.changeCounter--
				}
				if(Object.keys(this.changeTracker[x.id]).length == 1) {
					delete this.changeTracker[x.id]
				}
			})

		},
		undoChange(assignment, property) {
			this.registerChange(this.changeTracker[assignment.id].assignment, property, this.changeTracker[assignment.id][property].oldValue)
		},
		addAcceptedSuggestion(assignmentId, recessRequestId) {
			this.acceptedSuggestions.push({
				assignmentId,
				recessRequestId,
			})
		},

		async save() {
			let assignments = Object.values(this.changeTracker)
				.filter(c => Object.keys(c).length > 1)
				.map(c => c.assignment)
			this.deletedAssignments.forEach(x => x.deleted = true)
			assignments = assignments
				.concat(this.newAssignments.map(x => ({...x, id: null, changes: x.changes?.map(y => ({...y, groupId: null}))})))
				.concat(this.deletedAssignments)

			if(assignments.some(x => this.getError(x))) {
				this.$root.error('Cannot save changes. Please check for errors.')
				return
			}

			try {
				const { data: warning } = await this.$http.post('api/assignments/validate', assignments)
				
				if(warning) {
					const confirmed = await this.$openConfirmDialog({
						title: 'Overallocated',
						description: warning,
					})
					if(!confirmed) return
				}

			} catch (error) {
				if(!error.notified){
					this.$root.error('Cannot save changes. Please check for errors.')
				}
				return
			}

			try {
				await this.$q.save({
        	api: 'api/assignments',
					data: { assignments, suggestions: this.acceptedSuggestions },
					successMsg: 'Saved Successfully',
					afterSuccessUrl: null,
					errorMsg: "Cannot save changes.",
					afterErrorUrl: null,
				})
				this.init()
				this.$refs.suggestedAssignmentList.init()
				this.$root.success('Saved Successfully')
			} catch (error) {
				if(error.notified){
					this.$openConfirmDialog({
						title: "Cannot save changes",
						description: error.response.data.message,
						confirmOnly: true,
					})
				}
			}
		},
		cancelChanges() {
			this.$openConfirmDialog({
				title: 'Cancel Changes',
				description: 'Are you sure you want to cancel your changes?',
			})
				.then(res => {
					if(!res) return
					this.init()
					this.$refs.suggestedAssignmentList.init()
				})
		},

		formatChangeValue(value, property) {
			if(value === null || value === undefined) return 'empty'

			switch(property) {
				case 'userId':
					return this.users.find(x => x.id == value).blueTag
				case 'billableId':
					return this.billables.find(x => x.id == value).code
				case 'billableLineId':
					return this.billableLines.find(x => x.id == value).role
				case 'otherBillableLine':
					return value
				case 'customerProjectId':
					return this.customerProjects.find(x => x.id == value).name
				case 'isBillable':
					return value ? 'Yes' : 'No'
				case 'startDate':
					return moment(value).format('MMM DD, YYYY')
				case 'endDate':
					return moment(value).format('MMM DD, YYYY')
			}
			return value
		},
		formatChangeProperty(property) {
			switch(property) {
				case 'userId':
					return 'User'
				case 'billableId':
					return 'Billable'
				case 'otherBillableLine':
				case 'billableLineId':
					return 'Role'
				case 'customerProjectId':
					return 'Project'
				case 'quantity':
					return 'Quantity'
				case 'isBillable':
					return 'Is billable'
				case 'startDate':
					return 'Start Date'
				case 'endDate':
					return 'End Date'
			}
			return property
		},

		userChanged(assignment, userId) {
			let assignments = [assignment]
			assignments = assignments.concat(this.selectedAssignments)

			assignments.forEach(x => {
				const user = this.users.find(u => u.id == userId)
				this.$set(x, 'userBlueTag', user.blueTag)
				this.$set(x, 'userFullName', user.fullName)
			})
			this.registerChange(assignment, 'userId', userId)
		},
		billableChanged(assignment, billableId) {
			let assignments = [assignment]
			assignments = assignments.concat(this.selectedAssignments)

			assignments.forEach(x => {
				const billable = this.billables.find(b => b.id == billableId)
				x.billableCode = billable.code
				x.billableCustomerId = billable.customerId
				x.billableType = billable.type
				this.registerChange(x, 'billableLineId', null)

				if(this.customerProjectId && !this.customerProjects.some(p => p.id == x.customerProjectId && p.customerId == billable.customerId))
					this.registerChange(x, 'customerProjectId', null)

				x.calendars = billable.calendars.map(x => ({
					color: x.calendar.color,
					name: x.calendar.name,
					id: x.calendarId,
				}))
	
				this.setHours(x)
			})
			
			this.registerChange(assignment, 'billableId', billableId)
		},
		customerProjectChanged(assignment, customerProjectId) {
			let assignments = [assignment]
			assignments = assignments.concat(this.selectedAssignments)

			assignments.forEach(x => {
				const customerProject = this.customerProjects.find(p => p.id == customerProjectId)
				x.customerProjectName = customerProject.name
			})
			this.registerChange(assignment, 'customerProjectId', customerProjectId)
		},

		async copyToClipboard() {
			let assignment = null
			if(this.contextAssignment.assignment) {
				assignment = this.contextAssignment.assignment
			}
			let assignments = this.selectedAssignments.filter(x => true)
			if(assignment?.id && !this.selectedAssignments.some(x => x.id == assignment.id)) {
				assignments.push(assignment)
			}

			if(!assignments.length) {
				assignments = await this.getAllAssignments()
			}
			
			const text = assignments
				.map(x => 
					`${ x.userBlueTag }\t` +
					`${ x.billableCode }\t` +
					`${ x.billableLineRole || x.otherBillableLine }\t` +
					`${ x.customerProjectName }\t` +
					`${ x.quantity }\t` +
					`${ x.isBillable ? 'Billable' : 'Not billable' }\t` +
					`${ x.timeStatusName }\t` +
					`${ x.statusName }\t` +
					`${ moment(x.startDate).format('YYYY-MM-DD') }\t` +
					`${ moment(x.endDate).format('YYYY-MM-DD') }\t` +
					`${ x.calendars.map(c => c.name).join(', ') }\t` +
					`${ x.actualWorkingHours }\t` +
					`${ x.totalWorkingHours }\t` +
					`${ x.months }\t` +
					`${ x.weeks }`
				)
				.join('\n')
			navigator.clipboard.writeText(text)
			this.clearContextAssignment()
		},
		async exportToExcel() {
			let assignments = this.selectedAssignments.filter(x => true)

			let assignment = this.contextAssignment.assignment
			if(assignment?.id && !this.selectedAssignments.some(x => x.id == assignment.id)) {
				assignments.push(assignment)
			}

			if(!assignments.length) {
				assignments = await this.getAllAssignments()
			}

			let props = {
        Title: `Assignments`,
        Subject: "Assignments",
        Author: 'BlueSurf',
        CreatedDate: new Date()
      }

      // Fill sheet info
      let data = [], row = []

      row.push('BlueTag')
      row.push('Billable Code')
      row.push('Role')
      row.push('Project')
      row.push('Quantity')
      row.push('Billable')
      row.push('Status')
      row.push('Billing')
      row.push('Start Date')
      row.push('End Date')
      row.push('Calendars')
      row.push('Actual Working Hours')
      row.push('Total Working Hours')
      row.push('Months Base 168 hrs')
      row.push('Weeks Base 168 hrs')
      data.push(row)

			for(let assignment of assignments){
				row = []
				row.push(assignment.userBlueTag)
				row.push(assignment.billableCode)
				row.push(assignment.billableLineRole || assignment.otherBillableLine)
				row.push(assignment.customerProjectName)
				row.push(assignment.quantity)
				row.push(assignment.isBillable ? 'Billable' : 'Not billable')
				row.push(assignment.timeStatusName)
				row.push(assignment.statusName)
				row.push(moment(assignment.startDate).format('YYYY-MM-DD'))
				row.push(moment(assignment.endDate).format('YYYY-MM-DD'))
				row.push(assignment.calendars.map(c => c.name).join(', '))
				row.push(assignment.actualWorkingHours)
				row.push(assignment.totalWorkingHours)
				row.push(assignment.months)
				row.push(assignment.weeks)
        data.push(row)
			}

      let sheets = [{
        name: 'Assignments',
        data
      }]

      this.createExcel(props, sheets)
		},
		getAllAssignments() {
			return this.$http.post(
				'/api/assignment/list',
				{
					...this.filters,
					page: 1,
					pageSize: -1,
					quantity: Number(this.filters.quantity) || null
				},
			)
				.then(res => {
					let assignments = res.data.assignments

					for(let assignment of assignments) {
						if(this.changeTracker[assignment.id]) {
							const newValues = this.changeTracker[assignment.id]
							for(let key in newValues) {
								if(key !== 'assignment')
									assignment[key] = newValues[key].newValue
							}
						}
					}

					assignments.forEach(this.setStatus)

					return assignments
				})
				.catch(err => {
					console.error(err)
					this.$root.error('Error loading assignments')
				})
		},

		openDatePicker(item, key, e) {
			if(item[key])
				this.datePicker.value = moment(item[key]).format('YYYY-MM-DD')
			else this.datePicker.value = ''
			this.datePicker.show = true
			this.datePicker.item = item
			this.datePicker.key = key
			this.datePicker.x = e.clientX
			this.datePicker.y = e.clientY
		},
		datePickerHandler() {
			this.datePicker.show = false
			this.registerChange(
				this.datePicker.item,
				this.datePicker.key,
				moment(this.datePicker.value).format('YYYY-MM-DD')
			)
			this.setHours(this.datePicker.item)
			this.setStatus(this.datePicker.item)
		},

		openCalendarsDialog(){
			this.$refs.calendarsDialog.open()
				.then(this.init)
		},
		setContextAssignment(e,rowInfo) {
			e.preventDefault()
			this.contextAssignment.assignment = rowInfo.item
			this.contextAssignment.x = e.clientX
			this.contextAssignment.y = e.clientY
			this.showActionsMenu = true
		},
		clearContextAssignment() {
			this.contextAssignment.assignment = null
			this.contextAssignment.x = null
			this.contextAssignment.y = null
			this.showActionsMenu = false
		},
	},
}
</script>

<style lang="scss" scoped>
.italic {
	font-style: italic;
}

.change {
	display: grid;
	gap: 5px;
	grid-template-columns: 240px 24px 215px;
	align-items: center;

	&:hover {
		background: rgba(0,0,0,.1);
	}

	.undo-btn {
		position: absolute;
		right: -10px;
		display: none;
	}
	&:hover .undo-btn {
		display: block;
	}
}

.changes-btn{
	position: fixed;
	right: 60px;
	bottom: 5px;
}

</style>