import { VueRenderer } from '@tiptap/vue-2'
import tippy from 'tippy.js'
import _ from 'lodash'
import MentionList from '../components/MentionList.vue'

const generateSuggestion = (items, context) => {
  return {
    items: ({ query }) => {
      return applySearch(query, items, ['blueTag', 'fullName'])
    },
  
    render: () => {
      let component
      let popup
  
      return {
        onStart: props => {
          component = new VueRenderer(MentionList, {
            parent: context.$root,
            propsData: props,
          })
  
          if (!props.clientRect) {
            return
          }
  
          popup = tippy('body', {
            getReferenceClientRect: props.clientRect,
            appendTo: () => document.getElementById('app'),
            content: component.element,
            showOnCreate: true,
            interactive: true,
            trigger: 'manual',
            placement: 'bottom-start',
          })
        },
  
        onUpdate(props) {
          component.updateProps(props)
  
          if (!props.clientRect) {
            return
          }
  
          popup[0].setProps({
            getReferenceClientRect: props.clientRect,
          })
        },
  
        onKeyDown(props) {
          if (props.event.key === 'Escape') {
            popup[0].hide()
  
            return true
          }
  
          return component.ref?.onKeyDown(props)
        },
  
        onExit() {
          popup[0].destroy()
          component.destroy()
        },
      }
    },
  }
}

const normalizeText = (text, toLower = false) => {
  text = '' + text
  if(toLower)
    text = text.toLowerCase();
  return text.normalize('NFD').replace(/([^n\u0300-\u036f]|n(?!\u0303(?![\u0300-\u036f])))[\u0300-\u036f]+/gi,"$1").normalize();
}

const normalizeIncludes = (str, search) =>{
  return normalizeText(str, true).includes(normalizeText(search, true))
}

const applySearch = (search, items, attrs = []) => {
  if(!search)
    return items
  if(!attrs.length){
    for(let attr in items[0]){
      attrs.push(attr)
    }
  }
  let filteredItems = []
  for(let item of items){
    for(let attr of attrs){
      if(
        ( typeof item[attr] === 'string' && normalizeIncludes(item[attr], search) ) ||
        ( typeof item[attr] === 'number' && normalizeIncludes(item[attr].toString(), search) )
      ){
        filteredItems.push(item)
        break
      }
    }
  }
  return filteredItems
}

export default generateSuggestion