<template>
	<div class="new-back fill-height pa-5">
		<v-text-field
			v-model="filters.search" hide-details class="inline-block"
			prepend-inner-icon="search" placeholder="Search"
			outlined dense
		>
			<template #append>
				<v-badge
					offset-x="12" offset-y="13"
					color="red" :value="!!appliedFilters" :content="appliedFilters"
					right small style="margin-top: -6px"
				>
					<v-btn icon @click="filtersActive = !filtersActive">
						<v-icon>mdi-tune</v-icon>
					</v-btn>
				</v-badge>
			</template>
		</v-text-field>
		
		<v-expansion-panels :value="filtersActive ? 0 : null" flat class="my-3">
			<v-expansion-panel>
				<v-expansion-panel-content color="newDesignBackground">
					<div class="d-flex flex-wrap" style="gap: 10px">
						<q-filter-input
							v-model="filters.customers" :items="customers"
							label="Customer" item-value="id" item-text="name"
							dense outlined hide-details clearable multiple
							:parent-shown-items="shownProjects"
							:data-comparer="(item, value) => item.customerId == value"
						/>
						<q-filter-input
							v-model="filters.projects" :items="projects"
							label="Name" item-value="id" item-text="name"
							dense outlined hide-details clearable multiple
							:parent-shown-items="shownProjects"
							:data-comparer="(item, value) => item.id == value"
						/>
						<q-filter-input
							v-model="filters.endpoints" :items="endpoints"
							label="Endpoint" item-value="id" item-text="name"
							dense outlined hide-details clearable multiple input-select
							:parent-shown-items="shownProjects"
							:data-comparer="(item, value) => item.endpointList.includes(value)"
						/>
						<q-filter-input
							v-model="filters.technologies" :items="technologies"
							label="Technology" item-value="name" item-text="name"
							dense outlined hide-details clearable multiple
							:parent-shown-items="shownProjects"
							:data-comparer="(item, value) => item.technologies.some(x => x.name == value)"
						/>
						<q-filter-input
							v-model="filters.industries" :items="industries"
							label="Industry" item-value="id" item-text="name"
							dense outlined hide-details clearable multiple
							:parent-shown-items="shownProjects"
							:data-comparer="(item, value) => item.industry == value"
						/>
						<v-text-field
							v-model="filters.membersQuantity"
							label="No. Members" type="number"
							dense outlined hide-details clearable
						/>
					</div>
				</v-expansion-panel-content>
			</v-expansion-panel>
		</v-expansion-panels>

		<data-table
			:headers="headers" :items="shownProjects"
			class="mt-3"
			@click:row="openOnePager"
			:item-class="() => 'cursor-pointer'"
		>
			<template #[`item.endpoints`]="{ item }">
				<div v-for="endpoint in item.endpointList" :key="endpoint">
					{{ endpoints.find(x => x.id == endpoint).name }}
				</div>
			</template>
			<template #[`item.technologies`]="{ item }">
				<v-menu>
					<template #activator="{ on }">
						<v-btn v-on="on" :disabled="!item.technologies.length" icon>
							<v-icon>mdi-dots-vertical</v-icon>
						</v-btn>
					</template>
					<v-card color="high-back" max-width="400">
						<v-card-text>
							<h3 class="mb-2">Technologies</h3>
							<v-chip v-for="tech in item.technologies" :key="tech.id" outlined>
								{{ tech.name }}
							</v-chip>
						</v-card-text>
					</v-card>
				</v-menu>
			</template>
			<template #[`item.industryName`]="{ item }">
				<v-chip v-if="item.industryName" :color="item.industryColor + '55'" :style="{ color: item.industryColor }">
					<v-icon class="mr-2">{{ item.industryIconClass }}</v-icon>
					{{ item.industryName }}
				</v-chip>
			</template>
			<template #[`item.duration`]="{ item }">
				<span v-if="item.duration != '-'">{{ item.duration }} months</span>
			</template>
		</data-table>

		<one-pager-pdf-dialog ref="pdfDialog"/>
	</div>
</template>

<script>
import moment from "moment"
import OnePagerPdfDialog from "../../dialogs/OnePagerPdfDialog"

export default {
	components: { OnePagerPdfDialog },
	data: () => ({
		filtersActive: false,
		filters: {
			customers: [],
			projects: [],
			endpoints: [],
			technologies: [],
			industries: [],
			membersQuantity: null,
		},

		headers: [
			{ text: 'Customer', value: 'customerName' },
			{ text: 'Name', value: 'name' },
			{ text: 'Endpoints', value: 'endpoints' },
			{ text: 'Technologies', value: 'technologies', sortable: false, align: 'center' },
			{ text: 'Industry', value: 'industryName' },
			{ text: 'Duration', value: 'duration', align: 'right'},
		],
		projects: [],
		endpoints: [],
		customers: [],
		industries: [],
	}),
	computed: {
		shownProjects(){
			let projects = this.projects

			if(this.filters.customers?.length){
				projects = projects.filter(x => this.filters.customers.includes(x.customerId))
			}
			if(this.filters.projects?.length){
				projects = projects.filter(x => this.filters.projects.includes(x.id))
			}
			if(this.filters.endpoints?.length){
				projects = projects.filter(x => this.filters.endpoints.some(y => x.endpointList.includes(y)))
			}
			if(this.filters.technologies?.length){
				projects = projects.filter(x => this.filters.technologies.some(y => x.technologies.some(z => z.name == y)))
			}
			if(this.filters.industries?.length){
				projects = projects.filter(x => this.filters.industries.includes(x.industry))
			}
			if(this.filters.membersQuantity){
				projects = projects.filter(x => x.membersQuantity == this.filters.membersQuantity)
			}

			if(this.filters.search){
				projects = this.applySearch(this.filters.search, projects, this.headers.map(x => x.value))
			}

			return projects
		},
		technologies(){
			return this.projects
				.flatMap(x => x.technologies)
				.filter((x, i, arr) => arr.findIndex(y => y.name == x.name) == i)
				.map(x => ({ name: x.name }))
		},
		appliedFilters(){
			return this.countActiveFilters(this.filters, 'search')
		},
	},
	created(){
    this.setUserPreferences("ProjectOnePager", "filters")
		document.title = "One Pager"
    this.$q.log(7, 53, 3, 'NA')
		this.init()
	},
	methods: {
		init(){
			this.$http
        .get("api/project?onePager=true&all=true")
        .then(res => {
          this.projects = res.data
						.filter(x => x.status != 4)
						.map(x => ({
							...x,
							duration: this.getDuration(x),
						}))
        })
        .catch(res => {
          console.error(res)
          this.$root.error("Cannot get records.")
        })
			
			this.$http
				.get('api/enums/ProjectEndpoints')
				.then(res => {
					this.endpoints = res.data
				})
			
			this.$http
				.get('api/customer')
				.then(res => {
					this.customers = res.data
				})
				
			this.$http
				.get('api/enums/ProjectIndustries')
				.then(res => {
					this.industries = res.data
				})
		},
		openOnePager(item) {
			this.$refs.pdfDialog.open(item)
		},
		getDuration(item){
			if(!item.startDate || !item.agreedEndDate) return '-'

			return moment(item.agreedEndDate).diff(item.startDate, 'month')
		},
	},
}
</script>

<style lang="scss" scoped>

::v-deep .v-expansion-panel-content__wrap{
	padding: 0 !important;
}
</style>