var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('blue-confirm-dialog',{ref:"dialog",attrs:{"persistent":""},scopedSlots:_vm._u([{key:"text",fn:function(){return [_c('v-form',{ref:"form"},[_c('div',{staticClass:"d-flex flex-column",staticStyle:{"gap":"10px"}},[_c('div',[_c('label',[_vm._v("Approver*")]),_c('q-user-autocomplete',{attrs:{"items":_vm.members,"confirmable":false,"rules":[function (val) { return !!val || 'Required'; }],"dense":"","outlined":"","hide-details":""},model:{value:(_vm.approval.approverId),callback:function ($$v) {_vm.$set(_vm.approval, "approverId", $$v)},expression:"approval.approverId"}})],1),_c('div',[_c('label',[_vm._v("Type")]),_c('v-select',{attrs:{"items":_vm.types,"item-value":"value","item-text":"displayName","dense":"","outlined":"","hide-details":""},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var item = ref.item;
return [_c('v-icon',{staticClass:"mr-2",attrs:{"color":item.color}},[_vm._v(" "+_vm._s(item.iconClass)+" ")]),_vm._v(" "+_vm._s(item.displayName)+" ")]}},{key:"selection",fn:function(ref){
var item = ref.item;
return [_c('v-icon',{staticClass:"mr-2",attrs:{"color":item.color}},[_vm._v(" "+_vm._s(item.iconClass)+" ")]),_vm._v(" "+_vm._s(item.displayName)+" ")]}}]),model:{value:(_vm.type),callback:function ($$v) {_vm.type=$$v},expression:"type"}})],1),_c('div',[_c('label',[_vm._v("Status to Approve*")]),_c('v-autocomplete',{attrs:{"items":_vm.shownStatus,"rules":[function (val) { return !!val || 'Required'; }],"dense":"","outlined":"","hide-details":"","item-value":"id"},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{attrs:{"label":"","color":item.color,"small":"","dark":""}},[_c('v-icon',{attrs:{"left":"","color":item.textColor,"small":""}},[_vm._v(" "+_vm._s(item.iconClass)+" ")]),_vm._v(" "+_vm._s(item.name)+" ")],1)]}},{key:"item",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{attrs:{"label":"","color":item.color,"small":"","dark":""}},[_c('v-icon',{attrs:{"left":"","color":item.textColor,"small":""}},[_vm._v(" "+_vm._s(item.iconClass)+" ")]),_vm._v(" "+_vm._s(item.name)+" ")],1)]}}]),model:{value:(_vm.approval.statusId),callback:function ($$v) {_vm.$set(_vm.approval, "statusId", $$v)},expression:"approval.statusId"}})],1),_c('div',[_c('label',[_vm._v("Requirements")]),_c('v-textarea',{attrs:{"dense":"","outlined":"","hide-details":"","rows":"2"},model:{value:(_vm.approval.requirements),callback:function ($$v) {_vm.$set(_vm.approval, "requirements", $$v)},expression:"approval.requirements"}})],1)])])]},proxy:true}])})}
var staticRenderFns = []

export { render, staticRenderFns }