<template>
	<div :class="{shown: value}" class="panel">
		<div @click="handleClickOutside" v-if="value" class="bckgrnd" :style="{zIndex}"></div>
		<div
			class="content"
			:style="{
				width: width+'px',
				right: '-'+(value ? 0 : width)+'px',
				zIndex: zIndex+1
			}"
		>
			<slot/>
		</div>
	</div>
</template>

<script>
export default {
	props: {
		value: { type: Boolean, default: false },
		closeOutside: { type: Boolean, default: false },
		width: { type: Number, default: 600 },
		zIndex: { type: Number, default: 199 },
	},
	methods: {
		handleClickOutside(){
			if(this.closeOutside){
				this.$emit('input', false)
			}
		},
	},
}
</script>

<style lang="scss" scoped>
.panel{
	.bckgrnd{
		position: fixed;
		left: 0; top: 0;
		width: 100vw;
		height: 100vh;
		background: rgba(0,0,0,0.5);
	}
	.content{
		position: fixed;
		top: 0;
		height: 100vh;
		transition: all .3s;
		overflow: scroll;
		overflow-x: hidden;
	}
	
	&.shown .content{
		right: 0;
	}
}
</style>