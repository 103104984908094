<template>
  <div class="q-rich-editor">
    <div v-if="!editting" class="rich-text-format my-2 pa-2 text--text" @click="editting = !disabled" v-html="innerValue"></div>
    <rich-editor
      v-if="editting"
      v-model="innerValue"
      @blur="blur($event)"
      @save="(id) => save(id)"
      @focus="focus"
      :handleEsc="cancel"
      :handlePaste="handlePaste"
      :user-suggestions="userSuggestions"
      :autofocus="autofocus"
    />
    <div class="mt-1">
      <v-btn small v-if="editting && includeButtons" @click="save" color="primary">
        <v-icon>done</v-icon>
      </v-btn>
      <v-btn outlined small v-if="editting && includeButtons" color="grey" @click="cancel" id="cancel-btn">
        <v-icon>close</v-icon>
      </v-btn>   
    </div>
  </div>
</template>

<script>
  import moment from 'moment'
  import _ from "lodash"

  export default {
    props: {
      placeholder: String,
      value: String,
      handlePaste: Function,
      disabled: { type: Boolean, default: false},
      includeButtons: { type: Boolean, default: false},
      userSuggestions: { type: Array },
      autofocus: Boolean,
    },
    data: () => ({
      innerValue: null,
      editting: false,
    }),
    watch: {
      value: function (val) {
        this.innerValue = val;
      },
    },
    computed: {
    },
    methods: {
      focus() {
        this.$emit('focus')
      },
      blur(e) {
        this.$emit('blur')
      },
      save(id) {
        this.$emit('save', this.innerValue, id)
        this.$emit('input', this.innerValue)
        this.editting = false
      },
      cancel() {
        this.innerValue = this.value
        this.editting = false
        this.$emit('close')
      },
    },
    created() {
    },
   }
</script>

<style scoped lang="scss">
  .q-rich-editor {
    padding-bottom: 10px;
    color: #333;
  }
  .pane {
    min-height: 50px;
  }
</style>