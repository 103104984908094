<template>
  <v-dialog v-model="dialog" persistent width="540" >
  <v-card>
    <v-card-title class="mb-2 word-break">
      {{ title }}
    </v-card-title>
    <v-card-text class="px-4">
      <v-form v-if="!individualForm" v-model="localForm">
        <v-text-field
          class="px-3"
          v-model="name"
          :rules="[
            v => v.length || rule,
          ]"
        >
          <template v-slot:label >
            <h3>{{label}}*</h3>
          </template>
        </v-text-field>
      </v-form>
      <slot v-else></slot>
    </v-card-text>
    <v-card-actions :style="{ justifyContent: 'flex-end' }">
      <v-btn :text="!depressed" :depressed="depressed" :color="depressed ? 'primary' : 'success'" @click="close">{{ closeText }}</v-btn>
      <v-btn :text="!depressed" :depressed="depressed" :color="depressed ? 'primary' : 'success'" :disabled="!individualForm ? !localForm : !form" @click="save">{{ saveText }}</v-btn>
    </v-card-actions>
  </v-card>
</v-dialog>
</template>

<script>
export default {
  props: [ 'depressed', 'individualForm', 'form' ],
  data: () => ({
    title: "",
    label: "",
    rule: "",
    dialog: false,
    name: '',
    localForm: false,
    closeText: "",
    saveText: "",
    resolve: undefined,
    reject: undefined,
  }),
  methods: {
    open (
      title = 'Confirm Action',
      label = 'Name',
      rule = 'The name is required',
      closeText = 'Close',
      saveText = 'Confirm'
    )
    {
      this.title = title
      this.label = label
      this.rule = rule
      this.closeText = closeText
      this.saveText = saveText
      this.dialog = true;
      return new Promise((resolve, reject) => {
        this.resolve = resolve
        this.reject = reject
      })
    },
    save() {
      this.resolve({ confirmed: true, name: this.name })
      this.name = ''
      this.dialog = false
    },
    close() {
      this.reject()
      this.name = ''
      this.dialog = false
    },
  }
}
</script>

<style lang="sass" scoped>


</style>