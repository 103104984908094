var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"grid body-2",class:{'pl-5': !_vm.pathToChild},style:({
			color: 'var(--v-text-lighten)',
			gridTemplateColumns: _vm.mainTemplateColumns
		})},_vm._l((_vm.mainHeaders),function(header,ix){return _c('div',{key:ix},[_vm._v(" "+_vm._s(header.text)+" ")])}),0),_c('v-expansion-panels',{staticClass:"mt-2",attrs:{"multiple":"","flat":"","readonly":!_vm.pathToChild}},_vm._l((_vm.items),function(item,ix){return _c('v-expansion-panel',{key:ix,staticClass:"bordered panel new-back lighten2",class:{ 'rounded-t-lg': !ix, 'rounded-b-lg': ix === _vm.items.length - 1 },attrs:{"active-class":"active-panel"}},[_c('v-expansion-panel-header',{staticClass:"py-3 px-0",staticStyle:{"min-height":"60px"},attrs:{"hide-actions":""}},[_c('div',{staticClass:"grid",class:{'pl-5': !_vm.pathToChild},style:({gridTemplateColumns: _vm.mainTemplateColumns})},_vm._l((_vm.mainHeaders),function(ref,jx){
		var value = ref.value;
return _c('div',{key:jx},[_vm._t(("item." + value),function(){return [_vm._v(" "+_vm._s(item[value])+" ")]},null,item)],2)}),0),(!!_vm.pathToChild)?_c('v-icon',{staticClass:"chevron"},[_vm._v("mdi-chevron-down")]):_vm._e()],1),(!!_vm.pathToChild)?_c('v-expansion-panel-content',{staticClass:"px-0 pt-3",class:{'new-back': _vm.$vuetify.theme.isDark},staticStyle:{"background":"var(--v-lightgray-lighten2)"}},[_c('div',{staticStyle:{"margin-left":"-24px","margin-right":"-24px"}},[_c('div',{staticClass:"grid caption text--text",style:({
							color: 'var(--v-text-lighten)',
							gridTemplateColumns: _vm.secondaryTemplateColumns
						})},_vm._l((_vm.secondaryHeaders),function(header,jx){return _c('div',{key:jx},[_vm._v(" "+_vm._s(header.text)+" ")])}),0),_vm._l((_vm.pathToChild(item)),function(subitem,jx){return _c('div',{key:jx,staticClass:"grid my-2",style:({gridTemplateColumns: _vm.secondaryTemplateColumns})},_vm._l((_vm.secondaryHeaders),function(ref,kx){
						var value = ref.value;
return _c('div',{key:kx},[_vm._t(("subitem." + value),function(){return [_vm._v(" "+_vm._s(subitem[value])+" ")]},null,subitem)],2)}),0)})],2)]):_vm._e()],1)}),1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }