<template>
	<div
    id="board-container"
    :style="{
      width: 'calc(100vw - 185px)',
    }"
  >
    <v-row
      align="stretch"
      class="board flex-nowrap" id="board"
    >
      <v-col
        v-for="s in shownKanban.statuses" :key="s.id" class="pa-0"
      >
        <v-card
          height="100%" class="column-card overflow-x-hidden dense-scrollbar hover-scrollbar" :elevation="0"
          :style="{
            maxHeight: $parent.isPersonal ? 'calc(100vh - 210px)' : 'calc(100vh - 195px)',
          }"
          :class="{'limit-red-bg': s.limit > 0 && s.limit < s.workItemsQuantity }"
          @click.right="$parent.openStatusContextMenu($event, s.id)"
        >
          <v-card-title class="px-1 py-0 justify-left th-fixed status-title new-back pb-1">
            <div class="text-center caption cursor-pointer flex"  @click="showStatusCycleTime(s.id)">
              <span
                :style="{
                  color: 
                    s.cycleTimeGoal ?
                      ( toMinutes(s.cycleTimeGoal, s.cycleTimeGoalUnit, $parent.project.usingWorkingHours) < s.cycleTime ? 'red' : 'green' )
                      : '',
                  position: 'relative',
                }"
              >
                <v-tooltip content-class="tooltip-timeline" :key="s.id + 'tooltip'" top v-if="s.isInitial || s.isFinal" v-model="s.showTooltip">
                  <template v-slot:activator="{ on }">
                    <v-icon
                      v-on="on"
                      @mouseover.native="toggleTooltip(s, true)"
                      @mouseleave.native="toggleTooltip(s, false)"
                      :key="s.id + 'status'"
                      class="cursor-pointer leadtime-icon mt-n1"
                      small
                    >
                      {{`mdi-${s.isInitial ? 'flag-outline' : 'flag'}`}}
                    </v-icon>
                  </template>
                  <span style="padding-bottom: -30px;">{{s.isInitial ? 'Initial' : 'Final'}}</span>
                </v-tooltip>
                {{
                  s.cycleTimeGoal ? 
                    formatTimeByUnit(s.cycleTime, s.cycleTimeGoalUnit, $parent.project.usingWorkingHours) :
                    getIndicatorTime(s.cycleTime, false, $parent.project.usingWorkingHours)
                }}
              </span>
              <b v-if="!!s.cycleTimeGoal">
                vs.
                <span>
                  {{ formatTimeByUnit(toMinutes(s.cycleTimeGoal, s.cycleTimeGoalUnit), s.cycleTimeGoalUnit) }}
                </span>
              </b>
            </div>
            <v-btn block :color="s.color" :style="{opacity: $vuetify.theme.isDark ? .9 : 1, cursor: 'default'}" dark class="mr-2 text-capitalize status-button-title" style="padding: 0 5px 0 12px;">
              <v-icon left small>{{s.iconClass}}</v-icon>
              <span class="status-name caption">{{s.name}}</span>

              <v-spacer></v-spacer>

              <v-icon v-if="$parent.filterActive" style="margin-right: 2px" small>mdi-filter-variant</v-icon>
              <span class="workitems-quantity" :style="{ width: s.limit > 0 ? 'auto' : '20px' }">
                <span
                  v-if="s.workItems"
                  :class="{'limit-red': s.limit > 0 && s.limit < s.workItemsQuantity}"
                  class="caption"
                >
                  {{s.workItemsQuantity}}
                </span>
                <span v-if="s.limit > 0" style="font-size: 13px;">/{{s.limit}}</span>
              </span>
              <v-tooltip top z-index="201" v-if="s.definitionOfDone && JSON.parse(s.definitionOfDone)">
                <template v-slot:activator="{ on, attrs }">
                  <span
                    v-bind="attrs"
                    v-on="on"
                    :style="{marginLeft: '3px', marginTop: '-3px', fontSize: '14px'}"
                  >
                    ⭐️
                  </span>
                </template>
                <span style="white-space:pre-wrap; word-break:break-word; max-width: 280px;" v-html="JSON.parse(s.definitionOfDone)"></span>
              </v-tooltip>
            </v-btn>
          </v-card-title>

          <v-card-text class="pa-2 column-card-content">
            <div>
              <div 
                class="show-all active" 
                @click="showLess(s)"
                v-if="s.workItems.length > 10"
              > Show Less </div>
            </div>
            <draggable
              v-model="s.workItems" group="columns"
              @change="changeWorkItem($event, s)"
              :move="workItemMovement" style="height: 100%"
              :options="{disabled: !$parent.canEditProject}"
            >
              <work-item
                v-for="workItem in s.workItems"
                :key="workItem.id"
                @click="someSelected ? addToMultiselect(workItem) : openWorkItemDetailsDialog(workItem.code)" 
                @clickRight="(e) => $parent.openWorkItemContextMenu(e, workItem)"
                @mouseenter="hoverMultiselect(workItem)"
                @mouseleave="workItemHover =  {}"
                :tags="$parent.workItemTags(workItem)"
                :sprints="$parent.sprints"
                :someSelected="someSelected"
                :usingWorkingHours="$parent.project.usingWorkingHours"
                :leadTimeGoal="$parent.leadTimeGoal"
                :workItem="workItem"
                :status="s"
              />
              <div class="d-flex justify-space-around">
                <div
                  v-if="s.workItems.length < s.workItemsQuantity"
                  @click="showMore(s)"
                  class="show-all active caption"
                > Show More </div>
                <div
                  v-else-if="s.workItems.length > 10" 
                  @click="showLess(s)"
                  class="show-all active caption"
                > Show Less </div>
                <div v-if="s.workItems.length < s.workItemsQuantity"
                  @click="showAll(s)"
                  class="show-all active caption"
                > Show All </div>
              </div>
            </draggable>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <status-cycle-time-dialog 
      ref="statusCycleTimeDialog"
      @clickWorkItem="$parent.openWorkItemDetailsDialog"
      :usingWorkingHours="$parent.project.usingWorkingHours"
      :projectCreatedAt="$parent.project.createdDateTime"
    >
    </status-cycle-time-dialog>
	</div>	
</template>

<script>
import draggable from "vuedraggable"
import WorkItem from '../../components/WorkItem'
import StatusCycleTimeDialog from "./../../dialogs/StatusCycleTimeDialog"

export default {
	components: {
    draggable,
    StatusCycleTimeDialog,
    WorkItem
  },
  props: {
    type: Number,
    id: String,
    selectedWorkItems: Array,
  },
	data: () => ({
    pressingCtrl: false,
    workItemHover: {},
    lastHoverElement: null,
    originHoverElement: null,

    kanbanTimeout: null,
    kanbans: [],
    quantityPerStatus: {},
	}),
	created(){
		window.addEventListener("keydown", this.keyPress)
    window.addEventListener("keyup", this.keyUp)

    this.loadKanbans()
	},
  computed: {
    shownKanban() {
      return this.kanbans
        .find(x => x.type == this.type) || {}
    },
    someSelected(){
      return !!this._selectedWorkItems.length
    },
    _selectedWorkItems() {
      let selectedWorkItems = this.allWorkItems
        .filter(wi => !!wi._selected)
      this.$emit('update:selectedWorkItems', selectedWorkItems)
      return selectedWorkItems
    },
    allWorkItems() {
      return this.kanbans
        .flatMap(x => x.statuses)
        .flatMap(x => x.workItems)
    },
  },
  watch: {
    '$parent.filters': {
      deep: true,
      handler() {
        this.loadKanbans()
      }
    },
    '$parent.search'(){
      this.loadKanbans()
    },
  },
	methods: {
    loadKanbans(skipWorkItems = false) {
      if(this.kanbanTimeout) {
        clearTimeout(this.kanbanTimeout)
      }
      this.kanbanTimeout = setTimeout(() => {
        this.$http.post(
          `api/project/${this.id}/kanban?skipWorkItems=${skipWorkItems}`,
          {
            ...this.$parent.filters,
            search: this.$parent.search,
            quantityPerStatus: Object.keys(this.quantityPerStatus)
              .map(x => ({statusId: x, quantity: this.quantityPerStatus[x]})),
          },
          {
            headers: { hideLoading: skipWorkItems }
          }
        )
          .then(res => {
            if(skipWorkItems) {
              let currentStatuses = this.kanbans
                .flatMap(x => x.statuses)
              let newStatuses = res.data
                .flatMap(x => x.statuses)
              
              newStatuses.forEach(newStatus => {
                let currentStatus = currentStatuses.find(x => x.id == newStatus.id)
                if(currentStatus) {
                  this.$set(currentStatus, 'cycleTime', newStatus.cycleTime)
                  this.$set(currentStatus, 'workItemsQuantity', newStatus.workItemsQuantity)
                }
              })
            }
            else {
              this.kanbans = Object.assign([], res.data)
            }
          })
          .catch(err => {
            console.error(err)
            this.$root.error('Cannot get kanban.')
          })
      }, 50)
    },
    openWorkItemDetailsDialog(code) {
      this.$parent.openWorkItemDetailsDialog(code, this.loadKanbans)
    },

    getColorWithTransparency(color) {
      return _.dropRight(color, 2).join("") + '33'
    },
    workItemMovement(e) {
      if(this.lastHoverElement) {
        this.lastHoverElement.classList.remove('hover-status')
        if(this.lastHoverElement.classList.contains('limit-red-bg'))
          this.lastHoverElement.style.setProperty('background-color', 'var(--v-error-lighten)', 'important')
        else
          this.lastHoverElement.style.setProperty('background-color', 'var(--v-newDesignBackground-base)', 'important')
        this.lastHoverElement.firstChild.style.setProperty('background-color', 'var(--v-newDesignBackground-base)', 'important')
      }
      this.lastHoverElement = e.to.__vue__.$el.parentElement.parentElement.parentElement
      this.originHoverElement = e.from.__vue__.$el.parentElement.parentElement.parentElement;
      
      this.lastHoverElement.classList.add('hover-status')
      this.lastHoverElement.style.borderColor = this.lastHoverElement.firstChild.firstChild.style.backgroundColor
      this.lastHoverElement.firstChild.style.setProperty(
        'background-color',
        this.getRgbWithOpacity(this.lastHoverElement.firstChild.firstChild.style.backgroundColor),
        'important'
      )
      this.lastHoverElement.style.setProperty(
        'background-color',
        this.getRgbWithOpacity(this.lastHoverElement.firstChild.firstChild.style.backgroundColor),
        'important'
      )
      this.getRgbWithOpacity(this.lastHoverElement.firstChild.firstChild.style.backgroundColor)
    },
    changeWorkItem(e, s) {      
      this.lastHoverElement.classList.remove('hover-status')

      if(this.lastHoverElement.classList.contains('limit-red-bg')) {
        this.lastHoverElement.style.setProperty('background-color', 'var(--v-error-lighten)', 'important')
        this.lastHoverElement.firstChild.style.setProperty('background-color', 'var(--v-error-lighten)', 'important')
      }
      else {
        this.lastHoverElement.style.setProperty('background-color', 'var(--v-newDesignBackground-base)', 'important')
        this.lastHoverElement.firstChild.style.setProperty('background-color', 'var(--v-newDesignBackground-base)', 'important')
      }

      if(this.originHoverElement.classList.contains('limit-red-bg')) {
        this.originHoverElement.style.setProperty('background-color', 'var(--v-error-lighten)', 'important')
        this.originHoverElement.firstChild.style.setProperty('background-color', 'var(--v-error-lighten)', 'important')
      }
      else {
        this.originHoverElement.style.setProperty('background-color', 'var(--v-newDesignBackground-base)', 'important')
        this.originHoverElement.firstChild.style.setProperty('background-color', 'var(--v-newDesignBackground-base)', 'important')
      }

      this.$nextTick(() => this.lastHoverElement = null)

      let eventInfo = e.added || e.moved
      if (!eventInfo) return
      this.$parent.workItemChanged(e, s)
        .then((res) => {
          let workItem = this.allWorkItems.find(x => x.id == eventInfo.element.id)
          if(workItem) {
            this.$set(workItem, 'statusId', res.data.statusId)
            this.$set(workItem, 'currentColumnAge', res.data.currentColumnAge)
            this.$set(workItem, 'age', res.data.age)
            this.$set(workItem, 'leadTime', res.data.leadTime)
          }
          this.loadKanbans(!this.someSelected)
        })
        .catch(err => {
          this.loadKanbans()
        })
    },
    getRgbWithOpacity(rgb) {
      rgb = rgb.replace('rgb(', '').replace(')', '')
      rgb = rgb.split(', ')
      rgb.push('0.2')
      rgb = rgb.join(',')
      rgb = `rgb(${rgb})`
      return rgb
    },
    roundedTotalHours(hours) {
      return Math.round(hours)
    },
    showStatusCycleTime(id){
      this.$refs.statusCycleTimeDialog.open(id)
    },
    showLess(status){
      this.$set(status, 'workItems', status.workItems.slice(0, 10))
      delete this.quantityPerStatus[status.id]
    },
    showMore(status){
      this.$http.post(
        `api/project/status/${status.id}/loadMore/${status.workItems.length}`,
        {
          ...this.$parent.filters,
          search: this.$parent.search,
        }
      )
        .then(res => {
          this.$set(status, 'workItems', status.workItems.concat(res.data))
          this.quantityPerStatus[status.id] = status.workItems.length
        })
        .catch(err => {
          console.error(err)
          this.$root.error('Cannot load more work items.')
        })
    },
    showAll(status){
      this.$http.post(
        `api/project/status/${status.id}/loadAll`,
        {
          ...this.$parent.filters,
          search: this.$parent.search,
        }
      )
        .then(res => {
          this.$set(status, 'workItems', res.data)
          this.quantityPerStatus[status.id] = res.data.length
        })
        .catch(err => {
          console.error(err)
          this.$root.error('Cannot load more work items.')
        })
    },
    hoverMultiselect(workItem){
      this.workItemHover = workItem
      if(this.pressingCtrl && this.$parent.someSelected){
        this.addToMultiselect(workItem)
      }
    },
    addToMultiselect(workItem){
      this.$set(workItem, '_selected', !workItem._selected)
    },
    keyPress(e){
      if(e.repeat)
        return
      this.pressingCtrl = e.ctrlKey || e.metaKey
      if(this.multiselect && this.pressingCtrl && this.workItemHover){
        this.addToMultiselect(this.workItemHover)
      }
    },
    keyUp(e){
      this.pressingCtrl = e.ctrlKey || e.metaKey
    },
    toggleTooltip(status, value) {
      this.$set(status, 'showTooltip', value)
    },
	},
}
</script>

<style lang="scss" scoped>
.tags-container{
  max-height: 400px;
  overflow-y: scroll;
}
.tooltip-timeline{
  margin-top: 30px;
}
.status-title{
  border-radius: 0 !important;
}
.status-button-title {
  max-width: 100%;
  overflow: hidden;
  letter-spacing: 0.05em;
  height: 40px !important;
  border-radius: 10px;
  display: flex;
}
.status-name {
  white-space: normal;
  word-break: normal;
  max-height: 35px;
  text-overflow: ellipsis;
  overflow: hidden;
  line-height: 16px;
  font-size: 11px;
}
.status-button-title ::v-deep .v-btn__content {
  max-width: 100%;
}

::v-deep .v-stepper__step__step .v-icon{
  color: #1976d2 !important;
  font-size: 27px !important;
}
::v-deep .v-stepper__step__step{
  margin-right: 2px !important;
}
::v-deep .v-stepper__step{
  padding-left: 0 !important;
  padding-right: 0 !important;
}
.timeline-line{
  color: #1976d2;
  border: 1px solid #1976d2 !important;
}
.timeline-hidden{
  width: 25px !important;
  padding-left: 32px;
  padding-top: 35px;
}

.board {
  width: max-content;
  min-width: 100%;
  margin: 0;
  justify-content: center;
  .col{
    max-width: 230px;
  }
}
.column-card {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  background: none !important;
}
.column-card-content {
  flex-grow: 1;
}

.dense-tooltip{
  padding: 3px 5px;
  font-size: .8em;
  line-height: 1rem;
}

.v-color-picker ::v-deep .v-color-picker__preview {
  display: none;
}

.priority-icon {
  background: #FFFFFF66;
  border-radius: 100%;
}

.show-all {
  text-decoration: underline;
}

.show-all.active {
  color: #0072ff;
  cursor: pointer;
}

.show-all.inactive {
  color: #777777;
}
.limit-red {
  color: #FF4747;
}

.limit-red-bg {
  background-color: var(--v-error-lighten) !important;
}
.leadtime-container{
  display: inline-flex;
  align-items: center;
  justify-content: center;
}
.leadtime-avg{
  background: #404040;
  color: white;
  padding: 4px 15px;
  border-radius: 3px;
  cursor: pointer;
  font-size: .87em;
}
::v-deep .v-input--selection-controls__input{
  margin-right: 0;
}
.leadtime-avg div{
  text-align: center;
}
.filter-container{
  display: inline-flex;
  gap: 10px;
  margin-top: 10px;
  max-width: 1000px;
}
::v-deep .search fieldset{ border: none; }
::v-deep .search .v-input__slot{ padding: 0 !important; }
.th-fixed {
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  z-index: 1;
  border-radius: 5px;
  background-color: none;
}
#board-container{
  max-height: calc(100vh - 170px);
  overflow-y:scroll;
}
#board-container::-webkit-scrollbar {
    width: 5px;
    height: 5px;
}
#board-container::-webkit-scrollbar-thumb{
  background: var(--v-scroll-lighten2);
}
@media (max-width: 1262px) {
  #board-container{
    max-height: calc(100vh - 205px);
    width: auto !important;
  }
}
.estimated-effort{
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 1px 5px;
  background: rgba(0,0,0,.08);
  border-radius: 5px;
}
.v-chip{
  margin-top: 5px !important;
}
.definitionOfDone-icon{
  font-size: 20px;
}
.multiselect-icon{
  position: absolute;
  top: -12px; left: -14px;
  font-size: 28px;
  background: var(--v-background-base);
  border-radius: 50%;
}
.color-filter{
  border: 1px solid #555;
  border-radius: 5px;
  padding: 7px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: var(--v-text-lighten2);

  .v-icon{
    color: var(--v-text-lighten2);
  }

  .colors-container{
    display: flex;
    flex-wrap: wrap;

    .color{
      display: inline-block;
      margin: 2px 4px 2px 0;
      border: 1px solid var(--v-text-lighten);
      width: 24px;
      height: 24px;
      border-radius: 50%;

      &:hover .v-icon{
        display: initial;
      }

      .v-icon{
        display: none;
        position: relative;
        left: 3px;
      }
    }
  }
}
.hover-status {
  border: 2px dashed;
}
.no-border {
  border: none;
}
.workitems-quantity {
  background: #FFFFFF1A;
  height: 30px;
  right: 0;
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.avatar-priority-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.priority-code-container {
  display: flex;
}
</style>