<template>
	<infinite-data-table
		:value="value" @#input="$emit('input', $event)"
		:headers="shownHeaders" :items="items"
		:server-items-length="totalItems"
		:total-items="totalItems"

		:is="infinite ? 'infinite-data-table' : 'data-table'"
		v-bind="$attrs" v-on="$listeners"
	>
		<template #[`header.actualWorkingHours`]> Actual<br><span class="header-span">Working Hours</span> </template>
		<template #[`header.totalWorkingHours`]> Total<br><span class="header-span">Working Hours</span> </template>
		<template #[`header.months`]> Months<br><span class="header-span">Base 168 hrs</span> </template>
		<template #[`header.weeks`]> Weeks<br><span class="header-span">Base 168 hrs</span> </template>

		<template #[`item.userBlueTag`]="{ item }">
			<span v-if="readonly || disabledHeaders.includes('userBlueTag')">{{ item.userBlueTag }}</span>
			<v-autocomplete
				v-else
				:value="item.userId"
				:items="users"
				@change="$emit('user-changed', item, $event)"
				item-value="id" item-text="blueTag"
				background-color="transparent"
				class="fill-height align-center body-2 hide-input"
				flat solo hide-details dense
			>
				<template #selection="{ item }">
					{{ item.blueTag }}
				</template>
				<template #item="{ item }">
					<v-list-item-avatar>
						<user-avatar :user-id="item.id" hide-loading/>
					</v-list-item-avatar>
					<v-list-item-content>
						<v-list-item-title>{{ item.blueTag }}</v-list-item-title>
						<v-list-item-subtitle>{{ item.fullName }}</v-list-item-subtitle>
					</v-list-item-content>
				</template>
			</v-autocomplete>
		</template>

		<template #[`item.billableCode`]="{ item }">
			<span v-if="readonly || disabledHeaders.includes('billableCode')">{{ item.billableCode }}</span>
			<v-tooltip v-else :disabled="!item.billableCustomerName" right>
				<template #activator="{ on }">
					<div v-on="on" class="billable-autocomplete">
						<v-autocomplete
							:value="item.billableId"
							@change="$emit('billable-changed', item, $event)"
							:items="billables"
							item-value="id" item-text="code"
							background-color="transparent"
							style="width: 170px"
							class="fill-height align-center body-2"
							flat solo hide-details dense
						>
							<template #item="{ item }">
								<div v-if="item.isCancelled || item.isClosed"></div>
								<div v-else class="d-flex align-center fill-height body-2" style="min-height: 32px">{{ item.code }}</div>
							</template>
						</v-autocomplete>
					</div>
				</template>
				{{ item.billableCustomerName }}
			</v-tooltip>
		</template>

		<template #[`item.billableLineRole`]="{ item }">
			<span v-if="readonly || disabledHeaders.includes('billableLineRole')">{{ item.billableLineRole || item.otherBillableLine }}</span>
			<v-select
				v-else-if="item.billableId"
				:value="item.billableLineId"
				@change="$emit('register-change', item, 'billableLineId', $event)"
				:items="billableLines.filter(bl => bl.billableId == item.billableId)" 
				item-value="id" item-text="role"
				background-color="transparent"
				class="fill-height align-center body-2 hide-input"
				flat solo hide-details dense
			>
				<template #item="{ item }">
					<v-tooltip right>
						<template #activator="{ on }">
							<div style="width: 100%; min-width: 150px" v-on="on">
								<div class="select-item">
									<span class="caption">Seat {{item.seat}}</span>
									<div class="d-flex align-center justify-space-between" style="gap: 10px">
										{{item.role}}
										<span>{{ currencyFormat(item.ratePerHour) }}</span>
									</div>
								</div>
								<billable-percentage-bar :primaryAmount="item.assignedHours" :objective="item.hours"/>
							</div>
						</template>
						<div>
							<div v-for="(h, ix) in item.assignments.slice(0, 20)" :key="ix">
								{{h.userBlueTag}} - {{h.hours}} hrs.
							</div>
							<div v-if="item.assignments.length > 20">...</div>
						</div>
					</v-tooltip>
				</template>
				<template #selection="{ item }">
					{{ item.role }}
				</template>
			</v-select>
			<v-text-field
				v-else
				:value="item.otherBillableLine"
				@change="$emit('register-change', item, 'otherBillableLine', $event)"
				background-color="transparent"
				class="fill-height align-center body-2"
				flat solo hide-details dense
			/>
		</template>

		<template #[`item.customerProjectName`]="{ item }">
			<div v-if="readonly || disabledHeaders.includes('customerProjectName')" class="py-1" :set="type = projectTypes.find(t => t.value == item.customerProjectType)">
				<div class="admin-project" :style="{background: type.lightColor}">
					<div class="letter" :style="{background: type.color}">
						{{type.small}}
					</div>
					<div class="name">{{ item.customerProjectName }}</div>
				</div>
			</div>
			<v-tooltip v-else :disabled="!item.billableCustomerName" right>
				<template #activator="{ on }">
					<div v-on="on">
						<v-select
							:value="item.customerProjectId"
							@change="$emit('customer-project-changed', item, $event)"
							:items="customerProjects.filter(p => !item.billableId || p.customerId == item.billableCustomerId)"
							item-value="id" item-text="name"
							background-color="transparent"
							class="fill-height align-center body-2 hide-input"
							flat solo hide-details dense
						>
						<template v-slot:item="{ item: project }">
							<div class="py-1" :set="type = projectTypes.find(t => t.value == project.type)">
								<div class="admin-project" :style="{background: type.lightColor}">
									<div class="letter" :style="{background: type.color}">
										{{type.small}}
									</div>
									<div class="name">{{project.name}}</div>
								</div>
							</div>
						</template>
						<template v-slot:selection="{ item: project }">
							<div class="py-1" :set="type = projectTypes.find(t => t.value == project.type)">
								<div class="admin-project" :style="{background: type.lightColor}">
									<div class="letter" :style="{background: type.color}">
										{{type.small}}
									</div>
									<div class="name">{{project.name}}</div>
								</div>
							</div>
						</template>
						</v-select>
					</div>
				</template>
				{{ item.billableCustomerName }}
			</v-tooltip>
		</template>

		<template #[`item.quantity`]="{ item }">
			<span v-if="readonly || disabledHeaders.includes('quantity')">{{ item.quantity }}</span>
			<v-text-field
				v-else
				:value="item.quantity"
				@change="$emit('register-change', item, 'quantity', $event)"
				background-color="transparent"
				class="fill-height align-center body-2 center-input"
				flat solo hide-details dense
				type="number" step=".5"
			/>
		</template>

		<template #[`item.isBillable`]="{ item }">
			<v-btn
				@click="(item.billableType !== 3 && !readonly && !disabledHeaders.includes('isBillable')) ? $emit('register-change', item, 'isBillable', !item.isBillable) : null"
				:color="item.isBillable ? 'success' : 'error'"
				class="currency-btn" icon
			>
				<v-icon>mdi-currency-usd</v-icon>
			</v-btn>
		</template>

		<template #[`item.timeStatus`]="{ item }">
			<div class="py-1">
				<v-btn
					:color="item.timeStatusColor"
					:style="{
						background: $vuetify.theme.isDark ? '' :
							item.timeStatusLightColor
					}"
					outlined x-small style="border-radius: 20px"
				>{{ item.timeStatusName }}</v-btn>
			</div>
		</template>

		<template #[`item.status`]="{ item }">
			<div class="py-1">
				<v-btn
					:color="item.statusColor"
					outlined x-small style="border-radius: 20px"
					:style="{
						background: $vuetify.theme.isDark ? '' : item.statusLightColor
					}"
				>{{item.statusName}}</v-btn>
			</div>
		</template>

		<template #[`item.startDate`]="{ item }">
			<span v-if="readonly || disabledHeaders.includes('startDate')">{{ item.startDate | formatDate }}</span>
			<v-text-field
				v-else
				@click="$emit('open-date-picker', item, 'startDate', $event)"
				:value="item.startDate | formatDate"
				background-color="transparent"
				class="fill-height align-center body-2 center-input"
				readonly flat solo hide-details dense
			/>
		</template>

		<template #[`item.endDate`]="{ item }">
			<span v-if="readonly || disabledHeaders.includes('endDate')">{{ item.endDate | formatDate }}</span>
			<v-text-field
				v-else
				@click="$emit('open-date-picker', item, 'endDate', $event)"
				:value="item.endDate | formatDate"
				background-color="transparent"
				class="fill-height align-center body-2 center-input"
				readonly flat solo hide-details dense
			/>
		</template>

		<template #[`item.calendars`]="{ item }">
			<div style="width: 100px">
				<div v-for="(calendar, ix) in item.calendars" :key="ix" style="white-space: nowrap; text-overflow: ellipsis; overflow: hidden;">
					<div class="dot" :style="{ background: calendar.color }"></div>
					<span class="text--text caption"> {{ calendar.name }} </span>
				</div>
			</div>
		</template>

		<template #[`item.months`]="{ item }">
			{{ item.months | number(2) }}
		</template>

		<template #[`item.weeks`]="{ item }">
			{{ item.weeks | number(2) }}
		</template>
		
		<template #[`item.actions`]="{ item }">
			<v-menu left offset-x>
				<template #activator="{ on }">
					<v-btn v-on="on" icon><v-icon>mdi-dots-vertical</v-icon></v-btn>
				</template>
				<v-list dense class="py-1">
					<v-list-item class="pa-1 py-0">
						<v-tooltip right z-index="200">
							<template #activator="{ on }">
								<v-btn
									v-on="on"
									@click="$adminSurf('openHistory', item, 'assignment')" icon
								>
									<v-icon>mdi-history</v-icon>
								</v-btn>
							</template>
							<span class="caption">History</span>
						</v-tooltip>
					</v-list-item>
					<v-list-item class="pa-1 py-0">
						<v-tooltip right z-index="200">
							<template #activator="{ on }">
								<v-btn
									v-on="on"
									@click="$emit('delete-assignment', item)" icon
									color="error"
								>
									<v-icon>mdi-delete</v-icon>
								</v-btn>
							</template>
							<span class="caption">Delete</span>
						</v-tooltip>
					</v-list-item>
				</v-list>
			</v-menu>
		</template>

	</infinite-data-table>
</template>

<script>
import BillableJSON from '../../../json/billable.json'
import BillablePercentageBar from '../billable/BillablePercentageBar'
import InfiniteDataTable from '../../InfiniteDataTable'
import DataTable from '../../DataTable.vue'

export default {
	components: {
		BillablePercentageBar,
		InfiniteDataTable,
		DataTable,
	},
	props: {
		items: Array,
		value: Array,
		totalItems: Number,
		billables: Array,
		billableLines: Array,
		customerProjects: Array,
		users: Array,

		infinite: Boolean,
		readonly: Boolean,

		hiddenHeaders: Array,
		disabledHeaders: { type: Array, default: () => [] },
	},
	data: () => ({
		headers: [
			{ text: "Bluetag", value: "userBlueTag", },
			{ text: "Billable Code", value: "billableCode", },
			{ text: "Role", value: "billableLineRole", },
			{ text: "Project", value: "customerProjectName", align: 'center',  },
			{ text: "Quantity", value: "quantity", align: 'center', width: '100', },
			{ text: "Billable", value: "isBillable", align: 'center', width: '100', },
			{ text: "Status", value: "timeStatus", align: 'center', },
			{ text: "Billing", value: "status", align: 'center', },
			{ text: "Start Date", value: "startDate", align: 'center', width: '120', },
			{ text: "End Date", value: "endDate", align: 'center', width: '120', },
			{ text: "Calendars", value: "calendars", cellClass: 'px-0', width: '110', },

			{ text: "Actual Working Hours", value: "actualWorkingHours", align: 'center', width: '60px', sortable: false, },
			{ text: "Total Working Hours", value: "totalWorkingHours", align: 'center', width: '60px', sortable: false, },
			{ text: "Months Base 168 hrs", value: "months", align: 'center', width: '60px', sortable: false, },
			{ text: "Weeks Base 168 hrs", value: "weeks", align: 'center', width: '60px', sortable: false, },
			{ text: "", value: "actions", align: 'center', width: 1, sortable: false, },
		],
		projectTypes: [],
	}),
	created() {
		this.projectTypes = BillableJSON.projectTypes
	},
	computed: {
		shownHeaders() {
			return this.headers.filter(h => !this.hiddenHeaders?.includes(h.value))
		},
	},
}
</script>

<style lang="scss" scoped>
.admin-project {
	max-width: 150px;
}
.dot {
	display: inline-block;
	width: 10px; height: 10px;
	border-radius: 50%;
}

.hide-input ::v-deep input{
	min-width: unset;
}
.header-span{
	font-weight: 400;
}

::v-deep {
	.v-data-table__wrapper {
		max-height: calc(100vh - 100px);
		overflow: scroll;
	}
	td, th {
		padding: 0 6px !important;
	}
	td:first-child, th:first-child {
		padding-left: 14px !important;
	}
	.v-list-item{
		min-height: 0 !important;
	}
	.v-input__slot {
		padding: 0 4px !important;
	}
	.v-autocomplete.v-select.v-input--is-focused input {
		min-width: unset;
	}
	.v-input__append-inner{
		display: none;
	}
	.center-input input{
		text-align: center;
	}
	.currency-btn::before{
		opacity: .08 !important;
	}
	.date-picker .v-input{
		height: 100%;
		align-items: center;
		font-size: 14px;
		background: transparent;
	}
}
</style>