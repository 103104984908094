<template>
	<v-dialog
		:fullscreen="$vuetify.breakpoint.xsOnly" persistent
		v-model="dialog" v-if="dialog" width="650" style="border-radius: 10px" content-class="dialog-content"
	>
		<div class="info-container">
			<v-btn @click="close" class="remind-later cursor-pointer" color="primary" small>Remind Me Later</v-btn>
			<h1> What's new in Blue Surf </h1>

			<div class="changes">
				<div class="change" v-for="(change, ix) in changeLog.changes" :key="ix">
					<div class="icon-container">
						<v-img :src="require(`../assets/infographic/${ix}.png`)" ></v-img>
					</div>
					<div class="description">
						<span>{{change.title}}</span>
						<p v-html="change.description">
						</p>
					</div>
				</div>
			</div>
			<div class="mt-2">
				<v-btn @click="gotIt" color="#00baff" style="width: 60%; color: white; margin-right: -70px" height="50">
					<h2>Got It!</h2>
				</v-btn>
				<div class="float-right mt-7 mr-5">{{changeLog.version}}</div>
			</div>
		</div>
	</v-dialog>
</template>

<script>
export default {
	data(){
		return {
      dialog: false,
			changeLog: {},
			resolve: () => {},
		}
	},
	methods: {
		open(changeLog){
			this.changeLog = changeLog
			this.dialog = true
			return new Promise((resolve) => {
				this.resolve = resolve
			})
		},
		getImage: (path) => __dirname + path,
		gotIt(){
			this.$http.post('api/user/current/stopShowInfographic')
				.then(() => {
					this.dialog = false
					this.resolve(false)
				})
		},
		close(){
			this.dialog = false
			this.resolve(true)
		},
	}
}
</script>

<style lang="scss" scoped>

::v-deep .dialog-content{
	border-radius: 10px;
	overflow: hidden;
}
::v-deep .dialog-content::-webkit-scrollbar {
	width: 6px;
	background: var(--v-scroll-lighten2);
	border-radius: 0 20px 20px 0;
}
::v-deep .dialog-content::-webkit-scrollbar-thumb {
	border-radius: 20px;
	background: #AED6F1;
}
.info-container{
	padding: 30px 5px;
	background: #244474;
	text-align: center;
	color: white;
	position: relative;
	overflow: hidden;
}
.close-icon{
	margin-bottom: -36px;
	padding-right: 10px;
	text-align: right;
}
.change{
	display: grid;
	grid-template-columns: 15% 85%;
	padding: 0 20px;
	margin: 10px 0;
	gap: 20px;

	.icon-container{
		display: flex;
		align-items: center;
		padding-right: 10px;
	}
	.description{
		text-align: justify;
		span{
			font-size: 1.3em;
			font-weight: 500;
		}
		p{
			color: #eee;
			font-weight: 400;
		}
	}
}
.changes{
	padding: 0 50px;
	overflow: scroll;
	max-height: calc(100vh - 240px);
}
.changes::-webkit-scrollbar{ background: transparent; }
.changes::-webkit-scrollbar-corner{ background: transparent; }
.changes::-webkit-scrollbar-thumb { background: #aaa; }
.remind-later{
	position: absolute;
	right: 5px; top: 10px;
	font-size: .7em;
}
</style>