var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('h2',{staticClass:"mb-3"},[_vm._v(" Approvals "),_c('v-btn',{attrs:{"disabled":!_vm.$parent.canEditProject,"x-small":"","fab":"","depressed":"","color":"primary"},on:{"click":function($event){return _vm.editApproval(null)}}},[_c('v-icon',{attrs:{"dark":""}},[_vm._v("add")])],1)],1),_c('data-table',{attrs:{"headers":_vm.headers,"items":_vm.$parent.config.approvals},scopedSlots:_vm._u([{key:"item.approverBlueTag",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex align-center",staticStyle:{"gap":"5px"}},[_c('user-avatar',{attrs:{"user-id":item.approverId,"size":38,"hide-loading":""}}),_c('div',[_c('b',[_vm._v(_vm._s(item.approverBlueTag))]),_c('div',{staticClass:"mt-n1"},[_vm._v(_vm._s(item.approverFullName))])])],1)]}},{key:"item.role",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.getRole(item.approverId))+" ")]}},{key:"item.statusName",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{attrs:{"color":item.statusColor,"dark":"","label":"","small":""}},[_c('v-icon',{attrs:{"left":"","color":"white","small":""}},[_vm._v(" "+_vm._s(item.statusIconClass)+" ")]),_vm._v(" "+_vm._s(item.statusName)+" ")],1)]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex"},[_c('v-btn',{attrs:{"x-small":"","fab":"","depressed":"","outlined":"","color":"primary"},on:{"click":function($event){return _vm.deleteApproval(item)}}},[_c('v-icon',{attrs:{"dark":""}},[_vm._v("delete")])],1),_c('v-btn',{staticClass:"mr-2",attrs:{"x-small":"","fab":"","depressed":"","color":"primary"},on:{"click":function($event){return _vm.editApproval(item)}}},[_c('v-icon',{attrs:{"dark":""}},[_vm._v("edit")])],1)],1)]}}],null,true)}),_c('edit-approval-dialog',{ref:"editApproval",attrs:{"members":_vm.$parent.members,"status":_vm.$parent.config.workItemStatuses,"project-id":_vm.$parent.id,"types":_vm.shownTypes}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }