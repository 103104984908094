<template>
	<v-dialog v-model="showDialog" persistent width="540">
		<v-card>
			<v-card-title>Acceptance Requirement</v-card-title>
			<v-card-text>
				<v-form @submit.prevent="save">
					<div>
						<label>Summary</label>
						<v-text-field
							v-model="item.name"
							placeholder="Summary"
							class="summary"
							large outlined
						/>
					</div>
					<div>
						<label>Description</label>
						<rich-editor
							placeholder="Description"
							class="summary"
							v-model="item.description"
							:rows="3"
							outlined hide-details
							:user-suggestions="users"
						/>
					</div>
				</v-form>
			</v-card-text>
			<v-card-actions class="justify-end pb-3 mr-2">
				<v-btn @click="showDialog = false" color="primary" text>
					Close
				</v-btn>
				<v-btn @click="save" color="primary" :disabled="!item.name">
					Create
				</v-btn>
			</v-card-actions>
		</v-card>
	</v-dialog>
</template>

<script>
export default {
	props: {
		users: Array
	},
	data: () => ({
		showDialog: false,
		item: {
			name: '',
			description: '',
		},
		resolve: null,
	}),
	methods: {
		open(item){
			this.item = {
				name: '',
				description: '',
			}
			if(item) this.item = _.cloneDeep(item)
			this.showDialog = true
			return new Promise(res => {
				this.resolve = res
			})
		},
		save(){
			if(!this.item.name) return
			this.showDialog = false
			this.resolve(this.item)
		},
	},
}
</script>

<style lang="scss" scoped>

</style>