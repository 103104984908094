<template>
  <div class="smart-search-wrapper" >
    <v-card class="smart-search" v-click-outside="handleClickOutside" v-show="showSmartSearch && $parent.search.length >= 2" max-height="500" scroll>
      <v-card-text>
        <h4 v-if="tags.length">Tags: </h4>
        <div class="px-1 py-2" v-if="tags.length">
          <v-chip
            v-for="item in tags"
            :key="item.id" class="cursor-pointer mr-1 mb-1"
            @click="change('tags', item.id)"
            dark small :color="item.id !== null ? item.color : 'white'"
            :style="`color: ${isBlackText(item.color) ? 'white' : 'black'}`"
          >
            {{item.name}}
          </v-chip>
        </div>
        <h4 v-if="sprints.length">Sprints: </h4>
        <div v-if="sprints.length" class="px-1 py-2">
          <v-chip
          v-for="item in sprints"
          :key="item.id" class="cursor-pointer mr-1 mb-1 accent--lighten"
          @click="change('sprints', item.id)"
          dark small label :color="item.id !== null ? item.color : 'white'"
          :class="item.id !== null ? '' : 'transparent'"
          :style="`color: ${$vuetify.theme.isDark ? 'white' : 'black'}`"
          >
          <span 
          >
            {{ item.name }}
          </span>
            </v-chip>
        </div>
        <h4 v-if="priorities.length">Priorities: </h4>  
        <v-list-item
          v-for="item in priorities"
          :key="item.id" class="pl-0 cursor-pointer"
          @click="change('priorities', item.value)"
        >
          <v-list-item-content>
            <v-list-item-title>
              <v-icon :color="item.color" class="mr-2">{{item.iconClass}}</v-icon>
              {{item.displayName}}
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <h4 v-if="users.length">Reported By: </h4>
        <v-list-item
          v-for="(user, ix) in users"
          :key="ix" class="pl-0 cursor-pointer"
          @click="change('reportedBy', user.id)"
        >
          <v-list-item-avatar class="font-weight-light white--text ma-0 mr-1">
            <user-avatar :showCard="false" :userId="user.id" hideLoading :size="30"></user-avatar>
          </v-list-item-avatar>  
          <v-list-item-content>
            <v-list-item-title>
              {{user.blueTag || user.fullName}}
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <h4 v-if="users.length">Assigned To: </h4>
        <v-list-item  
          v-for="user in users"
          :key="user.id" class="pl-0 cursor-pointer"
          @click="change('assignedTo', user.id)"
        >
          <v-list-item-avatar class="font-weight-light white--text ma-0 mr-1">
            <user-avatar :showCard="false" :userId="user.id" hideLoading :size="30"></user-avatar>
          </v-list-item-avatar>  
          <v-list-item-content>
            <v-list-item-title>
              {{user.blueTag || user.fullName}}
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item  
          v-for="wi in workItems"
          :key="wi.id" class="pl-0 cursor-pointer"
          @click="$parent.openWorkItemDetailsDialog(wi.id)"
        >
          <v-card-title  class="py-1 pl-1 pr-3">
            <v-icon :color="wi.typeColor">{{wi.typeIconClass}}</v-icon>
          </v-card-title>
          <v-list-item-content>
            <v-list-item-title>
              {{ wi.name }}
              <br>
              {{wi.code}}
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
export default {
  props: {
    change: { type: Function, default: () => {} },
    showSmartSearch: { type: Boolean, default: false },
    closeOutside: { type: Boolean, default: false },
    tags: { type: Array, default: () => {[]} },
    workItems: { type: Array, default: () => {[]} },
    priorities: { type: Array, default: () => {[]} },
    users: { type: Array, default: () => {[]} },
    sprints: { type: Array, default: () => {[]} },
  },
  methods: {
    handleClickOutside(e){
			if(this.closeOutside && this.showSmartSearch){
				this.$emit('close', e)
			}
		},
  }
}
</script>

<style >
.smart-search {
  z-index: 2;
  position: fixed;
  overflow-y: auto !important;
  background: var(--v-card-base);
  width: 317px;
}
.smart-search-wrapper {
  z-index: 120;
  position: relative;
}
</style>