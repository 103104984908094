<template>
	<div class="fill-height new-back">
		<v-container fluid>
			<data-table
				@click:row="edit"
				:headers="headers"
				:items="items"
				:item-class="() => 'cursor-pointer'"
			>
				<template #[`item.active`]="{ item }">
					<v-icon>{{ item.active ? 'check' : 'close' }}</v-icon>
				</template>
				<template #[`item.displayInOnePager`]="{ item }">
					<v-icon>{{ item.displayInOnePager ? 'check' : 'close' }}</v-icon>
				</template>
				<template #[`item.actions`]="{ item }">
					<v-menu left offset-x z-index="200">
						<template #activator="{ on }">
							<v-btn v-on="on" icon><v-icon>mdi-dots-vertical</v-icon></v-btn>
						</template>
						<v-list dense class="py-1">
							<v-list-item class="pa-1 py-0">
								<v-tooltip right z-index="200">
									<template #activator="{ on }">
										<v-btn v-on="on" color="error" @click="deleteType(item.id)" text small>
											<v-icon>delete</v-icon>
										</v-btn>
									</template>
									<span>Delete</span>
								</v-tooltip>
							</v-list-item>
						</v-list>
					</v-menu>
				</template>
			</data-table>
			<v-btn
				v-if="permissions.add"
				@click="editionType = {}"
				class="mt-n3" color="primary"
				small fab
			>
				<v-icon>add</v-icon>
			</v-btn>
		</v-container>

		<v-dialog v-if="!!editionType" :value="!!editionType" width="600" persistent>
			<v-card color="newDesignBackground">
				<v-card-title>
					<h3>{{ !!editionType.id ? 'New Project Type' : 'Edit Project Type' }}</h3>
				</v-card-title>
				<v-card-text>
					<v-form ref="form" class="mt-1">
						<div class="d-flex justify-space-between">
							<div>
								<label>Name*</label>
								<v-text-field
									v-model="editionType.name"
									:rules="[v => !!v || 'Name is required']"
									outlined dense hide-details
								/>
							</div>
							<div class="d-flex justify-space-between" style="gap: 20px">
								<div class="text-center">
									<label>Active</label> <br>
									<v-checkbox
										v-model="editionType.active"
										color="primary" class="mt-0 pl-2 d-inline-block"
										hide-details
									/>
								</div>
								<div class="text-center">
									<label>One Pagers</label> <br>
									<v-checkbox
										v-model="editionType.displayInOnePager"
										color="primary" class="mt-0 pl-2 d-inline-block"
										hide-details
									/>
								</div>
							</div>
						</div>
						<div class="mt-2">
							<label>Description</label>
							<v-textarea
								v-model="editionType.description"
								:rows="3"
								outlined dense hide-details
							/>
						</div>
					</v-form>
				</v-card-text>
				<v-card-actions class="justify-end pb-4">
					<v-btn @click="editionType = null" color="primary" outlined> Cancel </v-btn>
					<v-btn @click="save" color="primary"> Save </v-btn>
				</v-card-actions>
			</v-card>
		</v-dialog>
	</div>
</template>

<script>
export default {
	data: () => ({
		items: [],
		headers: [
			{ text: 'Name', value: 'name' },
			{ text: 'Description', value: 'description' },
			{ text: 'Active', value: 'active', align: 'center' },
			{ text: 'One Pagers', value: 'displayInOnePager', align: 'center' },
			{ text: '', value: 'actions', sortable: false, width: '1' },
		],
		permissions: {
			add: false,
			edit: false,
			delete: false,
		},

		showDialog: false,
		editionType: null,
	}),
	created(){
    this.$security.hasRequiredPermission('add')
      .then(res => this.permissions.add = res)
    this.$q.log(6, 51, 3, 'NA')
		this.init()
	},	
	methods: {
		init(){
			this.$http.get('api/project/types')
				.then(res => {
					this.items = res.data
				})
				.catch(err => {
					console.error(err)
					this.$root.error('Error loading project types')
				})
		},
		edit(item){
			this.editionType = structuredClone(item)
		},
		save(){
			if(!this.$refs.form.validate()) return
			this.$http.post('api/project/type', this.editionType)
				.then(res => {
					this.editionType = null
					this.init()
					this.$root.success('Project type saved')
				})
				.catch(err => {
					console.error(err)
					this.$root.error('Error saving project type')
				})
		},
		deleteType(id){
			this.$openConfirmDialog({
				title: 'Delete Project Type',
				description: 'Are you sure you want to delete this project type?',
			})
				.then(confirmed => {
					if(!confirmed) return
					
					this.$http.delete('api/project/type/' + id)
						.then(() => {
							this.init()
							this.$root.success('Project type deleted')
						})
						.catch(err => {
							console.error(err)
							this.$root.error('Error deleting project type')
						})
				})
		},
	},
}
</script>

<style lang="scss" scoped>

</style>