<template>
  <div v-if="!selectedSprint">
    <data-table
      :headers="headers" :items="sprints"
      @click:row="openDetails"
    >
      <template #[`item.name`]="{ item }">
        <v-chip
					:style="`color: ${isBlackText(item.color) ? 'white' : 'black'}`"
          :color="item.color ? item.color : '#FFFFFF'"
          small dark
        >
          {{ item.name }}
        </v-chip>
      </template>
      <template #[`item.startDate`]="{ item }">
        {{ item.startDate | formatDate }}
      </template>
      <template #[`item.endDate`]="{ item }">
        {{ item.endDate | formatDate }}
      </template>
      <template #[`item.criticalPath`]="{ item }">
        {{ item.criticalPath }}%
      </template>
      <template #[`item.status`]="{ item }">
        <v-tooltip :disabled="!item.canceled" top>
          <template #activator="{ on }">
            <span v-on="on">{{ item.statusName }}</span>
          </template>
          <span>{{ item.cancelMessage }}</span>
        </v-tooltip>
      </template>
      <template #[`item.leadTime`]="{ item }">
        {{ item.leadTime | minutesToTime }}
      </template>
      <template #[`item.actions`]="{ item }">
				<v-menu v-if="$parent.canEditProject && !item.canceled" left offset-x>
					<template #activator="{ on }">
						<v-btn v-on="on" icon><v-icon>mdi-dots-vertical</v-icon></v-btn>
					</template>
					<v-list dense class="py-1" color="high-back">
						<v-list-item class="pa-0">
							<v-tooltip right z-index="200">
								<template #activator="{ on }">
									<v-btn v-on="on" @click="cancel(item)" color="error" text small>
										<v-icon>mdi-cancel</v-icon>
									</v-btn>
								</template>
								<span class="caption">Cancel</span>
							</v-tooltip>
						</v-list-item>
					</v-list>
				</v-menu>
      </template>
    </data-table>
    <blue-confirm-dialog ref="cancelDialog" persistent>
      <template #text>
        <v-text-field
          v-model="cancelMessage"
          label="Cancellation Comment"
          outlined hide-details
          :rules="[$rules.required]"
        />
      </template>
    </blue-confirm-dialog>
  </div>
  <div v-else>
    <template>
      <h2>
        <v-btn @click="selectedSprint = null" icon>
          <v-icon>mdi-chevron-left</v-icon>
        </v-btn>
        Sprint Backlog
      </h2>
      <v-form ref="form" class="mt-5">
        <v-row dense>
          <v-col :cols="3">
            <v-text-field
              v-model="selectedSprint.name"
              :disabled="selectedSprint.canceled"
              :readonly="!isEmployee"
              :rules="[$rules.required]"
              outlined dense
              label="Name*"
            />
          </v-col>
          <v-col :cols="3">
            <q-date-picker
              v-model="selectedSprint.startDate"
              :max="selectedSprint.endDate"
              :rules="[checkValidDateForSprints]"
              :disabled="selectedSprint.canceled"
              :readonly="!isEmployee"
              inner dense outlined
              label="Start Date*"
            />
          </v-col>
          <v-col :cols="3">
            <q-date-picker
              v-model="selectedSprint.endDate"
              :min="selectedSprint.startDate"
              :rules="[checkValidDateForSprints]"
              :disabled="selectedSprint.canceled"
              :readonly="!isEmployee"
              inner outlined dense
              label="End Date*"
            />
          </v-col>
          <v-col :cols="3">
            <v-text-field 
              v-model="selectedSprint.criticalPath"
              :disabled="selectedSprint.canceled"
              :readonly="!isEmployee"
              :min="0" :max="100"
              @change="v => selectedSprint.criticalPath = trunc(v, 0, 100)"
              :rules="[$rules.requiredNumber, $rules.notNegative]"
              outlined dense
              type="number" 
              class="critical-path-input"
              label="% Bug Fixing*"
            />
          </v-col>
          <v-col :cols="3">
            <v-text-field 
              v-model="selectedSprint.devsQuantity"
              :disabled="selectedSprint.canceled"
              :readonly="!isEmployee"
              :rules="[$rules.requiredNumber, $rules.notNegative]"
              :min="0"
              outlined dense
              type="number"
              label="Devs Quantity*"
            />
          </v-col>
          <v-col :cols="6">
            <v-textarea
              v-model="selectedSprint.goal"
              :disabled="selectedSprint.canceled"
              :readonly="!isEmployee"
              :rules="[$rules.required]"
              outlined dense auto-grow
              label="Goal*"
              rows="1"
            />
          </v-col>
          <v-col :cols="3">
            <v-menu
              open-on-click bottom offset-x
              :close-on-content-click="false"
              :disabled="selectedSprint.canceled || !isEmployee"
              z-index="200"
            >
              <template v-slot:activator="{ on }">
                <v-btn
                  v-on="$parent.canEditProject ? on : null"
                  :color="selectedSprint.color" outlined
                  :dark="$vuetify.theme.isDark ? !isBlackText(selectedSprint.color) : isBlackText(selectedSprint.color)"
                >
                  <v-icon>invert_colors</v-icon>
                  Color
                  <v-icon right>mdi-chevron-right</v-icon>
                </v-btn>
              </template>
              <color-picker v-model="selectedSprint.color" sprints miniVersion/>
            </v-menu>
          </v-col>
        </v-row>
        <div v-if="!selectedSprint.canceled && isEmployee" class="text-right mt-n5">
          <v-btn @click="save(null)" color="primary">Save Changes</v-btn>
        </div>
      </v-form>
    </template>
    <div v-if="selectedSprint.id">
      <div class="text-right mt-15">
        <toggle-view v-model="workItemView" :items="workItemViews" dense/>
      </div>
  
      <sprint-work-item-selection
        v-if="workItemView === 0"
        ref="workItemSelection"
        :sprint="selectedSprint"
        :id="id" :project-id="projectId"
        :is-employee="isEmployee"
      />
      <sprint-work-item-gantt
        v-else
        ref="workItemGantt"
        :sprint="selectedSprint"
        :id="id" :project-id="projectId"
      />
    </div>

  </div>
</template>

<script>
import moment from "moment";
import SprintWorkItemSelection from "../../components/sprint/SprintWorkItemSelection"
import SprintWorkItemGantt from "../../components/sprint/SprintWorkItemGantt"

export default {
	props: {
		id: { type: String, required: true },
    isEmployee: { type: Boolean },
    projectId: { type: String },
    socket: { type: Object, default: () => ({}) },
	},
  components: {
    SprintWorkItemSelection,
    SprintWorkItemGantt,
  },
  data: () => ({
    headers: [
      { text: "Name", value: "name" },
      { text: "Start Date", value: "startDate", width: "130px" },
      { text: "End Date", value: "endDate", width: "130px" },
      { text: "Goal", value: "goal" },
      { text: "% for Bug Fixing", value: "criticalPath", align: "center", width: "130px" },
      { text: "Status", value: "status", align: "center", width: "100px" },
      { text: "Lead Time", value: "leadTime", align: "center", width: "100px" },
      { text: "", value: "actions", align: "center", width: "1", sortable: false },
    ],
    workItemView: 0,
    workItemViews: [
      { text: 'Sprint Backlog', iconClass: 'mdi-clipboard-list-outline' },
      { text: 'Sprint Plan', iconClass: 'mdi-chart-gantt' },
    ],
		sprints: [],
    selectedSprint: null,
    cancelMessage: "",
  }),
	created() {
		this.init()
    this.setUserPreferences("SprintList", "showCol")
	},
  computed: {
    sprintsDates() {
      if(!this.sprints.length) return {}
      return this.sprints.filter(sprint => sprint.startDate && sprint.endDate)
    },
    canAdd() {
      return !this.selectedSprint || !!this.selectedSprint?.id
    },
  },
  methods: {
		init() {
      this.$http.get(`api/project/${this.id}/sprint?leadTime=true`)
        .then(res => {
          this.sprints = res.data
        })
        .catch(err => {
          console.error(err)
          this.$root.error('Failed to load sprints')
        })
		},
    save(sprint) {
      if(!sprint) sprint = this.selectedSprint

      if(this.$refs.form && !this.$refs.form.validate()) return
      sprint.projectId = this.projectId
      sprint.criticalPath = parseFloat(parseFloat(sprint.criticalPath).toFixed(2)) || sprint.criticalPath
      return this.$q.save({
        api: `/api/projectSprint`,
        data: sprint,
        successMsg: "Sprint saved.",
        afterSuccessUrl: null,
        errorMsg: "Cannot save sprint.",
        afterErrorUrl: null,
      })
        .then(res => {
          this.$root.success('Sprint saved')
          this.init()
          this.$set(sprint, 'id', res.data.id)
          if(this.$refs.workItemGantt)
            this.$refs.workItemGantt.init()
          this.$emit('update')
        })
        .catch(err => {
          console.error(err)
          this.$root.error('Failed to save sprint')
        })
    },
    cancel(item) {
      this.$refs.cancelDialog.open({
        title: 'Cancel Sprint',
      })
        .then(res => {
          if(!res) return

          if(!this.cancelMessage) {
            this.$root.error('Cancellation comment is mandatory')
            this.cancel(item)
            return
          }
          
          item = item
          item.canceled = true
          item.cancelMessage = this.cancelMessage

          this.save(item)
            .then(() => {
              this.$root.success('Sprint cancelled')
              this.init()
              this.$refs.cancelDialog.close()
            })
        })
    },

    onAddButton() {
      if(!this.selectedSprint)
        this.openDetails({})
      else
        this.$refs.workItemSelection.openWorkItemCreateDialog()
    },
    openDetails(sprint) {
      this.selectedSprint = structuredClone(sprint)
      this.$nextTick(() => {
        if(this.$refs.workItemSelection)
          this.$refs.workItemSelection.fetchWorkItems()
      })
    },

    getSprintStatus(sprint) {
      let date = moment().format();
      const status = {
        active: "Active",
        notStarted: "Not Started",
        cancelled: "Canceled",
        closed: "Closed",
      };
      if (sprint.canceled) return status.cancelled;
      else if (!sprint.startDate || !sprint.endDate)
        return "Dates must be defined";
      else if (moment(date).isBefore(sprint.startDate))
        return status.notStarted;
      else if (moment(date).isAfter(sprint.endDate)) return status.closed;
      else if (
        moment(date).isBetween(sprint.startDate, sprint.endDate, "day", [])
      )
        return status.active;
      else {
        return "";
      }
    },
    checkValidDateForSprints(date) {
      if(!date) return 'The date is mandatory'
      if(!this.sprintsDates.length) return true
      let validDate = true
      this.sprintsDates
        .filter(sprint => sprint.id !== this.selectedSprint.id)
        .forEach(sprint => {
          if(!sprint.canceled && moment(date).isBetween(sprint.startDate, sprint.endDate, 'day', [])) {
            validDate = false
          }
        })
      return validDate || 'The date must not interfere other sprints'
    },
  },
};
</script>

<style lang="scss" scoped>

</style>