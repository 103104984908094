<template>
	<div class="pa-5">
		<v-row>
	
			<v-dialog :fullscreen="$vuetify.breakpoint.xsOnly" scrollable persistent 
				v-model="addingMember" :width="450" v-if="addingMember" @keydown.esc="addingMember = false"
			>
				<v-card class="add-new-card">
					<v-card-title>
						New Member
					</v-card-title>
					<v-card-text>
						<v-autocomplete label="User" :items="shownUsers"
							:style="{paddingTop: '10px'}"
							item-value="id" item-text="blueTag" 
							:filter="(item, queryText, itemText) => normalizeIncludes(item.blueTag + ' ' + item.fullName, queryText)"
							v-model="newMember.userId" outlined hide-details :disabled="newMember.id"
							dense
						>
							<template v-slot:item="{ item, attrs }">
								<v-list-item-content class="pa-0">
									<v-list-item class="pa-0">
										<v-list-item-avatar color="indigo" class="headline font-weight-light white--text">
											{{ (item.blueTag || '-').charAt(0) }}
										</v-list-item-avatar>
										<v-list-item-content>
											<v-list-item-title v-text="item.blueTag"></v-list-item-title>
											<v-list-item-subtitle v-text="item.fullName"></v-list-item-subtitle>
										</v-list-item-content>
									</v-list-item>
								</v-list-item-content>
							</template>
							<template v-slot:selection="{ attrs, item, parent, selected }">
								{{ item.blueTag }}
							</template>
						</v-autocomplete>
						<br>
						<v-select label="Role" :items="roles"
							item-value="value" item-text="name" 
							v-model="newMember.role" outlined hide-details
							dense
						/>
					</v-card-text>
					<v-card-actions class="d-flex justify-space-between">
						<v-btn v-show="newMember.id" @click="deleteMember" color="error">Delete</v-btn>
						<span>
							<v-btn
								class="mr-2"
								@click="cancelEditMember"
							>
								Cancel
							</v-btn>
							<v-btn
								@click="saveNewMember"
								color="primary"
							>
								Confirm
							</v-btn>
						</span>
					</v-card-actions>
				</v-card>
			</v-dialog>
	
			<div v-if="hasDetailedPermission" style="width: calc(100vw - 200px); position: sticky; left: 120px">
				<div class="d-flex mt-3" style="justify-content: flex-end;">
					<toggle-view v-model="view" :items="views" class="mr-3" />
					<v-btn @click="openSettings" icon>
						<v-icon>mdi-cog-outline</v-icon>
					</v-btn>
				</div>
			</div>
	
			<v-col cols="12" v-show="!showHistory" :class="{'mt-n10': hasDetailedPermission}">
				<h2 class="mb-1">Core Team</h2>
				<div v-show="!coreTeam.length">No items to show</div>
				<draggable-sort
					v-model="coreTeam"
					itemKey="id"
					space-evenly rowClass="d-flex draggable-row"
				>
					<template v-slot="member">
						<TeamMemberCard
							:item="member"
							@click="openMemberDetails(member)"
							is-core-member
						/>
					</template>
				</draggable-sort>
				<h2 class="mt-8 mb-2">
					Hero Support
					<v-btn
						@click="openAddMember(0)"
						:disabled="!canEditProject || !isInternal"
						color="primary" class="add-btn"
						fab x-small depressed
					>
						<v-icon color="white">mdi-plus</v-icon>
					</v-btn>
				</h2>
				<div v-show="!heroSupport.length">No items to show</div>
				<draggable-sort
					v-model="heroSupport"
					itemKey="id"
					space-evenly rowClass="d-flex draggable-row"
				>
					<template v-slot="member">
						<TeamMemberCard
							:item="member"
							@click="editMember(member)"
							is-hero-member
						/>
					</template>
				</draggable-sort>
				<h2 class="mt-8 mb-2">
					Stakeholders
					<v-btn
						@click="openAddMember(1)"
						fab x-small depressed
						color="primary" class="add-btn"
						:disabled="!canEditProject || !isInternal"
					>
						<v-icon color="white">mdi-plus</v-icon>
					</v-btn>
				</h2>
				<div v-show="!stakeholders.length">No items to show</div>
				<br>
				<draggable-sort
					v-model="stakeholders"
					itemKey="id"
					space-evenly rowClass="d-flex draggable-row"
				>
					<template v-slot="member">
						<TeamMemberCard
							:item="member"
							@click="editMember(member)"
							is-stakeholder
						/>
					</template>
				</draggable-sort>
			</v-col>
			<v-col v-show="showHistory">
				<v-data-table
					@click:row="openMemberDetails"
					:headers="
						headers.filter((x) => x.view === undefined || x.view == view)
					"
					:items="membersBillables.filter((x) => !x.isCurrent)"
					class="mt-2"
					:item-class="() => 'cursor-pointer'"
					:page.sync="page"
					:items-per-page="itemsPerPage"
					hide-default-footer
				>
					<template #[`item.user`]="{ item }">
						<div class="d-flex my-3">
							<user-avatar :userId="item.userId" hideLoading :size="45"/>
							<div class="ml-2">
								<v-list-item-title v-if="item" v-text="item.userBlueTag"></v-list-item-title>
								<v-list-item-subtitle v-if="item" v-text="item.userFullName"></v-list-item-subtitle>
							</div>
						</div>
					</template>
					<template #[`item.startDate`]="{ item }">
						{{ item.startDate | formatDate }}
					</template>
					<template #[`item.endDate`]="{ item }">
						<span v-if="item.isCurrent">Present</span>
						<span v-else>{{ item.endDate | formatDate }}</span>
					</template>
					<template #[`item.customerPerception`]="{ item }">
						<v-icon
							:color="semaphoreColors[item.customerPerception].color"
						>
							{{ semaphoreColors[item.customerPerception].icon }}
						</v-icon>
					</template>
					<template #[`item.requirements`]="{ item }">
						<v-chip
							v-if="item.requirements.some((x) => !x.checked)"
							color="error"
							outlined
						>
							{{item.requirements.filter((x) => !x.checked).length}}
							Pending
						</v-chip>
						<v-chip v-else color="success" outlined>Completed</v-chip>
					</template>
					<template #[`item.nucleiHappiness`]="{ item }">
						<v-icon
							:color="semaphoreColors[item.nucleiHappiness].color"
						>
							{{ semaphoreColors[item.nucleiHappiness].icon }}
						</v-icon>
					</template>
	
					<template #footer="{ props }">
						<div class="d-flex justify-space-between mt-4" style="align-items: center;">
							<div class="d-flex" style="align-items: center;">
								<v-autocomplete
									outlined dense hide-details
									style="width: 70px"
									class="rounded-lg overflow-hidden"
									width="20"
									:items="[5, 10, 15, 20]"
									v-model="itemsPerPage"
									height="20"
								></v-autocomplete>
								<div class="ml-2 body-1" style="font-size: 20px;">
									Entries per page
								</div>
							</div>
							<div class="d-flex align-center">
								<span class="mr-2">
									{{ props.pagination.pageStart }} - {{ props.pagination.pageStop }} / {{ props.pagination.itemsLength }}
								</span>
								<v-pagination
									v-model="page"
									:length="pageCount"
								></v-pagination>
							</div>
						</div>
					</template>
				</v-data-table>
			</v-col>
			<nuclei-requirements-dialog
				ref="settingsDialog"
			/>
			<nuclei-member-dialog
				ref="nucleiMemberDialog"
				:id="id"
			/>
		</v-row>
	</div>
</template>

<script>
import DraggableSort from '../../components/DraggableSort'
import NucleiRequirementsDialog from '../../dialogs/NucleiRequirementsDialog.vue';
import TeamMemberCard from '../../components/TeamMemberCard.vue'
import NucleiMemberDialog from '../../dialogs/NucleiMemberDialog.vue';
import moment from 'moment'
import _ from "lodash"
export default {
	props: ["id", 'projectName', 'canEditProject', 'isInternal'],
	components: {
		DraggableSort,
		TeamMemberCard,
		NucleiRequirementsDialog,
		NucleiMemberDialog,
	},
	data() {
		return {
			view: 0,
			views: [
				{ iconClass: "mdi-account-group", text: "Team" },
				{ iconClass: "mdi-history", text: "Team Details" },
			],
			page: 1,
      pageCount: 0,
      itemsPerPage: 10,
			members: [],
			addingMember: false,
			roles: [
				{ name: 'Business Analyst', value: 0 },
				{ name: 'Delivery Manager', value: 1 },
				{ name: 'Developer ', value: 2 },
				{ name: 'Key User', value: 3 },
				{ name: 'Product Owner', value: 4 },
				{ name: 'Program Manager', value: 5 },
				{ name: 'Project Manager', value: 6 },
				{ name: 'QA', value: 7 },
				{ name: 'Solutions Architect', value: 8 },
				{ name: 'Stakeholder', value: 9 },
				{ name: 'UX/UI', value: 10 },
				{ name: 'Other', value: 11 },
			],
			headers: [
				{ text: "Name", value: "user", view: 1 },
				{ text: "Start Date", value: "startDate" },
				{ text: "End Date", value: "endDate", view: 1 },
				{ text: "Role", value: "assignment.billableLineRole" },
				{
					text: "C. Perception",
					value: "customerPerception",
					align: "center",
					sortable: false,
				},
				{
					text: "Requirements",
					value: "requirements",
					align: "center",
					sortable: false,
				},
				{
					text: "Nuclei Happiness",
					value: "nucleiHappiness",
					align: "center",
					sortable: false,
				},
				{
					text: "Highlights",
					value: "highlights.length",
					align: "center",
					sortable: false,
				},
			],
			semaphoreColors: [
				{ color: 'error', icon: 'mdi-emoticon-sad-outline' },
				{ color: '#fdc37f', icon: 'mdi-emoticon-neutral-outline' },
				{ color: 'success', icon: 'mdi-emoticon-excited-outline' }
			],
			newMember: {
				userId: null,
				role: null,
			},
			newRole: {},
			users: [],
			membersBillables: [],
			healthchecks: [],
			incidents: [],
			project: {},
			selectedMember: {},
			today: moment().startOf('day'),
			notEmployeeType: 1,
			userTypeToShow: 1,
			hasDetailedPermission: false,
		}
	},
	computed: {
		coreTeam() {
			return this.membersBillables.filter((x) => x.isCurrent)
		},
		stakeholders() {
			return this.members.filter((x) => x.user.type == this.notEmployeeType)
		},
		heroSupport() {
			return this.members.filter((x) => x.user.type != this.notEmployeeType)
		},
		shownUsers() {
			if(this.newMember.id)
				return this.users
			let ids = this.members.map(m => m.user.id)
			return this.users.filter(user => !ids.includes(user.id) && user.type == this.userTypeToShow)
		},
		showHistory() {
			return this.view == 1
		},
	},
	mounted() {
		this.$q.log(1, 8, 3, this.id, this.$parent.item.id)
		this.init()
	},
	methods: {
		init(){
			this.loadHealthchecksAndIncidents()
			this.$http
				.get(`/api/project/${this.id}/members`)
				.then(res => {
					this.members = res.data
					this.members = this.members.map(member => {
						if(!member.user.blueTag){
							member.user.blueTag = member.user.fullName
							member.user.fullName = '-'
						}
						return member
					})
			this.$http.get(`api/nucleiManagement/${this.id}/members`)
        .then((res) => {
          this.membersBillables = res.data.map((x) => ({
            ...x,
            userId: x.assignment.userId,
						user: x.assignment.user,
            startDate: moment(x.assignment.startDate),
            endDate: moment(x.assignment.endDate),
            isCurrent:
              moment(x.assignment.startDate).startOf('day').isSameOrBefore(this.today, "day") &&
              moment(x.assignment.endDate).startOf('day').isSameOrAfter(this.today, "day"),
          }))
					this.hasDetailedPermission = this.membersBillables.some(x => x.customerPerception || x.customerPerception === 0)
        })
        .catch(response => {
          window.getApp.error("Cannot get nuclei members.");
        })
					this.$http
						.get(`/api/user?active=true`)
							.then(res => {
								this.users = res.data
								this.users = this.users.map(user => {
									if(!user.blueTag){
										user.blueTag = user.fullName
										user.fullName = ''
									}
									return user
								})
							})
							.catch(res => {
								window.getApp.error("Cannot obtain users.")
								console.error(res)
							})
				})
				.catch(res => {
          window.getApp.error("Cannot obtain project members.")

				})

			this.$http
				.get(`/api/project/${this.id}`)
				.then(res => {
					this.project = res.data
				})
				.catch(res => {
					window.getApp.error("Cannot get project.");
					console.log(res);
				});
		},
		positionChanged(e){
			let member = e.moved.element
			this.$http.post(`/api/Project/${this.id}/moveMember/${member.id}/${e.moved.newIndex}`)
					.then(res => {
							member = res.data
					})
		},
		saveNewMember(){
			if(this.newMember.id) {
				this.updateMember()
				return
			}
			let member = {
				projectId: this.project.id,
				...this.newMember,
			}
			this.$http
				.post(`api/Project/member`, member)
				.then(res => {
					this.addingMember = false
					this.init()
				})
				.catch(res => {
					window.getApp.error("Cannot post member.");
					console.log(res);
				})
		},
		openAddMember(userTypeToShow){
			this.userTypeToShow = userTypeToShow
			this.addingMember = true
			this.newMember = {}
		},
		editMember(member){
			if(!this.canEditProject || !this.isInternal)
				return
			this.userTypeToShow = 0
			this.addingMember = true
			this.newMember = JSON.parse(JSON.stringify(member))
		},
		cancelEditMember(){
			this.addingMember = false
			this.newMember = {}
		},
		deleteMember(){
			this.$q.confirmDelete({
				api: `api/Project/member/${this.newMember.id}`,
				messageBody: "Are you sure you want to remove this member?", 
				sucessMsg: "Member removed",
				errorMsg: "The member was not removed.",
			}).then(res => {
					this.cancelEditMember()
					this.init()
			})
		},
		updateMember(){
			this.$http
				.post(`api/Project/member`, this.newMember)
				.then(res => {
					this.cancelEditMember()
					this.init()
				})
				.catch(res => {
					window.getApp.error("Cannot post member.");
					console.log(res);
				})
		},
		openSettings() {
			// Temporal while the nuclei settings dialog is fully migrated
      let lastHealthcheckDate = this.project.createdDateTime
      if(this.healthchecks.length)
        lastHealthcheckDate = this.healthchecks[0].date
      this.$refs.settingsDialog.open(this.id, this.project.healthcheckReminderRecurrence, lastHealthcheckDate)
        .then((res) => {
          if(res) {
						this.init()
					}
        })
    },
		loadHealthchecksAndIncidents(){
      this.$http.get(`/api/nucleiManagement/workItems/${this.id}`)
        .then(res => {
          this.incidents = res.data
        })
        .catch(res => {
          window.getApp.error("Cannot get work items.")
        })
			this.$http.get(`api/nucleiManagement/${this.id}/healthchecks`)
				.then(res => {
					this.healthchecks = res.data
						.map(h => ({
							...h,
							daysAgo: this.today.diff(moment(h.date), 'day')
						}))
				})
				.catch(err => {
					console.error(err)
					this.$root.error('Cannot get healthchecks')
				})
    },
		openMemberDetails(member) {
			if(this.hasDetailedPermission)
				this.$refs.nucleiMemberDialog.open(member)
    },
	}
}
</script>

<style scoped lang="scss">
::v-deep .dragg-col{
	min-width: 350px;
}
::v-deep .v-pagination__navigation{
	box-shadow: none !important;
}
.add-new-card{
	padding: 20px 10px;
}
.member-container{
	text-align: center;

	.avatar{
		margin-bottom: 10px;
		margin-right: -25px;
	}

	.edit-icon{
		cursor: pointer;
		float: right;
		top: 65px;
		right: 10px;
	}

	.content{
		background: var(--v-background-lighten2);
		border-radius: 5px;
		padding: 20px 30px;
		padding-top: 65px;
		margin-top: -55px;

		.role-container{
			border-top: 1px solid #7fafe0;
			margin-top: 10px;
			padding: 10px 0;
			color: var(--v-accent-base);
			text-align: center;

			.role-input-container{
				margin-top: 5px;
				.role-input{
					width: 200px;
					display: inline-block;
					margin-right: 5px;
				}
				.icon{
					margin: 0 2px;
					cursor: pointer;
				}
				.icon:hover{
					color: var(--v-text-base);
				}
			}
		}
	}
}

::v-deep .draggable-row{
  gap: 20px;
}
</style>