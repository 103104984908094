<template>
	<div>
		<draggable
			v-model="innerValue"
			@change="handleChange($event)"
			:class="rowClass"
			v-if="!spaceEvenly"
		>
			<v-col
				v-for="(item, ix) in innerValue" :key="item[itemKey] || ix"
				:cols="cols" :sm="sm" :md="md" :lg="lg"
				:class="colClass" v-show="filter(item)"
			>
				<slot v-bind="item"> {{item}} </slot>
			</v-col>
		</draggable>
		<draggable
			v-model="innerValue"
			@change="handleChange($event)"
			:class="rowClass"
			v-else
		>
			<div
				v-for="(item, ix) in innerValue" :key="ix"
				:cols="cols" :sm="sm" :md="md" :lg="lg"
				:class="colClass" v-show="filter(item)"
			>
				<slot v-bind="item"> {{item}} </slot>
			</div>
		</draggable>
	</div>
</template>

<script>
import draggable from "vuedraggable"

export default {
	components: { draggable },
	props: {
		value: { type: Array, default: () => [] },
		filter: { type: Function, default: arr => true },
		colClass: { type: String, default: '' },
		rowClass: { type: String, default: 'row' },
		itemKey: { type: String, default: '' },
		cols: { type: Number, default: null },
		sm: { type: Number, default: null },
		md: { type: Number, default: null },
		lg: { type: Number, default: null },
		spaceEvenly: { type: Boolean, default: false },
	},
	data: () => ({
		innerValue: [],
	}),
	mounted() {
		this.innerValue = this.value
	},
	watch: {
		value(){
			this.innerValue = this.value
		},
	},
	methods: {
		handleChange(e){
			this.$emit('input', this.innerValue)
			this.$emit('change', e)
		},
	},
}
</script>