<template>
  <div class="q-autocomplete">

    <!-- <v-icon :color="color">{{icon}}</v-icon>
    {{display}} -->

    <!-- TODO: componentize -->
    <v-autocomplete
      ref="vAutocomplete" dense class="autocomplete"
      :disabled="disabled" :readonly="readonly" :items="items" :item-value="itemValue" :item-text="itemText" v-model="innerValue" single-line solo 
      :flat="!editting" @blur="save" @focus="editting = true" @change="hideButtons ? save() : ''" hide-details
    >
      <template v-slot:item="{ item }">
        <slot name="item" v-bind="item">
          <v-icon :color="item[itemColor]" class="mr-2">{{item[itemIconClass]}}</v-icon>
          {{item[itemText]}}
        </slot>
      </template>
      <template v-slot:selection="{ item }">
        <slot  name="selection" v-bind="item">
          <v-icon :color="item[itemColor]" class="mr-2">{{item[itemIconClass]}}</v-icon>
          {{ item[itemText] }}
        </slot>
      </template>
      <template v-slot:append>
        <v-btn fab x-small v-if="editting && !hideButtons" @click="save" color="primary">
          <v-icon>done</v-icon>
        </v-btn>
        <v-btn icon v-if="editting && !hideButtons" @click="cancel">
          <v-icon>close</v-icon>
        </v-btn>
        </template>
      <template  v-slot:prepend-item>
        <slot>
          <button v-if="addFirstElement" id="addFirstElementButton" class="ml-3 " @click="addElement">
            <v-icon
              small
              left
              class="mr-0"
              :color="$vuetify.theme.dark ? 'white' : 'black'"
            >
              add
            </v-icon>
            <span class="source-text">
              Add Source
            </span>
          </button>
        </slot>
      </template>
    </v-autocomplete>
  </div>
</template>

<script>
  import moment from 'moment'
  import _ from "lodash"

  export default {
    props: {
      value: null,
      itemValue: { type: String, default: 'id'},
      itemText: { type: String, default: 'name'},
      itemColor: { type: String, default: 'color'},
      itemIconClass: { type: String, default: 'iconClass'},
      items: Array,
      disabled: { type: Boolean, default: false},
      readonly: { type: Boolean, default: false},
      hideButtons: { type: Boolean, default: false },
      addFirstElement: { type: Boolean, default: false },
    },
    data: () => ({
      innerValue: null,
      editting: false,
      inputValue: null,
    }),
    watch: {
      value: function (val) {
        this.innerValue = val;
      },
    },
    computed: {
      display() {
        if (!this.items) return ''
        let item = this.items.find(i => i[this.itemValue] == this.innerValue)
        if (!item) return ''
        return item[this.itemText]
      },
      icon() {
        if (!this.items) return ''
        let item = this.items.find(i => i[this.itemValue] == this.innerValue)
        if (!item) return ''
        return item[this.itemIconClass]
      },
      color() {
        if (!this.items) return ''
        let item = this.items.find(i => i[this.itemValue] == this.innerValue)
        if (!item) return ''
        return item[this.itemColor]
      },
    },
    methods: {
      addElement(){
        this.$emit('saveElement')
        this.editting = false
        this.$refs.vAutocomplete.blur()
      },
      save(e) {
        if(e?.relatedTarget?.id === 'addFirstElementButton') {
          return
        }
        this.$emit('save', this.innerValue)
        this.$emit('input', this.innerValue)
        this.editting = false
        this.inputValue = null
        this.$refs.vAutocomplete.blur()
      },
      cancel() {
        this.innerValue = this.value
        this.editting = false
        this.inputValue = null
        this.$refs.vAutocomplete.blur()
      },
    },
    created() {
    },
   }
</script>

<style scoped lang="scss">
  .q-autocomplete {
    padding-bottom: 10px;
    // background: green;
  }
  // .summary ::v-deep input {
  //   font-size: 22px;
  //   font-weight: 700;
  //   color: #555555;
  // }
  .autocomplete {
    margin-left: -12px;
    margin-right: -12px;
    // background: red;
  }
  .autocomplete ::v-deep .v-input__slot {
    // background: yellow !important;
  }
  .autocomplete ::v-deep .v-input__slot .v-select__slot {
    background: var(--v-background-lighten2);
    border-radius: 3px;
  }
  .autocomplete.v-input--is-focused ::v-deep .v-input__slot .v-select__slot {
    background: initial;
  }
  .addElement {
    width: 100%;
    font-size: 14px;
    text-align: start;
  }
  #addFirstElementButton:hover {
    opacity : .5;
  }
  .source-text {
    opacity: 1 !important;
  }
</style>
