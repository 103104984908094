<template>
	<div class="pa-3 fill-height new-back">
		<filter-search-input :value="search" @input="updateSearch" :applied-filters="appliedFilters" outlined dense>
			<template #append>
				<v-btn-toggle
					v-model="filters.timeUnit"
					@change="loadReport"
					tile group
					color="primary"
				>
					<v-btn v-for="unit in timeUnits" :key="unit.value" small :value="unit.value">
						{{ unit.text }}
					</v-btn>
				</v-btn-toggle>
			</template>
			<q-date-picker
				v-model="filters.startDate"
				label="Start date"
				dense outlined inner range hide-details single-line clearable
			/>
			<q-date-picker
				v-model="filters.endDate"
				label="End date"
				dense outlined inner range hide-details single-line clearable
			/>
			<v-checkbox
				v-model="filters.activeOnly"
				hide-details
				label="Active only"
				class="mt-1"
			/>
		</filter-search-input>

		<data-table
			:shownItemsPerPage="shownItems.length"
			:headers="headers"
			:items="shownItems"
			fix-first-col fixed-header
			style="width: calc(100vw - 190px)"
			height="calc(100vh - 210px)"
			@click:row="toggleExpand"
		>
			<template #[`header.userBlueTag`]>
				<div class="d-inline-block pl-4 body-2">BlueTag</div>
			</template>
			<template v-for="(header, ix) in headers.filter(x => x.value.startsWith('date_'))" #[getSlot(header.value)]="{ item }">
				<div :key="header.value">
					<div
						v-if="item.assignationDates[ix].assignationPercentage || item.assignationDates[ix].assignationPercentage === 0"
						:style="getPercentageStyles(item.assignationDates[ix].assignationPercentage)"
						class="px-2 chip" :class="{ 'reduced-bright': $vuetify.theme.isDark }"
					>
						{{ item.assignationDates[ix].assignationPercentage }}%
					</div>
					<span v-else>-</span>
				</div>
			</template>
			<template #[`item.userBlueTag`]="{ item }">
				<div class="d-flex align-center cursor-pointer" style="gap: 10px; width: 100%">
					<div class="d-flex flex-column" style="width: calc(100% - 65px)">
						<div class="d-flex">
							<b>{{ item.userBlueTag }}</b>
							<span class="caption ml-5" style="color: var(--v-text-lighten)" v-show="item.expanded">{{ item.userRoles }}</span>
						</div>
						<span class="body-2 nobr" style="color: var(--v-text-lighten)" v-show="item.expanded">{{ item.userFullName }}</span>
					</div>
				</div>
			</template>
		</data-table>
	</div>
</template>

<script>
import moment from "moment"

export default {
	data: () => ({
		headers: [],
		items: [],
		timeUnits: [
			{ text: 'Days', value: 0 },
			{ text: 'Weeks', value: 1 },
			{ text: 'Months', value: 2 },
		],

		search: '',
		searchTimeout: null,
		filters: {
			startDate: moment().startOf('year').format('YYYY-MM-DD'),
			endDate: '',
			timeUnit: 2,
			activeOnly: false,
		},
	}),
	created(){
		document.title = 'Roster 100%'
    this.$q.log(7, 48, 3, 'NA')
		this.loadReport()
	},
	computed: {
		shownItems() {
			return this.applySearch(this.search, this.items, ['userBlueTag', 'userFullName', 'userRoles'])
		},
		appliedFilters() {
			let applied = 0
			if(this.filters.activeOnly) applied++
			return applied
		},
	},
	methods: {
		loadReport(){
			if(
				this.filters.startDate && this.filters.endDate &&
				moment(this.filters.endDate).isBefore(this.filters.startDate)
			){
				this.filters.startDate = this.filters.endDate
				return
			}
			this.$http.get(`
				api/admin/roster?
				startDate=${this.filters.startDate}&
				endDate=${this.filters.endDate}&
				timeUnit=${this.filters.timeUnit}&
				activeOnly=${this.filters.activeOnly}
			`)
				.then(res => {
					this.items = res.data.sort((a, b) => {
						if(moment(a.userBlueniversary).isBefore(b.userBlueniversary)) return -1
						if(moment(b.userBlueniversary).isBefore(a.userBlueniversary)) return 1
						return 0
					})
					this.headers = [{ text: 'Bluetag', value: 'userBlueTag', width: 150 }]

					if(this.items.length){
						this.items[0].assignationDates
							.forEach((x, ix) => this.headers.push({
								text: this.formatDate(x.date),
								value: 'date_' + ix,
								align: 'center',
								width: 75,
								sortable: false,
							}))
					}					
				})
		},
		updateSearch(v) {
			clearTimeout(this.searchTimeout)
			this.searchTimeout = setTimeout(() => {
				this.search = v
			}, 300)
		},
		clearDateRange(){
			this.$nextTick(this.loadReport)
		},
		formatDate(date){
			if(this.filters.timeUnit == 0) return moment(date).format('MMM D, YYYY')
			if(this.filters.timeUnit == 1) return moment(date).format('W, YYYY')
			return moment(date).format('MMM YYYY')
		},
		getSlot(headerValue){
			return 'item.' + headerValue
		},
		getPercentageStyles(value){
			const colors = this.getColors(value)
			return {
				background: colors[0],
				color: colors[1],
			}
		},
		getColors(value){
			if(value <= 0) return ['#DB2B30', '#F5C4C5']
			if(value <= 20) return ['#F4C7BD', '#D93814']
			if(value <= 40) return ['#FBD8B9', '#F27405']
			if(value <= 60) return ['#FBECB9', '#F2BA05']
			if(value <= 90) return ['#EAFBB9', '#6F9C10']
			if(value <= 100) return ['#BCE3C9', '#109C3E']
			return ['#DB2B30', '#F5C4C5']
		},
		toggleExpand(item){
			this.$set(item, 'expanded', !item.expanded)
		},
	},
	watch: {
		filters: {
			deep: true,
			handler(){
				this.loadReport()
			}
		}
	},
}
</script>

<style lang="scss" scoped>
label{
	color: var(--v-text-lighten);
}
.chip {
	border-radius: 10px;
	font-size: .8rem;
}
.reduced-bright{
	filter: brightness(.9)
}
::v-deep {
	th{
		padding: 0 !important;
		font-size: .7em !important;
	}
	td{
		height: 35px !important;
	}
	thead tr th:first-child {
		z-index: 3 !important;
	}
}
</style>