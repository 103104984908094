<template>
	<html-to-pdf
		v-if="show" v-model="show" :name="'Account Balance - ' + accountBalance.customerName"
		prevent
	>
		<div>
			<img
				width="175" src="../../assets/invoice/people-icon.png"
				style="position: absolute; left: 0; top: 800px; z-index: 0"
				contain
			/>
			<div class="d-flex flex" style="z-index: 1; gap: 5px">
				<div class="d-flex justify-space-between flex-column flex">
					<div class="d-flex justify-space-between">
						<div v-if="accountBalance.businessDetails" class="flex caption">
							<div style="line-height: 15px">
								<b>{{ accountBalance.businessDetails.businessName }}</b> <br/>
								{{ accountBalance.businessDetails.addressLine }} {{ accountBalance.businessDetails.addressLine2 }} <br/>
								{{ accountBalance.businessDetails.city }} {{ accountBalance.businessDetails.state }} <br/>
								{{ accountBalance.businessDetails.zipCode }} <br/>
								{{ accountBalance.businessDetails.email }}
							</div>
						</div>
						<v-img
							src="../../assets/bluePeople.png"
							height="90" width="200" contain
						/>
					</div>
					<h3 class="headline primary--text mb-2">Statement</h3>
					<div class="d-flex justify-space-between" style="z-index: 1">
						<div style="line-height: 15px" class="caption">
							<b>TO</b>
							<div>
								<div>{{ accountBalance.customerRfc.name }}</div>
								{{ accountBalance.customerRfc.addressLine }} {{ accountBalance.customerRfc.colony }} <br/>
								{{ accountBalance.customerRfc.city }} {{ accountBalance.customerRfc.state }} {{ accountBalance.customerRfc.zipCode }} <br/>
								{{ accountBalance.customerRfc.country }} <br/>
							</div>
						</div>
						<div class="text-right caption" style="line-height: 15px;">
							<b>DATE: </b> {{ today | formatDate }} <br/>
							<b>CREDIT DAYS: </b> {{ accountBalance.creditDays }} <br/>
							<b>TOTAL BALANCE: </b> <span v-html="getAmount(accountBalance.totalBalance)"></span>
							<b>OVERDUE BALANCE: </b> <span v-html="getAmount(accountBalance.overdueBalance)"></span>
							<b>STATEMENT START DATE: </b> {{ accountBalance.statementStartDate | formatDate }}
						</div>
					</div>

					<div class="mt-1">
						<h3 class="headline primary--text mb-2">Invoices</h3>
						<v-data-table
							:headers="invoiceHeaders"
							:items="accountBalance.invoices"
							style="z-index: 1"
							disable-pagination
							disable-sort
							hide-default-footer
							class="transparent"
						>
							<template #[`item.issueDate`]="{ item }">
								{{ item.issueDate | formatDate }}
							</template>
							<template #[`item.paymentDay`]="{ item }">
								{{ item.paymentDay | formatDate }}
							</template>
							<template #[`item.total`]="{ item }">
								{{ toMoney(item.total, item.currency) }}
								<span v-if="item.status == 1" :style="{color: `var(--v-${invoiceStatus[item.status].color})`}">
									( {{ toMoney(item.pending, item.currency) }} )
								</span>
							</template>
							<template #[`item.status`]="{ item }">
								<b :style="{color: `var(--v-${invoiceStatus[item.status].color})`}">
									{{ invoiceStatus[item.status].text }}
								</b>
							</template>
						</v-data-table>
					</div>

						<div class="mt-5">
							<h3 class="headline primary--text mb-2">Payments</h3>
							<v-data-table
								:headers="paymentHeaders"
								:items="accountBalance.payments"
								style="z-index: 1"
								disable-pagination
								disable-sort
								hide-default-footer
								class="transparent"
							>
								<template #[`item.date`]="{ item }">
									{{ item.date | formatDate }}
								</template>
								<template #[`item.amount`]="{ item }">
									{{ toMoney(item.amount, item.currency) }}
								</template>
								<template #[`item.status`]="{ item }">
									<b :class="`${item.statusType.color}--text`">
										{{ item.statusType.description }}
									</b>
								</template>
								<template #[`item.invoices`]="{ item }">
									{{ item.invoices.map(invoice => invoice.code).join(', ') }}
								</template>
							</v-data-table>
						</div>

						<div class="mt-5">
							<v-data-table
								:headers="dueHeaders"
								:items="[accountBalance.dueAmount]"
								style="z-index: 1"
								disable-pagination
								disable-sort
								hide-default-footer
								class="transparent dense"
							>
								<template #[`item.current`]="{ item }">
									<span v-html="getAmount(item.current)"></span>
								</template>
								<template #[`item.days30`]="{ item }">
									<span v-html="getAmount(item.days30)"></span>
								</template>
								<template #[`item.days60`]="{ item }">
									<span v-html="getAmount(item.days60)"></span>
								</template>
								<template #[`item.days90`]="{ item }">
									<span v-html="getAmount(item.days90)"></span>
								</template>
								<template #[`item.days90plus`]="{ item }">
									<span v-html="getAmount(item.days90plus)"></span>
								</template>
								<template #[`item.total`]="{ item }">
									<span v-html="getAmount(item.total)"></span>
								</template>
							</v-data-table>
						</div>

				</div>
			</div>
		</div>
	</html-to-pdf>
</template>

<script>
import moment from 'moment'

export default {
	data: () => ({
		show: false,
		showUSD: true,
		accountBalance: null,
		today: moment().format('YYYY-MM-DD'),

		invoiceHeaders: [
			{ text: 'Number/Code', value: 'code', align: 'center' },
			{ text: 'Date', value: 'issueDate', align: 'center' },
			{ text: 'Due Date', value: 'paymentDay', align: 'center' },
			{ text: 'Amount', value: 'total', align: 'right'},
			{ text: 'Status', value: 'status', align: 'center' },
		],
		invoiceStatus: {
			0: {
				text: 'Paid',
				color: 'success-base',
			},
			1: {
				text: 'Partial',
				color: 'warning-darken',
			},
			2: {
				text: 'Pending - On Time',
				color: 'primary-base',
			},
			3: {
				text: 'Overdue',
				color: 'error-base',
			},
		},

		paymentHeaders: [
			{ text: 'Reference Number', value: 'reference', align: 'center' },
			{ text: 'Date', value: 'date', align: 'center' },
			{ text: 'Amount', value: 'amount', align: 'right' },
			{ text: 'Status', value: 'status', align: 'center' },
			{ text: 'Applied to the invoice', value: 'invoices', align: 'center' },
		],

		dueHeaders: [
			{ text: 'Current Due', value: 'current', },
			{ text: '1-30 Days Past Due', value: 'days30', },
			{ text: '31-60 Days Past Due', value: 'days60', },
			{ text: '61-90 Days Past Due', value: 'days90', },
			{ text: '90+ Days Past Due', value: 'days90plus', },
			{ text: 'Amount Due', value: 'total', },
		],
	}),
	methods: {
		async open(customerId){

			try {
				const { data: accountBalance } = await this.$http.get(`api/customer/${customerId}/balance/summary`)
				this.accountBalance = accountBalance
				this.showUSD = true
				if(accountBalance.customerCountry === 1) this.showUSD = false
			} catch (error) {
				console.error(error)
				this.$root.error('Failed to load account balance')
			}

			this.show = true
		},
		getAmount(amount){
			let text = ''
			const currencies = ['usd', 'mxn', 'eur']
			const formatter = new Intl.NumberFormat('en-US', {
				style: 'currency',
				currency: 'USD',
				minimumFractionDigits: 2,
			})

			for(let currency of currencies) {
				if(amount[currency]) {
					const money = formatter.format(amount[currency])
					if(currency === 'usd' && !this.showUSD)
						text += money + '<br>'
					else
						text += `${currency.toUpperCase()}${money}<br>`
				}
			}

			return text || '$0.00 <br>'
		},
		toMoney(amount, currency) {
			const formatter = new Intl.NumberFormat('en-US', {
				style: 'currency',
				currency: 'USD',
				minimumFractionDigits: 2,
			})

			const money = formatter.format(amount)

			if(amount === 0) {
				return '$0.00'
			}

			if(currency === 'USD' && !this.showUSD)
				return money
			else
				return `${currency.toUpperCase()}${money}`
		},
	},
	filters: {
		amount(amount){
			let text = ''
			const currencies = ['usd', 'mxn', 'eur']
			const formatter = new Intl.NumberFormat('en-US', {
				style: 'currency',
				currency: 'USD',
				minimumFractionDigits: 2,
			})

			for(let currency of currencies) {
				if(amount[currency]) {
					const money = formatter.format(amount[currency])
					if(currency === 'USD' && !this.showUSD)
						text += money
					else
						text += `${currency.toUpperCase()}${money}<br>${currency.toUpperCase()}${money}<br>`
				}
			}

			return text || '$0.00'
		},
	},
}
</script>

<style lang="scss" scoped>
::v-deep .v-data-table {
	td {
		font-size: 10px !important;
		height: 30px !important;
	}
	th {
		font-size: 11px !important;
		height: 30px !important;
	}
}

::v-deep .v-data-table thead > tr {
	background: #eff7f8;
	th {
	}
	span {
		color: #64adcf !important;
		font-weight: 400;
		text-transform: uppercase;
	}
}
</style>