<template>
    <v-container grid-list-md fill-height fluid>
        <ProjectLinkDialog :dialog="showDialog" :cancel="() => showDialog = false" :callback="init" :projectId="id" :link="selectedLink"/>
        <ProjectLinkDelete :dialog="showDelete" :cancel="() => showDelete = false" :callback="init" :projectId="id" :link="selectedLink"/>
        <v-layout column fill-height justify-start>
            <div style="display: flex; align-items: center">
                <v-btn fab small color="primary" @click="createLink" :disabled="!canEditProject">
                    <v-icon dark>add</v-icon>
                </v-btn>
                <v-text-field
                    prepend-inner-icon="search" label="Search"
                    single-line hide-details outlined dense
                    style="flex: none" class="ml-2"
                    v-model="search"
                />
            </div>

            <draggable-sort
                v-model="links" @change="positionChanged"
                :filter="(link) => this.normalizeIncludes(link.name, search) || this.normalizeIncludes(link.url, search)"
                :cols="12" :md="10" :lg="6"
                class="mt-5"
            >
                <template v-slot="link">
                    <div class="link" @click="openUrl(link.url)">
                        <div class="icon-container background--lighten2">
                            <v-icon color="secondary">mdi-link-box-variant</v-icon>
                        </div>
                        <div class="info-container">
                            <h3>{{link.name}}</h3>
                            <span>{{setMaxText(link.url, 9000)}}</span>
                        </div>
                        <div class="actions">
                            <v-icon :disabled="!canEditProject" @click.stop="selectLink(link)" class="table-action" color="#1976D2">mdi-pencil</v-icon>
                            <v-icon :disabled="!canEditProject" @click.stop="deleteLink(link)" class="table-action" color="red">mdi-delete</v-icon>
                        </div>
                    </div>
                </template>
            </draggable-sort>

        </v-layout>
    </v-container>
</template>
<script>
import ProjectLinkDialog from '../../dialogs/ProjectLinkDialog'
import ProjectLinkDelete from '../../dialogs/ProjectLinkDelete'
import DraggableSort from '../../components/DraggableSort'

export default {
    props: {
        id: { type: String, default: null },
        canEditProject: { type: Boolean, default: false },
        projectName: { type: String, default:''}
    },
    components: {
        ProjectLinkDialog,
        ProjectLinkDelete,
        DraggableSort,
    },
    data(){
        return{
            canAdd: false,
            showDialog: false,
            showDelete: false,
            links: [],
            headers: [
                { text: "Name", value: "name" },
                { text: "Description", value: "description" },
                { text: "Link", value: "url" },
                { text: "", value: "actions" },
            ],
            selectedLink: {},
            search: '',
        }
    },
    mounted() {
        this.$q.log(1, 7, 3, this.id, this.$parent.item?.id || this.id)
        this.init();
    },
    methods: {
        init() {
            this.showDialog = false;
            this.showDelete = false;
            this.$http
                .get(`/api/Project/${this.id}/links`)
                .then(res => {
                    this.links = res.data
                })
                .catch(res => {
                    window.getApp.error("Cannot get links.");
                    console.log(res);
                });
        },
        positionChanged(e){
            let link = e.moved.element
            this.$http.post(`/api/Project/${this.id}/moveLink/${link.id}/${e.moved.newIndex}`)
                .then(res => {
                    link = res.data
                })
        },
        selectLink(link){
            this.selectedLink = JSON.parse(JSON.stringify(link));
            this.showDialog = true;
        },
        createLink(){
            this.selectedLink = {};
            this.showDialog = true;
        },
        deleteLink(link){
            this.selectedLink = JSON.parse(JSON.stringify(link));
            this.showDelete = true;
        },
        getUrl(url){
            if (!/^https?:\/\//i.test(url))
                url = 'http://' + url;
            return url;
        },
        openUrl(url){
            url = this.getUrl(url)
            let tagA = document.createElement('a')
            tagA.href = url
            tagA.target = '_blank'
            tagA.rel = 'noopener noreferrer'
            tagA.click()
        }
    }
}
</script>
<style scoped lang="scss">
.link{
    background: var(--v-background-lighten2);
    width: 100%;
    border-radius: 5px;
    height: 65px;
    display: grid;
    grid-template-columns: 55px auto 60px;
    align-items: center;
    padding: 8px;
    margin-bottom: 10px;
    // color: #333;
    text-decoration: none;
    cursor: pointer;

    .icon-container{
        display: flex;
        align-items: center;
        justify-content: center;
        height: 100%;
        padding: 5px;
        background: #eee;
        margin-right: 8px;

        .v-icon{
            font-size: 38px;
            color: #9c88ff;
        }
    }

    .info-container{
        grid-row: 1;
        grid-column: 2;
        text-overflow: ellipsis;
        white-space:nowrap;
        overflow: hidden;
        span{
            color: var(--v-text-lighten);
        }
    }

    .actions{
        text-align: right;
        .table-action{
           cursor: pointer;
           margin: 0;
        }
        .table-action:hover::after{
            opacity: .12;
        }
    }
}
</style>