<template>
  <v-row>
      <v-col cols="11">
        <v-row>
          <v-col cols="4" class="col-login">
            <span>Frequency</span>
            <v-text-field
              dense
              outlined
              type="number"
              v-model="item.frequency"
              :min="0"
            />
          </v-col>
          <v-col cols="4" class="col-login">
            <span>Unit</span>
            <v-autocomplete
              outlined
              dense
              hide-details
              :items="recessConfigurationLineUnities"
              item-value="value"
              item-text="name"
              v-model="item.unity"
            />
          </v-col>
          <v-col cols="4" class="col-login">
            <span>Vacation Days</span>
            <v-text-field
              dense
              type="number"
              outlined
              v-model="item.days"
              :min="0"
            />
          </v-col>
        </v-row>
      </v-col>
      <v-col cols="1" class="d-flex align-center justify-end pl-0">
        <v-btn
          @click="$emit('delete', item)"
          icon
          color="error"
        >
          <span :style="{ 'fontSize': '30px' }" class="material-symbols-outlined">
            delete
          </span>
        </v-btn>
      </v-col>
  </v-row>
</template>

<script>
export default {
  props: {
    item: { type: Object, default: () => ({}) },
  },
  data: () => ({
    recessConfigurationLineUnities: [
      { value: 0, name: 'Days' },
      { value: 1, name: 'Weeks' },
      { value: 2, name: 'Months' },
      { value: 3, name: 'Years' },
    ]
  })
}
</script>

<style scoped lang="scss">
.col-login {
  padding-bottom: 0px !important;
  max-height: 120px !important;

  span {
    font-weight: 400;
    color: var(--v-text-lighten2);
  }
}
</style>