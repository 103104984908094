var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('filter-search-input',{attrs:{"applied-filters":_vm.appliedFilters,"outlined":"","dense":""},scopedSlots:_vm._u([{key:"append",fn:function(){return [(_vm.permissions.add)?_c('div',[_c('v-tooltip',{attrs:{"top":"","z-index":"200"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"fab":"","color":"primary","small":""},on:{"click":function($event){return _vm.editPayment('new')}}},on),[_c('v-icon',[_vm._v("mdi-plus")])],1)]}}],null,false,2832589924)},[_c('span',{staticClass:"caption"},[_vm._v("Add new")])])],1):_vm._e()]},proxy:true}]),model:{value:(_vm.filters.search),callback:function ($$v) {_vm.$set(_vm.filters, "search", $$v)},expression:"filters.search"}},[(!_vm.invoiceDetailsView)?_c('v-autocomplete',{attrs:{"label":"Customer","outlined":"","dense":"","hide-details":"","single-line":"","clearable":"","multiple":"","items":_vm.shownCustomers,"item-text":"name","item-value":"id"},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"opt",style:({color: item.filtered ? '' : 'grey'})},[(_vm.filters.customer.includes(item.id))?_c('v-icon',{attrs:{"color":"primary"}},[_vm._v("mdi-checkbox-marked")]):_c('v-icon',[_vm._v("mdi-checkbox-blank-outline")]),_vm._v(" "+_vm._s(item.name)+" ")],1)]}}],null,false,2475935236),model:{value:(_vm.filters.customer),callback:function ($$v) {_vm.$set(_vm.filters, "customer", $$v)},expression:"filters.customer"}}):_vm._e(),_c('q-date-picker',{attrs:{"label":"Start Date","inner":"","outlined":"","dense":"","hide-details":"","clearable":"","max":_vm.filters.endDate},model:{value:(_vm.filters.startDate),callback:function ($$v) {_vm.$set(_vm.filters, "startDate", $$v)},expression:"filters.startDate"}}),_c('q-date-picker',{attrs:{"label":"End Date","inner":"","outlined":"","dense":"","hide-details":"","clearable":"","min":_vm.filters.startDate},model:{value:(_vm.filters.endDate),callback:function ($$v) {_vm.$set(_vm.filters, "endDate", $$v)},expression:"filters.endDate"}}),(!_vm.invoiceDetailsView)?_c('v-autocomplete',{attrs:{"label":"Invoice","outlined":"","dense":"","hide-details":"","single-line":"","clearable":"","multiple":"","items":_vm.shownInvoices,"item-text":"code","item-value":"id"},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"opt",style:({color: item.filtered ? '' : 'grey'})},[(_vm.filters.invoice.includes(item.id))?_c('v-icon',{attrs:{"color":"primary"}},[_vm._v("mdi-checkbox-marked")]):_c('v-icon',[_vm._v("mdi-checkbox-blank-outline")]),_vm._v(" "+_vm._s(item.code)+" ")],1)]}}],null,false,1931240635),model:{value:(_vm.filters.invoice),callback:function ($$v) {_vm.$set(_vm.filters, "invoice", $$v)},expression:"filters.invoice"}}):_vm._e(),_c('q-filter-input',{attrs:{"items":_vm.statuses,"label":"Status","dense":"","outlined":"","hide-details":"","clearable":"","input-select":"","multiple":"","hide-input":"","parent-shown-items":_vm.shownPayments,"data-comparer":function (item, value) { return item.status == value; }},model:{value:(_vm.filters.status),callback:function ($$v) {_vm.$set(_vm.filters, "status", $$v)},expression:"filters.status"}})],1),_c('v-data-table',{staticClass:"rounded-lg bordered new-back lighten2",attrs:{"item-class":function () { return _vm.permissions.detail ? 'cursor-pointer' : ''; },"items":_vm.shownPayments,"headers":_vm.shownHeaders},on:{"click:row":function (x) { return _vm.permissions.detail ? _vm.editPayment(x.id) : null; }},scopedSlots:_vm._u([{key:"item.customer",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"my-1"},[_c('h3',[_vm._v(_vm._s(item.customerName))]),_c('span',[_vm._v(_vm._s(item.concept))])])]}},{key:"item.date",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("formatDate")(item.date))+" ")]}},{key:"item.amount",fn:function(ref){
var item = ref.item;
return [(_vm.invoiceDetailsView)?_c('span',[_vm._v(" "+_vm._s(_vm.currencyFormat( item.invoices.find(function (x) { return x.invoiceId == _vm.referencedInvoiceId; }).exchangeRateAmount || item.invoices.find(function (x) { return x.invoiceId == _vm.referencedInvoiceId; }).amount ))+" ")]):_c('span',[_vm._v(_vm._s(_vm.currencyFormat(item.amount)))])]}},{key:"item.balanceInFavor",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.balanceInFavor ? _vm.currencyFormat(item.balanceInFavor) : '-')+" ")]}},{key:"item.deleted",fn:function(ref){
var item = ref.item;
return [_c('div',[_c('v-btn',{staticStyle:{"border-radius":"20px"},style:({
						background: _vm.$vuetify.theme.isDark ? '' : item.statusLightColor
					}),attrs:{"color":item.statusColor,"outlined":"","small":""}},[_vm._v(_vm._s(item.statusDisplayName))])],1)]}},{key:"item.actions",fn:function(ref){
					var item = ref.item;
return [_c('v-menu',{attrs:{"left":"","offset-x":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
					var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"icon":""}},on),[_c('v-icon',[_vm._v("mdi-dots-vertical")])],1)]}}],null,true)},[_c('v-list',{staticClass:"py-1",attrs:{"dense":""}},[_c('v-list-item',{staticClass:"pa-1 py-0"},[_c('v-tooltip',{attrs:{"right":"","z-index":"200"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
					var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"text":"","small":""},on:{"click":function($event){return _vm.$adminSurf('openHistory', item, 'payment')}}},on),[_c('v-icon',[_vm._v("mdi-history")])],1)]}}],null,true)},[_c('span',{staticClass:"caption"},[_vm._v("History")])])],1),_c('v-list-item',{staticClass:"pa-1 py-0"},[_c('v-tooltip',{attrs:{"right":"","z-index":"200"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
					var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"text":"","small":"","color":"error","disabled":item.deleted},on:{"click":function($event){return _vm.cancelPayment(item)}}},on),[_c('v-icon',[_vm._v("mdi-cancel")])],1)]}}],null,true)},[_c('span',{staticClass:"caption"},[_vm._v("Cancel")])])],1),_c('v-list-item',{staticClass:"pa-1 py-0"},[_c('watchers-menu',{attrs:{"entity-id":item.id,"watchers":item.subscribers,"required-permission":"payments"},on:{"watcher-added":function($event){return item.subscribers.push($event)},"watcher-removed":function($event){item.subscribers.splice(item.subscribers.findIndex(function (x) { return x.userId == $event; }), 1)}}})],1)],1)],1)]}}],null,true)}),_c('payment-create-dialog',{ref:"paymentCreateDialog",attrs:{"invoiceDetailsView":_vm.invoiceDetailsView}}),_c('blue-confirm-dialog',{ref:"confirmDialog"})],1)}
var staticRenderFns = []

export { render, staticRenderFns }