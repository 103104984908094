<template>
	<div>
		<h2 class="mb-3">
			Approvals
			<v-btn
				@click="editApproval(null)"
				:disabled="!$parent.canEditProject"
				x-small fab depressed color="primary"
			>
				<v-icon dark>add</v-icon>
			</v-btn>
		</h2>
		<data-table :headers="headers" :items="$parent.config.approvals">
			<template #[`item.approverBlueTag`]="{ item }">
				<div class="d-flex align-center" style="gap: 5px">
					<user-avatar :user-id="item.approverId" :size="38" hide-loading/>
					<div>
						<b>{{ item.approverBlueTag }}</b>
						<div class="mt-n1">{{ item.approverFullName }}</div>
					</div>
				</div>
			</template>
			<template #[`item.role`]="{ item }">
				{{ getRole(item.approverId) }}
			</template>
			<template #[`item.statusName`]="{ item }">
				<v-chip :color="item.statusColor" dark label small>
					<v-icon left color="white" small>
						{{ item.statusIconClass }}
					</v-icon>
					{{ item.statusName }}
				</v-chip>
			</template>
			<template #[`item.actions`]="{ item }">
				<div class="d-flex">
					<v-btn
						@click="deleteApproval(item)"
						x-small fab depressed outlined color="primary"
					>
						<v-icon dark>delete</v-icon>
					</v-btn>
					<v-btn
						@click="editApproval(item)"
						x-small fab depressed color="primary"
						class="mr-2"
					>
						<v-icon dark>edit</v-icon>
					</v-btn>
				</div>
			</template>
		</data-table>

		<edit-approval-dialog
			ref="editApproval"
			:members="$parent.members"
			:status="$parent.config.workItemStatuses"
			:project-id="$parent.id"
			:types="shownTypes"
		/>
	</div>
</template>

<script>
import EditApprovalDialog from '../../dialogs/projectConfig/EditApprovalDialog'

export default {
	components: { EditApprovalDialog },
	data: () => ({
		headers: [
			{ text: 'Bluetag', value: 'approverBlueTag' },
			{ text: 'Role', value: 'role' },
			{ text: 'Status to Approve', value: 'statusName' },
			{ text: 'Requirements for Approval', value: 'requirements' },
			{ text: '', value: 'actions', width: 1 },
		],
	}),
	computed: {
    shownTypes() {
      if (!this.$parent.boardConfigs || !this.$parent.types) return []
      return this.$parent.types.filter(t => this.$parent.boardConfigs.some(s => s.workItemType == t.value))
    },
	},
	methods: {
		editApproval(approval) {
			this.$refs.editApproval.open(approval)
				.then(res => {
					if(!approval)
						this.$parent.config.approvals.push(res)
					else
						approval = Object.assign(approval, res)
				})
		},
		async deleteApproval(approval) {
			const confirmed = await this.$openConfirmDialog({
					title: 'Delete approval',
					description: `Are you sure you want to delete approval for <b>${approval.approverBlueTag || approval.approverFullName}</b>?`,
				})
			if(!confirmed) return

			this.$http.delete(`api/project/approval/${approval.id}`)
				.then(() => {
					this.$parent.config.approvals.splice(this.$parent.config.approvals.findIndex(x => x.id == approval.id), 1)
				})
				.catch(err => {
					console.error(err)
					this.$root.error('Failed to delete approval')
				})
		},
		getRole(id){
			return this.$parent.members.find(x => x.id == id)?.role
		},
	},
}
</script>

<style lang="scss" scoped>

</style>