<template>
  <v-container grid-list-md fill-height fluid>
    <v-layout column fill-height justify-start>
      <div class="sticky">
        <span>My Kanban is private and only you have access to it.</span>
      </div>  
      <v-flex class="nav-container">
        <v-tabs fixed-tabs v-model="activeTab" show-arrows>
          <v-tabs-slider color="primary"></v-tabs-slider>
          <v-tab
            v-for="tab in tabs" :key="tab.name"
            :to="tab.to"
          >
            {{tab.title}}
          </v-tab>

          <v-tabs-items v-model="activeTab">
            <v-tab-item v-for="tab in tabs" :key="tab.name" :id="tab.to">
              <v-card flat>
                <v-card-text style="padding-bottom: 2px;">
                  <router-view 
                    v-if="activeTab === tab.to && item.id"
                    :canEditProject="true"
                    :isInternal="true"
                    projectName="My Kanban"
                    :isPersonal="true"
                    :id="item.id"
                    :socket="socket"
                  ></router-view>
                </v-card-text>
              </v-card>
            </v-tab-item>
          </v-tabs-items>
        </v-tabs>   
      </v-flex> 
    </v-layout>
  </v-container>
</template>

<script>
import _ from "lodash"

export default {
  props: ['socket'],
  data: function() { 
    return {
      componentName: "ProjectDetails",
      item: {},
      activeTab: null,
      tabs: [],
      current: '',
      canEditProject: false,
    };
  },
  computed: {
  },
  created() {
    document.title = 'My kanban'
    this.$q.log(3, 20, 3, 'NA')
    this.init();
    this.current = this.$router.history.current.path.split("/");
    this.current = this.current[this.current.length-1]
  },
  methods: {
    init() {
      this.tabs =  [
        { name: 'board', title: 'Board', to: `/myKanban` },
        { name: 'links', title: 'Links', to: `/myKanban/links` },
        { name: 'config', title: 'Config', to: `/myKanban/config` },
      ]

      this.$http
        .get(`/api/myKanban`)
        .then(response => {
          this.item = response.data
        })
        .catch(response => {
          window.getApp.error("Cannot get record.");
          console.log(response);
        });
    },
  }
    
}
</script>

<style scoped lang="scss">
  .check-icon {
    margin: 0;
  }

  // HACK: Vuetify Tab height bug workaround https://github.com/vuetifyjs/vuetify/issues/6962
  ::v-deep .v-tabs .v-window__container { 
    height: auto !important;
  }

  .nav-container ::v-deep .v-slide-group, .sticky{
    position: sticky;
    left: 120px;
    width: 400px;
  }
</style> 