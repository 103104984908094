<template>
  <v-dialog v-model="dialog" persistent max-width="700px">
    <v-card>
      <v-card-title>
        <span class="headline">
          Tag
        </span>
        <br>
        <div class="cyan--text" v-if="!tag.id">New</div>
        <div class="cyan--text" v-if="tag.id">Edit</div>
      </v-card-title>
        <v-card-text>
          <v-container>

          <v-row>
            <v-col :cols="12">
              <v-text-field label="Name" v-model="tag.name"></v-text-field>
            </v-col>
            <v-col :cols="12">
              <v-textarea label="Description" v-model="tag.description" rows="2" auto-grow></v-textarea>
            </v-col>
            <v-col :cols="12">
              <v-select placeholder="For Type*" multiple :items="shownTypes" item-value="value" item-text="displayName" v-model="tag.types">
                <template v-slot:item="{ item }">
                  <div v-if="item.value !== selectedType">
                    <v-icon v-if="tag.types && tag.types.includes(item.value)" color="primary">mdi-checkbox-marked</v-icon>
                    <v-icon v-else>mdi-checkbox-blank-outline</v-icon>
                    <v-icon :color="item.color" class="mr-2">{{item.iconClass}}</v-icon>
                    {{item.displayName}}
                  </div>
                  <v-list-item v-else>
                    <v-icon disabled v-if="tag.types && tag.types.includes(item.value)" color="primary">mdi-checkbox-marked</v-icon>
                    <v-icon v-else>mdi-checkbox-blank-outline</v-icon>
                    <v-icon :color="item.color" class="mr-2">{{item.iconClass}}</v-icon>
                    {{item.displayName}}
                  </v-list-item>
                </template>
                <template v-slot:selection="{ item }">
                  <div class="mr-2 mb-1 rounded pa-2" style="border: 1px solid lightgrey">
                    <v-icon :color="item.color" class="mr-0">{{item.iconClass}}</v-icon>
                    {{item.displayName}}
                  </div>
                </template>
              </v-select>
            </v-col>
            <v-row>
              <v-col :cols="7">
                <label>Color</label>
                <color-picker
                  v-model="tag.color"
                  :tags="true"
                />
              </v-col>
              <v-col cols="5">
                <v-switch :disabled="!tag.id" hide-details inset class="small-switch" label="Active" v-model="tag.active"></v-switch>
              </v-col>
            </v-row>
          </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click.native="close">Close</v-btn>
          <v-btn color="primary" :disabled="!tag.types || !tag.types.length" @click.native="save()">Save</v-btn>
        </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  props: {
    callback: { type: Function, default: () => {} }
  },
  name: 'CreateTagDialog',
  data() {
    return {
      dialog: false,
      tag: {},
      types: [],
      boardConfigs: [],
      config: {},
      selectedType: undefined
    }
  },
  computed: {
    shownTypes() {
      if (!this.boardConfigs || !this.types) return []
      return this.types.filter(t => this.boardConfigs.some(s => s.workItemType == t.value))
    }
  },
  methods: {
    async open(projectId, tag, selectedType) {
      this.$http.get(`api/project/${projectId}/config?active=true`)
        .then(res => {
          this.config = res.data
          this.boardConfigs = this.config.boardConfigs.sort((a, b) => {
            return a.workItemType - b.workItemType
          })
        })
        .catch(() => {
          window.getApp.error("Cannot get project config")
          this.close()
        })
      this.$http.get(`/api/Enums/WorkItemType`)
        .then(res => {
          this.types = res.data
        })
        .catch(() => {
          window.getApp.error("Cannot get project types")
          this.close()
        })
      this.dialog = true
      if(tag?.id) {
        this.tag = tag
        if(this.tag.description)
          this.tag.description = JSON.parse(this.tag.description)
      } else {
        this.tag = {
          projectId,
          status: 0, // Not Started
          color: '#1AFF85FF',
          types: [],
          active: true,
        }
      }
      if(typeof selectedType == 'number') {
        this.selectedType = selectedType
        this.tag.types.push(selectedType)
      }
    },
    close() {
      this.dialog = false
      this.tag = {}
    },
    save() {
      let tagToSave = JSON.parse(JSON.stringify(this.tag))
      tagToSave.description = JSON.stringify(tagToSave.description || "")
      tagToSave.color = tagToSave.color.hexa || tagToSave.color

      this.$q.save({
        api: `/api/projectTag`,
        data: tagToSave,
        successMsg: "Tag saved.",
        afterSuccessUrl: null,
        errorMsg: "Cannot save tag.",
        afterErrorUrl: null,
      }).then(res => {
        this.close()
        this.callback(res.data)
      })
    }
  }
}
</script>

<style scoped>
  .headline {
    width: 100%;
  }
</style>