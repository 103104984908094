<template>
	<div>
		<div class="ma-3" v-if="!selectedBadge">
			<div class="mb-5">
				<v-btn v-if="permissions.add" @click="openDialog" fab color="primary" class="inline-block">
					<v-icon large dark>add</v-icon>
				</v-btn>
			</div>

			<div class="badges ml-2">
				<div class="badge-container elevation-5" v-for="(badge, ix) in badges" :key="ix" @click="permissions.detail ? openDetails(badge) : null">
					<div class="text-center img-container">
						<v-img class="inline-block" :src="formatHttp(badge.imgUrl)" height="200" contain></v-img>
					</div>
					<div class="text">
						<div>
							{{ badge.name }}
						</div>
					</div>
				</div>
			</div>
		</div>
		<div v-else class="ma-5 badge-details">
			<v-card class="mx-auto bordered" flat max-width="100%" fill-height>
					
				<div class="cover-image primary">
					<v-btn @click="goBack" fab absolute :style="{ top: 0, left: 0 }" icon dark> <v-icon>mdi-arrow-left</v-icon> </v-btn>
					<v-btn v-if="permissions.edit" @click="editBadge(badges.find(b => b.id == selectedBadge.id))" fab absolute :style="{ top: 0, right: 0 }" icon dark> <v-icon>edit</v-icon> </v-btn>
					<div class="details">
						<div></div>
						<h2 :title="selectedBadge.name">{{selectedBadge.name}}</h2>
						<div>{{selectedBadge.description}}</div>
					</div>
					<div class="avatar-container">
						<div class="points">Points: {{selectedBadge.points}}</div>
						<v-avatar class="avatar elevation-5" color="white" size="180">
							<v-img contain :src="formatHttp(selectedBadge.imgUrl)"></v-img>
						</v-avatar>
					</div>
				</div>

				<v-card-title>
					People
					<v-btn @click="addUser" v-if="!editing && permissions.edit" fab color="primary" small class="ml-2 inline-block">
						<v-icon dark>add</v-icon>
					</v-btn>
					<v-btn @click="editing = true" v-if="!showSaveBtn && !editing && permissions.edit" fab color="primary" small class="ml-2 mr-3 inline-block">
						<v-icon dark>mdi-pencil</v-icon>
					</v-btn>
					<v-btn @click="save" v-if="showSaveBtn || editing" color="primary" class="ml-3">
						Save
					</v-btn>
					<v-btn @click="cancel" outlined v-if="showSaveBtn || editing" color="error" class="ml-3">
						Cancel
					</v-btn>
				</v-card-title>
				<v-card-text>
					<v-data-table
						:headers="headersUsers"
						:items="selectedBadge.users"
						class="new-back lighten2 bordered rounded-lg"
						sort-by="applicationUserBlueTag"
					>
						<template v-slot:[`item.applicationUserBlueTag`]="{ item }">
							<div v-if="!item.isNew && !editing">
								{{ item.applicationUserBlueTag }}
							</div>
							<v-autocomplete
								v-else outlined hide-details class="selectable inline-block" label="BlueTag*" :items="shownUsers" 
								:error="!item.applicationUserId && sended"
                item-value="id" item-text="blueTag"
                v-model="item.applicationUserId" dense
								item-disabled="disabled"
							>
              </v-autocomplete>
						</template>
						<template v-slot:[`item.applicationUserName`]="{ item }">
							<div v-if="!item.isNew && !editing">
								{{ item.applicationUserName }}
							</div>
							<v-autocomplete
								v-else outlined hide-details class="selectable inline-block" label="Name*" :items="users" 
								:error="!item.applicationUserId && sended"
                item-value="id" item-text="fullName"
                v-model="item.applicationUserId" dense
							>
              </v-autocomplete>
						</template>
						<template v-slot:[`item.dateEarned`]="{ item }">
							<div v-if="!item.isNew && !editing">
								{{ formatDate(item.dateEarned) }}
							</div>
							<div v-else style="max-width: 200px; padding: 8px 0;">
              	<q-date-picker
									label="Date*" v-model="item.dateEarned"
									inner outlined dense hide-details
									:error="!item.dateEarned && sended"
								></q-date-picker>
							</div>
						</template>
						<template v-slot:[`item.expirationDate`]="{ item }">
							<div v-if="!item.isNew && !editing">
								{{ item.expirationDate ? formatDate(item.expirationDate) : '' }}
							</div>
							<div v-else style="max-width: 200px; padding: 8px 0;">
              	<q-date-picker
									label="" v-model="item.expirationDate"
									inner outlined dense hide-details
									:error="!item.expirationDate && sended"
									clearable
								></q-date-picker>
							</div>
						</template>
						<template v-slot:[`item.countdown`]="{ item }">
							<div v-if="item.expirationDate">
								<div v-if="getDifference(item.expirationDate) >= 0">
									{{ getCountdown(item.expirationDate) }}
								</div>
								<v-tooltip right v-else color="error" :disabled="item.isNew">
									<template v-slot:activator="{ on }">
										<div v-on="on" style="display: inline-block;" class="error--text pr-5">
											{{ getCountdown(item.expirationDate) }}
										</div>
									</template>
									<span>This badge has expired.</span>
							</v-tooltip>
							</div>
						</template>
						<template v-slot:[`item.delete`]="{ item }">
							<v-menu left offset-x>
								<template #activator="{ on }">
									<v-btn v-on="on" icon><v-icon>mdi-dots-vertical</v-icon></v-btn>
								</template>
								<v-list dense class="py-1">
									<v-list-item class="pa-1 py-0">
										<v-tooltip right z-index="200">
											<template #activator="{ on }">
												<v-btn v-on="on" text small color="error" @click="removeUser(item)">
													<v-icon class="cursor-pointer">mdi-delete</v-icon>
												</v-btn>
											</template>
											Remove
										</v-tooltip>
									</v-list-item>
								</v-list>
							</v-menu>

						</template>
					</v-data-table>
				</v-card-text>

			</v-card>
		</div>
		<BadgeDialog ref="badgeDialog"/>
	</div>
</template>

<script>
import moment from 'moment'
import BadgeDialog from '../../dialogs/BadgeDialog'
export default {
	title: 'Badges',
	components: {
		BadgeDialog,
	},
	data(){
		return {
			badges: [],
			selectedBadge: null,
			headersUsers: [
				{ text: "BlueTag", value: "applicationUserBlueTag" },
				{ text: "Name", value: "applicationUserName" },
				{ text: "Date", value: "dateEarned" },
				{ text: "Expiration Date", value: "expirationDate" },
				{ text: "Countdown", value: "countdown" },
				{ text: "", value: "delete", width: "50" },
			],
			users: [],
			currentNewIndex: 1,
			sended: false,
			editing: false,

			permissions: {
				detail: false,
				add: false,
				edit: false,
			},
		}
	},
	mounted(){
		this.$security.hasRequiredPermission('detail')
			.then(res => this.permissions.detail = res)
		this.$security.hasRequiredPermission('add')
			.then(res => this.permissions.add = res)
		this.$security.hasRequiredPermission('edit')
			.then(res => this.permissions.edit = res)

    this.$q.log(6, 31, 3, 'NA')
		this.init()
	},
	watch: {
		$route(){
			if(this.$route.fullPath == '/badges'){
				this.editing = false
				this.selectedBadge = null
				this.sended = false
			}
		}
	},
	computed: {
		shownUsers(){
			let currentIds = this.selectedBadge.users.map(u => u.applicationUserId)
			return this.users.map(u => {
				u.disabled = currentIds.includes(u.id)
				return u
			})
		},
		selectedUserIds(){
			return this.selectedBadge.users.map(u => u.applicationUserId)
		},
		showSaveBtn(){
			return this.selectedBadge && this.selectedBadge.users.filter(u => u.isNew).length > 0
		},
	},
	methods: {
		init(){
			this.$http.get('api/badge').then(res => {
				this.badges = res.data.sort((a, b) => {
					if(a.name.toLowerCase() < b.name.toLowerCase()) { return -1; }
					if(a.name.toLowerCase() > b.name.toLowerCase()) { return 1; }
					return 0
				})
				if (this.$route.query.badge) {
					let badge = this.badges.find(b => b.id == this.$route.query.badge)
					if(badge)
						this.$nextTick(() => {
							document.title = `Badge - ${badge.name}`
							this.selectedBadge = JSON.parse(JSON.stringify(badge))
						})
				}
			})
			this.$http.get('api/user?active=true&onlyInterns=true')
				.then(res => {
					this.users = res.data
				})
		},
		openDialog(){
			this.$refs.badgeDialog.open(
				{
					name: '',
					description: '',
					users: [],
					imgUrl: '',
				}
			).then(() => {
					this.init()
				})
		},
		getDifference(date){
			return moment(date).diff(moment(), 'minutes')
		},
		getCountdown(date){
			return this.getIndicatorTime(this.getDifference(date), true)
		},
		editBadge(badge){
			this.$refs.badgeDialog.open(Object.assign({}, badge))
				.then(newBadge => {
					this.selectedBadge = newBadge
					this.init()
				})
		},

		openDetails(badge){
			document.title = `Badge - ${badge.name}`
			this.selectedBadge = JSON.parse(JSON.stringify(badge))
			this.$router.push('?badge=' + badge.id)
		},
		goBack(){
			this.$router.push('/badges')
			document.title = 'Badges'
		},
		
		async removeUser(user){
			if(!user.isNew){
				let res = await this.$vuetifyConfirmDialog.open(
					'Confirm remove',
					'Are you sure you want to remove the badge from this person?',
					"Cancel",
					"Confirm"
				)
				if(!res) return
				await this.$http.delete(`api/badge/${this.selectedBadge.id}/removeUser/${user.applicationUserId}`);
			}
			this.selectedBadge.users = this.selectedBadge.users.filter(u => u.id != user.id)
			let newUsersQuantity = this.selectedBadge.users.filter(u => u.isNew).length
			if(!newUsersQuantity) this.sended = false
		},
		addUser(){
			this.selectedBadge.users = [{
				applicationUserId: null,
				applicationUserBlueTag: '',
				applicationUserName: '',
				dateEarned: moment().format('MMM D, YYYY'),
				expirationDate: null,
				id: this.currentNewIndex++,
				isNew: true,
			}].concat(this.selectedBadge.users)
		},
		cancel(){
			this.selectedBadge = JSON.parse(JSON.stringify(this.badges.find(b => b.id == this.selectedBadge.id)))
			this.editing = false
		},
		save(){
			for(let user of this.selectedBadge.users){
				if(!user.applicationUserId || !user.dateEarned){
					this.sended = true
					window.getApp.error("Fill all the required fields")
					return
				}
				if(user.isNew){
					delete user.isNew
					delete user.id
				}
			}
			this.$http.post(`/api/badge`, this.selectedBadge).then(res => {
        this.selectedBadge = res.data
				this.sended = false
				this.editing = false
				this.init()
				window.getApp.success("Updated successfully.")
			})
			.catch(err => {
				console.error(err)
				window.getApp.error("Cannot save badge.")
			})
		},
	},
}
</script>

<style scoped lang="scss">
.inline-block {
	display: inline-block;
}

.badge-container {
	float: left;
	width: 200px;
	box-sizing: content-box;
	border-radius: 5px;
	margin-bottom: 22px;
	margin-right: 22px;
	cursor: pointer;
	position: relative;
	overflow: hidden;

	.img-container {
		height: 200px;
	}

	.text {
		display: flex;
		background: var(--v-background-lighten3) !important;
		align-items: center;
		justify-content: center;
		padding: 5px;
		text-align: center;
		height: 45px;
		overflow: hidden;
		line-height: 22px;
		text-overflow: ellipsis;
		div{
			display: -webkit-box;
			-webkit-line-clamp: 2;
			-webkit-box-orient: vertical;
		}
	}
}
.badge-container:hover {
	.card-btn{
		display: inline-block;
	}
}

::v-deep .v-list-item--disabled {
	display: none;
}
.badge-details {

	@media (max-width: 1262px) {
		.details{
			width: calc(100% - 310px) !important;
		}
	}

	.cover-image {
		width: 100%;
		height: 200px;
		overflow: hidden;
		.details{
			margin-left: 45px;
			padding-bottom: 10px;
			color: white;
			width: calc(75% - 185px);
			height: 100%;
			display: grid;
			grid-template-rows: auto max-content max-content;
			
			h2{
				margin-bottom: 0 !important;
				font-size: 2em;
				line-height: 2.5rem;
				margin-bottom: 10px;
			}
			div{
				font-size: 1.3em;
				font-weight: 300;
			}
		}
		.avatar-container{
			position: absolute;
			top: 10px;
			right: 50px;

			.points{
				position: absolute;
				left: -70px; bottom: 0;
				padding: 2px 7px;
				border-radius: 5px;
				background: darkblue;
				color: white;
			}
			.avatar{
				border: 8px solid white !important;
			}
		}
	}
}
</style>