// import config from './../config'
import moment from "moment"
import store from '../store'

const BlueSystem = {
  install (Vue, options) {

    Vue.mixin({
      created() {
        let currentUser = null;
        let currentUserPermissions = null;
        let version = null;
        let vueInstance = this;
        this.getBackendUrl = function (url) {
          if (!url) return window._config.backendUrl
          return new URL(url, window._config.backendUrl).href
        }
        this.$blueSystem = {
          config: window._config,
          getVersion() {
            let promise = new Promise((resolve, reject) => {

              if (version) {
                resolve(version);
              } else {
                vueInstance.$http.get(`/api/instance/info`, { headers: { hideLoading: true } })
                  .then(res => {
                    version = res.data.version;
                    resolve(version);
                  })
                  .catch(res => {
                    reject(res);
                    window.getApp.error("Cannot obtain version.");
                    console.log(res);
                  })
              }
            });
            return promise;

          },
          getCurrentUser() {
            const { state } = store
            const { user } = state
            if(user.id) {
              return new Promise(resolve => resolve(user))
            }
            return new Promise((resolve, reject) => {
              vueInstance.$http.get(`/api/instance/currentUser`)
                .then(res => {
                  store.commit('setProperty', { property: 'user', value: res.data })
                  resolve(res.data);
                  return res.data
                })
                .catch(res => {
                  reject(res);
                  console.log(res);
                })
            })
          },
          clearCurrentUser() {
            store.commit('setProperty', { property: 'user', value: {} })
          },
          getCurrentUserPermissions(forceUpdate = true) {
            const { state } = store
            const { permissions, permissionsPromise } = state
            if(!forceUpdate) {
              if(permissions.userId) {
                return new Promise(resolve => resolve(permissions))
              }
              if(permissionsPromise) {
                return permissionsPromise
              }
            }
            return new Promise((resolve, reject) => {
              let permissionsPr = vueInstance.$http.get(`/api/instance/currentUser/permissions`)
                .then(res => {
                  store.commit('setProperty', { property: 'permissions', value: res.data })
                  resolve(res.data);
                  return res.data
                })
                .catch(res => {
                  reject(res);
                  vueInstance.$router.push({path: 'login', query: {redirect: window.location.pathname}})
                  console.log(res);
                })
              store.commit('setProperty', { property: 'permissionsPromise', value: permissionsPr })
            });
          },
          checkConfetti(){
            this.getCurrentUser()
              .then((res) => {
                let user = res
                vueInstance.$http.get('/api/instance/currentTime', { headers: { hideLoading: true } }) 
                  .then(res => {
                    let now = moment(res.data)
                    const anniversary = moment(now.format('YYYY') + '-04-06')
                    const valentines = moment(now.format('YYYY') + '-02-14')
                    const halloween = moment(now.format('YYYY' + '-10-31'))
                    store.commit('setProperty', { property: 'isAnniversary', value: now.isSame(anniversary, 'month') })
                    let options = null
                    let duration = 2000
                    if(user.birthdate && moment(user.birthdate).date() == moment().date() && moment(user.birthdate).month() == moment().month()){
                      options = {}
                    }
                    else if(user.blueniversary && moment(user.blueniversary).date() == moment().date() && moment(user.blueniversary).month() == moment().month()){
                      options = { defaultColors: [ 'blue', 'white' ] }
                    }
                    else if(now.isSame(anniversary, 'day')){
                      options = {
                        particles: [
                          {
                            type: 'image',
                            url: require('../assets/aniversary.png'),
                            size: 30,
                          },
                          {
                            type: 'image',
                            url: require('../assets/aniversary_blue.png'),
                            size: 30,
                          },
                          {
                            type: 'circle',
                            colors: ['white', '#167bd7'],
                          },
                        ],
                        particlesPerFrame: 1,
                        defaultDropRate: 7,
                      }
                      duration = 3000
                    }
                    else if(now.isSame(anniversary, 'isoWeek')){
                      options = {
                        defaultColors: [ 'blue', 'white' ],
                        particlesPerFrame: 1,
                      }
                    }
                    else if(now.isSame(valentines, 'day')){
                      options = {
                        particles: [
                          {
                            type: 'heart',
                            colors: [
                              'red',
                              'pink',
                            ],
                            size: 30,
                          }
                        ]
                      }
                    }
                    else if(now.isSame(halloween, 'month')) {
                      options = {
                        particles: [
                          {
                            type: 'image',
                            url: require('../assets/halloween-orange.svg'),
                            size: 30,
                          },
                          {
                            type: 'image',
                            url: require('../assets/halloween-purple.svg'),
                            size: 30,
                          },
                          {
                            type: 'circle',
                            colors: ['purple', 'orange'],
                          },
                        ],
                        particlesPerFrame: 1,
                        defaultDropRate: 7,
                      }
                      duration = 3000
                    }
                    if(options){
                      vueInstance.$confetti.start(options)
                      setTimeout(() => { vueInstance.$confetti.stop() }, duration)
                    }
                  })
              })
          },
        };
      },
      methods: {
        setUserPreferences(componentName, ...vars) {
          
          // Set cookie values to properties
          let cookieValue = this.$q.getCookie(componentName)
          if (cookieValue) {
            cookieValue = JSON.parse(cookieValue)

            vars.forEach(vName => {
              if (typeof cookieValue[vName] !== "undefined") {
                this[vName] = cookieValue[vName]
              }
            });
          }

          vars.forEach(vName => {
            this.$watch(vName, (newValue) => {

              // Get object from cookie
              let cookieValue = this.$q.getCookie(componentName)
              if (cookieValue) {
                cookieValue = JSON.parse(cookieValue)
              }

              if (!cookieValue || typeof cookieValue != "object") {
                cookieValue = {}
              }

              cookieValue[vName] = newValue

              // Save object into cookie
              cookieValue = JSON.stringify(cookieValue)
              this.$q.setCookie(componentName, cookieValue, 365)

            }, { deep: true })
          })
        },
      }
    });
  }
}
export default BlueSystem
