<template>
  <div>
    <v-dialog v-if="dialog" v-model="dialog" width="480">
      <v-card class="py-3 new-back">
        <v-card-title class="justify-space-between">
          <v-row>
            <v-col>
              <h3 style="
                font-size: 16px;
                font-weight: 700;
                line-height: 22px;
                letter-spacing: 0em;
                text-align: left;
              ">
                {{ item.id ? 'Edit' : '' }} Request Vacation
              </h3>
            </v-col>
            <v-col>
              <div v-if="!userSelection" class="body-2 d-flex align-center">
                <v-checkbox v-model="includeHolidays" class="mt-n1 pt-0" hide-details/>
                <label style="word-break: normal;">Include holidays and weekends</label>
              </div>
            </v-col>
          </v-row>
        </v-card-title>
        <v-card-text class="d-flex justify-center flex-column mt-3">
          <v-row class="mb-5">
            <template v-if="userSelection">
              <v-col :cols="6" class="d-flex align-center">
                <q-user-autocomplete
                  v-model="item.requestorId"
                  @change="fetchHolidays"
                  :items="users"
                  label="User"
                  :confirmable="false"
                  outlined dense
                />
              </v-col>
              <v-col :cols="6" class="d-flex align-center">
                <div class="body-2 d-flex align-center">
                  <v-checkbox v-model="includeHolidays" class="mt-n1 pt-0" hide-details/>
                  <label style="word-break: normal;">Include holidays and weekends</label>
                </div>
              </v-col>
              <v-col :cols="12" class="d-flex justify-center">
                <div class="body-2">
                  <v-select
                    v-model="item.type" :items="types"
                    label="Type" item-text="name" item-value="id"
                    outlined dense hide-details
                    :rules="[$rules.requiredNumber]"
                  />
                </div>
              </v-col>
            </template>
            <v-col :cols="6">
              <input type="radio" id="one" :value="0" v-model="requestDateType">
              <label class="ml-1" for="one" style="font-weight: 400;">Request Time Window</label>
            </v-col>
            <v-col :cols="6">
              <input type="radio" id="two" :value="1" v-model="requestDateType">
              <label class="ml-1" for="two" style="font-weight: 400;">Request Specific dates</label>
            </v-col>
          </v-row>
          <v-date-picker
            class="date-picker new-back"
            v-model="item.lines"
            :multiple="specificDates"
            :range="timeWindow"
            no-title :disabled="userSelection && !item.requestorId"
            :allowed-dates="isAllowedDate"
          />
          <br>
          <div style="width: 290px; margin: auto;">
            <label for="Comments">Comments (optional)</label>
            <v-text-field
              width="290"
              outlined
              hide-details
              v-model="item.requestorComments"
              class="comments rounded-lg"
            ></v-text-field>
          </div>
        </v-card-text>
        <v-card-actions class="justify-end">
          <v-btn outlined color="#0071F2" class="rounded-lg" @click=" dialog = false">Cancel</v-btn>
          <v-btn color="#0071F2" class="rounded-lg" style="color: white" @click=" save()">Request {{ item.type === 0 ? 'Vacation' : '' }} Days</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <blue-confirm-dialog ref="confirmDialog" _title="Confirm Request">
      <template #text>
        <div class="mt-0 text1">
          You are about to request vacation days. Please, check that the data is correct and confirm your request
        </div>
        <div class="text1 mt-6">
          Requested Days:
          <b>
            {{ item.lines.length }}
            (
            <span
              v-for="d in item.lines"
              :key="d"
            >
              {{ formatDate(d) }}
            </span>
            )
          </b>
        </div>
        <div class="text1 mt-2">
          Remaining Days:
          <b>
            {{ recessDays - item.lines.length }}
          </b>
        </div>
        <div class="text1 mt-3" v-if="item.requestorComments.length">
          Comments:
          <br>
          <div class="mt-2">
            {{ item.requestorComments }}
          </div>
        </div>

      </template>
      <template #actions="{ cancel, confirm }">
        <v-spacer/>
        <v-btn class="rounded-lg" @click="cancel" color="primary" outlined>Cancel</v-btn>
        <v-btn class="rounded-lg" @click="confirm()" color="primary">
          Confirm
        </v-btn>
      </template>
    </blue-confirm-dialog>
    <blue-confirm-dialog
      ref="confirmedVacations"
      :_title="
        item.type === 0
          ? 'Vacation'
          : item.type === 1
            ? 'Unpaid Leave'
            : 'Paid Leave'
        + ' Requested'
      "
    >
      <template #text>
        <div class="mt-0 text1">
          Your
          {{ 
            item.type === 0
              ? 'vacation'
              : item.type === 1
                ? 'unpaid leave'
                : 'paid leave'
          }}
          request has been received and is being processed. We will give you an answer as soon as possible
        </div>
      </template>
      <template #actions="{ cancel }">
        <v-spacer/>
        <v-btn class="rounded-lg" @click="() => finish(cancel)" color="primary">
          Got it
        </v-btn>
      </template>
    </blue-confirm-dialog>
  </div>
</template>

<script>
import moment from "moment"
import _ from 'lodash'
export default {
  props: {
    userSelection: Boolean,
  },
  data: () => ({
    dialog: false,
    item: {
      requestorComments: "",
      lines: [],
      type: 0,
    },
    requestDateType: 1,
    recessDays: 0,
    resolve: undefined,
    reject: undefined,
    datePickerFormat: 'YYYY-MM-DD',
    doNotIncludeWeekends: true,
    includeHolidays: false,
    users: [],
    holidays: [],
    types: [],
  }),
  watch: {
    requestDateType(newVal, oldVal) {
      if(newVal == 1 && this.item.lines?.length > 1) {
        let dates = this.getDaysBetweenTimeWindow()
        this.item.lines = dates
      } else if(newVal == 0 && this.item.lines?.length > 1) {
        let dates = this.item.lines.map(d => moment(d))
        var maxDate = moment.max(dates).format(this.datePickerFormat).toString()
        var minDate = moment.min(dates).format(this.datePickerFormat).toString()
        this.item.lines = [maxDate, minDate]
      }
    }
  },
  computed: {
    specificDates() {
      return this.requestDateType == 1
    },
    timeWindow() {
      return this.requestDateType == 0
    },
  },
  methods: {
    isAllowedDate(date) {
      return this.includeHolidays || !this.holidays.some(x => moment(x).isSame(date, 'day'))
    },
    formatDate(date) {
      return moment(date).format("MMM-DD")
    },
    getDaysBetweenTimeWindow() {
      var dates = _.cloneDeep(this.item.lines);
      if(dates.length < 2) return dates
      dates = dates.map(d => moment(d))

      let startDate = moment.min(dates);
      let endDate = moment.max(dates)
      dates = []

      dates.push(startDate.format(this.datePickerFormat).toString())
      startDate = startDate.clone().add(1, 'day')

      while(!startDate.isSame(endDate)) {
        dates.push(startDate.format(this.datePickerFormat).toString());
        startDate = startDate.clone().add(1, 'days');
      }
      dates.push(endDate.format(this.datePickerFormat).toString())
      return dates;
    },
    async save() {
      if(this.timeWindow)
        this.requestDateType = 1

      if(!this.item.lines.length) {
        this.$root.error("No dates salected")
        return
      }

      let requestGoogleLogin = false
      try {
        const res = await this.$http.get('api/google/hasScope/calendar.events')
        requestGoogleLogin = !res.data
      } catch (error) {
        requestGoogleLogin = true
      }
      if(requestGoogleLogin) {
        await this.$gapi.grantOfflineAccess().then(async res => {
          await this.$http.post(`api/google/linkAccount`, {code: res, url: window.location.origin})
        })
      }

      this.openConfirmDialog()
    },
    open(opts = {}) {
      this.item = {
        requestorComments: "",
        lines: [],
        type: 0,
      }
      this.init()
      if(opts.id) {
        opts = Object.assign({}, opts)
        opts.lines = opts.lines.map(line =>
          moment(line.date).format(this.datePickerFormat).toString()
        )
        this.item = Object.assign(this.item, opts)
      }
      this.dialog = true;
      return new Promise((resolve, reject) => {
        this.resolve = resolve
        this.reject = reject
      })
    },
    formatRecessRequest() {
      this.item.lines = this.item.lines.map(d => ({
        date: d
      }))
    },
    async openConfirmDialog() {
      this.item.lines = this.item.lines.sort((a, b) => moment(a).diff(moment(b)))
      if(this.item.type === 0) {
        const res = await this.$refs.confirmDialog.open()
        if(!res)
          return
      }
      const item = structuredClone(this.item)
      item.lines = item.lines.map(d => ({
        date: d
      }))
      this.$q.save({
        api: `/api/recess/request`,
        data: item,
        successMsg: null,
        afterSuccessUrl: null,
        errorMsg: "Cannot make request.",
        afterErrorUrl: null,
      })
        .then(() => {
          this.dialog = false;
          this.$refs.confirmedVacations.open()
        })
    },
    finish(cancel) {
      this.resolve()
      this._data = Object.assign(this._data, this.$options.data())
      cancel()
    },
    init() {
      this.$http.get(`api/user/recessDays/current`)
        .then(res => {
          this.recessDays = res.data
        })
				.catch(error => this.$root.error('Failed to get user'))

      this.$http.get(`api/enums/recessRequestTypes`)
        .then(res => {
          this.types = res.data
        })
				.catch(error => {
          console.error(error)
          this.$root.error('Failed to get types')
        })
      
      if(this.userSelection) {
        this.$http.get('api/user?onlyInterns=true&active=true')
          .then(res => {
            this.users = res.data
          })
          .catch(error => {
            console.error(error)
            this.$root.error('Failed to get users')
          })
      }
      else {
        this.fetchHolidays()
      }
    },
    fetchHolidays() {
      const userId = this.userSelection ? this.item.requestorId : null
      this.$http.get('api/recess/holidays?userId=' + userId)
        .then(res => {
          this.holidays = res.data.map(x => x.date)
        })
        .catch(error => {
          console.error(error)
          this.$root.error('Failed to get holidays')
        })
    },
  }
}
</script>

<style scoped lang="scss">
.date-picker ::v-deep {
  &.v-btn--rounded {
    border-radius: 10px;
  }
  &.v-btn--active {
    background-color: #3D73EB !important;
  }
  &.v-picker__body {
    border-radius: 12px !important;
    border: 2px solid #E5E5E5
  }
}

.container {
  margin: 0 auto;
  width: max-content;
}
.comments ::v-deep .v-input__slot {
  &.v-input__slot {
    border: 2px solid #E5E5E5
  }
  fieldset {
    border: none
  }
}
::v-deep .v-picker__body {
  background: var(--v-newDesignBackground-lighten2) !important;
}
.v-btn {
  text-transform: none !important;
}
.text1 {
  font-size: 1.2em;
  font-weight: 300;
}

</style>