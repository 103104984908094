
<template>
  <div class="pa-3 new-back fill-height">
    <v-menu v-model="showDailyContextMenu" :min-width="150" :position-x="xDailyMenu" :position-y="yDailyMenu" absolute offset-y >
      <v-list dense class="py-1">
        <v-list-item class="pr-2" @click="reopen">
          <v-icon left>mdi-lock-open-outline</v-icon>
          <small>Reopen</small>
        </v-list-item>
      </v-list>
    </v-menu>
    <div class="d-flex" style="gap: 15px">
      <q-date-picker label="From" v-model="filters.dateStart" outlined dense hide-details inner/>
      <q-date-picker label="To" v-model="filters.dateEnd" outlined dense hide-details inner/>
      <v-checkbox
        v-model="filters.activeUsers"
        label="Only Active Users"
        class="mt-1"
      />
      <v-checkbox
        v-model="filters.reportersOnly"
        label="Only Reporter Users"
        class="mt-1"
      />
      <v-btn rounded color="primary" @click="updateReport">
          Update
      </v-btn>
    </div>

    <div class="hours-table">
      <v-btn color="secondary" small rounded @click="vm.unformatted = !vm.unformatted">
          <span v-if="!vm.unformatted">Formatted</span>
          <span v-if="vm.unformatted">Unformatted</span>
      </v-btn>

      <table :class="{ 'formatted' : !vm.unformatted }" class="table table-condensed table-bordered table-striped new-back lighten2 rounded-lg">
          <colgroup>
              <col /><col />
              <col :class="{'weekend': d.isWeekend}" v-for="(d, ix) in dates" :key="ix" />
              <col class="total" />
          </colgroup>
          <thead>
              <tr>
                  <th></th>
                  <th></th>
                  <th v-for="(d, ix) in dates" :key="ix" class="text-center">
                      <small>
                          <nobr>{{d.date|formatDateCustom('MMM D')}}</nobr>
                      </small>
                  </th>
                  <th></th>
              </tr>
              <tr>
                <th class="text-right">
                      BlueTag
                  </th>
                  <th class="text-right">
                      Reporter
                      <a class="cursor-pointer"
                        popover-template="'viewUsers.html'" popover-placement="bottom">
                          <i class="fa fa-filter"></i>
                      </a>
                  </th>
                  <th v-for="(d, ix) in dates" :key="ix" class="text-center">
                      <small>
                          <nobr>{{d.date|formatDateCustom('ddd')}}</nobr>
                      </small>
                  </th>
                  <th class="text-center">
                      <small>Total</small>
                  </th>
              </tr>
          </thead>
          <tbody>
              <tr v-for="(row, ix) in orderedUsers" :key="ix">
                  <td class="text-right">
                      <nobr>
                          {{row.reporterBlueTag}}
                      </nobr>
                  </td>
                  <td class="text-right">
                      <nobr>
                          {{row.reporterFullName}}
                      </nobr>
                  </td>
                  <td
                    v-for="(col, ix) in row.hours" :key="ix"
                    class="text-center" :class="col.class"
                    @click.right="openDailyContextMenu($event, col, row)"
                  >
                      <span v-if="col.status == 2">
                        {{col.executedHours | number(2,0) }}
                      </span>
                      <span v-else>-</span>
                  </td>
                  <th class="text-center">
                      {{row.total || 0 | number(2,0)}}
                  </th>
              </tr>
          </tbody>
          <tfoot>
              <tr>
                  <th></th>
                  <th class="text-right">Total</th>
                  <th class="text-center" v-for="(rec, ix) in dates" :key="ix">
                      {{ data.totals[rec.date.toLocaleDateString()] || 0 | number(2,0) }}
                  </th>
                  <th class="text-center">
                      {{data.grandTotal || 0 | number(2,0)}}
                  </th>
              </tr>
          </tfoot>
      </table>
    </div>
  </div>
</template>

<script>
import _ from "lodash"
import moment from 'moment'

export default {
  title: 'Daily Rours Report',
  props: [],
  data: () => ({ 
    vm: {
      unformatted: false,
    },
    filters: {
      dateStart: moment().startOf('day').add(-7, 'days').format("YYYY-MM-DD"),
      dateEnd: moment().startOf('day').format("YYYY-MM-DD"),
      activeUsers: true,
      reportersOnly: true
    },
    reportData: [],
    dates: [],
    data: {},

    showDailyContextMenu: false,
    contextReport: null,
    contextReporter: null,
    xDailyMenu: 0,
    yDailyMenu: 0,
  }),
  computed: {
    orderedUsers() {
      return _(this.data.users)
        .filter(u => u.visible)
        .orderBy(['reporterFullName'])
        .value();
    },
    selectAllLabel() {
      var unselected = _.filter(this.data.users, { visible: false }).length;
      return unselected ? 'Select all' : 'Unselect all';
    }
  },
  created() {
    this.$q.log(7, 35, 3, 'NA')
    this.setUserPreferences("DailyHoursReport", "vm", "filters");
    if (this.$route.query.from) {
        this.filters.dateStart = moment(this.$route.querys.from).toDate();
    }
    if (this.$route.query.to) {
        this.filters.dateEnd = moment(this.$route.querys.to).toDate();
    }

    this.init();
  },
  methods: {
    updateReport() {
      this.init();
    },
    openDailyContextMenu(e, col, row) {
      e.preventDefault()
      if(col.status != 2)
        return
      this.xDailyMenu = e.clientX
      this.yDailyMenu = e.clientY
      this.$nextTick(() => {
        this.showDailyContextMenu = true
        this.contextReport = col
        this.contextReporter = row
      })
    },
    reopen(){
      if(!this.contextReport)
        return

      console.log(this.contextReporter)
      let date = moment(this.contextReport.date)
      this.$vuetifyConfirmDialog.open(
        'Reopen daily report',
        `Are you sure you want to reopen this day? ${date.format('MMM DD, YYYY')} for ${this.contextReporter.reporterBlueTag}`,
        "Cancel",
        "Confirm"
      ).then(res => {
        if(!res)
          return
        this.$http.post(`api/Effort/DailyReport/Reopen/${this.contextReport.reporterId}/${date.format('YYYY-MM-DD')}`)
          .then(res => {
            this.contextReport.status = res.data.status
            window.getApp.success('Daily report reopened successfully')
          })
          .catch(err => {
            console.log(err)
            window.getApp.error('Cannot reopen the daily report')
          })
      })
    },
    selectAll() {
      var unselected = _.filter(this.data.users, { visible: false }).length;

      _.each(this.data.users, user => {
          user.visible = !!unselected;
      });

    },
    updateTotals() {
      this.data.totals = {};
      this.data.grandTotal = 0;
      for(let user of this.data.users){
        this.data.grandTotal += user.total;
        for(let hour of user.hours){
          if(hour.executedHours && hour.status == 2){
            let normalicedDate = new Date(hour.date.substring(0, 10).replaceAll('-', '/')).toLocaleDateString();
            if(!this.data.totals[normalicedDate])
              this.data.totals[normalicedDate] = 0;
            this.data.totals[normalicedDate] += hour.executedHours;
          }
        }
      }
    },
    initTable() {
      if (!this.reportData) return;
      var dt = moment(this.filters.dateStart);
      this.dates = [];

      while (dt.isSameOrBefore(this.filters.dateEnd)) {
        this.dates.push({
          date: dt.toDate(),
          isWeekend: dt.day() == 6 || dt.day() == 0,
        });
        dt = dt.add(1, 'day');
      }

      this.data = {
        users: _.map(this.reportData, record => {
          return {
            visible: true,
            reporterId: record.reporterId,
            reporterFullName: record.reporterFullName,
            reporterBlueTag: record.reporterBlueTag,
            hours: _.map(this.dates, d => {

              var rec = _.find(record.hours, r => {
                return moment(r.date).isSame(d.date, 'day');
              });

              if (!rec) {
                rec = {
                  date: d.date,
                  executedHours: 0,
                };
              }
              rec.executedHours = Math.round(rec.executedHours*100)/100

              if (!d.isWeekend) {

                if (!(record.reporterDailyHoursToReport > 0 || record.reporterDailyHoursToReport === 0)) {
                  rec.class = ""
                } else if (rec.executedHours >= record.reporterDailyHoursToReport) {
                  rec.class = "x-green";
                } else if (rec.executedHours > 0 && rec.executedHours < record.reporterDailyHoursToReport) {
                  // reported hours but less than needed on week day
                  rec.class = "x-yellow";
                } else if(rec.executedHours == 0 && rec.status == 2) {
                  rec.class = "x-yellow";
                } else {
                  // 0 hours reported on week day
                  rec.class = "x-red"
                }
              }

              return rec;
            }),
            total: _.reduce(_.map(record.hours.filter(h => h.status == 2), 'executedHours'), function (a, b) {
                return a + b;
            }, 0),
          }
        }),
      };
      this.updateTotals();
    },
    init() {
      this.$http
        .post(`/api/report/dailyHours`, this.filters)
        .then(res => {
          this.reportData = res.data;
          this.initTable();
        })
        .catch(res => {
          window.getApp.error("Cannot obtain report.");
          console.log(res);
        });
    }
  },
  watch: {
  }
}
</script>

<style scoped lang="scss">
  .hours-table table {
    width: 100%;
    background: var(--v-background-lighten);
  }
  .hours-table .table.formatted .x-red {
    background-color: #ff6767;
  }

  .hours-table .table.formatted .x-yellow {
    background-color: #ffe97c;
  }

  .hours-table .table.formatted .x-green {
    background-color: #8bff6a;
  }

  .hours-table .table col.weekend, .daily-report .table col.total {
    background-color: var(--v-background-lighten2);
  }

  
/* TODO: move to general css */
table {
  border-spacing: 0;
  width: 100%;
}

/* TODO: change to material style (instead of bootstrap) */
.table-bordered thead > tr > th, 
.table-bordered > tbody > tr > th, 
.table-bordered > tfoot > tr > th, 
.table-bordered thead > tr > td, 
.table-bordered > tbody > tr > td, 
.table-bordered > tfoot > tr > td {
  border: 1px solid var(--v-lightgray-base);
}
.table-striped > tbody > tr:nth-of-type(odd) {
  background-color: var(--v-background-lighten2);
}
.table-condensed thead > tr > th, 
.table-condensed > tbody > tr > th, 
.table-condensed > tfoot > tr > th, 
.table-condensed thead > tr > td, 
.table-condensed > tbody > tr > td, 
.table-condensed > tfoot > tr > td {
  padding: 5px;
}
.table-hover > tbody > tr:hover {
  background-color: #f5f5f5;
}
</style> 