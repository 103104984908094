var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-data-table',_vm._g({ref:"table",staticClass:"rounded-lg bordered new-back lighten2",attrs:{"value":_vm.value,"headers":_vm.headers,"items":_vm.workItems,"show-select":_vm.showSelect},scopedSlots:_vm._u([{key:"body",fn:function(props){return [(_vm.$refs.table)?_c('tbody',_vm._l((props.items),function(item){return _c('v-nodes',{key:item.id,attrs:{"vnodes":_vm.$refs.table.genItems([item], props)},on:{"dragstart":function($event){return $event.dataTransfer.setData('id', item.id)}}})}),1):_vm._e()]}},{key:"item.code",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"text-no-wrap"},[_vm._v(" "+_vm._s(item.code)+" ")])]}},{key:"item.type",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"dark":"","elevation":0,"fab":"","x-small":"","color":item.typeColor}},on),[_c('v-icon',[_vm._v(_vm._s(item.typeIconClass))])],1)]}}],null,true)},[_c('span',{domProps:{"textContent":_vm._s(item.typeDisplayName)}})])]}},{key:"item.name",fn:function(ref){
var item = ref.item;
return [(item.isNew && !item.name)?_c('v-text-field',{staticClass:"inline-block",attrs:{"value":item.name,"label":"Summary*","outlined":"","dense":"","hide-details":"","single-line":""},on:{"change":function($event){return _vm.handleFirstSummaryChange($event, item)}}}):_c('div',{class:{oneline: _vm.singleLine},staticStyle:{"text-align":"left"},attrs:{"title":item.name}},[_vm._v(_vm._s(item.name))])]}},{key:"item.description",fn:function(ref){
var item = ref.item;
return [(!item.isNew)?_c('div',{class:{oneline: _vm.singleLine},staticStyle:{"text-align":"left"},domProps:{"innerHTML":_vm._s(item.description)}}):_vm._e()]}},{key:"item.statusName",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"status-container",class:{'oneline-ellipsis': _vm.singleLine},style:(("background:" + (item.statusColor) + ";"))},[(item.statusIconClass)?_c('v-icon',{attrs:{"color":"white","small":""}},[_vm._v(_vm._s(item.statusIconClass))]):_vm._e(),_vm._v(" "+_vm._s(_vm.setMaxText(item.statusName, 25))+" ")],1)]}},{key:"item.priority",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('div',{staticClass:"fill-height d-flex align-center justify-center"},[_c('v-img',_vm._g({staticStyle:{"flex":"none"},attrs:{"width":"24px","height":"24px","src":require(("../../assets/priority-icons/" + (item.priority) + ".png")),"contain":""}},on))],1)]}}],null,true)},[_c('span',{staticClass:"text-capitalize",domProps:{"textContent":_vm._s(item.priorityDisplayName)}})])]}},{key:"item.value",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('div',{staticClass:"fill-height d-flex align-center justify-center"},[_c('v-img',_vm._g({staticStyle:{"flex":"none"},attrs:{"width":"24px","height":"24px","src":require(("../../assets/priority-icons/" + (item.value) + ".png")),"contain":""}},on))],1)]}}],null,true)},[_c('span',{staticClass:"text-capitalize",domProps:{"textContent":_vm._s(item.valueDisplayName)}})])]}},{key:"item.estimatedEffort",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.estimatedEffort)+" ")]}},{key:"item.totalHours",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.roundedTotalHours(item.totalHours))+" ")]}},{key:"item.currentSprintName",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(item.currentSprintName || ''))])]}},{key:"item.allTags",fn:function(ref){
var item = ref.item;
return [_c('div',{class:{oneline: _vm.singleLine}},_vm._l((item.tags),function(tag,ix){return _c('v-chip',{key:ix,staticClass:"mr-1",style:(("color: " + (_vm.isBlackText(tag.tagColor) ? 'white' : 'black'))),attrs:{"x-small":"","dark":"","color":tag.tagColor}},[_vm._v(" "+_vm._s(tag.tagName)+" ")])}),1)]}},{key:"item.assignedToBlueTag",fn:function(ref){
var item = ref.item;
return [_c('div',[(item.assignedToId)?_c('span',[_vm._v(_vm._s(item.assignedToBlueTag || item.assignedToFullName))]):_c('i',[_vm._v("Unassigned")])])]}},{key:"item.reportedByBlueTag",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(item.reportedByBlueTag || item.reportedByFullName))])]}},{key:"item.createdAt",fn:function(ref){
var item = ref.item;
return [_c('small',[_vm._v(_vm._s(_vm._f("formatDate")(item.createdAt)))])]}}],null,true)},_vm.$listeners))}
var staticRenderFns = []

export { render, staticRenderFns }