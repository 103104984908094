
<template>
  <div id="board-container" :style="'width:' + (isInternal ? 'calc(100vw - 180px)' : 'calc(100vw - 50px)')">

    <v-menu v-model="showColumnContextMenu" :min-width="150" :position-x="xColumnMenu" :position-y="yColumnMenu" absolute offset-y >
      <v-list dense class="py-1">
        <v-list-item class="pr-2" @click="openWorkItemCreateDialog(contextStatusId)">
          <v-icon left>add</v-icon>
          <small>Create new</small>
        </v-list-item>
      </v-list>
    </v-menu>

    <v-menu v-model="showWorkItemContextMenu" :min-width="150" :position-x="xMenu" :position-y="yMenu" absolute offset-y >
      <v-list dense class="py-1">

        <v-list-item class="pr-2" @click="clearColor">
          <v-icon left>format_color_reset</v-icon>
          <small>Clear color</small>
        </v-list-item>

        <v-menu open-on-hover bottom offset-x :close-on-content-click="false">
          <template v-slot:activator="{ on }">

            <v-list-item v-on="on" class="pr-2">
              <v-icon left>invert_colors</v-icon>
              <v-list-item-content>
                <v-list-item-title>Color</v-list-item-title>
              </v-list-item-content>
              <v-list-item-action>
                <v-icon color="">chevron_right</v-icon>
              </v-list-item-action>
            </v-list-item>
          </template>
          <v-color-picker @update:color="colorChanged" v-model="color"
            hide-canvas hide-inputs hide-mode-switch show-swatches swatches-max-height="300px"></v-color-picker>
        </v-menu>

        <v-menu open-on-hover bottom offset-x :close-on-content-click="false">
          <template v-slot:activator="{ on }">

            <v-list-item v-on="on" class="pr-2">
              <v-icon left>label</v-icon>
              <v-list-item-content>
                <v-list-item-title>Tags</v-list-item-title>
              </v-list-item-content>
              <v-list-item-action>
                <v-icon color="">chevron_right</v-icon>
              </v-list-item-action>
            </v-list-item>
          </template>
          <v-list dense class="py-1 tags-container">
            <v-list-item v-if="!tags || !tags.length">
                <v-list-item-title>
                  <i>No tags available</i>
                </v-list-item-title>
            </v-list-item>
            <v-tooltip right :disabled="!tag.description || !JSON.parse(tag.description)"  v-for="(tag, ix) in tags" :key="ix">
              <template v-slot:activator="{ on }">
                <v-list-item class="" v-on="on"
                    @click="toggleTag(tag)">
                  <v-list-item-content>
                    <v-list-item-title>
                      <v-chip
                        :style="{ display: 'block', cursor: 'pointer', color: isBlackText(tag.color) ? 'white' : 'black' }" small dark :color="tag.color"
                      >
                        <v-icon small left v-if="contextWorkItem && contextWorkItem.tags && contextWorkItem.tags.some(t => t.tagId == tag.id)">check</v-icon>
                        <v-icon small left v-else color="grey lighten-1">clear</v-icon>
                        {{tag.name}}
                      </v-chip>
                    </v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </template>
              <div style="white-space:pre-wrap; word-break:break-word; max-width: 280px;" v-html="tag.description ? JSON.parse(tag.description) : ''"></div>
            </v-tooltip>
          </v-list>
        </v-menu>

        <v-menu :open-on-hover="!someActivedMenu" bottom offset-x :close-on-content-click="false">
          <template v-slot:activator="{ on }">
            <v-list-item v-on="on" class="pr-2">
              <v-icon left>mdi-arrow-right-bold-hexagon-outline</v-icon>
              <v-list-item-content>
                <v-list-item-title>Move</v-list-item-title>
              </v-list-item-content>
              <v-list-item-action>
                <v-icon color="">chevron_right</v-icon>
              </v-list-item-action>
            </v-list-item>
          </template>
          <v-list dense class="py-1">
            <v-menu
              v-model="activedMenus[type.value]"
              v-for="(type, ix) in shownTypes" :key="ix"
              open-on-hover bottom offset-x :close-on-content-click="false"
            >
              <template v-slot:activator="{ on }">
                <v-list-item v-on="on">
                  <v-icon left small>{{type.iconClass}}</v-icon>
                  <v-list-item-content>
                    <v-list-item-title>{{type.displayName}}</v-list-item-title>
                  </v-list-item-content>
                  <v-list-item-action>
                    <v-icon color="">chevron_right</v-icon>
                  </v-list-item-action>
                </v-list-item>
              </template>
              <v-list dense>
                <v-list-item @click="moveToStatus(status)" v-for="(status, jx) in getStatusesByType(type.value)" :key="jx">
                  <v-icon left small>{{status.iconClass}}</v-icon>
                  <v-list-item-content>
                    <v-list-item-title>{{status.name}}</v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </v-list>
            </v-menu>
          </v-list>
        </v-menu>

        <v-list-item class="pr-2" @click="assignedToMe">
          <v-icon left>mdi-account-star</v-icon>
          <small>Assigned to me</small>
        </v-list-item>

      </v-list>
    </v-menu>


    <div style="position: sticky; left: 10px;">
      <div class="leadtime-container float-right" style="margin-right: 20px; margin-top: 10px">
        
        <div class="text-center mr-1">
          <label style="color: var(--v-text-lighten)">Multiselect</label>
          <br>
          <v-switch
            v-if="canEditProject"
            v-model="multiselect"
            inset hide-details dense
            class="mr-0 my-0 pt-0 inline-block"
            @change="v => multiselected = []"
          ></v-switch>
        </div>

        <div class="leadtime-avg" @click="showLeadTime">
          Lead Time Real vs. Objective
          <br>
          <div>
            <span
              :style="{
                color: 
                  leadTimeGoal.leadTimeGoal ?
                    ( toMinutes(leadTimeGoal.leadTimeGoal, leadTimeGoal.leadTimeGoalUnit, project.usingWorkingHours) < leadTime.value ? 'salmon' : 'lightgreen' )
                    : 'white'
              }"
            >
              {{
                leadTimeGoal.leadTimeGoal ?
                  formatTimeByUnit(leadTime.value, leadTimeGoal.leadTimeGoalUnit, project.usingWorkingHours) :
                  getIndicatorTime(leadTime.value, false, project.usingWorkingHours)
              }}
            </span>
            <span v-if="leadTimeGoal.leadTimeGoal">
              vs.
              {{ formatTimeByUnit(toMinutes(leadTimeGoal.leadTimeGoal, leadTimeGoal.leadTimeGoalUnit), leadTimeGoal.leadTimeGoalUnit) }}
            </span>
          </div>
        </div>
      </div>

      <v-btn fab small color="primary" @click="openWorkItemCreateDialog()" :disabled="!shownStatuses.length || !canEditProject">
        <v-icon dark>add</v-icon>
      </v-btn>

      <v-slide-group right show-arrows mandatory v-model="board" style="display: inline-block; width: auto !important; position: relative; left: 5px; top: 13px;">
        <v-slide-item v-for="type in shownTypes" :key="type.value" v-slot:default="{ active, toggle }">
          <v-btn class="ma-1 ml-1 text-capitalize" :input-value="active" depressed active-class="primary" small @click="toggle" :disabled="multiselect">
            {{type.displayName}}
          </v-btn>
        </v-slide-item>
      </v-slide-group>

      <div class="filter-container">
        <v-row class="ml-1">
          <v-col class="px-1">
            <v-autocomplete label="Tags" :items="tags"
              item-value="id"  item-text="name"
              :search-input.sync="inputSearches.tags"
              v-model="filters.tags" outlined multiple hide-details dense clearable
              @change="applyFilters()"
            >
              <template v-slot:item="{ item, attrs }">
                <v-list-item-action>
                  <v-checkbox v-model="attrs.inputValue"></v-checkbox>
                </v-list-item-action>
                <v-list-item-content>
                  <v-list-item-title>
                    <v-chip class="cursor-pointer" dark small :color="item.color"
                      :style="`color: ${isBlackText(item.color) ? 'white' : 'black'}`"
                    >
                      {{item.name}}
                    </v-chip>
                  </v-list-item-title>
                </v-list-item-content>
              </template>
              <template v-slot:selection="{ attrs, item, parent, selected }">
                <v-chip v-if="item === Object(item)" dark
                  v-bind="attrs" :color="item.color" :input-value="selected" small
                  :style="`color: ${isBlackText(item.color) ? 'white' : 'black'}`"
                >
                  <span class="pr-2">
                    {{ item.name }}
                  </span>
                  <v-icon small @click="parent.selectItem(item)">close</v-icon>
                </v-chip>
              </template>
            </v-autocomplete>
            <div class="mt-1 mx-1">
              <a
                @click="tagCondition = 'and'; assignWorkItems();"
                class="mr-2"
                :style="{
                  color: tagCondition == 'and' ? '' : '#A3CEDC'
                }"
              >AND</a>
              <a
                @click="tagCondition = 'or'; assignWorkItems();"
                :style="{
                  color: tagCondition == 'or' ? '' : '#A3CEDC'
                }"
              >OR</a>
            </div>
          </v-col>

          <v-col class="px-1" v-if="!isPersonal">
            <v-autocomplete label="Assigned to" :items="users"
              item-value="id" item-text="blueTag"
              :filter="(item, queryText, itemText) => normalizeIncludes(item.blueTag + ' ' + item.fullName, queryText)"
              :search-input.sync="inputSearches.assignedTo"
              v-model="filters.assignedTo" outlined multiple hide-details dense clearable
              @change="applyFilters()"
            >
              <template v-slot:item="{ item, attrs }">
                <v-list-item-action>
                  <v-checkbox v-model="attrs.inputValue"></v-checkbox>
                </v-list-item-action>
                <v-list-item-content class="pa-0">
                  <v-list-item class="pa-0">
                    <v-list-item-avatar color="indigo" class="headline font-weight-light white--text">
                      {{ (item.blueTag || '-').charAt(0) }}
                    </v-list-item-avatar>
                    <v-list-item-content>
                      <v-list-item-title v-text="item.blueTag"></v-list-item-title>
                      <v-list-item-subtitle v-text="item.fullName"></v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>
                </v-list-item-content>
              </template>
              <template v-slot:selection="{ attrs, item, parent, selected }">
                <v-chip v-if="item === Object(item)"
                  v-bind="attrs" class="accent--lighten" :input-value="selected" label small
                >
                  <span class="pr-2">
                    {{ item.blueTag }}
                  </span>
                  <v-icon small @click="parent.selectItem(item)">close</v-icon>
                </v-chip>
              </template>
            </v-autocomplete>
            <div class="mt-1 mx-1" v-if="!client()"><a @click="setAssigedToMe()">Assigned to me</a></div>
          </v-col>

          <v-col class="px-1" v-if="!isPersonal">
            <v-autocomplete label="Reported by" :items="users"
              item-value="id" item-text="blueTag"
              :filter="(item, queryText, itemText) => normalizeIncludes(item.blueTag + ' ' + item.fullName, queryText)"
              :search-input.sync="inputSearches.reportedBy"
              v-model="filters.reportedBy" outlined multiple hide-details dense clearable
              @change="applyFilters()"
            >
              <template v-slot:item="{ item, attrs }">
                <v-list-item-action>
                  <v-checkbox v-model="attrs.inputValue"></v-checkbox>
                </v-list-item-action>
                <v-list-item-content class="pa-0">
                  <v-list-item class="pa-0">
                    <v-list-item-avatar color="indigo" class="headline font-weight-light white--text">
                      {{ (item.blueTag || '-').charAt(0) }}
                    </v-list-item-avatar>
                    <v-list-item-content>
                      <v-list-item-title v-text="item.blueTag"></v-list-item-title>
                      <v-list-item-subtitle v-text="item.fullName"></v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>
                </v-list-item-content>
              </template>
              <template v-slot:selection="{ attrs, item, parent, selected }">
                <v-chip v-if="item === Object(item)"
                  v-bind="attrs" class="accent--lighten" :input-value="selected" label small>
                  <span class="pr-2">
                    {{ item.blueTag }}
                  </span>
                  <v-icon small @click="parent.selectItem(item)">close</v-icon>
                </v-chip>
              </template>
            </v-autocomplete>
            <div class="mt-1 mx-1"><a @click="setReportedByMe()">Reported by me</a></div>
          </v-col>
          <v-col class="px-1">
            <v-menu open-on-hover bottom offset-x :close-on-content-click="false">
              <template v-slot:activator="{ on }">
                <div class="color-filter" v-on="on">
                  <div v-if="!filters.colors.length">
                    <v-icon left>invert_colors</v-icon>
                    <span>Color</span>
                  </div>
                  <div class="colors-container" v-else>
                    <div 
                      v-for="(color, ix) in filters.colors" :key="ix"
                      @click="filters.colors = filters.colors.filter(c => c != color)"
                      class="color cursor-pointer"
                      :style="{background: color}"
                    >
                      <v-icon small :color="isBlackText(color) ? 'white' : 'black'">close</v-icon>
                    </div>
                  </div>
                  <v-icon>chevron_right</v-icon>
                </div>
              </template>

              <v-color-picker
                @update:color="addColorFilter"
                hide-canvas hide-inputs hide-mode-switch
                show-swatches swatches-max-height="300px"
              ></v-color-picker>
            </v-menu>
            <div class="primary--text mt-1 cursor-pointer" @click="filters.colors = []; assignWorkItems();">Clear colors</div>
          </v-col>
          <v-col class="px-1">
            <div style="display: flex; align-items: center">
              <v-text-field
                prepend-inner-icon="search"
                label="Summary"
                v-model="search"
                outlined dense hide-details clearable class="input"
              />
            </div>
          </v-col>
        </v-row>
        <v-btn @click="resetFilters" small fab color="primary" class="ml-1">
          <v-icon>mdi-filter-variant-remove</v-icon>
        </v-btn>
      </div>
    </div>

    <v-row v-if="shownStatuses.length == 0">
      <p class="ma-3 mt-5">
        No boards or status configured.
        <router-link 
          :to="isPersonal ? 'myKanban/config' : `/project/${this.id}/config`"
        >
          Configure status
        </router-link>

      </p>
    </v-row>

    <div class="mt-0">
      <span class="primary--text pl-2">Cycle Time Real vs. Objective</span>

      <div 
        v-if="countBeforeFirstInitial() != shownStatuses.length && countAfterLastFinal() != shownStatuses.length"
        :style="{ 
          width: (shownStatuses.length*columnWidth-marginTimeLine-columnWidth/2+20-columnWidth*countAfterLastFinal()) + 'px',
          marginLeft: (marginTimeLine-5) + 'px',
          marginTop: countBeforeFirstInitial() ? '-20px' : '0' 
        }"
      >
        <v-stepper flat :height="24" :key="stepperKey" style="background: transparent">
          <v-stepper-header style="margin-top: -24px">

            <template v-for="(status, ix) in shownStatuses">
              <v-tooltip content-class="tooltip-timeline" :key="ix + 'tooltip'" top v-if="status.isInitial || status.isFinal" v-model="status.showTooltip">
                <template v-slot:activator="{ on }">
                  <v-stepper-step
                    step=""
                    v-on="on"
                    @mouseover.native="toggleTooltip(status, true)"
                    @mouseleave.native="toggleTooltip(status, false)"
                    style="cursor: pointer"
                    :key="ix + 'status'"
                    complete
                    :color="status.isInitial ? 'background' : '#1976d2'"
                    complete-icon="mdi-checkbox-blank-circle-outline"
                  />
                </template>
                <span style="padding-bottom: -30px;">{{status.isInitial ? 'Initial' : 'Final'}}</span>
              </v-tooltip>
              
              <div v-else-if="isAfterFirstInitial(status) && isBeforeLastFinal(status)" :key="ix + 'div'" class="timeline-hidden">
                <v-divider class="timeline-line" v-if="ix != shownStatuses.length-1" :key="ix + 'divider'" />
              </div>
              <v-divider class="timeline-line" v-if="isAfterFirstInitial(status) && isBeforeLastFinal(status)" :key="ix + 'divider'" />
            </template>

          </v-stepper-header>
        </v-stepper>
      </div>
    </div>

    <v-row align="stretch" class="board flex-nowrap" id="board">
      <v-col v-for="(s, ix) in shownStatuses" :key="ix" class="column pa-1">
        <div class="cycletime-avg">
          <v-row>
            <v-col @click="showStatusCycleTime(s.id)" :style="{cursor: 'pointer'}">
              <span
                :style="{
                  color: 
                    s.cycleTimeGoal ?
                      ( toMinutes(s.cycleTimeGoal, s.cycleTimeGoalUnit, project.usingWorkingHours) < s.cycleTime ? 'red' : 'green' )
                      : ''
                }"
              >
                {{
                  s.cycleTimeGoal ? 
                    formatTimeByUnit(s.cycleTime, s.cycleTimeGoalUnit, project.usingWorkingHours) :
                    getIndicatorTime(s.cycleTime, false, project.usingWorkingHours)
                }}
              </span>
              <span v-if="!!s.cycleTimeGoal">
                vs.
                <span>
                  {{ formatTimeByUnit(toMinutes(s.cycleTimeGoal, s.cycleTimeGoalUnit), s.cycleTimeGoalUnit) }}
                </span>
              </span>
            </v-col>
          </v-row>
        </div>
        <v-card :class="{'limit-red-bg': s.limit > 0 && s.limit < s.workItems.length }"  height="calc(100% - 45px)" class="column-card" :elevation="0"
          @click.right="openColumnContextMenu($event, s.id)">
          <v-card-title class="pa-2 justify-left th-fixed status-title">
            <v-btn block :color="s.color" :style="{opacity: $vuetify.theme.isDark ? .9 : 1}" dark class="mr-2 text-capitalize status-button-title" style="padding: 0 5px 0 12px;">
              <v-icon left>{{s.iconClass}}</v-icon>
              <span class="status-name">{{s.name}}</span>

              <v-spacer></v-spacer>

              <v-icon v-if="filterActive()" style="margin-right: 2px" small>mdi-filter-variant</v-icon>
              <span v-if="s.workItems" :class="{'limit-red': s.limit > 0 && s.limit < s.workItems.length}" style="font-size: 13px;">
                {{s.workItems.length}}
              </span>
              <span v-if="s.limit > 0" style="font-size: 13px;">/{{s.limit}}</span>
              <v-tooltip top v-if="s.definitionOfDone && JSON.parse(s.definitionOfDone)">
                <template v-slot:activator="{ on, attrs }">
                  <span
                    v-bind="attrs"
                    v-on="on"
                    :style="{marginLeft: '3px', marginTop: '-3px', fontSize: '16px'}"
                  >
                    ⭐️
                  </span>
                </template>
                <span style="white-space:pre-wrap; word-break:break-word; max-width: 280px;" v-html="JSON.parse(s.definitionOfDone)"></span>
              </v-tooltip>
            </v-btn>

          </v-card-title>
          <v-card-text class="pa-2 column-card-content">
            <div>
              <div 
                class="show-all active" 
                @click="showLess(s.id)"
                v-if="s.shownWorkItemsQuantity > 10"
              > Show Less </div>
            </div>
            <draggable v-model="s.workItems" group="columns" style="height: 100%"
              @change="workItemChanged($event, s)">
                <v-card v-for="(workItem, ix) in s.workItems.slice(0, s.shownWorkItemsQuantity)" :key="ix" 
                  class="work-item my-2" :style="{opacity: $vuetify.theme.isDark ? .9 : 1}" :color="!!workItem.boardColor ? workItem.boardColor : '#fff'"
                  @click="multiselect ? addToMultiselect(workItem.id) : openWorkItemDetailsDialog(workItem.code)" 
                  @click.right="openWorkItemContextMenu($event, workItem)"
                  @mouseenter="hoverMultiselect(workItem.id)"
                  @mouseleave="workItemHover =  ''"
                >
                  <v-card-text class="pa-2 summary" :class="{'white--text': workItem.dark, 'grey--text text--darken-4': !workItem.dark }">
                    <v-icon 
                      v-if="multiselect && !multiselected.includes(workItem.id)"
                      class="multiselect-icon"
                    >mdi-checkbox-blank-circle-outline</v-icon>
                    <v-icon
                      v-else-if="multiselect"
                      class="multiselect-icon"
                      color="accent"
                    >mdi-check-circle</v-icon>
                    <span class="float-right estimated-effort" v-if="workItem.estimatedEffort != undefined && workItem.estimatedEffort != null">
                      <small>
                        {{workItem.estimatedEffort + ' hrs'}}
                      </small>

                      <v-icon class="priority-icon" small :color="workItem.priorityColor" style="display: none">{{workItem.priorityIconClass}}</v-icon>
                    </span>
                    <div>
                      <v-tooltip z-index="111" top v-for="(tag, ix) in workItemTags(workItem)" :key="ix" :disabled="!tag.tagDescription || !JSON.parse(tag.tagDescription)">
                        <template v-slot:activator="{ on }">
                          <v-chip
                            v-on="on"
                            class="mr-1" x-small dark
                            :color="tag.tagColor"
                            :style="`color: ${isBlackText(tag.tagColor) ? 'white' : 'black'}`"
                          >
                            {{tag.tagName}}
                          </v-chip>
                        </template>
                        <div style="white-space:pre-wrap; word-break:break-word; max-width: 280px;" v-html="tag.tagDescription ? JSON.parse(tag.tagDescription) : ''"></div>
                      </v-tooltip>
                    </div>
                    {{workItem.name}}
                  </v-card-text>
                  <v-card-actions :style="{padding: 0}">
                    <div class="actions-box">
                      <v-row align="center" class="actions-container" :class="{'white--text': workItem.dark, 'black--text': !workItem.dark }">
                        <v-col class="pa-0 pt-2 pl-7 text-no-wrap" >
                          <v-badge class="mr-1" :icon="workItem.typeIconClass" :color="workItem.typeColor" left>
                          </v-badge>
                          <span>
                            {{workItem.code}}
                          </span>
                        </v-col>
                        <v-spacer></v-spacer>
                        <v-col class="pa-0 pr-3 text-no-wrap text-right code" :class="{'white--text': workItem.dark, 'black--text': !workItem.dark }">

                          <user-avatar :userId="workItem.assignedToId" :size="30" hideLoading></user-avatar>
                        </v-col>
                      </v-row>
                      <div class="indicators" :class="{'white--text': workItem.dark, 'black--text': !workItem.dark}">
                        <div>
                          WIA <br>
                          <span style="white-space: nowrap">
                            {{
                              getIndicatorTime(
                                (isNullDate(workItem.initiatedAt) || workItem.leadTime) ? null : workItem.age,
                                false, project.usingWorkingHours
                              )
                            }}
                          </span>
                        </div>
                        <div style="overflow: hidden; text-overflow: ellipsis; text-align: left">
                          <span style="white-space: nowrap">
                            WIA {{s.name}}
                          </span>
                          <br>
                          <span>
                            {{getIndicatorTime(workItem.currentColumnAge, false, project.usingWorkingHours)}}
                          </span>
                        </div>
                        <div>
                          LT <br>
                          <span style="white-space: nowrap">
                            {{
                              getIndicatorTime(
                                (isNullDate(workItem.initiatedAt) || workItem.age || !s.isFinal) ? null : workItem.leadTime,
                                false, project.usingWorkingHours
                              )
                            }}
                          </span>
                        </div>
                      </div>
                    </div>
                  </v-card-actions>
                </v-card>
                <div class="text-center">
                  <v-row>
                    <v-col>
                      <div
                        v-if="s.shownWorkItemsQuantity < s.workItems.length"
                        @click="showMore(s.id)"
                        class="show-all active"
                      > Show More </div>
                      <div
                        v-else-if="s.shownWorkItemsQuantity > 10" 
                        @click="showLess(s.id)"
                        class="show-all active"
                      > Show Less </div>
                    </v-col>
                    <v-col v-if="s.shownWorkItemsQuantity < s.workItems.length">
                      <div
                        @click="showAll(s.id)"
                        class="show-all active"
                      > Show All </div>
                    </v-col>
                  </v-row>
                </div>
            </draggable>
          </v-card-text>

        </v-card>
      </v-col>
    </v-row>
    <work-item-details-dialog ref="workItemDetailsDialog" :isPersonal="isPersonal"></work-item-details-dialog>
    <work-item-create-dialog ref="workItemCreateDialog" :isPersonal="isPersonal"></work-item-create-dialog>
    <status-cycle-time-dialog ref="statusCycleTimeDialog" @clickWorkItem="openWorkItemDetailsDialog" :usingWorkingHours="project.usingWorkingHours"></status-cycle-time-dialog>
    <lead-time-dialog ref="leadTimeDialog" @clickWorkItem="openWorkItemDetailsDialog" :usingWorkingHours="project.usingWorkingHours"></lead-time-dialog>
  </div>
</template>

<script>
import _ from "lodash"
import moment from 'moment'
import draggable from "vuedraggable"
import WorkItemDetailsDialog from "./../../dialogs/WorkItemDetailsDialog"
import WorkItemCreateDialog from "./../../dialogs/WorkItemCreateDialog"
import StatusCycleTimeDialog from "./../../dialogs/StatusCycleTimeDialog"
import LeadTimeDialog from "./../../dialogs/LeadTimeDialog"

export default {
  title: 'Board',
  props: ["id", "wi", 'canEditProject', 'isInternal', 'projectName', 'isPersonal', 'socket'],
  components: {
    draggable,
    WorkItemDetailsDialog,
    WorkItemCreateDialog,
    StatusCycleTimeDialog,
    LeadTimeDialog,
  },
  data: () => ({
    showWorkItemContextMenu: false,
    xMenu: 0,
    yMenu: 0,
    contextWorkItem: null,

    showColumnContextMenu: false,
    xColumnMenu: 0,
    yColumnMenu: 0,
    contextStatusId: null,

    projectConfig: {},
    workItems: [],
    color: null,
    board: 0,
    types: [],
    project: {},

    leadTimeTotal: 0,
    finishedCards: 0,
    cycleTimeByStatus: {},

    filters: {
      tags: [],
      assignedTo: [],
      reportedBy: [],
      colors: [],
    },
    inputSearches: {},
    tagCondition: 'and',
    users: [],
    leadTime: {
      value: 0,
      type: -1,
    },
    columnWidth: 228,
    stepperKey: 0,
    reloadInterval: null,

    multiselected: [],
    multiselect: false,
    workItemHover: '',
    pressingCtrl: false,

    activeSearch: false,
    search: '',
    searchTimeout: null,

    activedMenus: {},
    shownStatuses: [],
  }),
  watch: {
    'socket.state'(){
      if(this.socket.state == 'Connected'){
        this.signalRConnection()
      }
    },
    search(){
      clearTimeout(this.searchTimeout)
      this.searchTimeout = setTimeout(() => {
        if(!this.search) this.search = ''
        this.assignWorkItems()
      }, 400)
    },
    board(){
      this.assignWorkItems()
    },
  },
  created() {
    window.addEventListener("keydown", this.keyPress)
    window.addEventListener("keyup", this.keyUp)
    this.setUserPreferences("ProjectBoard", "board")

    this.init()
    if (this.wi) {
      this.$nextTick(() => this.openWorkItemDetailsDialog(this.wi))
    }

    window.addEventListener('resize', () => {
      this.stepperKey++
    })
  },
  beforeDestroy(){
    this.socket.invoke('LeaveGroup', this.project.id)
    clearInterval(this.reloadInterval)
  },
  computed: {
    someActivedMenu(){
      for(let key in this.activedMenus){
        if(this.activedMenus[key])
          return true
      }
      return false
    },
    tags() {
      if (!this.projectConfig || !this.projectConfig.tags) return []
      let tags = _.orderBy(this.projectConfig.tags, ['sortIndex'])
      tags = tags.filter(t => t.types.includes(this.selectedType))
      return tags
    },
    shownTypes() {
      if (!this.projectConfig || !this.projectConfig.workItemStatuses || !this.types) return []
      return this.types.filter(t =>
        this.projectConfig.workItemStatuses.some(s => s.workItemType == t.value)
      ).map(t => {
        let cur = this.projectConfig.boardConfigs.find(s => s.workItemType == t.value)
        t.displayName = (cur && cur.nickname) ? cur.nickname : t.displayName
        return t
      })
    },
    selectedType() {
      let shownTypes = this.shownTypes
      if (shownTypes && this.board < this.shownTypes.length && this.board != -1) {
        let type = shownTypes[this.board].value
        if(this.leadTime.type != type){
          this.$http.get(`api/Project/${this.id}/leadTime/${type}`, {headers: {hideLoading: true}}).then(res => {
            this.leadTime = {
              value: res.data.leadTime,
              type: type
            }
          })
        }
        return shownTypes[this.board].value
      }
      return null
    },
    marginTimeLine(){
      return this.columnWidth/2 + this.countBeforeFirstInitial()*this.columnWidth
    },
    leadTimeGoal(){
      if(this.projectConfig && this.projectConfig.boardConfigs)
        return this.projectConfig.boardConfigs.find(lt => lt.workItemType == this.selectedType) || {}
      return {}
    },
  },
  methods: {
    moveToStatus(status){
      let workItem = this.contextWorkItem
      let newIndex = 0
      let previousStatus = this.projectConfig.workItemStatuses.find(st => st.id == workItem.statusId)
      status = this.projectConfig.workItemStatuses.find(st => st.id == status.id)
      let previousIndex = workItem.boardColumnSortIndex
      
      if(status.confetti && workItem.statusId != status.id){
        this.$confetti.start()
        setTimeout(() => { this.$confetti.stop() }, 2000)
      }

      if(this.multiselect && this.multiselected.includes(workItem.id)){
        this.multipleWorkItemChanged(status, newIndex)
        return
      }

      this.leadTime = {
        value: this.leadTime.value,
        type: this.leadTime.type
      }

      this.$q.save({
        api: `/api/WorkItem/${workItem.id}/moveOnBoard/${status.id}/${newIndex}`,
        data: null,
        successMsg: `${workItem.typeDisplayName} updated.`,
        afterSuccessUrl: null,
        afterErrorUrl: null,
      }).then(res => {
        let currentSortIndex = 0;
        for(let [i, wi] of this.workItems.sort( (a, b) => a.boardColumnSortIndex - b.boardColumnSortIndex ).entries()){
          if(wi.id == workItem.id){
            this.workItems[i] = res.data;
          }
          else if(wi.statusId == res.data.statusId){
            if(currentSortIndex == res.data.boardColumnSortIndex)
              currentSortIndex++;
            if(wi.boardColumnSortIndex != currentSortIndex)
              this.workItems[i].boardColumnSortIndex = currentSortIndex;
            currentSortIndex++;
          }
        }

        this.assignWorkItems()

        this.board = this.shownTypes.findIndex((t, i) => t.value == res.data.type)
        this.socket.invoke("ProjectUpdated", this.project.id, workItem.id)
        this.computeIndicators();
      }).catch(res => {
        if(res.response.status){
          window.getApp.error("You don't have permission to edit this project.")
        } else {
          window.getApp.error("Cannot save changes.")
        }
        workItem.boardColumnSortIndex = previousIndex
        workItem.statusId = previousStatus.id
        for(let [i, wi] of status.workItems.entries()){
          if(wi.id == workItem.id){
            status.workItems[i] = workItem
          }
        }

        let currentSortIndex = 0
        for(let [i, wi] of this.workItems.sort( (a, b) => a.boardColumnSortIndex - b.boardColumnSortIndex ).entries()){
          if(wi.id == workItem.id){
            this.workItems[i] = workItem
            break
          }
          else if(wi.statusId == workItem.statusId){
            if(currentSortIndex == workItem.boardColumnSortIndex)
              currentSortIndex++
            if(wi.boardColumnSortIndex != currentSortIndex)
              this.workItems[i].boardColumnSortIndex = currentSortIndex
            currentSortIndex++
          }
        }
        this.projectConfig.workItemStatuses.forEach(status => {
          this.$set(status, 'workItems', [])
          status.workItems = _.orderBy(this.workItems.filter(wi => wi.statusId == status.id), ['boardColumnSortIndex'])
        })

        this.$forceUpdate()

        console.error(res)
      })
    },
    getStatusesByType(type){
      if (!this.projectConfig || !this.projectConfig.workItemStatuses) return []
      return this.projectConfig.workItemStatuses.filter(wi => wi.workItemType == type)
    },
    addColorFilter(color){
      if(color.hex.toLowerCase() != '#ff0000' && !this.filters.colors.includes(color.hexa)){
        this.filters.colors.push(color.hexa)
        this.assignWorkItems()
      }
    },
    toggleTooltip(status, value) {
      status.showTooltip = value
      this.$forceUpdate()
    },
    signalRConnection(){
      if(this.socket.state != 'Connected'){
        setTimeout(this.signalRConnection, 1000)
        return
      }
      
      this.socket.off('ProjectUpdate')
      this.socket.off('WorkItemDelete')
      this.socket.invoke("JoinGroup", this.project.id)

      this.socket.on("ProjectUpdate", this.workItemUpdated)

      this.socket.on('WorkItemDelete', workItemId => {
        this.workItems = this.workItems.filter(wi => wi.id != workItemId)
        this.computeIndicators()
      })
    },
    workItemUpdated(workItemId){
      this.$http.get(`api/workItem/${workItemId}`, {headers: {hideLoading: true}})
        .then(res => {
          let newWorkItem = res.data;
          if(!newWorkItem){
            this.workItems = this.workItems.filter(wi => wi.id != workItemId && wi.code != workItemId)
            this.computeIndicators()
            this.projectConfig.workItemStatuses.forEach(status => {
              this.$set(status, 'workItems', [])
              status.workItems = _.orderBy(this.workItems.filter(wi => wi.statusId == status.id), ['boardColumnSortIndex'])
            })
            return
          }
          this.setDarkenWorkItem(newWorkItem);

          let oldWorkItem = this.workItems.find(wi => wi.id == newWorkItem.id);
          if(!oldWorkItem)
            this.workItems.push(newWorkItem);
          else{
            let currentSortIndex = 0;
            for(let [i, wi] of this.workItems.sort( (a, b) => a.boardColumnSortIndex - b.boardColumnSortIndex ).entries()){
              if(wi.id == newWorkItem.id){
                this.workItems[i] = newWorkItem;
              }
              else if(wi.statusId == newWorkItem.statusId){
                if(currentSortIndex == newWorkItem.boardColumnSortIndex)
                  currentSortIndex++;
                if(wi.boardColumnSortIndex != currentSortIndex)
                  this.workItems[i].boardColumnSortIndex = currentSortIndex
                currentSortIndex++;
              }
            }
          }
          this.assignWorkItems()
          this.computeIndicators()
        })
    },
    computeIndicators(){
      let promiseConfig = this.$http
        .get(`/api/project/${this.id}/config?active=true`, {headers: {hideLoading: true}})
        .then(res => {
          this.projectConfig.boardConfigs = res.data.boardConfigs
          for(let status of res.data.workItemStatuses){
            let curStatus = this.projectConfig.workItemStatuses.find(s => s.id == status.id)
            curStatus.cycleTime = status.cycleTime
          }
        })
        .catch(res => {
          window.getApp.error("Cannot get project sprints, tags and status.")
          console.log(res)
        })
      let promiseLeadTime = this.$http.get(`api/Project/${this.id}/leadTime/${this.selectedType}`, {headers: {hideLoading: true}})
        .then(res => {
          this.leadTime = {
            value: res.data.leadTime,
            type: this.selectedType
          }
        })

      Promise.all([promiseConfig, promiseLeadTime]).then(() => {
        this.$forceUpdate()
      })
    },
    getDiffMinutes(lower, upper = new Date()){
      let diffTime = upper - lower;
      return Math.floor(diffTime / (1000 * 60));
    },
    assignWorkItems(){
      this.shownStatuses = []
      for(let status of this.projectConfig.workItemStatuses.filter(s => s.workItemType == this.selectedType)){
        this.$set(status, 'workItems', [])
        let workItems = this.workItems.filter(wi => {
          if(wi.statusId != status.id) return false

          let containTag = true
          if(this.filters.tags && this.filters.tags.length){
            if(!wi.tags || !wi.tags.length)
              containTag = false
            else if(this.tagCondition == 'and'){
              containTag = !this.filters.tags.some(t =>
                !wi.tags.map(t2 => t2.tagId).includes(t)
              )
            }
            else{
              containTag = wi.tags.some(t => this.filters.tags.includes(t.tagId))
            }
          }
          if(!containTag) return false

          let isReportedBy = (!this.filters.reportedBy || !this.filters.reportedBy.length);
          isReportedBy = (isReportedBy || this.filters.reportedBy.includes(wi.reportedById));
          if(!isReportedBy) return false
          
          let isAssignedTo = (!this.filters.assignedTo || !this.filters.assignedTo.length);
          isAssignedTo = (isAssignedTo || this.filters.assignedTo.includes(wi.assignedToId));
          isAssignedTo = (isAssignedTo || (this.filters.assignedTo.includes(null) && wi.assignedToId == undefined));
          if(!isAssignedTo) return false

          let isColor = (!this.filters.colors || !this.filters.colors.length);
          isColor = (isColor || this.filters.colors.includes(wi.boardColor))
          if(!isColor) return false
          
          return this.normalizeIncludes(wi.name, this.search)
        })
        status.workItems = _.orderBy(workItems, ['boardColumnSortIndex'])
        if(!status.shownWorkItemsQuantity){
          this.$set(status, 'shownWorkItemsQuantity', 10)
        }
        this.shownStatuses.push(status)
      }
      if(this.$el.querySelector("#board").children.length > 0){
        this.$nextTick(() => {
          this.columnWidth = this.$el.querySelector("#board").children[0].offsetWidth
          this.stepperKey++
        })
      }
    },
    init() {
      this.$http.get(`/api/Enums/WorkItemType`)
        .then(res => {
          this.types = res.data
        })
        .catch(res => {
          window.getApp.error("Cannot obtain types.")
          console.log(res)
        })

      let workItemPm = this.$http.get(`/api/project/${this.id}/workItem`)
        .then(res => {
          this.workItems = res.data
          this.workItems.forEach(this.setDarkenWorkItem);
        })
        .catch(res => {
          window.getApp.error("Cannot obtain work items.")
          console.log(res)
        })

      let configPm = this.$http
        .get(`/api/project/${this.id}/config?active=true`)
        .then(res => {
          this.projectConfig = res.data
        })
        .catch(res => {
          window.getApp.error("Cannot get project sprints, tags and status.")
          console.log(res)
        })

      this.$http.get(`api/Project/${this.id}/leadTime/${this.selectedType}`).then(res => {
        this.leadTime = {
          value: res.data.leadTime,
          type: this.selectedType
        }
      })

      this.$http
        .get(`/api/project/${this.id}`)
        .then(response => {
          this.project = response.data
          if(!this.isPersonal && this.project)
            document.title = 'Board - ' + this.project.name
          else if(this.isPersonal)
            document.title = 'My Kanban'
          this.signalRConnection();
        })
        .catch(response => {
          console.log(response)
          window.getApp.error("Cannot get project.");
        });
      this.$http
        .get(`/api/project/${this.id}/members`)
        .then(res => {
          let members = res.data
          this.users = members.map(member => {
            if(!member.user.blueTag)
              member.user.blueTag = member.user.fullName
            return member.user
          })
          this.$q.addFirstItem(this.users, 'Unassigned', 'blueTag')
        })
        .catch(res => {
          console.log(res)
          window.getApp.error("Cannot obtain project members.")
        })

      Promise.all([typePm, workItemPm, configPm])
        .then((res) => {
          this.assignWorkItems()
          setTimeout(() => {
            this.columnWidth = this.$el.querySelector("#board").children[0].offsetWidth
            this.stepperKey++
          }, 500)
        })
    },
    workItemTags(workItem) {
      if (!workItem.tags) return []
      return _.orderBy(workItem.tags, ['tagSortIndex'])
    },
    colorChanged(color) {
      if (!this.contextWorkItem) {
        console.error("No context work item")
      }

      if (!color) {
        color = null
      } else {
        color = color.hexa || color
      }

      if(this.multiselect && this.multiselected.includes(this.contextWorkItem.id)){
        this.saveMultipleProperty(color, 'boardColor')
      }
      else{
        this.saveProperty(this.contextWorkItem, color, 'boardColor')
        this.setDarkenWorkItem(this.contextWorkItem)
      }
    },
    clearColor() {
      if(this.multiselect && this.multiselected.includes(this.contextWorkItem.id)){
        this.saveMultipleProperty(null, 'boardColor')
      }
      else{
        this.saveProperty(this.contextWorkItem, null, 'boardColor')
        this.setDarkenWorkItem(this.contextWorkItem)
      }
    },
    async assignedToMe(){
      if(this.multiselect && this.multiselected.includes(this.contextWorkItem.id)){
        this.saveMultipleProperty((await this.$blueSystem.getCurrentUser()).id, 'assignedToId')
      }
      else{
        this.saveProperty(this.contextWorkItem, (await this.$blueSystem.getCurrentUser()).id, 'assignedToId')
        this.setDarkenWorkItem(this.contextWorkItem)
      }
    },
    openWorkItemContextMenu(e, workItem) {
      e.preventDefault()
      e.stopPropagation()
      this.xMenu = e.clientX
      this.yMenu = e.clientY
      this.showColumnContextMenu = false
      this.$nextTick(() => {
        this.showWorkItemContextMenu = true
        this.contextWorkItem = workItem
        this.color = workItem.boardColor || "#FFFFFFFF"
      })
    },
    openColumnContextMenu(e, statusId) {
      e.preventDefault()
      this.xColumnMenu = e.clientX
      this.yColumnMenu = e.clientY
      this.showWorkItemContextMenu = false
      this.$nextTick(() => {
        this.showColumnContextMenu = true
        this.contextStatusId = statusId
      })
    },
    setDarkenWorkItem(workItem) {
      let color = workItem.boardColor
      if (!color) {
        this.$set(workItem, 'dark', false)
        return
      }

      const hex   = color.replace(/#/, '')
      const r     = parseInt(hex.substr(0, 2), 16)
      const g     = parseInt(hex.substr(2, 2), 16)
      const b     = parseInt(hex.substr(4, 2), 16)

      let luma = [
          0.299 * r,
          0.587 * g,
          0.114 * b
      ].reduce((a, b) => a + b) / 255

      this.$set(workItem, 'dark', (luma < 0.5))
    },
    multiToggleTag(tag, addTag){
      for(let wi of this.workItems){
        if(this.multiselected.includes(wi.id)){
          let tags = wi.tags;
          let wiHas = tags.some(t => t.tagId == tag.id)
          if(!wiHas && addTag){
            tags.push({
              tagId: tag.id,
              tagName: tag.name,
              tagColor: tag.color,
              tagSortIndex: tag.sortIndex,
            })
          }
          else if(wiHas && !addTag)
            tags = tags.filter(t => t.tagId != tag.id)
          wi.tags = tags
        }
      }
      this.saveMultipleProperty(addTag, 'tag', tag.id)
    },
    toggleTag(tag) {
      let workItem = this.contextWorkItem
      let hasTag = workItem.tags.some(t => t.tagId == tag.id)
      if (hasTag) {
        workItem.tags = workItem.tags.filter(t => t.tagId != tag.id)
      } else {
        // temporary tag
        workItem.tags.push({
          tagId: tag.id,
          tagName: tag.name,
          tagColor: tag.color,
          tagSortIndex: tag.sortIndex,
        })
      }
      let addTag = !hasTag

      if(this.multiselect && this.multiselected.includes(this.contextWorkItem.id)){
        this.multiToggleTag(tag, addTag)
        return
      }

      this.$q.save({
        api: `/api/WorkItem/${workItem.id}/tag/${tag.id}/${addTag}`,
        data: null,
        successMsg: null,
        afterSuccessUrl: null,
        afterErrorUrl: null,
      }).then(res => {
        for(let [i, wi] of this.workItems.entries()){
          if(wi.id == workItem.id){
            this.workItems[i] = res.data
            this.setDarkenWorkItem(this.workItems[i])  
          }
        }

        let status = this.projectConfig.workItemStatuses.find(st => st.id == workItem.statusId)
        for(let [i, wi] of status.workItems.entries()){
          if(wi.id == workItem.id){
            status.workItems[i] = res.data
          }
        }

        this.socket.invoke("ProjectUpdated", this.project.id, workItem.id)
        this.$forceUpdate()
      }).catch(res => {
        if(res.response.status){
          window.getApp.error("You don't have permission to edit this project.")
        } else {
          window.getApp.error("Cannot save changes.")
        }
        workItem.tags = workItem.tags.filter(t => !!t.id)
        this.setDarkenWorkItem(workItem)
      })

    },
    saveProperty(workItem, value, property) {
      let original = workItem[property]
      workItem[property] = value
      if (original === value) {
        return
      }

      // value = encodeURIComponent(value)
      this.$q.save({
        api: `/api/WorkItem/${workItem.id}/byProperty/${property}`,
        data: {
          value: value
        },
        successMsg: null,
        afterSuccessUrl: null,
        afterErrorUrl: null,
      }).then(res => {
        for(let [i, wi] of this.workItems.entries()){
          if(wi.id == workItem.id){
            this.workItems[i] = res.data;
            this.setDarkenWorkItem(this.workItems[i])
          }
        }
        this.$forceUpdate()
        this.socket.invoke("ProjectUpdated", this.project.id, workItem.id)
      }).catch(res => {
        if(res.response.status){
          window.getApp.error("You don't have permission to edit this project.")
        } else {
          window.getApp.error("Cannot save changes.")
        }
        workItem[property] = original
        this.setDarkenWorkItem(workItem)
      })
    },
    saveMultipleProperty(value, property, tagId = '') {
      this.$q.save({
        api: `/api/WorkItems/byProperty/${property}`,
        data: {
          value,
          workItems: this.multiselected,
          tagId,
        },
        successMsg: null,
        afterSuccessUrl: null,
        afterErrorUrl: null,
      }).then(res => {
        if(property != 'tag'){
          for(let wi of this.workItems){
            if(this.multiselected.includes(wi.id)){
              this.$set(wi, property, value)
              this.setDarkenWorkItem(wi)
            }
          }
        }
      })
    },
    openWorkItemDetailsDialog(id, callback) {
      let projectCode = id.substring(0, id.lastIndexOf("-"))
      let link = `/project/${projectCode}/board?wi=${id}`
      if(this.client())
        link = `/client/project/${projectCode}/board?wi=${id}`
      if(this.isPersonal)
        link = `/myKanban?wi=${id}`
      
      this.$router.push({ path: link })

      let defaults = {
        projectId: this.id,
        openCreateDialog: this.openWorkItemCreateDialog
      }

      this.$refs.workItemDetailsDialog.open(id, defaults, this.socket)
        .finally(result => {
          if(this.client())
            link = `/client/project/${projectCode}/board`
          else if(this.isPersonal)
            link = `/myKanban`
          else
            link = `/project/${projectCode}/board`
          this.$router.push(link)

          if(!this.isPersonal && this.project)
            document.title = 'Board - ' + this.project.name
          else if(this.isPersonal)
            document.title = 'My Kanban'
          
          this.workItemUpdated(id)

          if(typeof callback == 'function')
            callback()
        })
    },
    openWorkItemCreateDialog(statusId, createFrom) {
      let project = this.$q.getParentVariable("ProjectDetails", "item")
      let defaults = {
        projectId: project.id,
        type: this.selectedType,
        statusId: statusId,
        createFrom
      }
      this.$refs.workItemCreateDialog.open(defaults, this.socket)
        .then(result => {
          // Reload
          this.init()
        })
    },
    multipleWorkItemChanged(status, index){
      this.$q.save({
        api: `/api/WorkItems/multipleMoveOnBoard/${status.id}/${index}`,
        data: { workItems: this.multiselected },
        successMsg: `Work Items updated.`,
        afterSuccessUrl: null,
        afterErrorUrl: null,
      }).then(res => {
        this.$http.get(`/api/project/${this.id}/workItem`)
          .then(res => {
            this.workItems = res.data
            this.workItems.forEach(this.setDarkenWorkItem);

            this.projectConfig.workItemStatuses.forEach(status => {
              this.$set(status, 'workItems', [])
              status.workItems = _.orderBy(this.workItems.filter(wi => wi.statusId == status.id), ['boardColumnSortIndex'])
              this.$set(status, 'shownWorkItemsQuantity', 10);
            })

            this.board = this.shownTypes.findIndex((t, i) => t.value == status.workItemType)
            this.multiselect = false
            this.showWorkItemContextMenu = false
            this.computeIndicators()

            for(let wi of this.multiselected){
              this.socket.invoke("ProjectUpdated", this.project.id, wi)
            }
          })
          .catch(res => {
            window.getApp.error("Cannot obtain work items.")
            console.log(res)
          })
      })
    },
    workItemChanged($event, status) {
      let eventInfo = $event.added || $event.moved
      if (!eventInfo) return
      let workItem = eventInfo.element
      let newIndex = eventInfo.newIndex
      let previousStatus = this.projectConfig.workItemStatuses.find(st => st.id == workItem.statusId)
      status = this.projectConfig.workItemStatuses.find(st => st.id == status.id)
      let previousIndex = workItem.boardColumnSortIndex
      
      if(status.confetti && workItem.statusId != status.id){
        this.$confetti.start()
        setTimeout(() => { this.$confetti.stop() }, 2000)
      }

      if(this.multiselect && this.multiselected.includes(workItem.id)){
        this.multipleWorkItemChanged(status, newIndex)
        return
      }

      this.leadTime = {
        value: this.leadTime.value,
        type: this.leadTime.type
      }

      this.$q.save({
        api: `/api/WorkItem/${workItem.id}/moveOnBoard/${status.id}/${newIndex}`,
        data: null,
        successMsg: `${workItem.typeDisplayName} updated.`,
        afterSuccessUrl: null,
        afterErrorUrl: null,
      }).then(res => {
        let currentSortIndex = 0;
        for(let [i, wi] of this.workItems.sort( (a, b) => a.boardColumnSortIndex - b.boardColumnSortIndex ).entries()){
          if(wi.id == workItem.id){
            this.workItems[i] = res.data;
          }
          else if(wi.statusId == res.data.statusId){
            if(currentSortIndex == res.data.boardColumnSortIndex)
              currentSortIndex++;
            if(wi.boardColumnSortIndex != currentSortIndex)
              this.workItems[i].boardColumnSortIndex = currentSortIndex;
            currentSortIndex++;
          }
        }

        for(let [i, wi] of status.workItems.entries()){
          if(wi.id == res.data.id){
            status.workItems[i] = res.data;
            this.setDarkenWorkItem(status.workItems[i], 1);
          }
        }

        this.socket.invoke("ProjectUpdated", this.project.id, workItem.id)
        this.computeIndicators();
      }).catch(res => {
        if(res.response.status){
          window.getApp.error("You don't have permission to edit this project.")
        } else {
          window.getApp.error("Cannot save changes.")
        }
        workItem.boardColumnSortIndex = previousIndex
        workItem.statusId = previousStatus.id
        for(let [i, wi] of status.workItems.entries()){
          if(wi.id == workItem.id){
            status.workItems[i] = workItem
          }
        }

        let currentSortIndex = 0
        for(let [i, wi] of this.workItems.sort( (a, b) => a.boardColumnSortIndex - b.boardColumnSortIndex ).entries()){
          if(wi.id == workItem.id){
            this.workItems[i] = workItem
            break
          }
          else if(wi.statusId == workItem.statusId){
            if(currentSortIndex == workItem.boardColumnSortIndex)
              currentSortIndex++
            if(wi.boardColumnSortIndex != currentSortIndex)
              this.workItems[i].boardColumnSortIndex = currentSortIndex
            currentSortIndex++
          }
        }
        this.projectConfig.workItemStatuses.forEach(status => {
          this.$set(status, 'workItems', [])
          status.workItems = _.orderBy(this.workItems.filter(wi => wi.statusId == status.id), ['boardColumnSortIndex'])
        })

        this.$forceUpdate()

        console.error(res)
      })
    },
    showLess(status){
      let statusData = this.projectConfig.workItemStatuses.find(s => s.id === status)
      this.$set(statusData, 'shownWorkItemsQuantity', 10)
    },
    showMore(status){
      let statusData = this.projectConfig.workItemStatuses.find(s => s.id === status)
      let quantity = statusData.shownWorkItemsQuantity + 20
      if(quantity > statusData.workItems.length)
        quantity = statusData.workItems.length
      this.$set(statusData, 'shownWorkItemsQuantity', quantity)
    },
    showAll(status){
      let statusData = this.projectConfig.workItemStatuses.find(s => s.id === status)
      this.$set(statusData, 'shownWorkItemsQuantity', statusData.workItems.length)
    },
    setAssigedToMe: async function() {
      let currentUser = await this.$blueSystem.getCurrentUser()

      if (currentUser)
        this.$set(this.filters, 'assignedTo', [ currentUser.id ])
      this.assignWorkItems()
    },
    setReportedByMe: async function() {
      let currentUser = await this.$blueSystem.getCurrentUser()
      if (currentUser)
        this.$set(this.filters, 'reportedBy', [ currentUser.id ])
      this.assignWorkItems()
    },
    applyFilters(){
      for(let key in this.inputSearches){
        this.inputSearches[key] = ''
      }
      this.assignWorkItems()
    },
    resetFilters(){
      this.filters = {
        tags: [],
        assignedTo: [],
        reportedBy: [],
        colors: [],
      }
      this.temporalSearch = ''
      this.assignWorkItems()
    },
    client() {
      return this.$router.history.current.path.startsWith('/client');
    },
    

    showStatusCycleTime(id){
      this.$refs.statusCycleTimeDialog.open(id)
    },

    showLeadTime(){
      this.$refs.leadTimeDialog.open(this.id, this.selectedType)
    },

    countBeforeFirstInitial(){
      let count = 0
      for(let ss of this.shownStatuses){
        if(ss.isInitial){
          return count
        } else {
          count++
        }
      }
      return count
    },

    countAfterLastFinal(){
      let count = 0
      let reversed = JSON.parse(JSON.stringify(this.shownStatuses))
      reversed.reverse()
      for(let ss of reversed){
        if(ss.isFinal){
          return count
        } else {
          count++
        }
      }
      return count
    },

    isAfterFirstInitial(status){
      let firstInitial = false
      for(let ss of this.shownStatuses){
        if(ss.isInitial){
          firstInitial = true
        }
        if(ss.id == status.id){
          return firstInitial
        }
      }
      return firstInitial
    },

    isBeforeLastFinal(status){
      let lastFinal = false
      let reversed = JSON.parse(JSON.stringify(this.shownStatuses))
      reversed.reverse()
      for(let ss of reversed){
        if(ss.id == status.id){
          return lastFinal
        }
        if(ss.isFinal){
          lastFinal = true
        }
      }
      return lastFinal
    },

    hoverMultiselect(id){
      this.workItemHover = id
      if(this.pressingCtrl && this.multiselect){
        this.addToMultiselect(id)
      }
    },

    addToMultiselect(id){
      if(this.multiselected.includes(id)){
        this.multiselected = this.multiselected.filter(wi => wi != id)
      }
      else {
        this.multiselected.push(id)
      }
    },

    keyPress(e){
      if(e.repeat)
        return
      this.pressingCtrl = e.ctrlKey || e.metaKey
      if(this.multiselect && this.pressingCtrl && this.workItemHover){
        this.addToMultiselect(this.workItemHover)
      }
    },
    keyUp(e){
      this.pressingCtrl = e.ctrlKey || e.metaKey
    },

    filterActive(){
      return (this.filters.tags && this.filters.tags.length) ||
        (this.filters.reportedBy && this.filters.reportedBy.length) ||
        (this.filters.assignedTo && this.filters.assignedTo.length) ||
        (this.filters.colors && this.filters.colors.length) ||
        (this.search)
    },
  }

}
</script>

<style scoped lang="scss">
.tags-container{
  max-height: 400px;
  overflow-y: scroll;
}
.tooltip-timeline{
  margin-top: 30px;
}
.status-title{
  border-radius: 0 !important;
}
.status-button-title {
  max-width: 100%;
  overflow: hidden;
  letter-spacing: 0.05em;
}
.status-name {
  white-space: normal;
  word-break: normal;
  max-height: 31px;
  text-overflow: ellipsis;
  overflow: hidden;
  margin-right: 5px;
}
.status-button-title ::v-deep .v-btn__content {
  max-width: 100%;
}

::v-deep .v-stepper__step__step .v-icon{
  color: #1976d2 !important;
  font-size: 27px !important;
}
::v-deep .v-stepper__step__step{
  margin-right: 2px !important;
}
::v-deep .v-stepper__step{
  padding-left: 0 !important;
  padding-right: 0 !important;
}
.timeline-line{
  color: #1976d2;
  border: 1px solid #1976d2 !important;
}
.timeline-hidden{
  width: 25px !important;
  padding-left: 32px;
  padding-top: 35px;
}

.board {
  min-height: 500px;
  width: max-content;
  min-width: 100%;
  margin: 0;
}
.column {
  flex-grow: 1;
  flex-shrink: 1;
  flex-basis: 0;
  width: 228px;
}
.column-card {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  background: var(--v-kanban-base) !important;
}
.column-card-content {
  flex-grow: 1;
}
.code {
  font-size: 16px;
  color: #777777;
}
.summary {
  font-size: 16px;
}
.work-item.sortable-ghost {
  border: 2px dashed #0072ff !important;
  cursor: grabbing !important;
}

.work-item {
  cursor: grab;
}

.work-item .summary {
  min-height: 50px;
}

.v-color-picker ::v-deep .v-color-picker__preview {
  display: none;
}

.priority-icon {
  background: #FFFFFF66;
  border-radius: 100%;
}

.show-all {
  text-decoration: underline;
}

.show-all.active {
  color: #0072ff;
  cursor: pointer;
}

.show-all.inactive {
  color: #777777;
}
.limit-red {
  color: #FF4747;
}

.limit-red-bg {
  background-color: var(--v-error-lighten) !important;
}
.actions-box{
  width: 100%;
}
.actions-container{
  margin: 0 -12px;
  padding: 0 8px;
}
.indicators{
  display: flex;
  justify-content: space-between;
  margin: 0;
  margin-top: 10px;
  padding: 0 5px;
  background: rgba(0,0,0,.15);
}
.indicators div{
  display: inline-block;
  padding: 5px 10px 5px 3px;
  line-height: 15px;
  font-size: 11px;

  span{
    font-size: 12px;
  }
}
.cycletime-avg{
  margin-bottom: 10px;
}
.cycletime-avg .row{
  margin: 0;
}
.cycletime-avg .col{
  text-align: center;
  background: var(--v-kanban-base);
  color: var(--v-text-base);
  font-weight: 600;
  padding: 5px 0;
  font-size: .9em;
}
.leadtime-container{
  display: inline-flex;
  align-items: center;
  justify-content: center;
}
.leadtime-avg{
  background: #404040;
  color: white;
  padding: 4px 15px;
  border-radius: 3px;
  cursor: pointer;
  font-size: .87em;
}
::v-deep .v-input--selection-controls__input{
  margin-right: 0;
}
.leadtime-avg div{
  text-align: center;
}
.filter-container{
  display: inline-flex;
  gap: 10px;
  margin-top: 10px;
  max-width: 1000px;
}
::v-deep .search fieldset{ border: none; }
::v-deep .search .v-input__slot{ padding: 0 !important; }
.th-fixed {
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  z-index: 1;
  border-radius: 5px;
  background-color: var(--v-kanban-base);
}
#board-container{
  height: calc(100vh - 125px);
  overflow-y:scroll;
}
#board-container::-webkit-scrollbar {
    width: 5px;
    height: 5px;
}
#board-container::-webkit-scrollbar-thumb{
  background: #AED6F1;
}
@media (max-width: 1262px) {
  #board-container{
    height: calc(100vh - 180px);
    width: auto !important;
  }
}
.estimated-effort{
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 1px 5px;
  background: rgba(0,0,0,.08);
  border-radius: 5px;
}
.v-chip{
  margin-top: 5px !important;
}
.definitionOfDone-icon{
  font-size: 20px;
}
.multiselect-icon{
  position: absolute;
  top: -12px; left: -14px;
  font-size: 28px;
  background: var(--v-background-base);
  border-radius: 50%;
}
.color-filter{
  border: 1px solid #555;
  border-radius: 5px;
  padding: 7px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: var(--v-text-lighten2);

  .v-icon{
    color: var(--v-text-lighten2);
  }

  .colors-container{
    display: flex;
    flex-wrap: wrap;

    .color{
      display: inline-block;
      margin: 2px 4px 2px 0;
      border: 1px solid var(--v-text-lighten);
      width: 24px;
      height: 24px;
      border-radius: 50%;

      &:hover .v-icon{
        display: initial;
      }

      .v-icon{
        display: none;
        position: relative;
        left: 3px;
      }
    }
  }
}
</style>