<template>
	<blue-confirm-dialog ref="dialog" _title="Healthcheck" class="pb-20">
		<template #text>
			<v-form v-model="validForm" ref="form">
				<v-row>
					<v-col>
						<label for="date">Date*</label>
						<q-date-picker
							v-model="item.date" label=""
							outlined inner dense hide-details
							:max="today" :rules="[v => !!v || 'Required']"
						/>
					</v-col>
					<v-col>
						<label for="responsible">Customer responsible / Stakeholder</label>
						<q-user-autocomplete
							v-model="item.responsibleId" :items="members"
							outlined hide-details dense :confirmable="false"
						/>
					</v-col>
				</v-row>
				
				<div class="mt-2" style="max-width: 300px">
					<label
						for="customerPerception"
						:class="{'error--text': validated && !item.customerPerception && item.customerPerception != 0}"
					>
						Customer Perception*
					</label>
					<div class="btn-container">
						<v-btn v-for="(color, ix) in semaphoreColors" :key="ix"
							@click="(item.customerPerception = ix)"
							depressed :color="item.customerPerception == ix ? color.color : 'grey'"
							outlined
						>
							<v-icon>{{color.icon}}</v-icon>
						</v-btn>
					</div>
				</div>

				<div class="mt-2">
					<label for="comments">Comments*</label>
					<v-textarea v-model="item.comments" outlined hide-details :rules="[v => !!v || 'Required']"/>
				</div>

			</v-form>

			<div class="mt-15">
				<h3>Incidents</h3>
				<v-btn @click="openScalingDialog({})" color="primary" class="mb-2 px-0" text>
					<v-icon left>mdi-plus</v-icon>
					Register Incident
				</v-btn>
				<div class="d-flex pa-2 pt-1 overflow-auto" style="gap: 20px">
          <incident-card
            v-for="(incident, ix) in item.escalations"
            :key="incident.id"
            :item="incident"
            :statuses="projectConfig.workItemStatuses"
            @click="openScalingDialog(incident, ix)"
            @statusChange="getHealthcheck"
						width="300"
					/>
        </div>
			</div>


		</template>
		<template #actions="{ cancel, confirm }">
			<v-spacer/>
			<v-btn @click="cancel" color="primary" text>Cancel</v-btn>
			<v-btn @click="validate(confirm)" color="primary">
				Save Healthcheck
			</v-btn>
			<nuclei-scaling-dialog
				ref="nucleiScalingDialog"
				:projectId="projectId"
				:returnScaling="true"
			/>
		</template>
	</blue-confirm-dialog>
</template>

<script>
import moment from 'moment'
import NucleiScalingDialog from "./NucleiScalingDialog";
import IncidentCard from '../components/IncidentCard.vue';

export default {
	props: {
		projectId: String,
	},
	components: { 
		NucleiScalingDialog,
		IncidentCard,
	},
	data: () => ({
		validForm: false,
		members: [],
		projectConfig: {},
		item: {
			customerPerception: null,
			escalations: [],
		},
		today: moment().format('YYYY-MM-DD'),
		semaphoreColors: [
			{ color: 'error', icon: 'mdi-emoticon-sad-outline' },
			{ color: '#fdc37f', icon: 'mdi-emoticon-neutral-outline' },
			{ color: 'success', icon: 'mdi-emoticon-excited-outline' }
		],
		scalingStatuses: [],
    scalingStatusColor: [
			{ id: 0, color: '#FCAF54', colorTransparency: '#FCAF541A', name: 'Reported' },
      { id: 1, color: '#167BD7', colorTransparency: '#167BD71A', name: 'In Progress' },
      { id: 2, color: '#039200', colorTransparency: '#0392001A', name: 'Solved' }
    ],
		validated: false,
	}),
	mounted(){
		this.$http.get(`/api/project/${this.projectId}/members`)
			.then(res => this.members = res.data.map(x => x.user) )
			.catch(err => this.$root.error("Cannot get project members."))

		this.$http.get(`api/Enums/ScalingBinnacleStatus`)
			.then((res) => {
				this.scalingStatuses = res.data
					.map(s => ({
						...s,
						...this.scalingStatusColor.find(x => x.id == s.id)
					}))
			})
			.catch(err => this.$root.error("Cannot get scaling binnacles status."))
		
		this.$http.get(`api/project/${this.projectId}/config?active=true`).then(res => {
			this.projectConfig = res.data
			this.projectConfig.workItemStatuses = this.projectConfig.workItemStatuses.filter(s => s.workItemType === 2)
		})
	},
	methods: {
    getStatusData(id) {
      return this.scalingStatusColor.find(x => x.id == id)
    },
		open(item){
			this.validated = false
			if(!item.id){
				this.item = {
					customerPerception: null,
					escalations: [],
				}
			}
			else {
				this.item = item
				this.item.escalations = this.item.workItems
			}
			return new Promise((resolve, reject) => {
				this.$refs.dialog.open({
					title: 'Healthcheck',
					confirmBtnText: 'Save Healthcheck',
					width: 750
				}).then(res => {
						if(!res){
							resolve(false)
							return
						}
						let item = JSON.parse(JSON.stringify(this.item))
						let escalations = []
						if(!this.item.id) {
							escalations = item.escalations
							escalations = escalations.map(es => {
								delete es.reportedBy
								delete es.reportedTo
								es.actions.forEach(ac => {
									delete ac.id
									delete ac.temporalId
									delete ac.status
									delete ac.relatedWorkItemName
									delete ac.relatedWorkItemStatusColor
									delete ac.relatedWorkItemStatusIconClass
									delete ac.relatedWorkItemStatusName
									delete ac.relatedWorkItemTypeColor
									delete ac.relatedWorkItemTypeIconClass
								})
								let actions = es.actions
								delete es.actions
								let incident = es
								return {
									actions,
									incident
								}
							})
						}
						delete item.escalations
						delete item.workItems
						this.$q.save({
							api: `api/WorkItems/createHealthcheck/${this.projectId}`,
							data: { healthcheck: item, incidents: escalations },
							successMsg: "Healthcheck created succesfully.",
							afterSuccessUrl: null,
							errorMsg: "Cannot save record.",
							afterErrorUrl: null,
						})
							.then(resolve)
							.catch(reject)
					})

			})
		},
		getHealthcheck() {
			this.$http.get(`/api/nucleiManagement/healthcheck/${this.item.id}`)
				.then(res => {
					console.log(res.data)
					this.item = res.data
					this.item.escalations = this.item.workItems
				})
		}, 
		openScalingDialog(escalation, ix){
			this.$refs.nucleiScalingDialog
				.open(this.projectId, escalation, this.item.id)
				.then(({item, actions}) => {
						if(this.item.id) {
							this.getHealthcheck()
							return
						}
					item.actions = actions
					let index = this.item.escalations.findIndex(x => x.id == escalation.id)
					if(escalation.id || escalation.temporalId){
						this.$set(this.item.escalations, index, item)
						return
					}
					this.item.escalations.push(item)
				})
		},
    getRelativeTime(date) {
      return moment(date).fromNow()
    },
		validate(confirm){
			this.$refs.form.validate()
			this.validated = true
			if(this.validForm && (this.item.customerPerception || this.item.customerPerception === 0)){
				confirm()
			}
		},
	},
}
</script>

<style lang="scss" scoped>
.btn-container{
	display: grid;
	grid-template-columns: 1fr 1fr 1fr;
	gap: 10px;

	.v-btn{
		height: 40px;
	}
}

.scaling-binnacles-card {
  min-width: 300px;
}

.v-card--link:focus:before{
  opacity: 0;
}
</style>