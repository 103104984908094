<template>
	<v-menu min-width="0px" :close-on-content-click="false">
		<template #activator="{ on }">
			<v-text-field
				v-on="on"
				v-bind="$attrs"
				:value="innerValue"
				readonly
			>
				<template #prepend-inner>
					<div class="dot" :style="{ background: innerValue }"></div>
				</template>
				<template #append>
					<v-icon>mdi-palette</v-icon>
				</template>
			</v-text-field>
		</template>
		<v-color-picker
			:value="innerValue"
			@input="input"
			dot-size="25"
			mode="hexa"
			swatches-max-height="200"
			hide-mode-switch
		/>
	</v-menu>	
</template>

<script>
export default {
	props: {
		value: String,
	},
	data: () => ({
		showMenu: false,
		innerValue: '',
	}),
	watch: {
		value(v){
			this.innerValue = v
		},
	},
	created(){
		if(this.value)
			this.innerValue = this.value
		else this.$emit('input', '#E97575')
	},
	methods: {
		input(v){
			this.innerValue = v
			this.$emit('input', v)
		},
	},
}
</script>

<style lang="scss" scoped>
.dot{
	display: inline-block;
	width: 20px; height: 20px;
	margin-top: 2px;
	border-radius: 50%;
}
</style>