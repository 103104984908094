<template>
  <v-menu v-model="showNotifications" content-class="mr-5" :nudge-left="0" :nudge-bottom="40" left min-width="400" max-height="600" z-index="200">
    <template #activator="{ on }">
      <div v-on="on" class="mr-6 cursor-pointer pt-2">
        <v-badge class="ma-0" dark right :value="!!newMessages" color="pink" overlap :content="newMessages > 99 ? '+99' : newMessages "
          offset-x="15" 
          offset-y="10">
          <span class="material-symbols-outlined">notifications</span>
        </v-badge>
      </div>
    </template>
    <v-card color="newDesignBackground" class="py-1" width="400">
      <v-card-title>
        <v-badge :value="!!newMessages" :content="newMessages" color="pink">
          <h4 class="text--text">Notifications</h4>
        </v-badge>
        <v-spacer></v-spacer>
        <v-btn
          v-if="!!newMessages"
          @click.stop="markAllAsRead"
          color="primary" x-small
        >
          Mark as read
        </v-btn>
      </v-card-title>
      <v-card-text class="px-0">
        <v-divider/>
        <v-list color="newDesignBackground" class="d-flex flex-column py-0">
          <v-list-item
            v-for="noti in notifications"
            :key="noti.id"
            :to="getLink(noti)"
            style="border-bottom: 1px solid var(--v-lightgray-base);"
          >
            <v-list-item-content>
              <div class="d-flex align-center" style="gap: 10px">
                <v-badge v-if="noti.actionUserId"  offset-x="12px" offset-y="55px" color="transparent">
                  <user-avatar
                    :user-id="noti.actionUserId"
                    :size="48" hide-loading
                  />
                  <template #badge>
                    <v-icon :color="noti.typeIconColor" :size="22">{{ noti.typeIconClass }}</v-icon>
                  </template>
                </v-badge>
                <v-avatar
                  v-else
                  :color="noti.typeIconColor"
                  :size="48"
                >
                  <v-icon color="white">{{ noti.typeIconClass }}</v-icon>
                </v-avatar>
                <div class="body-2 d-flex flex-column justify-space-between flex" style="gap: 5px">
                  <div>
                    <b>
                      {{ noti.actionUserBlueTag || noti.actionUserFullName }}
                    </b>
                    <span v-html="noti.actionDescription"></span>
                  </div>
                  <template v-if="noti.type === 2 && noti.workItemChange">
                    <div class="d-flex flex-column align-center my-1">
                      <v-chip
                        :color="noti.workItemChange.originalStatusColor"
                        label small dark
                      >
                        <v-icon left>{{noti.workItemChange.originalStatusIconClass}}</v-icon>
                        {{ noti.workItemChange.originalStatusName }}
                      </v-chip>
                      <v-icon>mdi-chevron-down</v-icon>
                      <v-chip
                        :color="noti.workItemChange.newStatusColor"
                        label small dark
                      >
                        <v-icon left>{{noti.workItemChange.newStatusIconClass}}</v-icon>
                        {{ noti.workItemChange.newStatusName }}
                      </v-chip>
                    </div>
                    <div class="d-flex justify-center" style="gap: 5px">
                      <v-btn :color="noti.workItemChange.originalStatusColor" dark fab x-small depressed>
                        <v-icon>{{noti.workItemChange.originalStatusIconClass}}</v-icon>
                      </v-btn>
                      <v-icon>mdi-arrow-right</v-icon>
                      <v-btn :color="noti.workItemChange.newStatusColor" dark fab x-small depressed>
                        <v-icon>{{noti.workItemChange.newStatusIconClass}}</v-icon>
                      </v-btn>
                    </div>
                  </template>
                  <div class="d-flex align-center justify-space-between caption">
                    <div class="d-flex align-center">
                      <div v-if="noti.relatedWorkItemPriority">
                        <v-img
                          :src="require(`../assets/priority-icons/${noti.relatedWorkItemPriority}.png`)"
                          width="22"
                        />
                      </div>
                      <span>
                        {{ noti.relatedEntityCode }}
                      </span>
                    </div>
                    <div class="d-flex align-center">
                      <v-icon small class="mr-1">mdi-alarm</v-icon>
                      {{ getTimeAgo(noti.dateTime) }}</div>
                  </div>
                </div>
              </div>
            </v-list-item-content>
          </v-list-item>
          <v-list-item v-if="showLoadMore">
            <v-list-item-content class="text-center">
              <a @click.stop="loadMoreNotifications">Load more</a>
            </v-list-item-content>
          </v-list-item>
        </v-list>
        <v-row>
          <v-col>
            <i v-if="!notifications.length">No new notifications.</i>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </v-menu>
</template>

<script>
import moment from 'moment'

export default {
	data: () => ({
    showNotifications: false,
    notifications: [],
		priorities: [],
    newMessages: 0,
    showLoadMore: true,
    readTimerIds: [],
    notiInterval: null,
	}),
	created(){
		this.$http.get(`/api/Enums/WorkItemPriority`)
			.then(res => {
				this.priorities = res.data
			})
			.catch(res => {
				this.$root.error("Cannot obtain priorities.")
				console.error(res)
			})
    
    // Notification ping
    this.notiInterval = setInterval(() => {
      this.updateUnread()
    }, 15000)
    this.updateUnread()
    this.loadMoreNotifications()
	},
  beforeDestroy() {
    clearInterval(this.notiInterval)
    this.readTimerIds.forEach(id => {
      clearTimeout(id)
    })
  },
	methods: {
		getTimeAgo(date){
			return moment(date).fromNow()
		},
    getLink(noti) {
      if(noti.entityClass === 0)
      {
        let code = ''
        code = noti.relatedWorkItemCode
  
        if (code) {
          let projectCode = code.substring(0, code.lastIndexOf("-"))
          return `/project/${projectCode}/workitems?wi=${code}`
        }
      }
      else if(noti.entityClass === 23) {
        return `/admin/billable/details/${noti.relatedEntityId}`
      }
      else if(noti.entityClass === 24) {
        return `/admin/billableItem/${noti.relatedEntityId}`
      }
      else if(noti.entityClass === 26) {
        return `/admin/invoice/${noti.relatedEntityId}`
      }
      else if(noti.entityClass === 27) {
        return `/admin/payment/${noti.relatedEntityId}`
      }
      else if(noti.entityClass === 43) {
        if([15, 16, 7, 18].includes(noti.type)) {
          if(noti.relatedUserId) {
            return `/recess/requests?userId=${noti.relatedUserId}`
          }
          return `/recess/requests/current`
        }
        else {
          return `/recess/requests`
        }
      }
      return null
    },
    loadMoreNotifications() {
      let limit = 10
      let after = this.notifications[this.notifications.length - 1]
      let afterId = ''
      if (after) {
        afterId = after.id
      }

      this.$http
        .get(`/api/user/current/notification?limit=${limit}&afterId=${afterId}`)
        .then(res => {
          let newNotis = res.data
          if (!newNotis.length) {
            this.showLoadMore = false
          }
          this.notifications = this.notifications.concat(newNotis)
          this.notifications = _.orderBy(this.notifications, ['dateTime'], ['desc'])

          if (newNotis.length) {
            let afterId = newNotis[0].id
            let beforeId = newNotis[newNotis.length - 1].id
            this.markAsRead(afterId, beforeId)
          }
        })
    },
    markAsRead(afterId, beforeId) {
      let msToRead = 3000
      let readTimerId = setTimeout(() => {

          this.$q.save({
            api: `/api/user/current/notification/markRead?afterId=${afterId}&beforeId=${beforeId}`,
            data: null,
            successMsg: null,
            afterSuccessUrl: null,
            errorMsg: "Cannot save changes.",
            afterErrorUrl: null,
            headers: { hideLoading: true }
          }).then(res => {
            let notis = this.notifications
            notis = _.dropWhile(notis, n => n.id != afterId)
            notis = _.takeWhile(notis, n => n.id != beforeId)
            notis.push(this.notifications.find(n => n.id === beforeId))
            
            notis.forEach(n => {
              if(n) {
                n.read = true
              }
            })
          }).catch(res => {
            console.log(res)
          })

      }, msToRead)
      this.readTimerIds.push(readTimerId)
    },
    markAllAsRead() {
      this.$q.save({
        api: `/api/user/current/notification/markRead/all`,
        data: null,
        successMsg: null,
        afterSuccessUrl: null,
        errorMsg: "Cannot save changes.",
        afterErrorUrl: null,
      }).then(_ => {
        this.notifications.forEach(n => {
          if(n) {
            n.read = true
          }
        })
        this.updateUnread();
      }).catch(res => {
        console.error(res);
      });
    },
    updateUnread() {
      if (!this.$root.login()) {
        this.$http
          .get(`/api/user/current/unreadNotifications`)
          .then(res => {
            this.newMessages = res.data
          })
          .catch(res => {
            console.error(res)
          })
      }
    },
	},
  watch: {
    showNotifications(val) {
      if (val) {
        this.notifications = []
        this.updateUnread()
        this.loadMoreNotifications()
      }
    }
  },
}
</script>