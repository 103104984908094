<template>
	<div
		v-bind="$attrs"
		class="bar nobr"
		:style="{ height: _height }"
	>
		<div
			v-for="(item, ix) in items" :key="ix"
			:style="{
				background: item.color,
				width: getWidth(item.value) + '%',
				height: _height,
				position: cumulative ? 'relative' : 'absolute',
			}"
		/>
	</div>	
</template>

<script>
export default {
	props: {
		items: { type: Array },
		height: { type: Number|String, default: 10 },
		cumulative: { type: Boolean, default: true },
	},
	computed: {
		_height(){
			if(typeof this.height == 'number') return this.height + 'px'
			return this.height
		},
	},
	methods: {
		getWidth(value){
			return Math.min(100, value)
		},
	},
}
</script>

<style lang="scss" scoped>
.bar{
	display: flex;
	justify-content: flex-start !important;
	width: 100%;
	background: lightgray;
	border-radius: 5px;
	position: relative;
	
	overflow: hidden;
	div{
		display: inline-block;
	}
}
</style>