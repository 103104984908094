import moment from "moment"

function getTypesCharts(projectsData, lambda) {
	let data = []
	for (let projectType of projectsData) {
		data.push({
			name: projectType.typeName,
			color: projectType.color,
			y: lambda(projectType),
		})
	}

	let opts = {
		chart: {
			plotBackgroundColor: null,
			plotBorderWidth: null,
			plotShadow: false,
			type: 'pie',
			backgroundColor: 'transparent'
		},
		title: { text: '' },
		tooltip: {
			pointFormat: '{series.name}: <b>{point.percentage:.1f}%</b>'
		},
		accessibility: {
			point: {
				valueSuffix: '%'
			}
		},
		plotOptions: {
			pie: {
				allowPointSelect: true,
				cursor: 'pointer',
				dataLabels: {
					enabled: true,
					distance: -12,
					format: '{point.y}',
					style: {
						textOutline: 'none',
						fontSize: '.85em',
					},
				},
				showInLegend: true,
				borderColor: 'none',
			},
		},
		legend: {
			layout: 'vertical',
			align: 'right',
			verticalAlign: 'middle'
		},
		series: [{
			innerSize: '70%',
			name: 'Brands',
			colorByPoint: true,
			data,
		}]
	}
	return opts
}

function workforceChart(data, date) {
	const filterDate = moment(date).startOf('day')
	return getTypesCharts(data,
		d => d.items
			.map(i => i.assignments
				.filter(x => filterDate.isSameOrAfter(x.startDate) && filterDate.isSameOrBefore(x.endDate))
			)
			.flat()
			.reduce((partial, a) => partial + a.quantity, 0)
	)
}

function projectsChart(data, date) {
	const filterDate = moment(date).startOf('day')
	return getTypesCharts(data,
		d => {
			let projects = new Set()
			let assignments = d.items
				.map(i => i.assignments
					.filter(x => filterDate.isSameOrAfter(x.startDate) && filterDate.isSameOrBefore(x.endDate))
				)
				.flat()
			for (let assignment of assignments)
				projects.add(assignment.customerProjectId || assignment.customerProjectName)
			return projects.size
		})
}

function jsonToArray(json) {
	let arr = []
	for (let key in json) {
		arr.push(json[key])
	}
	return arr
}

function poolcampChart(assignments, dates, timeUnit, objective = 0, colorByProject = {}, getHolidays) {
	assignments = assignments.filter(a => a.customerProjectType == 9)
	let projects = {}
	for (let assignment of assignments) {
		if (!projects[assignment.customerProjectId])
			projects[assignment.customerProjectId] = {
				name: assignment.customerProjectName,
				color: colorByProject[assignment.customerProjectId],
				data: [],
			}
	}
	let date = moment(dates.startDate).startOf(timeUnit).clone()
	let categories = []
	while (date.isSameOrBefore(dates.endDate, timeUnit)) {

		let hoursPerProject = {}
		for (let assignment of assignments) {
			let from = assignment.startDate.clone()
			let to = assignment.endDate.clone()
			if (from.isBefore(date, timeUnit)) {
				from = date.clone().startOf(timeUnit)
			}
			if (to.isAfter(date, timeUnit)) {
				to = date.clone().endOf(timeUnit)
			}
			if (to.isBefore(from, 'day')) {
				continue
			}
			let holidays = getHolidays(assignment, from, to)
			let diff = (to.diff(from, 'day') - holidays + 1) * assignment.quantity * 8
			if (!hoursPerProject[assignment.customerProjectId]) hoursPerProject[assignment.customerProjectId] = 0
			hoursPerProject[assignment.customerProjectId] += diff
		}

		if (categories.length || Object.keys(hoursPerProject).length) {
			let format = 'MMM'
			if(timeUnit == 'Weeks')
				format = 'ww'
			else if(timeUnit == 'Days')
				format = 'MMM DD'

			categories.push(date.format(format))
			for (let project in projects) {
				projects[project].data.push({
					y: hoursPerProject[project],
				})
			}
		}

		date.add(1, timeUnit)
	}

	let opts = {
		chart: {
			type: 'column',
			backgroundColor: 'transparent',
		},
		title: { text: '' },
		xAxis: { categories },
		yAxis: [
			{ min: 0, title: { text: '' }, },
		],
		tooltip: {
			headerFormat: '<b>{point.x}</b><br/>',
			pointFormat: '{series.name}: {point.y}<br/>Total: {point.stackTotal}'
		},
		plotOptions: {
			column: {
				stacking: 'normal',
				borderColor: 'none',
			}
		},
		series: jsonToArray(projects).concat({
			name: 'Objective',
			type: 'line',
			data: [[0, objective], [categories.length - 1, objective]],
			tooltip: {
				valueSuffix: 'hrs.'
			},
		})
	}
	return opts
}

function workforceCustomerChart(customers, date) {
	const filterDate = moment(date).startOf('day')
	return getCustomersCharts(customers, customer => {
		return customer.customerProjects
			.flatMap(x => x.assignments
				.filter(x => filterDate.isSameOrAfter(x.startDate) && filterDate.isSameOrBefore(x.endDate))
			)
			.reduce((acc, x) => acc + x.quantity, 0)
	})
}

function getCustomersCharts(customerData, lambda) {
	let data = []

	for (let customer of customerData) {
		data.push({
			name: customer.name.substr(0, 10),
			color: customer.colorByProject,
			y: lambda(customer)
		})
	}

	data.sort((a, b) => b.y - a.y)
	// take after 10
	let others = data.slice(10)
		.reduce((acc, x) => acc + x.y, 0)

	// take first 10
	data = data.slice(0, 10)
	data.push({
		name: 'Others',
		color: '#ccc',
		y: others
	})

	let opts = {
		chart: {
			plotBackgroundColor: null,
			plotBorderWidth: null,
			plotShadow: false,
			type: 'pie',
			backgroundColor: 'transparent'
		},
		title: { text: '' },
		tooltip:
		{
			pointFormat: '{series.name}: <b>{point.percentage:.1f}%</b>'
		},
		accessibility:
		{
			point:
			{
				valueSuffix: '%'
			}
		},
		plotOptions:
		{
			pie:
			{
				allowPointSelect: true,
				cursor: 'pointer',
				dataLabels:
				{
					enabled: true,
					distance: -15,
					format: '{point.y}',
					style:
					{
						textOutline: 'none',
						fontSize: '.85em',
					},
				},
				showInLegend: true,
				borderColor: 'none',
			},
		},
		legend:
		{
			layout: 'vertical',
			align: 'right',
			verticalAlign: 'middle'
		},
		series:
			[{
				innerSize: '70%',
				name: '',
				colorByPoint: true,
				data,
			}]
	}
	return opts
}

function isExpiredHealthcheck(lastHealthcheckDate, reminderRecurrence, date) {
	const filterDate = moment(date).startOf('day')
	let expirationDate = moment(lastHealthcheckDate).startOf('day')

	if(reminderRecurrence == 0)
		expirationDate.add(2, 'weeks')
	else
		expirationDate.add(1, 'month')

	return filterDate.isAfter(expirationDate)
}

function healthcheckChart(healthchecks, projects, date, handleClick) {
	let data = []

	let projectsBySatisfaction = {
		happy: [],
		regular: [],
		upset: [],
		pending: [],
	}

	for(let project of projects) {
		const projectHealthchecks = healthchecks
			.filter(x => x.projectId == project.id)
		
		const lastHealthcheck = projectHealthchecks
			.filter(x => moment(x.date).isSameOrBefore(date, 'day'))
			.sort((a, b) => moment(b.date).diff(moment(a.date)))
			[0]

		if (lastHealthcheck && !isExpiredHealthcheck(lastHealthcheck.date, project.healthcheckReminderRecurrence, date)) {
			switch (lastHealthcheck.customerPerception) {
				case 0:
					projectsBySatisfaction.upset.push(project)
					break
				case 1:
					projectsBySatisfaction.regular.push(project)
					break
				case 2:
					projectsBySatisfaction.happy.push(project)
					break
			}
		}
		else projectsBySatisfaction.pending.push(project)
	}

	data = [
		{
			name: 'Happy',
			color: 'lightgreen',
			y: projectsBySatisfaction.happy.length
		},
		{
			name: 'Regular',
			color: 'yellow',
			y: projectsBySatisfaction.regular.length
		},
		{
			name: 'Upset',
			color: 'red',
			y: projectsBySatisfaction.upset.length
		},
		{
			name: 'Pending',
			color: 'lightgray',
			y: projectsBySatisfaction.pending.length
		},
	]

	let opts = {
		chart: {
			plotBackgroundColor: null,
			plotBorderWidth: null,
			plotShadow: false,
			type: 'pie',
			backgroundColor: 'transparent'
		},
		title: { text: '' },
		tooltip:
		{
			pointFormat: '{series.name}: <b>{point.percentage:.1f}%</b>'
		},
		accessibility:
		{
			point:
			{
				valueSuffix: '%'
			}
		},
		plotOptions:
		{
			pie:
			{
				allowPointSelect: true,
				cursor: "pointer",
				dataLabels:
				{
					enabled: true,
					distance: -20,
					format: '{point.y}',
					style:
					{
						textOutline: 'none',
						fontSize: '1em',
					},
				},
				showInLegend: true,
				borderColor: 'none',
			},
		},
		legend:
		{
			layout: 'vertical',
			align: 'right',
			verticalAlign: 'middle'
		},
		series:
			[{
				innerSize: '70%',
				name: 'Brands',
				colorByPoint: true,
				data,
				point: {
					events: {
						click: function (event) {
							event.preventDefault()
							handleClick(event, projectsBySatisfaction[event.point.name.toLowerCase()])
						},
					},
				},
			}]
	}
	return opts
}


export default { workforceChart, workforceCustomerChart, projectsChart, poolcampChart, healthcheckChart }