<template>
	<div class="new-back fill-height py-5 px-3">
		<filter-search-input
			v-model="search" :applied-filters="appliedFilters"
			outlined dense
			label="Search by billable code"
		>
			<q-filter-input
				v-model="filters.projects"
				label="Project"
				:items="projects"
				item-value="id" item-text="name"
				:parent-shown-items="shownItems"
				outlined hide-details dense multiple clearable
			/>
			<v-autocomplete
				v-model="filters.deliveryManagers"
				outlined hide-details dense multiple clearable
				label="Deliver Manager"
				:items="deliveryManagers"
			/>
			<v-autocomplete
				v-model="filters.accountManagers"
				outlined hide-details dense multiple clearable
				label="Account Manager"
				:items="accountManagers"
			/>
			<v-select
				v-model="filters.status" :items="billableStatuses"
				label="Billable Status" item-value="id" item-text="id"
				dense outlined hide-details clearable input-select multiple hide-input
			>
				<template #item="{ item }">
					<v-btn
						:color="item.color"
						outlined small style="border-radius: 20px"
						:style="{
							background: $vuetify.theme.isDark ? '' : item.lightColor
						}"
					>{{item.description}}</v-btn>
				</template>
				<template #selection="{ item }">
					<v-btn
						:color="item.color"
						outlined small style="border-radius: 20px"
						:style="{
							background: $vuetify.theme.isDark ? '' : item.lightColor
						}"
					>{{item.description}}</v-btn>
				</template>
			</v-select>
			<q-date-picker
				v-model="filters.startDate"
				label="Start Date"
				outlined hide-details dense inner clearable
			/>
			<q-date-picker
				v-model="filters.endDate"
				label="End Date"
				outlined hide-details dense inner clearable
			/>
		</filter-search-input>
		<v-data-table
			@click:row="openWeeklyReport"
			class="rounded-lg bordered new-back lighten2"
			:headers="headers"
			:items="shownItems"
		>
			<template #[`item.deliveryManagerBlueTags`]="{ item }">
				<div class="d-flex flex-column">
					<span v-for="bluetag in item.deliveryManagerBlueTags" :key="bluetag">{{ bluetag }}</span>
				</div>
			</template>
			<template #[`item.projects`]="{ item }">
				<span v-for="project in item.projects" :key="project.id">
					{{ project.projectName }}
				</span>
			</template>
			<template #[`item.startDate`]="{ item }">
				{{ item.startDate | formatDate }}
			</template>
			<template #[`item.executedNonBillableHours`]="{ item }">
				{{ item.executedNonBillableHours | number(1) }}
			</template>
			<template #[`item.effective`]="{ item }">
				<span
					v-if="item.executedHours"
					:class="{
						'success--text': item.effective >= 90,
						'warning--text': item.effective >= 75 && item.effective < 90,
						'error--text': item.effective < 75,
					}"
				>
					{{ item.effective | number(0) }}%
				</span>
				<span v-else>-</span>
			</template>
			<template #[`item.executedBillableHours`]="{ item }">
				<billable-percentage-bar
					:amountFormatter="x => round(x, 1)"
					:percentage-decimals="0"
					:primaryAmount="item.executedBillableHours"
					:objective="item.contractedHours"
				/>
			</template>
		</v-data-table>
		<side-panel
			v-model="showWeeklyReport" close-outside
		>
			<v-card class="fill-height high-back overflow-auto">
				<v-card-title>Weekly Report</v-card-title>
				<v-card-text>
					<h3>{{ weeklyReport.code }}</h3>

					<v-expansion-panels class="mt-5" inset flat>
						<v-expansion-panel
							v-for="week in weeklyReport.shownWeeks"
							:key="week.date"
							class="new-back bordered darken"
						>
							<v-expansion-panel-header>
								<div>
									<b>Week {{ week.date | formatDateCustom('W') }}</b>
									-
									<span>
										{{ week.date | formatDateCustom('YYYY') }}
									</span>
								</div>
								<div class="d-flex gap-5">
									<div class="d-flex flex-column gap-2">
										<b>Billable</b>
										<div>{{ week.billableHours | number(1) }}</div>
									</div>
									<div class="d-flex flex-column gap-2">
										<b>Non-billable</b>
										<div>{{ week.nonBillableHours | number(1) }}</div>
									</div>
									<div class="d-flex flex-column gap-2">
										<b>Effective</b>
										<div class="success--text">{{ (week.totalHours ? week.billableHours * 100 / week.totalHours : 0) | number(2) }}%</div>
									</div>
								</div>
							</v-expansion-panel-header>
							<v-expansion-panel-content>
								<v-list>
									<v-list-item
										v-for="detail in week.details"
										:key="detail.reporterId"
										class="pl-0"
									>
										<v-list-item-avatar>
											<user-avatar :user-id="detail.reporterId" :size="48" :show-card="false"/>
										</v-list-item-avatar>
										<v-list-item-content>
											<div class="d-flex justify-space-between align-center">
												<div class="d-flex flex-column gap-1">
													<b>{{ detail.reporterBlueTag }}</b>
													<span>{{ detail.reporterFullName }}</span>
												</div>
												<div class="subtitle-1">
													Total:
													<b>{{ detail.totalHours | number(1) }}</b>
													(<span class="success--text">{{ detail.billableHours | number(1) }}</span>
													/
													<span class="error--text">{{ detail.nonBillableHours | number(1) }}</span>)
												</div>
											</div>
										</v-list-item-content>
									</v-list-item>
								</v-list>
							</v-expansion-panel-content>
						</v-expansion-panel>
					</v-expansion-panels>

				</v-card-text>
			</v-card>
		</side-panel>
	</div>
</template>

<script>
import BillablePercentageBar from '../../components/admin-surf/billable/BillablePercentageBar'
import moment from 'moment'

export default {
	title: 'RPEH Report',
	components: { BillablePercentageBar },
	data: () => ({
		headers: [
			{ text: 'Billable Code', value: 'code' },
			{ text: 'Customer', value: 'customerName' },
			{ text: 'Delivery Manager', value: 'deliveryManagerBlueTags' },
			{ text: 'Account Manager', value: 'customerAccountManagerBlueTag' },
			{ text: 'Project', value: 'projects' },
			{ text: 'Currency', value: 'currency', align: 'center'},
			{ text: 'Start Date', value: 'startDate', align: 'center'},
			{ text: 'Ex. Non-Billable', value: 'executedNonBillableHours', align: 'right'},
			{ text: 'Ex. Billable / Total Ex.', value: 'effective', align: 'right'},
			{ text: 'Ex. Billable vs. Contracted', value: 'executedBillableHours', align: 'center'},
		],
		search: '',

		filters: {
			startDate: null,
			endDate: null,
			projects: [],
			deliveryManagers: [],
			accountManagers: [],
			status: [1, 2, 3, 4, 5],
		},
		projects: [],
		billableStatuses: [],

		report: [],
		showWeeklyReport: false,
		weeklyReport: {},
	}),
	computed: {
		shownItems() {
			let items = this.report
			items = this.applySearch(this.search, items)

			if(this.filters.projects.length) {
				items = items.filter(x => x.projects.some(p => this.filters.projects.includes(p.projectId)))
			}
			if(this.filters.deliveryManagers.length) {
				items = items.filter(x => x.deliveryManagerBlueTags.some(p => this.filters.deliveryManagers.includes(p)))
			}
			if(this.filters.accountManagers.length) {
				items = items.filter(x => this.filters.accountManagers.includes(x.customerAccountManagerBlueTag))
			}
			if(this.filters.status.length) {
				items = items.filter(x => this.filters.status.includes(x.status))
			}
			if(this.filters.startDate) {
				items = items.filter(x => moment(x.startDate).isSameOrAfter(this.filters.startDate, 'day'))
			}
			if(this.filters.endDate) {
				items = items.filter(x => moment(x.startDate).isSameOrBefore(this.filters.endDate, 'day'))
			}

			items.forEach(item => {
				item.shownWeeks = item.weeklyReport.filter(week => {
					if(this.filters.startDate && moment(week.date).isBefore(this.filters.startDate, 'day')) return false
					if(this.filters.endDate && moment(week.date).isAfter(this.filters.endDate, 'day')) return false
					return true
				})
			})

			return items
		},
		deliveryManagers() {
			return this.report
				.map(x => x.deliveryManagerBlueTags)
				.flat()
				.filter((v, i, a) => !!v && a.indexOf(v) === i)
		},
		accountManagers() {
			return this.report
				.map(x => x.customerAccountManagerBlueTag)
				.filter((v, i, a) => !!v && a.indexOf(v) === i)
		},
		appliedFilters() {
			return this.countActiveFilters(this.filters)
		},
	},
	created() {
		this.init()
	},
	methods: {
		init() {
			this.$http.get('api/rpeh/report')
				.then(res => {
					this.report = res.data
				})
				.catch(error => {
					console.error(error)
					this.$root.error('Failed to load billables')
				})
			this.$http.get('api/project?all=true')
				.then(res => {
					this.projects = res.data
				})
				.catch(error => {
					console.error(error)
					this.$root.error('Failed to load projects')
				})
			this.$http.get('api/enums/admin/billable/status')
				.then(res => {
					this.billableStatuses = res.data
				})
				.catch(res => {
					console.error(res)
					window.getApp.error("Cannot obtain billable statuses.")
				})
		},
		openWeeklyReport(billable) {
			this.weeklyReport = billable
			this.showWeeklyReport = true
		},
	},
}
</script>

<style lang="scss" scoped>

</style>