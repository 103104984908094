
<template>
  <div>
    <v-layout row wrap>
        
      <v-flex xs12>
        <q-date-picker label="From" v-model="filters.dateStart"></q-date-picker>
      </v-flex>
      <v-flex xs12>
        <q-date-picker label="To" v-model="filters.dateEnd"></q-date-picker>
      </v-flex>
      <v-flex sm4>
        <v-autocomplete label="Reporter" :items="users" 
              item-value="id" item-text="fullName" 
              :filter="(item, queryText, itemText) => normalizeIncludes(item.blueTag + ' ' + item.fullName, queryText)"
              v-model="filters.reporterId" multiple hide-details>

          <template v-slot:item="{ item, attrs }">
            <v-list-item-action>
              <v-checkbox v-model="attrs.inputValue"></v-checkbox>
            </v-list-item-action>
            <v-list-item-content class="pa-0">
              <v-list-item class="pa-0">
                <v-list-item-avatar color="indigo" class="headline font-weight-light white--text">
                  {{ (item.blueTag || '-').charAt(0) }}
                </v-list-item-avatar>
                <v-list-item-content>
                  <v-list-item-title v-text="item.blueTag"></v-list-item-title>
                  <v-list-item-subtitle v-text="item.fullName"></v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
            </v-list-item-content>
          </template>
          <template v-slot:selection="{ attrs, item, parent, selected }">
            <v-chip v-if="item === Object(item)"
              v-bind="attrs" class="accent--lighten" :input-value="selected" label small>
              <span class="pr-2">
                {{ item.blueTag }}
              </span>
              <v-icon small @click="parent.selectItem(item)">close</v-icon>
            </v-chip>
          </template>
        </v-autocomplete>
      </v-flex>
      <v-flex sm4>
        <v-select v-model="filters.linked" :items="linkedItems" label="Linked" item-text="name" item-value="id"></v-select>
      </v-flex>
      <v-flex sm2>
        <v-checkbox hide-details v-model="filters.includeEffectiveness" label="Show Effective"></v-checkbox>
      </v-flex>
      <v-flex sm2>
        <v-checkbox hide-details v-model="filters.includeNoProjectLinked" label="Include activities without project"></v-checkbox>
      </v-flex>
      <v-flex xs12>
        <v-btn rounded color="primary" @click="updateReport()">
          Update
        </v-btn>
        <v-tooltip top>
          <template v-slot:activator="{ on }">
            <div small class="excel-btn" text v-on="on" @click="downloadExcel">
              <img :src="excelIcon"/>
            </div>
          </template>
          <span>Download Excel</span>
        </v-tooltip>
      </v-flex>
      <v-flex xs12>
        <v-menu v-model="menu" :close-on-content-click="false" bottom offset-y>
          <template v-slot:activator="{ on }">
            <v-btn rounded small v-on="on">
              View...
            </v-btn>
          </template>
          <v-card>
            <v-list dense>
              <v-list-item><v-list-item-action><v-checkbox dense v-model="vo.showCol['reporterBlueTag']" color="primary"></v-checkbox></v-list-item-action><v-list-item-title> BlueTag</v-list-item-title></v-list-item>
              <v-list-item><v-list-item-action><v-checkbox dense v-model="vo.showCol['reporterFullName']" color="primary"></v-checkbox></v-list-item-action><v-list-item-title> Reporter</v-list-item-title></v-list-item>
              <v-list-item><v-list-item-action><v-checkbox dense v-model="vo.showCol['date']" color="primary"></v-checkbox></v-list-item-action><v-list-item-title> Date</v-list-item-title></v-list-item>
              <v-list-item><v-list-item-action><v-checkbox dense v-model="vo.showCol['week']" color="primary"></v-checkbox></v-list-item-action><v-list-item-title> Week</v-list-item-title></v-list-item>
              <v-list-item><v-list-item-action><v-checkbox dense v-model="vo.showCol['linkedItem']" color="primary"></v-checkbox></v-list-item-action><v-list-item-title> Linked</v-list-item-title></v-list-item>
              <v-list-item><v-list-item-action><v-checkbox dense v-model="vo.showCol['linkedItem.name']" color="primary"></v-checkbox></v-list-item-action><v-list-item-title> Task</v-list-item-title></v-list-item>
              <v-list-item><v-list-item-action><v-checkbox dense v-model="vo.showCol['description']" color="primary"></v-checkbox></v-list-item-action><v-list-item-title> Description</v-list-item-title></v-list-item>
              <v-list-item><v-list-item-action><v-checkbox dense v-model="vo.showCol['workItemLinkedWorkItems']" color="primary"></v-checkbox></v-list-item-action><v-list-item-title> Related Work Items</v-list-item-title></v-list-item>
              <v-list-item><v-list-item-action><v-checkbox dense v-model="vo.showCol['executed']" color="primary"></v-checkbox></v-list-item-action><v-list-item-title> Executed</v-list-item-title></v-list-item>
              <v-list-item v-if="includeEffectiveness"><v-list-item-action><v-checkbox dense v-model="vo.showCol['effective']" color="primary"></v-checkbox></v-list-item-action><v-list-item-title> Effective</v-list-item-title></v-list-item>
              <v-list-item><v-list-item-action><v-checkbox dense v-model="vo.showCol['pending']" color="primary"></v-checkbox></v-list-item-action><v-list-item-title> Pending</v-list-item-title></v-list-item>
              <v-list-item><v-list-item-action><v-checkbox dense v-model="vo.showCol['categoryName']" color="primary"></v-checkbox></v-list-item-action><v-list-item-title> Category</v-list-item-title></v-list-item>
              <v-list-item><v-list-item-action><v-checkbox dense v-model="vo.showCol['subcategoryName']" color="primary"></v-checkbox></v-list-item-action><v-list-item-title> Subcategory</v-list-item-title></v-list-item>
            </v-list>

            <v-card-actions>
              <v-spacer></v-spacer>

              <v-btn color="primary" text @click="menu = false">Close</v-btn>
            </v-card-actions>
          </v-card>
        </v-menu>
      </v-flex>
      <v-flex xs12>
        <v-data-table
          :headers="visibleHeaders"
          :items="efforts"
          class="elevation-1"
          
        >
          <template v-slot:item.reporterBlueTag="props">
            <div class="nobr" style="min-width:65px">
              {{props.item.reporterBlueTag}}
            </div>
          </template>
          <template v-slot:item.reporterFullName="props">
            <div class="nobr" style="min-width:70px">
              {{props.item.reporterFullName}}
            </div>
          </template>
          <template v-slot:item.date="props">
            <div class="nobr" style="min-width:45px">
              {{props.item.date|formatDateWeekDay}}
            </div>
          </template>
          <template v-slot:item.week="props">
            <div class="nobr" style="min-width:50px">
              {{props.item.week}}
            </div>
          </template>
          <template v-slot:item.linkedItem="props">
            <div style="min-width:56px">
              {{(props.item.linkedItem) ? 'Linked' : 'Unlinked'}}
            </div>
          </template>
          <template #[`item.linkedItem.name`]="props">
            <div v-if="props.item.linkedItem" style="min-width:45px">
              {{props.item.linkedItem.name}}
            </div>
          </template>
          <template v-slot:item.description="props">
            <div style="min-width:82px">
              {{props.item.description}}
            </div>
          </template>
          <template v-slot:item.workItemLinkedWorkItems="props">
            <div style="min-width:82px" class="d-flex flex-column">
              <span v-for="linked in props.item.workItemLinkedWorkItems" :key="linked.id" class="nobr">
                {{ linked.relatedWorkItemCode }}
              </span>
            </div>
          </template>
          <template v-slot:item.executed="props">
            <div style="min-width:70px" v-if="props.item.executed">
              {{props.item.executed | number(2)}}
            </div>
            <div style="min-width:70px" v-else>
              -
            </div>
          </template>
          <template v-slot:item.effective="props">
            <div class="green--text" style="min-width:68px">
              {{props.item.effective|number(2)}}
            </div>
          </template>
          <template v-slot:item.pending="props">
            <div v-if="props.item.pending" style="min-width:65px">{{props.item.pending|number(2)}}</div>
            <div v-if="!props.item.pending" style="min-width:65px">-</div>
          </template>
          <template v-slot:item.categoryName="props">
            <div class="nobr" style="min-width:68px">
              {{props.item.categoryName || 'Ninguna'}}
            </div>
          </template>
          <template v-slot:item.subcategoryName="props">
            <div class="nobr" style="min-width:90px">
              {{props.item.subcategoryName || 'Ninguna'}}
            </div>
          </template>
          <template slot="footer">
            <th v-if="colspan1" :colspan="colspan1" class="text-right">Total</th>
            <th class="text-center">{{totalExecuted | number(2)}}</th>
            <th v-if="colspan2" :colspan="colspan2"></th>
          </template>
        </v-data-table>
      </v-flex>
    </v-layout>
  </div>
</template>

<script>
import _ from "lodash"
import moment from 'moment'
import excelIcon from '../../assets/excel-icon.svg';

export default {
  props: ["id", 'projectName'],
  data: function() {
    return {
      excelIcon,
      headers: [
        { show: 'reporterBlueTag',  text: "BlueTag",      value: "reporterBlueTag" },
        { show: 'reporterFullName', text: "Reporter",     value: "reporterFullName" },
        { show: 'date',             text: "Date",         value: "date" },
        { show: 'week',             text: "Week",         value: "week" },
        { show: 'linked',           text: "Linked",       value: "linkedItem", sort: (a, b) => {
          if(a && !b) return -1
          if(!a && b) return 1
          return 0
        } },
        { show: 'task',             text: "WorkItem / Task",         value: "linkedItem.name" },
        { show: 'description',      text: "Description",  value: "description" },
        { show: 'workItemLinkedWorkItems',text: "Related Work Items",value: "workItemLinkedWorkItems" },
        { show: 'executed',         text: "Executed",     value: "executed", align: "center" },
        { show: 'effective',        text: "Effective",    value: "effective", align: "center" },
        { show: 'pending',          text: "Pending",      value: "pending", align: "center" },
        { show: 'categoryName',     text: "Category",     value: "categoryName" },
        { show: 'subcategoryName',  text: "Subcategory",  value: "subcategoryName" },
      ],
      filters: {
        dateStart: moment().startOf('day').add(-7, 'days').format("YYYY-MM-DD"),
        dateEnd: moment().startOf('day').format("YYYY-MM-DD"),
        projectId: this.id,
        reporterId: null,
        linked: null,
        includeEffectiveness: true,
        includeNoProjectLinked: false
      },
      project: {},
      includeEffectiveness: true,
      vm: {},
      vo: {
        showCol: {
          reporterBlueTag: true,
          reporterFullName: true,
          date: true,
          week: true,
          //projectName: true,
          //projectTypeName: true,
          //projectCustomerProduct: true,
          linkedItem: true,
          'linkedItem.name': true,
          description: true,
          executed: true,
          effective: true,
          pending: true,
          categoryName: true,
          subcategoryName: true,
        }
      },
      colspan1: 0,
      colspan2: 0,
      efforts: [],
      users: [],
      totalExecuted: null,
      totalEffective: null,
      linkedItems: [
        {id: null, name: 'All'},
        {id: false, name: 'Unlinked'},
        {id: true, name: 'Linked'}
      ],
      menu: false,
    };
  },
  created() {
    this.$q.log(1, 16, 3, this.id, this.$parent.item.id)
    if (this.$route.query.from) {
      this.filters.dateStart = moment(this.$route.query.from).format("YYYY-MM-DD")
    }
    if (this.$route.query.to) {
      this.filters.dateEnd = moment(this.$route.query.to).format("YYYY-MM-DD")
    }
    if (this.$route.query.linked === "1") {
      this.filters.linked = 1
    }
    if (this.$route.query.linked === "0") {
      this.filters.linked = 0
    }
    if (this.$route.query.reporterId) {
      this.filters.reporterId = this.$route.query.reporterId
    }
    if (this.$route.query.showEffective === "true" || this.$route.query.showEffective === "false") {
      this.filters.includeEffectiveness = this.$route.query.showEffective
    }
    

    this.$http
      .get(`/api/user`)
        .then(res => {
          this.users = res.data
        })
        .catch(res => {
          window.getApp.error("Cannot obtain users.")
          console.log(res)
        })
    if(this.id){
      this.$http
        .get(`/api/project/${this.id}`)
        .then(res => {
          this.project = res.data
        })
        .catch(res => {
          window.getApp.error("Cannot get project sprints, tags and status.")
          console.error(res)
        })
    }

    let hasPredefinedQuery = !!_.values(this.$route.query).length
    if(hasPredefinedQuery) {
      this.updateReport()
    }

  },
  watch: {
    "vo.showCol": {
      deep: true,
      handler: function () { 
        // this.hideColumns();
        this.updateColspans(); 
      },
    }
  },
  computed: {
    visibleHeaders() {
      let headers = this.headers.filter(h => {
        let visible = this.vo.showCol[h.value];
        if (h.value === "effective" ) {
          visible = visible && this.includeEffectiveness;
        }
        return visible;
      });
      return headers;
    }
  },
  methods: {
    // hideColumns() {
    //   for (let name in this.vo.showCol) {
    //     let header = this.headers.find(h => h.value === name)
    //     if (header) {
    //       header.class = this.vo.showCol[name] ? "" : "d-none"
    //     }
    //   }
    // },
    updateColspans() {
      this.colspan1 = 0;
      this.colspan2 = 0;
      if (this.vo.showCol.reporterFullName) this.colspan1 += 1;
      if (this.vo.showCol.date) this.colspan1 += 1;
      if (this.vo.showCol.week) this.colspan1 += 1;
      if (this.vo.showCol.typeName) this.colspan1 += 1;
      if (this.vo.showCol.projectName) this.colspan1 += 1;
      if (this.vo.showCol.projectTypeName) this.colspan1 += 1;
      if (this.vo.showCol.projectCustomerProduct) this.colspan1 += 1;
      if (this.vo.showCol.linkedItem) this.colspan1 += 1;
      if (this.vo.showCol['linkedItem.name']) this.colspan1 += 1;
      if (this.vo.showCol.description) this.colspan1 += 1;

      if (this.vo.showCol.categoryName) this.colspan2 += 1;
      if (this.vo.showCol.subcategoryName) this.colspan2 += 1;
    },
    updateReport() {
      this.updateColspans()
      let filters = JSON.parse(JSON.stringify(this.filters));
      filters.projectId = [this.project.id];
      this.$http
        .post(`/api/report/activityLog`, filters)
        .then(res => {
          this.includeEffectiveness = this.filters.includeEffectiveness
          this.efforts = res.data
            .map(x => ({
              ...x,
              week: moment(x.date).format('GGGG-ww'),
            }))
          for(let effort of this.efforts){
            let tagDiv = document.createElement("div")
            tagDiv.innerHTML = effort.description || ''
            effort.description = tagDiv.innerText
          }
          // initTable()
          this.totalExecuted = _.map(this.efforts, "executed").reduce(function (a, b) { return a + b }, 0)
          this.totalEffective = _.map(this.efforts, "effective").reduce(function (a, b) { return a + b }, 0)
        })
        .catch(res => {
          window.getApp.error("Cannot obtain report.")
          console.log(res)
        })

    },
    formatNumber(value, decimalPlaces, minDecimalPlaces) {
      if (value === null || value === undefined || !value.toFixed) return;
      if (!decimalPlaces) {
        decimalPlaces = 0;
      }
      if (!minDecimalPlaces && minDecimalPlaces !== 0) {
        minDecimalPlaces = decimalPlaces;
      }
      return value.toLocaleString('en', {
        maximumFractionDigits: decimalPlaces,
        minimumFractionDigits: minDecimalPlaces,
      });
    },
    downloadExcel(){
      let props = {
        Title: `Activity Log`,
        Subject: "Activity Log",
        Author: 'BlueSurf',
        CreatedDate: new Date()
      };

      // Fill sheet info
      let data = [], row = [];

      for(let header of this.headers) {
        if(this.vo.showCol[header.value])
          row.push(header.text)
      }
      data.push(row);

      for(let effort of this.efforts) {
        row = []
        for(let header of this.headers) {
          if(this.vo.showCol[header.value]){
            if(header.value == 'date'){
              row.push(moment(String(effort.date)).format('(ddd) MMM D, YYYY'))
            }
            else if(header.value == 'week'){
              row.push(moment(String(effort.date)).format('GGGG-ww'))
            }
            else if(header.value == 'linkedItem'){
              row.push(effort.linkedItem ? 'Linked' : 'Unlinked')
            }
            else if(header.value == 'linkedItem.name'){
              row.push(effort.linkedItem?.name || '')
            }
            else if(header.value == 'description'){
              row.push(effort.description || '')
            }
            else if(header.value == 'effective'){
              row.push(this.formatNumber(effort.effective, 2) || '-')
            }
            else if(header.value == 'pending'){
              row.push(this.formatNumber(effort.pending, 2) || '-')
            }
            else {
              row.push(effort[header.value] || '')
            }
          }
        }
        data.push(row)
      }

      let sheets = [{
        name: 'Activity Log',
        data
      }]

      this.createExcel(props, sheets);
    },
  }
}
</script>

<style scoped lang="scss">
select.form-control {
  height: 26px;
  padding: 3px 6px;
  border-radius: 2px;
  margin-bottom: 10px;
  width: 300px;
}
.view-options label { margin: 2px; }
.view-options .checkbox { margin: 2px; }
.excel-btn{
  position: absolute;
  right: 10px;
  background: #21a366;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  height: 38px;
  width:  38px;
  color: white;
  border-radius: 50%;
  cursor: pointer;
  margin: 0 15px;
  box-shadow: 0 2px 5px #888;
  
  img{
    filter: invert(99%) sepia(7%) saturate(35%) hue-rotate(255deg) brightness(110%) contrast(99%);
    width: 28px;
  }
}
</style> 