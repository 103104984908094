<template>
	<div>
		<data-table
			:headers="headers"
			:items="items"
		>
			<template #[`item.requestorBlueTag`]="{ item }">
				<b>
					<span>{{ item.requestorBlueTag }}</span>
				</b>
				<br>
				<span>{{ item.requestorJobTitle }}</span>
			</template>
			<template #[`item.requestorBlueniversary`]="{ item }">
				{{ item.requestorBlueniversary | formatDate }}
			</template>
			<template #[`item.assignments`]="{ item }">
				<span
					v-for="(project, ix) in item.basicCustomerProjects"
					:key="ix"
					class="py-1"
					:set="type = projectTypes.find(t => t.value == project.type)"
				>
					<div class="project-container" :style="{background: type.lightColor}">
						<div class="letter" :style="{background: type.color}">
							{{type.small}}
						</div>
						<div class="name">{{project.name}}</div>
					</div>
				</span>
			</template>
			<template #[`item.type`]="{ item }">
				<span class="text-no-wrap">
					{{ types.find(t => t.id == item.type) ? types.find(t => t.id == item.type).name : '' }}
				</span>
			</template>
			<template #[`item.lines`]="{ item }">
				<span>
					<span v-if="item.lines.some(x => x.date)">
						{{ item.lines | formatDates }}
					</span>
				</span>
			</template>
			<template #[`item.createdAt`]="{ item }">
				{{ item.createdAt | formatDate }}
			</template>
			<template #[`item.status`]="{ item }">
				<v-menu
					open-on-hover
					style="z-index: 230"
					class="menu "
				>
					<template #activator="{ on }">
						<span :set="status = requestStatus.find(s => s.value == item.status)">
							<v-chip
								:color="status.background"
								:style="{ color: status.color }"
								v-on="on"
								small
							>
								{{ status.name }}
							</v-chip>
						</span>
					</template>
					<v-list dense>
						<v-list-item
							v-for="(requestApprover, ix) in item.requestApprovers"
							:key="ix"
						>
							<div
								class="d-flex justify-space-between align-center"
								style="min-width: 20px; width: 100%"
							>
								<span class="mr-2 body-2">
									<user-avatar :user-id="requestApprover.approverId" :size="28" hide-loading/>
									{{ requestApprover.approverBlueTag }}
								</span>
								<span :set="status = requestApproverStatus.find(s => s.value == requestApprover.status)">
										<v-chip
											:color="status.background"
											:style="{ color: status.color }"
											small
										>
											{{ status.name }}
										</v-chip>
									</span>
							</div>
						</v-list-item>
						<v-list-item
							v-if="item.cancellationRemark"
						>
							<div
								class="d-flex justify-space-between align-center"
								style="min-width: 20px; width: 100%"
							>
								<span class="mr-2 body-2">
									{{ item.cancellationRemark }}
								</span>
							</div>
						</v-list-item>
					</v-list>
				</v-menu>
			</template>
			<template #[`item.actions`]="{ item }">
				<div class="nobr">
					<v-tooltip top v-for="(action, ix) in actions" :key="ix">
						<template v-slot:activator="{ on }">
							<v-btn
								@click.stop="openCancellationDialog(item, action.value)"
								icon
								fab
								v-on="on"
								:color="action.color"
								x-small
								:disabled="disableRequest(action, item)"
							>
								<span class="material-symbols-outlined" v-if="action.googleIcon">
									{{ action.icon }}
								</span>
								<v-icon v-else>{{ action.icon }}</v-icon>
							</v-btn>
						</template>
						<span>
							{{ action.requestApproverStatus }}
						</span>
						<v-dialog v-model="dialogOpen" persistent max-width="600px">
								<v-card>
									<v-card-title>
										<span class="text-h5">Cancelation Remarks</span>
									</v-card-title>
									<v-card-text>
										<v-container>
											<v-row>
												<v-col cols="12" sm="12" md="12">
													<v-text-field
														label="Please provide a short message about the cancelation"
														required
														v-model="cancellationRemark"
													></v-text-field>
												</v-col>
											</v-row>
										</v-container>
									</v-card-text>
									<v-card-actions>
										<v-spacer></v-spacer>
										<v-btn color="blue darken-1" text @click="dialogOpen = false">
											Close
										</v-btn>
										<v-btn
											@click.stop="approve(item, action.value)"
											:disabled="!cancellationRemark || cancellationRemark.length == 0"
											color="blue darken-1"
											text
										>
											Confirm
										</v-btn>
									</v-card-actions>
								</v-card>
						</v-dialog>
					</v-tooltip>
				</div>
			</template>
		</data-table>

		<blue-confirm-dialog ref="confirmDialog"/>
		<blue-confirm-dialog ref="confirmApproveDialog">
			<template #text>
				<div>
					You are about to approve the <b>{{ selectedRequest.requestorBlueTag }}'s'</b> vacation days
					request. Please, check that the data is correct and confirm the request.
				</div>
				<div>
					Requested Days: <b> {{ selectedRequest.lines.length }} </b> ({{ selectedRequest.lines | formatDates}})
				</div>
				<div>
					Remaining Days: <b class="error--text"> {{ selectedRequest.availableDays - selectedRequest.lines.length }} </b>
				</div>
			</template>
		</blue-confirm-dialog>
	</div>
</template>
<script>
import moment from "moment"
import BillableJSON from '../../json/billable.json'

export default {
	props: { items: Array },
	data() {
		return {
      dialogOpen: false,
      cancellationRemark: '',
			headers: [
				{
					text: 'BlueTag', 					value: 'requestorBlueTag',
					sort: (a, b) => a.localeCompare(b),
				},
				{ text: 'Blueniversary', 	value: 'requestorBlueniversary' },
				{ text: 'Assignments', 			value: 'assignments' },
				{ text: 'Type', 						value: 'type' },
				{ text: 'Available Days', 	value: 'availableDays' },
				{ text: 'Requested Days', 	value: 'lines.length', align: 'center' },
				{ text: 'Requested Dates', 	value: 'lines' },
				{ text: 'Comments', 				value: 'requestorComments' },
				{ text: 'Request Date',   	value: 'createdAt', width: 130 },
				{ text: 'Status', 					value: 'status' },
				{ text: "", 								value: "actions", class: "nobr", align: 'right', sortable: false }, 
			],
			requestStatus: [
				{ name: 'Requested', value: 0, background: '#E5E5E5', color: '#00000080' },
				{ name: 'Draft',     value: 1, background: '#E0EDFE', color: '#0270FC' },
				{ name: 'Approved',  value: 2, background: '#CEEAD6', color: '#098129' },
				{ name: 'Rejected',  value: 3, background: '#F8DAD7', color: '#DC4437' },
				{ name: 'Cancelled', value: 4, background: 'red',     color: '#fff' },
			],
			requestApproverStatus : [
				{ name: 'Pending Approval', value: 0, background: '#E5E5E5', color: '#00000080' },
				{ name: 'Draft',            value: 1, background: '#E0EDFE', color: '#0270FC' },
				{ name: 'Approved',         value: 2, background: '#CEEAD6', color: '#098129' },
				{ name: 'Rejected',         value: 3, background: '#F8DAD7', color: '#DC4437' },
				{ name: 'Cancelled',        value: 4, background: 'red',     color: '#fff' },
			],
			actions : [
				{ requestApproverStatus: 'Draft',   googleIcon: false, icon: 'mdi-arrow-u-left-bottom', value: 1, color: '' },
				{ requestApproverStatus: 'Approve', googleIcon: true, icon: 'check_circle', value: 2, color: 'success' },
				{ requestApproverStatus: 'Reject',  googleIcon: true, icon: 'cancel', value: 3, color: 'error' },
				{ requestApproverStatus: 'Cancel',  googleIcon: true, icon: 'close', value: 4, color: 'error', cancelPermissionNedeed: true },
			],
			projectTypes: [],
			types: [],
			selectedRequest: null,
		}
	},
	
	computed: {
		
	},
	created() {
		this.projectTypes = BillableJSON.projectTypes
		this.$http.get(`api/enums/recessRequestTypes`)
			.then(res => {
				this.types = res.data
			})
			.catch(error => {
				console.error(error)
				this.$root.error('Failed to get types')
			})
	},
	methods: {
		disableRequest(action, request) {
			if(action.cancelPermissionNedeed)
				return !this.$security.can('delete') || request.statusIsFinal
			return !request.canEdit
		},
		openCancellationDialog(request, status) {
			this.cancellationRemark = ''
      if (status == 4) {
        this.dialogOpen = true;
      }
      else{
        this.approve(request, status);
      }
    },
    openConfirmDialog() {
      return this.$refs.confirmDialog.open({
        title: "Confirm cancellation",
        description: "Are you sure you want to cancel this request?",
        confirmBtnColor: "error",
      });
    },
    async approve(request, status) {
      const cancelStatusValue = 4
			const approveStatus = 2
      if (status == cancelStatusValue) {
        let res = await this.openConfirmDialog()
        if(!res)
        	return
      }
			if (status == approveStatus) {
				const availableDays = request.availableDays - request.lines.length
				if (availableDays < 0) {
					this.selectedRequest = request
					const confirmed = await this.$refs.confirmApproveDialog.open({ title: 'Approve Request' })
					if (!confirmed) return
				}
			}
      this.$http.post(`api/recess/request/${request.id}/status/${status}`, {value: this.cancellationRemark})
      	.then(() => {
      		this.$emit('update')
      		this.$root.$refs.appMenu?.fetchPendingData()
          this.dialogOpen = false;
      	})
      	.catch(err => {
      		console.error(err)
      		this.$root.error('Cannot response this request')
      	})
    },
	},
	filters: {
    formatDates: function(dates) {
      dates = dates.map(d => moment(d.date))

      if(dates.length == 1)
        return dates[0].format("MMM DD") + ' [' + dates[0].format("YYYY") + ']';
      else if(dates.length == 2)
        return `${dates[0].format("MMM DD")} and ${dates[1].format("MMM DD")}` + ' [' + dates[0].format("YYYY") + ']';
      
      let startDate = moment.min(dates)
      let endDate = moment.max(dates)
      let allDatesBetweenStartAndEnd = true

      dates.forEach(date => {
        if(!date.isSameOrBefore(endDate) && !date.isSameOrAfter(startDate)) {
          allDatesBetweenStartAndEnd = false
        }
      })

      if(endDate.diff(startDate, 'days') == dates.length -1 && allDatesBetweenStartAndEnd) {
        startDate = startDate.format("MMM DD")
        endDate = endDate.format("MMM DD")
        return `${startDate} - ${endDate}` + ' [' + dates[0].format("YYYY") + ']';
      }

      return dates.map(d => d.format("MMM DD")).join(", ") + ' [' + dates[0].format("YYYY") + ']';
    }
	},
}
</script>
<style lang="scss" scoped>
.border-top {
	border-top: 1px solid var(--v-lightgray-lighten) !important;
}
.project-container{
	display: inline-block;
	border-radius: 15px;
	margin-right: 5px;
	white-space:nowrap;
	height: 30px;
	div{
		display: inline-flex;
		align-items: center;
		justify-content: center;

	}
	.letter{
		height: 30px;
		width: 30px;
		padding: 5px;
		color: white;
		border-radius: 15px;
	}
	.name{
		padding: 0 10px 0 5px;
		color: black;
	}
}
.v-menu__content {
  border-radius: 12px;
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
  border: 2px solid var(--v-lightgray-lighten) !important;
}
</style>