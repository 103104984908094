<template>
  <div class="pa-3 new-back fill-height" id="pageTable">
    <v-container grid-list-xl fluid>
      <v-layout row wrap>
        <v-container fluid class="new-back fill-height align-start" style="gap: 20px">

          <v-row class="align-center mb-n10">
            <v-col :sm="12" :md="4" :lg="3" :xl="2">
              <div class="new-back lighten2 bordered darken rounded-lg overflow-hidden ">
                <v-app-bar flat dense color="transparent">
                  <v-text-field flat solo style="background: var(--v-newDesignBackground-lighten2) !important"
                    prepend-icon="search" placeholder="Search" v-model="search" hide-details class="hidden-sm-and-down"
                    @keypress.enter="handleEnter"
                  />
                  <v-badge
                    offset-x="17" offset-y="20"
                    color="red" :value="!!appliedFilters" :content="appliedFilters"
                    right small
                  >
                    <v-btn icon @click="filtersActive = !filtersActive">
                      <v-icon>mdi-tune</v-icon>
                    </v-btn>
                  </v-badge>
                </v-app-bar>
                <v-divider></v-divider>
              </div>
            </v-col>
            <v-spacer />
            <v-btn v-if="canCreateProjects" class="mr-3 mb-n5" fab small color="primary" to="/project/new">
              <v-icon>add</v-icon>
            </v-btn>
          </v-row>

          <v-expansion-panels :value="filtersActive ? 0 : null" flat class="pt-6 pb-">
            <v-expansion-panel>
              <v-expansion-panel-content color="newDesignBackground">
                <div class="d-inline-flex" style="gap: 10px">
                  <q-filter-input
                    v-model="filters.types" :items="types"
                    label="Type" item-value="id" item-text="name"
                    outlined hide-details clearable input-select multiple hide-input
                    :parent-shown-items="shownProjects"
                    :data-comparer="(item, value) => item.typeId == value"
                  >
                    <template #selection="{ item }">
                      <v-chip class="ma-1">
                        {{ item.name }}
                      </v-chip>
                    </template>
                  </q-filter-input>
                  <q-filter-input
                    v-model="filters.statuses" :items="statuses"
                    label="Status" item-value="id" item-text="name"
                    outlined hide-details clearable input-select multiple hide-input
                    :parent-shown-items="shownProjects"
                    :data-comparer="(item, value) => item.status == value"
                  />
                </div>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>

          <v-row>
            <v-col v-if="!shownProjects.length">No projects</v-col>
            <v-col
              v-for="project in shownProjects"
              @click="canOpenProjects ? goToDetail(project) : null"
              :key="project.id" :sm="12" :md="4" :lg="3" :xl="2"
            >
              <div
                class="d-flex flex-column justify-space-between bordered rounded-lg fill-height cursor-pointer py-3 px-5"
                style="gap: 20px">
                <span class="title ellipsis-text">{{ project.name }}
                  <template  v-if="canEditProject">
                  <v-menu left offset-x z-index="200">
                    <template #activator="{ on }">
                      <v-btn v-on="on"  style="float:right" icon>
                        <v-icon>mdi-dots-vertical</v-icon>
                      </v-btn>
                    </template>
                    <v-list dense class="py-1">
                      <v-list-item class="pa-1 py-0">
                        <v-tooltip right z-index="200">
                          <template #activator="{ on }">
                            <v-btn v-on="on" @click="close(project.id)" text small >
                              <v-icon>mdi-door</v-icon>
                            </v-btn>
                          </template>
                          <span>Close Project</span>
                        </v-tooltip>
                      </v-list-item>
                    </v-list>
                  </v-menu>
                </template></span>
                
                <div class="body-1 ellipsis-text">
                  Small Code: {{ project.smallCode }}
                  <br>
                  Project Type: {{ project.typeName }}
                </div>

                <div :set="statusColor = colorByStatus[project.status]">
                  <div class="inline-block bordered rounded-pill body-2 px-3 py-1" :style="{
                    'border-color': `${statusColor.color} !important`,
                    'color': `${statusColor.color} !important`,
                    'background': $vuetify.theme.isDark ? '' : `${statusColor.lightColor} !important`,
                  }">
                  {{ project.statusName }}
                 </div>                
                </div>
              </div>
            </v-col>
          </v-row>
        </v-container>
      </v-layout>
    </v-container>
  </div>
</template>

<script>
export default {
  title: 'Projects', 
  created() {
    this.setUserPreferences("ProjectList", "filters")
    this.init()
    this.$q.log(1, 1, 3, 'NA')
  },
  data() {
    return {
      search: "",
      types: [],
      statuses: [],
      items: [],
      colorByStatus: [
        { color: '#808080', lightColor: '#eee' },
        { color: '#16c946', lightColor: '#e1f8dd' },
        { color: '#f57c00', lightColor: '#fbe7d0' },
        { color: '#808080', lightColor: '#eee' },
        { color: 'var(--v-error-base)', lightColor: '#f5d0d0' },
      ],

      projects: [],
      canCreateProjects: false,
      canOpenProjects: false,
      canEditProject: false,

      filtersActive: false,
      filters: {
        types: [],
        statuses: [1],
      },
    };
  },
  computed: {
    shownProjects() {
      let projects = this.projects

      const headers = ['smallCode', 'name', 'typeName', 'statusName']
      projects = this.applySearch(this.search, projects, headers)

      if(this.filters.types.length)
        projects = projects.filter(x => this.filters.types.includes(x.typeId))
      if(this.filters.statuses.length)
        projects = projects.filter(x => this.filters.statuses.includes(x.status))

      return projects
    },
		appliedFilters(){
			return this.countActiveFilters(this.filters)
		},
  },
  methods: {
    init() {     
      this.$security.hasRequiredPermission('add')
        .then(res => this.canCreateProjects = res)
      this.$security.hasRequiredPermission('detail')
        .then(res => this.canOpenProjects = res)
        this.$security.hasRequiredPermission('edit')
        .then(res => this.canEditProject = res)  
        this.canEditProject = false;        
      this.$http
        .get("/api/user/current/project")
        .then(res => {
          this.projects = res.data;            
        })
        .catch(res => {
          window.getApp.error("Cannot get records.");
          console.log(res);
        });

      this.$http.get('api/project/types')
        .then(res => {
          this.types = res.data
        })

      this.$http
        .get(`/api/Enums/ProjectStatus`)
        .then(res => {
          this.statuses = res.data;
        })
        .catch(res => {
          window.getApp.error("Cannot obtain project status.");
          console.log(res);
        });      
    },
    goToDetail(project) {
      this.$router.push({ name: 'ProjectWorkItems', params: { id: project.smallCode || project.id } });
    },
    handleEnter() {
      if (this.search && this.shownProjects.length) {
        this.$router.push({ name: 'ProjectWorkItems', params: { id: this.shownProjects[0].smallCode || this.shownProjects[0].id } });
      }
    },
    async close(projectParam) {
      let message = { title: 'Close Project', description: 'Are you sure you want to close this project?' }
      let res = false
      res = await this.$openConfirmDialog({
        title: message.title,
        description: message.description,
      })
      if (!res) {
        return
      }
      else {
        this.$http
          .get(`/api/project/${projectParam}`)
          .then(res => {
            this.project = res.data,
            this.project.status = 3 //ProjectStatus.Completed
            this.$q.save({
              api: "/api/project",
              data: this.project,
              afterSuccessUrl: null,
              errorMsg: "Cannot save record.",
              afterErrorUrl: null,
            }).then(res => {
              let project = res.data
              let error = typeof project == 'string'
              this.init()
              if (error) {
                window.getApp.error(project);
                return
              }
            })

          });
      }
    }
  }}
  
  </script>

<style scoped lang="scss">
.ellipsis-text {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

::v-deep .v-expansion-panel-content__wrap{
	padding: 0 !important;
}

.v-btn.sort-icon {
  height: 20px;
  width: 20px;
  margin: 0;
}

::v-deep .v-input__slot {
  background: transparent !important;
}
</style>