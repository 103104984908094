export default function(item, types) {
	let data = []
	for(let resources of item.types){
		if(!resources.quantity) continue
		let type = types.find(t => t.value == resources.type)
		data.push({
			name: type.text,
			color: type.color,
			y: resources.quantity,
		})
	}

	return {
		key: item.deliveryManagerId,
		chart: {
			plotBackgroundColor: null,
			plotBorderWidth: null,
			plotShadow: false,
			type: 'pie',
		},
		title: {
			text: item.deliveryManagerBlueTag || item.DeliveryManagerFullName,
			enabled: true,
			layout: 'horizontal',
			align: 'center',
			verticalAlign: 'bottom'
		},
		subtitle: {
			text: `
				Projects: ${item.activeProjectsQuantity} <br/>
				<div style="margin-top: 10px">Workforce: ${item.activeResources}</div>
			`,
			align: 'center',
			verticalAlign: 'middle',
			y: -7,
			style: {
				fontWeight: 'bold',
				fontSize: '1.3em'
			},
		},
		tooltip: {
			pointFormat: '{series.name}: <b>{point.percentage:.1f}%</b>'
		},
		credits: { enabled: false },
		accessibility: {
			point: {
				valueSuffix: '%'
			}
		},
		plotOptions: {
			pie: {
				allowPointSelect: true,
				cursor: 'pointer',
				dataLabels: {
					enabled: true,
					distance: -15,
					format: '{point.y}',
					style: {
						textOutline: 'none',
						fontSize: '1.2em',
					},
				},
				showInLegend: true,
				borderColor: 'none',
			},
		},
		
		series: [{
			innerSize: '70%',
			name: '',
			colorByPoint: true,
			showInLegend: false,
			data,
		}],
	}
}