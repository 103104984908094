<template>
	<div class="px-5 pt-3 new-back full-height">
		<div class="text-right mb-3">
			<v-menu z-index="200" :close-on-content-click="false">
				<template #activator="{ on }">
					<v-btn v-on="on" color="primary" outlined>
						Actions <v-icon right>mdi-chevron-down</v-icon>
					</v-btn>
				</template>
				<v-list class="actions">
					<v-list-item v-if="!editing" @click="editing = true" :disabled="!item.isDraft || item.isClosed">
						<v-list-item-content class="px-3">
							<v-list-item-title class="px-0">
								<v-icon left color="primary">mdi-pencil</v-icon> Edit billable item
							</v-list-item-title>
						</v-list-item-content>
					</v-list-item>
					<v-list-item v-if="canApprove" @click="approve">
						<v-list-item-content class="px-3">
							<v-list-item-title class="px-0">
								<v-icon left color="primary">mdi-check</v-icon> Approve billable item
							</v-list-item-title>
						</v-list-item-content>
					</v-list-item>
					<v-list-item @click="$adminSurf('billableItemToPdf', item)">
						<v-list-item-content class="px-3">
							<v-list-item-title class="px-0">
								<v-icon left color="#ff6347">mdi-file-pdf-box</v-icon> Export to PDF
							</v-list-item-title>
						</v-list-item-content>
					</v-list-item>
					<v-list-item @click="$adminSurf('openHistory', item, 'billableItem')">
						<v-list-item-content class="px-3">
							<v-list-item-title class="px-0">
								<v-icon left color="secondary">mdi-clock-outline</v-icon> Version history
							</v-list-item-title>
						</v-list-item-content>
					</v-list-item>
					<watchers-menu
						@watcher-added="item.subscribers.push($event)"
						@watcher-removed="item.subscribers.splice(item.subscribers.findIndex(x => x.userId == $event), 1)"
						:entity-id="item.id"
						:watchers="item.subscribers"
						required-permission="billableItems"
					>
						<template #default="{ on }">
							<v-list-item v-on="on">
								<v-list-item-content class="px-3">
									<v-list-item-title class="px-0">
										<v-icon left>mdi-eye</v-icon> Watchers
									</v-list-item-title>
								</v-list-item-content>
							</v-list-item>
						</template>
					</watchers-menu>
				</v-list>
			</v-menu>
		</div>

		<v-row class="mb-3">
			<v-col>
				<div
					class="indicator elevation-1 rounded"
				>	
					<v-icon
						color="primary"
						:style="{background: $vuetify.theme.isDark ? '' : '#e8f2fb'}"
					>
						mdi-tag-outline
					</v-icon>
					<div> <h3>{{ currencyFormat(item.total) }}</h3> <span>Billed</span> </div>
				</div>
			</v-col>
			<v-col>
				<div
					class="indicator elevation-1 rounded"
				>
					<v-btn :color="$vuetify.theme.isDark ? 'transparent' : '#fdf4d7'" small depressed fab>
						<v-icon color="#f2ba05" class="mt-n1">
							mdi-receipt-text-check-outline
						</v-icon>
					</v-btn>
					<div> <h3>{{ currencyFormat(item.invoiced) }}</h3> <span>Invoiced</span> </div>
				</div>
			</v-col>
			<v-col>
				<div class="indicator elevation-1 rounded">
					<v-btn :color="$vuetify.theme.isDark ? 'transparent' : '#fcdbdb'" small depressed fab>
						<v-icon color="error" class="mt-n1">
							mdi-clipboard-clock-outline
						</v-icon>
					</v-btn>
					<div> <h3>{{ currencyFormat(item.total - item.invoiced + item.adjustmentAmount) }}</h3> <span>Pending amount to invoice</span> </div>
				</div>
			</v-col>
		</v-row>

		<v-row>
			<v-col :sm="6">
				<v-row>
					<v-col :cols="4" class="pt-0">
						<label>Billable</label>
						<v-text-field :value="item.billableCode" readonly outlined hide-details class="mt-2"/>
					</v-col>
					<v-col :cols="4" class="pt-0">
						<label>Customer</label>
						<v-text-field :value="item.customerName" readonly outlined hide-details class="mt-2"/>
					</v-col>
					<v-col :cols="4" class="pt-0">
						<label>Currency</label>
						<v-select :items="[1]" :value="1" readonly outlined hide-details class="mt-2">
							<template #selection>
								<img
									v-if="item.billableCurrency"
									:height="32" contain class="mr-4"
									:src="require(`../../assets/currencies/${item.billableCurrency.toLowerCase()}.png`)"
								/>
								{{item.billableCurrency}}
							</template>
							<template #append><div></div></template>
						</v-select>
					</v-col>
					<v-col :cols="4" class="pt-0">
						<label>Start Date</label>
						<q-date-picker
							@change="computeHours" :readonly="(!!item.invoices && !!item.invoices.length) || !editing"
							v-model="item.startDate" label=""
							inner outlined class="mt-2"
						/>
					</v-col>
					<v-col :cols="4" class="pt-0">
						<label>End Date</label>
						<q-date-picker
							@change="computeHours" :readonly="(!!item.invoices && !!item.invoices.length) || !editing"
							v-model="item.endDate" label=""
							inner outlined class="mt-2"
						/>
					</v-col>
					<v-col :cols="4" class="pt-0">
						<label>Status</label><br>
						<div class="background--colored mt-2 pt-2 py-3 pl-3 rounded">

							<v-menu offset-x right open-on-hover>
								<template #activator="{ on }">
									<v-btn
										v-on="on"
										:color="item.statusColor"
										outlined style="border-radius: 20px"
										:style="{
											background: $vuetify.theme.isDark ? '' : item.statusLightColor
										}"
									>{{item.statusDisplayName}}</v-btn>
								</template>

								<v-list dense>
									<v-list-item
										v-for="approver in item.approvers.filter(x => x.statusToApprove === 2)"
										:key="approver.id"
									>
										<div
											class="d-flex justify-space-between align-center"
											style="min-width: 20px; width: 100%"
										>
											<span class="mr-2 body-2">
												<user-avatar :user-id="approver.approverId" :size="28" :show-card="false"/>
												{{ approver.approverBlueTag }}
											</span>
											<span :set="status = approverStatus.find(s => s.id == approver.status)">
												<v-chip
													v-if="status"
													:color="status.lightColor"
													:style="{ color: status.color }"
													small
												>
													{{ status.description }}
												</v-chip>
											</span>
										</div>
									</v-list-item>
								</v-list>
							</v-menu>
						</div>
					</v-col>
				</v-row>
				
				<div class="text-right mb-n3">
					<i>All amounts before taxes</i>
				</div>

				<billable-item-lines-table
					:item="item"
					:editing="editing"
					class="mt-4"
				/>

				<div v-if="editing" class="text-right mt-2">
					<v-btn
						v-if="item.isDraft"
						@click="save(true)" color="primary" outlined
						:disabled="!item.startDate || !item.endDate || !item.total"
					>
						Save as draft
					</v-btn>
					<v-btn
						@click="save(false)" color="primary" class="ml-2"
						:disabled="!item.startDate || !item.endDate || !item.total"
					>
						Submit
					</v-btn>
				</div>

			</v-col>
			<v-col>
				<v-card class="new-back lighten2">
					<v-card-title>Related Invoices</v-card-title>
					<v-card-text>
						<v-data-table
							:items="item.invoices" :headers="invoiceHeaders"
							class="new-back lighten2"
							:item-class="() => hasInvoicePermission ? 'cursor-pointer' : ''"
							@click:row="row => hasInvoicePermission && $q.openNewTab(`/admin/invoice/${row.invoiceId}`)"
						>
							<template #[`item.invoiceDate`]="{ item }">
								{{ item.invoiceIssueDate | formatDate }}
							</template>
							<template #[`item.amount`]="{ item }">
								<span :style="{ textDecoration: item.invoiceStatus == 3 ? 'line-through' : '' }">
									{{ currencyFormat(item.amount)  }}
								</span>
							</template>
							<template #[`item.invoiceCurrency`]="{ item }">
								<div class="d-flex align-center">
									<img
										v-if="item.invoiceCurrency"
										:height="20" contain class="mr-1"
										:src="require(`../../assets/currencies/${item.invoiceCurrency.toLowerCase()}.png`)"
									/>
									<b>{{item.invoiceCurrency}}</b>
								</div>
							</template>
							<template #[`item.status`]="{ item }">
								<v-btn
									:color="item.invoiceStatusColor"
									outlined small style="border-radius: 20px"
									:style="{
										background: $vuetify.theme.isDark ? '' : item.invoiceStatusLightColor
									}"
								>{{item.invoiceStatusName}}</v-btn>
							</template>
						</v-data-table>
					</v-card-text>
				</v-card>

				<div class="py-5 px-3">
					<div v-if="!item.adjustmentAmount && Math.abs(item.total - item.invoiced) >= 0.01" class="text-right">
						<v-btn @click="adjustmentDialog" color="primary" small>Adjust Billable Item</v-btn>
					</div>
					<template v-if="item.adjustmentAmount">
						<div class="d-flex align-center">
							<h3>Adjustment</h3>
							<v-btn @click="removeAdjustment" icon color="error">
								<v-icon>delete</v-icon>
							</v-btn>
						</div>
						<div class="d-flex pt-3">
							<div class="flex">
								Amount:
								<br>
								<v-text-field
									:value="currencyFormat(item.adjustmentAmount)"
									outlined readonly dense
									class="d-inline-block input-right"
									:class="{ 'error--text': item.adjustmentAmount < 0, 'success--text': item.adjustmentAmount > 0 }"
								/>
							</div>
							<div class="flex">
								Justification:
								<v-textarea
									:value="item.adjustmentReason"
									outlined readonly no-resize
									rows="3"
								/>
							</div>
						</div>
					</template>
				</div>
			</v-col>
		</v-row>
		<billable-item-adjustment-dialog ref="adjustmentDialog"/>
	</div>
</template>

<script>
import BillableItemLinesTable from '../../components/admin-surf/billable-item/BillableItemLinesTable.vue'
import WatchersMenu from '../../components/admin-surf/WatchersMenu.vue'
import BillableItemAdjustmentDialog from '../../dialogs/adminSurf/BillableItemAdjustmentDialog'

export default {
	props: ['id'],
	components: { BillableItemLinesTable, WatchersMenu, BillableItemAdjustmentDialog },
	data: () => ({
		item: {
			approvers: [],
		},
		selectedLines: [],
		editing: false,
		hasInvoicePermission: false,
		invoiceHeaders: [
			{ text: 'Number', value: 'invoiceCode' },
			{ text: 'Business name', value: 'invoiceRfcName' },
			{ text: 'Invoice Date', value: 'invoiceDate' },
			{ text: 'Amount', value: 'amount' },
			{ text: 'Currency', value: 'invoiceCurrency' },
			{ text: 'Status', value: 'status' },
		],
		approverStatus : [],
		currentUser: {},
	}),
	mounted(){
		this.$security.hasRequiredPermission('detail', 'adminSurfInvoices')
			.then(res => this.hasInvoicePermission = res)

		this.$blueSystem.getCurrentUser()
			.then(user => {
				this.currentUser = user
			})
			.catch(error => this.$root.error('Failed to get user'))

		this.$http.get('api/enums/approver/status')
			.then(res => {
				this.approverStatus = res.data
			})
			.catch(err => {
				console.error(err)
				this.$root.error('Cannot obtain billable items')
			})

		this.$http.get(`api/billableItem/${this.id}`)
			.then(res => {
				this.item = res.data
				this.item.lines.forEach(line => {
					line.selectedMembers = line.members
				})
				this.$q.changeHeaderTitle(this.item.code)
			})
			.catch(err => {
				console.log(err)
				window.getApp.error("Cannot get billable item")
			})
	},
	computed: {
		isFixedRate(){
			return this.item.billableType === 0
		},
		canApprove() {
			return !this.item.isDraft && !this.item.reviewed &&
				this.item.approvers?.some(x =>
						x.approverId == this.currentUser.id &&
						x.statusToApprove === 2 &&
						x.status === 0
					)
		},
	},
	methods: {
		save(isDraft){
			const toSave = structuredClone(this.item)
			toSave.isDraft = isDraft
			if(toSave.billableType !== 0){
				toSave.lines.forEach(x => x.members = x.selectedMembers)
				toSave.lines = toSave.lines.filter(x => x.members.length)
			}
			else{
				toSave.lines = toSave.lines.filter(x => x.total > 0)
			}

			this.$http.post('api/BillableItem', toSave)
				.then(res => {
					window.getApp.success('Updated successfully')
					this.$router.push('/admin/billableItem')
				})
				.catch(err => {
					console.log(err)
					window.getApp.error('Cannot update billable item')
				})
		},
		approve() {
			this.$adminSurf('approveBillableItem', this.item)
				.then(res => {
					this.item = Object.assign(this.item, res.data)
				})
				.catch(err => {
					console.error(err)
					this.$root.error('Cannot approve billable item')
				})
		},
		adjustmentDialog(){
			return this.$refs.adjustmentDialog.open(this.id)
				.then(() => {
					this.$http.get(`api/billableItem/${this.id}`)
						.then(res => {
							this.item = res.data
							this.item.lines.forEach(line => {
								line.selectedMembers = line.members
							})
							this.$q.changeHeaderTitle(this.item.code)
						})
						.catch(err => {
							console.error(err)
							window.getApp.error("Cannot get billable item")
						})
				})
		},
		async removeAdjustment(){
			const confirm = await this.$openConfirmDialog({
				title: 'Remove adjustment',
				description: 'Are you sure you want to remove the adjustment?',
			})
			if(!confirm) return

			try {
				const res = await this.$http.delete(`api/billableItem/${this.id}/adjustment`)
				this.item = Object.assign(this.item, res.data)
			} catch (error) {
				if(error.notified) return
				console.error(error)
				this.$root.error('Cannot remove adjustment')
			}
		},
		computeHours(){
			this.$http.post(
				'api/billableItem/build?itemBaseId='+this.item.id,
				{
					billableIds: [this.item.billableId],
					startDate: this.item.startDate,
					endDate: this.item.endDate
				}
			)
				.then(res => {
					this.item = res.data[0]
					this.item.lines.forEach(line => {
						line.selectedLines = line.billableItemLines
					})
				})
		},
		computeTotals(billableLine){
			this.$nextTick(() => {
				let hours = billableLine.selectedMembers
					.reduce((acc, line) => acc + line.hours, 0)
				let total = billableLine.selectedMembers
					.reduce((acc, line) => acc + line.total, 0)
				this.$set(billableLine, 'hours', hours)
				this.$set(billableLine, 'total', total)

				hours = this.item.lines
					.reduce((acc, line) => acc + line.hours, 0)
				total = this.item.lines
					.reduce((acc, line) => acc + line.total, 0)
				this.$set(this.item, 'hours', hours)
				this.$set(this.item, 'total', total)
			})
		},
		toggleExpanded(line){
			this.$set(line, 'expanded', !line.expanded)
		},
	},
}
</script>

<style lang="scss" scoped>
::v-deep .actions .v-list-item{
	min-height: 0 !important;
	padding: 0;
	div{
		padding-left: 15px;
		width: 100%;
	}
}

::v-deep .v-input--is-readonly{
	.v-input__slot{
		background: var(--v-coloredBack-base);
	}
	fieldset{
		border: none;
	}
}

::v-deep .success--text input{
	color: var(--v-success) !important;
}
::v-deep .error--text input{
	color: var(--v-error-base) !important;
}

.indicator{
	display: grid;
	grid-template-columns: 50px auto;
	background: var(--v-newDesignBackground-lighten2);
	padding: 10px;
	border-radius: 8px;
	align-items: center;

	.v-icon{
		width: 40px;
		height: 40px;
		border-radius: 50%;
	}
	&.indicator-error{
		background: var(--v-error-lighten);
	}
}
.assignments{
	border-radius: 5px;
	margin-top: 20px;
	
	.line{
		display: grid; 
		grid-template-columns: 3fr 2fr 120px 120px 2fr;
		align-items: center;
		height: 60px;
		padding: 0 15px;
	}
	.header{
		border-bottom: 1px solid var(--v-lightgray-base);
		height: 40px;
	}

	.assignment{
		border-bottom: 1px solid var(--v-lightgray-base);
	}

	.chevron{
		border: 1px solid var(--v-lightgray-base);
		border-radius: 5px;
	}
}
.grey-text{
	color: var(--v-text-lighten2);
}
.footer{
	display: grid;
	grid-template-columns: 3fr 2fr 120px 120px 2fr;
	padding: 0 15px;
	min-height: 50px;
	align-items: center;
}
::v-deep .v-data-table__selected{
	background: inherit !important;
}
</style>