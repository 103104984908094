<template>
  <v-dialog v-model="show" persistent max-width="700px">
    <v-card>
      <v-card-title>
        <span class="headline"> Status </span>
        <br />
        <div class="cyan--text" v-if="!status.id">New</div>
        <div class="cyan--text" v-if="status.id">Edit</div>
      </v-card-title>
      <v-card-text>
        <v-container>
          <v-row>
            <v-col :cols="12" class="pa-1">
              <v-text-field v-model="status.name" label="Name"/>
            </v-col>
            <v-col :cols="12" class="pa-1">
              <v-text-field v-model="status.description" label="Description"/>
            </v-col>
            <v-col :cols="5" class="pa-1">
              <v-text-field
                label="Limit"
                placeholder="Max number of cards in status column"
                v-model="status.limit"
                type="number"
                :min="1"
                :rules="[rules.minValue]"
                @wheel="$event.target.blur()"
              >
              </v-text-field>
            </v-col>
            <v-col :cols="1"></v-col>
            <v-col :cols="3" class="pa-1">
              <v-text-field
                label="Objective Cycle Time"
                v-model="status.cycleTimeGoal"
                :min="1"
                :rules="[rules.minValue]"
                type="number"
              >
              </v-text-field>
            </v-col>
            <v-col :cols="3" class="pa-1">
              <v-select
                v-model="status.cycleTimeGoalUnit"
                :items="cycleTimeGoalUnits"
                :item-text="
                  (item) =>
                    status.cycleTimeGoal == 1 ? item.name.substr(0, item.name.length-1) : item.name
                "
                :item-value="(item) => item.value"
              />
            </v-col>
            <v-col :cols="12" class="pa-1">
              <v-textarea
                label="Definition of Done"
                v-model="status.definitionOfDone"
                rows="2"
                auto-grow
              ></v-textarea>
            </v-col>
            <v-col :cols="12" class="pa-1">
              <v-autocomplete
                label="For Type"
                :disabled="!!status.id"
                :items="types"
                item-value="value"
                item-text="displayName"
                v-model="status.workItemType"
              >
                <template v-slot:item="{ item }">
                  <v-icon :color="item.color" class="mr-2">
                    {{ item.iconClass }}
                  </v-icon>
                  {{ item.displayName }}
                </template>
                <template v-slot:selection="{ item }">
                  <v-icon :color="item.color" class="mr-2">
                    {{ item.iconClass }}
                  </v-icon>
                  {{ item.displayName }}
                </template>
              </v-autocomplete>
            </v-col>
            <v-col :cols="12" class="pa-1">
              <v-autocomplete
                v-model="destinations"
                label="Destinations"
                :items="shownStatuses"
                item-text="name"
                item-value="id"
                multiple
              >
                <template #selection="{ item }">
                  <v-chip label :color="item.color" small dark>
                    <v-icon left :color="item.textColor" small>
                      {{ item.iconClass }}
                    </v-icon>
                    {{ item.name }}
                  </v-chip>
                </template>
              </v-autocomplete>
            </v-col>
            <v-col :cols="6" class="pa-1">
              <label>Color</label>
              <v-color-picker
                label="Color"
                v-model="status.color"
                mode="hexa"
              ></v-color-picker>
            </v-col>
            <v-col :cols="6" class="pa-1">
              <label>Icon</label>
              <br />
              <v-btn
                fab dark
                :color="status.color"
                @click="iconPickerDialog = true"
              >
                <v-icon dark>{{ status.iconClass }}</v-icon>
              </v-btn>
            </v-col>
            <v-col :cols="6" :md="3" class="pa-1">
              <v-checkbox
                label="Is Initial"
                hide-details
                v-model="status.isInitial"
                :disabled="status.isFinal"
              ></v-checkbox>
            </v-col>
            <v-col :cols="6" :md="3" class="pa-1">
              <v-checkbox
                label="Is Final"
                hide-details
                v-model="status.isFinal"
                :disabled="status.isInitial"
              ></v-checkbox>
            </v-col>
            <v-col :cols="6" :md="3" class="pa-1">
              <v-checkbox
                label="Is Default"
                hide-details
                v-model="status.isDefault"
              ></v-checkbox>
            </v-col>
            <v-col :cols="6" :md="3" class="pa-1">
              <v-checkbox
                label="Active"
                hide-details
                v-model="status.active"
              ></v-checkbox>
            </v-col>
            <v-col :cols="6" :md="3" class="pa-1">
              <v-checkbox
                label="Confetti"
                hide-details
                v-model="status.confetti"
              ></v-checkbox>
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          @click="close"
          color="blue darken-1" text
        >
					Close
				</v-btn>
        <v-btn color="blue darken-1" dark @click="save">
					Save
			</v-btn>
      </v-card-actions>
    </v-card>

    <v-dialog v-if="iconPickerDialog" v-model="iconPickerDialog">
      <v-card>
        <v-card-title class="headline" primary-title>
          Select Icon
        </v-card-title>
        <v-card-text>
          <v-text-field placeholder="search" v-model="searchIcon" hide-details outlined></v-text-field>
          <div v-for="(category, ix) in iconCategories" :key="ix">
            <h2 class="mt-5 mb-2 text-capitalize">{{category.name}}</h2>
            <v-row wrap>
              <template v-for="(icon, ix) in category.icons">
                <v-col class="icon-tile d-inline-block text-center" v-if="!icon.hidden" :key="ix">
                  <v-btn x-large dark text color="primary"
                    @click="status.iconClass = icon.name; iconPickerDialog = false">
                    <v-icon large dark>{{icon.name}}</v-icon>
                  </v-btn>
                  <div class="icon-name">
                    <small>
                      {{icon.name}}
                    </small>
                  </div>
                </v-col>
              </template>
            </v-row>
          </div>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="primary" text @click="iconPickerDialog = false">
          Close
        </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-dialog>
</template>

<script>
import materialIcons from './../../utils/material-icons'

export default {
  props: {
    types: Array,
    statuses: Array,
  },
  data: () => ({
    status: {},
    show: false,
    rules: {
      minValue: value => value >= 0 || 'Add a positive value',
    },
    destinations: [],

    iconPickerDialog: false,
    iconCategories: [],
    icons: [],
    searchIcon: '',

    resolve: null,
  }),
  created(){
    // Initialize iconCategories
    this.icons = materialIcons
    let categories = this.icons.map(i => i.categories).flat()
    categories = [...new Set(categories)]
    categories.sort()
    categories = categories
      .map(name => ({ 
        name: name,
        icons: this.icons.filter(i => i.categories.indexOf(name) != -1),
      }))
    this.iconCategories = categories
  },
  computed: {
    shownStatuses() {
      return this.statuses.filter(s =>
        !s.deleted && s.active &&
        this.status.workItemType == s.workItemType &&
        this.status.id != s.id
      )
    },
  },
  watch: {
    searchIcon() {
      this.iconCategories.forEach(cat => cat.icons.forEach(i => {
        i.hidden = !i.name.includes(this.searchIcon)
          && !(i.tags && i.tags.some(t => t.includes(this.searchIcon)))
      }))
    },
  },
	methods: {
    open(status){
      this.status = structuredClone(status)
			if(this.types.length === 1){
        this.$set(this.status, 'workItemType', this.types[0].value)
				this.status.workItemType = this.types[0].value
			}
      this.show = true
			this.destinations = this.status.destinations?.map(x => x.destinyId) || []

      return new Promise((resolve) => {
        this.resolve = resolve
      })
    },
    save(){
      if((!this.status.workItemType && this.status.workItemType !== 0) || !this.types.some(x => x.value === this.status.workItemType)) {
				this.$root.error('Please select a work item type first')
				return
			}

      this.show = false
			this.status.color = this.status.color.hexa || this.status.color
			this.status.definitionOfDone = JSON.stringify(this.status.definitionOfDone || "")
			this.status.destinations = this.destinations.map(x => ({ destinyId: x }))
      this.resolve(this.status)
    },
    close(){
      this.show = false
      this.resolve(null)
    },
  },
}
</script>

<style lang="scss" scoped>

</style>