<template>
	<div style="position: relative">
		<div class="d-flex flex indicators justify-end pr-3" style="gap: 20px" @click.stop>
			<v-menu bottom offset-y open-on-hover>
				<template #activator="{ on }">
					<div
						v-on="on"
						class="indicator elevation-2 rounded white--text" style="height: 60px"
						:class="{success: !todayIndicators.differenceUsers.length, 'indicator-error': !!todayIndicators.differenceUsers.length}"
					>
						<v-icon size="30" color="white"> mdi-account-multiple </v-icon>
						<div class="d-flex align-center">
							<h3>{{ todayIndicators.currentUsers }} / {{ todayIndicators.expectedUsers }}</h3>
							<span class="ml-1">People</span>
						</div>
					</div>
				</template>
				<v-card max-height="400" class="overflow-y-auto">
					<v-card-text class="pa-0">
						<v-list dense>
							<v-list-item
								v-for="(u, ix) in todayIndicators.differenceUsers" :key="u.id"
								:style="{
									borderBottom: ix < todayIndicators.differenceUsers.length - 1
										? '1px solid var(--v-lightgray-base)' : ''
								}"
							>
								<v-list-item-avatar>
									<user-avatar :user-id="u.id" :show-card="false"/>
								</v-list-item-avatar>
								<v-list-item-content>
									<v-list-item-title>{{u.blueTag}}</v-list-item-title>
									<v-list-item-subtitle>{{u.fullName}}</v-list-item-subtitle>
								</v-list-item-content>
							</v-list-item>
						</v-list>
					</v-card-text>
				</v-card>
			</v-menu>

			<v-menu bottom offset-y open-on-hover>
				<template #activator="{ on }">
					<div
						v-on="on"
						class="indicator elevation-2 rounded white--text" style="height: 60px"
						:class="{success: !todayIndicators.differenceWorkforce.length, 'indicator-error': !!todayIndicators.differenceWorkforce.length}"
					>
						<v-icon size="25" color="white"> mdi-wrench </v-icon>
						<div class="d-flex align-center">
							<h3>{{ todayIndicators.currentWorkforce }} / {{ todayIndicators.expectedWorkforce }}</h3>
							<span class="ml-1">Workforce</span>
						</div>
					</div>
				</template>
				<v-card max-height="400" class="overflow-y-auto">
					<v-card-text class="pa-0">
						<v-list dense>
							<v-list-item
								v-for="(u, ix) in todayIndicators.differenceWorkforce" :key="u.id"
								:style="{
									borderBottom: ix < todayIndicators.differenceWorkforce.length - 1
										? '1px solid var(--v-lightgray-base)' : ''
								}"
							>
								<v-list-item-avatar>
									<user-avatar :user-id="u.id" :show-card="false"/>
								</v-list-item-avatar>
								<v-list-item-content>
									<v-list-item-title>{{u.blueTag}}</v-list-item-title>
									<v-list-item-subtitle>{{u.fullName}}</v-list-item-subtitle>
									<div class="text-right" style="position: absolute; right: 15px;">
										<v-list-item-title>{{u.difference > 0 ? 'Sub-Assigned' : 'Over-Assigned'}}</v-list-item-title>
										<v-list-item-subtitle>
											{{Math.abs(u.difference)}}
										</v-list-item-subtitle>
									</div>
								</v-list-item-content>
							</v-list-item>
						</v-list>
					</v-card-text>
				</v-card>
			</v-menu>

			<div class="indicator high-back elevation-2 rounded" style="height: 60px">
				<v-icon
					:style="{background: $vuetify.theme.isDark ? '' : '#e8f2fb'}"
					color="#167bd7"
				> mdi-clipboard-account </v-icon>
				<div class="d-flex align-center">
					<h3>{{ todayIndicators.activeNuclei }}</h3>
					<span class="ml-2" style="margin-top: 2px">Active Nuclei</span>
				</div>
			</div>
		</div>
		<v-menu
			v-model="billableTooltip.show"
			:position-x="billableTooltip.x" :position-y="billableTooltip.y"
			transition="scale-transition"
		>
			<v-card>
				<v-card-text class="px-3 py-2">
					<div class="caption"><b>Billable:</b></div>
					<span class="body-2">{{ billableTooltip.text }}</span>
				</v-card-text>
			</v-card>
		</v-menu>

		<filter-search-input v-model.lazy="filters.search" :applied-filters="appliedFilters" outlined dense>
			<template #append>
				<v-btn-toggle v-model="dateUnitVal" @change="setDateUnit" tile group color="primary">
					<v-btn small :value="0"> Days </v-btn>
					<v-btn small :value="1"> Weeks </v-btn>
					<v-btn small :value="2"> Months </v-btn>
				</v-btn-toggle>
			</template>

			<q-date-picker
				v-model="filters.startDate"
				label="Active from"
				inner outlined dense hide-details
				@change="handleDateChange"
			/>

			<q-date-picker
				v-model="filters.endDate"
				label="Active to"
				inner outlined dense hide-details
				:min="filters.startDate" @change="handleDateChange"
			/>

			<v-autocomplete label="Bluetag" dense outlined hide-details clearable multiple
				v-model="filters.user" :items="shownUsers" item-text="blueTag" item-value="id"
				@change="setDates">
				<template v-slot:item="{ item }">
					<div class="opt" :style="{ color: item.filtered ? '' : 'grey' }">
						<v-icon v-if="filters.user.includes(item.id)"
							color="primary">mdi-checkbox-marked</v-icon>
						<v-icon v-else>mdi-checkbox-blank-outline</v-icon>
						{{ item.blueTag }}
					</div>
				</template>
			</v-autocomplete>

			<v-autocomplete label="Project" outlined dense hide-details single-line clearable multiple
				v-model="filters.customerProject" :items="shownProjects" item-text="name" item-value="id"
				@change="setDates">
				<template v-slot:item="{ item }">
					<div class="opt" :style="{ color: item.filtered ? '' : 'grey' }">
						<v-icon v-if="filters.customerProject.includes(item.id)"
							color="primary">mdi-checkbox-marked</v-icon>
						<v-icon v-else>mdi-checkbox-blank-outline</v-icon>
						{{ item.name }}
					</div>
				</template>
			</v-autocomplete>

			<v-autocomplete
				v-model="filters.customer"
				label="Customer"
				outlined dense hide-details single-line clearable multiple
				:items="shownCustomers" item-text="name" item-value="id"
				@change="setDates"
			>
				<template v-slot:item="{ item }">
					<div class="opt" :style="{ color: item.filtered ? '' : 'grey' }">
						<v-icon v-if="filters.customer.includes(item.id)"
							color="primary">mdi-checkbox-marked</v-icon>
						<v-icon v-else>mdi-checkbox-blank-outline</v-icon>
						{{ item.name }}
					</div>
				</template>
			</v-autocomplete>

			<v-autocomplete label="Billable" outlined dense hide-details single-line clearable multiple
				v-model="filters.billable" :items="shownBillables" item-text="code" item-value="id"
				@change="setDates">
				<template v-slot:item="{ item }">
					<div class="opt" :style="{ color: item.filtered ? '' : 'grey' }">
						<v-icon v-if="filters.billable.includes(item.id)"
							color="primary">mdi-checkbox-marked</v-icon>
						<v-icon v-else>mdi-checkbox-blank-outline</v-icon>
						{{ item.code }}
					</div>
				</template>
			</v-autocomplete>

			<v-autocomplete
				v-model="filters.projectType" :items="shownProjectTypes" item-text="text" item-value="value"
				label="Project Type" outlined dense hide-details single-line clearable multiple
				class="inline-block"
				@change="setDates"
			>
				<template v-slot:item="{ item }">
					<div class="opt" :style="{ color: item.filtered ? '' : 'grey' }">
						<v-icon v-if="filters.projectType.includes(item.value)"
							color="primary">mdi-checkbox-marked</v-icon>
						<v-icon v-else>mdi-checkbox-blank-outline</v-icon>
						{{ item.text }}
					</div>
				</template>
			</v-autocomplete>

			<v-autocomplete
				v-model="filters.role" :items="roles"
				label="Role" outlined dense hide-details single-line clearable multiple
			/>

		</filter-search-input>

		<div class="chart bordered rounded mt-3" id="chart" :style="{ maxHeight: `calc(100vh - ${getTopPx()}px)` }">
			<div style="width: max-content">

				<div class="line header">
					<div class="sticky">
						<div class="st head caption" style="color: var(--v-text-lighten2)">
							<div style="width: 70px"><b>BlueTag</b></div>
							<div style="width: 60px" class="text-center" title="Quantity"><b>Quantity</b></div>
							<div style="width: 120px" class="mr-2"><b>Role</b></div>
							<div style="width: 150px; margin-right: 0"><b>Project</b></div>
						</div>
					</div>
					<div v-for="(date, ix) in dates" :key="ix" class="date new-back" :class="{ 'no-border': !ix }">
						<b class="head">{{ date.children.length > 3 ? date.title : date.title.substr(0, 3) }}</b>
						<div class="children">
							<div v-for="(child, jx) in date.children" :key="jx" :id="child.isToday ? 'today' : null"
								:class="{
									primary: child.isToday,
									'error-date': isErrorDate(child),
									'top-date': true,
								}">
								{{ child.text }}
							</div>
						</div>
					</div>
				</div>

				<div v-if="!data.length">
					No data available
				</div>

				<div v-for="customerType in shownData"
					:key="customerType.type">
					<div class="line">
						<div class="sticky type-header">
							<div @click="toggleProjectType(customerType.type)"
								class="st cursor-pointer body-2 rounded-lg"
								:class="$vuetify.theme.isDark ? 'new-back lighten2 bordered' : 'lightgray--lighten'">
								<b>{{ customerType.typeName }}</b>
								<v-spacer />
								<span class="caption poolcamp-projects">
									<b>WorkForce: </b>{{ customerType.workForce }}
									<b class="ml-4">People: </b>{{ customerType.people }}
								</span>
								<v-icon
									:class="{ 'rotate-180': isExpanded(customerType.type) }">mdi-chevron-down</v-icon>
							</div>
						</div>
					</div>
					<div v-for="(item, jx) in customerType.items" :key="jx"
						:set="user = (users.find(u => u.id == customerType.userId) || {})" class="line new-back"
						v-show="isExpanded(customerType.type)">
						<div class="sticky">
							<div class="st">
								<div style="width: 70px;" :title="item.userBlueTag || item.userFullName">
									<v-icon color="error"
										v-if="user.errors && user.errors.length">mdi-alert-circle</v-icon>
									<v-tooltip top style="z-index: 124; background: black;">
										<template v-slot:activator="{ on }">
											<b v-on="on">{{ item.userBlueTag }}</b>
										</template>
										<span>{{ item.userFullName }}</span>
									</v-tooltip>
								</div>
								<div
									style="width: 60px; text-align: center; overflow: hidden; text-overflow: ellipsis;">
									{{ item.quantity }}
								</div>
								<div style="width: 120px" class="mr-2">
									<v-chip
										:style="{ color: isBlackText(item.billableLineRole || 'white') ? 'white' : 'black' }"
										small dark
										:color="assignmentsRoles[item.billableLineRole] ? assignmentsRoles[item.billableLineRole] : 'black'">
										{{ setMaxText(item.billableLineRole, 16) }}
									</v-chip>
								</div>
								<div style="width: 150px" class="project-name mr-0"
									:title="item.customerProjectName">
									{{ item.customerProjectName }}
								</div>
							</div>
						</div>
						<div class="ranges-cont" v-for="assignment in item.assignments" :key="assignment.id">
							<div @mouseenter="showBillableTooltip($event, assignment)"
								@mouseleave="billableTooltip.show = false"
								class="date-range"
								:class="{ 'no-billable': !assignment.isBillable, 'recess-request': !!assignment.recessRequestId }"
								:style="{
									width: getDateRangeWidth(assignment),
									left: getDateRangeLeftPosition(assignment),
									backgroundColor: colorByProject[assignment.customerProjectId],
								}"
							>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import moment from "moment"
import { workItems } from '../../../json/colors.json'
import billableJson from '../../../json/billable.json'

export default {
	data: () => ({
		filters: {
			startDate: '',
			endDate: '',
			user: [],
			customerProject: [],
			customer: [],
			billable: [],
			projectType: [],
			role: [],
		},
		colorsForRoles: [],
		users: [],
		allUsers: [],
		customers: [],
		billables: [],
		assignments: [],
		projectTypes: [],
		customerProjects: [],

		todayIndicators: {
			differenceUsers: [],
			differenceWorkforce: [],
		},

		data: [],
		dataItems: [],
		dates: [],
		errorDates: [],
		colors: [
			'#00B300', '#FF0000', '#BF0069', '#FFCC00', '#82D900',
			'#FF8C00', '#800080', '#6B00B3', '#0000FF', '#009090',
			'#FF008C', '#0099FF', '#196D19', '#02A4ED', '#E94B01',
			'#003980', '#039D93', '#2E1C88', '#B50206', '#7139E5',
		],
		colorByProject: {},
		dateUnit: 'day',
		dateUnitVal: 0,

		errorAlert: true,
		okAlert: true,

		billableTooltip: { show: false },
	}),
	mounted() {
		this.colorsForRoles = _.cloneDeep(workItems)
		this.projectTypes = _.cloneDeep(billableJson.projectTypes)
		this.setUserPreferences('AdminBoard', 'filters')
		this.filters.startDate = moment().format('YYYY-MM-DD')
		this.filters.endDate = moment().format('YYYY-MM-DD')
		this.init()
	},
	computed: {
		shownData() {
			console.time('Data')
			let data = _.cloneDeep(this.data)

			for (let customerType of data) {
				for (let item of customerType.items) {
					item.assignments = item.assignments
						.filter(a => {
							if (this.filters.user && this.filters.user.length && !this.filters.user.includes(a.userId)) 
								return false
							
							if (this.filters.customerProject && this.filters.customerProject.length && !this.filters.customerProject.includes(a.customerProjectId)) 
								return false

							if (this.filters.customer && this.filters.customer.length && !this.filters.customer.includes(a.billableCustomerId)) 
								return false

							if (this.filters.billable && this.filters.billable.length && !this.filters.billable.includes(a.billableId)) 
								return false

							if (this.filters.projectType && this.filters.projectType.length && !this.filters.projectType.includes(a.customerProjectType)) 
								return false

							if (this.filters.role && this.filters.role.length && !this.filters.role.includes(a.billableLineRole || a.otherBillableLine)) 
								return false

							if(
								this.filters.search &&
								!this.normalizeIncludes(a.userBlueTag, this.filters.search) &&
								!this.normalizeIncludes(a.customerProjectName, this.filters.search) &&
								!this.normalizeIncludes(a.billableLineRole, this.filters.search) &&
								!this.normalizeIncludes(a.otherBillableLine, this.filters.search)
							)
								return false

							return true
						})
				}
				customerType.items = customerType.items.filter(i => !!i.assignments.length)
			}
			data = data.filter(d => !!d.items.length)
			console.timeEnd('Data')
			return data
		},
		roles() {
			return this.data
				.map(d =>
					d.items
						.map(i => i.assignments)
						.flat()
				)
				.flat()
				.map(x => x.billableLineRole || x.otherBillableLine)
				.filter((v, i, a) => a.indexOf(v) === i)
		},
		shownAssignments() {
			return this.shownData
				.map(d =>
					d.items
						.map(i => i.assignments)
						.flat()
				)
				.flat()
		},
		shownUsers() {
			let users = this.users.filter(u => this.assignments.some(a => a.userId == u.id))
			users.map(u => {
				u.filtered = this.shownAssignments.some(a => a.userId == u.id)
			})
			users = users.sort((a, b) => {
				if (b.filtered == a.filtered)
					return a.blueTag.localeCompare(b.blueTag)
				return b.filtered - a.filtered
			})
			return users
		},
		shownProjects() {
			let projects = this.customerProjects.filter(cp => this.assignments.some(a => a.customerProjectId == cp.id))
			projects.map(p => {
				p.filtered = this.shownAssignments.some(a => a.customerProjectId == p.id)
			})
			projects = projects.sort((a, b) => {
				if (b.filtered == a.filtered)
					return a.name.localeCompare(b.name)
				return b.filtered - a.filtered
			})
			return projects
		},
		shownCustomers() {
			let customers = this.customers.filter(c => this.assignments.some(a => a.billableCustomerId == c.id))
			customers.map(p => {
				p.filtered = this.shownAssignments.some(a => a.billableCustomerId == p.id)
			})
			customers = customers.sort((a, b) => {
				if (b.filtered == a.filtered)
					return a.name.localeCompare(b.name)
				return b.filtered - a.filtered
			})
			return customers
		},
		shownBillables() {
			let billables = this.billables.filter(b => this.assignments.some(a => a.billableId == b.id))
			billables.map(b => {
				b.filtered = this.shownAssignments.some(a => a.billableId == b.id)
			})
			billables = billables.sort((a, b) => {
				if (b.filtered == a.filtered)
					return a.code.localeCompare(b.code)
				return b.filtered - a.filtered
			})
			return billables
		},
		shownProjectTypes() {
			let types = this.projectTypes.filter(t => this.assignments.some(a => a.customerProjectType == t.value))
			types.map(t => {
				t.filtered = this.shownAssignments.some(a => a.customerProjectType == t.value)
			})
			types = types.sort((a, b) => {
				if (b.filtered == a.filtered)
					return a.text.localeCompare(b.text)
				return b.filtered - a.filtered
			})
			return types
		},
		assignmentsRoles() {
			if (!this.shownData) return {}
			let items = []
			this.shownData.forEach(currentType => {
				currentType.items.forEach(item => items.push(item))
			})
			const roles = items.map(item => item.billableLineRole)
			const colorPerRole = {}
			let colorKey = 0
			roles.forEach(role => {
				if (colorPerRole.hasOwnProperty(role)) return
				colorPerRole[role] = this.colorsForRoles[colorKey++]
				colorKey %= this.colorsForRoles.length
			})
			return colorPerRole
		},
		limitDates() {
			let startDate = null
			let endDate = null
			if (!this.assignments || !this.assignments.length) return {}
			for (let a of this.assignments) {
				if (!startDate || moment(a.startDate) < startDate) {
					startDate = moment(a.startDate)
				}
				if (!endDate || moment(a.endDate) > endDate) {
					endDate = moment(a.endDate)
				}
			}
			return {
				startDate: startDate || moment(this.filters.startDate),
				endDate: endDate || moment(this.filters.endDate)
			}
		},
		appliedFilters() {
			return this.countActiveFilters(this.filters, ['search', 'startDate', 'endDate'])
		},
	},
	methods: {
		init() {
			let assignmentsPr = this.updateGantt()
			
			this.$http.get('api/user?onlyInterns=true&department=devCenter')
				.then(res => {
					this.allUsers = res.data
					this.users = res.data.filter(x => x.active)
				})
			
				
			const customerPr = this.$http.get("/api/customer?onlyWithCustomerProjects=true")
				.then(res => {
					this.customers = res.data
				})
				.catch(res => {
					window.getApp.error("Cannot obtain customers.")
				})
			
			let projectsPr = this.$http.get('api/CustomerProject')
				.then(res => {
					this.customerProjects = res.data
				})

			this.$http.get('api/Billable')
				.then(res => {
					this.billables = res.data
				})
				.catch(res => {
					window.getApp.error("Cannot obtain billables.")
				})
			
			this.$http.get('api/admin/board/indicators')
				.then(res => {
					this.todayIndicators = res.data
				})
				.catch(err => {
					console.error(err)
					this.$root.error('Cannot get indicators')
				})

			Promise.all([assignmentsPr, projectsPr, customerPr])
				.then(() => {
					this.setDateUnit(0)
				})
		},
		updateGantt() {
			return this.$http.post(`api/admin/board`, { ...this.filters })
				.then(res => {
					this.projectTypes
						.forEach((t, ix) => {
							let type = res.data.find(r => r.type == t.value)
							if (type) {
								type.sortIndex = ix
								type.color = t.color
							}
						})
					this.data = res.data
						.sort((a, b) => a.sortIndex < b.sortIndex ? -1 : 1)
					this.dataItems = this.data
						.map(d => d.items)
						.flat()
					this.assignments = this.data
						.map(d =>
							d.items
								.map(i => i.assignments)
								.flat()
						)
						.flat()
					let colorKey = 0
					this.assignments.forEach((a, ix) => {
						a.startDate = moment(a.startDate)
						a.endDate = moment(a.endDate)

						if (!this.colorByProject[a.customerProjectId]) {
							this.colorByProject[a.customerProjectId] = this.colors[colorKey]
							colorKey++
							colorKey %= this.colors.length
						}
					})

				}
				)
				.catch(err => {
					console.log(err)
					this.$root.error('Cannot get assignments')
				})
		},
		
		handleDateChange() {
			if (moment(this.filters.startDate).isAfter(this.filters.endDate))
				this.filters.endDate = this.filters.startDate
			this.updateGantt()
				.then(() => {
					this.setDates()
					this.$nextTick(() => {
						if (this.$el.querySelector('#today'))
							this.$el.querySelector('#chart').scrollLeft = this.$el.querySelector('#today').offsetLeft - 565
					})
				})
		},

		setDateUnit(val) {
			this.dateUnitVal = val
			this.dateUnit = 'day'
			if (val === 1)
				this.dateUnit = 'isoweek'
			else if (val === 2)
				this.dateUnit = 'month'
			this.setDates()
			this.$nextTick(() => {
				if (this.$el.querySelector('#today'))
					this.$el.querySelector('#chart').scrollLeft = this.$el.querySelector('#today').offsetLeft - 565
			})
		},
		setDates() {
			let startDate = this.limitDates.startDate.clone()
			let endDate = this.limitDates.endDate.clone()
			this.dates = []

			let parentUnit = 'MMM YYYY'
			if (this.dateUnitVal == 2)
				parentUnit = 'YYYY'

			while (startDate.isSameOrBefore(endDate, this.dateUnit)) {
				let length = this.dates.length
				if (!length || startDate.format(parentUnit) != this.dates[length - 1].title) {
					this.dates.push({
						title: startDate.format(parentUnit),
						children: [],
					})
					length++
				}
				let text = startDate.format('D')
				if (this.dateUnitVal == 1)
					text = startDate.isoWeek()
				else if (this.dateUnitVal == 2)
					text = startDate.format('MMM')

				let isToday = startDate.isSame(moment(), this.dateUnit)

				this.dates[length - 1].children.push({ text, value: startDate.clone(), isToday })
				startDate = startDate.add(1, this.dateUnit)
			}
		},
		isErrorDate(date) {
			return this.errorDates.some(d => d.isSame(date.value, this.dateUnit))
		},
		isBetween(assignment, date) {
			for (let dates of assignment.dates) {
				let iD = moment(dates.startDate)
				let eD = moment(dates.endDate)
				let d = moment(date)
				if (d.isBetween(iD, eD, this.dateUnit) || d.isSame(iD, this.dateUnit) || d.isSame(eD, this.dateUnit))
					return true
			}
		},
		isSame(dateA, dateB) {
			let da = moment(dateA)
			let db = moment(dateB)
			return da.isSame(db, this.dateUnit)
		},
		getTopPx() {
			return 215
		},
		getDateRangeWidth(assignment) {
			let unit = this.dateUnit
			let divisions = {
				'day': 30,
				'week': 4,
				'month': 12,
			}
			let days = assignment.endDate.clone()
				.startOf(unit)
				.diff(assignment.startDate.clone().startOf(unit), unit, true)
				+ 1

			if (unit == 'isoweek') {
				unit = 'week'
				days = assignment.endDate.clone().startOf('isoweek')
					.diff(assignment.startDate.clone().startOf('isoweek'), unit, true) + 1
			}
			days = Math.ceil(days)
			return (days * (27 + 2 / divisions[unit])) + 'px'
		},
		getDateRangeLeftPosition(assignment) {
			let unit = this.dateUnit
			let divisions = {
				'day': 30,
				'week': 4,
				'month': 12,
			}
			let days = assignment.startDate.clone().diff(this.limitDates.startDate, unit)
			if (unit == 'isoweek') {
				unit = 'week'
				days = assignment.startDate.clone().startOf('isoweek')
					.diff(this.limitDates.startDate.clone().startOf('isoweek'), unit)
			}
			return (days * (27 + 2 / divisions[unit])) + 'px'
		},
		
		showBillableTooltip(e, assignment) {
			if (this.billableTooltip.show) return
			this.billableTooltip.text = assignment.billableCode || 'No billable'
			this.billableTooltip.x = e.clientX
			this.billableTooltip.y = e.clientY
			this.billableTooltip.show = true
		},

		isExpanded(value) {
			let expanded = this.projectTypes.find(x => x.value == value).expanded
			return expanded || expanded === undefined
		},
		toggleProjectType(value) {
			let type = this.projectTypes.find(x => x.value == value)
			this.$set(type, 'expanded', type.expanded === undefined ? false : !type.expanded)
		},
	},
}
</script>

<style lang="scss" scoped>
#today {
	color: white;
}

.indicators {
	position: absolute;
	right: 0;
	top: -80px;
	z-index: 101;
}

.indicator {
	display: flex;
	gap: 10px;
	padding: 5px 10px;
	border-radius: 8px;
	align-items: center;
	height: 80px;
	border-radius: 50%;

	.v-icon {
		width: 30px;
		height: 30px;
		border-radius: 50%;
	}

	&.indicator-error {
		background: var(--v-error-lighten) !important;
		color: white;

		.v-icon {
			color: white !important;
			background: none !important;
		}
	}

	h2{
		line-height: 20px;
	}
}

::v-deep .panels {
	h2 { flex: none; }
	.v-expansion-panel-header__icon { display: none; }
	.v-expansion-panel-content__wrap { padding: 0; }
	.v-expansion-panel-header:not(.v-expansion-panel-header--mousedown):focus::before { opacity: 0; }
}

.chart::-webkit-scrollbar {
	width: 10px;
	height: 10px;
	background: var(--v-newDesignBackground-base)
}

.chart::-webkit-scrollbar-thumb {
	background: var(--v-background-lighten2)
}

.chart {
	width: calc(100vw - 200px);
	overflow-x: scroll;
	margin-top: 5px;
	font-size: .8em;

	.header {
		position: sticky;
		top: 0;
		z-index: 2;
	}

	.line {
		display: flex;
		width: max-content;
		width: 100%;

		.sticky {
			display: flex;
			position: sticky;
			align-items: flex-end;
			background: var(--v-newDesignBackground-base);
			left: 0;
			z-index: 1;
			min-height: 14px;
			border-right: 2px solid var(--v-lightgray-lighten);
			width: 428px;

			&.type-header {
				padding: 10px 5px;
				user-select: none;

				.st {
					gap: 10px;
					width: 100%;
					padding: 5px 5px 5px 10px;
				}
			}

			.st {
				display: flex;
				align-items: center;
				padding: 2px 5px 2px 10px;

				div {
					white-space: nowrap;
					overflow: hidden;
					text-overflow: ellipsis;
				}
			}

			.head {
				border-bottom: 2px solid var(--v-lightgray-lighten);
			}
		}

		.date {
			padding: 5px 0;
			border: 1px solid var(--v-lightgray-lighten);
			border-bottom-width: 2px;
			border-top: 0;

			&.no-border {
				border-left: none !important;
			}

			.head {
				position: sticky;
				left: 428px;
				font-size: 1.2em;
				padding: 0 8px;
			}

			.children {
				display: flex;

				div {
					text-align: center;
					padding: 0 8px;
					width: 27px;
					height: 27px;
					display: inline-flex;
					align-items: center;
					justify-content: center;
					border-radius: 50%;
				}

				.error-date {
					background: var(--v-error-base);
					color: white;
				}
			}
		}
	}

	.no-billable {
		&.recess-request {
			background-color: var(--v-lightgray-lighten) !important;
		}
		&:not(.recess-request) {
			filter: brightness(1.1);
		}
		background-image: url('../../../assets/textures/diagonals.png');
		background-repeat: repeat;
	}
}

.ranges-cont {
	position: relative;
	display: flex;
	align-items: center;

	.date-range {
		display: inline-block;
		position: absolute;
		height: 16px;
		border-radius: 20px;
	}
}
</style>