<template>
	<div class="new-back full-height py-5 px-5">
    <div class="d-inline-flex align-center gap-10">
      <v-text-field
        v-model="filters.search"
        prepend-inner-icon="search"
        label="Search by bluetag, description, etc"
        class="d-inline-block"
        single-line outlined dense hide-details clearable
      >
        <template v-slot:append>
          <v-badge
            offset-x="12" offset-y="13"
            color="red" :value="!!appliedFilters" :content="appliedFilters"
            right small
          >
            <v-icon
              @mouseup.stop
              @click.stop="showFilters = !showFilters" class="mr-2"
              :color="showFilters ? 'primary' : $vuetify.theme.isDark ? 'white' : '' "
            > mdi-tune </v-icon>
          </v-badge>
        </template>
      </v-text-field>
      <div class="d-inline-flex align-center gap-2">
        <label>Billable Status:</label>
        <v-btn
          v-for="(color, ix) in billableColors" :key="ix"
          @click="applyIsBillable(color)"
          :color="color.color" icon small
          :style="($vuetify.theme.isDark ? 'border: 2px solid ' : 'background: ') + color.lightColor"
          :disabled="!selectedEfforts.length"
        >
          <v-icon>mdi-currency-usd</v-icon>
        </v-btn>
  
        <div class="d-flex align-center ml-10" style="gap: 10px">
          <label>Service:</label>
          <v-menu>
            <template #activator="{ on }">
              <v-btn
                v-on="on" color="secondary"
                small :disabled="!selectedEfforts.length"
              >
                Select service
              </v-btn>
            </template>
            <v-list>
              <v-list-item
                v-for="line in billable.lines" :key="line.id"
                @click="applyRole(line.id)"
              >
                {{ line.role }}
              </v-list-item>
            </v-list>
          </v-menu>
        </div>
  
        <div class="d-flex align-center ml-10 hide-input" style="gap: 10px; min-width: 300px">
          <label>Billable:</label>
          <v-menu>
            <template #activator="{ on }">
              <v-btn
                v-on="on" color="secondary"
                small :disabled="!selectedEfforts.length"
              >
                Select billable
              </v-btn>
            </template>
            <v-list>
              <v-list-item
                v-for="billable in [{ id: null, code: 'NA' }, { id: billable.id, code: billable.code }]" :key="billable.id"
                @click="applyBillable(billable.id)"
              >
                {{ billable.code }}
              </v-list-item>
            </v-list>
          </v-menu>
        </div>
      </div>
      <div>
      </div>
    </div>
    <v-expand-transition>
      <div v-show="showFilters" class="mt-5">
        <div class="d-flex" style="gap: 10px">
          <q-date-picker
            v-model="filters.startDate"
            label="Start date"
            outlined inner hide-details dense clearable
          />
          <q-date-picker
            v-model="filters.endDate"
            label="End date"
            outlined inner hide-details dense clearable
          />
          <q-filter-input
            v-model="filters.reporterId" :items="shownUsers"
            parent-shown-items="shownEfforts" label="Bluetag"
            item-text="blueTag" item-value="id"
            dense outlined hide-details clearable multiple
            :data-comparer="(item, value) => item.reporterId == value"
          />
          <q-filter-input
            v-model="filters.isBillable" :items="isBillableOpts"
            parent-shown-items="shownEfforts" label="Type"
            dense outlined hide-details clearable
            :data-comparer="(item, value) => this.isBillableOpts[value].isBillable == item.isBillable"
          >
            <template #item="{ item }">
              <v-btn
                :color="item.color" icon small class="mr-2"
                :style="($vuetify.theme.isDark ? 'border: 2px solid ' : 'background: ') + item.lightColor"
              >
                <v-icon>mdi-currency-usd</v-icon>
              </v-btn>
              {{ item.text }}
            </template>
            <template #selection="{ item }">
              <v-btn
                :color="item.color" icon small class="mr-2"
                :style="($vuetify.theme.isDark ? 'border: 2px solid ' : 'background: ') + item.lightColor"
              >
                <v-icon>mdi-currency-usd</v-icon>
              </v-btn>
              {{ item.text }}
            </template>
          </q-filter-input>
          <v-text-field
            v-model="filters.executedHours"
            @keypress="hourValidation"
            label="Executed hours"
            dense outlined hide-details clearable
          />
          <v-checkbox
            v-model="filters.includeEffortsWithoutBillable"
            label="Include efforts without billable"
            dense hide-details
          />
        </div>
      </div>
    </v-expand-transition>

    <v-data-table
      v-model="selectedEfforts" show-select
		  checkbox-color="primary"
      class="bordered rounded-lg new-back lighten2 mt-5"
      :headers="headers" :items="shownEfforts"
    >
      <template #[`item.isBillable`]="{ item:effort }">
        <v-btn
          @click="setNextIsBillable(effort)"
          :color="billableColors[effort.isBillable].color" icon small
          :style="($vuetify.theme.isDark ? 'border: 2px solid ' : 'background: ') + billableColors[effort.isBillable].lightColor"
        >
          <v-icon>mdi-currency-usd</v-icon>
        </v-btn>
      </template>
      <template #[`item.reporter`]="{ item:effort }">
        {{ effort.reporterBlueTag || effort.reporterFullName }}
      </template>
      <template #[`item.billableLineId`]="{ item:effort }">
        <v-select
          v-model="effort.billableLineId" :items="billable.lines"
          @change="billableLineChanged(effort, $event)"
          outlined hide-details dense clearable :disabled="!!effort.billableItemId"
          item-value="id" item-text="role"
        />
      </template>
      <template #[`item.billable`]="{ item:effort }">
        <v-select
          v-model="effort.billableId"
          @change="billableChanged(effort, $event)"
          :items="[{ id: null, code: 'NA' }, { id: billable.id, code: billable.code }]"
          outlined hide-details dense :disabled="!!effort.billableItemId"
          item-value="id" item-text="code"
        />
      </template>
      <template #[`item.date`]="{ item:effort }">
        {{ effort.date | formatDateCustom('(ddd) MMM D, YYYY') }}
      </template>
      <template #[`item.executed`]="{ item:effort }">
        {{ effort.executedHrs }} : {{ effort.executedMins }}
      </template>
      <template #[`item.billed`]="{ item:effort }">
        <v-icon v-if="effort.billableItemId" color="success" class="ml-n3">mdi-check</v-icon>
        <v-icon v-else color="grey" class="ml-n3">mdi-close</v-icon>
      </template>
    </v-data-table>
		<v-btn @click="save" color="primary" class="float-right mt-5">Accept</v-btn>
	</div>
</template>

<script>
import moment from 'moment'
export default {
	props: ['id'],
	data: () => ({
		billable: {},
    efforts: [],
		users: [],
    billableColors: {
      true:      { color: 'success', lightColor: '#bce3c9', text: 'Billable', value: 0, isBillable: true },
      false:     { color: 'error',   lightColor: '#f5c4c5', text: 'Non-Billable', value: 1, isBillable: false },
      undefined: { color: 'gray',    lightColor: '#e4e4e4', text: 'Pending', value: 2, isBillable: undefined },
    },

    showFilters: false,
    filters: {
      search: '',
      startDate: '',
      endDate: '',
      reporterId: [],
      isBillable: null,
      executedHours: '',
      includeEffortsWithoutBillable: true,
    },

    selectedEfforts: [],
    headers: [
      { text: 'Is Billable', value: 'isBillable', align: 'center', sortable: false },
      { text: 'Team member', value: 'reporter', align: 'center', sortable: false  },
      { text: 'Service',        value: 'billableLineId', width: 300 },
      { text: 'Description', value: 'description' },
      { text: 'Billable',    value: 'billable', width: 250 },
      { text: 'Date',        value: 'date'        },
      { text: 'Executed',    value: 'executed',   align: 'center', sortable: false },
      { text: 'Billed',      value: 'billed',   align: 'center' },
    ],

    warnOnLeave: false,
	}),
	created() {
    this.setUserPreferences('BillableExecutedHours', 'filters')
    this.init()
	},
  beforeRouteLeave (to, from, next) {
    if (!this.warnOnLeave) return next()
    if(to.path == '/login')
      next()
    else{
      const answer = window.confirm('Do you really want to leave? You have unsaved changes!')
      if (answer) {
        next()
      } else {
        next(false)
      }
    }
  },
	computed: {
		shownEfforts(){
			let efforts = this.efforts
      if(this.filters.startDate){
        efforts = efforts.filter(x => moment(this.filters.startDate).isSameOrBefore(x.date, 'day'))
      }
      if(this.filters.endDate){
        efforts = efforts.filter(x => moment(this.filters.endDate).isSameOrAfter(x.date, 'day'))
      }
      if(this.filters.reporterId.length){
        efforts = efforts.filter(x => this.filters.reporterId.includes(x.reporterId))
      }
      if(this.filters.isBillable !== null){
        efforts = efforts.filter(x => this.isBillableOpts[this.filters.isBillable].isBillable == x.isBillable)
      }
      if(this.filters.executedHours){
        const splitted = this.filters.executedHours.split(':')
        let executed = 0
        let executedHrs = '', executedMins = ''
        if(splitted.length){
          executedHrs = splitted[0]
          executed += parseFloat(executedHrs)
        }
        if(splitted.length > 1 && splitted[1]){
          executedMins = splitted[1]
          executed += parseFloat(executedMins) / 60
        }
        executed = Math.round(executed*1000)/1000
        efforts = efforts.filter(x =>
          (executedHrs == x.executedHrs && !executedMins) ||
          (executedMins == x.executedMins && !executedHrs) ||
          executed == x.executed
        )
      }
      if(!this.filters.includeEffortsWithoutBillable){
        efforts = efforts.filter(x => x.billableId)
      }

      efforts = this.applySearch(this.filters.search, efforts)

			return efforts
		},
    shownUsers(){
      const reporterIds = this.efforts.map(e => e.reporterId)
      return this.users
        .filter(u => reporterIds.includes(u.id))
    },
    appliedFilters(){
      let ans = 0
      for(const prop in this.filters)
        if(
          prop != 'search' && this.filters[prop] &&
          (!Array.isArray(this.filters[prop]) || this.filters[prop].length)
        )
          ans++
      return ans
    },
    isBillableOpts(){
      return _.toArray(this.billableColors)
    },
	},
	methods: {
    init() {
      let efforts = []
			let billablePr = this.$http.get(`api/Billable/${this.id}`)
				.then(res => {
					this.billable = res.data
          efforts = efforts.concat(this.billable.efforts
            .map(x => ({...x, isSelectable: !x.billableItemId}))
            .sort((a, b) => {
              if(a.isBillable == b.isBillable)
                return moment(a.date).isBefore(b.date, 'day') ? -1 : 1
              return a.isBillable === undefined ? -1 : 1
            }))
				})
        .catch(err => {
          this.$root.error('Cannot get billable')
          console.error(err)
        })
			let usersPr = this.$http.get('api/user?active=true&onlyIntern=true')
				.then(res => {
					this.users = res.data.filter(x => x.blueTag)
				})
        .catch(err => {
          this.$root.error('Cannot get users')
          console.error(err)
        })
      let effortPr = this.$http.get(`api/billable/${this.id}/notLinkedEfforts`)
        .then(res => {
          efforts = efforts.concat(res.data
            .map(x => ({...x, billableId: null, isSelectable: !x.billableItemId}))
            .sort((a, b) => {
              if(a.isBillable == b.isBillable)
                return moment(a.date).isBefore(b.date, 'day') ? -1 : 1
              return a.isBillable === undefined ? -1 : 1
            }))
        })
        .catch(err => {
          this.$root.error('Cannot get efforts')
          console.error(err)
        })
        
      Promise.all([billablePr, usersPr, effortPr])
				.then(() => {
          this.efforts = efforts
          this.efforts.forEach(e => {
            this.updateExecutedMins(e)
					})
				})
		},
		save(){
			this.$q.save({
				api: 'api/Efforts',
				data: this.efforts,
        successMsg: 'Saved Succesfully',
        errorMsg: 'Cannot save changes.',
			})
        .then(() => {
          this.warnOnLeave = false
          this.$router.go(-1)
        })
        .catch(err => {
          console.error(err)
          this.$root.error('Cannot save changes.')
        })
		},

    setNextIsBillable(effort){
      if(effort.billableItemId){
        this.$root.error('This effort is already linked to a billable item')
        return
      }
      const nextIsBillables = {
        true: false,
        false: undefined,
        undefined: true
      }
      this.$set(effort, 'isBillable', nextIsBillables[effort.isBillable])
      this.warnOnLeave = true
    },
    billableLineChanged(effort, newValue) {
      if(newValue){
        this.$set(effort, 'billableId', this.billable.id)
      }
      this.warnOnLeave = true
    },
    billableChanged(effort, newValue) {
      if(!newValue){
        this.$set(effort, 'billableLineId', null)
      }
      this.warnOnLeave = true
    },

    applyIsBillable(val){
      this.selectedEfforts
        .filter(x => !x.billableItemId)
        .forEach(e => this.$set(e, 'isBillable', val.isBillable))
      this.warnOnLeave = true
    },
    applyRole(billableLine){
      this.selectedEfforts
        .filter(x => !x.billableItemId)
        .forEach(e => {
          this.$set(e, 'billableLineId', billableLine)
          
          if(billableLine) {
            this.$set(e, 'billableId', this.billable.id)
          }
        })
      this.warnOnLeave = true
    },
    applyBillable(billableId) {
      this.selectedEfforts
        .filter(x => !x.billableItemId)
        .forEach(e => {
          this.$set(e, 'billableId', billableId)

          if(!billableId) {
            this.$set(e, 'billableLineId', null)
          }
        })
      this.warnOnLeave = true
    },

    updateExecutedMins(effort) {
      this.$set(effort, 'executedHrs', Math.floor(+effort.executed))
      this.$set(effort, 'executedMins', Math.round((+effort.executed % 1) * 60))
      // "00" format
      if (effort.executedMins < 10) {
        effort.executedMins = "0" + effort.executedMins
      }
    },
    hourValidation(e){
      const keyCode = (e.keyCode ? e.keyCode : e.which)
      if ((keyCode < 48 || keyCode > 57) && e.key !== '.' && e.key !== ':') { // 46 is dot
        e.preventDefault()
      }
    },
	},
}
</script>

<style lang="scss" scoped>
::v-deep .item-billed{
	&.v-data-table__selected{
		background: none !important;
	}
	.v-data-table__checkbox{
		display: none;
	}
}
</style>