<template>
  <div class="q-autocomplete" v-if="!multiple">

          
    <v-autocomplete
      ref="vAutocomplete" class="autocomplete"
      :disabled="disabled" :items="items" :item-value="itemValue" :item-text="itemText" v-model="innerValue"
      dense :single-line="singleLine" :solo="solo" :outlined="outlined" :flat="!editting && flat" :readonly="readonly"
      :filter="(item, queryText, itemText) => normalizeIncludes(item.blueTag + ' ' + item.fullName, queryText)"
      :label="label" :error="error"
      @blur="$emit('blur'); save($event); $emit('change', $event)"
      @focus="editting = true"
      @focusin="focus"
      @change="saveOnChange ? save($event) : v => $emit('change', v)"
      :hide-details="hideDetails"
      @keyup.esc="cancel"
      :autofocus="autofocus"
      v-bind="$attrs"
    >

      <template v-slot:item="{ item }">
        <v-list-item-avatar color="indigo" class="headline font-weight-light white--text">
          <span v-if="item && item[itemText] && !item.profilePictureId">
            {{ item[itemText].charAt(0) }}
          </span>
          <v-img v-if="item.profilePictureId" :src="item.profilePicSrc" @error="item.profilePicError = true"></v-img>
        </v-list-item-avatar>
        <v-list-item-content>
          <v-list-item-title v-if="item" v-text="item[itemText]"></v-list-item-title>
          <v-list-item-subtitle v-if="item" v-text="item[itemSubtitle]"></v-list-item-subtitle>
        </v-list-item-content>
      </template>
      <template v-slot:selection="{ item }">

        <v-list-item class="pa-0">

          <v-menu :open-on-hover="true" :offset-y="true" v-model="menu" :nudge-width="500">
            <template v-slot:activator="{ on }">
              <v-list-item-avatar v-on="on" color="indigo" class="headline font-weight-light white--text" :size="dense ? 32 : 40">
                <span v-if="item && item[itemText] && !item.profilePictureId" :style="{fontSize: dense ? '.8em' : '1em'}">
                  {{ item[itemText].charAt(0) }}
                </span>
                <v-img v-if="item.profilePictureId" :src="item.profilePicSrc" @error="item.profilePicError = true" :size="12"></v-img>
              </v-list-item-avatar>     
            </template>

            <profile-card v-if="openUserCard && selectedItem && selectedItem.id && !editting" :small="true" :userId="selectedItem.id"></profile-card>
          </v-menu>

          <v-list-item-content>
            <v-list-item-title v-if="item" v-text="item[itemText] || item[itemSubtitle]"></v-list-item-title>
            <v-list-item-subtitle v-if="item && !dense" v-text="item[itemText] ? item[itemSubtitle] : ''"></v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>

      </template>
      <template #append v-if="readonly"><div></div></template>
      <template v-slot:append v-else-if="confirmable">
        <v-btn fab x-small v-if="editting && !handleSave" @click="save" color="primary">
          <v-icon>done</v-icon>
        </v-btn>
        <v-btn icon v-if="editting && !handleEsc" @click="cancel">
          <v-icon>close</v-icon>
        </v-btn>
      </template>
      <template #append v-else-if="hideAppend">
        <div></div>
      </template>
    </v-autocomplete>


  </div>
  <div v-else>
    <v-autocomplete
      ref="vAutocomplete" 
      clearable dense multiple hide-details :solo="solo" :outlined="outlined" :flat="!editting && flat"
      :items="items" :item-value="itemValue" :item-text="itemText" v-model="innerValue" :label="label"
      :filter="(item, queryText, itemText) => normalizeIncludes(item.blueTag + ' ' + item.fullName, queryText)"
      @change="save($event)" @focus="editting = true" @focusin="focus" @keyup.esc="cancel"
    >
      <template v-slot:item="{ item }">
        <v-list-item-avatar color="indigo" class="headline font-weight-light white--text">
          <span v-if="item && !item.profilePictureId">
            {{ (item.blueTag || item.fullName).charAt(0) }}
          </span>
          <v-img v-if="item.profilePictureId" :src="item.profilePicSrc" @error="item.profilePicError = true"></v-img>
        </v-list-item-avatar>
        <v-list-item-content>
          <v-list-item-title v-if="item" v-text="item.blueTag"></v-list-item-title>
          <v-list-item-subtitle v-if="item" v-text="item.fullName"></v-list-item-subtitle>
        </v-list-item-content>
      </template>

      <template v-slot:selection="{ attrs, item, parent, selected, index }">
        <v-chip color="accent" class="mt-1" v-if="!showNumberOfSelection">
          <v-icon small left @click="removeUser(item[itemValue])">clear</v-icon> 
          {{item.blueTag || item.fullName}}
        </v-chip>
        <span v-else>
          <v-chip v-if="item === Object(item) && index === 0"
            v-bind="attrs" class="accent--lighten" :input-value="selected" label small
          >
            <span class="pr-2">
              {{ item.blueTag }}
            </span>
            <v-icon small @click.stop="parent.selectItem(item)">close</v-icon>
          </v-chip>
          <span
            v-if="index === 1"
            class="grey--text text-caption mt-1 ml-1"
          >
            (+{{ innerValue.length - 1 }})
          </span>
        </span>
      </template>
    </v-autocomplete>
  </div>
</template>

<script>
import moment from 'moment'
import _ from "lodash"
import ProfileCard from "./panels/ProfileCard"

export default {
  props: {
    handleEsc: { type: Boolean, default: false },
    handleSave: { type: Boolean, default: false },
    value: null,
    itemValue: { type: String, default: 'id'},
    itemText: { type: String, default: 'blueTag'},
    itemSubtitle: { type: String, default: 'fullName'},
    items: Array,
    disabled: {type: Boolean, default: false},
    confirmable: {type: Boolean, default: true},
    readonly: {type: Boolean, default: false},
    openUserCard: {type: Boolean, default: true},
    autofocus: {type: Boolean, default: false},
    multiple: {type: Boolean, default: false},
    outlined: {type: Boolean, default: false},
    flat: {type: Boolean, default: false},
    solo: {type: Boolean, default: false},
    dense: {type: Boolean, default: false},
    singleLine: {type: Boolean, default: true},
    label: {type: String, default: ''},
    error: null,
    hideAppend: {type: Boolean, default: false},
    hideDetails: {type: Boolean, default: true},
    saveOnChange: {type: Boolean, default: false},
    showNumberOfSelection: {type: Boolean, default: false},
  },
  components: {
    ProfileCard,
  },
  data: () => ({
    innerValue: null,
    editting: false,
    menu: false,
    fav: false,

    profilePicError: false,
    profilePicFailover: 'https://cdn.vuetifyjs.com/images/parallax/material2.jpg',
  }),
  watch: {
    value: function (val) {
      this.innerValue = val;
    },
    items(){
      this.items.forEach(this.$q.profilePicSrc)
    },
  },
  computed: {
    display() {
      if (!this.items) return ''
      let item = this.items.find(i => i[this.itemValue] == this.innerValue)
      if (!item) return ''
      return item[this.itemText]
    },
    selectedItem() {
      let item = this.items.find(i => i[this.itemValue] == this.innerValue)
      return item
    },
  },
  mounted(){
    if(this.autofocus){
      this.$refs.vAutocomplete.activateMenu()
    }
  },
  methods: {
    onChange(e){
      if(!this.confirmable)
        this.save(e)
      this.$emit('change', e)
    },
    focus() {
      this.$emit('focus')
    },
    save(e) {
      let id = null
      if(e?.relatedTarget?.id) {
        id = e.relatedTarget.id
      }
      
      this.$emit('save', this.innerValue, id)
      this.$emit('input', this.innerValue)
      this.editting = false
      this.$refs.vAutocomplete.blur()
    },
    cancel() {
      this.innerValue = this.value
      this.editting = false
      this.$refs.vAutocomplete.blur()
    },
    removeUser(val) {
      this.innerValue = this.innerValue.filter(u => u != val)
      this.$emit('input', this.innerValue)
    },
    blur(){
      document.activeElement.blur()
    },
  },
  created() {
    this.items.forEach(this.$q.profilePicSrc)
    this.innerValue = this.value
  },
}
</script>

<style scoped lang="scss">
  .autocomplete ::v-deep .v-input__slot {
    background: transparent !important;
  }
  .autocomplete ::v-deep .v-input__slot .v-select__slot {
    border-radius: 3px;
  }
  .autocomplete.v-input--is-focused ::v-deep .v-input__slot .v-select__slot {
    background: initial;
  }
</style>
