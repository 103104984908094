<template>
    <v-dialog :fullscreen="$vuetify.breakpoint.xsOnly" scrollable persistent 
        v-model="dialog" :max-width="550" v-if="dialog">
        <v-form ref="form" v-model="validForm">
            <v-card style="padding: 20px;">
                <v-card-title class="pt-2 pb-4">
                    {{
                        link.id ?
                        'Edit Link' :
                        'New Link'
                    }}
                </v-card-title>
                <v-card-text>

                    <label>Name *</label>
                    <v-text-field
                        v-model="link.name"
                        solo
                        dense
                        outlined
                        required :rules="[v => (!!v && !!v.trim()) || 'Name is required']"
                    ></v-text-field>
                    
                    <label>Description</label>
                    <v-text-field
                        v-model="link.description"
                        solo
                        dense
                        outlined
                    ></v-text-field>
                    
                    <label>Link *</label>
                    <v-text-field
                        v-model="link.url"
                        solo
                        dense
                        outlined
                        required :rules="[v => (!!v && !!v.trim()) || 'Link is required']"
                    ></v-text-field>

                </v-card-text>
                
                <v-card-actions class="px-6">
                    <v-spacer></v-spacer>
                    <v-btn color="primary" text @click="cancel">
                        Cancel
                    </v-btn>
                    <v-btn color="primary" depressed @click="save" :disabled="!validForm">
                        {{
                            link.id ?
                            'Save' :
                            'Create'
                        }}
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-form>
    </v-dialog>
</template>
<script>
export default {
    props: ['dialog', 'cancel', 'projectId', 'callback', 'link'],
    data(){
        return{
            validForm: false,
        }
    },
    methods:{
        save(){
            this.$http.post(`api/Project/${this.projectId}/link`, this.link).then(res => {
                if(this.link.id)
                    window.getApp.success("Link updated successfully.");
                else
                    window.getApp.success("Link created successfully.");
                this.callback();
            }).catch(response => {
                window.getApp.error("Cannot save link.");
                console.log(response);
            });
        }
    }
}
</script>
<style scoped>
.color-black{
    color: black !important;
}
</style>