<template>
	<div class="px-6 py-3 new-back fill-height">
		<v-tabs v-model="view" background-color="newDesignBackground">
			<v-tab>Validations</v-tab>
			<v-tab>Billable Categories</v-tab>
			<v-tab>Invoice Details</v-tab>
		</v-tabs>
		<v-tabs-items v-model="view" class="mt-5 new-back">
			<v-tab-item :value="0">
				<validation-rules/>
			</v-tab-item>
			<v-tab-item :value="1">
				<billable-categories/>
			</v-tab-item>
			<v-tab-item :value="2">
				<invoice-details-config/>
			</v-tab-item>
		</v-tabs-items>
	</div>
</template>

<script>
import ValidationRules from '../../components/admin-surf/config/ValidationRules';
import BillableCategories from '../../components/admin-surf/config/BillableCategories';
import InvoiceDetailsConfig from '../../components/admin-surf/config/InvoiceDetailsConfig';

export default {
	components: { ValidationRules, BillableCategories, InvoiceDetailsConfig },
	data: () => ({
		view: 0,
	}),
	created() {
		document.title = 'Admin Surf Config'
	},
	methods: {
	},
	computed: {
	},
}
</script>

<style lang="scss" scoped>

</style>