<template>
	<div class="py-3 px-5 new-back full-height">
		<div class="text-right mb-2 mt-n3">
			<v-menu v-if="id != 'new'" z-index="200" :close-on-content-click="false">
				<template #activator="{ on }">
					<v-btn v-on="on" color="primary" outlined>
						Actions
						<v-icon right>mdi-chevron-down</v-icon>
					</v-btn>
				</template>
				<v-list>
					<v-list-item v-if="canApprove" @click="validateBillable" class="pa-1 py-0">
						<v-list-item-content class="px-3">
							<v-list-item-title class="px-0">
								<v-icon left color="primary">mdi-check</v-icon> Validate billable
							</v-list-item-title>
						</v-list-item-content>
					</v-list-item>
					<v-list-item @click="$adminSurf('openHistory', billable, 'billable')" class="pa-1 py-0">
						<v-list-item-content class="px-3">
							<v-list-item-title class="px-0">
								<v-icon left color="secondary">mdi-clock-outline</v-icon> Version history
							</v-list-item-title>
						</v-list-item-content>
					</v-list-item>
					<v-list-item v-if="!billable.isCancelled && billable.status != 6" @click="$adminSurf('closeBillable', billable)" class="pa-1 py-0">
						<v-list-item-content class="px-3">
							<v-list-item-title class="px-0">
								<v-icon left color="primary">mdi-door</v-icon>
								Close Billable
							</v-list-item-title>
						</v-list-item-content>
					</v-list-item>
					<v-list-item v-if="!billable.isCancelled && billable.status != 6" @click="cancelBillable" class="pa-1 py-0">
						<v-list-item-content class="px-3">
							<v-list-item-title class="px-0">
								<v-icon left color="error">mdi-cancel</v-icon>
								Cancel Billable
							</v-list-item-title>
						</v-list-item-content>
					</v-list-item>
					<watchers-menu
						@watcher-added="billable.subscribers.push($event)"
						@watcher-removed="billable.subscribers.splice(billable.subscribers.findIndex(x => x.userId == $event), 1)"
						:entity-id="id"
						:watchers="billable.subscribers"
						required-permission="billables"
					>
						<template #default="{ on }">
							<v-list-item v-on="on" class="pa-1 py-0">
								<v-list-item-content class="px-3">
									<v-list-item-title class="px-0">
										<v-icon left>mdi-eye</v-icon>
										Watchers
									</v-list-item-title>
								</v-list-item-content>
							</v-list-item>
						</template>
					</watchers-menu>
				</v-list>
			</v-menu>
		</div>
		<v-form v-model="validForm" ref="form">
			<div :class="{ grid: isFixedRate }">
				<div>
					<v-row>
						<v-col :cols="4">
							<label>Customer*</label>
							<v-autocomplete
								v-model="billable.customerId" :rules="fieldRules"
								@change="updateCustomer"
								:items="customers" item-text="name" item-value="id"
								hide-details outlined :readonly="!!selectedBillableId || billable.id"
							/>
						</v-col>
						<v-col :cols="4">
							<label :class="{'error--text': sended && (!customerSmallCode || !codeProjectType) && !billable.code}">Billable Code*</label>
							<v-text-field
								v-if="billable.id" v-model="billable.code"
								readonly
								:rules="fieldRules" :hide-details="!copiedFromCode"
								outlined :messages="'Copied from: ' + copiedFromCode"
							/>
							<div
								v-else
								class="d-flex align-center justify-space-around"
								style="height: 56px; gap: 5px"
								:class="{ 'flex-wrap': !showCodeSelection }"
							>
								<template v-if="!showCodeSelection">
									<v-btn @click="openBillableSelect" :disabled="!billable.customerId" small>Extend billable</v-btn>
									<v-btn @click="setCode" :disabled="!billable.customerId" small color="primary">New billable</v-btn>
								</template>
								<template v-else>
									<v-text-field
										:value="customerSmallCode"
										hide-details outlined disabled
										style="flex: none; width: 65px"
									/>
									-
									<v-select
										v-model="billable.categoryId" :rules="fieldRules" :items="categories"
										@change="codeProjectType = categories.find(x => x.id == billable.categoryId).smallCode"
										item-text="name" item-value="id"
										hide-details outlined :disabled="!billable.customerId || !!billable.id || !!selectedBillableId"
									>
										<template #selection="{ item }">
											<span>{{ item.smallCode }}</span>
										</template>
									</v-select>
									-
									<v-text-field
										:value="codeConsecutiveNumber"
										hide-details outlined disabled
									/>
									<v-btn
									 	@click="clearCode"
										x-small fab depressed
										color="error"
									>
										<v-icon>close</v-icon>
									</v-btn>
								</template>
							</div>
						</v-col>
						<v-col :cols="4">
							<label>Currency*</label>
							<v-select
								v-model="billable.currency" :rules="fieldRules" :items="currencies"
								hide-details outlined :disabled="!billable.customerId || billable.isApproved"
							/>
						</v-col>
						<v-col :cols="4">
							<label>Type*</label>
							<v-select
								v-model="billable.type" :rules="fieldRules"
								:disabled="!!billable.assignments.length || !billable.customerId" :items="types"
								hide-details outlined
							/>
							<v-checkbox
								v-if="[1, 2].includes(billable.type)"
								v-model="billable.isOpen"
								class="mt-1" label="Open billable"
								hide-details :disabled="!billable.customerId || billable.isApproved"
							/>
						</v-col>
						<v-col :cols="4">
							<label>Start Date</label>
							<q-date-picker
								v-model="billable.startDate" hide-details outlined inner
								label="" @change="setPayments(); updateAllExpirationDates()"
								:disabled="!billable.customerId || billable.isApproved"
							/>
						</v-col>
						<v-col v-if="false" :cols="4">
							<label>End Date</label>
							<q-date-picker
								v-model="billable.endDate" hide-details outlined inner
								label=""
								:disabled="!billable.customerId || billable.isApproved"
								:min="billable.startDate"
							/>
						</v-col>
						<v-col :cols="4">
							<label>Calendars*</label>
							<v-select
								v-model="selectedCalendars"
								:items="calendars"
								item-value="id" item-text="name"
								outlined multiple hide-details :disabled="!billable.customerId || billable.isApproved"
								:rules="[v => (!!v && !!v.length) || 'Required']"
							>
								<template #selection="{ item }">
									<v-chip :color="item.color" outlined>
										<div class="dot mr-1" :style="{ background: item.color }"></div>
										<span class="text--text"> {{ item.name }} </span>
									</v-chip>
								</template>
							</v-select>
						</v-col>
						<v-col :cols="8">
							<label>Notes</label>
							<v-textarea v-model="billable.notes" hide-details outlined :rows="3" :disabled="!billable.customerId"/>
						</v-col>
					</v-row>

					<div class="mt-10">
						<h2>
							Links
							<v-btn @click="editLink({})" :disabled="!billable.customerId" x-small fab depressed color="primary">
								<v-icon>mdi-plus</v-icon>
							</v-btn>
						</h2>
						<div v-if="!billable.links || !billable.links.length" class="mt-1">No registered links</div>
						<v-row class="mt-n1">
							<v-col
								v-for="(link, ix) in billable.links" :key="ix"
								:cols="6"
							>
								<div
									class="link new-back lighten2 rounded bordered pa-2"
									style="gap: 10px"
								>
									<v-icon x-large color="secondary">mdi-link-box-variant</v-icon>
									<div class="link-text">
										<h3>{{ link.name }}</h3>
										<span>{{ link.url }}</span>
									</div>
									<div>
										<v-icon @click="editLink(link)" color="primary">mdi-pencil</v-icon>
										<v-icon @click="deleteLink(ix)" color="error">mdi-delete</v-icon>
									</div>
								</div>
							</v-col>
						</v-row>
					</div>
		
					<div class="mt-15 inline-block">
						<h2 class="inline-block">
							Services
							<v-btn @click="addBillableLine" :disabled="!billable.customerId || billable.isApproved" fab x-small color="primary">
								<v-icon>mdi-plus</v-icon>
							</v-btn>
						</h2>
						<div v-for="(line, ix) in billable.lines" :key="ix">
							<div class="mt-3 line">
								<div class="pt-5" style="height: 100%">
									<v-btn
										@click="removeBillableLine(ix)" :disabled="!canDeleteLine(line) || billable.isApproved"
										style="background: var(--v-error-darken); color: white"
										fab small
									>
										<v-icon>mdi-minus</v-icon>
									</v-btn>
								</div>
								<div>
									<label>Description*</label>
									<v-text-field
										v-model="line.role" :rules="fieldRules"
										outlined hide-details :disabled="billable.isApproved"
									/>
								</div>
								<div style="width: 65px">
									<label>Quantity*</label>
									<v-text-field
										v-model="line.quantity" :rules="numberRules"
										type="number" step="0.25" outlined hide-details
										:disabled="billable.isApproved"
									/>
								</div>
								<div v-if="!billable.isOpen && !isFixedRate" class="mid-input">
									<div>
										<label>Duration*</label>
										<v-text-field
											v-model="line.duration" :rules="numberRules"
											@change="updateExpirationDate(line)"
											type="number" outlined hide-details
											:disabled="billable.isApproved"
										/>
									</div>
									<div>
										<label>&nbsp;</label>
										<v-select
											v-model="line.durationUnit" :rules="fieldRules" :items="durationUnits"
											@change="updateExpirationDate(line)"
											outlined hide-details :disabled="billable.isApproved"
										/>
									</div>
								</div>
								<div v-if="billable.type !== 3">
									<label v-if="!isFixedRate">Rate per hour</label>
									<label v-else>Unitary Rate</label>
									<input-mask
										v-model="line.rate" :rules="fieldRules"
										outlined hide-details type="number" :mask="currencyFormat"
										:disabled="billable.isApproved"
									/>
								</div>
								<div class="d-flex justify-space-between align-center gap-5" style="height: 100%">
									<span v-if="!billable.isOpen && !isFixedRate" class="title mt-1 pt-5">
										{{ line.duration * getHoursFactor(line.durationUnit) * line.quantity }} hrs
									</span>
									<div v-if="!billable.isOpen && !isFixedRate" class="d-flex flex-column title mt-1">
										<span class="caption">Subtotal:</span>
										<span>
											${{ (line.duration * getHoursFactor(line.durationUnit) * line.quantity * line.rate) | money }}
										</span>
									</div>
								</div>
							</div>
							<br>
						</div>
						<div v-if="billable.lines.length && !billable.isOpen && !isFixedRate" class="d-flex flex-column align-end mt-10">
							<ul>
								<li v-for="unit in usedDurationUnits" :key="unit.value">
									Based on {{ unit.hours }} hours per {{ unit.text.substr(0, unit.text.length-1) }}
								</li>
							</ul>
						</div>
					</div>

					<div class="mt-15">
						<h2>
							Operational Projects
							<v-btn @click="addProject" :disabled="!billable.customerId" fab x-small color="primary">
								<v-icon>mdi-plus</v-icon>
							</v-btn>
						</h2>
						<data-table
							:headers="operationalProjectHeaders"
							:items="billable.projects"
							disable-sort
							class="mt-5"
						>
							<template #[`item.activeBillable`]="{ item }">
								<v-icon v-if="item.active">mdi-check</v-icon>
								<v-btn v-else @click="$set(item, 'active', true)" color="primary" small>Activate</v-btn>
							</template>
						</data-table>
					</div>
				</div>
				<div v-if="isFixedRate">
					<v-card class="new-back lighten2" style="height: 100%">
						<v-card-title>Billing Schedule</v-card-title>
						<v-card-text>
							<div class="split-selection">
								<label> Split </label>
								<v-select
									v-model="billable.paymentScheduleType"
									:items="paymentScheduleTypes"
									class="mt-0 pt-0 mb-4" dense hide-details
									@change="setPayments" :disabled="!billable.customerId || !isBillable"
								/>
							</div>
							<v-row>
								<v-col>
									<label>Payments</label>
									<v-text-field
										v-model="billable.scheduledPaymentsQuantity"
										type="number" outlined hide-details dense 
										@change="setPayments" :disabled="!billable.customerId || !isBillable"
										:rules="[v => !!v || 'Required']"
									/>
								</v-col>
								<v-col>
									<label>Credit Days</label>
									<v-text-field
										v-model="billable.creditDays"
										type="number" outlined hide-details dense :disabled="!billable.customerId || !isBillable"
										@change="v => {$set(billable, 'creditDays', trunc(v, 0)); setPayments()}"
										:rules="[v => !!v || 'Required']"
									/>
								</v-col>
							</v-row>
							<v-data-table
								:headers="paymentHeaders" :items="billable.scheduledPayments"
								hide-default-footer class="my-5 new-back lighten2" disable-pagination
							>
								<template #[`item.index`]="{ index }">
									{{index+1}}
								</template>
								<template #[`item.percentage`]="{ item }">
									<input-mask
										v-model="item.amount" dense hide-details type="number"
										:mask="v => v + '%'"
									/>
								</template>
								<template #[`item.amount`]="{ item }">
									<v-menu open-on-hover left offset-x>
										<template #activator="{ on }">
											<div v-on="on">
												<span v-if="!billable.paymentScheduleType">{{currencyFormat(item.equallyAmount)}}</span>
												<span v-else-if="billable.paymentScheduleType === 2">{{ currencyFormat((item.amount/100)*total) }}</span>
												<input-mask
													v-else v-model="item.amount" dense hide-details type="number"
													:mask="billable.paymentScheduleType === 1 ? currencyFormat : v => v + '%'"
												/>
											</div>
										</template>
										<v-card>
											<v-card-text>
												<div
													class="totals"
													:set="amount =
														billable.paymentScheduleType == 1 ? item.amount :
														billable.paymentScheduleType == 0 ? item.equallyAmount:
														(item.amount/100)*total
													"
												>
													<div class="total"> <b>Subtotal:</b> {{currencyFormat(amount / (1+taxFactor))}} </div>
													<div class="total">
														<b>Tax:</b>
														{{billable.requireTax ? currencyFormat(amount*taxFactor / (1+taxFactor)) : '-'}}
													</div>
													<div class="total"> <b>Total:</b> {{currencyFormat(amount)}} </div>
												</div>
											</v-card-text>
										</v-card>
									</v-menu>
								</template>
								<template #[`item.date`]="{ item }">
									<q-date-picker v-model="item.date" inner icon="" label="" :rules="fieldRules" :zIndex="200"/>
								</template>
							</v-data-table>

							<div class="totals">
								<div v-if="!billable.isOpen && !isFixedRate" class="total"> <b>Hours:</b> {{ totalHours }} hrs </div>
								<div class="total"> <b>Subtotal:</b> {{ !billable.isOpen ? currencyFormat(subtotal) : '-' }} </div>
								<div class="total pl-0">
									<section class="d-flex align-center">
										<v-checkbox
											v-model="billable.requireTax" @change="setPayments"
											class="mt-0 inline-block" hide-details
											:disabled="!isBillable"
										/>
										<b>Tax:</b>
									</section>
									{{billable.requireTax ? currencyFormat(taxAmount) : '-'}}
								</div>
								<div class="total"> <b>Total:</b> {{ !billable.isOpen ? currencyFormat(total) : '-' }} </div>
							</div>
						</v-card-text>
					</v-card>
				</div>
			</div>
		</v-form>
		<div class="d-flex justify-end mt-5">
			<v-btn @click="save(true)" color="secondary" :disabled="!!billable.assignments.length || billable.isApproved">Save Draft</v-btn>
			<v-btn @click="save(false)" color="primary" class="ml-2">Save Billable</v-btn>
		</div>

		<blue-confirm-dialog ref="billableSelect">
			<template #text>
				<billables-table @click:row="selectBillable" :billables="billables.filter(x => !x.isCancelled && (!billable.customerId || x.customerId == billable.customerId))" x-small/>
			</template>
			<template #actions>
				<div></div>
			</template>
		</blue-confirm-dialog>
		<blue-confirm-dialog ref="editLink">
			<template #text>
				<div>
					<label>Name*</label>
					<v-text-field v-model="selectedLink.name" :rules="fieldRules" outlined hide-details dense/>
				</div>
				<div class="mt-3">
					<label>URL*</label>
					<v-text-field v-model="selectedLink.url" :rules="fieldRules" outlined hide-details dense/>
				</div>
			</template>
		</blue-confirm-dialog>
		<blue-confirm-dialog ref="addProject">
			<template #text>
				<div>
					<v-autocomplete
						v-model="newProject"
						label="Select Project"
						:items="shownProjects"
						:rules="fieldRules"
						outlined hide-details dense single-line
						item-text="name" return-object
					/>
				</div>
			</template>
		</blue-confirm-dialog>
	</div>
</template>

<script>
import moment from "moment"
import BillablesTable from '../../components/BillablesTable'
import WatchersMenu from "../../components/admin-surf/WatchersMenu"

export default {
	props: ['id'],
	components: { BillablesTable, WatchersMenu },
	data(){
		return {
			validForm: false,
			billable: {
				currency: null,
				type: null,
				paymentScheduleType: null,
				lines: [],
				scheduledPayments: [],
				assignments: [],
				requireTax: false,
				links: [],
				projects: [],
			},
    	customers: [],
			billables: [],
			currencies: ['MXN', 'USD', 'EUR'],
			copiedFromCode: '',

			selectedBillableId: '',
			showCodeSelection: false,
			codeProjectType: '',
			customerSmallCode: '',
			codeConsecutiveNumber: '',
			
			types: [
				{ text: 'Fixed Rate', value: 0, },
				{ text: 'Rate per Assigned Hour', value: 1, },
				{ text: 'Rate per Executed Hour', value: 2, },
				{ text: 'Internal', value: 3, },
			],
			categories: [],
			durationUnits: [
				{ value: 3, hours: 1, text: 'Hours' },
				{ value: 0, hours: 8, text: 'Days' },
				{ value: 1, hours: 40, text: 'Weeks' },
				{ value: 2, hours: 168, text: 'Months' },
			],
			fieldRules: [
				(v) => (!!v || v === 0) || 'Field is required',
			],
			numberRules: [
				(v) => (!!v || v === 0) || 'Field is required',
				(v) => parseFloat(v) > 0 || 'Value cannot be less than or equal to zero',
			],
			paymentScheduleTypes: [
				{ text: 'equally', value: 0, },
				{ text: 'by amount', value: 1, },
				{ text: 'by percentages', value: 2, },
			],

			sended: false,
			canEditCode: true,

			selectedLink: {},

			operationalProjectHeaders: [
				{ text: 'Small Code', 			value: 'projectSmallCode' },
				{ text: 'Name', 						value: 'projectName' },
				{ text: 'Project Status',		value: 'projectStatusName' },
				{ text: 'Effort Reporting', value: 'activeBillable', align: 'center' },
			],
			operationalProjects: [],
			newProject: {},

			selectedCalendars: [],
			calendars: [],
			currentUser: {},
		}
	},
	mounted(){
		this.$q.changeHeaderTitle(`${this.id == 'new' ? 'New ' : 'Edit '} billable`);
		document.title = 'Billable'
		let id = this.id
		let billableBase = this.$route.query?.billableBase
		let billablePr = null

		if(id == 'new' && billableBase){
			id = billableBase
		}
		if(id != 'new'){
			billablePr = this.$http.get(`api/Billable/${id}`)
				.then(res => {
					this.billable = res.data
					this.selectedCalendars = this.billable.calendars?.map(x => x.calendarId)

					if(this.id == 'new'){
						this.copiedFromCode = this.billable.code
						delete this.billable.id
						delete this.billable.code
						delete this.billable.discount
						delete this.billable.startDate
						delete this.billable.notes
						delete this.billable.scheduledPaymentsQuantity
						delete this.billable.creditDays
						delete this.billable.changes
						delete this.billable.isApproved
						delete this.billable.isDraft
						delete this.billable.isCancelled
						delete this.billable.IsApproved
						delete this.billable.IsClosed
						delete this.billable.subscribers
						this.billable.scheduledPayments = []
						this.billable.assignments = []
						this.billable.items = []
						this.billable.paymentScheduleType = 0
						this.billable.links.forEach(x => {
							delete x.id
							delete x.billableId
						})
						this.billable.lines
							.forEach(l => {
								delete l.id
								delete l.billableId
								delete l.duration
								delete l.billableItemLines
							})
					}
					this.setPayments()
				})
		}

		this.$blueSystem.getCurrentUser()
			.then(user => {
				this.currentUser = user
			})
			.catch(error => this.$root.error('Failed to get user'))
		this.$http.get('api/billable?includeAssignments=true')
			.then(res => {
				this.billables = res.data
			})
			.catch(err => {
				this.$root.error('Cannot get billables')
				console.error(err)
			})
		this.$http.get("/api/customer")
			.then(res => {
				this.customers = _.orderBy(res.data, ['name'])
			})
			.catch(res => {
				window.getApp.error("Cannot obtain customers.")
			})
		this.$http.get('api/project')
			.then(res => {
				this.operationalProjects = res.data
			})
		this.$http.get('api/admin/calendars')
			.then(res => {
				this.calendars = res.data
			})
		const categoryPr = this.$http.get('api/billable/category')
			.then(res => {
				this.categories = res.data
			})
			.catch(err => {
				console.error(err)
				this.$root.error('Cannot get categories')
			})
		Promise.all([billablePr, categoryPr])
			.then(() => {
				if(this.billable.id) {
					this.codeProjectType = this.billable.code.split('-')[1]
					if(!this.billable.categoryId) {
						this.$set(this.billable, 'categoryId', this.categories.find(x => x.smallCode == this.codeProjectType)?.id)
					}
				}
			})
	},
	watch: {
		'billable.paymentScheduleType'(cur, prev){
			prev !== null ? this.billable.scheduledPayments.forEach(p => p.amount = 0) : null
		},
	},
	computed: {
		paymentHeaders(){
			let headers = [
      	{ text: "Payment", value: "index", sortable: false, width: 1 },
      	{ text: "%", value: "percentage", sortable: false, align: 'center', width: '90'},
      	{ text: "Amount", value: "amount", sortable: false },
      	{ text: "Date", value: "date", sortable: false },
			]

			if(this.billable.paymentScheduleType !== 2)
				headers = headers.filter((h,i) => i != 1)

			return headers
		},
		areValidMounts(){
			if(!this.billable.paymentScheduleType) return true
			let totalAmount = this.billable.scheduledPayments.reduce((partial, a) => partial + Number(a.amount), 0)
			if(this.billable.paymentScheduleType === 1) return totalAmount == this.round(this.total, 2)
			return totalAmount == 100
		},
		totalHours(){
			return this.billable.lines
				.reduce((partial, line) => {
					const unit = this.durationUnits.find(x => x.value == line.durationUnit)
					return partial + unit.hours * line.quantity * line.duration
				}, 0)
		},
		subtotal(){
			if(this.billable.isOpen) return 0
			const HoursFactors = [8, 40, 168, 1]
			let isFixed = this.billable.type === 0
			let subtotal = 0

			for(let line of this.billable.lines)
			{
				let rate = line.rate
				line.hours = HoursFactors[line.durationUnit] * line.duration * line.quantity

				if(isFixed){
					subtotal += rate * line.quantity
				}
				else{
					subtotal += rate * line.hours
				}
			}
			this.$nextTick(this.setPayments)
			return subtotal - subtotal * ((this.billable.discount || 0)/100)
		},
		taxAmount(){
			return this.subtotal * this.taxFactor
		},
		total(){
			return this.subtotal + this.taxAmount
		},
		taxFactor(){
			return this.billable.requireTax ? .16 : 0
		},
		usedDurationUnits(){
			return this.durationUnits
				.filter(du =>
					du.hours != 1 &&
					this.billable.lines.some(l => l.durationUnit == du.value)
				)
		},
		shownProjects(){
			return this.operationalProjects.filter(x => !this.billable.projects.some(p => p.projectId == x.id) && x.customerId == this.billable.customerId)
		},
		isFixedRate(){
			return this.billable.type === 0
		},
		isExecuted(){
			return this.billable.type === 2
		},
		isBillable(){
			return this.billable.type !== 3
		},
		canApprove() {
			return !this.billable.isDraft && !this.billable.isApproved && !this.billable.isCancelled &&
				this.billable.approvers?.some(x =>
						x.approverId == this.currentUser.id &&
						x.statusToApprove === 1 &&
						x.status === 0
					)
		},
	},
	methods: {
		clearCode(){
			this.selectedBillableId = ''
			this.showCodeSelection = false
		},
		openBillableSelect(){
			this.$refs.billableSelect.open({
				title: 'Billable Selection',
				addClose: true,
				width: 600
			})
		},
		selectBillable(billable){
			this.billable.lines = billable.lines
				.map(x => ({
					duration: x.duration,
					durationUnit: x.durationUnit,
					quantity: x.quantity,
					rate: x.rate,
					role: x.role,
					seat: x.seat,
				}))

			this.selectedBillableId = billable.id
			this.codeProjectType = billable.code.split('-')[1]
			this.$set(this.billable, 'customerId', billable.customerId)
			this.$set(this.billable, 'categoryId', billable.categoryId)

			if(!this.billable.categoryId) {
				this.$set(this.billable, 'categoryId', this.categories.find(x => x.smallCode == this.codeProjectType)?.id)
			}

			this.$refs.billableSelect.close()
			this.setCode()
		},
		setCode(){
			this.showCodeSelection = true
			this.updateCustomer()
		},
		updateCustomer(){
			if(!this.billable.customerId) return
			const customer = this.customers.find(x => x.id == this.billable.customerId)
			this.customerSmallCode = customer.smallCode
			this.billable.projects = []

			this.$http.get(
				`api/customer/${this.billable.customerId}/nextBillableCode?extendFromBillableId=${this.selectedBillableId}`
			)
				.then(res => {
					this.codeConsecutiveNumber = res.data
				})
				.catch(err => {
					this.$root.error('Cannot get customer info')
					console.error(err)
				})
		},
		setPayments(){
			if(!this.billable.scheduledPaymentsQuantity) return
			let payments = this.billable.scheduledPayments || []
			let monthlyPayment = this.total / this.billable.scheduledPaymentsQuantity
			let curAmount = 0

			if(this.billable.scheduledPaymentsQuantity == 0){
				this.billable.scheduledPayments = []
				return
			}

			payments = payments.slice(0, this.billable.scheduledPaymentsQuantity)
			
			while(payments.length < this.billable.scheduledPaymentsQuantity){
				payments.push({
					equallyAmount: 0,
					amount: 0,
					date: '',
				})
			}

			for(let payment of payments){
				payment.equallyAmount = this.round(monthlyPayment)
				curAmount += this.round(monthlyPayment)
			}
			payments[payments.length-1].equallyAmount = this.round(payments[payments.length-1].equallyAmount + this.total - curAmount)
			this.billable.scheduledPayments = payments
			this.$forceUpdate()
		},
		canDeleteLine(line){
			return !this.billable.assignments.some(a => a.billableLineId == line.id)
		},
		addBillableLine(){
			this.billable.lines.push( { role: '', quantity: 1, duration: 1, durationUnit: 0, rate: 0, } )
		},
		removeBillableLine(index){
      this.$vuetifyConfirmDialog.open("Remove Seat", "Are you sure you want to remove this seat?", "Cancel", "Confirm")
				.then(res => {
					if(!res)
						return
					this.billable.lines = this.billable.lines.filter((bl, ix) => ix != index)
				})
		},
		save(isDraft){
			this.billable.isDraft = isDraft
			this.sended = true
			if(!this.areValidMounts){
				this.$root.error('The amount of the payments must be equal to the billable total')
				return
			}
			if(!this.billable.id){
				this.billable.code = this.customerSmallCode + '-'
					+ this.codeProjectType + '-'
					+ this.codeConsecutiveNumber
			}
			if(!this.$refs.form.validate()){
				return
			}
			if((!this.customerSmallCode || !this.codeProjectType) && !this.billable.id){
				return
			}
			this.billable.paymentScheduleType = this.billable.paymentScheduleType || 0
			this.billable.scheduledPayments
				.forEach(p => {
					if(!this.billable.paymentScheduleType)
						p.amount = p.equallyAmount
					delete p.equallyAmount
					delete p.index
				})

			this.billable.calendars = this.selectedCalendars
				.map(x => ({ calendarId: x }))

			this.$http.post('api/billable', this.billable)
				.then(res => {
					if(typeof res.data == 'string'){
						window.getApp.error(res.data)
						return
					}

					window.getApp.success('Billable saved successfully')
					this.$router.go(-1)
				})
				.catch(err => {
					console.log(err)
					if (!err.notified) {
						window.getApp.error('Cannot save billable')
					}
				})
		},
		validateBillable(){
			this.$adminSurf('validateBillable', this.billable)
				.then(res => {
					this.billable = Object.assign(this.billable, res)
					this.$root.success('Billable marked as approved')
				})
				.catch(res => {
					console.log(res)
					this.$root.error('Cannot validate billable')
				})
		},
		cancelBillable(){
			this.$adminSurf('cancelBillable', this.id)
				.then(res => {
					if(res)
						this.$router.push('/admin/billable')
				})
				.catch(res => {
					console.log(res)
					this.$root.error('Cannot delete billable')
				})
		},
		updateAllExpirationDates(){
			this.billable.lines.forEach(this.updateExpirationDate)
		},
		updateExpirationDate(line){
			if(!this.billable.startDate) return

			const unit = this.durationUnits.find(x => x.value == line.durationUnit)
			const expirationDate = moment(this.billable.startDate)
				.add(line.duration, unit.text)
				.format('YYYY-MM-DD')
			this.$set(line, 'expirationDate', expirationDate)
		},
		updateDuration(line){
			if(!this.billable.startDate) return

			for(const unit of this.durationUnits){
				const diff = moment(line.expirationDate)
					.diff(this.billable.startDate, unit.text, true)
				if(diff == Math.round(diff)){
					this.$set(line, 'duration', diff)
					this.$set(line, 'durationUnit', unit.value)
				}
			}
		},
		getHoursFactor(unit){
			return this.durationUnits.find(x => x.value == unit).hours
		},
		editLink(link){
			this.selectedLink = _.cloneDeep(link)
			this.$refs.editLink
				.open({
					title: link.name ? 'Edit link' : 'Add link',
				})
				.then(res => {
					if(!res) return
					if(!this.selectedLink.name || !this.selectedLink.url){
						this.$root.error('Please, complete the required fields')
						this.editLink(this.selectedLink)
						return
					}
					if(link.name){
						link.name = this.selectedLink.name
						link.url = this.selectedLink.url
					}
					else
						this.billable.links.push(this.selectedLink)
				})
		},
		deleteLink(ix){
			this.billable.links = this.billable.links.filter((x, i) => i != ix)
		},
		addProject(){
			this.newProject = null
			this.$refs.addProject
				.open({
					title: 'Add project',
				})
				.then(res => {
					if(!res) return
					if(!this.newProject){
						this.$root.error('Please, complete the required fields')
						this.addProject()
						return
					}
					this.billable.projects.push({
						projectId: this.newProject.id,
						projectSmallCode: this.newProject.smallCode,
						projectName: this.newProject.name,
						projectStatus: this.newProject.status,
						projectStatusName: this.newProject.statusName,
					})
				})
		},
	},
}
</script>

<style lang="scss" scoped>
.dot {
	display: inline-block;
	width: 10px; height: 10px;
	border-radius: 50%;
}

.grid{
	display: grid;
	grid-template-columns: auto 470px;
	gap: 20px;
}

.line {
	display: inline-flex;
	align-items: center;
	gap: 10px;
}

.mid-input{
	display: grid;
	grid-template-columns: 65px 115px;
	gap: 10px;
}

.split-selection{
	display: flex;
	gap: 5px;
	font-size: 16px;
	color: var(--v-text-base);

	label{
		margin-top: 3px;
	}
}

.link{
	display: grid;
	align-items: center;
	grid-template-columns: 40px calc(100% - 110px) 50px;
	.link-text{
		white-space: nowrap;
		overflow: hidden;
		text-overflow: ellipsis;
	}
}

.totals{
	display: flex;
	flex-direction: column;
	font-size: 1.2em;
	align-items: flex-end;

	.total{
		min-width: 200px;
		display: flex;
		gap: 30px;
		justify-content: space-between;

		section{
			position: relative;
			.v-input{
				position: absolute;
				left: -32px;
			}
		}
	}
}
</style>