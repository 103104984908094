<template>
	<div class="pa-3 new-back">
		<v-menu v-if="canEdit" z-index="200" :close-on-content-click="false">
			<template #activator="{ on }">
				<v-btn v-on="on" color="primary" outlined absolute right>
					Actions
					<v-icon right>mdi-chevron-down</v-icon>
				</v-btn>
			</template>
			<v-list>
				<v-list-item v-if="!billable.isCancelled && !billable.isClosed" :to="`/admin/billable/${billable.id}`">
					<v-list-item-content class="px-3">
						<v-list-item-title class="px-0">
							<v-icon left color="primary">mdi-pencil</v-icon> Edit billable
						</v-list-item-title>
					</v-list-item-content>
				</v-list-item>
				<v-list-item v-if="canApprove" @click="validateBillable">
					<v-list-item-content class="px-3">
						<v-list-item-title class="px-0">
							<v-icon left color="primary">mdi-check</v-icon> Validate billable
						</v-list-item-title>
					</v-list-item-content>
				</v-list-item>
				<v-list-item @click="$adminSurf('openHistory', billable, 'billable')">
					<v-list-item-content class="px-3">
						<v-list-item-title class="px-0">
							<v-icon left color="secondary">mdi-clock-outline</v-icon> Version history
						</v-list-item-title>
					</v-list-item-content>
				</v-list-item>
				<v-list-item v-if="!billable.isCancelled && billable.status != 6" @click="$adminSurf('closeBillable', billable)">
					<v-list-item-content class="px-3">
						<v-list-item-title class="px-0">
							<v-icon left color="primary">mdi-door</v-icon>
							Close Billable
						</v-list-item-title>
					</v-list-item-content>
				</v-list-item>
				<v-list-item v-if="!billable.isCancelled && billable.status != 6" @click="cancelBillable">
					<v-list-item-content class="px-3">
						<v-list-item-title class="px-0">
							<v-icon left color="error">mdi-cancel</v-icon>
							Cancel Billable
						</v-list-item-title>
					</v-list-item-content>
				</v-list-item>
				<watchers-menu
					@watcher-added="billable.subscribers.push($event)"
					@watcher-removed="billable.subscribers.splice(billable.subscribers.findIndex(x => x.userId == $event), 1)"
					:entity-id="id"
					:watchers="billable.subscribers"
					required-permission="billables"
				>
					<template #default="{ on }">
						<v-list-item v-on="on" class="pa-1 py-0">
							<v-list-item-content class="px-3">
								<v-list-item-title class="px-0">
									<v-icon left>mdi-eye</v-icon>
									Watchers
								</v-list-item-title>
							</v-list-item-content>
						</v-list-item>
					</template>
				</watchers-menu>
			</v-list>
		</v-menu>
		<v-row>
			<v-col :cols="9">
				<div class="d-inline-flex flex-column mb-5">
					<h2>Billable Lines</h2>
					<div class="d-inline-flex flex-column mt-3" style="gap: 20px">
						<div
							v-for="(line, ix) in billable.lines" :key="ix"
							class="d-inline-flex" style="gap: 10px"
						>
							<div>
								<v-text-field v-model="line.role" readonly filled hide-details label="Description"></v-text-field>
							</div>
							<div>
								<v-text-field v-model="line.quantity" readonly filled hide-details label="Quantity" type="number"/>
							</div>
							<div v-if="!billable.isOpen && !isFixedRate">
								<v-text-field v-model="line.duration" readonly filled hide-details label="Duration" type="number"></v-text-field>
							</div>
							<div v-if="!billable.isOpen && !isFixedRate">
								<v-select v-model="line.durationUnit" :items="durationUnits" readonly filled hide-details label="Period"></v-select>
							</div>
							<div v-if="isBillable">
								<v-text-field
									:value="currencyFormat(line.rate)"
									readonly filled hide-details
									:label="isFixedRate ? 'Unitary Rate' : 'Rate per hour'"
								/>
							</div>
							<div v-if="!billable.isOpen && isBillable">
								<v-text-field :value="currencyFormat(getLineSubtotal(line))" readonly filled hide-details label="Service subtotal rate"/>
							</div>
						</div>
					</div>
					<div v-if="isBillable && !billable.isOpen" class="d-inline-flex justify-end mt-3">
						<div
							class="d-flex flex-column pt-2 pb-1 px-5 rounded"
							style="background: var(--v-lightgray-lighten)"
						>
							<span class="body-2">Subtotal rate</span>
							<div class="mt-n1 title">{{currencyFormat(billable.subtotal)}}</div>
						</div>
					</div>
				</div>

				<div class="mb-10">
					<h2>Links</h2>
					<div v-if="!billable.links || !billable.links.length" class="mt-1">No registered links</div>
					<v-row class="mt-n1">
						<v-col
							v-for="(link, ix) in billable.links" :key="ix"
							:cols="6"
						>
							<div
								@click="openLink(link.url)"
								class="link new-back lighten2 rounded bordered cursor-pointer pa-2"
								style="gap: 10px"
							>
								<v-icon x-large color="secondary">mdi-link-box-variant</v-icon>
								<div class="link-text">
									<h3>{{ link.name }}</h3>
									<span>{{ link.url }}</span>
								</div>
							</div>
						</v-col>
					</v-row>
				</div>

				<div v-if="isExecuted || isFixedRate" class="mb-10">
					<div class="flex-space-between mb-2">
						<h2>Effort Summary</h2>
						<v-btn :to="`/admin/billable/executedHours/${id}`" color="primary" outlined small>Effort Report</v-btn>
					</div>
					<div class="effort-indicators my-3">
						<div>
							<v-icon
								:style="{background: $vuetify.theme.isDark ? '' : '#b8d7fe'}"
								color="#1e7afd" large
							> mdi-cog-outline </v-icon>
							<div>
								<h3>{{ effortSummary.totalExecuted | limitDecimals(1) }} hours</h3>
								<div class="subtitle">Total Executed</div>
							</div>
						</div>
						<div>
							<v-icon
								:style="{background: $vuetify.theme.isDark ? '' : '#bce3c9'}"
								color="#3faf63" large
							> mdi-currency-usd </v-icon>
							<div>
								<h3>{{ effortSummary.executedBillable | limitDecimals(1) }} hours</h3>
								<div class="subtitle">Executed Billable</div>
							</div>
						</div>
						<div>
							<v-icon
								:style="{background: $vuetify.theme.isDark ? '' : '#f5c4c5'}"
								color="#dd353a" large
							> mdi-currency-usd-off </v-icon>
							<div>
								<h3>{{ effortSummary.executedNonBillable | limitDecimals(1) }} hours</h3>
								<div class="subtitle">Executed Non Billable</div>
							</div>
						</div>
						<div
							:style="{
								background: effortSummary.unclassified ? 'var(--v-error-base)' : '',
								color: effortSummary.unclassified ? 'white' : '',
							}"
						>
							<v-icon
								:style="{background: ($vuetify.theme.isDark || effortSummary.unclassified) ? '' : '#b8d7fe'}"
								:color="effortSummary.unclassified ? '#fff' : '#1e7afd'" large
							> mdi-information-outline </v-icon>
							<div>
								<h3>{{ effortSummary.unclassified | limitDecimals(1) }} hours</h3>
								<div class="subtitle">Unclassified</div>
							</div>
						</div>
					</div>
					<v-data-table
						class="rounded-lg bordered new-back lighten2 overflow-hidden"
						:headers="effortSummaryHeaders" :items="effortSummary.members"
						:loading="!effortSummary.members"
					>
						<template #[`item.teamMember`]="{ item }">
							<div class="d-flex align-center my-1" style="gap: 7px">
								<user-avatar :size="42" :user-id="item.userId" hide-loading/>
								<div>
									<b>{{ item.userBlueTag }}</b><br>
									<span>{{ item.userFullName }}</span>
								</div>
							</div>
						</template>
						<template #[`item.executedBillable`]="{ item }">{{ item.executedBillable | limitDecimals(1) }}</template>
						<template #[`item.executedNonBillable`]="{ item }">{{ item.executedNonBillable | limitDecimals(1) }}</template>
						<template #[`item.totalExecuted`]="{ item }">{{ item.totalExecuted | limitDecimals(1) }}</template>
						<template #[`item.unclassified`]="{ item }">{{ item.unclassified | limitDecimals(1) }}</template>
					</v-data-table>
				</div>

				<div v-else class="mb-10">
					<div class="flex-space-between mb-2">
						<h2>Assignments</h2>
						<v-btn :to="`/admin/billable/executedHours/${id}`" color="primary" outlined small>Effort Report</v-btn>
					</div>
					<v-data-table
						class="rounded-lg bordered new-back lighten2 overflow-hidden"
						:headers="assignmentsHeaders" :items="assignments"
					>
						<template v-slot:[`header.actualWH`]> Actual<br><span class="header-span">Working Hours</span> </template>
						<template v-slot:[`header.totalWH`]> Total<br><span class="header-span">Working Hours</span> </template>
						<template v-slot:[`header.months`]> Months<br><span class="header-span">Base 168 hrs</span> </template>
						<template v-slot:[`header.weeks`]> Weeks<br><span class="header-span">Base 168 hrs</span> </template>
						<template v-slot:[`item.user.blueTag`]="{ item }">
							<div class="py-1">
								{{ item.userBlueTag }}
							</div>
						</template>
						<template v-slot:[`item.billableLineRole`]="{ item }">
							<div class="py-1">
								{{ item.billableLineRole || item.otherBillableLineRole }}
							</div>
						</template>
						<template v-slot:[`item.customerProject`]="{ item }">
							<div class="py-1">
								<div
									class="project"
									:style="{background: projectTypes[item.customerProjectType].lightColor}"
								>
									<div
										class="letter"
										:style="{background: projectTypes[item.customerProjectType].color}"
									>
										{{projectTypes[item.customerProjectType].small}}
									</div>
									<div class="name">{{item.customerProjectName}}</div>
								</div>
							</div>
						</template>
						<template v-slot:[`item.quantity`]="{ item }">
							<div class="py-1">
								{{item.quantity}}
							</div>
						</template>
						<template v-slot:[`item.isBillable`]="{ item }">
							<div class="py-1">
								<v-btn :color="item.isBillable ? 'green' : 'error'" icon><v-icon>mdi-currency-usd</v-icon></v-btn>
							</div>
						</template>
						<template v-slot:[`item.status`]="{ item }">
							<div class="py-1">
								<v-btn
									:color="item.status ? 'green' : 'error'"
									:style="{background: item.status ? '#e1f8dd' : '#f5d0d0'}"
									outlined small style="border-radius: 20px"
								>{{item.status ? 'Active' : 'Inactive'}}</v-btn>
							</div>
						</template>
						<template v-slot:[`item.startDate`]="{ item }">
							<div class="py-1">
								{{item.startDate | formatDate}}
							</div>
						</template>
						<template v-slot:[`item.endDate`]="{ item }">
							<div class="py-1">
								{{item.endDate | formatDate}}
							</div>
						</template>
						<template #[`item.months`]="{ item }">
							{{ item.months | number(2) }}
						</template>
						<template #[`item.weeks`]="{ item }">
							{{ item.weeks | number(2) }}
						</template>
					</v-data-table>
				</div>

				<div>
					<h2>Operational Projects</h2>
					<data-table
						:headers="operationalProjectHeaders"
						:items="billable.projects"
						disable-sort
						class="mt-5"
					>
						<template #[`item.activeBillable`]="{ item }">
							<v-icon v-if="item.active">mdi-check</v-icon>
							<v-icon v-else>mdi-close</v-icon>
						</template>
					</data-table>
				</div>
			</v-col>
			<v-col :cols="3">
				<h4>Customer</h4>
				<div class="customer">
					<v-avatar color="indigo" size="32">{{billable.customerName[0]}}</v-avatar>
					<span>{{billable.customerName}}</span>
				</div>
				
				<h4>Currency</h4>
				<div class="customer">
					<v-avatar color="indigo" size="32">
						<v-img :src="require(`../../assets/currencies/${billable.currency.toLowerCase()}.png`)"></v-img>
					</v-avatar>
					<span>{{billable.currency}}</span>
				</div>
				
				<h4>Type</h4>
				<div class="customer">
					<span>{{types.find(t => billable.type == t.value).text}}</span>
					<b v-if="billable.isOpen" class="primary--text ml-5 title">Is Open</b>
				</div>

				<div class="d-flex gap-10">
					<div>
						<h4>Start Date</h4>
						<div class="customer">
							<span v-if="billable.startDate">{{ billable.startDate | formatDate }}</span>
							<span v-else>-</span>
						</div>
					</div>
					<div v-if="false">
						<h4>End Date</h4>
						<div class="customer">
							<span v-if="billable.endDate">{{ billable.endDate | formatDate }}</span>
							<span v-else>-</span>
						</div>
					</div>
				</div>

				<h4>Calendars</h4>
				<div class="customer d-flex" style="gap: 5px">
					<v-chip v-for="(calendar, ix) in billable.calendars" :key="ix" :color="calendar.calendar.color" outlined>
						<div class="dot mr-1" :style="{ background: calendar.calendar.color }"></div>
						<span class="text--text body-2"> {{ calendar.calendar.name }} </span>
					</v-chip>
				</div>
				
				<h4>Credit Days</h4>
				<div class="customer">
					<span>{{billable.creditDays}} Days</span>
				</div>
				
				<h4>Notes</h4>
				<div class="customer">
					<p style="white-space: pre-line">{{billable.notes || '-'}}</p>
				</div>

				<v-divider class="mb-5"/>
				
				<div class="hours-cont">
					<div>
						<b>Contracted Hours</b>
						<b v-if="billable.contractedHours">{{billable.contractedHours | limitDecimals(2)}}</b>
						<b v-else>-</b>
					</div>
					<div class="success--text">
						<span class="subtitle-1">Billable Planned Hours</span>
						<span class="subtitle-1">{{billable.plannedBillableHours | limitDecimals(2)}}</span>
					</div>
					<div class="error--text">
						<span class="subtitle-1">Non Billable Planned Hours</span>
						<span class="subtitle-1">{{billable.plannedNonBillableHours | limitDecimals(2)}}</span>
					</div>
					<div v-if="!isFixedRate && !isExecuted" class="d-block">
						<billable-percentage-bar
							:primaryAmount="billable.plannedBillableHours"
							:secondaryAmount="billable.plannedNonBillableHours"
							:secondaryColor="!isBillable ? 'var(--v-success-base)' : 'var(--v-error-base)'"
							primaryTextColor="var(--v-success-base)"
							:objective="billable.isOpen ? billable.plannedBillableHours : billable.contractedHours"
							boldObjective :use-total-amount="!isBillable"
							:infinity="billable.isOpen"
						/>
					</div>
					<div v-else>-</div>
				</div>


				<v-divider class="my-5"/>

				<div class="hours-cont">
					<div class="success--text">
						<span class="subtitle-1">Billable Executed Hours</span>
						<span class="subtitle-1">{{billable.executedBillableHours | limitDecimals(2)}}</span>
					</div>
					<div class="error--text">
						<span class="subtitle-1">Non Billable Executed Hours</span>
						<span class="subtitle-1">{{billable.executedNonBillableHours | limitDecimals(2)}}</span>
					</div>
					<div v-if="!isFixedRate && !isExecuted" class="d-block">
						<billable-percentage-bar
							:primaryAmount="billable.executedBillableHours"
							:secondaryAmount="billable.executedNonBillableHours"
							:secondaryColor="!isBillable ? 'var(--v-success-base)' : 'var(--v-error-base)'"
							primaryTextColor="var(--v-success-base)"
							:objective="billable.plannedHours"
							stack boldObjective :use-total-amount="!isBillable"
						/>
					</div>
				</div>

				<v-divider class="my-5"/>

				<div class="hours-cont">
					<div>
						<b>Subtotal</b>
						<b v-if="!billable.isOpen">{{ currencyFormat(billable.subtotalWithDiscount) }}</b>
						<b v-else>-</b>
					</div>
					<div class="success--text">
						<span class="subtitle-1">Billed Amount</span>
						<span class="subtitle-1">{{ currencyFormat(billable.billed) }}</span>
					</div>
					<div v-if="!billable.isOpen" class="d-block">
						<billable-percentage-bar
							:primaryAmount="billable.billed"
							primaryTextColor="var(--v-success-base)"
							:objective="billable.subtotalWithDiscount"
							:amountFormatter="currencyFormat"
							boldObjective
						/>
					</div>
					<div v-else>-</div>
				</div>

				<template v-if="isFixedRate">
					<v-divider class="my-5"/>
	
					<v-card color="newDesignBackground">
						<v-card-title>Billable Schedule</v-card-title>
						<v-card-text>
							<v-row>
								<v-col>
									<label>Payments</label>
									<v-text-field
										v-model="billable.scheduledPaymentsQuantity"
										type="number"
										outlined hide-details dense readonly
									/>
								</v-col>
								<v-col>
									<label>Credit Days</label>
									<v-text-field
										v-model="billable.creditDays"
										type="number"
										outlined hide-details dense readonly
									/>
								</v-col>
							</v-row>
							<v-data-table
								:headers="paymentHeaders" :items="billable.scheduledPayments"
								hide-default-footer class="my-5 new-back"
							>
								<template #[`item.index`]="{ index }">
									{{index+1}}
								</template>
								<template #[`item.percentage`]="{ item }">
									{{item.amount}}%
								</template>
								<template #[`item.amount`]="{ item }">
									<v-menu open-on-hover left offset-x>
										<template #activator="{ on }">
											<div v-on="on">
												<span v-if="billable.paymentScheduleType === 2">{{ currencyFormat((item.amount/100)*billable.totalRate) }}</span>
												<span v-else>{{currencyFormat(item.amount)}}</span>
											</div>
										</template>
										<v-card>
											<v-card-text>
												<div
													class="totals"
													:set="amount =
														billable.paymentScheduleType != 2 ? item.amount :
														(item.amount/100)*billable.totalRate
													"
												>
													<div class="total"> <b>Subtotal:</b> {{currencyFormat(amount / (1+taxFactor))}} </div>
													<div class="total">
														<b>Tax:</b>
														{{billable.requireTax ? currencyFormat(amount*taxFactor / (1+taxFactor)) : '-'}}
													</div>
													<div class="total"> <b>Total:</b> {{currencyFormat(amount)}} </div>
												</div>
											</v-card-text>
										</v-card>
									</v-menu>
								</template>
								<template #[`item.date`]="{ item }">
									<span>{{ formatDate(item.date) }}</span>
								</template>
							</v-data-table>
	
							<div class="totals">
								<div class="total"> <b>Subtotal:</b> {{currencyFormat(billable.subtotalWithDiscount)}} </div>
								<div class="total pl-0">
									<b>Tax:</b>
									{{billable.requireTax ? currencyFormat(billable.taxAmount) : '-'}}
								</div>
								<div class="total"> <b>Total:</b> {{currencyFormat(billable.totalRate)}} </div>
							</div>
						</v-card-text>
					</v-card>
				</template>

			</v-col>
		</v-row>
	</div>
</template>

<script>
import moment from 'moment'
import BillableJSON from '../../json/billable.json'
import BillablePercentageBar from '../../components/admin-surf/billable/BillablePercentageBar'
import WatchersMenu from '../../components/admin-surf/WatchersMenu'

export default {
	props: ['id'],
	components: { BillablePercentageBar, WatchersMenu },
	data: () => ({
		customers: [],
		projects: [],
		billable: {
			customerProject: {name: ''},
			customerName: '',
			currency: 'USD',
			type: 0,
			changes: [],
		},
		assignments: [],
		effortSummary: {},
		durationUnits: [
			{ value: 3, hours: 1, text: 'Hours' },
			{ value: 0, hours: 8, text: 'Days' },
			{ value: 1, hours: 40, text: 'Weeks' },
			{ value: 2, hours: 168, text: 'Months' },
		],
		types: [
			{ text: 'Fixed Rate', value: 0, },
			{ text: 'Rate per Assigned Hour', value: 1, },
			{ text: 'Rate per Executed Hour', value: 2, },
			{ text: 'Internal', value: 3, },
		],
		effortSummaryHeaders: [
			{ text: 'Team Member', value: 'teamMember' },
			{ text: 'Executed billable', value: 'executedBillable', align: 'center' },
			{ text: 'Executed non-billable', value: 'executedNonBillable', align: 'center' },
			{ text: 'Total', value: 'totalExecuted', align: 'center' },
			{ text: 'Unclassified', value: 'unclassified', align: 'center' },
		],
		assignmentsHeaders: [
			{ text: "Bluetag", value: "user.blueTag" },
			{ text: "Role", value: "billableLineRole" },
			{ text: "Project", value: "customerProject" },
			{ text: "Quantity", value: "quantity" },
			{ text: "Billable", value: "isBillable", align: 'center' },
			{ text: "Status", value: "status", align: 'center' },
			{ text: "Start Date", value: "startDate" },
			{ text: "End Date", value: "endDate" },

			{ text: "Actual Working Hours", value: "actualWorkingHours" },
			{ text: "Total Working Hours", value: "totalWorkingHours" },
			{ text: "Months Base 168 hrs", value: "months" },
			{ text: "Weeks Base 168 hrs", value: "weeks" },
		],
		projectTypes: [],
		billableTypes: [
			{ value: true, text: 'Billable' },
			{ value: false, text: 'No Billable' },
		],
		paymentScheduleTypes: [
			{ text: 'equally', value: 0, },
			{ text: 'by exact amount', value: 1, },
			{ text: 'by percentages', value: 2, },
		],
		operationalProjectHeaders: [
			{ text: 'Small Code', 			value: 'projectSmallCode' },
			{ text: 'Name', 						value: 'projectName' },
			{ text: 'Project Status',		value: 'projectStatusName' },
			{ text: 'Effort Reporting', value: 'activeBillable', align: 'center' },
		],
		canEdit: false,
		currentUser: {},
	}),
	mounted(){
		this.projectTypes = BillableJSON.projectTypes

		this.$security.hasRequiredPermission('edit')
			.then(res => this.canEdit = res)

		this.init()
	},
	computed: {
		isFixedRate(){
			return this.billable?.type === 0
		},
		isExecuted(){
			return this.billable?.type === 2
		},
		isBillable(){
			return this.billable?.type !== 3
		},
		taxFactor(){
			return this.billable.requireTax ? .16 : 0
		},
		paymentHeaders(){
			let headers = [
      	{ text: "Payment", value: "index", sortable: false, width: 1 },
      	{ text: "%", value: "percentage", sortable: false, align: 'center', width: '90'},
      	{ text: "Amount", value: "amount", sortable: false },
      	{ text: "Date", value: "date", sortable: false },
			]

			if(this.billable.paymentScheduleType !== 2)
				headers = headers.filter((h,i) => i != 1)

			return headers
		},
		
		canApprove() {
			return !this.billable.isDraft && !this.billable.isApproved && !this.billable.isCancelled &&
				this.billable.approvers?.some(x =>
						x.approverId == this.currentUser.id &&
						x.statusToApprove === 1 &&
						x.status === 0
					)
		},
	},
	methods: {
		init(){
			this.$blueSystem.getCurrentUser()
				.then(user => {
					this.currentUser = user
				})
				.catch(error => this.$root.error('Failed to get user'))

			let billablePr = this.$http.get(`api/Billable/${this.id}`)
				.then(res => {
					this.billable = res.data
					this.$q.changeHeaderTitle(this.billable.code)
				})
			let assignmentsPr = this.$http.post('api/assignment/list', { billables: [this.id] })
				.then(res => {
					this.assignments = res.data.assignments
						.map(x => ({
							...x,
							status: moment().isBetween(x.startDate, x.endDate, 'day', '[]'),
						}))
				})
			this.$http.get("/api/customer?onlyWithCustomerProjects=true")
				.then(res => {
					this.customers = _.orderBy(res.data, ['name'])

					for(let customer of this.customers){
						for(let project of customer.customerProjects){
							if(!this.projects.some(p => p.id == project.id)){
								this.projects.push(project)
							}
						}
					}
					this.projects = _.orderBy(this.projects, ['name'])
				})
				.catch(res => {
					window.getApp.error("Cannot obtain customers.")
				})
			Promise.all([billablePr, assignmentsPr]).then(() => {
				if(this.isExecuted || this.isFixedRate){
					this.$http.get(`api/billable/${this.id}/effortSummary`, { headers: { hideLoading: true } })
						.then(res => {
							this.effortSummary = res.data
						})
						.catch(err => {
							console.error(err)
							this.$root.error('Cannot load effort summary')
						})
				}
			})
		},
		validateBillable(){
			this.$adminSurf('validateBillable', this.billable)
				.then(res => {
					this.billable = Object.assign(this.billable, res.data)
					this.$root.success('Billable marked as approved')
				})
				.catch(res => {
					console.log(res)
					this.$root.error('Cannot validate billable')
				})
		},
		cancelBillable(){
			this.$adminSurf('cancelBillable', this.id)
				.then(res => {
					if(res)
						this.$router.push('/admin/billable')
				})
				.catch(res => {
					console.log(res)
					this.$root.error('Cannot delete billable')
				})
		},
		formatCurrency(line){
			line.rate = line.rate + ''
			line.rate = line.rate.replace(/[^0-9\.]/g, '')
			line.rate = this.currencyFormat(line.rate)
		},
		rateUnit(durationUnit){
			if(this.billable.type === 0){
				return this.durationUnits.find(du => du.value == durationUnit).text
			}
			return 'hour'
		},
		getLineSubtotal(line) {
			if(this.billable.type === 0){
				return line.quantity * line.rate
			}
			return line.hours * line.ratePerHour
		},
		getUrl(url){
			if (!/^https?:\/\//i.test(url))
				url = 'https://' + url
			return url
		},
		openLink(url){
			window.open(this.getUrl(url), '_blank')
		},
	},
}
</script>

<style scoped lang="scss">
::v-deep .v-list-item{
	min-height: 0 !important;
	padding: 0;
	div{
		padding-left: 15px;
		width: 100%;
	}
}

.dot {
	display: inline-block;
	width: 10px; height: 10px;
	border-radius: 50%;
}

h4{
	margin-bottom: 10px;
	color: var(--v-text-lighten) !important;
}

.customer{
	display: flex;
	align-items: center;
	margin-bottom: 25px;
	.v-avatar{
		color: white;
		font-size: 18px;
		margin-right: 5px;
	}
	span{
		font-size: 18px;
		color: var(--v-text-base);
		font-weight: 600;
	}
	p{
		font-size: 18px;
		color: var(--v-text-base);
	}
}

.effort-indicators{
	display: flex;
	gap: 10px;
	> div{
		flex-grow: 1;
		display: flex;
		align-items: center;
		gap: 10px;
		border: 1px solid var(--v-lightgray-lighten);
		padding: 5px 10px;
		border-radius: 10px;
		background: var(--v-newDesignBackground-lighten2);

		.v-icon{
			border-radius: 50%;
			padding: 7px;
		}
		.subtitle{
			font-size: 14px;
			margin-top: -5px;
		}
	}
}

.hours-cont{
	display: flex;
	flex-direction: column;
	gap: 10px;

	> div {
		display: flex;
		justify-content: space-between;
		align-items: center;
	}
}
.project{
	display: inline-block;
	border-radius: 15px;
	margin-right: 5px;
	white-space:nowrap;
	div{
		display: inline-flex;
		align-items: center;
		justify-content: center;

	}
	.letter{
		width: 30px;
		padding: 5px;
		color: white;
		border-radius: 15px;
	}
	.name{
		padding: 0 10px 0 5px;
		color: black;
	}
}

.link{
	display: grid;
	align-items: center;
	grid-template-columns: 40px calc(100% - 60px);
	.link-text{
		white-space: nowrap;
		overflow: hidden;
		text-overflow: ellipsis;
	}
}

.split-selection{
	display: flex;
	gap: 5px;
	font-size: 16px;
	color: var(--v-text-base);

	label{
		margin-top: 3px;
	}
}
.totals{
	display: flex;
	flex-direction: column;
	font-size: 1.2em;
	align-items: flex-end;

	.total{
		min-width: 200px;
		display: flex;
		gap: 30px;
		justify-content: space-between;

		section{
			position: relative;
			.v-input{
				position: absolute;
				left: -32px;
			}
		}
	}
}
</style>