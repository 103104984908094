<template>
	<div class="pa-3 new-back full-height">

		<filter-search-input v-model="filters.search" :applied-filters="appliedFilters" outlined dense>
			<template #append>
				<v-btn v-if="permissions.add" to="supplier/new" color="primary" fab depressed small>
					<v-icon>mdi-plus</v-icon>
				</v-btn>
			</template>
			<q-filter-input
				v-model="filters.supplier" :items="suppliers"
				label="Supplier" item-value="id" item-text="name"
				dense outlined hide-details clearable input-select multiple hide-input
				:parent-shown-items="shownSuppliers"
				:data-comparer="(item, value) => item.id == value"
			/>
			<v-select
				v-model="filters.active" :items="activeOptions"
				label="Active" dense outlined hide-details clearable
			/>
		</filter-search-input>

		<data-table
			@click:row="row => permissions.detail ? this.$router.push('supplier/' + row.id) : null"
			:item-class="() => permissions.detail ? 'cursor-pointer' : ''"
			:headers="headers" :items="shownSuppliers"
			class="mt-2"
		>
			<template #[`item.active`]="{ item }">
				<v-icon class="ml-n3">
					mdi-{{ item.active ? 'check' : 'close' }}
				</v-icon>
			</template>
			<template #[`item.actions`]="{ item }">
				<v-menu v-if="permissions.actions" left offset-x>
					<template #activator="{ on }">
						<v-btn v-on="on" icon><v-icon>mdi-dots-vertical</v-icon></v-btn>
					</template>
					<v-list dense class="py-1">
						<v-list-item class="pa-1 py-0">
							<v-tooltip right z-index="200">
								<template #activator="{ on }">
									<v-btn v-on="on" @click="deleteSupplier(item)" color="error" text small>
										<v-icon>mdi-delete</v-icon>
									</v-btn>
								</template>
								<span class="caption">Delete</span>
							</v-tooltip>
						</v-list-item>
					</v-list>
				</v-menu>
			</template>
		</data-table>
	</div>
</template>

<script>
export default {
	data: () => ({
		filters: {
			search: '',
			supplier: [],
		},
		headers: [
			{ text: 'Supplier', value: 'name',  },
			{ text: 'Legal Name', value: 'businessName',  },
			{ text: 'Active', value: 'active', align: 'center' },
			{ text: '', value: 'actions', sortable: false, width: 0 },
		],
		suppliers: [],
		
    permissions: {
      detail: false,
      add: false,
      actions: false,
    },
		activeOptions: [
			{ text: 'Active', value: true },
			{ text: 'Inactive', value: false },
		],
	}),
	created(){
		document.title = 'Suppliers'
		this.$security.hasRequiredPermission('detail')
			.then(res => this.permissions.detail = res)
		this.$security.hasRequiredPermission('add')
			.then(res => this.permissions.add = res)
		this.$security.hasRequiredPermission('full_access')
			.then(res => this.permissions.actions = res)
		this.$q.log(4, 44, 3, 'NA')
		this.init()
	},
	computed: {
		shownSuppliers(){
			let suppliers = this.suppliers

			if(this.filters.supplier.length){
				suppliers = suppliers.filter(supplier => this.filters.supplier.includes(supplier.id))
			}
			if(this.filters.active !== null && this.filters.active !== undefined){
				suppliers = suppliers.filter(supplier => supplier.active == this.filters.active)
			}

			return suppliers
		},
    appliedFilters(){
			return this.countActiveFilters(this.filters, ['search'])
		},
	},
	methods: {
		init(){
			this.$http.get('api/supplier?includeInactive=true')
				.then(res => {
					this.suppliers = res.data
				})
				.catch(err => {
					console.error(err)
					this.$root.error('Failed to load suppliers')
				})
		},
		deleteSupplier(item){
			this.$openConfirmDialog({
				title: 'Delete Supplier',
				description: `Are you sure you want to delete <b>${item.name}</b>?`,
			})
				.then(res => {
					if(!res) return
					this.$http.delete(`api/supplier/${item.id}`)
						.then(() => {
							this.$root.success('Supplier deleted')
							this.init()
						})
						.catch(err => {
							console.error(err)
							this.$root.error('Failed to delete supplier')
						})
				})
		},
	},
}
</script>

<style lang="scss" scoped>
</style>