
export default {
	required: (value) => !!value || 'Required.',
	requiredNumber: (value) => !!value || value === 0 || 'Required.',
	requiredArray: (value) => (!!value && value.length > 0) || 'Required.',
	notNegative: (value) => value >= 0 || 'Must be positive.',
	email: (value) => {
		const pattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/
		return pattern.test(value) || 'Invalid e-mail.'
	},
}