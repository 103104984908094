var render = function () {
var this$1 = this;
var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"new-back full-height py-5 px-5"},[_c('div',{staticClass:"d-inline-flex align-center gap-10"},[_c('v-text-field',{staticClass:"d-inline-block",attrs:{"prepend-inner-icon":"search","label":"Search by bluetag, description, etc","single-line":"","outlined":"","dense":"","hide-details":"","clearable":""},scopedSlots:_vm._u([{key:"append",fn:function(){return [_c('v-badge',{attrs:{"offset-x":"12","offset-y":"13","color":"red","value":!!_vm.appliedFilters,"content":_vm.appliedFilters,"right":"","small":""}},[_c('v-icon',{staticClass:"mr-2",attrs:{"color":_vm.showFilters ? 'primary' : _vm.$vuetify.theme.isDark ? 'white' : ''},on:{"mouseup":function($event){$event.stopPropagation();},"click":function($event){$event.stopPropagation();_vm.showFilters = !_vm.showFilters}}},[_vm._v(" mdi-tune ")])],1)]},proxy:true}]),model:{value:(_vm.filters.search),callback:function ($$v) {_vm.$set(_vm.filters, "search", $$v)},expression:"filters.search"}}),_c('div',{staticClass:"d-inline-flex align-center gap-2"},[_c('label',[_vm._v("Billable Status:")]),_vm._l((_vm.billableColors),function(color,ix){return _c('v-btn',{key:ix,style:((_vm.$vuetify.theme.isDark ? 'border: 2px solid ' : 'background: ') + color.lightColor),attrs:{"color":color.color,"icon":"","small":"","disabled":!_vm.selectedEfforts.length},on:{"click":function($event){return _vm.applyIsBillable(color)}}},[_c('v-icon',[_vm._v("mdi-currency-usd")])],1)}),_c('div',{staticClass:"d-flex align-center ml-10",staticStyle:{"gap":"10px"}},[_c('label',[_vm._v("Service:")]),_c('v-menu',{scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"color":"secondary","small":"","disabled":!_vm.selectedEfforts.length}},on),[_vm._v(" Select service ")])]}}])},[_c('v-list',_vm._l((_vm.billable.lines),function(line){return _c('v-list-item',{key:line.id,on:{"click":function($event){return _vm.applyRole(line.id)}}},[_vm._v(" "+_vm._s(line.role)+" ")])}),1)],1)],1),_c('div',{staticClass:"d-flex align-center ml-10 hide-input",staticStyle:{"gap":"10px","min-width":"300px"}},[_c('label',[_vm._v("Billable:")]),_c('v-menu',{scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"color":"secondary","small":"","disabled":!_vm.selectedEfforts.length}},on),[_vm._v(" Select billable ")])]}}])},[_c('v-list',_vm._l(([{ id: null, code: 'NA' }, { id: _vm.billable.id, code: _vm.billable.code }]),function(billable){return _c('v-list-item',{key:billable.id,on:{"click":function($event){return _vm.applyBillable(billable.id)}}},[_vm._v(" "+_vm._s(billable.code)+" ")])}),1)],1)],1)],2),_c('div')],1),_c('v-expand-transition',[_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.showFilters),expression:"showFilters"}],staticClass:"mt-5"},[_c('div',{staticClass:"d-flex",staticStyle:{"gap":"10px"}},[_c('q-date-picker',{attrs:{"label":"Start date","outlined":"","inner":"","hide-details":"","dense":"","clearable":""},model:{value:(_vm.filters.startDate),callback:function ($$v) {_vm.$set(_vm.filters, "startDate", $$v)},expression:"filters.startDate"}}),_c('q-date-picker',{attrs:{"label":"End date","outlined":"","inner":"","hide-details":"","dense":"","clearable":""},model:{value:(_vm.filters.endDate),callback:function ($$v) {_vm.$set(_vm.filters, "endDate", $$v)},expression:"filters.endDate"}}),_c('q-filter-input',{attrs:{"items":_vm.shownUsers,"parent-shown-items":"shownEfforts","label":"Bluetag","item-text":"blueTag","item-value":"id","dense":"","outlined":"","hide-details":"","clearable":"","multiple":"","data-comparer":function (item, value) { return item.reporterId == value; }},model:{value:(_vm.filters.reporterId),callback:function ($$v) {_vm.$set(_vm.filters, "reporterId", $$v)},expression:"filters.reporterId"}}),_c('q-filter-input',{attrs:{"items":_vm.isBillableOpts,"parent-shown-items":"shownEfforts","label":"Type","dense":"","outlined":"","hide-details":"","clearable":"","data-comparer":function (item, value) { return this$1.isBillableOpts[value].isBillable == item.isBillable; }},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{staticClass:"mr-2",style:((_vm.$vuetify.theme.isDark ? 'border: 2px solid ' : 'background: ') + item.lightColor),attrs:{"color":item.color,"icon":"","small":""}},[_c('v-icon',[_vm._v("mdi-currency-usd")])],1),_vm._v(" "+_vm._s(item.text)+" ")]}},{key:"selection",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{staticClass:"mr-2",style:((_vm.$vuetify.theme.isDark ? 'border: 2px solid ' : 'background: ') + item.lightColor),attrs:{"color":item.color,"icon":"","small":""}},[_c('v-icon',[_vm._v("mdi-currency-usd")])],1),_vm._v(" "+_vm._s(item.text)+" ")]}}]),model:{value:(_vm.filters.isBillable),callback:function ($$v) {_vm.$set(_vm.filters, "isBillable", $$v)},expression:"filters.isBillable"}}),_c('v-text-field',{attrs:{"label":"Executed hours","dense":"","outlined":"","hide-details":"","clearable":""},on:{"keypress":_vm.hourValidation},model:{value:(_vm.filters.executedHours),callback:function ($$v) {_vm.$set(_vm.filters, "executedHours", $$v)},expression:"filters.executedHours"}}),_c('v-checkbox',{attrs:{"label":"Include efforts without billable","dense":"","hide-details":""},model:{value:(_vm.filters.includeEffortsWithoutBillable),callback:function ($$v) {_vm.$set(_vm.filters, "includeEffortsWithoutBillable", $$v)},expression:"filters.includeEffortsWithoutBillable"}})],1)])]),_c('v-data-table',{staticClass:"bordered rounded-lg new-back lighten2 mt-5",attrs:{"show-select":"","checkbox-color":"primary","headers":_vm.headers,"items":_vm.shownEfforts},scopedSlots:_vm._u([{key:"item.isBillable",fn:function(ref){
var effort = ref.item;
return [_c('v-btn',{style:((_vm.$vuetify.theme.isDark ? 'border: 2px solid ' : 'background: ') + _vm.billableColors[effort.isBillable].lightColor),attrs:{"color":_vm.billableColors[effort.isBillable].color,"icon":"","small":""},on:{"click":function($event){return _vm.setNextIsBillable(effort)}}},[_c('v-icon',[_vm._v("mdi-currency-usd")])],1)]}},{key:"item.reporter",fn:function(ref){
var effort = ref.item;
return [_vm._v(" "+_vm._s(effort.reporterBlueTag || effort.reporterFullName)+" ")]}},{key:"item.billableLineId",fn:function(ref){
var effort = ref.item;
return [_c('v-select',{attrs:{"items":_vm.billable.lines,"outlined":"","hide-details":"","dense":"","clearable":"","disabled":!!effort.billableItemId,"item-value":"id","item-text":"role"},on:{"change":function($event){return _vm.billableLineChanged(effort, $event)}},model:{value:(effort.billableLineId),callback:function ($$v) {_vm.$set(effort, "billableLineId", $$v)},expression:"effort.billableLineId"}})]}},{key:"item.billable",fn:function(ref){
var effort = ref.item;
return [_c('v-select',{attrs:{"items":[{ id: null, code: 'NA' }, { id: _vm.billable.id, code: _vm.billable.code }],"outlined":"","hide-details":"","dense":"","disabled":!!effort.billableItemId,"item-value":"id","item-text":"code"},on:{"change":function($event){return _vm.billableChanged(effort, $event)}},model:{value:(effort.billableId),callback:function ($$v) {_vm.$set(effort, "billableId", $$v)},expression:"effort.billableId"}})]}},{key:"item.date",fn:function(ref){
var effort = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("formatDateCustom")(effort.date,'(ddd) MMM D, YYYY'))+" ")]}},{key:"item.executed",fn:function(ref){
var effort = ref.item;
return [_vm._v(" "+_vm._s(effort.executedHrs)+" : "+_vm._s(effort.executedMins)+" ")]}},{key:"item.billed",fn:function(ref){
var effort = ref.item;
return [(effort.billableItemId)?_c('v-icon',{staticClass:"ml-n3",attrs:{"color":"success"}},[_vm._v("mdi-check")]):_c('v-icon',{staticClass:"ml-n3",attrs:{"color":"grey"}},[_vm._v("mdi-close")])]}}],null,true),model:{value:(_vm.selectedEfforts),callback:function ($$v) {_vm.selectedEfforts=$$v},expression:"selectedEfforts"}}),_c('v-btn',{staticClass:"float-right mt-5",attrs:{"color":"primary"},on:{"click":_vm.save}},[_vm._v("Accept")])],1)}
var staticRenderFns = []

export { render, staticRenderFns }