<template>
	<v-data-table
		class="bordered rounded-lg" :class="{ 'new-back lighten2': newBack }"
		hide-default-footer
		disable-pagination
		disable-sort
		show-expand
		:headers="headers"
		:items="item.lines"
		:expanded.sync="expanded"
		item-key="billableLineId"
	>
		<template #[`item.seat`]="{ item }">
			<b>{{ item.billableLineRole }}</b>
		</template>
		<template #[`item.rate`]="{ item }">
			${{ item.rate | money }}
		</template>
		<template #[`item.quantity`]="{ item }">
			{{ item.quantity | number(2) }}
		</template>
		<template #[`item.total`]="{ item }">
			${{ item.total | money }}
		</template>

		<template #expanded-item="{ headers: lineHeaders, item: line }">
			<td :colspan="lineHeaders.length" class="text-center">
				<v-data-table
					v-model="line.selectedMembers"
					@input="computeTotals(line)"
					:items="line.members" :headers="assignmentHeaders"
					hide-default-footer disable-pagination disable-sort
					:show-select="!!editing"
					class="bordered mx-5 my-3 rounded-lg overflow-hidden"
					:class="{ 'new-back lighten2': newBack }"
				>
					<template #[`item.teamMember`]="{ item }">
						<b :class="userDisplayName === 'BlueTag' ? 'subtitle-1' : 'caption'">{{ item['user' + userDisplayName] }}</b>
					</template>
					<template #[`item.startDate`]="{ item }">
						{{ item.startDate | formatDate}}
					</template>
					<template #[`item.endDate`]="{ item }">
						{{ item.endDate | formatDate}}
					</template>
					<template #[`item.hours`]="{ item }">
						<span v-if="item.hours">{{ item.hours | number(2) }}</span>
						<span v-else>-</span>
					</template>
					<template #[`item.total`]="{ item }">
						{{ item.hours ? currencyFormat(item.hours * line.rate) : '-' }}
					</template>
				</v-data-table>
			</td>
		</template>
		<template #foot>
			<td
				:colspan="headers.length-2"
				style="border-top: 1px solid var(--v-lightgray-lighten)"
			/>
			<td class="px-4 py-2" style="border-top: 1px solid var(--v-lightgray-lighten)">
				<b>Totals</b>
			</td>
			<td class="px-4" style="border-top: 1px solid var(--v-lightgray-lighten)">
				{{ item.hours | number(2) }}
			</td>
			<td class="px-4" style="border-top: 1px solid var(--v-lightgray-lighten)">
				${{ item.total | money }}
			</td>
		</template>
	</v-data-table>
</template>

<script>
export default {
	props: {
		item: Object,
		editing: Boolean,
		expandedByDefault: Boolean,
		userDisplayName: { type: String, default: 'BlueTag' },
		newBack: { type: Boolean, default: true },
	},
	data: () => ({
		assignmentHeaders: [
			{ text: 'Team Member', value: 'teamMember' },
			{ text: 'Start Date', value: 'startDate' },
			{ text: 'End Date', value: 'endDate' },
			{ text: 'Hours', value: 'hours' },
			{ text: 'Amount', value: 'total' },
		],
		expanded: [],
	}),
	computed: {
		headers(){
			return [
				{ text: 'Seat', value: 'seat' },
				{ text: this.isFixedRate ? 'Unitary rate' : 'Rate per hour', value: 'rate' },
				{ text: this.isFixedRate ? 'Quantity' : 'Hours', value: 'quantity' },
				{ text: 'Total', value: 'total' },
			]
		},
		isFixedRate(){
			return this.item.billableType === 0
		},
	},
	mounted(){
		if(this.expandedByDefault)
			this.expanded = this.item.lines
	},
	watch: {
		item(){
			if(this.expandedByDefault)
				this.expanded = this.item.lines
		},
	},
	methods: {
		computeTotals(billableLine){
			this.$nextTick(() => {
				let hours = billableLine.selectedMembers
					.reduce((acc, line) => acc + line.hours, 0)
				let total = billableLine.selectedMembers
					.reduce((acc, line) => acc + line.total, 0)
				this.$set(billableLine, 'quantity', hours)
				this.$set(billableLine, 'total', total)

				hours = this.item.lines
					.reduce((acc, line) => acc + line.quantity, 0)
				total = this.item.lines
					.reduce((acc, line) => acc + line.total, 0)
				this.$set(this.item, 'hours', hours)
				this.$set(this.item, 'total', total)
			})
		},
	},
}
</script>

<style lang="scss" scoped>

::v-deep .v-data-table__expanded__content{
  box-shadow: none !important;
}
</style>