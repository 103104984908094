<template>

  <v-app class="login-app pa-0" >
    <v-content class="pa-0">
      <v-row class="main-container logo-background" :style="{ backgroundImage: 'url(' + require('@/assets/new-logo-surf.svg') + ')' }">
        <v-col :cols="6" class="form-container py-0">
          <form @submit.prevent="login" v-if="!forgotPassword && !resetPassword">
            <v-card 
              class="pa-8 login-card"
              outlined
              style="background: transparent"
              >
              <div >
                <v-img
                  max-width="180"
                  :src="$vuetify.theme.dark ? '/blue-surf-logo-white.svg' : '/blue-surf-logo.png'"
                  style="margin-right: 1rem; margin-bottom: 12px;"
                  href="/landing" 
                >
                </v-img>
              </div>
              <p style="font-size: 16px !important; font-weight:200 !important; ">Powered by Blue People</p>
              <div style="font-size: 1.0rem; padding-bottom: 20px;" class="mt-5">
                <span style="font-size: 1.5rem;">Sign in</span> <br>
                <span style="font-weight: 400 !important">Enter your credentials below</span>
              </div>
              <v-card-text class="px-0">
                  <v-row dense>
                    <v-col class="col-login">
                      <span style="font-size: 16px">Email or username</span>
                      <v-text-field
                        v-model="model.email"
                        :rules="[rules.required]"
                        :validate-on-blur="false"
                        name="email"
                        placeholder="Enter email"
                        outlined
                        ></v-text-field>
                    </v-col>
                  </v-row>
                  <v-row dense no-gutters>
                    <v-col class="col-login">
                      <span style="font-size: 16px">Password</span>
                      <a @click="forgotPassword = true" style="font-weight: 400; float: right;">Forgot password?</a>
                      <v-text-field
                        v-model="model.password"
                        :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
                        :rules="[rules.required]"
                        :type="showPassword ? 'text' : 'password'"
                        id="password"
                        placeholder="Enter password"
                        name="password"
                        outlined
                        @click:append="showPassword = !showPassword"
                        class="pb-0 my-0"
                        ></v-text-field>
                      <v-row dense no-gutters>
                      </v-row>
                    </v-col>
                  </v-row>
                  <v-alert :value="!!loginMessage" type="error">
                    {{loginMessage}}
                  </v-alert>
              </v-card-text>
              <v-card-actions class="px-0">
                <v-row dense justify="center">
                  <v-col class="col-login">
                    <v-btn 
                    block
                    large 
                    type="submit"
                    color="#328FF8">
                      <span style="color:white;">
                        Sign in
                      </span>
                    </v-btn>
                  </v-col>
                </v-row>
              </v-card-actions>
            </v-card>
          </form>

          <form @submit.prevent="sendRecovery" v-if="forgotPassword">
            <v-card
              outlined
              width="420"
              class="pa-4 login-card"
            >
              <v-card-text class="login-title pt-4">
                {{
                  recoverySended ? 
                    'Thank you! Please check your email' :
                    'Enter your email for recovery'
                }}
              </v-card-text>
              <v-card-text>
                  <v-row dense>
                    <v-col class="col-login">
                      <span>Email or username</span>
                      <v-text-field
                        :disabled="recoverySended"
                        v-model="model.email"
                        :validate-on-blur="false"
                        name="email"
                        placeholder="Enter email"
                        outlined
                      >
                      <v-icon slot="append" color="green" v-if="recoverySended">
                        mdi-check-all
                      </v-icon>
                      </v-text-field>
                    </v-col>
                  </v-row>
                  <v-alert :value="!!loginMessage" type="error">
                    {{loginMessage}}
                  </v-alert>
              </v-card-text>
              <v-card-actions>
                <v-row dense justify="center" v-if="recoverySended">
                  <v-col class="col-login">
                    <v-btn
                      block
                      large
                      @click="goBack"
                      color="#328FF8"
                    >
                      <span style="color:white;">
                        Go Back
                      </span>
                    </v-btn>
                  </v-col>
                </v-row>
                <v-row  v-else>
                  <v-col class="col-login" cols="6">
                    <v-btn
                      block
                      large
                      @click="goBack"
                      color="white"
                    >
                      <span style="color:#328FF8;">
                        Go Back
                      </span>
                    </v-btn>
                  </v-col>
                  <v-col class="col-login">
                    <v-btn
                      block
                      large
                      color="#328FF8"
                      type="submit"
                    >
                      <span style="color:white;">
                        Send
                      </span>
                    </v-btn>
                  </v-col>
                </v-row>
              </v-card-actions>
            </v-card>
          </form>

          <form @submit.prevent="changePassword" v-if="resetPassword">
            <v-card class="elevation-12 pa-4 login-card" v-if="!passwordChanged">
              <v-card-text v-if="settingPassword" class="login-title pt-4">Please create your password</v-card-text>
              <v-card-text v-else class="login-title pt-4">Please write your new password</v-card-text>
              <v-card-text>
                  <v-row dense no-gutters>
                    <v-col class="col-login">
                      <span>Password</span>
                      <v-text-field
                        v-model="model.password"
                        type="password"
                        id="password"
                        placeholder="Enter password"
                        name="password"
                        outlined
                        hide-details
                        class="pb-0 my-0"
                        ></v-text-field>
                    </v-col>
                  </v-row>
                  <v-row dense no-gutters>
                    <v-col class="col-login">
                      <span>Password</span>
                      <v-text-field
                        v-model="model.confirmPassword"
                        type="password"
                        id="password"
                        placeholder="Enter password"
                        name="password"
                        outlined
                        class="pb-0 my-0"
                        ></v-text-field>
                    </v-col>
                  </v-row>
                  <v-alert :value="!!loginMessage" type="error">
                    {{loginMessage}}
                  </v-alert>
              </v-card-text>
              <v-card-actions>
                <v-row>
                  <v-col class="col-login" cols="4">
                    <v-btn
                      block
                      large
                      @click="goLogin"
                      color="white"
                    >
                      <span style="color:#328FF8;">
                        Go Login
                      </span>
                    </v-btn>
                  </v-col>
                  <v-col class="col-login" cols="8">
                    <v-btn
                      block
                      large
                      color="#328FF8"
                      type="submit"
                    >
                      <span style="color:white;">
                        {{
                          settingPassword ?
                            'Save Password':
                            'Change Password'
                        }}
                      </span>
                    </v-btn>
                  </v-col>
                </v-row>
              </v-card-actions>
            </v-card>

            <v-card class="elevation-12 pa-4 login-card" v-else>
              <v-card-text class="login-title pt-12" style="text-align:center; margin-bottom: 30px;">
                <v-icon size="50" style="margin-bottom: 10px">mdi-lock-reset</v-icon>
                <br>
                Password reseted successfully
              </v-card-text>
              <v-card-actions style="justify-content:center">
                <v-row  dense justify="center">
                  <v-col class="col-login">
                    <v-btn
                      block
                      large
                      width="120"
                      @click="goLogin"
                      color="#328FF8"
                    >
                      <span style="color:white;">
                        Go Login
                      </span>
                    </v-btn>
                  </v-col>
                </v-row>
              </v-card-actions>
            </v-card> 
          </form>
        </v-col>
      </v-row>
    </v-content>
    <v-snackbar v-model="snackbar" :color="snackbarColor" :bottom="true" :right="true" :timeout="5000">
      {{ snackText }}
      <v-btn text @click="snackbar = false">
        Close
      </v-btn>
    </v-snackbar>
  </v-app>
</template>

<script>
export default {
  title: 'BlueSurf',
  mounted() {
    this.setUserPreferences("AppMenu", "darkTheme")
    try {
      const cookies = JSON.parse(this.$q.getCookie("AppMenu"))
      this.$vuetify.theme.dark  = cookies.darkTheme
    } catch {
      console.error('The theme cookie is not defined')
    }
  },
  data() {
    return {
      rules: {
        required: (value) => !!value || 'Required'
      },
      title: "BlueSurf",
      loginMessage: "",
      apiurl:this.$http.defaults.baseURL,
      model: {},
      snackbar: false,
      snackText: "",
      snackbarColor: "info",
      version: "0.0.0",
      showPassword: false,
      forgotPassword: false,
      recoverySended: false,
      resetPassword: false,
      passwordChanged: false,
      darkTheme: false,
    };
  },
  methods: {
    login() {
      this.$http
        .post("api/login", { email: this.model.email, password: this.model.password })
          .then((result) => {
            window.getApp.dueReports = 0
            if (result.data === 'Success') {
              let redirect = this.$route.query?.redirect
              if(redirect && redirect.includes('login'))
                redirect = null
              this.$router.push(redirect || '/profile/me');
              this.$blueSystem.checkConfetti()
            } 
            else if(result.data === 'Client'){
              this.$router.push('/external/project');
            }
            else {
              this.error(result.data)
            }
          }).catch(res => {
            console.log(res)
            this.error("Cannot login.")
          })
    },
    error(message) {
      this.snackbar = true
      this.snackText = message
      this.snackbarColor = "error"
      console.log(`error: ${message}`)
    },
    goBack(){
      this.forgotPassword = false;
      this.recoverySended = false;
      this.passwordChanged= false;
      this.resetPassword  = false;
      this.model.password = '';
    },
    goLogin(){
      this.$router.push('/login');
      document.title = 'BlueSurf'
      this.goBack();
    },
    sendRecovery(){
      var form = new FormData();
      form.append('email', this.model.email);
      form.append('recoveryUrl', window.location.origin);
      this.$http
        .post("api/passwordRecovery", form).then((result) => {
          if (result.data === 'Success') {
            this.snackbar = true
            this.snackText = 'Send it successfully'
            this.snackbarColor = "success"
            this.recoverySended = true;
          } else {
            this.error(result.data)
          }
        }).catch(res => {
          console.log(res)
          this.error("Unable to retrieve password.")
        })
    },
    changePassword(){
      var form = new FormData();
      let apiUrl = 'api/resetPassword'
      form.append('password', this.model.password);
      form.append('confirmPassword', this.model.confirmPassword);
      if(this.settingPassword){
        apiUrl = 'api/setPassword'
        form.append('recoveryCode', this.$route.query.creationCode);
      } else {
        form.append('recoveryCode', this.$route.query.recoveryCode);
      }
      this.$http
        .post(apiUrl, form).then((result) => {
          if (result.data === 'Success') {
            this.snackbar = true
            this.snackText = 'Password updated successfully'
            this.snackbarColor = "success"
            this.passwordChanged = true;
          } else {
            this.error(result.data)
          }
        }).catch(res => {
          console.error(res)
          this.error("Unable to reset password.")
        })
    }
  },
  created() {
    this.resetPassword = this.$router.history.current.path === '/resetPassword' || this.$router.history.current.path === '/setPassword';
    this.settingPassword = this.$router.history.current.path === '/setPassword'

    if(this.resetPassword)
      document.title = 'Password Recovery'
    if(this.settingPassword)
      document.title = 'Password Creation'

    this.$blueSystem.getVersion()
      .then(version => this.version = version)

    if (typeof LOGIN_MESSAGE !== 'undefined') {
      this.loginMessage = LOGIN_MESSAGE;
    }
  }
};
</script>

<style scoped lang="scss">
.login-title {
  font-size: 1.4em;
}
.login-card {
  border-radius: 12px;
  opacity: 100;
  transition: opacity 1.2s;
  min-width: 480px;
  max-width: 530px;
}
.main-container {
  height: 101.2%;
  max-width: 100vw;
  background-color: var(--v-newDesignBackground-base) !important;
}
.form-container {
  display: flex;
  align-items: center;
  justify-content: center;
}
.logo-background {
  background-size: 45%;
  background-position: 95% 220px;
  background-repeat: no-repeat;
}
.col-login{
  padding-bottom: 0px !important;
  max-height: 120px !important;

  span{
    font-weight: 400;
    color:darkgrey;
  }
  .v-text-field{
    border-radius: 10px;
    margin: 10px 0px 10px 0px !important;
    .v-text-field_slot{
      border: 1px solid rgba(31, 31, 31, 0.2) !important;
    }
  }
  .v-btn{
    border-radius: 15px !important;
  }
}
</style>