
<template>
  <div class="pa-3 new-back fill-height">
    <div class="d-flex flex-wrap filters mb-3" style="gap: 25px 10px">
      <q-date-picker label="From" v-model="filters.dateStart" outlined dense hide-details inner/>
      <q-date-picker label="To" v-model="filters.dateEnd" outlined dense hide-details inner/>
      <div style="position: relative">
        <v-autocomplete label="Project" :items="shownProjects"
          item-value="id" item-text="name"
          :filter="(item, queryText, itemText) => normalizeIncludes(item.name + ' ' + item.smallCode, queryText)"
          v-model="filters.projectId" multiple outlined dense hide-details
        >
          <template v-slot:item="{ item, attrs }">
            <v-list-item-action>
              <v-checkbox v-model="attrs.inputValue"></v-checkbox>
            </v-list-item-action>
            <v-list-item-content class="pa-0">
              <v-list-item class="pa-0">
                <v-list-item-content>
                  <v-list-item-title>{{ item.name }}</v-list-item-title>
                  <v-list-item-subtitle>{{ item.smallCode }}</v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
            </v-list-item-content>
          </template>
          <template v-slot:selection="{ attrs, item, parent, selected }">
            <v-chip v-if="item === Object(item)"
              v-bind="attrs" class="accent--lighten" :input-value="selected" label small>
              <span class="pr-2">
                {{ item.name }}
              </span>
              <v-icon small @click="parent.selectItem(item)">close</v-icon>
            </v-chip>
          </template>
        </v-autocomplete>
        <div
          @click="setOnlyActive(!filters.onlyActive)"
          class="text-right cursor-pointer noselect"
          :style="{color: filters.onlyActive ? '#167bd7' : '#aaa'}"
          style="position: absolute; right: 0"
        >
          Only Active
        </div>
      </div>
      <v-select 
        @click.native.stop="chooseTask"
        readonly="" 
        label="Task" 
        class="cursor-pointer" 
        append-icon="arrow_drop_down" 
        :items="[0]" :value="0" 
        :disabled="filters.projectId.length != 1"
        outlined dense hide-details
      >
        <template v-slot:selection="{ item, index }">
          <v-icon small v-if="filters.taskId" @click.stop="filters.taskId = null">clear</v-icon>
          {{filters.taskId ? chosenTask.name : 'All'}}
        </template>
      </v-select>
      <v-autocomplete
        v-model="filters.reporterId"
        label="Reporter" :items="users" 
        item-value="id" item-text="fullName" 
        :filter="(item, queryText, itemText) => normalizeIncludes(item.blueTag + ' ' + item.fullName, queryText)"
        multiple outlined dense hide-details
      >

        <template v-slot:item="{ item, attrs }">
          <v-list-item-action>
            <v-checkbox v-model="attrs.inputValue"></v-checkbox>
          </v-list-item-action>
          <v-list-item-content class="pa-0">
            <v-list-item class="pa-0">
              <v-list-item-avatar color="indigo" class="headline font-weight-light white--text">
                {{ (item.blueTag || '-').charAt(0) }}
              </v-list-item-avatar>
              <v-list-item-content>
                <v-list-item-title v-text="item.blueTag"></v-list-item-title>
                <v-list-item-subtitle v-text="item.fullName"></v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
          </v-list-item-content>
        </template>
        <template v-slot:selection="{ attrs, item, parent, selected }">
          <v-chip v-if="item === Object(item)"
            v-bind="attrs" class="accent--lighten" :input-value="selected" label small>
            <span class="pr-2">
              {{ item.blueTag }}
            </span>
            <v-icon small @click="parent.selectItem(item)">close</v-icon>
          </v-chip>
        </template>
      </v-autocomplete>
      <v-select
        v-model="filters.linked" :items="linkedItems"
        label="Linked" item-text="name" item-value="id"
        outlined dense hide-details
      />
      <v-autocomplete
        v-model="filters.workItemRelatedProjectId" :items="shownProjects"
        label="Related Project" item-text="name" item-value="id"
        outlined dense hide-details multiple
      >
        <template v-slot:item="{ item, attrs }">
          <v-list-item-action>
            <v-checkbox v-model="attrs.inputValue"></v-checkbox>
          </v-list-item-action>
          <v-list-item-content class="pa-0">
            <v-list-item class="pa-0">
              <v-list-item-content>
                <v-list-item-title>{{ item.name }}</v-list-item-title>
                <v-list-item-subtitle>{{ item.smallCode }}</v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
          </v-list-item-content>
        </template>
        <template v-slot:selection="{ attrs, item, parent, selected }">
          <v-chip v-if="item === Object(item)"
            v-bind="attrs" class="accent--lighten" :input-value="selected" label small>
            <span class="pr-2">
              {{ item.name }}
            </span>
            <v-icon small @click="parent.selectItem(item)">close</v-icon>
          </v-chip>
        </template>
      </v-autocomplete>
      <v-text-field
        v-model="filters.linkedWorkItemCode"
        label="Related Work Item"
        outlined dense hide-details
      />
      <v-checkbox
        v-model="filters.includeEffectiveness" label="Show Effective"
        class="mt-0" hide-details
      />
      <v-checkbox
        v-model="filters.includeNoProjectLinked" label="Include activities without project"
        class="mt-0" hide-details
      />
    </div>
    
    <v-btn class="mb-5" rounded color="primary" @click="updateReport()">
      Update
    </v-btn>

    <div class="d-flex justify-space-between align-end mb-3">
      <v-menu v-model="menu" :close-on-content-click="false" bottom offset-y>
        <template v-slot:activator="{ on }">
          <v-btn color="secondary" rounded small v-on="on">
            View...
          </v-btn>
        </template>

        <v-card>
          <v-list dense>
            <v-list-item><v-list-item-action><v-checkbox dense v-model="vo.showCol['reporterBlueTag']" color="primary"></v-checkbox></v-list-item-action><v-list-item-title> Reporter Blue Tag</v-list-item-title></v-list-item>
            <v-list-item><v-list-item-action><v-checkbox dense v-model="vo.showCol['reporterFullName']" color="primary"></v-checkbox></v-list-item-action><v-list-item-title> Reporter</v-list-item-title></v-list-item>
            <v-list-item><v-list-item-action><v-checkbox dense v-model="vo.showCol['date']" color="primary"></v-checkbox></v-list-item-action><v-list-item-title> Date</v-list-item-title></v-list-item>
            <v-list-item><v-list-item-action><v-checkbox dense v-model="vo.showCol['week']" color="primary"></v-checkbox></v-list-item-action><v-list-item-title> Week</v-list-item-title></v-list-item>
            <v-list-item><v-list-item-action><v-checkbox dense v-model="vo.showCol['projectName']" color="primary"></v-checkbox></v-list-item-action><v-list-item-title> Project</v-list-item-title></v-list-item>
            <v-list-item><v-list-item-action><v-checkbox dense v-model="vo.showCol['projectTypeName']" color="primary"></v-checkbox></v-list-item-action><v-list-item-title> Project Type</v-list-item-title></v-list-item>
            <v-list-item><v-list-item-action><v-checkbox dense v-model="vo.showCol['projectCustomerProduct']" color="primary"></v-checkbox></v-list-item-action><v-list-item-title> Project Customer</v-list-item-title></v-list-item>
            <v-list-item><v-list-item-action><v-checkbox dense v-model="vo.showCol['linkedItem']" color="primary"></v-checkbox></v-list-item-action><v-list-item-title> Linked</v-list-item-title></v-list-item>
            <v-list-item><v-list-item-action><v-checkbox dense v-model="vo.showCol['linkedItem.name']" color="primary"></v-checkbox></v-list-item-action><v-list-item-title> Task</v-list-item-title></v-list-item>
            <v-list-item><v-list-item-action><v-checkbox dense v-model="vo.showCol['description']" color="primary"></v-checkbox></v-list-item-action><v-list-item-title> Description</v-list-item-title></v-list-item>
            <v-list-item><v-list-item-action><v-checkbox dense v-model="vo.showCol['workItemLinkedWorkItems']" color="primary"></v-checkbox></v-list-item-action><v-list-item-title> Related Work Items</v-list-item-title></v-list-item>
            <v-list-item><v-list-item-action><v-checkbox dense v-model="vo.showCol['executed']" color="primary"></v-checkbox></v-list-item-action><v-list-item-title> Executed</v-list-item-title></v-list-item>
            <v-list-item v-if="includeEffectiveness"><v-list-item-action><v-checkbox dense v-model="vo.showCol['effective']" color="primary"></v-checkbox></v-list-item-action><v-list-item-title> Effective</v-list-item-title></v-list-item>
            <v-list-item><v-list-item-action><v-checkbox dense v-model="vo.showCol['pending']" color="primary"></v-checkbox></v-list-item-action><v-list-item-title> Pending</v-list-item-title></v-list-item>
            <v-list-item><v-list-item-action><v-checkbox dense v-model="vo.showCol['categoryName']" color="primary"></v-checkbox></v-list-item-action><v-list-item-title> Category</v-list-item-title></v-list-item>
            <v-list-item><v-list-item-action><v-checkbox dense v-model="vo.showCol['subcategoryName']" color="primary"></v-checkbox></v-list-item-action><v-list-item-title> Subcategory</v-list-item-title></v-list-item>
          </v-list>

          <v-card-actions>
            <v-spacer></v-spacer>

            <v-btn color="primary" text @click="menu = false">Close</v-btn>
          </v-card-actions>
        </v-card>
      </v-menu>

      <v-tooltip top>
        <template v-slot:activator="{ on }">
          <div small class="excel-btn" text v-on="on" @click="downloadExcel">
            <img :src="excelIcon"/>
          </div>
        </template>
        <span>Download Excel</span>
      </v-tooltip>
    </div>

    <div>
      <div v-if="!efforts || !efforts.length">
        <v-alert :value="true" type="info">No data available.</v-alert>
      </div>

      <div v-if="efforts && efforts.length">
        <v-data-table
          :items="efforts"
          :headers="visibleHeaders"
          class="new-back lighten2 bordered rounded-lg">
          <template v-slot:item="{ item }">
            <tr>
    
            <td v-if="vo.showCol['reporterBlueTag']">
              <div style="min-width:65px">
                {{item.reporterBlueTag}}
              </div>
            </td>
            <td v-if="vo.showCol['reporterFullName']">
              <div style="min-width:70px">
                {{item.reporterFullName}}
              </div>
            </td>
            <td v-if="vo.showCol['date']" class="nobr">
              <div style="min-width:45px">
                {{item.date|formatDateWeekDay}}
              </div>
            </td>
            <td v-if="vo.showCol['week']" class="nobr">
              <div style="min-width:50px">
                {{ item.week }}
              </div>
            </td>
            <td v-if="vo.showCol['projectName']">
              <div v-if="!item.projectId" class="" style="min-width:58px">
                <i>NA</i>
              </div>
              <div v-if="item.projectId" style="min-width:58px">
                {{item.projectName}}
              </div>
            </td>
            <td v-if="vo.showCol['projectTypeName']">
              <div v-if="!item.projectId" class="" style="min-width:48px">
                <i>NA</i>
              </div>
              <div v-if="item.projectId" style="min-width:48px">
                {{item.projectTypeName}}
              </div>
            </td>
            <td v-if="vo.showCol['projectCustomerProduct']">
              <div style="min-width:114px">
                {{item.projectCustomerProduct || '-'}}
              </div>
            </td>
            <td v-if="vo.showCol['linkedItem']">
              <div style="min-width:56px">
                {{(item.taskId || item.workItemId) ? 'Linked' : 'Unlinked'}}
              </div>
            </td>
            <td v-if="vo.showCol['linkedItem.name']">
              <div v-if="item.linkedItem" style="min-width:45px">
                {{item.linkedItem.name}}
              </div>
            </td>
            <td v-if="vo.showCol['description']">
              <div style="min-width:82px">
                {{ item.description }}
              </div>
            </td>
            <td v-if="vo.showCol['workItemLinkedWorkItems']">
              <div style="min-width:82px" class="d-flex flex-column">
                <span v-for="linked in item.workItemLinkedWorkItems" :key="linked.id" class="nobr">
                  {{ linked.relatedWorkItemCode }}
                </span>
              </div>
            </td>
            <td v-if="vo.showCol['executed']" class="text-center">
              <div style="min-width:70px" v-if="item.executed">
                {{item.executed|number(2)}}
              </div>
              <div style="min-width:70px" v-else>
                -
              </div>
            </td>
            <td v-if="vo.showCol['effective'] && includeEffectiveness" class="text-center green--text">
              <v-menu v-model="item.effPopover" offset-y :close-on-content-click="false" :min-width="300" :max-width="300"> 
                <template v-slot:activator="{ on }">
                  <div class="cursor-pointer" v-on="on" @click="getEffectiveCalc(item)" style="min-width:68px">
                    {{item.effective|number(2)}}
                  </div>
                </template>
                <v-card>
                  <div class="pa-4">
                    <small class="grey--text pb-2 d-block">Effective Formula</small>
                    <v-img class="formula" aspect-ratio="5.5" src="formula_effective.png"></v-img>
                  </div>
                  <v-divider></v-divider>
                  <v-list v-if="item.effectiveCalc">s
                    <v-list-item>
                      <v-list-item-content>
                        <v-list-item-subtitle>Task</v-list-item-subtitle>
                        <v-list-item-title>
                          <i v-if="!item.taskId">Unlinked</i>
                          <span v-if="item.taskId">{{item.taskName}}</span>
                        </v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>
                    <v-list-item>
                      <v-list-item-content>
                        <v-list-item-subtitle>Effective</v-list-item-subtitle>
                        <v-list-item-title class="success--text">{{item.effectiveCalc.effective | number(2)}}</v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>
                    <v-list-item>
                      <v-list-item-content>
                        <v-list-item-subtitle>Estimated Effort</v-list-item-subtitle>
                        <v-list-item-title>{{item.effectiveCalc.estimatedEffort | number(2)}}</v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>
                    <v-list-item>
                      <v-list-item-content>
                        <v-list-item-subtitle>Executed</v-list-item-subtitle>
                        <v-list-item-title>{{item.effectiveCalc.executed | number(2)}}</v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>
                    <v-list-item>
                      <v-list-item-content>
                        <v-list-item-subtitle>Total Executed</v-list-item-subtitle>
                        <v-list-item-title>{{item.effectiveCalc.totalExecuted | number(2)}}</v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>
                    <v-list-item>
                      <v-list-item-content>
                        <v-list-item-subtitle>Pending Effort</v-list-item-subtitle>
                        <v-list-item-title>{{item.effectiveCalc.finalPending | number(2)}}</v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>
                  </v-list>
                  <v-divider></v-divider>
    
                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn small color="primary" text @click="item.effPopover = false">Got it</v-btn>
                  </v-card-actions>
                </v-card>
              </v-menu>
              
            </td>
            <td v-if="vo.showCol['pending']" class="text-center yellow--text text--darken-3">
              <div style="min-width:65px">
                {{item.pending|number(2) || '-'}}
              </div>
            </td>
            <td v-if="vo.showCol['categoryName']">
              <div style="min-width:68px">
                {{item.categoryName || 'Ninguna'}}
              </div>
            </td>
            <td v-if="vo.showCol['subcategoryName']">
              <div style="min-width:90px">
                {{item.subcategoryName || 'Ninguna'}}
              </div>
            </td>
            </tr>
          </template>
          <template slot="footer">
            <div style="display: inline-block;" v-if="colspan1" :colspan="colspan1" class="text-right pl-3">Total Executed: </div>
            <div style="display: inline-block; margin-left: 5px;" class="text-center">{{totalExecuted | number(2)}}</div>
            <div style="display: inline-block; width: 30px;"></div>
            <div style="display: inline-block;" v-if="colspan1" :colspan="colspan1" class="text-right green--text">Total Effective: </div>
            <div style="display: inline-block; margin-left: 5px;" class="text-center green--text">{{totalEffective | number(2)}}</div>
            <div style="display: inline-block;" v-if="colspan2" :colspan="colspan2"></div>
          </template>
        </v-data-table>
      </div>
    </div>

    <choose-task-dialog ref="chooseTaskDialog" :selectParentTasks="true"></choose-task-dialog>
  </div>
</template>

<script>
import _ from "lodash"
import moment from 'moment'
import ChooseTaskDialog from "./../../dialogs/ChooseTaskDialog"
import excelIcon from '../../assets/excel-icon.svg';

export default {
  title: 'Report',
  components: { 
    ChooseTaskDialog 
  },
  props: [],
  data: () => ({
    excelIcon,
    filters: {
      dateStart: moment().startOf('day').add(-7, 'days').format("YYYY-MM-DD"),
      dateEnd: moment().startOf('day').format("YYYY-MM-DD"),
      projectId: [],
      reporterId: [],
      workItemRelatedProjectId: [],
      linked: null,
      includeEffectiveness: true,
      includeNoProjectLinked: false,
      taskId: null,
      onlyActive: true,
      linkedWorkItemCode: '',
    },
    chosenTask: {},
    includeEffectiveness: true,
    vm: {},
    vo: {
      showCol: {
        reporterBlueTag: true,
        reporterFullName: true,
        date: true,
        week: true,
        projectName: true,
        projectTypeName: true,
        projectCustomerProduct: true,
        linkedItem: true,
        'linkedItem.name': true,
        description: true,
        workItemLinkedWorkItems: true,
        executed: true,
        effective: true,
        pending: true,
        categoryName: true,
        subcategoryName: true,
      }
    },
    colspan1: 0,
    colspan2: 0,
    projects: [],
    efforts: [],
    users: [],
    totalExecuted: null,
    totalEffective: null,
    linkedItems: [
      {id: null, name: 'All'},
      {id: false, name: 'Unlinked'},
      {id: true, name: 'Linked'}
    ],
    menu: false,
    headers: [
      { show: 'reporterBlueTag',        text: "BlueTag",          value: "reporterBlueTag" },
      { show: 'reporterFullName',       text: "Reporter",         value: "reporterFullName" },
      { show: 'date',                   text: "Date",             value: "date" },
      { show: 'week',                   text: "Week",             value: "week" },
      { show: 'projectName',            text: "Project",          value: "projectName" },
      { show: 'projectTypeName',        text: "Project Type",     value: "projectTypeName" },
      { show: 'projectCustomerProduct', text: "Project Customer", value: "projectCustomerProduct" },
      { show: 'linked',                 text: "Linked",           value: "linkedItem", sort: (a, b) => {
        if(a && !b) return -1
        if(!a && b) return 1
        return 0
      } },
      { show: 'task',                   text: "WorkItem / Task",  value: "linkedItem.name", },
      { show: 'description',            text: "Description",      value: "description" },
      { show: 'workItemLinkedWorkItems',text: "Related Work Items",value: "workItemLinkedWorkItems" },
      { show: 'executed',               text: "Executed",         value: "executed", align: "center" },
      { show: 'effective',              text: "Effective",        value: "effective", align: "center" },
      { show: 'pending',                text: "Pending",          value: "pending", align: "center" },
      { show: 'categoryName',           text: "Category",         value: "categoryName" },
      { show: 'subcategoryName',        text: "Subcategory",      value: "subcategoryName" },
    ],
  }),
  computed: {
    visibleHeaders() {
      let headers = this.headers.filter(h => {
        let visible = this.vo.showCol[h.value];
        if (h.value === "effective" ) {
          visible = visible && this.includeEffectiveness;
        }
        return visible;
      });
      return headers;
    },
    shownProjects() {
      if(this.filters.onlyActive){
        return this.projects.filter(p => p.status == 1)
      }
      return this.projects
    },
  },
  created() {
    this.$q.log(7, 16, 3, 'NA')
    this.setUserPreferences("ActivityLogReport", "vm", "vo", "filters")

    if (this.$route.query.from) {
      this.filters.dateStart = moment(this.$route.query.from).format("YYYY-MM-DD");
    }
    if (this.$route.query.to) {
      this.filters.dateEnd = moment(this.$route.query.to).format("YYYY-MM-DD");
    }
    // if (parseInt(this.$route.query.type) >= 0) {
    //   this.filters.type = parseInt(this.$route.query.type);
    // }
    if (this.$route.query.linked === "1") {
      this.filters.linked = 1;
    }
    if (this.$route.query.linked === "0") {
      this.filters.linked = 0;
    }
    if (this.$route.query.projectId) {
      this.filters.projectId = this.$route.query.projectId;
    }
    if (this.$route.query.showEffective === "true" || this.$route.query.showEffective === "false") {
      this.filters.includeEffectiveness = this.$route.query.showEffective;
    }

    this.$http
      .get(`/api/user`)
      .then(res => {
        this.users = res.data;
      })
      .catch(res => {
        window.getApp.error("Cannot obtain users.");
        console.log(res);
      });

    this.$http
      .get(`/api/project`)
      .then(res => {
        this.projects = res.data;
      })
      .catch(res => {
        window.getApp.error("Cannot obtain projects.");
        console.log(res);
      });

    let hasPredefinedQuery = !!_.values(this.$route.query).length
    if(hasPredefinedQuery) {
      this.updateReport()
    }

  },
  watch: {
    "vo.showCol": {
      deep: true,
      handler: function () { this.updateColspans(); },
    }
  },
  methods: {
    setOnlyActive(value){
      if(value){
        let activeIds = this.projects.filter(p => p.status == 1).map(p => p.id)
        this.filters.projectId = this.filters.projectId.filter(p => activeIds.includes(p))
      }
      this.filters.onlyActive = value
    },
    getEffectiveCalc(effort) {
      this.$http
        .get(`/api/report/effortEffectiveCalc/${effort.id}`)
        .then(res => {
          let effectiveCalc = res.data
          this.$set(effort, "effectiveCalc", effectiveCalc)
        })
        .catch(res => {
          window.getApp.error("Cannot obtain calculation details.")
          console.log(res)
        });
    },
    chooseTask() {
      if(this.filters.projectId.length != 1)
        return;
      this.$refs.chooseTaskDialog.open(this.filters.projectId[0], this.users)
        .then(task => {
          this.filters.taskId = task.id
          this.chosenTask = task
        })
    },
    updateColspans() {
      this.colspan1 = 0;
      this.colspan2 = 0;
      if (this.vo.showCol.reporterFullName) this.colspan1 += 1;
      if (this.vo.showCol.date) this.colspan1 += 1;
      if (this.vo.showCol.week) this.colspan1 += 1;
      if (this.vo.showCol.projectName) this.colspan1 += 1;
      if (this.vo.showCol.projectTypeName) this.colspan1 += 1;
      if (this.vo.showCol.projectCustomerProduct) this.colspan1 += 1;
      if (this.vo.showCol.linkedItem) this.colspan1 += 1;
      if (this.vo.showCol['linkedItem.name']) this.colspan1 += 1;
      if (this.vo.showCol.description) this.colspan1 += 1;

      if (this.vo.showCol.categoryName) this.colspan2 += 1;
      if (this.vo.showCol.subcategoryName) this.colspan2 += 1;
    },
    updateReport() {
      this.filters.reporterId = this.filters.reporterId.filter(r => r)
      this.$http
        .post(`/api/report/activityLog`, this.filters)
        .then(res => {
          this.includeEffectiveness = this.filters.includeEffectiveness
          this.efforts = res.data
            .map(x => ({
              ...x,
              week: moment(x.date).format('GGGG-ww'),
            }))
          for(let effort of this.efforts){
            let tagDiv = document.createElement("div")
            tagDiv.innerHTML = effort.description || ''
            effort.description = tagDiv.innerText
          }
          this.updateColspans();

          // initTable()
          this.totalExecuted = _.map(this.efforts, "executed").reduce(function (a, b) { return a + b }, 0)
          this.totalEffective = _.map(this.efforts, "effective").reduce(function (a, b) { return a + b }, 0)
        })
        .catch(res => {
          window.getApp.error("Cannot obtain report.");
          console.log(res);
        });
        
    },
    formatNumber(value, decimalPlaces, minDecimalPlaces) {
      if (value === null || value === undefined || !value.toFixed) return;
      if (!decimalPlaces) {
        decimalPlaces = 0;
      }
      if (!minDecimalPlaces && minDecimalPlaces !== 0) {
        minDecimalPlaces = decimalPlaces;
      }
      return value.toLocaleString('en', {
        maximumFractionDigits: decimalPlaces,
        minimumFractionDigits: minDecimalPlaces,
      });
    },
    downloadExcel(){
      let props = {
        Title: `Activity Log`,
        Subject: "Activity Log",
        Author: 'BlueSurf',
        CreatedDate: new Date()
      };

      // Fill sheet info
      let data = [], row = [];

      for(let header of this.headers) {
        if(this.vo.showCol[header.value])
          row.push(header.text)
      }
      data.push(row);

      for(let effort of this.efforts) {
        row = []
        for(let header of this.headers) {
          if(this.vo.showCol[header.value]){
            if(header.value == 'date'){
              row.push(moment(String(effort.date)).format('(ddd) MMM D, YYYY'))
            }
            else if(header.value == 'week'){
              row.push(moment(String(effort.date)).format('GGGG-ww'))
            }
            else if(header.value == 'linkedItem'){
              row.push(effort.linkedItem ? 'Linked' : 'Unlinked')
            }
            else if(header.value == 'linkedItem.name'){
              row.push(effort.linkedItem?.name || '')
            }
            else if(header.value == 'description'){
              row.push(effort.description || '')
            }
            else if(header.value == 'effective'){
              row.push(this.formatNumber(effort.effective, 2) || '-')
            }
            else if(header.value == 'pending'){
              row.push(this.formatNumber(effort.pending, 2) || '-')
            }
            else {
              row.push(effort[header.value] || '')
            }
          }
        }
        data.push(row)
      }

      let sheets = [{
        name: 'Activity Log',
        data
      }]

      this.createExcel(props, sheets);
    },
  }
    
}
</script>

<style scoped lang="scss">
select.form-control {
  height: 26px;
  padding: 3px 6px;
  border-radius: 2px;
  margin-bottom: 10px;
  width: 300px;
}
.view-options label { margin: 2px; }
.view-options .checkbox { margin: 2px; }
  
.excel-btn{
  background: #21a366;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  height: 38px;
  width:  38px;
  color: white;
  border-radius: 50%;
  cursor: pointer;
  margin: 0 15px;
  box-shadow: 0 2px 5px #888;
  
  img{
    filter: invert(99%) sepia(7%) saturate(35%) hue-rotate(255deg) brightness(110%) contrast(99%);
    width: 28px;
  }
}

.filters > * {
  flex-grow: 0;
}
</style> 