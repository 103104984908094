<template>
    <v-dialog :fullscreen="$vuetify.breakpoint.xsOnly" scrollable persistent 
        v-model="dialog" :max-width="550" v-if="dialog">
        <v-form ref="form" v-model="validForm">
            <v-card style="padding: 20px;">
                <v-card-title class="pt-2 pb-4">
                    {{
                        link.id ?
                        'Edit' :
                        'New'
                    }}
                </v-card-title>
                <v-card-text>
                    <v-row>
                        <v-col>
                            <label style="font-size:1.1em">Icon *</label>
                            <v-btn class="" color="primary" fab @click="iconPickerDialog = true">
                                <v-icon dark>{{link.icon}}</v-icon>
                            </v-btn><br>
                        </v-col>
                        <v-col cols="10">
                            <label class="">Name *</label>
                            <v-text-field
                                v-model="link.name"
                                solo
                                outlined
                                required :rules="[v => (!!v && !!v.trim()) || 'Name is required']"
                            ></v-text-field>
                        </v-col>
                    </v-row>

                    
                    
                    <label class="">URL *</label>
                    <v-text-field
                        v-model="link.url"
                        solo
                        outlined
                        required :rules="[v => (!!v && !!v.trim()) || 'URL is required']"
                    ></v-text-field>

                </v-card-text>
                
                <v-card-actions class="px-6">
                    <v-spacer></v-spacer>
                    <v-btn color="primary" text @click="cancel">
                        Cancel
                    </v-btn>
                    <v-btn color="primary" depressed @click="save" :disabled="!validForm || !link.icon">
                        {{
                            link.id ?
                            'Save' :
                            'Create'
                        }}
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-form>

        <v-dialog v-model="iconPickerDialog" v-if="iconPickerDialog">
            <v-card>
                <v-card-title class="headline" primary-title>
                    Select Icon
                </v-card-title>
                <v-card-text>
                    <v-text-field placeholder="search" v-model="searchIcon" hide-details outlined></v-text-field>
                    <div v-for="(category, ix) in iconCategories" :key="ix">
                        <h2 class="mt-5 mb-2 text-capitalize">{{category.name}}</h2>
                        <v-row wrap>
                            <template v-for="(icon, ix) in category.icons">
                                <v-col class="icon-tile d-inline-block text-center" v-if="!icon.hidden" :key="ix">
                                    <v-btn x-large dark text color="primary"
                                        @click="link.icon = icon.name; iconPickerDialog = false"
                                    >
                                        <v-icon large dark>{{icon.name}}</v-icon>
                                    </v-btn>
                                <div class="icon-name">
                                    <small>
                                        {{icon.name}}
                                    </small>
                                </div>
                                </v-col>
                            </template>
                        </v-row>
                    </div>
                </v-card-text>
                <v-divider></v-divider>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="primary" text @click="iconPickerDialog = false">
                        Close
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </v-dialog>
</template>
<script>
import materialIcons from '../utils/material-icons'

export default {
    props: ['dialog', 'cancel', 'callback', 'link'],
    data(){
        return{
            validForm: false,
            icons: materialIcons,
            iconPickerDialog: false,
            iconCategories: [],
            searchIcon: '',
        }
    },
    mounted() {
        // Initialize iconCategories
        let categories = this.icons.map(i => i.categories).flat()
        categories = [...new Set(categories)]
        categories.sort()
        categories = categories
            .map(name => ({ 
            name: name,
            icons: this.icons.filter(i => i.categories.indexOf(name) != -1),
            }))
        this.iconCategories = categories
    },
    watch: {
        searchIcon() {
            this.iconCategories.forEach(cat => cat.icons.forEach(i => {
                i.hidden = !i.name.includes(this.searchIcon)
                && !(i.tags && i.tags.some(t => t.includes(this.searchIcon)))
            }))
        },
    },
    methods:{
        save(){
            this.$http.post(`api/user/current/link`, this.link).then(res => {
                if(this.link.id)
                    window.getApp.success("Link updated successfully.");
                else
                    window.getApp.success("Link created successfully.");
                this.callback();
            }).catch(response => {
                window.getApp.error("Cannot save link.");
                console.log(response);
            });
        },
    }
}
</script>
<style scoped>
.color-black{
    color: black !important;
}
</style>